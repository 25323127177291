import portalAPIGateway from '../../../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteSecureHomeworkerAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteSecureHomeworkerAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteQuoteSecureHomeworkerAPIMixin_saveQuoteSecureHomeworkerToDB: async function (quoteId, quoteSecureHomeworker) {
      var axiosConfig = this.iQuoteQuoteSecureHomeworkerAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.put('iquote/quotesecureHomeworker/' + quoteId, quoteSecureHomeworker, axiosConfig).then((response) => {
          returnValue = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteSecureHomeworkerAPIMixin_saveQuoteSecureHomeworkerToDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteSecureHomeworkerAPIMixin_deleteQuoteSecureHomeworkerFromDB: async function (quoteSecureHomeworker) {
      var axiosConfig = this.iQuoteQuoteSecureHomeworkerAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.delete('iquote/quotesecureHomeworker/' + quoteSecureHomeworker.id, null, axiosConfig).then((response) => {
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteSecureHomeworkerAPIMixin_deleteQuoteSecureHomeworkerFromDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteSecureHomeworkerAPIMixin_getQuoteSecureHomeworkerFromDb: async function (quoteId) {
      var axiosConfig = this.iQuoteQuoteSecureHomeworkerAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquote/quotesecureHomeworker/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteSecureHomeworkerAPIMixin_getQuoteSecureHomeworkerFromDb returned error: ' + error)
      }

      return data
    }
  }
}
