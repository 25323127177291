<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-support"
          primary-title
        >
          Support Page
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="The support page allows the user to access administrative functions, the visibility of which is determined by the user’s permissions."
                image-source="support.png"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpSupport',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
