<template>
  <div
    id="changeUserEmail"
    class="updateSectionContainer"
  >
    <div
      id="frmUpdateUser"
      class="changeUserHolder"
    >
      <div class="changeUserFieldHolder">

        <label
          for="txtUpdateEmailAddress"
          class="changeUserLabel"
        >Current Email Address:</label>
        <label
          id="lblUpdateEmailAddress"
          class="changeUserEmailAddress"
        >{{ userEmailAddress }}</label>
        <label
          v-if="updateEntityPermission"
          for="txtUpdateEmailAddress"
          class="changeUserLabel"
        >New Email Address:</label>
        <input
          v-if="updateEntityPermission"
          id="txtUpdateEmailAddress"
          :class="{ errorHighlight: emailError || userNameExists }"
          v-model.trim="newEmailAddress"
          type="text"
          class="changeUserTextbox"
          @blur="checkForm"
        >
      </div>
      <Spinner v-if="isSaving" />
      <input
        v-if="updateEntityPermission && !isSaving"
        id="changeUserButton"
        :disabled="!formOK"
        :class="getButtonClass"
        style="margin-left: 318px"
        type="button"
        value="Update"
        title="Updates the user email address"
        @click="updateSelectedUser()"
      >
    </div>
  </div>
</template>

<script>
import { DataEventBus } from '../../events/dataEvents'
import { usersMixin } from '../../mixins/users'
import { adminMixin } from '../../mixins/adminMixin'
import { permissionsMixin } from '../../mixins/permissions'
import Spinner from '../common/spinner'
import { mapActions } from 'vuex'
import AuthRepository from '../../communications/repositories/authRepository'

export default {
  components: {
    Spinner
  },
  mixins: [
    usersMixin,
    permissionsMixin,
    AuthRepository,
    adminMixin
  ],
  props: {
    user: {
      type: Object,
      default: function () { return {} }
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      userEmailAddress: '',
      newEmailAddress: '',
      areas: [],
      formOK: false,
      emailError: false,
      userNameExists: false,
      isSaving: false
    }
  },
  computed: {
    updateEntityPermission () {
      return this.$store.getters.updateEntityPermission
    },
    getButtonClass () {
      if (this.formOK) {
        return 'changeUserButtonEnabled'
      } else {
        return 'changeUserButton'
      }
    }
  },
  watch: {
    user () {
      this.userEmailAddress = this.user.emailAddress.toLowerCase()
      this.setComponent()
    }
  },
  mounted () {
    this.userEmailAddress = this.user.emailAddress.toLowerCase()
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setReloadUsers'
    ]),
    setComponent: async function () {
      this.formOK = false
      this.emailError = false
      this.userNameExists = false
      this.isSaving = false
    },
    updateSelectedUser: async function () {
      console.log(this.user)
      this.isSaving = true
      this.checkForm()
      if (this.formOK) {
        var usr = {
          id: this.user.id,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          emailAddress: this.newEmailAddress
        }
        // If the email address is the same as the user name, then we will let the user know and quit the function.
        if (this.newEmailAddress.toLowerCase() === this.userEmailAddress.toLowerCase()) {
          this.$store.commit('showMessage', { content: 'This email address: ' + this.newEmailAddress + ' is the same as the existing address.', color: 'red', timeout: 3000 })
        } else {
          // now we've correctly populated the user object, we'll send it off to the user service to update
          var rtn = await this.changeUserEmail(usr)
          if (rtn.length > 0) {
            this.$store.commit('showMessage', { content: 'Failed to change email address: ' + this.userEmailAddress + ' to ' + this.newEmailAddress + ' in the database.', color: 'red', timeout: 3000 })
          } else {
            usr.ChannelCompanyID = this.$store.getters.channelCompany.id
            this.sendUserUpdateEmail(usr)
            this.$store.commit('showMessage', { content: 'Successfully change email address of user to ' + this.newEmailAddress, color: 'blue', timeout: 3000 })
            setTimeout(function () {
              DataEventBus.$emit('usersSet', true)
            }, 500)
          }
        }
      } else {
        this.$store.commit('showMessage', { content: 'Failed to update the user in the authorisation database.', color: 'red', timeout: 3000 })
      }
      this.isSaving = false
    },
    async sendUserUpdateEmail (usr) {
      var rtn = await this.sendUserUpdatedEmail(usr)
      if (rtn.length > 0) {
        this.$store.commit('showMessage', { content: 'Unable to send the email to confirm the user detail updates', color: 'red', timeout: 3000 })
      }
    },
    async checkForm () {
      this.emailError = this.userEmailAddress.length === 0 || !this.validateEmail(this.newEmailAddress) || !this.validateFirstChar(this.newEmailAddress)
      this.userNameExists = false

      // Check if we are a maintel.co.uk address and if so, then we are not allowed to change it.
      var maintelAddressError = false
      if (this.user.emailAddress.indexOf('@maintel.co.uk') > 0 && this.newEmailAddress.indexOf('@maintel.co.uk') === -1) {
        this.$store.commit('showMessage', { content: 'You are not allowed to replace a Maintel email address with one that is external to Maintel.', color: 'red', timeout: 6000 })
        maintelAddressError = true
      }

      if (!this.emailError) {
        this.userNameExists = await this.validateUsername(this.newEmailAddress.toLowerCase())
      }

      var error = this.emailError || this.userNameExists || this.maintelAddressError

      if (error) {
        var errorMessage = ('Please fix the issues with the following field(s): ' +
            (this.maintelAddressError ? 'You are not allowed to change a maintel address to any ohter, ' : '') +
            (this.emailError ? 'provide a valid email.' : this.userNameExists ? 'the email address ' + this.newEmailAddress + ' is already in use.' : '')
        ).replace(/,\s*$/, '')
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }

      this.formOK = !error && !this.userNameExists && !maintelAddressError && !this.emailError
    },
    validateFirstChar (email) {
      var re = /^[A-Za-z0-9_-]*$/
      return re.test(email[0])
    },
    validateEmail (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async validateUsername (username) {
      var rtn = false
      if (username !== null) {
        if (username !== this.user.emailAddress.toLowerCase()) {
          await this.getUserListByUsername(username)
            .then(result => {
              rtn = !result || result.some(user => user.userName.toLowerCase() === username)
            })
            .catch((_) => {
              // console.log('Username not found')
            })
        }
      }
      return rtn
    }
  }
}
</script>

<style scoped>
  .changeUserLabel {
    width: 166px;
    line-height: 2em;
    display: inline-block;
    font-size: 0.9em;
  }
  .changeUserTextbox {
    width: 260px;
    padding-left: 5px;
    display: inline-block;
  }
  .changeUserData {
    width: 145px;
    display: inline-block;
  }
  .changeUserEmailAddress {
    word-wrap: break-word;
    width: fit-content;
    block-size: fit-content;
    font-weight: bold;
  }
  .updateSectionContainer {
    width:400px;
    display: none;
    margin-bottom:10px;
  }
  .changeUserHolder {
    background-color: rgba(255,255,255,0.7);
    padding:10px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .changeUserButton {
    opacity: 0.7;
    background-color: grey;
    cursor: default;
  }
  .changeUserButtonEnabled {
    opacity: 1;
  }
</style>
