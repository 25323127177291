<template>
  <div
    v-if="$store.getters.openSubForm"
    class="customerNewRoleBackground"
  >
    <div class="customerNewRoleHolder">
      <div class="roleTableholder">
        <table>
          <thead>
            <tr>
              <th style="width:10%;">
                <img
                  :src="newRoleIcon"
                  class="icon"
                >
              </th>
              <th style="width:80%;">Add Role to User for Top Level Site</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="!roles.length"
              class="alert"
            >
              <td colspan="2">
                <p
                  class="alert"
                >
                  There are no roles assigned to this top level site to add.
                </p>
              </td>
            </tr>
            <tr
              v-for="m in roles"
              id="memberList"
              :key="m.id"
            >
              <td>
                <input
                  :id="m.id"
                  type="checkbox"
                  title="Select User"
                  class="memberItem"
                  @change="checkForm"
                >
              </td>
              <td>{{ m.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <input
        id="permissionCancelNewRoleButton"
        type="button"
        class="permissionCancelNewRoleButton"
        value="Cancel"
        title="Cancel the add operation"
        @click="cancelAdd()"
      >
      <input
        v-if="roles.length"
        id="permissionAddNewRoleButton"
        :class="getButtonClass"
        type="button"
        value="Add Role"
        title="Adds the new role to the permission"
        @click="addRole()"
      >
      <input
        type="text"
        class="focusField"
        @focus="cycleFocus"
      >
    </div>
  </div>
</template>
<script>
import { usersMixin } from '../../mixins/users'
import { permissionsMixin } from '../../mixins/permissions'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'UserCustomerNewRole',
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    roles: {
      type: Array,
      default: () => []
    },
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      formOK: false,
      targetUserId: -1
    }
  },
  computed: {
    ...mapState([
      'openSubForm'
    ]),
    getButtonClass () {
      if (this.formOK) {
        return 'permissionAddNewRoleButtonEnabled'
      } else {
        return 'permissionAddNewRoleButton'
      }
    },
    newRoleIcon () {
      return require('../../assets/icons/People.png')
    },
    openSubForm () {
      return this.$store.getters.openSubForm
    }
  },
  watch: {
    openSubForm () {
      if (document.getElementById('memberList') && this.openSubForm) {
        document.getElementById('memberList').focus()
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'setSubForm'
    ]),
    checkForm () {
      var selected = false
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          selected = true
        }
      }
      this.formOK = selected
    },
    checkExists (entity, id) {
      return entity.id === id
    },
    closeForm () {
      this.setSubForm(false)
    },
    addRole: async function () {
      var memberItems = document.getElementsByClassName('memberItem')
      var assignedRoles = []

      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          await this.addEntityRole(this.userid, memberItems[i].id)
          assignedRoles.push(this.roles.find(o => o.id.toString() === memberItems[i].id))
        }
      }
      this.$emit('RolesAdded', assignedRoles)
      this.closeForm()
    },
    cancelAdd () {
      this.$emit('RoleCancelled')
      this.$store.commit('showMessage', { content: 'Please assign a role to the customer before the customer assignment can be complete.', color: 'red', timeout: 5000 })
      this.closeForm()
    },
    cycleFocus () {
      if (document.getElementById('memberList')) {
        document.getElementById('memberList').focus()
      }
    }
  }
}

</script>
<style scoped>
  .customerNewRoleBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .customerNewRoleHolder {
    display: inline-block;
    background-color: rgba(255,255,255,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 160px);
    top: calc(10%);
    height: 320px;
    width: 360px;
    z-index: 999;
  }
  .permissionCancelNewRoleButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .permissionAddNewRoleButton {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    opacity: 0.5;
  }
  .permissionAddNewRoleButtonEnabled {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .roleTableholder {
    overflow: auto;
    height:254px;
    max-height:300px;
  }
  .focusField {
    position: absolute;
    left:0;
    bottom:0;
    width:0;
    background-color: rgba(240,240,240,1);
    opacity: 0;
  }
  .memberItem {
    margin: 7px;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
    color: black;
    background-color: #fff;
  }
  .icon {
    height: 25px;
    width: 25px;
  }
</style>
