<template>
  <div
    :class="{disabledTooltip: disableTooltip}"
    class="tooltiptext"
  >
    {{ text }}
    <span
      :style="tooltipTextStyle"
      class="tooltipcontent"
      v-html="tooltipContent" />
  </div>
</template>

<script>
export default {
  name: 'TooltipText',
  props: {
    text: {
      type: String,
      default: ''
    },
    tooltipContent: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '400'
    },
    align: {
      type: String,
      default: 'left'
    },
    disableTooltip: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    tooltipTextStyle () {
      if (this.align.toLowerCase() === 'center' || this.align.toLowerCase() === 'centre') {
      /* Use half of the width (120/2 = 60), to center the tooltip */
      /* If we want to use this, need to make it appear on top of left-hand menu if tooltip is too close to the left-hand side.  (z-index didn't work) */
        return 'width:' + this.width + 'px;margin-left:-' + (this.width / 2) + 'px;'
      } else if (this.align.toLowerCase() === 'right') {
        return 'width:' + this.width + 'px;margin-left:-' + this.width + 'px;'
      } else {
        return 'width:' + this.width + 'px;'
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>

div.tooltiptext {
  position: relative;
  font-weight: bold;
}

div.disabledTooltip {
  font-weight: normal;
}

/* Tooltip content */
.tooltiptext .tooltipcontent {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 100;
  font-size: 12px;
  white-space: normal;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 100%;
  left: 50%;

  z-index: 999;
}

/* Tooltip text */
.tooltiptext .tooltipcontent strong, b {
  font-size: 1em;
}

/* Padding for list items in tooltips */
/* Using deep selector >>> for this.  See: https://medium.com/@brockreece/scoped-styles-with-v-html-c0f6d2dc5d8e */
.tooltiptext .tooltipcontent >>> li {
  margin-bottom:5px;
  text-align:left;
}

/* Tooltip text */
.tooltiptext .tooltipcontent >>> li strong, b {
  font-size: 1em;
}

div.tooltiptext:hover {
  background-color: orange;
}

div.disabledTooltip:hover {
  background-color: inherit;
}

/* Show the tooltip text when you mouse over the tooltip container */
div.tooltiptext:hover span.tooltipcontent {
  visibility: visible;
}

div.disabledTooltip:hover span.tooltipcontent {
  visibility: hidden;
}

</style>
