import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { mapActions } from 'vuex'

export const iQuoteCalculationsAvayaAACCMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin],
  data () {
    return {
      quoteTechnologyAvayaAACC: {},
      quote: {},
      licenses: {}
    }
  },
  computed: {
    aaccCalcs_usersToHandle () {
      if (this.quoteTechnologyAvayaAACC.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyAvayaAACC.userProfiles.forEach(userProfile => {
        users += userProfile.maxAllowed
      })
      return users
    },
    aaccCalcs_voiceUsersToHandle () {
      if (this.quoteTechnologyAvayaAACC.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyAvayaAACC.userProfiles.forEach(userProfile => {
        if (userProfile.description.toLowerCase().includes('voice agent')) {
          users += userProfile.maxAllowed
        }
      })
      return users
    },
    aaccCalcs_multimediaUsersToHandle () {
      if (this.quoteTechnologyAvayaAACC.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyAvayaAACC.userProfiles.forEach(userProfile => {
        if (userProfile.description.toLowerCase().includes('multimedia agent')) {
          users += userProfile.maxAllowed
        }
      })
      return users
    },
    aaccCalcs_usersToLicense () {
      if (this.quoteTechnologyAvayaAACC.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyAvayaAACC.userProfiles.forEach(userProfile => {
        users += userProfile.minCommitment
      })
      return users
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyAvayaAACC'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcAvayaAACCVMs: function () {
      this.quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC

      if (this.quoteTechnologyAvayaAACC.id === undefined) {
        return
      }

      this.quote = this.$store.getters.quote

      this.calcAACC_VMs()
      this.calcAACCAdjunct_VMs()
    },
    calcAACC_VMs: function () {
      if (this.quoteTechnologyAvayaAACC.options.avayaAACCDesktopOptionId === this.iQuoteConstants.avayaAACCDesktopOptions.WORKSPACES) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_AACC_WIDGET_WEB_SERVER, this.iQuoteConstants.dataCentres.fareham, true, 1)
      }

      var packageId = 0

      if (this.aaccCalcs_voiceUsersToHandle > 0 && this.aaccCalcs_multimediaUsersToHandle === 0) {
        if (this.quoteTechnologyAvayaAACC.options.avayaAACCDesktopOptionId === this.iQuoteConstants.avayaAACCDesktopOptions.AGENT_DESKTOP) {
          if (this.aaccCalcs_voiceUsersToHandle >= 1501) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICE_NODESKTOP_PACKAGE5
          } else if (this.aaccCalcs_voiceUsersToHandle >= 751) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICE_NODESKTOP_PACKAGE4
          } else if (this.aaccCalcs_voiceUsersToHandle >= 301) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICE_NODESKTOP_PACKAGE3
          } else if (this.aaccCalcs_voiceUsersToHandle >= 151) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICE_NODESKTOP_PACKAGE2
          } else if (this.aaccCalcs_voiceUsersToHandle >= 1) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICE_NODESKTOP_PACKAGE1
          }
        } else {
          if (this.aaccCalcs_usersToHandle >= 301) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICE_WORKSPACES_PACKAGE2
          } else if (this.aaccCalcs_usersToHandle >= 1) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICE_WORKSPACES_PACKAGE1
          }
        }
      } else if (this.aaccCalcs_voiceUsersToHandle > 0 && this.aaccCalcs_multimediaUsersToHandle > 0) {
        if (this.quoteTechnologyAvayaAACC.options.avayaAACCDesktopOptionId === this.iQuoteConstants.avayaAACCDesktopOptions.AGENT_DESKTOP) {
          if (this.aaccCalcs_usersToHandle >= 1501) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICEANDMM_AADD_PACKAGE6
          } else if (this.aaccCalcs_usersToHandle >= 751) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICEANDMM_AADD_PACKAGE5
          } else if (this.aaccCalcs_usersToHandle >= 501) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICEANDMM_AADD_PACKAGE4
          } else if (this.aaccCalcs_usersToHandle >= 301) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICEANDMM_AADD_PACKAGE3
          } else if (this.aaccCalcs_usersToHandle >= 151) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICEANDMM_AADD_PACKAGE2
          } else if (this.aaccCalcs_usersToHandle >= 1) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICEANDMM_AADD_PACKAGE1
          }
        } else {
          if (this.aaccCalcs_usersToHandle >= 301) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICEANDMM_WORKSPACES_PACKAGE2
          } else if (this.aaccCalcs_usersToHandle >= 1) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_VOICEANDMM_WORKSPACES_PACKAGE1
          }
        }
      } else if (this.aaccCalcs_voiceUsersToHandle === 0 && this.aaccCalcs_multimediaUsersToHandle > 0) {
        if (this.quoteTechnologyAvayaAACC.options.avayaAACCDesktopOptionId === this.iQuoteConstants.avayaAACCDesktopOptions.AGENT_DESKTOP) {
          if (this.aaccCalcs_multimediaUsersToHandle >= 1501) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_MM_AADD_PACKAGE
          } else if (this.aaccCalcs_multimediaUsersToHandle >= 1001) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_MM_AADD_PACKAGE3
          } else if (this.aaccCalcs_multimediaUsersToHandle >= 501) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_MM_AADD_PACKAGE2
          } else if (this.aaccCalcs_multimediaUsersToHandle >= 1) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_MM_AADD_PACKAGE1
          }
        } else {
          if (this.aaccCalcs_multimediaUsersToHandle >= 1) {
            packageId = this.iQuoteConstants.packages.AVAYA_AACC_MM_WORKSPACES_PACKAGE2
          }
        }
      }

      if (packageId !== 0) {
        var packageVirtualMachines = this.$store.getters.packageVirtualMachines
        for (let index = 0; index < packageVirtualMachines.length; index++) {
          const packageVirtualMachine = packageVirtualMachines[index]
          if (packageVirtualMachine.technologyPackageId === packageId) {
            this.iQuoteStore_AddRequiredVmToQuote(packageVirtualMachine.virtualMachineId, this.iQuoteConstants.dataCentres.fareham, true, packageVirtualMachine.quantity)
            this.iQuoteStore_AddRequiredVmToQuote(packageVirtualMachine.virtualMachineId, this.iQuoteConstants.dataCentres.goswellRoad, true, packageVirtualMachine.quantity)
          }
        }
      }

      var usersToAllocate = this.aaccCalcs_usersToHandle

      while (usersToAllocate > 0) {
        if (usersToAllocate >= 201) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_AACC_AMS_LARGE, this.iQuoteConstants.dataCentres.fareham, true, 1)
          usersToAllocate -= 400
        }
        if (usersToAllocate >= 1) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_AACC_AMS_SMALL, this.iQuoteConstants.dataCentres.fareham, true, 1)
          usersToAllocate -= 200
        }
      }
    },
    calcAACCAdjunct_VMs: function () {
      if (this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls > 0) {
        var storagePeriodYears
        const contingencyMultiplier = 1.1
        const storagePerHourMb = 4.21 /* G.729A Mono */

        if (this.quoteTechnologyAvayaAACC.adjuncts.retentionPeriod > this.quote.termLengthMonths) {
          storagePeriodYears = this.quoteTechnologyAvayaAACC.adjuncts.retentionPeriod / 12
        } else {
          storagePeriodYears = this.quote.termLengthMonths / 12
        }

        const workingDaysPerWeek = 7
        const weeksPerYear = 52
        var callsPerYear = this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls * this.quoteTechnologyAvayaAACC.adjuncts.numCallsPerAgentPerDay * workingDaysPerWeek * weeksPerYear

        const minutesInAnHour = 60
        var hoursOfRecordingsPerYear = ((callsPerYear * this.quoteTechnologyAvayaAACC.adjuncts.avgCallDuration) / minutesInAnHour)
        var storageRequiredMb = hoursOfRecordingsPerYear * contingencyMultiplier * storagePerHourMb * storagePeriodYears
        var storageRequiredGb = storageRequiredMb / 1000

        console.log('storage required for AACC Recordings = ' + storageRequiredGb + 'Gb')

        if (this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls <= 150) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_CONSOLIDATED, this.iQuoteConstants.dataCentres.fareham, true, 1, storageRequiredGb)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_THALES_KMS, this.iQuoteConstants.dataCentres.fareham, true, 1)
        } else {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_APPLICATION, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_DATABASE, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_WFO_RECORDER, this.iQuoteConstants.dataCentres.fareham, true, 1, storageRequiredGb)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_THALES_KMS, this.iQuoteConstants.dataCentres.fareham, true, 1)
        }
      }

      if (this.quoteTechnologyAvayaAACC.adjuncts.wfm) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFM, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFM, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.quoteTechnologyAvayaAACC.adjuncts.dpa) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.DPA, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.DPA, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.quoteTechnologyAvayaAACC.adjuncts.speechAnalysis) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SPEECH_ANALYSIS, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SPEECH_ANALYSIS, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.quoteTechnologyAvayaAACC.adjuncts.avayaWFOOptionId === this.iQuoteConstants.avayaWFOOptions.VERINT) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_VERINT, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_VERINT, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (this.quoteTechnologyAvayaAACC.adjuncts.avayaWFOOptionId === this.iQuoteConstants.avayaWFOOptions.AVAYA) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_AVAYA, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_AVAYA, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }
    },
    calcAACCAdjunctLicenses: function () {
      if (this.quoteTechnologyAvayaAACC.id === undefined) {
        return
      }

      this.licenses = this.$store.getters.licenses

      if (this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls > 0) {
        if (this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls <= 150) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_CALL_RECORDING_TIER1, this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls)
        } else if (this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls <= 650) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_CALL_RECORDING_TIER2, this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_CALL_RECORDING_TIER3, this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls)
        }
      }
    },
    calcAACCUserLicenses: function () {
      if (this.quoteTechnologyAvayaAACC.id === undefined) {
        return
      }

      this.licenses = this.$store.getters.licenses
      var userProfileLicenses = this.$store.getters.userProfileLicenses
      var requiredLicenseId = 0
      var tierId

      if (this.avayaAACCCalcs_usersToLicense > 5000) {
        tierId = 3
      } else if (this.avayaAACCCalcs_usersToLicense > 500) {
        tierId = 2
      } else {
        tierId = 1
      }

      for (let index = 0; index < this.quoteTechnologyAvayaAACC.userProfiles.length; index++) {
        const userProfile = this.quoteTechnologyAvayaAACC.userProfiles[index]
        userProfile.annualPrice = 0
        requiredLicenseId = 0

        if (userProfileLicenses.length > 0) {
          userProfileLicenses.forEach(userProfileLicense => {
            if (userProfileLicense.technologyUserProfileId === userProfile.technologyUserProfileId &&
                userProfileLicense.quoteLicenseTypeId === this.quote.quoteLicenseTypeId &&
                userProfileLicense.quoteServiceLevelTypeId === this.quote.quoteServiceLevelTypeId &&
                userProfileLicense.licenseTierId === tierId) {
              requiredLicenseId = userProfileLicense.licenseId
            }
          })
        }

        if (requiredLicenseId !== 0) {
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
          userProfile.annualTotalCost = userProfile.minCommitment * this.utilFunctions_GetLicenseCost(requiredLicenseId) * 12
          userProfile.annualPrice = userProfile.minCommitment * this.utilFunctions_GetLicensePrice(requiredLicenseId) * 12
          userProfile.rate = this.utilFunctions_GetLicensePrice(requiredLicenseId)
        }
      }

      this.setQuoteTechnologyAvayaAACC(this.quoteTechnologyAvayaAACC)
    }
  }
}
