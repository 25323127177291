<template>
  <progress
    id="controlRefreshBar"
    :value="barProgress"
    :title="progressTitle"
    max="100"
  > {{ barProgress }}% </progress>
</template>
<script>
export default {
  name: 'TimerProgressComponent',
  props: {
    pollingInterval: {
      type: Number,
      default: 1000
    },
    widgetId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      timerObj: {},
      tickCount: 1,
      barProgress: 100
    }
  },
  computed: {
    progressTitle () {
      if ((this.pollingInterval - Math.round(this.tickCount)) > 60) {
        return Math.round((this.pollingInterval - Math.round(this.tickCount)) / 60) + ' minutes until next data refresh'
      } else {
        return (this.pollingInterval - Math.round(this.tickCount)) + ' seconds until next data refresh'
      }
    }
  },
  mounted () {
    this.stopTimer()
    this.timerObj = window.setInterval(this.processTick, 1000)
    this.processTick()
  },
  unmounted () {
    this.stopTimer()
  },
  methods: {
    stopTimer () {
      window.clearInterval(this.timerObj)
      this.timerObj = null
    },
    processTick () {
      this.tickCount++
      this.barProgress = 100 - ((this.tickCount / this.pollingInterval) * 100)
      if (this.tickCount === this.pollingInterval) {
        this.$emit('reload-widget', this.widgetId)
        this.tickCount = 0
      }
    }
  }
}
</script>
<style scoped>
#controlRefreshBar {
  display: inline-block;
  width: 100%;
  border-radius: 0;
  height: 4px;
  cursor: pointer;
}
#controlRefreshBar[value]::-webkit-progress-bar {
  background-color: #fff;
}
#controlRefreshBar[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, rgb(218, 240, 255), rgb(218, 240, 255));
  border-radius: 0;
  background-size: 100%;
}
</style>
