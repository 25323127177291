<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-header"
          primary-title
        >
          Header Section
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details the functionality available through the header section of the portal."
              />
              <HelpSection
                instruction="1.) The toolbar at the top of the Portal contains a company select drop-down and a data refresh icon."
                image-source="header_1.png"
                indentation="0"
              />
              <div
                v-if="companies.length > 1"
                class="helpSectionContainer"
              >
                <h3> Company Multi-Select </h3>
                <HelpSection
                  instruction="a.) The select box is populated with a list of available companies to select."
                  indentation="1"
                />
                <HelpSection
                  instruction="b.) Selecting a new company from the drop-down box will change the data displayed to that of the newly selected company."
                  image-source="header_2.png"
                  indentation="1"
                />
                <HelpSection
                  instruction="c.) The company select box will only appear if the currently logged in user has access to more than a single company."
                  indentation="1"
                />
              </div>
              <h3> Data Refresh </h3>
              <HelpSection
                instruction="d.) The refresh button allows the data displayed to be reloaded."
                indentation="1"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpManageUsers',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    companies () {
      return this.$store.getters.companies
    }
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
