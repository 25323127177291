<template>
  <div
    v-if="$store.getters.openSubForm2"
    class="permissionNewRoleBackground"
  >
    <div class="permissionNewRoleHolder">
      <div class="roleTableholder">
        <table
          id="newPermissionRoleTable"
        >
          <thead>
            <tr>
              <th style="width:10%">
                <img
                  :src="newMemberIcon"
                  class="icon"
                >
              </th>
              <th style="width:80%">Add Role To Permission</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="m in members"
              id="memberList"
              :key="m.id"
            >
              <td>
                <input
                  :id="m.id"
                  type="checkbox"
                  title="Select User"
                  class="memberItem"
                  @change="checkForm"
                >
              </td>
              <td>{{ m.name }} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <input
        id="permissionCancelNewRoleButton"
        type="button"
        class="permissionCancelNewRoleButton"
        value="Cancel"
        title="Cancel the add operation"
        @click="cancelAdd()"
      >
      <input
        id="permissionAddNewRoleButton"
        :class="getButtonClass"
        type="button"
        value="Add Role"
        title="Adds the new role to the permission"
        @click="addMember()"
      >
      <input
        type="text"
        class="focusField"
        @focus="cycleFocus"
      >
    </div>
  </div>
</template>
<script>
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'PermissionNewRole',
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    permissionid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    currentRoles: {
      type: Array,
      default: () => []
    },
    currentUserRoles: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      members: [],
      formOK: false
    }
  },
  computed: {
    ...mapState([
      'openSubForm2'
    ]),
    getButtonClass () {
      if (this.formOK) {
        return 'permissionAddNewRoleButtonEnabled'
      } else {
        return 'permissionAddNewRoleButton'
      }
    },
    roles () {
      return this.$store.getters.roles
    },
    newMemberIcon () {
      return require('../../../assets/icons/People.png')
    }
  },
  watch: {
    currentRoles () {
      this.setComponent()
    }
  },
  created () {
    this.setComponent()
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.openSubForm2,
      (newValue) => {
        if (document.getElementById('memberList') && newValue) {
          document.getElementById('memberList').focus()
        }
      }
    )
  },
  methods: {
    ...mapActions([
      'setSubForm2',
      'setReloadRoles'
    ]),
    setComponent () {
      this.getMembers()
    },
    checkForm () {
      var selected = false
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          selected = true
        }
      }
      this.formOK = selected
    },
    checkExists (entity, id) {
      return entity.id === id
    },
    getMembers: async function () {
      this.members = this.roles.filter(o => !this.currentRoles.some(c => c.roleId === o.id))
    },
    closeForm () {
      this.setSubForm2(false)
    },
    addMember: async function () {
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          this.addPermissionRole(memberItems[i].id, this.permissionid, this.areaid).then(() => {
            this.setReloadRoles(true)
            this.closeForm()
          })
        }
      }
    },
    cancelAdd () {
      this.closeForm()
    },
    cycleFocus () {
      if (document.getElementById('memberList')) {
        document.getElementById('memberList').focus()
      }
    }
  }
}

</script>
<style scoped>
  .permissionNewRoleBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .permissionNewRoleHolder {
    display: inline-block;
    background-color: rgba(255,255,255,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 160px);
    top: calc(10%);
    max-height: calc(100% - 180px);
    width: 360px;
    z-index: 999;
  }
  .permissionCancelNewRoleButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .permissionAddNewRoleButton {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    opacity: 0.5;
  }
  .permissionAddNewRoleButtonEnabled {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .roleTableholder {
    overflow: auto;
    max-height: 300px;
  }
  .focusField {
    position: absolute;
    left:0;
    bottom:0;
    width:0;
    opacity: 0;
    background-color: rgba(240,240,240,1);
  }
  .memberItem {
    margin: 7px;
  }
  .icon {
    height: 25px;
    width: 25px;
  }
</style>
