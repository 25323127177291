import { mapActions } from 'vuex'
import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsCommonMixin } from '../iQuoteCalculationsCommonMixin'

export const iQuoteCalculationsReviewScreenMixin = {
  data () {
    return {
    }
  },
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsCommonMixin
  ],
  computed: {
    /*
     * BUSINESS VALUES
     */

    iQuoteCalculationsReviewScreenMixin_tcv () {
      if (this.iQuoteStore_GetQuote.chargeUpfront) {
        return this.totalUpfrontCharges + (this.totalRecurringAnnualPrice * (this.iQuoteStore_GetQuote.termLengthMonths / 12)) - this.calculationsCommonMixin_totalYr1Discount
      } else {
        return (this.totalRecurringAnnualPrice * (this.iQuoteStore_GetQuote.termLengthMonths / 12)) - this.calculationsCommonMixin_totalYr1Discount
      }
    },
    iQuoteCalculationsReviewScreenMixin_tcm () {
      return this.iQuoteCalculationsReviewScreenMixin_tcv - (((this.totalLicensesAnnualCost + this.totalInternalAnnualUnitCosts + this.managedServiceCost + this.annualAWSPlatformCost + this.annualIconGatewayCost + this.callmediaCXNowSipAnnualCost + this.sipAnnualUnitCosts + this.annualTeamsUnitCostsCost + this.annualGenesysUnitCostsCost) * (this.iQuoteStore_GetQuote.termLengthMonths / 12)) + this.totalUpfrontInfrastructureCost + this.totalUpfrontSipSetupCost + this.totalUpfrontPSCost + this.calcGenesysConnector_genesysNonPSUpfrontCosts + this.calcRingCentralMixin_ringCentralNonPSUpfrontCosts)
    },
    iQuoteCalculationsReviewScreenMixin_yr1Revenue () {
      var value = this.totalRecurringAnnualPrice + this.totalUpfrontCharges - this.calculationsCommonMixin_totalYr1Discount
      return value
    },
    iQuoteCalculationsReviewScreenMixin_yr1Margin () {
      var margin = this.iQuoteCalculationsReviewScreenMixin_yr1Revenue - (this.sipAnnualUnitCosts + this.totalLicensesAnnualCost + this.totalInternalAnnualUnitCosts + this.managedServiceCost + this.annualAWSPlatformCost + this.annualIconGatewayCost + this.callmediaCXNowSipAnnualCost + this.totalUpfrontSipSetupCost + this.totalUpfrontPSCost + this.annualTeamsUnitCostsCost + this.annualGenesysUnitCostsCost + this.upfrontIconInfrastructureCost + this.calcGenesysConnector_genesysNonPSUpfrontCosts + this.calcRingCentralMixin_ringCentralNonPSUpfrontCosts)
      return Number(margin.toFixed(2))
    },
    iQuoteCalculationsReviewScreenMixin_iconCommunicateOrExpansionsTotalAnnualCost () {
      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined && this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined && this.iQuoteStore_GetQuoteExpansions.id === undefined && this.iQuoteStore_GetGammaSip.id === undefined) {
        return 0
      }

      var totalAnnualCost = 0

      totalAnnualCost = this.managedServiceCost + this.totalInternalAnnualUnitCosts + this.iconCommunicateTotalAnnualLicenseCost

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.code.toLowerCase().includes('mbglicences') || license.code.toLowerCase().includes('src ports')) {
          totalAnnualCost += license.annualCost
        }
      })
      return totalAnnualCost
    },
    iQuoteCalculationsReviewScreenMixin_callmediaCXNowTotalAnnualCost () {
      if (this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow.id === undefined) {
        return 0
      }

      var totalAnnualCost = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations && license.serviceId === this.iQuoteConstants.services.callmediaCXNow) {
          totalAnnualCost += license.annualCost
        }
      })

      totalAnnualCost += this.callmediaCXNowAnnualUnitCosts

      if (!this.iQuoteStore_GetQuote.chargeUpfront) {
        var upfrontTotal = 0

        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (!unitCost.recurringCost && unitCost.includeInCalculations) {
            if (unitCost.serviceId === this.iQuoteConstants.services.callmediaCXNow) {
              upfrontTotal += unitCost.cost * unitCost.quantity
            }
          }
        })

        totalAnnualCost += upfrontTotal / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
      }

      return totalAnnualCost
    },
    iQuoteCalculationsReviewScreenMixin_teamsConnectorTotalAnnualCost () {
      if (this.iQuoteStore_GetTeamsConnector.id === undefined) {
        return 0
      }

      var totalAnnualCost = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations && license.serviceId === this.iQuoteConstants.services.teamsConnector) {
          totalAnnualCost += license.annualCost
        }
      })

      totalAnnualCost += this.teamsConnectorAnnualUnitCosts

      if (!this.iQuoteStore_GetQuote.chargeUpfront) {
        var upfrontTotal = 0

        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (!unitCost.recurringCost && unitCost.includeInCalculations) {
            if (unitCost.serviceId === this.iQuoteConstants.services.teamsConnector) {
              upfrontTotal += unitCost.cost * unitCost.quantity
            }
          }
        })

        totalAnnualCost += upfrontTotal / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
      }

      return totalAnnualCost
    },
    iQuoteCalculationsReviewScreenMixin_genesysTotalAnnualCost () {
      if (this.iQuoteStore_GetQuoteGenesys.id === undefined) {
        return 0
      }

      var totalAnnualCost = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations &&
           (license.serviceId === this.iQuoteConstants.services.genesys ||
            license.serviceId === this.iQuoteConstants.services.teamsConnector)) {
          totalAnnualCost += license.annualCost
        }
      })

      totalAnnualCost += this.genesysAnnualUnitCosts + this.managedServiceCost

      if (!this.iQuoteStore_GetQuote.chargeUpfront) {
        var upfrontTotal = 0

        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (!unitCost.recurringCost && unitCost.includeInCalculations) {
            if (unitCost.serviceId === this.iQuoteConstants.services.genesys ||
              unitCost.serviceId === this.iQuoteConstants.services.teamsConnector) {
              upfrontTotal += unitCost.cost * unitCost.quantity
            }
          }
        })

        totalAnnualCost += upfrontTotal / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
      }

      return totalAnnualCost
    },
    iQuoteCalculationsReviewScreenMixin_ringCentralTotalAnnualCost () {
      if (this.iQuoteStore_GetQuoteRingCentral.ucTechId === undefined) {
        return 0
      }

      var totalAnnualCost = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations &&
            license.serviceId === this.iQuoteConstants.services.ringCentral) {
          totalAnnualCost += license.annualCost
        }
      })

      totalAnnualCost += this.ringCentralAnnualUnitCosts

      // If you have chosen to NOT charge upfront, then the upfront costs need to be spread across
      // the term length.  So we total up the upfront costs, and divide by the number of contract months
      if (!this.iQuoteStore_GetQuote.chargeUpfront) {
        var upfrontTotal = 0

        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (!unitCost.recurringCost && unitCost.includeInCalculations) {
            if (unitCost.serviceId === this.iQuoteConstants.services.ringCentral) {
              upfrontTotal += unitCost.cost * unitCost.quantity
            }
          }
        })

        totalAnnualCost += upfrontTotal / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
      }

      return totalAnnualCost
    },
    iQuoteCalculationsReviewScreenMixin_phonelinePlusTotalAnnualCost () {
      if (this.iQuoteStore_GetPhoneLinePlus.id === undefined) {
        return 0
      }

      var totalAnnualCost = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations && license.serviceId === this.iQuoteConstants.services.sip) {
          totalAnnualCost += license.annualCost
        }
      })

      totalAnnualCost += this.phonelinePlusAnnualUnitCosts

      if (!this.iQuoteStore_GetQuote.chargeUpfront) {
        var upfrontTotal = 0

        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (!unitCost.recurringCost && unitCost.includeInCalculations) {
            if (unitCost.serviceId === this.iQuoteConstants.services.sip) {
              upfrontTotal += unitCost.cost * unitCost.quantity
            }
          }
        })

        totalAnnualCost += upfrontTotal / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
      }

      return totalAnnualCost
    },
    iQuoteCalculationsReviewScreenMixin_iconGatewayTotalAnnualCost () {
      if (this.iQuoteStore_GetQuoteTechnologyIconGateway.id === undefined) {
        return 0
      }

      var totalAnnualCost = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations && license.serviceId === this.iQuoteConstants.services.gateway) {
          totalAnnualCost += license.annualCost
        }
      })

      totalAnnualCost += this.iQuoteCalculationsReviewScreenMixin_iconGatewayAnnualUnitCosts

      return totalAnnualCost
    },
    iQuoteCalculationsReviewScreenMixin_callmediaCXNowUpfrontCosts () {
      if (!this.iQuoteStore_GetQuote.chargeUpfront) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.callmediaCXNow) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })
      return total
    },
    iQuoteCalculationsReviewScreenMixin_iconGatewayUpfrontCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.gateway) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })
      return total
    },
    iQuoteCalculationsReviewScreenMixin_teamsConnectorUpfrontCosts () {
      if (this.iQuoteStore_GetTeamsConnector.id === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.teamsConnector) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })
      return total
    },
    iQuoteCalculationsReviewScreenMixin_genesysUpfrontCosts () {
      if (this.iQuoteStore_GetQuoteGenesys.id === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.genesys ||
              unitCost.serviceId === this.iQuoteConstants.services.teamsConnector) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })
      return total
    },
    iQuoteCalculationsReviewScreenMixin_ringCentralUpfrontCosts () {
      if (this.iQuoteStore_GetQuoteRingCentral.ucTechId === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.ringCentral) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })
      return total
    },
    iQuoteCalculationsReviewScreenMixin_phonelinePlusUpfrontCosts () {
      if (this.iQuoteStore_GetPhoneLinePlus.id === undefined) {
        return 0
      }
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.sip) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })
      return total
    },
    iQuoteCalculationsReviewScreenMixin_iconGatewayAnnualUnitCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.gateway) {
            total += unitCost.yearlyCost
          }
        }
      })
      return total
    },
    iQuoteCalculationsReviewScreenMixin_sipMigrationUpfrontCost () {
      return this.totalUpfrontSipSetupCost + this.upfrontIconInfrastructureCost
    },
    iQuoteCalculationsReviewScreenMixin_gammaSipUpfrontCost () {
      return this.totalUpfrontSipSetupCost
    },
    iQuoteCalculationsReviewScreenMixin_sipMigrationAnnualCost () {
      return this.sipTotalAnnualCost + this.totalAnnualExternalHelpdeskCost + this.totalAnnualInternalHelpdeskCost + this.annualIconInfrastructureCost
    },
    iQuoteCalculationsReviewScreenMixin_gammaSipAnnualCost () {
      return this.sipTotalAnnualCost
    },
    iQuoteCalculationsReviewScreenMixin_annualDatatrackLicenseCost () {
      var total = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.description.toLowerCase().includes('datatrack') || license.description.toLowerCase().includes('data track')) {
          total += license.annualCost
        }
      })
      return total
    },
    iQuoteCalculationsReviewScreenMixin_annualRedboxLicenseCost () {
      var total = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.description.toLowerCase().includes('redbox')) {
          total += license.annualCost
        }
      })
      return total
    },
    iQuoteCalculationsReviewScreenMixin_ShowPerpetualLicenseType () {
      const quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
      const quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
      const quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite
      const quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC

      return (quoteTechnologyAvayaAura.id !== undefined || quoteTechnologyMitelUC.id !== undefined) &&
        (!(quoteTechnologyAvayaElite.id !== undefined && quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls > 0)) &&
        (!(quoteTechnologyAvayaAACC.id !== undefined && quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls > 0))
    },
    iQuoteCalculationsReviewScreenMixin_ShowSurrenderLicenseType () {
      const quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
      const quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia

      return quoteTechnologyAvayaAura.id !== undefined && quoteTechnologyCallmedia.id === undefined
    }
  },
  methods: {
    ...mapActions([
    ])
  },
  mounted () {
  }
}
