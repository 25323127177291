<template>
  <div
    v-if="options"
    class="dropdown">
    <!-- Dropdown Input -->
    <form>
      <input
        :name="name"
        v-model="searchFilter"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="[dropdownClass, dropdownInputClass]"
        autocomplete="off"
        @focus="showOptions()"
        @blur="attachBlurEvent ? exit() : null"
        @keyup="keyMonitor" >
    </form>
    <!-- Dropdown Menu -->
    <div
      v-show="optionsShown"
      ref="dropdownmenu"
      class="dropdown-content">
      <div
        v-for="(option, index) in filteredOptions"
        :key="index"
        class="dropdown-item"
        @mousedown="selectOption(option)"
        @blur="attachBlurEvent ? blurOption(option) : null"
      >
        {{ option.name || option.id ||'-' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PortalDropdown',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    options: {
      type: Array,
      required: true,
      default: () => ([]),
      note: 'Options of dropdown. An array of options with id and name'
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing'
    },
    parentClass: {
      type: String,
      required: false,
      default: '',
      note: 'the class of the parent element that the dropdown will adjust its height to'
    },
    dropdownInputClass: {
      type: String,
      required: false,
      default: '',
      note: 'The custom class to be applied to the input box (used for searching)'
    },
    defaultSelectedOption: {
      type: Object,
      requred: false,
      default: () => {},
      note: 'The default option to be selected initially'
    },
    attachBlurEvent: {
      type: Boolean,
      requred: false,
      default: true,
      note: 'If the blur event is attached to the drop down list'
    },
    clearBox: {
      type: Boolean,
      requred: false,
      default: true,
      note: 'Clears the currently selected entry'
    }
  },
  data () {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: '',
      dropdownClass: 'dropdown-input'
    }
  },
  computed: {
    filteredOptions () {
      const filtered = []
      for (const option of this.options) {
        if (!this.searchFilter || (this.searchFilter && this.searchFilter.length < 1)) {
          if (filtered.length < this.maxItem) {
            filtered.push(option)
          }
        } else if (this.searchFilter && option.name.toLowerCase().includes(this.searchFilter.toLowerCase())) {
          if (filtered.length < this.maxItem) {
            filtered.push(option)
          }
        }
      }
      return filtered
    }
  },
  watch: {
    searchFilter () {
      if (this.filteredOptions.length === 0) {
        this.selected = {}
      } else if (!this.selected) {
        this.selected = this.filteredOptions[0]
      }
      this.$emit('filter', this.searchFilter)
    },
    options () {
      if (this.options.length > 0) {
        this.selected = this.options[0]
        this.searchFilter = this.selected.name
      }
    },
    clearBox () {
      this.selected = {}
      this.searchFilter = ''
    },
    optionsShown () {
      if (this.optionsShown) {
        // Added this because otherwise this fires before the DOM has updated - before the display-none has been removed.
        this.$nextTick(() => {
          if (this.parentClass !== '') {
            var parent = this.findParentElementByClass(this.$refs.dropdownmenu, this.parentClass)

            if (parent !== undefined) {
              // Get the offset top of the dropdown list our parent container
              var targetDropdownOffsetTop = this.$refs.dropdownmenu.offsetTop
              // If we have a parent container, calculate the height of the dropdown based on the containers height
              var calculatedHeight = parent.clientHeight - targetDropdownOffsetTop - ((parent.clientHeight / 100) * 15)
              this.$refs.dropdownmenu.style.maxHeight = `${calculatedHeight}px`
            } else {
              console.warn(`Couldn't find element with class ${this.parentClass}`)
            }
          }
        })
      }
    },
    defaultSelectedOption () {
      this.selected = this.defaultSelectedOption
      this.searchFilter = this.selected.name
    }
  },
  created () {
    this.$emit('selected', this.selected)
    if (this.defaultSelectedOption) {
      this.selected = this.defaultSelectedOption
      this.searchFilter = this.selected.name
    }
  },
  methods: {
    selectOption (option) {
      this.selected = option
      this.optionsShown = false
      this.searchFilter = this.selected.name
      this.$emit('selected', this.selected)
    },
    blurOption (option) {
      this.$emit('blur', option.id)
    },
    showOptions () {
      if (!this.disabled) {
        this.searchFilter = ''
        this.optionsShown = true
        this.dropdownClass = 'dropdown-input-shown'
        this.$emit('optionsDisplayed')
      }
    },
    exit () {
      if (!this.selected) {
        this.selected = {}
        this.searchFilter = ''
      } else {
        this.searchFilter = this.selected.name
      }
      this.$emit('selected', this.selected)
      this.dropdownClass = 'dropdown-input'
      this.optionsShown = false
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === 'Enter' && this.filteredOptions[0]) { this.selectOption(this.filteredOptions[0]) }
    },
    findParentElementByClass (element, className) {
      // Does our DOM element have the class we are looking for?
      if (element && element.parentElement && element.parentElement.classList.contains(className)) {
        // It does, so return it!
        return element
      } else if (element && element.parentElement !== undefined) {
        // It doesn't, so now we try again using the target elements parent
        return this.findParentElementByClass(element.parentElement, className)
      }
    }
  }
}
</script>

<style scoped>
  .dropdown {
    position: relative;
    display: inline-block;
    margin: auto;
  }
  .dropdown .dropdown-input-shown {
    background: #fff;
    cursor: pointer;
    border: 1px solid #e7ecf5;
    border-radius: 5px 8px 0 0;
    color: #333;
    display: block;
    font-size: .8em;
    padding: 6px;
    width: 100%;
  }
  .dropdown .dropdown-input {
    background: #fff;
    cursor: pointer;
    border: 1px solid #e7ecf5;
    border-radius: 5px;
    color: #333;
    display: block;
    font-size: .8em;
    padding: 6px;
    width: 100%;
  }
  .dropdown .dropdown-input:hover {
    background: #f8f8fa;
  }
  .dropdown-content {
    position: absolute;
    background-color: #fff;
    min-width: 100%;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05);
    overflow-y: auto;
    z-index: 1;
    max-height:200px;
  }
  .dropdown-content .dropdown-item {
    color: black;
    font-size: .8em;
    padding: 4px 8px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  .dropdown-content .dropdown-item:hover {
    background-color: #e7ecf5;
  }
  .dropdown:hover .dropdowncontent {
    display: block;
  }
</style>
