<template>
  <input
    ref="input"
    :class="{invalidInput:!isValid}"
    :value="dateToYYYYMMDD(value)"
    type="date"
    @input="updateValue($event.target)"
    @focus="selectAll"
  >
</template>

<script>
export default {
  name: 'PortalDateTime',
  props: {
    value: {
      type: Date,
      default: new Date()
    }
  },
  data () {
    return {
      isValid: true
    }
  },
  methods: {
    dateToYYYYMMDD (date) {
      // may have timezone caveats https://stackoverflow.com/a/29774197/1850609
      return date && date.toISOString().split('T')[0]
    },
    updateValue: function (target) {
      if (target.valueAsDate !== null && target.valueAsDate.getYear() + 1900 >= 2019 && target.valueAsDate.getYear() + 1900 < 2100) {
        this.isValid = true
        this.$emit('input', target.valueAsDate)
        this.$emit('change', target.valueAsDate)
      } else {
        this.isValid = false
      }
    },
    selectAll: function (event) {
      // Workaround for Safari bug
      // https://stackoverflow.com/q/1269722/1850609
      setTimeout(function () {
        event.target.select()
      }, 0)
    }
  }
}
</script>

<style scoped>
.invalidInput {
    color:red;
}
</style>
