<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-createusers"
          primary-title
        >
          Creating Users
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details how to create a user, assign sites and assign roles."
              />
              <HelpSection
                instruction="1.) Navigate to the 'User Management' page via the left hand navigation menu."
                indentation="0"
              />
              <HelpSection
                instruction="2.) From the 'User Management' page click on the 'Add New User' button in the top right hand corner to see the 'Create New User' screen."
                image-source="create_users_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="3.) On the 'Create New User' screen, all the fields under the 'Personal' header must be populated. Note that the completion of a section is indicated by a tick in the section header."
                image-source="create_users_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="4.) Each section on the ‘Create New User’ screen can be expanded / collapsed by clicking the header. Expand the ‘Sites’ section to assign the sites available to the new user."
                image-source="create_users_4.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) The search bar at the top of the section can be used to find specific sites."
                image-source="create_users_4a.png"
                indentation="1"
              />
              <HelpSection
                instruction="b.) There is the ability to assign sites individually using the toggle switches against each site name."
                image-source="create_users_4b.png"
                indentation="1"
              />
              <HelpSection
                instruction="c.) Sites can be assigned individually using the toggle switches against each site."
                image-source="create_users_4c.png"
                indentation="1"
              />
              <HelpSection
                instruction="5.) Expand the ‘Roles’ section to assign roles to the new user."
                image-source="create_users_5.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) The search bar at the top of the section can be used to find specific roles."
                image-source="create_users_5a.png"
                indentation="1"
              />
              <HelpSection
                instruction="b.) Roles can be assigned individually using the toggle switches against each site."
                image-source="create_users_5b.png"
                indentation="1"
              />
              <HelpSection
                instruction="c.) All available roles can also be assigned en masse to the new user using the ‘Assign all roles’ toggle switch in the section header."
                image-source="create_users_5c.png"
                indentation="1"
              />
              <HelpSection
                instruction="d.) Once one or more roles have been selected, a table of ‘Effective Permissions’ will be displayed – this will indicate the permissions and their enabled state against the new user. I.e. whether it will be enabled or disabled for the user as a result of the roles applied."
                image-source="create_users_5d.png"
                indentation="1"
              />
              <HelpSection
                instruction="i.) Permissions will only be enabled if they are explicitly enabled against a role."
                indentation="2"
              />
              <HelpSection
                instruction="ii.) Permissions will be disabled if in one or more roles the permission is disabled or by default."
                indentation="2"
              />
              <HelpSection
                instruction="6.) Once all three sections have been completed, the ‘Submit’ button in the bottom right hand corner of the screen is enabled."
                image-source="create_users_6.png"
                indentation="0"
              />
              <HelpSection
                instruction="7.) Assuming all of the validation passes, a notification will appear indicating the successful creation of the user and you will be redirected to the ‘User Management’ page. Otherwise, a separate notification will be shown indicating any problems with the new user information."
                image-source="create_users_7.png"
                indentation="0"
              />
              <HelpSection
                instruction="8.) On successful creation a confirmation e-mail will be sent to the new user’s e-mail address. This e-mail contains a link that will allow the new user to set their password."
                image-source="create_users_8.png"
              />
              <HelpSection
                instruction="9.) The link will direct the new user to the screen below, where they can set a password."
                image-source="create_users_9.png"
                indentation="0"
              />
              <HelpSection
                instruction="10.) Once a valid password has been entered and confirmed via the ‘Change Password’ button in the bottom right hand corner, the password will be set and the new user will be logged in automatically."
                indentation="0"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpCreateUsers',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
