<template>
  <div>
    <div
      v-if="!viewCompanyPermission"
      class="noResults"
    >
      Sorry, you do not have permission to manage UC Analytics companies
    </div>
    <div
      v-else
      id="stormCompanyManagementContainer"
      class="stormCompanyManagementSectionData"
    >
      <Spinner v-if="isLoading" />
      <StormNewCompany
        @reload-companies="setStormCompanies()"
      />
      <TextButton
        v-if="createCompanyPermission"
        button-id="btnNewStorm"
        class="stormAddButton"
        title="Add a new UC Analytics company entry"
        text="Add New UC Analytics Company"
        @click="openNewStorm"
      />
      <div
        v-show="!isLoading"
        class="mdContainer appSectionBody">
        <div
          :class="{mdHalfScreen : !!selectedCompany}"
          class="mdStormCompanyManagement"
        >
          <table id="companiesTable">
            <thead />
            <tbody>
              <tr
                v-for="u in currentFilter"
                :key="u.id"
                :tabindex="20"
                :class="getRowColour(u.id)"
                @click="setCurrentCompany(u)"
              >
                <td>{{ u.companyName }}</td>
                <td>{{ u.baseUrl }}</td>
                <td>{{ u.apiCode }}</td>
                <td>{{ u.subscriptionTierName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="!!selectedCompany"
          class="mdSelection"
        >
          <SectionCollapsedHeader
            v-if="editCompanyPermission"
            id="sectionRoleDetails"
            :title="stormCompanyDetailsTitle"
            type="subHeader"
            icon-file="Edit"
            controls="stormDetails"
          />
          <StormDetails
            id="stormDetails"
            :company="selectedCompany"
            @reload-companies="setStormCompanies()"
          />
          <SectionCollapsedHeader
            v-if="deleteCompanyPermission"
            id="sectionStormDeleteCompany"
            :title="stormCompanyDeleteTitle"
            type="subHeader"
            icon-file="Cross"
            controls="stormDeleteCompany"
          />
          <StormDeleteCompany
            id="stormDeleteCompany"
            :storm-company-id="selectedCompany.companyId"
            @reload-companies="setStormCompanies()"
          />
        </div>
      </div>
    </div>
    <input
      id="backButton"
      type="button"
      class="backButton"
      title="Back to support page"
      @click="closeStormManagementForm()"
    >
    <br><br>
  </div>
</template>

<script>
import StormDetails from './stormDetails'
import StormDeleteCompany from './stormDeleteCompany'
import StormNewCompany from './stormNewCompany'
import { stormMixin } from '../../../mixins/stormMixin'
import { adminMixin } from '../../../mixins/adminMixin'
import TextButton from '../../common/textButton'
import tableHandler from '../../../mixins/tableHandler'
import { DataEventBus } from '../../../events/dataEvents'
import { mapActions } from 'vuex'
import Spinner from '../../common/spinner'
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'

export default {
  metaInfo: {
    title: 'Support - Manage UC Analytics Companies'
  },
  name: 'StormComponent',
  components: {
    Spinner,
    SectionCollapsedHeader,
    StormDetails,
    StormDeleteCompany,
    StormNewCompany,
    TextButton
  },
  mixins: [
    stormMixin,
    adminMixin,
    tableHandler
  ],
  data () {
    return {
      isLoading: false,
      dataReturned: [],
      stormCompanies: [],
      selectedCompany: null
    }
  },
  computed: {
    stormCompanyDetailsTitle () {
      return 'Edit ' + this.selectedCompany.companyName
    },
    stormCompanyDeleteTitle () {
      return 'Delete ' + this.selectedCompany.companyName
    },
    subscriptionTiers () {
      return this.$store.getters.stormSubscriptionTiers
    },
    viewCompanyPermission () {
      return this.$store.getters.viewCompanyPermission
    },
    editCompanyPermission () {
      return this.$store.getters.editCompanyPermission
    },
    deleteCompanyPermission () {
      return this.$store.getters.deleteCompanyPermission
    },
    createCompanyPermission () {
      return this.$store.getters.createCompanyPermission
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  mounted () {
    this.setStormCompanies()
    DataEventBus.$on('refresh-data', () => {
      this.setStormCompanies()
    })
  },
  methods: {
    ...mapActions([
      'setMainForm'
    ]),
    closeStormManagementForm () {
      this.formOK = false
      this.$emit('setTileMode')
    },
    setStormCompanies: async function () {
      this.setMainForm(false)
      this.selectedCompany = null
      if (this.viewCompanyPermission) {
        this.isLoading = true
        this.stormCompanies = []
        var rtn = await this.getStormCompanies()
        var companies = this.$store.getters.companies
        // Remove any companies that we don't have permissions to see...
        rtn = rtn.filter(co1 => !companies.some(co2 => co1.id === co2.id))
        rtn.forEach(c => {
          var comp = companies.find(co => co.id === c.companyId)
          if (comp !== undefined) {
            var st = this.subscriptionTiers.find(sub => sub.id === c.subscriptionTierId)
            var sc = {
              companyId: c.companyId,
              companyName: comp.name,
              baseUrl: c.baseUrl,
              apiCode: c.apiCode,
              subscriptionTierId: c.subscriptionTierId,
              subscriptionTierName: st.name
            }
            this.stormCompanies.push(sc)
          }
        })

        this.initTable()
        this.isLoading = false
      }
    },
    initTable: function () {
      this.loading = true
      this.$nextTick(() => {
        this.initialiseTable(
          'companiesTable',
          this.stormCompanies,
          ['companyName', 'baseUrl', 'apiCode', 'subscriptionTierName'],
          ['Company', 'Base URL', 'API Code', 'Tier'],
          ['', '', '', ''],
          null,
          ['25%', '30%', '30%', '15%'],
          20,
          true
        )
      })
      this.loading = false
    },
    getRowColour (id) {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      if (this.selectedCompany && this.selectedCompany.companyId === id) {
        rtn = 'itemSelected'
      }
      return rtn
    },
    setCurrentCompany (cmp) {
      if (this.selectedCompany && this.selectedCompany.companyId === cmp.companyId) {
        this.selectedCompany = null
      } else {
        this.selectedCompany = cmp
      }
    },
    openNewStorm () {
      this.setMainForm(true)
    }
  }
}
</script>
<style scoped>
.stormCompanyManagementSectionData {
  margin: 20px;
}
.backButton {
  margin: 20px;
}
.stormAddButton {
  cursor: pointer;
  margin: 0 0 10px calc(100% - 268px);
}
.shContainer {
  width: 360px;
}
</style>
