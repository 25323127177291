<template>
  <v-toolbar
    v-if="isLoggedOn"
    id="core-toolbar"
    :style="toolbarBackground"
    app
    flat
    prominent
  >
    <div class="v-toolbar-title">
      <v-toolbar-title
        class="font-weight-light"
        style="color: #fff!important;font-weight: bold!important;padding-right: 10px;"
      >
        {{ title }}
      </v-toolbar-title>
    </div>
    <v-spacer />
  </v-toolbar>
</template>

<script>
import { mapActions } from 'vuex'
import { DataEventBus } from '../../events/dataEvents'
import PortalDropdown from '../common/portalDropdown'

export default {
  components: {
    PortalDropdown
  },
  data: () => ({
    title: '',
    companyList: [],
    selectedCompany: ''
  }),
  computed: {
    toolbarBackground () {
      return `background-image: ${this.$store.getters.topbarBackground}`
    },
    isLoggedOn () {
      return this.$store.getters.loggedOn
    },
    customers () {
      return this.$store.getters.customers
    },
    currentCompany () {
      return this.$store.getters.currentCompany
    }
  },
  mounted () {
    // this.setCompanyList()
    DataEventBus.$on('title-changed', (title) => {
      this.title = title
    })
    DataEventBus.$on('data-loaded', (loading) => {
      if (!loading) {
        this.$store.commit('showMessage', { content: 'Data load completed', color: 'green', timeout: 3000 })
        DataEventBus.$emit('refresh-data')
      }
    })
  },
  methods: {
    ...mapActions([
      'setCurrentCustomer',
      'setCurrentCompany'
    ]),
    refreshData () {
      DataEventBus.$emit('load-data', false)
      this.$store.commit('showMessage', { content: 'Data loading in background...', color: 'blue', timeout: 3000 })
    },
    showOptions () {
      DataEventBus.$emit('company-dropdown-displayed')
    }
  }
}
</script>

<style>
  #core-toolbar a {
    text-decoration: none;
  }
  .v-select__selections input {
    border:none!important;
  }
  .v-toolbar__content {
    padding-right: 0!important;
    padding-left: 16px!important;
  }
  .dropdown .toolbarSearchInput {
    min-height: 40px !important;
    font-size: 15px !important;
    text-align: center;
    width: 340px;
  }
</style>
<style scoped>
  .companySelect {
    min-height: 40px;
    margin-top: 12px;
    min-width: 320px;
  }

  .v-icon {
    font-size: 0.8em!important;
  }
</style>
