<template>
  <table
    id="marginsTable"
    class="iquoteDesignpackTable noTableShading">
    <!-- MARGINS -->
    <tr>
      <th rowspan="2">Description</th>
      <th rowspan="2">SF COE</th>
      <th colspan="4">Applicable Margins</th>
    </tr>
    <tr>
      <th colspan="2">Upfront</th>
      <th colspan="2">Annual</th>
    </tr>
    <tr
      v-if="designPackMarginsComponent.margins.length === 0"
    >
      <td
        colspan="6"
        style="text-align:center">No Global Margins are applicable to this design pack</td>
    </tr>
    <tbody
      v-for="marginEntry in designPackMarginsComponent.margins"
      :key="'margin' + marginEntry.id">
      <tr>
        <td
          rowspan="2"
          style="width:300px"
          class="tableRowLabel"><span>{{ marginEntry.description }}</span></td>
        <td
          rowspan="2"
          style="width:300px"
          class="tableRowLabel">{{ iQuoteFunctions_getSF_COE_NameFromId(marginEntry.salesforceCoeCodeId) }}</td>
        <td>Ext. Costs</td>
        <td v-if="marginEntry.upfrontExternalMargin === null">-</td>
        <td
          v-if="marginEntry.upfrontExternalMargin !== null"
          :class="{editableCell: !isReadOnly && !marginEntry.isReadOnly, precalculatedCell: marginEntry.isReadOnly && !isReadOnly}">
          <input
            v-model.number="marginEntry.upfrontExternalMargin"
            :disabled="isReadOnly || marginEntry.isReadOnly"
            :class="{InputError: !marginIsValid(marginEntry.upfrontExternalMargin, marginEntry.upfrontExternalIquoteUserMinMargin) && !marginEntry.isReadOnly}"
            :title="marginTooltip(marginEntry)"
            class="percentageTextInput"
            type="number"
            step="any"
            min="0"
            @change="onMarginValueChanged($event)">
          %
          <TooltipIcon
            v-if="pricesHaveBeenOverridden(marginEntry, iQuoteConstants.costTypes.external, iQuoteConstants.recurrence.oneOff)"
            :text="getMarginTooltipText(marginEntry, iQuoteConstants.costTypes.external, iQuoteConstants.recurrence.oneOff)"
            style="margin-left: 5px"
            width="500"
          />
        </td>
        <td>Ext. Costs</td>
        <td v-if="marginEntry.recurringExternalMargin === null">-</td>
        <td
          v-if="marginEntry.recurringExternalMargin !== null"
          :class="{editableCell: !isReadOnly && !marginEntry.isReadOnly, precalculatedCell: marginEntry.isReadOnly && !isReadOnly}">
          <input
            v-model.number="marginEntry.recurringExternalMargin"
            :disabled="isReadOnly || marginEntry.isReadOnly"
            :class="{InputError: !marginIsValid(marginEntry.recurringExternalMargin, marginEntry.recurringExternalIquoteUserMinMargin) && !marginEntry.isReadOnly}"
            :title="marginTooltip(marginEntry)"
            class="percentageTextInput"
            type="number"
            step="any"
            min="0"
            @change="onMarginValueChanged($event)">
          %
          <TooltipIcon
            v-if="pricesHaveBeenOverridden(marginEntry, iQuoteConstants.costTypes.external, iQuoteConstants.recurrence.recurring)"
            :text="getMarginTooltipText(marginEntry, iQuoteConstants.costTypes.external, iQuoteConstants.recurrence.recurring)"
            style="margin-left: 5px"
            width="500"
          />
        </td>
      </tr>
      <tr>
        <td>Int. Costs</td>
        <td v-if="marginEntry.upfrontInternalMargin === null">-</td>
        <td
          v-if="marginEntry.upfrontInternalMargin !== null"
          :class="{editableCell: !isReadOnly && !marginEntry.isReadOnly, precalculatedCell: marginEntry.isReadOnly && !isReadOnly}">
          <input
            v-model.number="marginEntry.upfrontInternalMargin"
            :disabled="isReadOnly || marginEntry.isReadOnly"
            :class="{InputError: !marginIsValid(marginEntry.upfrontInternalMargin, marginEntry.upfrontInternalIquoteUserMinMargin) && !marginEntry.isReadOnly}"
            :title="marginTooltip(marginEntry)"
            class="percentageTextInput"
            type="number"
            step="any"
            min="0"
            @change="onMarginValueChanged($event)">
          %
          <TooltipIcon
            v-if="pricesHaveBeenOverridden(marginEntry, iQuoteConstants.costTypes.internal, iQuoteConstants.recurrence.oneOff)"
            :text="getMarginTooltipText(marginEntry, iQuoteConstants.costTypes.internal, iQuoteConstants.recurrence.oneOff)"
            style="margin-left: 5px"
            width="500"
          />
        </td>
        <td>Int. Costs</td>
        <td v-if="marginEntry.recurringInternalMargin === null">-</td>
        <td
          v-if="marginEntry.recurringInternalMargin !== null"
          :class="{editableCell: !isReadOnly && !marginEntry.isReadOnly, precalculatedCell: marginEntry.isReadOnly && !isReadOnly}">
          <input
            v-model.number="marginEntry.recurringInternalMargin"
            :disabled="isReadOnly || marginEntry.isReadOnly"
            :class="{InputError: !marginIsValid(marginEntry.recurringInternalMargin, marginEntry.recurringInternalIquoteUserMinMargin) && !marginEntry.isReadOnly}"
            :title="marginTooltip(marginEntry)"
            class="percentageTextInput"
            type="number"
            step="any"
            min="0"
            @change="onMarginValueChanged($event)">
          %
          <TooltipIcon
            v-if="pricesHaveBeenOverridden(marginEntry, iQuoteConstants.costTypes.internal, iQuoteConstants.recurrence.recurring)"
            :text="getMarginTooltipText(marginEntry, iQuoteConstants.costTypes.internal, iQuoteConstants.recurrence.recurring)"
            style="margin-left: 5px"
            width="500"
          />
        </td>
      </tr>
    </tbody>
    <tr>
      <td
        colspan="6"
        style="border-left:2px solid transparent; border-right: 2px solid transparent;">&nbsp;</td>
    </tr>
    <!-- DISCOUNTS-->
    <tr>
      <th rowspan="2">Description</th>
      <th rowspan="2">SF COE</th>
      <th colspan="4">Applicable Discounts</th>
    </tr>
    <tr>
      <th colspan="2">Upfront</th>
      <th colspan="2">Annual</th>
    </tr>
    <tr
      v-if="designPackMarginsComponent.discounts.length === 0"
    >
      <td
        colspan="6"
        style="text-align:center">No Global Discounts are applicable to this design pack</td>
    </tr>
    <tbody
      v-for="discountEntry in designPackMarginsComponent.discounts"
      :key="'discount' + discountEntry.id">
      <tr>
        <td
          rowspan="2"
          style="width:300px"
          class="tableRowLabel"><span>{{ discountEntry.description }}</span></td>
        <td
          rowspan="2"
          style="width:300px"
          class="tableRowLabel">{{ iQuoteFunctions_getSF_COE_NameFromId(discountEntry.salesforceCoeCodeId) }}</td>
        <td>Ext. Pricing</td>
        <td v-if="discountEntry.upfrontExternalDiscount === null">-</td>
        <td
          v-if="discountEntry.upfrontExternalDiscount !== null"
          :class="{editableCell: !isReadOnly}">
          <input
            v-model.number="discountEntry.upfrontExternalDiscount"
            :disabled="isReadOnly"
            :class="{InputError: !discountIsValid(discountEntry, 'upfrontExternalDiscount')}"
            :max="maxDiscount()"
            class="percentageTextInput"
            type="number"
            step="any"
            min="0"
            @change="onDiscountValueChanged($event)">
          %
          <TooltipIcon
            v-if="pricesHaveBeenOverridden(discountEntry, iQuoteConstants.costTypes.external, iQuoteConstants.recurrence.oneOff)"
            :text="getDiscountTooltipText(discountEntry, iQuoteConstants.costTypes.external, iQuoteConstants.recurrence.oneOff)"
            style="margin-left: 5px"
            width="500"
          />
        </td>
        <td>Ext. Pricing</td>
        <td v-if="discountEntry.recurringExternalDiscount === null">-</td>
        <td
          v-if="discountEntry.recurringExternalDiscount !== null"
          :class="{editableCell: !isReadOnly}">
          <input
            v-model.number="discountEntry.recurringExternalDiscount"
            :disabled="isReadOnly"
            :class="{InputError: !discountIsValid(discountEntry, 'recurringExternalDiscount')}"
            :max="maxDiscount()"
            class="percentageTextInput"
            type="number"
            step="any"
            min="0"
            @change="onDiscountValueChanged($event)">
          %
          <TooltipIcon
            v-if="pricesHaveBeenOverridden(discountEntry, iQuoteConstants.costTypes.external, iQuoteConstants.recurrence.recurring)"
            :text="getDiscountTooltipText(discountEntry, iQuoteConstants.costTypes.external, iQuoteConstants.recurrence.recurring)"
            style="margin-left: 5px"
            width="500"
          />
        </td>
      </tr>
      <tr>
        <td>Int. Pricing</td>
        <td v-if="discountEntry.upfrontInternalDiscount === null">-</td>
        <td
          v-if="discountEntry.upfrontInternalDiscount !== null"
          :class="{editableCell: !isReadOnly}">
          <input
            v-model.number="discountEntry.upfrontInternalDiscount"
            :disabled="isReadOnly"
            :class="{InputError: !discountIsValid(discountEntry, 'upfrontInternalDiscount')}"
            :max="maxDiscount()"
            class="percentageTextInput"
            type="number"
            step="any"
            min="0"
            @change="onDiscountValueChanged($event)">
          %
          <TooltipIcon
            v-if="pricesHaveBeenOverridden(discountEntry, iQuoteConstants.costTypes.internal, iQuoteConstants.recurrence.oneOff)"
            :text="getDiscountTooltipText(discountEntry, iQuoteConstants.costTypes.internal, iQuoteConstants.recurrence.oneOff)"
            style="margin-left: 5px"
            width="500"
          />
        </td>
        <td>Int. Pricing</td>
        <td v-if="discountEntry.recurringInternalDiscount === null">-</td>
        <td
          v-if="discountEntry.recurringInternalDiscount !== null"
          :class="{editableCell: !isReadOnly}">
          <input
            v-model.number="discountEntry.recurringInternalDiscount"
            :disabled="isReadOnly"
            :class="{InputError: !discountIsValid(discountEntry, 'recurringInternalDiscount')}"
            :max="maxDiscount()"
            class="percentageTextInput"
            type="number"
            step="any"
            min="0"
            @change="onDiscountValueChanged($event)">
          %
          <TooltipIcon
            v-if="pricesHaveBeenOverridden(discountEntry, iQuoteConstants.costTypes.internal, iQuoteConstants.recurrence.recurring)"
            :text="getDiscountTooltipText(discountEntry, iQuoteConstants.costTypes.internal, iQuoteConstants.recurrence.recurring)"
            style="margin-left: 5px"
            width="500"
          />
        </td>
      </tr>
    </tbody>
    <!-- PRICE OVERRIDES -->
    <tbody
      v-if="showPriceOverrides"
    >
      <tr>
        <td
          colspan="6"
          style="border-left:2px solid transparent; border-right: 2px solid transparent;">&nbsp;</td>
      </tr>
      <tr>
        <th
          rowspan="2"
          colspan="2">Description/SF COE</th>
        <th colspan="4"><span style="margin-right:60px">Price Overrides</span>
          <span v-if="designPackMarginsComponent.priceOverrides.length !== 0">
            <input
              v-model="designPackMarginsComponent.hideUnusedEntries"
              type="checkbox"
            >&nbsp;Hide Unused Overrides<input>
          </span>
        </th>
      </tr>
      <tr>
        <th colspan="2">Upfront</th>
        <th colspan="2">Annual</th>
      </tr>
      <tr
        v-if="designPackMarginsComponent.priceOverrides.length === 0"
      >
        <td
          colspan="6"
          style="text-align:center">No price overrides are applicable to this design pack</td>
      </tr>
      <tr
        v-if="designPackMarginsComponent.priceOverrides.length > 0 &&
          (designPackMarginsComponent.hideUnusedEntries &&
          designPackMarginsComponent.priceOverrides.filter(x => (x.upfrontExternalSellPrice !== '' && x.upfrontExternalSellPrice !== null) ||
            (x.upfrontInternalSellPrice !== '' && x.upfrontInternalSellPrice !== null) ||
            (x.recurringExternalSellPrice !== '' && x.recurringExternalSellPrice !== null) ||
          (x.recurringInternalSellPrice !== '' && x.recurringInternalSellPrice !== null)).length === 0)
        "
      >
        <td
          colspan="6"
          style="text-align:center">No price overrides have been specified on this design pack.  Click the checkbox above to see which entries can be overridden</td>
      </tr>
    </tbody>
    <tbody
      v-for="priceOverrideEntry in designPackMarginsComponent.priceOverrides"
      :key="'priceOverride_' + priceOverrideEntry.description + '_' + priceOverrideEntry.salesforceCoeCodeId">
      <tr
        v-if="showPriceOverrides && (designPackMarginsComponent.hideUnusedEntries === false ||
          (priceOverrideEntry.upfrontExternalSellPrice !== '' && priceOverrideEntry.upfrontExternalSellPrice !== null) ||
          (priceOverrideEntry.upfrontInternalSellPrice !== '' && priceOverrideEntry.upfrontInternalSellPrice !== null) ||
          (priceOverrideEntry.recurringExternalSellPrice !== '' && priceOverrideEntry.recurringExternalSellPrice !== null) ||
          (priceOverrideEntry.recurringInternalSellPrice !== '' && priceOverrideEntry.recurringInternalSellPrice !== null)
        )"
      >
        <td
          rowspan="2"
          style="width:300px"
          class="tableRowLabel"><span>{{ priceOverrideEntry.description }}</span></td>
        <td
          rowspan="2"
          style="width:300px"
          class="tableRowLabel">{{ iQuoteFunctions_getSF_COE_NameFromId(priceOverrideEntry.salesforceCoeCodeId) }}</td>
        <td>Ext. Pricing</td>
        <td v-if="priceOverrideEntry.upfrontExternalSellPrice === null">-</td>
        <td
          v-if="priceOverrideEntry.upfrontExternalSellPrice !== null"
          :class="{editableCell: !isReadOnly}">£
          <input
            v-model.number="priceOverrideEntry.upfrontExternalSellPrice"
            :disabled="isReadOnly"
            :class="{InputError: !priceOverrideIsValid(priceOverrideEntry.upfrontExternalSellPrice)}"
            class="currencyTextInput priceOverrideInput"
            type="number"
            min="0"
            @change="onPriceOverrideValueChanged(priceOverrideEntry, 'upfrontExternalSellPrice')"
            @keyup="onPriceOverrideValueChanged(priceOverrideEntry, 'upfrontExternalSellPrice')"
            @blur="onPriceOverrideFieldLostFocus($event, priceOverrideEntry, 'upfrontExternalSellPrice')">
        </td>
        <td>Ext. Pricing</td>
        <td v-if="priceOverrideEntry.recurringExternalSellPrice === null">-</td>
        <td
          v-if="priceOverrideEntry.recurringExternalSellPrice !== null"
          :class="{editableCell: !isReadOnly}">£
          <input
            v-model.number="priceOverrideEntry.recurringExternalSellPrice"
            :disabled="isReadOnly"
            :class="{InputError: !priceOverrideIsValid(priceOverrideEntry.recurringExternalSellPrice)}"
            class="currencyTextInput priceOverrideInput"
            type="number"
            min="0"
            @change="onPriceOverrideValueChanged(priceOverrideEntry, 'recurringExternalSellPrice')"
            @keyup="onPriceOverrideValueChanged(priceOverrideEntry, 'recurringExternalSellPrice')"
            @blur="onPriceOverrideFieldLostFocus($event, priceOverrideEntry, 'recurringExternalSellPrice')">
        </td>
      </tr>
      <tr
        v-if="showPriceOverrides && (designPackMarginsComponent.hideUnusedEntries === false ||
          (priceOverrideEntry.upfrontExternalSellPrice !== '' && priceOverrideEntry.upfrontExternalSellPrice !== null) ||
          (priceOverrideEntry.upfrontInternalSellPrice !== '' && priceOverrideEntry.upfrontInternalSellPrice !== null) ||
          (priceOverrideEntry.recurringExternalSellPrice !== '' && priceOverrideEntry.recurringExternalSellPrice !== null) ||
          (priceOverrideEntry.recurringInternalSellPrice !== '' && priceOverrideEntry.recurringInternalSellPrice !== null)
      )">
        <td>Int. Pricing</td>
        <td v-if="priceOverrideEntry.upfrontInternalSellPrice === null">-</td>
        <td
          v-if="priceOverrideEntry.upfrontInternalSellPrice !== null"
          :class="{editableCell: !isReadOnly}">£
          <input
            v-model.number="priceOverrideEntry.upfrontInternalSellPrice"
            :disabled="isReadOnly"
            :class="{InputError: !priceOverrideIsValid(priceOverrideEntry.upfrontInternalSellPrice)}"
            class="currencyTextInput priceOverrideInput"
            type="number"
            min="0"
            @change="onPriceOverrideValueChanged(priceOverrideEntry, 'upfrontInternalSellPrice')"
            @keyup="onPriceOverrideValueChanged(priceOverrideEntry, 'upfrontInternalSellPrice')"
            @blur="onPriceOverrideFieldLostFocus($event, priceOverrideEntry, 'upfrontInternalSellPrice')">
        </td>
        <td>Int. Pricing</td>
        <td v-if="priceOverrideEntry.recurringInternalSellPrice === null">-</td>
        <td
          v-if="priceOverrideEntry.recurringInternalSellPrice !== null"
          :class="{editableCell: !isReadOnly}">£
          <input
            v-model.number="priceOverrideEntry.recurringInternalSellPrice"
            :disabled="isReadOnly"
            :class="{InputError: !priceOverrideIsValid(priceOverrideEntry.recurringInternalSellPrice)}"
            class="currencyTextInput priceOverrideInput"
            type="number"
            min="0"
            @change="onPriceOverrideValueChanged(priceOverrideEntry, 'recurringInternalSellPrice')"
            @keyup="onPriceOverrideValueChanged(priceOverrideEntry, 'recurringInternalSellPrice')"
            @blur="onPriceOverrideFieldLostFocus($event, priceOverrideEntry, 'recurringInternalSellPrice')">
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>

import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'
import { iQuoteStaticDataAPIMixin } from '../../../mixins/iQuote/iQuoteStaticDataAPIMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { mapActions } from 'vuex'
import TooltipIcon from '../../common/tooltipIcon'

export default {
  name: 'DesignPackMargins',
  components: {
    TooltipIcon
  },
  mixins: [
    iQuoteStoreMixin,
    iQuoteConstants,
    iQuoteFunctions,
    iQuoteCalculationsDesignPackMixin,
    iQuoteStaticDataAPIMixin,
    iQuoteQuoteDesignPacksAPIMixin
  ],
  props: {
    isReadOnly: {
      type: Boolean,
      default: function () { return false }
    },
    showPriceOverrides: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      designPackMarginsComponent: {
        margins: [],
        discounts: [],
        priceOverrides: [],
        hideUnusedEntries: true,
        showBlendedValuesInTooltips: false
      }
    }
  },
  computed: {
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.designPack,
      (newValue) => {
        if (newValue.priceOverrides !== undefined) {
          this.initialise(newValue)
        }
      }
    )

    if (this.iQuoteStore_GetDesignPack.priceOverrides !== undefined) {
      this.initialise(this.iQuoteStore_GetDesignPack)
    }
  },
  async created () {
  },
  async destroyed () {
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    initialise (designPack) {
      // Make a copy of the objects in the margin and discount arrays, so we don't directly change proeprties of the original
      this.designPackMarginsComponent.margins = designPack.margins.map(a => ({ ...a }))
      this.designPackMarginsComponent.discounts = designPack.discounts.map(a => ({ ...a }))

      this.designPackMarginsComponent.priceOverrides = []

      designPack.priceOverrides.forEach(entry => {
        var newEntry = {
          description: entry.description,
          salesforceCoeCodeId: entry.salesforceCoeCodeId,
          recurringInternalSellPrice: entry.recurringInternalSellPrice,
          recurringExternalSellPrice: entry.recurringExternalSellPrice,
          upfrontInternalSellPrice: entry.upfrontInternalSellPrice,
          upfrontExternalSellPrice: entry.upfrontExternalSellPrice,
          recurringInternalSellPriceInUse: entry.recurringInternalSellPriceInUse,
          recurringExternalSellPriceInUse: entry.recurringExternalSellPriceInUse,
          upfrontInternalSellPriceInUse: entry.upfrontInternalSellPriceInUse,
          upfrontExternalSellPriceInUse: entry.upfrontExternalSellPriceInUse,
          recurringInternalMaxDiscount: entry.recurringInternalMaxDiscount,
          recurringExternalMaxDiscount: entry.recurringExternalMaxDiscount,
          upfrontInternalMaxDiscount: entry.upfrontInternalMaxDiscount,
          upfrontExternalMaxDiscount: entry.upfrontExternalMaxDiscount
        }

        // If it's possible to enter a value, but currently there isn't one specified, set textbox to empty string
        newEntry.recurringInternalSellPrice = newEntry.recurringInternalSellPrice === null && entry.recurringInternalSellPriceInUse ? '' : newEntry.recurringInternalSellPrice
        newEntry.recurringExternalSellPrice = newEntry.recurringExternalSellPrice === null && entry.recurringExternalSellPriceInUse ? '' : newEntry.recurringExternalSellPrice
        newEntry.upfrontInternalSellPrice = newEntry.upfrontInternalSellPrice === null && entry.upfrontInternalSellPriceInUse ? '' : newEntry.upfrontInternalSellPrice
        newEntry.upfrontExternalSellPrice = newEntry.upfrontExternalSellPrice === null && entry.upfrontExternalSellPriceInUse ? '' : newEntry.upfrontExternalSellPrice

        this.designPackMarginsComponent.priceOverrides.push(newEntry)
      })
    },
    pricesHaveBeenOverridden (marginOrDiscountEntry, costTypeId, recurrence) {
      var dpEntryField

      if (recurrence === this.iQuoteConstants.recurrence.oneOff) {
        dpEntryField = 'unitOOPriceOverride'
      } else {
        dpEntryField = 'unitARPriceOverride'
      }

      var dpEntriesInSection = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.dpSectionDescription === marginOrDiscountEntry.description &&
                                                                                             x.dpSectionSalesforceCoeCodeId === marginOrDiscountEntry.salesforceCoeCodeId &&
                                                                                             x.costTypeId === costTypeId)
      var numPriceOverrides = dpEntriesInSection.reduce((accumulator, dataEntry) => accumulator + (dataEntry[dpEntryField] === null ? 0 : 1), 0)
      return (numPriceOverrides > 0)
    },
    getBlendedMargin (marginEntry, costTypeId, recurrence) {
      var dpEntryFieldPrefix
      var dpEntryPriceOverrideField

      if (recurrence === this.iQuoteConstants.recurrence.oneOff) {
        dpEntryPriceOverrideField = 'unitOOPriceOverride'
        if (costTypeId === this.iQuoteConstants.costTypes.external) {
          dpEntryFieldPrefix = 'unitOOExternal'
        } else {
          dpEntryFieldPrefix = 'unitOOInternal'
        }
      } else {
        dpEntryPriceOverrideField = 'unitARPriceOverride'
        if (costTypeId === this.iQuoteConstants.costTypes.external) {
          dpEntryFieldPrefix = 'unitARExternal'
        } else {
          dpEntryFieldPrefix = 'unitARInternal'
        }
      }

      console.log('foo', 'getBlendedMargin', marginEntry.description)

      var dpEntriesInSection = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.dpSectionDescription === marginEntry.description &&
                                                                                             x.dpSectionSalesforceCoeCodeId === marginEntry.salesforceCoeCodeId &&
                                                                                             x.costTypeId === costTypeId)

      var totalCost = dpEntriesInSection.reduce((accumulator, dataEntry) => accumulator + (dataEntry[dpEntryFieldPrefix + 'Cost'] * dataEntry.quantity), 0)
      var totalPrice = dpEntriesInSection.reduce((accumulator, dataEntry) => accumulator + ((dataEntry[dpEntryPriceOverrideField] === null ? dataEntry[dpEntryFieldPrefix + 'Price'] : dataEntry[dpEntryPriceOverrideField]) * dataEntry.quantity), 0)

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(((totalPrice - totalCost) / totalPrice) * 100)
    },
    getMarginTooltipText (marginEntry, costTypeId, recurrence) {
      if (!this.designPackMarginsComponent.showBlendedValuesInTooltips) {
        return 'Prices have been overridden.  See breakdown table for details'
      }

      var textPrefix = 'Prices have been overridden.  See breakdown table for details. Blended Discount is: '

      var marginPercentAsText = textPrefix + this.getBlendedMargin(marginEntry, costTypeId, recurrence) + '%'

      return marginPercentAsText
    },
    getDiscountTooltipText (discountEntry, costTypeId, recurrence) {
      if (!this.designPackMarginsComponent.showBlendedValuesInTooltips) {
        return 'Prices have been overridden.  See breakdown table for details'
      }

      var textPrefix = 'Prices have been overridden.  See breakdown table for details. Blended Discount is: '

      var dpEntryFieldPrefix
      var dpEntryPriceOverrideField
      var discountField

      if (recurrence === this.iQuoteConstants.recurrence.oneOff) {
        dpEntryPriceOverrideField = 'unitOOPriceOverride'
        if (costTypeId === this.iQuoteConstants.costTypes.external) {
          dpEntryFieldPrefix = 'unitOOExternal'
          discountField = 'upfrontExternalDiscount'
        } else {
          dpEntryFieldPrefix = 'unitOOInternal'
          discountField = 'upfrontInternalDiscount'
        }
      } else {
        dpEntryPriceOverrideField = 'unitARPriceOverride'
        if (costTypeId === this.iQuoteConstants.costTypes.external) {
          dpEntryFieldPrefix = 'unitARExternal'
          discountField = 'recurringExternalDiscount'
        } else {
          dpEntryFieldPrefix = 'unitARInternal'
          discountField = 'recurringExternalDiscount'
        }
      }

      var dpEntriesInSection = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.dpSectionDescription === discountEntry.description &&
                                                                                             x.dpSectionSalesforceCoeCodeId === discountEntry.salesforceCoeCodeId &&
                                                                                             x.costTypeId === costTypeId)

      var totalRRP = dpEntriesInSection.reduce((accumulator, dataEntry) => accumulator + ((dataEntry[dpEntryFieldPrefix + 'RRP'] === undefined ? dataEntry[dpEntryFieldPrefix + 'Price'] / ((100 - discountEntry[discountField]) / 100) : dataEntry[dpEntryFieldPrefix + 'RRP']) * dataEntry.quantity), 0)
      var totalPrice = dpEntriesInSection.reduce((accumulator, dataEntry) => accumulator + ((dataEntry[dpEntryPriceOverrideField] === null ? dataEntry[dpEntryFieldPrefix + 'Price'] : dataEntry[dpEntryPriceOverrideField]) * dataEntry.quantity), 0)
      var totalDiscount = totalRRP - totalPrice

      var discountPercentAsText = this.iQuoteFunctions_RoundToTwoDecimalPlaces((totalDiscount / totalRRP) * 100) + '%'

      return textPrefix + discountPercentAsText
    },
    onMarginValueChanged (event) {
      if (event.target.value > 99) {
        event.target.value = 99
      } else if (event.target.value === '') {
        event.target.value = 20
      }

      this.$emit('marginsChanged', this.designPackMarginsComponent.margins)
    },
    onDiscountValueChanged (event) {
      if (event.target.value > 99) {
        event.target.value = 99
      } else if (event.target.value === '') {
        event.target.value = 0
      }

      this.$emit('discountsChanged', this.designPackMarginsComponent.discounts)
    },
    onPriceOverrideValueChanged (priceOverrideEntry, fieldModified) {
      this.$emit('priceOverridesChanged', this.getPriceOverrideFieldChangedData(priceOverrideEntry, fieldModified))
    },
    onPriceOverrideFieldLostFocus (event, priceOverrideEntry, fieldModified) {
      if (event.relatedTarget === null || !event.relatedTarget.classList.contains('priceOverrideInput')) {
        // We've not navigated to another priceOverride field
        this.$emit('priceOverrideTableLostFocus')
      }
    },
    getPriceOverrideFieldChangedData (priceOverrideEntry, fieldModified) {
      var priceOverrideValue = (priceOverrideEntry[fieldModified] === '' ? null : priceOverrideEntry[fieldModified])

      var priceOverrideValueChangedData = {
        description: priceOverrideEntry.description,
        salesforceCoeCodeId: priceOverrideEntry.salesforceCoeCodeId,
        field: fieldModified,
        value: priceOverrideValue
      }

      return priceOverrideValueChangedData
    },
    marginIsValid (value, min) {
      return value >= min && value < 100
    },
    discountIsValid (discountEntry, editedField) {
      var max

      switch (editedField) {
        case 'recurringInternalDiscount':
          max = discountEntry.recurringInternalMaxDiscount
          break
        case 'recurringExternalDiscount':
          max = discountEntry.recurringExternalMaxDiscount
          break
        case 'upfrontInternalDiscount':
          max = discountEntry.upfrontInternalMaxDiscount
          break
        case 'upfrontExternalDiscount':
          max = discountEntry.upfrontExternalMaxDiscount
          break
      }

      if (max === null || max === undefined) {
        return true
      }

      var value = discountEntry[editedField]
      return value >= 0 && value <= max
    },
    priceOverrideIsValid (value) {
      return !isNaN(value) && !isNaN(parseFloat(value))
    },
    maxDiscount () {
      return 100
    },
    setMarginAsUsed (margin) {
      // Initally the margins that we have in our list are loaded from what was saved in the DB against the
      // design pack. We don't know if that margin is still in use (just that it was at some point).  We set
      // a flag on those that are still in use, so we only allow the user to see the ones that will
      // affect the design pack, but we still keep the unused ones that were previously configured,
      // just in case the user puts back on the design pack the entries that used it

      var existingMargin = this.designPackMarginsComponent.margins.find(x => x.salesforceCoeCodeId === margin.salesforceCoeCodeId && x.description === margin.description)

      if (existingMargin === undefined) {
        margin.inUse = true
        this.designPackMarginsComponent.margins.push(margin)
      } else {
        existingMargin.inUse = true

        // The margin settings entry has been found.  Set any margins that haven't already been set to those on the margin passed in (default values)

        if (existingMargin.upfrontExternalMargin === null) {
          existingMargin.upfrontExternalMargin = margin.upfrontExternalMargin
        }

        if (existingMargin.upfrontInternalMargin === null) {
          existingMargin.upfrontInternalMargin = margin.upfrontInternalMargin
        }

        if (existingMargin.recurringExternalMargin === null) {
          existingMargin.recurringExternalMargin = margin.recurringExternalMargin
        }

        if (existingMargin.recurringInternalMargin === null) {
          existingMargin.recurringInternalMargin = margin.recurringInternalMargin
        }

        existingMargin.upfrontExternalIquoteUserMinMargin = margin.upfrontExternalIquoteUserMinMargin
        existingMargin.upfrontInternalIquoteUserMinMargin = margin.upfrontInternalIquoteUserMinMargin
        existingMargin.recurringExternalIquoteUserMinMargin = margin.recurringExternalIquoteUserMinMargin
        existingMargin.recurringInternalIquoteUserMinMargin = margin.recurringInternalIquoteUserMinMargin
      }
    },
    setDiscountAsUsed (discount) {
      var existingDiscount = this.designPackMarginsComponent.discounts.find(x => x.salesforceCoeCodeId === discount.salesforceCoeCodeId && x.description === discount.description)

      if (existingDiscount === undefined) {
        discount.inUse = true
        this.designPackMarginsComponent.discounts.push(discount)
      } else {
        existingDiscount.inUse = true
      }
    },
    marginTooltip (marginEntry) {
      if (marginEntry.isReadOnly) {
        return 'This margin is precalculated and cannot be modified'
      }

      return ''
    }
  }
}
</script>
<style scoped>
  #marginsTable {
    margin-top: 15px;
  }

  .tableRowLabel {
    font-weight: bold;
  }

  .percentageTextInput {
    min-width: 10px;
    width: 50px;
    border: none;
  }
  .currencyTextInput {
    min-width: 80px;
    width: 80px;
    border: none;
  }

  .editableCell {
    background-color: yellow;
  }

  .precalculatedCell {
    background-color: #A9CCE3;
  }
</style>
