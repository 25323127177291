import portalAPIGateway from '../../../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteIconConnectAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteIconConnectAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteQuoteIconConnectAPIMixin_saveQuoteIconConnectToDB: async function (quoteId, quoteIconConnect) {
      var axiosConfig = this.iQuoteQuoteIconConnectAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.put('iquote/quoteiconconnect/' + quoteId, quoteIconConnect, axiosConfig).then((response) => {
          returnValue = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteIconConnectAPIMixin_saveQuoteIconConnectToDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteIconConnectAPIMixin_deleteQuoteIconConnectFromDB: async function (quoteIconConnect) {
      var axiosConfig = this.iQuoteQuoteIconConnectAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.delete('iquote/quoteiconconnect/' + quoteIconConnect.id, null, axiosConfig).then((response) => {
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteIconConnectAPIMixin_deleteQuoteIconConnectFromDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteIconConnectAPIMixin_getQuoteIconConnectFromDb: async function (quoteId) {
      var axiosConfig = this.iQuoteQuoteIconConnectAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquote/quoteiconconnect/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteIconConnectAPIMixin_getQuoteIconConnectFromDb returned error: ' + error)
      }

      return data
    }
  }
}
