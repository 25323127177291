<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      :overlay="true"
      persistent
      max-width="50rem"
      transition="dialog-bottom-transition"
      class="dialog"
    >
      <v-card
        :id="dlgid"
        class="ma-0 dialog-border"
        height="240px"
        tile
      >
        <v-toolbar
          dense
          flat
          color="white"
        >
          <v-spacer/>
          <v-btn
            v-if="dlgCloseFromTop"
            :id="btnCancelID"
            style="position: absolute; right: 0.8rem; top: 0;"
            icon
            @click="cancel"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="card">
          <v-flex
            xs12
            sm12
            md1
            style="margin-right:1.5rem"
          >
            <v-icon
              v-if="dlgIcon"
              color="orange"
              style="margin: 0 1.5rem; font-size:2.5rem"
            >{{ dlgIcon }}</v-icon>
          </v-flex>
          <v-flex
            xs12
            sm12
            md10
          >
            <div
              class="title font-weight-light"
              v-html="dlgMessage"/>
          </v-flex>
        </v-card-title>
        <v-card-actions style="position: absolute;bottom: 1rem; right: 1rem;">
          <v-spacer/>
          <v-btn
            v-if="negativeBtnText || negativeBtnText != null"
            id="negativeButton"
            :disabled="isPressed && hideButtonsAfterPress"
            style="color: orange !important;"
            color="orange"
            flat
            @click="negativeAction"
          >{{ negativeBtnText }}</v-btn>
          <v-btn
            v-if="positiveBtnText || positiveBtnText != null"
            id="positiveButton"
            :disabled="isPressed && hideButtonsAfterPress"
            color="orange"
            dark
            @click="positiveAction"
          >{{ positiveBtnText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Spinner from '../common/spinner'

export default {
  name: 'ConfirmationDialogBox',
  components: {
    Spinner
  },
  props: {
    dialog: { type: Boolean, default: false },
    dlgid: { type: String, required: false, default: '' },
    dlgIcon: { type: String, required: false, default: '' },
    dlgMessage: { type: String, default: '' },
    btnPositiveID: { type: String, default: '' },
    btnNegativeID: { type: String, default: '' },
    btnCancelID: { type: String, default: '' },
    dlgCloseFromTop: { type: Boolean, default: false },
    negativeBtnText: { type: String, default: '' },
    positiveBtnText: { type: String, default: '' },
    hideButtonsAfterPress: { type: Boolean, default: true }
  },
  data () {
    return {
      isPressed: false
    }
  },
  methods: {
    negativeAction () {
      this.$emit('negativeAction')
    },
    positiveAction () {
      this.isPressed = true
      this.$emit('positiveAction')
    },
    cancel () {
      this.$emit('cancelDialog')
    }
  }
}
</script>

<style>
  .v-dialog {
    overflow-y: unset !important;
  }
  .card {
      padding:0.5rem 1.5rem -1rem 1rem;
  }
  .title {
    padding-top: 5px;
  }
  .dialog-border {
    border-left: 1rem solid orange !important
  }
  .v-btn__content {
    font-weight: bold;
  }
</style>
