<template>
  <div class="parentContainer">
    <div class="opportunities">
      <h2> Quotes </h2>
      <div
        class="priorityFilterContainer"
      >
        <div
          class="statusIcon"
        >
          Closed Quotes
          <TriStateToggleSwitch
            :enabled="true"
            :current-state=" displayClosedQuotes ? 1 : 2"
            :tri-state="false"
            style="top:5px;"
            @statechanged="filterQuoteStatus()"
          />
        </div>
      </div>
      <div
        :class="[ QuotesDetailsVisible ? 'splitviewleft': '']"
      >
        <QuotesSiteSummary
          v-show="QuotesSiteSummaryVisible"
          :display-closed-quotes="displayClosedQuotes"
          @opportunityDrillDown="opportunityDrillDown"
        />
      </div>
      <div
        :class="[ QuotesDetailsVisible ? 'splitviewright': '']"
      >
        <QuotesDetails
          v-show="QuotesDetailsVisible"
          :selected-opportunity="targetOpportunity"
        />
      </div>
      <input
        v-if="quotesStepNumber != 1"
        type="button"
        class="backButton"
        title="Back"
        @click="quotesNavigateTo()"
      >
    </div>
  </div>
</template>

<script>

import QuotesSiteSummary from './QuotesSiteSummaryComponent'
import QuotesDetails from './QuotesDetailsComponent'
import { permissionsMixin } from '../../mixins/permissions'
import { DataEventBus } from '../../events/dataEvents'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'

export default {
  name: 'QuotesContainer',
  components: {
    QuotesSiteSummary,
    QuotesDetails,
    TriStateToggleSwitch
  },
  mixins: [
    permissionsMixin
  ],
  data () {
    return {
      QuotesSiteSummaryVisible: true,
      QuotesDetailsVisible: false,
      quotesStepNumber: 1,
      targetSiteOpportunities: [],
      targetOpportunity: {},
      displayClosedQuotes: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    DataEventBus.$on('company-changed', (customer) => {
      this.quotesStepNumber = 1
      this.quotesNavigateTo()
    })
    DataEventBus.$on('refresh-data', () => {
      this.quotesStepNumber = 1
      this.quotesNavigateTo()
    })
  },
  created () {
  },
  methods: {
    quotesNavigateTo: function () {
      switch (this.quotesStepNumber) {
        default:
          this.quotesStepNumber = 1
          this.QuotesSiteSummaryVisible = true
          this.QuotesDetailsVisible = false
          break
      }
    },
    opportunityDrillDown: function ({ opportunity }) {
      if (this.targetOpportunity !== opportunity) {
        this.targetOpportunity = opportunity

        if (this.targetOpportunity !== {}) {
          this.OpportunitiesTopLevelSummaryVisible = false
          this.QuotesSiteSummaryVisible = true
          this.QuotesDetailsVisible = true
        }
      } else {
        this.QuotesDetailsVisible = !this.QuotesDetailsVisible
      }
    },
    filterQuoteStatus: function () {
      this.displayClosedQuotes = !this.displayClosedQuotes
    }
  }
}
</script>

<style scoped>
  .parentContainer {
    max-height: 500px;
    overflow-y: auto;
  }
  .backButton {
    margin: 5px 24px;
    width: 80%;
  }
  .splitviewleft {
    float: left;
    width:60%;
  }
  .splitviewright {
    display: inline-block;
    width:40%;
  }
  .opportunities h2 {
    margin: 0 0 0 10px;
    font-size: 1.4em;
  }
  .opportunities {
    background-color: #fff;
    border-radius: 10px;
    margin: 5px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    max-height:600px;
    overflow-y:auto;
  }

    .Red {
    background-color: rgb(239, 83, 80);
    border: 2px rgb(240, 73, 70) solid;
  }

  .Amber {
    background-color: rgb(255, 167, 38);
    border: 2px rgb(241, 159, 36) solid;
  }

  .Green {
    background-color: rgb(102, 187, 106);
    border: 2px rgb(93, 170, 97) solid;
  }

  .Grey {
    background-color: rgb(214, 213, 213);
    border: 2px rgb(199, 198, 198) solid;
  }

  .Purple {
    background-color: rgb(167, 80, 239);
    border: 2px rgb(155, 48, 243) solid;
  }

  .priorityFilterContainer {
    display: flex;
  }

  .statusIcon {
    margin: 5px 5px 10px 5px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: 400;
    cursor: pointer;
  }

</style>
