<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <component :is="externalQuotesModelMixin_State.currentPage"/>
    </div>
  </div>
</template>

<script>
import RootPage from './externalQuotes/rootPage'
import BomPage from './externalQuotes/bomPage'
import IconNowPage from './externalQuotes/iconNowPage'
import IconConnectPage from './externalQuotes/iconConnectPage'
import IconSecurePage from './externalQuotes/iconSecurePage'
import SecureHomeworkerPage from './externalQuotes/secureHomeworkerPage'
import ManagedServicePage from './externalQuotes/managedServicePage'
import NewManagedServicePage from './externalQuotes/newManagedServicePage'
import CallsAndLinesPage from './externalQuotes/callsAndLinesPage'
import HorizonPage from './externalQuotes/horizonPage'
import TwilioPage from './externalQuotes/twilioPage'
import { externalQuotesModelMixin } from '../../../mixins/iQuote/Models/externalQuotesModelMixin'

import { mapActions } from 'vuex'

export default {
  name: 'ExternalQuotes',
  components: {
    RootPage,
    BomPage,
    IconConnectPage,
    ManagedServicePage,
    NewManagedServicePage,
    CallsAndLinesPage,
    HorizonPage,
    IconSecurePage,
    SecureHomeworkerPage,
    IconNowPage,
    TwilioPage
  },
  mixins: [externalQuotesModelMixin],
  data () {
    return {
    }
  },
  computed: {
  },
  async mounted () {
  },
  methods: {
    ...mapActions([
    ])
  }
}
</script>
<style scoped>
  .quotePanel {width: 1520px}
</style>
