<template>
  <div>
    <v-icon
      title="Copy text to clipboard"
      @click="copyToClipboard"
    >
      mdi-clipboard-text
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    element: {
      type: String,
      default: ''
    }
  },
  methods: {
    copyToClipboard () {
      var textToCopy = document.getElementById(this.element).innerHTML
      if (textToCopy) {
        try {
          navigator.clipboard.writeText(textToCopy)
          this.$store.commit('showMessage', { content: 'The text ' + textToCopy + ' has been copied to clipboard', color: 'blue', timeout: 3000 })
        } catch (err) {
          console.log('Unable to copy to clipboard at this time')
        }
      }
    }
  }
}
</script>
