import axios from '../interceptors/apiInterceptor'
import { mapActions } from 'vuex'
export const ratingMarkerRepository = {
  data () {
    return {
      ratingMarkers: [],
      allRatingMarkers: []
    }
  },
  methods: {
    ...mapActions([
      'setRatings'
    ]),
    alarmsGet (username, pastDays) {
      this.ratingMarkers = []
      var sites = this.$store.getters.sites
      if (sites && sites.length > 0) {
        var siteList = ''
        for (let i = 0; i < sites.length; i++) {
          siteList += sites[i].id + ','
        }
        if (sites.length > 0) {
          siteList = siteList.slice(0, -1)
          return axios
            .post('/RatingMarker/all', {
              Username: username,
              PastDays: pastDays,
              SiteList: siteList
            })
            .then(res => {
              this.setRatings(res.data)
            })
            .catch((err) => {
              // eslint-disable-next-line
              console.log('error getting rating markers', err)
            })
        }
      }
    },
    ratingMarkersGet (username, siteId, pastDays) {
      this.ratingMarkers = []
      return axios
        .post('/RatingMarker', {
          Value: username,
          SiteId: siteId,
          PastDays: pastDays
        })
        .then(res => {
          this.ratingMarkers = res.data
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log('Error getting rating markers', err)
        })
    }
  }
}
