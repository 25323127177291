<template>
  <div class="commercialContainer">
    <h2>Commercial Audit</h2>
    <p>This section will eventually contain previous Designpacks that have been generated for this opportunity</p>
  </div>
</template>

<script>

export default {
  name: 'CommercialAudit',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style scoped>

</style>
