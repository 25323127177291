import portalAPIGateway from '../../communications/interceptors/apiInterceptor'
export const iQuoteExportsAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteExportsAPIMixin_getAxiosConfigJSON () {
      var userid = this.$store.getters.userId
      return {
        timeout: 120000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteExportsAPIMixin_getAxiosConfig_Binary () {
      var userid = this.$store.getters.userId
      return {
        timeout: 180000,
        responseType: 'arraybuffer',
        dataType: 'binary',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Accept': 'application/octet-stream',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteExportsAPIMixin_getBOMDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getBOMDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getBOMDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackDataFromEditedDesignPack: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getDesignPackDataFromEditedDesignPack', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getDesignPackDataFromEditedDesignPack returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getDesignPackDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getDesignPackDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getIconConnectDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getIconConnectDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getIconConnectDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getIconNowDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getIconNowDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getIconNowDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getSecureHomeworkerDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getSecureHomeworkerDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getSecureHomeworkerDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getIconSecureDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getIconSecureDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getIconSecureDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getManagedServiceDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getManagedServiceDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getManagedServiceDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getNewManagedServiceDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getNewManagedServiceDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getNewManagedServiceDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getCallsAndLinesDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getCallsAndLinesDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getCallsAndLinesDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getHorizonDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getHorizonDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getHorizonDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getTwilioDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getTwilioDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getTwilioDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackCircuitsDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getDesignPackCircuitsDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getDesignPackCircuitsDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackHardwareDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/getDesignPackHardwareDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getDesignPackHardwareDataFromExcel returned error: ' + error);
      }
      return data
    },
    iQuoteExportsAPIMixin_getBomTemplate: async function () {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.get('iquoteexport/GetBomTemplate', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getBomTemplate returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackCircuitsTemplate: async function () {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.get('iquoteexport/GetDesignPackCircuitsTemplate', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getDesignPackCircuitsTemplate returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackHardwareTemplate: async function () {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.get('iquoteexport/GetDesignPackHardwareTemplate', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getDesignPackHardwareTemplate returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getExcelDesignPack: async function (id) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.get('iquoteexport/GetDesignPackExcel/' + id, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getExcelDesignPack returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getQuoteCallTarrifDoc: async function (id) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.get('iquoteexport/GetQuoteCallTarrifDoc/' + id, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getQuoteCallTarrifDoc returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getQuoteTemplateDocument: async function (id) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.get('iquoteexport/GetQuoteTemplate/' + id, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getQuoteTemplateDocument returned error: ' + error)
      }
      return data
    },
    getQuoteAutotaskTemplateDocument: async function (id) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.get('iquoteexport/GetQuoteAutotaskTemplate/' + id, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('GetQuoteAutotaskTemplateDocument returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackExport: async function (designPack) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()

      try {
        await portalAPIGateway.put('iquoteexport/GetDesignPackExport', designPack, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getDesignPackExport returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackCircuitsAutotaskImportSpreadsheet: async function (designPack) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()

      try {
        await portalAPIGateway.put('iquoteexport/GetDesignPackCircuitsAutotaskImportSpreadsheet', designPack, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getDesignPackCircuitsAutotaskImportSpreadsheet returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getQuoteCxNowDdiDetailDocument: async function (id) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.get('iquoteexport/GetQuoteCxNowDdiDetailDocument/' + id, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteExportsAPIMixin_getQuoteCxNowDdiDetailDocument returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getQuoteDesignPackAutoTaskExportExcel: async function (designPack) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.put('iquoteexport/GetQuoteDesignPackAutoTaskExportExcel', designPack, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        data = 'error'
        console.log('iQuoteExportsAPIMixin_getQuoteDesignPackAutoTaskExportExcel returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackCeasesTemplate: async function () {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfig_Binary()
      try {
        await portalAPIGateway.get('iquoteexport/GetDesignPackCeasesTemplate', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('GetDesignPackCeasesTemplate returned error: ' + error)
      }
      return data
    },
    iQuoteExportsAPIMixin_getDesignPackCeasesDataFromExcel: async function (excelSpreadsheetBase64) {
      var data = []
      var axiosConfig = this.iQuoteExportsAPIMixin_getAxiosConfigJSON()

      var payload = {
        contents: excelSpreadsheetBase64
      }

      try {
        await portalAPIGateway.put('iquoteexport/ExtractDataFromFile/GetDesignPackCeasesDataFromExcel', payload, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('GetDesignPackCeasesDataFromExcel returned error: ' + error);
      }
      return data
    }
  }
}
