import authAPI from '../communications/interceptors/authInterceptors.js'
import { mapActions } from 'vuex'
export const verifyMixin = {
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'setTokens'
    ]),
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
    },
    verifyTwilioToken: async function (req) {
      var rtn = false
      var axiosConfig = this.getHeaders()
      try {
        await authAPI.post('verify/check', req, axiosConfig).then((response) => {
          this.setTokens(response.data)
          rtn = true
        })
      } catch (_) {
        console.log('Error calling verifyTwilioToken')
      }
      return rtn
    }
  }
}
