<template>
  <div
    class="securityGroupsHolder"
  >
    <Spinner
      v-if="loading"
      id="securityGroupMemberSpinner"
    />
    <p
      v-if="!loading && (!userSecurityGroups || userSecurityGroups.length === 0)"
      class="alert"
    >
      This user has no Security Groups.
    </p>
    <table
      v-if="!loading && userSecurityGroups.length > 0"
    >
      <tbody>
        <tr
          v-for="g in userSecurityGroups"
          :class="getRowColour()"
          :key="g.id"
        >
          <td
            :title="g.name"
          >{{ g.name }} </td>
          <td
            style="text-align:right;width:8%"
          >
            <!--
              This button will only be shown if you are a storm administrator
              or you are our admin account (in case a role is not awarded that prevents you)
              or the admin account, but not the admin security group, which should never be deleted
              or the default viewer, but not the user security group, which should never be deleted
            -->
            <input
              v-if="showDeleteButton(g)"
              type="button"
              class="deleteButton"
              title="Remove member from securityGroup"
              @click="removeSecurityGroup(g)"
            >
          </td>
        </tr>
      </tbody>
    </table>
    <input
      v-if="stormAdmin && !loading"
      id="addSecurityGroupButton"
      type="button"
      class="addSecurityGroupButton"
      value="Add Security Group"
      title="Adds a security broup to the selected user"
      @click="addSecurityGroup()"
    >
    <StormAddSecurityGroup
      :storm-user-id="stormUserId"
      :current-web-users="currentWebUsers"
      :max-web-users="maxWebUsers"
      @reload-storm-security-groups="setComponent()"
    />
  </div>
</template>
<script>
import { stormMixin } from '../../../mixins/stormMixin'
import Spinner from '../../common/spinner'
import StormAddSecurityGroup from './stormAddSecurityGroup'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SecurityGroups',
  components: {
    Spinner,
    StormAddSecurityGroup
  },
  mixins: [
    stormMixin
  ],
  props: {
    stormUserId: {
      type: Number,
      default: -1
    },
    stormUser: {
      type: Object,
      default: function () { return {} }
    },
    stormAdmin: {
      type: Boolean,
      default: false
    },
    maxWebUsers: {
      type: Number,
      default: 0
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      showNew: false,
      loading: false,
      userSecurityGroups: [],
      currentWebUsers: 0
    }
  },
  computed: {
    ...mapState([
      'openSubForm4'
    ]),
    stormUsers () {
      return this.$store.getters.stormUsers
    },
    adminEmail () {
      return process.env.VUE_APP_STORM_ADMIN_USER
    },
    ssoSecurityGroup () {
      return this.$store.getters.stormSSOSecurityGroup
    },
    stormAdminSecurityGroup () {
      return this.$store.getters.stormAdminSecurityGroup
    },
    stormUserSecurityGroup () {
      return this.$store.getters.stormUserSecurityGroup
    },
    stormDefaultUser () {
      return this.$store.getters.stormDefaultUser
    }
  },
  watch: {
    stormUserId: async function () {
      await this.setComponent()
    }
  },
  mounted: async function () {
    await this.setComponent()
  },
  methods: {
    ...mapActions([
      'setSubForm4',
      'setSecurityGroups'
    ]),
    setComponent: async function () {
      this.loading = true
      this.userSecurityGroups = []
      if (this.stormUserId > 0) {
        this.userSecurityGroups = await this.getStormSecurityGroupsByUser(this.stormUserId)
        if (this.userSecurityGroups === '') {
          this.userSecurityGroups = []
        }
      }
      await this.setWebAccessMembers()
      this.setSubForm4(false)
      this.loading = false
    },
    addSecurityGroup: async function () {
      this.setSubForm4(true)
    },
    removeSecurityGroup: async function (g) {
      await this.deleteUserFromStormSecurityGroup(this.stormUserId, g.id)
      await this.setComponent()
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    },
    setWebAccessMembers: async function () {
      var userCount = 0
      for (let i = 0; i < this.stormUsers.length; i++) {
        var lsg = await this.getStormSecurityGroupsByUser(this.stormUsers[i].id)
        if (lsg.length > 0) {
          lsg.forEach(sg => {
            if (sg.name === this.ssoSecurityGroup) {
              userCount++
            }
          })
        }
      }
      this.currentWebUsers = userCount
    },
    showDeleteButton (g) {
      // This button will only be shown if you are a storm administrator
      // or you have more than one security group assigned
      // or you are our admin account (in case a role is not awarded that prevents you)
      // or the admin account, but not the admin security group, which should never be deleted
      // or the default viewer, but not the user security group, which should never be deleted
      var isAdmin = this.stormAdmin || this.stormUser.email === this.adminEmail
      if (g.name === this.stormAdminSecurityGroup && this.stormUser.email === this.adminEmail) {
        return false
      } else if (g.name === this.stormUserSecurityGroup && this.stormUser.email === this.stormDefaultUser) {
        return false
      } else if (this.userSecurityGroups.length < 2) {
        return false
      } else {
        return isAdmin
      }
    }
  }
}
</script>
<style scoped>
  .securityGroupsHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .addSecurityGroupButton {
    margin: 10px;
    float: right;
    cursor: pointer;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
  }
</style>
