<template>
  <div style="padding-top:30px;">
    <div
      class="releaseNotesContainer"
    >
      <table id="historyTable">
        <thead />
        <tbody>
          <tr
            v-for="v in currentFilter"
            :key="v.id"
          >
            <td :title="v.Version">{{ v.Version }}</td>
            <td :title="v.Date">{{ v.Date }}</td>
            <td :title="v.ReleasedBy">{{ v.ReleasedBy }}</td>
            <td :title="v.Description">{{ v.Description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bottombar">
      <input
        id="backButton"
        type="button"
        class="backButton"
        title="Back to support page"
        @click="closeReleaseNotes()"
      >
    </div>
  </div>
</template>

<script>
import tableHandler from '../../mixins/tableHandler'
export default {
  metaInfo: {
    title: 'Support - Release Notes'
  },
  mixins: [
    tableHandler
  ],
  data () {
    return {
      releaseNotes: []
    }
  },
  mounted () {
    this.$store.getters.history.forEach(item => {
      this.releaseNotes.push(item)
    })
    this.releaseNotes.reverse()

    this.initTable()
  },
  methods: {
    initTable: function () {
      this.initialiseTable('historyTable', this.releaseNotes,
        ['Version', 'Date', 'ReleasedBy', 'Description'],
        ['Version', 'Date', 'Released By', 'Description'],
        ['', '', '', ''],
        '650px',
        ['10%', '15%', '15%', '60%'],
        20,
        true,
        'release_notes.csv'
      )
    },
    closeReleaseNotes: function () {
      this.$emit('setTileMode')
    }
  }
}
</script>

<style scoped>
.releaseNotesContainer {
    display: inline-block;
    margin: 0 0 0 15px;
}
.bottombar {
    margin:10px 0 0 15px;
}
</style>
