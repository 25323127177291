<template>
  <div class="pgContainer" >
    <table
      v-if="gridList.length>0"
      :id="`tblRow${gridDisplay}`"
      class="pgTable">
      <thead class="pgHead">
        <tr
          :class="{pgSmall:size==='small'}"
          class="pgHeaderRow">
          <td
            v-for="col in gridColumns"
            :key="col.title"
            :class="col.extraClass"
            :style="{width: getWidth(col.width) }"
            class="pgHeadItem">
            {{ col.title | removeUnderscores }}
          </td>
        </tr>
      </thead>
      <tbody class="pgBody">
        <tr
          v-for="item of gridList"
          :key="item.id"
          :id="`tblRow${gridDisplay}${item.id}`"
          :class="{itemSelected : selected==item.id,
                   highlightPurple: item.Highlight==='Purple',
                   highlightRed: item.Highlight==='Red',
                   highlightAmber: item.Highlight==='Amber',
                   highlightGreen: item.Highlight==='Green',
                   pgSmall:size==='small'}"
          class="pgBodyRow"
          @click="selectRow(item)">
          <td
            v-for="(col,index) in gridColumns"
            :key="index"
            :id="`tblRow${gridDisplay}${item.id}${col.title}`"
            :class="getClasses(index, item.id)"
            :style="{width: getWidth(col.width) }"
            class="pgBodyItem">
            <button
              v-if="index===0"
              class="pgButton"
              @focus="focusItem=item.id" >
              {{ item[col.title] }}
            </button>
            <div v-else>
              {{ item[col.title] }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else
      class="pgNoRows">
      <div v-if="size==='small'">
        <h3 class="appDarkFont">
          {{ gridDisplay }}
        </h3>
      </div>
      <div v-else>
        <h2 class="appDarkFont">
          {{ gridDisplay }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    removeUnderscores: (value) => {
      return value.replace('_', ' ')
    }
  },
  props: {
    gridColumns: {
      type: Array,
      default: () => ([])
    },
    gridList: {
      type: Array,
      default: () => ([])
    },
    gridDisplay: {
      type: String,
      default: ''
    },
    preSelectedRow: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      searchQuery: '',
      sortKey: '',
      selected: null,
      focusItem: ''
    }
  },
  computed: {
    selectedItem () {
      return this.selected !== null ? this.selected : this.preSelectedRow
    }
  },
  methods: {
    selectRow (item) {
      this.focusItem = ''
      if (item.id !== this.selected) {
        this.selected = item.id
        this.$emit('ItemSelected', item)
        return
      }
      this.selected = '-1'
      this.$emit('ItemSelected', null)
    },
    getClasses (index, id) {
      var classes = this.gridColumns[index].extraClass
      if (index === 0) {
        classes += ' intialCol'
      }
      if (this.size === 'small') {
        classes += ' pgSmall'
      }
      if (this.focusItem === id) {
        classes += ' pgFocused'
      }

      return classes
    },
    getWidth (value) {
      if (!value || value === '') {
        return 'auto'
      }
      return value
    }
  }

}
</script>

<style scoped>

  .pgButton{
      text-decoration: none;
      border:none;
      background-color: transparent;
      color: inherit;
      text-align: left;
  }

  .pgContainer{
    max-height: 340px;
    overflow: auto;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }

  .pgTable{
      min-height: 100px;
      width:100%;
      border-spacing: 0;
  }

  .pgHead{
      background-color:var(--main-bg-colour-trans);
      background-color:rgba(44,62,80,0.7);
  }

  .pgHeadItem{
    line-height:50px;
    font-size:12pt;
    color:white;
  }
  .pgHeaderRow.pgSmall{
    font-size:12pt;
  }

  .pgBodyRow{
    cursor: pointer;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 6px;
    transition: all 2s ease-in-out;
  }

  .pgBodyRow .pgSmall{
    font-size:11pt;
  }
  .pgBodyRow:nth-child(2n+1) {
    /* background: rgba(255, 255, 255,1); */
    background-color:#d1d8e0;
    color:black;
    opacity: 0.85;
  }

  .pgBodyRow:nth-child(2n) {
    /* background: rgba(141, 212, 238,1); */
    background-color:#2bcbba;
    opacity: 0.85;
    color:black;
  }

  .pgBodyRow:hover{
    opacity:1;
    transform: scale(1.008);
  }
  .itemSelected:hover{
    opacity:1;
    transform: scale(1);
  }

  .pgBodyRow.highlightRed:nth-child(2n+1) {
      /* background:rgba(204, 0, 0,0.9); */
      background-color:rgb(255, 77, 77);
      color:white;
  }

  .pgBodyRow.highlightRed:nth-child(2n) {
      /* background:#FF4444; */
      background-color:#ff3838;
      color:white;
  }

  .pgBodyRow.highlightAmber:nth-child(2n+1) {
      /* background:rgba(255, 136, 0,1); */
      background-color:#fd9644;
      color:white;
  }

  .pgBodyRow.highlightAmber:nth-child(2n) {
      /* background:#FFBB33; */
      background-color:#fa8231;
      color:white;
  }

  .pgBodyRow.highlightGreen:nth-child(2n+1) {
      /* background:rgba(0, 126, 51,1); */
      background-color:#26de81;
      color:black;
  }

  .pgBodyRow.highlightGreen:nth-child(2n) {
      /* background:#00C851; */
      background-color:#20bf6b;
      color:black;
  }

  .pgBodyRow td{
      padding: 2px 5px 2px 5px;
  }

  .pgHeadItem, .pgBodyItem{
      padding: 7px 5px 7px 5px;
  }

  .pgTitleClass{
    text-align: left;
    white-space: nowrap;
  }

  .pgCenter{
    text-align: center;
  }

  .pgBodyRow{
      transition: all 0.3s ease-in-out;
  }

  .pgBodyRow.itemSelected > * {
      background-color:yellow;
      text-decoration: underline;
      color:black;
      transform: scaleY(1.1) translateX(0);
      border-top:1px solid black;
      border-bottom:1px solid black;
      cursor: default;
  }

  .pgNoRows{
    text-align: center;
    line-height:50px;
  }

  .pgFocused{
        background-color: rgba(255, 255, 255, 0.5);
        border:white dashed 1px;
  }

</style>
