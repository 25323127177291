<template>
  <div
    id="serviceOverview"
  >
    <div
      v-if="viewAreaTicketsPermission"
    >
      <div>
        <ServiceStatusTile class="dashboardTile" />
        <div
          id="ServiceAlarms"
          style="padding:15px 0;"
        >
          <overviewCard
            id="ticketsClickthroughUnresolved"
            :value="filteredAlarms.find(({ name }) => name === 'Unresolved Alarms').alarmCount.toString() || 'N/A'"
            to="/site-health?alarmsFilter=high"
            title="Total Unresolved Alarms"
            colour="red"
            icon="mdi-alarm"
          />
          <overviewCard
            id="ticketsClickthroughRaised"
            :value="filteredAlarms.find(({ name }) => name === 'Alarms Raised').alarmCount.toString()"
            to="/site-health"
            title="Total Alarms Raised"
            colour="grey"
            icon="mdi-alarm"
            sub-icon="mdi-calendar"
            sub-text="Within Last 7 Days"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="noResults"
    >
      Sorry, you do not have permission to view the service status page
    </div>
  </div>
</template>

<script>

import tableHandler from '../../mixins/tableHandler'
import ServiceStatusTile from '../tiles/dashboard/ServiceStatusTile'
import overviewCard from '../../components/common/overviewCard'

export default {
  components: {
    ServiceStatusTile,
    overviewCard
  },
  mixins: [
    tableHandler
  ],
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    alarmList () {
      return this.$store.getters.ratings || []
    },
    sitesList () {
      return this.$store.getters.sites
    },
    autotaskIcon () {
      return require(`../../assets/icons/autotask-icon.png`)
    },
    viewAreaTicketsPermission () {
      return this.$store.getters.viewAreaTicketsPermission
    },
    filteredAlarms () {
      return [
        {
          name: 'Unresolved Alarms',
          alarmCount: this.alarmList.filter(alarm =>
            alarm.raised &&
            !this.alarmList.some(alarm2 => !alarm2.raised && alarm2.reference === alarm.reference && new Date(alarm2.dateRaised) >= new Date(alarm.dateRaised))).length
        },
        {
          name: 'Alarms Raised',
          alarmCount: this.alarmList.filter(alarm =>
            new Date(alarm.dateRaised) >= new Date().setDate(new Date().getDate() - 7)).length
        }
      ]
    }
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
    serviceStatusFilter: function (statusName) {
      var baseUrl = `${window.location.origin}/site-health`
      switch (statusName ? statusName.toLowerCase() : '') {
        case 'high':
          return baseUrl + '?alarmsFilter=high'
        case 'medium':
          return baseUrl + '?alarmsFilter=medium'
        case 'low':
          return baseUrl + '?alarmsFilter=low'
        default:
          return baseUrl + ''
      }
    }
  }
}
</script>

<style scoped>
  .appSectionContainer {
    min-height: 100%;
  }

  .Red {
    background-color: rgb(239, 83, 80);
  }

  .Amber {
    background-color: rgb(255, 167, 38);
  }

  .Green {
    background-color: rgb(102, 187, 106);
  }

  .Grey {
    background-color: rgb(153, 153, 153);
  }

  .Purple {
    background-color: rgb(167, 80, 239);
  }

</style>
