<template>
  <div class="quotePanel">
    <div class="searchBar">
      <label
        for="cboCreatedBy">Search Text:
      </label>
      <input
        id="txtSearch"
        v-model="searchString"
        type="text"
        @keyup="searchForQuotes()"
        @focus="searchTextFocus()"
      >
      <label
        for="dteDateStart"
        class="labelDateStart"
      >Date Range:</label>
      <PortalDateTime
        id="dteDateStart"
        v-model="searchStartDate"
        @change="searchForQuotes()"
      />
      to&nbsp;&nbsp;
      <PortalDateTime
        id="dteDateTo"
        v-model="searchEndDate"
        @change="searchForQuotes()"
      />
      <label
        for="chkMyQuotes">Show Only My Quotes:
      </label>
      <input
        id="chkMyQuotes"
        v-model.number="justMyQuotes"
        type="checkbox"
        @change="setTable()"
      >
      <label
        v-if="!justMyQuotes"
        for="cboCreatedBy">Creator:
      </label>
      <select
        v-if="!justMyQuotes"
        id="cboCreatedBy"
        v-model="filterCreatedByUserId"
        class="rackUnitsDropdown"
        @change="setTable()"
      >
        <option
          v-for="u in quoteUsersFilter"
          :key="u.id"
          :value="u.id"
          :title="u.name"
        >{{ u.name }}</option>
      </select>
    </div>
    <div class="mdContainer appSectionBody">
      <div
        :class="{mdHalfScreen : !!selectedQuote}"
        class="mdQuotes">
        <div
          :class="{hidden: searchResults.length === 0}">
          <table
            id="quotesTable">
            <thead />
            <tbody>
              <tr
                v-for="v in currentFilter"
                :key="v.id"
                :tabindex="20"
                @click="selectQuote(v)"
                @keyup="testSelectQuote($event, v)"
              >
                <td>{{ v.quoteOpportunityId }}</td>
                <td>{{ v.revisionNumber }}</td>
                <td>{{ v.salesforceQuoteNumber }}</td>
                <td>{{ v.salesforceAccountName }}</td>
                <td>{{ v.quoteDescription }}</td>
                <td>{{ stateTextFromId(v.quoteStateId) }}</td>
                <td>{{ iQuoteStore_DisplayUserNameFromId(v.createdByUserId) }}</td>
                <td>{{ iQuoteStore_DisplayUserNameFromId(v.lastEditedByUserId) }}</td>
                <td>{{ displayDate(v.dateLastEdited) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-if="!!selectedQuote"
        class="mdSelection"
        style="background-color: #fff;"
      >
        <SectionCollapsedHeader
          id="sectionQuoteSummary"
          :start-collapsed="false"
          :enable-click="true"
          title="Quote Details"
          type="subHeader"
          icon-file="Edit"
          controls="quoteSummaryComponent"
        />
        <div
          id="quoteSummaryComponent">
          <quoteSummary
            :loading="loadingQuote"
            :buttons="quoteSummaryButtons"
            @buttonClicked="handleQuoteSummaryButtonClicked"
          />
        </div>
      </div>
    </div>
    <ConfirmationDialogBox
      :dialog="showDuplicateQuoteDialog"
      :dlg-message="`Are you sure want to duplicate this quote?`"
      :dlg-close-from-top="false"
      dlgid="duplicateQuoteConfirmationDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      negative-btn-text="No"
      positive-btn-text="Yes"
      @negativeAction="setDuplicateQuoteDialog"
      @positiveAction="duplicateQuote()"
      @cancelDialog="setDuplicateQuoteDialog"
    />
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import PortalDateTime from '../../common/portalDateTime'
import { mapActions } from 'vuex'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { usersMixin } from '../../../mixins/users'
import tableHandler from '../../../mixins/tableHandler'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteQuoteExpansionsAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteExpansionsAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteLoaderMixin } from '../../../mixins/iQuote/utils/iQuoteLoaderMixin'
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'
import quoteSummary from '../panels/quoteSummary'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import ConfirmationDialogBox from '../../common/confirmationDialogBox'

export default {
  name: 'HomePage',
  components: {
    SectionCollapsedHeader,
    quoteSummary,
    TooltipIcon,
    PortalDateTime,
    ConfirmationDialogBox
  },
  mixins: [ iQuoteQuoteExpansionsAPIMixin, iQuoteQuoteAPIMixin, ShowHelpMixin, tableHandler, iQuoteConstants, iQuoteQuoteTechnologyAPIMixin, iQuoteFunctions, usersMixin, iQuoteStoreMixin, iQuoteLoaderMixin ],
  data () {
    return {
      searchString: 'unpublished',
      searchResults: [],
      filteredSearchResults: [],
      selectedQuote: null,
      services: [],
      searchStartDate: null,
      searchEndDate: null,
      loadingQuote: false,
      justMyQuotes: false,
      filterCreatedByUserId: -1,
      showDuplicateQuoteDialog: false
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    search () {
      return require(`../../../assets/icons/search.png`)
    },
    fakegraph () {
      return require(`../../../assets/icons/fakegraph.png`)
    },
    quoteUsersFilter () {
      var usersStore = this.$store.getters.quoteUsers
      var usersList = []

      usersList.push({ id: -1, name: 'Anyone' })

      for (let index = 0; index < usersStore.length; index++) {
        const user = usersStore[index]
        usersList.push({ id: user.id, name: this.iQuoteStore_DisplayUserNameFromId(user.id) })
      }

      return usersList
    },
    quoteUsers () {
      return this.$store.getters.quoteUsers
    },
    quoteSummaryButtons () {
      var buttons = []

      if (!this.iQuoteStore_GetQuote.locked) {
        buttons.push('Edit Quote')
      }

      buttons.push('Duplicate Quote')

      return buttons
    }
  },
  async mounted () {
    this.searchString = 'Unpublished'
    this.searchStartDate = new Date()
    this.searchStartDate.setDate(this.searchStartDate.getDate() - 30)
    this.searchStartDate.setHours(0, 0, 0, 0)
    this.searchEndDate = new Date()
    this.searchEndDate.setHours(23, 59, 59, 999)
    this.services = this.$store.getters.serviceList

    var searchStringParam = this.$route.params.searchText

    if (searchStringParam !== undefined) {
      this.searchString = searchStringParam

      await this.searchForQuotes()
    } else {
      this.searchForQuotes()
    }
  },
  methods: {
    ...mapActions([
      'setQuote',
      'setCurrentQuoteState',
      'setCurrentQuotePage',
      'setCurrentQuoteSubPage',
      'setQuoteUsers'
    ]),
    handleQuoteSummaryButtonClicked (button) {
      switch (button) {
        case 'Edit Quote':
          this.editQuote()
          break
        case 'Duplicate Quote':
          this.setDuplicateQuoteDialog(true)
          break
      }
    },
    editQuote: async function () {
      this.setCurrentQuotePage('CreateQuote')
      this.setCurrentQuoteState('ChooseOpportunity')
      this.setCurrentQuoteSubPage('QuoteDetails')
    },
    duplicateQuote: async function () {
      var quote = this.iQuoteStore_GetQuote

      // Reset this quote so a new one will be saved
      quote.id = 0 /* Reset the quote so that it's saved to the DB with a new ID */
      quote.quoteOpportunityId = 0 /* Reset the quote so that it's saved to the DB with a new opportunity */
      quote.quoteDescription = quote.quoteDescription + ' 2'
      this.setQuote(quote)

      this.setCurrentQuotePage('CreateQuote')
      this.setCurrentQuoteState('DuplicateQuote')
    },
    setDuplicateQuoteDialog: function (value) {
      this.showDuplicateQuoteDialog = value
    },
    searchTextFocus: function () {
      if (this.searchString === 'Unpublished') {
        this.searchString = ''
        this.searchForQuotes()
      }
    },
    displayDate: function (dateToFormat) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' }

      if (dateToFormat === '0001-01-01T00:00:00') {
        return ''
      } else {
        return new Date(dateToFormat).toLocaleDateString('en-gb', options)
      }
    },
    setTable: function () {
      if (!this.justMyQuotes && this.filterCreatedByUserId === -1) {
        this.filteredSearchResults = this.searchResults
      } else {
        this.filteredSearchResults = []
        var userId

        if (this.justMyQuotes) {
          userId = this.$store.getters.userid
        } else {
          userId = this.filterCreatedByUserId
        }

        for (let index = 0; index < this.searchResults.length; index++) {
          const quote = this.searchResults[index]
          if (quote.createdByUserId === userId) {
            this.filteredSearchResults.push(quote)
          }
        }
      }

      this.initialiseTable('quotesTable', this.filteredSearchResults,
        ['quoteOpportunityId', 'revisionNumber', 'quoteNumber', 'accountName', 'quoteDescription', 'quoteStateId', 'createdByUserId', 'lastEditedByUserId', 'dateLastEdited'],
        ['iQuote Id', 'Revision', 'SF Quote Number', 'Account Name', 'Description', 'State', 'Created By', 'Last Edited By', 'Date Last Edited'],
        null,
        '700px',
        ['70px', '70px', '70px', '140px', '', '100px', '140px', '140px', '110px'],
        20)
    },
    stateTextFromId: function (id) {
      switch (id) {
        case this.iQuoteConstants.quoteStates.UNPUBLISHED:
          return 'Unpublished'
        case this.iQuoteConstants.quoteStates.PUBLISHED:
          return 'Published'
        case this.iQuoteConstants.quoteStates.EXPIRED:
          return 'Expired'
        case this.iQuoteConstants.quoteStates.ORDERED:
          return 'Ordered'
        case this.iQuoteConstants.quoteStates.SUPERSEDED:
          return 'Superseded'
      }
    },
    searchForQuotes: async function () {
      var foundCreatedUser = {}
      var foundLastEditedUser = {}
      var foundNewUsersToAddToStore = false
      var userFromUserService = {}

      if (this.$store.getters.viewiQuotePermission) {
        this.searchEndDate.setHours(23, 59, 59, 999)
        this.searchResults = await this.searchQuotes(this.searchString, this.searchStartDate, this.searchEndDate)

        var users = this.quoteUsers

        // Loop through every search result and find the users contained within the result set
        for (let index = 0; index < this.searchResults.length; index++) {
          const quote = this.searchResults[index]
          foundCreatedUser = {}
          foundLastEditedUser = {}

          // Check the users store for details of users that created or modified the quote
          users.forEach(user => {
            if (user.id === quote.createdByUserId) {
              foundCreatedUser = user
            }
            if (user.id === quote.lastEditedByUserId) {
              foundLastEditedUser = user
            }
          })

          // Created By User wasn't in the store, so query details from the user service and add to the store
          if (foundCreatedUser.id === undefined && quote.createdByUserId !== 0) {
            userFromUserService = await this.getUserById(quote.createdByUserId)

            if (userFromUserService.id !== 0) {
              users.push(userFromUserService)
              foundNewUsersToAddToStore = true
            }
          }

          // Last Edited User wasn't in the store, so query details from the user service and add to the store
          if (foundLastEditedUser.id === undefined && quote.lastEditedByUserId !== quote.createdByUserId && quote.lastEditedByUserId !== 0) {
            userFromUserService = await this.getUserById(quote.lastEditedByUserId)
            if (userFromUserService.id !== 0) {
              users.push(userFromUserService)
              foundNewUsersToAddToStore = true
            }
          }
        }

        if (foundNewUsersToAddToStore) {
          this.setQuoteUsers(users)
        }

        this.setTable()

        // If the search was for a quoteid then display that item panel
        for (let index = 0; index < this.searchResults.length; index++) {
          const quote = this.searchResults[index]
          if (quote.id === Number(this.searchString)) {
            this.selectQuote(quote)
          }
        }

        if (this.searchResults.length === 0) {
          this.selectQuote(null)
        }
      }
    },
    selectQuote: async function (selectedQuote) {
      if (selectedQuote === null) {
        return
      }

      if (this.selectedQuote !== null && this.selectedQuote.id === selectedQuote.id) {
        this.selectedQuote = null
      } else {
        selectedQuote.marginAWS = 20 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        selectedQuote.marginIconGateway = 20 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        selectedQuote.marginInfrastructure = 30 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        selectedQuote.marginGammaSip = 33.3333 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        selectedQuote.marginSipMigration = 30 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        selectedQuote.marginCxNowPS = 50 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        this.loadingQuote = true

        await this.iQuoteLoaderMixin_loadQuoteIntoStore(selectedQuote.id)

        this.selectedQuote = selectedQuote
        this.loadingQuote = false
      }
    },
    testSelectQuote (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectQuote(selectedRow)
      }
    }
  }
}
</script>
<style scoped>
  #txtSearch {
    width:320px;
  }

  .labelDateStart {
    width:120px;
    margin-right: 10px;
    margin-left: 30px;
  }
  .searchBar {
    text-align:left;
    margin-bottom: 20px
  }

  .searchBar label {
    font-weight: bold;
    margin-left: 20px;
    width: auto;
  }

  .searchBar input[type=date] {
    margin-right: 10px;
    border: 1px solid black
  }
  .searchBar input[type=text] {
    margin-right: 10px;
    border: 1px solid black
  }

  .searchBar input[type=checkbox] {
    margin-right: 10px;
    border: 1px solid black
  }
  .searchBar select {
    margin-right: 10px;
    width: 150px;
    border: 1px solid black
  }
  .quotePanel {
    margin: 0px;
    width: auto;
    min-width: 900px;
  }
  .fakegraph {display: block;margin-top:20px;}
  .homePageContainer { text-align: center; background-color:white}

 .appSectionHeader {
    background: white;
    padding: 15px;
  }
  .mdContainer{
    display: flex;
    flex-direction: row;
  }

  .mdSelection{
    width:600px;
    margin: 0 0 0 10px;
  }
  .mdQuotes{
    margin: 0;
    width:100%;
    min-height: calc(100vh - 300px);
  }
  .mdQuotes.mdHalfScreen{
    width:calc(100% - 600px);
    min-height: calc(100vh - 50px);
  }

  #quotesTable {
    text-align:left;
    margin-bottom: 40px;
  }

  #searchTooltip {
    display: inline;
  }

  .hidden {
    display: none
  }

</style>
