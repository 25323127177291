import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteCalculationsDesignPackMixin } from './iQuoteCalculationsDesignPack'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { mapActions } from 'vuex'

export const iQuoteCalculationsDesignPackPSMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin,
    iQuoteFunctions,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackMixin
  ],
  data () {
    return {
      iQuoteCalculationsDesignPackPSMixin: {
      }
    }
  },
  computed: {
    iQuoteCalculationsDesignPackPSMixin_psProfilesWithScheduleCosts () {
      return this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.filter(x => this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfileSchedules.some(item => item.designPackPsRoleProfileId === x.id))
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    async iQuoteCalculationsDesignPackPSMixin_updatePsCoreEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psCoreEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsCoreEntries')
    },
    async iQuoteCalculationsDesignPackPSMixin_updatePsOptionsEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psOptionsEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsOptionsEntries')
    },
    async iQuoteCalculationsDesignPackPSMixin_updatePsExpenseEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psExpenseEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsExpenseEntries')
    },
    async iQuoteCalculationsDesignPackPSMixin_updatePsThirdPartyInstallationEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psThirdPartyInstallationEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsExpenseEntries')
    },
    async iQuoteCalculationsDesignPackPSMixin_updatePsThirdPartyOtherEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psThirdPartyOtherEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsThirdPartyOtherEntries')
    },
    iQuoteCalculationsDesignPackPSMixin_getPsProfileDescription (id) {
      var match = this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.find(x => x.id === id)

      if (match === undefined) {
        return 'None'
      }

      return match.description
    },
    iQuoteCalculationsDesignPackPSMixin_getPsScheduleDescription (id) {
      var match = this.iQuoteStore_GetProfessionalServicesLookups.designPackPSSchedules.find(x => x.id === id)

      if (match === undefined) {
        return 'None'
      }

      return match.description
    }
  }
}
