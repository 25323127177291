// This module is used for state mamangement for dashboard related variables.

const state = {
  // Used in the TicketManagementTile dashboard tile component
  circuitstatus: {},
  ticketstatus: {},
  projectsummary: [],
  topLevelProjectSummary: [],
  topLevelSitesSummary: [],
  opportunities: [],
  isDashboardImageVisible: true
}
const mutations = {
  SET_CIRCUIT_STATUS (state, circuitstatus) {
    state.circuitstatus = circuitstatus
  },
  SET_TICKET_STATUS (state, ticketstatus) {
    state.ticketstatus = ticketstatus
  },
  SET_PROJECT_SUMMARY (state, projectsummary) {
    state.projectsummary = projectsummary
  },
  SET_TOP_LEVEL_PROJECT_SUMMARY (state, topLevelProjectSummary) {
    state.topLevelProjectSummary = topLevelProjectSummary
  },
  SET_TOP_LEVEL_SITES_SUMMARY (state, topLevelSitesSummary) {
    state.topLevelSitesSummary = topLevelSitesSummary
  },
  SET_OPPORTUNITIES (state, opportunities) {
    state.opportunities = opportunities
  },
  SET_DASHBOARD_IMAGE_VISIBILITY (state, visible) {
    state.isDashboardImageVisible = visible
  }
}
const actions = {
  setCircuitStatus: ({ commit }, circuitstatus) => {
    commit('SET_CIRCUIT_STATUS', circuitstatus)
    localStorage.setItem('circuitstatus', circuitstatus)
  },
  setTicketStatus: ({ commit }, ticketstatus) => {
    commit('SET_TICKET_STATUS', ticketstatus)
    localStorage.setItem('ticketstatus', ticketstatus)
  },
  setProjectSummary: ({ commit }, projectsummary) => {
    commit('SET_PROJECT_SUMMARY', projectsummary)
  },
  setTopLevelProjectSummary: ({ commit }, topLevelProjectSummary) => {
    commit('SET_TOP_LEVEL_PROJECT_SUMMARY', topLevelProjectSummary)
  },
  setTopLevelSitesSummary: ({ commit }, topLevelSitesSummary) => {
    commit('SET_TOP_LEVEL_SITES_SUMMARY', topLevelSitesSummary)
  },
  setOpportunities: ({ commit }, opportunities) => {
    commit('SET_OPPORTUNITIES', opportunities)
  },
  setDashboardImageVisibility: ({ commit }, visibility) => {
    commit('SET_DASHBOARD_IMAGE_VISIBILITY', visibility)
  },
  clearDashboard: ({ commit }) => {
    commit('SET_CIRCUIT_STATUS', null)
    commit('SET_TICKET_STATUS', null)
    commit('SET_PROJECT_SUMMARY', null)
    commit('SET_TOP_LEVEL_PROJECT_SUMMARY', null)
    commit('SET_OPPORTUNITIES', null)
    commit('SET_DASHBOARD_IMAGE_VISIBILITY', true)
  }
}

const getters = {
  circuitstatus: state => {
    return state.circuitstatus
  },
  ticketstatus: state => {
    return state.ticketstatus
  },
  projectsummary: state => {
    return state.projectsummary
  },
  topLevelProjectSummary: state => {
    return state.topLevelProjectSummary
  },
  topLevelSitesSummary: state => {
    return state.topLevelSitesSummary
  },
  opportunities: state => {
    return state.opportunities
  },
  isDashboardImageVisible: state => {
    return state.isDashboardImageVisible
  }
}
// This export is used to allow the vuex components to extend ito the global state
export default {
  state,
  mutations,
  actions,
  getters
}
