import portalAPIGateway from '../../communications/interceptors/apiInterceptor'
export const iQuoteQuoteAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
    },
    searchQuotes: async function (searchString, startDate, endDate) {
      var data = []
      var axiosConfig = this.iQuoteQuoteAPIMixin_getAxiosConfig()
      var searchParams = {
        searchString: searchString,
        startDate: startDate,
        endDate: endDate
      }
      try {
        await portalAPIGateway.post('iquotearray/quotes/search/', searchParams, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('searchQuotes error: ' + error)
      }
      return data
    },
    getQuoteById: async function (id) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/quotes/' + id, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteById error: ' + error)
      }
      return data
    },
    iQuoteQuoteAPIMixin_CreateQuoteOpportunity: async function (quote) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.post('iquote/quotes/', quote, axiosConfig).then((response) => {
          data = response.data

          data.marginInfrastructure = 30 /* todo - remove this when we save in the quote */
          data.marginAWS = 20 /* todo - remove this when we save in the quote */
          data.marginIconGateway = 20 /* todo - remove this when we save in the quote */
          data.marginGammaSip = 33.3333 /* todo - remove this when we save in the quote */
          data.marginSipMigration = 30 /* todo - remove this when we save in the quote */
          data.marginCxNowPS = 50 /* todo - remove this when we save in the quote */
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('createQuote error: ' + error)
      }
      return data
    },
    iQuoteQuoteAPIMixin_AddOrUpdateQuoteRevision: async function (quote) {
      if (quote.id === undefined) {
        console.log('ERROR: recieved empty quote object in function iQuoteQuoteAPIMixin_AddOrUpdateQuoteRevision()')
        return {}
      }

      var data = {}
      var axiosConfig = this.iQuoteQuoteAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.put('iquote/quotes/', quote, axiosConfig).then((response) => {
          data = response.data

          data.marginInfrastructure = 30 /* todo - remove this when we save in the quote */
          data.marginAWS = 20 /* todo - remove this when we save in the quote */
          data.marginIconGateway = 20 /* todo - remove this when we save in the quote */
          data.marginGammaSip = 33.3333 /* todo - remove this when we save in the quote */
          data.marginSipMigration = 30 /* todo - remove this when we save in the quote */
          data.marginCxNowPS = 50 /* todo - remove this when we save in the quote */
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('updateQuote error: ' + error)
      }
      return data
    },
    createOpportunity: async function (opportunity) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.post('iquote/opportunities/', opportunity, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('createOpportunity error: ' + error)
      }
      return data
    },
    updateOpportunity: async function (opportunity) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.put('iquote/opportunities/', opportunity, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('updateOpportunity error: ' + error)
      }
      return data
    },
    iQuoteQuoteAPIMixin_getOpportunityById: async function (id) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/opportunities/id/' + id, null, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getOpportunity error: ' + error)
      }
      return data
    },
    getTechnologyUserProfiles: async function (technologyId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/technologyUserProfiles/technology/', technologyId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getTechnologyUserProfiles error: ' + error)
      }
      return data
    }
  }
}
