<template>
  <div class="quoteSiteSummaryContainer">
    <Spinner
      v-if="loading"
    />
    <table
      v-show="!loading && opportunitiesData.length > 0"
      id="opportunitiesSiteSummaryTable"
      class="dashboardTable"
    >
      <thead />
      <tbody
        v-show="!loading && opportunitiesData.length > 0"
      >
        <tr
          v-for="v in currentFilter"
          :key="v.opportunityID"
          :tabindex="20"
          :class="statusClass(v.stageName)"
          @click="drilldownOpportunity(v)"
        >
          <td :title="v.accountName">{{ v.accountName }}</td>
          <td :title="v.opportunityName">{{ v.opportunityName }}</td>
          <td :title="v.createdDate ? new Date(v.createdDate).toDateString() : 'N/A'">
            {{ v.createdDate ? new Date(v.createdDate).toDateString() : 'N/A' }}
          </td>
          <td
            :title="v.closeDate ? new Date(v.closeDate).toDateString() : 'N/A'"
          >
            {{ v.closeDate ? new Date(v.closeDate).toDateString() : 'N/A' }}
          </td>
          <td :title="v.stageName">{{ v.stageName }}</td>
        </tr>
      </tbody>
    </table>
    <p
      v-if="opportunitiesData.length === 0 && !loading"
      class="noResults"
    >
      No quote data available.
    </p>
  </div>
</template>

<script>

import tableHandler from '../../mixins/tableHandler'
import Spinner from '../common/spinner'
import { iQuoteSalesforceAPIMixin } from '../../mixins/iQuote/iQuoteSalesforceAPIMixin'
import { DataEventBus } from '../../events/dataEvents'

export default {
  name: 'OpportunitiesSiteSummary',
  components: {
    Spinner
  },
  mixins: [
    tableHandler,
    iQuoteSalesforceAPIMixin
  ],
  props: {
    displayClosedQuotes: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      opportunitiesData: []
    }
  },
  computed: {
    customerFlags () {
      return this.$store.getters.customerFlags
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    displayClosedQuotes () {
      this.getOpportunitiesData()
    }
  },
  mounted () {
    if (this.opportunitiesData.length === 0) {
      this.loading = true
    }

    this.getOpportunitiesData()
    DataEventBus.$on('refresh-data', () => {
      this.loading = true
      this.getOpportunitiesData()
    })
    DataEventBus.$on('company-changed', () => {
      this.loading = true
    })
    DataEventBus.$on('updateOpportunities', () => {
      this.getOpportunitiesData()
      this.loading = false
    })
  },
  created () {
  },
  methods: {
    getOpportunitiesData () {
      this.loading = true
      this.opportunitiesData = this.$store.getters.opportunities.filter(({ closeDate }) => this.displayClosedQuotes ? true : new Date(closeDate) >= new Date())
      if (this.opportunitiesData.length > 0) {
        this.displaySiteOpportunities()
      }

      this.loading = false
    },
    displaySiteOpportunities: function () {
      this.selected = -1
      if (this.opportunitiesData.length > 0) {
        this.$nextTick(() => {
          this.initialiseTable('opportunitiesSiteSummaryTable', this.opportunitiesData,
            ['accountName', 'opportunityName', 'createdDate', 'closeDate', 'stageName'],
            ['Account', 'Name', 'Created Date', 'Closed Date', 'Status'],
            ['accountName', 'opportunityName', 'createdDate', 'closeDate', 'stageName'],
            null,
            ['20%', '20%', '20%', '20%', '20%'])
        })
      }
    },
    drilldownOpportunity: function ({ opportunityID }) {
      var targetOpportunity = this.opportunitiesData.find(o => o.opportunityID === opportunityID)
      this.$emit('opportunityDrillDown', {
        opportunity: targetOpportunity
      })
    },
    getStatus: function (opportunity) {
      var shouldDisplayQuoteProvidedStatus = this.customerFlags && this.customerFlags.some(o => o.flagName === 'QuoteCompleteStatusDisplay' && o.flagValue && o.flagValue.toLowerCase() === 'yes')
      var hasQuoteProvidedStatus = opportunity.stageName && opportunity.stageName.toLowerCase() === 'quote complete'
      var calculatedStatus = shouldDisplayQuoteProvidedStatus && hasQuoteProvidedStatus
        ? 'Quote Complete'
        : 'Open'

      return opportunity.closeDate
        ? (new Date(opportunity.closeDate) <= new Date()
          ? 'Closed'
          : calculatedStatus)
        : calculatedStatus
    },
    statusClass (status) {
      var rtn = ''
      if (!this.useAltColour) {
        rtn = 'Solid'
      }
      switch (status.toLowerCase()) {
        case 'pricing provided':
          rtn += 'Blue'
          break
        default:
          rtn += 'Amber'
          break
      }
      return rtn
    }
  }
}
</script>

<style scoped>
  .quoteSiteSummaryContainer {
    max-height: 400px;
    padding:10px;
  }

  .dashboardTable td {
    display:inline-block;
    width:19%;
  }
</style>
