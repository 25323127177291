import webAPI from '../communications/interceptors/apiInterceptor'
export const highlightMixin = {
  data () {
    return {
    }
  },
  methods: {
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        }
      }
    },
    getEncryptedToken: async function (partnerFolderName, userEmailAddress) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        var data = {
          partner: partnerFolderName,
          uid: userEmailAddress
        }
        await webAPI.post('/HighlightSSO/authenticate', data, axiosConfig).then((response) => {
          var responseParts = response.data.split('token=')
          rtn = responseParts[responseParts.length - 1]
        })
      } catch (_) {
        console.log('Error calling getEncryptedToken')
      }
      return rtn
    },
    decryptToken: async function (token) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        var data = {
          token
        }
        await webAPI.post('/HighlightSSO/decrypt', data, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (_) {
        // eslint-disable-next-line
        console.log('Error calling DecryptToken')
      }
      return rtn
    },
    getCookie: async function (partnerFolderName, userEmailAddress, token) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        var data = {
          partner: partnerFolderName,
          uid: userEmailAddress,
          token
        }
        await webAPI.post('/HighlightSSO/cookie', data, axiosConfig).then((response) => {
          var responseParts = response.data.split('cookie=')
          rtn = responseParts[responseParts.length - 1]
        })
      } catch (_) {
        // eslint-disable-next-line
        console.log('Error calling getCookie')
      }
      return rtn
    }
  }
}
