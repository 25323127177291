<template>
  <div
    id="manageDefaultCompanies"
  >
    <input
      v-model="searchTerm"
      type="text"
      class="searchBox"
      placeholder="Search..."
    >
    <div
      id="defaultCompany"
      class="dcmSectionContainer"
    >
      <div
        v-for="({ id, name }) in companies"
        :key="id"
        :class="customers.find(o => o.companyId === id).isDefault ? 'default' : ''"
      >
        <input
          :value="id"
          :checked="customers.find(o => o.companyId === id).isDefault"
          type="radio"
          name="company"
          @change="updateDefault"
        > {{ name }}
      </div>
    </div>
  </div>
</template>
<script>

import { permissionsMixin } from '../../mixins/permissions'

export default {
  name: 'ManageCustomers',
  components: {
  },
  mixins: [
    permissionsMixin
  ],
  props: {
  },
  data: () => {
    return {
      searchTerm: ''
    }
  },
  computed: {
    customers () {
      return this.$store.getters.customers
    },
    companies () {
      return this.$store.getters.companies.filter(o => o.name.toLowerCase().includes(this.searchTerm ? this.searchTerm.toLowerCase() : o.name.toLowerCase()))
    },
    userid () {
      return this.$store.getters.userid
    }
  },
  methods: {
    updateDefault: async function ({ srcElement: { value } }) {
      var defaultCustomer = this.customers.find(o => o.isDefault)
      if (defaultCustomer) {
        // remove the old default
        await this.createUserCustomer({ entityId: this.userid, customerId: defaultCustomer.id, isDefault: false })
      }
      // update the new record with it set to default
      await this.createUserCustomer({ entityId: this.userid, customerId: this.customers.find(o => o.companyId === parseInt(value)).id, isDefault: true })
      await this.getCustomers()
      this.$store.commit('showMessage', { content: 'Your default company has been changed.', color: 'green', timeout: 3000 })
    }
  }
}
</script>
<style scoped>
  .searchBox {
    width: 100%;
    padding:2px;
  }
  .dcmSectionContainer {
    max-height: 150px;
    overflow-y: auto;
    padding:5px;
  }
</style>
