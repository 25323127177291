import { render, staticRenderFns } from "./sipGammaService.vue?vue&type=template&id=5cf03ea4&scoped=true&"
import script from "./sipGammaService.vue?vue&type=script&lang=js&"
export * from "./sipGammaService.vue?vue&type=script&lang=js&"
import style0 from "./sipGammaService.vue?vue&type=style&index=0&id=5cf03ea4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf03ea4",
  null
  
)

export default component.exports