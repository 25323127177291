import portalAPIGateway from '../../communications/interceptors/apiInterceptor'

export const iQuoteAdjunctDataAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteAdjunctDataAPIMixin_getAxiosConfig () {
      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userId,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
    },
    getAACCAdjuncts: async function (quoteId) {
      var data = []
      var axiosConfig = this.iQuoteAdjunctDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/avayaaacc/quote/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getAACCAdjuncts returned error: ' + error)
      }
      return data
    },
    getEliteAdjuncts: async function (quoteId) {
      var data = []
      var axiosConfig = this.iQuoteAdjunctDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/avayaelite/quote/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getEliteAdjuncts returned error: ' + error)
      }
      return data
    },
    getAuraAdjuncts: async function (quoteId) {
      var data = []
      var axiosConfig = this.iQuoteAdjunctDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/avayaaura/quote/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getAuraAdjuncts returned error: ' + error)
      }
      return data
    },
    getCallmediaAdjuncts: async function (quoteId) {
      var data = []
      var axiosConfig = this.iQuoteAdjunctDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/callmedia/quote/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getCallmediaAdjuncts returned error: ' + error)
      }
      return data
    },
    getUserProfileCallmedia: async function (quoteId) {
      var data = []
      var axiosConfig = this.iQuoteAdjunctDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/userprofile/callmedia/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getUserProfileCallmedia returned error: ' + error)
      }
      return data
    }
  }
}
