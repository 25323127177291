export const ShowHelpMixin = {
  data () {
    return {
    }
  },
  methods: {
    showHelp (category) {
      alert('This will show the help for ' + category)
    }
  }
}
