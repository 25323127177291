// This module is used for state mamangement for iQuote related variables
const state = {
  termLengths: [],
  resiliences: [],
  applicationLicenseTypes: [],
  serviceLevels: [],
  wfooptions: [],
  codecs: [],
  serviceList: [],
  serviceCategories: [],
  oohPortings: [],
  sipAccess: [],
  sipAccessTypes: [],
  virtualMachines: [],
  bladeServers: [],
  licenses: [],
  unitCosts: [],
  userProfileLicenses: [],
  cxNowPlatformSizes: [],
  packageVirtualMachines: [],
  expansionVmCategories: [],
  expansionVmSubCategories: [],
  virtualMachineExpansionCategoryLinks: [],
  designPackTableSections: [],
  userProfileCategories: [],
  technologyUserProfiles: [],
  quoteTechnologyUserProfilesApprovedRCPrice: [],
  technologyHardware: [],
  cxNowApps: [],
  cxNowConnectors: [],
  salesforceCoeCodes: [],
  circuitsTableLookups: {
    hostingAndCablingCarriers: [],
    hostingAndCablingProducts: [],
    broadbandCarriers: [],
    fixedLineVoiceCarriers: [],
    broadbandProducts: [],
    fixedLineVoiceProducts: []
  },
  hardwareTableLookups: {
    miscEquipmentTypes: [],
    miscEquipmentSubTypes: []
  },
  avayaAXPSocialMediaPackages: [],
  professionalServicesLookups: {
    designPackPSRoleProfiles: [],
    designPackPSSchedules: [],
    designPackPSRoleProfileSchedules: []
  },
  ucAnalyticsQuoteTypes: []
}

const mutations = {
  SET_SERVICE_LIST (state, serviceList) {
    state.serviceList = serviceList
  },
  SET_TERM_LENGTHS (state, termLengths) {
    state.termLengths = termLengths
  },
  SET_CXNOW_PLATFORMSIZES (state, cxNowPlatformSizes) {
    state.cxNowPlatformSizes = cxNowPlatformSizes
  },
  SET_VIRTUAL_MACHINES (state, virtualMachines) {
    state.virtualMachines = virtualMachines
  },
  SET_BLADE_SERVERS (state, bladeServers) {
    state.bladeServers = bladeServers
  },
  SET_LICENSES (state, licenses) {
    state.licenses = licenses
  },
  SET_SERVICE_CATEGORIES (state, serviceCategories) {
    state.serviceCategories = serviceCategories
  },
  SET_UNIT_COSTS (state, unitCosts) {
    state.unitCosts = unitCosts
  },
  SET_RESILIENCES (state, resiliences) {
    state.resiliences = resiliences
  },
  SET_APPLICATION_LICENSE_TYPES (state, applicationLicenseTypes) {
    state.applicationLicenseTypes = applicationLicenseTypes
  },
  SET_SERVICE_LEVELS (state, serviceLevels) {
    state.serviceLevels = serviceLevels
  },
  SET_WFO_OPTIONS (state, wfooptions) {
    state.wfooptions = wfooptions
  },
  SET_CODECS (state, codecs) {
    state.codecs = codecs
  },
  SET_EXPANSION_VM_CATEGORIES (state, expansionVmCategories) {
    state.expansionVmCategories = expansionVmCategories
  },
  SET_EXPANSION_VM_SUB_CATEGORIES (state, expansionVmSubCategories) {
    state.expansionVmSubCategories = expansionVmSubCategories
  },
  SET_VIRTUAL_MACHINE_EXPANSION_CATEGORY_LINKS (state, virtualMachineExpansionCategoryLinks) {
    state.virtualMachineExpansionCategoryLinks = virtualMachineExpansionCategoryLinks
  },
  SET_OOH_PORTINGS (state, oohPortings) {
    state.oohPortings = oohPortings
  },
  SET_SIP_ACCESS (state, sipAccess) {
    state.sipAccess = sipAccess
  },
  SET_SIP_ACCESS_TYPES (state, sipAccessTypes) {
    state.sipAccessTypes = sipAccessTypes
  },
  SET_USERPROFILE_LICENSES (state, userProfileLicenses) {
    state.userProfileLicenses = userProfileLicenses
  },
  SET_USERPROFILE_CATEGORIES (state, userProfileCategories) {
    state.userProfileCategories = userProfileCategories
  },
  SET_PACKAGE_VIRTUALMACHINES (state, packageVirtualMachines) {
    state.packageVirtualMachines = packageVirtualMachines
  },
  SET_DESIGNPACK_TABLE_SECTIONS (state, designPackTableSections) {
    state.designPackTableSections = designPackTableSections
  },
  SET_TECHNOLOGY_USER_PROFILES (state, technologyUserProfiles) {
    state.technologyUserProfiles = technologyUserProfiles
  },
  SET_QUOTE_TECHNOLOGY_USER_PROFILES_APPROVED_RC_PRICE (state, quoteTechnologyUserProfilesApprovedRCPrice) {
    state.quoteTechnologyUserProfilesApprovedRCPrice = quoteTechnologyUserProfilesApprovedRCPrice
  },
  SET_TECHNOLOGY_HARDWARE (state, technologyHardware) {
    state.technologyHardware = technologyHardware
  },
  SET_CXNOW_APPS (state, cxNowApps) {
    state.cxNowApps = cxNowApps
  },
  SET_CXNOW_CONNECTORS (state, cxNowConnectors) {
    state.cxNowConnectors = cxNowConnectors
  },
  SET_SALESFORCE_COE_CODES (state, salesforceCoeCodes) {
    state.salesforceCoeCodes = salesforceCoeCodes
  },
  SET_CIRCUITS_TABLE_LOOKUPS (state, circuitsTableLookups) {
    state.circuitsTableLookups = circuitsTableLookups
  },
  SET_HARDWARE_TABLE_LOOKUPS (state, hardwareTableLookups) {
    state.hardwareTableLookups = hardwareTableLookups
  },
  SET_AVAYA_AXP_SOCIAL_MEDIA_PACKAGES (state, avayaAXPSocialMediaPackages) {
    state.avayaAXPSocialMediaPackages = avayaAXPSocialMediaPackages
  },
  SET_PROFESSIONAL_SERVICES_LOOKUPS (state, professionalServicesLookups) {
    state.professionalServicesLookups = professionalServicesLookups
  },
  SET_UC_ANALYTICS_QUOTE_TYPES (state, ucAnalyticsQuoteTypes) {
    state.ucAnalyticsQuoteTypes = ucAnalyticsQuoteTypes
  }
}

const actions = {
  setServiceList: ({ commit }, serviceList) => {
    commit('SET_SERVICE_LIST', serviceList)
  },
  setTermLengths: ({ commit }, termLengths) => {
    commit('SET_TERM_LENGTHS', termLengths)
  },
  setVirtualMachines: ({ commit }, virtualMachines) => {
    commit('SET_VIRTUAL_MACHINES', virtualMachines)
  },
  setBladeServers: ({ commit }, bladeServers) => {
    commit('SET_BLADE_SERVERS', bladeServers)
  },
  setLicenses: ({ commit }, licenses) => {
    commit('SET_LICENSES', licenses)
  },
  setServiceCategories: ({ commit }, serviceCategories) => {
    commit('SET_SERVICE_CATEGORIES', serviceCategories)
  },
  setUnitCosts: ({ commit }, unitCosts) => {
    commit('SET_UNIT_COSTS', unitCosts)
  },
  setResiliences: ({ commit }, resiliences) => {
    commit('SET_RESILIENCES', resiliences)
  },
  setApplicationLicenseTypes: ({ commit }, applicationLicenseTypes) => {
    commit('SET_APPLICATION_LICENSE_TYPES', applicationLicenseTypes)
  },
  setServiceLevels: ({ commit }, serviceLevels) => {
    commit('SET_SERVICE_LEVELS', serviceLevels)
  },
  setWFOOptions: ({ commit }, wfooptions) => {
    commit('SET_WFO_OPTIONS', wfooptions)
  },
  setCodecs: ({ commit }, codecs) => {
    commit('SET_CODECS', codecs)
  },
  setExpansionVmCategories: ({ commit }, expansionVmCategories) => {
    commit('SET_EXPANSION_VM_CATEGORIES', expansionVmCategories)
  },
  setExpansionVmSubCategories: ({ commit }, expansionVmSubCategories) => {
    commit('SET_EXPANSION_VM_SUB_CATEGORIES', expansionVmSubCategories)
  },
  setVirtualMachineExpansionCategoryLinks: ({ commit }, virtualMachineExpansionCategoryLinks) => {
    commit('SET_VIRTUAL_MACHINE_EXPANSION_CATEGORY_LINKS', virtualMachineExpansionCategoryLinks)
  },
  setOOHPortings: ({ commit }, oohPortings) => {
    commit('SET_OOH_PORTINGS', oohPortings)
  },
  setSipAccess: ({ commit }, sipAccess) => {
    commit('SET_SIP_ACCESS', sipAccess)
  },
  setSipAccessTypes: ({ commit }, sipAccessTypes) => {
    commit('SET_SIP_ACCESS_TYPES', sipAccessTypes)
  },
  setUserProfileLicenses: ({ commit }, userProfileLicenses) => {
    commit('SET_USERPROFILE_LICENSES', userProfileLicenses)
  },
  setUserProfileCategories: ({ commit }, userProfileCategories) => {
    commit('SET_USERPROFILE_CATEGORIES', userProfileCategories)
  },
  setCXNowPlatformSizes: ({ commit }, cxNowPlatformSizes) => {
    commit('SET_CXNOW_PLATFORMSIZES', cxNowPlatformSizes)
  },
  setPackageVirtualMachines: ({ commit }, packageVirtualMachines) => {
    commit('SET_PACKAGE_VIRTUALMACHINES', packageVirtualMachines)
  },
  setDesignPackTableSections: ({ commit }, designPackTableSections) => {
    commit('SET_DESIGNPACK_TABLE_SECTIONS', designPackTableSections)
  },
  setTechnologyUserProfiles: ({ commit }, technologyUserProfiles) => {
    commit('SET_TECHNOLOGY_USER_PROFILES', technologyUserProfiles)
  },
  setQuoteTechnologyUserProfilesApprovedRCPrice: ({ commit }, quoteTechnologyUserProfilesApprovedRCPrice) => {
    commit('SET_QUOTE_TECHNOLOGY_USER_PROFILES_APPROVED_RC_PRICE', quoteTechnologyUserProfilesApprovedRCPrice)
  },
  setTechnologyHardware: ({ commit }, technologyHardware) => {
    commit('SET_TECHNOLOGY_HARDWARE', technologyHardware)
  },
  setCxNowApps: ({ commit }, cxNowApps) => {
    commit('SET_CXNOW_APPS', cxNowApps)
  },
  setCxNowConnectors: ({ commit }, cxNowConnectors) => {
    commit('SET_CXNOW_CONNECTORS', cxNowConnectors)
  },
  setSalesforceCoeCodes: ({ commit }, salesforceCoeCodes) => {
    commit('SET_SALESFORCE_COE_CODES', salesforceCoeCodes)
  },
  setCircuitsTableLookups: ({ commit }, circuitsTableLookups) => {
    commit('SET_CIRCUITS_TABLE_LOOKUPS', circuitsTableLookups)
  },
  setHardwareTableLookups: ({ commit }, hardwareTableLookups) => {
    commit('SET_HARDWARE_TABLE_LOOKUPS', hardwareTableLookups)
  },
  setAvayaAXPSocialMediaPackages: ({ commit }, avayaAXPSocialMediaPackages) => {
    commit('SET_AVAYA_AXP_SOCIAL_MEDIA_PACKAGES', avayaAXPSocialMediaPackages)
  },
  setProfessionalServicesLookups: ({ commit }, professionalServicesLookups) => {
    commit('SET_PROFESSIONAL_SERVICES_LOOKUPS', professionalServicesLookups)
  },
  setUCAnalyticsQuoteTypes: ({ commit }, ucAnalyticsQuoteTypes) => {
    commit('SET_UC_ANALYTICS_QUOTE_TYPES', ucAnalyticsQuoteTypes)
  }
}

const getters = {
  serviceList: state => {
    return state.serviceList
  },
  termLengths: state => {
    return state.termLengths
  },
  resiliences: state => {
    return state.resiliences
  },
  applicationLicenseTypes: state => {
    return state.applicationLicenseTypes
  },
  serviceLevels: state => {
    return state.serviceLevels
  },
  wfooptions: state => {
    return state.wfooptions
  },
  codecs: state => {
    return state.codecs
  },
  expansionVmCategories: state => {
    return state.expansionVmCategories
  },
  expansionVmSubCategories: state => {
    return state.expansionVmSubCategories
  },
  virtualMachineExpansionCategoryLinks: state => {
    return state.virtualMachineExpansionCategoryLinks
  },
  oohPortings: state => {
    return state.oohPortings
  },
  sipAccess: state => {
    return state.sipAccess
  },
  sipAccessTypes: state => {
    return state.sipAccessTypes
  },
  virtualMachines: state => {
    return state.virtualMachines
  },
  bladeServers: state => {
    return state.bladeServers
  },
  licenses: state => {
    return state.licenses
  },
  serviceCategories: state => {
    return state.serviceCategories
  },
  unitCosts: state => {
    return state.unitCosts
  },
  userProfileLicenses: state => {
    return state.userProfileLicenses
  },
  userProfileCategories: state => {
    return state.userProfileCategories
  },
  cxNowPlatformSizes: state => {
    return state.cxNowPlatformSizes
  },
  packageVirtualMachines: state => {
    return state.packageVirtualMachines
  },
  designPackTableSections: state => {
    return state.designPackTableSections
  },
  technologyHardware: state => {
    return state.technologyHardware
  },
  technologyUserProfiles: state => {
    return state.technologyUserProfiles
  },
  cxNowConnectors: state => {
    return state.cxNowConnectors
  },
  cxNowApps: state => {
    return state.cxNowApps
  },
  salesforceCoeCodes: state => {
    return state.salesforceCoeCodes
  },
  circuitsTableLookups: state => {
    return state.circuitsTableLookups
  },
  hardwareTableLookups: state => {
    return state.hardwareTableLookups
  },
  avayaAXPSocialMediaPackages: state => {
    return state.avayaAXPSocialMediaPackages
  },
  professionalServicesLookups: state => {
    return state.professionalServicesLookups
  },
  ucAnalyticsQuoteTypes: state => {
    return state.ucAnalyticsQuoteTypes
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
