<template>
  <div>
    <Spinner v-if="isLoading" />
    <router-link
      v-ripple
      v-if="!isLoading"
      class="toolbar-items"
      to="tickets">
      <material-stats-card
        v-if="!isLoading"
        :value="completedtickets"
        color="green"
        icon="mdi-check-outline"
        title="Completed Tickets"
        sub-icon="mdi-calendar"
        sub-text="in the last 7 days"
      />
    </router-link>
  </div>
</template>

<script>
import { DataEventBus } from '../../../events/dataEvents'
import Spinner from '../../common/spinner'

export default {
  name: 'CompletedTicketsTile',
  components: {
    Spinner
  },
  data () {
    return {
      completedtickets: '0',
      isLoading: false
    }
  },
  mounted () {
    DataEventBus.$on('load-dashboard', (state) => {
      this.populateCompletedTickets()
    })
    DataEventBus.$on('tickets-loaded', () => {
      this.populateCompletedTickets()
    })
    this.populateCompletedTickets()
  },
  methods: {
    populateCompletedTickets () {
      this.isLoading = true
      if (this.$store.getters.tickets) {
        this.completedtickets = this.$store.getters.tickets.filter(t => t.statusId === 5).length.toString()
      }
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
  .v-card__text { padding-bottom: 6px;}
</style>
