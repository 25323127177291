<template>
  <div>
    <Spinner v-if="saving" />
    <div v-if="!saving">
      <div class="attachFileContainer">
        <label
          for="txtFileUploadTitle"
          class="uploadImageLabel"
        >Title:</label>
        <input
          id="txtFileUploadTitle"
          :class="[fileNameError ? 'uploadFileTextboxError' : 'uploadFileTextbox']"
          v-model="fileName"
          type="text"
          @blur="checkFileName"
        >
        <br>
        <UploadFile
          type="all/*"
          @upload="uploadFile"/>
      </div>
      <div class="viewFilesContainer">
        <v-card class="cardUploadedFiles">
          <v-toolbar
            color="#274a5a"
            dark
          >
            <v-icon>mdi-clipboard-text</v-icon>
            <v-toolbar-title><span style="color: white;">Uploaded Files</span></v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
          <v-list
            two-line
            subheader
          >
            <div
              v-if="files.length<=0"
              class="noResults">
              <h3>There are no files to upload.</h3>
            </div>
            <template
              v-for="(file, index) in files"
              v-else
            >
              <v-list-tile
                :key="file.name"
                avatar
              >
                <v-list-tile-avatar>
                  <v-icon :class="[file.iconClass]">{{ file.icon }}</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title><span>{{ `${file.title? file.title + ' - ' : ''}${file.name} - (${file.size})` }}</span></v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn
                    icon
                    ripple
                    class="icon"
                    @click="deleteFile(file.id)"
                  >
                    <v-icon class="icon">mdi-delete</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider
                v-if="files.length > index+1"
                :key="index"
                :inset="true"
              />
            </template>
          </v-list>
        </v-card>
      </div>
      <div
        v-if="ticket"
      >
        <div>
          <br>
          <label
            for="optNotifyResources"
            class="ticketNoteOptionLabel"
          >Notify Resources on this Ticket:</label>
          <TriStateToggleSwitch
            id="optNotifyResources"
            :enabled="true"
            :tri-state="false"
            :current-state="2"
            class="toggleSwitch"
            @statechanged="({ selectedState }) => {updateNotifyResources(selectedState)}"
          />
        </div>
        <br>
        <label
          for="txtUpdateRecipients"
          class="ticketNoteTextareaLabel"
        >Other Recipients (emails separated by semicolons)</label>
        <textarea
          id="txtUpdateRecipients"
          v-model="recipients"
          :class="ticketRecipientsEmailClass"
          style="height:30px"
          class="ticketRecipients"
          @blur="checkOtherRecipients"
        />
      </div>
    </div>
    <div
      v-if="showAddButton"
      class="buttonContainer"
    >
      <input
        id="addAttachmentButton"
        :class="buttonClass"
        type="button"
        title="Add Attachments"
        value="Add Attachments"
        @click="addTicketAttachments"
      >
    </div>
  </div>
</template>

<script>
import TriStateToggleSwitch from '../../components/common/triStateToggleSwitch'
import UploadFile from '../../components/common/uploadFile'
import Spinner from '../common/spinner'
import { autotaskAPIMixin } from '../../mixins/autotaskAPIMixin'
import { ticketMixin } from '../../mixins/ticket'
export default {
  name: 'AttachFiles',
  components: {
    UploadFile,
    TriStateToggleSwitch,
    Spinner
  },
  mixins: [
    autotaskAPIMixin,
    ticketMixin
  ],
  props: {
    ticket: {
      type: Object,
      default: null
    },
    ticketId: {
      type: Number,
      default: null
    },
    siteId: {
      type: Number,
      default: null
    },
    showAddButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileName: '',
      maxFileSize: 7000,
      files: [],
      fileNameError: false,
      notifyResources: false,
      recipientEmailError: false,
      recipients: '',
      saving: false
    }
  },
  computed: {
    formOK () {
      return !this.recipientEmailError && !this.fileNameError && this.files.length > 0
    },
    buttonClass () {
      if (this.formOK) {
        return 'buttonEnabled'
      } else {
        return 'buttonDisabled'
      }
    },
    fileNameClass () {
      if (this.fileNameError) {
        return 'uploadFileTextboxError'
      } else {
        return 'uploadFileTextbox'
      }
    },
    ticketRecipientsEmailClass () {
      var rtn = 'tickeRecipientsTextarea'
      if (this.recipientEmailError) {
        rtn = 'tickeRecipientsTextareaError'
      }
      return rtn
    }
  },
  methods: {
    async addTicketAttachments () {
      if (this.formOK) {
        this.saving = true
        var contactId = 0
        var site = this.$store.getters.sites.filter(s => s.id === this.siteId)
        console.log('Found site: ' + site.id)
        if (site.length > 0) {
          contactId = await this.getSiteContact(site[0])
        }
        if (contactId === 0) {
          contactId = await this.setNewContact(this.siteId)
        }
        if (contactId > 0) {
          for (let i = 0; i < this.files.length; i++) {
            var data = this.files[i].file
            var title = this.files[i].title
            if (title) {
              title = title.replace(RegExp('\'', 'g'), '`')
            }
            if (title.length === 0) { title = this.files[i].name }
            if (data.indexOf(',') > -1) {
              data = data.substring(data.indexOf(',') + 1, data.length)
            }
            var payload = {
              name: title,
              fileName: this.files[i].name,
              data: data,
              contactId: contactId,
              isNew: false
            }
            var rtn = await this.addTicketAttachment(this.ticketId, payload)
            if (rtn === 'error') {
              this.$store.commit('showMessage', { content: 'The ticket attachments failed to add correctly.', color: 'red', timeout: 3000 })
            } else {
              this.$store.commit('showMessage', { content: 'The ticket attachments added correctly.', color: 'green', timeout: 3000 })
              this.fileName = ''
              var addresses = ''
              this.$emit('attachment-added')
              if (this.notifyResources && this.ticket.assignedResourceId && this.ticket.assignedResourceId > 0) {
                var resource = await this.getResourceById(this.ticket.assignedResourceId)
                addresses = resource.email
              }
              if (this.recipients && this.recipients.length > 0) {
                if (addresses.length > 0) { addresses += ';' }
                addresses += this.recipients
                this.recipients = ''
              }
              if (addresses.length > 0) {
                await this.sendNotification(addresses)
                this.$store.commit('showMessage', { content: 'A notification of the new attachments was sent successfully.', color: 'green', timeout: 3000 })
              }
            }
          }
          this.saving = false
          this.files = []
        } else {
          console.log('ERROR - could not resolve or set a contact for the creation of the attachment.')
        }
      }
    },
    async uploadFile (event) {
      const file = event.target.files[0]
      const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      // divide by 1000 to get MB
      const fileSize = parseInt(file.size) / 1024
      if (!(fileSize > 0 && fileSize <= this.maxFileSize)) {
        alert('you exceed the maximum file size of 7Mb')
        return
      }

      let icon = ''
      let color = ''
      if (fileExtension === 'pdf') {
        icon = 'mdi-file-pdf'
        color = 'red'
      } else if (['png', 'jpg', 'jif', 'jfif', 'bmp'].includes(fileExtension)) {
        icon = 'mdi-image'
        color = 'orange'
      } else {
        icon = 'mdi-clipboard-text'
        color = 'blue'
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.files.push({
          id: this.files.length + 1,
          name: file.name,
          title: this.fileName,
          icon: icon,
          iconClass: `${color} lighten-1 white--text`,
          file: e.target.result,
          size: `${fileSize.toFixed(2)} KB`
        })
        this.fileName = ''
        this.$emit('updated', this.files)
      }
    },
    deleteFile (id) {
      if (this.files.length > 0) {
        const tempFiles = this.files.filter(f => f.id !== id)
        this.files = tempFiles
      }
    },
    checkFileName () {
      this.fileNameError = false
      if (this.fileName.length === 0 && this.files.length === 0) {
        this.$store.commit('showMessage', { content: 'Please enter a title for the attachment upload.', color: 'red', timeout: 3000 })
        this.fileNameError = true
      }
    },
    updateNotifyResources (state) {
      this.notifyResources = (state === 1)
    },
    sendNotification: async function (addresses) {
      var title = 'New Files Attached To Ticket:'
      var body = 'Files added :<br/>'
      for (let i = 0; i < this.files.length; i++) {
        body += this.files[i].name + '<br/>'
      }
      await this.sendResourceNotification(addresses, title, body, this.ticket)
    },
    checkOtherRecipients () {
      var isOk = true
      this.recipients = this.recipients.replace(' ', '')
      var fields = this.recipients.split(';')
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].length > 0) {
          if (!this.validateEmail(fields[i])) {
            isOk = false
          }
        }
      }
      this.recipientEmailError = !isOk
      if (!isOk) {
        this.$store.commit('showMessage', { content: 'One or more "Other Recipients" email addresses are not valid', color: 'red', timeout: 3000 })
      } else {
        this.$emit('recipients-change', this.recipients)
      }
    },
    validateEmail (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }
  }
}
</script>

<style scoped>
  .attachFileContainer {
    display: inline-block;
    width: calc(50% - 10px);
    margin-top: 10px;
    padding-left: 10px;
  }
  .viewFilesContainer {
    display: inline-block;
    width: calc(50% - 10px);
    vertical-align: top;
    margin-top: 10px;
  }
  .uploadImageLabel {
    width: 100px;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .uploadFileTextbox {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    border: default;
    background-color: white;
    width:calc(100% - 120px);
  }
  .uploadFileTextboxError {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    background-color: white;
    border: solid 1px red;
    width:calc(100% - 120px);
  }
  .tickeRecipientsTextarea {
    border-radius: 5px;
    border: solid 1px #9aa8b1;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    padding: 0 5px;
    width:100%;
    size:1;
  }
  .tickeRecipientsTextareaError {
    border-radius: 5px;
    border: solid 1px red;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    width:100%;
    size:1;
  }
  .cardUploadedFiles {
    width: 100%;
  }
  .v-card{
    margin-bottom: 5px !important;
  }
  .icon {
    color:  grey !important;
  }
  .icon:hover{
    color:  red !important;
  }
  span{
      color: grey;
  }
  .noResults{
    text-align: center;
  }
  .buttonContainer {
    width: 100%;
    text-align: right;
  }
  .buttonDisabled {
    cursor: default;
    border: default;
    background: default;
  }
  .buttonEnabled {
    cursor: pointer;
    border: solid 1px #309b22;
    background: white;
  }
</style>
