<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-accountsettings"
          primary-title
        >
          Account Settings
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
              style="display:inline-block;"
            >
              <div
                v-if="hasMultipleCustomers"
                class="helpSectionContainer"
              >
                <h3> Default Companies </h3>
                <HelpSection
                  instruction="A Default Company is the company that will be selected on login to the portal. This section details how to manage the current users default company."
                />
                <HelpSection
                  instruction="1.) Navigate to the 'Account Settings' page via the left hand navigation menu."
                  image-source="account_default_customer_1.png"
                  indentation="0"
                />
                <HelpSection
                  instruction="a.) Under the 'Companies' section there is a list of all companies available to the current user."
                  indentation="1"
                />
                <HelpSection
                  instruction="b.) The selected company indicates the default company for the current user."
                  indentation="1"
                />
                <HelpSection
                  instruction="c.) The default company is user specific and is only managed through this screen."
                  indentation="1"
                />
              </div>
              <h3> Change Password </h3>
              <HelpSection
                instruction="This section details how to manage the current users password."
              />
              <HelpSection
                instruction="1.) Navigate to the 'Account Settings' page via the left hand navigation menu."
                image-source="account_default_customer_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Under the 'Reset Password' section are the fields which can be used to change the current users password."
                indentation="1"
              />
              <HelpSection
                instruction="b.) The current user’s password must be input. The new password must be at least five characters in length and the ‘New Password’ and ‘Confirm Password’ fields should match."
                indentation="1"
              />
              <HelpSection
                instruction="c.) If the new password does not meet these requirements, an error will be displayed and the password will not be changed."
                image-source="account_reset_password_1_error.png"
                indentation="1"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpAccountSettings',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    hasMultipleCustomers () {
      return this.$store.getters.customers.length > 1
    }
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }
</style>
