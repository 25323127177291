<template>
  <div
    :id="id"
    class="updateSectionContainer"
  >
    <div
      id="frmUpdateUser"
      class="updateUserHolder"
    >
      <div class="updateUserFieldHolder">
        <label
          for="txtUpdateFirstName"
          class="updateUserLabel"
        >First Name:</label>
        <input
          v-if="updateEntityPermission"
          id="txtUpdateFirstName"
          :class="{ errorHighlight: firstNameError }"
          v-model="user.firstName"
          type="text"
          class="updateUserTextbox"
          @blur="checkForm"
        >
        <label
          v-if="!updateEntityPermission"
          id="lblUpdateFirstName"
          class="updateUserData"
        >{{ user.firstName }}</label>
        <label
          for="txtUpdateLastName"
          class="updateUserLabel"
        >Last Name:</label>
        <input
          v-if="updateEntityPermission"
          id="txtUpdateLastName"
          :class="{ errorHighlight: lastNameError }"
          v-model="user.lastName"
          type="text"
          class="updateUserTextbox"
          @blur="checkForm"
        >
        <label
          v-if="!updateEntityPermission"
          id="lblUpdateLastName"
          class="updateUserData"
        >{{ user.lastName }}</label>
      </div>
      <Spinner v-if="isSaving" />
      <input
        v-if="updateEntityPermission && !isSaving"
        id="updateUserButton"
        :disabled="!formOK"
        :class="getButtonClass"
        style="margin-left: 318px"
        type="button"
        value="Update"
        title="Updates the user details user"
        @click="updateSelectedUser()"
      >
    </div>
  </div>
</template>

<script>
import { DataEventBus } from '../../events/dataEvents'
import { usersMixin } from '../../mixins/users'
import { adminMixin } from '../../mixins/adminMixin'
import { permissionsMixin } from '../../mixins/permissions'
import Spinner from '../common/spinner'
import { mapActions } from 'vuex'
import AuthRepository from '../../communications/repositories/authRepository'

export default {
  components: {
    Spinner
  },
  mixins: [
    usersMixin,
    permissionsMixin,
    AuthRepository,
    adminMixin
  ],
  props: {
    user: {
      type: Object,
      default: function () { return {} }
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      areas: [],
      formOK: false,
      firstNameError: false,
      lastNameError: false,
      isSaving: false
    }
  },
  computed: {
    updateEntityPermission () {
      return this.$store.getters.updateEntityPermission
    },
    getButtonClass () {
      if (this.formOK) {
        return 'updateUserButtonEnabled'
      } else {
        return 'updateUserButton'
      }
    }
  },
  watch: {
    user () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setReloadUsers'
    ]),
    setComponent: async function () {
      // document.getElementById('txtUpdateAvatar').value = this.user.avatar
      this.checkForm()
    },
    updateSelectedUser: async function () {
      this.isSaving = true
      this.checkForm()
      if (this.formOK) {
        var usrFName = this.user.firstName
        var usrLName = this.user.lastName
        var usrEmail = this.user.emailAddress
        var usrUsername = this.user.userName
        // var usrAvatar = document.getElementById('txtAvatar').value
        var usr = {
          Id: this.user.id,
          FirstName: usrFName,
          LastName: usrLName,
          EmailAddress: usrEmail,
          UserName: usrUsername,
          Disabled: this.user.Disabled,
          Locked: this.user.Locked,
          Deleted: this.user.Deleted
        }
        try {
          // now we've correctly populated the user object, we'll send it off to the user service to update
          this.updateUser(usr).then(result => {
            this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
          })
        } catch (error) {
          this.$store.commit('showMessage', { content: 'Failed to update the user in the authorisation database with error "' + error + '".', color: 'red', timeout: 3000 })
        }
        setTimeout(function () {
          DataEventBus.$emit('usersSet', true)
        }, 500)
        this.isSaving = false
      }
    },
    async checkForm () {
      this.firstNameError = this.user.firstName.length === 0
      this.lastNameError = this.user.lastName.length === 0

      var error = this.firstNameError || this.lastNameError

      if (error) {
        var errorMessage = ('Please fix the issues with the following field(s): ' +
            (this.firstNameError ? 'First Name, ' : '') +
            (this.lastNameError ? 'Last Name, ' : '')
        ).replace(/,\s*$/, '')
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }

      this.formOK = !error && !this.userNameExists
    }
  }
}
</script>

<style scoped>
  .updateUserLabel {
    width: 120px;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .updateUserTextbox {
    width: 260px;
    padding-left: 5px;
    display: inline-block;
  }
  .updateUserData {
    width: 145px;
    display: inline-block;
  }
  .updateSectionContainer {
    width:400px;
    display: none;
    margin-bottom:10px;
  }
  .updateUserHolder {
    background-color: rgba(255,255,255,0.7);
    padding:10px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .updateUserButton {
    opacity: 0.7;
    background-color: grey;
    cursor: default;
  }
  .updateUserButtonEnabled {
    opacity: 1;
  }
</style>
