<template>
  <v-dialog
    id="dialogbox"
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    @keydown.esc="closeDialogBox"
  >
    <v-card tile>
      <v-toolbar
        card
        dark
        color="primary"
      >
        <v-toolbar-title
          id="dialogboxTitle"
          style="color: white; font-size:24px"
        >
          {{ title }}
        </v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn
            id="dialogboxCloseButton"
            icon
            dark
            @click="closeDialogBox"
          >
            <v-icon style="color: white; font-size:24px">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
        <v-menu
          bottom
          right
          offset-y
        >
          <template>
            <v-btn
              dark
              icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-toolbar>
      <v-card-text>
        <slot/>
      </v-card-text>
      <div style="flex: 1 1 auto;"/>
    </v-card>
  </v-dialog>
</template>

<script>
import Spinner from '../common/spinner'
export default {
  name: 'DialogBox',
  components: {
    Spinner
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    show: {
      get () {
        return this.dialog
      }
    }
  },
  methods: {
    closeDialogBox () {
      this.$emit('close', false)
    }
  }
}
</script>
