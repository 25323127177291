var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"nowServiceOverview"}},[(_vm.viewNowServicePermission)?_c('div',[(_vm.loading)?_c('Spinner'):_c('div',[_c('table',{staticClass:"serviceSummaryTableNow",attrs:{"id":"serviceSummaryTableNow"}},[_c('thead'),_vm._v(" "),_c('tbody',_vm._l((_vm.currentFilter),function(ref){
var containerSerialNumber = ref.containerSerialNumber;
var containerName = ref.containerName;
var statusName = ref.statusName;
var departmentSerialNumber = ref.departmentSerialNumber;
return _c('tr',{key:("" + containerSerialNumber + departmentSerialNumber),class:_vm.serviceStatusColour(statusName),on:{"click":function () {
              _vm.selectedContainer = containerName
              _vm.loadStatuses(containerName)
            }}},[_c('td',{attrs:{"id":"containerName","title":containerName}},[_vm._v("\n              "+_vm._s(containerName)+"\n            ")]),_vm._v(" "),_c('td',{attrs:{"title":statusName}},[_c('div',{staticClass:"lozenge",class:_vm.serviceStatusColour(statusName),attrs:{"title":statusName}},[_vm._v("\n                "+_vm._s(statusName)+"\n              ")])])])}),0)]),_vm._v(" "),(_vm.selectedContainer)?_c('div',{attrs:{"id":"selectedContainerDetails"}},[_c('h3',[_vm._v("\n          "+_vm._s(_vm.selectedContainer)+"\n        ")]),_vm._v(" "),_vm._l((_vm.statusData),function(ref){
            var id = ref.id;
            var color = ref.color;
            var icon = ref.icon;
            var title = ref.title;
return _c('material-stats-card',{key:color+icon+title,staticStyle:{"margin":"35px 0 !important"},attrs:{"id":id,"title":title,"color":color,"icon":icon}})}),_vm._v(" "),_c('div',{staticClass:"healthHistoryBar"},_vm._l((_vm.selectedContainerHealthHistory.severities.map(function (healthStatus,index) { return ({ healthStatus: healthStatus, index: index }); })),function(ref){
            var healthStatus = ref.healthStatus;
            var index = ref.index;
return _c('div',{key:index,staticClass:"healthHistorySegment",class:_vm.loadStatusColor(healthStatus),attrs:{"title":_vm.parseStatus(healthStatus)}})}),0),_vm._v(" "),_c('p',{staticClass:"healthHistoryDetails"},[_vm._v("\n          Health History "+_vm._s(_vm.selectedContainerHealthHistoryRange)+"\n        ")])],2):_vm._e()])],1):_c('div',{staticClass:"noResults"},[_vm._v("\n    Sorry, you do not have permission to view the service status page\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }