<template>
  <div
    v-if="$store.getters.openSubForm2"
    class="areaNewRoleBackground"
  >
    <div class="areaNewRoleHolder">
      <div class="roleTableholder">
        <table>
          <thead>
            <tr>
              <th style="width:10%;border-top-right-radius:0px;border-top-left-radius:0px">
                <img
                  :src="newRoleIcon"
                  class="icon"
                >
              </th>
              <th style="width:80%;border-top-right-radius:0px;border-top-left-radius:0px">Add Role To Site</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="!roles.length"
              class="alert"
            >
              <td colspan="2">
                <p
                  class="alert"
                >
                  There are no roles to add.
                </p>
              </td>
            </tr>
            <tr
              v-for="m in roles"
              id="memberList"
              :key="m.id"
            >
              <td style="padding:0px;">
                <input
                  :id="m.id"
                  type="checkbox"
                  title="Select User"
                  class="memberItem"
                  @change="checkForm"
                >
              </td>
              <td style="padding:0px;">{{ m.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <input
        id="permissionCancelNewRoleButton"
        type="button"
        class="permissionCancelNewRoleButton"
        value="Cancel"
        title="Cancel the add operation"
        @click="cancelAdd()"
      >
      <input
        v-if="roles.length"
        id="permissionAddNewRoleButton"
        :class="getButtonClass"
        type="button"
        value="Add Role"
        title="Adds the new role to the permission"
        @click="addRole()"
      >
      <input
        type="text"
        class="focusField"
        @focus="cycleFocus"
      >
    </div>
  </div>
</template>
<script>
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'AreaNewRole',
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    areaid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      roles: [],
      formOK: false
    }
  },
  computed: {
    ...mapState([
      'openSubForm2'
    ]),
    getButtonClass () {
      if (this.formOK) {
        return 'permissionAddNewRoleButtonEnabled'
      } else {
        return 'permissionAddNewRoleButton'
      }
    },
    newRoleIcon () {
      return require('../../../assets/icons/People.png')
    }
  },
  watch: {
    areaid () {
      this.setComponent()
    }
  },
  created () {
    this.setComponent()
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.openSubForm2,
      (newValue) => {
        if (document.getElementById('memberList') && newValue) {
          document.getElementById('memberList').focus()
        }
      }
    )
  },
  methods: {
    ...mapActions([
      'setSubForm2',
      'setReloadRoles'
    ]),
    setComponent () {
      this.getRoles()
    },
    checkForm () {
      var selected = false
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          selected = true
        }
      }
      this.formOK = selected
    },
    checkExists (entity, id) {
      return entity.id === id
    },
    getRoles: async function () {
      this.getAreaRoles(this.areaid).then((roleMembers) => {
        this.getUserRoles(this.userid).then((areaMembers) => {
          var roles = []
          areaMembers.forEach(amember => {
            var foundIt = false
            if (roleMembers.length > 0) {
              roleMembers.forEach(rmember => {
                if (amember.id === rmember.id) {
                  foundIt = true
                }
              })
            }
            if (!foundIt) {
              roles.push(amember)
            }
          })
          this.roles = roles
        })
      })
    },
    closeForm () {
      this.setSubForm2(false)
    },
    addRole: async function () {
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          await this.addRoleArea(memberItems[i].id, this.areaid)
        }
      }
      this.setReloadRoles(true)
      this.closeForm()
    },
    cancelAdd () {
      this.closeForm()
    },
    cycleFocus () {
      if (document.getElementById('memberList')) {
        document.getElementById('memberList').focus()
      }
    }
  }
}

</script>
<style scoped>
  .areaNewRoleBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .areaNewRoleHolder {
    display: inline-block;
    background-color: rgba(255,255,255,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 160px);
    top: calc(10%);
    height: 320px;
    width: 360px;
    z-index: 999;
  }
  .permissionCancelNewRoleButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .permissionAddNewRoleButton {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    opacity: 0.5;
  }
  .permissionAddNewRoleButtonEnabled {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .roleTableholder {
    overflow: auto;
    height:254px;
  }
  .focusField {
    position: absolute;
    left:0;
    bottom:0;
    width:0;
    background-color: rgba(240,240,240,1);
    opacity: 0;
  }
  .memberItem {
    margin: 7px;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
    color: black;
    background-color: #fff;
  }
  .icon {
    height: 25px;
    width: 25px;
  }
</style>
