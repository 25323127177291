<template>
  <div class="pmnDueDateContainer">
    <Spinner v-if="loading" />
    <material-card>
      <vc-donut
        :size="200"
        :thickness="40"
        :sections="pmnData || undefined"
        :total="pmnTotal || undefined"
        :start-angle="0"
        background="white"
        foreground="grey"
        unit="px"
        has-legend
        legend-placement="bottom"
        @section-click="sectionClick">
        <h4 class="title font-weight-light">PMN Due Dates</h4>
      </vc-donut>
      <template slot="actions">
        <v-icon
          class="mr-2"
          small
        >mdi-clock-outline
        </v-icon>
        <span class="caption grey--text font-weight-light">{{ updateInterval }}</span>
      </template>
    </material-card>
  </div>
</template>

<script>

import { DataEventBus } from '../../../events/dataEvents'
import { dashboardMixin } from '../../../mixins/dashboard'
import { autotaskServiceMixin } from '../../../mixins/autotaskServiceMixin'

export default {
  name: 'PMNDueDate',
  mixins: [
    dashboardMixin,
    autotaskServiceMixin
  ],
  data () {
    return {
      loading: false,
      timer: 0,
      timerInterval: process.env.VUE_APP_DATA_REFRESH_TIME,
      pmnData: [],
      lastUpdatedDate: Date.now(),
      lastUpdatedText: 'Updated 0 seconds ago'
    }
  },
  computed: {
    currentTickets () {
      return this.$store.getters.pmnTickets
    },
    currentTicketUDFs () {
      return this.$store.getters.ticketUDFs
    },
    pmnTotal () {
      var rtn = 0
      this.pmnData.forEach((pmn) => {
        rtn += Number(pmn.value)
      })
      return rtn
    },
    updateInterval () {
      return `Updates every ${(process.env.VUE_APP_DATA_REFRESH_TIME / 1000)} seconds`
    }
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.displayPMNDueDate()
    })
    this.displayPMNDueDate()
  },
  methods: {
    dateData: function (daysNo) {
      var rtn = []
      this.currentTickets.forEach((ticket) => {
        if (new Date(ticket.dueDateTime) > new Date() &&
          new Date(ticket.dueDateTime) < new Date(new Date().setDate(new Date().getDate() + daysNo)) &&
          this.getUDFValue(ticket.id, 'Planned Maintenance Type') !== 'WSUS') {
          rtn.push(ticket)
        }
      })
      return rtn
    },
    displayPMNDueDate: function () {
      this.loading = true
      this.pmnData = []

      if (!this.currentTickets.length) {
        this.pmnData.push({
          label: 'No Data Available',
          value: 0,
          color: this.getPMNColour('')
        })
      } else {
        var data1 = this.dateData(1)
        this.pmnData.push({
          label: `Due Today (${data1.length})`,
          value: data1.length,
          color: this.getPMNColour(1)
        })
        var data7 = this.dateData(7)
        this.pmnData.push({
          label: `Due in 7 Days (${data7.length})`,
          value: data7.length,
          color: this.getPMNColour(7)
        })
        var data10 = this.dateData(10)
        this.pmnData.push({
          label: `Due in 10 Days (${data10.length})`,
          value: data10.length,
          color: this.getPMNColour(10)
        })
        var data28 = this.dateData(28)
        this.pmnData.push({
          label: `Due in 28 Days (${data28.length})`,
          value: data28.length,
          color: this.getPMNColour(28)
        })
      }
      this.lastUpdatedText = this.setLastUpdatedText(this.lastUpdatedDate)
      this.loading = false
    },
    drilldownProject: function ({ id }) {
      this.$emit('projectDrillDown', {
        project: this.projectData.find(o => o.id === id)
      })
    },
    getPMNColour (dayNo) {
      var rtn = 'Grey'
      if (dayNo === 1) rtn = 'rgb(166, 106, 174)'
      if (dayNo === 7) rtn = 'rgb(233, 106, 102)'
      if (dayNo === 10) rtn = 'rgb(255, 200, 97)'
      if (dayNo === 28) rtn = 'rgb(100, 194, 201)'
      return rtn
    },
    setTimer () {
      clearInterval(this.timer)
      return setInterval(async () => {
        await this.getProjectSummary(this.$store.getters.topLevelAreas)
        this.getProjectsSummaryChartData()
      }, this.timerInterval)
    },
    sectionClick (e) {
      if (e.label.indexOf('Today') > -1) { this.$router.push('pmn?daysFilter=1') }
      if (e.label.indexOf('7 Days') > -1) { this.$router.push('pmn?daysFilter=7') }
      if (e.label.indexOf('10 Days') > -1) { this.$router.push('pmn?daysFilter=10') }
      if (e.label.indexOf('28 Days') > -1) { this.$router.push('pmn?daysFilter=28') }
    },
    getUDFValue (id, key) {
      var udf = this.currentTicketUDFs.filter(u => u.id === id)
      for (let i = 0; i < udf.length; i++) {
        if (udf[i].name === key) {
          return udf[i].value
        }
      }
      return ''
    }
  }
}
</script>

<style scoped>
  #pmnDueDateTable {
    height: auto !important;
  }
  .pmnDueDateContainer {
    max-height: 400px;
    padding:10px;
  }

  .dashboardTable td {
    display:inline-block;
    width:19%;
  }
  .cdc-filler {
    cursor: pointer;
  }
</style>
