import portalAPIGateway from '../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteDesignPackEntriesAPIMixin = {
  mixins: [
  ],
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteDesignPackEntriesAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    saveQuoteDesignPackEntriesToDB: async function () {
      var axiosConfig = this.iQuoteQuoteDesignPackEntriesAPIMixin_getAxiosConfig()

      var quoteId = this.$store.getters.quote.id
      var designPackEntries = this.$store.getters.quoteDesignPackEntries

      try {
        await portalAPIGateway.post('iquote/quoteDesignPackEntries/' + quoteId, designPackEntries, axiosConfig).then(() => {
          return true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('saveQuoteDesignPackEntriesToDB failed!')
      }
    },
    getTechnologiesByServiceId: async function (serviceid) {
      var data = []
      var axiosConfig = this.iQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/technologies/service/' + serviceid, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getTechnologiesByServiceId returned error: ' + error)
      }
      return data
    },
    getDesignPackEntriesFromDB: async function () {
      var quoteId = this.$store.getters.quote.id
      var axiosConfig = this.iQuoteQuoteDesignPackEntriesAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquotearray/quoteDesignPackEntries/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getDesignPackEntriesFromDB returned error: ' + error)
      }

      return data
    }
  }
}
