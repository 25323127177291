import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const iQuoteCalculationsAvayaAXPMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
    }
  },
  computed: {
    /*
     * BUSINESS VALUES
     */
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcAvayaAXPConnector_calculate: function () {
      if (this.iQuoteStore_GetQuoteAvayaAXP.id === undefined) {
        return
      }

      this.calcAvayaAXPConnector_calculateAvayaAXPLicensesAndUnitCosts()
      this.calcAvayaAXPConnector_calculateTeamsConnectorLicensesAndUnitcosts()
    },
    calcAvayaAXPConnector_calculateAvayaAXPLicensesAndUnitCosts () {
      var userProfiles = this.iQuoteStore_GetQuoteAvayaAXP.userProfiles.filter(x => x.minCommitment > 0)
      var licenseId = null
      var avayaAXP = this.iQuoteStore_GetQuoteAvayaAXP

      var bundleProfiles = userProfiles.filter(x => x.userProfileCategoryId === this.iQuoteConstants.AvayaAXPUserProfileCategories.PROFILES)
      var numberOfUsers = 0
      var licenseQuantity
      var tierQuantity

      if (bundleProfiles.length > 0) {
        numberOfUsers = bundleProfiles.reduce((accumulator, entry) => { return accumulator + entry.minCommitment }, 0)
      }

      userProfiles.forEach(userProfile => {
        licenseId = undefined

        if (userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.EACH_ADDITIONAL_1TB_STORAGE) {
          licenseQuantity = userProfile.minCommitment // Quantity of license is the number of TB requested
          tierQuantity = numberOfUsers // We choose the tier based on the number of users.  Not the number of Tb

          licenseId = this.calcAvayaAXPConnector_getLicenseIdForUserProfile(userProfile.technologyUserProfileId, tierQuantity)
        } else if (userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.INTERACTION_DATA_EXPORT_MANAGER) {
          if (userProfile.minCommitment === 1 /* Yes */) {
            licenseQuantity = numberOfUsers /* if "Yes", then 1 license for each user on solution */
            tierQuantity = numberOfUsers

            licenseId = this.calcAvayaAXPConnector_getLicenseIdForUserProfile(userProfile.technologyUserProfileId, tierQuantity)
          }
        } else {
          /* For all other user profiles and add-ons we use the mincommitment for both the tier determination and the quantity of license */
          licenseQuantity = userProfile.minCommitment
          tierQuantity = userProfile.minCommitment

          licenseId = this.calcAvayaAXPConnector_getLicenseIdForUserProfile(userProfile.technologyUserProfileId, tierQuantity)
        }

        if (licenseId !== undefined) {
          this.iQuoteStore_AddRequiredLicenseToQuote(licenseId, licenseQuantity)
        }
      })

      switch (avayaAXP.options.avayaAXPSocialMediaPackageId) {
        case (this.iQuoteConstants.AvayaAXPSocialMediaMessagingOptions.MESSAGING_10K_MSGS_EA_MONTH_FIXED_SUBS):
          licenseId = this.iQuoteConstants.licenses.AVAYA_AXP_AXP_MESSAGING_10K_MSGS_EA_MONTH_FIXED_SUBS
          this.iQuoteStore_AddRequiredLicenseToQuote(licenseId, 1)
          break
        case (this.iQuoteConstants.AvayaAXPSocialMediaMessagingOptions.MESSAGING_70K_MSGS_EA_MONTH_FIXED_SUBS):
          licenseId = this.iQuoteConstants.licenses.AVAYA_AXP_AXP_MESSAGING_70K_MSGS_EA_MONTH_FIXED_SUBS
          this.iQuoteStore_AddRequiredLicenseToQuote(licenseId, 1)
          break
        case (this.iQuoteConstants.AvayaAXPSocialMediaMessagingOptions.MESSAGING_300K_MSGS_EA_MO_FIXED_SUBS):
          licenseId = this.iQuoteConstants.licenses.AVAYA_AXP_AXP_MESSAGING_300K_MSGS_EA_MO_FIXED_SUBS
          this.iQuoteStore_AddRequiredLicenseToQuote(licenseId, 1)
          break
        case (this.iQuoteConstants.AvayaAXPSocialMediaMessagingOptions.MESSAGING_1000K_MSGS_EA_MO_FIXED_SUBS):
          licenseId = this.iQuoteConstants.licenses.AVAYA_AXP_AXP_MESSAGING_1000K_MSGS_EA_MO_FIXED_SUBS
          this.iQuoteStore_AddRequiredLicenseToQuote(licenseId, 1)
          break
      }
    },
    calcAvayaAXPConnector_calculateTeamsConnectorLicensesAndUnitcosts () {
      var quote = this.$store.getters.quote
      var numSipChannels = 0
      var additionalSbcLicencesForInternalCalls = this.iQuoteStore_GetQuoteAvayaAXP.options.additionalSbcLicencesForInternalCalls

      var totalNumTeamsUsers = this.iQuoteStore_GetQuoteAvayaAXP.options.numAvayaAXPUsers + Math.ceil(this.iQuoteStore_GetQuoteAvayaAXP.options.numPBXUsers)

      numSipChannels = this.iQuoteStore_GetQuoteAvayaAXP.options.sipChannelsCalculated

      if (this.iQuoteStore_GetQuoteAvayaAXP.options.numAdditionalSIPChannels > 0) {
        if (quote.termLengthMonths >= 36) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_ACTIVE_36, this.iQuoteStore_GetQuoteAvayaAXP.options.numAdditionalSIPChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_STANDBY_36, this.iQuoteStore_GetQuoteAvayaAXP.options.numAdditionalSIPChannels)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_ACTIVE_12, this.iQuoteStore_GetQuoteAvayaAXP.options.numAdditionalSIPChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_STANDBY_12, this.iQuoteStore_GetQuoteAvayaAXP.options.numAdditionalSIPChannels)
        }
      }

      if (numSipChannels > 0) {
        if (!this.utilFunctions_UnitCostExistsOnQuote(this.iQuoteConstants.unitCosts.TEAMS_SIP_SETUP) &&
            !this.utilFunctions_UnitCostExistsOnQuote(this.iQuoteConstants.unitCosts.CXNOW_GAMMA_SIP_SETUP)) {
          // Might have already been added by Teams Connector or CXNow if ordered together with AvayaAXP
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_SIP_SETUP, 2)
        }

        if (quote.termLengthMonths >= 36) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_ACTIVE_36, numSipChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_STANDBY_36, numSipChannels)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_ACTIVE_12, numSipChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_STANDBY_12, numSipChannels)
        }

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_MAINTEL_SBC_SUPPORT, Math.ceil((numSipChannels + additionalSbcLicencesForInternalCalls) / 25))

        if (numSipChannels <= 100 || this.iQuoteStore_GetQuoteAvayaAXP.options.numPBXUsers > 0) {
          switch (this.iQuoteStore_GetQuoteAvayaAXP.options.usersPerSipChannel) {
            case 1:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 3:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 5:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 7:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 10:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 120:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
          }
        } else {
          switch (this.iQuoteStore_GetQuoteAvayaAXP.options.usersPerSipChannel) {
            case 1:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 3:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 5:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 7:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 10:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 120:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
          }
        }

        var iconBandwidth = 2 * (5 + ((numSipChannels + additionalSbcLicencesForInternalCalls) / 5))
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ICON_BANDWIDTH_CONTRIBUTION, iconBandwidth)

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_SBC_PORT_CHARGE, numSipChannels + additionalSbcLicencesForInternalCalls)

        if (this.iQuoteStore_GetQuoteAvayaAXP.options.teamsConnectorNumberPortingOptionId === 2 /* 2 = NEW DDI */) {
          var numDDIs = this.iQuoteStore_GetQuoteAvayaAXP.options.newDDIs
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_NEW_DDI, numDDIs)
        }

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_PROJECT_COORDINATOR, 1)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ENGINEER_LEVEL_B, 1)
      }
    },
    calcAvayaAXPConnector_getLicenseIdForUserProfile (technologyUserProfileId, quantity) {
      var licenseId
      var userProfilePropertyName = ''

      // Find name of constant that represents this user profile
      Object.keys(this.iQuoteConstants.avayaAXPUserProfiles).forEach(userProfileKey => {
        if (this.iQuoteConstants.avayaAXPUserProfiles[userProfileKey] === technologyUserProfileId) {
          userProfilePropertyName = userProfileKey
        }
      })

      if (userProfilePropertyName === '') {
        console.log('ERROR: calcAvayaAXPConnector_getLicenseIdForUserProfile', 'Failed to find ID in AXP User Profiles' + technologyUserProfileId)
        return undefined
      }

      // Work out the tier from the quantity
      var tierText = ''

      if (quantity < 100) {
        tierText = '20_99'
      } else if (quantity < 200) {
        tierText = '100_199'
      } else if (quantity < 400) {
        tierText = '200_399'
      } else {
        tierText = '400'
      }

      // Work out the name of the constant for this license based on the profile name, the term length and the tier
      var licensePropertyName = 'AVAYA_AXP_' + userProfilePropertyName + '_' + (this.iQuoteStore_GetQuote.termLengthMonths / 12) + 'YR_' + tierText + '_USERS'

      licenseId = this.iQuoteConstants.licenses[licensePropertyName]

      if (licenseId === undefined) {
        console.log('ERROR: License not found for profile', userProfilePropertyName)
      }

      return licenseId
    }
  }
}
