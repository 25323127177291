<template>
  <div class="helpSection">
    <p
      :class="`${sectionIndentation}`"
    >
      {{ instruction }}
    </p>
    <div
      v-if="imageSource"
      class="imageContainer"
    >
      <img
        :src="helpImage"
        :class="sectionIndentation"
        class="helpScreen"
        @click="displayImage"
      >
    </div>
    <div
      v-if="bigPicture"
    >
      <div
        class="bigImageBackground"
      />
      <div
        class="bigImageContainer"
        @click="closeImage"
      >
        <img
          :src="helpImage"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpSection',
  components: {
  },
  mixins: [
  ],
  props: {
    instruction: {
      type: String,
      default: ''
    },
    imageSource: {
      type: String,
      default: ''
    },
    indentation: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      loading: false,
      bigPicture: false
    }
  },
  computed: {
    helpImage () {
      return require(`../../assets/images/help/${this.imageSource}`)
    },
    sectionIndentation () {
      switch (this.indentation) {
        case '1':
          return 'indentedOnce'
        case '2':
          return 'indentedTwice'
        default:
          return ''
      }
    }
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
    displayImage: function () {
      this.bigPicture = true
    },
    closeImage: function () {
      this.bigPicture = false
    }
  }
}
</script>

<style scoped>
    p {
      width:100%;
      font-size: 18px;
      font-weight: 400;
      padding: 1rem;
      margin:0;
    }

    img {
        max-width: 700px;
        width:50%;
        box-shadow: 2px 4px 3px rgba(36, 68, 30, 0.3);
        border-radius: 5px;
        cursor: pointer;
    }

    p.indentedOnce {
      padding:1rem 2rem;
    }

    p.indentedTwice {
      padding: 1rem 3rem;
    }

    img.indentedOnce {
      margin: 0 1rem;
    }

    img.indentedTwice {
      margin: 0 2rem;
    }

    .imageContainer {
      padding: 0 1rem;
    }

    .bigImageBackground {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 100;
      opacity: 0.7;
      background-color: #000;
    }

    .bigImageContainer {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 101;
    }
    .bigImageContainer img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 1000px !important;
      width: 75%;
      transform: translate(-50%, -50%);
    }

    .helpSectionContainer>.helpSection:nth-of-type(even) {
      background-color: #e0e0e0;
    }

    .helpSectionContainer>.helpSection:nth-of-type(odd) {
      background-color: #f1efef;
    }

</style>
