import { render, staticRenderFns } from "./billOfMaterials.vue?vue&type=template&id=29280bf2&scoped=true&"
import script from "./billOfMaterials.vue?vue&type=script&lang=js&"
export * from "./billOfMaterials.vue?vue&type=script&lang=js&"
import style0 from "./billOfMaterials.vue?vue&type=style&index=0&id=29280bf2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29280bf2",
  null
  
)

export default component.exports