<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-advancedsearch"
          primary-title
        >
          Ticket Advanced Search
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="1.) Select the ‘Advanced Search’ button in the top right hand corner to open the ‘Advanced Search’ screen."
                image-source="advanced_search_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="2.) This screen allows the tickets in Autotask to be queried by the API directly by supplying filter criteria"
                image-source="advanced_search_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="3.) When the 'Apply Filter' button is pressed, the results of the query are shown in the table and the filter indicated as text at the top."
                image-source="advanced_search_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="4.) The query can be cleared by pressing the 'Clear Filter' button."
                image-source="advanced_search_4.png"
                indentation="0"
              />
              <HelpSection
                instruction="4.) The tickets screen can be returned to by pressing the 'Back' button."
                image-source="advanced_search_5.png"
                indentation="0"
              />
              <HelpSection
                instruction="5.) The You can redisplay the filters set by pressing the filter button."
                image-source="advanced_search_6.png"
                indentation="0"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpMyTickets',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
