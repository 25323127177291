<template>
  <div class="parentSectionForm">
    <h3
      id="sectionHeader"
      :class="[showCustomeFieldsSection ? 'expanded' : '']"
      @click="() => { showCustomeFieldsSection = !showCustomeFieldsSection }"
    >
      {{ title }}
      <span class="statuscontainer">
        <span
          :class="[sectionComplete ? 'completeSection' : 'incompleteSection']"
          :title="[sectionComplete ? 'Section Complete' : 'Section Incomplete']"
        />
        <span class="background" />
      </span>
      <span
        :class="[showCustomeFieldsSection ? 'minimiseSection' : 'maximiseSection']"
      />
    </h3>
    <slot/>
  </div>
</template>

<script>
import Spinner from '../common/spinner'

export default {
  name: 'FormButton',
  components: {
    Spinner
  },
  props: {
    saving: {
      type: Boolean,
      default: false
    },
    status: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showCustomeFieldsSection: true,
      sectionComplete: true
    }
  },
  computed: {
    getButtonClass () {
      if (this.status) {
        return 'submitButton'
      } else {
        return 'submitButtonDisabled'
      }
    }
  }
}
</script>

<style scoped>
  .parentSectionForm{
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .expanded {
    border-radius: 5px 5px 0 0;
  }
  .completeSection {
    background-image: url('../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  .incompleteSection {
    background-image: url('../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  span.statuscontainer {
    float: left;
    margin-right: 5px;
  }
  span.statuscontainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }
  .minimiseSection {
    background-image: url('../../assets/icons/up_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  .maximiseSection {
    background-image: url('../../assets/icons/down_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  .slide-enter-active, .slide-leave-active {
    transition: max-height .2s ease-out;
  }

  .slide-enter, .slide-leave-to {
    max-height: 0px;
  }

  .slide-enter-to, .slide-leave {
    max-height: 200px;
  }
</style>
