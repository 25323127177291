<template>
  <div>
    <div
      v-if="!createEntityPermission"
      class="noResults"
    >
      Sorry, you do not have permission to create global user
    </div>
    <div
      v-else
      id="createGlobalUser"
      class="createSectionData"
    >
      <div
        id="CreateGlobalUser"
        class="createGlobalUserHolder"
      >
        <div class="parentCreateGlobalUserForm">
          <h3
            id="PersonalSectionHeader"
            :class="[showPersonalSection ? 'expanded' : '']"
            @click="() => { showPersonalSection = !showPersonalSection }"
          >
            Personal
            <span class="statuscontainer">
              <span
                :class="[sectionComplete ? 'completeSection' : 'incompleteSection']"
                :title="[sectionComplete ? 'Section Complete' : 'Section Incomplete']"
              />
              <span class="background" />
            </span>
            <span
              :class="[showPersonalSection ? 'minimiseSection' : 'maximiseSection']"
            />
          </h3>
          <transition name="slide">
            <div
              v-if="showPersonalSection"
              class="createGlobalUserLeft"
            >
              <label
                for="txtCreateGlobalUserFirstName"
                class="createGlobalUserLabel"
              >First Name:</label>
              <input
                id="txtCreateGlobalUserFirstName"
                :class="{ errorHighlight: firstNameError }"
                v-model="user.firstName"
                type="text"
                class="createGlobalUserTextbox firstName"
                @blur="checkForm"
              >
              <label
                for="txtCreateGlobalUserLastName"
                class="createGlobalUserLabel"
              >Last Name:</label>
              <input
                id="txtCreateGlobalUserLastName"
                :class="{ errorHighlight: lastNameError }"
                v-model="user.lastName"
                type="text"
                class="createGlobalUserTextbox lastName"
                @blur="checkForm"
              >
            </div>
          </transition>
          <transition name="slide">
            <div
              v-if="showPersonalSection"
              class="createGlobalUserRight"
            >
              <label
                for="txtCreateGlobalUserEmailAddress"
                class="createGlobalUserLabel"
              >Email Address:</label>
              <input
                id="txtCreateGlobalUserEmailAddress"
                :class="{ errorHighlight: emailError || userNameExists}"
                v-model.trim="user.emailAddress"
                type="text"
                class="createGlobalUserTextbox emailAddress"
                @blur="checkForm"
              >
              <label
                for="optCreateGlobalUserAccountType"
                class="createGlobalUserLabel"
              >Account Type:</label>
              <select
                id="optCreateGlobalUserAccountType"
                v-model="user.accountType"
                class="createGlobalUserTextbox"
              >
                <option
                  value="SDM"
                  title="SDM"
                >SDM</option>
                <option
                  value="Public"
                  title="Public"
                >Public</option>
                <option
                  value="Admin"
                  title="Admin"
                >Admin</option>
              </select>
            </div>
          </transition>
        </div>
        <div class="bottombar">
          <Spinner v-if="isSaving" />
          <input
            v-if="!isSaving"
            id="cancelCreateGlobalUserButton"
            class="cancelGlobalUserButton"
            type="button"
            title="Cancel the create global user process"
            @click="closeCreateGlobalUserForm()"
          >
          <input
            v-if="!isSaving"
            id="createGlobalUserButton"
            :disabled="!formOK && !sectionComplete"
            :class="getButtonClass"
            type="button"
            title="Create a global user"
            @click="createUser()"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { adminMixin } from '../../mixins/adminMixin'
import { permissionsMixin } from '../../mixins/permissions'
import AuthRepository from '../../communications/repositories/authRepository'
import Spinner from '../common/spinner'
import { DataEventBus } from '../../events/dataEvents'

export default {
  metaInfo: {
    title: 'Support - Create Global User'
  },
  name: 'CreateGlobalUser',
  components: {
    Spinner
  },
  mixins: [
    AuthRepository,
    adminMixin,
    permissionsMixin
  ],
  props: {
  },
  data () {
    return {
      user: {
        firstName: null,
        lastName: null,
        emailAddress: null,
        accountType: 'SDM'
      },
      formOK: false,
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      emailCompanyError: false,
      accountTypeError: false,
      userNameExists: false,
      isSaving: false,
      showPersonalSection: true
    }
  },
  computed: {
    createEntityPermission () {
      return this.$store.getters.createEntityPermission
    },
    getButtonClass () {
      if (this.formOK && this.sectionComplete) {
        return 'createGlobalUserButtonEnabled'
      } else {
        return 'createGlobalUserButton'
      }
    },
    sectionComplete () {
      return this.user.firstName &&
      this.user.lastName &&
      this.user.emailAddress &&
      this.validateFirstChar(this.user.emailAddress) &&
      this.validateEmail(this.user.emailAddress) &&
      this.validateCompanyEmail(this.user.emailAddress) &&
      !this.userNameExists &&
      this.user.accountType
    },
    topLevelAreas () {
      return this.$store.getters.areas.filter(({ parent }) => parent === 0)
    }
  },
  mounted () {
    this.getUserAreas(this.$store.getters.userid)
    DataEventBus.$on('refresh-data', () => {
      this.getUserAreas(this.$store.getters.userid)
    })
  },
  methods: {
    createUser: async function () {
      this.isSaving = true

      await this.checkForm({ target: { } })
      if (this.formOK) {
        try {
          // Create the user in the auth and user services and setting up permissions in permission service
          await this.createGlobalUser(this.user)
            .catch((ex) => {
              console.error(ex)
              throw ex
            })
          this.$store.commit('showMessage', { content: 'Global user has been created successfully', color: 'blue', timeout: 3000 })
          if (this.$store.getters.viewEntityPermission) { this.usersGet() }
          this.isSaving = false
          this.closeCreateGlobalUserForm()
        } catch (ex) {
          console.error(ex)
          this.$store.commit('showMessage', { content: 'Global user creation failed', color: 'red', timeout: 3000 })
          this.isSaving = false
        }
      } else {
        this.$store.commit('showMessage', { content: 'Something went wrong, please try again...', color: 'red', timeout: 3000 })
        this.isSaving = false
      }
    },
    async checkForm ({ target: { className: checkField } }) {
      var errorMessage = ''
      if (checkField) {
        if (checkField.indexOf('firstName') > -1) {
          this.firstNameError = !this.user.firstName
        } else if (checkField.indexOf('lastName') > -1) {
          this.lastNameError = !this.user.lastName
        } else if (checkField.indexOf('emailAddress') > -1) {
          this.emailError = !this.user.emailAddress || !this.validateEmail(this.user.emailAddress) || !this.validateFirstChar(this.user.emailAddress)
          this.emailCompanyError = !this.validateCompanyEmail(this.user.emailAddress)
          this.userNameExists = false
          if (!this.emailError) {
            this.userNameExists = await this.validateUsername(this.user.emailAddress)
          }
        }
      } else {
        this.firstNameError = !this.user.firstName
        this.lastNameError = !this.user.lastName
        this.emailError = !this.user.emailAddress || !this.validateEmail(this.user.emailAddress) || !this.validateFirstChar(this.user.emailAddress)
        this.emailCompanyError = !this.validateCompanyEmail(this.user.emailAddress)
        this.userNameExists = false
        if (!this.emailError) {
          this.userNameExists = await this.validateUsername(this.user.emailAddress)
        }
      }
      var error = this.firstNameError || this.lastNameError || this.emailError || this.userNameExists || this.emailCompanyError
      if (error) {
        errorMessage = ('Please fix the issues with the following field(s): ' +
            (this.firstNameError ? 'First Name, ' : '') +
            (this.lastNameError ? 'Last Name, ' : '') +
            (this.emailError ? 'provide a valid email.' : this.userNameExists ? 'the email address ' + this.user.emailAddress + ' is already in use.' : '') +
            (this.emailCompanyError ? 'this operation may only use email addresses with ' + this.$store.getters.channelEmailSuffix + ' as a suffix' : '')
        ).replace(/,\s*$/, '')

        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !error
    },
    validateFirstChar (email) {
      var re = /^[A-Za-z0-9_-]*$/
      return re.test(email[0])
    },
    validateEmail (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    validateCompanyEmail (email) {
      var rtn = false
      if (this.$store.getters.channelCompany.companyId === this.$store.getters.currentCompany.id) {
        if (email.indexOf(this.$store.getters.channelEmailSuffix) > 0) {
          rtn = true
        }
      }
      return rtn
    },
    async validateUsername (value) {
      const username = value.toLowerCase()
      var rtn = false
      if (username !== null) {
        await this.getUserListByUsername(username)
          .then(result => {
            rtn = !result || result.some(user => user.userName.toLowerCase() === username)
          })
          .catch((_) => {
            console.log('Username not found')
          })
      }
      return rtn
    },
    closeCreateGlobalUserForm () {
      this.firstNameError = false
      this.lastNameError = false
      this.emailError = false
      this.accountTypeError = false
      this.userNameExists = false
      this.user = {
        firstName: null,
        lastName: null,
        emailAddress: null,
        accountType: null
      }
      this.$emit('setTileMode')
    }
  }
}
</script>

<style scoped>
  .parentCreateGlobalUserForm{
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .createGlobalUserLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .createGlobalUserTextbox {
    width: 68%;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }
  .createGlobalUserData {
    width: 145px;
    display: inline-block;
  }
  .createSectionData {
    position: absolute;
    bottom: 36px;
    top: 0;
    right: 0;
    background-color: #fff;
    left: 0;
    z-index: 1;
    margin-right: 3vw;
  }
  .createGlobalUserHolder {
    background-color: rgba(255,255,255,0.7);
    padding: 30px 15px 0 10px;
    overflow-y: auto;
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .cancelGlobalUserButton {
    background-image: url('../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
  }
  .createGlobalUserButton {
    background-image: url('../../assets/icons/check-tick-disabled.svg');
    cursor: default;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .createGlobalUserButtonEnabled {
    background-image: url('../../assets/icons/check-tick.svg');
    cursor: pointer;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .createGlobalUserLeft {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .createGlobalUserRight {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .bottombar {
    text-align: right;
  }
  .slide-enter-active, .slide-leave-active {
    transition: max-height .2s ease-out;
  }

  .slide-enter, .slide-leave-to {
    max-height: 0px;
  }

  .slide-enter-to, .slide-leave {
    max-height: 200px;
  }

  h3 {
    cursor: pointer;
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0;
    color: #fff;
    border-radius: 5px;
  }
  .expanded {
    border-radius: 5px 5px 0 0;
  }

  input {
    border-radius: 5px;
  }

  .completeSection {
    background-image: url('../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .incompleteSection {
    background-image: url('../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  span.statuscontainer {
    float: left;
    margin-right: 5px;
  }

  span.statuscontainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }

  .minimiseSection {
    background-image: url('../../assets/icons/up_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .maximiseSection {
    background-image: url('../../assets/icons/down_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .formContent {
    overflow-y: auto;
  }
  select {
    -webkit-appearance: menulist;
    border: solid 1px #9aa8b1;
    height: 26px;
  }
</style>
