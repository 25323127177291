<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-servicestatusdrawer"
          primary-title
        >
          Service Status Drawer
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="The Service Status Drawer shows details of all services currently owned, currently available and their statuses."
              />
              <HelpSection
                instruction="1.) Click on any of the service tiles to view the service details panel and associated details in the panel."
                image-source="service_status_drawer_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Clicking on the 'ICON Communicate / ICON Contact' service tile will display service details."
                indentation="1"
                image-source="service_status_drawer_1a.png"
              />
              <HelpSection
                instruction="i.) This table displays the services and their respective statuses."
                indentation="2"
              />
              <HelpSection
                instruction="ii.) Clicking on one of the services listed in the table will display a further breakdown of service details."
                indentation="2"
                image-source="service_status_drawer_1b.png"
              />
              <HelpSection
                instruction="iii.) This displays a breakdown of the system, application and network statuses as well as the health history of the service over the past 24 hours."
                indentation="2"
              />
              <HelpSection
                instruction="b.) Clicking on the 'ICON Connect' service tile will display service details."
                indentation="1"
                image-source="service_status_drawer_1c.png"
              />
              <HelpSection
                instruction="i.) The top doughnut chart displays the count of sites with alarms in given states."
                indentation="2"
              />
              <HelpSection
                instruction="ii.) The two panels beneath display general alarm stats for the selected company."
                indentation="2"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpServiceStatusDrawer',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
