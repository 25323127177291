import portalAPIGateway from '../../../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteCallsAndLinesAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteCallsAndLinesAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteQuoteCallsAndLinesAPIMixin_saveQuoteCallsAndLinesToDB: async function (quoteId, quoteCallsAndLines) {
      var axiosConfig = this.iQuoteQuoteCallsAndLinesAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.put('iquote/quotecallsandlines/' + quoteId, quoteCallsAndLines, axiosConfig).then((response) => {
          returnValue = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteCallsAndLinesAPIMixin_saveQuoteCallsAndLinesToDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteCallsAndLinesAPIMixin_deleteQuoteCallsAndLinesFromDB: async function (quoteCallsAndLines) {
      var axiosConfig = this.iQuoteQuoteCallsAndLinesAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.delete('iquote/quotecallsandlines/' + quoteCallsAndLines.id, null, axiosConfig).then((response) => {
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteCallsAndLinesAPIMixin_deleteQuoteCallsAndLinesFromDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteCallsAndLinesAPIMixin_getQuoteCallsAndLinesFromDb: async function (quoteId) {
      var axiosConfig = this.iQuoteQuoteCallsAndLinesAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquote/quotecallsandlines/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteCallsAndLinesAPIMixin_getQuoteCallsAndLinesFromDb returned error: ' + error)
      }

      return data
    }
  }
}
