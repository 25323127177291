import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { mapActions } from 'vuex'

export const iQuoteCalculationsCallmediaMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin],
  data () {
    return {
      quoteTechnologyCallmedia: {},
      quote: {},
      callmediaOutboundUsers: 0,
      licenses: {}
    }
  },
  computed: {
    callmediaCalcs_usersToHandle () {
      if (this.quoteTechnologyCallmedia.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyCallmedia.userProfiles.forEach(userProfile => {
        users += userProfile.maxAllowed
      })
      return users
    },
    callmediaCalcs_usersToLicense () {
      if (this.quoteTechnologyCallmedia.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyCallmedia.userProfiles.forEach(userProfile => {
        users += userProfile.minCommitment
      })
      return users
    },
    callmediaCalcs_multiChannelUsersToHandle () {
      if (this.quoteTechnologyCallmedia.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyCallmedia.userProfiles.forEach(userProfile => {
        if (userProfile.description.includes('Multi-Channel')) {
          users += userProfile.maxAllowed
        }
      })
      return users
    },
    callmediaCalcs_outboundUsersToHandle () {
      if (this.quoteTechnologyCallmedia.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyCallmedia.userProfiles.forEach(userProfile => {
        if (userProfile.description.includes('Outbound')) {
          users += userProfile.maxAllowed
        }
      })
      return users
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyCallmedia'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcCallmediaVMs: function () {
      this.quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia

      if (this.quoteTechnologyCallmedia.id === undefined) {
        return
      }

      this.quote = this.$store.getters.quote

      // TODO: When we add Skype Support, we will need to set this to true when that is chosen
      var skypeSystem = false

      if (!skypeSystem) {
      // AVAYA / MITEL / CISCO Phone SYstems

        if (this.callmediaCalcs_outboundUsersToHandle + this.callmediaCalcs_multiChannelUsersToHandle === 0) {
          // Voice Only

          if (this.callmediaCalcs_usersToHandle <= 10) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_COMBINED_SQLEXPRESS_CMSRV1, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 30) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_COMBINED_STANDARD_CMSRV2, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 80) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_STANDARD_CMSRV4, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 250) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_STANDARD_CMSRV4, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 1000) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_STANDARD_CMSRV4, this.iQuoteConstants.dataCentres.fareham, true, 1)
          }
        } else {
          // Multimedia and/or outbound

          if (this.callmediaCalcs_usersToHandle <= 10) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_COMBINED_STANDARD_CMSRV2, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 30) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_COMBINED_ENHANCED_CMSRV3, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 80) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_STANDARD_CMSRV4, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 250) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_STANDARD_CMSRV4, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 1000) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_STANDARD_CMSRV4, this.iQuoteConstants.dataCentres.fareham, true, 1)
          }
        }
      } else {
        //  SKYPE Phone Systems

        if (this.callmediaCalcs_outboundUsersToHandle + this.callmediaCalcs_multiChannelUsersToHandle === 0) {
          // Voice Only

          if (this.callmediaCalcs_usersToHandle <= 10) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_COMBINED_STANDARD_CMSRV2, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 30) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_COMBINED_STANDARD_CMSRV2, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 80) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_SMALL_S4B_CMSRV5, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 250) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_SMALL_S4B_CMSRV5, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 1000) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_SMALL_S4B_CMSRV5, this.iQuoteConstants.dataCentres.fareham, true, 1)
          }
        } else {
          // Multimedia and/or outbound

          if (this.callmediaCalcs_usersToHandle <= 10) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_COMBINED_STANDARD_CMSRV2, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 30) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_COMBINED_ENHANCED_CMSRV3, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 80) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_SMALL_S4B_CMSRV5, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 250) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_LARGE_S4B_CMSRV6, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 1000) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_APP_LARGE_S4B_CMSRV6, this.iQuoteConstants.dataCentres.fareham, true, 1)
          }
        }
      }

      // SQL SERVER
      if (this.callmediaCalcs_outboundUsersToHandle + this.callmediaCalcs_multiChannelUsersToHandle === 0) {
        // Voice Only
        if (this.callmediaCalcs_usersToHandle > 30) {
          if (this.callmediaCalcs_usersToHandle <= 80) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_SQL_SMALL_VOICE_CMSRV7, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 250) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_SQL_MEDIUM_VOICE_CMSRV9, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 1000) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_SQL_LARGE_VOICE_CMSRV11, this.iQuoteConstants.dataCentres.fareham, true, 1)
          }
        }
      } else {
        // Multimedia an/or outbound
        if (this.callmediaCalcs_usersToHandle > 30) {
          if (this.callmediaCalcs_usersToHandle <= 80) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_SQL_SMALL_MULTI_CMSRV8, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 250) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_SQL_MEDIUM_MULTI_CMSRV10, this.iQuoteConstants.dataCentres.fareham, true, 1)
          } else if (this.callmediaCalcs_usersToHandle <= 1000) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.CALLMEDIA_SQL_LARGE_MULTI_CMSRV12, this.iQuoteConstants.dataCentres.fareham, true, 1)
          }
        }
      }

      this.calcCallmediaAdjunct_VMs()
    },
    calcCallmediaAdjunct_VMs: function () {
      if (this.quoteTechnologyCallmedia.adjuncts.wfm) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFM, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFM, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.quoteTechnologyCallmedia.adjuncts.dpa) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.DPA, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.DPA, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.quoteTechnologyCallmedia.adjuncts.speechAnalysis) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SPEECH_ANALYSIS, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SPEECH_ANALYSIS, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.quoteTechnologyCallmedia.adjuncts.avayaWFOOptionId === this.iQuoteConstants.avayaWFOOptions.VERINT) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_VERINT, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_VERINT, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (this.quoteTechnologyCallmedia.adjuncts.avayaWFOOptionId === this.iQuoteConstants.avayaWFOOptions.AVAYA) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_AVAYA, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_AVAYA, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }
    },
    calcCallmediaAdjunctLicenses: function () {
      if (this.quoteTechnologyCallmedia.id === undefined) {
        return
      }

      this.licenses = this.$store.getters.licenses

      if (this.quoteTechnologyCallmedia.options.ivrPorts > 0 && this.quote.quoteLicenseTypeId !== this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_IVRPORTS, this.quoteTechnologyCallmedia.options.ivrPorts)
      }

      if (this.quoteTechnologyCallmedia.adjuncts.wfm) {
        // TODO: Add Licenses here if required
        // this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.WFM, 1)
      }

      if (this.quoteTechnologyCallmedia.adjuncts.dpa) {
        // TODO: Add Licenses here if required
        // this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DPA, 1)
      }

      if (this.quoteTechnologyCallmedia.adjuncts.speechAnalysis) {
        // TODO: Add Licenses here if required
        // this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SPEECH_ANALYSIS, 1)
      }
    },
    calcCallmediaUserLicenses: function () {
      this.licenses = this.$store.getters.licenses
      var requiredLicenseId = 0
      var licensePrice = 0
      var licenseCost = 0

      if (this.quoteTechnologyCallmedia.id === undefined) {
        return
      }

      for (let index = 0; index < this.quoteTechnologyCallmedia.userProfiles.length; index++) {
        const userProfile = this.quoteTechnologyCallmedia.userProfiles[index]

        // All user types get inbound voice
        if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
          requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_VOICE_PERPETUAL
        } else {
          requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_VOICE
        }

        this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
        licenseCost = this.utilFunctions_GetLicenseCost(requiredLicenseId)
        licensePrice = this.utilFunctions_GetLicensePrice(requiredLicenseId)

        switch (userProfile.description) {
          case 'Voice Agent':
            break
          case 'Multi-Channel Agent':
            if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
              requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_MULTI_CHANNEL_PERPETUAL
            } else {
              requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_MULTI_CHANNEL
            }

            this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
            licenseCost += this.utilFunctions_GetLicenseCost(requiredLicenseId)
            licensePrice += this.utilFunctions_GetLicensePrice(requiredLicenseId)
            break
          case 'Voice & Outbound Agent':
            if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
              requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_OUTBOUND_PERPETUAL
            } else {
              requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_OUTBOUND
            }

            this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
            licenseCost += this.utilFunctions_GetLicenseCost(requiredLicenseId)
            licensePrice += this.utilFunctions_GetLicensePrice(requiredLicenseId)
            break
          case 'Multi-Channel & Outbound Agent':
            if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
              requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_MULTI_CHANNEL_PERPETUAL
            } else {
              requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_MULTI_CHANNEL
            }

            this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
            licenseCost += this.utilFunctions_GetLicenseCost(requiredLicenseId)
            licensePrice += this.utilFunctions_GetLicensePrice(requiredLicenseId)

            if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
              requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_OUTBOUND_PERPETUAL
            } else {
              requiredLicenseId = this.iQuoteConstants.licenses.CALLMEDIA_OUTBOUND
            }
            this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
            licenseCost += this.utilFunctions_GetLicenseCost(requiredLicenseId)
            licensePrice += this.utilFunctions_GetLicensePrice(requiredLicenseId)
            break
          default:
            console.log('Unexpected user profile encountered: ' + userProfile.description)
            break
        }

        userProfile.annualTotalCost = userProfile.minCommitment * licenseCost * 12
        userProfile.annualPrice = userProfile.minCommitment * licensePrice * 12
        userProfile.rate = licensePrice
      }

      this.setQuoteTechnologyCallmedia(this.quoteTechnologyCallmedia)
    }
  }
}
