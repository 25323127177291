import portalAPIGateway from '../../../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteManagedServiceAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteManagedServiceAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteQuoteManagedServiceAPIMixin_saveQuoteManagedServiceToDB: async function (quoteId, quoteManagedService) {
      var axiosConfig = this.iQuoteQuoteManagedServiceAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.put('iquote/quotemanagedservice/' + quoteId, quoteManagedService, axiosConfig).then((response) => {
          returnValue = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteManagedServiceAPIMixin_saveQuoteManagedServiceToDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteManagedServiceAPIMixin_deleteQuoteManagedServiceFromDB: async function (quoteManagedService) {
      var axiosConfig = this.iQuoteQuoteManagedServiceAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.delete('iquote/quotemanagedservice/' + quoteManagedService.id, null, axiosConfig).then((response) => {
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteManagedServiceAPIMixin_deleteQuoteManagedServiceFromDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteManagedServiceAPIMixin_getQuoteManagedServiceFromDb: async function (quoteId) {
      var axiosConfig = this.iQuoteQuoteManagedServiceAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquote/quotemanagedservice/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteManagedServiceAPIMixin_getQuoteManagedServiceFromDb returned error: ' + error)
      }

      return data
    }
  }
}
