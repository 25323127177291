export const dashboardMixin = {
  data () {
    return {
    }
  },
  methods: {
    setLastUpdatedText (lastUpdatedDate) {
      var rtn = ''
      var diff = Date.now() - lastUpdatedDate
      if (diff < 60000) {
        rtn = `Updated ` + Math.round(diff / 1000) + ` seconds ago`
      } else if (diff > 60000 && diff < 3600000) {
        if (Math.round(diff / 60000) === 1) {
          rtn = `Updated ` + Math.round(diff / 60000) + ` minute ago`
        } else {
          rtn = `Updated ` + Math.round(diff / 60000) + ` minutes ago`
        }
      } else if (diff > 3600000) {
        if (Math.round(diff / 3600000) === 1) {
          rtn = `Updated ` + Math.round(diff / 3600000) + ` hour ago`
        } else {
          rtn = `Updated ` + Math.round(diff / 3600000) + ` hours ago`
        }
      }
      return rtn
    },
    areTheSame (arr1, arr2) {
      if (arr1.length === arr2.length) {
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i].priority !== arr2[i].priority || arr1[i].ticketNumber !== arr2[i].ticketNumber) {
            return false
          }
        }
        return true
      } else {
        return false
      }
    },
    areTheSameData (arr1, arr2) {
      try {
        if (arr1 && arr2.data && arr2.data.labels && arr2.data.series && arr1.length && arr2.data.labels.length) {
          if (arr1.length === arr2.data.labels.length) {
            for (let i = 0; i < arr1.length; i++) {
              if (arr1[i].priority.replace('- ', '\n') !== arr2.data.labels[i] || parseInt(arr1[i].ticketNumber) !== parseInt(arr2.data.series[0][i])) {
                return false
              }
            }
            return true
          } else {
            return false
          }
        }
      } catch (_) {
        return false
      }
    }
  }
}
