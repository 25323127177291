<template>
  <div>
    <div class="topMenu">
      <div
        v-for="menuItem in topLevelMenuItems"
        :key="menuItem"
        :id="menuItem"
        :class="{selected: (selectedTopMenuItem === menuItem)}"
        class="topMenuNavItem"
        @click="setSelectedTopMenu(menuItem)"
      >
        <span 
        :style="applyBold(menuItem)"
        >{{ map[menuItem].isIquoteMenuItem ? menuItem : camelCaseToSpacedTitle(menuItem) }}</span>
      </div>  
      <div class="rightDiv">
        <div class="supportTicketDiv">
          <button
            title="Create New iQuote Support ticket"
            @click="openSupportTicket"
            @close="closeSupportTicket"
          >Support ticket</button>
        </div>
        <div class="logoutDiv">
          <span
            style="cursor: pointer;"
            @click="logout()"
          >Logout</span>
        </div>
      </div>
    </div>
    <dialogBox
      :dialog="dialog"
      title="Create iQuote Support Ticket"
      @close="setDialog"
    >
      <newTicket
        :dialog="dialog"
        @close="setDialog"
      />
    </dialogBox>
    <div
      v-if="subLevelMenuItems(selectedTopMenuItem).length > 0"
      class="topMenuSubmenu">
      <div
        v-for="subMenuItem in subLevelMenuItems(selectedTopMenuItem)"
        :key="subMenuItem"
        :id="subMenuItem"
        :class="{selected: (selectedSubMenuItem === subMenuItem)}"
        class="topMenuNavItem"
        @click="handleSubMenuClicked(selectedTopMenuItem, subMenuItem)"
      >
        {{ camelCaseToSpacedTitle(subMenuItem) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import dialogBox from './dialogBox'
import newTicket from '../iQuote/menuScreens/newTicket'
export default {
  // eslint-disable-next-line
  name: 'TopMenu',
  components: {
    dialogBox,
    newTicket
  },
  props: {
    map: {
      /*
        This prop is an object that contains sub-objects where the names of those objects is used to generate the menu.
        If a top level object property contains a child object property then those are considered sub menu items

        You can, optionally, specify a page to be launched when each menu item is clicked.  If you don't specify a page,
        then the name of the page will be generated based on the name of the menu item that was clicked.

        Note that a Top level menu should NOT have a page property if there's a submenu, as this component will
        automatically select the first item in the submenu when the top menu is clicked.

      EXAMPLE:

      var map = {
        home: { page: 'Home' },
        coolStuff: {
          nuclearFusion: {} // Note that the page property wasn't supplied so a page of "CoolStuff_NuclearFusion" will be assumed
          wormHoles: { page: 'WormHoleTheory'} // Note that the page property shows that the page launched can be different to the menu name
          timeTravel: { page: 'TimeTravel}
        }
        help: {} // Note that the page property wasn't supplied so a page of "Help" will be assumed
      }

      The above map will generate a menu structure like this:

        Home
        Cool Stuff
          - Nuclear Fusion
          - Worm Holes
          - Time Travel
        Help

      You can dynamically change your map in the definition of your parent control like this:

        if (this.$store.getters.iQuoteTesterPermission || this.$store.getters.iQuoteCommercialsPermission) {
          map.commercial = {
            designPack: { page: 'CommercialDesignPack' }
          }
        }

      */
      type: Object,
      default: function () { return {} }
    },
    selectedTopMenuItem: {
      /* The name of the currently selected menu item.  Note that you must update this every time this component
         raises a showPage" event otherwise the menu will not update to show you have selected it
      */
      type: String,
      default: function () { '' }
    },
    selectedSubMenuItem: {
      /* The name of the currently selected submenu item.  Note that you must update this every time this component
         raises a showPage" event otherwise the menu will not update to show you have selected it
      */
      type: String,
      default: function () { '' }
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    // Gets list of menu items to display on the top level of the menubar.  This function returns the names of the objects (strings)
    topLevelMenuItems () {
      return Object.keys(this.map)
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
    ]),
    // Gets list of menu items to display on the sub level of the menubar that are underneath the specified top level menu.
    // This function returns the names of the objects (strings)
    subLevelMenuItems (topMenuItem) {
      var keys = Object.keys(this.map)
      for (let index = 0; index < keys.length; index++) {
        const menuItem = keys[index]
        if (menuItem === topMenuItem) {
          return Object.keys(this.map[menuItem]).filter(x => x !== 'page' && x !== 'isIquoteMenuItem')
        }
      }

      return []
    },
    // The menu map that's passed in consists of objects that represent the each menu item to be dispalayed
    // This function uses the name of each object to create the menu item text to display by converting the
    // camel cased name into a string where each capital letter is replaced by a space character
    camelCaseToSpacedTitle (str) {
      let newStr = ''
      for (let i = 0, l = str.length; i < l; i++) {
        if (i === 0) {
          newStr += str[i].toUpperCase()
        } else {
          if (str[i] === str[i].toUpperCase()) {
            newStr += ' '
          }
          newStr += str[i]
        }
      }
      return newStr
    },
    // Capitalise the first letter of a supplied string
    titleCase (str) {
      return str[0].toUpperCase() + str.substring(1, str.length)
    },
    // A menu has been clicked, so we have to emit an event to tell the parent component what page is must display
    setSelectedTopMenu (topMenuItem) {
      if (this.subLevelMenuItems(topMenuItem).length === 0) {
        // This menu has no sublevel, so just show page that's defined against the top level menu
        if (this.map[topMenuItem].page !== undefined) {
          // Page name has been specified in the map, so emit that in the event
          this.$emit('showPage', this.map[topMenuItem].page)
        } else {
          // Page name has NOT been specified in the map, so we assume the page name is the same as
          // the menu name (In title case rather than camel case)
          this.$emit('showPage', this.titleCase(topMenuItem))
        }
      } else {
        // This top-level menu has a submenu, so simulate a click on the first child
        this.handleSubMenuClicked(topMenuItem, this.subLevelMenuItems(topMenuItem)[0])
      }
    },
    // A submenu has been clicked, so we have to emit an event to tell the parent component what page is must display
    handleSubMenuClicked (topMenuItem, subMenuItem) {
      if (this.map[topMenuItem][subMenuItem].page !== undefined) {
        // Page name has been specified in the map, so emit that in the event
        this.$emit('showPage', this.map[topMenuItem][subMenuItem].page)
      } else {
        // Page name has NOT been specified in the map, so we assume the page name is the same as
        // the combined top level menu name and sublevel menu name separated by an underscore (In
        // title case rather than camel case)
        this.$emit('showPage', this.titleCase(topMenuItem) + '_' + this.titleCase(subMenuItem))
      }
    },
    // Method to open the iQuote support ticket screen
    openSupportTicket () {
      this.dialog = true
    },
    // Method to close the iQuote support ticket screen
    closeSupportTicket () {
      this.dialog = false
    },
    // Method to set the dialog state of the form
    setDialog: function (value) {
      this.dialog = value
    },    
    applyBold(menuItem) {
      if (this.map[menuItem].isIquoteMenuItem) {
        return 'font-weight: bold'
      }
    },
    logout() {
      this.$router.push('/logout')
    }
  }
}
</script>
<style scoped>
  .topMenuSubmenu {
    display: block;
    text-align: left;
    background-image: linear-gradient(to right, #63B452, #63B452);
    border-bottom: 1px solid silver;
    color: white;
    height: 50px;
  }

  .topMenu {      
      display: flex;
      text-align: left;
      background-image: linear-gradient(to right, #283683, #283683 );
      border-bottom: 1px solid silver;
      color: white;
      height: 50px;
      min-width: 1400px;
  }

  .topMenuNavItem {
    margin-top: 10px;
    display: inline-block;
    text-wrap: nowrap;
    padding: 2px 10px;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 25px;
    text-transform: capitalize;
  }
  .topMenuNavItem.selected {
    font-weight: bold;
    box-shadow: 0 12px 20px -10px rgba(255, 255, 255, 0.44), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.33) !important;
  }
  .rightDiv {
    display: flex;
    margin-left: auto;
    margin-right: 1%;
    align-items: center;
  }
  .supportTicketDiv {
    border: 1px solid #fff;
    padding: 2px 8px;    
  }
  .logoutDiv {
    margin-left: 3rem;
  }

</style>
