<template>
  <v-snackbar
    v-model="show"
    :timeout="timeout"
    :color="color"
    class="snackbar-container"
    bottom
    right>
    {{ message }}
    <v-btn
      dark
      flat
      icon
      class="closeBtn"
      @click="() => { show = false }"
    >Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: '',
      color: '',
      timeout: 3000
    }
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showMessage') {
        this.message = state.Snackbar.content
        this.color = state.Snackbar.color
        this.timeout = state.Snackbar.timeout
        this.show = true
      }
    })
  }
}
</script>

<style scoped>
  .closeBtn {
    border: solid 1px #fff;
    border-radius: 10px;
    padding: 0 10px;
  }
  .v-btn.v-btn--icon {
    width: 68px;
  }
  .snackbar-container {
    z-index:99999999999;
  }
</style>
