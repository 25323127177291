<template>
  <div>
    <Spinner v-if="isLoading" />
    <router-link
      v-ripple
      v-if="!isLoading"
      class="toolbar-items"
      to="pmn?ticketsFilter=emergency">
      <material-stats-card
        v-if="!isLoading"
        :value="emergencyPMN"
        color="red"
        icon="mdi-exclamation"
        title="Emergency PMN Tickets"
        sub-icon="mdi-clock"
        sub-text="Number of emergency planned maintenance tickets"
        style="margin-bottom: 35px!important;"
      />
    </router-link>
  </div>
</template>

<script>
import { DataEventBus } from '../../../events/dataEvents'
import Spinner from '../../common/spinner'

export default {
  name: 'PMNEmergencyCoponent',
  components: {
    Spinner
  },
  data () {
    return {
      emergencyPMN: '0',
      isLoading: false
    }
  },
  computed: {
    currentTickets () {
      return this.$store.getters.pmnTickets
    },
    currentTicketUDFs () {
      return this.$store.getters.ticketUDFs
    }
  },
  mounted () {
    DataEventBus.$on('load-dashboard', (state) => {
      this.populateEmergencyTickets()
    })
    DataEventBus.$on('tickets-loaded', () => {
      this.populateEmergencyTickets()
    })
    this.populateEmergencyTickets()
  },
  methods: {
    populateEmergencyTickets () {
      this.isLoading = true
      if (this.$store.getters.pmnTickets) {
        this.emergencyPMN = this.getEmergencyTicketCount().toString()
      }
      this.isLoading = false
    },
    getEmergencyTicketCount () {
      return this.currentTickets.filter(t => this.getUDFValue(t.id, 'Planned Maintenance Category') === 'Emergency').length
    },
    getUDFValue (id, key) {
      var udf = this.currentTicketUDFs.filter(u => u.id === id)
      for (let i = 0; i < udf.length; i++) {
        if (udf[i].name === key) {
          return udf[i].value
        }
      }
      return ''
    }
  }
}
</script>
<style scoped>
  .v-card__text { padding-bottom: 6px;}
</style>
