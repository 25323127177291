<template>
  <div
    id="tblticketDetails"
    class="ptrContainer"
  >
    <div
      v-if="ticket.Ticket_Number && ticket.Ticket_Number.length>0"
      class="ptrTreeViewTicket"
    >
      <div class="ticketDetailHeader">
        <v-icon
          :style="ticketStyle(ticket)"
          class="detailTicketIcon"
        >
          mdi-ticket-account
        </v-icon>
        Ticket Details - {{ ticket.Service_Impact }}
      </div>
      <div class="detailTicketEscallationRow">
        <v-icon
          :style="emergencyTicketType(ticket)"
          :title="emergencyTicketTypeText(ticket)"
          class="detailTicketEmergencyIcon"
        >
          mdi-exclamation
        </v-icon>
        <div
          v-if="ticket.Problem_Ticket_Id"
          class="detailTicketEscallation"
        >
          Problem Ticket
        </div>
        <div
          v-else
          class="detailTicketEscallation"
        >
          {{ ticketLabel }}
        </div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Ticket No</div>
        <div
          id="ticketNumberField"
          class="ticketDetailRowValue"
        >{{ ticket.Ticket_Number }}</div>
        <clipboard
          class="clipboardIcon"
          element="ticketNumberField"
        />
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">PM Type</div>
        <div class="ticketDetailRowValue">{{ ticket.PM_Type }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">PM Reference</div>
        <div class="ticketDetailRowValue">{{ ticket.PM_Reference }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">PM Category</div>
        <div class="ticketDetailRowValue">{{ ticket.PM_Category }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Date Due</div>
        <div class="ticketDetailRowValue">{{ ticket.Date_Due }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Duration</div>
        <div class="ticketDetailRowValue">{{ ticket.Estimated_Hours }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Site</div>
        <div class="ticketDetailRowValue">{{ ticket.Site }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Title</div>
        <div class="ticketDetailRowValue">{{ ticket.Title }}</div>
      </div>
      <div
        v-show="ticket.Service_Impact.indexOf('Change') > -1"
        class="ticketDetailRow"
      >
        <div class="ticketDetailRowLabel">Status</div>
        <div class="ticketDetailRowValue">{{ ticket.Status }}</div>
      </div>
      <!-- <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Last Activity</div>
        <div class="ticketDetailRowValue">{{ ticket.Last_Activity }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Priority</div>
        <div class="ticketDetailRowValue">{{ ticket.Priority }}</div>
      </div> -->
      <div
        v-show="ticket.Issue_Type!=='Unknown'"
        class="ticketDetailRow"
      >
        <div class="ticketDetailRowLabel">Issue Type</div>
        <div class="ticketDetailRowValue">{{ ticket.Issue_Type }}</div>
      </div>
      <div
        v-show="ticket.Sub_Issue_Type!=='Unknown'"
        class="ticketDetailRow"
      >
        <div class="ticketDetailRowLabel">Sub Issue</div>
        <div class="ticketDetailRowValue">{{ ticket.Sub_Issue_Type }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="detailButtons">
          <div class="ticketDetailButton">
            <button
              id="ticketDetailButton"
              @click="setDialog(true)"
            >Details</button>
            <dialogBox
              :dialog="dialog"
              :title="ticket.Title"
              @close="setDialog"
            >
              <ticketDetails
                :ticket="fullTicket"
                :show-new-notes="showNewNotes"
              />
            </dialogBox>
          </div>
          <!-- <div class="ticketDetailRowIcon">
            <img
              :src="autotaskIcon"
              class="openDetailsIcon"
              title="Open in Autotask"
              @click="openTicket($event, ticket)"
            >
          </div> -->
        </div>
      </div>
    </div>
    <div
      v-else
      class="ptrNoRows"
    >
      No ticket found
    </div>
  </div>
</template>
<script>

import clipboard from '../common/clipboard'
import dialogBox from '../common/dialogBox'
import ticketDetails from './ticketDetails'

export default {
  components: {
    clipboard,
    dialogBox,
    ticketDetails
  },
  props: {
    ticket: {
      type: Object,
      default: () => {}
    },
    fullTicket: {
      type: Object,
      default: () => {}
    },
    showDetails: {
      type: Boolean,
      default: () => false
    },
    showNewNotes: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      dialog: false,
      contactName: 'Unknown'
    }
  },
  computed: {
    autotaskIcon () {
      return require(`../../assets/icons/autotask-icon.png`)
    },
    ticketLabel () {
      return 'Planned Maintenance Ticket'
    }
  },
  watch: {
    showDetails () {
      this.setDialog(this.showDetails)
    }
  },
  mounted () {
    if (this.showDetails) {
      this.setDialog(true)
    }
  },
  methods: {
    openTicket (event, selectedRow) {
      this.selectedTicket = selectedRow
      this.selected = selectedRow.id
      var url = 'https://maintel.itclientportal.com/ClientPortal/ServiceTickets/ServiceTicketDetail.aspx?callBackMethod=refreshTickets&taskid=' + selectedRow.id
      var win = window.open(url, '_blank')
      win.focus()
    },
    ticketStyle (tckt) {
      if (tckt.Service_Impact) {
        if (tckt.Service_Impact.indexOf('Critical') > -1) {
          return 'color:rgb(239, 83, 80)'
        } else if (tckt.Service_Impact.indexOf('High') > -1) {
          return 'color:rgb(167, 80, 239)'
        } else if (tckt.Service_Impact.indexOf('Medium') > -1) {
          return 'color:rgb(255, 167, 38)'
        } else if (tckt.Service_Impact.indexOf('Low') > -1) {
          return 'color:rgb(102, 187, 106)'
        } else if (tckt.Service_Impact.indexOf('Risk') > -1) {
          return 'color:rgb(104 201 242)'
        } else if (tckt.Service_Impact.indexOf('Request') > -1) {
          return 'color:rgb(108, 203, 255)'
        } else {
          return 'color:rgb(214, 213, 213)'
        }
      } else {
        return 'color:rgb(214, 213, 213)'
      }
    },
    emergencyTicketType  (tckt) {
      if (tckt.PM_Category === 'Emergency') {
        return 'background-color:rgb(249 103 63);color: #fff;'
      } else {
        return 'background-color:rgb(122, 212, 67);color: #fff;'
      }
    },
    emergencyTicketTypeText (tckt) {
      if (tckt.PM_Category === 'Emergency') {
        return 'Emergency maintenance notification'
      } else {
        return 'Standard maintenance notification'
      }
    },
    setDialog: function (value) {
      this.dialog = value
    },
    getCheckDate (str) {
      var updated = str.substring(5, 2) + '/' + str.substring(0, 2) + '/' + str.substring(6, str.length)
      var dte = new Date(updated)
      var now = new Date()
      now.setHours(0, 0, 0, 0)
      return (dte < now)
    }
  }
}
</script>
<style scoped>
  .ptrTreeViewTicket {
    background-color: transparent;
    border-spacing: 0;
  }
  .ticketDetailHeader {
    background-color: #274a5a;
    color: #fff;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
  }
  .ticketDetailRow {

    padding:5px;
  }
  .ticketDetailRowLabel {
    width:100px;
    display: inline-block;
    vertical-align: top;
  }
  .ticketDetailRowLabelLong{
    width:180px;
    display: inline-block;
    vertical-align: top;
  }
  .ticketDetailRowValue {
    font-weight: bold;
    display: inline-block;
    max-width: 286px;
    word-wrap: break-word;
  }
  .ticketDetailRowValueShort {
    font-weight: bold;
    display: inline-block;
    max-width: 226px;
    word-wrap: break-word;
  }
  .ticketDetailRowIcon {
    text-align: right;
  }
  .openDetailsIcon {
    width:64px;
    height:64px;
    cursor: pointer;
  }
  .detailTicketIcon {
    margin-right: 10px;
    font-size: 2em;
    line-height: 0.6em;
  }
  .detailTicketEmergencyIcon {
    margin: 0 10px 0 5px;
    font-size: 2em;
    border-radius: 5px;
    cursor: pointer;
    vertical-align: middle;
  }
  .detailTicketTitle {
    display:inline-block;
  }
  .clipboardIcon {
    display:inline-block;
  }
  .detailTicketEscallation {
    display: inline-block;
    font-weight: bold;
    margin: 10px 0;
    font-size: 1.2em;
  }
  .ticketDetailButton button{
    padding: 5px 10px 5px 10px;
    border: 1px solid black;
  }
  .detailButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
</style>
