<template>
  <div class="ticketDetailContainer">
    <div class="headerWarning">Please note - for all P1 critical incidents please call our service desk on 0344 871 1122 (option 2 for support)</div>
    <div class="headerWarning">Please be aware that Maintel reserve the right to charge for any work which is not covered by our contract and service level agreement with you.</div>
    <Spinner v-if="saving" />
    <div v-if="!saving">
      <div class="parentCreateTicketForm">
        <h3
          id="requestTypeSectionHeader"
          :class="[showRequestTypeSection ? 'expanded' : '']"
          @click="() => { showRequestTypeSection = !showRequestTypeSection }"
        >
          1. Request Type{{ !requestTypeSectionCompleted ? ' - Please enter values in all fields.' : '' }}
          <span class="statuscontainer">
            <span
              :class="[requestTypeSectionCompleted ? 'completeSection' : 'incompleteSection']"
              :title="[requestTypeSectionCompleted ? 'Section Complete' : 'Section Incomplete']"
            />
            <span class="background" />
          </span>
          <span
            :class="[showRequestTypeSection ? 'minimiseSection' : 'maximiseSection']"
          />
        </h3>
        <transition name="slide">
          <div
            v-if="showRequestTypeSection"
            class="createTicketLeft"
          >
            <label
              for="cboRequestType"
              class="createTicket"
            >Request Type:</label>
            <select
              id="cboRequestType"
              :class="{ errorHighlight: requestTypeError }"
              v-model="requestTypeId"
              class="createTicketTextbox"
              tabindex="1"
              @change="selectRequestType"
            >
              <option
                v-for="requestType in requestTypes"
                :key="requestType.name"
                :value="requestType.id"
                :title="requestType.name"
              >{{ requestType.name }}</option>
            </select>
          </div>
        </transition>
      </div>
      <div class="parentCreateTicketForm">
        <h3
          id="PersonalSectionHeader"
          :class="[showTicketDetailSection ? 'expanded' : '']"
          @click="() => { showTicketDetailSection = !showTicketDetailSection }"
        >
          2. Ticket Details{{ !ticketDetailSectionCompleted ? ' - Please enter values in all fields.' : '' }}
          <span class="statuscontainer">
            <span
              :class="[ticketDetailSectionCompleted ? 'completeSection' : 'incompleteSection']"
              :title="[ticketDetailSectionCompleted ? 'Section Complete' : 'Section Incomplete']"
            />
            <span class="background" />
          </span>
          <span
            :class="[showTicketDetailSection ? 'minimiseSection' : 'maximiseSection']"
          />
        </h3>
        <transition name="slide">
          <div
            v-if="showTicketDetailSection"
            class="createTicketLeft"
          >
            <label
              for="txtTitle"
              class="createTicketLabel"
            >Title:</label>
            <input
              id="txtTitle"
              :class="{ errorHighlight: titleError }"
              v-model="ticket.title"
              type="text"
              class="createTicketTextbox ticketTitle"
              tabindex="2"
              maxlength="255"
              @blur="checkForm"
            >
            <label
              for="txtDescription"
              class="createTicketTextareaLabel"
            >Description:</label>
            <textarea
              id="txtDescription"
              :class="{ errorHighlight: descriptionError }"
              v-model="ticket.description"
              type="text"
              class="createTicketTextbox ticketDescription"
              tabindex="3"
              maxlength="5000"
              @blur="checkForm"
            />
          </div>
        </transition>
        <transition name="slide">
          <div
            v-if="showTicketDetailSection"
            class="createTicketLeft"
          >
            <label
              for="txtSite"
              class="createTicketLabel"
            >Site:</label>
            <PortalDropdown
              id="txtSite"
              :options="sites"
              :disabled="false"
              :max-item="999999"
              :class="{ errorHighlight: siteError }"
              :attach-blur-event="false"
              class="createTicketTextbox outline ticketSite"
              parent-class="createTicketLeft"
              placeholder="Select Site"
              tabindex="4"
              @selected="selectSite"
            />
            <div
              v-show="requestTypeId!==1 && requestTypeId!==2"
            >
              <label
                for="cboPriority"
                class="createTicketLabel"
              >Priority:</label>
              <select
                id="cboPriority"
                :class="{ errorHighlight: priorityError }"
                v-model="ticket.priorityId"
                class="createTicketTextbox ticketPriority"
                tabindex="5"
                @blur="checkForm"
                @change="checkForm"
              >
                <option
                  v-for="priority in priorities"
                  :key="priority.id"
                  :value="priority.id"
                  :title="priority.name"
                  :selected="priority.isDefault"
                >{{ priority.name }}</option>
              </select>
            </div>
            <div
              v-show="requestTypeId==5"
            >
              <label
                for="cboMobileNetwork"
                class="createTicketLabel"
              >Mobile Network:</label>
              <select
                id="cboMobileNetwork"
                :class="{ errorHighlight: siteError }"
                v-model="mobileNetwork"
                class="createTicketTextbox outline mobileNetwork"
                parent-class="createTicketLeft"
                placeholder="Select Mobile Network"
                tabindex="5"
                @blur="checkForm"
                @change="checkForm"
              >
                <option
                  v-for="mobileNetwork in mobileNetworks"
                  :key="mobileNetwork.id"
                  :value="mobileNetwork.id"
                  :title="mobileNetwork.name"
                >{{ mobileNetwork.name }}</option>
              </select>
            </div>
            <div
              v-show="requestTypeId==1"
            >
              <label
                for="cboConfigurationItemType"
                class="createTicketLabel"
              >Issue Area:</label>
              <select
                id="cboConfigurationItemType"
                :placeholder="configItemTypePlaceholder"
                class="createTicketTextbox ticketPriority"
                @change="setConfigurationItemType"
              >
                <option
                  v-for="configurationItemType in configurationItemTypes"
                  :key="configurationItemType.id"
                  :value="configurationItemType.id"
                  :title="configurationItemType.name"
                >{{ configurationItemType.name }}</option>
              </select>
            </div>
            <div
              v-show="requestTypeId===5"
            >
              <label
                for="cboSubIssueType"
                class="createTicketLabel"
              >Sub Issue Type:</label>
              <select
                id="cboSubIssueType"
                :class="{ errorHighlight: priorityError }"
                v-model="subIssueTypeId"
                class="createTicketTextbox ticketPriority"
                tabindex="6"
                @blur="checkForm"
                @change="selectSubIssueType"
              >
                <option
                  v-for="subIssueType in subIssueTypes"
                  :key="subIssueType.id"
                  :value="subIssueType.id"
                  :title="subIssueType.name"
                >{{ subIssueType.name }}</option>
              </select>
            </div>
          </div>
        </transition>
      </div>
      <div class="parentCreateTicketForm">
        <h3
          id="customFieldsSectionHeader"
          :class="[showCustomFieldsSection ? 'expanded' : '']"
          @click="() => { showCustomFieldsSection = !showCustomFieldsSection }"
        >
          3. Additional Ticket Fields{{ !customFieldsSectionCompleted ? ' - Compulsory fields shown with a red asterix.' : '' }}
          <span class="statuscontainer">
            <span
              :class="[customFieldsSectionCompleted ? 'completeSection' : 'incompleteSection']"
              :title="[customFieldsSectionCompleted ? 'Section Complete' : 'Section Incomplete']"
            />
            <span class="background" />
          </span>
          <span
            :class="[showCustomFieldsSection ? 'minimiseSection' : 'maximiseSection']"
          />
        </h3>
        <transition name="slide">
          <div
            v-show="showCustomFieldsSection"
            class="customFieldsContainer"
            style="margin:10px 0;padding-bottom:5px;"
          >
            <div v-show="showConfigurationItemSelection">
              <label
                for="configurationItemID"
                class="fieldLabel"
              >Site Configuration Item</label>
              <label style="color:red;margin-right:3px;vertical-align:top;font-weight:bold;">
                *
              </label>
              <PortalDropdown
                id="configurationItemID"
                :options="configurationItems"
                :disabled="false"
                :max-item="999999"
                :class="{ errorHighlight: siteError }"
                :placeholder="configItemPlaceholder"
                :attach-blur-event="false"
                class="createTicketTextbox outline ticketConfigItem"
                @selected="setConfigurationItems"
              />
            </div>
            <div id="customFieldsDiv"/>
          </div>
        </transition>
      </div>
      <div class="parentCreateTicketForm">
        <h3
          id="attachmentSectionHeader"
          :class="[showAttachmentSection ? 'expanded' : '']"
          @click="() => { showAttachmentSection = !showAttachmentSection }"
        >
          4. Attachment
          <span class="statuscontainer">
            <span
              :class="[attachmentSectionCompleted ? 'completeSection' : 'incompleteSection']"
              :title="[attachmentSectionCompleted ? 'Section Complete' : 'Section Incomplete']"
            />
            <span class="background" />
          </span>
          <span
            :class="[showAttachmentSection ? 'minimiseSection' : 'maximiseSection']"
          />
        </h3>
        <transition
          v-if="showAttachmentSection"
          name="slide"
        >
          <div class="divNewAttachmentContainer">
            <AttachFiles
              :show="showAttachmentSection"
              @recipients-change="updateRecipients"
              @updated="filesUpdated"
            />
          </div>
        </transition>
      </div>
      <div class="buttonHolder">
        <TextButton
          :status="true"
          :saving="false"
          :disabled="!formOK"
          title="Save and Close"
          text="Save and Close"
          @click="submitForm(1)"
        />
        <TextButton
          :status="true"
          :saving="false"
          :disabled="!formOK"
          title="Save and View Ticket"
          text="Save and View Ticket"
          @click="submitForm(2)"
        />
        <TextButton
          :status="true"
          :saving="false"
          :disabled="!formOK"
          title="Save and Create Another"
          text="Save and Create Another"
          @click="submitForm(3)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '../common/spinner'
import TicketNote from './ticketNote'
import AttachFiles from './attachFiles'
import PortalDropdown from '../common/portalDropdown'
import TextButton from '../common/textButton'
import { autotaskAPIMixin } from '../../mixins/autotaskAPIMixin'
import { autotaskServiceMixin } from '../../mixins/autotaskServiceMixin'
import { ticketMixin } from '../../mixins/ticket'
import { DataEventBus } from '../../events/dataEvents'
import { mapActions } from 'vuex'

export default {
  name: 'CreateTicket',
  components: {
    Spinner,
    TicketNote,
    AttachFiles,
    PortalDropdown,
    TextButton
  },
  mixins: [
    autotaskAPIMixin,
    autotaskServiceMixin,
    ticketMixin
  ],
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true,
      note: 'If the dialog is shown or not.'
    }
  },
  data () {
    return {
      ticket: {
        title: null,
        description: null,
        priorityId: null,
        ticketTypeId: null,
        accountId: null,
        contactId: null,
        customFields: []
      },
      files: [],
      configurationItemId: null,
      requestTypeId: null,
      products: [],
      configurationItemTypes: [],
      configurationItems: [],
      priorities: [],
      requestTypeError: false,
      titleError: false,
      descriptionError: false,
      siteError: false,
      priorityError: false,
      extraFieldsError: false,
      isSaving: false,
      showRequestTypeSection: true,
      showTicketDetailSection: false,
      showCustomFieldsSection: false,
      showAttachmentSection: false,
      showExtraFieldsSection: false,
      showConfigurationItemSelection: true,
      configurationItemsLoading: false,
      requestTypeSectionCompleted: false,
      ticketDetailSectionCompleted: false,
      customFieldsSectionCompleted: false,
      attachmentSectionCompleted: true,
      oldTicketDescription: '',
      formOK: false,
      saving: false,
      recipients: '',
      subIssueTypeId: 0,
      mobileNetwork: 0
    }
  },
  computed: {
    requestTypes () {
      var requestType = []
      if (this.hasContractBlocks) {
        requestType =
          [
            { id: 1, ticketTypeId: 2, name: 'Incident', description: '', ticketCategoryId: 102 },
            { id: 2, ticketTypeId: 3, name: 'Query', description: '', ticketCategoryId: 137 },
            { id: 3, ticketTypeId: 1, name: 'MAC Request (Using MAC Pack Contract)', description: '', ticketCategoryId: 171 },
            { id: 5, ticketTypeId: 2, name: 'Mobile Operations', description: '', ticketCategoryId: 113 }
          ]
      } else {
        requestType =
          [
            { id: 1, ticketTypeId: 2, name: 'Incident', description: '', ticketCategoryId: 102 },
            { id: 2, ticketTypeId: 3, name: 'Query', description: '', ticketCategoryId: 137 },
            { id: 4, ticketTypeId: 1, name: 'Change Request (Time & Materials)', description: '', ticketCategoryId: 179 },
            { id: 5, ticketTypeId: 2, name: 'Mobile Operations', description: '', ticketCategoryId: 113 }
          ]
      }
      return requestType
    },
    ticketFields () {
      const ticketField =
        [
          { id: 'configurationItemID', requestTypeId: 1, name: 'configurationItemID', description: 'Configuration item', dataType: 'configurationItemID', isCustomField: 0, isPickList: 0, isRequired: true, maxlength: 0, display: true },
          { id: 'CustomerReference', requestTypeId: 1, name: 'Customer Reference', description: 'Customer Reference', dataType: 'string', isCustomField: 1, isPickList: 0, isRequired: true, maxlength: 20, display: true },
          { id: 'specialPriority1', requestTypeId: 1, name: 'specialPriority1', description: 'How urgent is your request?', dataType: 'select', isCustomField: 0, isPickList: 0, isRequired: true, maxlength: 20, display: true },
          { id: 'specialPriority2', requestTypeId: 1, name: 'specialPriority2', description: 'Who is impacted?', dataType: 'select', isCustomField: 0, isPickList: 0, isRequired: true, maxlength: 20, display: true },
          { id: 'specialDescription1', requestTypeId: 1, name: 'specialDescription1', description: 'Please list any troubleshooting steps you have taken?', dataType: 'textarea', isCustomField: 0, isPickList: 0, isRequired: true, maxlength: 500, display: true },
          { id: 'specialDescription2', requestTypeId: 1, name: 'specialDescription2', description: 'Please list any error message/codes or status lights that appear?', dataType: 'textarea', isCustomField: 0, isPickList: 0, isRequired: true, maxlength: 500, display: true },
          { id: 'specialDescription3', requestTypeId: 1, name: 'specialDescription3', description: 'If you are unsure about which configuration item you have chosen, please describe which system you are reporting your issue against.', dataType: 'textarea', isCustomField: 0, isPickList: 0, isRequired: true, maxlength: 500, display: true },
          { id: 'CustomerReference', requestTypeId: 2, name: 'Customer Reference', description: 'Customer Reference', dataType: 'string', isCustomField: 1, isPickList: 0, isRequired: true, maxlength: 20, display: true },
          { id: 'CustomerReference', requestTypeId: 3, name: 'Customer Reference', description: 'Customer Reference', dataType: 'string', isCustomField: 1, isPickList: 0, isRequired: true, maxlength: 20, display: true },
          { id: 'CustomerReqDate', requestTypeId: 3, name: 'Customer Req. Date', description: 'The date of change requested', dataType: 'datetime', isCustomField: 1, isPickList: 0, isRequired: true, maxlength: 0, display: true },
          { id: 'CustomerReference', requestTypeId: 4, name: 'Customer Reference', description: 'Customer Reference', dataType: 'string', isCustomField: 1, isPickList: 0, isRequired: true, maxlength: 20, display: true },
          { id: 'CustomerReqDate', requestTypeId: 4, name: 'Customer Req. Date', description: 'The date of change requested', dataType: 'datetime', isCustomField: 1, isPickList: 0, isRequired: true, maxlength: 0, display: true }
        ]
      return ticketField
    },
    priorityFields () {
      const priorityFields =
        [
          { id: 9, requestTypeId: 1, name: 'P4 - Low', isDefault: 0 },
          { id: 8, requestTypeId: 1, name: 'P3 - Medium', isDefault: 1 },
          { id: 7, requestTypeId: 1, name: 'P2 - High', isDefault: 0 },
          { id: 5, requestTypeId: 1, name: 'P1 – Critical', isDefault: 0 },
          { id: 6, requestTypeId: 1, name: 'MI - Major Incident', isDefault: 0 },
          { id: 10, requestTypeId: 2, name: 'None', isDefault: 1 },
          { id: 15, requestTypeId: 3, name: 'Standard Change', isDefault: 1 },
          { id: 24, requestTypeId: 3, name: 'Emergency Change', isDefault: 0 },
          { id: 15, requestTypeId: 4, name: 'Standard Change', isDefault: 1 },
          { id: 24, requestTypeId: 4, name: 'Emergency Change', isDefault: 0 },
          { id: 10, requestTypeId: 4, name: 'None (Planned Maintenance)', isDefault: 0 },
          { id: 9, requestTypeId: 5, name: 'P4 - Low', isDefault: 0 },
          { id: 8, requestTypeId: 5, name: 'P3 - Medium', isDefault: 1 },
          { id: 7, requestTypeId: 5, name: 'P2 - High', isDefault: 0 },
          { id: 5, requestTypeId: 5, name: 'P1 – Critical', isDefault: 0 }
        ]
      return priorityFields
    },
    priorityList () {
      return this.$store.getters.priorityList
    },
    mobileNetworks () {
      return [
        { 'id': 1, 'name': 'Cellhire (Vodafone)' },
        { 'id': 2, 'name': 'EE' },
        { 'id': 3, 'name': 'Multi Network' },
        { 'id': 4, 'name': 'O2' },
        { 'id': 5, 'name': 'Vodafone' },
        { 'id': 6, 'name': 'EE/MDEE' }
      ]
    },
    subIssueTypes () {
      return [
        { 'id': 1, 'name': 'Mobile Add/Remove Services', 'IssueTypeId': '32', 'subIssueTypeId': '387' },
        { 'id': 2, 'name': 'Mobile Billing Queries', 'IssueTypeId': '32', 'subIssueTypeId': '313' },
        { 'id': 3, 'name': 'Mobile Fault', 'IssueTypeId': '32', 'subIssueTypeId': '277' },
        { 'id': 4, 'name': 'General Query', 'IssueTypeId': '32', 'subIssueTypeId': '314' },
        { 'id': 5, 'name': 'Mobile Hardware and Connection', 'IssueTypeId': '32', 'subIssueTypeId': '252' },
        { 'id': 6, 'name': 'Mobile Hardware Only', 'IssueTypeId': '32', 'subIssueTypeId': '258' },
        { 'id': 7, 'name': 'Mobile Leaver', 'IssueTypeId': '32', 'subIssueTypeId': '405' },
        { 'id': 8, 'name': 'Mobile Reallocation', 'IssueTypeId': '32', 'subIssueTypeId': '254' },
        { 'id': 9, 'name': 'Mobile New Connection(s)', 'IssueTypeId': '32', 'subIssueTypeId': '261' },
        { 'id': 10, 'name': 'Mobile New Starter', 'IssueTypeId': '32', 'subIssueTypeId': '259' },
        { 'id': 11, 'name': 'Mobile Bar/Unbar', 'IssueTypeId': '32', 'subIssueTypeId': '260' },
        { 'id': 12, 'name': 'Mobile PAC Request', 'IssueTypeId': '32', 'subIssueTypeId': '385' },
        { 'id': 13, 'name': 'Mobile Port In', 'IssueTypeId': '32', 'subIssueTypeId': '386' },
        { 'id': 14, 'name': 'Mobile Port Out', 'IssueTypeId': '32', 'subIssueTypeId': '404' },
        { 'id': 15, 'name': 'Mobile SIM Swap', 'IssueTypeId': '32', 'subIssueTypeId': '262' },
        { 'id': 16, 'name': 'Mobile Tariff Change', 'IssueTypeId': '32', 'subIssueTypeId': '315' },
        { 'id': 17, 'name': 'Mobile Bolt On', 'IssueTypeId': '32', 'subIssueTypeId': '402' },
        { 'id': 18, 'name': 'Mobile Unlocking', 'IssueTypeId': '32', 'subIssueTypeId': '390' },
        { 'id': 19, 'name': 'Mobile Username/Cost Centre Change', 'IssueTypeId': '32', 'subIssueTypeId': '389' },
        { 'id': 20, 'name': 'Mobile SIM Card Order Only', 'IssueTypeId': '32', 'subIssueTypeId': '401' }
      ]
    },
    subIssueTypeFields () {
      return [
        { 'subIssueTypeId': 1, 'name': 'Mobile Number', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 1, 'name': 'Service Required', 'dataType': 'string', 'id': 'txtServiceRequired', 'description': 'Service Required', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 1, 'name': 'Service To Be Removed', 'dataType': 'string', 'id': 'txtServiceToBeRemoved', 'description': 'Service To Be Removed', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 2, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 2, 'name': 'Mobile Number(s) Effected', 'dataType': 'string', 'id': 'txtMobileNumbers', 'description': 'Mobile Number(s) Effected', 'maxlength': 400, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 2, 'name': 'Invoice Date/Number', 'dataType': 'string', 'id': 'txtInvoiceNumber', 'description': 'Invoice Date/Number', 'maxlength': 50, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 2, 'name': 'Billing Query Description', 'dataType': 'string', 'id': 'txtBillingQueryDescription', 'description': 'Billing Query Description', 'maxlength': 255, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 3, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 3, 'name': 'Mobile Number Effected', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 3, 'name': 'Device Model', 'dataType': 'string', 'id': 'txtDeviceModel', 'description': 'Device Model', 'maxlength': 50, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 3, 'name': 'Fault Start Date', 'dataType': 'datetime', 'id': 'txtFaultStartDate', 'description': 'Fault Start Date', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 3, 'name': 'Description of Fault', 'dataType': 'string', 'id': 'txtDescriptionOfFault', 'description': 'Description of Fault', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 3, 'name': 'IMEI', 'dataType': 'string', 'id': 'txtIMEI', 'description': 'IMEI', 'maxlength': 15, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 4, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 4, 'name': 'Requestor Name', 'dataType': 'string', 'id': 'txtRequestorName', 'description': 'Requestor Name', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 4, 'name': 'Requestor Email Address', 'dataType': 'string', 'id': 'txtRequestorEmailAddress', 'description': 'Requestor Email Address', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 4, 'name': 'Requestor Contact Number', 'dataType': 'string', 'id': 'txtRequestorContactNumber', 'description': 'Requestor Contact Number', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 4, 'name': 'Mobile Number of the request', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number of the request', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 4, 'name': 'Query:', 'dataType': 'string', 'id': 'txtQuery', 'description': 'Query:', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': 'If you are logging this on someone else’s behalf please provide relevant contact details' },
        { 'subIssueTypeId': 5, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'SIM Number', 'dataType': 'string', 'id': 'txtSIMNumber', 'description': 'SIM Number', 'maxlength': 35, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'New SIM Required', 'dataType': 'select', 'id': 'cboSIMRequired', 'description': 'New SIM Required', 'maxlength': 3, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'Tariff Required', 'dataType': 'string', 'id': 'txtTariffRequired', 'description': 'Tariff Required', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'Additional Services Needed', 'dataType': 'checkbox', 'id': 'chkAdditionalServicesNeeded', 'description': 'Additional Services Needed', 'maxlength': 3, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'Additional Services Description', 'dataType': 'string', 'id': 'txtAdditionalServicesDescription', 'description': 'Additional Services Description', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': false, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'Device Model', 'dataType': 'string', 'id': 'txtDeviceModel', 'description': 'Device Model', 'maxlength': 50, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'Invoice or Kit fund', 'dataType': 'select', 'id': 'cboInvoiceOrKitFund', 'description': 'Invoice or Kit fund', 'maxlength': 30, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'Username', 'dataType': 'string', 'id': 'txtMobileUsername', 'description': 'Username', 'maxlength': 50, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'Cost Centre', 'dataType': 'string', 'id': 'txtCostCentre', 'description': 'Cost Centre', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'PO Number', 'dataType': 'string', 'id': 'txtPONumber', 'description': 'PO Number', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 5, 'name': 'Delivery Address', 'dataType': 'textarea', 'id': 'txtDeliveryAddress', 'description': 'Delivery Address', 'maxlength': 1000, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 6, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 6, 'name': 'Hardware Required', 'dataType': 'string', 'id': 'txtHardwareRequired', 'description': 'Hardware Required', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': 'Include Model, Memory Variant, Colour' },
        { 'subIssueTypeId': 6, 'name': 'Accessories Required', 'dataType': 'checkbox', 'id': 'chkAdditionalServicesNeeded', 'description': 'Accessories Required', 'maxlength': 3, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 6, 'name': 'Accessory Description', 'dataType': 'string', 'id': 'txtAdditionalServicesDescription', 'description': 'Accessories Description', 'maxlength': 255, 'default': '', 'isRequired': false, 'udfField': '', 'display': false, 'placeholder': '' },
        { 'subIssueTypeId': 6, 'name': 'Invoice or Kit fund', 'dataType': 'select', 'id': 'cboInvoiceOrKitFund', 'description': 'Invoice or Kit fund', 'maxlength': 30, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 6, 'name': 'PO Number', 'dataType': 'string', 'id': 'txtPONumber', 'description': 'PO Number', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 6, 'name': 'Username', 'dataType': 'string', 'id': 'txtMobileUsername', 'description': 'Username', 'maxlength': 50, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 6, 'name': 'SIM Required', 'dataType': 'select', 'id': 'cboSIMRequired', 'description': 'SIM Required', 'maxlength': 3, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 6, 'name': 'Number of Handsets', 'dataType': 'integer', 'id': 'txtNumberOfHandsets', 'description': 'Number of Handsets', 'maxlength': 3, 'default': 1, 'isRequired': true, 'udfField': 'Number of Handsets', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 6, 'name': 'Delivery Address', 'dataType': 'textarea', 'id': 'txtDeliveryAddress', 'description': 'Delivery Address', 'maxlength': 1000, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 7, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 7, 'name': 'Mobile Number', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 7, 'name': 'Assign to Reallocation', 'dataType': 'select', 'id': 'cboAssignToReallocation', 'description': 'Assign to Reallocation', 'maxlength': 3, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 7, 'name': 'PAC Code', 'dataType': 'select', 'id': 'cboPACCode', 'description': 'PAC Code', 'maxlength': 3, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 8, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 8, 'name': 'SIM Number', 'dataType': 'string', 'id': 'txtSIMNumber', 'description': 'SIM Number', 'maxlength': 35, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 8, 'name': 'Username', 'dataType': 'string', 'id': 'txtMobileUsername', 'description': 'Username', 'maxlength': 50, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 8, 'name': 'Cost Centre', 'dataType': 'string', 'id': 'txtCostCentre', 'description': 'Cost Centre', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 9, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 9, 'name': 'SIM Number', 'dataType': 'string', 'id': 'txtSIMNumber', 'description': 'SIM Number', 'maxlength': 50, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 9, 'name': 'SIM Required', 'dataType': 'select', 'id': 'cboSIMRequired', 'description': 'SIM Required', 'maxlength': 3, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 9, 'name': 'Tariff Required', 'dataType': 'string', 'id': 'txtTariffRequired', 'description': 'Tariff Required', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 9, 'name': 'Lead Number', 'dataType': 'string', 'id': 'txtLeadNumber', 'description': 'Lead Number', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 9, 'name': 'Additional Services Needed', 'dataType': 'checkbox', 'id': 'chkAdditionalServicesNeeded', 'description': 'Additional Services Needed', 'maxlength': 3, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 9, 'name': 'Additional Services Description', 'dataType': 'string', 'id': 'txtAdditionalServicesDescription', 'description': 'Additional Services Description', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': false, 'placeholder': '' },
        { 'subIssueTypeId': 9, 'name': 'Username', 'dataType': 'string', 'id': 'txtMobileUsername', 'description': 'Username', 'maxlength': 50, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 9, 'name': 'Delivery Address', 'dataType': 'textarea', 'id': 'txtDeliveryAddress', 'description': 'Delivery Address', 'maxlength': 1000, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'SIM Number', 'dataType': 'string', 'id': 'txtSIMNumber', 'description': 'SIM Number', 'maxlength': 35, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'SIM Required', 'dataType': 'select', 'id': 'cboSIMRequired', 'description': 'SIM Required', 'maxlength': 3, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Tariff Required', 'dataType': 'string', 'id': 'txtTariffRequired', 'description': 'Tariff Required', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Lead Number', 'dataType': 'string', 'id': 'txtLeadNumber', 'description': 'Lead Number', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Additional Services Needed', 'dataType': 'checkbox', 'id': 'chkAdditionalServicesNeeded', 'description': 'Additional Services Needed', 'maxlength': 3, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Additional Services Description', 'dataType': 'string', 'id': 'txtAdditionalServicesDescription', 'description': 'Additional Services Description', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': false, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Username', 'dataType': 'string', 'id': 'txtMobileUsername', 'description': 'Username', 'maxlength': 50, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Device Model', 'dataType': 'string', 'id': 'txtDeviceModel', 'description': 'Device Model', 'maxlength': 50, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Handset Required', 'dataType': 'select', 'id': 'cboHandsetRequired', 'description': 'Handset Required', 'maxlength': 3, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Cost Centre', 'dataType': 'string', 'id': 'txtCostCentre', 'description': 'Cost Centre', 'maxlength': 30, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Invoice or Kit fund', 'dataType': 'select', 'id': 'cboInvoiceOrKitFund', 'description': 'Invoice or Kit fund', 'maxlength': 30, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 10, 'name': 'Delivery Address', 'dataType': 'textarea', 'id': 'txtDeliveryAddress', 'description': 'Delivery Address', 'maxlength': 1000, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 11, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 11, 'name': 'Mobile Number', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 11, 'name': 'Bar Required', 'dataType': 'select', 'id': 'cboBarRequired', 'description': 'Bar Required', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 11, 'name': 'Unbar Required', 'dataType': 'string', 'id': 'txtUnbarRequired', 'description': 'Unbar Required', 'maxlength': 50, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 12, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 12, 'name': 'Mobile Number(s)', 'dataType': 'string', 'id': 'txtMobileNumbers', 'description': 'Mobile Number(s)', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 13, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 13, 'name': 'Mobile Number', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 13, 'name': 'SIM Number', 'dataType': 'string', 'id': 'txtSIMNumber', 'description': 'SIM Number', 'maxlength': 35, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 13, 'name': 'SIM Required', 'dataType': 'select', 'id': 'cboSIMRequired', 'description': 'SIM Required', 'maxlength': 3, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 13, 'name': 'Tariff Required', 'dataType': 'string', 'id': 'txtTariffRequired', 'description': 'Tariff Required', 'maxlength': 255, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 13, 'name': 'Contract Length', 'dataType': 'string', 'id': 'txtContractLength', 'description': 'Contract Length', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 13, 'name': 'PAC Code', 'dataType': 'string', 'id': 'txtPACCode', 'description': 'PAC Code', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 13, 'name': 'Port In Date', 'dataType': 'datetime', 'id': 'txtPortInDate', 'description': 'Port In Date', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 13, 'name': 'Delivery Address', 'dataType': 'textarea', 'id': 'txtDeliveryAddress', 'description': 'Delivery Address', 'maxlength': 1000, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 14, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 14, 'name': 'Mobile Number', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 15, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 15, 'name': 'Username', 'dataType': 'string', 'id': 'txtMobileUsername', 'description': 'Username', 'maxlength': 50, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 15, 'name': 'Cost Centre', 'dataType': 'string', 'id': 'txtCostCentre', 'description': 'Cost Centre', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 15, 'name': 'Mobile Number', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 15, 'name': 'SIM Number', 'dataType': 'string', 'id': 'txtSIMNumber', 'description': 'SIM Number', 'maxlength': 35, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 15, 'name': 'SIM Required', 'dataType': 'select', 'id': 'cboSIMRequired', 'description': 'SIM Required', 'maxlength': 3, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 15, 'name': 'Delivery Address', 'dataType': 'textarea', 'id': 'txtDeliveryAddress', 'description': 'Delivery Address', 'maxlength': 1000, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 16, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 16, 'name': 'Mobile Number', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 16, 'name': 'Username', 'dataType': 'string', 'id': 'txtMobileUsername', 'description': 'Username', 'maxlength': 50, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 16, 'name': 'Cost Centre', 'dataType': 'string', 'id': 'txtCostCentre', 'description': 'Cost Centre', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 16, 'name': 'Desired Tariff', 'dataType': 'string', 'id': 'txtDesiredTariff', 'description': 'Desired Tariff', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 16, 'name': 'Contract Length', 'dataType': 'string', 'id': 'txtDurationOfContract', 'description': 'Contract Length', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 17, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 17, 'name': 'Mobile Number', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 17, 'name': 'Desired Bolt On', 'dataType': 'string', 'id': 'txtDesiredBoltOn', 'description': 'Desired Bolt On', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 18, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 18, 'name': 'Mobile Number of Handset', 'dataType': 'string', 'id': 'txtMobileNumberUsedInHandset', 'description': 'Mobile Number of Handset', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 18, 'name': 'IMEI Number', 'dataType': 'string', 'id': 'txtIMEINumber', 'description': 'IMEI Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 19, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 19, 'name': 'Mobile Number', 'dataType': 'string', 'id': 'txtMobileNumber', 'description': 'Mobile Number', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 19, 'name': 'New Username', 'dataType': 'string', 'id': 'txtNewUsername', 'description': 'New Username', 'maxlength': 50, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 19, 'name': 'New Cost Centre', 'dataType': 'string', 'id': 'txtNewCostCentre', 'description': 'New Cost Centre', 'maxlength': 25, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 20, 'name': 'Customer Reference', 'dataType': 'string', 'id': 'txtCustomerReference', 'description': 'Customer Reference', 'maxlength': 25, 'default': 'N/A', 'isRequired': false, 'udfField': 'Customer Reference', 'display': true, 'placeholder': '' },
        { 'subIssueTypeId': 20, 'name': 'SIM Type', 'dataType': 'select', 'id': 'cboSIMType', 'description': 'SIM Type', 'maxlength': 25, 'default': '', 'isRequired': true, 'udfField': '', 'display': true, 'placeholder': 'Trippple SIM, E-SIM etc...' },
        { 'subIssueTypeId': 20, 'name': 'Delivery Address', 'dataType': 'textarea', 'id': 'txtDeliveryAddress', 'description': 'Delivery Address', 'maxlength': 1000, 'default': '', 'isRequired': false, 'udfField': '', 'display': true, 'placeholder': '' }
      ]
    },
    configurationItemTypeList () {
      return this.$store.getters.configurationItemTypeList
    },
    autotaskContactId () {
      if (this.$store.getters.autotaskContact.length === 0) {
        return 0
      } else {
        return this.$store.getters.autotaskContact[0].id
      }
    },
    configItemPlaceholder () {
      if (this.configurationItemsLoading) {
        return 'Loading, please wait...'
      } else if (this.ticket.accountId == null) {
        return 'Please select a configuration type'
      } else if (this.configurationItems.length === 0) {
        return 'No configuration items for the selected site'
      } else {
        return 'Select Configuration Item'
      }
    },
    configItemTypePlaceholder () {
      if (this.configurationItemsLoading) {
        return 'Loading, please wait...'
      } else if (this.ticket.accountId == null) {
        return 'Please select a site'
      } else if (this.configurationItems.length === 0) {
        return 'No issue areas for the selected site'
      } else {
        return 'Select Issue Area'
      }
    },
    hasContractBlocks () {
      return this.$store.getters.contractBlocks.length > 0
    },
    currentSites () {
      return this.$store.getters.sites
    },
    sites () {
      if (this.requestTypeId === 5 && this.currentSites) {
        var mobSites = this.currentSites.filter(s => s.name.indexOf('AZM') > -1)
        if (mobSites.length > 0) {
          return mobSites
        } else {
          return this.currentSites
        }
      } else if (this.currentSites) {
        var stdSites = this.currentSites.filter(s => s.name.indexOf('AZM') === -1)
        if (stdSites.length > 0) {
          return stdSites
        } else {
          return this.currentSites
        }
      } else {
        this.$store.commit('showMessage', { content: 'No sites with active configuration items were resolved. You may not have permissions to be able to see them.', color: 'red', timeout: 5000 })
      }
    }
  },
  watch: {
    dialog () {
      if (this.dialog) {
        this.clearForm()
      }
    }
  },
  mounted () {
    this.configurationItemTypes = []
  },
  methods: {
    ...mapActions([
      'setTickets',
      'setConfigurationItemTypeList'
    ]),
    setForm () {
      this.customFieldsSectionCompleted = false
      this.requestTypeError = !this.requestTypeId
      this.requestTypeSectionCompleted = this.requestTypeId
      this.showTicketDetailSection = true
      this.showCustomFieldsSection = true
      this.setExtraFields(this.requestTypeId, 'requestType')
      this.getPriorityFields(this.requestTypeId)
      if (this.$store.getters.autotaskContact.length > 0) {
        this.ticket.contactId = this.$store.getters.autotaskContact[0].id
      }
    },
    /**********************************************
    * This is where the ticket is physically
    * added into Autotask. The process followed
    * is:
    * 1. Get the payload for the new ticket.
    * 2. resolve the contact and add to the payload.
    *   a. if found, added to the ticket contact field.
    *   b. if not, then the creator neme is added to the description of the ticket.
    * 3. Send the ticket to Autotask API service.
    * 4. If successful:
    *   a. Upload the associated file(s).
    *   b. Clear and close the page.
    *   c. Add the ticket to the Autotask Database.
    * 5. If not:
    *   a. Set the old description back in place.
    *
    * The parameter postAction may be one of the following:
    * 1 = Save and Close
    * 2 = Save and View Ticket
    * 3 = Save and Create Another
    **********************************************/
    submitForm: async function (postAction) {
      this.saving = true
      if (this.formOK && this.ticket) {
        var newTicket = this.getNewTicketPayload()

        var rtn = await this.setNewContact(this.ticket.accountId)
        if (isNaN(rtn) && this.ticket.contactId === 0) {
          this.$store.commit('showMessage', { content: 'failed to get Autotask contact with: ' + rtn, color: 'red', timeout: 5000 })
          return
        } else {
          // We try to add a contact for the ticket's site if we cannot find one. If the returned contact ID is zero,
          // we can neither find an existing contact for the user or create a new one, therefore we don't want to log
          // the ticket
          if (rtn === 0) {
            this.$store.commit('showMessage', { content: 'Failed to get or add Autotask contact for user: ' + this.$store.getters.currentUser.emailAddress, color: 'red', timeout: 5000 })
            // Switch off the "saving" flag or the screen appears to hang
            this.saving = false
            return
          } else {
            // If fred@maintel.co.uk has access to sites for parents 8192 and 8194, he should have contacts against
            // both sites. The setNewContact method should have picked the correct contact ID for the site he has
            // selected, so this is the contact that we should use. Otherwise we might try to create a ticket for
            // an 8194 site using his contact ID for 8192, or vice versa
            this.ticket.contactId = rtn
          }
        }

        var result = await this.addNewTicket(newTicket)
        if (!result.itemId) {
          newTicket.description = this.oldTicketDescription
          this.$store.commit('showMessage', { content: 'New ticket failed to create with: ' + result, color: 'red', timeout: 3000 })
        } else {
          var ticketId = result.itemId
          await this.uploadFiles(ticketId)
          this.$store.commit('showMessage', { content: 'New ticket created successfully.', color: 'green', timeout: 3000 })
          await this.addCreatedTicket(ticketId)

          // All is OK, so choose the correct way to update the ticket screen.
          if (postAction === 1) {
            // Save and Close
            this.clearFormAndClose()
          } else if (postAction === 2) {
            // Save and View Ticket
            this.clearFormAndViewTicket(ticketId)
          } else if (postAction === 3) {
            // Save and Create Another
            this.clearForm()
          }
        }
      } else {
        newTicket.description = this.oldTicketDescription
        this.$store.commit('showMessage', { content: 'The form failed to check correctly.', color: 'orange', timeout: 3000 })
      }
      this.saving = false
    },
    selectRequestType () {
      this.ticket.ticketTypeId = this.requestTypes.find(r => r.id === this.requestTypeId).ticketTypeId
      this.setForm()
      this.checkForm({ target: { className: 'requestType' } })
      DataEventBus.$emit('still-active')
    },
    setConfigurationItems (item) {
      this.configurationItemId = item.id
    },
    selectSite: function (site) {
      if (site) {
        if (site.id) {
          this.ticket.accountId = site.id
          if (this.requestTypeId === 1) {
            this.configurationItemId = null
            this.configurationItems = []
            this.setConfigItemTypeList(site.id)
          }
          this.checkForm({ target: { className: 'ticketSite' } })
          this.checkExtraFields()
          // this.ticket.contactId = this.getSiteContact(site)
        }
      }
    },
    selectSubIssueType: function (subIssueType) {
      if (subIssueType.target) {
        if (subIssueType.target.value) {
          // var fields = this.subIssueTypeFields.filter(s => s.subIssueTypeId === subIssueType.id)
          this.subIssueTypeId = subIssueType.target.value
          this.setExtraFields(subIssueType.target.value, 'mobileOperation')
          this.checkForm(({ target: { className: 'mobileOperation' } }))
        }
      }
    },
    setConfigurationItemType: async function (item, value) {
      if (!this.configurationItemsLoading) {
        this.configurationItemsLoading = true
        this.configurationItems = []
        var val = document.getElementById(item.target.id)
        if (value) {
          this.configurationItemType = value
        } else {
          this.configurationItemType = val.options[val.selectedIndex].text
        }
        var type = this.configurationItemTypeList.filter(ci => ci.name === this.configurationItemType)
        if (type.length > 0) {
          if (this.ticket.accountId) {
            var rtn = await this.getAutotaskConfigItemsByTypeAndSite(type[0].value, this.ticket.accountId)
            if (rtn.length > 0) {
              var list = rtn.map(ci => ci.productId)
              var products = await this.getAutotaskProductList(list.slice(0, 460))
              rtn.forEach(ci => {
                if (ci.configurationItemType !== null && ci.configurationItemType !== undefined && products.length > 0) {
                  var pdct = products.find(p => p.id === ci.productId)
                  var nm = ''
                  if (ci.referenceTitle !== null) {
                    nm += ci.referenceTitle
                  }
                  if (this.configurationItemType !== undefined) {
                    if (nm.length > 0) {
                      nm += ' - ' + this.configurationItemType
                    } else {
                      nm += this.configurationItemType
                    }
                  }
                  if (pdct !== undefined) {
                    if (nm.length > 0) {
                      nm += ' - ' + pdct.name
                    } else {
                      nm += pdct.name
                    }
                    // only show 50 characters of the description if found
                    if (pdct.description !== null && pdct.description.length > 0) {
                      if (nm.length > 0) {
                        nm += ' - ' + pdct.description.substring(0, 50)
                      } else {
                        nm += pdct.description.substring(0, 50)
                      }
                    }
                    if (ci.serialNumber !== null) {
                      if (nm.length > 0) {
                        nm += ' - ' + ci.serialNumber
                      } else {
                        nm += ci.serialNumber
                      }
                    }
                  }
                  ci.name = nm
                }
              })
            } else {
              rtn = []
            }
          } else {
            rtn = []
          }
          this.configurationItems = rtn
          if (rtn.length > 0) {
            this.configurationItemId = rtn[0].id
          }
        }
        this.configurationItemsLoading = false
      }
    },
    setConfigItemTypeList: async function (id) {
      this.configurationItemTypes = await this.getValidIssueTypes(id)
      if (this.configurationItemTypes.length > 0) {
        var item = {}
        item.target = {}
        item.target.id = 'cboConfigurationItemType'
        await this.setConfigurationItemType(item, this.configurationItemTypes[0].name)
      } else {
        this.$store.commit('showMessage', { content: 'You cannot raise an incident ticket against this site as it does not contain any configuration items.', color: 'red', timeout: 4000 })
      }
    },
    getPriorityFields (id) {
      this.priorities = this.priorityFields.filter(p => p.requestTypeId === id)
      this.ticket.priorityId = this.priorities.find(p => p.isDefault === 1).id
    },
    setPriority () {
      var sp1 = document.getElementById('specialPriority1').value
      var sp2 = document.getElementById('specialPriority2').value
      // 9 = P4 - Low
      if ((sp1 === 'Low' && sp2 === 'Only Me') || (sp1 === 'Low' && sp2 === 'Others') || (sp1 === 'Medium' && sp2 === 'Only Me')) { this.ticket.priorityId = 9 }
      // 8 = P3 - Medium
      if ((sp1 === 'Low' && sp2 === 'Company') || (sp1 === 'Medium' && sp2 === 'Others') || (sp1 === 'High' && sp2 === 'Only Me')) { this.ticket.priorityId = 8 }
      // 7 = P2 - High
      if ((sp1 === 'Medium' && sp2 === 'Company') || (sp1 === 'High' && sp2 === 'Others') || (sp1 === 'High' && sp2 === 'Company')) { this.ticket.priorityId = 7 }
    },
    getNewTicketPayload () {
      this.oldTicketDescription = this.ticket.description.toString()
      if (this.requestTypeId === 1) { this.setPriority() }
      if (this.requestTypeId === 2) { this.ticket.priorityId = 10 }
      var newTicket = this.ticket
      newTicket.title = newTicket.title.replace(RegExp('\'', 'g'), '`')
      newTicket.description = newTicket.description.replace(RegExp('\'', 'g'), '`')
      var requestType = this.requestTypes.filter(t => t.id === this.requestTypeId)
      newTicket.categoryId = requestType[0].ticketCategoryId
      var customFields
      var description = ''
      if (this.requestTypeId < 5) {
        customFields = this.ticketFields.filter(t => t.requestTypeId === this.requestTypeId)
      } else {
        customFields = this.subIssueTypeFields.filter(s => s.subIssueTypeId.toString() === this.subIssueTypeId && s.udfField === '')
        var subIssueTypeField = this.subIssueTypes.find(s => s.id.toString() === this.subIssueTypeId)
        newTicket.issueTypeId = parseInt(subIssueTypeField.IssueTypeId)
        newTicket.subIssueTypeId = parseInt(subIssueTypeField.subIssueTypeId)
        var mobNetwork = this.mobileNetworks.find(m => m.id === this.mobileNetwork)
        description += '\n\n' + 'Mobile Network: ' + mobNetwork.name
        var udfFields = this.subIssueTypeFields.filter(s => s.subIssueTypeId.toString() === this.subIssueTypeId && s.udfField !== '')
        udfFields.forEach(uField => {
          var value = document.getElementById(uField.id).value.replace(RegExp('\'', 'g'), '`')
          var udf = {}
          udf.Name = uField.udfField
          udf.Value = value
          udf.IsCustomField = true
          newTicket.customFields.push(udf)
        })
      }
      customFields.forEach(customField => {
        var val = ''
        if (document.getElementById(customField.id).value) {
          val = document.getElementById(customField.id).value.replace(RegExp('\'', 'g'), '`')
        }
        if (customField.id.indexOf('specialPriority') < 0) {
          if (customField.id.length > 18 && customField.id.substring(0, 18) === 'specialDescription') {
            description += '\n\nQ. ' + customField.description + '\nA. ' + val
          } else if (this.subIssueTypeId > 0) {
            description += '\n\n' + customField.description + ': ' + val
          } else {
            if (customField.dataType === 'configurationItemID') {
              val = this.configurationItemId
            }
            var newTicketCustomField = { name: customField.name, value: val, isCustomField: customField.isCustomField }
            var existingCustomfields = newTicket.customFields
            existingCustomfields.push(newTicketCustomField)
            newTicket.customFields = existingCustomfields
          }
        }
      })
      if (description.length > 0) {
        newTicket.description = newTicket.description + description
      }
      return newTicket
    },
    filesUpdated (files) {
      this.files = files
    },
    uploadFiles: async function (ticketId) {
      if (this.files.length > 0) {
        var autotaskId = null
        if (autotaskId === null) {
          autotaskId = this.ticket.contactId
        }
        if (autotaskId === null && this.$store.getters.autotaskContact.length > 0) {
          autotaskId = this.$store.getters.autotaskContact[0].id
        }
        for (let i = 0; i < this.files.length; i++) {
          var data = this.files[i].file
          var title = this.files[i].title
          if (title.length === 0) { title = this.files[i].name }
          if (data.indexOf(',') > -1) {
            data = data.substring(data.indexOf(',') + 1, data.length)
          }
          var payload = {
            name: title,
            fileName: this.files[i].name,
            data: data,
            contactId: autotaskId,
            isNew: true
          }
          var rtn = await this.addTicketAttachment(ticketId, payload)
          if (rtn === 'error') {
            this.$store.commit('showMessage', { content: 'The ticket attachments failed to add correctly.', color: 'red', timeout: 3000 })
          } else {
            console.log('New Attachment id: ' + rtn.itemId)
            this.$store.commit('showMessage', { content: 'The ticket attachments added correctly.', color: 'green', timeout: 3000 })
            if (this.recipients && this.recipients.length > 0) {
              this.sendNotification(ticketId, this.recipients)
            }
          }
        }
        this.files = []
      }
    },
    checkForm ({ target: { className: checkField, id: checkId } }) {
      if (this.requestTypeId === 1) { this.setPriority() }
      if (checkField) {
        if (checkField.indexOf('requestType') > -1) {
          this.requestTypeError = !this.requestTypeId
        } else if (checkField.indexOf('ticketTitle') > -1) {
          this.titleError = !this.ticket.title
        } else if (checkField.indexOf('ticketDescription') > -1) {
          this.descriptionError = !this.ticket.description
        } else if (checkField.indexOf('ticketSite') > -1) {
          this.siteError = !this.ticket.accountId
        } else if (checkField.indexOf('ticketPriority' && this.requestTypeId > 2) > -1) {
          this.priorityError = !this.ticket.priorityId
        }
      } else {
        this.requestTypeError = !this.requestTypeId
        this.titleError = !this.ticket.title
        this.descriptionError = !this.ticket.description
        this.siteError = !this.ticket.accountId
        this.priorityError = !this.ticket.priorityId
      }
      this.requestTypeSectionCompleted = !this.requestTypeError
      if (this.requestTypeSectionCompleted && this.requestTypeId < 5) {
        this.ticketDetailSectionCompleted = this.ticket.title && this.ticket.description && this.ticket.accountId && this.ticket.priorityId
      } else if (this.requestTypeSectionCompleted && this.requestTypeId === 5) {
        this.ticketDetailSectionCompleted = this.ticket.title && this.ticket.description && this.ticket.accountId && this.ticket.priorityId && this.subIssueTypeId > 0 & this.mobileNetwork > 0
      }
      this.attachmentSectionCompleted = true

      var error = this.titleError || this.descriptionError || this.siteError || this.priorityError

      if (error) {
        var errorMessage = ('Please fix the issues with the following field(s): ' +
            (this.titleError ? 'Ticket title, ' : '') +
            (this.descriptionError ? 'ticket description, ' : '') +
            (this.siteError ? 'ticket site, ' : '') +
            (this.priorityError ? 'ticket priority, ' : '')
        )
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 8000 })
      }

      this.formOK = this.requestTypeSectionCompleted && this.ticketDetailSectionCompleted && this.customFieldsSectionCompleted && this.attachmentSectionCompleted
    },
    checkExtraField (id) {
      var customField
      if (this.requestTypeId === 5) {
        customField = this.subIssueTypeFields.find(t => t.id === id && t.subIssueTypeId.toString() === this.subIssueTypeId)
      } else {
        if (this.requestTypeId === 1) {
          this.setPriority()
        }
        customField = this.ticketFields.find(t => t.id === id)
      }
      var field = document.getElementById(id)
      if (field !== null) {
        if (customField.isRequired && customField.dataType === 'string') {
          if (field.value.length === 0) {
            field.setAttribute('style', 'display:inline-block;border:solid 1px red;border-radius:5px;padding:0 5px;')
            this.$store.commit('showMessage', { content: 'Please enter a value for ' + customField.description, color: 'red', timeout: 10000 })
          } else {
            field.setAttribute('style', 'display:inline-block;border:solid 1px #9aa8b1;border-radius:5px;padding:0 5px;')
          }
        }
        if (customField.isRequired && customField.dataType === 'textarea') {
          if (field.value.length === 0) {
            field.setAttribute('style', 'display:inline-block;border: solid 1px red;border-radius:5px;width:calc(100% - 20px);padding:0 5px;')
            this.$store.commit('showMessage', { content: 'Please enter a value for ' + customField.description, color: 'red', timeout: 10000 })
          } else {
            field.setAttribute('style', 'display:inline-block;border: solid 1px #9aa8b1;border-radius:5px;width:calc(100% - 20px);padding:0 5px;')
          }
        }
        if (customField.isRequired && (customField.dataType === 'datetime' || customField.dataType === 'integer')) {
          if (field.value.length === 0) {
            field.setAttribute('style', 'display:inline-block;border:solid 1px red;border-radius:5px;')
            this.$store.commit('showMessage', { content: 'Please enter a value for ' + customField.description, color: 'red', timeout: 10000 })
          } else {
            field.setAttribute('style', 'display:inline-block;border:solid 1px #9aa8b1;border-radius:5px;')
          }
        }
        if (customField.isRequired && (customField.dataType === 'select')) {
          if (field.value.length === 0) {
            field.setAttribute('style', 'display:inline-block;border:solid 1px red;border-radius:5px;')
            this.$store.commit('showMessage', { content: 'Please enter a value for ' + customField.description, color: 'red', timeout: 10000 })
          } else {
            field.setAttribute('style', 'display:inline-block;border:solid 1px #9aa8b1;border-radius:5px;')
          }
        }
        if (customField.dataType === 'configurationItemID') {
          if (this.configurationItemId === null) {
            this.$store.commit('showMessage', { content: 'Please select a configuration item', color: 'red', timeout: 10000 })
          }
        }
      }
      this.checkExtraFields()
    },
    checkExtraFields () {
      var that = this
      this.customFieldsSectionCompleted = true
      var id = this.requestTypeId
      var customFields
      if (this.requestTypeId < 5) {
        customFields = this.ticketFields.filter(t => t.requestTypeId === id)
      } else if (this.requestTypeId === 5) {
        customFields = this.subIssueTypeFields.filter(t => t.subIssueTypeId.toString() === this.subIssueTypeId)
      }
      customFields.forEach(customField => {
        if (customField.id === 'txtAdditionalServicesDescription') {
          var check = document.getElementById('chkAdditionalServicesNeeded')
          if (check && check.checked) {
            var addDesc = document.getElementById('txtAdditionalServicesDescription')
            if (addDesc.value.length === 0) {
              that.customFieldsSectionCompleted = false
            }
          }
        } else if (customField.dataType === 'configurationItemID') {
          if (that.configurationItemId === null) {
            that.customFieldsSectionCompleted = false
          }
        } else if (customField.id === 'specialPriority1' || customField.id === 'specialPriority2') {
          if (that.ticket.priorityId === undefined) {
            that.customFieldsSectionCompleted = false
          }
        } else {
          var fieldInput = document.getElementById(customField.id)
          if (customField.isRequired && fieldInput.value.length === 0) {
            that.customFieldsSectionCompleted = false
          }
        }
      })
      this.formOK = this.requestTypeSectionCompleted && this.ticketDetailSectionCompleted && this.customFieldsSectionCompleted && this.attachmentSectionCompleted
    },
    setExtraFields (id, type) {
      var that = this
      var customFields
      if (type === 'requestType') {
        customFields = this.ticketFields.filter(t => t.requestTypeId === id)
      } else if (type === 'mobileOperation') {
        customFields = this.subIssueTypeFields.filter(s => s.subIssueTypeId.toString() === id)
      } else {
        return
      }
      var customFieldsDiv = document.getElementById('customFieldsDiv')
      customFieldsDiv.innerHTML = ''
      this.showConfigurationItemSelection = false
      customFields.forEach(customField => {
        var customFieldDiv = document.createElement('div')
        if (customField.display) {
          customFieldDiv.setAttribute('style', 'margin:0 0 5px 5px;width: 100%;')
        } else {
          customFieldDiv.setAttribute('style', 'margin:0 0 5px 5px;width: 100%;display:none;')
        }
        var requiredLabel = document.createElement('label')
        if (customField.isRequired) {
          requiredLabel.setAttribute('style', 'color: red;vertical-align: top;font-weight: bold;margin-right: 5px;')
          requiredLabel.innerHTML = '*'
        } else {
          requiredLabel.setAttribute('style', ';margin-right: 8px;')
          requiredLabel.innerHTML = '&nbsp'
        }
        var fieldLength = 'calc(100% - 264px)'
        if (customField.maxlength < 255) {
          fieldLength = (customField.maxlength * 8) + 'px'
        }
        if (customField.dataType === 'string') {
          var stringInput = document.createElement('input')
          stringInput.type = 'text'
          stringInput.id = customField.id
          if (customField.default) {
            stringInput.value = customField.default
          }
          stringInput.setAttribute('maxlength', customField.maxlength)
          stringInput.setAttribute('width', customField.maxlength)
          if (customField.placeholder && customField.placeholder.length > 0) {
            stringInput.setAttribute('placeholder', customField.placeholder)
          } else {
            stringInput.setAttribute('placeholder', customField.maxlength + ' chars max')
          }
          stringInput.setAttribute('style', 'display:inline-block;border:solid 1px #9aa8b1;border-radius:5px;padding:0 5px;width:' + fieldLength)
          stringInput.addEventListener('blur', function () { that.checkExtraField(customField.id) })
          var stringLabel = document.createElement('label')
          stringLabel.innerHTML = customField.description
          stringLabel.setAttribute('for', customField.id)
          stringLabel.setAttribute('style', 'display:inline-block;margin-right:16px;font-weight:bold;vertical-align:top;min-width:225px;')
          customFieldDiv.appendChild(stringLabel)
          customFieldDiv.appendChild(requiredLabel)
          customFieldDiv.appendChild(stringInput)
        } else if (customField.dataType === 'textarea') {
          var textareaInput = document.createElement('textarea')
          textareaInput.id = customField.id
          if (customField.default) {
            textareaInput.innerHTML = customField.default
          }
          textareaInput.setAttribute('style', 'display:inline-block;border: solid 1px #9aa8b1;border-radius:5px;width:calc(100% - 20px);padding:0 5px;')
          textareaInput.setAttribute('maxlength', customField.maxlength)
          if (customField.placeholder && customField.placeholder.length > 0) {
            textareaInput.setAttribute('placeholder', customField.placeholder)
          } else {
            textareaInput.setAttribute('placeholder', customField.maxlength + ' chars max')
          }
          textareaInput.addEventListener('blur', function () { that.checkExtraField(customField.id) })
          var textareaLabel = document.createElement('label')
          textareaLabel.innerHTML = customField.description
          textareaLabel.setAttribute('for', customField.id)
          textareaLabel.setAttribute('style', 'display:inline-block;margin-right:16px;font-weight:bold;vertical-align:top;min-width:225px;')
          customFieldDiv.appendChild(textareaLabel)
          customFieldDiv.appendChild(document.createElement('br'))
          if (customField.isRequired) {
            customFieldDiv.appendChild(requiredLabel)
          }
          customFieldDiv.appendChild(textareaInput)
        } else if (customField.dataType === 'integer') {
          var integerInput = document.createElement('input')
          integerInput.setAttribute('type', 'number')
          integerInput.id = customField.id
          if (customField.default) {
            integerInput.value = customField.default
          }
          integerInput.setAttribute('style', 'display:inline-block;border:solid 1px #9aa8b1;border-radius:5px;')
          integerInput.addEventListener('blur', function () { that.checkExtraField(customField.id) })
          var integerLabel = document.createElement('label')
          integerLabel.setAttribute('for', customField.id)
          integerLabel.setAttribute('style', 'display:inline-block;margin-right:16px;font-weight:bold;vertical-align:top;min-width:225px;')
          integerLabel.innerHTML = customField.description
          customFieldDiv.appendChild(integerLabel)
          customFieldDiv.appendChild(requiredLabel)
          customFieldDiv.appendChild(integerInput)
        } else if (customField.dataType === 'datetime') {
          var datetimeInput = document.createElement('input')
          datetimeInput.setAttribute('type', 'date')
          datetimeInput.id = customField.id
          datetimeInput.setAttribute('style', 'display:inline-block;border:solid 1px #9aa8b1;border-radius:5px;width:194px;')
          datetimeInput.addEventListener('blur', function () { that.checkExtraField(customField.id) })
          var datetimeLabel = document.createElement('label')
          datetimeLabel.setAttribute('for', customField.id)
          datetimeLabel.setAttribute('style', 'display:inline-block;margin-right:16px;font-weight:bold;vertical-align:top;min-width:225px;')
          datetimeLabel.innerHTML = customField.description
          customFieldDiv.appendChild(datetimeLabel)
          customFieldDiv.appendChild(requiredLabel)
          customFieldDiv.appendChild(datetimeInput)
        } else if (customField.dataType === 'checkbox') {
          var checkboxInput = document.createElement('input')
          checkboxInput.setAttribute('type', 'checkbox')
          checkboxInput.id = customField.id
          checkboxInput.setAttribute('style', 'display:inline-block;border:solid 1px #9aa8b1;border-radius:5px;width:20px;height:20px;vertical-align: middle;')
          if (customField.id === 'chkAdditionalServicesNeeded') {
            checkboxInput.addEventListener('click', function (e) {
              var detail = document.getElementById('txtAdditionalServicesDescription')
              if (detail) {
                if (e.target.checked) {
                  detail.parentElement.style.display = 'inline-block'
                  that.checkForm(({ target: { className: 'txtAdditionalServicesDescription' } }))
                  that.checkExtraFields()
                } else {
                  detail.parentElement.style.display = 'none'
                }
              }
              that.checkForm(({ target: { className: 'txtAdditionalServicesDescription' } }))
              that.checkExtraFields()
            })
          }
          var checkboxLabel = document.createElement('label')
          checkboxLabel.setAttribute('for', customField.id)
          checkboxLabel.setAttribute('style', 'display:inline-block;margin-right:16px;font-weight:bold;vertical-align:top;min-width:225px;')
          checkboxLabel.innerHTML = customField.description
          customFieldDiv.appendChild(checkboxLabel)
          customFieldDiv.appendChild(requiredLabel)
          customFieldDiv.appendChild(checkboxInput)
        } else if (customField.dataType === 'select') {
          var selectInput = document.createElement('select')
          selectInput.setAttribute('style', 'display:inline-block;border:solid 1px #9aa8b1;border-radius:5px;width:194px;padding: 0 5px;')
          selectInput.id = customField.id
          if (customField.id === 'specialPriority1') {
            selectInput.addEventListener('change', function () {
              that.setPriority()
            }, false)
            for (let i = 1; i < 4; i++) {
              var opt1 = document.createElement('option')
              var txt1 = 'Low'
              if (i === 2) { txt1 = 'Medium' }
              if (i === 3) { txt1 = 'High' }
              opt1.innerHTML = txt1
              opt1.value = txt1
              selectInput.appendChild(opt1)
            }
          } else if (customField.id === 'specialPriority2') {
            selectInput.addEventListener('change', function () {
              that.setPriority()
            }, false)
            for (let i = 1; i < 4; i++) {
              var opt2 = document.createElement('option')
              var txt2 = 'Only Me'
              if (i === 2) { txt2 = 'Others' }
              if (i === 3) { txt2 = 'Company' }
              opt2.innerHTML = txt2
              opt2.value = txt2
              selectInput.appendChild(opt2)
            }
          } else if (customField.id === 'cboInvoiceOrKitFund') {
            for (let i = 1; i < 3; i++) {
              var optInvoiceOrKitFund = document.createElement('option')
              var txtInvoiceOrKitFund = 'Invoice'
              if (i === 2) { txtInvoiceOrKitFund = 'Kit Fund' }
              optInvoiceOrKitFund.innerHTML = txtInvoiceOrKitFund
              optInvoiceOrKitFund.value = txtInvoiceOrKitFund
              selectInput.appendChild(optInvoiceOrKitFund)
            }
          } else if (customField.id === 'cboBarRequired') {
            for (let i = 1; i < 7; i++) {
              var optBarRequired = document.createElement('option')
              var txt3 = 'Full'
              if (i === 2) { txt3 = 'GPRS' }
              if (i === 3) { txt3 = 'Roaming' }
              if (i === 4) { txt3 = 'International Call' }
              if (i === 5) { txt3 = 'Lost/Stolen' }
              if (i === 6) { txt3 = 'IMEI' }
              optBarRequired.value = txt3
              optBarRequired.innerHTML = txt3
              selectInput.appendChild(optBarRequired)
            }
          } else if (customField.id === 'cboSIMType') {
            for (let i = 1; i < 3; i++) {
              var cboSIMType = document.createElement('option')
              var txt4 = 'Triple SIM'
              if (i === 2) { txt4 = 'E-SIM' }
              if (!(this.mobileNetwork === 5 && i === 2)) {
                cboSIMType.value = txt4
                cboSIMType.innerHTML = txt4
                selectInput.appendChild(cboSIMType)
              }
            }
          } else {
            for (let i = 1; i < 3; i++) {
              var optYesNo = document.createElement('option')
              var txtYesNo = 'Yes'
              optYesNo.value = 'Yes'
              if (i === 2) { txtYesNo = 'No'; optYesNo.value = 'No' }
              optYesNo.innerHTML = txtYesNo
              selectInput.appendChild(optYesNo)
            }
          }
          var selectLabel = document.createElement('label')
          selectLabel.setAttribute('for', customField.id)
          selectLabel.setAttribute('style', 'display:inline-block;margin-right:16px;font-weight:bold;vertical-align:top;min-width:225px;')
          selectLabel.innerHTML = customField.description
          customFieldDiv.appendChild(selectLabel)
          customFieldDiv.appendChild(requiredLabel)
          customFieldDiv.appendChild(selectInput)
        } else if (customField.dataType === 'configurationItemID') {
          this.showConfigurationItemSelection = true
        } else {
          console.log('Received dataType, ' + customField.dataType + ', for extra ticket field that was not recognised')
        }
        customFieldsDiv.appendChild(customFieldDiv)
      })
    },
    addCreatedTicket: async function (ticketId) {
      var ticket = await this.getTicketById(ticketId)
      if (ticket.ticketNumber !== null) {
        await this.addAutotaskServiceTicket(ticket)
        var tickets = this.$store.getters.tickets
        tickets.push(ticket)
        this.setTickets(tickets)
      }
    },
    clearForm () {
      this.ticket.title = null
      this.ticket.description = null
      this.ticket.priorityId = null
      this.ticket.ticketTypeId = null
      this.ticket.accountId = null
      this.ticket.contactId = null
      this.ticket.customFields = []
      this.configurationItem = {}
      this.configurationItemId = null
      this.requestTypeId = null
      this.configurationItems = []
      this.configurationItemTypes = []
      this.priorities = []
      this.showTicketDetailSection = false
      this.showCustomFieldsSection = false
      this.showAttachmentSection = false
      this.showExtraFieldsSection = false
      this.requestTypeSectionCompleted = false
      this.ticketDetailSectionCompleted = false
      this.customFieldsSectionCompleted = false
      this.subIssueTypeId = 0
      this.mobileNetwork = 0
      this.formOK = false
    },
    clearFormAndClose () {
      this.clearForm()
      this.$emit('close', false)
    },
    clearFormAndViewTicket (ticketId) {
      this.clearForm()
      this.$emit('show-ticket', ticketId)
    },
    sendNotification: async function (ticketId, addresses) {
      var title = 'New Files Attached To Ticket:'
      var body = 'Files added :<br/>'
      for (let i = 0; i < this.files.length; i++) {
        body += this.files[i].name + '<br/>'
      }
      var ticket = await this.getTicketById(ticketId)
      await this.sendResourceNotification(addresses, title, body, ticket)
    },
    updateRecipients (val) {
      this.recipients = val
    },
    getValidIssueTypes: async function (id) {
      var rtn = []
      var issueTypes = this.$store.getters.configurationItemTypeList
      var configItemTypes = await this.getConfigurationItemTypesBySite(id)
      for (let i = 0; i < issueTypes.length; i++) {
        for (let j = 0; j < configItemTypes.length; j++) {
          if (issueTypes[i].value + '' === configItemTypes[j].configurationItemType + '') {
            rtn.push(issueTypes[i])
          }
        }
      }
      return rtn
    }
  }
}
</script>

<style scoped>
  .headerWarning {
    color: red;
    font-weight: 500;
    margin-top: -15px;
    line-height: 2.5em;
  }
  .textAreaInput {
    display:inline-block;
    border: solid 1px #9aa8b1;
    border-radius:5px;
    width:calc(100% - 20px);
    padding:0 5px;
  }
  .textAreaInputError {
    display:inline-block;
    border: solid 1px red;
    border-radius:5px;
    width:calc(100% - 20px);
    padding:0 5px;
  }
  .buttonHolder {
    width:100%;
    text-align: right;
  }
  select {
    border-radius: 5px;
  }
  .dropdown  {
    border-radius: 5px;
  }
  .fieldLabel {
    display: inline-block;
    font-weight: bold;
    width: 240px;
    padding-left: 5px;
    margin-bottom: 10px;
  }
  .ticketDetailContainer {
    padding: 0 10px 0 10px;
  }
  .createTicketRight {
    width: 50%;
    display: inline-block;
    padding: 5px 5px 0 5px;
  }
  .createTicket {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .errorHighlight {
    border: 1px solid red!important;
  }
  .parentCreateTicketForm{
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .createTicketLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .createTicketTextareaLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .createTicketTextbox {
    width: 68%;
    padding: 0 5px;
    display: inline-block;
  }
  textarea {
    border-radius: 5px;
    border: solid 1px #9aa8b1;
  }
  .outline {
    border: solid 1px #9aa8b1;
  }
  .createTicketData {
    width: 145px;
    display: inline-block;
  }
  .createSectionData {
    position: absolute;
    bottom: 36px;
    top: 0;
    right: 0;
    background-color: #fff;
    left: 0;
    z-index: 1;
    margin-right: 3vw;
  }
  .createTicketHolder {
    background-color: rgba(255,255,255,0.7);
    padding: 0 15px 0 10px;
    overflow-y: auto;
  }
  .cancelTicketButton {
    background-image: url('../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
  }
  .createTicketButton {
    background-image: url('../../assets/icons/check-tick-disabled.svg');
    cursor: default;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .createTicketButtonEnabled {
    background-image: url('../../assets/icons/check-tick.svg');
    cursor: pointer;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .createTicketLeft {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .createTicketRight {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .tickeRecipientsTextarea {
    border-radius: 5px;
    border: solid 1px #9aa8b1;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    padding: 0 5px;
    width:100%;
    size:1;
  }
  .tickeRecipientsTextareaError {
    border-radius: 5px;
    border: solid 1px red;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    width:100%;
    size:1;
  }
  .divNewAttachmentContainer {
    padding: 20px;
    background-color: #e8f5e9;
    border: solid #9aa8b1 1px;
  }
  .bottombar {
    text-align: right;
  }
  .slide-enter-active, .slide-leave-active {
    transition: max-height .2s ease-out;
  }

  .slide-enter, .slide-leave-to {
    max-height: 0px;
  }

  .slide-enter-to, .slide-leave {
    max-height: 200px;
  }

  h3 {
    cursor: pointer;
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0;
    color: #fff;
    border-radius: 5px;
  }
  .expanded {
    border-radius: 5px 5px 0 0;
  }

  input {
    border-radius: 5px;
  }
  textarea {
    border-radius: 5px;
  }

  .completeSection {
    background-image: url('../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .incompleteSection {
    background-image: url('../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  span.statuscontainer {
    float: left;
    margin-right: 5px;
  }

  span.statuscontainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }

  .minimiseSection {
    background-image: url('../../assets/icons/up_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .maximiseSection {
    background-image: url('../../assets/icons/down_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .formContent {
    overflow-y: auto;
  }
  select {
    appearance: menulist;
    border: solid 1px #9aa8b1;
    height: 26px;
  }
  #configurationItemID {
    width: calc(100% - 260px);
  }
</style>
