import { mapActions } from 'vuex'
import { usersMixin } from '../users'

export const iQuoteFunctions = {
  mixins: [usersMixin],
  data () {
    return {
    }
  },
  async mounted () {
  },
  methods: {
    ...mapActions([
      'setQuoteUsers'
    ]),
    iQuoteFunctions_IsInteger (value) {
      var returnValue = true
      if (value === '' || value === null) {
        returnValue = false
      }
      var NumericValue = Number(value)
      if (!Number.isInteger(NumericValue)) {
        returnValue = false
      }
      return returnValue
    },
    iQuoteFunctions_ThousandSeparator (amount) {
      var returnValue = amount
      if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
        var numberParts = amount.split('.')
        var partBeforeDecimalPlace = numberParts[0]
        var partAfterDecimalPlace = '00'

        if (numberParts.length > 1) {
          partAfterDecimalPlace = numberParts[1]
        }

        returnValue = partBeforeDecimalPlace.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        returnValue += '.' + partAfterDecimalPlace
      }
      return returnValue
    },
    iQuoteFunctions_GetCostDisplay (value) {
      if (value === 0 || value === null) {
        return '-'
      } else {
        return '£' + this.iQuoteFunctions_ThousandSeparator(this.iQuoteFunctions_RoundToTwoDecimalPlaces.toFixed(2))
      }
    },
    iQuoteFunctions_RoundToTwoDecimalPlaces (value) {
      return Number((+(Math.round(value + 'e+2') + 'e-2')).toFixed(2))
    },
    iQuoteFunctions_RoundToFourDecimalPlaces (value) {
      return Number((+(Math.round(value + 'e+4') + 'e-4')).toFixed(4))
    },
    iQuoteFunctions_ArrayRemoveById: function (arr, id) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          arr.splice(i, 1)
          i--
        }
      }
    },
    iQuoteFunctions_GetArrayItemById: function (arr, id) {
      var item = {}
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          item = arr[i]
        }
      }
      return item
    },
    iQuoteFunctions_ShowError: function (Message) {
      this.$store.commit('showMessage', { content: Message, color: 'red', timeout: 15000 })
    },
    iQuoteFunctions_ShowWarning: function (Message) {
      this.$store.commit('showMessage', { content: Message, color: 'amber', timeout: 7000 })
    },
    iQuoteFunctions_ShowMessage: function (Message) {
      this.$store.commit('showMessage', { content: Message, color: 'Green', timeout: 5000 })
    },
    iQuoteFunctions_delay (delayInms) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(2)
        }, delayInms)
      })
    },
    iQuoteFunctions_getSF_COE_NameFromId  (codeId) {
      var matches = this.iQuoteStore_GetSalesforceCoeCodes.filter(e => e.id === codeId)

      if (matches.length > 0) {
        return matches[0].code
      } else {
        return 'Unknown: ' + codeId
      }
    },
    iQuoteFunctions_getSF_COE_IdFromName (salesforceCoeCode) {
      var matches = this.iQuoteStore_GetSalesforceCoeCodes.filter(e => e.code.toLowerCase() === salesforceCoeCode.toLowerCase())

      if (matches.length > 0) {
        return matches[0].id
      } else {
        console.log('salesforceCoeCode ' + salesforceCoeCode + 'not found in ', this.iQuoteStore_GetSalesforceCoeCodes)
        return 0
      }
    },
    iQuoteFunctions_isValidSFCoeCode (salesforceCoeCode) {
      return this.iQuoteStore_GetSalesforceCoeCodes.some(e => e.code.toLowerCase() === salesforceCoeCode.toLowerCase())
    }
  }
}
