import portalAPIGateway from '../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteLicensesRequiredAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteLicensesRequiredAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    getQuoteLicensesFromDB: async function () {
      var axiosConfig = this.iQuoteQuoteUnitCostsAPIMixin_getAxiosConfig()
      var data = []

      var quoteId = this.$store.getters.quote.id

      try {
        await portalAPIGateway.get('iquotearray/quoteLicenses/' + quoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteLicensesFromDB failed!')
      }

      return data
    },
    saveQuoteLicensesToDB: async function () {
      var axiosConfig = this.iQuoteQuoteLicensesRequiredAPIMixin_getAxiosConfig()

      var quoteId = this.$store.getters.quote.id
      var licensesRequired = this.$store.getters.quoteLicensesRequired

      try {
        await portalAPIGateway.post('iquote/quoteLicenses/' + quoteId, licensesRequired, axiosConfig).then(() => {
          return true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('saveQuoteLicensesRequiredToDB failed!')
      }
    }
  }
}
