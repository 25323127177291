<template>
  <div>
    <Spinner v-if="loading" />
    <router-link
      v-ripple
      v-if="!loading"
      class="toolbar-items"
      to="quotes-and-projects">
      <material-card>
        <vc-donut
          :size="200"
          :thickness="40"
          :sections="projectsData || undefined"
          :total="projectsTotal || undefined"
          :start-angle="0"
          background="white"
          foreground="grey"
          unit="px"
          has-legend
          legend-placement="bottom">
          <h4 class="title font-weight-light">Project RAG Status</h4>
        </vc-donut>
        <template slot="actions">
          <v-icon
            class="mr-2"
            small
          >mdi-clock-outline
          </v-icon>
          <span class="caption grey--text font-weight-light">{{ updateInterval }}</span>
        </template>
      </material-card>
    </router-link>
  </div>
</template>
<script>
import { autotaskServiceMixin } from '../../../mixins/autotaskServiceMixin'
import { dashboardMixin } from '../../../mixins/dashboard'
import { DataEventBus } from '../../../events/dataEvents'
import Spinner from '../../common/spinner'

export default {
  name: 'ProjectStatusTile',
  components: {
    Spinner
  },
  mixins: [
    autotaskServiceMixin,
    dashboardMixin
  ],
  data () {
    return {
      loading: false,
      timer: 0,
      timerInterval: process.env.VUE_APP_DATA_REFRESH_TIME,
      projectsData: [],
      lastUpdatedDate: Date.now(),
      lastUpdatedText: 'Updated 0 seconds ago'
    }
  },
  computed: {
    projectSummary () {
      return this.$store.getters.projectsummary
    },
    projectsTotal () {
      var rtn = 0
      if (this.projectSummary) {
        this.projectSummary.forEach((project) => {
          rtn += project.projectCount
        })
      }
      return rtn
    },
    updateInterval () {
      return `Updates every ${(process.env.VUE_APP_DATA_REFRESH_TIME / 1000)} seconds`
    }
  },
  watch: {
    projectSummary () {
      this.getProjectsSummaryChartData()
    }
  },
  mounted () {
    DataEventBus.$on('updateProjectSummary', async () => {
      await this.getProjectSummary(this.$store.getters.topLevelAreas)
      this.getProjectsSummaryChartData()
    })
    this.getProjectsSummaryChartData()
  },
  created () {
    this.timer = this.setTimer()
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    getProjectsSummaryChartData: function () {
      if (this.projectSummary) {
        this.loading = true
        var projectStatusCounts = {}
        this.projectsData = []

        if (this.projectSummary) {
          this.projectSummary.forEach((project) => {
            if (!projectStatusCounts[project.status]) {
              projectStatusCounts[project.status] = project.projectCount
            } else {
              projectStatusCounts[project.status] += project.projectCount
            }
          })
        }

        Object.keys(projectStatusCounts).forEach((status) => {
          this.projectsData.push({
            label: `${status} (${projectStatusCounts[status]})`,
            value: projectStatusCounts[status],
            color: this.getStatusColour(status)
          })
        })

        if (!this.projectsData.length) {
          this.projectsData.push({
            label: 'No Data Available',
            value: 0,
            color: this.getStatusColour('')
          })
        }
        this.lastUpdatedText = this.setLastUpdatedText(this.lastUpdatedDate)
        this.loading = false
      }
    },
    getStatusColour: function (status) {
      switch (status.toLowerCase()) {
        case 'red':
          return 'rgb(239, 83, 80)'
        case 'green':
          return 'rgb(102, 187, 106)'
        case 'amber':
          return 'rgb(255, 167, 38)'
        default:
          return 'rgb(153, 153, 153)'
      }
    },
    setTimer () {
      clearInterval(this.timer)
      return setInterval(async () => {
        await this.getProjectSummary(this.$store.getters.topLevelAreas)
        this.getProjectsSummaryChartData()
      }, this.timerInterval)
    }
  }
}
</script>
