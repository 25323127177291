<template>
  <div class="switch">
    <div
      :class="stateClass"
      :title="statePopup"
      class="slider round" >
      <div
        v-if="triState"
      >
        <div
          class="clickableElement"
          @click="toggleState(2)" />
        <div
          class="clickableElement"
          @click="toggleState(0)" />
        <div
          class="clickableElement"
          @click="toggleState(1)" />
      </div>
      <div
        v-else
      >
        <div
          class="clickableElementMax"
          @click="toggleState(state === 1 ? 2 : 1)" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TriStateToggleSwitch',
  props: {
    enabled: {
      type: Boolean,
      default: false
    },
    triState: {
      type: Boolean,
      default: true
    },
    currentState: {
      type: Number,
      default: 1
    },
    permissionId: {
      type: Number,
      default: -1
    },
    roleId: {
      type: Number,
      default: -1
    },
    userId: {
      type: Number,
      default: -1
    },
    areaId: {
      type: Number,
      default: -1
    },
    customerId: {
      type: Number,
      default: -1
    },
    companyId: {
      type: Number,
      default: -1
    },
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      state: 1
    }
  },
  computed: {
    stateClass () {
      var rtn = ''

      switch (this.state) {
        case 0:
          rtn = 'neutral'
          break
        case 1:
          rtn = 'active'
          break
        case 2:
          rtn = 'inactive'
          break
      }

      if (!this.enabled) {
        rtn += ' disabled'
      }

      return rtn
    },
    statePopup () {
      switch (this.state) {
        case 0:
          return 'Inherited'
        case 1:
          return 'Active'
        case 2:
          return 'inactive'
      }
    }
  },
  watch: {
    currentState: {
      handler (currentState) {
        this.state = currentState
      },
      immediate: true
    }
  },
  mounted () {
    this.state = this.currentState
  },
  methods: {
    toggleState (val) {
      if (this.enabled) {
        this.state = val
        var permissionId = this.permissionId
        var roleId = this.roleId
        var userId = this.userId
        var areaId = this.areaId
        var customerId = this.customerId
        var companyId = this.companyId
        var text = this.text

        this.$emit('statechanged', {
          roleId,
          permissionId,
          selectedState: this.state,
          userId,
          areaId,
          customerId,
          companyId,
          text
        })
      }
    }

  }
}
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 20px;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f3f3f3;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider .clickableElement {
  min-width:20px;
  height: 20px;
  display:inline-block;
}

.slider .clickableElementMax {
  min-width:100%;
  height: 20px;
  display:inline-block;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  right: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.round.active:before {
  height: 18px;
  width: 18px;
  right: 1px;
  bottom: 1px;
}

.slider.round.inactive:before{
  -webkit-transform: translateX(-40px);
  -ms-transform: translateX(-40px);
  transform: translateX(-40px);
}

.slider.round.neutral:before {
  -webkit-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  transform: translateX(-20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round.inactive {
  background-color:#ff1414;
}

.slider.round.inactive.disabled {
  background-color:#bdbdbd;
}

.slider.round.neutral {
  background-color:#283583;
}

.slider.round.neutral.disabled {
  background-color:#bdbdbd;
}

.slider.round.active {
  background-color:#2b6b12;
}

.slider.round.active.disabled {
  background-color:#bdbdbd;
}

</style>
