<template>
  <div
    :class="selected"
    class="createCustomerTileContainer"
  >
    <div>
      <img
        :id="id"
        :src="imageSource"
        :alt="createCustomerName"
        @click="()=>this.$emit('setCurrentView', 'create-customer')"
      >
    </div>
    <div class="tileButtons" >
      <button @click="remove()">Remove</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UploadDashboardImageComponent',
  data () {
    return {
    }
  },
  computed: {
    imageSource () {
      return require(`../../assets/icons/avayaAura.png`)
    }
  },
  mounted () {
  }
}
</script>
<style scoped>
  .createCustomerTileContainer {
    display: inline-block;
    margin: 20px 0 0 20px;
    background-color: #fff;
    border: solid 2px #235622;
    border-radius: 8px;
  }
  .createCustomerTileContainer:hover {
    background-color: rgb(199, 209, 199);
    border: solid 2px #000;
  }
  .createCustomerTileContainer.selected {
    display: inline-block;
    margin: 20px 0 0 20px;
    background-color: rgb(199, 209, 199);
    border: solid 2px #235622;
  }
  .createCustomerTileContainer.selected:hover {
    background-color: rgb(122, 136, 122);
    border: solid 2px #000;
  }
  .createCustomerTileContainer img {
    width:140px;
    cursor: pointer;
  }
  .createCustomerTileContainer .tileButtons{
    visibility:hidden;
  }
  .createCustomerTileContainer.selected .tileButtons{
    visibility:visible;
  }
</style>
