<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconCommunicate"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <p>Select the number of user profiles required:</p>
      <br><br>
      <table>
        <tr>
          <th style="text-align:left;">Profile
            <TooltipIcon text="Description of user types, full details are covered in the <u>Service Description</u>.  Profiles may vary dependant on vendor" />
          </th>
          <th>Min Commit Users<br>
            <TooltipIcon text="This covers the minimum number of agents committed to in the customer MSA and the actual number of users required for operation" />
          </th>
          <th>Maximum<br>
            <TooltipIcon text="The maximum number of agents configures the size of the ICON infrastructure and maximum size the customer may grow to.  This value requires to be 20% above the Minimum Commit Users. Adjust to required level" />
          </th>
        </tr>
        <tr><td colspan="3">&nbsp;</td></tr>
        <tr
          v-for="userProfile in quoteTechnologyAvayaAura.userProfiles"
          :key="userProfile.technologyUserProfileId"
        >
          <td
            :class="{InputError: !isValidUserProfile(userProfile)}"
            style="width:220px; text-align:left"
          >{{ userProfile.description }}</td>
          <td>
            <input
              v-model.number="userProfile.minCommitment"
              type="number"
              min="0"
              max="1000000"
              @keyup="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
              @change="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
            >
          </td>
          <td>
            <input
              v-model.number="userProfile.maxAllowed"
              type="number"
              min="0"
              max="1000000"
            >
          </td>
        </tr>

      </table>
      <div class="telsetsLabel">Telsets</div>
      <TooltipIcon text="Telsets added using the Avaya DaaS service.  This is a rental model and allows the customer to purchase in an opex model.  No Power supply is shipped with these sets.  These should be ordered separately" />
      <br>
      <table>
        <tr
          v-for="hardware in quoteTechnologyAvayaAura.hardware"
          :key="hardware.technologyHardwareId"
        >
          <td>
            <label
              :class="{InputError: !isValidHardware(hardware)}"
              for="numHardware"
              style="width:220px; text-align:left"
            >{{ hardware.description }}
              <TooltipIcon
                v-if="hardware.additionalInfo != ''"
                :text="hardware.additionalInfo" />
            </label>
          </td>
          <td>
            <input
              id="numHardware"
              v-model.number="hardware.quantity"
              type="number"
              min="0"
              max="1000000"
            >
          </td>
          <td>
            <label
              v-if="hardware.quantity > 0 && hardware.warning != ''"
              class="InputError"
              style="width:420px; text-align:left"
            >{{ hardware.warning }}
            </label>
          </td>
        </tr>
      </table>
      <br><br><br>
      <h4
      >Does the customer require their own public domain space or will they use ICON anywhere.net domain
        <TooltipIcon
          width="500"
          text="Customer needs to purchase these Domain names themselves if they choose to use their own public name space" />
      </h4>
      <br>
      <input
        id="1"
        v-model.number="quoteTechnologyAvayaAura.options.publicNameSpaceOptionId"
        type="radio"
        name="1"
        value="1">ICON anywhere.net domain
      <br>
      <input
        id="2"
        v-model.number="quoteTechnologyAvayaAura.options.publicNameSpaceOptionId"
        type="radio"
        name="2"
        value="2">Own public name space
      <label
        v-if="quoteTechnologyAvayaAura.options.publicNameSpaceOptionId === iQuoteConstants.PublicNameSpaceOptions.CUSTOMER_PROVIDES"
        class="InputError"
        style="width:620px; text-align:left">Customer needs to purchase these Domain names themselves
      </label>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('IconCommunicate')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('AdjunctsAura')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { mapActions } from 'vuex'
export default {
  name: 'UserProfileAura',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteStoreMixin],
  data () {
    return {
      quote: {},
      quoteTechnologyAvayaAura: {
        userProfiles: [],
        hardware: [],
        options: {
          id: 0,
          quoteId: 0,
          publicNameSpaceOptionId: 1 /* MAINTEL_PROVIDES */
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconCommunicate () {
      return require(`../../../assets/icons/iconCommunicate.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    var quoteTechnologyAvayaAuraStore = this.$store.getters.quoteTechnologyAvayaAura

    if (quoteTechnologyAvayaAuraStore.id === undefined || quoteTechnologyAvayaAuraStore.id === 0) {
      var userProfiles
      var adjuncts
      var hardware
      var options

      this.quoteTechnologyAvayaAura.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.avayaAura)

      if (this.quoteTechnologyAvayaAura.id === 0) {
        this.quoteTechnologyAvayaAura.id = await this.addQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.avayaAura)

        // Set default values for new technology on quote
        userProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.avayaAura, this.quoteTechnologyAvayaAura.id)
        hardware = this.iQuoteStore_getDefaultQuoteTechnologyHardware(this.iQuoteConstants.technologies.avayaAura, this.quoteTechnologyAvayaAura.id)
        adjuncts = {
          id: 0,
          quoteId: this.quote.id,
          rightfax: false,
          dataTrackCallLogging: false,
          maxConcurrentCalls: 0,
          numCallsPerAgentPerDay: 0,
          avgCallDuration: 0,
          retentionPeriod: 0,
          recordingLicenses: 0,
          qualityMonitoring: 0,
          keywordSpotting: 0,
          transcription: 0,
          engineEnabling: 0,
          connectForSalesforce: 0,
          contactCentreIntegration: false
        }
        options = {
          id: 0,
          quoteId: this.quote.id,
          publicNameSpaceOptionId: 1 /* MAINTEL_PROVIDES */
        }
      } else {
        // Retrieve data from DB
        userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyAvayaAura.id)
        adjuncts = await this.getQuoteAuraAdjuncts(this.quote.id)
        hardware = await this.getQuoteTechnologyHardware(this.quoteTechnologyAvayaAura.id)
        options = await this.getQuoteOptionsAvayaAura(this.quote.id)
      }

      if (userProfiles.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyAvayaAura, 'userProfiles', userProfiles)
      }

      if (adjuncts.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyAvayaAura, 'adjuncts', adjuncts)
      }

      if (hardware.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyAvayaAura, 'hardware', hardware)
      }

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyAvayaAura, 'options', options)
      }
      this.setQuoteTechnologyAvayaAura(this.quoteTechnologyAvayaAura)
    } else {
      this.quoteTechnologyAvayaAura = quoteTechnologyAvayaAuraStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyAvayaAura'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      var TotalUserCount = 0
      var minWasGreaterThanMax = false
      var maxNotHighEnough = false

      for (let index = 0; index < this.quoteTechnologyAvayaAura.userProfiles.length; index++) {
        const userProfile = this.quoteTechnologyAvayaAura.userProfiles[index]
        if (userProfile.minCommitment > userProfile.maxAllowed) {
          minWasGreaterThanMax = true
        }
        if (userProfile.maxAllowed < (userProfile.minCommitment * 1.2)) {
          maxNotHighEnough = true
        }
        if (!this.isValidUserProfile(userProfile)) {
          ValidationFailed = true
        } else {
          TotalUserCount += userProfile.minCommitment
        }
      }
      for (let index = 0; index < this.quoteTechnologyAvayaAura.hardware.length; index++) {
        const hardware = this.quoteTechnologyAvayaAura.hardware[index]
        if (!this.isValidHardware(hardware)) {
          ValidationFailed = true
        }
      }
      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied (even if pressing back rather than next)
        this.quoteTechnologyAvayaAura.userProfiles = await this.saveQuoteTechnologyUserProfiles(this.quoteTechnologyAvayaAura.userProfiles)
        this.quoteTechnologyAvayaAura.hardware = await this.saveQuoteTechnologyHardware(this.quoteTechnologyAvayaAura.hardware)
        this.quoteTechnologyAvayaAura.options = await this.saveQuoteOptionsAvayaAura(this.quoteTechnologyAvayaAura.options)
      }
      if (minWasGreaterThanMax) {
        this.iQuoteFunctions_ShowError('min commit cannot exceed maximum value')
        return
      } else if (maxNotHighEnough) {
        this.iQuoteFunctions_ShowError('maximum value must be at least 20% higher than the min commit')
        return
      } else if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      } else if (TotalUserCount < 1000) {
        if (page === 'IconCommunicate' && TotalUserCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter at least 1000 users, or enter 0 (this will remove aura from the quote)')
          return
        } else if (page === 'AdjunctsAura') {
          this.iQuoteFunctions_ShowError('Please enter at least 1000 users')
          return
        }
      } else if (TotalUserCount > 10000) {
        if (page === 'IconCommunicate' && TotalUserCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter 10,000 or less users, or enter 0 (this will remove aura from the quote)')
        } else if (page === 'AdjunctsAura') {
          this.iQuoteFunctions_ShowError('cannot support over 10,000 users')
        }
        return
      }

      if (TotalUserCount > 0) {
        // update local store
        this.setQuoteTechnologyAvayaAura(this.quoteTechnologyAvayaAura)
      } else {
        this.deleteQuoteTechnology(this.quoteTechnologyAvayaAura.id)
        // delete from local store
        this.setQuoteTechnologyAvayaAura({})
      }

      // update local store
      this.setCurrentQuoteState(page)
    },
    isValidUserProfile (userProfile) {
      return userProfile.minCommitment <= userProfile.maxAllowed &&
                                          this.iQuoteFunctions_IsInteger(userProfile.minCommitment) &&
                                          this.iQuoteFunctions_IsInteger(userProfile.maxAllowed) &&
                                          userProfile.minCommitment >= 0 &&
                                          userProfile.maxAllowed >= 0 &&
                                          userProfile.maxAllowed >= (userProfile.minCommitment * 1.2)
    },
    isValidHardware (hardware) {
      return (this.iQuoteFunctions_IsInteger(hardware.quantity) && hardware.quantity >= 0)
    }
  }
}
</script>
<style scoped>
  table tr th {text-align: center;}
  table tr td {text-align: center;}
  .telsetsLabel {
    display: inline-block;
    width: 60px;
    margin: 20px 0;
    font-weight: 600;
  }
</style>
