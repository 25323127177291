import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'
import { mapActions } from 'vuex'

export const iQuoteCalculationsMitelUCMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
      quoteTechnologyMitelUC: {},
      quote: {},
      licenses: {}
    }
  },
  computed: {
    mitelUCCalcs_MiCollabUsersToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyMitelUC.userProfiles.forEach(userProfile => {
        if (userProfile.description === 'Standard' ||
            userProfile.description === 'Premium' ||
            userProfile.description === 'Ultimate') {
          users += userProfile.maxAllowed
        }
      })
      return users
    },
    mitelUCCalcs_MCDUsersToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyMitelUC.userProfiles.forEach(userProfile => {
        if (userProfile.description === 'Basic' ||
            userProfile.description === 'Entry' ||
            userProfile.description === 'Standard' ||
            userProfile.description === 'Premium' ||
            userProfile.description === 'Ultimate') {
          users += userProfile.maxAllowed
        }
      })
      return users
    },
    mitelUCCalcs_usersToLicense () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyMitelUC.userProfiles.forEach(userProfile => {
        users += userProfile.minCommitment
      })
      return users
    },
    mitelUCCalcs_usersToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyMitelUC.userProfiles.forEach(userProfile => {
        users += userProfile.maxAllowed
      })
      return users
    },
    mitelUCCalcs_ascTotals () {
      return this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.ascEssentials +
        this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.ascPremier +
        this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.ascElite
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyMitelUC'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcMitelUnitCosts: function () {
      var iconSecCertCostPerYear = 0
      var servers = this.iQuoteStore_GetQuoteVMsRequired.filter(x => (x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_SML ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_LRG ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICOLLAB_SML ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICOLLAB_MED ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG2 ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICONTACT_SML ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICONTACT_MED ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICONTACT_LRG) &&
                                                                         x.dataCentreId === this.iQuoteConstants.dataCentres.fareham
      )

      var serverCount = 0

      servers.forEach(server => {
        serverCount += server.quantity
      })

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.options.publicNameSpaceOptionId === this.iQuoteConstants.PublicNameSpaceOptions.MAINTEL_PROVIDES) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_UPFRONT, 1, null, this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_UPFRONT_COST)

        if (serverCount > 3) {
          iconSecCertCostPerYear = this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_COST_MAX
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_RECURRING, 1, iconSecCertCostPerYear, iconSecCertCostPerYear)
        } else {
          iconSecCertCostPerYear = this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_BASE_COST + (this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_COST_PER_SERVER * serverCount)
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_RECURRING, 1, iconSecCertCostPerYear, iconSecCertCostPerYear)
        }
      }
    },
    calcMitelUCVMs: function () {
      this.quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
      var quantity = 0

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined) {
        return
      }

      // These VMs are required for every MitelUC Deployment
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_FOREST_DC, this.iQuoteConstants.dataCentres.fareham, true, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_FOREST_DC, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.options.concurrentCallsToRemoteWorkers > 0) {
        if (this.iQuoteStore_GetQuoteTechnologyMitelUC.options.concurrentCallsToRemoteWorkers <= 200) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_SML, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_SML, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
        } else {
          quantity = Math.ceil(this.iQuoteStore_GetQuoteTechnologyMitelUC.options.concurrentCallsToRemoteWorkers / 500)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_LRG, this.iQuoteConstants.dataCentres.fareham, true, quantity)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_LRG, this.iQuoteConstants.dataCentres.goswellRoad, true, quantity)
        }
      }

      if (this.mitelUCCalcs_usersToHandle > 0) {
        quantity = 1
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.MITEL_VM_UVR, this.iQuoteConstants.dataCentres.fareham, true, quantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.MITEL_VM_UVR, this.iQuoteConstants.dataCentres.goswellRoad, false, quantity)
      }

      if (this.mitelUCCalcs_MiCollabUsersToHandle > 0) {
        if (this.mitelUCCalcs_MiCollabUsersToHandle <= 250) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_SML, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_SML, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        } else if (this.mitelUCCalcs_MiCollabUsersToHandle <= 1500) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_MED, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_MED, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        } else if (this.mitelUCCalcs_MiCollabUsersToHandle <= 2500) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        } else {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG2, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG2, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.options.oigServersAreRequired) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MVB_OIG, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MVB_OIG, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id !== undefined && (this.iQuoteStore_GetQuoteTechnologyMitelCC.options.dedicatedIVRServerIsRequired || this.iQuoteStore_GetQuoteTechnologyMitelCC.options.ivrPorts >= 40)) {
        quantity = Math.ceil(this.iQuoteStore_GetQuoteTechnologyMitelCC.options.ivrPorts / 120)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_IVR, this.iQuoteConstants.dataCentres.fareham, true, quantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_IVR, this.iQuoteConstants.dataCentres.goswellRoad, true, quantity)
      }

      if (this.iQuoteStore_GetQuoteTechnologyCallmedia.id !== undefined && (this.iQuoteStore_GetQuoteTechnologyCallmedia.options.dedicatedIVRServerIsRequired || this.iQuoteStore_GetQuoteTechnologyCallmedia.options.ivrPorts >= 40)) {
        quantity = Math.ceil(this.iQuoteStore_GetQuoteTechnologyCallmedia.options.ivrPorts / 120)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_IVR, this.iQuoteConstants.dataCentres.fareham, true, quantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_IVR, this.iQuoteConstants.dataCentres.goswellRoad, true, quantity)
      }

      this.calcMitelUC_VMs_MCD()
      this.calcMitelUCAdjunct_VMs()
    },
    calcMitelUC_VMs_MCD: function () {
      var trunkGateways = 0
      var pathGateways = 0

      if (this.mitelUCCalcs_MCDUsersToHandle <= 250 && ((this.numPrimarySipChannels <= 60 && this.mitelCCCalcs_voiceAgentsToHandle === 0) || (this.numPrimarySipChannels <= 45 && this.mitelCCCalcs_voiceAgentsToHandle <= 30))) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_SML, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_SML, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (this.mitelUCCalcs_MCDUsersToHandle <= 1500 && ((this.numPrimarySipChannels <= 300 && this.mitelCCCalcs_voiceAgentsToHandle === 0) || (this.numPrimarySipChannels <= 150 && this.mitelCCCalcs_voiceAgentsToHandle <= 100))) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_MED, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_MED, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (this.mitelUCCalcs_MCDUsersToHandle <= 10000) {
        var ucUsersToAllocate = this.mitelUCCalcs_MCDUsersToHandle
        var ccUsersToAllocate = this.mitelCCCalcs_voiceAgentsToHandle

        if (this.mitelCCCalcs_voiceAgentsToHandle > 175 || this.numPrimarySipChannels > 250) {
          pathGateways = Math.ceil(this.numPrimarySipChannels / 350)
        }

        while (ucUsersToAllocate > 0 || ccUsersToAllocate > 0) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_LRG, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_LRG, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)

          ucUsersToAllocate -= 2500

          if (pathGateways === 0) {
            ccUsersToAllocate -= 175
          } else {
            ccUsersToAllocate -= 1200
          }
        }

        if ((this.mitelCCCalcs_voiceAgentsToHandle === 0 && this.numPrimarySipChannels > 500) || (this.mitelCCCalcs_voiceAgentsToHandle > 0 && this.numPrimarySipChannels > 250)) {
          trunkGateways++
        }

        if (pathGateways === 1 && trunkGateways === 1) {
          trunkGateways = 0
        }

        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_LRG, this.iQuoteConstants.dataCentres.fareham, true, trunkGateways + pathGateways)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_LRG, this.iQuoteConstants.dataCentres.goswellRoad, true, trunkGateways + pathGateways)
      } else {
        // TODO: put a warning here if exceeding capacity
      }
    },
    calcMitelUCAdjunct_VMs: function () {
      if (this.mitelUCCalcs_ascTotals > 0) {
        const hoursPerGb = 18.2
        const minutesPerGb = hoursPerGb * 60
        const daysPerYear = 260
        const recordingSpacePerMinute = 1 / minutesPerGb

        var storage = this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.maxConcurrentCalls *
                      this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.numCallsPerAgentPerDay *
                      this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.avgCallDuration *
                      recordingSpacePerMinute *
                      ((this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.retentionPeriod / 12) * daysPerYear)

        if (storage > 2000) {
          storage = storage / 5
        }

        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.virtualMachineId, this.iQuoteConstants.dataCentres.fareham, true, 1, 0, storage)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.virtualMachineId, this.iQuoteConstants.dataCentres.goswellRoad, true, 1, 0, storage, false, true)

        if (this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.maxConcurrentCalls <= 200) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_SRC_SML, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_SRC_SML, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        } else {
          var quantity = Math.ceil(this.iQuoteStore_GetQuoteTechnologyMitelUC.options.concurrentCallsToRemoteWorkers / 400)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_SRC_MED, this.iQuoteConstants.dataCentres.fareham, true, quantity)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_SRC_MED, this.iQuoteConstants.dataCentres.goswellRoad, true, quantity)
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.rightfax) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.RIGHTFAX, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.RIGHTFAX, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.dataTrackCallLogging) {
        // TODO: don't appear to be any VMs for this?
      }
    },
    calcMitelUCAdjunctLicenses: function () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined) {
        return
      }

      this.licenses = this.$store.getters.licenses

      this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_ASC_Essentials, this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.ascEssentials)
      this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_ASC_Premier, this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.ascPremier)
      this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_ASC_Elite, this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.ascElite)

      var numRecordingChannels = this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.maxConcurrentCalls

      if (numRecordingChannels > 0) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_SRCPORTS, numRecordingChannels)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.options.webConferencingIsRequired && this.iQuoteStore_GetQuote.quoteLicenseTypeId !== this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_AWC, this.iQuoteStore_GetQuoteTechnologyMitelUC.options.additionalWebConferencingPorts)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.options.additionalVoicemailBoxes > 0 && this.iQuoteStore_GetQuote.quoteLicenseTypeId !== this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_MAILBOX, this.iQuoteStore_GetQuoteTechnologyMitelUC.options.additionalVoicemailBoxes)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.adjuncts.dataTrackCallLogging) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DATATRACK_CDR, 1)

        if (this.mitelUCCalcs_usersToLicense > 2500) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DATATRACK_TIER3, this.mitelUCCalcs_usersToLicense)
        } else if (this.mitelUCCalcs_usersToLicense > 1000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DATATRACK_TIER2, this.mitelUCCalcs_usersToLicense)
        } else if (this.mitelUCCalcs_usersToLicense >= 500) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DATATRACK_TIER1, this.mitelUCCalcs_usersToLicense)
        }
      }
    },
    calcMitelUCUserLicenses: function () {
      this.licenses = this.$store.getters.licenses
      var userProfileLicenses = this.$store.getters.userProfileLicenses
      var requiredLicenseId = 0
      var tierId = 1
      var quoteTechnologyMitelUC = this.iQuoteStore_GetQuoteTechnologyMitelUC

      if (quoteTechnologyMitelUC.id === undefined) {
        return
      }

      if (this.mitelUCCalcs_usersToLicense > 2500) {
        tierId = 3
      } else if (this.mitelUCCalcs_usersToLicense > 1250) {
        tierId = 2
      } else {
        tierId = 1
      }

      quoteTechnologyMitelUC.userProfiles.forEach(userProfile => {
        userProfile.annualPrice = 0
        userProfile.rate = 0
        userProfile.annualTotalCost = 0
        requiredLicenseId = 0

        userProfileLicenses.forEach(userProfileLicense => {
          if (userProfileLicense.technologyUserProfileId === userProfile.technologyUserProfileId &&
              userProfileLicense.quoteLicenseTypeId === this.iQuoteStore_GetQuote.quoteLicenseTypeId &&
              userProfileLicense.quoteServiceLevelTypeId === this.iQuoteStore_GetQuote.quoteServiceLevelTypeId &&
              userProfileLicense.licenseTierId === tierId) {
            requiredLicenseId = userProfileLicense.licenseId

            if (requiredLicenseId !== 0) {
              this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
              userProfile.annualTotalCost += userProfile.minCommitment * this.utilFunctions_GetLicenseCost(requiredLicenseId) * 12
              userProfile.annualPrice += userProfile.minCommitment * this.utilFunctions_GetLicensePrice(requiredLicenseId) * 12
              userProfile.rate += this.utilFunctions_GetLicensePrice(requiredLicenseId)
            }
          }
        })
      })

      this.setQuoteTechnologyMitelUC(quoteTechnologyMitelUC)
    }
  }
}
