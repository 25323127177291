
import authAPI from '../interceptors/authInterceptors'
import { AuthEventBus } from '../../events/authEvents'
import { mapActions } from 'vuex'
import { usersMixin } from '../../mixins/users'
export default {
  mixins: [
    usersMixin
  ],
  methods: {
    ...mapActions([
      'setUsername',
      'setTokens',
      'setUserid'
    ]),
    authRequestSendLogon (username, password) {
      this.setUsername(username)
      return authAPI.post('/Credentials/login', {
        username: username,
        password: password
      })
        .then(res => {
          // If request for logon is successful then the tokens in local storage should be set for future authorized endpoints.
          this.setTokens(res.data)
          return (true)
        })
        .catch((__) => {
          return false
        })
    },
    authRequestSendPasswordReset (Username, Password, ResetToken) {
      let resetObject
      resetObject = {
        Username: Username,
        Password: Password,
        ResetToken: ResetToken
      }
      authAPI.post('/ResetPassword', resetObject)
        .then(res => {
          if (res.data.status === 'error') {
            this.$store.commit('showMessage', { content: res.data.message, color: 'red', timeout: 3000 })
          } else {
            this.$emit('user-updated', this.username)
            this.$store.commit('showMessage', { content: `Password changed for ${Username}`, color: 'blue', timeout: 3000 })
          }
        })
        .catch((__) => {
          this.$store.commit('showMessage', { content: 'Invalid request', color: 'red', timeout: 3000 })
          this.oldPasswordError = true
          this.totalError = true
        })
    },
    authRequestSendPasswordChange (Username, Password, OldPassword) {
      var rtn = false
      authAPI.post('/ChangePassword', {
        Username: Username,
        Password: Password,
        OldPassword: OldPassword
      })
        .then(res => {
          if (res.data.status === 'error') {
            this.$store.commit('showMessage', { content: res.data.message, color: 'red', timeout: 3000 })
          } else if (res.status === 204) {
            this.$store.commit('showMessage', { content: 'The old password you have entered is incorrect.', color: 'red', timeout: 3000 })
          } else if (res.status === 200) {
            rtn = true
            this.$store.commit('showMessage', { content: `Password changed for ${Username}`, color: 'blue', timeout: 3000 })
          }
        })
        .catch((__) => {
          this.$store.commit('showMessage', { content: 'Invalid request', color: 'red', timeout: 3000 })
        })
      return rtn
    },
    authRequestSendSetPassword (username, password, token) {
      AuthEventBus.$emit('show-loader', true, 'Setting Password...')
      authAPI.post('/ChangePassword/new', {
        Username: username,
        Password: password,
        Token: token
      })
        .then(res => {
          AuthEventBus.$emit('show-loader', false)
          if (res.data.status === 'error') {
            this.$store.commit('showMessage', { content: res.data.message, color: 'red', timeout: 3000 })
          } else {
            this.$store.commit('showMessage', { content: `Password changed for ${username}`, color: 'blue', timeout: 3000 })
            // this.authRequestSendLogon(username, password)
            AuthEventBus.$emit('load-page', '')
          }
        })
        .catch((__) => {
          this.$store.commit('showMessage', { content: 'Invalid request', color: 'red', timeout: 3000 })
        })
    },
    async authRequestCreateNewUser ({ emailAddress, userName, enabled }) {
      await authAPI.post('/users', {
        emailAddress,
        userName,
        enabled,
        password: Math.random().toString(36).substr(2, 5)
      })
        .then(res => {
          return res.data.status !== 'error'
        })
        .catch((__) => {
          return false
        })
    },
    async authRequestUpdateUser ({ emailAddress, userName, originalUserName }) {
      var rtn = false
      await authAPI.put('/users', {
        emailAddress,
        userName,
        originalUserName
      })
        .then(res => {
          rtn = res.statusText === '' || res.statusText.toLowerCase() === 'ok'
        })
        .catch((__) => {
          rtn = false
        })
      return rtn
    },
    async authRequestDeleteUser ({ userName }) {
      authAPI.delete('/users', {
        data: {
          userName
        }
      })
        .then(res => {
          return res.data.status !== 'error'
        })
        .catch((__) => {
          return false
        })
    },
    async authRequestWelcomeUser ({ userName }) {
      var url = window.location.origin + '/'
      await authAPI.post('/ForgottenPassword', {
        SiteURL: url,
        Username: userName,
        Type: 'create'
      })
    },
    async getRefreshToken (userName) {
      // console.log('Refreshing: ' + localStorage.getItem('refreshToken'))
      var payload = '{"Username":"' + userName + '"}'
      await authAPI.post('/RefreshToken/refresh', payload, {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Refresh': localStorage.getItem('refreshToken'),
        'Content-Type': 'application/json'
      })
        .then(res => {
          this.setTokens(res.data)
          // console.log('Refreshed token to: ' + localStorage.getItem('refreshToken'))
        })
    }
  }
}
