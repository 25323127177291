<template>
  <div
    class="userGroupsHolder"
  >
    <Spinner
      v-if="loading"
      id="userGroupMemberSpinner"
    />
    <p
      v-if="!loading && (!userUserGroups || userUserGroups.length === 0)"
      class="alert"
    >
      This user has no User Groups.
    </p>
    <table
      v-if="!loading && userUserGroups.length > 0"
    >
      <tbody>
        <tr
          v-for="g in userUserGroups"
          :class="getRowColour()"
          :key="g.id"
        >
          <td
            :title="g.name"
          >{{ g.name }} </td>
          <td
            style="text-align:right;width:8%"
          >
            <input
              v-if="showDeleteButton(g)"
              type="button"
              class="deleteButton"
              title="Remove member from userGroup"
              @click="removeUserGroup(g)"
            >
          </td>
        </tr>
      </tbody>
    </table>
    <input
      v-if="stormAdmin && !loading"
      id="addUserGroupButton"
      type="button"
      class="addUserGroupButton"
      value="Add User Group"
      title="Adds a user broup to the selected user"
      @click="addUserGroup()"
    >
    <StormAddUserGroup
      :storm-user-id="stormUserId"
      :current-web-users="currentWebUsers"
      :max-web-users="maxWebUsers"
      @reload-storm-user-groups="setComponent()"
    />
  </div>
</template>
<script>
import { stormMixin } from '../../../mixins/stormMixin'
import Spinner from '../../common/spinner'
import StormAddUserGroup from './stormAddUserGroup'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'UserGroups',
  components: {
    Spinner,
    StormAddUserGroup
  },
  mixins: [
    stormMixin
  ],
  props: {
    stormUserId: {
      type: Number,
      default: -1
    },
    stormUser: {
      type: Object,
      default: function () { return {} }
    },
    stormAdmin: {
      type: Boolean,
      default: false
    },
    maxWebUsers: {
      type: Number,
      default: 0
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      showNew: false,
      loading: false,
      userUserGroups: [],
      currentWebUsers: 0
    }
  },
  computed: {
    ...mapState([
      'openSubForm3'
    ]),
    stormUsers () {
      return this.$store.getters.stormUsers
    },
    adminEmail () {
      return process.env.VUE_APP_STORM_ADMIN_USER
    },
    ssoSecurityGroup () {
      return this.$store.getters.stormSSOSecurityGroup
    },
    stormAdminSecurityGroup () {
      return this.$store.getters.stormAdminSecurityGroup
    },
    stormInternalUserGroup () {
      return this.$store.getters.stormInternalUserGroup
    },
    stormStandardUserGroup () {
      return this.$store.getters.stormStandardUserGroup
    },
    stormUserSecurityGroup () {
      return this.$store.getters.stormUserSecurityGroup
    },
    stormDefaultUser () {
      return this.$store.getters.stormDefaultUser
    }
  },
  watch: {
    stormUserId: async function () {
      await this.setComponent()
    }
  },
  mounted: async function () {
    await this.setComponent()
  },
  methods: {
    ...mapActions([
      'setSubForm3',
      'setUserGroups'
    ]),
    setComponent: async function () {
      this.loading = true
      this.userUserGroups = []
      if (this.stormUserId > 0) {
        this.userUserGroups = await this.getStormUserGroupsByUser(this.stormUserId)
        if (this.userUserGroups === '') {
          this.userUserGroups = []
        }
      }
      await this.setWebAccessMembers()
      this.setSubForm3(false)
      this.loading = false
    },
    addUserGroup: async function () {
      this.setSubForm3(true)
    },
    removeUserGroup: async function (g) {
      if (this.userUserGroups.length < 2) {
        this.$store.commit('showMessage', { content: 'At least one user group must be awarded to the user.', color: 'red', timeout: 3000 })
      } else {
        await this.deleteUserFromStormUserGroup(this.stormUserId, g.id)
        await this.setComponent()
      }
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    },
    setWebAccessMembers: async function () {
      var userCount = 0
      for (let i = 0; i < this.stormUsers.length; i++) {
        var lsg = await this.getStormUserGroupsByUser(this.stormUsers[i].id)
        if (lsg.length > 0) {
          lsg.forEach(sg => {
            if (sg.name === this.ssoSecurityGroup) {
              userCount++
            }
          })
        }
      }
      this.currentWebUsers = userCount
    },
    showDeleteButton (g) {
      // This button will only be shown if you are a storm administrator
      // or you are our admin account (in case a role is not awarded that prevents you)
      // or the admin account, but not the internal user group, which should never be deleted
      // or the default viewer, but not the standard user group, which should never be deleted
      var isAdmin = this.stormAdmin || this.stormUser.email === this.adminEmail
      if (g.name === this.stormInternalUserGroup && this.stormUser.email === this.adminEmail) {
        return false
      }
      if (g.name === this.stormStandardUserGroup && this.stormUser.email === this.stormDefaultUser) {
        return false
      } else {
        return isAdmin
      }
    }
  }
}
</script>
<style scoped>
  .userGroupsHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .addUserGroupButton {
    margin: 10px;
    float: right;
    cursor: pointer;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
  }
</style>
