<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      #<label for="txtiQuoteReference">iQuote</label>
      <input
        id="txtiQuoteReference"
        :value="iQuoteStore_GetQuote.id"
        type="text"
        readonly="true"
      >
      <br>
      <label for="txtAccount">Account</label>
      <input
        id="txtAccount"
        :value="iQuoteStore_Getquote.salesforceAccountName"
        readonly="true"
        type="text"
      >
      <br>
      <label for="txtFirstName">Contact Name</label>
      <input
        id="txtFirstName"
        :value="iQuoteStore_Getquote.salesforceContactFirstName"
        type="text"
        readonly="true"
      >
      <input
        id="txtLastName"
        :value="iQuoteStore_Getquote.salesforceContactLastName"
        readonly="true"
        type="text"
      >
      <br>
      <label for="txtQuoteDescription">Quote Description</label>
      <input
        id="txtQuoteDescription"
        :value="iQuoteStore_GetQuote.quoteDescription"
        readonly="true"
        type="text"
      >
      <br>
      <label for="txtTermLength">Term Length</label>
      <input
        id="txtTermLength"
        :value="iQuoteStore_GetQuote.termLengthMonths + ' Months'"
        readonly="true"
        type="text"
      >
    </div>
    <hr>
    <div
      v-if="iQuoteStore_GetQuoteSIPMigrationProperties.sipAccessTypeId === 1 /* Gold */"
      class="InputError"
    >
      <br>
      SIP Migration has been selected with "Gold" Service level. Pricing is budgetary.  This will have to be validated by pre-sales
    </div>
    <div
      class="quotePanel"
    >
      <div
        v-if="customerSummaryComponent_SubTotalUpfrontCharges > 0"
      >
        <h2 v-if="iQuoteStore_GetQuote.chargeUpfront">Upfront Charges</h2>
        <table
          v-if="iQuoteStore_GetQuote.chargeUpfront"
          id="tblUpfrontFees"
          class="noHoverPointer"
        >
          <thead>
            <tr><th colspan="2" /><th>Qty</th><th>Price</th><th>Total Price</th></tr>
          </thead>
          <tbody
            v-for="entry in iQuoteStore_GetQuoteCustomerSummaryEntries"
            :key="entry.id">
            <tr
              v-if="entry.isAnnualCharge === false">
              <td
                colspan="2">{{ entry.description }}
                <TooltipIcon
                  v-if="entry.tooltip !== undefined"
                  :text="entry.tooltip"
                  width="200"
                  align="right" />
              </td>
              <td>
                <input
                  id="infrastructureQuantity"
                  :value="entry.quantity"
                  readonly="true"
                  type="number"
                >
              </td>
              <td>£
                <input
                  :id="'customerSummaryUpfrontUnitPrice' + entry.id"
                  :value="iQuoteFunctions_ThousandSeparator(entry.price.toFixed(2))"
                  class="currencyInput"
                  readonly="true"
                  type="text"
                >
              </td>
              <td>£
                <input
                  :id="'customerSummaryUpfrontTotalPrice' + entry.id"
                  :value="iQuoteFunctions_ThousandSeparator((entry.price * entry.quantity).toFixed(2))"
                  class="currencyInput"
                  readonly="true"
                  type="text"
                >
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="TableSectionFooter">
              <td
                colspan="4"
                style="text-align:left; font-weight:bold; padding-right:20px;">Upfront Charges Subtotal:</td>
              <td>£
                <input
                  id="numTotalUpfrontPrice"
                  :value="iQuoteFunctions_ThousandSeparator(customerSummaryComponent_SubTotalUpfrontCharges.toFixed(2))"
                  class="currencyInput"
                  readonly="true"
                  type="text"
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Subscription Minimum Commitment</h2>
      <table
        id="tblRecurringLicenses"
        class="noHoverPointer">
        <thead>
          <tr><th colspan="2" /><th>Qty<br>&nbsp;</th><th>Monthly Price<br>(Per unit)</th><th>Annual Price<br>(Total)</th></tr>
        </thead>
        <tbody
          v-for="cat in customerSummaryCategories"
          :key="cat.id">
          <tr
            v-if="categoryEntries(cat.id, true).length > 0"
            class="TableSectionHeader">
            <td colspan="5">{{ cat.categoryName }}</td>
          </tr>
          <tr
            v-for="entry in categoryEntries(cat.id, true)"
            :key="entry.description">
            <td colspan="2">{{ entry.description }}</td>
            <td>
              <input
                :id="'quantity' + entry.description"
                :value="entry.quantity"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                :id="'price' + entry.description"
                :value="iQuoteFunctions_ThousandSeparator(entry.price.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                :id="'numAnnualPrice' + entry.description"
                :value="iQuoteFunctions_ThousandSeparator(entry.annualPrice.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
        <tbody class="nostripes">
          <tr class="TableSectionFooter">
            <td
              colspan="4"
              style="text-align:left; font-weight:bold; padding-right:20px;">Annual Charges Subtotal:</td>
            <td>£
              <input
                id="numSubTotal"
                :value="iQuoteFunctions_ThousandSeparator(customerSummaryComponent_SubTotalAnnualCharges.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
          <tr
            v-if="calculationsCommonMixin_totalYr1Discount > 0"
            class="TableSectionFooter">
            <td
              colspan="4"
              style="text-align:left; font-weight:bold; padding-right:20px;">Yr1 Discount:</td>
            <td>£
              <input
                id="numYr1Discount"
                :value="iQuoteFunctions_ThousandSeparator(calculationsCommonMixin_totalYr1Discount.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr class="TableSectionFooter">
            <td
              colspan="2"
              style="text-align:left; font-weight:bold; padding-right:20px;font-size:1.1em">Contract Term Minimum Commit Total:</td>
            <td colspan="2">£
              <input
                id="numMinCommitTotal"
                :value="iQuoteFunctions_ThousandSeparator((iQuoteCalculationsReviewScreenMixin_tcv).toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="quotePanel">
      <div class="buttons">
        <input
          id="btnBack"
          value="< Back"
          type="button"
          @click="gotoPage('Review')"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteCalculationsReviewScreenMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsReviewScreenMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'

export default {
  name: 'CustomerSummary',
  components: {
    TooltipIcon
  },
  mixins: [
    iQuoteCalculationsCommonMixin, iQuoteFunctions, iQuoteCalculationsReviewScreenMixin, iQuoteStoreMixin
  ],
  data () {
    return {
      license: {},
      licensesRequiredSummary: [],
      quoteBladeServers: [],
      quote: {},
      customerSummaryCategories: [
        { id: 1, categoryName: 'Icon Communicate with Avaya Aura' },
        { id: 2, categoryName: 'Icon Communicate with Mitel' },
        { id: 3, categoryName: 'Icon Contact with Avaya AACC' },
        { id: 4, categoryName: 'Icon Contact with Avaya Elite' },
        { id: 5, categoryName: 'Icon Contact with Mitel' },
        { id: 6, categoryName: 'Icon Contact with Callmedia' },
        { id: 7, categoryName: 'Gamma SIP' },
        { id: 8, categoryName: 'Callmedia CX Now' },
        { id: 9, categoryName: 'Icon Gateway' },
        { id: 10, categoryName: 'Sip Services' },
        { id: 11, categoryName: 'Expansions' },
        { id: 12, categoryName: 'Teams Connector' },
        { id: 13, categoryName: 'Phoneline+' },
        { id: 14, categoryName: 'Genesys' },
        { id: 15, categoryName: 'RingCentral (UC)' },
        { id: 16, categoryName: 'RingCentral (CC)' }
      ]
    }
  },
  computed: {
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    customerSummaryComponent_SubTotalUpfrontCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteCustomerSummaryEntries.forEach(entry => {
        if (!entry.isAnnualCharge) {
          total += entry.price * entry.quantity
        }
      })
      return total
    },
    customerSummaryComponent_SubTotalAnnualCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteCustomerSummaryEntries.forEach(entry => {
        if (entry.isAnnualCharge) {
          total += entry.annualPrice
        }
      })
      return total
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),
    gotoPage (page) {
      this.setCurrentQuoteState(page)
    },
    categoryEntries (categoryId, isAnnualCharge) {
      var filteredEntries = []
      var entries = this.iQuoteStore_GetQuoteCustomerSummaryEntries

      for (let index = 0; index < entries.length; index++) {
        const entry = entries[index]
        if (entry.customerSummaryCategoryId === categoryId && entry.isAnnualCharge === isAnnualCharge) {
          filteredEntries.push(entry)
        }
      }
      return filteredEntries
    }
  }
}
</script>
<style scoped>
  #tblRecurringLicenses{margin-bottom: 10px;}
  #tblRecurringLicenses tr {padding-bottom: 30px;}
  #tblUpfrontFees {margin-bottom: 10px;}
  #tblRecurring {margin-bottom: 10px;display: none;}

  .licenseContainer{
    display: inline-block;
  }

  .quotePanel {
    width: 950px;
    text-align: left;
  }

  #txtiQuoteReference {width: 240px;}
  #txtAccount  {width: 240px;}
  #txtFirstName {width: 115px;}
  #txtLastName {width: 115px;}
  #txtQuoteDescription {width: 240px;}
  #txtTermLength {width: 240px;}
</style>
