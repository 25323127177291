// This module is used for state mamangement for Autotask configuration values
import autotaskService from '../../communications/interceptors/apiInterceptor'
const state = {
  autotaskContact: [],
  priorityList: [],
  statusList: [],
  sourceList: [],
  queueIdList: [],
  serviceLevelAgreementIdList: [],
  issueTypeList: [],
  subIssueTypeList: [],
  ticketCategoryList: [],
  ticketTypeList: [],
  creatorTypeList: [],
  publishList: [],
  configurationItemTypeList: [],
  contractBlocks: []
}

const mutations = {
  SET_AUTOTASK_CONTACT (state, autotaskContact) {
    state.autotaskContact = autotaskContact
  },
  SET_PRIORITY_LIST (state, priorityList) {
    state.priorityList = priorityList
  },
  SET_STATUS_LIST (state, statusList) {
    state.statusList = statusList
  },
  SET_SOURCE_LIST (state, sourceList) {
    state.sourceList = sourceList
  },
  SET_QUEUEID_LIST (state, queueIdList) {
    state.queueIdList = queueIdList
  },
  SET_SLAID_LIST (state, serviceLevelAgreementIdList) {
    state.serviceLevelAgreementIdList = serviceLevelAgreementIdList
  },
  SET_ISSUE_TYPE_LIST (state, issueTypeList) {
    state.issueTypeList = issueTypeList
  },
  SET_SUBISSUE_TYPE_LIST (state, subIssueTypeList) {
    state.subIssueTypeList = subIssueTypeList
  },
  SET_TICKET_CATEGORY_LIST (state, ticketCategoryList) {
    state.ticketCategoryList = ticketCategoryList
  },
  SET_TICKET_TYPE_LIST (state, ticketTypeList) {
    state.ticketTypeList = ticketTypeList
  },
  SET_CREATOR_TYPE_LIST (state, creatorTypeList) {
    state.creatorTypeList = creatorTypeList
  },
  SET_PUBLISH_LIST (state, publishList) {
    state.publishList = publishList
  },
  SET_CONFIGURATION_ITEMTYPE_LIST (state, configurationItemTypeList) {
    state.configurationItemTypeList = configurationItemTypeList
  },
  SET_CONTRACT_BLOCKS (state, contractBlocks) {
    state.contractBlocks = contractBlocks
  }
}

const actions = {
  setAutotaskContact: ({ commit }, autotaskContact) => {
    commit('SET_AUTOTASK_CONTACT', autotaskContact)
  },
  setPriorityList: ({ commit }, priorityList) => {
    commit('SET_PRIORITY_LIST', priorityList)
  },
  setStatusList: ({ commit }, statusList) => {
    commit('SET_STATUS_LIST', statusList)
  },
  setSourceList: ({ commit }, sourceList) => {
    commit('SET_SOURCE_LIST', sourceList)
  },
  setQueueIdList: ({ commit }, queueIdList) => {
    commit('SET_QUEUEID_LIST', queueIdList)
  },
  setServiceLevelAgreementIDList: ({ commit }, serviceLevelAgreementIdList) => {
    commit('SET_SLAID_LIST', serviceLevelAgreementIdList)
  },
  setIssueTypeList: ({ commit }, issueTypeList) => {
    commit('SET_ISSUE_TYPE_LIST', issueTypeList)
  },
  setSubIssueTypeList: ({ commit }, subIssueTypeList) => {
    commit('SET_SUBISSUE_TYPE_LIST', subIssueTypeList)
  },
  setTicketCategoryList: ({ commit }, ticketCategoryList) => {
    commit('SET_TICKET_CATEGORY_LIST', ticketCategoryList)
  },
  setTicketTypeList: ({ commit }, ticketTypeList) => {
    commit('SET_TICKET_TYPE_LIST', ticketTypeList)
  },
  setCreatorTypeList: ({ commit }, creatorTypeList) => {
    commit('SET_CREATOR_TYPE_LIST', creatorTypeList)
  },
  setPublishList: ({ commit }, publishList) => {
    commit('SET_PUBLISH_LIST', publishList)
  },
  setConfigurationItemTypeList: ({ commit }, configurationItemTypeList) => {
    commit('SET_CONFIGURATION_ITEMTYPE_LIST', configurationItemTypeList)
  },
  setContractBlocks: ({ commit }, contractBlocks) => {
    commit('SET_CONTRACT_BLOCKS', contractBlocks)
  },
  initAutotask: ({ commit }) => {
    var axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }
    try {
      autotaskService.get('/autotaskservice/references/status', axiosConfig).then((response) => {
        commit('SET_STATUS_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for status: ' + e)
    }
    try {
      autotaskService.get('/autotaskservice/references/source', axiosConfig).then((response) => {
        commit('SET_SOURCE_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for source')
    }
    try {
      autotaskService.get('/autotaskservice/references/queueID', axiosConfig).then((response) => {
        commit('SET_QUEUEID_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for queueID')
    }
    try {
      autotaskService.get('/autotaskservice/references/priority', axiosConfig).then((response) => {
        commit('SET_PRIORITY_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling references for priority')
    }
    try {
      autotaskService.get('/autotaskservice/references/serviceLevelAgreementID', axiosConfig).then((response) => {
        commit('SET_SLAID_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for serviceLevelAgreementID')
    }
    try {
      autotaskService.get('/autotaskservice/references/issueType', axiosConfig).then((response) => {
        commit('SET_ISSUE_TYPE_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for issueType')
    }
    try {
      autotaskService.get('/autotaskservice/references/subIssueType', axiosConfig).then((response) => {
        commit('SET_SUBISSUE_TYPE_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for subIssueType')
    }
    try {
      autotaskService.get('/autotaskservice/references/ticketCategory', axiosConfig).then((response) => {
        commit('SET_TICKET_CATEGORY_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for ticketCategory')
    }
    try {
      autotaskService.get('/autotaskservice/references/ticketType', axiosConfig).then((response) => {
        commit('SET_TICKET_TYPE_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for ticketType')
    }
    try {
      autotaskService.get('/autotaskservice/references/publish', axiosConfig).then((response) => {
        commit('SET_PUBLISH_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for publish')
    }
    try {
      autotaskService.get('/autotaskservice/references/configurationItemType', axiosConfig).then((response) => {
        // console.log('setting configuration items...')
        commit('SET_CONFIGURATION_ITEMTYPE_LIST', response.data)
      })
    } catch (e) {
      console.log('Error calling getReferenceDataList for configurationItemType')
    }
  }
}
const getters = {
  autotaskContact: state => {
    return state.autotaskContact
  },
  priorityList: (state) => {
    return state.priorityList
  },
  statusList: (state) => {
    return state.statusList
  },
  sourceList: (state) => {
    return state.sourceList
  },
  queueIdList: (state) => {
    return state.queueIdList
  },
  serviceLevelAgreementIdList: (state) => {
    return state.serviceLevelAgreementIdList
  },
  issueTypeList: (state) => {
    return state.issueTypeList
  },
  subIssueTypeList: (state) => {
    return state.subIssueTypeList
  },
  ticketCategoryList: (state) => {
    return state.ticketCategoryList
  },
  ticketTypeList: (state) => {
    return state.ticketTypeList
  },
  creatorTypeList: (state) => {
    return state.creatorTypeList
  },
  publishList: (state) => {
    return state.publishList
  },
  configurationItemTypeList: (state) => {
    return state.configurationItemTypeList
  },
  contractBlocks: (state) => {
    return state.contractBlocks
  }
}

// This export is used to allow the vuex components to extend ito the global state
export default {
  state,
  mutations,
  actions,
  getters
}
