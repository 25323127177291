import portalAPIGateway from '../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteExpansionsAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteExpansionsAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    getQuoteExpansionVirtualMachinesFromQuoteId: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteExpansionsAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/quoteExpansionVirtualMachines/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data

          if (data === '') {
            // No expansions are on the quote
            data = []
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteExpansionVirtualMachinesFromDB error: ' + error)
      }
      return data
    },
    saveQuoteExpansionVirtualMachinesToDB: async function (quoteId, expansions) {
      var axiosConfig = this.iQuoteQuoteExpansionsAPIMixin_getAxiosConfig()

      try {
        await portalAPIGateway.post('iquote/quoteExpansionVirtualMachines/' + quoteId, expansions.expansionVirtualMachines, axiosConfig).then(() => {
          return true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('saveQuoteExpansionVirtualMachinesToDB failed!')
      }
    }
  }
}
