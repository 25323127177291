<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconGamma"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>Icon Gateway</h3>
      <br>
      <table
        v-if="quoteTechnologyIconGateway.options.id !== undefined"
        id="iconGatewayTable">
        <tr>
          <th class="iconGatewayLabelColumn"/>
          <th>Fareham</th>
          <th>Goswell Road</th>
        </tr>
        <tr>
          <td>
            <label>Rack U*
              <TooltipIcon
                text="Rack Space measure in Unit (U) size.  Shared 3Kw Rack (2Kw Rack Annual Cost + Additional 1Kw), cost per 2U, 44U available" />
            </label>
          </td>
          <td>
            <select
              id="cbofarehamRackU"
              v-model="quoteTechnologyIconGateway.options.farehamRackU"
              class="rackUnitsDropdown"
            >
              <option
                v-for="c in rackU"
                :key="c.id"
                :value="c.id"
                :title="c.name"
              >{{ c.name }}</option>
            </select>
          </td>
          <td>
            <select
              id="cboGoswellRackU"
              v-model="quoteTechnologyIconGateway.options.goswellRackU"
              class="rackUnitsDropdown"
            >
              <option
                v-for="c in rackU"
                :key="c.id"
                :value="c.id"
                :title="c.name"
              >{{ c.name }}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            <label>Size of Connection
              <TooltipIcon
                text="In Data Centre connectivity specify the number of RJ45 ports and Cat 6a connections required" />
            </label>
          </td>
          <td>
            <input
              id="numConnectionSizeGoswell"
              v-model.number="quoteTechnologyIconGateway.options.farehamConnectionSize"
              :class="{invalidInput: !isValidFarehamConnectionSize}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
          <td>
            <input
              id="numConnectionSizeGoswell"
              v-model.number="quoteTechnologyIconGateway.options.goswellConnectionSize"
              :class="{invalidInput: !isValidGoswellConnectionSize}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
        </tr>
        <tr>
          <td colspan="3">&nbsp;</td>
        </tr>
        <tr>
          <td
            colspan="3"><label>*Allow at least 1U for demarcation</label><hr></td>
        </tr>
        <tr>
          <td colspan="3">&nbsp;</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <th
            colspan="2"
            class="columnAppliesToBothDataCentres">Bandwidth (Mbps)</th>
        </tr>
        <tr>
          <td>
            <label>WAN Dual Feed (Diverse &amp; Resilient)
              <TooltipIcon
                text="Cross-connect connection size.  This value is duplicated in both Fareham and Goswell Road" />
            </label>
          </td>
          <td
            colspan="2"
            class="columnAppliesToBothDataCentres">
            <input
              id="numWanTotalFeedBandwidth"
              v-model.number="quoteTechnologyIconGateway.options.wanTotalFeedBandwidth"
              :class="{invalidInput: !isValidWanTotalFeedBandwidth}"
              class="small"
              type="number"
              min="0"
              max="10000"
              @keyup="wanTotalFeedBandwidthChanged()"
            >
          </td>
        </tr>
        <tr v-if="!priorityBandwidthEqualsWanBandwidth">
          <td
            colspan="3"
            class="InputError">The total of the priority levels below, must equals the value of the WAN Dual Feed above</td>
        </tr>
        <tr>
          <td colspan="3">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <label class="wanPriority">Gold (EF)
              <TooltipIcon
                text="By default, all cross-connect bandwidth is assigned to Expedited Forwarding (EF).  This is used to provide resources to latency to voice" />
            </label>
          </td>
          <td
            colspan="2"
            class="columnAppliesToBothDataCentres">
            <input
              id="numWanGoldBandwidth"
              v-model.number="quoteTechnologyIconGateway.options.wanGoldBandwidth"
              :class="{invalidInput: !isValidWanGoldBandwidth}"
              class="small"
              type="number"
              min="0"
              max="10000"
            >
          </td>
        </tr>
        <tr>
          <td>
            <label class="wanPriority">Silver (AF)
              <TooltipIcon
                text="By default, this should be zero.  Assured Forwarding (AF) is used to provide priority values to different data applications" />
            </label>
          </td>
          <td
            colspan="2"
            class="columnAppliesToBothDataCentres">
            <input
              id="numWanSilverBandwidth"
              v-model.number="quoteTechnologyIconGateway.options.wanSilverBandwidth"
              :class="{invalidInput: !isValidWanSilverBandwidth}"
              class="small"
              type="number"
              min="0"
              max="10000"
            >
          </td>
        </tr>
        <tr>
          <td>
            <label class="wanPriority">Bronze (BE)
              <TooltipIcon
                text="By default, this should be zero.  Best Efforts (BE) is used when no priority is required to voice" />
            </label>
          </td>
          <td
            colspan="2"
            class="columnAppliesToBothDataCentres">
            <input
              id="numWanBronzeBandwidth"
              v-model.number="quoteTechnologyIconGateway.options.wanBronzeBandwidth"
              :class="{invalidInput: !isValidWanBronzeBandwidth}"
              class="small"
              type="number"
              min="0"
              max="10000"
            >
          </td>
        </tr>
      </table>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'IconGateway',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin],
  data () {
    return {
      quote: {
        id: 0
      },
      codecs: [],
      quoteTechnologyIconGateway: {
        options: {
          id: 0,
          farehamRackU: 2,
          goswellRackU: 2,
          farehamConnectionSize: 0,
          goswellConnectionSize: 0,
          wanTotalFeedBandwidth: 500,
          wanGoldBandwidth: 500,
          wanSilverBandwidth: 0,
          wanBronzeBandwidth: 0
        },
        previousTotalWanValue: 0
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconGamma () {
      return require(`../../../assets/icons/iconGateway.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidFarehamConnectionSize () {
      var value = this.quoteTechnologyIconGateway.options.farehamConnectionSize
      return (this.iQuoteFunctions_IsInteger(value) && value > 0)
    },
    isValidGoswellConnectionSize () {
      var value = this.quoteTechnologyIconGateway.options.goswellConnectionSize
      return (this.iQuoteFunctions_IsInteger(value) && value > 0)
    },
    isValidWanTotalFeedBandwidth () {
      var value = this.quoteTechnologyIconGateway.options.wanTotalFeedBandwidth
      return (this.iQuoteFunctions_IsInteger(value) && value > 0 && value < 10000)
    },
    isValidWanGoldBandwidth () {
      var value = this.quoteTechnologyIconGateway.options.wanGoldBandwidth
      return (this.iQuoteFunctions_IsInteger(value) && this.priorityBandwidthEqualsWanBandwidth)
    },
    isValidWanSilverBandwidth () {
      var value = this.quoteTechnologyIconGateway.options.wanSilverBandwidth
      return (this.iQuoteFunctions_IsInteger(value) && this.priorityBandwidthEqualsWanBandwidth)
    },
    isValidWanBronzeBandwidth () {
      var value = this.quoteTechnologyIconGateway.options.wanBronzeBandwidth
      return (this.iQuoteFunctions_IsInteger(value) && this.priorityBandwidthEqualsWanBandwidth)
    },
    totalwanPriorityBandwidth () {
      return this.quoteTechnologyIconGateway.options.wanGoldBandwidth +
             this.quoteTechnologyIconGateway.options.wanSilverBandwidth +
             this.quoteTechnologyIconGateway.options.wanBronzeBandwidth
    },
    priorityBandwidthEqualsWanBandwidth () {
      return this.totalwanPriorityBandwidth === this.quoteTechnologyIconGateway.options.wanTotalFeedBandwidth
    },
    rackU () {
      var values = []

      for (let i = 1; i <= 11; i++) {
        var units = i * 2
        values.push({ id: units, name: units })
      }

      return values
    }
  },
  async mounted () {
    this.codecs = this.$store.getters.codecs
    this.quote = this.$store.getters.quote

    var options
    var quoteTechnologyIconGatewayStore = this.$store.getters.quoteTechnologyIconGateway

    if (quoteTechnologyIconGatewayStore.options === undefined) {
      // Retrieve data from DB
      var quoteOptionsIconGatewayFromDB = await this.getQuoteOptionsIconGateway(this.quote.id)

      if (quoteOptionsIconGatewayFromDB.id === undefined) {
        // Not in DB - create new
        options = {
          id: 0,
          quoteId: this.quote.id,
          farehamRackU: 2,
          goswellRackU: 2,
          farehamConnectionSize: 0,
          goswellConnectionSize: 0,
          wanTotalFeedBandwidth: 500,
          wanGoldBandwidth: 500,
          wanSilverBandwidth: 0,
          wanBronzeBandwidth: 0
        }
      } else {
        // Set component data to what came from DB
        options = quoteOptionsIconGatewayFromDB
      }

      // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
      this.$set(this.quoteTechnologyIconGateway, 'options', options)

      this.previousTotalWanValue = this.quoteTechnologyIconGateway.options.wanTotalFeedBandwidth

      this.setQuoteTechnologyIconGateway(this.quoteTechnologyIconGateway)
    } else {
      this.quoteTechnologyIconGateway = quoteTechnologyIconGatewayStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyIconGateway'
    ]),
    async gotoPage (page) {
      if (this.quoteTechnologyIconGateway.overrideSIPChannels === 0 && this.quoteTechnologyIconGateway.sipConfigured === 0) {
        var technologyId = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.iconGateway)
        // Delete from DB
        this.deleteQuoteTechnology(technologyId)
        // Clear form details from store
        this.setQuoteTechnologyIconGateway({})
      } else {
        this.setQuoteTechnologyIconGateway(this.quoteTechnologyIconGateway)
      }
      this.setCurrentQuoteState(page)
    },
    wanTotalFeedBandwidthChanged () {
      if (this.isValidWanTotalFeedBandwidth &&
          this.quoteTechnologyIconGateway.options.wanGoldBandwidth === this.totalwanPriorityBandwidth && /* Gold is the only priority with a value */
          (this.totalwanPriorityBandwidth === 0 || this.totalwanPriorityBandwidth === this.previousTotalWanValue)) {
        this.quoteTechnologyIconGateway.options.wanGoldBandwidth = this.quoteTechnologyIconGateway.options.wanTotalFeedBandwidth
        this.previousTotalWanValue = this.quoteTechnologyIconGateway.options.wanTotalFeedBandwidth
      }
    },
    async finishService () {
      var ValidationFailed = false
      if (!this.isValidFarehamConnectionSize) {
        ValidationFailed = true
      }
      if (!this.isValidGoswellConnectionSize) {
        ValidationFailed = true
      }
      if (!this.isValidWanTotalFeedBandwidth) {
        ValidationFailed = true
      }
      if (!this.isValidWanGoldBandwidth) {
        ValidationFailed = true
      }
      if (!this.isValidWanSilverBandwidth) {
        ValidationFailed = true
      }
      if (!this.isValidWanBronzeBandwidth) {
        ValidationFailed = true
      }
      if (!this.priorityBandwidthEqualsWanBandwidth) {
        ValidationFailed = true
      }

      if (!ValidationFailed) {
      // Save to DB only if valid data is supplied

        if (this.quoteTechnologyIconGateway.id === 0 || this.quoteTechnologyIconGateway.id === undefined) {
          this.quoteTechnologyIconGateway.id = await this.addQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.iconGateway)
        }

        this.setQuoteTechnologyIconGateway(this.quoteTechnologyIconGateway)
        this.saveQuoteOptionsIconGateway(this.quoteTechnologyIconGateway.options)
        this.gotoPage('SelectService')
      } else {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
      }
    }
  }
}
</script>
<style scoped>
  .quotePanel {width: 800px;}
  .quotePanel label{width:300px;}
  .quotePanel .rackUnitsDropdown{width:60px;}
  .hidden{visibility: hidden};
  .quotePanel .iconGatewayLabelColumn{width: 400px;  }
  .quotePanel .columnAppliesToBothDataCentres{padding-left:135px}
  .quotePanel .wanPriority{margin-left:50px}
</style>
