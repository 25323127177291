<template>
  <div class="widget-wrapper">
    <apexchart
      :options="getChartOptions(widget)"
      :series="getChartSeries(widget)"
      width="100%"
    />
    <div class="widget-footer">
      <div class="widget-footer-timestamp">
        <span
          :title="'Data last updated at ' + timestamp()"
          class="timestamp"
        >{{ timestamp() }}</span>
      </div>
    </div>
    <TimerProgressComponent
      id="controlRefreshBar"
      :polling-interval="pollingIntervalInSeconds"
      :widget-id="widget.id"
      @reload-widget="setWidgetData"
    />
  </div>
</template>
<script>
import TimerProgressComponent from '../UCAnalytics/timerProgressComponent'
import { reportsMixin } from '../../mixins/reportsMixin'
export default {
  name: 'ChartSurveyScores',
  components: {
    TimerProgressComponent
  },
  mixins: [
    reportsMixin
  ],
  props: {
    widget: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      timerObj: {},
      widgetData: {},
      tickCount: 1,
      barProgress: 0
    }
  },
  computed: {
    getAllMonths () {
      // Important note: Irritatingly, the GetMonth() function returns January as 0, not 1
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

      var monthArray = []
      // Get the current date
      var currentDate = new Date()
      var currentYear = currentDate.getFullYear()
      var currentMonth = currentDate.getMonth()
      var currentDay = currentDate.getDay()
      var workingYear = currentYear - 1
      var workingMonth = currentMonth
      var workingDate = new Date(workingYear, workingMonth, currentDay)

      // Derive an array of objects with month names and survey scores. We do it this
      // way so there are no gaps for months which we don't have survey scores for
      while (workingDate < currentDate) {
        var monthEntry = {
          'monthName': workingYear + '-' + months[workingMonth],
          'year': workingYear,
          'monthNumber': workingMonth + 1,
          'averageScore': 0,
          'numberOfScores': 0
        }

        monthArray.push(monthEntry)

        if (workingMonth === 11) {
          workingMonth = 0
          workingYear += 1
        } else {
          workingMonth += 1
        }
        workingDate = new Date(workingYear, workingMonth, currentDay)
      }
      return monthArray
    },
    chartLabels () {
      var rtn = []
      var allMonths = this.getAllMonths
      for (let i = 0; i < allMonths.length; i++) {
        rtn.push(allMonths[i].monthName)
      }
      return rtn
    },
    pollingIntervalInSeconds () {
      return this.widget.pollingInterval
    },
    progressTitle () {
      if ((this.pollingIntervalInSeconds - Math.round(this.tickCount)) > 60) {
        return Math.round((this.pollingIntervalInSeconds - Math.round(this.tickCount)) / 60) + ' minutes until next data refresh'
      } else {
        return (this.pollingIntervalInSeconds - Math.round(this.tickCount)) + ' seconds until next data refresh'
      }
    }
  },
  mounted () {
    this.widgetData = this.widget
  },
  methods: {
    getChartOptions (widget) {
      var showGrid = (widget.data)
      var obj = {}
      obj = {
        series: [],
        labels: showGrid ? this.chartLabels : [],
        noData: {
          text: 'No survey scores found',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '16px',
            fontFamily: 'Arial'
          }
        },
        chart: {
          title: widget.title,
          id: widget.id,
          type: 'bar',
          stacked: false,
          toolbar: {
            show: showGrid,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            }
          }
        },
        colors: [function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }, function ({ value, seriesIndex, w }) {
          var green = '#00E396'
          var amber = '#FEB019'
          var red = '#ff0000'

          if (value < 4) {
            return red
          } else {
            if (value > 4.5) {
              return green
            } else {
              return amber
            }
          }
        }],
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: false,
            isFunnel: false
          }
        },
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: showGrid
          }
        },
        title: {
          text: this.chartCustomTitle(widget),
          align: 'middle'
        },
        xaxis: {
          type: 'string',
          labels: {
            show: showGrid
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        grid: {
          position: 'back',
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: showGrid
            }
          }
        },
        tooltip: {
          enabled: true
        },
        legend: {
          show: false
        }
      }
      return obj
    },
    getChartSeries (widget) {
      if (widget.data) {
        var dataSeries = this.getAllMonths
        var displaySeries = []

        for (let i = 0; i < widget.data.length; i++) {
          for (let j = 0; j < dataSeries.length; j++) {
            if (dataSeries[j].year === widget.data[i].year && dataSeries[j].monthNumber === widget.data[i].monthNumber) {
              dataSeries[j].averageScore += widget.data[i].average
              dataSeries[j].numberOfScores += 1
            }
          }
        }

        for (let j = 0; j < dataSeries.length; j++) {
          var overallAverageScore = dataSeries[j].averageScore / dataSeries[j].numberOfScores
          displaySeries.push(overallAverageScore.toFixed(1))
        }

        return [{ name: 'Averages', 'data': displaySeries }]
      } else {
        return []
      }
    },
    // Reload the widget data on reaching the polling interval
    setWidgetData: async function (widgetId) {
      if (widgetId === this.widget.id) {
        // var rtn = await this.getWidgetById(this.id)
        // if (rtn.chartWidgets) {
        //   this.widgetData = rtn.chartWidgets[0]
        // }
      }
    },
    chartCustomTitle: function (widget) {
      if (widget.data) {
        var noMonthsWithScores = 0
        var averageScore = 0
        var yearlyAverage = 0

        for (let i = 0; i < widget.data.length; i++) {
          noMonthsWithScores += 1 // Because we won't necessarily have a score for every month
          averageScore += widget.data[i].average
        }
        yearlyAverage = averageScore / noMonthsWithScores
        return widget.title + '. Yearly Survey Score Average: ' + yearlyAverage.toFixed(1)
      } else {
        return widget.title
      }
    },
    timestamp (utc) {
      var localDate = new Date(Date.now()).toLocaleString()
      if (utc) {
        localDate = new Date(utc).toLocaleString()
      }
      return localDate
    }
  }
}
</script>
<style scoped>

.widget-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #000000;
  border: #dddddd 1px solid;
  padding: 10px 0 0 0;
}
.widget-header-title {
  cursor: default;
  padding: 0.4em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-content-wrapper {
  overflow: visible;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
}
.widget-content {
  height: 100%;
  font-size: 2em;
}
.widget-footer {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  flex-shrink: 0;
}
.widget-footer-timestamp {
  font-size: 0.8em;
  color: #aaa;
  text-align: right;
  margin-left: auto;
}
.timestamp {
  transition: all 0.1s ease;
  color: #aaa;
  font-size: 0.8em;
  text-align: right;
  background-color: white;
  cursor: pointer;
}
.apexcharts-title-text {
  margin-bottom: 10px;
}
#controlRefreshBar {
  display: inline-block;
  width: 100%;
  border-radius: 0;
  height: 4px;
}
#controlRefreshBar[value]::-webkit-progress-bar {
  background-color: #fff;
}
#controlRefreshBar[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, rgb(218, 240, 255), rgb(218, 240, 255));
  border-radius: 0;
  background-size: 100%;
}
</style>
