<template>
  <div>
    <h2
      v-if="!externalQuotesModelMixin.isLoading && (
        externalQuotesModelMixin_State.quoteBoms.length > 0 ||
        externalQuotesModelMixin_State.quoteIconConnect.id != undefined ||
        externalQuotesModelMixin_State.quoteIconNow.id != undefined ||
        externalQuotesModelMixin_State.quoteManagedService.id != undefined ||
        externalQuotesModelMixin_State.quoteNewManagedService.id != undefined ||
        externalQuotesModelMixin_State.quoteCallsAndLines.id != undefined ||
        externalQuotesModelMixin_State.quoteHorizon.id != undefined ||
        externalQuotesModelMixin_State.quoteIconSecure.id != undefined ||
        externalQuotesModelMixin_State.quoteSecureHomeworker.id != undefined ||
        externalQuotesModelMixin_State.quoteTwilio.id != undefined ||
        !isReadOnly /* Because this component is also used in the editor, we always show this heading when editing */
      )
      ">External Quotes</h2>
    <div
      v-if="externalQuotesModelMixin_State.quoteBoms.length > 0"
    >
      <h3>BOMs</h3>
      <table
        id="BomListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th />
        </tr>
        <tr
          v-for="bom in externalQuotesModelMixin_State.quoteBoms"
          :key="bom.id"
        >
          <td>{{ bom.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getBomItemTotalUpfrontCost(bom)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getBomItemTotalRecurringCost(bom)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getBomItemTotalYear1Cost(bom)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getBomItemTotalContractCost(bom)) }}</td>
          <td>
            <input
              v-if="bom.files.length > 0"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(bom.files[0]).fileVersionId)"
            >
            <input
              v-if="bom.files.length === 0 && bom.fileUploadVersionId"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(bom.fileUploadVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEdit' + bom.id.toString()"
              value="Edit"
              type="button"
              @click="editBom(bom)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDelete' + bom.id.toString()"
              value="x"
              type="button"
              @click="deleteBom(bom)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <div v-if="externalQuotesModelMixin_State.quoteIconConnect.id != undefined">
      <h3>Icon Connect</h3>
      <table
        id="IconConnectListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th />
        </tr>
        <tr>
          <td>{{ externalQuotesModelMixin_State.quoteIconConnect.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconConnectCostSummaryTotalUpfrontCost(externalQuotesModelMixin_State.quoteIconConnect)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconConnectCostSummaryTotalRecurringCost(externalQuotesModelMixin_State.quoteIconConnect)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconConnectCostSummaryTotalYear1Cost(externalQuotesModelMixin_State.quoteIconConnect)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconConnectCostSummaryTotalContractCost(externalQuotesModelMixin_State.quoteIconConnect)) }}</td>
          <td>
            <input
              v-if="externalQuotesModelMixin_State.quoteIconConnect.files.length > 0"
              id="btnDownloadFileLatestIconConnectSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(externalQuotesModelMixin_State.quoteIconConnect.files[0]).fileVersionId)"
            >
            <input
              v-if="externalQuotesModelMixin_State.quoteIconConnect.files.length === 0 && externalQuotesModelMixin_State.quoteIconConnect.fileUploadVersionId"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.quoteIconConnect.fileUploadVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEditIconConnect' + externalQuotesModelMixin_State.quoteIconConnect.id.toString()"
              value="Edit"
              type="button"
              @click="editIconConnect(externalQuotesModelMixin_State.quoteIconConnect)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDeleteIconConnect' + externalQuotesModelMixin_State.quoteIconConnect.id.toString()"
              value="x"
              type="button"
              @click="deleteIconConnect(externalQuotesModelMixin_State.quoteIconConnect)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <div v-if="externalQuotesModelMixin_State.quoteIconNow.id != undefined">
      <h3>Icon Now</h3>
      <table
        id="IconNowListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th />
        </tr>
        <tr>
          <td>{{ externalQuotesModelMixin_State.quoteIconNow.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconNowCostSummaryTotalUpfrontCost(externalQuotesModelMixin_State.quoteIconNow)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconNowCostSummaryTotalRecurringCost(externalQuotesModelMixin_State.quoteIconNow)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconNowCostSummaryTotalYear1Cost(externalQuotesModelMixin_State.quoteIconNow)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconNowCostSummaryTotalContractCost(externalQuotesModelMixin_State.quoteIconNow)) }}</td>
          <td>
            <input
              v-if="externalQuotesModelMixin_State.quoteIconNow.files.length > 0"
              id="btnDownloadFileLatestIconNowSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(externalQuotesModelMixin_State.quoteIconNow.files[0]).fileVersionId)"
            >
            <input
              v-if="externalQuotesModelMixin_State.quoteIconNow.files.length === 0 && externalQuotesModelMixin_State.quoteIconNow.fileUploadVersionId"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.quoteIconNow.fileUploadVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEditIconNow' + externalQuotesModelMixin_State.quoteIconNow.id.toString()"
              value="Edit"
              type="button"
              @click="editIconNow(externalQuotesModelMixin_State.quoteIconNow)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDeleteIconNow' + externalQuotesModelMixin_State.quoteIconNow.id.toString()"
              value="x"
              type="button"
              @click="deleteIconNow(externalQuotesModelMixin_State.quoteIconNow)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <div v-if="externalQuotesModelMixin_State.quoteManagedService.id != undefined">
      <h3>Managed Service</h3>
      <table
        id="ManagedServiceListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th />
        </tr>
        <tr>
          <td>{{ externalQuotesModelMixin_State.quoteManagedService.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getManagedServiceCostSummaryTotalUpfrontCost(externalQuotesModelMixin_State.quoteManagedService)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getManagedServiceCostSummaryTotalRecurringCost(externalQuotesModelMixin_State.quoteManagedService)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getManagedServiceCostSummaryTotalYear1Cost(externalQuotesModelMixin_State.quoteManagedService)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getManagedServiceCostSummaryTotalContractCost(externalQuotesModelMixin_State.quoteManagedService)) }}</td>
          <td>
            <input
              v-if="externalQuotesModelMixin_State.quoteManagedService.files.length > 0"
              id="btnDownloadFileLatestQuoteManagedServiceSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(externalQuotesModelMixin_State.quoteManagedService.files[0]).fileVersionId)"
            >
            <input
              v-if="externalQuotesModelMixin_State.quoteManagedService.files.length === 0 && externalQuotesModelMixin_State.quoteManagedService.fileUploadVersionId"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.quoteManagedService.fileUploadVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEditManagedService' + externalQuotesModelMixin_State.quoteManagedService.id.toString()"
              value="Edit"
              type="button"
              @click="editManagedService(externalQuotesModelMixin_State.quoteManagedService)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDeleteManagedService' + externalQuotesModelMixin_State.quoteManagedService.id.toString()"
              value="x"
              type="button"
              @click="deleteManagedService(externalQuotesModelMixin_State.quoteManagedService)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <div v-if="externalQuotesModelMixin_State.quoteNewManagedService.id != undefined">
      <h3>New Managed Service</h3>
      <table
        id="NewManagedServiceListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th />
        </tr>
        <tr>
          <td>{{ externalQuotesModelMixin_State.quoteNewManagedService.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalUpfrontCost(externalQuotesModelMixin_State.quoteNewManagedService)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalRecurringCost(externalQuotesModelMixin_State.quoteNewManagedService)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalYear1Cost(externalQuotesModelMixin_State.quoteNewManagedService)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalContractCost(externalQuotesModelMixin_State.quoteNewManagedService)) }}</td>
          <td>
            <input
              v-if="externalQuotesModelMixin_State.quoteNewManagedService.files.length > 0"
              id="btnDownloadFileLatestQuoteNewManagedServiceSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(externalQuotesModelMixin_State.quoteNewManagedService.files[0]).fileVersionId)"
            >
            <input
              v-if="externalQuotesModelMixin_State.quoteNewManagedService.files.length === 0 && externalQuotesModelMixin_State.quoteNewManagedService.fileUploadVersionId"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.quoteNewManagedService.fileUploadVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEditNewManagedService' + externalQuotesModelMixin_State.quoteNewManagedService.id.toString()"
              value="Edit"
              type="button"
              @click="editNewManagedService(externalQuotesModelMixin_State.quoteNewManagedService)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDeleteNewManagedService' + externalQuotesModelMixin_State.quoteNewManagedService.id.toString()"
              value="x"
              type="button"
              @click="deleteNewManagedService(externalQuotesModelMixin_State.quoteNewManagedService)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <div v-if="externalQuotesModelMixin_State.quoteCallsAndLines.id != undefined">
      <h3>Calls and Lines</h3>
      <table
        id="CallsAndLinesListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th />
        </tr>
        <tr>
          <td>{{ externalQuotesModelMixin_State.quoteCallsAndLines.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalUpfrontCost(externalQuotesModelMixin_State.quoteCallsAndLines)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalRecurringCost(externalQuotesModelMixin_State.quoteCallsAndLines)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalYear1Cost(externalQuotesModelMixin_State.quoteCallsAndLines)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalContractCost(externalQuotesModelMixin_State.quoteCallsAndLines)) }}</td>
          <td>
            <input
              v-if="externalQuotesModelMixin_State.quoteCallsAndLines.files.length > 0"
              id="btnDownloadFileLatestCallsAndLinesSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(externalQuotesModelMixin_State.quoteCallsAndLines.files[0]).fileVersionId)"
            >
            <input
              v-if="externalQuotesModelMixin_State.quoteCallsAndLines.files.length === 0 && externalQuotesModelMixin_State.quoteCallsAndLines.fileUploadVersionId"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.quoteCallsAndLines.fileUploadVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEditCallsAndLines' + externalQuotesModelMixin_State.quoteCallsAndLines.id.toString()"
              value="Edit"
              type="button"
              @click="editCallsAndLines(externalQuotesModelMixin_State.quoteCallsAndLines)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDeleteCallsAndLines' + externalQuotesModelMixin_State.quoteCallsAndLines.id.toString()"
              value="x"
              type="button"
              @click="deleteCallsAndLines(externalQuotesModelMixin_State.quoteCallsAndLines)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <div v-if="externalQuotesModelMixin_State.quoteHorizon.id != undefined">
      <h3>Horizon</h3>
      <table
        id="HorizonListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th />
        </tr>
        <tr>
          <td>{{ externalQuotesModelMixin_State.quoteHorizon.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontCost(externalQuotesModelMixin_State.quoteHorizon)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringCost(externalQuotesModelMixin_State.quoteHorizon)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getHorizonCostSummaryTotalYear1Cost(externalQuotesModelMixin_State.quoteHorizon)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getHorizonCostSummaryTotalContractCost(externalQuotesModelMixin_State.quoteHorizon)) }}</td>
          <td>
            <input
              v-if="externalQuotesModelMixin_State.quoteHorizon.files.length > 0"
              id="btnDownloadFileLatestHorizonSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(externalQuotesModelMixin_State.quoteHorizon.files[0]).fileVersionId)"
            >
            <input
              v-if="externalQuotesModelMixin_State.quoteHorizon.files.length === 0 && externalQuotesModelMixin_State.quoteHorizon.fileUploadVersionId"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.quoteHorizon.fileUploadVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEditHorizon' + externalQuotesModelMixin_State.quoteHorizon.id.toString()"
              value="Edit"
              type="button"
              @click="editHorizon(externalQuotesModelMixin_State.quoteHorizon)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDeleteHorizon' + externalQuotesModelMixin_State.quoteHorizon.id.toString()"
              value="x"
              type="button"
              @click="deleteHorizon(externalQuotesModelMixin_State.quoteHorizon)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <div v-if="externalQuotesModelMixin_State.quoteIconSecure.id != undefined">
      <h3>Icon Secure</h3>
      <table
        id="IconSecureListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th />
        </tr>
        <tr>
          <td>{{ externalQuotesModelMixin_State.quoteIconSecure.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconSecureCostSummaryTotalUpfrontCost(externalQuotesModelMixin_State.quoteIconSecure)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconSecureCostSummaryTotalRecurringCost(externalQuotesModelMixin_State.quoteIconSecure)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconSecureCostSummaryTotalYear1Cost(externalQuotesModelMixin_State.quoteIconSecure)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getIconSecureCostSummaryTotalContractCost(externalQuotesModelMixin_State.quoteIconSecure)) }}</td>
          <td>
            <input
              v-if="externalQuotesModelMixin_State.quoteIconSecure.files.length > 0"
              id="btnDownloadFileLatestIconSecureSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(externalQuotesModelMixin_State.quoteIconSecure.files[0]).fileVersionId)"
            >
            <input
              v-if="externalQuotesModelMixin_State.quoteIconSecure.files.length === 0 && externalQuotesModelMixin_State.quoteIconSecure.fileUploadVersionId"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.quoteIconSecure.fileUploadVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEditIconSecure' + externalQuotesModelMixin_State.quoteIconSecure.id.toString()"
              value="Edit"
              type="button"
              @click="editIconSecure(externalQuotesModelMixin_State.quoteIconSecure)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDeleteIconSecure' + externalQuotesModelMixin_State.quoteIconSecure.id.toString()"
              value="x"
              type="button"
              @click="deleteIconSecure(externalQuotesModelMixin_State.quoteIconSecure)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <div v-if="externalQuotesModelMixin_State.quoteSecureHomeworker.id != undefined">
      <h3>Secure Homeworker</h3>
      <table
        id="SecureHomeworkerListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th v-if="!isReadOnly" />
        </tr>
        <tr>
          <td>{{ externalQuotesModelMixin_State.quoteSecureHomeworker.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalUpfrontCost(externalQuotesModelMixin_State.quoteSecureHomeworker)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalRecurringCost(externalQuotesModelMixin_State.quoteSecureHomeworker)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalYear1Cost(externalQuotesModelMixin_State.quoteSecureHomeworker)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalContractCost(externalQuotesModelMixin_State.quoteSecureHomeworker)) }}</td>
          <td>
            <input
              v-if="externalQuotesModelMixin_State.quoteIconSecure.files.length > 0"
              id="btnDownloadFileLatestSecureHomeworkerSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(externalQuotesModelMixin_State.quoteSecureHomeworker.files[0]).fileVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEditSecureHomeworker' + externalQuotesModelMixin_State.quoteSecureHomeworker.id.toString()"
              value="Edit"
              type="button"
              @click="editSecureHomeworker(externalQuotesModelMixin_State.quoteSecureHomeworker)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDeleteSecureHomeworker' + externalQuotesModelMixin_State.quoteSecureHomeworker.id.toString()"
              value="x"
              type="button"
              @click="deleteSecureHomeworker(externalQuotesModelMixin_State.quoteSecureHomeworker)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <div v-if="externalQuotesModelMixin_State.quoteTwilio.id != undefined">
      <h3>Twilio</h3>
      <table
        id="TwilioListTable"
        class="iQuoteTable">
        <tr>
          <th style="width:180px">Description</th>
          <th style="width:80px">Upfront Cost</th>
          <th style="width:80px">Annual Cost</th>
          <th style="width:80px">Yr1 Cost</th>
          <th style="width:80px">Contract Cost</th>
          <th />
        </tr>
        <tr>
          <td>{{ externalQuotesModelMixin_State.quoteTwilio.description }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getTwilioCostSummaryTotalUpfrontCost(externalQuotesModelMixin_State.quoteTwilio)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getTwilioCostSummaryTotalRecurringCost(externalQuotesModelMixin_State.quoteTwilio)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getTwilioCostSummaryTotalYear1Cost(externalQuotesModelMixin_State.quoteTwilio)) }}</td>
          <td>{{ getCostDisplay(externalQuotesModelMixin_getTwilioCostSummaryTotalContractCost(externalQuotesModelMixin_State.quoteTwilio)) }}</td>
          <td>
            <input
              v-if="externalQuotesModelMixin_State.quoteTwilio.files.length > 0"
              id="btnDownloadFileLatestQuoteTwilioSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_getLatestFileVersion(externalQuotesModelMixin_State.quoteTwilio.files[0]).fileVersionId)"
            >
            <input
              v-if="externalQuotesModelMixin_State.quoteTwilio.files.length === 0 && externalQuotesModelMixin_State.quoteTwilio.fileUploadVersionId"
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.quoteTwilio.fileUploadVersionId)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnEditTwilio' + externalQuotesModelMixin_State.quoteTwilio.id.toString()"
              value="Edit"
              type="button"
              @click="editTwilio(externalQuotesModelMixin_State.quoteTwilio)"
            >
            <input
              v-if="!isReadOnly"
              :id="'btnDeleteTwilio' + externalQuotesModelMixin_State.quoteTwilio.id.toString()"
              value="x"
              type="button"
              @click="deleteTwilio(externalQuotesModelMixin_State.quoteTwilio)"
            >
          </td>
        </tr>
      </table>
      <br>
    </div>
    <label
      v-if="!isReadOnly"
      style="width: 200px">Type of Quote to Add:</label>
    <select
      v-if="!isReadOnly"
      id="cboExternalQuoteSources"
      v-model="externalQuotesComponent.selectedExternalQuoteSourceToAdd"
    >
      <option
        v-if="externalQuotesComponent.selectedExternalQuoteSourceToAdd === 0"
        key="0"
        value="0"
        title="< Please Select >"
      >&lt; Please Select &gt;
      </option>
      <option
        v-for="s in externalQuotesComponent_externalQuoteSources"
        :key="s.id"
        :value="s.id"
        :title="s.description"
      >{{ s.description }}
      </option>
    </select>
    <input
      v-if="externalQuotesComponent.selectedExternalQuoteSourceToAdd !== 0"
      :id="'btnAddExternalQuote'"
      value="Add"
      type="button"
      @click="addExternalQuote()"
    >
    <br>
    <br>
    <div
      v-if="!isReadOnly"
      class="buttons">
      <div class="leftButtons">
        <input
          id="btnOk"
          value="OK"
          type="button"
          @click="okButtonPressed"
        >
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../../common/tooltipIcon'
import { iQuoteQuoteAPIMixin } from '../../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStaticDataAPIMixin } from '../../../../mixins/iQuote/iQuoteStaticDataAPIMixin'
import { externalQuotesModelMixin } from '../../../../mixins/iQuote/Models/externalQuotesModelMixin'

import { mapActions } from 'vuex'
export default {
  name: 'RootPage',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteStaticDataAPIMixin, externalQuotesModelMixin],
  props: {
    isReadOnly: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      externalQuotesComponent: {
        salesforceCoeCodes: [],
        selectedExternalQuoteSourceToAdd: 0
      }
    }
  },
  computed: {
    externalQuotesComponent_externalQuoteSources () {
      var returnValues = [
        { id: this.externalQuotesComponent_externalQuoteSourceConstants.bom, description: 'BOM' }
      ]

      if (this.externalQuotesModelMixin_State.quoteIconConnect.id === undefined) {
        returnValues.push({ id: this.externalQuotesComponent_externalQuoteSourceConstants.iconConnect, description: 'Icon Connect' })
      }

      if (this.externalQuotesModelMixin_State.quoteCallsAndLines.id === undefined) {
        returnValues.push({ id: this.externalQuotesComponent_externalQuoteSourceConstants.callsAndLines, description: 'Calls & Lines' })
      }

      if (this.externalQuotesModelMixin_State.quoteHorizon.id === undefined) {
        returnValues.push({ id: this.externalQuotesComponent_externalQuoteSourceConstants.horizon, description: 'Horizon' })
      }

      if (this.externalQuotesModelMixin_State.quoteManagedService.id === undefined) {
        returnValues.push({ id: this.externalQuotesComponent_externalQuoteSourceConstants.servicesPricing, description: 'Services Pricing' })
      }

      if (this.externalQuotesModelMixin_State.quoteNewManagedService.id === undefined) {
        returnValues.push({ id: this.externalQuotesComponent_externalQuoteSourceConstants.newServicesPricing, description: 'New Services Pricing' })
      }

      if (this.externalQuotesModelMixin_State.quoteIconSecure.id === undefined) {
        returnValues.push({ id: this.externalQuotesComponent_externalQuoteSourceConstants.iconSecure, description: 'Icon Secure' })
      }

      // if (this.externalQuotesModelMixin_State.quoteSecureHomeworker.id === undefined) {
      //   returnValues.push({ id: this.externalQuotesComponent_externalQuoteSourceConstants.secureHomeworker, description: 'Secure Homeworker' })
      // }

      if (this.externalQuotesModelMixin_State.quoteIconNow.id === undefined) {
        returnValues.push({ id: this.externalQuotesComponent_externalQuoteSourceConstants.iconNow, description: 'Icon Now' })
      }

      if (this.externalQuotesModelMixin_State.quoteTwilio.id === undefined) {
        // returnValues.push({ id: this.externalQuotesComponent_externalQuoteSourceConstants.twilio, description: 'Twilio' })
      }

      return returnValues
    },
    externalQuotesComponent_externalQuoteSourceConstants () {
      return Object.freeze({
        bom: 1,
        iconConnect: 2,
        iconSecure: 3,
        iconNow: 4,
        secureHomeworker: 5,
        horizon: 6,
        ringCentral: 7,
        servicesPricing: 8,
        callsAndLines: 9,
        psInfra: 10,
        psApps: 11,
        newServicesPricing: 12,
        twilio: 13
      })
    }
  },
  async mounted () {
    var salesforceCoeCodes = await this.iQuoteStaticDataAPIMixin_getSalesforceCoeCodes()

    this.externalQuotesComponent.salesforceCoeCodes = salesforceCoeCodes.filter(x => x.selectableForBoms === true)
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),
    getCostDisplay (value) {
      if (value === 0 || value === null) {
        return '-'
      } else {
        return '£' + this.iQuoteFunctions_ThousandSeparator(value.toFixed(2))
      }
    },
    addExternalQuote () {
      var selectedType = this.externalQuotesComponent.selectedExternalQuoteSourceToAdd

      switch (selectedType) {
        case this.externalQuotesComponent_externalQuoteSourceConstants.bom:
          this.addBom()
          break
        case this.externalQuotesComponent_externalQuoteSourceConstants.iconNow:
          this.addIconNow()
          break
        case this.externalQuotesComponent_externalQuoteSourceConstants.iconConnect:
          this.addIconConnect()
          break
        case this.externalQuotesComponent_externalQuoteSourceConstants.iconSecure:
          this.addIconSecure()
          break
        case this.externalQuotesComponent_externalQuoteSourceConstants.servicesPricing:
          this.addManagedService()
          break
        case this.externalQuotesComponent_externalQuoteSourceConstants.newServicesPricing:
          this.addNewManagedService()
          break
        case this.externalQuotesComponent_externalQuoteSourceConstants.callsAndLines:
          this.addCallsAndLines()
          break
        case this.externalQuotesComponent_externalQuoteSourceConstants.horizon:
          this.addHorizon()
          break
        case this.externalQuotesComponent_externalQuoteSourceConstants.secureHomeworker:
          this.addSecureHomeworker()
          break
        case this.externalQuotesComponent_externalQuoteSourceConstants.twilio:
          this.addTwilio()
          break
        default:
          this.iQuoteFunctions_ShowError('Not implemented yet!')
          break
      }
    },
    addBom () {
      var selectedQuoteBom = {
        id: 0,
        description: '',
        entries: [],
        files: [],
        designPackItemLookupId: 0
      }
      this.externalQuotesModelMixin_setSelectedQuoteBom(selectedQuoteBom)
      this.externalQuotesModelMixin_setCurrentPage('BomPage')
    },
    editBom (bom) {
      // make a copy of the bom object, so that we can have a working copy with invalid values but have the ability to only save valid rows
      var selectedQuoteBom = {
        id: bom.id,
        description: bom.description,
        fileUploadVersionId: bom.fileUploadVersionId,
        entries: [],
        files: []
      }

      bom.entries.forEach(entry => {
        selectedQuoteBom.entries.push(
          {
            id: entry.id,
            description: entry.description,
            salesforceCoeCodeId: entry.salesforceCoeCodeId,
            upfrontExternalCost: entry.upfrontExternalCost,
            upfrontInternalCost: entry.upfrontInternalCost,
            recurringExternalCost: entry.recurringExternalCost,
            recurringInternalCost: entry.recurringInternalCost
          }
        )
      })

      bom.files.forEach(file => {
        selectedQuoteBom.files.push(
          {
            id: file.id,
            fileUploadId: file.fileUploadId,
            title: file.title,
            fileName: file.fileName,
            versionList: file.versionList,
            isBomSpreadsheet: file.isBomSpreadsheet
          }
        )
      })

      this.externalQuotesModelMixin_setSelectedQuoteBom(selectedQuoteBom)
      this.externalQuotesModelMixin_setCurrentPage('BomPage')
    },
    deleteBom (bom) {
      this.externalQuotesModelMixin_handleBomDeleted(bom)
    },
    editIconConnect (IconConnect) {
      this.externalQuotesModelMixin_setCurrentPage('IconConnectPage')
    },
    deleteIconConnect (IconConnect) {
      this.externalQuotesModelMixin_handleIconConnectDeleted(IconConnect)
    },
    addIconConnect () {
      var quoteIconConnect = {
        id: 0,
        description: '',
        costSummaryEntries: [],
        psEntries: [],
        autotaskEntries: [],
        files: [],
        designPackItemLookupId: 0
      }
      this.externalQuotesModelMixin_setSelectedIconConnect(quoteIconConnect)
      this.externalQuotesModelMixin_setCurrentPage('IconConnectPage')
    },
    editIconNow (IconNow) {
      this.externalQuotesModelMixin_setCurrentPage('IconNowPage')
    },
    deleteIconNow (IconNow) {
      this.externalQuotesModelMixin_handleIconNowDeleted(IconNow)
    },
    addIconNow () {
      var quoteIconNow = {
        id: 0,
        description: '',
        costSummaryEntries: [],
        priceSummaryEntries: [],
        psEntries: [],
        autotaskEntries: [],
        files: []
      }
      this.externalQuotesModelMixin_setSelectedIconNow(quoteIconNow)
      this.externalQuotesModelMixin_setCurrentPage('IconNowPage')
    },
    editSecureHomeworker (SecureHomeworker) {
      this.externalQuotesModelMixin_setCurrentPage('SecureHomeworkerPage')
    },
    deleteSecureHomeworker (SecureHomeworker) {
      this.externalQuotesModelMixin_handleSecureHomeworkerDeleted(SecureHomeworker)
    },
    addSecureHomeworker () {
      var quoteSecureHomeworker = {
        id: 0,
        description: '',
        costSummaryEntries: [],
        psEntries: [],
        autotaskEntries: [],
        files: []
      }
      this.externalQuotesModelMixin_setSelectedSecureHomeworker(quoteSecureHomeworker)
      this.externalQuotesModelMixin_setCurrentPage('SecureHomeworkerPage')
    },
    editManagedService (ManagedService) {
      this.externalQuotesModelMixin_setCurrentPage('ManagedServicePage')
    },
    deleteManagedService (ManagedService) {
      this.externalQuotesModelMixin_handleManagedServiceDeleted(ManagedService)
    },
    addManagedService () {
      var quoteManagedService = {
        id: 0,
        description: '',
        costSummaryEntries: [],
        psEntries: [],
        autotaskEntries: [],
        files: []
      }
      this.externalQuotesModelMixin_setSelectedManagedService(quoteManagedService)
      this.externalQuotesModelMixin_setCurrentPage('ManagedServicePage')
    },
    editNewManagedService () {
      this.externalQuotesModelMixin_setCurrentPage('NewManagedServicePage')
    },
    deleteNewManagedService (NewManagedService) {
      this.externalQuotesModelMixin_handleNewManagedServiceDeleted(NewManagedService)
    },
    addNewManagedService () {
      var quoteNewManagedService = {
        id: 0,
        description: '',
        costSummaryEntries: [],
        autotaskEntries: [],
        files: []
      }
      this.externalQuotesModelMixin_setSelectedNewManagedService(quoteNewManagedService)
      this.externalQuotesModelMixin_setCurrentPage('NewManagedServicePage')
    },
    editCallsAndLines (CallsAndLines) {
      this.externalQuotesModelMixin_setCurrentPage('CallsAndLinesPage')
    },
    deleteCallsAndLines (CallsAndLines) {
      this.externalQuotesModelMixin_handleCallsAndLinesDeleted(CallsAndLines)
    },
    addCallsAndLines () {
      var quoteCallsAndLines = {
        id: 0,
        description: '',
        costSummaryEntries: [],
        psEntries: [],
        autotaskEntries: [],
        files: []
      }
      this.externalQuotesModelMixin_setSelectedCallsAndLines(quoteCallsAndLines)
      this.externalQuotesModelMixin_setCurrentPage('CallsAndLinesPage')
    },
    editHorizon (Horizon) {
      this.externalQuotesModelMixin_setCurrentPage('HorizonPage')
    },
    deleteHorizon (Horizon) {
      this.externalQuotesModelMixin_handleHorizonDeleted(Horizon)
    },
    addHorizon () {
      var quoteHorizon = {
        id: 0,
        description: '',
        costSummaryEntries: [],
        psEntries: [],
        autotaskEntries: [],
        files: []
      }
      this.externalQuotesModelMixin_setSelectedHorizon(quoteHorizon)
      this.externalQuotesModelMixin_setCurrentPage('HorizonPage')
    },
    editIconSecure (IconSecure) {
      this.externalQuotesModelMixin_setCurrentPage('IconSecurePage')
    },
    deleteIconSecure (IconSecure) {
      this.externalQuotesModelMixin_handleIconSecureDeleted(IconSecure)
    },
    addIconSecure () {
      var quoteIconSecure = {
        id: 0,
        description: '',
        costSummaryEntries: [],
        psEntries: [],
        autotaskEntries: [],
        files: []
      }
      this.externalQuotesModelMixin_setSelectedIconSecure(quoteIconSecure)
      this.externalQuotesModelMixin_setCurrentPage('IconSecurePage')
    },
    editTwilio () {
      this.externalQuotesModelMixin_setCurrentPage('TwilioPage')
    },
    deleteTwilio (Twilio) {
      this.externalQuotesModelMixin_handleTwilioDeleted(Twilio)
    },
    addTwilio () {
      var quoteTwilio = {
        id: 0,
        description: '',
        costSummaryEntries: [],
        priceSummaryEntries: [],
        autotaskEntries: [],
        files: []
      }
      this.externalQuotesModelMixin_setSelectedTwilio(quoteTwilio)
      this.externalQuotesModelMixin_setCurrentPage('TwilioPage')
    },
    async okButtonPressed () {
      this.setCurrentQuoteState('Review')
    },
    downloadFile: async function (fileId) {
      var data = await this.UploadsAPIMixin_DownloadFile('iquote', fileId)
      var fileDetails = await this.UploadsAPIMixin_GetFileDetailsByVersionId('iQuote', fileId)
      var contentType = 'application/octet-stream'
      var fileExtension = fileDetails.name.split('.').pop().toLowerCase()

      switch (fileExtension) {
        case 'xls':
          contentType = 'application/vnd.ms-excel'
          break
        case 'xlsx':
          contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
        case 'doc':
          contentType = 'application/vnd.ms-word'
          break
        case 'docx':
          contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          break
        case 'pdf':
          contentType = 'application/pdf'
          break
        case 'jpg':
          contentType = 'image/jpeg'
          break
        case 'png':
          contentType = 'image/png'
          break
      }

      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: contentType }))
      a.download = fileDetails.name
      document.body.appendChild(a)
      a.click()
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 1520px}
  .small {width:60px;}
  .hidden {visibility: hidden;}

  .quotePanel label {
    width:80px;
    font-weight: bold;
  }

  #txtBomDescription {
    width:500px
  }

  table {
    font-size: 1em;
  }

  .quotePanel input[type=number].currencyInput{
    width: 60px;
  }

  #BomListTable,
  #IconConnectListTable,
  #IconNowListTable,
  #IconSecureListTable,
  #CallsAndLinesListTable,
  #ManagedServiceListTable,
  #NewManagedServiceListTable,
  #SecureHomeworkerListTable,
  #HorizonListTable,
  #TwilioListTable {
    width: auto;
  }

  #BomListTable input[type=button],
  #CallsAndLinesListTable input[type=button],
  #IconSecureListTable input[type=button],
  #IconConnectListTable input[type=button],
  #IconNowListTable input[type=button],
  #ManagedServiceListTable input[type=button],
  #NewManagedServiceListTable input[type=button],
  #SecureHomeworkerListTable input[type=button],
  #HorizonListTable input[type=button],
  #TwilioListTable input[type=button]
  {
    margin: 0;
    padding: 5px;
  }

  #EditBomFilesTable {
    margin-top: 17px;
    width: 400px;
  }

  #EditBomTable {
    width: 700px;
  }

    #EditBomTable input[type=text] {
    border: none;
    margin: 0;
    padding: 0;
  }

  #bomFilesSection {
    width: 400px;
    display: inline-block;
    vertical-align: top;
    padding-left: 30px;
  }

  #BomEntries {
    display: inline-block;
  }

  #bomEntriesLeftPanel {
    display: inline-block;
  }

  #EditBomTable td input[type=number] {
    border: none;
    margin: 0;
    padding: 0;
  }

  #EditBomTable input[type=button] {
    margin: 0;
    padding: 5px;
  }

  #EditBomTable td {
    margin: 0;
    padding: 5px;
  }

  #cboSalesforceCoeCodes {
    width: 150px;
    border: none;
    margin: 0;
    padding: 0;
    height: 18px;
  }
</style>
