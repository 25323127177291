import portalAPIGateway from '../../communications/interceptors/apiInterceptor'
export const iQuoteStaticDataAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteStaticDataAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
    },
    iQuoteStaticDataAPIMixin_getTermLengths: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/termlengths', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getTermLengths error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getCXNowPlatformSizes: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/cxnowplatformsizes', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getCXNowPlatformSizes error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getResiliences: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/resiliences', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getResiliences error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getApplicationLicenseTypes: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/licensetypes', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getApplicationLicenseTypes error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getServiceLevels: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/servicelevels', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getServiceLevels error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getQuoteTypes: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/quotetypes', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getQuoteTypes error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getServerTypes: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/servertypes', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getServerTypes error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getServices: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/services', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getServices  error:' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getServiceCategories: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/servicecategories', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getServiceCategories  error:' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getSIPCodecs: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/sipcodecs/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getSIPCodecs error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getExpansionVmCategories: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/expansionVmCategories/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getExpansionVmCategories error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getExpansionVmSubCategories: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/expansionVmSubCategories/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getExpansionVmSubCategories error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getVendors: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/vendors', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getVendors error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getWFOOptions: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/wfooptions', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getWFOOptions error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getOOHPortings: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/oohportingtypes', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getOOHPortings error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getSipAccess: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/sipaccess', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getSipAccess error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getSipAccessTypes: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/data/sipaccesstypes', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getSipAccessTypes error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getUserProfileLicenses: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/userprofilelicenses', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getUserProfileLicenses error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getUserProfileCategories: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/userprofilecategories', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getUserProfileCategories error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getVirtualMachines: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/VirtualMachines/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getVirtualMachines returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getSalesforceCoeCodes: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/SalesforceCoeCodes/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getSalesforceCoeCodes returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getVirtualMachineExpansionCategoryLinks: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/VirtualMachineExpansionCategoryLinks/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getVirtualMachineExpansionCategoryLinks returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getBladeServers: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/BladeServers/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getBladeServers returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getLicenses: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/Licenses/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getLicenses returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getUnitCosts: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/UnitCosts/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getUnitCosts returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getPackageVirtualMachines: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/packages/packageVirtualMachines/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getPackageVirtualMachines returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getDesignPackTableSections: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/designPackTableSections/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getDesignPackTableSections returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getTechnologyHardware: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/technologyhardware/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getTechnologyHardware returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getTechnologyUserProfiles: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/technologyuserprofiles/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getTechnologyUserProfiles returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getCxNowConnectors: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/callmediacxnowconnectors/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getCxNowConnectors returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getCxNowApps: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/callmediacxnowapps/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getCxNowApps returned error: ' + error);
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getAvayaAXPSocialMediaPackages: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/avayaaxpsocialmediapackages', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getAvayaAXPSocialMediaPackages error: ' + error)
      }
      return data
    },
    iQuoteStaticDataAPIMixin_getUCAnalyticsQuoteTypes: async function () {
      var data = []
      var axiosConfig = this.iQuoteStaticDataAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/ucAnalyticsQuoteTypes', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteStaticDataAPIMixin_getUCAnalyticsQuoteTypes error: ' + error)
      }
      return data
    }
  }
}
