<template>
  <div
    id="ServiceStatusTile"
  >
    <router-link
      v-ripple
      class="toolbar-items"
      to="site-health">
      <material-card>
        <vc-donut
          :size="200"
          :thickness="40"
          :sections="sections"
          :total="siteTotal"
          :start-angle="0"
          background="white"
          foreground="grey"
          unit="px"
          has-legend
          legend-placement="bottom">
          <h4 class="title font-weight-light">Circuit Status</h4>
        </vc-donut>
      </material-card>
    </router-link>
  </div>
</template>
<script>
import { dashboardMixin } from '../../../mixins/dashboard.js'

export default {
  name: 'ServiceStatusTile',
  components: {
  },
  mixins: [dashboardMixin],
  data () {
    return {
    }
  },
  computed: {
    alarmList () {
      return this.$store.getters.ratings || []
    },
    sitesList () {
      return this.$store.getters.sites
    },
    sections () {
      var rtn = []

      var highPrioritySites = this.getFilteredSiteList('high').length
      var mediumPrioritySites = this.getFilteredSiteList('medium').length
      var lowPrioritySites = this.getFilteredSiteList('low').length

      rtn.push({ label: `Sites with Unresolved Alarms (` + highPrioritySites + `)`, value: highPrioritySites, color: '#ef5350' })
      rtn.push({ label: `Sites with Alarms Resolved (Today) (` + mediumPrioritySites + `)`, value: mediumPrioritySites, color: '#ffa726' })
      rtn.push({ label: `Sites with Alarms Resolved (Month) (` + lowPrioritySites + `)`, value: lowPrioritySites, color: '#66bb6a' })

      if (!highPrioritySites && !mediumPrioritySites && !lowPrioritySites) {
        rtn = [{ label: ' No Alarm Data Available', value: 0, color: '#999' }]
      }

      return rtn
    },
    siteTotal () {
      return this.getFilteredSiteList('high').length + this.getFilteredSiteList('medium').length + this.getFilteredSiteList('low').length || 1
    }
  },
  mounted () {
  },
  created () {
  },
  methods: {
    getFilteredSiteList: function (filter = '') {
      var filteredAlarmList = this.getFilteredAlarmList(filter)
      return this.sitesList
        ? this.sitesList.filter(site =>
          filteredAlarmList.some(({ siteId }) => siteId === site.id))
        : []
    },
    getFilteredAlarmList: function (filter) {
      var highPriorityAlarms = this.alarmList.filter(alarm =>
        alarm.raised &&
        !this.alarmList.some(alarm2 => !alarm2.raised && alarm2.reference === alarm.reference && new Date(alarm2.dateRaised) >= new Date(alarm.dateRaised)))
      switch (filter ? filter.toLowerCase() : '') {
        case 'high':
          return highPriorityAlarms
        case 'medium':
          return this.alarmList.filter(alarm =>
            alarm.raised &&
            new Date(alarm.dateRaised) >= new Date(new Date().setHours(0, 0, 0, 0)) &&
            !highPriorityAlarms.some(({ siteId }) => siteId === alarm.siteId))
        case 'low':
          return this.alarmList.filter(alarm =>
            !alarm.raised && new Date(alarm.dateRaised) >= new Date().setMonth(new Date().getMonth() - 1))
        default:
          return this.alarmList
      }
    }
  }
}
</script>
