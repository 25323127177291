<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-ticketdashboard"
          primary-title
        >
          Ticket Dashboard
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details how the report embedded on the ticket dashboard page works."
              />
              <HelpSection
                instruction="1.) Upon logging into ICON Portal, from the Home screen select from the left hand menu Ticket Dashboard."
                image-source="ticket_dashboard_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="2.) Once selected you are presented with the Ticket Dashboard which summarises completed tickets from the last 13 months."
                indentation="0"
              />
              <HelpSection
                instruction="3.) The dashboard is interactive, you can see additional information by using your mouse cursor by hovering over key elements and clicking on bars or data points to filter the data on the whole dashboard."
                image-source="ticket_dashboard_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="4.) For example by clicking on a month on the SLA Graph X-axis, the whole dashboard will filter to show all visualisations with that month’s data."
                image-source="ticket_dashboard_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="5.) Any filter / selection can be reset using the reset button in the top left of the dashboard."
                image-source="ticket_dashboard_4.png"
                ndentation="0"
              />
              <HelpSection
                instruction="6.) The button on the top left if hovered over will provide help and supporting information for the dashboard. All visualisations, if hovered over, have help to clarify what data is shown."
                indentation="0"
                image-source="ticket_dashboard_5.png"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpDashboardImage',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
