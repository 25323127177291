<template>
  <div :style="containerStyle">
    <img
      :class="{ opaque: paginationData[index].isHovering.first && currentPageGreaterThanOne, notAllowed: paginationData[index].isHovering.first && !currentPageGreaterThanOne }"
      src="../../assets/icons/toFirst.png"
      @mouseover="paginationData[index].isHovering.first = true"
      @mouseout="paginationData[index].isHovering.first = false"
      @click="firstClicked()"
    >
    <img
      :class="{ opaque: paginationData[index].isHovering.prev && currentPageGreaterThanOne, notAllowed: paginationData[index].isHovering.prev && !currentPageGreaterThanOne }"
      src="../../assets/icons/toPrev.png"
      @mouseover="paginationData[index].isHovering.prev = true"
      @mouseout="paginationData[index].isHovering.prev = false"
      @click="prevClicked()"
    >
    <span>Page</span>
    <input
      v-model.number="paginationData[index].currentPage"
      @blur="pageChanged()"
    >
    <span>Of</span>
    <input
      v-model.number="paginationData[index].totalPages"
      :disabled="true"
    >
    <span class="totalEntries">(Total: {{ paginationData[index].totalEntries }})</span>
    <img
      :class="{ opaque: paginationData[index].isHovering.next && currentPageLessThanTotalPages, notAllowed: paginationData[index].isHovering.next && !currentPageLessThanTotalPages }"
      src="../../assets/icons/toNext.png"
      @mouseover="paginationData[index].isHovering.next = true"
      @mouseout="paginationData[index].isHovering.next = false"
      @click="nextClicked()"
    >
    <img
      :class="{ opaque: paginationData[index].isHovering.last && currentPageLessThanTotalPages, notAllowed: paginationData[index].isHovering.last && !currentPageLessThanTotalPages }"
      src="../../assets/icons/toLast.png"
      @mouseover="paginationData[index].isHovering.last = true"
      @mouseout="paginationData[index].isHovering.last = false"
      @click="lastClicked()"
    >
    <input
      v-model="paginationData[index].filter"
      class="search"
      placeholder="Search..."
      @keyup="searchText()"
    >
  </div>
</template>
<script>
import { DataEventBus } from '../../events/dataEvents'

export default {
  name: 'Pagination',
  props: {
    pageLength: { type: Number, default: 20 },
    index: { type: Number, default: 0 },
    marginTop: { type: Number, default: 0 },
    marginBottom: { type: Number, default: 0 },
    hasReplicaInTheSamePage: { type: Boolean, default: false }
  },
  data () {
    return {
      paginationData: [
        { currentPage: 0, totalPages: 0, totalEntries: 0, isHovering: { first: false, prev: false, next: false, last: false, filter: '' } },
        { currentPage: 0, totalPages: 0, totalEntries: 0, isHovering: { first: false, prev: false, next: false, last: false, filter: '' } },
        { currentPage: 0, totalPages: 0, totalEntries: 0, isHovering: { first: false, prev: false, next: false, last: false, filter: '' } },
        { currentPage: 0, totalPages: 0, totalEntries: 0, isHovering: { first: false, prev: false, next: false, last: false, filter: '' } },
        { currentPage: 0, totalPages: 0, totalEntries: 0, isHovering: { first: false, prev: false, next: false, last: false, filter: '' } }
      ]
    }
  },
  computed: {
    totalEntries () {
      return this.paginationData[this.index].totalEntries
    },
    currentPage () {
      return this.paginationData[this.index].currentPage
    },
    totalPages () {
      return this.paginationData[this.index].totalPages
    },
    currentPageGreaterThanOne () {
      return this.currentPage > 1
    },
    currentPageLessThanTotalPages () {
      return this.currentPage < this.totalPages
    },
    currentPageGreaterThanTotalPages () {
      return this.currentPage > this.totalPages
    },
    containerStyle () {
      return `margin-left: 32%; margin-top: ${this.marginTop}px; margin-bottom: ${this.marginBottom}px;`
    }
  },
  watch: {
    totalEntries () {
      this.paginationData[this.index].totalPages = Math.ceil(this.totalEntries / this.pageLength)
      if (this.currentPage > this.totalPages) {
        this.paginationData[this.index].currentPage = this.totalPages
      }
      if (this.paginationData[this.index].currentPage === 0 && this.totalEntries > 0) {
        this.paginationData[this.index].currentPage = 1
      }
    },
    totalPages () {
      if (this.paginationData[this.index].currentPage === 0 && this.paginationData[this.index].totalPages === 1) {
        this.paginationData[this.index].currentPage = 1
      }
    }
  },
  created () {
    DataEventBus.$on(`refreshTotalEntries${this.index}`, (newTotalEntries) => {
      this.paginationData[this.index].totalEntries = newTotalEntries
    })
    if (this.hasReplicaInTheSamePage) {
      DataEventBus.$on(`refreshReplica${this.index}`, (property, forceUpdate, value) => {
        this.paginationData[this.index][property] = value
        if (forceUpdate) {
          this.$forceUpdate()
        }
      })
    }
  },
  destroyed () {
    DataEventBus.$off(`refreshTotalEntries${this.index}`)
    if (this.hasReplicaInTheSamePage) {
      DataEventBus.$off(`refreshReplica${this.index}`)
    }
  },
  methods: {
    firstClicked () {
      if (this.currentPageGreaterThanOne) {
        this.paginationData[this.index].currentPage = 1
        this.$emit('firstClicked')
      }
    },
    prevClicked () {
      if (this.currentPageGreaterThanOne) {
        this.paginationData[this.index].currentPage = this.currentPage - 1
        this.$emit('prevClicked')
      }
    },
    nextClicked () {
      if (this.currentPageLessThanTotalPages) {
        this.paginationData[this.index].currentPage = this.currentPage + 1
        this.$emit('nextClicked')
      }
    },
    lastClicked () {
      if (this.currentPageLessThanTotalPages) {
        this.paginationData[this.index].currentPage = this.totalPages
        this.$emit('lastClicked')
      }
    },
    pageChanged () {
      if (this.currentPageGreaterThanTotalPages) {
        this.paginationData[this.index].currentPage = this.totalPages
      }
      if (this.paginationData[this.index].currentPage === 0 && this.totalPages > 0) {
        this.paginationData[this.index].currentPage = 1
      }
      this.$emit('pageChanged', this.currentPage)
    },
    searchText () {
      this.$emit('searchText', this.paginationData[this.index].filter)
    }
  }
}
</script>

<style scoped>
  span {
    color: black;
    font-weight: 400;
    margin-right: 8px;
    font-size: medium;
  }
  .totalEntries {
    font-size: 14px;
  }
  input {
    background-color: #d2dae9;
    margin-right: 8px;
    padding: 3px;
    width: 35px;
    border: 1px solid gray !important;
    cursor: text !important;
  }
  img {
    margin-right: 8px;
    cursor: pointer;
  }
  .opaque {
    filter: brightness(60%);
  }
  .notAllowed {
    cursor: not-allowed;
  }
  .search {
    margin-left: 12px;
    width: 120px;
  }
  </style>
