<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-ucanalytics"
          primary-title
        >
          Support - UC Analytics Companies
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details the addition, removal and editing of companies' UC Analytics integration details."
              />
              <HelpSection
                instruction="1) Navigate to the Support page and select the “UC Analytics Companies” tile."
                image-source="uc_analytics_7.png"
                indentation="0"
              />
              <HelpSection
                instruction="2) The UC Analytics Companies page should be returned with a number of options."
                image-source="uc_analytics_8.png"
                indentation="0"
              />
              <HelpSection
                instruction="a. A new UC Analytics Company can be created via the Add New UC Analytics Company. This will cause a pop up box to appear with the Company, Base URL and Subscription Tier as compulsory fields."
                image-source="uc_analytics_9.png"
                indentation="1"
              />
              <HelpSection
                instruction="b. Once all these fields are filled and 'Add Company' is selected, the company will be added as a UC Analytics company."
                indentation="1"
              />
              <HelpSection
                instruction="c. Selecting an existing UC Analytics company will cause two new options to appear - “Edit [company]” and “Delete [company]”"
                image-source="uc_analytics_10.png"
                indentation="1"
              />
              <HelpSection
                instruction="d. Expand the “Edit” option to see options for changing the Base URL or Subscription Tier."
                image-source="uc_analytics_11.png"
                indentation="1"
              />
              <HelpSection
                instruction="e. Expand the “Delete” option to have the option to remove the selected company's UC Analytics integration (note: this will not delete the company from the Portal, but rather will just remove its UC Analytics integration)."
                image-source="uc_analytics_12.png"
                indentation="1"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpUCAnalyticsSupport',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
