import contextAPI from '../communications/interceptors/apiInterceptor'

export const contextMixin = {
  data () {
    return {
    }
  },
  methods: {
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
    },
    /*******************************************
    * GET calls, returning all companies with slugs
    * as well as an individual company
    *******************************************/
    getSlugCompanies: async function () {
      var rtn = []
      var axiosConfig = this.getHeaders()
      try {
        await contextAPI.get(`/context/companyslugs`, axiosConfig)
          .then((response) => {
            rtn = response.data
          })
          .catch((rtn) => {
            console.log('Error calling getSlugCompanies with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling getSlugCompanies with exception:' + e)
      }
      return rtn
    },
    getSlugByCompany: async function (companyId) {
      var rtn = {}
      if (companyId === 0) {
        return rtn
      }
      var axiosConfig = this.getHeaders()
      try {
        await contextAPI.get(`/context/companyslugs/companyid/` + companyId, axiosConfig)
          .then((response) => {
            rtn = response.data
          })
          .catch((rtn) => {
            console.log('Error calling getSlugByCompany with company id ' + companyId + ' with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling getSlugByCompany with company id ' + companyId + ' with exception:' + e)
      }
      return rtn
    },
    /*********************************************************
    * POST call to retrieve the contents of the context IFrame
    **********************************************************/
    getContextIFrame: async function (projectLink) {
      const invalidReturn = '<h1>{returnMessage}</h1>'
      if (projectLink === undefined || projectLink === null || projectLink.length === 0) {
        return invalidReturn.replace('{returnMessage}', 'Cannot display context data - a valid context URL was not supplied')
      }
      var axiosConfig = this.getHeaders()
      axiosConfig.headers.ContextHash = '37ce3994-6684-4fc8-b93e-794d2b04b38e'
      // console.log('AXIOS CONFIG!', axiosConfig)
      try {
        await contextAPI.get(projectLink, '', axiosConfig)
          .then((response) => {
            if (response === undefined || response === null) {
              return invalidReturn.replace('{returnMessage}', 'Failed to retrieve context data')
            } else {
              return response
            }
          })
          .catch((rtn) => {
            console.log('Error calling getContextIFrame with error:' + rtn)
            return invalidReturn.replace('{returnMessage}', 'Cannot display context data - error: ' + rtn)
          })
      } catch (e) {
        console.log('Error calling getContextIFrame with error:' + e)
        return invalidReturn.replace('{returnMessage}', 'Cannot display context data - error: ' + e.message)
      }
    },

    /*******************************************
    * PUT call to update a company slug
    *******************************************/
    updateCompanySlug: async function (companyId, companySlug) {
      var rtn = false
      if (companyId === 0 || companySlug.length === 0) {
        return rtn
      }
      var axiosConfig = this.getHeaders()
      var payload = { 'companyId': companyId, 'companySlug': companySlug }
      try {
        await contextAPI.put(`/context/companyslugs/`, payload, axiosConfig)
          .then((response) => {
            rtn = true
          })
          .catch((rtn) => {
            console.log('Error calling updateCompanySlug with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling updateCompanySlug with exception:' + e)
      }
      return rtn
    },

    /*******************************************
    * POST call to add a new company slug
    *******************************************/
    addCompanySlug: async function (companyId, companySlug) {
      var rtn = false
      if (companyId === 0 || companySlug.length === 0) {
        return rtn
      }
      var axiosConfig = this.getHeaders()
      var payload = { 'companyId': companyId, 'companySlug': companySlug }
      try {
        await contextAPI.post(`/context/companyslugs/`, payload, axiosConfig)
          .then((response) => {
            rtn = true
          })
          .catch((rtn) => {
            console.log('Error calling addCompanySlug with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling addCompanySlug with exception:' + e)
      }
      return rtn
    },

    /*******************************************
    * DELETE call to delete a company slug
    *******************************************/
    deleteCompanySlug: async function (companyId, companySlug) {
      var rtn = false
      if (companyId === 0) {
        return rtn
      }
      var axiosConfig = this.getHeaders()
      try {
        await contextAPI.delete(`/context/companyslugs/` + companyId + `/` + companySlug, axiosConfig)
          .then((response) => {
            rtn = true
          })
          .catch((rtn) => {
            console.log('Error calling deleteCompanySlug with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling deleteCompanySlug with exception:' + e)
      }
      return rtn
    }
  }
}
