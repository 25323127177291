import usersAPI from '../communications/interceptors/apiInterceptor.js'
export const usersMixin = {
  data () {
    return {
    }
  },
  methods: {
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
    },
    getUser: async function (user) {
      var data = {}
      try {
        await usersAPI.get('users/email/' + user, { 'Content-Type': 'application/json;charset=UTF-8' }).then((response) => {
          data = response.data
        })
      } catch (_) {
        // eslint-disable-next-line
        console.log('Error calling getUser')
      }
      return data
    },
    getUserByUsername: async function (user) {
      var data = {}
      try {
        await usersAPI.get('users/username/' + user, { 'Content-Type': 'application/json;charset=UTF-8' }).then((response) => {
          data = response.data
        })
      } catch (_) {
        // eslint-disable-next-line
        console.log('Error calling getUser')
      }
      return data
    },
    getUserByNameWithoutAuth: async function (user) {
      var data = {}
      try {
        await usersAPI.get('users/usernamenoauth/' + user, { 'Content-Type': 'application/json;charset=UTF-8' }).then((response) => {
          data = response.data
        })
      } catch (_) {
        // eslint-disable-next-line
        console.log('Error calling getUserByName')
      }
      return data
    },
    getUserById: async function (userId) {
      var data = {}
      try {
        await usersAPI.get('users/id/' + userId, { 'Content-Type': 'application/json;charset=UTF-8' }).then((response) => {
          data = response.data
        })
      } catch (_) {
        // eslint-disable-next-line
        console.log('Error calling getUser')
      }
      return data
    },
    getChannelFilteredUsers: function (data) {
      // Check to see if we are a channel company member
      if (data.length > 0) {
        var isChannel = false
        this.$store.getters.companies.forEach(company => {
          if (company.id === this.$store.getters.channelCompany.companyId) {
            isChannel = true
          }
        })
        if (!isChannel) {
          var res = data.filter(user => !this.$store.getters.channelUsers.includes(user.id))
          return res
        } else {
          return data
        }
      } else {
        return data
      }
    },
    getUserList: async function (userList) {
      var data = []
      if (userList.length > 0) {
        var axiosConfig = this.getHeaders()
        try {
          await usersAPI.post('usersarray/bag', { userList }, axiosConfig).then((response) => {
            data = this.getChannelFilteredUsers(response.data)
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return data
    },
    getUserListByUsername: async function (userList) {
      var data = []
      if (userList.length > 0) {
        var axiosConfig = this.getHeaders()
        try {
          await usersAPI.get('usersarray/bag/username/' + userList, axiosConfig).then((response) => {
            data = this.getChannelFilteredUsers(response.data)
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return data
    },
    addUser: async function ({ userName, firstName, lastName, emailAddress }) {
      var data = {}
      var axiosConfig = this.getHeaders()
      var user = {
        userName,
        firstName,
        lastName,
        emailAddress,
        areaId: 0,
        avatar: ''
      }
      try {
        await usersAPI.post('/users', user, axiosConfig).then((reponse) => {
          var rtn = reponse.data
          data = rtn
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    deleteUser: async function ({ id }) {
      var axiosConfig = this.getHeaders()
      try {
        await usersAPI.delete('/users/' + id, axiosConfig)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    updateUser: async function (user) {
      var axiosConfig = this.getHeaders()
      await usersAPI.put('/users', user, axiosConfig)
    },
    // method no longer called from forgottenPasswordRequest
    // updateNewUser: async function (user) {
    //   var axiosConfig = this.getHeaders()
    //   await usersAPI.patch('/users', user, axiosConfig)
    // },
    addRequestAccessUser: async function (payload) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
      var rtn = ''
      try {
        await usersAPI.post('/users/requestinguser', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    checkRequestAccessUser: async function (emailAddress) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
      var rtn = ''
      try {
        await usersAPI.get('/users/requestinguser/' + emailAddress, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    deleteRequestAccessUser: async function (emailAddress) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
      var rtn = ''
      try {
        await usersAPI.delete('/users/requestinguser/' + emailAddress, '', axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getRequestAccessUsers: async function () {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
      var rtn = ''
      try {
        await usersAPI.get('/users/requestinguser', axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    }
  }
}
