import portalAPIGateway from '../../communications/interceptors/apiInterceptor'
export const iQuoteTechnologyAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteTechnologyAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    getTechnologyById: async function (id) {
      var data = {}
      var axiosConfig = this.iQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/technologies/' + id, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getTechnologyById returned error: ' + error)
      }
      return data
    },
    getTechnologiesByServiceId: async function (serviceid) {
      var data = []
      var axiosConfig = this.iQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/technologies/service/' + serviceid, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getTechnologiesByServiceId returned error: ' + error)
      }
      return data
    },
    getServiceCategoriesByServiceId: async function (serviceid) {
      var data = []
      var categories = this.$store.getters.serviceCategories

      categories.forEach(cat => {
        if (cat.serviceId === serviceid) {
          data.push(cat)
        }
      })

      return data
    },
    getTechnologiesByServiceCategoryId: async function (servicecategoryid) {
      var data = []
      var axiosConfig = this.iQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/technologies/servicecategory/' + servicecategoryid, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getTechnologiesByServiceCategoryId returned error: ' + error)
      }
      return data
    },
    getTechnologiesByServiceAndVendor: async function (serviceid, vendorid) {
      var data = []
      var axiosConfig = this.iQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/technologies/' + serviceid + '/' + vendorid, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getTechnologiesByServiceAndVendor returned error: ' + error)
      }
      return data
    }
  }
}
