<template>
  <div
    :id="id"
    class="roleAreasHolder"
  >
    <Spinner
      v-if="loading"
      id="roleAreaSpinner"
    />
    <table v-if="!loading">
      <tbody>
        <tr
          v-for="a in areas"
          :key="a.id"
          :class="getRowColour()"
        >
          <td>{{ a.name }} </td>
          <td
            v-if="removeRoleAreaPermission"
            style="text-align:right"
          >
            <input
              type="button"
              class="deleteButton"
              title="Remove site from role"
              @click="removeAreaRole(a)"
            >
          </td>
        </tr>
      </tbody>
    </table>
    <input
      v-if="assignRoleAreaPermission && !loading"
      id="roleAreasButton"
      type="button"
      class="roleAreasButton"
      value="Add Site"
      title="Adds a new site to the role"
      @click="addArea()"
    >
    <RoleNewArea
      :roleid="roleid"
    />
  </div>
</template>
<script>
import RoleNewArea from './roleNewArea'
import { permissionsMixin } from '../../../mixins/permissions'
import Spinner from '../../common/spinner'
import { mapActions } from 'vuex'
export default {
  name: 'RoleAreas',
  components: {
    RoleNewArea,
    Spinner
  },
  mixins: [
    permissionsMixin
  ],
  props: {
    roleid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: 'rolesAreasTitle'
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      loading: true,
      areas: []
    }
  },
  computed: {
    reloadAreas () {
      return this.$store.getters.setReloadRoles
    },
    assignRoleAreaPermission () {
      return this.$store.getters.assignRoleAreaPermission
    },
    removeRoleAreaPermission () {
      return this.$store.getters.removeRoleAreaPermission
    }
  },
  watch: {
    roleid () {
      this.setComponent()
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.reloadAreas,
      (newValue) => {
        if (newValue) {
          this.setComponent()
          this.setReloadAreas(false)
        }
      }
    )
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setSubForm3',
      'setReloadAreas',
      'setRoleAreas'
    ]),
    setComponent: async function () {
      if (this.roleid > -1) {
        this.loading = true
        this.getAreas()
      }
    },
    getAreas: async function () {
      if (this.roleid > -1) {
        this.getRoleAreasById(this.roleid).then((result) => {
          this.areas = result
          this.setRoleAreas(result)
          this.loading = false
        })
      }
    },
    addArea: async function () {
      this.setSubForm3(true)
    },
    removeAreaRole: async function (r) {
      await this.deleteRoleArea(this.roleid, r.id)
      this.setComponent()
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    }
  }
}

</script>
<style scoped>
  .roleAreasHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    display: none;
    max-height: 260px;
    overflow: auto;
  }
  .roleAreasButton {
    margin: 10px;
    float: right;
    cursor: pointer;
  }
</style>
