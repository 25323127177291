<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconCallmediaCXNow"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>Callmedia CX Now</h3>
      <p>Select the number of user profiles required:</p>
      <br><br>
      <table>
        <tr>
          <th style="text-align:left;">Profile
            <TooltipIcon text="Description of user types, full details are covered in the <u>Service Description</u>.  Profiles may vary dependant on vendor" />
          </th>
          <th>Min Commit Users<br>
            <TooltipIcon text="This covers the minimum number of agents committed to in the customer MSA and the actual number of users required for operation" />
          </th>
        </tr>
        <tr><td colspan="3">&nbsp;</td></tr>
        <tr
          v-for="userProfile in quoteTechnologyCallmediaCXNow.userProfiles"
          :key="userProfile.technologyUserProfileId"
        >
          <td
            :class="{InputError: !isValidUserProfile(userProfile)}"
            class="userProfile"
            style="width:220px; text-align:left"
          >{{ userProfile.description }}
            <TooltipIcon
              v-if="userProfile.additionalInfo != ''"
              :text="userProfile.additionalInfo" /></td>
          <td>
            <input
              v-model.number="userProfile.minCommitment"
              type="number"
              min="0"
              max="1000000"
              @keyup="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
              @change="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
            >
          </td>
        </tr>
        <tr><td colspan="2"><hr></td></tr>
        <tr
        >
          <td
            class="userProfile"
            style="width:220px; text-align:left"
          ><b>TOTAL:</b>
          </td>
          <td>
            <input
              :value="totalUserCount"
              type="number"
              min="0"
              max="1000000"
              readonly
            >
          </td>
        </tr>
      </table>
      <div
        v-if="totalUserCount < 50"
        class="InputError agentWarning">
        <br>
        Warning - For 50 or more users the AWS charges increase.  Therefore if the customer is expected to burst over 50 at any point then check the box below
      </div>
      <br>
      <label
        class=""
        for="chkAddTalkativePS"
      >Add Talkative PS
        <TooltipIcon text="<ul><li>If unselected then PS is removed from costs.  Licenses are still added, as the customer may want to turn the functionality on later</li><li>Enhanced Web Chat includes:<ul><li>Co Browsing</li><li>Video Calls</li><li>Real-Time language translation</li><li>Customer Journey Tracking</li><li>Support for WhatsApp and Facebook social messaging</li></ul></li></ul>" />
      </label>
      <input
        id="chkAddTalkativePS"
        v-model="quoteTechnologyCallmediaCXNow.options.addTalkativePS"
        type="checkbox"
        class="small"
      >
      <label
        v-if="totalUserCount <= 50"
        class=""
        for="chkBurstPast50"
      >Customer expects to burst past 50 Agents?
      </label>
      <input
        v-if="totalUserCount <= 50"
        id="chkBurstPast50"
        :checked="quoteTechnologyCallmediaCXNow.options.platformSize === 100 && totalUserCount <= 50"
        type="checkbox"
        class="small"
        @change="setPlatformSize($event)"
      >
      <br>
      <br>
      <div v-if="quoteTechnologyCallmediaCXNow.options !== undefined">
        <label
          for="cboCcisConnections"
        >Real-Time Information Connections<TooltipIcon text="Callmedia CX Now supports up to 25 concurrent real-time information connections as standard, for wallboards and supervisors.  If greater than 25 is required then please select the bundle with the needed number of connections" />
        </label>
        <select
          id="cboCcisConnections"
          v-model="quoteTechnologyCallmediaCXNow.options.ccisConnections"
        >
          <option
            v-for="t in cxNowCcisConnections"
            :key="t.id"
            :value="t.id"
            :title="t.name"
          >{{ t.name }}</option>
        </select>
        <br>
        <label
          v-if="digitalUserCount !== totalUserCount || digitalUserCount === 0"
          for="txtOpportunityName">Permanent Media PSTN Connections
          <TooltipIcon text="Determines whether additional SIP channels are provisioned to support nailed up media connections to agents via the PSTN" />
        </label>
        <input
          v-if="digitalUserCount !== totalUserCount || digitalUserCount === 0"
          id="txtPSTNConnectionsRequired"
          v-model.number="quoteTechnologyCallmediaCXNow.options.pstnConnectionsRequired"
          type="number"
          min="0"
          max="400"
        >
        <br>
        <label
          class=""
          for="chkAddPSBundle"
        >Add PS Bundle
          <TooltipIcon text="PS can be removed to allow quotes to be raised for competitive comparisons" />
        </label>
        <input
          id="chkAddPSBundle"
          v-model="quoteTechnologyCallmediaCXNow.options.addPSBundle"
          type="checkbox"
          class="small"
        >
        <div
          v-if="!quoteTechnologyCallmediaCXNow.options.addPSBundle"
          class="InputError">PS should only be removed to allow quotes to be raised for competitive comparisons
        </div>
        <br>
        <label
          class=""
          for="chkIntegrateWithRingCentral"
        >Integrate With RingCentral
          <TooltipIcon text="Select if CX Now is to be used with RingCentral.  This will add one RingCentral license per user.  This applies to both ACO and RCO." />
        </label>
        <input
          id="chkIntegrateWithRingCentral"
          v-model="quoteTechnologyCallmediaCXNow.options.integrateWithRingCentral"
          type="checkbox"
          class="small"
        >
      </div>
      <br>
      <br>
      <table class="noTableShading">
        <tr>
          <td>
            <label
              class=""
            >Select Connectors from App Store
              <TooltipIcon text="Select the required Connectors from the list available, these are charged up-front to the customer" />
            </label>
          </td>
          <td/>
        </tr>
        <span
          v-for="connector in quoteTechnologyCallmediaCXNow.connectors"
          :key="connector.callmediaCxNowConnectorId">
          <tr>
            <td>
              <input
                :id="'chkConnector' + connector.callmediaCxNowConnectorId"
                v-model="connector.selected"
                type="checkbox"
                class="small norightmargin"
                style="margin-right: 0px"
              >
              <label
                :for="'chkConnector' + connector.callmediaCxNowConnectorId"
                class="checkboxLabel"
                style="width: auto"
              >{{ connector.description }}
              </label>
              <div
                v-if="connector.selected && connector.connectivityCost + connector.maintelPSCost + connector.thirdPartyCost === 0"
                class="InputError">This connector has to be quoted separately
              </div>
            </td>
          </tr>
        </span>
      </table>
      <br>
      <br><br>
      <table class="noTableShading">
        <tbody>
          <tr
            v-if="quoteTechnologyCallmediaCXNow.apps.length > 0">
            <td style="padding: 0px; margin:0px">
              <label
                class=""
              >Select Applications from App Store
                <TooltipIcon text="Select the required Applications from the list available, these are charged up-front to the customer" />
              </label>
            </td>
            <td/>
          </tr>
        </tbody>
        <tbody
          v-for="app in quoteTechnologyCallmediaCXNow.apps"
          :key="app.callmediaCxNowAppId">
          <tr>
            <td>
              <input
                :id="'chkApp' + app.callmediaCxNowAppId"
                v-model="app.selected"
                type="checkbox"
                class="small norightmargin"
                style="margin-right: 0px"
              >
              <span>{{ app.description }}</span>
              <div
                v-if="app.selected && app.connectivityCost + app.maintelPSCost + app.thirdPartyCost"
                class="InputError">This application has to be quoted separately
              </div>
            </td>
          </tr>
          <tr v-if="app.quantityDescription !== null && app.selected">
            <td>
              <span class="listindent" />
              <input
                :id="'chkAppQty' + app.id"
                :class="{invalidInput: !isValidAppQuantity(app)}"
                v-model.number="app.quantity"
                type="number"
                min="0"
                max="1000000"
              >
              <label
                :for="'chkAppQty' + app.id"
                style="width: auto"
              >{{ app.quantityDescription }}
              </label>
              <TooltipIcon
                v-show="app.quantityTooltip !== null && app.quantityTooltip !== '' "
                :text="app.quantityTooltip"
                width="300"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('selectService')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('AdjunctsCallmediaCXNow')"
          >
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'UserProfilesCallmediaCXNow',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin],
  data () {
    return {
      quote: {},
      quoteTechnologyCallmediaCXNow: {
        options: {
          ccisConnections: 25,
          pstnConnectionsRequired: 0,
          teamsConnectorRequired: false,
          integrateWithRingCentral: false,
          addTalkativePS: true
        },
        apps: [],
        connectors: []
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconCallmediaCXNow () {
      return require(`../../../assets/icons/callmediaCXNow.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    totalUserCount () {
      var totalUserCount = 0
      if (this.quoteTechnologyCallmediaCXNow.userProfiles !== undefined) {
        for (let index = 0; index < this.quoteTechnologyCallmediaCXNow.userProfiles.length; index++) {
          const userProfile = this.quoteTechnologyCallmediaCXNow.userProfiles[index]

          if (this.isValidUserProfile(userProfile)) {
            totalUserCount += userProfile.minCommitment
          }
        }
      }
      return totalUserCount
    },
    digitalUserCount () {
      var totalUserCount = 0
      if (this.quoteTechnologyCallmediaCXNow.userProfiles !== undefined) {
        for (let index = 0; index < this.quoteTechnologyCallmediaCXNow.userProfiles.length; index++) {
          const userProfile = this.quoteTechnologyCallmediaCXNow.userProfiles[index]

          if (this.isValidUserProfile(userProfile) && userProfile.technologyUserProfileId === this.iQuoteConstants.cxNowUserProfiles.DIGITAL_ONLY) {
            totalUserCount += userProfile.minCommitment
          }
        }
      }
      return totalUserCount
    },
    cxNowCcisConnections () {
      var values = []
      const connectionsPerServer = 25

      for (let serverCount = 1; serverCount <= 8; serverCount++) {
        var maxConnections = serverCount * connectionsPerServer
        values.push({ id: maxConnections, name: 'up to ' + maxConnections + ' connections' })
      }

      return values
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote

    var quoteTechnologyCallmediaCXNowStore = this.$store.getters.quoteTechnologyCallmediaCXNow

    if (quoteTechnologyCallmediaCXNowStore.id === undefined || quoteTechnologyCallmediaCXNowStore.id === 0) {
      var userProfiles
      var adjuncts
      var options
      var connectors
      var apps

      this.quoteTechnologyCallmediaCXNow.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.callmediaCXNow)

      if (this.quoteTechnologyCallmediaCXNow.id === 0) {
        this.quoteTechnologyCallmediaCXNow.id = await this.addQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.callmediaCXNow)

        // Set default values for new technology on quote
        userProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.callmediaCXNow, this.quoteTechnologyCallmediaCXNow.id)
        connectors = this.iQuoteStore_getDefaultCxNowConnectors()
        apps = this.iQuoteStore_getDefaultCxNowApps()
        adjuncts = {
          id: 0,
          quoteId: this.quote.id
        }
        options = {
          id: 0,
          quoteId: this.quote.id,
          platformSize: 100,
          pstnConnectionsRequired: 0,
          teamsConnectorRequired: false,
          ccisConnections: 25,
          accelerateCXNow: false,
          addPSBundle: true,
          addTalkativePS: true,
          integrateWithRingCentral: false
        }
      } else {
        // Retrieve data from DB
        userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyCallmediaCXNow.id)
        adjuncts = await this.getQuoteCallmediaCXNowAdjuncts(this.quote.id)
        options = await this.getQuoteOptionsCallmediaCXNow(this.quote.id)
        connectors = await this.getQuoteCallmediaCXNowConnectors(this.quote.id)
        apps = await this.getQuoteCallmediaCXNowApps(this.quote.id)
      }

      if (userProfiles.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyCallmediaCXNow, 'userProfiles', userProfiles)
      }

      if (adjuncts.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyCallmediaCXNow, 'adjuncts', adjuncts)
      }

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyCallmediaCXNow, 'options', options)
      }

      if (connectors.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyCallmediaCXNow, 'connectors', connectors)
      }

      if (apps.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyCallmediaCXNow, 'apps', apps)
      }

      this.setQuoteTechnologyCallmediaCXNow(this.quoteTechnologyCallmediaCXNow)
    } else {
      this.quoteTechnologyCallmediaCXNow = quoteTechnologyCallmediaCXNowStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyCallmediaCXNow'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      var TotalUserCount = 0

      for (let index = 0; index < this.quoteTechnologyCallmediaCXNow.userProfiles.length; index++) {
        const userProfile = this.quoteTechnologyCallmediaCXNow.userProfiles[index]

        if (!this.isValidUserProfile(userProfile)) {
          ValidationFailed = true
        } else {
          TotalUserCount += userProfile.minCommitment
        }
      }

      for (let index = 0; index < this.quoteTechnologyCallmediaCXNow.apps.length; index++) {
        const app = this.quoteTechnologyCallmediaCXNow.apps[index]

        if (!app.selected && app.quantity > 0) {
          app.quantity = 0
        }
        if (!this.isValidAppQuantity(app)) {
          ValidationFailed = true
        }
      }

      if (TotalUserCount <= 50) {
        this.quoteTechnologyCallmediaCXNow.options.accelerateCXNow = false
      } else {
        this.quoteTechnologyCallmediaCXNow.options.platformSize = 100
      }

      if (this.digitalUserCount === this.totalUserCount && this.digitalUserCount > 0) {
        this.quoteTechnologyCallmediaCXNow.options.pstnConnectionsRequired = 0
      }

      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied (even if pressing back rather than next)
        this.quoteTechnologyCallmediaCXNow.userProfiles = await this.saveQuoteTechnologyUserProfiles(this.quoteTechnologyCallmediaCXNow.userProfiles)
        this.quoteTechnologyCallmediaCXNow.options = await this.saveQuoteOptionsCallmediaCXNow(this.quoteTechnologyCallmediaCXNow.options)
        this.quoteTechnologyCallmediaCXNow.apps = await this.saveQuoteCallmediaCXNowApps(this.quoteTechnologyCallmediaCXNow.apps)
        this.quoteTechnologyCallmediaCXNow.connectors = await this.saveQuoteCallmediaCXNowConnectors(this.quoteTechnologyCallmediaCXNow.connectors)
      }

      if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      } else if (TotalUserCount === 0 && page === 'AdjunctsCallmediaCXNow') {
        this.iQuoteFunctions_ShowError('Please enter how many users')
        return
      } else if (TotalUserCount < 1 && page === 'AdjunctsCallmediaCXNow') {
        this.iQuoteFunctions_ShowError('Please enter at least 1 user')
        return
      } else if (TotalUserCount > 400) {
        // Can't go next if invalid inputs supplied (but can go back)
        if (page === 'IconContact' && TotalUserCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter less than 400 users, or enter 0 (this will remove callmedia cx now from the quote)')
        } else if (page === 'AdjunctsCallmediaCXNow') {
          this.iQuoteFunctions_ShowError('cannot support over 400 users')
        }
        return
      }

      if (TotalUserCount > 0) {
        // update local store
        this.setQuoteTechnologyCallmediaCXNow(this.quoteTechnologyCallmediaCXNow)
      } else {
        this.deleteQuoteTechnology(this.quoteTechnologyCallmediaCXNow.id)
        // delete from local store
        this.setQuoteTechnologyCallmediaCXNow({})
      }

      this.setCurrentQuoteState(page)
    },
    isValidUserProfile (userProfile) {
      return userProfile.minCommitment <= userProfile.maxAllowed &&
                                          this.iQuoteFunctions_IsInteger(userProfile.minCommitment) &&
                                          this.iQuoteFunctions_IsInteger(userProfile.maxAllowed) &&
                                          userProfile.minCommitment >= 0 &&
                                          userProfile.maxAllowed >= 0 &&
                                          userProfile.maxAllowed >= (userProfile.minCommitment * 1.2)
    },
    isValidAppQuantity (app) {
      return !app.selected || (this.iQuoteFunctions_IsInteger(app.quantity) &&
             app.quantity > 0)
    },
    setPlatformSize (event) {
      var val = event.target.checked
      if (val || this.totalUserCount > 50) {
        this.quoteTechnologyCallmediaCXNow.options.platformSize = 100
      } else {
        this.quoteTechnologyCallmediaCXNow.options.platformSize = 50
      }
    },
    finishService () {
      this.gotoPage('SelectService')
    }
  }
}
</script>
<style scoped>
  .small{width:60px;}
  .quotePanel label{width: 350px}
  .quotePanel {width: 800px}
  .hidden{visibility: hidden}
  div .quotePanel .agentWarning {margin-bottom: 32px;}

  .quotePanel span.listindent {margin-right: 65px;}
</style>
