<template>
  <div
    v-if="$store.getters.openMainForm"
    class="createEmailSuffixTileContainer"
  >
    <h3>Create New Email Suffix</h3>
    <div class="emailSuffixTileContainer">
      <label
        for="txtEmailSuffixCompany"
        class="createEmailSuffixLabel"
      >Select Company:</label>
      <PortalDropdown
        id="txtEmailSuffixCompany"
        :options="companies"
        :disabled="false"
        :max-item="999999"
        :class="{ errorHighlight: companySelectError }"
        :attach-blur-event="false"
        v-model="companyId"
        class="companySelect"
        style="width: 380px;"
        placeholder="Please select a Company to assign the email suffix to"
        tabindex="2"
        @selected="selectCustomerCompany"
        @blur="checkForm"
      />
    </div>
    <div>
      <label
        for="txtEmailSuffix"
        class="createEmailSuffixLabel"
      >Email Suffix:</label>
      <input
        id="txtEmailSuffix"
        :class="{ errorHighlight: emailSuffixError || emailDuplicationError }"
        v-model.trim="emailSuffix"
        type="text"
        class="createEmailSuffixTextbox emailSuffix"
        @blur="checkForm"
      >
    </div>
    <div class="createEmailSuffixControls">
      <TextButton
        title="Cancel Email Suffix Creation"
        text="Cancel"
        @click="close()"
      />
      <TextButton
        :disabled="companySelectError || emailSuffixError || emailDuplicationError"
        title="Create Email Suffix"
        text="Create"
        style="float:right;"
        @click="create()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { permissionsMixin } from '../../../mixins/permissions'
import PortalDropdown from '../../common/portalDropdown'
import TextButton from '../../common/textButton.vue'
export default {
  name: 'CreateEmailSuffixComponent',
  components: {
    PortalDropdown,
    TextButton
  },
  mixins: [
    permissionsMixin
  ],
  data () {
    return {
      companySelectError: true,
      emailSuffixError: false,
      emailDuplicationError: false,
      emailSuffix: '',
      companyId: 0,
      emailSuffixes: []
    }
  },
  computed: {
    companies () {
      return this.$store.getters.companies
    }
  },
  async mounted () {
    this.emailSuffixes = await this.getAllCompanyEmailSuffixes()
  },
  methods: {
    ...mapActions([
      'setMainForm'
    ]),
    selectCustomerCompany (company) {
      this.companyId = company.id
      this.companySelectError = false
    },
    checkForm ({ target: { className: checkField } }) {
      if (checkField) {
        if (checkField.indexOf('emailSuffix') > 0) {
          this.emailSuffixError = this.validateEmailSuffix(this.emailSuffix)
          this.emailDuplicationError = this.isDuplicateSuffix(this.emailSuffix)
        }
        if (checkField === 'companySelect') {
          this.companySelectError = !this.companyId || this.companyId === 0
        }
      } else {
        this.emailSuffixError = this.validateEmailSuffix(this.emailSuffix)
        this.emailDuplicationError = this.isDuplicateSuffix(this.emailSuffix)
        this.companySelectError = !this.companyId || this.companyId === 0
      }
      if (this.emailSuffixError) {
        this.$store.commit('showMessage', { content: 'The email suffix is not valid', color: 'red', timeout: 5000 })
      } else if (this.emailDuplicationError) {
        this.$store.commit('showMessage', { content: 'The email suffix is already in use and cannot be used again', color: 'red', timeout: 5000 })
      } else {
        if (this.companySelectError) {
          this.$store.commit('showMessage', { content: 'Please select a company to associate with the email suffix', color: 'red', timeout: 5000 })
        }
      }
    },
    validateEmailSuffix (suffix) {
      var re = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return !re.test(suffix)
    },
    isDuplicateSuffix (suffix) {
      var res = this.emailSuffixes.find(s => s.emailSuffix === suffix)
      return res !== undefined
    },
    async create () {
      this.checkForm({ target: { } })
      if (!this.emailSuffixError && !this.emailDuplicationError && !this.companySelectError) {
        if (await this.addEmailSuffix(this.companyId, this.emailSuffix)) {
          this.$store.commit('showMessage', { content: 'The email suffix was created successfully', color: 'blue', timeout: 3000 })
          this.close()
        } else {
          this.$store.commit('showMessage', { content: 'An error occurred writing the email suffix to the database', color: 'red', timeout: 5000 })
        }
      }
    },
    close () {
      this.companyId = 0
      this.emailSuffix = ''
      this.$emit('emailSuffixCreated')
      this.setMainForm(false)
    }
  }
}
</script>
<style scoped>
  .createEmailSuffixTileContainer {
    display: inline-block;
    margin: 20px 0 0 20px;
    background-color: #fff;
    border: #aaa solid 1px;
    padding: 10px;
    border-radius: 5px;
  }
  .emailSuffixTileContainer{
    margin-bottom: 8px;;
  }
  .createEmailSuffixLabel {
    display: inline-block;
    width:140px;
  }
  .createEmailSuffixControls {
    margin: 20px 0 20px 0;
    width:100%;
  }
  .companySelect {
    display: inline-block;
  }
  .emailSuffix{
    padding-left: 5px;
  }
  .errorHighlight {
    border: 1px solid red;
  }
</style>
