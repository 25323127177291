<template>
  <div class="switchContainer">
    <label class="switch">
      <input
        :checked="checked"
        :disabled="!enabled"
        type="checkbox"
        @change="changePermission($event)">
      <span
        :class="enabledClass"
        class="slider round"/>
    </label>
  </div>
</template>
<script>
export default {
  name: 'ToggleSwitch',
  props: {
    areaid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    state: {
      type: Number,
      default: -1
    },
    permissionid: {
      type: Number,
      default: -1
    },
    mode: {
      type: String,
      default: ''
    },
    enabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checked: true
    }
  },
  computed: {
    enabledClass () {
      if (this.enabled) {
        return 'none'
      } else {
        return 'disabled'
      }
    }
  },
  watch: {
    state () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    setComponent () {
      if (this.state === '1') {
        this.checked = true
      } else if (this.state === '2') {
        this.checked = false
      }
    },
    changePermission (event) {
      var elem = event.target
      // eslint-disable-next-line
        console.log('mode: ' + this.mode + ', areaid: ' + this.areaid + ', userid: ' + this.userid + ', permissionid: ' + this.permissionid + ', value: ' + elem.checked);
      var perm = { mode: this.mode, areaid: this.areaid, userid: this.userid, permissionid: this.permissionid, value: elem.checked }
      this.$emit('changePermission', perm)
    }
  }
}
</script>

<style scoped>
.switchContainer {
  display:inline-block;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 90, 90);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: rgb(77, 215, 77);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(77, 215, 77);
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round.disabled {
  background-color:#c18080;
}

input:checked + .slider.round.disabled {
  background-color:#92bd92;
}

</style>
