<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-managepermissions"
          primary-title
        >
          Managing Permissions
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details how permissions can be assigned to roles."
              />
              <HelpSection
                instruction="1.) Navigate to the 'Security' page via the left hand navigation menu."
                image-source="manage_permissions_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="2) Select the 'Permissions' tab to view a list of all permissions available to manage for the current user."
                indentation="0"
              />
              <HelpSection
                instruction="a.) When a permission is selected, a menu on the right hand side with further options will display."
                indentation="1"
              />
              <HelpSection
                instruction="3.) Under the 'Role Permissions' section the roles that the selected permission belongs to can be managed."
                image-source="manage_permissions_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) There are three states for permissions:"
                indentation="1"
              />
              <HelpSection
                instruction="i.) ‘Enabled’ which will grant the permission"
                indentation="2"
              />
              <HelpSection
                instruction="ii.) ‘Disabled’ which will deny the permission"
                indentation="2"
              />
              <HelpSection
                instruction="iii.) ‘Inherit’ which will allow other roles assigned to a user to dictate whether a permission is allowed or denied."
                indentation="2"
              />
              <HelpSection
                instruction="b.) Permissions can be removed completely from a Role using the bin control next to each role name. This will prevent control of the permission for that Role group."
                indentation="1"
              />
              <HelpSection
                instruction="4.) Using the 'Add Role' button the permission can be added to a new Role that it isn't already assigned to."
                image-source="manage_permissions_3.png"
                indentation="0"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpManageUsers',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
