// This module is used for state mamangement for ticket related variables
// It also includes the temporary products table for multiple CI returns

const state = {
  tickets: [],
  pmnTickets: [],
  ticketUDFs: [],
  tempTickets: [],
  tempProducts: []
}

const mutations = {
  SET_TICKETS (state, tickets) {
    state.tickets = tickets
  },
  SET_TICKET_UDFS (state, ticketUDFs) {
    state.ticketUDFs = ticketUDFs
  },
  SET_TEMP_TICKETS (state, tempTickets) {
    state.tempTickets = tempTickets
  },
  SET_PMN_TICKETS (state, pmnTickets) {
    state.pmnTickets = pmnTickets
  },
  SET_TEMP_PRODUCTS (state, tempProducts) {
    state.tempProducts = tempProducts
  }
}

const actions = {
  setTickets: ({ commit }, tickets) => {
    commit('SET_TICKETS', tickets)
  },
  setTicketUDFs: ({ commit }, ticketUDFs) => {
    commit('SET_TICKET_UDFS', ticketUDFs)
  },
  setPMNTickets: ({ commit }, pmnTickets) => {
    commit('SET_PMN_TICKETS', pmnTickets)
  },
  setTempTickets: ({ commit }, tempTickets) => {
    commit('SET_TEMP_TICKETS', tempTickets)
  },
  setTempProducts: ({ commit }, tempProducts) => {
    commit('SET_TEMP_PRODUCTS', tempProducts)
  },
  setTicket: ({ commit }, ticket) => {
    var tickets = state.tickets
    var index = tickets.findIndex(x => x.id === ticket.id)
    tickets[index] = ticket
    commit('SET_TICKETS', tickets)
  },
  clearTickets: ({ commit }) => {
    commit('SET_TICKETS', null)
  }
}

const getters = {
  tickets: state => {
    return state.tickets
  },
  ticketUDFs: state => {
    return state.ticketUDFs
  },
  ticket: (state) => (id) => {
    return state.tickets.find(x => x.id === id)
  },
  pmnTickets: state => {
    return state.pmnTickets
  },
  tempTickets: state => {
    return state.tempTickets
  },
  tempProducts: state => {
    return state.tempProducts
  },
  ticketHighlight: state => {
    if (!state.tickets) { return '' }
    if (state.tickets.findIndex(x => x.highlight === 'Red') > -1) { return 'Red' }
    if (state.tickets.findIndex(x => x.highlight === 'Amber') > -1) { return 'Amber' }
    return ''
  }
}

// This export is used to allow the vuex components to extend ito the global state
export default {
  state,
  mutations,
  actions,
  getters
}
