<template>
  <div
    v-if="$store.getters.openSubForm"
    class="roleNewMemberBackground"
  >
    <div class="roleNewMemberHolder">
      <div class="roleTableholder">
        <table>
          <thead>
            <tr>
              <th colspan="4">New Permission Member</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="m in members"
              id="memberList"
              :key="m.id"
            >
              <td>
                <input
                  :id="m.id"
                  type="checkbox"
                  title="Select User"
                  class="memberItem"
                  @change="checkForm"
                >
              </td>
              <td>{{ m.userName }} </td>
              <td>{{ m.firstName }} </td>
              <td>{{ m.lastName }} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <input
        id="roleCancelNewMemberButton"
        type="button"
        class="roleCancelNewMemberButton"
        value="Cancel"
        title="Cancel the add operation"
        @click="cancelAdd()"
      >
      <input
        id="roleAddNewMemberButton"
        :class="getButtonClass"
        type="button"
        value="Add User"
        title="Adds the new user to the permission"
        @click="addMember()"
      >
      <input
        type="text"
        class="focusField"
        @focus="cycleFocus"
      >
    </div>
  </div>
</template>
<script>
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'PermissionNewMember',
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    permissionid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    areaid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      members: [],
      formOK: false
    }
  },
  computed: {
    ...mapState([
      'openSubForm'
    ]),
    getButtonClass () {
      if (this.formOK) {
        return 'roleAddNewMemberButtonEnabled'
      } else {
        return 'roleAddNewMemberButton'
      }
    }
  },
  watch: {
    areaid () {
      this.setComponent()
    }
  },
  created () {
    this.setComponent()
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.openSubForm,
      (newValue) => {
        if (document.getElementById('memberList') && newValue) {
          document.getElementById('memberList').focus()
        }
      }
    )
  },
  methods: {
    ...mapActions([
      'setSubForm',
      'setReloadMembers'
    ]),
    setComponent () {
      this.getMembers()
    },
    checkForm () {
      var selected = false
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          selected = true
        }
      }
      this.formOK = selected
    },
    checkExists (entity, id) {
      return entity.id === id
    },
    getMembers: async function () {
      var userList = ''
      var rMembers = await this.getPermissionEntityMembers(this.permissionid, this.areaid)
      var aMembers = await this.getUsers(this.userid)
      var members = []
      aMembers.forEach(member => {
        var e = this.checkExists(rMembers, member.id)
        if (!e) {
          members.push(member)
        }
      })
      members.forEach(entity => {
        userList += '|' + entity.id
      })
      userList = userList.substring(1)
      var rtn = await this.getUserList(userList)
      this.members = rtn
    },
    closeForm () {
      this.setSubForm(false)
    },
    addMember: async function () {
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          await this.addEntityPermission(memberItems[i].id, this.areaid, this.permissionid)
        }
      }
      this.setReloadMembers(true)
      this.closeForm()
    },
    cancelAdd () {
      this.closeForm()
    },
    cycleFocus () {
      if (document.getElementById('memberList')) {
        document.getElementById('memberList').focus()
      }
    }
  }
}

</script>
<style scoped>
  .roleNewMemberBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .roleNewMemberHolder {
    display: inline-block;
    background-color: rgba(255,255,255,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 160px);
    top: calc(10%);
    max-height: calc(100% - 180px);
    width: 380px;
    z-index: 999;
  }
  .roleCancelNewMemberButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .roleAddNewMemberButton {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    opacity: 0.5;
  }
  .roleAddNewMemberButtonEnabled {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .roleTableholder {
    overflow: auto;
    height:calc(100% - 64px);
    max-height: 300px;
  }
  .focusField {
    position: absolute;
    left:0;
    bottom:0;
    width:0;
    background-color: rgba(240,240,240,1);
  }
  .memberItem {
    margin: 7px;
  }
</style>
