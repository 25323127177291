<template>
  <div class="quotePanelContainer">
    <div class="quotePanel" >
      <label
        :class="{InputError: !isValidOpportunity}"
        for="txtSalesforceQuote"
      >Salesforce Quote #
        <TooltipIcon
          width="500"
          text="Login to Salesforce and retrieve from Salesforce Opportunity Number" />
      </label>
      <input
        id="txtSalesforceQuote"
        v-model="quote.salesforceQuoteNumber"
        :readonly="quote.quoteOpportunityId !== 0"
        type="text"
        @keyup="resetSalesforceOpportunity()"
      >
      <img
        v-if="quote.quoteOpportunityId === 0"
        :src="search"
        class="search"
        @click="getSalesforceOpportunity()"
      >
      <div
        v-if="searchingSalesforce"
      >
        <spinner />
      </div>
      <div
        v-if="isValidOpportunity && !searchingSalesforce"
      >
        <div>
          <label for="txtAccount">Account</label>
          <input
            id="txtAccount"
            :value="quote.salesforceAccountName"
            readonly="true"
            type="text"
          >
        </div>
        <div v-if="opportunity != null">
          <label
            :class="{InputError: quote.salesforceContactFirstName === null}"
            for="cboContact"
          >Contact</label>
          <select
            id="cboContact"
            v-model="opportunity.contactId"
            @change="contactChanged($event)"
          >
            <option
              v-for="c in opportunity.contacts"
              :key="c.id"
              :value="c.id"
              :title="c.firstName + ' ' + c.lastName"
              :selected="c.id === opportunity.contactId"
            >{{ c.firstName + ' ' + c.lastName }}</option>
          </select>
        </div>
        <div v-if="opportunity == null">
          <label for="txtContact" >Contact</label>
          <input
            id="txtContact"
            :value="contactFullName"
            type="text"
            readonly="true"
          >
          <img
            v-if="quote.salesforceContactFirstName != null"
            :src="search"
            class="search"
            @click="getSalesforceOpportunity()"
          >
        </div>
        <div>
          <label
            :class="{InputError: quote.salesforceContactEmail === null && quote.salesforceContactFirstName !== null}"
            for="txtContactEmail"
          >Contact Email</label>
          <input
            id="txtContactEmail"
            :value="quote.salesforceContactEmail"
            type="text"
            readonly="true"
          >
          <label for="txtOpportunityName">Opportunity Name</label>
          <input
            id="txtOpportunityName"
            :value="quote.salesforceOpportunityName"
            type="text"
            readonly="true"
          >
          <label for="txtQuoteDescription">Quote Description</label>
          <input
            id="txtQuoteDescription"
            v-model="quote.quoteDescription"
            type="text"
          >
        </div>
      </div>
    </div>
    <hr>
    <div
      v-if="isValidOpportunity && !searchingSalesforce"
      class="quotePanel" >
      <label for="txtiQuoteReference">iQuote Reference</label>
      <input
        id="txtiQuoteReference"
        :value="displayQuoteRef"
        type="text"
        readonly="true"
      >
      <label for="txtDateCreated">Date Created</label>
      <input
        id="txtDateCreated"
        :value="quoteDateCreated && quoteDateCreated.toISOString().split('T')[0]"
        type="date"
        readonly="true"
      >
      <label for="txtDateLastEdited">Date Last Edited</label>
      <input
        id="txtDateLastEdited"
        :value="quoteDateLastEdited && quoteDateLastEdited.toISOString().split('T')[0]"
        type="date"
        readonly="true"
      >
      <label for="txtCreatedByName">Created By</label>
      <input
        id="txtCreatedByName"
        :value="createdByName"
        type="text"
        readonly="true"
      >
      <label for="txtLastEditedByName">Last Edited By</label>
      <input
        id="txtLastEditedByName"
        :value="lastEditedByName"
        type="text"
        readonly="true"
      >
      <br><br>
      <div class="buttons">
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="nextPage"
          >
        </div>
        <div class="middleButtons">
          <input
            id="saveButton"
            value="Save"
            type="button"
            @click="saveQuoteButtonClicked"
          >
        </div>
      </div>

      <br>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { mapActions } from 'vuex'
import { iQuoteAdjunctDataAPIMixin } from '../../../mixins/iQuote/iQuoteAdjunctDataAPIMixin'
import { iQuoteSalesforceAPIMixin } from '../../../mixins/iQuote/iQuoteSalesforceAPIMixin'
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import Spinner from '../../common/spinner'

export default {
  name: 'ChooseOpportunity',
  components: {
    TooltipIcon,
    Spinner
  },
  mixins: [ShowHelpMixin, iQuoteAdjunctDataAPIMixin, iQuoteSalesforceAPIMixin, iQuoteQuoteAPIMixin, iQuoteFunctions, iQuoteStoreMixin],
  data () {
    return {
      quote: {},
      opportunity: null,
      searchingSalesforce: false
    }
  },
  computed: {
    isValidOpportunity () {
      return (this.quote.salesforceOpportunityId !== null)
    },
    lastEditedByName () {
      return this.iQuoteStore_DisplayUserNameFromId(this.quote.lastEditedByUserId)
    },
    createdByName () {
      return this.iQuoteStore_DisplayUserNameFromId(this.quote.createdByUserId)
    },
    displayQuoteRef () {
      if (this.quote.id === null || this.quote.id === 0) {
        return '<Not Saved Yet>'
      } else {
        return this.quote.quoteOpportunityId + ' (revision ' + this.quote.revisionNumber + ')'
      }
    },
    contactFullName () {
      if (this.quote.salesforceContactFirstName !== null) {
        return this.quote.salesforceContactFirstName + ' ' + this.quote.salesforceContactLastName
      } else {
        return ''
      }
    },
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    search () {
      return require(`../../../assets/icons/search.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    toiQuoteSave () {
      return require(`../../../assets/icons/save.png`)
    },
    quoteDateCreated () {
      if (this.quote.dateCreated === undefined || this.quote.dateCreated === '0001-01-01T00:00:00') {
        return null
      } else {
        return new Date(this.quote.dateCreated)
      }
    },
    quoteDateLastEdited () {
      if (this.quote.dateLastEdited === undefined || this.quote.dateLastEdited === '0001-01-01T00:00:00') {
        return null
      } else {
        return new Date(this.quote.dateLastEdited)
      }
    },
    quoteDatePublished () {
      if (this.quote.datePublished === undefined || this.quote.datePublished === '0001-01-01T00:00:00') {
        return null
      } else {
        return new Date(this.quote.datePublished)
      }
    },
    quoteIsValid () {
      return this.quote.salesforceContactEmail !== null
    }
  },
  mounted () {
    this.initialiseFromStore()
  },
  created () {
    this.initialiseFromStore()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuote'
    ]),
    nextPage: async function () {
      var result = await this.saveQuote()
      if (result === 'ok') {
        // If there wasn't a problem with saving the quote, and it is valid we can change to the next page

        this.setCurrentQuoteState('SelectService')
      } else {
        alert(result)
      }
    },
    saveQuoteButtonClicked: async function () {
      await this.saveQuote()
      if (this.quote.id !== 0) {
        // If there wasn't a problem with saving the quote we can tell the user
        alert('Quote was sucessfully saved')
      }
    },
    getSalesforceOpportunity: async function () {
      this.searchingSalesforce = true

      var SalesforceOpportunity = await this.iQuoteSalesforceAPIMixin_getSalesforceOpportunityData(this.quote.salesforceQuoteNumber)

      if (SalesforceOpportunity === null) {
        this.iQuoteFunctions_ShowError('Error Querying Salesforce.  Please try again.')
        this.quote.salesforceOpportunityId = null
      } else if (SalesforceOpportunity.accountName === undefined) {
        this.iQuoteFunctions_ShowError('Salesforce Opportunity not found.  Please check that you typed it correctly and that it is not deleted from Salesforce.')
        this.quote.salesforceOpportunityId = null
      } else {
        this.quote.salesforceOpportunityId = SalesforceOpportunity.id
        if (this.quote.quoteDescription === null || this.quote.quoteDescription === '') {
          this.quote.quoteDescription = SalesforceOpportunity.opportunityName
        }
        this.quote.salesforceOpportunityName = SalesforceOpportunity.opportunityName
        this.quote.salesforceAccountName = SalesforceOpportunity.accountName
        // A contact has already been chosen, so use that rather than the one assigned in salesforce for this opportunity
        if (this.quote.salesforceContactFirstName !== null) {
          for (let i = 0; i < SalesforceOpportunity.contacts.length; i++) {
            if (SalesforceOpportunity.contacts[i].contactFirstName === this.quote.salesforceContactFirstName && SalesforceOpportunity.contacts[i].contactLastName === this.quote.salesforceContactLastName) {
              SalesforceOpportunity.contactId = SalesforceOpportunity.contacts[i].id
            }
          }
        }
        this.opportunity = SalesforceOpportunity
        this.setQuoteContactDetails(this.opportunity.contactId)
      }

      this.searchingSalesforce = false
    },
    resetSalesforceOpportunity: async function () {
      this.quote.salesforceOpportunityId = null
      this.quote.salesforceAccountName = null
      this.quote.salesforceOpportunityName = null
      this.quote.salesforceContactEmail = null
      this.quote.salesforceContactFirstName = null
      this.quote.salesforceContactLastName = null
      this.opportunity = null
      if (this.quote.salesforceQuoteNumber !== undefined && this.quote.salesforceQuoteNumber.length === 7) {
        this.getSalesforceOpportunity()
      } else {
        this.quote.salesforceOpportunityId = null
      }
    },
    saveQuote: async function () {
      if (!this.quoteIsValid) {
        return 'Please supply all required information to proceed'
      } else {
        if ((this.quote.salesforceOpportunityId === null || this.quote.quoteOpportunityId === 0)) {
          this.quote.salesforceOpportunityId = this.opportunity.opportunityID
        }

        if (this.quote.id === 0) {
        // This is a new quote, so need to create entry in DB
          var returnedQuote = await this.iQuoteQuoteAPIMixin_CreateQuoteOpportunity(this.quote)
          if (returnedQuote.id === undefined) {
            return 'Error saving quote'
          } else {
            this.quote = returnedQuote
          }
        } else {
        // This is an Existing quote, so update it
          this.quote.lastEditedByUserId = this.$store.getters.userid
          this.quote = await this.iQuoteQuoteAPIMixin_AddOrUpdateQuoteRevision(this.quote)
        }

        // Save updated quote details to the local store
        this.setQuote(this.quote)

        return 'ok'
      }
    },
    getComboItemName: function (list, id) {
      var name = ''

      list.forEach(element => {
        if (element.id === id) {
          name = element.name
        }
      })

      return name
    },
    contactChanged: function (event) {
      this.setQuoteContactDetails(event.target.value)
    },
    setQuoteContactDetails: function (contactId) {
      for (let i = 0; i < this.opportunity.contacts.length; i++) {
        if (this.opportunity.contacts[i].id === contactId) {
          this.quote.salesforceContactEmail = this.opportunity.contacts[i].email
          this.quote.salesforceContactFirstName = this.opportunity.contacts[i].firstName
          this.quote.salesforceContactLastName = this.opportunity.contacts[i].lastName
        }
      }
    },
    initialiseFromStore () {
      this.setCurrentQuoteState(this.name)
      this.quote = this.$store.getters.quote
    }
  }
}
</script>
<style scoped>
  .quotePanel {
    width: 580px;
    text-align: left;
  }

  #txtAccount {width:200px;}
  #txtContact {width:160px;}
  #txtContactEmail {width:200px;}
  #txtOpportunityName {width:200px;}
  #txtQuoteDescription {width:200px;}
  #txtFirstName {width:54px;}
  #txtLastName {width:54px;}
</style>
