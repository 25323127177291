<template>
  <div class="securityHolder">
    <div class="mdContainer appSectionBody">
      <RoleNew />
      <RoleNewArea />
      <div
        :class="{ mdHalfScreen : !!selectedRole }"
        class="mdRoleManagement"
      >
        <TextButton
          v-if="createRolePermission"
          :button-tabindex="10"
          button-style="margin-bottom: 10px; float: right;"
          button-id="btnNewRole"
          title="Add a new Role"
          text="Add New Role"
          @click="openNewRole"
        />
        <table id="rolesTable">
          <thead/>
          <tbody>
            <tr
              v-for="v in currentFilter"
              :key="v.id"
              :class="getRowColour(v.id)"
              :tabindex="20"
              @click="selectRole(v)"
              @keyup="testSelectRole($event, v)"
            >
              <td :title="v.name">{{ v.name }}</td>
              <td>{{ v.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="!!selectedRole"
        class="mdSelection"
      >
        <SectionCollapsedHeader
          id="sectionRoleDetails"
          :title="rolesDetailsTitle"
          type="subHeader"
          icon-file="Edit"
          controls="roleDetails"
        />
        <RoleDetails
          id="roleDetails"
          :roleid="selectedRole.id"
          :userid="userid"
        />
        <SectionCollapsedHeader
          v-if="viewEntityRolePermission"
          id="sectionRoleMembers"
          :title="rolesMembersTitle"
          type="subHeader"
          icon-file="Person"
          controls="roleMembers"
        />
        <RoleMembers
          id="roleMembers"
          :roleid="selectedRole.id"
          :userid="userid"
          :use-alt-colour="useAltColour"
        />
        <SectionCollapsedHeader
          v-if="viewAssignedPermissionPermission"
          id="sectionRolePermissions"
          :title="rolesPermissionsTitle"
          type="subHeader"
          icon-file="Lock"
          controls="rolePermissions"
        />
        <RolePermissions
          id="rolePermissions"
          :roleid="selectedRole.id"
          :use-alt-colour="useAltColour"
        />
        <SectionCollapsedHeader
          v-if="viewRoleAreaPermission"
          id="sectionRoleAreas"
          :title="rolesAreasTitle"
          type="subHeader"
          icon-file="People"
          controls="roleAreas"
        />
        <RoleAreas
          id="roleAreas"
          :roleid="selectedRole.id"
          :use-alt-colour="useAltColour"
        />
      </div>
    </div>
  </div>
</template>
<script>
import RoleMembers from './roles/roleMembers'
import RoleDetails from './roles/roleDetails'
import RolePermissions from './roles/rolePermissions'
import RoleNew from './roles/roleNew'
import RoleAreas from './roles/roleAreas'
import RoleNewArea from './roles/roleNewArea'
import SectionCollapsedHeader from '../../components/common/sectionCollapsedHeader'
import tableHandler from '../../mixins/tableHandler'
import { permissionsMixin } from '../../mixins/permissions'
import { mapActions } from 'vuex'
import TextButton from '../common/textButton'
export default {
  name: 'RolesPage',
  components: {
    SectionCollapsedHeader,
    RoleMembers,
    RoleDetails,
    RoleAreas,
    RoleNew,
    RoleNewArea,
    RolePermissions,
    TextButton
  },
  mixins: [
    permissionsMixin,
    tableHandler
  ],
  props: {
    username: {
      type: String,
      default: ''
    },
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      selectedRole: null,
      selected: null
    }
  },
  computed: {
    roles () {
      return this.$store.getters.roles
    },
    rolesMembersTitle () {
      return 'Members of ' + this.selectedRole.name
    },
    rolesDetailsTitle () {
      return 'Details for ' + this.selectedRole.name
    },
    rolesPermissionsTitle () {
      return 'Permissions for ' + this.selectedRole.name
    },
    rolesAreasTitle () {
      return 'Sites for ' + this.selectedRole.name
    },
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    reloadRoles () {
      return this.$store.getters.reloadRoles
    },
    newRole () {
      return require(`../../assets/icons/new.svg`)
    },
    viewEntityRolePermission () {
      return this.$store.getters.viewEntityRolePermission
    },
    viewRoleAreaPermission () {
      return this.$store.getters.viewRoleAreaPermission
    },
    viewRolePermission () {
      return this.$store.getters.viewRoleAreaPermission
    },
    viewAssignedPermissionPermission () {
      return this.$store.getters.viewAssignedPermissionPermission
    },
    createRolePermission () {
      return this.$store.getters.createRolePermission
    },
    updateRolePermission () {
      return this.$store.getters.updateRolePermission
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    userid () {
      this.setComponent()
    },
    reloadRoles (val) {
      if (val) {
        this.setComponent()
        this.setReloadRoles(false)
      }
    },
    selectedRole () {
      if (this.selectedRole) {
        this.getRoleMembers(this.selectedRole.id)
      }
    },
    roles () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setMainForm',
      'setReloadRoles',
      'setRoleMembers'
    ]),
    setComponent () {
      this.setMainForm(false)
      this.getRoles()
    },
    openNewRole () {
      this.setMainForm(true)
    },
    selectRole (selectedRow) {
      this.selectedRole = selectedRow
      if (this.selected === selectedRow.id) {
        this.selected = '-1'
        this.selectedRole = null
      } else {
        this.selected = selectedRow.id
      }
    },
    testSelectRole (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectRole(selectedRow)
      }
    },
    getRoles () {
      this.initialiseTable(
        'rolesTable',
        this.roles,
        ['name', 'description'],
        ['Role Name', 'Description'],
        ['', ''],
        '600px',
        null
      )
    },
    getRowColour (id) {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      if (this.selectSite === id) {
        rtn = 'itemSelected'
      }
      return rtn
    }
  }
}

</script>
<style scoped>
  .mdSelection {
    display:inline-block;
    width: 390px;
    vertical-align: top;
    margin-left: 10px;
  }
  .mdRoleManagement{
    display:inline-block;
    margin: 0;
    width:100%;
  }
  .mdRoleManagement.mdHalfScreen{
    width:calc(100% - 400px);
  }
  .securityHolder {
    width:100%;
  }
  .appSectionBody{
    margin-top:10px;
    background: white;
    padding: 0px 15px 15px 15px;
  }
  .pgContainer {
    height: calc(100vh - 280px);
  }
  .imgNewRole {
    width:35px;
    height:35px;
  }
</style>
