<template>
  <div class="designPackSummaryContainer quotePanel">
    <div>
      <h3>Equinix Cloud Connect</h3>
      <CircuitsEquinixCloudConnectTableEditor
        @tableUpdated="onCircuitsEquinixCloudConnectTableUpdated"
      />
      <br>
      <h3>Hosting and Cabling</h3>
      <CircuitsHostingAndCablingTableEditor
        @tableUpdated="onCircuitsHostingAndCablingTableUpdated"
      />
      <br>
      <div class="wanConnectivityBroadbandBox">
        <TabStrip
          :tabs="wanConnectivityBroadbandTabs"
          :selected-tab="circuitsComponent.wanConnectivityBroadbandCurrentTab"
          :has-line="false"
          @tabChanged="handleWanConnectivityBroadbandTabChanged"/>
        <div
          v-if="circuitsComponent.wanConnectivityBroadbandCurrentTab === 'WANConnectivityBroadband'">
          <h3>WAN Connectivity</h3>
          <CircuitsWanConnectivityTableEditor
            @tableUpdated="onCircuitsWanConnectivityTableUpdated"
          />
          <h3>Broadband</h3>
          <CircuitsBroadbandTableEditor
            @tableUpdated="onCircuitsBroadbandTableUpdated"
          />
        </div>
        <div
          v-if="circuitsComponent.wanConnectivityBroadbandCurrentTab === 'UploadSheet'"
          class="uploadSheetBox">
          <input
            v-if="!isReadOnly"
            id="btnDownloadCircuitsTemplate"
            value="Download Circuits Template"
            type="button"
            @click="downloadCircuitsTemplate()"
          >
          <br><br>
          <input
            v-if="!isReadOnly"
            id="btnUploadCircuit"
            value="Upload Circuits Spreadsheet"
            type="button"
            @click="uploadCircuitsSpreadsheetButtonClicked('Upload Design Pack Circuits Spreadsheet', 0)"
          >
        </div>
      </div>
      <br>
      <h3>Fixed Line Voice</h3>
      <CircuitsFixedLineVoiceTableEditor
        @tableUpdated="onCircuitsFixedLineVoiceTableUpdated"
      />
    </div>
    <p />
    <FileUploadDialogBox
      :dialog="circuitsComponent.showFileUploadDialog"
      :dlg-message="circuitsComponent.fileUploadDialogMessage"
      :dlg-close-from-top="false"
      :file-upload-catalog-id="circuitsComponent.selectedFileUploadCatalogId"
      :checking-file="circuitsComponent.fileDialogCheckingFile"
      :selected-file-error="circuitsComponent.fileDialogSelectedFileError"
      :save-file="false"
      :show-title="false"
      :warning-message="'Warning:<br>This will remove any existing WAN and Broadband table entries'"
      :handle-after-upload="true"
      dlgid="fileUploadDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      @afterUploadAction="afterFileUploaded"
      @cancelAction="circuitsComponent.showFileUploadDialog = false"
      @fileChosenAction="fileChosen"
    />
    <ConfirmationDialogBox
      :dialog="circuitsComponent.showConfirmationDialogBox"
      :dlg-message="`This will remove any existing Circuits WAN and Broadband table entries.<br>Do you wish to continue?`"
      :dlg-close-from-top="false"
      :hide-buttons-after-press="false"
      dlgid="uploadCircuits"
      dlg-icon="mdi-alert-circle-outline"
      negative-btn-text="No"
      positive-btn-text="Yes"
      @negativeAction="cancelUpload()"
      @cancelDialog="cancelUpload()"
      @positiveAction="confirmUpload()"
    />
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import DownloadButton from '@/components/common/downloadButton'
import CircuitsEquinixCloudConnectTableEditor from '../designPackComponents/circuits/circuitsEquinixCloudConnectTableEditor'
import CircuitsWanConnectivityTableEditor from '../designPackComponents/circuits/circuitsWanConnectivityTableEditor'
import CircuitsHostingAndCablingTableEditor from '../designPackComponents/circuits/circuitsHostingAndCablingTableEditor'
import CircuitsBroadbandTableEditor from '../designPackComponents/circuits/circuitsBroadbandTableEditor'
import CircuitsFixedLineVoiceTableEditor from '../designPackComponents/circuits/circuitsFixedLineVoiceTableEditor'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackCircuitsMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackCircuits'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { iQuoteCalculationsDesignPackExportMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackExport'
import TabStrip from '../../common/tabStrip'
import ConfirmationDialogBox from '../../common/confirmationDialogBox'
import FileUploadDialogBox from '../../common/fileUploadDialogBox'
import { DataEventBus } from '../../../events/dataEvents'

export default {
  name: 'Circuits',
  components: {
    CircuitsEquinixCloudConnectTableEditor,
    CircuitsWanConnectivityTableEditor,
    CircuitsHostingAndCablingTableEditor,
    CircuitsBroadbandTableEditor,
    CircuitsFixedLineVoiceTableEditor,
    DownloadButton,
    TabStrip,
    FileUploadDialogBox,
    ConfirmationDialogBox
  },
  mixins: [
    iQuoteStoreMixin,
    iQuoteConstants,
    iQuoteFunctions,
    iQuoteCalculationsDesignPackCircuitsMixin,
    iQuoteExportsAPIMixin,
    iQuoteCalculationsDesignPackExportMixin
  ],
  data () {
    return {
      circuitsComponent: {
        wanConnectivityBroadbandCurrentTab: 'WANConnectivityBroadband',
        wanConnectivityEntries: [],
        fileUploadDialogMessage: '',
        fileDialogSelectedFileError: '',
        showFileUploadDialog: false,
        FileUploadCatalogId: 0,
        selectedFileUploadCatalogId: 0,
        fileDialogCheckingFile: false,
        fileName: '',
        showConfirmationDialogBox: false,
        uploadedFile: null
      }
    }
  },
  computed: {
    wanConnectivityBroadbandTabs () {
      var tabItems = [
        { name: 'WANConnectivityBroadband', text: 'WAN Connectivity/Broadband' }
      ]
      if (!this.isReadOnly) {
        tabItems.push({ name: 'UploadSheet', text: 'Upload sheet' })
      }

      return tabItems
    },
    isReadOnly () {
      return this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.isReadOnly
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    onCircuitsEquinixCloudConnectTableUpdated (newEntries) {
      this.iQuoteCalculationsDesignPackCircuitsMixin_updateEquinixCloudConnectEntries(newEntries)
    },
    async onCircuitsWanConnectivityTableUpdated (newEntries) {
      await this.iQuoteCalculationsDesignPackCircuitsMixin_updateWanConnectivityEntries(newEntries)
    },
    onCircuitsHostingAndCablingTableUpdated (newEntries) {
      this.iQuoteCalculationsDesignPackCircuitsMixin_updateHostingAndCablingEntries(newEntries)
    },
    async onCircuitsBroadbandTableUpdated (newEntries) {
      await this.iQuoteCalculationsDesignPackCircuitsMixin_updateBroadbandEntries(newEntries)
    },
    onCircuitsFixedLineVoiceTableUpdated (newEntries) {
      this.iQuoteCalculationsDesignPackCircuitsMixin_updateFixedLineVoiceEntries(newEntries)
    },
    async downloadCircuitsAutotaskImportSpreadsheet () {
      return this.iQuoteExportsAPIMixin_getDesignPackCircuitsAutotaskImportSpreadsheet(this.iQuoteCalculationsDesignPackExportMixin_getDesignPackForExport())
    },
    handleWanConnectivityBroadbandTabChanged (newTab) {
      this.circuitsComponent.wanConnectivityBroadbandCurrentTab = newTab.name === 'WANConnectivityBroadband' ? 'WANConnectivityBroadband' : 'UploadSheet'
    },
    downloadCircuitsTemplate: async function () {
      var data = await this.iQuoteExportsAPIMixin_getDesignPackCircuitsTemplate()
      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
      a.download = 'Circuits Template.xlsx'
      document.body.appendChild(a)
      a.click()
    },
    uploadCircuitsSpreadsheetButtonClicked: async function (message, fileUploadCatalogId) {
      DataEventBus.$emit('resetDialog')
      this.circuitsComponent.fileUploadDialogMessage = message
      this.circuitsComponent.fileDialogSelectedFileError = ''
      this.circuitsComponent.showFileUploadDialog = true
      this.circuitsComponent.selectedFileUploadCatalogId = fileUploadCatalogId
    },
    async fileChosen (newFile) {
      this.circuitsComponent.fileDialogCheckingFile = true
      this.circuitsComponent.fileDialogSelectedFileError = ''
      var fileExtension = newFile.name.split('.').pop().toLowerCase()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xls' && fileExtension !== 'xltm' && fileExtension !== 'xlsm') {
        this.circuitsComponent.fileDialogSelectedFileError = 'Only Excel Spreadsheets can be uploaded'
      } else {
        var data = await this.iQuoteExportsAPIMixin_getDesignPackCircuitsDataFromExcel(newFile.contents)

        if (data.error === undefined) {
          this.circuitsComponent.fileDialogSelectedFileError = 'Error contacting file export service.  Cannot upload.  Please contact your administrator for assistance'
        } else if (data.error !== '') {
          this.circuitsComponent.fileDialogSelectedFileError = data.error
        } else if (data.entries.length === 0) {
          this.circuitsComponent.fileDialogSelectedFileError = 'No Circuits WAN Connectivity/Broadband circuits Entries found on this spreadsheet'
        }
      }

      this.circuitsComponent.fileDialogCheckingFile = false
    },
    cancelUpload () {
      this.circuitsComponent.showConfirmationDialogBox = false
      DataEventBus.$emit('showUploadProgress', false)
    },
    async confirmUpload () {
      this.circuitsComponent.showConfirmationDialogBox = false
      DataEventBus.$emit('showUploadProgress', true)

      await this.setValuesFromSpreadsheet(this.circuitsComponent.uploadedFile)
    },
    async afterFileUploaded (newFile) {
      this.circuitsComponent.fileName = newFile.name
      this.circuitsComponent.uploadedFile = newFile
      this.circuitsComponent.showConfirmationDialogBox = true
    },
    setValuesFromSpreadsheet: async function (newFile) {
      var designPackCircuitsData = await this.iQuoteExportsAPIMixin_getDesignPackCircuitsDataFromExcel(newFile.contents)
      var designPack = this.iQuoteStore_GetDesignPack

      designPack.circuitsWanConnectivityEntries = designPackCircuitsData.entries.WanConnectivityEntries
      designPack.circuitsBroadbandEntries = designPackCircuitsData.entries.BroadbandEntries

      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack)

      DataEventBus.$emit('afterUploadCompleted')

      this.circuitsComponent.wanConnectivityBroadbandCurrentTab = 'WANConnectivityBroadband'
    }
  }
}
</script>
<style scoped>
  td.cellContainsValue {
    font-weight: bold;
  }
  .wanConnectivityBroadbandBox {
    border: 1px solid #808080;
    padding: 8px;
    margin-bottom: 0;
  }
  .uploadSheetBox {
    height: 425px;
    padding-top: 50px;
    margin-left: 20px;
  }

  table tbody tr td {
    font-size: 1.1em;
  }
</style>
