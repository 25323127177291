<template>
  <div class="fpwContainer">
    <div class="fpwInformation fpwMinRow">
      <div class="fpwInformationText">
        Enter your username (likely to be an e-mail address) then click Reset Password.<br><br>An email will then be sent to your registered email address with a reset password link.
      </div>
    </div>
    <div class="fpwUsername fpwTextBox fpwMinRow">
      <PortalTextBox
        :item-value="resetUsername"
        :item-error="error"
        text-id="txtResetPasswordUsername"
        item-type="uid"
        item-place-holder="Email Address / Username"
        @EditValue="updateResetUsername($event)"
        @EnterClicked="resetPassword()" />
    </div>
    <div class="fpwButtons fpwItem fpwMinRow">
      <button
        id="btnSendEmail"
        :style="loginBackgroundColour"
        class="loginButton"
        title="Reset Password"
        @click="resetPassword()"
      >
        <p>SEND EMAIL</p>
      </button>
    </div>
  </div>
</template>
<script>
import PortalTextBox from '../common/portalTextBox'
import { usersMixin } from '../../mixins/users'
import axios from '../../communications/interceptors/authInterceptors'
import { setTimeout, clearTimeout } from 'timers'
import { mapActions } from 'vuex'
export default {
  components: {
    PortalTextBox
  },
  mixins: [
    usersMixin
  ],
  data () {
    return {
      resetUsername: '',
      error: false,
      timeoutSet: null
    }
  },
  computed: {
    loginBackgroundColour () {
      return `background-color: ${this.$store.getters.loginButtonColour}`
    }
  },
  methods: {
    ...mapActions([
      'clearAuth'
    ]),
    cancel () {
      this.resetForm()
      this.$emit('CancelRequest')
    },
    resetPassword () {
      this.error = false
      // A username opf less than 5 chars is obviously invalid so reject without a call
      if (this.resetUsername.length < 5) {
        this.$store.commit('showMessage', { content: 'Please enter a valid email address', color: 'red', timeout: 3000 })
        this.error = true
        if (this.timeoutSet === null) {
          clearTimeout(this.timeoutSet)
        }
        this.timeoutSet = setTimeout(() => this.resetForm(), 5000)
        return
      }
      this.sendResetPassword()
    },
    sendResetPassword: async function () {
      this.$store.commit('showMessage', { content: 'Sending email - please wait...', color: 'blue', timeout: 2000 })
      var url = window.location.origin + '/'
      await axios.post('/ForgottenPassword', {
        SiteURL: url,
        Username: this.resetUsername,
        Type: 'reset'
      }).then(() => {
        // The commented out code below sets the user state to disabled. This is taken out as it means anyone can disable any other users account.
        // axios.post('/users/disable', { Username: this.resetUsername })
        // this.updateNewUser({ Username: this.resetUsername, Locked: false, Disabled: true, Deleted: false })
        this.resetUsername = ''
        this.$store.commit('showMessage', { content: 'An email has been sent to your associated account', color: 'green', timeout: 3000 })
      }).catch((error) => {
      // A 403 is a forbidden response in this instance the user's account would have been locked or disabled
        if (error.response && error.response.status && error.response.status === 403) {
          this.$store.commit('showMessage', { content: 'You account appears to be locked or disabled', color: 'red', timeout: 3000 })
        } else {
          this.$store.commit('showMessage', { content: 'An error has occured, please try again later', color: 'red', timeout: 3000 })
        }
        this.resetUsernameError = true
      }).finally(() => {
        // Clear out any authentication details just to ensure nothing is left in the system
        this.clearAuth()
      })
    },
    resetForm () {
      this.error = false
      this.resetUsername = ''
    },
    updateResetUsername (value) {
      clearTimeout(this.timeoutSet)
      this.error = false
      this.resetUsername = value
    }
  }
}
</script>

<style>
  .fpwInformationText {
    color: #333;
    text-align: left;
    margin: 0 0 20px 7px;
  }
</style>
