<template>
  <div class="quotePanelContainer">
    <div class="quotePanel" >
      <label for="txtiQuoteReference">iQuote</label>
      <img
        :src="iconContact"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <p>Select required Contact Centre solution</p>
      <br>
      <TechnologyTile
        v-for="t in technologies.filter(x => x.id !== iQuoteConstants.technologies.callmedia || iQuoteStore_GetQuoteTechnologyCallmedia.id !== undefined)"
        :technology-id="t.id"
        :technology-name="t.technologyName"
        :key="t.id"
      />
      <br><br>
      <input
        id="btnBack"
        value="< Back"
        type="button"
        @click="gotoPage('SelectService')"
      >
    </div>
  </div>
</template>

<script>
import { ShowHelpMixin } from '../../../../mixins/showHelpMixin'
import { iQuoteTechnologyAPIMixin } from '../../../../mixins/iQuote/iQuoteTechnologyAPIMixin'
import { iQuoteConstants } from '../../../../mixins/iQuote/iQuoteConstants'
import { iQuoteStoreMixin } from '../../../../mixins/iQuote/iQuoteStoreMixin'
import { mapActions } from 'vuex'
import TechnologyTile from '../../tiles/iQuoteTechnologyTile'
export default {
  name: 'IconContact',
  components: {
    TechnologyTile
  },
  mixins: [ShowHelpMixin, iQuoteTechnologyAPIMixin, iQuoteConstants, iQuoteStoreMixin],
  data () {
    return {
      technologies: [],
      quote: {}
    }
  },
  computed: {
    question () {
      return require(`../../../../assets/icons/question.png`)
    },
    iconCommunicate () {
      return require(`../../../../assets/icons/iconCommunicate.png`)
    },
    iconContact () {
      return require(`../../../../assets/icons/iconContact.png`)
    },
    gammaSIP () {
      return require(`../../../../assets/icons/gammaSIP.png`)
    },
    toiQuotePrevious () {
      return require(`../../../../assets/icons/toiQuotePrev.png`)
    }
  },
  mounted () {
    this.quote = this.$store.getters.quote
    this.setService()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),
    gotoPage (page) {
      this.setCurrentQuoteState(page)
    },
    setService: async function () {
      this.quote = this.$store.getters.quote
      this.technologies = await this.getTechnologiesByServiceId(this.$store.getters.currentService)
    }
  }
}
</script>
<style scoped>
</style>
