<template>
  <div class="tmtContainer">
    <div class="tmtVerticalList">
      <div class="tmtTitleBar">
        <div class="tmtHeader tmtBar">
          <h3 class="appDarkFont"><u>Open Tickets</u></h3>
        </div>
      </div>
      <div class="tmtHeader tmtBar">
        <div class="tmtItem tmtHeader tmtPriority">
          Priority
        </div>
        <div class="tmtItem tmtHeader tmtNumber">
          Within SLA
        </div>
        <div class="tmtItem tmtHeader tmtNumber">
          Outside SLA
        </div>
      </div>
      <div
        v-for="ticketInfo in ticketList"
        :key="ticketInfo.priority"
        class="tmtBody tmtBar"
      >
        <div
          :style="{borderBottom:`solid 2px ${ticketInfo.underlineColour}`}"
          class="tmtItem tmtBody tmtPriority"
        >
          &nbsp;&nbsp;{{ ticketInfo.priority }}
        </div>
        <div
          :class="{ tmtNoValue:ticketInfo.noWithinSLA === 0 }"
          class="tmtItem tmtBody tmtNumber tmtInsideSLA"
        >
          {{ ticketInfo.noWithinSLA }}
        </div>
        <div
          :class="{ tmtNoValue:ticketInfo.noOutsideSLA === 0 }"
          class="tmtItem tmtBody tmtNumber tmtOutsideSLA"
        >
          {{ ticketInfo.noOutsideSLA }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      ticketList: [
        { priority: 'P1', underlineColour: '#c0392b', noWithinSLA: 1, noOutsideSLA: 0 },
        { priority: 'P2', underlineColour: '#e67e22', noWithinSLA: 0, noOutsideSLA: 1 },
        { priority: 'P3', underlineColour: '#f1c40f', noWithinSLA: 15, noOutsideSLA: 0 },
        { priority: 'P4', underlineColour: '#27ae60', noWithinSLA: 12, noOutsideSLA: 1 }
      ]
    }
  }
}
</script>

<style>
  .tmtItem{
    margin:2px;
  }
  .tmtContainer{
    display:flex;
    flex-direction: column;
    padding:15px 0 5px 0;
  }
  .tmtTitleBar{
    display:flex;
    flex-direction: row;
  }
  .tmtBar{
    display:flex;
    flex-direction: row;
    padding:5px;
  }
  .tmtPriority{
    width:20%;
    font-weight:600;
  }
  .tmtNumber{
    width:40%;
    text-align:center;
  }
  .tmtHeader{
    font-weight:600;
  }
  .tmtOutsideSLA{
    background-color:rgba(192, 57, 43,0.2);
    border:2px rgba(192, 57, 43,0.8) solid;
  }
  .tmtInsideSLA{
    background-color:rgba(39, 174, 96,0.2);
    border:2px rgba(39, 174, 96,0.8) solid;
  }
  .tmtNoValue{
    opacity: 0.3;
  }
</style>
