<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconTeamsConnector"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="teamsConnectorComponent.quote.quoteOpportunityId + ' (revision ' + teamsConnectorComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>Teams Connector</h3>
      <br>
      <label
        :class="{InputError:!isValidNumTeamsUsers}"
        for="numNumTeamsUsers"
      >Number of Teams Users<TooltipIcon
        text="Number of Teams Users end users" />
      </label>
      <input
        id="numNumTeamsUsers"
        v-model.number="teamsConnectorComponent.teamsConnector.numTeamsUsers"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidNumPBXUsers}"
        for="numPBXUsers"
      >Number of PBX Users<TooltipIcon
        text="Number of PBX End Users required to integrate with" />
      </label>
      <input
        id="numPBXUsers"
        v-model.number="teamsConnectorComponent.teamsConnector.numPBXUsers"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        for="numsipChannelsCalculated"
      >Trunk Ratio<TooltipIcon
        text="Number of users per SIP channel" />
      </label>
      <select
        id="cboUsersPerSipChannel"
        v-model.number="teamsConnectorComponent.teamsConnector.usersPerSipChannel"
      >
        <option
          v-for="item in teamsConnectorComponent.usersPerSipChannelOptions"
          :key="item.val"
          :value="item.val"
          :title="item.val"
        >
          {{ item.val }}
        </option>
      </select>
      <label
        for="numsipChannelsCalculated"
      >Total Number of SIP Channels
      </label>
      <input
        id="numsipChannelsCalculated"
        v-model.number="sipChannelsCalculated"
        class="small"
        type="number"
        min="0"
        max="100000"
        readonly
      >
      <p class="InputError">If SIP Services are ordered with Teams Connector, please ensure the correct number of SIP channels have been configured</p>
      <label
        :class="{InputError:!isValidAdditionalSbcLicencesForInternalCalls}"
        for="numAdditionalSbcLicencesForInternalCalls"
      >Number of SBC Channels for Internal Calls
      </label>
      <input
        id="numAdditionalSbcLicencesForInternalCalls"
        v-model.number="teamsConnectorComponent.teamsConnector.additionalSbcLicencesForInternalCalls"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br><br>
      <label
        :class="{InputError:!isValidNumAdditionalSIPChannels}"
        for="numAdditionalSIPChannels"
      >Number of Additional SIP Channels
      </label>
      <input
        id="numAdditionalSIPChannels"
        v-model.number="teamsConnectorComponent.teamsConnector.numAdditionalSIPChannels"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br><br>
      <label
        class=""
        for="cboSubscriptionBillingPeriod"
      >Subscription Billing Period</label>
      <select
        id="cboSubscriptionBillingPeriod"
        v-model.number="teamsConnectorComponent.teamsConnector.subscriptionBillingPeriodId"
      >
        <option
          key="1"
          value="1"
          title="Annual"
        >Annual</option>
        <option
          key="2"
          value="2"
          title="Monthly"
        >Monthly</option>
      </select>
      <label
        class=""
        for="cboNumberPorting"
      >Number Porting
      </label>
      <select
        id="cboNumberPorting"
        v-model.number="teamsConnectorComponent.teamsConnector.teamsConnectorNumberPortingOptionId"
        @change="setNumDDIs($event)"
      >
        <option
          key="1"
          value="1"
          title="Number Porting"
        >Number Porting</option>
        <option
          key="2"
          value="2"
          title="New DDI"
        >New DDI</option>
      </select>
      <label
        v-if="teamsConnectorComponent.teamsConnector.teamsConnectorNumberPortingOptionId === 2"
        :class="{InputError:!isValidNumNewDDIs}"
        for="numNewDDI"
      >New DDI<TooltipIcon
        text="If you require new DDI numbers please state how many you need" />
      </label>
      <input
        v-if="teamsConnectorComponent.teamsConnector.teamsConnectorNumberPortingOptionId === 2"
        id="numNewDDI"
        v-model.number="teamsConnectorComponent.teamsConnector.newDDIs"
        class="small"
        type="number"
        min="0"
        max="100000"
      >

      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('TeamsConnectorRecordings')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'TeamsConnector',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin],
  data () {
    return {
      teamsConnectorComponent: {
        quote: {},
        teamsConnector: {
          id: 0,
          numTeamsUsers: 0,
          numPBXUsers: 0,
          sipChannelsCalculated: 0,
          additionalSbcLicencesForInternalCalls: 0,
          subscriptionBillingPeriodId: 1 /* 1 = annual */,
          attendantConsoleSubscriptionBillingPeriodId: 1 /* 1 = annual */,
          teamsConnectorNumberPortingOptionId: 1, // 1 = Number Porting, 2 = New DDI
          usersPerSipChannel: 5,
          numAttendantConsoles: 0,
          numAdditionalSIPChannels: 0
        },
        usersPerSipChannelOptions: [{ val: 3 }, { val: 5 }, { val: 7 }, { val: 10 }]
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconTeamsConnector () {
      return require(`../../../assets/icons/teamsConnector.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidNumPBXUsers () {
      var value = this.iQuoteStore_GetTeamsConnector.numPBXUsers
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumTeamsUsers () {
      var value = this.iQuoteStore_GetTeamsConnector.numTeamsUsers
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidAdditionalSbcLicencesForInternalCalls () {
      var value = this.iQuoteStore_GetTeamsConnector.additionalSbcLicencesForInternalCalls
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumAdditionalSIPChannels () {
      var value = this.iQuoteStore_GetTeamsConnector.numAdditionalSIPChannels
      return (this.iQuoteFunctions_IsInteger(value))
    },
    sipChannelsCalculated () {
      return Math.ceil((this.teamsConnectorComponent.teamsConnector.numTeamsUsers + this.teamsConnectorComponent.teamsConnector.numPBXUsers) / this.teamsConnectorComponent.teamsConnector.usersPerSipChannel)
    },
    isValidNumNewDDIs () {
      var value = this.teamsConnectorComponent.teamsConnector.newDDIs
      return (this.iQuoteFunctions_IsInteger(value))
    }
  },
  async mounted () {
    this.teamsConnectorComponent.quote = this.$store.getters.quote
    this.teamsConnectorComponent.teamsConnector = this.iQuoteStore_GetTeamsConnector

    if (this.iQuoteStore_GetTeamsConnector.id === undefined) {
      var teamsConnectorFromDb = await this.getQuoteTeamsConnectorProperties(this.teamsConnectorComponent.quote.id)

      if (teamsConnectorFromDb.teamsConnectorNumberPortingOptionId === undefined) {
        // Not in Db
        this.teamsConnectorComponent.teamsConnector = {
          /* PRIMARY SETTINGS */
          id: 0,
          quoteId: this.teamsConnectorComponent.quote.id,
          numTeamsUsers: 0,
          numPBXUsers: 0,
          sipChannelsCalculated: 0,
          additionalSbcLicencesForInternalCalls: 0,
          subscriptionBillingPeriodId: this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL,
          teamsConnectorNumberPortingOptionId: 1, // 1 = Number Porting, 2 = New DDI
          newDDIs: 0,
          usersPerSipChannel: 5,
          numAdditionalSIPChannels: 0,

          /* RECORDINGS SETTINGS */
          numRecordingChannels: 0,
          numRecordingTranscriptionPackages: 0,
          numRecordingTranslationPackages: 0,
          numRecordingStorageUnits: 0,
          numRecordingArchiveStorageUnits: 0,
          teamsConnectorRecordingTypeId: this.iQuoteConstants.teamsConnectorRecordingTypes.VOICE,
          teamsConnectorRecordingRedundencyOptionId: this.iQuoteConstants.teamsConnectorRecordingRedundencyOptions.LOCAL,
          recordingComplianceRequired: false,
          recordingAnalyticsRequired: false,
          numRecordingExportPackages: 0,
          teamsConnectorRecordingExportTypeId: this.iQuoteConstants.teamsConnectorRecordingExportTypes.PUBLIC,

          /* ATTENDANT CONSOLE SETTINGS */
          attendantConsoleSubscriptionBillingPeriodId: this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL,
          numAttendantConsoles: 0
        }
      } else {
        this.teamsConnectorComponent.teamsConnector = teamsConnectorFromDb
      }

      this.setQuoteTeamsConnector(this.teamsConnectorComponent.teamsConnector)

      this.teamsConnectorComponent.teamsConnector = this.iQuoteStore_GetTeamsConnector
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTeamsConnector'
    ]),
    async gotoPage (page) {
      var technologyId = await this.getQuoteTechnologyId(this.teamsConnectorComponent.quote.id, this.iQuoteConstants.technologies.teamsConnector)

      if (page === 'SelectService') {
        if (this.iQuoteStore_GetTeamsConnector.sipChannelsCalculated === 0 && technologyId >= 1) {
        // TeamsConnector not required so delete from DB
          this.deleteQuoteTechnology(technologyId)
          // Clear form details from store
          this.setQuoteTeamsConnector({})
        } else {
          // Going back, so reset to what it was in db
          var teamsConnectorFromDb = await this.getQuoteTeamsConnectorProperties(this.teamsConnectorComponent.quote.id)
          this.setQuoteTeamsConnector(teamsConnectorFromDb)
        }
        this.setCurrentQuoteState(page)
      } else {
        // Going to second teams page
        if (this.teamsConnectorComponent_validatePage()) {
        // Save to DB only if valid data is supplied
          if (this.teamsConnectorComponent.teamsConnector.id === 0) {
            // eslint-disable-next-line
            var techId = await this.addQuoteTechnologyId(this.teamsConnectorComponent.quote.id, this.iQuoteConstants.technologies.teamsConnector)
          }

          this.teamsConnectorComponent.teamsConnector.sipChannelsCalculated = this.sipChannelsCalculated

          this.teamsConnectorComponent.teamsConnector = await this.saveQuoteTeamsConnectorProperties(this.teamsConnectorComponent.teamsConnector)

          this.setQuoteTeamsConnector(this.teamsConnectorComponent.teamsConnector)
          this.setCurrentQuoteState(page)
        }
      }
    },
    setNumDDIs (event) {
      var option = event.target.value
      if (option === '2' /* New DDI */) {
        this.teamsConnectorComponent.teamsConnector.newDDIs = this.sipChannelsCalculated
      } else {
        this.teamsConnectorComponent.teamsConnector.newDDIs = 0
      }
    },
    teamsConnectorComponent_validatePage () {
      var ValidationFailed = false
      if (!this.isValidNumTeamsUsers ||
          !this.isValidNumPBXUsers ||
          !this.isValidNumAdditionalSIPChannels ||
          !this.isValidAdditionalSbcLicencesForInternalCalls) {
        ValidationFailed = true
      }

      if (!ValidationFailed) {
        return true
      } else {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return false
      }
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 620px}
  .quotePanel label {width:320px;}
  .small {width:60px;}
  .hidden {visibility: hidden;}

  #cboUsersPerSipChannel {
    width: 50px
  }
</style>
