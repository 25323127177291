<template>
  <div>
    <div
      v-if="false"
      class="noResults"
    >
      Sorry, you do not have permission to assign roles to users
    </div>
    <div
      id="createUserRolesSectionContainer"
      class="createUserRolesSectionContainer"
    >
      <h3
        id="RolesSectionHeader"
        :class="[showRolesSection ? 'expanded' : '']"
        @click="() => { showRolesSection = !showRolesSection }"
      >
        3. Roles
        <span class="statuscontainer">
          <span
            :class="[sectionComplete ? 'completeSection' : 'incompleteSection']"
            :title="[sectionComplete ? 'Section Complete' : 'Section Incomplete']"
          />
          <span class="background" />
        </span>
        <span
          :class="[showRolesSection ? 'minimiseSection' : 'maximiseSection']"
        />
      </h3>
      <transition name="slide">
        <div
          v-if="showRolesSection"
          class="createUserRolesContainer"
        >
          <div class="searchContainer">
            <label
              for="userRolesFilter"
            >
              Search:
            </label>
            <input
              id="userRolesFilter"
              v-model="filterText"
              class="searchFilter"
              type="text"
            >
          </div>
          <div
            :class="[hasSelectedRole ? 'createUserRolesLeft' : '']"
          >
            <Spinner
              v-if="loading"
              id="createUserRoleSpinner"
            />
            <table
              v-else
              id="rolesTable"
            >
              <thead>
                <tr>
                  <th class="roleNameHeader">
                    Role Name
                    <div
                      v-if="roles.length"
                      style="float:right"
                    >
                      Assign all Roles:
                    </div>
                  </th>
                  <th class="toggleSwitchHeader">
                    <TriStateToggleSwitch
                      v-if="roles.length"
                      :enabled="true"
                      :current-state="2"
                      :tri-state="false"
                      class="tableToggleSwitch rolesTableToggleSwitch"
                      @statechanged="({ selectedState }) => {
                        addAllRoles(selectedState)
                      }"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="({ id, name }) in roles"
                  :key="id"
                >
                  <td>{{ name }} </td>
                  <td style="padding: 6px 0 6px 0;">
                    <TriStateToggleSwitch
                      v-if="!allRolesSelected"
                      :enabled="true"
                      :current-state="2"
                      :role-id="id"
                      :tri-state="false"
                      class="tableToggleSwitch"
                      @statechanged="addRole"
                    />
                  </td>
                </tr>
                <tr
                  v-if="!roles.length"
                >
                  <td
                    colspan="2"
                    class="noResults noRoles"
                  >
                    No Roles Available
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-show="hasSelectedRole"
            class="createUserRolesRight"
          >
            <Spinner
              v-if="loadingEffectivePermissions"
            />
            <table
              v-else
              id="effectivePermissionsTable"
            >
              <thead>
                <tr>
                  <th style="width: 45%" > Permission </th>
                  <th style="width: 5%" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="({ permissionName, allowed }) in effectivePermissions"
                  :key="permissionName"
                >
                  <td> {{ permissionName }} </td>
                  <td style="padding-right:10px;">
                    <span class="statuscontainer">
                      <span
                        :class="[allowed === 1 ? 'completeSection' : 'incompleteSection']"
                        :title="[allowed === 1 ? 'Allowed' : 'Disabled']"
                      />
                      <span class="background" />
                    </span>
                  </td>
                </tr>
                <tr
                  v-if="effectivePermissions.length === 0"
                >
                  <td
                    colspan="2"
                    class="alertText"
                  > No Permissions to display </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { permissionsMixin } from '../../mixins/permissions'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'
import Spinner from '../common/spinner'
export default {
  name: 'CreateUserRoles',
  components: {
    TriStateToggleSwitch,
    Spinner
  },
  mixins: [ permissionsMixin ],
  props: {
    roleSelected: {
      type: Boolean,
      default: false
    },
    selectedRoles: {
      type: Array,
      default: () => []
    },
    selectedAreas: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showRolesSection: false,
      sectionComplete: false,
      allRolesSelected: false,
      filterText: '',
      rawPermissions: [],
      effectivePermissions: {},
      loadingEffectivePermissions: false,
      roles: [],
      loading: false
    }
  },
  computed: {
    hasSelectedRole () {
      return this.selectedRoles.length > 0
    }
  },
  watch: {
    roleSelected () {
      this.sectionComplete = this.roleSelected
    },
    selectedAreas () {
      this.calculateRoles()
    },
    filterText () {
      this.calculateRoles()
    },
    selectedRoles () {
      this.effectivePermissions = []
      this.rawPermissions = []
      this.loadingEffectivePermissions = true
      var roleIds = this.selectedRoles.map((role) => {
        return role.roleId
      })
      const roleList = { 'roleList': roleIds.join('|') }
      this.getRoleListPermissions(roleList).then((permissions) => {
        if (permissions) {
          permissions.forEach(({ permissionName, allowed }) => {
            if (allowed !== 0) {
              this.rawPermissions.push({ permissionName, allowed })
            }
          })
        }
        // Calculate the effective permissions to display
        this.rawPermissions.map(({ permissionName }) => {
          // If it's already 2 for any of the roles, then it should display disabled, otherwise it is enabled
          var allowed = this.rawPermissions.some(permission => permission.permissionName === permissionName && permission.allowed === 2) ? 2 : 1

          // Is it in the array already?
          if (!this.effectivePermissions.some(permission => permission.permissionName === permissionName)) {
            this.effectivePermissions.push({ allowed, permissionName })
          } else {
            var target = this.effectivePermissions.find(permission => permission.permissionName === permissionName)
            target.allowed = allowed
          }
        })
      })

      this.loadingEffectivePermissions = false
    }
  },
  mounted () {
    this.calculateRoles()
  },
  methods: {
    addRole: function ({ roleId, selectedState }) {
      this.$emit('roleChanged', {
        roleId,
        selectedState
      })
    },
    addAllRoles: function (selectedState) {
      this.allRolesSelected = selectedState === 1
      this.$emit('allRolesSelected', {
        selectedState
      })
    },
    calculateRoles: function () {
      this.loading = true
      this.roles = []
      this.getRoleAreas().then((result) => {
        if (this.selectedAreas.length > 0) {
          this.roles = this.$store.getters.roles
            .filter(role =>
              result
                .filter(({ areaId }) => this.selectedAreas
                  .some(({ areaId: selectedAreaId, parentId: selectedParentId }) => (areaId === selectedAreaId || areaId === selectedParentId)))
                .some(({ roleId }) => roleId === role.id) &&
              role.name.toLowerCase().includes(this.filterText.toLowerCase()))
        }
        if (this.$store.getters.channelCompany.companyId !== this.$store.getters.currentCompany.id) {
          this.roles = this.roles.filter(r => !this.$store.getters.channelRoles.includes(r.id))
        }
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
  .createUserRolesSectionContainer
  {
    width:100%;
    margin-bottom:10px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    border-radius: 5px;
  }
  .createUserRolesContainer{
    background-color: rgba(255,255,255,0.7);
    max-height: 214px;
    overflow-y: auto;
  }

  .slide-enter-active, .slide-leave-active {
    transition: max-height .2s ease-out;
  }

  .slide-enter, .slide-leave-to {
    max-height: 0px;
    overflow:hidden;
  }

  .slide-enter-to, .slide-leave {
    max-height: 300px;
    overflow:hidden;
  }

  h3 {
    cursor: pointer;
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0;
    color: #fff;
    border-radius: 5px;
  }
  .expanded {
    border-radius: 5px 5px 0 0;
  }
  .completeSection {
    background-image: url('../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .incompleteSection {
    background-image: url('../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  span.statuscontainer {
    float: left;
    margin-right: 5px;
  }

  span.statuscontainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }

  .minimiseSection {
    background-image: url('../../assets/icons/up_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .maximiseSection {
    background-image: url('../../assets/icons/down_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .searchFilter {
    width: 90%;
    border-radius: 5px;
  }

  .searchContainer {
    padding: 5px;
    position: sticky;
    height: 40px;
    display: inline-block;
    top: 0px;
    background-color: #fff;
    width: 100%;
    z-index: 1;
  }

  .createUserRolesLeft {
    width: 50%;
    display: inline-block;
    padding: 5px;
  }
  .createUserRolesRight {
    width: 50%;
    float:right;
    padding: 5px;
    overflow-y:auto;
  }

  .roleNameHeader {
    width: 90%;
  }

  .toggleSwitchHeader {
    width: 10%;
  }

  .createUserRolesLeft .roleNameHeader {
    width: 40%
  }

  .effectivePermissionRow {
    display: inline-block;
    width:100%;
  }

  .tableToggleSwitch {
    float: right;
    margin-right: 10px
  }

  .alertText {
    font-weight:bold;
    text-align:center;
  }

</style>
