<template>
  <div class="securityHolder">
    <div class="mdContainer appSectionBody">
      <StormNewUser
        @reload-storm-users="setComponent()"
      />
      <div
        :class="{ mdHalfScreen : !!selectedStormUser }"
        class="mdStormManagement"
      >
        <div
          v-if="stormAdmin"
          class="stormManagementHeader"
        >
          <!-- <div class="userWarning" >{{ userWarning }}</div> -->
          <TextButton
            :disabled="!stormAdmin"
            :button-tabindex="10"
            button-style="margin-bottom: 10px; float: right;"
            button-id="btnNewStorm"
            title="Add a new UC Analytics User"
            text="Add New UC Analytics User"
            @click="openNewStorm"
          />
        </div>
        <table id="stormTable">
          <thead/>
          <tbody>
            <tr
              v-for="v in currentFilter"
              :key="v.id"
              :class="getRowColour(v.id)"
              :tabindex="20"
              @click="selectStormUser(v)"
              @keyup="testSelectStorm($event, v)"
            >
              <td :title="v.forename">{{ v.forename }}</td>
              <td :title="v.surname">{{ v.surname }}</td>
              <td :title="v.email">{{ v.email }}</td>
              <td :title="v.accountName">{{ v.phone }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="!!selectedStormUser"
        class="mdSelection"
      >
        <SectionCollapsedHeader
          id="sectionStormDetails"
          :title="stormDetailsTitle"
          type="subHeader"
          icon-file="Edit"
          controls="stormDetails"
        />
        <StormDetails
          id="stormDetails"
          :storm-user="selectedStormUser"
          :storm-admin="stormAdmin"
        />
        <SectionCollapsedHeader
          id="sectionStormSecurityGroups"
          :title="stormSecurityGroupsTitle"
          type="subHeader"
          icon-file="People"
          controls="stormSecurityGroupsComponent"
        />
        <StormSecurityGroups
          id="stormSecurityGroupsComponent"
          :storm-user-id="selectedStormUser.id"
          :storm-user="selectedStormUser"
          :storm-admin="stormAdmin"
          :max-web-users="maxWebUsers"
          :use-alt-colour="useAltColour"
        />
        <SectionCollapsedHeader
          id="sectionStormUserGroups"
          :title="stormUserGroupsTitle"
          type="subHeader"
          icon-file="People"
          controls="stormUserGroupsComponent"
        />
        <StormUserGroups
          id="stormUserGroupsComponent"
          :storm-user-id="selectedStormUser.id"
          :storm-user="selectedStormUser"
          :storm-admin="stormAdmin"
          :max-web-users="maxWebUsers"
          :use-alt-colour="useAltColour"
        />
        <SectionCollapsedHeader
          v-if="displayDelete"
          id="sectionStormDeleteUser"
          :title="stormDeleteUserTitle"
          type="subHeader"
          icon-file="Cross"
          controls="stormDeleteUserComponent"
        />
        <StormDeleteUser
          v-if="displayDelete"
          id="stormDeleteUserComponent"
          :storm-user-id="selectedStormUser.id"
          :storm-admin="stormAdmin"
          :use-alt-colour="useAltColour"
          @reload-storm-users="setComponent()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StormSecurityGroups from './storm/stormSecurityGroups'
import StormUserGroups from './storm/stormUserGroups'
import StormDetails from './storm/stormDetails'
import StormNewUser from './storm/stormNewUser'
import StormDeleteUser from './storm/stormDeleteUser'
import SectionCollapsedHeader from '../../components/common/sectionCollapsedHeader'
import tableHandler from '../../mixins/tableHandler'
import TextButton from '../common/textButton'
import { stormMixin } from '../../mixins/stormMixin'
import { mapActions } from 'vuex'
import { DataEventBus } from '../../events/dataEvents'
export default {
  name: 'StormsPage',
  components: {
    SectionCollapsedHeader,
    StormSecurityGroups,
    StormUserGroups,
    StormDetails,
    StormNewUser,
    StormDeleteUser,
    TextButton
  },
  mixins: [
    stormMixin,
    tableHandler
  ],
  props: {
    username: {
      type: String,
      default: ''
    },
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      selectedStormUser: null,
      selected: null,
      stormAdmin: false,
      maxWebUsers: 0
    }
  },
  computed: {
    displayDelete () {
      if (this.selectedStormUser) {
        var isAdmin = this.stormAdmin || this.selectedStormUser.email === this.adminEmail
        if (this.selectedStormUser.email === this.adminEmail || this.selectedStormUser.email === this.stormDefaultUser) {
          return false
        } else {
          return isAdmin
        }
      } else {
        return false
      }
    },
    stormUsers () {
      return this.$store.getters.stormUsers
    },
    adminEmail () {
      return process.env.VUE_APP_STORM_ADMIN_USER
    },
    stormDefaultUser () {
      return this.$store.getters.stormDefaultUser
    },
    stormSecurityGroupsTitle () {
      return 'Security groups for ' + this.selectedStormUser.forename + ' ' + this.selectedStormUser.surname
    },
    stormUserGroupsTitle () {
      return 'User groups for ' + this.selectedStormUser.forename + ' ' + this.selectedStormUser.surname
    },
    stormDetailsTitle () {
      return 'Details for ' + this.selectedStormUser.forename + ' ' + this.selectedStormUser.surname
    },
    stormDeleteUserTitle () {
      return 'Delete User ' + this.selectedStormUser.forename + ' ' + this.selectedStormUser.surname
    },
    subscriptionTiers () {
      return this.$store.getters.stormSubscriptionTiers
    },
    maxUserCheck () {
      if (this.$store.getters.stormCompany.subscriptionTierId === 4) {
        return false
      } else {
        var sc = this.subscriptionTiers.find(s => s.id === this.$store.getters.stormCompany.subscriptionTierId)
        return sc.maxUsers <= this.$store.getters.stormUsers.length
      }
    },
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    reloadStorms () {
      return this.$store.getters.reloadStorms
    },
    newStorm () {
      return require(`../../assets/icons/new.svg`)
    },
    userWarning () {
      var tier = this.subscriptionTiers.find(s => s.id === this.$store.getters.stormCompany.subscriptionTierId)
      var rtn = 'You are allowed ' + (tier.maxUsers === 0 ? 'unlimited' : tier.maxUsers) + ' users for subscription tier ' + tier.name
      return rtn
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    userid () {
      this.setComponent()
    },
    reloadStorms (val) {
      if (val) {
        this.setComponent()
        this.setReloadStorms(false)
      }
    },
    storm () {
      this.setComponent()
    }
  },
  mounted: async function () {
    DataEventBus.$on('refresh-data', () => {
      this.setComponent()
    })
    this.maxWebUsers = await this.getStormCompanySysInfo()
    await this.setComponent()
  },
  methods: {
    ...mapActions([
      'setMainForm'
    ]),
    setComponent: async function () {
      this.setMainForm(false)
      await this.setSecurity()
      this.getStormUsers()
    },
    openNewStorm () {
      this.setMainForm(true)
    },
    selectStormUser (selectedRow) {
      this.selectedStormUser = selectedRow
      if (this.selected === selectedRow.id) {
        this.selected = '-1'
        this.selectedStormUser = null
      } else {
        this.selected = selectedRow.id
      }
    },
    testSelectStorm (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectStormUser(selectedRow)
      }
    },
    getStormUsers () {
      this.initialiseTable(
        'stormTable',
        this.stormUsers,
        ['forename', 'surname', 'email', 'phone'],
        ['First Name', 'Last Name', 'Email Address', 'Phone'],
        ['', '', '', ''],
        null,
        ['20%', '20%', '40%', '20%']
      )
    },
    getRowColour (id) {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      if (this.selected === id) {
        rtn = 'itemSelected'
      }
      return rtn
    },
    setSecurity: async function () {
      // resolve the security groups to check for admin group membership
      this.stormAdmin = false
      this.userSecurityGroups = await this.getStormSecurityGroupsByUser(this.$store.getters.stormUser.id)
      this.userSecurityGroups.forEach(sg => {
        if (sg.name.toLowerCase() === this.$store.getters.stormAdminSecurityGroup.toLowerCase()) {
          this.stormAdmin = true
        }
      })
    }
  }
}

</script>
<style scoped>
  .textButton {
    float: right;
  }
  .mdSelection {
    display:inline-block;
    width: 390px;
    vertical-align: top;
    margin-left: 10px;
  }
  .mdStormManagement{
    display:inline-block;
    margin: 0;
    width:100%;
  }
  .mdStormManagement.mdHalfScreen{
    width:calc(100% - 400px);
  }
  .securityHolder {
    width:100%;
  }
  .appSectionBody{
    margin-top:10px;
    background: white;
    padding: 0px 15px 15px 15px;
  }
  .pgContainer {
    height: calc(100vh - 280px);
  }
  .imgNewStorm {
    width:35px;
    height:35px;
  }
  .stormManagementHeader {
    display: inline-block;
    width: 100%;
  }
  .userWarning {
    width: calc(100% - 240px);
    padding: 8px 0 6px 8px;
    background-color: #ff8d7c;
    display: inline-block;
    border-radius: 5px;
    font-weight: bold;
    margin-bottom: 10px;
  }
</style>
