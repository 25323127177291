<template>
  <div
    :class="{tcsDisplay:isMounted}"
    class="tcsContainer"
  >
    <div class="tcsHeader">
      <SectionHeader
        :title="tileInformation.title"
        :icon-file="tileInformation.iconFile"
        enable-click="true"
        type ="subHeader"
        @click="meClick()"/>
    </div>
    <div class="tcsBodyContainer">
      <component :is="tileInformation.component" />
    </div>
  </div>
</template>
<script>
import SectionHeader from '../common/sectionHeader'
import TicketManagementTile from './systemTiles/ticketManagementTile'
import ServiceStatusTile from './systemTiles/serviceStatusTile'
import PricingConfiguratorTile from './systemTiles/pricingConfiguratorTile'
import ProvisioningTile from './systemTiles/provisioningTile'
export default {
  components: {
    SectionHeader,
    TicketManagementTile,
    ServiceStatusTile,
    PricingConfiguratorTile,
    ProvisioningTile
  },
  props: {
    tileInformation: {
      type: String,
      default: ''
    },
    tileOrder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isMounted: false,
      tileDisplayTimeout: null
    }
  },
  mounted () {
    this.tileDisplayTimeout = setTimeout(() => { this.isMounted = true }, (110 * this.tileOrder))
  },
  methods: {
    meClick () {
      console.log('clicked', this.tileInformation)
    }
  }
}
</script>

<style>
  .tcsContainer {
    margin:10px;
    padding:5px;
    background-color: rgba(255,255,255,0.7);
    width:95%;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    transform:scale(0.01) translateY(-100vh);
    opacity: 0;
  }

  .tcsContainer.tcsDisplay {
    animation: showDisplay 0.4s 0.75s forwards;
  }

  @keyframes showDisplay{
    0%{
      transform:translateY(-100vh) scale(0.1);
      opacity: 0;
    }
    30%{
       transform: translateY(0vh) scale(0.1);
       opacity: 1;
    }
    80%{
      transform:translateY(0vh) scale(1.05);
      opacity: 1;
    }
    100%{
      transform:translateY(0vh) scale(1);
      opacity: 1;
    }
  }
</style>
