import { render, staticRenderFns } from "./overviewCard.vue?vue&type=template&id=ab773484&scoped=true&"
import script from "./overviewCard.vue?vue&type=script&lang=js&"
export * from "./overviewCard.vue?vue&type=script&lang=js&"
import style0 from "./overviewCard.vue?vue&type=style&index=0&id=ab773484&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab773484",
  null
  
)

export default component.exports