<template>
  <div class="parentContainer">
    <div class="projects">
      <h2> Projects </h2>
      <div
        class="priorityFilterContainer"
      >
        <div
          :class="getPriorityColour('high')"
          class="priorityIcon"
          @click="applyFilter('high')"
        >
          Red Projects {{ `(${getFilteredSiteList('High').length})` }}
        </div>
        <div
          :class="getPriorityColour('medium')"
          class="priorityIcon"
          @click="applyFilter('medium')"
        >
          Amber Projects {{ `(${getFilteredSiteList('Medium').length})` }}
        </div>
        <div
          :class="getPriorityColour('low')"
          class="priorityIcon"
          @click="applyFilter('low')"
        >
          Green Projects {{ `(${getFilteredSiteList('Low').length})` }}
        </div>
        <div
          :class="getPriorityColour('')"
          class="priorityIcon"
          @click="applyFilter(undefined)"
        >
          All Projects {{ `(${getFilteredSiteList('').length})` }}
        </div>
        <div
          class="statusIcon"
        >
          Closed Projects
          <TriStateToggleSwitch
            :enabled="true"
            :current-state=" displayClosedProjects ? 1 : 2"
            :tri-state="false"
            class="switchClosedProjects"
            style="top:5px;"
            @statechanged="filterProjectStatus()"
          />
        </div>
      </div>
      <ProjectsTopLevelSummary
        v-show="ProjectsTopLevelSummaryVisible"
        :filter="projectsFilter"
        :display-closed-projects="displayClosedProjects"
        @siteProjectDrillDown="siteProjectDrillDown"
      />
      <div
        :class="[ ProjectDetailsVisible ? 'splitviewleft': '']"
      >
        <ProjectSiteSummary
          v-show="ProjectSiteSummaryVisible"
          :selected-site="targetSite"
          :status="targetStatus"
          :filter="projectsFilter"
          @projectDrillDown="projectDrillDown"
        />
      </div>
      <div
        :class="[ ProjectDetailsVisible ? 'splitviewright': '']"
      >
        <ProjectDetails
          v-show="ProjectDetailsVisible"
          :selected-project="targetProject"
        />
      </div>
      <input
        v-if="projectsStepNumber != 1"
        type="button"
        class="backButton"
        title="Back"
        @click="projectsNavigateTo()"
      >
    </div>
  </div>
</template>

<script>

import ProjectsTopLevelSummary from './ProjectsTopLevelSummaryComponent'
import ProjectSiteSummary from './ProjectSiteSummaryComponent'
import ProjectDetails from './ProjectDetailsComponent'
import { permissionsMixin } from '../../mixins/permissions'
import { DataEventBus } from '../../events/dataEvents'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'

export default {
  name: 'ProjectsContainer',
  components: {
    ProjectsTopLevelSummary,
    ProjectSiteSummary,
    ProjectDetails,
    TriStateToggleSwitch
  },
  mixins: [
    permissionsMixin
  ],
  data () {
    return {
      ProjectsTopLevelSummaryVisible: true,
      ProjectSiteSummaryVisible: false,
      ProjectDetailsVisible: false,
      projectsStepNumber: 1,
      targetSite: -1,
      targetStatus: '',
      targetProject: {},
      displayClosedProjects: false
    }
  },
  computed: {
    projectsFilter () {
      return this.$route.query.projectsFilter || ''
    },
    topLevelSitesSummary () {
      return this.$store.getters.topLevelSitesSummary
        ? this.$store.getters.topLevelSitesSummary.filter(({ status }) => status.toLowerCase() === (this.displayClosedProjects ? status : 'open'))
        : []
    }
  },
  watch: {
    projectsFilter () {
      this.applyFilter(this.projectsFilter)
    },
    displayClosedProjects () {
      this.applyFilter(this.projectsFilter)
    }
  },
  mounted () {
    DataEventBus.$on('company-changed', (customer) => {
      this.projectsStepNumber = 1
      this.projectsNavigateTo()
    })
    DataEventBus.$on('refresh-data', () => {
      this.projectsStepNumber = 1
      this.projectsNavigateTo()
    })
  },
  created () {
  },
  methods: {
    projectsNavigateTo: function () {
      switch (this.projectsStepNumber) {
        default:
          this.projectsStepNumber = 1
          this.ProjectsTopLevelSummaryVisible = true
          this.ProjectSiteSummaryVisible = false
          this.ProjectDetailsVisible = false
          break
      }
    },
    siteProjectDrillDown: function ({ siteId, status }) {
      this.targetSite = siteId
      this.targetStatus = status

      if (this.targetSite !== -1) {
        this.ProjectsTopLevelSummaryVisible = false
        this.ProjectSiteSummaryVisible = true
        this.ProjectDetailsVisible = false
        this.projectsStepNumber = 2
      }
    },
    projectDrillDown: function ({ project }) {
      if (this.targetProject !== project) {
        this.targetProject = project

        if (this.targetProject !== {}) {
          this.ProjectsTopLevelSummaryVisible = false
          this.ProjectSiteSummaryVisible = true
          this.ProjectDetailsVisible = true
        }
      } else {
        this.ProjectDetailsVisible = !this.ProjectDetailsVisible
      }
    },
    getPriorityColour: function (priority) {
      var isSelectedPriority = this.projectsFilter === priority ? 'selected' : ''

      switch (priority ? priority.toLowerCase() : '') {
        case 'high':
          return 'Red ' + isSelectedPriority
        case 'medium':
          return 'Amber ' + isSelectedPriority
        case 'low':
          return 'Green ' + isSelectedPriority
        default:
          return 'Grey ' + isSelectedPriority
      }
    },
    applyFilter: function (projectsFilter) {
      this.$router.push({ path: 'quotes-and-projects', query: { projectsFilter } })
      this.projectsStepNumber = 1
      this.projectsNavigateTo()
    },
    getFilteredSiteList: function (filter = '') {
      switch (filter.toLowerCase()) {
        case 'high':
          return this.topLevelSitesSummary.filter(o => o.ragStatus.toLowerCase() === 'red')
            .map(({ siteId }) => ({ siteId }))
        case 'medium':
          return this.topLevelSitesSummary.filter(o => o.ragStatus.toLowerCase() === 'amber')
            .map(({ siteId }) => ({ siteId }))
        case 'low':
          return this.topLevelSitesSummary.filter(o => o.ragStatus.toLowerCase() === 'green')
            .map(({ siteId }) => ({ siteId }))
        default:
          return this.topLevelSitesSummary
      }
    },
    filterProjectStatus () {
      this.displayClosedProjects = !this.displayClosedProjects
    }
  }
}
</script>

<style scoped>
  .parentContainer {
    max-height: 550px;
    overflow-y: auto;
    margin-bottom:20px;
  }
  .backButton {
    margin: 5px 24px;
    width: 80%;
  }
  .splitviewleft {
    float: left;
    width:60%;
  }
  .splitviewright {
    display: inline-block;
    width:40%;
  }
  .projects h2 {
    margin: 0 0 0 10px;
    font-size: 1.4em;
  }
  .projects {
    background-color: #fff;
    border-radius: 10px;
    margin: 5px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    max-height:600px;
    overflow-y:auto;
  }

  .Red {
    background-color: rgb(239, 83, 80);
    border: 2px rgb(240, 73, 70) solid;
  }

  .Amber {
    background-color: rgb(255, 167, 38);
    border: 2px rgb(241, 159, 36) solid;
  }

  .Green {
    background-color: rgb(102, 187, 106);
    border: 2px rgb(93, 170, 97) solid;
  }

  .Grey {
    background-color: rgb(214, 213, 213);
    border: 2px rgb(199, 198, 198) solid;
  }

  .Purple {
    background-color: rgb(167, 80, 239);
    border: 2px rgb(155, 48, 243) solid;
  }

  .priorityFilterContainer {
    display: flex;
  }

  .priorityIcon {
    flex: 1;
    margin: 5px 5px 10px 5px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: 400;
    cursor: pointer;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  }

  .statusIcon {
    margin: 5px 5px 10px 5px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: 400;
    cursor: pointer;
  }

  .selected {
    color: #fff;
  }

</style>
