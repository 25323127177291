<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-quotesandprojects"
          primary-title
        >
          Quotes and Projects
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details the Quotes and Projects screen and it's associated functionality."
              />
              <HelpSection
                instruction="Navigate to the 'Quotes and Projects' page via the left hand navigation menu."
                image-source="quotes_and_projects_1.png"
              />
              <h3> Projects </h3>
              <HelpSection
                instruction="1.) Under the 'Projects' section there is a list of all the sites with projects available to the logged in user."
                indentation="0"
              />
              <HelpSection
                instruction="2.) Each site can be clicked which will return the projects available for the selected site."
                image-source="quotes_and_projects_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Each project is highlighted based on the current RAG status of the project."
                indentation="1"
              />
              <HelpSection
                instruction="3.) Each project can be clicked which will return the project details for the selected project."
                image-source="quotes_and_projects_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) A project status bar will be displayed if there are dates recorded against the project."
                indentation="1"
              />
              <HelpSection
                instruction="4.) There are filters available to filter the projects displayed."
                image-source="quotes_and_projects_5.png"
                indentation="0"
              />
              <h3> Quotes </h3>
              <HelpSection
                instruction="1.) Under the 'Quotes' section there is a list of all quotes available to the logged in user."
                indentation="0"
              />
              <HelpSection
                instruction="2.) Each Quote can be clicked which displays further details about the selected Quote in a panel on the right hand side of the table."
                image-source="quotes_and_projects_4.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Each Quote is highlighted based on the current Quote status, blue for 'Pricing Provided' and green for all other statuses."
                indentation="1"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpDefaultCustomers',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
