<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      :overlay="true"
      persistent
      max-width="50rem"
      transition="dialog-bottom-transition"
      class="dialog"
    >
      <v-card
        :id="dlgid"
        class="ma-0 dialog-border"
        height="560px"
        tile
      >
        <v-toolbar
          dense
          flat
          color="white"
        >
          <v-flex
            xs12
            sm12
            md12
          >
            <h3>{{ dlgMessage }}</h3>
            <hr>
            <br>
          </v-flex>
        </v-toolbar>
        <div v-if="warningMessage !== '' && !fileUploaded">
          <div
            class="warningMessage"
            v-html="warningMessage"/>
        </div>
        <v-card-title class="card">
          <v-flex
            v-if="!uploading && fileUploadCatalogId === 0 && !fileUploaded"
            xs12
            sm12
            md10
          >
            <div v-if="showTitle">
              <v-spacer />
              <v-spacer />
              <label
                for="txtFileUploadTitle"
                class="uploadTitleLabel"
              >Title:</label>
              <input
                id="txtFileUploadTitle"
                :class="[fileNameError ? 'uploadFileTextboxError' : 'uploadFileTextbox']"
                v-model="file.title"
                type="text"
              >
            </div>
            <br><br>
          </v-flex>
          <v-flex
            v-if="!uploading && fileUploadCatalogId !== 0 && !fileUploaded"
            xs12
            sm12
            md10
          >
            <v-spacer />
            <v-spacer />
            <label
              for="txtFileUploadTitle"
              class="uploadTitleLabel"
            >Notes:</label>
            <input
              id="txtFileUploadVersionNotes"
              :class="[versionNotesError ? 'uploadFileTextboxError' : 'uploadFileTextbox']"
              v-model="file.versionNotes"
              type="text"
            >
            <br><br>
          </v-flex>
          <v-flex
            v-show="!fileUploaded"
            xs6
            sm6
            md6
          >
            <UploadFile
              type="all/*"
              @upload="handleFileChosen"/>
          </v-flex>
          <v-flex
            v-if="!fileUploaded && file.name !== ''"
            xs6
            sm6
            md6
            align-content-start
          >
            <table class="tableNoBoxShadow">
              <tr>
                <th>File Name:</th>
                <td class="uploadFilenameLabel">{{ file.name }}</td>
              </tr>
              <tr>
                <th>File Size:</th>
                <td>{{ file.size }}</td>
              </tr>
              <tr><td colspan="2">&nbsp;</td></tr>
              <tr
                v-if="checkingFile"
              >
                <th>Checking File</th>
                <td><Spinner /></td>
              </tr>
              <tr
                v-if="selectedFileError !== ''"
              >
                <th
                  colspan="2"
                  style="color:red">{{ selectedFileError }}</th>
              </tr>
              <tr
                v-if="uploadError"
              >
                <th
                  colspan="2"
                  style="color:red">There was an error uploading the file.  Please contact your administrator for assistance</th>
              </tr>
              <tr
                v-if="uploading"
              >
                <th>Uploading File</th>
                <td><Spinner /></td>
              </tr>
            </table>
          </v-flex>
          <v-flex
            v-if="fileUploaded"
            xs12
            sm12
            md12
            align-content-center
          >
            The file was successfully uploaded
          </v-flex>
        </v-card-title>
        <v-card-actions style="position: absolute;bottom: 1rem; right: 1rem;">
          <v-spacer/>
          <v-btn
            v-show="!fileNameError && file.name != '' && !(fileUploadCatalogId !== 0 && file.versionNotes === '')"
            :disabled="uploading || fileUploaded || checkingFile || selectedFileError !== ''"
            dark
            @click="uploadAction"
          >Upload</v-btn>
          <v-btn
            v-if="!fileUploaded && !uploading && !checkingFile"
            dark
            @click="cancelAction"
          >Cancel</v-btn>
          <v-btn
            v-if="fileUploaded"
            dark
            @click="closeAction"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Spinner from './spinner'
import UploadFile from './uploadFile'
import portalAPIGateway from '../../communications/interceptors/apiInterceptor'
import { DataEventBus } from '../../events/dataEvents'

export default {
  name: 'FileUploadDialogBox',
  components: {
    Spinner,
    UploadFile
  },
  mixins: {
    portalAPIGateway
  },
  props: {
    dialog: { type: Boolean, default: false },
    dlgid: { type: String, required: false, default: '' },
    dlgIcon: { type: String, required: false, default: '' },
    dlgMessage: { type: String, default: '' },
    dlgCloseFromTop: { type: Boolean, default: false },
    fileUploadCatalogId: { type: Number, default: 0 },
    checkingFile: { type: Boolean, default: false },
    selectedFileError: { type: String, default: '' },
    saveFile: { type: Boolean, default: true },
    showTitle: { type: Boolean, default: true },
    warningMessage: { type: String, default: '' },
    handleAfterUpload: { type: Boolean, default: false }
  },
  data () {
    return {
      fileUploaded: false,
      uploading: false,
      maxFileSize: 10000000,
      file: {
        id: null,
        fileUploadCatalogId: null,
        fileVersionId: null,
        name: '',
        title: '',
        versionNotes: '',
        contents: []
      },
      fileIcon: '',
      fileIconClass: '',
      fileSize: '',
      uploadError: false
    }
  },
  computed: {
    fileNameError () {
      return this.file.title.trim() === ''
    },
    versionNotesError () {
      return this.file.versionNotes.trim() === '' && this.fileUploadCatalogId !== 0
    }
  },
  created () {
    if (this.handleAfterUpload) {
      DataEventBus.$on('afterUploadCompleted', () => {
        this.fileUploaded = true
        this.uploading = false
      })
      DataEventBus.$on('showUploadProgress', (value) => {
        this.uploading = value
      })
      DataEventBus.$on('resetDialog', () => {
        this.resetDialog()
      })
    }
  },
  destroyed () {
    if (this.handleAfterUpload) {
      DataEventBus.$off('afterUploadCompleted')
      DataEventBus.$off('showUploadProgress')
      DataEventBus.$off('resetDialog')
    }
  },
  methods: {
    async uploadAction () {
      if (this.handleAfterUpload) {
        this.$emit('afterUploadAction', this.file)
      }
      this.uploadError = false
      this.uploading = true

      if (this.saveFile) {
        var config = {
          timeout: 120000,
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userId,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }

        var payload = {
          fileUploadCatalogId: this.fileUploadCatalogId,
          name: this.file.name,
          title: this.file.title,
          contents: this.file.contents,
          versionNotes: this.file.versionNotes
        }

        try {
          await portalAPIGateway.put(`/portalUploads/iQuote/`, payload, config).then((response) => {
            this.file = response.data
          })
        } catch (e) {
          this.file.id = null
          this.uploadError = true
        }
      }
      this.fileUploaded = !this.handleAfterUpload
      this.uploading = this.handleAfterUpload
    },
    resetDialog () {
      this.file = {
        id: null,
        fileUploadCatalogId: null,
        fileVersionId: null,
        name: '',
        title: '',
        versionNotes: '',
        versionNumber: ''
      }

      this.fileIcon = ''
      this.fileIconClass = ''
      this.fileSize = ''
      this.file.contents = []

      this.uploading = false
      this.fileUploaded = false
      this.uploadError = false
    },
    closeAction () {
      if (!this.handleAfterUpload) {
        this.$emit('uploadAction', this.file)
        this.resetDialog()
      } else {
        this.cancelAction()
      }
    },
    cancelAction () {
      this.$emit('cancelAction')
      this.resetDialog()
    },
    async handleFileChosen (event) {
      const file = event.target.files[0]
      const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      // divide by 1000 to get MB
      const fileSize = parseInt(file.size) / 1024
      if (!(fileSize > 0 && fileSize <= this.maxFileSize)) {
        alert('you exceed the file size')
        this.file.name = ''
        return
      }

      let icon = ''
      let color = ''
      if (fileExtension === 'pdf') {
        icon = 'mdi-file-pdf'
        color = 'red'
      } else if (['png', 'jpg', 'jif', 'jfif', 'bmp'].includes(fileExtension)) {
        icon = 'mdi-image'
        color = 'orange'
      } else {
        icon = 'mdi-clipboard-text'
        color = 'blue'
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.file.id = 0
        this.file.name = file.name
        this.file.title = file.name
        this.fileIcon = icon
        this.fileIconClass = `${color} lighten-1 white--text`
        this.file.contents = e.target.result
        this.file.size = `${fileSize.toFixed(2)} KB`

        if (this.fileUploadCatalogId === 0) {
          this.file.versionNotes = 'Initial Version'
        }
        this.uploadError = false
        this.$emit('fileChosenAction', this.file)
      }
    }
  }
}
</script>

<style>

  table.tableNoBoxShadow {
    box-shadow: none;
  }

  .v-dialog {
    overflow-y: unset !important;
  }
  .card {
      padding:0.5rem 1.5rem -1rem 1rem;
  }
  .title {
    padding-top: 5px;
  }
  .dialog-border {
    border-left: 1rem solid silver !important
  }
  .v-btn__content {
    font-weight: bold;
  }

  .uploadTitleLabel {
    width: 100px;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .warningMessage {
    margin-left: 20px;
    font-size: x-large;
    line-height: 1.3em;
    font-weight: 500;
    font-family: 'Arial';
    color: #ed2e11;
  }

  input[type=text].uploadFileTextbox {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    border: default;
    background-color: white;
    width:calc(100% - 120px);
  }

  input[type=text].uploadFileTextboxError {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    background-color: white;
    border: solid 1px red;
    width:calc(100% - 120px);
  }

  .progressContainer{
    width:100%;
    text-align: left;
  }

  .progressBar {
    width: 100%;
    height: 30px;
    border: solid 1px silver;
    background-color: #fff;
    background-image: linear-gradient(to right, rgb(240, 240, 240), white);
    border-radius: 8px;
    margin-top:10px;
  }

  .progressValueBar {
    background-image: linear-gradient(to right, rgb(198, 255, 198), #63B452);
    height: 26px;
    border-radius: 8px;
    margin:1px 2px;
    border: 1px solid silver
  }

  .uploadFilenameLabel {
    width: 200px;
    word-wrap: break-word;
  }
</style>
