<template>
  <div
    v-show="viewDashboardImage"
    class="dashboardImageContainer"
  >
    <material-card>
      <div class="closeDashboardImageButtonContainer">
        <button
          class="closeDashboardImageButton"
          @click="handleDashboardImageVisibility(false)"
        >
          <v-icon>mdi-close</v-icon>
        </button>
      </div>
      <Spinner v-if="slides.length === 0" />
      <div>
        <vueper-slides
          :duration="5000"
          autoplay
        >
          <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :content="slide.title"
            :link="slide.link"
            :image="`/dashboard/${slide.image}`"
            :title="slide.title"
            target="_blank"/>
        </vueper-slides>
      </div>
    </material-card>
  </div>
</template>
<script>

import Spinner from '../common/spinner'
import { DataEventBus } from '../../events/dataEvents'
import { dashboardImageMixin } from '../../mixins/dashboardImage'
import { mapActions } from 'vuex'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'DashboardImage',
  components: {
    Spinner,
    VueperSlides,
    VueperSlide
  },
  mixins: [
    dashboardImageMixin
  ],
  data () {
    return {
      slides: []
    }
  },
  computed: {
    viewDashboardImage () {
      return this.$store.getters.isDashboardImageVisible
    }
  },
  created: async function () {
    this.setImages()
  },
  async mounted () {
    DataEventBus.$on('dashboard-image-changed', async () => {
      this.setImages()
      this.setSliderText()
    })
  },
  updated: async function () {
    this.setSliderText()
  },
  methods: {
    ...mapActions([
      'setDashboardImageVisibility'
    ]),
    setImages: async function () {
      await this.getDashboardImageJson().then((response) => {
        this.slides = response
      }).catch((error) => {
        this.handleDashboardImageVisibility(false)
        console.log(error)
      })
    },
    handleDashboardImageVisibility: async function (isEnabled) {
      this.setDashboardImageVisibility(isEnabled)
    },
    setSliderText () {
      var sliderDivs = document.getElementsByClassName('vueperslide')
      for (var i = 0; i < sliderDivs.length; i++) {
        sliderDivs[i].innerText = ''
      }
    }
  }
}
</script>

<style scoped>
  .dashboardImageContainer{
    aspect-ratio: 3983/1517;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 3983/1517;
    box-shadow: 8px 8px 15px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  }
  .closeDashboardImageButton {
    width: 100%;
    height: 100%;
    background-color: #fcfafa;
    border-radius: 50%;
  }
  .closeDashboardImageButtonContainer {
    background: #fff;
    height: 30px;
    width: 30px;
    position: absolute;
    top: -15px;
    right: -15px;
    border-radius: 50%;
    text-align: center;
    z-index: 1;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  .closeDashboardImageButtonContainer:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
</style>
