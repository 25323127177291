import portalAPIGateway from '../../../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteBomsAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteBomsAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteQuoteBomsAPIMixin_saveQuoteBomToDB: async function (quoteId, quoteBoms) {
      var axiosConfig = this.iQuoteQuoteBomsAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.put('iquote/quoteboms/' + quoteId, quoteBoms, axiosConfig).then((response) => {
          returnValue = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteBomsAPIMixin_saveQuoteBomToDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteBomsAPIMixin_deleteQuoteBomFromDB: async function (quoteBom) {
      var axiosConfig = this.iQuoteQuoteBomsAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.delete('iquote/quoteboms/' + quoteBom.id, null, axiosConfig).then((response) => {
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteBomsAPIMixin_deleteQuoteBomFromDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteBomsAPIMixin_getQuoteBomsFromDb: async function (quoteId) {
      var axiosConfig = this.iQuoteQuoteBomsAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquotearray/quoteboms/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteBomsAPIMixin_getQuoteBomsFromDb returned error: ' + error)
      }

      return data
    }
  }
}
