<template>
  <div>
    <Spinner v-if="loading" />
    <router-link
      v-ripple
      v-if="!loading"
      class="toolbar-items"
      to="quotes-and-projects">
      <material-card>
        <vc-donut
          :size="200"
          :thickness="40"
          :sections="opportunitiesData || []"
          :total="opportunitiesTotal || undefined"
          :start-angle="0"
          background="white"
          foreground="grey"
          unit="px"
          has-legend
          legend-placement="bottom">
          <h4 class="title font-weight-light">Quote Status</h4>
        </vc-donut>
        <template
          slot="actions"
        >
          <div
            style="display:inline-block;"
          >
            <v-icon
              class="mr-2"
              small
            >mdi-calendar
            </v-icon>
            <span class="caption grey--text font-weight-light">In the last 30 days</span>
            <br>
            <v-icon
              class="mr-2"
              small
            >mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">{{ updateInterval }}</span>
          </div>
        </template>
      </material-card>
    </router-link>
  </div>
</template>
<script>
import { iQuoteSalesforceAPIMixin } from '../../../mixins/iQuote/iQuoteSalesforceAPIMixin'
import { dashboardMixin } from '../../../mixins/dashboard'
import { DataEventBus } from '../../../events/dataEvents'
import Spinner from '../../common/spinner'

export default {
  name: 'OpportunityStatusTile',
  components: {
    Spinner
  },
  mixins: [
    iQuoteSalesforceAPIMixin,
    dashboardMixin
  ],
  data () {
    return {
      timer: 0,
      timerInterval: process.env.VUE_APP_DATA_REFRESH_TIME,
      loading: false,
      opportunitiesData: [],
      lastUpdatedDate: Date.now(),
      lastUpdatedText: 'Updated 0 seconds ago'
    }
  },
  computed: {
    opportunities () {
      return this.$store.getters.opportunities
    },
    opportunitiesTotal () {
      return this.opportunities ? this.opportunities.length : 0
    },
    customerFlags () {
      return this.$store.getters.customerFlags
    },
    updateInterval () {
      return `Updates every ${(this.timerInterval / 1000)} seconds`
    }
  },
  mounted () {
    DataEventBus.$on('updateOpportunities', async () => {
      await this.iQuoteSalesforceAPIMixin_getSalesforceCustomerOpportunityData()
      this.getOpportunitiesSummaryChartData()
      this.timer = this.setTimer()
    })
    this.getOpportunitiesSummaryChartData()
  },
  created () {
    this.timer = this.setTimer()
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    getOpportunitiesSummaryChartData: function () {
      if (this.opportunities) {
        this.loading = true
        var opportunityStatusCounts = {}
        this.opportunitiesData = []

        this.opportunities.forEach(({ stageName }) => {
          var stage = ''
          switch (stageName.toLowerCase()) {
            case 'pricing provided':
              stage = 'Pricing Provided'
              break
            default:
              stage = 'In Flight'
          }

          if (!opportunityStatusCounts[stage]) {
            opportunityStatusCounts[stage] = 1
          } else {
            opportunityStatusCounts[stage] += 1
          }
        })

        Object.keys(opportunityStatusCounts).forEach((status) => {
          this.opportunitiesData.push({
            label: `${status} (${opportunityStatusCounts[status]})`,
            value: opportunityStatusCounts[status],
            color: this.getStatusColour(status)
          })
        })

        if (!this.opportunitiesData.length) {
          this.opportunitiesData.push({
            label: 'No Data Available',
            value: 0,
            color: this.getStatusColour('')
          })
        }
        this.lastUpdatedText = this.setLastUpdatedText(this.lastUpdatedDate)
        this.loading = false
      }
    },
    getStatus: function (opportunity) {
      var shouldDisplayQuoteProvidedStatus = this.customerFlags && this.customerFlags.some(o => o.flagName === 'QuoteCompleteStatusDisplay' && o.flagValue.toLowerCase() === 'yes')
      var hasQuoteProvidedStatus = opportunity.stageName && opportunity.stageName.toLowerCase() === 'quote complete'
      var calculatedStatus = shouldDisplayQuoteProvidedStatus && hasQuoteProvidedStatus
        ? 'Quote Complete'
        : 'Open'

      return opportunity.closeDate
        ? (new Date(opportunity.closeDate) <= new Date()
          ? 'Closed'
          : calculatedStatus)
        : calculatedStatus
    },
    getStatusColour: function (status) {
      switch (status.toLowerCase()) {
        case 'pricing provided':
          return '#2196f3'
        case 'in flight':
          return 'rgb(255, 167, 38)'
        default:
          return 'rgb(153, 153, 153)'
      }
    },
    setTimer () {
      clearInterval(this.timer)
      return setInterval(async () => {
        await this.iQuoteSalesforceAPIMixin_getSalesforceCustomerOpportunityData()
        this.getOpportunitiesSummaryChartData()
      }, this.timerInterval)
    }
  }
}
</script>
