<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-createticket"
          primary-title
        >
          Create New Ticket
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="1.) Select the ‘Create New Ticket’ button in the top right hand corner to open the ‘Create New Ticket’ screen."
                image-source="create_ticket_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="2.) On the ‘Create New Ticket’ screen, the type of ticket is selected from the ‘Request Type’ drop-down menu. Selecting the ‘Request Type’ produces a different set of fields depending on the ticket type. Note that the completion of a section is indicated by a tick in the section header and hides the message ‘Please enter values in all fields’."
                image-source="create_ticket_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="3.) When ‘Incident’ is chosen, then the ‘Ticket Details’ and ‘Additional Ticket Fields’ sections will be expanded as shown below."
                image-source="create_ticket_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Fill in all the fields in the ‘Ticket Details’ section. Completion of the section is indicated by a tick in the section header and hides the message ‘Please enter values in all fields’. Note the ‘Issue Area’ field will only be populated once a Site is selected."
                indentation="1"
              />
              <HelpSection
                instruction="b.) Fill in all the fields in the ‘Additional Ticket Fields’ section. Completion of the section is indicated by a tick in the section header, and hides the message ‘Please enter values in all fields’. Note the ‘Site Configuration Item’ field will only be populated once the Issue Area has been selected."
                indentation="1"
              />
              <HelpSection
                instruction="4.) When ‘Query’ is chosen, then the ‘Ticket Details’ and ‘Additional Ticket Fields’ sections will be expanded as shown below."
                image-source="create_ticket_4.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Fill in all the fields in the ‘Ticket Details’ section. Completion of the section is indicated by a tick in the section header and hides the message ‘Please enter values in all fields’"
                indentation="1"
              />
              <HelpSection
                instruction="b.) Fill in all the fields in the ‘Additional Ticket Fields’ section. Completion of the section is indicated by a tick in the section header and hides the message ‘Please enter values in all fields’."
                indentation="1"
              />
              <HelpSection
                instruction="5.) Select ‘Change Request (Time and Materials)’ or ‘MAC Request (Using MAC Pack Contract)’ as a request type - which one the user has access to will depend on their log-in. The ‘Ticket Details’ and ‘Additional Ticket Fields’ sections will be expanded."
                image-source="create_ticket_5.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Fill in all the fields in the ‘Ticket Details’ section. Completion of the section is indicated by a tick in the section header and hides the message ‘Please enter values in all fields’."
                indentation="1"
              />
              <HelpSection
                instruction="b.) Fill in all the fields in the ‘Additional Ticket Fields’ section. Completion of the section is indicated by a tick in the section header and hides the message ‘Please enter values in all fields’."
                indentation="1"
              />
              <h3> Attachment </h3>
              <HelpSection
                instruction="To add an attachment, select the Attachment section. A new file can be added by dragging it and dropping it into the ‘Drag a file here or click’ area, or by clicking on this area to select a file. If a title is provided, it should be entered before attaching the file. Resources can be notified of the update by setting the 'Notify Resources' toggle to green. This will notify the Maintel resources by default; other recipients can be added by manually entering the email of the person to be notified. Multiple addresses may be added, separated by semi-colon. After selecting a file or files to upload, the uploaded files will be listed in the Upload Files section. Files can be deleted from this list before clicking on the Add Attachments button."
                image-source="create_ticket_7.png"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpMyTickets',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
