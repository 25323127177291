import { mapActions } from 'vuex'

export const iQuoteDisplayExpansionsMixin = {
  data () {
    return {
    }
  },
  mixins: [
  ],
  computed: {
    displayExpansions_virtualMachinesStore () {
      return this.$store.getters.virtualMachines
    }
  },
  methods: {
    ...mapActions([
    ]),
    displayExpansions_getServerNameForRequiredVirtualMachine (rvm) {
      if (rvm.virtualMachineId === null) {
        if (rvm.customServerName === '') {
          return 'NO NAME'
        } else {
          return rvm.customServerName
        }
      } else {
        return this.displayExpansions_getServerNameFromVirtualMachineId(rvm.virtualMachineId)
      }
    },
    displayExpansions_getServerNameFromVirtualMachineId (virtualMachineId) {
      var vms = this.displayExpansions_virtualMachinesStore
      var name = 'unknown'

      vms.forEach(vm => {
        if (vm.id === virtualMachineId) {
          name = vm.name
        }
      })
      return name
    }
  },
  mounted () {
  }
}
