import portalAPIGateway from '../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteCallmediaCXNowDdiDetailsAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteCallmediaCXNowDdiDetailsAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    saveQuoteCallmediaCXNowDdiDetailsToDB: async function () {
      var axiosConfig = this.iQuoteQuoteCallmediaCXNowDdiDetailsAPIMixin_getAxiosConfig()

      var quoteId = this.$store.getters.quote.id
      var ddiDetails = this.$store.getters.quoteCallmediaCXNowDdiDetails

      try {
        await portalAPIGateway.post('iquote/quoteCallmediaCXNowDdiDetails/' + quoteId, ddiDetails, axiosConfig).then(() => {
          return true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('saveQuoteCallmediaCXNowDdiDetailsToDB failed!')
      }
    },
    getQuoteCallmediaCXNowDdiDetailsFromQuoteId: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteCallmediaCXNowDdiDetailsAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteCallmediaCXNowDdiDetails/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data

          if (data === '') {
            // No expansions are on the quote
            data = []
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteCallmediaCXNowDdiDetailsFromQuoteId error: ' + error)
      }
      return data
    }
  }
}
