<template>
  <div class="widget-wrapper">
    <apexchart
      :options="getChartOptions(widget)"
      :series="getChartSeries(widget)"
      :type="widget.type"
      width="100%"
    />
    <div class="widget-footer">
      <div class="widget-footer-timestamp">
        <span
          :title="'Data last updated at ' + timestamp()"
          class="timestamp"
        >{{ timestamp() }}</span>
      </div>
    </div>
    <TimerProgressComponent
      id="controlRefreshBar"
      :polling-interval="pollingIntervalInSeconds"
      :widget-id="widget.id"
      @reload-widget="setWidgetData"
    />
  </div>
</template>
<script>
import TimerProgressComponent from '../UCAnalytics/timerProgressComponent'
import { reportsMixin } from '../../mixins/reportsMixin'
export default {
  name: 'ChartMACsUsed',
  components: {
    TimerProgressComponent
  },
  mixins: [
    reportsMixin
  ],
  props: {
    widget: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      timerObj: {},
      widgetData: {},
      tickCount: 1,
      barProgress: 0
    }
  },
  computed: {
    chartLabels () {
      var rtn = []
      if (this.widget.data) {
        for (let i = 0; i < this.widget.data.length; i++) {
          rtn.push(this.widget.data[i].contractName)
        }
      }
      return rtn
    },
    pollingIntervalInSeconds () {
      return this.widget.pollingInterval
    },
    progressTitle () {
      if ((this.pollingIntervalInSeconds - Math.round(this.tickCount)) > 60) {
        return Math.round((this.pollingIntervalInSeconds - Math.round(this.tickCount)) / 60) + ' minutes until next data refresh'
      } else {
        return (this.pollingIntervalInSeconds - Math.round(this.tickCount)) + ' seconds until next data refresh'
      }
    }
  },
  mounted () {
    this.widgetData = this.widget
  },
  methods: {
    getChartOptions (widget) {
      var obj = {}
      obj = {
        series: [],
        labels: this.chartLabels,
        noData: {
          text: 'Loading data, please wait...'
        },
        chart: {
          title: this.widget.title,
          id: this.widget.id,
          height: 450,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true
        },
        xaxis: {
          categories: ['']
        },
        yaxis: {
          type: 'number'
        },
        title: {
          text: widget.title,
          floating: true,
          align: 'left',
          style: {
            color: '#444'
          }
        }
      }
      return obj
    },
    getChartSeries (widget) {
      var obj = []
      if (widget.data) {
        for (let i = 0; i < widget.data.length; i++) {
          // var elem = { name: widget.data[i].contractName, data: [widget.data[i].hours] }
          var elem = widget.data[i].hours
          obj.push(elem)
        }
      }
      return obj
    },
    // Reload the widget data on reaching the polling interval
    setWidgetData: async function (widgetId) {
      if (widgetId === this.widget.id) {
        // var rtn = await this.getWidgetById(this.id)
        // if (rtn.chartWidgets) {
        //   this.widgetData = rtn.chartWidgets[0]
        // }
      }
    },
    timestamp (utc) {
      var localDate = new Date(Date.now()).toLocaleString()
      if (utc) {
        localDate = new Date(utc).toLocaleString()
      }
      return localDate
    }
  }
}
</script>
<style scoped>

.widget-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #000000;
  border: #dddddd 1px solid;
  padding: 10px 0 0 0;
}
.widget-header-title {
  cursor: default;
  padding: 0.4em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-content-wrapper {
  overflow: visible;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
}
.widget-content {
  height: 100%;
  font-size: 2em;
}
.widget-footer {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  flex-shrink: 0;
}
.widget-footer-timestamp {
  font-size: 0.8em;
  color: #aaa;
  text-align: right;
  margin-left: auto;
}
.timestamp {
  transition: all 0.1s ease;
  color: #aaa;
  font-size: 0.8em;
  text-align: right;
  background-color: white;
  cursor: pointer;
}
.apexcharts-title-text {
  margin-bottom: 10px;
}
#controlRefreshBar {
  display: inline-block;
  width: 100%;
  border-radius: 0;
  height: 4px;
}
#controlRefreshBar[value]::-webkit-progress-bar {
  background-color: #fff;
}
#controlRefreshBar[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, rgb(218, 240, 255), rgb(218, 240, 255));
  border-radius: 0;
  background-size: 100%;
}
</style>
