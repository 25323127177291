<template>
  <div
    :id="id"
    class="permissionEntityMembersHolder"
  >
    <Spinner v-show="loading" />
    <div
      v-show="!loading"
      id="permissionMembers"
      class="prmContainer"
    >
      <table>
        <thead>
          <tr>
            <th style="width:25%">First Name</th>
            <th style="width:25%">Last Name</th>
            <th style="width:50%">Email Address</th>
          </tr>
        </thead>
        <tbody
          v-if="members.length === 0"
        >
          <tr>
            <td colspan="3">No Members Assigned</td>
          </tr>
        </tbody>
        <tbody
          v-if="members.length > 0"
        >
          <tr
            v-for="m in members"
            :key="m.id"
          >
            <td :title="m.firstName">{{ m.firstName }} </td>
            <td :title="m.lastName">{{ m.lastName }} </td>
            <td :title="m.emailAddress">{{ m.emailAddress }} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import Spinner from '../../common/spinner'
export default {
  name: 'PermissionEntityMembers',
  components: {
    Spinner
  },
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    permission: {
      type: Object,
      default: () => {}
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      members: []
    }
  },
  computed: {
    reloadMembers () {
      return this.$store.getters.reloadMembers
    }
  },
  watch: {
    permission () {
      this.getMembers()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    setComponent: async function () {
      await this.getMembers()
    },
    getMembers: async function () {
      this.loading = true
      var entities = await this.getPermissionEntityMembers(this.permission.permissionId)
      var entityList = ''
      entities.forEach(user => { entityList += '|' + user.id })
      entityList = entityList.substring(1)
      this.members = await this.getUserList(entityList)
      this.loading = false
    }
  }
}

</script>
<style scoped>
  .prmContainer {
    padding: 15px 10px 10px 10px;
  }
  .permissionEntityMembersHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    display: none;
        max-height: 300px;
    overflow-y: auto;
  }
  table thead tr th {padding:3px 5px;font-size: 0.9em;}
</style>
