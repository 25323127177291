import { mapActions } from 'vuex'
import { iQuoteFunctions } from './iQuoteFunctions'

export const iQuoteStoreMixin = {
  data: function () {
    return {
    }
  },
  mixins: [
    iQuoteFunctions
  ],
  computed: {
    /* GETTERS */
    iQuoteStore_CurrentQuoteExternalQuotesState: function () {
      return this.$store.getters.currentQuoteExternalQuotesState
    },
    iQuoteStore_GetDesignPack: function () {
      return this.$store.getters.designPack
    },
    iQuoteStore_GetDesignPackDetailedDataEntries: function () {
      return this.$store.getters.designPackDetailedDataEntries
    },
    iQuoteStore_GetLicenses: function () {
      return this.$store.getters.licenses
    },
    iQuoteStore_GetServices: function () {
      return this.$store.getters.serviceList
    },
    iQuoteStore_GetUnitCosts: function () {
      return this.$store.getters.unitCosts
    },
    iQuoteStore_GetDesignPackTableSections: function () {
      return this.$store.getters.designPackTableSections
    },
    iQuoteStore_GetVirtualMachines: function () {
      return this.$store.getters.virtualMachines
    },
    iQuoteStore_GetBladeServers: function () {
      return this.$store.getters.bladeServers
    },
    iQuoteStore_GetQuote: function () {
      return this.$store.getters.quote
    },
    iQuoteStore_GetQuoteTechnologyAvayaAura: function () {
      return this.$store.getters.quoteTechnologyAvayaAura
    },
    iQuoteStore_GetQuoteTechnologyMitelUC: function () {
      return this.$store.getters.quoteTechnologyMitelUC
    },
    iQuoteStore_GetQuoteTechnologyAvayaElite: function () {
      return this.$store.getters.quoteTechnologyAvayaElite
    },
    iQuoteStore_GetQuoteTechnologyAvayaAACC: function () {
      return this.$store.getters.quoteTechnologyAvayaAACC
    },
    iQuoteStore_GetQuoteTechnologyCallmedia: function () {
      return this.$store.getters.quoteTechnologyCallmedia
    },
    iQuoteStore_GetQuoteTechnologyCallmediaCXNow: function () {
      return this.$store.getters.quoteTechnologyCallmediaCXNow
    },
    iQuoteStore_GetQuoteTechnologyIconGateway: function () {
      return this.$store.getters.quoteTechnologyIconGateway
    },
    iQuoteStore_GetQuoteSIPMigrationProperties: function () {
      return this.$store.getters.quoteSIPMigrationProperties
    },
    iQuoteStore_GetGammaSip: function () {
      return this.$store.getters.quoteGammaSIP
    },
    iQuoteStore_GetPhoneLinePlus: function () {
      return this.$store.getters.quotePhoneLinePlus
    },
    iQuoteStore_GetTeamsConnector: function () {
      return this.$store.getters.teamsConnector
    },
    iQuoteStore_GetQuoteGenesys: function () {
      return this.$store.getters.quoteGenesys
    },
    iQuoteStore_GetQuoteSycurio: function () {
      return this.$store.getters.quoteSycurio
    },
    iQuoteStore_GetQuoteUCAnalytics: function () {
      return this.$store.getters.quoteUCAnalytics
    },
    iQuoteStore_GetQuoteRingCentral: function () {
      return this.$store.getters.quoteRingCentral
    },
    iQuoteStore_GetQuoteAvayaAXP: function () {
      return this.$store.getters.quoteAvayaAXP
    },
    iQuoteStore_GetAvayaAXPSocialMediaPackages: function () {
      return this.$store.getters.avayaAXPSocialMediaPackages
    },
    iQuoteStore_GetUCAnalyticsQuoteTypes: function () {
      return this.$store.getters.ucAnalyticsQuoteTypes
    },
    iQuoteStore_GetQuoteExpansions: function () {
      return this.$store.getters.quoteExpansions
    },
    iQuoteStore_GetQuoteTechnologyMitelCC: function () {
      return this.$store.getters.quoteTechnologyMitelCC
    },
    iQuoteStore_GetQuoteUnitCostsRequired: function () {
      return this.$store.getters.quoteUnitCosts
    },
    iQuoteStore_GetQuoteBladeServers: function () {
      return this.$store.getters.quoteBladeServers
    },
    iQuoteStore_GetQuoteDesignPackEntries: function () {
      return this.$store.getters.quoteDesignPackEntries
    },
    iQuoteStore_GetQuoteCustomerSummaryEntries: function () {
      return this.$store.getters.quoteCustomerSummaryEntries
    },
    iQuoteStore_GetQuoteVMsRequired: function () {
      return this.$store.getters.quoteVMsRequired
    },
    iQuoteStore_GetQuoteLicensesRequired: function () {
      return this.$store.getters.quoteLicensesRequired
    },
    iQuoteStore_GetCodecs: function () {
      return this.$store.getters.quoteLicensesRequired
    },
    iQuoteStore_GetDesignPackList: function () {
      return this.$store.getters.designPackList
    },
    iQuoteStore_GetCxNowApps: function () {
      return this.$store.getters.cxNowApps
    },
    iQuoteStore_GetCxNowConnectors: function () {
      return this.$store.getters.cxNowConnectors
    },
    iQuoteStore_GetCxNow: function () {
      return this.$store.getters.quoteTechnologyCallmediaCXNow
    },
    iQuoteStore_GetSalesforceCoeCodes: function () {
      return this.$store.getters.salesforceCoeCodes
    },
    iQuoteStore_GetCircuitsTableLookups: function () {
      return this.$store.getters.circuitsTableLookups
    },
    iQuoteStore_GetHardwareTableLookups: function () {
      return this.$store.getters.hardwareTableLookups
    },
    iQuoteStore_GetProfessionalServicesLookups: function () {
      return this.$store.getters.professionalServicesLookups
    }
  },
  methods: {
    ...mapActions([
      'setQuoteVMsRequired',
      'setQuoteLicensesRequired',
      'setQuoteDesignPackEntries',
      'setQuoteUnitCosts',
      'setQuoteCustomerSummaryEntries'
    ]),

    iQuoteStore_DisplayUserNameFromId: function (id) {
      var user = this.iQuoteStore_iQuoteUserFromStoreById(id)
      if (id === null) {
        return 'User Not Recorded'
      } else if ((user.firstName === undefined || user.firstName === null) && (user.lastName === undefined || user.lastName === null)) {
        return 'Unknown User (' + id + ')'
      } else if (user.lastName === undefined || user.lastName === null) {
        return user.firstName
      } else if (user.firstName === undefined || user.firstName === null) {
        return user.lastName
      } else {
        return user.firstName + ' ' + user.lastName
      }
    },
    iQuoteStore_iQuoteUserFromStoreById: function (id) {
      var users = this.$store.getters.quoteUsers
      var foundUser = {}

      if (id === 0) {
        return {}
      } else {
        users.forEach(user => {
          if (user.id === id) {
            foundUser = user
          }
        })

        if (foundUser.id !== undefined) {
          return foundUser
        } else {
          return {}
        }
      }
    },
    iQuoteStore_getDefaultCxNowConnectors () {
      var cxNowConnectors = this.$store.getters.cxNowConnectors
      var quoteCxNowConnectors = []

      cxNowConnectors.forEach(connector => {
        quoteCxNowConnectors.push({
          id: 0,
          quoteId: this.$store.getters.quote.id,
          code: connector.code,
          description: connector.description,
          callmediaCxNowConnectorId: connector.id,
          maintelPsCost: connector.maintelPsCost,
          connectivityCost: connector.connectivityCost,
          thirdPartyCost: connector.thirdPartyCost,
          selected: false
        })
      })

      return quoteCxNowConnectors
    },
    iQuoteStore_getDefaultCxNowApps () {
      var cxNowApps = this.$store.getters.cxNowApps
      var quoteCxNowApps = []

      cxNowApps.forEach(app => {
        quoteCxNowApps.push({
          id: 0,
          quoteId: this.$store.getters.quote.id,
          callmediaCxNowAppId: app.id,
          code: app.code,
          description: app.description,
          maintelPsCost: app.maintelPsCost,
          connectivityCost: app.connectivityCost,
          thirdPartyCost: app.thirdPartyCost,
          selected: false,
          quantity: 0,
          quantityTooltip: app.quantityTooltip,
          quantityDescription: app.quantityDescription
        })
      })

      return quoteCxNowApps
    },
    iQuoteStore_getDefaultQuoteTechnologyUserProfiles (technologyId, quoteTechnologyId) {
      var allUserProfiles = this.$store.getters.technologyUserProfiles
      var userProfilesForTech = []

      allUserProfiles.forEach(userProfile => {
        if (userProfile.technologyId === technologyId) {
          userProfilesForTech.push({
            id: 0,
            additionalInfo: userProfile.additionalInfo,
            cost: userProfile.cost,
            technologyUserProfileId: userProfile.id,
            technologyId: userProfile.technologyId,
            userProfileCategoryId: userProfile.userProfileCategoryId,
            description: userProfile.userProfileDescription,
            warning: userProfile.warning,
            minCommitment: 0,
            maxAllowed: 0,
            quoteTechnologyId: quoteTechnologyId
          })
        }
      })

      return userProfilesForTech
    },
    iQuoteStore_getAllQuoteTechnologyUserProfilesApprovedRCPrice (userProfiles, userProfilesApprovedRCPrices) {
      let allUserProfilesApprovedRCPrice = userProfilesApprovedRCPrices?.length ? userProfilesApprovedRCPrices.slice() : []

      const addNewItem = (item) => {
        allUserProfilesApprovedRCPrice.push({
          id: 0,
          technologyUserProfileId: item.technologyUserProfileId,
          quoteTechnologyId: item.quoteTechnologyId,
          buyPrice: ''
        })
      }
      if (userProfiles?.length) {
        userProfiles.forEach(item => {
          if (userProfilesApprovedRCPrices?.length) {
            const approvedRCPrice = userProfilesApprovedRCPrices.find(w => w.quoteTechnologyId === item.quoteTechnologyId &&
                w.technologyUserProfileId === item.technologyUserProfileId)
            if (approvedRCPrice === undefined) {
              addNewItem(item)
            }
          } else {
            addNewItem(item)
          }
        })
      }
      return allUserProfilesApprovedRCPrice
    },
    iQuoteStore_getDefaultQuoteTechnologyHardware (technologyId, quoteTechnologyId) {
      var allHardware = this.$store.getters.technologyHardware
      var hardwareForTech = []

      allHardware.forEach(hardware => {
        if (hardware.technologyId === technologyId) {
          hardwareForTech.push({
            id: 0,
            additionalInfo: hardware.additionalInfo,
            cost: hardware.cost,
            description: hardware.hardwareDescription,
            technologyHardwareId: hardware.id,
            technologyId: hardware.technologyId,
            warning: hardware.warning,
            quantity: 0,
            quoteTechnologyId: quoteTechnologyId
          })
        }
      })

      return hardwareForTech
    },

    /* ADD ENTRIES TO STORE LISTS */

    iQuoteStore_AddRequiredVmToQuote: function (virtualMachineId, dataCentreId, active, quantity, additionalStorageGbStandard = 0, additionalStorageGbArchive = 0, isExpansionVm = false, allowZertoInGoswell = false) {
      if (quantity === 0) {
        return
      }

      var quoteVMsRequired = this.iQuoteStore_GetQuoteVMsRequired
      var quoteVMsAvailable = this.iQuoteStore_GetVirtualMachines
      var vmAdded = false
      var expansionQuantity = 0

      if (isExpansionVm) {
        expansionQuantity = quantity
      }

      // See if we have already added at least one of these servers on the quote for this data centre already.  If so, we just need to increment the quantity
      quoteVMsRequired.forEach(vm => {
        if (vm.virtualMachineId === virtualMachineId && vm.dataCentreId === dataCentreId) {
          vmAdded = true
          vm.quantity += quantity
          vm.expansionQuantity += expansionQuantity
        }
      })

      // If we haven't already added this VM to the quote for this datacentre, we need to copy the specs and cost from the list of available VMs
      if (!vmAdded) {
        quoteVMsAvailable.forEach(vm => {
          if (vm.id === virtualMachineId) {
            // ignore requests to put servers in goswell if the server has zerto, as the backup isn't needed.
            if (dataCentreId === this.iQuoteConstants.dataCentres.fareham || (dataCentreId === this.iQuoteConstants.dataCentres.goswellRoad && !vm.specification.zerto) || allowZertoInGoswell) {
              quoteVMsRequired.push({
                virtualMachineId: vm.id,
                virtualMachineSpecId: vm.specification.id,
                dataCentreId: dataCentreId,
                quantity: quantity,
                expansionQuantity: expansionQuantity,
                active: active,
                additionalStorageGbStandard: Math.ceil(additionalStorageGbStandard),
                additionalStorageGbArchive: Math.ceil(additionalStorageGbArchive),
                cost: 0 /* Cost of the VM will be worked out later when we know what percentage of a blade it needs */
              })
              vmAdded = true
            }
          }
        })
      }

      this.setQuoteVMsRequired(quoteVMsRequired)
    },
    iQuoteStore_AddRequiredLicenseToQuote: function (licenseId, quantity, overridePrice = null, overrideVisibleToCustomer = null, overrideIncludeInCalculations = null, overrideCost = null) {
      if (quantity === 0) {
        return
      }

      var quoteLicensesRequiredStore = this.iQuoteStore_GetQuoteLicensesRequired

      var licenseAdded = false

      var license

      this.$store.getters.licenses.forEach(availableLicense => {
        if (availableLicense.id === licenseId) {
          license = availableLicense
        }
      })

      if (license === undefined) {
        console.log('License ' + licenseId + ' not found')
        return
      }

      var price = license.price
      var cost = license.cost

      var annualPrice
      var annualCost

      // See if we have already added at least one of these licenses on the quote.  If so, we just need to increment the quantity
      quoteLicensesRequiredStore.forEach(license => {
        if (license.licenseId === licenseId) {
          licenseAdded = true
          license.quantity += quantity

          if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
            annualPrice = price * license.quantity * 12
            annualCost = cost * license.quantity * 12
          } else if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.YEARLY) {
            annualPrice = price * license.quantity
            annualCost = cost * license.quantity
          } else if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.ONE_OFF) {
            annualPrice = 0
            annualCost = 0
          }

          license.annualPrice = Number(annualPrice.toFixed(2))
          license.annualCost = Number(annualCost.toFixed(2))
        }
      })

      // If we haven't already added this license to the quote, we need to add a new entry to the list
      if (!licenseAdded) {
        var visibleToCustomer = license.visibleToCustomer
        var includeInCalculations = license.includeInCalculations

        if (overridePrice !== null) {
          price = overridePrice
        }

        if (overrideCost !== null) {
          cost = overrideCost
        }

        if (overrideIncludeInCalculations !== null) {
          includeInCalculations = overrideIncludeInCalculations
        }

        if (overrideVisibleToCustomer !== null) {
          visibleToCustomer = overrideVisibleToCustomer
        }

        if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
          annualPrice = price * quantity * 12
          annualCost = cost * quantity * 12
        } else if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.YEARLY) {
          annualPrice = price * quantity
          annualCost = cost * quantity
        } else if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.ONE_OFF) {
          annualPrice = 0
          annualCost = 0
        }

        quoteLicensesRequiredStore.push({
          id: this.iQuoteStore_getTempLicenseId(),
          code: license.code,
          description: license.description,
          licenseId: licenseId,
          quantity: quantity,
          cost: cost,
          price: price,
          annualPrice: Number(annualPrice.toFixed(2)),
          annualCost: Number(annualCost.toFixed(2)),
          visibleToCustomer: visibleToCustomer,
          includeInCalculations: includeInCalculations,
          serviceId: license.serviceId,
          isPrimaryUserLicense: license.isPrimaryUserLicense,
          chargeIntervalId: license.chargeIntervalId,
          priceOverriden: (overridePrice !== null),
          designPackTableSectionId: license.designPackTableSectionId,
          showOnExportATCodesList: license.showOnExportATCodesList
        })
        licenseAdded = true
      }

      this.setQuoteLicensesRequired(quoteLicensesRequiredStore)
    },
    iQuoteStore_SetRequiredUnitCostPriceWithMargin: function (unitCostId, margin) {
      var quoteUnitCostsRequired = this.iQuoteStore_GetQuoteUnitCostsRequired

      quoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === unitCostId) {
          unitCost.price = unitCost.cost / ((100 - margin) / 100)
        }
      })

      this.setQuoteUnitCosts(quoteUnitCostsRequired)
    },
    iQuoteStore_AddRequiredUnitCostToQuote: function (unitCostId, quantity, overrideUnitCostPerYear = null, overrideCost = null, overridePrice = null) {
      if (quantity === 0) {
        return
      }

      var quoteUnitCostsRequired = this.iQuoteStore_GetQuoteUnitCostsRequired
      var unitCostAdded = false
      var unitCostPerYear = 0.0
      var unitCostPricePerYear = 0.0
      var cost
      var price

      const isTeamsIconBandwidthContribution = unitCostId === this.iQuoteConstants.unitCosts.TEAMS_ICON_BANDWIDTH_CONTRIBUTION
      quantity = isTeamsIconBandwidthContribution ? Number(Math.round(quantity)) : Number(quantity.toFixed(2))
      if (overrideUnitCostPerYear !== null) {
        unitCostPerYear = overrideUnitCostPerYear
      } else {
        unitCostPerYear = this.utilFunctions_GetUnitCostAmountPerYear(unitCostId) * quantity
      }

      if (unitCostPerYear !== null) {
        unitCostPerYear = this.iQuoteFunctions_RoundToFourDecimalPlaces(unitCostPerYear)
      }

      if (overrideCost !== null) {
        cost = overrideCost
      } else {
        cost = this.utilFunctions_GetUnitCostCost(unitCostId)
      }

      cost = this.iQuoteFunctions_RoundToFourDecimalPlaces(cost)

      if (overridePrice !== null) {
        price = overridePrice
        price = this.iQuoteFunctions_RoundToTwoDecimalPlaces(price)

        var defaultUnitCost = this.utilFunctions_GetUnitCostFromId(unitCostId)

        if (defaultUnitCost.paymentsPerYear !== -1) {
          unitCostPricePerYear = price * defaultUnitCost.paymentsPerYear
        } else {
          unitCostPricePerYear = price / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
        }
      } else {
        price = this.utilFunctions_GetUnitCostPrice(unitCostId)
        unitCostPricePerYear = this.utilFunctions_GetUnitCostPriceAmountPerYear(unitCostId)
      }

      if (unitCostPricePerYear === 'ERROR') {
        unitCostPricePerYear = 'ERROR'
      } else {
        unitCostPricePerYear *= quantity
        unitCostPricePerYear = this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitCostPricePerYear)
      }

      // console.log('adding unitCost ' + unitCostId + ' value:' + unitCostPerYear)

      // See if we have already added at least one of these unitCosts on the quote.  If so, we just need to increment the quantity
      quoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === unitCostId) {
          unitCostAdded = true
          unitCost.quantity += quantity
          unitCost.yearlyCost = this.iQuoteFunctions_RoundToFourDecimalPlaces(unitCost.yearlyCost + unitCostPerYear)
          unitCost.yearlyPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitCost.yearlyPrice + unitCostPricePerYear)
        }
      })

      // If we haven't already added this unitCost to the quote, we need to add a new entry to the list
      if (!unitCostAdded) {
        var availableUnitCosts = this.$store.getters.unitCosts

        availableUnitCosts.forEach(availableUnitCost => {
          if (availableUnitCost.id === unitCostId) {
            quoteUnitCostsRequired.push({
              id: this.iQuoteStore_getTempUnitCostId(),
              name: availableUnitCost.name,
              description: availableUnitCost.description,
              recurringCost: availableUnitCost.recurringCost,
              unitCostId: unitCostId,
              quantity: quantity,
              cost: cost,
              price: price,
              yearlyCost: unitCostPerYear,
              yearlyPrice: unitCostPricePerYear,
              unitCostCategoryId: availableUnitCost.unitCostCategoryId,
              visibleToCustomer: availableUnitCost.visibleToCustomer,
              showOnExportATCodesList: availableUnitCost.showOnExportATCodesList,
              includeInCalculations: availableUnitCost.includeInCalculations,
              serviceId: availableUnitCost.serviceId,
              paymentsPerYear: availableUnitCost.paymentsPerYear,
              costTypeId: availableUnitCost.costTypeId,
              designPackTableSectionId: availableUnitCost.designPackTableSectionId,
              startYear: availableUnitCost.startYear,
              endYear: availableUnitCost.endYear,
              quoteDescription: availableUnitCost.quoteDescription
            })
          }
        })
      }

      this.setQuoteUnitCosts(quoteUnitCostsRequired)
    },
    iQuoteStore_AddDesignPackEntryToQuote: function (designPackEntryTypeId, description, SF_COE,
      upfrontExternalCost, upfrontInternalCost,
      recurringExternalCost, recurringInternalCost,
      designPackTableSectionId,
      upfrontExternalPrice, upfrontInternalPrice,
      recurringExternalPrice, recurringInternalPrice,
      upfrontExternalHiddenCost, upfrontInternalHiddenCost,
      recurringExternalHiddenCost, recurringInternalHiddenCost,
      upfrontExternalQuoteOnlyCost, upfrontInternalQuoteOnlyCost,
      recurringExternalQuoteOnlyCost, recurringInternalQuoteOnlyCost) {
      var quoteDesignPackEntries = this.$store.getters.quoteDesignPackEntries

      var newEntry = {
        designPackEntryTypeId: designPackEntryTypeId,
        SF_COE: SF_COE,
        description: description,
        upfrontExternalCost: upfrontExternalCost,
        upfrontInternalCost: upfrontInternalCost,
        recurringExternalCost: recurringExternalCost,
        recurringInternalCost: recurringInternalCost,
        designPackTableSectionId: designPackTableSectionId,
        upfrontExternalPrice: upfrontExternalPrice,
        upfrontInternalPrice: upfrontInternalPrice,
        recurringExternalPrice: recurringExternalPrice,
        recurringInternalPrice: recurringInternalPrice,
        upfrontExternalHiddenCost: upfrontExternalHiddenCost,
        upfrontInternalHiddenCost: upfrontInternalHiddenCost,
        recurringExternalHiddenCost: recurringExternalHiddenCost,
        recurringInternalHiddenCost: recurringInternalHiddenCost,
        upfrontExternalQuoteOnlyCost: upfrontExternalQuoteOnlyCost,
        upfrontInternalQuoteOnlyCost: upfrontInternalQuoteOnlyCost,
        recurringExternalQuoteOnlyCost: recurringExternalQuoteOnlyCost,
        recurringInternalQuoteOnlyCost: recurringInternalQuoteOnlyCost
      }

      quoteDesignPackEntries.push(newEntry)

      this.setQuoteDesignPackEntries(quoteDesignPackEntries)
    },
    iQuoteStore_AddCustomerSummaryEntryToQuote: function (customerSummaryCategoryId, description, quantity, price, annualPrice, isAnnualCharge) {
      var quoteCustomerSummaryEntries = this.$store.getters.quoteCustomerSummaryEntries

      price = Number(price.toFixed(2))

      if (annualPrice !== null) {
        annualPrice = Number(annualPrice.toFixed(2))
      }

      quoteCustomerSummaryEntries.push({
        customerSummaryCategoryId: customerSummaryCategoryId,
        description: description,
        sortOrder: quoteCustomerSummaryEntries.length + 1,
        quantity: quantity,
        price: price,
        annualPrice: annualPrice,
        isAnnualCharge: isAnnualCharge
      })

      this.setQuoteCustomerSummaryEntries(quoteCustomerSummaryEntries)
    },
    iQuoteStore_getTempUnitCostId () {
      /* items that are not saved to the db yet will have negative ids */
      var maxId = -1000

      var arr = this.iQuoteStore_GetQuoteUnitCostsRequired

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id > maxId) {
          maxId = arr[i].id + 1
        }
      }

      return maxId
    },
    iQuoteStore_getTempLicenseId () {
      /* items that are not saved to the db yet will have negative ids */
      var maxId = -1000

      var arr = this.iQuoteStore_GetQuoteLicensesRequired

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id > maxId) {
          maxId = arr[i].id + 1
        }
      }

      return maxId
    }
  },
  mounted () {
  }
}
