<template>
  <div class="quotePanelContainer">
    <div>
      <strong>Choose the Quote to base this design pack on:</strong>
      <br>
      <p v-if="createDesignPackFromQuoteComponent.searchResults.length === 0 && !createDesignPackFromQuoteComponent.searchingQuotes">No Quotes Found!</p>
      <div
        :class="{mdHalfScreen : !!createDesignPackFromQuoteComponent.selectedQuote}"
        class="mdQuotes">
        <div
          v-show="createDesignPackFromQuoteComponent.searchResults.length !== 0">
          <table
            id="quotesTable">
            <thead />
            <tbody>
              <tr
                v-for="v in currentFilter"
                :key="v.id"
                :tabindex="20"
                @click="selectQuote(v)"
                @keyup="testSelectQuote($event, v)"
              >
                <td>{{ v.quoteOpportunityId }}</td>
                <td>{{ v.revisionNumber }}</td>
                <td>{{ v.salesforceQuoteNumber }}</td>
                <td>{{ v.salesforceAccountName }}</td>
                <td>{{ v.quoteDescription }}</td>
                <td>{{ stateTextFromId(v.quoteStateId) }}</td>
                <td>{{ iQuoteStore_DisplayUserNameFromId(v.createdByUserId) }}</td>
                <td>{{ iQuoteStore_DisplayUserNameFromId(v.lastEditedByUserId) }}</td>
                <td>{{ displayDate(v.dateLastEdited) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="!!createDesignPackFromQuoteComponent.selectedQuote"
      class="mdSelection"
      style="background-color: #fff;"
    >
      <SectionCollapsedHeader
        id="sectionQuoteSummary"
        :start-collapsed="false"
        :enable-click="true"
        title="Quote Details"
        type="subHeader"
        icon-file="Edit"
        controls="quoteSummaryComponent"
      />
      <div
        id="quoteSummaryComponent">
        <quoteSummary
          :loading="createDesignPackFromQuoteComponent.loadingQuote"
          :buttons="['Create Design Pack Using This Quote']"
          @buttonClicked="handleQuoteSummaryButtonClicked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { mapActions } from 'vuex'
import { iQuoteAdjunctDataAPIMixin } from '../../../mixins/iQuote/iQuoteAdjunctDataAPIMixin'
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import tableHandler from '../../../mixins/tableHandler'
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'
import quoteSummary from '../panels/quoteSummary'
import { usersMixin } from '../../../mixins/users'
import { iQuoteLoaderMixin } from '../../../mixins/iQuote/utils/iQuoteLoaderMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'
import { iQuoteDuplicateMixin } from '../../../mixins/iQuote/utils/iQuoteDuplicateMixin'

export default {
  name: 'CreateDesignPackFromQuote',
  components: {
    TooltipIcon,
    SectionCollapsedHeader,
    quoteSummary
  },
  mixins: [
    usersMixin,
    tableHandler,
    iQuoteConstants,
    ShowHelpMixin,
    iQuoteAdjunctDataAPIMixin,
    iQuoteQuoteAPIMixin,
    iQuoteFunctions,
    iQuoteStoreMixin,
    iQuoteLoaderMixin,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackMixin,
    iQuoteDuplicateMixin
  ],
  props: {
    salesforceQuoteNumber: {
      type: String,
      default: function () {
        return ''
      }
    },
    salesforceOpportunity: {
      type: Object,
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      createDesignPackFromQuoteComponent: {
        loadingQuote: false,
        searchingQuotes: false,
        selectedQuote: null,
        lastEditedUser: {},
        createdByUser: {},
        searchResults: [],
        searchedSalesforceQuoteNumber: ''
      }
    }
  },
  computed: {
    lastEditedByName () {
      if (this.createDesignPackFromQuoteComponent.selectedQuote === null) {
        return
      }
      return this.iQuoteStore_DisplayUserNameFromId(this.createDesignPackFromQuoteComponent.selectedQuote.lastEditedByUserId)
    },
    createdByName () {
      if (this.createDesignPackFromQuoteComponent.selectedQuote === null) {
        return
      }
      return this.iQuoteStore_DisplayUserNameFromId(this.createDesignPackFromQuoteComponent.selectedQuote.createdByUserId)
    },
    displayQuoteRef () {
      if (this.createDesignPackFromQuoteComponent.selectedQuote === null) {
        return
      }
      if (this.createDesignPackFromQuoteComponent.selectedQuote.id === null || this.createDesignPackFromQuoteComponent.selectedQuote.id === 0) {
        return '<Not Saved Yet>'
      } else {
        return this.createDesignPackFromQuoteComponent.selectedQuote.id
      }
    },
    contactFullName () {
      if (this.createDesignPackFromQuoteComponent.selectedQuote === null) {
        return
      }

      if (this.createDesignPackFromQuoteComponent.selectedQuote.salesforceContactFirstName !== null && this.createDesignPackFromQuoteComponent.selectedQuote.salesforceContactLastName !== null) {
        return this.createDesignPackFromQuoteComponent.selectedQuote.salesforceContactFirstName + ' ' + this.createDesignPackFromQuoteComponent.quote.salesforceContactLastName
      } else {
        return ''
      }
    },
    quoteUsers () {
      return this.$store.getters.quoteUsers
    }
  },
  watch: {
    salesforceQuoteNumber () {
      this.searchForQuotes()
    }
  },
  mounted () {
    this.searchForQuotes()
  },
  created () {
  },
  updated () {
  },
  methods: {
    ...mapActions([
    ]),
    selectQuote: async function (selectedQuote) {
      if (this.createDesignPackFromQuoteComponent.selectedQuote !== null && this.createDesignPackFromQuoteComponent.selectedQuote.id === selectedQuote.id) {
        this.createDesignPackFromQuoteComponent.selectedQuote = null
      } else {
        this.createDesignPackFromQuoteComponent.selectedQuote = selectedQuote
        this.createDesignPackFromQuoteComponent.loadingQuote = true
        await this.iQuoteLoaderMixin_loadQuoteIntoStore(selectedQuote.id)
        this.createDesignPackFromQuoteComponent.loadingQuote = false
      }
    },
    async createDesignPack (designPackName) {
      var opportunity = {
        opportunityId: this.createDesignPackFromQuoteComponent.selectedQuote.salesforceOpportunityId,
        quoteNumber: this.createDesignPackFromQuoteComponent.selectedQuote.salesforceQuoteNumber,
        contactFirstName: this.createDesignPackFromQuoteComponent.selectedQuote.salesforceContactFirstName,
        contactLastName: this.createDesignPackFromQuoteComponent.selectedQuote.salesforceContactLastName,
        contactEmail: this.createDesignPackFromQuoteComponent.selectedQuote.salesforceContactEmail,
        opportunityName: this.createDesignPackFromQuoteComponent.selectedQuote.salesforceOpportunityName,
        accountName: this.createDesignPackFromQuoteComponent.selectedQuote.salesforceAccountName
      }

      var quote = this.iQuoteStore_GetQuote
      if (this.iQuoteStore_GetQuote.designPacksLinked.length > 0) {
        quote = await this.iQuoteDuplicate_CreateDuplicateQuote(this.iQuoteStore_GetQuote, false, true)
      }

      var returnedOpportunity = await this.createOpportunity(opportunity)
      var salesforceOpportunityId = returnedOpportunity.id

      var newDesignPack = {
        quoteDesignPackId: 0,
        name: designPackName,
        quoteId: quote.id,
        termLengthMonths: quote.termLengthMonths,
        salesforceOpportunityId: salesforceOpportunityId,
        quoteDesignPackRevisionId: 0,
        revisionNumber: 1,
        revisions: [{
          id: 0,
          revisionNumber: 1
        }],
        users: [],
        description: '',
        manufacturerCode: '',
        projectName: '',
        numberOfSites: 1,
        newOrExistingCustomer: '',
        upgradeMigration: false,
        pmoReviewer: '',
        maintenanceTakeOn: false,
        contractFrameworkId: 1,
        professionalServicesReview: false,
        newManufacturerCodeRequired: false,
        contractIdCoterminatedWith: '',
        showPSTab: true,
        showAutotaskTab: true,
        showHardwareTab: false,
        showCircuitsTab: false,
        showStandardCaveatsTab: false,
        showCeasesTab: false,
        dpExported: false,
        psCoreEntries: [],
        psOptionsEntries: [],
        psExpenseEntries: [],
        psThirdPartyInstallationEntries: [],
        psThirdPartyOtherEntries: [],
        hardwareMainEntries: [],
        hardwareMiscEntries: [],
        circuitsEquinixCloudConnectEntries: [],
        circuitsWanConnectivityEntries: [],
        circuitsHostingAndCablingEntries: [],
        circuitsBroadbandEntries: [],
        circuitsFixedLineVoiceEntries: [],
        circuitsIpVpnSetupEntries: [],
        margins: [],
        discounts: [],
        priceOverrides: [],
        exchangeRateUSDToGPB: null,
        exchangeRateUSDToGPBDate: null,
        exchangeRateGBPToEUR: null,
        exchangeRateGBPToEURDate: null,
        autotaskCustAccNo: '',
        locked: false,
        createdByUserId: this.$store.getters.userid,
        hldReference: '',
        pmoReview: '',
        createdBy: '',
        disabledEntries: [],
        addPCTime: false,
        useDetailDataForSummaries: true,
        chargeUpfront: quote.chargeUpfront,
        ccUsers: 0,
        ucUsers: 0,
        ccUsersOverride: null,
        ucUsersOverride: null,
        standardCaveats: '',
        ceasesEntries: [],
        marginCircuitsEquinixCloudConnectCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsEquinixCloudConnectOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsHostingAndCablingCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsHostingAndCablingOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsWanConnectivityCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsWanConnectivityOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsBroadbandCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsBroadbandOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsFixedLineVoiceCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsFixedLineVoiceOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginHardwareMainPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginHardwareMiscEquipmentPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        isReadOnly: false,
        quoteDesignPackTypeId: this.iQuoteConstants.QuoteDesignPackTypes.FROM_QUOTE,
        externalDesignPackFileUploadCatalogId: null,
        externalDPEntries: [],
        editedDesignOverviewEntry: null,
        editedDetailedSummaryBreakdownEntries: [],
        editedAutotaskEntries: [],
        serviceLevelTypeId: 0,
        ccaasProvider: '',
        ucaasProvider: '',
        removedPCTime: false,
        lastDesignPackItemLookupId: 0,
        priceOverrideARFrameworkCharge: null,
        priceOverrideOOFrameworkCharge: null,
        priceOverrideOrderCoordination: null,
        disableSupportContingency: false
      }

      var savedDesignPack = await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(newDesignPack)

      const action = 'Created DP from quote'
      await this.iQuoteQuoteDesignPacksAPIMixin_addAuditing(action, 1, null)

      this.$emit('designpack-selected', savedDesignPack)
    },
    async handleQuoteSummaryButtonClicked (button) {
      var designPackName = this.createDesignPackFromQuoteComponent.selectedQuote.salesforceQuoteNumber + ' - ' +
                           this.createDesignPackFromQuoteComponent.selectedQuote.salesforceAccountName + ' ' +
                           this.createDesignPackFromQuoteComponent.selectedQuote.quoteDescription

      await this.createDesignPack(designPackName)
    },
    displayDate: function (dateToFormat) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' }

      if (dateToFormat === '0001-01-01T00:00:00') {
        return ''
      } else {
        return new Date(dateToFormat).toLocaleDateString('en-gb', options)
      }
    },
    initialiseFromStore () {
    },
    setTable: function () {
      this.initialiseTable('quotesTable', this.createDesignPackFromQuoteComponent.searchResults,
        ['quoteOpportunityId', 'revisionNumber', 'quoteNumber', 'accountName', 'quoteDescription', 'quoteStateId', 'createdByUserId', 'lastEditedByUserId', 'dateLastEdited'],
        ['iQuote Id', 'Revision', 'SF Quote Number', 'Account Name', 'Description', 'State', 'Created By', 'Last Edited By', 'Date Last Edited'],
        null,
        '400px',
        ['70px', '70px', '140px', '', '100px', '140px', '140px', '110px'],
        10)
    },
    stateTextFromId: function (id) {
      switch (id) {
        case this.iQuoteConstants.quoteStates.UNPUBLISHED:
          return 'Unpublished'
        case this.iQuoteConstants.quoteStates.PUBLISHED:
          return 'Published'
        case this.iQuoteConstants.quoteStates.EXPIRED:
          return 'Expired'
        case this.iQuoteConstants.quoteStates.ORDERED:
          return 'Ordered'
        case this.iQuoteConstants.quoteStates.SUPERSEDED:
          return 'Superseded'
      }
    },
    searchForQuotes: async function () {
      var foundCreatedUser = {}
      var foundLastEditedUser = {}
      var foundNewUsersToAddToStore = false
      var userFromUserService = {}

      var searchStartDate = new Date()
      var searchEndDate = new Date()

      if (this.createDesignPackFromQuoteComponent.searchedSalesforceQuoteNumber === this.salesforceQuoteNumber) {
        return
      }

      this.createDesignPackFromQuoteComponent.searchedSalesforceQuoteNumber = this.salesforceQuoteNumber

      if (this.salesforceQuoteNumber.length !== 7) {
        this.createDesignPackFromQuoteComponent.searchResults = []
        return
      }

      this.createDesignPackFromQuoteComponent.searchingQuotes = true

      searchStartDate.setDate(searchStartDate.getDate() - 10000)
      searchStartDate.setHours(0, 0, 0, 0)
      searchEndDate.setHours(23, 59, 59, 999)

      if (this.$store.getters.viewiQuotePermission) {
        searchEndDate.setHours(23, 59, 59, 999)
        this.createDesignPackFromQuoteComponent.searchResults = await this.searchQuotes(this.salesforceQuoteNumber, searchStartDate, searchEndDate)

        var users = this.quoteUsers

        // Loop through every search result and find the users contained within the result set
        for (let index = 0; index < this.createDesignPackFromQuoteComponent.searchResults.length; index++) {
          const quote = this.createDesignPackFromQuoteComponent.searchResults[index]
          foundCreatedUser = {}
          foundLastEditedUser = {}

          // Check the users store for details of users that created or modified the quote
          users.forEach(user => {
            if (user.id === quote.createdByUserId) {
              foundCreatedUser = user
            }
            if (user.id === quote.lastEditedByUserId) {
              foundLastEditedUser = user
            }
          })

          // Created By User wasn't in the store, so query details from the user service and add to the store
          if (foundCreatedUser.id === undefined && quote.createdByUserId !== 0) {
            userFromUserService = await this.getUserById(quote.createdByUserId)

            if (userFromUserService.id !== 0) {
              users.push(userFromUserService)
              foundNewUsersToAddToStore = true
            }
          }

          // Last Edited User wasn't in the store, so query details from the user service and add to the store
          if (foundLastEditedUser.id === undefined && quote.lastEditedByUserId !== quote.createdByUserId && quote.lastEditedByUserId !== 0) {
            userFromUserService = await this.getUserById(quote.lastEditedByUserId)
            if (userFromUserService.id !== 0) {
              users.push(userFromUserService)
              foundNewUsersToAddToStore = true
            }
          }
        }

        if (foundNewUsersToAddToStore) {
          this.setQuoteUsers(users)
        }

        this.setTable()

        if (this.createDesignPackFromQuoteComponent.searchResults.length === 0) {
          this.selectQuote(null)
        }
      }

      this.createDesignPackFromQuoteComponent.searchingQuotes = false
    }
  }
}
</script>
<style scoped>
  .quotePanel {
    width: 580px;
    text-align: left;
  }

  .mdContainer{
    display: flex;
    flex-direction: row;
  }

  .mdSelection{
    width:600px;
    margin: 0 0 0 10px;
    float:right;
  }
  .mdQuotes{
    margin: 0;
    min-height: calc(100vh - 300px);
    float:left;
  }
  .mdQuotes.mdHalfScreen{
    width:calc(100% - 620px);
    min-height: calc(100vh - 50px);
  }

  #txtDesignPackName {width: 400px;}

  #txtAccount {width:200px;}
  #txtContact {width:160px;}
  #txtContactEmail {width:200px;}
  #txtOpportunityName {width:200px;}
  #txtQuoteDescription {width:200px;}
  #txtFirstName {width:54px;}
  #txtLastName {width:54px;}

  #quotesTable {
    margin-bottom: 60px;
  }
</style>
