import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { mapActions } from 'vuex'

export const iQuoteCalculationsAvayaEliteMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin],
  data () {
    return {
      quoteTechnologyAvayaElite: {},
      quote: {},
      licenses: {}
    }
  },
  computed: {
    eliteCalcs_usersToHandle () {
      if (this.quoteTechnologyAvayaElite.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyAvayaElite.userProfiles.forEach(userProfile => {
        users += userProfile.maxAllowed
      })
      return users
    },
    eliteCalcs_usersToLicense () {
      if (this.quoteTechnologyAvayaElite.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyAvayaElite.userProfiles.forEach(userProfile => {
        users += userProfile.minCommitment
      })
      return users
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyAvayaElite'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcAvayaEliteVMs: function () {
      this.quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite

      if (this.quoteTechnologyAvayaElite.id === undefined) {
        return
      }

      this.quote = this.$store.getters.quote

      this.calcElite_VMs()
      this.calcEliteAdjunct_VMs()
    },
    calcElite_VMs: function () {
      var usersToAllocate = this.eliteCalcs_usersToHandle

      while (usersToAllocate > 0) {
        while (usersToAllocate > 5000) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.ELITE_CMS_PROFILE_3, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.ELITE_CMS_PROFILE_3, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          usersToAllocate -= 10000
        }

        while (usersToAllocate > 500) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.ELITE_CMS_PROFILE_2, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.ELITE_CMS_PROFILE_2, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          usersToAllocate -= 5000
        }

        while (usersToAllocate > 0) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.ELITE_CMS_PROFILE_1, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.ELITE_CMS_PROFILE_1, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          usersToAllocate -= 500
        }
      }
    },
    calcEliteAdjunct_VMs: function () {
      if (this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls > 0) {
        var storagePeriodYears
        const contingencyMultiplier = 1.1
        const storagePerHourMb = 4.21 /* G.729A Mono */

        if (this.quoteTechnologyAvayaElite.adjuncts.retentionPeriod > this.quote.termLengthMonths) {
          storagePeriodYears = this.quoteTechnologyAvayaElite.adjuncts.retentionPeriod / 12
        } else {
          storagePeriodYears = this.quote.termLengthMonths / 12
        }

        const workingDaysPerWeek = 7
        const weeksPerYear = 52
        var callsPerYear = this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls * this.quoteTechnologyAvayaElite.adjuncts.numCallsPerAgentPerDay * workingDaysPerWeek * weeksPerYear

        const minutesInAnHour = 60
        var hoursOfRecordingsPerYear = ((callsPerYear * this.quoteTechnologyAvayaElite.adjuncts.avgCallDuration) / minutesInAnHour)
        var storageRequiredMb = hoursOfRecordingsPerYear * contingencyMultiplier * storagePerHourMb * storagePeriodYears
        var storageRequiredGb = storageRequiredMb / 1000

        console.log('storage required for Elite Recordings = ' + storageRequiredGb + 'Gb')

        if (this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls <= 150) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_CONSOLIDATED, this.iQuoteConstants.dataCentres.fareham, true, 1, storageRequiredGb)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_THALES_KMS, this.iQuoteConstants.dataCentres.fareham, true, 1)
        } else {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_APPLICATION, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_DATABASE, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_WFO_RECORDER, this.iQuoteConstants.dataCentres.fareham, true, 1, storageRequiredGb)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AVAYA_WFO_THALES_KMS, this.iQuoteConstants.dataCentres.fareham, true, 1)
        }
      }

      if (this.quoteTechnologyAvayaElite.adjuncts.wfm) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFM, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFM, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.quoteTechnologyAvayaElite.adjuncts.dpa) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.DPA, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.DPA, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.quoteTechnologyAvayaElite.adjuncts.speechAnalysis) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SPEECH_ANALYSIS, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SPEECH_ANALYSIS, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.quoteTechnologyAvayaElite.adjuncts.avayaWFOOptionId === this.iQuoteConstants.avayaWFOOptions.VERINT) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_VERINT, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_VERINT, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (this.quoteTechnologyAvayaElite.adjuncts.avayaWFOOptionId === this.iQuoteConstants.avayaWFOOptions.AVAYA) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_AVAYA, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_AVAYA, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }
    },
    calcEliteAdjunctLicenses: function () {
      if (this.quoteTechnologyAvayaElite.id === undefined) {
        return
      }

      this.licenses = this.$store.getters.licenses

      if (this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls > 0) {
        if (this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls <= 150) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_CALL_RECORDING_TIER1, this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls)
        } else if (this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls <= 650) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_CALL_RECORDING_TIER2, this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AVAYA_CALL_RECORDING_TIER3, this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls)
        }
      }
    },
    calcEliteUserLicenses: function () {
      this.licenses = this.$store.getters.licenses
      var requiredLicenseId = 0

      if (this.quoteTechnologyAvayaElite.id === undefined) {
        return
      }

      for (let index = 0; index < this.quoteTechnologyAvayaElite.userProfiles.length; index++) {
        const userProfile = this.quoteTechnologyAvayaElite.userProfiles[index]
        userProfile.annualPrice = 0
        requiredLicenseId = 0

        switch (userProfile.description) {
          case 'Basic Voice':
            if (this.eliteCalcs_usersToLicense > 500) {
              if (this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER3_STANDARD_SUBSCRIPTION
                } else if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER3_PREMIUM_SUBSCRIPTION
                }
              } else if (this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER3_STANDARD_PERPETUAL
                } else if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER3_PREMIUM_PERPETUAL
                }
              } else if (this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER3_STANDARD_SURRENDER
                } else if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER3_PREMIUM_SURRENDER
                }
              }
            } else if (this.eliteCalcs_usersToLicense > 250) {
              if (this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER2_STANDARD_SUBSCRIPTION
                } else if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER2_PREMIUM_SUBSCRIPTION
                }
              } else if (this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER2_STANDARD_PERPETUAL
                } else if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER2_PREMIUM_PERPETUAL
                }
              } else if (this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER2_STANDARD_SURRENDER
                } else if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER2_PREMIUM_SURRENDER
                }
              }
            } else if (this.eliteCalcs_usersToLicense >= 0) {
              if (this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER1_STANDARD_SUBSCRIPTION
                } else if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER1_PREMIUM_SUBSCRIPTION
                }
              } else if (this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER1_STANDARD_PERPETUAL
                } else if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER1_PREMIUM_PERPETUAL
                }
              } else if (this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER1_STANDARD_SURRENDER
                } else if (this.quote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.ELITE_VOICE_TIER1_PREMIUM_SURRENDER
                }
              }
            }
            break
        }

        if (requiredLicenseId !== 0) {
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
          userProfile.annualTotalCost = userProfile.minCommitment * this.utilFunctions_GetLicenseCost(requiredLicenseId) * 12
          userProfile.annualPrice = userProfile.minCommitment * this.utilFunctions_GetLicensePrice(requiredLicenseId) * 12
          userProfile.rate = this.utilFunctions_GetLicensePrice(requiredLicenseId)
        }
      }

      this.setQuoteTechnologyAvayaElite(this.quoteTechnologyAvayaElite)
    }
  }
}
