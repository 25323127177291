<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-deleteusers"
          primary-title
        >
          Delete Users
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details how to delete users."
              />
              <HelpSection
                instruction="1.) Navigate to the support page, then select the 'Delete Users' tile."
                image-source="delete_users_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="2.) Select the user(s) to be deleted from the table. Users on different pages can be selected by clicking the page navigation buttons on the bottom of the table. Specific users can be looked up by typing the first name, last name or user id into the search box at the bottom of the table. To leave the ‘Delete Users’ page, click the back button on the bottom left hand of the page."
                image-source="delete_users_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="b.) Once all users to be deleted have been selected, click the delete icon."
                indentation="1"
              />
              <HelpSection
                instruction="c.) Once the delete button is pressed, a confirmation dialog box will be displayed asking to confirm the deletion of the selected user(s)."
                indentation="1"
              />
              <HelpSection
                instruction="d.) If ‘Yes’ is pressed, the selected users will be permanently deleted. Otherwise, selecting 'No' will return to the Delete Users page."
                indentation="1"
              />
              <HelpSection
                instruction="3.) All users in the user table can be selected and deleted at once by clicking the ‘Select All Users’ toggle."
                indentation="0"
                image-source="delete_users_3.png"
              />
              <HelpSection
                instruction="a.) Once the Delete icon is pressed, a confirmation dialog box will be displayed asking to confirm the deletion of the selected users."
                indentation="1"
              />
              <HelpSection
                instruction="b.) If ‘Yes’ is pressed, the selected users will be permanently deleted. Otherwise, selecting 'No' will return to the Delete Users page."
                indentation="1"
              />
              <HelpSection
                instruction="4.) If the current user has access to multiple companies, users from a different company can be viewed by selecting the company from the ‘Company Multi-Select’ drop-down in the top right of the page."
                image-source="delete_users_4.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Once a new company is selected, the users table will update to show a list of users under the company. These users can then be deleted by following the previous steps."
                indentation="1"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpDashboardImage',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
