<template>
  <div class="widget-wrapper">
    <div
      :title="title"
      class="widget-header-title"
    >{{ title }}</div>
    <div class="widget-content-wrapper">
      <div class="widget-content">
        <span class="prefix">{{ prefix }} </span>
        {{ value }}
        <span class="suffix"> {{ suffix }}</span>
      </div>
    </div>
    <div class="widget-footer">
      <div class="widget-footer-timestamp">
        <span
          :title="'Data last updated at ' + timestamp()"
          class="timestamp"
        >{{ timestamp() }}</span>
      </div>
    </div>

    <TimerProgressComponent
      id="controlRefreshBar"
      :polling-interval="pollingInterval"
      :widget-id="widgetId"
      @reload-widget="setWidgetData"
    />
  </div>
</template>
<script>
import TimerProgressComponent from '../UCAnalytics/timerProgressComponent'
import { reportsMixin } from '../../mixins/reportsMixin'
export default {
  name: 'BasicWidgetComponent',
  components: {
    TimerProgressComponent
  },
  mixins: [
    reportsMixin
  ],
  props: {
    widgetId: {
      type: Number,
      default: () => 1
    },
    title: {
      type: String,
      default: () => ''
    },
    value: {
      type: Number,
      default: () => 1
    },
    pollingInterval: {
      type: Number,
      default: () => 180
    },
    prefix: {
      type: String,
      default: () => ''
    },
    suffix: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      timerObj: {},
      tickCount: 1,
      barProgress: 0
    }
  },
  computed: {
    pollingIntervalInSeconds () {
      return this.pollingInterval
    },
    progressTitle () {
      if ((this.pollingIntervalInSeconds - Math.round(this.tickCount)) > 60) {
        return Math.round((this.pollingIntervalInSeconds - Math.round(this.tickCount)) / 60) + ' minutes until next data refresh'
      } else {
        return (this.pollingIntervalInSeconds - Math.round(this.tickCount)) + ' seconds until next data refresh'
      }
    }
  },
  methods: {
    setWidgetData: async function (widgetId) {
      if (widgetId === this.widgetId) {
        // var rtn = await this.getWidgetById(this.id)
        // if (rtn.basicWidgets) {
        //   this.widgetData = rtn.basicWidgets[0]
        // }
      }
    },
    timestamp (utc) {
      var localDate = new Date(Date.now()).toLocaleString()
      if (utc) {
        localDate = new Date(utc).toLocaleString()
      }
      return localDate
    }
  }
}
</script>
<style scoped>
.widget-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #000000;
  border: #dddddd 1px solid;
}
.widget-header-title {
  cursor: default;
  padding: 0.4em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
  font-weight: 800;
  font-family: Helvetica, Arial, sans-serif;
}
.widget-content-wrapper {
  overflow: visible;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
}
.widget-content {
  height: 100%;
  font-size: 1.6em;
  font-family: Helvetica, Arial, sans-serif;
  color: #555;
}
.widget-footer {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  flex-shrink: 0;
}
.widget-footer-timestamp {
  font-size: 0.8em;
  color: #aaa;
  text-align: right;
  width: 100%;
}
.timestamp {
  transition: all 0.1s ease;
  color: #aaa;
  font-size: 0.8em;
  text-align: right;
  background-color: white;
  cursor: pointer;
}
#controlRefreshBar {
  display: inline-block;
  width: 100%;
  border-radius: 0;
  height: 4px;
}
#controlRefreshBar[value]::-webkit-progress-bar {
  background-color: #fff;
}
#controlRefreshBar[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, rgb(218, 240, 255), rgb(218, 240, 255));
  border-radius: 0;
  background-size: 100%;
}
</style>
