<template>
  <div
    id="tblticketDetails"
    class="ptrContainer"
  >
    <div
      v-if="ticket.Ticket_Number && ticket.Ticket_Number.length>0"
      class="ptrTreeViewTicket"
    >
      <div class="ticketDetailHeader">
        <v-icon
          :style="ticketStyle(ticket)"
          class="detailTicketIcon"
        >
          mdi-ticket-account
        </v-icon>
        Ticket Details - {{ ticket.Priority }}
      </div>
      <div class="detailTicketEscallationRow">
        <v-icon
          :style="ticketType(ticket)"
          class="detailTicketEscallationIcon"
        >
          mdi-ticket-percent
        </v-icon>
        <div
          v-if="ticket.Problem_Ticket_Id"
          class="detailTicketEscallation"
        >
          Problem Ticket
        </div>
        <div
          v-else
          class="detailTicketEscallation"
        >
          {{ ticketLabel }}
        </div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Ticket No</div>
        <div
          id="ticketNumberField"
          class="ticketDetailRowValue"
        >{{ ticket.Ticket_Number }}</div>
        <clipboard
          class="clipboardIcon"
          element="ticketNumberField"
        />
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Created By</div>
        <div class="ticketDetailRowValue">{{ ticket.Contact_Name }}</div>
      </div>
      <div
        v-if="ticket.Customer_Reference && ticket.Customer_Reference.length > 0"
        class="ticketDetailRow"
      >
        <div class="ticketDetailRowLabel">Customer Reference</div>
        <div
          id="customerReferenceField"
          class="ticketDetailRowValue"
        >{{ ticket.Customer_Reference }}</div>
        <clipboard
          class="clipboardIcon"
          element="customerReferenceField"
        />
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Site</div>
        <div class="ticketDetailRowValue">{{ ticket.Site }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Title</div>
        <div class="ticketDetailRowValue">{{ ticket.Title }}</div>
      </div>
      <!-- <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Assigned Resource</div>
        <div class="ticketDetailRowValue">{{ ticket.Assigned_Resource }} ({{ fullTicket.assignedResourceId }})</div>
      </div> -->
      <!-- <div
        v-show="ticket.Priority.indexOf('Change') > -1"
        class="ticketDetailRow"
      >
        <div class="ticketDetailRowLabelLong">Date of maintenance</div>
        <div class="ticketDetailRowValueShort">{{ ticket.Date_Due }}</div>
      </div>
      <div
        v-show="ticket.Priority.indexOf('Change') > -1"
        class="ticketDetailRow"
      >
        <div class="ticketDetailRowLabelLong">Length of downtime</div>
        <div class="ticketDetailRowValueShort">{{ ticket.Estimated_Hours }}</div>
      </div>-->
      <div
        v-show="ticket.Priority.indexOf('Change') > -1"
        class="ticketDetailRow"
      >
        <div class="ticketDetailRowLabel">Status</div>
        <div class="ticketDetailRowValue">{{ ticket.Status }}</div>
      </div>
      <!-- <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Date Added</div>
        <div class="ticketDetailRowValue">{{ ticket.Date_Added }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Last Activity</div>
        <div class="ticketDetailRowValue">{{ ticket.Last_Activity }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="ticketDetailRowLabel">Priority</div>
        <div class="ticketDetailRowValue">{{ ticket.Priority }}</div>
      </div> -->
      <div
        v-show="ticket.Issue_Type!=='Unknown'"
        class="ticketDetailRow"
      >
        <div class="ticketDetailRowLabel">Issue Type</div>
        <div class="ticketDetailRowValue">{{ ticket.Issue_Type }}</div>
      </div>
      <div
        v-show="ticket.Sub_Issue_Type!=='Unknown'"
        class="ticketDetailRow"
      >
        <div class="ticketDetailRowLabel">Sub Issue</div>
        <div class="ticketDetailRowValue">{{ ticket.Sub_Issue_Type }}</div>
      </div>
      <div class="ticketDetailRow">
        <div class="detailButtons">
          <div class="ticketDetailButton">
            <button
              id="ticketDetailButton"
              @click="setDialog(true)"
            >Details</button>
            <dialogBox
              :dialog="dialog"
              :title="ticket.Title"
              @close="setDialog"
            >
              <ticketDetails
                :ticket="fullTicket"
                :show-new-notes="showNewNotes"
              />
            </dialogBox>
          </div>
          <!-- <div class="ticketDetailRowIcon">
            <img
              :src="autotaskIcon"
              class="openDetailsIcon"
              title="Open in Autotask"
              @click="openTicket($event, ticket)"
            >
          </div> -->
        </div>
      </div>
    </div>
    <div
      v-else
      class="ptrNoRows"
    >
      No ticket found
    </div>
  </div>
</template>
<script>

import clipboard from '../common/clipboard'
import dialogBox from '../common/dialogBox'
import ticketDetails from './ticketDetails'

export default {
  components: {
    clipboard,
    dialogBox,
    ticketDetails
  },
  props: {
    ticket: {
      type: Object,
      default: () => {}
    },
    fullTicket: {
      type: Object,
      default: () => {}
    },
    showDetails: {
      type: Boolean,
      default: () => false
    },
    showNewNotes: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      dialog: false,
      contactName: 'Unknown'
    }
  },
  computed: {
    autotaskIcon () {
      return require(`../../assets/icons/autotask-icon.png`)
    },
    ticketLabel () {
      if (this.ticket.Priority.indexOf('Change') > -1) {
        return 'Change Ticket'
      } else if (this.ticket.Priority.indexOf('Request') > -1) {
        return 'Request Ticket'
      } else {
        return 'Incident Ticket'
      }
    }
  },
  watch: {
    showDetails () {
      this.setDialog(this.showDetails)
    }
  },
  mounted () {
    if (this.showDetails) {
      this.setDialog(true)
    }
  },
  methods: {
    openTicket (event, selectedRow) {
      this.selectedTicket = selectedRow
      this.selected = selectedRow.id
      var url = 'https://maintel.itclientportal.com/ClientPortal/ServiceTickets/ServiceTicketDetail.aspx?callBackMethod=refreshTickets&taskid=' + selectedRow.id
      var win = window.open(url, '_blank')
      win.focus()
    },
    ticketStyle (tckt) {
      if (tckt.Priority) {
        if (tckt.Priority.indexOf('Critical') > -1) {
          return 'color:rgb(167, 80, 239)'
        } else if (tckt.Priority.indexOf('High') > -1) {
          return 'color:rgb(239, 83, 80)'
        } else if (tckt.Priority.indexOf('Medium') > -1) {
          return 'color:rgb(255, 167, 38)'
        } else if (tckt.Priority.indexOf('Low') > -1) {
          return 'color:rgb(102, 187, 106)'
        } else if (tckt.Priority.indexOf('Change') > -1) {
          return 'color:rgb(162, 148, 132)'
        } else if (tckt.Priority.indexOf('Request') > -1) {
          return 'color:rgb(108, 203, 255)'
        } else {
          return 'color:rgb(214, 213, 213)'
        }
      } else {
        return 'color:rgb(214, 213, 213)'
      }
    },
    ticketType (tckt) {
      if (tckt.Priority.indexOf('Change') > -1) {
        return 'color:rgb(162, 148, 132)'
      } else if (tckt.Priority.indexOf('Request') > -1) {
        return 'color:rgb(108, 203, 255)'
      } else if (tckt.Problem_Ticket_Id) {
        return 'color:rgb(108, 203, 255)'
      } else {
        return 'color:rgb(122, 212, 67)'
      }
    },
    setDialog: function (value) {
      this.dialog = value
    }
  }
}
</script>
<style scoped>
  .ptrTreeViewTicket {
    background-color: transparent;
    border-spacing: 0;
  }
  .ticketDetailHeader {
    background-color: #274a5a;
    color: #fff;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
  }
  .ticketDetailRow {

    padding:5px;
  }
  .ticketDetailRowLabel {
    width:80px;
    display: inline-block;
    vertical-align: top;
  }
  .ticketDetailRowLabelLong{
    width:180px;
    display: inline-block;
    vertical-align: top;
  }
  .ticketDetailRowValue {
    font-weight: bold;
    display: inline-block;
    max-width: 306px;
    word-wrap: break-word;
  }
  .ticketDetailRowValueShort {
    font-weight: bold;
    display: inline-block;
    max-width: 226px;
    word-wrap: break-word;
  }
  .ticketDetailRowIcon {
    text-align: right;
  }
  .openDetailsIcon {
    width:64px;
    height:64px;
    cursor: pointer;
  }
  .detailTicketIcon {
    margin-right: 10px;
    font-size: 2em;
    line-height: 0.6em;
  }
  .detailTicketEscallationIcon {
    margin: 0 10px 0 5px;
    font-size: 2.4em;
    line-height: 0.6em;
  }
  .detailTicketTitle {
    display:inline-block;
  }
  .clipboardIcon {
    display:inline-block;
  }
  .detailTicketEscallation {
    display: inline-block;
    font-weight: bold;
    margin: 10px 0;
    font-size: 1.2em;
  }
  .ticketDetailButton button{
    padding: 5px 10px 5px 10px;
    border: 1px solid black;
  }
  .detailButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
</style>
