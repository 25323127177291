<template>
  <div>
    <div class="mttContainer">
      <div class="mttDetails">
        <div class="mttTextContainer">
          <input
            v-model.trim="value"
            :id="textId"
            :type="inputType"
            :placeholder="itemPlaceHolder"
            class="mttTextbox"
            @keyup="(event) => { editValue(event) }"
            @blur="(event) => { blurEvent(event) }"
            @paste="editValue" >
        </div>
        <div class="mttIconContainer">
          <img
            :id="textId +'Icon'"
            :src="iconUrl"
            :alt="altText"
            :title="altText"
            class="mttIcon">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemPlaceHolder: {
      type: String,
      default: ''
    },
    itemType: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: ''
    },
    itemError: {
      type: Boolean,
      default: false
    },
    textId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      value: '',
      hasFocus: false
    }
  },
  computed: {
    iconUrl () {
      switch (this.itemType) {
        case 'uid':
        case 'fst':
        case 'lst': {
          return require('../../assets/icons/PersonNew.png')
        }
        case 'pwd': {
          return require('../../assets/icons/LockNew.png')
        }
        case 'sea': {
          return require('../../assets/icons/SearchMini.png')
        }
        case 'ema': {
          return require('../../assets/icons/EmailNew2.png')
        }
        case 'com': {
          return require('../../assets/icons/company.png')
        }
        case 'cod': {
          return require('../../assets/icons/email.png')
        }
        default: {
          return ''
        }
      }
    },
    altText () {
      switch (this.itemType) {
        case 'uid': {
          return 'Please enter your username (usually your email address)'
        }
        case 'pwd': {
          return 'Please enter your password'
        }
        case 'sea': {
          return 'Please enter search text'
        }
        case 'ema': {
          return 'Please enter your username (usually your email address)'
        }
        case 'fst': {
          return 'Please enter your first name'
        }
        case 'lst': {
          return 'Please enter your last name'
        }
        case 'com': {
          return 'Please enter the company name'
        }
        case 'cod': {
          return 'Please enter the verification code'
        }
        default: {
          return ''
        }
      }
    },
    inputType () {
      if (this.itemType === 'pwd') {
        return 'password'
      }
      return 'text'
    }
  },
  watch: {
    itemValue () {
      this.value = this.itemValue
    }
  },
  methods: {
    blurEvent (event) {
      this.$emit('blur', event)
    },
    editValue (event) {
      if (event.type === 'paste') {
        var clipText

        // Safari, Chrome, Firefox
        if (event.clipboardData !== undefined) {
          clipText = event.clipboardData.getData('text/plain')
        } else { // IE and Edge
          clipText = window.clipboardData.getData('text')
        }
        this.$emit('EditValue', clipText)
        return
      }

      if (event.keyCode === 13) {
        this.$emit('EnterClicked')
        return
      }

      this.$emit('EditValue', this.value)
    }
  }
}
</script>

<style scoped>
    .mttContainer{
        width:100%;
        height: 34px;
        font-size: 10pt;
        border-radius: 15px;
        display:block;
        overflow:hidden;
        background-color: transparent;
      }

      .mttDetails{
        width:calc(100% - 4px);
        height:26px;
        border-radius: 5px;
        border:2px solid transparent;
        position:relative;
        z-index:50;
        cursor: pointer;
        background-color: white;
        top:2px;
        display: flex;
        flex-direction: rows;
      }

    .mttIcon{
        width:22px;
        height:22px;
    }
    .mttIconContainer{
        width:30px;
    }

    .mttTextContainer{
        width:calc(100% - 30px);
        margin-left:5px;
    }
    .mttTextbox{
        width:calc(100% - 20px);
        height: 23px;
        padding:0 5px 0 5px;
    }

    .mttTextbox:focus{
        border: 1px black dashed;
        border: var(--textbox-has-focus);
    }

</style>
