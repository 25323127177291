<template>
  <div class="createQuoteContainer">
    <component :is="currentPanel" />
  </div>
</template>

<script>
import QuoteType from '../panels/quoteType'
import SelectService from '../panels/tileScreens/selectService'
import ChooseOpportunity from '../panels/chooseOpportunity'
import DuplicateQuote from '../panels/duplicateQuote'
import IconContact from '../panels/tileScreens/iconContact'
import IconCommunicate from '../panels/tileScreens/iconCommunicate'
import InternationalSip from '../panels/internationalSip'
import UserProfilesAura from '../panels/userProfilesAura'
import UserProfilesMitelUC from '../panels/userProfilesMitelUC'
import UserProfilesMitelCC from '../panels/userProfilesMitelCC'
import UserProfilesAACC from '../panels/userProfilesAACC'
import UserProfilesElite from '../panels/userProfilesElite'
import UserProfilesCallmedia from '../panels/userProfilesCallmedia'
import UserProfilesGenesys from '../panels/userProfilesGenesys'
import UserProfilesAvayaAXP from '../panels/userProfilesAvayaAXP'
import AvayaAXPPage2 from '../panels/avayaAXPPage2'
import AvayaAXPPage3 from '../panels/avayaAXPPage3'
import Sycurio from '../panels/sycurio'
import UCAnalytics from '../panels/ucAnalytics'
import GenesysPage2 from '../panels/genesysPage2'
import AdjunctsAura from '../panels/adjunctsAura'
import AdjunctsAACC from '../panels/adjunctsAACC'
import AdjunctsElite from '../panels/adjunctsElite'
import AdjunctsCallmedia from '../panels/adjunctsCallmedia'
import AdjunctsMitelUC from '../panels/adjunctsMitelUC'
import AdjunctsMitelCC from '../panels/adjunctsMitelCC'
import SIPServices from '../panels/tileScreens/sipServices'
import SipGammaService from '../panels/sipGammaService'
import TeamsConnector from '../panels/teamsConnector'
import TeamsConnectorRecordings from '../panels/teamsConnectorRecordings'
import TeamsConnectorAttendantConsole from '../panels/teamsConnectorAttendantConsole'
import PhoneLinePlus from '../panels/phoneLinePlus'
import SipMigrationOffer from '../panels/SipMigrationOffer'
import UserProfilesCallmediaCXNow from '../panels/userProfilesCallmediaCXNow'
import Expansions from '../panels/expansions'
import IconGateway from '../panels/iconGateway'
import AdjunctsCallmediaCXNow from '../panels/adjunctsCallmediaCXNow'
import ExternalQuotes from '../panels/externalQuotes'
import Review from '../panels/review'
import Published from '../panels/published'
import BillOfMaterials from '../panels/billOfMaterials'
import CustomerSummary from '../panels/customerSummary'
import CxNowDdiDetail from '../panels/cxNowDdiDetail'
import UserProfilesRingCentralUC from '../panels/userProfilesRingCentralUC'
import UserProfilesRingCentralCC from '../panels/userProfilesRingCentralCC'
import { mapState } from 'vuex'
export default {
  name: 'CreateQuote',
  components: {
    QuoteType,
    ChooseOpportunity,
    DuplicateQuote,
    SelectService,
    IconContact,
    IconCommunicate,
    UserProfilesAura,
    UserProfilesAACC,
    UserProfilesElite,
    UserProfilesCallmedia,
    UserProfilesMitelUC,
    UserProfilesMitelCC,
    UserProfilesGenesys,
    GenesysPage2,
    UserProfilesAvayaAXP,
    AvayaAXPPage2,
    AvayaAXPPage3,
    Sycurio,
    UCAnalytics,
    AdjunctsMitelUC,
    AdjunctsMitelCC,
    AdjunctsAura,
    AdjunctsAACC,
    AdjunctsElite,
    AdjunctsCallmedia,
    SIPServices,
    Review,
    Published,
    BillOfMaterials,
    CustomerSummary,
    UserProfilesCallmediaCXNow,
    AdjunctsCallmediaCXNow,
    IconGateway,
    SipGammaService,
    PhoneLinePlus,
    SipMigrationOffer,
    InternationalSip,
    Expansions,
    CxNowDdiDetail,
    TeamsConnector,
    TeamsConnectorRecordings,
    TeamsConnectorAttendantConsole,
    ExternalQuotes,
    UserProfilesRingCentralUC,
    UserProfilesRingCentralCC
  },
  data () {
    return {
      currentPanel: this.$store.getters.currentQuoteState
    }
  },
  computed: {
    ...mapState([
      'currentQuoteState'
    ])
  },
  created () {
    this.$store.watch(
      (state, getters) => getters.currentQuoteState,
      (newValue) => {
        if (newValue !== undefined) {
          this.currentPanel = newValue
        }
      }
    )
  },
  methods: {
  }
}
</script>
