<template>
  <div class="designPackSummaryContainer">
    <div>
      <table
        id="CostsTable"
        class="iquoteDesignpackTable">
        <tr>
          <th
            rowspan="2"
            width="200px">Description</th>
          <th
            rowspan="2"
            width="150px">SF COE</th>
          <th colspan="2">Upfront</th>
          <th colspan="2">Recurring</th>
        </tr>
        <tr>
          <th>External</th>
          <th>Internal</th>
          <th>External</th>
          <th>Internal</th>
        </tr>
        <tr
          v-for="entry in iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.filter(x => x.source === 'iQuote')"
          :key="entry.description + entry.SF_COE">
          <td>{{ entry.description }}</td>
          <td>{{ entry.SF_COE }}</td>
          <td
            :class="{disabledCell: entry.upfrontExternalCost === 0}"
            class="numericCell"
          ><span v-if="entry.upfrontExternalCost !== 0">£{{ entry.upfrontExternalCost.toFixed(2) }}</span></td>
          <td
            :class="{disabledCell: entry.upfrontInternalCost === 0}"
            class="numericCell"
          ><span v-if="entry.upfrontInternalCost !== 0">£{{ entry.upfrontInternalCost.toFixed(2) }}</span></td>
          <td
            :class="{disabledCell: entry.recurringExternalCost === 0}"
            class="numericCell"
          ><span v-if="entry.recurringExternalCost !== 0">£{{ entry.recurringExternalCost.toFixed(2) }}</span></td>
          <td
            :class="{disabledCell: entry.recurringInternalCost === 0}"
            class="numericCell"
          ><span v-if="entry.recurringInternalCost !== 0">£{{ entry.recurringInternalCost.toFixed(2) }}</span></td>
        </tr>
      </table>
    </div>
    <br>
    <input
      v-if="(iQuoteStore_GetDesignPack.revisionNumber === iQuoteStore_GetDesignPack.revisions.length && iQuoteStore_GetDesignPack.locked === false)"
      id="btnEditQuote"
      value="Edit Quote"
      type="button"
      @click="editQuote">
    <strong
      v-if="(iQuoteStore_GetDesignPack.revisionNumber !== iQuoteStore_GetDesignPack.revisions.length)"
    >
      You cannot edit this quote, as there is a newer revision attached to a later revision of this DP
    </strong>
    <hr>
    <br>
    <div>
      <quoteSummary
        id="iQuoteDetails"
        :loading="false"
        :buttons="[]"
      />
    </div>
  </div>
</template>

<script>

import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'
import quoteSummary from '../panels/quoteSummary'
import { mapActions } from 'vuex'

export default {
  name: 'Iquote',
  components: {
    quoteSummary
  },
  mixins: [
    iQuoteStoreMixin,
    iQuoteCalculationsDesignPackMixin
  ],
  props: {
    isReadOnly: {
      type: Boolean,
      default: function () { return {} }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuotePage',
      'setCurrentQuoteState',
      'setCurrentQuoteSubPage'
    ]),
    editQuote: async function () {
      this.setCurrentQuotePage('CreateQuote')
      this.setCurrentQuoteState('ChooseOpportunity')
      this.setCurrentQuoteSubPage('QuoteDetails')
    }
  }
}
</script>
<style scoped>
  #CostsTable {
    margin-top: 15px;
    width: 675px;
  }

.disabledCell {
  background-color: gray;
  color: gray;
}

.numericCell {
  text-align: right;
}

#iQuoteDetails {
  border: 1px solid black;
  width: 1050px;
}

</style>
