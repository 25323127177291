<template>
  <div
    :id="id"
    class="roleMembersHolder"
  >
    <Spinner
      v-if="loading"
      id="roleMemberSpinner"
    />
    <p
      v-if="!loading && !roleMembers"
      class="alert"
    >
      This role has no members.
    </p>
    <table
      v-if="!loading && roleMembers"
    >
      <tbody>
        <tr
          v-for="m in members"
          :class="getRowColour()"
          :key="m.id"
        >
          <td
            :title="m.userName"
            style="width:50%"
          >{{ m.userName }} </td>
          <td
            :title="m.firstName"
            style="width:21%"
          >{{ m.firstName }} </td>
          <td
            :title="m.lastName"
            style="width:21%"
          >{{ m.lastName }} </td>
          <td
            v-if="removeEntityRolePermission"
            style="text-align:right;width:8%"
          >
            <input
              type="button"
              class="deleteButton"
              title="Remove member from role"
              @click="removeRoleMember(m)"
            >
          </td>
        </tr>
      </tbody>
    </table>
    <input
      v-if="assignEntityRolePermission && !loading"
      id="roleMembersButton"
      type="button"
      class="roleMembersButton"
      value="Add Member"
      title="Adds the new user to the role"
      @click="addMember()"
    >
    <RoleNewMember
      :roleid="roleid"
      :userid="userid"
    />
  </div>
</template>
<script>
import RoleNewMember from './roleNewMember'
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import Spinner from '../../common/spinner'
import { mapActions } from 'vuex'
export default {
  name: 'RoleMembers',
  components: {
    RoleNewMember,
    Spinner
  },
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    roleid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      members: [],
      showNew: false,
      loading: false
    }
  },
  computed: {
    assignEntityRolePermission () {
      return this.$store.getters.assignEntityRolePermission
    },
    removeEntityRolePermission () {
      return this.$store.getters.removeEntityRolePermission
    },
    roleMembers () {
      return this.$store.getters.roleMembers
    }
  },
  watch: {
    roleid () {
      this.loading = true
      this.setRoleMembers(undefined)
    },
    roleMembers () {
      this.loading = true
      this.setComponent()
    }
  },
  mounted () {
    this.loading = true
  },
  methods: {
    ...mapActions([
      'setSubForm2',
      'setRoleMembers'
    ]),
    setComponent: async function () {
      if (this.roleid > -1) {
        this.getMembers()
      }
      this.setSubForm2(false)
    },
    getMembers: async function () {
      var userList = ''
      if (this.roleMembers && this.roleMembers.length > 0) {
        this.roleMembers.forEach(entity => {
          userList += '|' + entity.entityId
        })

        userList = userList.substring(1)
        await this.getUserList(userList).then((result) => {
          this.members = result
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    addMember: async function () {
      this.setSubForm2(true)
    },
    removeRoleMember: async function (m) {
      await this.deleteEntityRole(m.id, this.roleid)

      this.getRoleMembers(this.roleid)
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    }
  }
}
</script>
<style scoped>
  .roleMembersHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .roleMembersButton {
    margin: 10px;
    float: right;
    cursor: pointer;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
  }
</style>
