<template>
  <div
    v-if="$store.getters.openSubForm2"
    class="roleNewMemberBackground"
  >
    <div class="roleNewMemberHolder">
      <div class="roleTableholder">
        <table style="table-layout: auto;">
          <thead>
            <tr>
              <th
                colspan="4"
                style="border-top-right-radius:0px;border-top-left-radius:0px"
              >
                <img
                  :src="newMemberIcon"
                  class="icon"
                >
                <div
                  class="title"
                >
                  New Role Member
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="!members.length"
              class="alert"
            >
              <td
                colspan="4"
              >
                <p
                  class="alert"
                >
                  There are no members to add.
                </p>
              </td>
            </tr>
            <tr
              v-for="m in members"
              id="memberList"
              :key="m.id"
            >
              <td
                style="padding:0px;width:6%"
                colspan="1"
              >
                <input
                  :id="m.id"
                  type="checkbox"
                  title="Select Role"
                  class="memberItem"
                  @change="checkForm"
                >
              </td>
              <td
                :title="m.userName"
                style="padding:0px;width:60%"
                colspan="1"
              >{{ m.userName }} </td>
              <td
                :title="m.firstName"
                style="padding:0px;width:17%"
                colspan="1"
              >{{ m.firstName }} </td>
              <td
                :title="m.lastName"
                style="padding:0px;width:17%"
                colspan="1"
              >{{ m.lastName }} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <input
        id="roleCancelNewMemberButton"
        class="roleCancelNewMemberButton"
        type="button"
        value="Cancel"
        title="Cancel the add operation"
        @click="cancelAdd()"
      >
      <input
        id="roleAddNewMemberButton"
        :class="getButtonClass"
        type="button"
        value="Add Member"
        title="Adds the new user to the role"
        @click="addMember()"
      >
      <input
        type="text"
        class="focusField"
        @focus="cycleFocus"
      >
    </div>
  </div>
</template>
<script>
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'RoleNewMember',
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    roleid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      members: [],
      formOK: false
    }
  },
  computed: {
    ...mapState([
      'openSubForm2'
    ]),
    loggedOnUserid () {
      return this.$store.getters.userid
    },
    getButtonClass () {
      if (this.formOK) {
        return 'roleAddNewMemberButtonEnabled'
      } else {
        return 'roleAddNewMemberButton'
      }
    },
    newMemberIcon () {
      return require('../../../assets/icons/Person.png')
    }
  },
  watch: {
    roleid () {
      this.setComponent()
    }
  },
  created () {
    this.setComponent()
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.openSubForm2,
      (newValue) => {
        if (newValue) {
          // document.getElementById('memberList').focus()
        }
      }
    )
  },
  methods: {
    ...mapActions([
      'setSubForm2',
      'setReloadMembers',
      'setRoleMembers'
    ]),
    setComponent () {
      this.getMembers()
    },
    checkForm () {
      var selected = false
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          selected = true
        }
      }
      this.formOK = selected
    },
    checkExists (entity, id) {
      return entity.id === id
    },
    getMembers: async function () {
      var userStringList = ''
      var rtn1 = await this.getUsers(this.loggedOnUserid)
      var rMembers = this.$store.getters.roleMembers
      var validMembers = []
      for (let i = 0; i < rtn1.length; i++) {
        var foundOne = false
        if (rMembers && rMembers.length > 0) {
          for (let j = 0; j < rMembers.length; j++) {
            if (rtn1[i].id === rMembers[j].entityId) { foundOne = true }
          }
        }
        if (!foundOne) { validMembers.push(rtn1[i]) }
      }
      validMembers.forEach(user => { userStringList += '|' + user.id })
      var userList = await this.getUserList(userStringList)
      // We are not allowed to add external users (not maintel.co.uk) to the channel company roles
      if (this.$store.getters.channelRoles.filter(r => r === this.roleid).length > 0 &&
        this.$store.getters.channelCompany.companyId !== this.$store.getters.currentCompany.id) {
        userList = userList.filter(user => user.emailAddress.indexOf(this.$store.getters.channelEmailSuffix) > -1)
      }
      this.members = userList
    },
    closeForm () {
      this.setSubForm2(false)
    },
    addMember: async function () {
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          await this.addEntityRole(memberItems[i].id, this.roleid)
        }
      }
      this.getRoleMembers(this.roleid)
      this.closeForm()
    },
    cancelAdd () {
      this.closeForm()
    },
    cycleFocus () {
      if (document.getElementById('memberList') != null) {
        document.getElementById('memberList').focus()
      }
    }
  }
}

</script>
<style scoped>
  .roleNewMemberBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .roleNewMemberHolder {    display: inline-block;
    background-color: rgba(255,255,255,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 212px);
    top: calc(10%);
    max-height: calc(100% - 180px);
    width: 424px;
    z-index: 999;
  }
  .roleCancelNewMemberButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .roleAddNewMemberButton {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    opacity: 0.5;
  }
  .roleAddNewMemberButtonEnabled {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .roleTableholder {
    overflow: auto;
    height:calc(100% - 64px);
    max-height: 300px;
  }
  .focusField {
    position: absolute;
    left:0;
    bottom:0;
    width:0;
    background-color: rgba(240,240,240,1);
    opacity: 0;
  }
  .memberItem {
    margin: 7px;
  }
  .icon {
    height: 25px;
    width: 25px;
  }
  .title {
    display: inline-block;
    height: 25px;
    padding-top:3px;
    padding-left: 10px;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
    color: black;
    background-color: #fff;
  }
</style>
