<template>
  <div
    id="sendWelcomeEmail"
    class="adcHolder"
  >
    <label
      class="sendWelcomeDetailLabel"
    >
      Pressing this button will send the welcome email to <b>{{ selectedUser.firstName + ' ' + selectedUser.lastName }}</b> at <b>{{ selectedUser.emailAddress }}</b>
    </label>
    <input
      :disabled="!selectedUser"
      value="Send"
      type="button"
      class="adcButton customerUpdateButton"
      @click="sendOnboardingEmail()"
    >
  </div>
</template>
<script>

import { usersMixin } from '../../mixins/users'
import { adminMixin } from '../../mixins/adminMixin'

export default {
  mixins: [
    usersMixin,
    adminMixin
  ],
  props: {
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      selectedUser: {}
    }
  },
  watch: {
    userid () {
      this.setSelectedUser()
    }
  },
  mounted () {
    this.setSelectedUser()
  },
  methods: {
    setSelectedUser: async function () {
      var user = this.$store.getters.users.find(o => o.id === this.userid)
      this.selectedUser = await this.getUserById(user.id)
    },
    sendOnboardingEmail: async function () {
      // console.log(this.selectedUser)
      this.$store.commit('showMessage', {
        content: `About to send welcome email to '${this.selectedUser.userName}'`,
        color: 'green',
        timeout: 3000
      })
      var rtn = await this.resendemail(this.selectedUser.userName, this.selectedUser.emailAddress)
      if (rtn.length === 0) {
        this.$store.commit('showMessage', {
          content: `The welcome email was sent to '${this.selectedUser.userName}' successfully!`,
          color: 'blue',
          timeout: 3000
        })
      } else {
        this.$store.commit('showMessage', {
          content: `The welcome email sent to '${this.selectedUser.userName}' failed with '${rtn}'`,
          color: 'red',
          timeout: 3000
        })
      }
    }
  }
}
</script>
<style scoped>
  .adcButton{
    width:140px;
    border: solid 1px grey;
    color: grey;
  }
  .adcSubHeader{
    padding-left:10px;
    display: inline-block;
    width: 220px;
  }
  .adcHolder {
    background-color: rgba(255,255,255,0.7);
    margin-bottom: 10px;
    padding: 10px 0;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    padding: 10px 5px;
    height: 150px;
    width: 100%;
    display: none;
  }
  .sendWelcomeDetailLabel {
    width: 100%;
    display: inline-block;
    margin-bottom: 40px;
  }
  .sendWelcomeDetailInput {
    width: 254px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .customerUpdateButton {
    float:right;
    cursor: pointer;
  }
</style>
