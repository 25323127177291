<template>
  <div
    v-if="$store.getters.openSubForm3"
    class="userAddUserGroupBackground"
  >
    <div class="userAddUserGroupHolder">
      <div class="userGroupTableholder">
        <table>
          <thead>
            <tr>
              <th style="width:10%;border-top-right-radius:0px;border-top-left-radius:0px">
                <img
                  :src="userGroupIcon"
                  class="icon"
                >
              </th>
              <th style="width:90%;border-top-right-radius:0px;border-top-left-radius:0px" >Add User Group to User</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="s in userGroups"
              id="userGroupList"
              :key="s.id"
            >
              <td>
                <input
                  :id="s.id"
                  type="checkbox"
                  title="Select User Group"
                  class="userGroupItem"
                  @change="checkForm"
                >
              </td>
              <td
                :title="s.name"
              >{{ s.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <input
        id="permissionCancelNewRoleButton"
        type="button"
        class="permissionCancelNewRoleButton"
        value="Cancel"
        title="Cancel the add operation"
        @click="cancelAdd()"
      >
      <input
        id="stormUserAddUserGroupButton"
        :class="getButtonClass"
        type="button"
        value="Add User Group"
        title="Adds the user group to the currently selected user"
        @click="addUserGroup()"
      >
      <input
        type="text"
        class="focusField"
        @focus="cycleFocus"
      >
    </div>
  </div>
</template>
<script>
import { stormMixin } from '../../../mixins/stormMixin'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'StormAddUserGroup',
  mixins: [
    stormMixin
  ],
  props: {
    stormUserId: {
      type: Number,
      default: -1
    },
    allowWebUser: {
      type: Boolean,
      default: false
    },
    currentWebUsers: {
      type: Number,
      default: -1
    },
    maxWebUsers: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      formOK: false,
      userGroups: [],
      userUserGroups: []
    }
  },
  computed: {
    ...mapState([
      'openSubForm3'
    ]),
    getButtonClass () {
      if (this.formOK) {
        return 'stormUserAddUserGroupButtonEnabled'
      } else {
        return 'stormUserAddUserGroupButton'
      }
    },
    userGroupIcon () {
      return require('../../../assets/icons/People.png')
    },
    ssoSecurityGroup () {
      return this.$store.getters.stormSSOSecurityGroup
    }
  },
  watch: {
    stormUserId: async function () {
      await this.setUserGroups()
    },
    currentWebUsers: async function () {
      await this.setUserGroups()
    }
  },
  mounted: async function () {
    await this.setUserGroups()
    this.$store.watch(
      (state, getters) => getters.openSubForm3,
      (newValue) => {
        if (document.getElementById('userGroupList') && newValue) {
          document.getElementById('userGroupList').focus()
        }
      }
    )
  },
  methods: {
    ...mapActions([
      'setSubForm3'
    ]),
    setUserGroups: async function () {
      this.userUserGroups = await this.getStormUserGroupsByUser(this.stormUserId)
      this.userGroups = this.$store.getters.stormUserGroups
      if (this.userUserGroups.length > 0) {
        this.userGroups = this.userGroups.filter(sg => !this.userUserGroups.find(rm => (sg.id === rm.id)))
      }
      // Remove the entry for 'Web Interface User' if we are at the maximum user count
      if (this.currentWebUsers >= this.maxWebUsers) {
        this.userGroups.splice(this.userGroups.findIndex(function (i) {
          return i.name === this.ssoSecurityGroup
        }), 1)
      }
    },
    checkForm () {
      var selected = false
      var userGroupItems = document.getElementsByClassName('userGroupItem')
      for (var i = 0; i < userGroupItems.length; i++) {
        if (userGroupItems[i].checked) {
          selected = true
        }
      }
      this.formOK = selected
    },
    checkExists (entity, id) {
      return entity.id === id
    },
    closeForm () {
      this.setSubForm3(false)
    },
    addUserGroup: async function () {
      var userGroupItems = document.getElementsByClassName('userGroupItem')
      for (var i = 0; i < userGroupItems.length; i++) {
        if (userGroupItems[i].checked) {
          if (await this.addUserToStormUserGroup(this.stormUserId, userGroupItems[i].id)) {
          }
        }
      }
      this.$emit('reload-storm-user-groups', this.stormUserId)
      this.closeForm()
    },
    cancelAdd () {
      this.closeForm()
    },
    cycleFocus () {
      if (document.getElementById('userGroupList')) {
        document.getElementById('userGroupList').focus()
      }
    }
  }
}

</script>
<style scoped>
  .userAddUserGroupBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .userAddUserGroupHolder {
    display: inline-block;
    background-color: rgba(255,255,255,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 160px);
    top: calc(10%);
    max-height: calc(100% - 180px);
    width: 360px;
    overflow: auto;
    z-index: 999;
    overflow-y: auto;
  }
  .permissionCancelNewRoleButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .stormUserAddUserGroupButton {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    opacity: 0.5;
  }
  .stormUserAddUserGroupButtonEnabled {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .userGroupTableholder {
    overflow-y: auto;
    max-height: 300px;
  }
  .focusField {
    position: absolute;
    left:0;
    bottom:0;
    width:0;
    opacity: 0;
    background-color: rgba(240,240,240,1);
  }
  .icon {
    height: 25px;
    width: 25px;
  }
</style>
