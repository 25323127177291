
import Vue from 'vue'
import 'babel-polyfill'
import './components'
import './plugins'
import { sync } from 'vuex-router-sync'
import { DataEventBus } from './events/dataEvents'

// Application imports
import App from './App'
import router from '@/router'
import { store } from './store/store.js'

// Donut chart imports
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'
Vue.use(Donut)

// Link store and router
sync(store, router)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.fullPath === '/' || to.fullPath.toLowerCase().startsWith('/passwordreset')) {
    next()
  } else {
    var loggedOn = store.getters.loggedOn
    var username = ''
    var token = ''
    var refreshToken = ''
    if (!loggedOn) {
      username = localStorage.getItem('username')
      token = localStorage.getItem('token')
      refreshToken = localStorage.getItem('refreshToken')
      if (username && username !== '' &&
              token && token !== '' &&
              refreshToken && refreshToken !== '') {
        store.dispatch('setTokens', {
          'token': token,
          'refreshToken': refreshToken
        })
        store.dispatch('setUsername', username)
        loggedOn = true
      } else {
        if (to.redirectedFrom && (
          to.fullPath.toLowerCase().indexOf('accessrequest') > -1 ||
          to.fullPath.toLowerCase().indexOf('accessrequest') > -1 ||
          to.fullPath.toLowerCase().indexOf('tickets') > -1)) {
          next({
            path: '/',
            query: {
              returnUrl: to.redirectedFrom
            }
          })
        } else if (to.path !== '/' && to.path.toLowerCase() !== '/setpassword') {
          next({
            path: '/',
            query: {
              returnUrl: to.fullPath
            }
          })
        }
      }
    }
    if (loggedOn && (to.fullPath !== '/' &&
      !to.fullPath.toLowerCase().startsWith('/setpassword') &&
      !to.fullPath.toLowerCase().startsWith('/accessrequest') &&
      !to.fullPath.toLowerCase().startsWith('/tickets') &&
      !to.fullPath.toLowerCase().startsWith('/logout'))) {
      if (from.query.returnUrl && from.query.returnUrl !== to.path) {
        localStorage.setItem('returnUrl', from.query.returnUrl)
        next(from.query.returnUrl)
      } else {
        next()
      }
    } else if (to.fullPath.startsWith('/logout') && loggedOn) {
      store.dispatch('setTokens', {
        'token': token,
        'refreshToken': refreshToken
      })
      store.dispatch('setUsername', username)
      DataEventBus.$emit('logged-off')
      next('/')
    } else if (to.fullPath === '/' && loggedOn) {
      next('/Home')
    } else {
      next()
    }
  }
})

/* eslint-disable no-new */
new Vue({
  router,
  store,
  async beforeCreate () {
    await this.$store.dispatch('initChannel')
    this.$mount('#app')
  },
  render: h => h(App)
})
