<template>
  <div class="dateHolder">
    <p class="dateHeader">{{ header }}</p>
    <input
      v-model="selectedDate"
      :min="minValue"
      :max="maxValue"
      type="date"
      class="dateControl"
      @blur="emitValue"
    >
    <!-- <p class="dateFooter">DD/MM/YYYY format</p> -->
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: () => ''
    },
    minValue: {
      type: String,
      default: () => ''
    },
    maxValue: {
      type: String,
      default: () => ''
    },
    reset: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    selectedDate: ''
  }),
  watch: {
    reset () {
      if (this.reset) {
        this.selectedDate = ''
      }
    }
  },
  methods: {
    emitValue () {
      this.$emit('date-control', this.selectedDate)
    }
  }
}
</script>
<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  left: 0;
}
input[type="date"] {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #fff;
  border-width: thin;
  font-size: 0.9em;
}
.dateHolder {
  display: inline-block;
}
.dateControl {
  width:180px;
  margin-right:30px;
}
.dateHeader {
  font-size: 0.8em;
  margin:0;
  vertical-align: bottom;
  font-weight: 500;
}
.dateFooter {
  font-size: 0.6em;
  margin:0;
  vertical-align: top;
}
</style>
