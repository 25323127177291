<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-createcustomers"
          primary-title
        >
          Managing Top Level Sites
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details how to manage the top level sites details and the users assigned to them."
              />
              <HelpSection
                instruction="1.) Navigate to the 'Security' page via the left hand navigation menu."
                image-source="manage_customers_users_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="2.) Navigate to the 'Top Level Sites' tab via the tabs at the top of 'Security' page."
                indentation="0"
              />
              <HelpSection
                instruction="a.) This tab displays a list of the top level sites that the user has access to against their currently selected company."
                image-source="manage_customers_users_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="3.) You are able to select a top level site from this list and a menu is displayed to the right of the table."
                image-source="manage_customers_users_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="4.) Under the ‘Members of…’ section the users associated with the top level site are displayed."
                indentation="0"
              />
              <HelpSection
                instruction="a.) The 'Enabled' toggle switch against each user row allows a user to be assigned or un-assigned from the selected top level site."
                image-source="manage_customers_users_4.png"
                indentation="1"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpManageCustomers',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
