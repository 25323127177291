<template>
  <div
    class="uploadDashboardImageContainer"
  >
    <div
      v-if="!uploadDashboardImage"
      class="noResults"
    >
      Sorry, you do not have permission to update dashboard image
    </div>
    <div
      v-if="!validJson"
      class="noResults"
    >
      Cannot read the json file from the Dashboard folder.
    </div>
    <div
      v-else
      id="updateDashboardImage"
      class="updateSectionData"
    >
      <div class="updateDashboardImageHolder" >
        <div class="parentUpdateDashboardImageForm">
          <h3
            id="dashboardImageSectionHeader"
            class="expanded"
          >
            Dashboard Image
            <span class="statusContainer">
              <span
                :class="[formOK ? 'completeSection' : 'incompleteSection']"
                :title="[formOK ? 'Section Complete' : 'Section Incomplete']"
              />
              <span class="background" />
            </span>
          </h3>
          <transition name="slide">
            <div class="updateDashboardImageLeft">
              <label
                for="txtUrl"
                class="updateDashboardImageLabel"
              >URL:</label>
              <input
                id="txtUrl"
                v-model="dashboardImage.url"
                type="text"
                class="updateDashboardImageTextbox"
              >
              <label
                for="txtAlt"
                class="updateDashboardImageLabel"
              >Alt Text:</label>
              <input
                id="txtAlt"
                :class="{ errorHighlight: altTextError }"
                v-model="dashboardImage.altText"
                type="text"
                class="updateDashboardImageTextbox"
                @blur="checkForm"
              >
              <label
                for="optEnableDashboardImage"
                class="updateDashboardImageLabel"
              >Enabled:</label>
              <TriStateToggleSwitch
                id="optEnableDashboardImage"
                :enabled="true"
                :tri-state="false"
                :current-state="dashboardImage.enabled ? 1 : 2"
                class="toggleSwitch"
                @statechanged="({ selectedState }) => {updateDashboardImageEnable(selectedState)}"
              />
              <br>
              <label
                for="inputImage"
                class="updateDashboardImageLabel"
              >Upload Image:</label>
              <div class="dropImageBox" >
                <input
                  id="inputImage"
                  type="file"
                  accept="image/*"
                  @change="uploadImage($event)"
                >
                <p>Drag an image file here or click.</p>
              </div>
            </div>
          </transition>
          <transition name="slide">
            <div class="updateDashboardImageRight">
              <v-card
                style="border: solid 1px black; width: 100%; padding-bottom: 40%">
                <img
                  v-if="dashboardImage.image || dashboardImage.filename"
                  :src="dashboardImage.image ? dashboardImage.image : getImagePath"
                  :alt="dashboardImage.altText"
                >
              </v-card>
              <div>
                <p style="margin-top: 10px;">Images not in the ratio 262:100 will be clipped.</p>
              </div>
            </div>
          </transition>
        </div>
        <div class="bottombar">
          <Spinner v-if="isSaving" />
          <input
            v-if="!isSaving"
            id="cancelDashboardImageButton"
            class="cancelDashboardImageButton"
            type="button"
            title="Cancel the Dashboard Image Update"
            @click="closeUploadDashboardImageForm()"
          >
          <input
            v-if="!isSaving"
            id="updateDashboardImageButton"
            :disabled="!getFormStatus"
            :class="getButtonClass"
            type="button"
            title="Update Dashboard Image"
            @click="updateDashboardImage()"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TriStateToggleSwitch from '../../../components/common/triStateToggleSwitch'
import { dashboardRepository } from '../../../communications/repositories/dashboardRepository'
import { dashboardImageMixin } from '../../../mixins/dashboardImage'
import { DataEventBus } from '../../../events/dataEvents'

import Spinner from '../../common/spinner'
import { mapActions, mapState } from 'vuex'

export default {
  metaInfo: {
    title: 'Support - Upload Dashboard Image'
  },
  name: 'UploadDashboardImage',
  components: {
    Spinner,
    TriStateToggleSwitch
  },
  mixins: [
    dashboardRepository,
    dashboardImageMixin
  ],
  data () {
    return {
      dashboardImage: {
        enabled: false,
        image: '',
        filename: '',
        altText: null,
        url: ''
      },
      formOK: false,
      altTextError: false,
      isSaving: false,
      validJson: true
    }
  },
  computed: {
    ...mapState([
      'openMainForm'
    ]),
    uploadDashboardImage () {
      return this.$store.getters.uploadDashboardImage
    },
    getImagePath () {
      return `/dashboard/${this.dashboardImage.filename}`
    },
    getFormStatus () {
      return this.formOK
    },
    getButtonClass () {
      if (this.formOK) {
        return 'updateDashboardImageButtonEnabled'
      } else {
        return 'updateDashboardImageButton'
      }
    }
  },
  created: async function () {
    await this.getDashboardImageJson().then((response) => {
      this.dashboardImage = { ...response, image: '' }
      this.formOK = this.dashboardImage.altText
    }).catch((error) => {
      this.validJson = false
      console.log(error)
    })
  },
  methods: {
    ...mapActions([
      'setDashboardImageVisibility'
    ]),
    async uploadImage (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      this.dashboardImage.filename = file.name
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.dashboardImage.image = e.target.result
      }
      this.checkForm({ target: { className: 'altText' } })
    },
    updateDashboardImageEnable (selectedState) {
      if (selectedState === 1) {
        this.dashboardImage.enabled = true
      } else {
        this.dashboardImage.enabled = false
      }
    },
    checkForm ({ target: { className: altText } }) {
      if (altText.indexOf('altText') > -1) {
        this.altTextError = !this.dashboardImage.altText
      } else {
        this.altTextError = !this.dashboardImage.altText
      }
      var error = this.altTextError
      var errorMessage = 'The Alt text for the image should be provided.'
      if (error) {
        document.getElementById('txtAlt').focus()
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 3000 })
      }
      this.formOK = !error
    },
    async updateDashboardImage () {
      this.isSaving = true
      if (this.formOK) {
        try {
          await this.addDashboardImage(this.dashboardImage)
          this.$store.commit('showMessage', { content: 'The dashboard image changes are complete.', color: 'blue', timeout: 3000 })
        } catch (ex) {
          console.error(ex)
          this.$store.commit('showMessage', { content: 'Dashboard image update failed, rolling back...', color: 'red', timeout: 3000 })
          this.isSaving = false
        }
      }
      this.setDashboardImageVisibility(this.dashboardImage.enabled && this.dashboardImage.filename.length > 0)
      DataEventBus.$emit('dashboard-image-changed')
      this.isSaving = false
    },
    closeUploadDashboardImageForm () {
      this.altTextError = false
      this.dashboardImage = {
        enabled: false,
        image: '',
        filename: '',
        altText: null,
        url: ''
      }
      this.$emit('setTileMode')
    }
  }
}
</script>

<style scoped>
  .updateSectionData {
    position: absolute;
    bottom: 36px;
    top: 0;
    right: 0;
    background-color: #fff;
    left: 0;
    z-index: 1;
    margin-right: 3vw;
  }
  .updateDashboardImageHolder {
    background-color: rgba(255,255,255,0.7);
    padding: 30px 35px 0 20px;
    overflow-y: auto;
  }
  .parentUpdateDashboardImageForm{
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .expanded {
    border-radius: 5px 5px 0 0;
  }
  span.statusContainer {
    float: left;
    margin-right: 5px;
  }
  span.statusContainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }
  .completeSection {
    background-image: url('../../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  .incompleteSection {
    background-image: url('../../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  input {
    border-radius: 5px;
  }
  .minimiseSection {
    background-image: url('../../../assets/icons/up_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  .maximiseSection {
    background-image: url('../../../assets/icons/down_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  .updateDashboardImageLeft {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .updateDashboardImageRight {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .updateDashboardImageLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .updateDashboardImageTextbox {
    width: 68%;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }
  .emptyDiv {
    width: 30%;
    display: inline-block;
  }
  .toggleSwitch {
    vertical-align: middle;
  }
.dropImageBox{
    /* display: flex; */
    position: relative;
    width: 68%;
    min-width: 270px;
    height: 280px;
    text-align: center;
    outline-offset: -5px;
    outline: 1px dashed #0f0f0f;
    background-color: rgba(233, 230, 230, 0.979);
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }
  .dropImageBox p{
    position: absolute;
    width: 100%;
    /*padding-top: 10px;*/
    text-align: center;
    font-weight: 800;
    color: #7a7979c7;
    font-family: Arial;
    z-index: 0;
    top: 45%;
  }
  .dropImageBox label{
    width: 100px;
  }
  .dropImageBox input{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    z-index: 3;
  }
  .uploadDashboardImageHolder {
    background-color: rgba(255,255,255,0.7);
    padding: 0 15px 0 10px;
    overflow-y: auto;
  }
  .uploadDashboardImageSectionData {
    position: absolute;
    bottom: 36px;
    top: 0;
    right: 0;
    background-color: #fff;
    left: 0;
    z-index: 1;
    margin-right: 3vw;
  }
  .parentUploadDashboardImageForm{
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .updateDashboardImageButton {
    background-image: url('../../../assets/icons/check-tick-disabled.svg');
    cursor: default;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .updateDashboardImageButtonEnabled {
    background-image: url('../../../assets/icons/check-tick.svg');
    cursor: pointer;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .cancelDashboardImageButton {
    background-image: url('../../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
  }

  .bottombar {
    text-align: right;
  }

  h3 {
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0;
    color: #fff;
    border-radius: 5px;
  }

  .formContent {
    overflow-y: auto;
  }

</style>
