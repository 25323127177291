<template>
  <div class="securityHolder">
    <div class="mdContainer appSectionBody">
      <div
        :class="{ mdHalfScreen : !!selectedCustomer }"
        class="mdCustomerManagement"
      >
        <table id="customersTable">
          <thead />
          <tbody>
            <tr
              v-for="v in currentFilter"
              :key="v.id"
              :class="getRowColour(v.id)"
              :tabindex="20"
              @click="selectCustomer(v)"
            >
              <td :title="v.name">{{ v.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="!!selectedCustomer"
        class="mdSelection"
      >
        <SectionCollapsedHeader
          v-if="editCustomerPermission"
          id="sectionAreaDetails"
          :title="customerDetailsTitle"
          type="subHeader"
          icon-file="Edit"
          controls="customerDetails"
        />
        <CustomerDetailsComponent
          v-if="editCustomerPermission"
          id="customerDetails"
          :customerid="selectedCustomer.id"
        />
        <SectionCollapsedHeader
          id="sectionCustomerUsers"
          :title="customerMembersTitle"
          type="subHeader"
          icon-file="Person"
          controls="customerUsers"
        />
        <CustomerUserComponent
          id="customerUsers"
          :use-alt-colour="useAltColour"
          :customerid="selectedCustomer.id"
        />
        <SectionCollapsedHeader
          v-if="createCustomerPermission"
          id="sendWelcomeEmail"
          title="Send Welcome Email"
          type="subHeader"
          icon-file="Messages"
          controls="customerOnboardingToggle"
        />
        <CustomerOnboardingToggleComponent
          v-if="createCustomerPermission"
          id="customerOnboardingToggle"
          :customerid="selectedCustomer.id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import tableHandler from '../../mixins/tableHandler'
import SectionCollapsedHeader from '../../components/common/sectionCollapsedHeader'
import CustomerUserComponent from '../security/customers/customerUsersComponent'
import CustomerDetailsComponent from '../security/customers/customerDetails'
import CustomerOnboardingToggleComponent from '../security/customers/customerOnboardingToggle'
import { permissionsMixin } from '../../mixins/permissions'
import { mapActions } from 'vuex'
export default {
  name: 'AreasPage',
  components: {
    SectionCollapsedHeader,
    CustomerUserComponent,
    CustomerDetailsComponent,
    CustomerOnboardingToggleComponent
  },
  mixins: [
    permissionsMixin,
    tableHandler
  ],
  props: {
  },
  data () {
    return {
      selectedCustomer: null,
      selected: null
    }
  },
  computed: {
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    customers () {
      return this.$store.getters.customers.filter((customer) => customer.companyId === this.selectedCompany.id)
    },
    customerMembersTitle () {
      return 'Members of ' + this.selectedCustomer.name
    },
    customerDetailsTitle () {
      return 'Details for ' + this.selectedCustomer.name
    },
    editCustomerPermission () {
      return this.$store.getters.editCustomerPermission
    },
    viewCustomerPermission () {
      return this.$store.getters.viewCustomerPermission
    },
    createCustomerPermission () {
      return this.$store.getters.createCustomerPermission
    },
    selectedCompany () {
      return this.$store.getters.currentCompany
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.customers,
      (newValue) => {
        if (newValue !== undefined) {
          this.setComponent()
        }
      }
    )
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setReloadAreas'
    ]),
    setComponent () {
      this.populateCustomersTable()
    },
    selectCustomer (selectedRow) {
      this.selectedCustomer = selectedRow
      if (this.selected === selectedRow.id) {
        this.selected = '-1'
        this.selectedCustomer = null
      } else {
        this.selected = selectedRow.id
      }
    },
    testSelectArea (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectArea(selectedRow)
      }
    },
    populateCustomersTable () {
      this.initialiseTable(
        'customersTable',
        this.customers,
        ['name'],
        ['Top Level Site'],
        ['100%'],
        '600px',
        null,
        18
      )
    },
    getRowColour (id) {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      if (this.selectSite === id) {
        rtn = 'itemSelected'
      }
      return rtn
    }
  }
}

</script>
<style scoped>
  .mdSelection {
    display:inline-block;
    width: 380px;
    vertical-align: top;
    margin-left: 10px;
  }

  .mdCustomerManagement{
    display:inline-block;
    margin: 0;
    width:100%;
  }

  .mdCustomerManagement.mdHalfScreen{
    width:calc(100% - 400px);
  }

  .securityHolder {
    width:100%;
  }

  .appSectionBody{
    display:inline-block;
    margin-top:10px;
    background: white;
    padding: 15px;
  }
</style>
