<template>
  <div>
    <div class="pcwItem">
      <HeaderLogo
        :class="{showLogo:!hideLogo}"
        class="pcwLogo"
      />
    </div>

    <passwordChange
      v-if="passwordResetSuccessful==false"
      :reset-token="id"
      class="pcwPasswordResetPage"
      @user-updated="passwordWasChanged()"
    />
    <div
      v-else
      class="pcwContainer"
    >
      <div class="pcwSubHeader"><h4>Your password was successfully reset</h4></div>
      <div class="pcwButtons">
        <PortalButton
          class="pcwButton"
          button-text="Go to Logon Page"
          direction="right"
          button-id="btnForgottenPassword"
          @Click="navigateToLogonPage()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import HeaderLogo from '../common/headerLogo'
import passwordChange from './passwordChangeComponent'
import PortalButton from '../common/portalButton'

export default {
  components: {
    passwordChange,
    HeaderLogo,
    PortalButton
  },
  data () {
    return {
      hideLogo: true,
      id: this.$route.query.id,
      passwordResetSuccessful: false
    }
  },
  methods: {
    passwordWasChanged () {
      this.passwordResetSuccessful = true
    },
    navigateToLogonPage () {
      document.location = '/'
    }
  }
}
</script>

<style scoped>
    .pcwLogo {
        width:400px;
        padding-left:35px;
        padding-top:10px;
        height:90px;
        opacity: 0;
    }
    .pcwLogo.showLogo {
        opacity: 1;
    }
    .pcwItem {
        width:100%;
    }
    .pcwMinRow {
        min-height: 30px;
    }
    .pcwButtons {
        align-items:flex-end;
        display:flex;
    }
    .pcwButton {
        margin: auto 0;
        width:50%;
    }
    .pcwContainer {
        display: flex;
        flex-direction: column;
        width:100%;
        height: 200px;
        justify-content: space-between;
        padding: 35px;
    }
</style>
