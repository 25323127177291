import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'
import { mapActions } from 'vuex'

export const iQuoteCalculationsCallmediaCXNowMixin = {
  name: 'iQuoteCalculationsCallmediaCXNowMixin',
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
      callmediaOutboundUsers: 0
    }
  },
  computed: {
    quoteTechnologyCallmediaCXNowStore () {
      return this.$store.getters.quoteTechnologyCallmediaCXNow
    },
    callmediaCXNowCalcs_totalCost () {
      var cost = 0
      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.serviceId === this.iQuoteConstants.services.callmediaCXNow &&
            unitCost.includeInCalculations) {
          if (unitCost.recurringCost) {
            cost += unitCost.cost * unitCost.quantity * unitCost.paymentsPerYear * (this.iQuoteStore_GetQuote.termLengthMonths / 12)
          } else {
            cost += unitCost.cost * unitCost.quantity
          }
        }
      })

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.serviceId === this.iQuoteConstants.services.callmediaCXNow &&
            license.includeInCalculations) {
          cost += license.cost * this.iQuoteStore_GetQuote.termLengthMonths * license.quantity
        }
      })

      return cost
    },
    callmediaCXNowCalcs_totalPrice () {
      var price = 0
      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.serviceId === this.iQuoteConstants.services.callmediaCXNow &&
            unitCost.includeInCalculations) {
          if (unitCost.recurringCost) {
            price += unitCost.price * unitCost.quantity * unitCost.paymentsPerYear * (this.iQuoteStore_GetQuote.termLengthMonths / 12)
          } else {
            price += unitCost.price * unitCost.quantity
          }
        }
      })

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.serviceId === this.iQuoteConstants.services.callmediaCXNow &&
            license.includeInCalculations) {
          price += license.price * this.iQuoteStore_GetQuote.termLengthMonths * license.quantity
        }
      })

      return price
    },
    callmediaCXNowCalcs_totalUsersCount () {
      if (this.quoteTechnologyCallmediaCXNowStore.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyCallmediaCXNowStore.userProfiles.forEach(userProfile => {
        users += userProfile.minCommitment
      })
      return users
    },
    callmediaCXNowCalcs_voiceOnlyUsersCount () {
      if (this.quoteTechnologyCallmediaCXNowStore.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyCallmediaCXNowStore.userProfiles.forEach(userProfile => {
        if (userProfile.technologyUserProfileId === this.iQuoteConstants.cxNowUserProfiles.VOICE_ONLY) {
          users += userProfile.minCommitment
        }
      })
      return users
    },
    callmediaCXNowCalcs_digitalOnlyUsersCount () {
      if (this.quoteTechnologyCallmediaCXNowStore.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyCallmediaCXNowStore.userProfiles.forEach(userProfile => {
        if (userProfile.technologyUserProfileId === this.iQuoteConstants.cxNowUserProfiles.DIGITAL_ONLY) {
          users += userProfile.minCommitment
        }
      })
      return users
    },
    callmediaCXNowCalcs_advancedUsersCount () {
      if (this.quoteTechnologyCallmediaCXNowStore.id === undefined) {
        return 0
      }

      var users = 0

      this.quoteTechnologyCallmediaCXNowStore.userProfiles.forEach(userProfile => {
        if (userProfile.technologyUserProfileId === this.iQuoteConstants.cxNowUserProfiles.ADVANCED) {
          users += userProfile.minCommitment
        }
      })
      return users
    },
    callmediaCXNowCalcs_multiChannelUsersCount () {
      return this.callmediaCXNowCalcs_digitalOnlyUsersCount + this.callmediaCXNowCalcs_advancedUsersCount
    },
    callmediaCXNowCalcs_LicensePriceAdjustMultiplier () {
      var priceAdjust = 1

      if (this.quoteTechnologyCallmediaCXNowStore.options !== undefined && this.quoteTechnologyCallmediaCXNowStore.options.accelerateCXNow) {
        priceAdjust *= 0.67 /* 33% reduction in price for accelerate now */
      }

      return priceAdjust
    },
    callmediaCXNowCalcs_PlatformChargePriceAdjustMultiplier () {
      var priceAdjust = 1

      if (this.iQuoteStore_GetQuote.termLengthMonths === 12) {
        priceAdjust /= 0.75 /* Add a 25% margin to 12 month contracts */
      }

      if (this.quoteTechnologyCallmediaCXNowStore.options !== undefined && this.quoteTechnologyCallmediaCXNowStore.options.accelerateCXNow) {
        priceAdjust *= 0.67 /* 33% reduction in price for accelerate now */
      }

      return priceAdjust
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyCallmediaCXNow',
      'setQuoteLicensesRequired'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    callmediaCXNowCalcs_calculate: function () {
      if (this.quoteTechnologyCallmediaCXNowStore.id !== undefined) {
        this.callmediaCXNowCalcs_calcUserLicenses()
        this.callmediaCXNowCalcs_calcAdjunctLicenses()
        this.callmediaCXNowCalcs_calcUnitCosts()
        this.callmediaCXNowCalcs_adjustPricingIfNotProfitable()
      }
    },
    callmediaCXNowCalcs_calcAdjunctLicenses: function () {
      if (this.quoteTechnologyCallmediaCXNowStore.id === undefined) {
        return
      }

      for (let index = 0; index < this.quoteTechnologyCallmediaCXNowStore.connectors.length; index++) {
        const connector = this.quoteTechnologyCallmediaCXNowStore.connectors[index]
        if (connector.selected) {
          switch (connector.connectorId) {
            case this.iQuoteConstants.cxNowConnectors.CRM_SF_STD:
              this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_CRM_SF_STD, 1)
              break
            case this.iQuoteConstants.cxNowConnectors.CRM_MS_365_STD:
              this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_CRM_MS_365_STD, 1)
              break
          }
        }
      }

      for (let index = 0; index < this.quoteTechnologyCallmediaCXNowStore.apps.length; index++) {
        const app = this.quoteTechnologyCallmediaCXNowStore.apps[index]
        if (app.selected) {
          switch (app.appId) {
            case this.iQuoteConstants.cxNowApps.WHATSAPP:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_WHATSAPP_BASE, 1)

              if (app.quantity > 1000) {
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_WHATSAPP_ADD_500, Math.ceil((app.quantity - 1000) / 500))
              }
              break
          }
        }
      }

      if (this.quoteTechnologyCallmediaCXNowStore.options.pstnConnectionsRequired > 0) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_NAILEDUP_SIP, this.quoteTechnologyCallmediaCXNowStore.options.pstnConnectionsRequired)
      }
    },
    callmediaCXNowCalcs_calcUnitCosts () {
      var quoteTechnologyCallmediaCXNow = this.$store.getters.quoteTechnologyCallmediaCXNow

      switch (quoteTechnologyCallmediaCXNow.options.platformSize) {
        case 50:
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_DIGITAL_SMALL, 1)

          if (quoteTechnologyCallmediaCXNow.options.ccisConnections > 25) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_CCIS_SERVER_SMALL, quoteTechnologyCallmediaCXNow.options.ccisConnections - 25)
          }
          break
        case 100:
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_DIGITAL_MEDIUM, 1)

          if (quoteTechnologyCallmediaCXNow.options.ccisConnections > 25) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_CCIS_SERVER_MEDIUM, (quoteTechnologyCallmediaCXNow.options.ccisConnections - 25) / 25)
          }
          break
        case 200:
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_DIGITAL_LARGE, 1)

          if (quoteTechnologyCallmediaCXNow.options.ccisConnections > 25) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_CCIS_SERVER_LARGE, (quoteTechnologyCallmediaCXNow.options.ccisConnections - 25) / 25)
          }
          break
      }

      if (!this.utilFunctions_UnitCostExistsOnQuote(this.iQuoteConstants.unitCosts.TEAMS_SIP_SETUP)) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_GAMMA_SIP_SETUP, 2)
      }

      if (this.callmediaCXNowCalcs_multiChannelUsersCount > 0 && quoteTechnologyCallmediaCXNow.options.addTalkativePS) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_TALKATIVE_PRO_SERVICES, 1)
      }

      if (quoteTechnologyCallmediaCXNow.options.addPSBundle) {
        var bundleProvisioningDays
        var bundleProjectCoordinationDays
        var bundleApplicationsConsultantDays
        var bundleProductSpecialistDays
        var bundleProductSpecialistHalfDays
        var buildInstallationsEngineerDays
        var buildApplicationsConsultantDays
        var buildCloudInfrastructureSpecialistDays
        var bundleId

        /* BUILD COSTS */
        buildInstallationsEngineerDays = 0.5
        buildApplicationsConsultantDays = 2.5
        buildCloudInfrastructureSpecialistDays = 1
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_PS_APPLICATIONS_CONSULTANT, buildApplicationsConsultantDays)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_PS_CLOUD_INFRASTRUCTURE_SPECIALIST, buildCloudInfrastructureSpecialistDays)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_PS_INSTALLATIONS_ENGINEER, buildInstallationsEngineerDays)

        /* BUNDLE COSTS */
        if (this.callmediaCXNowCalcs_advancedUsersCount > 0 ||
           (this.callmediaCXNowCalcs_digitalOnlyUsersCount > 0 && this.callmediaCXNowCalcs_voiceOnlyUsersCount > 0)) {
          /* Mixture of Voice and Digital */
          bundleProvisioningDays = 0.5
          bundleProjectCoordinationDays = 4.5
          bundleApplicationsConsultantDays = 1.5
          bundleProductSpecialistDays = 11
          bundleProductSpecialistHalfDays = 0

          bundleId = this.iQuoteConstants.unitCosts.CXNOW_PS_DIGITAL_BUNDLE
        } else if (this.callmediaCXNowCalcs_digitalOnlyUsersCount) {
          /* Digital Only */
          bundleProvisioningDays = 1
          bundleProjectCoordinationDays = 3.5
          bundleApplicationsConsultantDays = 1
          bundleProductSpecialistDays = 11
          bundleProductSpecialistHalfDays = 1

          bundleId = this.iQuoteConstants.unitCosts.CXNOW_PS_DIGITAL_BUNDLE
        } else {
          /* Voice Only */
          bundleProvisioningDays = 0.5
          bundleProjectCoordinationDays = 4
          bundleApplicationsConsultantDays = 1.5
          bundleProductSpecialistDays = 11
          bundleProductSpecialistHalfDays = 0

          bundleId = this.iQuoteConstants.unitCosts.CXNOW_PS_START_BUNDLE
        }

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_PS_PROVISIONING, bundleProvisioningDays)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_PS_PROJECT_COORDINATOR, bundleProjectCoordinationDays)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_PS_APPLICATIONS_CONSULTANT, bundleApplicationsConsultantDays)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_PS_PRODUCT_SPECIALIST, bundleProductSpecialistDays)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.CXNOW_PS_PRODUCT_SPECIALIST_HALFDAY, bundleProductSpecialistHalfDays)

        var bundleCost = (this.utilFunctions_GetUnitCostCost(this.iQuoteConstants.unitCosts.CXNOW_PS_PROVISIONING) * bundleProvisioningDays) +
                        (this.utilFunctions_GetUnitCostCost(this.iQuoteConstants.unitCosts.CXNOW_PS_PROJECT_COORDINATOR) * bundleProjectCoordinationDays) +
                        (this.utilFunctions_GetUnitCostCost(this.iQuoteConstants.unitCosts.CXNOW_PS_APPLICATIONS_CONSULTANT) * (bundleApplicationsConsultantDays + buildApplicationsConsultantDays)) +
                        (this.utilFunctions_GetUnitCostCost(this.iQuoteConstants.unitCosts.CXNOW_PS_PRODUCT_SPECIALIST) * bundleProductSpecialistDays) +
                        (this.utilFunctions_GetUnitCostCost(this.iQuoteConstants.unitCosts.CXNOW_PS_PRODUCT_SPECIALIST_HALFDAY) * bundleProductSpecialistHalfDays) +
                        (this.utilFunctions_GetUnitCostCost(this.iQuoteConstants.unitCosts.CXNOW_PS_INSTALLATIONS_ENGINEER) * buildInstallationsEngineerDays) +
                        (this.utilFunctions_GetUnitCostCost(this.iQuoteConstants.unitCosts.CXNOW_PS_CLOUD_INFRASTRUCTURE_SPECIALIST) * buildCloudInfrastructureSpecialistDays)

        var bundlePrice = bundleCost / ((100 - this.iQuoteStore_GetQuote.marginCxNowPS) / 100)

        this.iQuoteStore_AddRequiredUnitCostToQuote(bundleId, 1, null, bundleCost, bundlePrice)
      }
    },
    callmediaCXNowCalcs_calcUserLicenses: function () {
      if (this.quoteTechnologyCallmediaCXNowStore.id === undefined) {
        return
      }

      var quoteTechnologyCallmediaCXNowStoreCopy = this.quoteTechnologyCallmediaCXNowStore

      for (let index = 0; index < quoteTechnologyCallmediaCXNowStoreCopy.userProfiles.length; index++) {
        const userProfile = quoteTechnologyCallmediaCXNowStoreCopy.userProfiles[index]
        var requiredLicenseId = 0
        var licensePrice = 0
        var licenseCost = 0
        userProfile.rate = 0
        userProfile.annualTotalCost = 0

        if (userProfile.technologyUserProfileId === this.iQuoteConstants.cxNowUserProfiles.VOICE_ONLY) {
          /* This doesn't not contribute towards the user profile rate or cost, as it's for autotask only */
          requiredLicenseId = this.iQuoteConstants.licenses.CXNOW_START_AUTOTASK
          licensePrice = this.utilFunctions_GetLicensePrice(requiredLicenseId) * this.callmediaCXNowCalcs_LicensePriceAdjustMultiplier
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment, licensePrice)

          requiredLicenseId = this.iQuoteConstants.licenses.CXNOW_VOICE_ONLY_USER
          licenseCost = this.utilFunctions_GetLicenseCost(requiredLicenseId)
          licensePrice = this.utilFunctions_GetLicensePrice(requiredLicenseId) * this.callmediaCXNowCalcs_LicensePriceAdjustMultiplier
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment, licensePrice)
          userProfile.rate += licensePrice
          userProfile.annualTotalCost += userProfile.minCommitment * licenseCost * 12
        } else if (userProfile.technologyUserProfileId === this.iQuoteConstants.cxNowUserProfiles.DIGITAL_ONLY) {
          requiredLicenseId = this.iQuoteConstants.licenses.CXNOW_DIGITAL_ONLY_USER
          licenseCost = this.utilFunctions_GetLicenseCost(requiredLicenseId)
          licensePrice = this.utilFunctions_GetLicensePrice(requiredLicenseId) * this.callmediaCXNowCalcs_LicensePriceAdjustMultiplier
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment, licensePrice)
          userProfile.rate += licensePrice
          userProfile.annualTotalCost += userProfile.minCommitment * licenseCost * 12

          /* This doesn't not contribute towards the user profile rate or cost, as it's for autotask only */
          requiredLicenseId = this.iQuoteConstants.licenses.CXNOW_DIGITAL_AUTOTASK
          licensePrice = this.utilFunctions_GetLicensePrice(requiredLicenseId) * this.callmediaCXNowCalcs_LicensePriceAdjustMultiplier
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment, licensePrice)
        } else if (userProfile.technologyUserProfileId === this.iQuoteConstants.cxNowUserProfiles.ADVANCED) {
          requiredLicenseId = this.iQuoteConstants.licenses.CXNOW_ADVANCED_USER
          licenseCost = this.utilFunctions_GetLicenseCost(requiredLicenseId)
          licensePrice = this.utilFunctions_GetLicensePrice(requiredLicenseId) * this.callmediaCXNowCalcs_LicensePriceAdjustMultiplier
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment, licensePrice)
          userProfile.rate += licensePrice
          userProfile.annualTotalCost += userProfile.minCommitment * licenseCost * 12

          /* This doesn't not contribute towards the user profile rate or cost, as it's for autotask only */
          requiredLicenseId = this.iQuoteConstants.licenses.CXNOW_START_AUTOTASK
          licensePrice = this.utilFunctions_GetLicensePrice(requiredLicenseId) * this.callmediaCXNowCalcs_LicensePriceAdjustMultiplier
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment, licensePrice)

          /* This doesn't not contribute towards the user profile rate or cost, as it's for autotask only */
          requiredLicenseId = this.iQuoteConstants.licenses.CXNOW_DIGITAL_AUTOTASK
          licensePrice = this.utilFunctions_GetLicensePrice(requiredLicenseId) * this.callmediaCXNowCalcs_LicensePriceAdjustMultiplier
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment, licensePrice)
        }

        if (quoteTechnologyCallmediaCXNowStoreCopy.options.integrateWithRingCentral) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_RING_CENTRAL, userProfile.minCommitment)
          licensePrice = this.utilFunctions_GetLicensePrice(this.iQuoteConstants.licenses.CXNOW_RING_CENTRAL)
          userProfile.rate += licensePrice
        }

        userProfile.annualPrice = userProfile.minCommitment * userProfile.rate * 12
      }

      if (this.callmediaCXNowCalcs_multiChannelUsersCount > 0) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_TALKATIVE_PLATFORM, 1)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_TALKATIVE_COMMITTED, this.callmediaCXNowCalcs_multiChannelUsersCount)
      }

      var numSipChannels = Math.ceil((this.callmediaCXNowCalcs_totalUsersCount - this.callmediaCXNowCalcs_digitalOnlyUsersCount) * 1.2) + quoteTechnologyCallmediaCXNowStoreCopy.options.pstnConnectionsRequired

      if (this.iQuoteStore_GetQuote.termLengthMonths >= 36) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_SIP_ACTIVE_36, numSipChannels, null, false)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_SIP_STANDBY_36, numSipChannels, null, false)
      } else {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_SIP_ACTIVE_12, numSipChannels, null, false)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_SIP_STANDBY_12, numSipChannels, null, false)
      }

      this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_SBC_PORT_CHARGE, numSipChannels)
      this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_AT_ASSET, 1)

      this.setQuoteTechnologyCallmediaCXNow(quoteTechnologyCallmediaCXNowStoreCopy)
    },
    callmediaCXNowCalcs_adjustPricingIfNotProfitable: function () {
      if (this.quoteTechnologyCallmediaCXNowStore.id === undefined) {
        return
      }

      var marginPercent = ((this.callmediaCXNowCalcs_totalPrice - this.callmediaCXNowCalcs_totalCost) / this.callmediaCXNowCalcs_totalPrice) * 100

      if (marginPercent < 11) {
        this.callmediaCXNowCalcs_setCxNowUserLicensesToZero()
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.CXNOW_BASE_PACKAGE, 1)
      }
    },
    callmediaCXNowCalcs_setCxNowUserLicensesToZero: function () {
      var licensesRequired = this.iQuoteStore_GetQuoteLicensesRequired
      licensesRequired.forEach(license => {
        if (license.licenseId === this.iQuoteConstants.licenses.CXNOW_VOICE_ONLY_USER ||
            license.licenseId === this.iQuoteConstants.licenses.CXNOW_DIGITAL_ONLY_USER ||
            license.licenseId === this.iQuoteConstants.licenses.CXNOW_ADVANCED_USER
        ) {
          license.price = 0
          license.annualPrice = 0
        }
      })

      this.setQuoteLicensesRequired(licensesRequired)
    }
  }
}
