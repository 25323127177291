<template>
  <div class="pgpConatiner">
    <div
      id="pgpPaginationButtons"
      :style="{ width : calculatedWidth }"
      class="pgpButtons">
      <div
        v-if="totalPages>3"
        :class="{pgpButton : buttonValid('P')}"
        class="pgpPrevious pgpEndBox">
        <a
          href="#"
          @click="selectPage(selectedPage-1)">Prev</a>
      </div>
      <div
        v-if="display.button[0].show"
        :class="{pgpSelection: display.button[0].highlight, pgpButton : buttonValid(display.button[0].pageNumber)}"
        class="pgpBox"
        @click="selectPage(display.button[0].pageNumber)">
        <a
          href="#"
          @click="selectPage(display.button[0].pageNumber)">{{ display.button[0].pageNumber }}</a>
      </div>
      <div
        v-if="display.button[1].show"
        :class="{pgpSelection: display.button[1].highlight, pgpButton : buttonValid(display.button[1].pageNumber)}"
        class="pgpBox"
        @click="selectPage(display.button[1].pageNumber)">
        <a
          href="#"
          @click="selectPage(display.button[1].pageNumber)">{{ display.button[1].pageNumber }}</a>
      </div>
      <div
        v-if="display.button[2].show"
        :class="{pgpSelection: display.button[2].highlight, pgpButton : buttonValid(display.button[2].pageNumber)}"
        class="pgpBox"
        @click="selectPage(display.button[2].pageNumber)">
        <a
          href="#"
          @click="selectPage(display.button[2].pageNumber)">{{ display.button[2].pageNumber }}</a>
      </div>
      <div
        v-if="display.button[3].show"
        :class="{pgpSelection: display.button[3].highlight, pgpButton : buttonValid(display.button[3].pageNumber)}"
        class="pgpBox"
        @click="selectPage(display.button[3].pageNumber)">
        <a
          href="#"
          @click="selectPage(display.button[3].pageNumber)">{{ display.button[3].pageNumber }}</a>
      </div>
      <div
        v-if="display.button[4].show"
        :class="{pgpSelection: display.button[4].highlight, pgpButton : buttonValid(display.button[4].pageNumber)}"
        class="pgpBox"
        @click="selectPage(display.button[4].pageNumber)">
        <a
          href="#"
          @click="selectPage(display.button[4].pageNumber)">{{ display.button[4].pageNumber }}</a>
      </div>
      <div
        v-if="display.button[5].show"
        :class="{pgpSelection: display.button[5].highlight, pgpButton : buttonValid(display.button[5].pageNumber)}"
        class="pgpBox"
        @click="selectPage(display.button[5].pageNumber)">
        <a
          href="#"
          @click="selectPage(display.button[5].pageNumber)">{{ display.button[5].pageNumber }}</a>
      </div>
      <div
        v-if="display.button[6].show"
        :class="{pgpSelection: display.button[6].highlight, pgpButton : buttonValid(display.button[6].pageNumber)}"
        class="pgpBox"
        @click="selectPage(display.button[6].pageNumber)">
        <a
          href="#"
          @click="selectPage(display.button[6].pageNumber)">{{ display.button[6].pageNumber }}</a>
      </div>
      <div
        v-if="totalPages>3"
        :class="{pgpButton : buttonValid('N')}"
        class="pgpNext pgpEndBox">
        <a
          href="#"
          @click="selectPage(selectedPage+1)">Next</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalPages: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {
      selectedPage: null,
      display: {
        previousDots: false,
        nextDots: false,
        button: [
          { show: false, pageNumber: 1, highlight: false },
          { show: false, pageNumber: 2, highlight: false },
          { show: false, pageNumber: 3, highlight: false },
          { show: false, pageNumber: 4, highlight: false },
          { show: false, pageNumber: 5, highlight: false },
          { show: false, pageNumber: 6, highlight: false },
          { show: false, pageNumber: 7, highlight: false }
        ]
      }
    }
  },
  computed: {
    currentPageSelection () {
      return this.selectedPage === null ? this.currentPage : this.selectedPage
    },
    calculatedWidth () {
      var pages = this.totalPages
      if (pages > 7) {
        pages = 7
      }
      var addition = 120
      if (pages < 4) {
        addition = 0
      }
      return (addition + (pages * 40)) + 'px'
    }
  },

  created () {
    this.processSelection()
  },
  methods: {
    selectPage (page) {
      if (!Number.isInteger(page) || this.totalPages < page) {
        return
      }
      this.selectedPage = page
      this.processSelection()
      this.$emit('PageSelected', this.currentPageSelection)
    },
    buttonValid (value) {
      if (value === '...') {
        return false
      }
      if (value === 'P' && this.currentPageSelection === 1) {
        return false
      }
      if (value === 'N' && this.currentPageSelection === this.totalPages) {
        return false
      }
      return value !== this.currentPageSelection
    },
    processSelection () {
      if (this.currentPageSelection <= 0) {
        this.selectedPage = 1
      } else if (this.currentPageSelection > this.totalPages) {
        this.selectedPage = this.totalPages
      }

      this.display = {
        previousDots: false,
        nextDots: false,
        button: [{ show: false, pageNumber: 0, highlight: false },
          { show: false, pageNumber: 0, highlight: false },
          { show: false, pageNumber: 0, highlight: false },
          { show: false, pageNumber: 0, highlight: false },
          { show: false, pageNumber: 0, highlight: false },
          { show: false, pageNumber: 0, highlight: false },
          { show: false, pageNumber: 0, highlight: false }
        ]
      }
      var pageId = 0
      var blnAddPage = false
      for (let counter = 1; counter <= this.totalPages; counter++) {
        blnAddPage = false
        if (counter < 3 ||
                        counter > this.totalPages - 2 ||
                        counter === (this.currentPageSelection + 1) ||
                        counter === (this.currentPageSelection - 1)) {
          blnAddPage = true
        } else if (counter === this.currentPageSelection) {
          blnAddPage = true
        } else if (this.currentPageSelection < 5 && counter < 6) {
          blnAddPage = true
        } else if (this.currentPageSelection > (this.totalPages - 4) && counter > (this.totalPages - 5)) {
          blnAddPage = true
        } else {
          if (counter < this.currentPageSelection) {
            this.display.previousDots = true
          } else {
            this.display.nextDots = true
          }
        }

        if (blnAddPage) {
          this.display.button[pageId].show = true
          this.display.button[pageId].pageNumber = counter
          this.display.button[pageId].highlight = counter === this.currentPageSelection
          pageId++
        }
      }

      if (this.display.previousDots) {
        this.display.button[1].pageNumber = '...'
      }

      if (this.display.nextDots) {
        this.display.button[5].pageNumber = '...'
      }

      this.selectedPage = this.currentPageSelection
    }

  }

}
</script>

<style scoped>
    .pgpConatiner{
        width:100%;
        margin:10px 2px;
        box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    }
    .pgpButtons{
        line-height: 30px;
        display:flex;
        flex-direction: row;
        margin: auto;
        border:1px solid grey;
        cursor: default;
    }
    .pgpBox{
        height:30px;
        width:40px;
        border-left:1px solid grey;
        text-align:center;
        color:rgba(44,62,80);
        background-color: var(--main-bg-colour-light-trans);
        background-color:rgba(255,255,255,0.7);
        opacity:0.5;
    }
    .pgpEndBox{
        height:30px;
        width:60px;
        border:none;
        text-align:center;
        color:rgba(44,62,80);
        opacity:0.5;
        background-color: var(--main-bg-colour-light-trans);
        background-color:rgba(255,255,255,0.7);
    }
    .pgpNext{
        border-left:1px solid grey;
    }
    .pgpSelection{
        text-decoration : underline;
        font-weight: 500;
        font-size:16pt;
        opacity:1;
    }

    .pgpButton{
        cursor: pointer;
        opacity:1;
    }

    .pgpButton:hover{
        transform:scale(1.05)
    }
</style>
