import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'

export const iQuoteCalculationsSIPMigrationMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
    }
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcSipMigration_calculate: function () {
      var quoteSIPMigrationProperties = this.$store.getters.quoteSIPMigrationProperties

      if (quoteSIPMigrationProperties.id === undefined) {
        return
      }

      if (quoteSIPMigrationProperties.numEdgemarcDevices !== 0) {
        // Recurring Unit Costs
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_C2E_HELPDESK_SUPPORT, quoteSIPMigrationProperties.numEdgemarcDevices)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_MAINTEL_SUPPORT_SERVICE_MONTHLY, quoteSIPMigrationProperties.numEdgemarcDevices)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_C2E_USAGE_100_SITE, quoteSIPMigrationProperties.numEdgemarcDevices)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_VRVMS_902_C2E, quoteSIPMigrationProperties.numEdgemarcDevices)

        if (quoteSIPMigrationProperties.survivability) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_C2E_USAGE_1_100_SURV, quoteSIPMigrationProperties.numEdgemarcDevices)
        }

        if (quoteSIPMigrationProperties.overrideShipmentCosts != null) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_SHIPMENT, 1, null, quoteSIPMigrationProperties.overrideShipmentCosts, (quoteSIPMigrationProperties.overrideShipmentCosts / 0.7))
        } else {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_SHIPMENT, quoteSIPMigrationProperties.numEdgemarcDevices)
        }

        if (quoteSIPMigrationProperties.overridePurchaseCost === null) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_EDGE_6000_901_C2E, quoteSIPMigrationProperties.numEdgemarcDevices)
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_EDGE_PC_UK, quoteSIPMigrationProperties.numEdgemarcDevices)
        } else {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_HARDWARE, 1, null, quoteSIPMigrationProperties.overridePurchaseCost, (quoteSIPMigrationProperties.overridePurchaseCost / 0.7))

          // Items added to every quote but with £0 cost and £0 price
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_EDGE_6000_901_C2E, quoteSIPMigrationProperties.numEdgemarcDevices, null, 0, 0)
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIPMIGRATION_EDGE_PC_UK, quoteSIPMigrationProperties.numEdgemarcDevices, null, 0, 0)
        }
      }
    }
  }
}
