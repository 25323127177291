export const dashboardImageMixin = {
  methods: {
    getDashboardImageJson: function () {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest()
        xhr.open('Get', '/dashboard/dashboardImage.json', true)
        xhr.onload = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            try {
              resolve(JSON.parse(xhr.responseText))
            } catch (error) {
              reject(error)
            }
          } else {
            reject(status)
          }
        }
        xhr.send()
      })
    }
  }
}
