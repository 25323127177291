<template>
  <button
    :id="buttonId"
    :title="title"
    :style="buttonStyle + backgroundColour"
    :tabindex="buttonTabindex"
    :disabled="disabled"
    :class="[disabled ? 'textButtonDisabled' : 'textButton']"
    @click="()=>this.$emit('click')"
  >
    {{ text }}
  </button>
</template>

<script>

export default {
  name: 'TextButton',
  props: {
    buttonId: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    buttonStyle: {
      type: String,
      default: ''
    },
    buttonTabindex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    backgroundColour () {
      if (this.disabled) {
        return `background-color: rgb(130 136 120)`
      } else {
        return `background-color: ${this.$store.getters.loginButtonColour}`
      }
    }
  }
}
</script>

<style scoped>
  .textButton {
    cursor: pointer;
    padding: 4px 18px 1px 18px;
    color: white;
    font-size: 1em;
    line-height: 2em;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    box-shadow: 2px 4px 3px rgb(0 0 0 / 30%);
  }
  .textButtonDisabled {
    padding: 4px 18px 1px 18px;
    color: white;
    font-size: 1em;
    line-height: 2em;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    box-shadow: 2px 4px 3px rgb(0 0 0 / 30%);
  }
  .textButton:hover {
        color: #666;
    }
</style>
