import portalAPIGateway from '../../communications/interceptors/apiInterceptor'
export const iQuoteQuoteTechnologyAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteTechnologyAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    createQuoteTechnology: async function (quoteTechnology) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.post('iquote/quoteTechnologies/', quoteTechnology, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('createQuoteTechnology error: ' + error)
      }
      return data
    },
    deleteQuoteTechnology: async function (quoteTechnologyId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.delete('iquote/quoteTechnologies/' + quoteTechnologyId, '', axiosConfig).then((response) => {
          data = response.dat
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('createQuoteTechnology error: ' + error);
      }
      return data
    },
    createQuoteTechnologyUserProfile: async function (userProfile) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.post('iquote/quoteTechnologyUserProfiles/', userProfile, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('createQuoteTechnologyUserProfile error: ' + error)
      }
      return data
    },
    updateQuoteTechnologyUserProfile: async function (userProfile) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.put('iquote/quoteTechnologyUserProfiles/', userProfile, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('updateQuoteTechnologyUserProfile error: ' + error)
      }
      return data
    },
    addQuoteTechnologyId: async function (quoteId, technologyId) {
      var id = 0
      var data
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        var quoteTechnology = {
          Id: 0,
          QuoteId: quoteId,
          TechnologyId: technologyId
        }

        await portalAPIGateway.post('iquote/quoteTechnologies/', quoteTechnology, axiosConfig).then((response) => {
          data = response.data
        })
        id = data.id
      } catch (error) {
        // eslint-disable-next-line
        console.log('addQuoteTechnologyId error: ' + error)
      }
      return id
    },
    getQuoteTechnologyId: async function (quoteId, technologyId) {
      var id = 0
      var data
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        // request existing entry for the quote
        await portalAPIGateway.get('iquotearray/quoteTechnologies/quote/' + quoteId, '', axiosConfig).then((response) => {
          data = response.data
        })

        if (data.length > 0) {
          data.forEach(technology => {
            if (technology.technologyId === technologyId && id === 0) {
              id = technology.id
            }
          })
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteTechnologyId error: ' + error)
      }
      return id
    },
    getQuoteTechnologyUserProfiles: async function (QuoteTechnologyId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/quoteTechnologyUserProfiles/quotetechnology/' + QuoteTechnologyId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteTechnologyUserProfiles error: ' + error)
      }
      return data
    },
    getQuoteTechnologyHardware: async function (QuoteTechnologyId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/quoteTechnologyHardware/quotetechnology/' + QuoteTechnologyId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteTechnologyHardware error: ' + error)
      }
      return data
    },
    getQuoteAuraAdjuncts: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/AvayaAura/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteAuraAdjuncts error: ' + error)
      }
      return data
    },
    getQuoteOptionsAvayaAura: async function (QuoteId) {
      var data = {}

      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsAvayaAura/quote/' + QuoteId, '', axiosConfig).then((response) => {
          if (response.data.id !== undefined) {
            data = response.data
          } else {
            // Default value in case this isn't on the quote
            data = {
              id: 0,
              quoteId: QuoteId,
              publicNameSpaceOptionId: 1 /* MAINTEL_PROVIDES */
            }

            console.log('getQuoteOptionsAvayaAura.  No Entry found - setting options to default')
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsAvayaAura error: ' + error)
      }
      return data
    },
    getQuoteCallmediaAdjuncts: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/Callmedia/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteCallmediaAdjuncts error: ' + error)
      }
      return data
    },
    getQuoteAvayaEliteAdjuncts: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/AvayaElite/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteAvayaEliteAdjuncts error: ' + error)
      }
      return data
    },
    getQuoteAvayaAACCAdjuncts: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/AvayaAACC/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteAvayaAACCAdjuncts error: ' + error)
      }
      return data
    },
    getQuoteMitelCCAdjuncts: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/MitelCC/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteMitelCCAdjuncts error: ' + error)
      }
      return data
    },
    getQuoteCallmediaCXNowAdjuncts: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/CallmediaCXNowAdjuncts/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteCallmediaCXNow Adjuncts error: ' + error)
      }
      return data
    },
    getQuoteMitelUCAdjuncts: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/MitelUC/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteMitelUCAdjuncts error: ' + error)
      }
      return data
    },
    getQuoteOptionsMitelUC: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsMitelUC/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsMitelUC error: ' + error)
      }
      return data
    },
    getQuoteOptionsCallmediaCXNow: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsCallmediaCXNow/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsCallmediaCXNow error: ' + error)
      }
      return data
    },
    getQuoteOptionsIconGateway: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsIconGateway/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsIconGateway error: ' + error)
      }
      return data
    },
    getQuoteCallmediaCXNowConnectors: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/QuoteCallmediaCXNowConnectors/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteCallmediaCXNowConnectors error: ' + error)
      }
      return data
    },
    getQuoteCallmediaCXNowApps: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/QuoteCallmediaCXNowApps/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteCallmediaCXNowApps error: ' + error)
      }
      return data
    },
    getQuoteOptionsMitelCC: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsMitelCC/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsMitelCC error: ' + error)
      }
      return data
    },
    getQuoteOptionsCallmedia: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsCallmedia/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsCallmedia error: ' + error)
      }
      return data
    },
    getQuoteOptionsGenesys: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsGenesys/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsGenesys error: ' + error)
      }
      return data
    },
    getQuoteOptionsAvayaAXP: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsAvayaAXP/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsAvayaAXP error: ' + error)
      }
      return data
    },
    getQuoteOptionsSycurio: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsSycurio/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsSycurio error: ' + error)
      }
      return data
    },
    getQuoteOptionsUCAnalytics: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsUCAnalytics/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsUCAnalytics error: ' + error)
      }
      return data
    },
    getQuoteOptionsRingCentral: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsRingCentral/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsRingCentral error: ' + error)
      }
      return data
    },
    getQuoteOptionsAvayaAACC: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteOptionsAvayaAACC/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteOptionsAvayaAACC error: ' + error)
      }
      return data
    },
    getQuoteSIPProperties: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/SIPProperties/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data

          if (data === '') {
            // No SIP is on the quote
            data = {}
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteSIPProperties error: ' + error)
      }
      return data
    },
    getQuotePhoneLinePlusProperties: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/quotephonelineplusproperties/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data

          if (data === '') {
            // No SIP migration is on the quote
            data = {}
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuotePhoneLinePlusProperties error: ' + error)
      }
      return data
    },
    getQuoteSIPMigrationProperties: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/quotesipmigrationproperties/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data

          if (data === '') {
            // No SIP migration is on the quote
            data = {}
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteSIPMigrationProperties error: ' + error)
      }
      return data
    },
    saveQuoteAuraAdjuncts: async function (adjuncts) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (adjuncts.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/AvayaAura/', adjuncts, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteAuraAdjuncts (UPDATE) error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/AvayaAura/', adjuncts, axiosConfig).then((response) => {
            adjuncts = response.data /* Adding new entry returns the id given to the record by the DB */
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteAuraAdjuncts (ADD) error: ' + error)
        }
      }

      return adjuncts
    },
    saveQuoteCallmediaAdjuncts: async function (adjuncts) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (adjuncts.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/Callmedia/', adjuncts, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteCallmediaAdjuncts (UPDATE) error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/Callmedia/', adjuncts, axiosConfig).then((response) => {
            adjuncts = response.data /* Adding new entry returns the id given to the record by the DB */
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteCallmediaAdjuncts (ADD) error: ' + error)
        }
      }

      return adjuncts
    },
    saveQuoteCallmediaCXNowAdjuncts: async function (adjuncts) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (adjuncts.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/CallmediaCXNowAdjuncts/', adjuncts, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteCallmediaCXNowAdjuncts (UPDATE) error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/CallmediaCXNowAdjuncts/', adjuncts, axiosConfig).then((response) => {
            adjuncts = response.data /* Adding new entry returns the id given to the record by the DB */
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteCallmediaCXNowAdjuncts (ADD) error: ' + error)
        }
      }

      return adjuncts
    },
    saveQuoteCallmediaCXNowConnectors: async function (connectorsList) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      var connectorsDtoList = []

      for (let index = 0; index < connectorsList.length; index++) {
        const connectorItem = connectorsList[index]
        var connectorDto = {}
        connectorDto.callmediaCxNowConnectorId = connectorItem.callmediaCxNowConnectorId
        connectorDto.id = connectorItem.id
        connectorDto.quoteId = connectorItem.quoteId
        connectorDto.selected = connectorItem.selected

        connectorsDtoList.push(connectorDto)
      }

      if (connectorsDtoList[0].id !== 0) {
        try {
          await portalAPIGateway.put('iquotearray/QuoteCallmediaCXNowConnectors/', connectorsDtoList, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteCallmediaCXNowConnectors error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquotearray/QuoteCallmediaCXNowConnectors/', connectorsDtoList, axiosConfig).then((response) => {
            connectorsList = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteCallmediaCXNowConnectors error: ' + error)
        }
      }

      return connectorsList
    },
    saveQuoteCallmediaCXNowApps: async function (appsList) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      var appsDtoList = []

      for (let index = 0; index < appsList.length; index++) {
        const appItem = appsList[index]
        var appDto = {}
        appDto.id = appItem.id
        appDto.quoteId = appItem.quoteId
        appDto.callmediaCxNowAppId = appItem.callmediaCxNowAppId
        appDto.selected = appItem.selected
        appDto.quantity = appItem.quantity

        appsDtoList.push(appDto)
      }

      if (appsDtoList[0].id !== 0) {
        try {
          await portalAPIGateway.put('iquotearray/QuoteCallmediaCXNowApps/', appsDtoList, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteCallmediaCXNowApps error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquotearray/QuoteCallmediaCXNowApps/', appsDtoList, axiosConfig).then((response) => {
            appsList = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteCallmediaCXNowApps error: ' + error)
        }
      }

      return appsList
    },
    saveQuoteMitelUCAdjuncts: async function (adjuncts) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (adjuncts.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/MitelUC/', adjuncts, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteMitelUCAdjuncts (UPDATE) error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/MitelUC/', adjuncts, axiosConfig).then((response) => {
            adjuncts = response.data /* Adding new entry returns the id given to the record by the DB */
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteMitelUCAdjuncts (ADD) error: ' + error)
        }
      }

      return adjuncts
    },
    saveQuoteMitelCCAdjuncts: async function (adjuncts) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (adjuncts.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/MitelCC/', adjuncts, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteMitelCCAdjuncts (UPDATE) error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/MitelCC/', adjuncts, axiosConfig).then((response) => {
            adjuncts = response.data /* Adding new entry returns the id given to the record by the DB */
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteMitelCCAdjuncts (ADD) error: ' + error)
        }
      }

      return adjuncts
    },
    saveQuoteEliteAdjuncts: async function (adjuncts) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (adjuncts.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/AvayaElite/', adjuncts, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteEliteAdjuncts (UPDATE) error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/AvayaElite/', adjuncts, axiosConfig).then((response) => {
            adjuncts = response.data /* Adding new entry returns the id given to the record by the DB */
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteEliteAdjuncts (ADD) error: ' + error)
        }
      }

      return adjuncts
    },
    saveQuoteAACCAdjuncts: async function (adjuncts) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (adjuncts.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/AvayaAACC/', adjuncts, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteAACCAdjuncts (UPDATE) error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/AvayaAACC/', adjuncts, axiosConfig).then((response) => {
            adjuncts = response.data /* Adding new entry returns the id given to the record by the DB */
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteAACCAdjuncts (ADD) error: ' + error)
        }
      }

      return adjuncts
    },
    saveQuoteOptionsAvayaAura: async function (options) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (options.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsAvayaAura/', options, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsAvayaAura error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsAvayaAura/', options, axiosConfig).then((response) => {
            options = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsAvayaAura error: ' + error)
        }
      }
      return options
    },
    saveQuoteOptionsAvayaAACC: async function (options) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (options.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsAvayaAACC/', options, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsAvayaAACC error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsAvayaAACC/', options, axiosConfig).then((response) => {
            options = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsAvayaAACC error: ' + error)
        }
      }
      return options
    },
    saveQuoteOptionsMitelUC: async function (options) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (options.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsMitelUC/', options, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsMitelUC error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsMitelUC/', options, axiosConfig).then((response) => {
            options = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsMitelUC error: ' + error)
        }
      }
      return options
    },
    saveQuoteOptionsMitelCC: async function (options) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (options.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsMitelCC/', options, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsMitelCC error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsMitelCC/', options, axiosConfig).then((response) => {
            options = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsMitelCC error: ' + error)
        }
      }
      return options
    },
    saveQuoteOptionsCallmedia: async function (options) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (options.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsCallmedia/', options, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsCallmedia error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsCallmedia/', options, axiosConfig).then((response) => {
            options = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsCallmedia error: ' + error)
        }
      }
      return options
    },
    saveQuoteOptionsCallmediaCXNow: async function (options) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (options.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsCallmediaCXNow/', options, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsCallmediaCXNow error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsCallmediaCXNow/', options, axiosConfig).then((response) => {
            options = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsCallmediaCXNow error: ' + error)
        }
      }
      return options
    },
    saveQuoteOptionsIconGateway: async function (options) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (options.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsIconGateway/', options, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsIconGateway error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsIconGateway/', options, axiosConfig).then((response) => {
            options = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsIconGateway error: ' + error)
        }
      }
      return options
    },
    saveQuoteSipProperties: async function (sipProperties) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (sipProperties.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/SIPProperties/', sipProperties, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteSipProperties error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/SIPProperties/', sipProperties, axiosConfig).then((response) => {
            sipProperties = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteSipProperties error: ' + error)
        }
      }
      return sipProperties
    },
    saveQuotePhoneLinePlusProperties: async function (sipPhoneLinePlusProperties) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (sipPhoneLinePlusProperties.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/quotephonelineplusproperties/', sipPhoneLinePlusProperties, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuotePhoneLinePlusProperties error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/quotephonelineplusproperties/', sipPhoneLinePlusProperties, axiosConfig).then((response) => {
            sipPhoneLinePlusProperties = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuotePhoneLinePlusProperties error: ' + error)
        }
      }
      return sipPhoneLinePlusProperties
    },
    saveQuoteSipMigrationProperties: async function (sipMigrationProperties) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      if (sipMigrationProperties.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/quotesipmigrationproperties/', sipMigrationProperties, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteSipMigrationProperties error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/quotesipmigrationproperties/', sipMigrationProperties, axiosConfig).then((response) => {
            sipMigrationProperties = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteSipMigrationProperties error: ' + error)
        }
      }
      return sipMigrationProperties
    },
    saveQuoteTechnologyHardware: async function (hardwareList) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      var hardwareDtoList = []

      for (let index = 0; index < hardwareList.length; index++) {
        const hardwareItem = hardwareList[index]
        var hardwareItemDto = {}
        hardwareItemDto.id = hardwareItem.id
        hardwareItemDto.quoteTechnologyId = hardwareItem.quoteTechnologyId
        hardwareItemDto.technologyHardwareId = hardwareItem.technologyHardwareId
        hardwareItemDto.quantity = hardwareItem.quantity
        hardwareItemDto.totalCost = hardwareItem.totalCost

        hardwareDtoList.push(hardwareItemDto)
      }

      if (hardwareDtoList[0].id !== 0) {
        try {
          await portalAPIGateway.put('iquotearray/quoteTechnologyHardware/', hardwareDtoList, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteTechnologyHardware error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquotearray/quoteTechnologyHardware/', hardwareDtoList, axiosConfig).then((response) => {
            hardwareList = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteTechnologyHardware error: ' + error)
        }
      }

      return hardwareList
    },
    saveQuoteTechnologyUserProfiles: async function (userProfilesList) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      var userProfilesDtoList = []

      if (userProfilesList?.length) {
        for (let index = 0; index < userProfilesList.length; index++) {
          const userProfileItem = userProfilesList[index]
          var userProfileDto = {}
          userProfileDto.id = userProfileItem.id
          userProfileDto.quoteTechnologyId = userProfileItem.quoteTechnologyId
          userProfileDto.technologyUserProfileId = userProfileItem.technologyUserProfileId
          userProfileDto.minCommitment = userProfileItem.minCommitment
          userProfileDto.maxAllowed = userProfileItem.maxAllowed
          userProfileDto.installCost = userProfileItem.installCost
          userProfileDto.annualTotalCost = userProfileItem.annualTotalCost
          userProfileDto.rateCardCost = userProfileItem.rateCardCost

          userProfilesDtoList.push(userProfileDto)
        }

        if (userProfilesDtoList[0].id !== 0) {
          try {
            await portalAPIGateway.put('iquotearray/quoteTechnologyUserProfiles/', userProfilesDtoList, axiosConfig).then((response) => {
            })
          } catch (error) {
            // eslint-disable-next-line
            console.log('saveQuoteTechnologyUserProfiles error: ' + error)
          }
        } else {
          try {
            await portalAPIGateway.post('iquotearray/quoteTechnologyUserProfiles/', userProfilesDtoList, axiosConfig).then((response) => {
              userProfilesList = response.data
            })
          } catch (error) {
            // eslint-disable-next-line
            console.log('saveQuoteTechnologyUserProfiles error: ' + error)
          }
        }
      }
      return userProfilesList
    },
    getQuoteTeamsConnectorProperties: async function (QuoteId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquote/QuoteTeamsConnectorProperties/quote/' + QuoteId, '', axiosConfig).then((response) => {
          data = response.data

          if (data === '') {
            // No Teams Connector is on the quote
            data = {}
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteTeamsConnectorProperties error: ' + error)
      }
      return data
    },
    saveQuoteTeamsConnectorProperties: async function (quoteTeamsConnectorProperties) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()

      if (quoteTeamsConnectorProperties.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteTeamsConnectorProperties/', quoteTeamsConnectorProperties, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteTeamsConnectorProperties error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteTeamsConnectorProperties/', quoteTeamsConnectorProperties, axiosConfig).then((response) => {
            quoteTeamsConnectorProperties = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteTeamsConnectorProperties error: ' + error)
        }
      }

      return quoteTeamsConnectorProperties
    },
    saveQuoteOptionsGenesys: async function (genesysOptions) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()

      if (genesysOptions.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsGenesys/', genesysOptions, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsGenesys error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsGenesys/', genesysOptions, axiosConfig).then((response) => {
            genesysOptions = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsGenesys error: ' + error)
        }
      }

      return genesysOptions
    },
    saveQuoteOptionsAvayaAXP: async function (avayaAXPOptions) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()

      if (avayaAXPOptions.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsAvayaAXP/', avayaAXPOptions, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsAvayaAXP error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsAvayaAXP/', avayaAXPOptions, axiosConfig).then((response) => {
            avayaAXPOptions = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsAvayaAXP error: ' + error)
        }
      }

      return avayaAXPOptions
    },
    saveQuoteOptionsSycurio: async function (sycurioOptions) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()

      if (sycurioOptions.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsSycurio/', sycurioOptions, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsSycurio error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsSycurio/', sycurioOptions, axiosConfig).then((response) => {
            sycurioOptions = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsSycurio error: ' + error)
        }
      }

      return sycurioOptions
    },
    saveQuoteOptionsUCAnalytics: async function (ucAnalyticsOptions) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()

      if (ucAnalyticsOptions.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsUCAnalytics/', ucAnalyticsOptions, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsUCAnalytics error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsUCAnalytics/', ucAnalyticsOptions, axiosConfig).then((response) => {
            ucAnalyticsOptions = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsUCAnalytics error: ' + error)
        }
      }

      return ucAnalyticsOptions
    },
    saveQuoteOptionsRingCentral: async function (genesysOptions) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()

      if (genesysOptions.id !== 0) {
        try {
          await portalAPIGateway.put('iquote/QuoteOptionsRingCentral/', genesysOptions, axiosConfig).then((response) => {
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsRingCentral error: ' + error)
        }
      } else {
        try {
          await portalAPIGateway.post('iquote/QuoteOptionsRingCentral/', genesysOptions, axiosConfig).then((response) => {
            genesysOptions = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('saveQuoteOptionsRingCentral error: ' + error)
        }
      }

      return genesysOptions
    },
    getQuoteTechnologyUserProfilesApprovedRCPrice: async function (QuoteTechnologyId) {
      var data = {}
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/quoteTechnologyUserProfilesApprovedRCPrice/quotetechnology/' + QuoteTechnologyId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteTechnologyUserProfilesApprovedRCPrice error: ' + error)
      }
      return data
    },
    saveQuoteTechnologyUserProfilesApprovedRCPrice: async function (userProfiles) {
      const axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      let userProfilesApprovedRCPriceList = []
      let userProfilesApprovedRCPriceDtoList = []
      for (let index = 0; index < userProfiles.length; index++) {
        const item = userProfiles[index]
        if (item.buyPrice !== undefined && item.buyPrice > 0) {
          var userProfileApprovedRCPriceDto = {}
          userProfileApprovedRCPriceDto.id = 0
          userProfileApprovedRCPriceDto.quoteTechnologyId = item.quoteTechnologyId
          userProfileApprovedRCPriceDto.technologyUserProfileId = item.technologyUserProfileId
          userProfileApprovedRCPriceDto.buyPrice = item.buyPrice
          userProfilesApprovedRCPriceDtoList.push(userProfileApprovedRCPriceDto)
        }
      }
      if (userProfilesApprovedRCPriceDtoList.length === 0) {
        return
      }
      try {
        await portalAPIGateway.post('iquotearray/quoteTechnologyUserProfilesApprovedRCPrice/', userProfilesApprovedRCPriceDtoList, axiosConfig).then((response) => {
          userProfilesApprovedRCPriceList = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('saveQuoteTechnologyUserProfilesApprovedRCPrice error: ' + error)
      }

      return userProfilesApprovedRCPriceList
    },
    deleteQuoteTechnologyUserProfilesApprovedRCPrice: async function (quoteTechnologyId) {
      var axiosConfig = this.iQuoteQuoteTechnologyAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.delete('iquotearray/quoteTechnologyUserProfilesApprovedRCPrice/' + quoteTechnologyId, null, axiosConfig).then((response) => {
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('deleteQuoteTechnologyUserProfilesApprovedRCPrice error: ' + error)
      }

      return []
    }
  }
}
