<template>
  <div style="padding-top:30px;">
    <div
      v-if="!viewEntityPermission"
      class="noResults"
    >
      Sorry, you do not have permission view requesting users
    </div>
    <div
      v-else
      :class="sectionClass"
    >
      <table
        id="companiesTable"
        style="table-layout: auto;margin-bottom:20px;font-weight: normal;"
      >
        <thead>
          <tr>
            <th>Email Address</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Company Name</th>
            <th>Request Date</th>
            <th>Approved</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="c in currentFilter"
            :key="c.emailAddress"
            @click="selectRequestingUser(c)"
          >
            <td
              :title="c.emailAddress"
            >{{ c.emailAddress }}</td>
            <td
              :title="c.firstName"
            >{{ c.firstName }}</td>
            <td
              :title="c.lastName"
            >{{ c.lastName }}</td>
            <td
              :title="c.companyName"
            >{{ c.companyName }}</td>
            <td
              :title="getScreenDate(c.requestDate)"
            >{{ getScreenDate(c.requestDate) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="selectedRequestingUser.length>0"
      class="mdSelection"
    >
      <SectionCollapsedHeader
        id="requestingUserEmail"
        icon-file="email"
        type="subHeader"
        title="Resend Request Email"
        controls="requestingUserEmail"
      />
      <RequestingUserEmail
        id="requestingUserEmail"
        :request="selectedRequestingUserRecord"
      />
      <SectionCollapsedHeader
        id="requestingUserApprove"
        icon-file="Tick"
        type="subHeader"
        title="Approve Access Request"
        controls="requestingUserApprove"
      />
      <RequestingUserApprove
        id="requestingUserApprove"
        :request="selectedRequestingUserRecord"
        @updated="updateTable()"
      />
      <SectionCollapsedHeader
        id="requestingUserReject"
        icon-file="Cross"
        type="subHeader"
        title="Reject Access Request"
        controls="requestingUserReject"
      />
      <RequestingUserReject
        id="requestingUserReject"
        :request="selectedRequestingUserRecord"
        @updated="updateTable()"
      />
    </div>
    <div class="bottombar" >
      <Spinner v-if="saveInProgress" />
      <input
        v-if="!saveInProgress"
        id="cancelRequestAccessButton"
        class="cancelRequestAccessButton"
        type="button"
        title="Cancel the request access process"
        @click="closeRequestingUserForm()"
      >
    </div>
  </div>
</template>

<script>
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'
import Spinner from '../../common/spinner'
import tableHandler from '../../../mixins/tableHandler'
import { usersMixin } from '../../../mixins/users'
import RequestingUserEmail from './requestingUserEmail'
import RequestingUserApprove from './requestingUserApprove'
import RequestingUserReject from './requestingUserReject'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
export default {
  metaInfo: {
    title: 'Support - Requesting Users'
  },
  name: 'RequestingUsers',
  components: {
    Spinner,
    SectionCollapsedHeader,
    RequestingUserEmail,
    RequestingUserApprove,
    RequestingUserReject,
    TriStateToggleSwitch
  },
  mixins: [
    tableHandler,
    usersMixin
  ],
  data () {
    return {
      users: [],
      requestingUsers: [],
      saveInProgress: false,
      loadingRequestingUser: false,
      formOK: false,
      selectedRequestingUser: '',
      selectedRequestingUserRecord: {}
    }
  },
  computed: {
    viewEntityPermission () {
      return this.$store.getters.viewEntityPermission
    },
    sectionClass () {
      if (this.selectedRequestingUser.length > 0) {
        return 'halfSection'
      } else {
        return 'fullSection'
      }
    }
  },
  async mounted () {
    await this.getAllRequestingUsers()
    this.setUpTable(this.requestingUsers)
  },
  methods: {
    getAllRequestingUsers: async function () {
      this.users = await this.getRequestAccessUsers()
      this.requestingUsers = this.users.filter(user => user.approved === false)
    },
    closeRequestingUserForm () {
      this.$emit('setTileMode')
    },
    selectRequestingUser (selectedRecord) {
      this.selectedRequestingUserRecord = selectedRecord
      if (this.selectedRequestingUser === selectedRecord.emailAddress) {
        this.selectedRequestingUser = ''
      } else {
        this.selectedRequestingUser = selectedRecord.emailAddress
      }
    },
    setUpTable (data) {
      this.initialiseTable(
        'companiesTable',
        data,
        ['emailAddress', 'firstName', 'lastName', 'companyName', 'requestDate'],
        ['Email Address', 'First  Name', 'Last Name', 'Company Name', 'Request Date'],
        ['', '', '', '', ''],
        ['30%', '20%', '20%', '20%', '10%'],
        null,
        24,
        true
      )
    },
    getScreenDate (dte) {
      var rtn = ''
      if (dte) {
        var date = new Date(dte)
        var day = this.checkForZero(date.getDate())
        var year = this.checkForZero(date.getFullYear())
        var month = this.checkForZero(date.getMonth() + 1)
        var hours = this.checkForZero(date.getHours())
        var minutes = this.checkForZero(date.getMinutes())
        rtn = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes
      }
      return rtn
    },
    checkForZero (int) {
      if (int.toString().length === 1) {
        int = '0' + int
      }
      return int
    },
    updateTable: async function () {
      await this.getAllRequestingUsers()
      this.requestingUsers = this.users.filter(user => user.approved === false)
      this.setUpTable(this.requestingUsers)
      this.selectedRequestingUser = ''
    }
  }
}
</script>
<style scoped>
  .updateSectionData {
    display: inline-block;
    width: 100%;
    padding-left: 20px;
  }
  .cancelRequestAccessButton {
    background-image: url('../../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
    float: right;
    margin-bottom: 40px;
  }
  .halfSection {
    display: inline-block;
    width:calc(100% - 420px);
    padding-left:20px;
  }
  .fullSection {
    display: inline-block;
    width:100%;
    padding-left:20px;
  }
</style>
