<template>
  <div style="padding-top:30px;">
    <div
      v-if="!createCustomerPermission"
      class="noResults"
    >
      Sorry, you do not have permission to add top level sites
    </div>
    <div
      v-else
      id="createCustomer"
      class="createSectionData"
    >
      <div
        id="CreateCustomer"
        class="createCustomerHolder"
      >
        <div
          :class="{parentCreateCustomerForm: showCreateCustomerSection}"
        >
          <h3>
            Add Top Level Site
            <span class="statuscontainer">
              <span
                :class="[sectionComplete ? 'completeSection' : 'incompleteSection']"
                :title="[sectionComplete ? 'Section Complete' : 'Section Incomplete']"
              />
              <span class="background" />
            </span>
          </h3>
          <div
            style="display:flex;"
          >
            <transition name="slide">
              <div
                v-if="showCreateCustomerSection"
                class="createCustomerLeft"
              >
                <label
                  for="txtNewCompany"
                  class="createCustomerLabel"
                > New Company? </label>
                <div
                  id="txtNewCompany"
                  class="createCustomerTextbox newCompany"
                >
                  <TriStateToggleSwitch
                    id="newcompany-toggle"
                    ref="newcompany-toggle"
                    :enabled="createCompanyPermission"
                    :current-state="newCompany ? 1 : 2"
                    :tri-state="false"
                    class="toggleSwitch"
                    tabindex="1"
                    @statechanged="toggleNewCompany"
                  />
                </div>
                <div
                  v-if="!newCompany"
                >
                  <label
                    for="txtExistingAdmin"
                    class="createCustomerLabel"
                  > Existing Admin? </label>
                  <div
                    id="txtExistingAdmin"
                    class="createCustomerTextbox existingAdmin"
                  >
                    <TriStateToggleSwitch
                      id="existingAdmin-toggle"
                      ref="existingAdmin-toggle"
                      :enabled="createCompanyPermission"
                      :current-state="existingAdmin ? 1 : 2"
                      :tri-state="false"
                      class="toggleSwitch"
                      tabindex="1"
                      @statechanged="toggleExistingAdmin"
                    />
                  </div>
                </div>
                <label
                  for="txtUpdateCustomerEnabled"
                  class="createCustomerLabel"
                > Enabled:</label>
                <div
                  id="txtUpdateCustomerEnabled"
                  class="createCustomerTextbox enabled"
                >
                  <TriStateToggleSwitch
                    id="enabled-toggle"
                    ref="enabled-toggle"
                    :enabled="true"
                    :current-state="customer.enabled ? 1 : 2"
                    :tri-state="false"
                    tabindex="7"
                    class="toggleSwitch"
                    @statechanged="selectCustomerEnabled"
                  />
                </div>
                <label
                  for="txtUpdateTopLevelAutotaskSite"
                  class="createCustomerLabelLong"
                  style="margin-top: 4px;"
                > Autotask Top Level Account No:</label>
                <input
                  id="txtUpdateTopLevelAutotaskSite"
                  :class="{ errorHighlight: topLevelAutotaskSiteError }"
                  v-model="customer.topLevelAutotaskSite"
                  type="number"
                  class="createCustomerNumber outline topLevelAutotaskSite"
                  tabindex="6"
                  @blur="validateTopLevelSite"
                >
              </div>
            </transition>
            <transition name="slide">
              <div
                v-if="showCreateCustomerSection"
                class="createCustomerRight"
              >
                <label
                  for="txtUpdateCompany"
                  class="createCustomerLabel"
                > {{ companyNameLabel }}</label>
                <PortalDropdown
                  v-show="!newCompany"
                  id="txtUpdateCompany"
                  :options="companies"
                  :disabled="false"
                  :max-item="999999"
                  :class="{ errorHighlight: companySelectError }"
                  :attach-blur-event="false"
                  parent-class="createCustomerRight"
                  placeholder="Please select a Company to assign the Top Level Site to"
                  tabindex="2"
                  @selected="selectCustomerCompany"
                  @blur="checkForm"
                />
                <input
                  v-show="newCompany"
                  id="txtUpdateCompany"
                  :class="{ errorHighlight: companyError }"
                  v-model="customer.companyName"
                  type="text"
                  class="createCustomerTextbox company"
                  tabindex="2"
                  @blur="checkForm"
                >
                <div
                  v-if="existingAdmin"
                >
                  <label
                    for="cboExistingAdmin"
                    class="createCustomerLabel existingAdmin"
                  > Admin Account: </label>
                  <PortalDropdown
                    id="cboExistingAdmin"
                    :options="userList"
                    :disabled="false"
                    :max-item="999999"
                    :class="{ errorHighlight: adminSelectError }"
                    :placeholder="adminPlaceholder"
                    :attach-blur-event="false"
                    parent-class="createCustomerRight"
                    tabindex="2"
                    @selected="selectAdminAccount"
                  />
                </div>
                <div
                  v-if="!existingAdmin"
                >
                  <label
                    for="txtUpdateadminAccountEmail"
                    class="createCustomerLabel"
                  >Admin Email:</label>
                  <input
                    id="txtUpdateadminAccountEmail"
                    :class="{ errorHighlight: adminAccountEmailError || userNameExists }"
                    v-model.trim="customer.adminAccountEmail"
                    type="text"
                    class="createCustomerTextbox adminAccountEmail"
                    tabindex="4"
                    @blur="checkForm"
                  >
                </div>
                <label
                  for="txtUpdateTraverseParentContainerId"
                  class="createCustomerLabelLong"
                  style="margin-top: 4px;"
                > Traverse Parent Container ID:</label>
                <input
                  id="txtUpdateTraverseParentContainerId"
                  v-model="customer.traverseParentContainerId"
                  type="number"
                  class="createCustomerNumber outline traverseParentContainerId"
                  tabindex="7"
                >
              </div>
            </transition>
          </div>
          <div
            v-if="showCreateCustomerSection"
            class="parentSiteContainer"
          >
            <label
              v-if="selectedTopLevelAutotaskSite"
              for="txtInfoTopLevelAutotaskSite"
              class="parentSiteLabel"
            > Resolved Account:</label>
            <p
              v-if="selectedTopLevelAutotaskSite"
              id="txtInfoTopLevelAutotaskSite"
              class="createCustomerTextbox"
            >
              {{ selectedTopLevelAutotaskSite }}
            </p>
          </div>
          <div
            class="permissionsTableContainer"
          >
            <transition name="slide">
              <table
                v-if="AdminToggled"
              >
                <thead>
                  <tr>
                    <th style="width:85%">Permission</th>
                    <th style="width:15%">Allowed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="({permissionId, permissionName, action, currentState}) in bindingPermissions"
                    :key="permissionId"
                  >
                    <td>{{ permissionName }} </td>
                    <td style="padding: 6px 5px 0 0;">
                      <TriStateToggleSwitch
                        v-if="!saveInProgress"
                        :enabled="true"
                        :current-state="currentState"
                        :permission-id="permissionId"
                        :ref="action"
                        @statechanged="setDefinedPermission"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </transition>
          </div>
          <div
            class="sitesTableContainer"
          >
            <table
              v-if="showCreateCustomerSection"
              id="unassignedSitesTable"
              style="margin-bottom: 15px;"
            >
              <thead>
                <tr>
                  <th style="width:10%;">Site ID</th>
                  <th style="width:35%">Name</th>
                  <th style="width:55%">Address</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loadingSites">
                  <td colspan="3">
                    <Spinner />
                  </td>
                </tr>
                <tr v-if="currentFilter.length === 0 && !loadingSites">
                  <td
                    v-if="!loadingSites"
                    colspan="3"
                  >There are no sites in the database that are not associated with a company</td>
                </tr>
                <tr
                  v-for="s in currentFilter"
                  v-else
                  :key="s.accountId"
                  @click="selectCompanyFromGrid(s.accountId)"
                >
                  <td>{{ s.accountId }}</td>
                  <td
                    :title="s.accountName"
                  >{{ s.accountName }}</td>
                  <td
                    :title="s.address1 + ' ' + s.address2 + ' ' + s.city + ' ' + s.state + ' ' + s.zip"
                  >{{ s.address1 + ' ' + s.address2 + ' ' + s.city + ' ' + s.state + ' ' + s.zip }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bottombar" >
          <Spinner v-if="saveInProgress" />
          <input
            v-if="!saveInProgress"
            id="createCustomerButton"
            :disabled="!formOK && !sectionComplete"
            :class="getButtonClass"
            type="button"
            title="Add the selected top level site"
            style="float:right;"
            tabindex="8"
            @click="createSelectedCustomer()"
          >
          <input
            v-if="!isSaving"
            id="cancelCustomerButton"
            class="cancelCustomerButton"
            type="button"
            title="Cancel the create customer process"
            @click="closeCreateCustomerForm()"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { permissionsMixin } from '../../mixins/permissions'
import { adminMixin } from '../../mixins/adminMixin'
import { usersMixin } from '../../mixins/users'
import tableHandler from '../../mixins/tableHandler'
import { DataEventBus } from '../../events/dataEvents'
import Spinner from '../common/spinner'
import PortalDropdown from '../common/portalDropdown'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'
import { autotaskServiceMixin } from '../../mixins/autotaskServiceMixin'

export default {
  metaInfo: {
    title: 'Support - Add Top Level Site'
  },
  components: {
    Spinner,
    PortalDropdown,
    TriStateToggleSwitch
  },
  mixins: [
    permissionsMixin,
    autotaskServiceMixin,
    adminMixin,
    usersMixin,
    tableHandler
  ],
  data () {
    return {
      customer: {
        name: null,
        topLevelAutotaskSite: null,
        adminAccountId: 0,
        adminAccountEmail: this.supportEmailAddress,
        enabled: false,
        companyName: null,
        companyId: 0,
        traverseParentContainerId: '',
        originatingCustomerId: this.$store.getters.currentCustomer.id
      },
      formOK: false,
      nameError: false,
      apostropheError: false,
      topLevelAutotaskSiteError: false,
      adminAccountEmailError: false,
      companyError: false,
      companySelectError: false,
      adminSelectError: false,
      userNameExists: false,
      showCreateCustomerSection: true,
      definedPermissions: [],
      newCompany: false,
      existingAdmin: false,
      selectedTopLevelAutotaskSite: '',
      bindingPermissions: [],
      saveInProgress: false,
      userList: [],
      isLoading: true,
      isSaving: false,
      AdminToggled: true,
      noSites: true,
      unassignedSites: [],
      loadingSites: false
    }
  },
  computed: {
    supportEmailAddress () {
      return 'portalquery@maintel.co.uk'
    },
    viewCustomerPermission () {
      return this.$store.getters.viewCustomerPermission
    },
    createCustomerPermission () {
      return this.$store.getters.createCustomerPermission
    },
    createCompanyPermission () {
      return this.$store.getters.createCompanyPermission
    },
    getButtonClass () {
      if (this.formOK && this.sectionComplete) {
        return 'createCustomerButtonEnabled'
      } else {
        return 'createCustomerButton'
      }
    },
    sectionComplete () {
      var companyPass = false
      var adminPass = false
      if (this.newCompany && this.customer.companyName) { companyPass = true }
      if (!this.newCompany && this.customer.companyId > 0) { companyPass = true }
      if (this.existingAdmin && this.customer.adminAccountId > 0) { adminPass = true }
      if (!this.existingAdmin && !this.userNameExists && this.customer.adminAccountEmail) { adminPass = true }
      return this.customer.name &&
        this.customer.topLevelAutotaskSite &&
        !this.customer.salesforceAccountId &&
        companyPass &&
        adminPass
    },
    permissions () {
      return this.$store.getters.permissions
    },
    companies () {
      return this.$store.getters.companies
    },
    companyNameLabel () {
      return this.newCompany ? 'Company Name:' : 'Existing Company:'
    },
    adminPlaceholder () {
      return this.customer.companyId > 0 ? 'Please select an admin account for the top level site' : 'Please select an existing company first'
    }
  },
  mounted () {
    this.setUnassignedTable([])
    this.initialisePermissions()
    this.setUnassignedSites()
    this.customer.adminAccountEmail = this.supportEmailAddress
  },
  methods: {
    setUnassignedTable (data) {
      this.initialiseTable(
        'unassignedSitesTable',
        data,
        ['accountId', 'accountName', 'address'],
        ['Site ID', 'Name', 'Address'],
        ['', '', ''],
        '68px',
        ['10%', '35%', '55%'],
        6
      )
    },
    createSelectedCustomer: async function () {
      this.saveInProgress = true
      await this.checkForm({ target: { } })

      if (this.formOK) {
        var customerProvisioningObject = {
          ...this.customer,
          permissions: this.definedPermissions
        }
        customerProvisioningObject.topLevelAutotaskSite = Number(customerProvisioningObject.topLevelAutotaskSite)
        customerProvisioningObject.adminAccountId = Number(customerProvisioningObject.adminAccountId)
        // console.log(JSON.stringify(customerProvisioningObject))
        var rtn = await this.onboardCustomer(customerProvisioningObject)
        if (rtn.length === 0) {
          this.$store.commit('showMessage', {
            content: `Top Level Site '${this.customer.name}' has been added successfully!`,
            color: 'blue',
            timeout: 3000
          })
          await this.getCustomers()
          await this.getCompanies()
          this.setUnassignedSites()
          this.ResetCustomerForm()
        } else {
          this.$store.commit('showMessage', {
            content: `Adding the top level site '${this.customer.name}' failed with '${rtn}'`,
            color: 'red',
            timeout: 3000
          })
        }
      }
      this.saveInProgress = false
      DataEventBus.$emit('load-data')
    },
    async checkForm ({ target: { className: checkField, id: checkId } }) {
      var errorMessage = ''
      if (checkField) {
        if (checkField.indexOf('name') > -1) {
          this.nameError = !this.customer.name || this.customer.name.length > 100
        } else if (checkField.indexOf('name') > -1) {
          this.apostropheError = !this.customer.name.indexOf('\'') > -1
        } else if (checkField.indexOf('topLevelAutotaskSite') > -1) {
          this.topLevelAutotaskSiteError = !this.customer.topLevelAutotaskSite
        } else if (checkField.indexOf('adminAccountEmail') > -1 && !this.existingAdmin) {
          this.adminAccountEmailError = !this.customer.adminAccountEmail || !this.validateEmail(this.customer.adminAccountEmail) || !this.validateFirstChar(this.customer.adminAccountEmail)
          this.userNameExists = false
          if (!this.adminAccountEmailError) {
            this.userNameExists = await this.validateUsername(this.customer.adminAccountEmail)
          }
        } else if (checkField.indexOf('company') > -1) {
          if (this.newCompany) {
            this.companyError = !this.customer.companyName
            this.apostropheError = !this.customer.companyName.indexOf('\'') > -1
          } else {
            this.companySelectError = !this.customer.companyId
          }
        } else if (checkField.indexOf('existingAdmin') > -1 && this.existingAdmin) {
          this.adminSelectError = this.customer.adminAccountId === undefined
        }
      } else {
        this.nameError = !this.customer.name
        this.topLevelAutotaskSiteError = !this.customer.topLevelAutotaskSite
        this.salesforceAccountIdError = !this.customer.salesforceAccountId
        this.adminAccountEmailError = !this.customer.adminAccountEmail || !this.validateEmail(this.customer.adminAccountEmail) || !this.validateFirstChar(this.customer.adminAccountEmail)
        this.userNameExists = false
        if (!this.adminAccountEmailError && !this.existingAdmin) {
          this.userNameExists = await this.validateUsername(this.customer.adminAccountEmail)
        }
        if (this.newCompany) {
          this.companyError = !this.customer.companyName
        } else {
          this.companySelectError = !this.customer.companyId
        }
        if (this.existingAdmin) {
          this.adminSelectError = this.customer.adminAccountId < 1
        }
      }
      var error = this.topLevelAutotaskSiteError || this.adminAccountEmailError || this.companyError || this.companySelectError || this.adminSelectError || this.userNameExists
      if (error) {
        errorMessage = ('Please fix the issues with the following field(s): ' +
            (this.apostropheError ? 'There is an apostrophe in the company or site name which is not allowed. ' : '') +
            (this.topLevelAutotaskSiteError ? 'Autotask Top Level Account No, ' : '') +
            (this.adminSelectError ? 'Admin Account, ' : '') +
            (this.companySelectError ? 'Existing Company, ' : '') +
            (this.companyError ? 'Company Name, ' : '') +
            (this.adminAccountEmailError ? 'Provide a valid Admin Email account.' : this.userNameExists ? 'The email address ' + this.customer.adminAccountEmail + ' is already in use.' : '')
        ).replace(/,\s*$/, '')

        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 3000 })
      }
      this.formOK = !error
    },
    closeCreateCustomerForm () {
      this.ResetCustomerForm()
      this.$emit('setTileMode')
    },
    ResetCustomerForm () {
      this.$nextTick(() => {
        this.nameError = false
        this.apostropheError = false
        this.topLevelAutotaskSiteError = false
        this.adminAccountEmailError = false
        this.companyError = false
        this.companySelectError = false
        this.userNameExists = false
        this.adminSelectError = false
        this.formOK = false
        this.newCompany = false
        this.customer = {
          name: null,
          topLevelAutotaskSite: null,
          traverseParentContainerId: '',
          adminAccountId: 0,
          adminAccountEmail: this.supportEmailAddress,
          enabled: false,
          companyId: 0,
          companyName: null
        }
        this.selectedTopLevelAutotaskSite = ''
        this.saveInProgress = false
      })
    },
    selectCustomerAutotaskSite ({ id }) {
      if (id) {
        this.customer.topLevelAutotaskSite = id
        this.checkForm({ target: { className: 'topLevelAutotaskSite' } })
      }
    },
    selectCustomerCompany (option) {
      if (option) {
        this.customer.companyId = option.id
        this.customer.companyName = option.name
        if (option.id) {
          this.setCompanyUsers(option.id)
        }
        if (this.isLoading) {
          this.isLoading = false
        } else {
          this.checkForm({ target: { className: 'company' } })
        }
      }
    },
    selectAdminAccount (option) {
      if (option) {
        this.customer.adminAccountId = option.id
        this.customer.adminAccountEmail = option.emailAddress
        if (this.AdminToggled) {
          this.AdminToggled = false
        } else {
          this.checkForm({ target: { className: 'existingAdmin' } })
        }
      }
    },
    selectCustomerEnabled ({ selectedState }) {
      this.customer.enabled = selectedState === 1
    },
    toggleNewCompany ({ selectedState }) {
      this.newCompany = selectedState === 1
      this.existingAdmin = false
      this.customer.companyId = 0
      this.companyError = false
      this.companySelectError = false
      this.isLoading = true
    },
    toggleExistingAdmin ({ selectedState }) {
      this.existingAdmin = selectedState === 1
      this.customer.adminAccountId = 0
      this.customer.adminAccountEmail = ''
      this.adminAccountEmailError = false
      this.adminSelectError = false
      this.AdminToggled = true
    },
    async setCompanyUsers (companyId) {
      this.userList = []
      if (companyId !== undefined) {
        var userStringList = ''
        this.getUsersByCompany(companyId).then((users) => {
          if (users.length > 0) {
            users.forEach(user => { userStringList += '|' + user.id })
            userStringList = userStringList.substring(1)
            this.getUserList(userStringList).then((result) => {
              var changedUsers = []
              result.forEach(usr => {
                var changedUser = {}
                changedUser.id = usr.id
                changedUser.name = usr.userName
                changedUser.emailAddress = usr.emailAddress
                changedUsers.push(changedUser)
              })
              this.userList = changedUsers
            })
          }
        })
      }
    },
    validateFirstChar (email) {
      var re = /^[A-Za-z0-9_-]*$/
      return re.test(email[0])
    },
    validateEmail (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    async validateUsername (value) {
      const username = value.toLowerCase()
      var rtn = false
      if (username !== null && username !== this.supportEmailAddress) {
        await this.getUserListByUsername(username)
          .then(result => {
            rtn = !result || result.some(user => user.emailAddress.toLowerCase() === username)
          })
          .catch((_) => {
            console.log('Username not found')
          })
      }
      return rtn
    },
    setDefinedPermission ({ permissionId, selectedState }) {
      if (!this.definedPermissions.some(o => o.permissionId === permissionId) && selectedState === 1) {
        this.definedPermissions.push({
          permissionId,
          enabled: true
        })
      } else {
        // remove it from the list..
        this.definedPermissions = this.definedPermissions.filter(o => o.permissionId !== permissionId)
      }
    },
    initialisePermissions () {
      this.bindingPermissions = this.permissions
      for (let i = 0; i < this.bindingPermissions.length; i++) {
        if (this.bindingPermissions[i].action === 'viewCompany' ||
          this.permissions[i].action === 'assignToCustomer' ||
          this.permissions[i].action === 'createEntity' ||
          this.permissions[i].action === 'deleteEntity' ||
          this.permissions[i].action === 'updateEntity' ||
          this.permissions[i].action === 'viewEntity' ||
          this.permissions[i].action === 'createRole' ||
          this.permissions[i].action === 'deleteRole' ||
          this.permissions[i].action === 'updateRole' ||
          this.permissions[i].action === 'viewRole' ||
          this.permissions[i].action === 'assignEntityRole' ||
          this.permissions[i].action === 'removeEntityRole' ||
          this.permissions[i].action === 'viewEntityRole' ||
          this.permissions[i].action === 'updateArea' ||
          this.permissions[i].action === 'viewArea' ||
          this.permissions[i].action === 'assignEntityArea' ||
          this.permissions[i].action === 'removeEntityArea' ||
          this.permissions[i].action === 'viewEntityArea' ||
          this.permissions[i].action === 'assignRoleArea' ||
          this.permissions[i].action === 'removeRoleArea' ||
          this.permissions[i].action === 'viewRoleArea' ||
          this.permissions[i].action === 'viewPermission' ||
          this.permissions[i].action === 'viewAssignedPermission' ||
          this.permissions[i].action === 'updateAllowPermission' ||
          this.permissions[i].action === 'updateGrantPermission' ||
          this.permissions[i].action === 'viewQuotesAndProjects' ||
          this.permissions[i].action === 'viewAreaTickets' ||
          this.permissions[i].action === 'viewCustomer' ||
          this.permissions[i].action === 'viewCommunicateService' ||
          this.permissions[i].action === 'viewSecureService' ||
          this.permissions[i].action === 'viewConnectService' ||
          this.permissions[i].action === 'viewNowService'
        ) {
          this.bindingPermissions[i].currentState = 1
          this.setDefinedPermission({ 'permissionId': this.bindingPermissions[i].permissionId, 'selectedState': 1 })
        } else {
          this.bindingPermissions[i].currentState = 2
        }
      }
    },
    async setUnassignedSites () {
      this.loadingSites = true
      var autotaskSites = await this.getUnassignedTopLevelSites()
      var permissionsAreas = await this.getAllTopLevelAreas()
      if (autotaskSites) {
        var filtered = autotaskSites.filter(site => !permissionsAreas.some(rm => rm.id === site.accountId))
        this.unassignedSites = filtered.sort((a, b) => a.accountName.localeCompare(b.accountName))
        this.setUnassignedTable(this.unassignedSites)
      }
      this.loadingSites = false
    },
    async validateTopLevelSite () {
      this.selectedTopLevelAutotaskSite = ''
      if (this.customer.topLevelAutotaskSite > 0) {
        var site = await this.sitesGetById(this.customer.topLevelAutotaskSite)
        if (site.length > 0) {
          this.selectedTopLevelAutotaskSite = site[0].accountName
          this.customer.name = site[0].accountName
        }
      }
      this.checkForm({ target: { className: 'topLevelAutotaskSite' } })
    },
    selectCompanyFromGrid (id) {
      this.customer.topLevelAutotaskSite = id
      this.validateTopLevelSite()
    }
  }
}
</script>
<style>
  .dropdown-input {
    border: 0 !important;
  }
</style>
<style scoped>
  .parentCreateCustomerForm{
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom: 2vh;
    border-radius: 5px;
  }
  .parentSiteLabel {
    display: inline-block;
    font-weight: bold;
    line-height: 2em;
  }
  .createCustomerLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .createCustomerLabelLong {
    width: 68%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .createCustomerTextbox {
    width: 68%;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }
  .createCustomerNumber {
    width: 30%;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }
  .outline {
    border: solid 1px #9aa8b1;
  }

  .createCustomerHolder {
    background-color: rgba(255,255,255,0.7);
    padding:10px;
  }
  @media only screen and (min-width: 1024px) {
    .createCustomerHolder {
      background-color: rgba(255,255,255,0.7);
      padding:10px;
      margin-right:5px;
    }
  }
  .errorHighlight {
    border: 1px solid red!important;
  }
  .parentSiteContainer {
    margin-left:5px;
  }
  .createCustomerButton {
    background-image: url('../../assets/icons/check-tick-disabled.svg');
    cursor: default;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .createCustomerButtonEnabled {
    background-image: url('../../assets/icons/check-tick.svg');
    cursor: pointer;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .cancelCustomerButton {
    background-image: url('../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
    margin-top: -4px;
  }
  .createCustomerLeft {
    width: 50%;
    display: inline-block;
    padding: 5px 5px 0 5px;
  }
  .createCustomerRight {
    width: 50%;
    display: inline-block;
    padding: 5px 5px 0 5px;
  }
  .slide-enter-active, .slide-leave-active {
    transition: max-height .2s ease-out;
  }

  .slide-enter, .slide-leave-to {
    max-height: 0px;
  }

  .slide-enter-to, .slide-leave {
    max-height: 200px;
  }

  h3 {
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0;
    color: #fff;
    border-radius: 5px 5px 0 0;
  }

  input {
    border-radius: 5px;
  }

  .completeSection {
    background-image: url('../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .incompleteSection {
    background-image: url('../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  span.statuscontainer {
    float: left;
    margin-right: 5px;
  }

  span.statuscontainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }

  .minimiseSection {
    background-image: url('../../assets/icons/up_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .maximiseSection {
    background-image: url('../../assets/icons/down_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .dropdown {
    border: solid 1px #9aa8b1;
    border-radius: 5px;
    width: 68%;
    margin-top: 3px;
  }

  .bottombar {
    text-align: right;
  }

  .toggleSwitch {
    vertical-align: middle;
  }

  .permissionsTableContainer {
    max-height: calc(100vh - 76vh);
    overflow-y: auto;
    margin-top: 10px;
  }

  .sitesTableContainer {
    max-height: calc(100vh - 60vh);
    overflow-y: auto;
    margin-top: 20px;
    padding-right: 10px;
  }

</style>
