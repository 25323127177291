<template>
  <div
    :id="id"
    class="areaHolder"
  >
    <Spinner v-if="loading" />
    <div
      v-else
    >
      <label
        for="txtUpdateAreaName"
        class="areaDetailLabel"
      >Site Name:</label>
      <input
        v-if="updateAreaPermission"
        id="txtUpdateAreaName"
        :class="{ errorHighlight: areaNameError }"
        v-model="area.name"
        class="areaDetailInput"
        type="text"
        @blur="checkForm"
      >
      <label
        v-if="!updateAreaPermission"
        id="lblUpdateAreaName"
        class="areaDetailData"
      >{{ area.name }}</label>
      <Spinner v-if="isSaving" />
      <div
        v-if="updateAreaPermission && !isSaving"
        class="updateButtonArea"
      >
        <input
          id="btnUpdateArea"
          :disabled="!formOK"
          type="button"
          class="areaUpdateButton"
          value="Update Site"
          title="Updates the name and description of the site"
          @click="updateSite()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions } from 'vuex'
import Spinner from '../../common/spinner'
export default {
  name: 'AreaDetails',
  components: {
    Spinner
  },
  mixins: [
    permissionsMixin
  ],
  props: {
    areaid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      area: {},
      updateAreaPermission: false,
      loading: false,
      formOK: false,
      areaNameError: false,
      isSaving: false
    }
  },
  computed: {
    areas () {
      return this.$store.getters.sites
    }
  },
  watch: {
    areaid () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setReloadAreas'
    ]),
    setComponent () {
      this.loading = true
      if (this.areaid > -1) {
        this.area = this.areas.find(area => area.id === this.areaid)
        this.checkForm()
        this.getUserPermission(this.userid, this.areaid, 'updateArea').then((result) => {
          this.updateAreaPermission = result
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    updateSite: async function () {
      this.isSaving = true
      if (this.formOK) {
        this.updateArea(this.area).then(() => {
          this.setReloadAreas(true)
          this.isSaving = false
          this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
        })
      } else {
        this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
        this.isSaving = false
      }
    },
    checkForm: function () {
      this.areaNameError = this.area.name.length === 0
      var error = this.area.id < 0 || this.areaNameError

      if (error) {
        var errorMessage = 'Please fix the issues with the following field: ' +
        (this.areaNameError ? 'site name' : '')
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !error
    }
  }
}
</script>
<style scoped>
  .areaHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }
  .areaDetailData {
    width: 260px;
    display: inline-block;
  }
  .areaDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .areaDetailInput {
    width: 254px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .areaUpdateButton {
    float:right;
    cursor: pointer;
  }
  .areaUpdateButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
</style>
