<template>
  <div class="uploadFileContainer">
    <label
      for="inputFile"
      class="uploadFileLabel"
    >{{ `${label}: (maximum size for each file is 7Mb)` }}</label>
    <br>
    <div class="dropFileBox" >
      <input
        id="inputFile"
        :accept="type"
        type="file"
        @change="(event)=> { onChange(event) }"
      >
      <p>{{ dragDropTitle }}</p>
    </div>
  </div>
</template>

<script>
import Spinner from '../common/spinner'

export default {
  name: 'FormButton',
  components: {
    Spinner
  },
  props: {
    saving: {
      type: Boolean,
      default: false
    },
    dragDropTitle: {
      type: String,
      default: 'Drag a file here or click.'
    },
    label: {
      type: String,
      default: 'Upload File'
    },
    type: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange (event) {
      this.$emit('upload', event)
      event.target.value = ''
    }
  }
}
</script>

<style scoped>
  .uploadFileContainer {
    display: inline-block;
    width: calc(100% - 10px);
  }
  .uploadFileLabel {
    width: 100%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .dropFileBox {
    /* display: flex; */
    position: relative;
    width: 68%;
    min-width: 270px;
    height: 280px;
    text-align: center;
    outline-offset: -5px;
    outline: 1px dashed #0f0f0f;
    background-color: rgba(233, 230, 230, 0.979);
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }
  .dropFileBox p {
    position: absolute;
    width: 100%;
    /*padding-top: 10px;*/
    text-align: center;
    font-weight: 800;
    color: #7a7979c7;
    font-family: Arial;
    z-index: 0;
    top: 45%;
  }
  .dropFileBox label {
    width: 100px;
  }
  .dropFileBox input {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    z-index: 3;
  }
</style>
