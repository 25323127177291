<template>
  <div
    :id="id"
    class="contextHolder"
  >
    <Spinner v-if="loading" />
    <div
      v-else
    >
      <div class="controlContainer">
        <label
          for="txtUpdateTraverseContainerId"
          class="contextDetailLabel"
        >Company Slug:</label>
        <input
          v-if="editCompanyPermission"
          id="txtUpdateBaseUrl"
          :class="{ errorHighlight: slugError }"
          v-model="company.companySlug"
          class="contextDetailInput"
          type="text"
          @blur="checkForm"
        >
      </div>
      <Spinner v-if="isSaving" />
      <div
        v-if="editCompanyPermission && !isSaving"
        class="updateButtonCompany"
      >
        <input
          id="btnUpdateCompany"
          :disabled="!formOK"
          type="button"
          class="contextUpdateCompanyButton"
          value="Update Company Slug"
          title="Updates the company slug"
          @click="updateCompany()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { contextMixin } from '../../../mixins/contextMixin'
import Spinner from '../../common/spinner'
export default {
  name: 'ContextManagement',
  components: {
    Spinner
  },
  mixins: [
    contextMixin
  ],
  props: {
    id: {
      type: String,
      default: ''
    },
    company: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      formOK: false,
      slugError: false,
      isSaving: false,
      contextAdmin: false
    }
  },
  computed: {
    editCompanyPermission () {
      return this.$store.getters.editCompanyPermission
    },
    companies () {
      return this.$store.getters.companies
    }
  },
  watch: {
    company () {
      this.setComponent()
    }
  },
  mounted () {
    console.log(this.company)
    this.setComponent()
  },
  methods: {
    setComponent: async function () {
      this.loading = true
      if (this.company.companyId > -1) {
        await this.getSlugByCompany(this.company.companyId)
        this.checkForm()
      }
      this.loading = false
    },
    updateCompany: async function () {
      if (!this.isSaving) {
        this.isSaving = true
        if (this.formOK) {
          var rtn = await this.updateCompanySlug(this.company.companyId, this.company.companySlug)
          if (rtn) {
            this.$emit('reload-companies', 0)
            this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
          } else {
            this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
          }
        }
        this.isSaving = false
      }
    },
    isNumeric (str) {
      if (typeof str !== 'string') return false
      return !isNaN(str) && !isNaN(parseFloat(str))
    },
    checkForm: function () {
      this.slugError = false
      if (this.company.companySlug.length === 0) {
        this.slugError = this.company.companySlug.length === 0
        var errorMessage = 'Please fix the issues with the following field: ' +
        (this.company.companySlug.length === 0 ? 'Company Slug' : '')
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !this.slugError
    }
  }
}
</script>
<style scoped>
  .contextHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }
  .contextDetailData {
    width: 250px;
    display: inline-block;
  }
  .controlContainer {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 10px 0;
  }
  .contextDetailLabel {
    width: 130px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .contextDetailInput {
    width: 344px;
    display: inline-block;
    padding: 0 0 0 5px;
    margin: 0 0 16px 0;
    vertical-align: bottom;
  }
  .subscriptionTierDropDown {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 0 0 0 5px;
    width: 344px;
    -moz-appearance: auto;
    -webkit-appearance: auto;
    appearance: auto;
  }
  .contextUpdateCompanyButton {
    float:right;
    cursor: pointer;
  }
  .contextUpdateCompanyButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
</style>
