<template>
  <div
    :id="id"
    class="statusHistoryHolder"
  >
    <div
      v-if="visibleTickets.length === 0"
      class="noStatusHistoryHolder"
    >{{ defaultGridText }}</div>
    <table v-if="visibleTickets.length > 0">
      <thead>
        <tr>
          <th>Title</th>
          <th>Priority</th>
          <th>Date Added</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="v in visibleTickets"
          :key="v.id"
          :class="rowColour(v)"
          @click="openTicketDetails(v)"
        >
          <td :title="v.Title">{{ v.Title }}</td>
          <td :title="v.Priority">{{ v.Priority }}</td>
          <td :title="v.Date_Added">{{ v.Date_Added }}</td>
          <td :title="v.Status">{{ v.Status }}</td>
        </tr>
      </tbody>
    </table>
    <dialogBox
      :dialog="dialog"
      :title="ticket.Title"
      @close="setDialog"
    >
      <ticketDetails :ticket="getTicket"/>
    </dialogBox>
  </div>
</template>
<script>
import PortalGrid from '../common/portalGrid'
import dialogBox from '../common/dialogBox'
import ticketDetails from '../tickets/ticketDetails'
export default {
  components: {
    PortalGrid,
    ticketDetails,
    dialogBox
  },
  props: {
    ticketColumns: {
      type: Array,
      default: function () { return [] }
    },
    visibleTickets: {
      type: Array,
      default: function () { return [] }
    },
    id: {
      type: String,
      default: ''
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      dialog: false,
      ticket: {}
    }
  },
  computed: {
    getTicket () {
      if (this.ticket.id) {
        const ticket = this.$store.getters.tickets.filter(ticket => ticket.id === this.ticket.id)[0]
        return ticket
      } else {
        return {}
      }
    },
    defaultGridText () {
      return `No Open tickets`
    }
  },
  methods: {
    openTicketDetails (ticket) {
      this.ticket = ticket
      this.setDialog(true)
    },
    rowColour (row) {
      var rtn = ''
      if (!this.useAltColour) {
        rtn = 'Solid'
      }
      if (row.Priority.indexOf('Critical') > -1) {
        rtn += 'Purple'
      } else if (row.Priority.indexOf('High') > -1) {
        rtn += 'Red'
      } else if (row.Priority.indexOf('Medium') > -1) {
        rtn += 'Amber'
      } else if (row.Priority.indexOf('Low') > -1) {
        rtn += 'Green'
      } else if (row.Priority.indexOf('Change') > -1) {
        rtn += 'Brown'
      } else if (row.Priority === 'P5 - Service Request') {
        rtn += 'Blue'
      } else {
        rtn += 'Grey'
      }
      return rtn
    },
    setDialog: function (value) {
      this.dialog = value
    }
  }
}
</script>
<style scoped>
  .openIcon {
    width: 20px;
    height: 20px;
  }
  .statusHistoryHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    overflow: auto;
    max-height: 350px;
    display: none;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .noStatusHistoryHolder {
    text-align: center;
    font-weight: bold;
  }
</style>
