import permissionsAPI from '../communications/interceptors/apiInterceptor'
import { mapActions } from 'vuex'
export const permissionsMixin = {
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'setAreas',
      'setUsers',
      'setRoles',
      'setViewSecurityPermission',
      'setRoleMembers',
      'setPermissions',
      'setAllPermissions',
      'setCustomers',
      'setTopLevelArea',
      'setCustomerFlags',
      'setCompanies',
      'setCompanyEmailSuffixes',
      'setChannelUsers',
      'setEntityStyles'
    ]),
    setPagePermissions (pageName, userid) {
      var area = 1
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
      permissionsAPI.get('permissionsarray/effectivepermissions/bag/' + pageName + '/' + area + '/' + userid, axiosConfig).then((response) => {
        if (response.data) {
          let perms = response.data
          for (let i = 0; i < perms.length; i++) {
            const perm = perms[i]
            if (perm.allowed !== 2) {
              document.getElementById(perm.action).style.display = 'none'
            }
          }
        }
      })
    },
    getChannelFilteredEntities: function (data) {
      // Check to see if we are a channel company member
      if (data.length > 0) {
        var isChannel = false
        // Matching the email suffix (below) does not work becasue of the technology centre support email addresses used for admin accounts
        // isChannel = this.$store.getters.userEmail.toLowerCase().endsWith(this.$store.getters.channelEmailSuffix)

        // Below was the alternative way of doing this, seeing if you have membership of the channel company company.
        this.$store.getters.companies.forEach(company => {
          if (company.id === this.$store.getters.channelCompany.companyId) {
            isChannel = true
          }
        })
        if (!isChannel) {
          var res = data.filter(user => !this.$store.getters.channelUsers.includes(user.id))
          return res
        } else {
          return data
        }
      } else {
        return data
      }
    },
    setChannelEntities: async function () {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/entities/channel/' + this.$store.getters.channelCompany.companyId, '', axiosConfig).then((response) => {
          var data = response.data.map(a => a.id)
          this.setChannelUsers(data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    getChannelFilteredRoles: function (data) {
      // Check to see if we are a channel company member
      if (data.length > 0) {
        var isChannel = false
        this.$store.getters.companies.forEach(company => {
          if (company.id === this.$store.getters.channelCompany.companyId) {
            isChannel = true
          }
        })
        if (!isChannel) {
          var res = data.filter(role => !this.$store.getters.channelRoles.includes(role.id))
          return res
        } else {
          return data
        }
      } else {
        return data
      }
    },
    setEntityPermissions: async function (userid) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.post('permissions/effectivepermissions/' + userid, '', axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    getUserByUserName: async function (username) {
      var data = []
      if (username.length > 0) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid
          }
        }
        try {
          // Get the users that the logged in user is entitled to see
          await permissionsAPI.get('permissions/entities', axiosConfig).then((response) => {
            data = this.getChannelFilteredEntities(response.data)
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return data
    },
    usersGet: async function () {
      var rtn = false
      var data = {}
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        permissionsAPI.get('permissionsarray/entities', axiosConfig).then((response) => {
          data = this.getChannelFilteredEntities(response.data)
          this.setUsers(data)
          rtn = true
        })
      } catch (_) {
        // eslint-disable-next-line
        console.log('Error calling usersGet')
      }
      return rtn
    },
    getUsers: async function (userid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        // eslint-disable-next-line
        // console.log('getUsers: ' + userid);
        // Get the users that the logged in user is entitled to see
        await permissionsAPI.get('permissionsarray/entities', axiosConfig).then((response) => {
          data = this.getChannelFilteredEntities(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getUsersByCustomer: async function (customerid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': customerid
        }
      }
      try {
        // eslint-disable-next-line
        // console.log('getUsers: ' + userid);
        // Get the users that the logged in user is entitled to see
        await permissionsAPI.get('permissionsarray/entities', axiosConfig).then((response) => {
          data = this.getChannelFilteredEntities(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getUsersByCompany: async function (companyid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        // eslint-disable-next-line
        // console.log('getUsers: ' + userid);
        // Get the users that the logged in user is entitled to see
        await permissionsAPI.get('permissionsarray/entities/company/' + companyid, axiosConfig).then((response) => {
          data = this.getChannelFilteredEntities(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getUserPermissions: async function (userid, areaid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/assignedpermissions/entity/' + userid + '/' + areaid, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getUserPermission: async function (userid, areaid, action) {
      var data = []
      var rtn = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/assignedpermissions/entity/' + userid + '/' + areaid, axiosConfig).then((response) => {
          data = response.data
          if (data.length > 0) {
            rtn = data.some(perm => perm.action === action && perm.allowed === 1)
          } else {
            rtn = data
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getUserViewPermissions: async function (userid) {
      // console.log('getUserViewPermissions - currentCustomer.id: ' + this.$store.getters.currentCustomer.id)
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/syslogin/view/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getUserTopLevelPermissions: async function (userid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        permissionsAPI.get('permissionsarray/syslogin/toplevelpermissions/', axiosConfig).then((response) => {
          this.setPermissions(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    updatePermission: async function (permission) {
      var result = false
      // entity object; type = 1 for user.
      var pmn = { Id: permission.id, Name: permission.name, Description: permission.description }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.put('permissionsarray/permissions/' + permission.id, pmn, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    addPermissionRole: async function (roleid, permissionid, areaid) {
      var result = false
      var pmr = { roleid: parseInt(roleid), areaid: areaid, permissionid: permissionid, allowed: 1, grant: 0 }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.post('permissions/assignedpermissions/role', pmr, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    deletePermissionRole: async function (roleid, permissionid) {
      var result = false
      // entity object; type = 1 for user.
      var pmr = { RoleId: roleid, PermissionId: permissionid }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.delete('permissions/assignedpermissions/', pmr, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    getUserAreas: async function (userid) {
      var data = []
      if (this.$store.getters.currentCustomer.id === undefined) {
        return data
      } else {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        try {
          await permissionsAPI.get('permissionsarray/areas', axiosConfig).then((response) => {
            data = response.data
            if (userid === this.$store.getters.userid) {
              this.setAreas(data)
            } else {
              this.setAreas([])
            }
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return data
    },
    getUserSecurityPermission: async function (userid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/syslogin/security/', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getAreaMembers: async function (areaid, userid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/entities/area/' + areaid, axiosConfig).then((response) => {
          data = this.getChannelFilteredEntities(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getArea: async function (areaid) {
      var data = []
      if (areaid > -1) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid
          }
        }
        try {
          await permissionsAPI.get('permissions/areas/' + areaid, axiosConfig).then((response) => {
            data = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return data
    },
    updateArea: async function (area) {
      var result = false
      // entity object; type = 1 for user.
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.put('permissions/areas/' + area.id, area, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    getUserRoles: async function (userid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        // Get the roles that user is entitled to see
        await permissionsAPI.get('permissionsarray/roles', axiosConfig).then((response) => {
          data = this.getChannelFilteredRoles(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getRoleAreas: async function () {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/roleareas', axiosConfig).then((response) => {
          data = this.getChannelFilteredRoles(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getRoleAreasByArea: async function (areaid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/roleareas/byarea/' + areaid, axiosConfig).then((response) => {
          data = this.getChannelFilteredRoles(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getRoleAreasByAreaRecusrive: async function (areaid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/roleareas/recursive/' + areaid, axiosConfig).then((response) => {
          data = this.getChannelFilteredRoles(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getRoleAreasById: async function (roleid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/areas/role/' + roleid, axiosConfig).then((response) => {
          data = this.getChannelFilteredRoles(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getAreaRoles: async function (areaid) {
      var data = []
      if (areaid > -1) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        try {
          await permissionsAPI.get('permissionsarray/roles/area/' + areaid, axiosConfig).then((response) => {
            data = this.getChannelFilteredRoles(response.data)
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return data
    },
    rolesGet: async function () {
      var data = []
      if (this.$store.getters.userid) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        try {
          await permissionsAPI.get('permissionsarray/roles/', axiosConfig).then((response) => {
            data = response.data
            if (data.length > 0) {
              data = this.getChannelFilteredRoles(response.data)
              this.setRoles(data)
            } else {
              this.setRoles([])
            }
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
    },
    getRoleMembers: async function (roleid) {
      if (roleid > -1) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        try {
          permissionsAPI.get('permissionsarray/roleentities/' + roleid, axiosConfig).then((response) => {
            this.setRoleMembers(response.data)
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
    },
    getRole: async function (roleid) {
      var data = []
      if (roleid > -1) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid
          }
        }
        try {
          await permissionsAPI.get('permissions/roles/' + roleid, axiosConfig).then((response) => {
            data = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return data
    },
    addRole: async function (role) {
      var data = { RoleId: 0 }
      var rle = { Name: role.Name, Description: role.Description }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.post('permissions/roles/', rle, axiosConfig).then((reponse) => {
          var rtn = reponse.data
          data = rtn
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    updateRole: async function (role) {
      var result = false
      // entity object; type = 1 for user.
      var rle = { Id: role.id, Name: role.name, Description: role.description }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.put('permissions/roles/' + role.id, rle, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    getRolePermissions: async function (roleid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/assignedpermissions/role/' + roleid + '/0', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getRoleListPermissions: async function (roleList) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      if (roleList.roleList.length > 0) {
        try {
          await permissionsAPI.post('permissionsarray/assignedpermissions/roles', roleList, axiosConfig).then((response) => {
            data = response.data
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return data
    },
    getRolePermission: async function (roleid, action) {
      var data = []
      var rtn = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/assignedpermissions/role/' + roleid + '/0', axiosConfig).then((response) => {
          data = response.data
          rtn = data.some(perm => perm.action === action && perm.allowed === 1)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getAllPermissions: async function () {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/permissions', axiosConfig).then((response) => {
          data = response.data
          this.setAllPermissions(data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getPermission: async function (permissionid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissions/permissions/' + permissionid, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getPermissionRoleMembers: async function (permissionid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/roles/permission/' + permissionid, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getPermissionEntityMembers: async function (permissionid) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/entities/permission/' + permissionid + '/0', axiosConfig).then((response) => {
          data = this.getChannelFilteredEntities(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getPermissionRoleDetails: async function (permissionid, areaId) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/assignedpermissions/permission/' + permissionid + '/' + areaId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    addEntity: async function (userid) {
      var result = false
      // entity object; type = 1 for user.
      var usr = { Id: userid, Type: 1, Reference: '' }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.post('permissions/entities', usr, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    addEntityArea: async function (userid, areaid) {
      var result = false
      var usr = { entityid: userid, areaid: areaid }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.post('permissions/areaentities', usr, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    addRoleArea: async function (roleid, areaid) {
      var result = false
      var data = { RoleId: roleid, AreaId: areaid }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.post('permissions/roleareas', data, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    addEntityPermission: async function (userid, areaid, permissionid) {
      var result = false
      var usr = { entityid: userid, areaid: areaid, permissionid: permissionid, allowed: 0, grant: 0 }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.post('permissions/assignedpermissions/entity', usr, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    deleteEntityArea: async function (userid, areaid) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.delete('permissions/areaentities/' + areaid + '/' + userid, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    deleteRoleArea: async function (roleid, areaid) {
      var result = false
      if (roleid > -1) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid
          }
        }
        try {
          await permissionsAPI.delete('permissions/roleareas/' + roleid + '/' + areaid, axiosConfig).then(() => {
            result = true
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return result
    },
    addEntityRole: async function (userid, roleid) {
      var result = false
      // entity object; type = 1 for user.
      var usr = { entityid: userid, roleid: roleid }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.post('permissions/roleentities', usr, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    deleteEntityRole: async function (userid, roleid) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.delete('permissions/roleentities/' + roleid + '/' + userid, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    deleteEntityRoleForCustomer: async function (userid, customerid) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': customerid
        }
      }
      try {
        await permissionsAPI.delete('permissions/roleentities/customer/' + customerid + '/' + userid, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    deleteEntityPermissionForArea: async function (userid, permissionid, areaid) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.delete('permissions/assignedpermissions/entity/' + permissionid + '/' + userid + '/' + areaid, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    deleteRolePermission: async function (roleid, permissionid, areaid) {
      var result = false
      if (roleid > -1) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        try {
          await permissionsAPI.delete('permissions/assignedpermissions/role/' + permissionid + '/' + roleid + '/' + areaid, axiosConfig).then(() => {
            result = true
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return result
    },
    updateAssignedPermissionByEntity: async function (permission, property, value) {
      var result = false

      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.put(`permissions/assignedpermissions/${permission.permissionId}/${permission.areaId}/${permission.entityId}/${property}/${value}`, {}, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
      return result
    },
    updateAssignedPermissionByRole: async function (permission, property, value) {
      var result = false

      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.put(`permissions/assignedpermissions/${permission.permissionId}/${permission.roleId}/${property}/${value}`, {}, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
      return result
    },
    checkSecurity: async function (uid) {
      this.setViewSecurityPermission(false)
      var rtn = await this.getUserSecurityPermission(uid)
      if (rtn.length > 0) {
        this.setViewSecurityPermission(true)
      }
    },
    createUser: async function ({
      id,
      type,
      reference
    }) {
      var rtn = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var user = {
        id,
        type,
        reference
      }
      try {
        await permissionsAPI.post('permissions/entities/', user, axiosConfig).then((response) => {
          rtn = response.data.length > 0
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }

      return rtn
    },
    deleteEntity: async function ({
      id
    }) {
      var rtn = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.delete('permissions/entities/' + id, axiosConfig).then((response) => {
          rtn = response.data.length > 0
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }

      return rtn
    },
    getTopLevelArea: async function () {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/syslogin/toplevelarea', axiosConfig).then((response) => {
          if (response.data.length > 0) {
            this.setTopLevelArea(response.data)
          } else {
            this.setTopLevelArea([{ areaId: this.$store.getters.currentCustomer.topLevelAutotaskSite }])
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    getAllTopLevelAreas: async function () {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/areas/alltoplevel/0', axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error in getAllTopLevelAreas with message: ' + error)
      }

      return rtn
    },
    getAllCompanyEmailSuffixes: async function () {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/companyemailsuffix', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getCompanyEmailSuffixes: async function () {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      var companyId = this.$store.getters.currentCustomer.companyId
      try {
        await permissionsAPI.get('permissionsarray/companyemailsuffix/company/' + companyId, axiosConfig).then((response) => {
          if (response.data.length > 0) {
            this.setCompanyEmailSuffixes(response.data)
          } else {
            this.setCompanyEmailSuffixes([])
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    getCompanyByEmailSuffix: async function (suffix) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
      var rtn = ''
      try {
        await permissionsAPI.get('permissionsarray/companyemailsuffix/emailsuffix/' + suffix, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    deleteEmailSuffix: async function (companyEmailSuffixId) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.delete('permissions/companyemailsuffix/' + companyEmailSuffixId, axiosConfig)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    addEmailSuffix: async function (companyId, emailSuffix) {
      var rtn = true
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var payload = { id: 0, companyId: companyId, emailSuffix: emailSuffix }
      try {
        await permissionsAPI.post('permissions/companyemailsuffix', payload, axiosConfig)
      } catch (error) {
        rtn = false
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    updateEmailSuffix: async function (id, companyId, emailSuffix) {
      var rtn = true
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var payload = { id: id, companyId: companyId, emailSuffix: emailSuffix }
      try {
        await permissionsAPI.put('permissions/companyemailsuffix', payload, axiosConfig)
      } catch (error) {
        rtn = false
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getCustomers: async function () {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/customers', axiosConfig).then((response) => {
          this.setCustomers(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    updateCustomer: async function (customer) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.put('permissions/customers', customer, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    getUserCustomers: async function (userId) {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get(`permissionsarray/customers/${userId}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    createUserCustomer: async function ({ entityId, customerId, isDefault }) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var customerEntity = {
        entityId,
        customerId,
        isDefault
      }
      try {
        await permissionsAPI.post(`permissions/customerentities`, customerEntity, axiosConfig)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    deleteUserCustomer: async function ({ entityId, customerId }) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.delete(`permissions/customerentities/${customerId}/${entityId}`, axiosConfig)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    getCustomerEntities: async function ({ customerId }) {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get(`permissionsarray/customerentities/${customerId}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getCustomerFlags: async function () {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get(`permissionsarray/customers/customerflag`, axiosConfig).then((response) => {
          this.setCustomerFlags(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    getRolesByCustomer: async function (customerId) {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': customerId
        }
      }
      try {
        await permissionsAPI.get(`permissionsarray/roles/customer/` + customerId, axiosConfig).then((response) => {
          rtn = this.getChannelFilteredRoles(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getRolesByCompany: async function (companyId) {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get(`permissionsarray/roles/company/` + companyId, axiosConfig).then((response) => {
          rtn = this.getChannelFilteredRoles(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getRolesByCustomerAndEntity: async function (customerId, userId) {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userId,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get(`permissionsarray/roles/customer/` + customerId, axiosConfig).then((response) => {
          rtn = this.getChannelFilteredRoles(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getAreaChildAreas: async function (areaId) {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/areas/children/' + areaId, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getTargetUserAreas: async function (userid, customerId) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': customerId
        }
      }

      try {
        await permissionsAPI.get('permissionsarray/areas/users/' + userid, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }

      return data
    },
    getRoleMembersForEntity: async function (userId, customerId) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': customerId
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/roleentities/entity/' + userId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    createCustomer: async function (customer) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var customerObject = {
        name: customer.name,
        topLevelAutotaskSite: customer.topLevelAutotaskSite,
        salesforceAccountId: customer.salesforceAccountId,
        primaryAdminAccount: customer.primaryAdminAccountEmail,
        enabled: customer.enabled,
        deleted: false,
        provisioningDate: null
      }
      try {
        await permissionsAPI.post('permissions/customers', customerObject, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    getTopLevelAreas: async function () {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/areas/toplevel/0', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getCompanies: async function () {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/companies', axiosConfig).then((response) => {
          this.setCompanies(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    getCompanyCustomers: async function (companyId) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/customers/company/' + companyId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    getCompanyById: async function (companyId) {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': 0
        }
      }
      try {
        await permissionsAPI.get('permissions/companies/id/' + companyId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return data
    },
    createEntityFull: async function (entityId, reference, areas, roles, customers) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var entityFull = {
        Id: entityId,
        Reference: reference,
        Areas: areas,
        Roles: roles,
        Customers: customers
      }
      try {
        await permissionsAPI.post('permissions/entities/createfull', entityFull, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    updateCompany: async function (company) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.put('permissions/companies', company, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    sitesGet (uid) {
      if (uid !== undefined) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        return permissionsAPI.get('/Site/' + uid, axiosConfig)
          .then(res => {
            this.setSites(res.data)
            return res.data
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log("error getting sites", err)
            return []
          })
      } else {
        return []
      }
    },
    mergeCompany: async function (mainCompanyId, mergeCompanyId, payload) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var rtn = ''
      try {
        await permissionsAPI.post('permissions/companies/merge/' + mainCompanyId + '/' + mergeCompanyId, payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
        rtn = error
      }
      return rtn
    },
    resolveEntityStyles: async function (entityId) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await permissionsAPI.get('permissionsarray/entitystyles/' + entityId + '/-', axiosConfig).then((response) => {
          this.setEntityStyles(response.data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    getEntityStyles: async function (entityId, styleName) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var rtn = ''
      try {
        await permissionsAPI.get('permissionsarray/entitystyles/' + entityId + '/' + styleName, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
        rtn = error
      }
      return rtn
    },
    upsertEntityStyle: async function (entityId, styleName, styleValue) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var rtn = true
      try {
        await permissionsAPI.post('permissions/entitystyles/' + styleName + '/' + styleValue + '/' + entityId, axiosConfig)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
        rtn = false
      }
      return rtn
    }
  }
}
