var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customerEntitiesHolder"},[(_vm.loading)?_c('Spinner'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"entityCustomersContainer"},[_c('table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"80%"}},[_vm._v("User")]),_vm._v(" "),(_vm.assignToCustomerPermission)?_c('th',{staticStyle:{"width":"20%"}},[_vm._v("\n            Enabled\n          ")]):_vm._e()])]),_vm._v(" "),_c('tbody',_vm._l((_vm.users),function(u){return _c('tr',{key:u.id,class:_vm.getRowColour()},[_c('td',[_vm._v(" "+_vm._s(u.firstName + ' ' + u.lastName)+" ")]),_vm._v(" "),(_vm.assignToCustomerPermission)?_c('td',[_c('TriStateToggleSwitch',{ref:("customer-toggle-" + (u.id)),refInFor:true,staticClass:"tableToggleSwitch",attrs:{"enabled":true,"current-state":_vm.customerEntityAssignments.some(function (ce) { return ce.entityId === u.id && ce.customerId === _vm.customerid; }) ? 1 : 2,"tri-state":false},on:{"statechanged":function (ref) {
                var selectedState = ref.selectedState;

                _vm.updateCustomerState({
                  entityId: u.id,
                  customerId: _vm.customerid,
                  isDefault: false,
                  selectedState: selectedState})
              }}})],1):_vm._e()])}),0)]),_vm._v(" "),_c('UserCustomerNewRole',{attrs:{"roles":_vm.customerRoles,"userid":_vm.selectedUserId},on:{"RolesAdded":_vm.addUserToCustomer,"RoleCancelled":_vm.undoCustomerAdd}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }