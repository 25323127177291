<template>
  <div class="projectsDetailsContainer">
    <span class="containerHeader">
      <label> {{ selectedProject.name || 'N/A' }} </label>
    </span>

    <!-- <div
      v-if="projectStatus !== 'N/A'"
      class="progressbar"
    >
      <div
        :class="projectStatusPercent"
        class="progression"
      />
    </div>
    <div
      v-if="projectStatus !== 'N/A'"
      class="statusContainer"
    >
      <div
        :class="sectionWidth"
        class="status"
      >
        Start
      </div>
      <div
        v-if="projectInitiationVisible"
        :class="sectionWidth"
        class="status"
      >
        Project Initiation
      </div>
      <div
        v-if="readyForServiceVisible"
        :class="sectionWidth"
        class="status"
      >
        Ready for Service
      </div>
      <div
        :class="sectionWidth"
        class="status"
        style="border-right: 1px solid rgb(154, 168, 177);"
      >
        End
      </div>
    </div> -->

    <span class="formDetails">
      <label> Project Number: </label> <p> {{ selectedProject.projectNumber || 'N/A' }} </p>
    </span>
    <span class="formDetails">
      <label> Quote Number: </label> <p> {{ selectedProject.salesforceNumber || 'N/A' }} </p>
    </span>
    <span class="formDetails">
      <label> Purchase Order Number: </label> <p> {{ selectedProject.purchaseOrderNumber || 'N/A' }} </p>
    </span>
    <span class="formDetails">
      <label> Project Lead: </label> <p> {{ formatManagerDisplayName(selectedProject.projectManagerName || 'N/A') }} </p>
    </span>
    <span class="formDetails">
      <label> RAG: </label>
      <p
        :class="ragClass"
        class="ragStatus"
      >
        {{ selectedProject.ragStatus || 'N/A' }}
      </p>
    </span>
    <span
      v-if="selectedProject.startDate"
      class="formDetails"
    >
      <label> Start Date: </label> <p> {{
        selectedProject.startDate ? new Date(selectedProject.startDate) > new Date() ? `${new Date(selectedProject.startDate).toDateString()} (Forecast)` : new Date(selectedProject.startDate).toDateString() : 'N/A'
      }} </p>
    </span>
    <span
      v-if="selectedProject.closedDate"
      class="formDetails"
    >
      <label> Closed Date: </label> <p> {{
        selectedProject.closedDate ? new Date(selectedProject.closedDate) > new Date() ? `${new Date(selectedProject.closedDate).toDateString()} (Forecast)` : new Date(selectedProject.closedDate).toDateString() : 'N/A'
      }} </p>
    </span>
    <TextButton
      v-if="hasTestPermissions && companySlugAvailable"
      text="View Status"
      title="View project in context"
      style="float:right;margin-right:10px;"
      @click="setDialog(true)"
    />
    <DialogBox
      :dialog="dialog"
      :title="selectedProject.name"
      @close="setDialog"
    >
      <iframe
        v-if="iFrameContentUrl"
        id="contextIframe"
        :src = "iFrameContentUrl"
        class="context-iframe"
      />
    </DialogBox>
  </div>
</template>

<script>

import { contextMixin } from '../../mixins/contextMixin'
import tableHandler from '../../mixins/tableHandler'
import DialogBox from '../common/dialogBox'
import Spinner from '../common/spinner'
import TextButton from '../common/textButton'
import iframeResizer from 'iframe-resizer'
export default {
  name: 'ProjectDetails',
  components: {
    Spinner,
    TextButton,
    DialogBox
  },
  mixins: [
    tableHandler,
    contextMixin
  ],
  props: {
    selectedProject: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      companySlug: {},
      iFrameUrl: '',
      dialog: false,
      projectURL: process.env.VUE_APP_CONTEXT_URL
    }
  },
  computed: {
    currentCustomer () {
      // Used to refresh the company slug
      return this.$store.getters.currentCustomer
    },
    projectStatus () {
      var status = this.getCurrentStatus()
      switch (status) {
        case 'end':
          return 'Project Closed'
        case 'readyForService':
          return 'Ready for Service'
        case 'initiated':
          return 'Initiation Complete'
        case 'started':
          return 'Project Started'
        default:
          return 'N/A'
      }
    },
    projectStatusPercent () {
      var status = this.getCurrentStatus()
      switch (status) {
        case 'end':
          return 'w100'
        case 'readyForService':
          switch (this.sectionWidth) {
            case 'w33':
              return 'w66'
            case 'w25':
              return 'w75'
            default:
              return 'w75'
          }
        case 'initiated':
          switch (this.sectionWidth) {
            case 'w33':
              return 'w66'
            case 'w25':
              return 'w50'
            default:
              return 'w50'
          }
        case 'started':
          // this will always need to be the width of a single section
          return this.sectionWidth
        default:
          return 'w0'
      }
    },
    ragClass () {
      switch (this.selectedProject.ragStatus ? this.selectedProject.ragStatus.toLowerCase() : '') {
        case 'red':
          return 'ragRed'
        case 'amber':
          return 'ragAmber'
        case 'green':
          return 'ragGreen'
        default:
          return 'ragGrey'
      }
    },
    sectionWidth () {
      if (this.readyForServiceVisible && this.projectInitiationVisible) {
        return 'w25'
      } else if (this.readyForServiceVisible || this.projectInitiationVisible) {
        return 'w33'
      } else {
        return 'w50'
      }
    },
    readyForServiceVisible () {
      return this.selectedProject.readyForServiceDate && new Date(this.selectedProject.readyForServiceDate) <= new Date()
    },
    projectInitiationVisible () {
      return this.selectedProject.initiationDate && new Date(this.selectedProject.initiationDate) <= new Date()
    },
    projectLink () {
      // Resolve the company slug from the Context database, based on the presently selected customer
      var returnUrl = ''
      if (this.companySlug.length > 0) {
        if (this.companySlug[0].companySlug !== undefined) {
          if (this.selectedProject !== undefined && this.selectedProject.id !== undefined) {
            returnUrl = this.projectURL + this.selectedProject.id + ':' + this.companySlug[0].companySlug
          }
        }
      }
      return returnUrl
    },
    iFrameContentUrl () {
      return this.iFrameUrl
    },
    hasTestPermissions () {
      return this.$store.getters.maintelTesting
    },
    companySlugAvailable () {
      // To stop the View Details button from displaying if we have no slug for the present company
      return this.companySlug.length > 0 && this.companySlug[0].companySlug !== undefined && this.companySlug[0].companySlug.length > 0
    }
  },
  watch: {
    currentCustomer () {
      // To make sure that the View Details button is shown/hidden if we switch customers while on the Projects page
      this.getCompanySlug()
    },
    companySlug () {
      // Reload the IFrame to display the context data when the company slug changes / is loaded
      this.iFrameUrl = ''
      this.loadIFrameUrl()
    },
    selectedProject () {
      this.iFrameUrl = ''
      this.loadIFrameUrl()
    }
  },
  mounted () {
    // Get the slug for the first company on initial load - this doesn't trigger the watcher
    this.getCompanySlug()
  },
  created () {
  },
  methods: {
    getCompanySlug: async function () {
      // Get the company slug based on the presently selected company ID
      if (this.currentCustomer !== undefined) {
        var companyId = this.currentCustomer.companyId
        if (companyId !== undefined) {
          this.companySlug = await this.getSlugByCompany(companyId)
        }
      }
    },
    loadIFrameUrl: async function () {
      var returnUrl = ''
      if (this.companySlug.length > 0) {
        if (this.companySlug[0].companySlug !== undefined) {
          if (this.selectedProject !== undefined && this.selectedProject.id !== undefined) {
            returnUrl = this.projectURL + this.selectedProject.id + ':' + this.companySlug[0].companySlug
            try {
              const response = await fetch(returnUrl, {
                credentials: 'include',
                method: 'GET',
                headers: {
                  // Here you can set any headers you want
                  'ContextHash': '37ce3994-6684-4fc8-b93e-794d2b04b38e',
                  'Access-Control-Request-Headers': 'contexthash',
                  'Accept': 'application/json'
                }
              })
              if (!response.ok) {
                throw new Error('Network response was not ok')
              }
              const data = await response
              console.log(data)
              this.iFrameUrl = returnUrl
            } catch (error) {
              console.error('Error fetching the iframe URL:', error)
            }
          }
        }
      }
    },
    getCurrentStatus: function () {
      if (this.selectedProject.completeDate && new Date(this.selectedProject.completeDate) <= new Date()) {
        return 'end'
      } else if (this.selectedProject.readyForServiceDate && new Date(this.selectedProject.readyForServiceDate) <= new Date()) {
        return 'readyForService'
      } else if (this.selectedProject.initiationDate && new Date(this.selectedProject.initiationDate) <= new Date()) {
        return 'initiated'
      } else if (this.selectedProject.startDate && new Date(this.selectedProject.startDate) <= new Date()) {
        return 'started'
      } else {
        return 'none'
      }
    },
    formatManagerDisplayName: function (managerName) {
      if (managerName) {
        if ((managerName.indexOf(',')) > -1) {
          var parts = managerName.split(',')
          return `${parts[1]} ${parts[0]}`
        } else {
          return managerName
        }
      } else {
        return ''
      }
    },
    setDialog: function (value) {
      this.dialog = value
    },
    resize () {
      iframeResizer({ checkOrigin: false }, '#contextIframe')
    }
  }
}
</script>

<style scoped>
  .context-iframe {
    height: 85vh;
  }
  .projectsDetailsContainer {
    max-height: 400px;
    overflow-y: auto;
    padding:5px;
  }

  .projectsDetailsContainer label {
    font-weight: 700;
  }

  .ragStatus {
    border-radius: 20px;
    padding: 2px 10px !important;
    color: #000;
    font-weight: bold;
  }

  .projectsDetailsContainer p {
    display:inline-block;
    padding-left: 5px;
  }

  .projectsDetailsContainer span {
    width: 100%;
    display:block;
  }

  .ragRed {
    background-color: #ee9491;
  }

  .ragAmber {
    background-color: #ffc861;
  }

  .ragGreen{
    background-color: #9ff08e;
  }

  .ragGrey {
    background-color: #c7dae7;
  }

  .containerHeader {
    background: #274a5a;
    padding: 6px 10px;
    margin: 0 0 5px 0;
    color: #fff;
    border-radius: 5px;
  }

  .formDetails {
    padding: 0 0 0 15px;
    margin: 0 0 5px 0;
  }

  .formDetails p {
    margin: 0;
  }

  .progressbar {
    height: 12px;
    color: #fff;
    border-bottom: 1px solid rgb(154, 168, 177);
    margin-top:10px;
  }

  .progression {
    background-color: rgb(99, 180, 82);
    height:11px;
    border-radius: 5px;
    padding: 0 5px;
    width: 0px;
  }

  .w0{
    display:none;
  }
  .w25 {
    width: calc(25% - 3px);
  }
  .w33 {
    width: calc(33.3% - 3px);
  }
  .w50 {
    width: calc(50% - 3px);
  }
  .w66 {
    width: calc(66.6% - 3px);
  }
  .w75 {
    width: calc(75% - 3px);
  }
  .w100 {
    width: calc(100% - 3px);
  }
  .statusContainer {
    margin-bottom:10px;
    color:black;
    height: 15px;
  }
  .status {
    display: inline-block;
    border-left: 1px solid rgb(154, 168, 177);
    height: 15px;
    position: relative;
    top: -4px;
    text-align: center;
    vertical-align:middle;
    font-size: 10px;
  }
  .placeholder {
    width: 25%;
    display:inline-block;
    height: 15px;
    position: relative;
  }
  .context-iframe {
    width: 1px;
    min-width: 100%;
  }
</style>
