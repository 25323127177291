export const state = () => ({
  content: '',
  color: '',
  timeout: 3000
})

export const mutations = {
  showMessage (state, payload) {
    state.content = payload.content
    state.color = payload.color
    state.timeout = payload.timeout
  }
}

// This export is used to allow the vuex components to extend ito the global state
export default {
  state,
  mutations
}
