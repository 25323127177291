import portalAPIGateway from '../../communications/interceptors/apiInterceptor'
import { mapActions } from 'vuex'

export const iQuoteSalesforceAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'setOpportunities'
    ]),
    iQuoteSalesforceAPIMixin_getAxiosConfig () {
      return {
        timeout: 60000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userId,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
    },
    iQuoteSalesforceAPIMixin_getSalesforceOpportunityData: async function (salesForceQuoteNumber) {
      var data = null
      var axiosConfig = this.iQuoteSalesforceAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotesalesforce/opportunities/' + salesForceQuoteNumber, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getOpportunityQuoteData failed!')
      }
      return data
    },
    iQuoteSalesforceAPIMixin_getSalesforceCustomerOpportunityData: async function (callback) {
      this.setOpportunities([])
      var salesForceAccountIds = this.$store.getters.customers.filter(customer => customer.companyId === this.$store.getters.currentCompany.id).map(({ salesforceAccountId }) => salesforceAccountId).join(',')
      if (salesForceAccountIds.length > 0) {
        var axiosConfig = this.iQuoteSalesforceAPIMixin_getAxiosConfig()
        try {
          await portalAPIGateway.post(`iquotesalesforce/opportunities/customer`, `{'siteList':'${salesForceAccountIds}'}`, axiosConfig).then((response) => {
            var opportunityCutOffDate = new Date()
            opportunityCutOffDate.setMonth(opportunityCutOffDate.getMonth() - 1)

            var data = response.data
              ? response.data
                // Only return opportunities that are currently open or have been closed in the past month
                .filter(o => !o.closeDate || new Date(o.closeDate) >= opportunityCutOffDate)
              : []

            this.setOpportunities(data)
            if (callback) {
              callback()
            }
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log('iQuoteSalesforceAPIMixin_getSalesforceCustomerOpportunityData failed!')
        }
      }
    },
    iQuoteSalesforceAPIMixin_setiQuoteLink: async function (salesForceQuoteNumber, iQuoteLinkUrl) {
      var data = []
      var axiosConfig = this.iQuoteSalesforceAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.post('iquotesalesforce/opportunities/' + salesForceQuoteNumber + '/SetiQuoteLink', { url: iQuoteLinkUrl }, axiosConfig).then((response) => {
          data = response.data
        })

        if (data.result.toLowerCase() !== 'ok') {
          console.log('iQuoteSalesforceAPIMixin_setiQuoteLink failed!  Error:' + data.detail)
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteSalesforceAPIMixin_setiQuoteLink failed!  Error:' + {error}.error.response.data)
      }
      return data
    },
    iQuoteSalesforceAPIMixin_setSalesForceOpportunityValues: async function (salesForceQuoteNumber, nameValuePairs) {
      var data = []
      var axiosConfig = this.iQuoteSalesforceAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.put('iquotesalesforce/opportunities/' + salesForceQuoteNumber + '/SetSalesForceOpportunityValues', nameValuePairs, axiosConfig).then((response) => {
          data = response.data
        })

        if (data.result.toLowerCase() !== 'ok') {
          console.log('iQuoteSalesforceAPIMixin_setSalesForceOpportunityValues failed!  Error:' + data.detail)
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteSalesforceAPIMixin_setSalesForceOpportunityValues failed!  Error:' + {error}.error.response.data)
      }
      return data
    }
  }
}
