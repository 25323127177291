<template>
  <div class="widget-wrapper">
    <apexchart
      :options="getChartOptions(widgetData)"
      :series="getChartSeries(widgetData)"
      :type="getChartType(widgetData)"
      width="100%"
    />
    <div class="widget-footer">
      <div class="widget-footer-timestamp">
        <span
          :title="widgetData.timestamp === undefined ? 'Not Set' : 'Data last updated at ' + timestamp(widgetData.timestamp)"
          class="timestamp"
        >{{ widgetData.timestamp === undefined ? 'Not Set' : timestamp(widgetData.timestamp) }}</span>
      </div>
    </div>
    <TimerProgressComponent
      id="controlRefreshBar"
      :polling-interval="widgetData.pollingIntervalInSeconds"
      :widget-id="widgetData.id"
      @reload-widget="setWidgetData"
    />
  </div>
</template>
<script>
import TimerProgressComponent from './timerProgressComponent'
import { stormMixin } from '../../mixins/stormMixin'
export default {
  name: 'ChartWidgetComponent',
  components: {
    TimerProgressComponent
  },
  mixins: [
    stormMixin
  ],
  props: {
    widget: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      timerObj: {},
      widgetData: {},
      tickCount: 1,
      barProgress: 0
    }
  },
  computed: {
    pollingInterval () {
      return this.widget.pollingIntervalInSeconds
    },
    progressTitle () {
      if ((this.pollingInterval - Math.round(this.tickCount)) > 60) {
        return Math.round((this.pollingInterval - Math.round(this.tickCount)) / 60) + ' minutes until next data refresh'
      } else {
        return (this.pollingInterval - Math.round(this.tickCount)) + ' seconds until next data refresh'
      }
    }
  },
  mounted () {
    this.widgetData = this.widget
  },
  methods: {
    getChartOptions (widget) {
      var obj = {}
      if (widget.data) {
        if (widget.displayType === 12) {
          // Average and Longest Imcoming Call Response Time - Daily 4 Weeks
          obj = {
            chart: {
              title: widget.name,
              id: widget.id,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
              showForSingleSeries: true
            },
            stroke: {
              curve: 'straight'
            },
            xaxis: {
              type: 'datetime',
              categories: widget.data.xAxis.labels
            },
            yaxis: {
              decimalsInFloat: 1,
              title: {
                text: widget.data.yAxis.title === null ? '' : widget.data.yAxis.title
              }
            },
            annotations: widget.id !== 4 ? undefined : { yaxis: [{ y: 0, y2: 20, borderColor: '#000', fillColor: '#FFF', opacity: 0.2 }, { y: 20, y2: 40, borderColor: '#000', fillColor: '#FEB019', opacity: 0.2 }, { y: 40, y2: 120, borderColor: '#000', fillColor: '#FE0019', opacity: 0.2 }] },
            title: {
              text: widget.name,
              floating: true,
              align: 'left',
              style: {
                color: '#444'
              }
            }
          }
        } else if (widget.displayType === 6) {
          // interaction Type Trend - 4 Weeks
          obj = {
            chart: {
              type: 'bar',
              height: 180,
              title: widget.name,
              id: widget.id,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                barHeight: '60%',
                horizontal: true
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
              showForSingleSeries: true
            },
            tooltip: {
              shared: true,
              intersect: false
            },
            xaxis: {
              type: 'number',
              categories: widget.data.xAxis.labels
            },
            yaxis: {
              decimalsInFloat: 1,
              title: {
                text: widget.data.yAxis.title === null ? '' : widget.data.yAxis.title
              },
              labels: {
                show: false
              }
            },
            title: {
              text: widget.name,
              floating: true,
              align: 'left',
              style: {
                color: '#444'
              }
            },
            colors: [
              function ({ value, seriesIndex, dataPointIndex, w }) {
                switch (seriesIndex) {
                  case 0:
                    return '#7CB5EC'
                  case 1:
                    return '#434348'
                  case 2:
                    return '#90ED7D'
                  case 3:
                    return '#F7A35C'
                  case 4:
                    return '#8085E9'
                  case 5:
                    return '#F15C80'
                  case 6:
                    return '#E4D354'
                  case 7:
                    return '#2B908F'
                  case 8:
                    return '#F45B5B'
                  case 9:
                    return '#91E8E1'
                  case 10:
                    return '#999eff'
                  case 11:
                    return '#ff7599'
                  case 12:
                    return '#fdec6d'
                  case 13:
                    return '#44a9a8'
                  case 14:
                    return '#ff7474'
                  case 15:
                    return '#aafffa'
                  default:
                    return '#7CB5EC'
                }
              }
            ]
          }
        } else if (widget.displayType === 16) {
          // Calls by Direction - 4 Weeks
          obj = {
            chart: {
              title: widget.name,
              id: widget.id,
              stacked: widget.data.series.length > 1,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
              showForSingleSeries: true
            },
            tooltip: {
              shared: true,
              intersect: false
            },
            xaxis: {
              type: 'datetime',
              categories: widget.data.xAxis.labels
            },
            yaxis: {
              decimalsInFloat: 1,
              title: {
                text: widget.data.yAxis.title === null ? '' : widget.data.yAxis.title
              }
            },
            title: {
              text: widget.name,
              floating: true,
              align: 'left',
              style: {
                color: '#444'
              }
            },
            colors: [
              function ({ value, seriesIndex, dataPointIndex, w }) {
                switch (seriesIndex) {
                  case 0:
                    return '#90ED7D'
                  case 1:
                    return '#434348'
                  case 2:
                    return '#7CB5EC'
                  case 3:
                    return '#F7A35C'
                  case 4:
                    return '#8085E9'
                  case 5:
                    return '#F15C80'
                  case 6:
                    return '#E4D354'
                  case 7:
                    return '#2B908F'
                  case 8:
                    return '#F45B5B'
                  case 9:
                    return '#91E8E1'
                  case 10:
                    return '#999eff'
                  case 11:
                    return '#ff7599'
                  case 12:
                    return '#fdec6d'
                  case 13:
                    return '#44a9a8'
                  case 14:
                    return '#ff7474'
                  case 15:
                    return '#aafffa'
                  default:
                    return '#7CB5EC'
                }
              }
            ]
          }
        } else {
          obj = {
            chart: {
              title: widget.name,
              id: widget.id,
              stacked: widget.data.series.length > 1
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
              showForSingleSeries: true
            },
            xaxis: {
              type: 'datetime',
              categories: widget.data.xAxis.labels
            },
            yaxis: {
              decimalsInFloat: 1,
              title: {
                text: widget.data.yAxis.title === null ? '' : widget.data.yAxis.title
              }
            },
            title: {
              text: widget.name,
              floating: true,
              align: 'left',
              style: {
                color: '#444'
              }
            }
          }
        }
      }
      return obj
    },
    getChartSeries (widget) {
      var obj = []
      if (widget.data) {
        for (let i = 0; i < widget.data.series.length; i++) {
          var elem = { name: widget.data.series[i].name, data: widget.data.series[i].data }
          obj.push(elem)
        }
      }
      return obj
    },
    getChartType (widget) {
      var type = ''
      switch (widget.displayType) {
        case 6:
        case 7:
        case 16:
          type = 'bar'
          break
        case 8:
        case 10:
        case 11:
          type = 'line'
          break
        case 12:
        case 13:
          type = 'area'
          break
        default:
          type = 'bar'
          break
      }
      return type
    },
    setWidgetData: async function (widgetId) {
      if (widgetId === this.widgetData.id) {
        var rtn = await this.getWidgetById(this.widgetData.id)
        if (rtn.chartWidgets) {
          this.widgetData = rtn.chartWidgets[0]
        }
      }
    },
    timestamp (utc) {
      var localDate = new Date(utc).toLocaleString()
      return localDate
    }
  }
}
</script>
<style scoped>

.widget-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #000000;
  border: #dddddd 1px solid;
  padding: 10px 0 0 0;
}
.widget-header-title {
  cursor: default;
  padding: 0.4em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-content-wrapper {
  overflow: visible;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
}
.widget-content {
  height: 100%;
  font-size: 2em;
}
.widget-footer {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  flex-shrink: 0;
}
.widget-footer-timestamp {
  font-size: 0.8em;
  color: #aaa;
  text-align: right;
  margin-left: auto;
}
.timestamp {
  transition: all 0.1s ease;
  color: #aaa;
  font-size: 0.8em;
  text-align: right;
  background-color: white;
  cursor: pointer;
}
.apexcharts-title-text {
  margin-bottom: 10px;
}
#controlRefreshBar {
  display: inline-block;
  width: 100%;
  border-radius: 0;
  height: 4px;
}
#controlRefreshBar[value]::-webkit-progress-bar {
  background-color: #fff;
}
#controlRefreshBar[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, rgb(218, 240, 255), rgb(218, 240, 255));
  border-radius: 0;
  background-size: 100%;
}
</style>
