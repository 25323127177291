import portalAPIGateway from '../../../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteTwilioAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteTwilioAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteQuoteTwilioAPIMixin_saveQuoteTwilioToDB: async function (quoteId, quoteTwilio) {
      var axiosConfig = this.iQuoteQuoteTwilioAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.put('iquote/quotetwilio/' + quoteId, quoteTwilio, axiosConfig).then((response) => {
          returnValue = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteTwilioAPIMixin_saveQuoteTwilioToDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteTwilioAPIMixin_deleteQuoteTwilioFromDB: async function (quoteTwilio) {
      var axiosConfig = this.iQuoteQuoteTwilioAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.delete('iquote/quotetwilio/' + quoteTwilio.id, null, axiosConfig).then((response) => {
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteTwilioAPIMixin_deleteQuoteTwilioFromDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteTwilioAPIMixin_getQuoteTwilioFromDb: async function (quoteId) {
      var axiosConfig = this.iQuoteQuoteTwilioAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquote/quotetwilio/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteTwilioAPIMixin_getQuoteTwilioFromDb returned error: ' + error)
      }

      return data
    }
  }
}
