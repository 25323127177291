<template>
  <div class="formFieldContainer">
    <label
      :for="idattr"
      class="createTicketLabel"
    >{{ `${label}: ` }}</label>
    <input
      :id="idattr"
      :class="{ errorHighlight: error }"
      type="text"
      class="createTicketTextbox ticketTitle"
      @blur="()=>this.$emit('onblur')"
    >
  </div>
</template>

<script>

export default {
  name: 'FormTextField',
  props: {
    idattr: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    classname: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
  .formFieldContainer {
    padding-top: 5px;
  }
  .createTicketLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .createTicketTextbox {
    width: 68%;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }
  .errorHighlight {
    border: 1px solid red!important;
  }
  input {
    border-radius: 5px;
  }
</style>
