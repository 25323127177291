import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { mapActions } from 'vuex'
import { iQuoteCalculationsDesignPackMixin } from './iQuoteCalculationsDesignPack'

export const iQuoteCalculationsDesignPackHardwareMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin,
    iQuoteFunctions,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackMixin
  ],
  data () {
    return {
      iQuoteCalculationsDesignPackHardwareMixin: {
        designPackMarginSettings: [
          { id: 1, description: 'Default' },
          { id: 2, description: 'Custom' }
        ]
      }
    }
  },
  computed: {
    iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack () {
      return this.iQuoteStore_GetDesignPack
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
    ]),
    async iQuoteCalculationsDesignPackHardwareMixin_updateMiscEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.hardwareMiscEntries = newEntries

      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackHardwareMixin_updateMiscEntries')
    },
    async iQuoteCalculationsDesignPackHardwareMixin_updateMainEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.hardwareMainEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackHardwareMixin_updateMainEntries')
    }
  }
}
