<template>
  <div
    class="tileContainer"
  >
    <div
      @click="()=>this.$emit('setCurrentView')"
      @mouseover="isHovered=true"
      @mouseout="isHovered=false">
      <div class="tileWrapper">
        <div class="tileContent">
          <div class="tileIconHolder">
            <v-icon
              :color="!isHovered ? iconColour : iconColourHovered"
              class="tileIcon"
            >{{ icon }}</v-icon>
          </div>
          <div class="tileBody">
            <p class="tileTitle">{{ title }}</p>
            <p class="tileDescription">{{ description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadDashboardImageComponent',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    iconColour: {
      type: String,
      required: true
    },
    iconColourHovered: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isHovered: false
    }
  }
}
</script>
<style scoped>
  .tileContainer {
    display: inline-block;
    margin: 20px;
    width: 340px;
    height: 125px;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    transition: all .3s ease;
  }
  .tileContainer:hover {
    /* transform: scale(1.05); */
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 25%);
    cursor: pointer;
  }
  .tileWrapper{
    height: 100px;
  }
  .tileContent {
    display: flex;
    padding: 5px;
  }
  .tileIconHolder {
    margin-left: 5px;
    position: absolute;
    top: 15%;
    width: 80px;
    flex-shrink: 0;
    height: 80px;
    /* background-image: linear-gradient(147deg , #fe8a39 0%, #fd3838 74%);
    box-shadow: 4px 13px 30px 1px rgb(252 56 56 / 20%); */
    border-radius: 5px;
    padding: 10px;
  }
  .tileIcon {
    font-size: 60px;
  }
  .tileBody {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 100px;
  }
  .tileBody p{
    color: #0d0925;
    margin-bottom: 0px;
  }
  .tileTitle {
    font-size: 18px;
    font-weight: 700;
  }
  .tileDescription {
    font-size: 16px;
  }
</style>
