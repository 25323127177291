import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { mapActions } from 'vuex'
import { iQuoteEventBus } from '../../../events/iQuoteEvents'

export const iQuoteCalculationsDesignPackCeasesMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin,
    iQuoteFunctions,
    iQuoteQuoteDesignPacksAPIMixin
  ],
  data () {
    return {
    }
  },
  computed: {
    iQuoteCalculationsDesignPackCeasesMixin_selectedDesignPack () {
      return this.iQuoteStore_GetDesignPack
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
    ]),
    async iQuoteCalculationsDesignPackCeasesMixin_updateEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.ceasesEntries = newEntries
      iQuoteEventBus.$emit('designpack-modified', designPack, 'iQuoteCalculationsDesignPackCeasesMixin_updateEntries')
    }
  }
}
