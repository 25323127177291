<template>
  <div>
    <Spinner v-if="saving" />
    <div v-if="!saving">
      <label
        for="txtTicketNoteTitle"
        class="ticketNoteLabel"
      >Note Title:</label>
      <input
        id="txtTicketNoteTitle"
        v-model="note.title"
        :class="ticketNoteTitleClass"
        type="text"
        @blur="checkTitle"
      >
      <br>
      <label
        for="txtTicketBody"
        class="ticketNoteLabel"
      >Note:</label>
      <textarea
        id="txtTicketBody"
        v-model="note.body"
        :class="ticketNoteBodyClass"
        type="text"
        @blur="checkBody"
      />
      <!-- <label
        for="optAppentToResolution"
        class="ticketNoteOptionLabel"
      >Append to Resolution:</label>
      <TriStateToggleSwitch
        id="optAppentToResolution"
        :enabled="true"
        :tri-state="false"
        :current-state="2"
        class="toggleSwitch"
        @statechanged="({ selectedState }) => {updateAppentToResolution(selectedState)}"
      /> -->
      <br><br>
      <label
        for="optNotifyResources"
        class="ticketNoteOptionLabel"
      >Notify Resources on this Ticket:</label>
      <TriStateToggleSwitch
        id="optNotifyResources"
        :enabled="true"
        :tri-state="false"
        :current-state="2"
        class="toggleSwitch"
        @statechanged="({ selectedState }) => {updateNotifyResources(selectedState)}"
      />
      <br><br>
      <label
        for="txtUpdateRecipients"
        class="ticketNoteTextareaLabel"
      >Other Recipients (emails separated by semicolons)</label>
      <textarea
        id="txtUpdateRecipients"
        v-model="note.recipients"
        :class="ticketRecipientsEmailClass"
        style="height:30px"
        class="ticketRecipients"
        @blur="checkOtherRecipients"
      />
      <div
        v-if="showAddButton"
        class="buttonContainer"
      >
        <input
          id="AddNoteButton"
          :class="buttonClass"
          :disabled="!formOK"
          type="button"
          title="Add Note"
          value="Add Note"
          @click="addNoteToTicket"
        >
      </div>
    </div>
  </div>
</template>

<script>
import TriStateToggleSwitch from '../../components/common/triStateToggleSwitch'
import Spinner from '../common/spinner'
import { autotaskAPIMixin } from '../../mixins/autotaskAPIMixin'
import { ticketMixin } from '../../mixins/ticket'
export default {
  name: 'TicketNote',
  components: {
    TriStateToggleSwitch,
    Spinner
  },
  mixins: [
    autotaskAPIMixin,
    ticketMixin
  ],
  props: {
    ticket: {
      type: Object,
      default: null
    },
    ticketId: {
      type: Number,
      default: null
    },
    siteId: {
      type: Number,
      default: null
    },
    showAddButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      note: {
        title: '',
        body: '',
        recipients: ''
      },
      noteTitleError: false,
      noteBodyError: false,
      notifyResources: false,
      recipientEmailError: false,
      saving: false
    }
  },
  computed: {
    formOK () {
      return this.note.title.length > 0 && this.note.body.length > 0 && !this.recipientEmailError
    },
    buttonClass () {
      if (this.formOK) {
        return 'buttonEnabled'
      } else {
        return 'buttonDisabled'
      }
    },
    ticketNoteBodyClass () {
      var rtn = 'ticketBodyTextarea'
      if (this.noteBodyError) {
        rtn = 'ticketBodyTextareaError'
      }
      return rtn
    },
    ticketNoteTitleClass () {
      var rtn = 'ticketNoteTextbox'
      if (this.noteTitleError) {
        rtn = 'ticketNoteTextboxError'
      }
      return rtn
    },
    ticketRecipientsEmailClass () {
      var rtn = 'tickeRecipientsTextarea'
      if (this.recipientEmailError) {
        rtn = 'tickeRecipientsTextareaError'
      }
      return rtn
    }
  },
  methods: {
    addNoteToTicket: async function () {
      if (this.formOK) {
        this.saving = true
        var contactId = 0
        var addresses = ''
        var body = this.note.body.replace(RegExp('\'', 'g'), '`')
        var title = this.note.title.replace(RegExp('\'', 'g'), '`')
        var siteArray = this.$store.getters.sites.filter(s => s.id === this.siteId)
        if (siteArray.length > 0) {
          console.log('Found site: ' + siteArray[0].id)
          contactId = await this.getSiteContact(siteArray[0])
        }
        if (contactId === 0) {
          contactId = await this.setNewContact(this.siteId)
        }
        if (contactId > 0) {
          var payload = {
            title: title,
            body: body,
            contactId: contactId
          }
          var rtn = await this.addTicketNote(this.ticketId, payload)
          if (rtn === 'error') {
            this.$store.commit('showMessage', { content: 'The ticket note failed to add correctly.', color: 'red', timeout: 3000 })
          } else {
            this.$store.commit('showMessage', { content: 'The ticket note added correctly.', color: 'green', timeout: 3000 })
            var recipients = this.note.recipients
            this.note.recipients = ''
            this.note.body = ''
            this.note.title = ''
            this.saving = false
            if (this.notifyResources && this.ticket.assignedResourceId && this.ticket.assignedResourceId > 0) {
              var resource = await this.getResourceById(this.ticket.assignedResourceId)
              if (resource && resource.length > 0) {
                addresses = resource[0].emailAddress
              }
            }
            if (recipients && recipients.length > 0) {
              if (addresses.length > 0) {
                addresses += ';'
              }
              addresses += recipients
            }
            if (addresses.length > 0) {
              await this.sendNotification(addresses, body, title)
              this.$store.commit('showMessage', { content: 'A notification for the new note was sent successfully.', color: 'green', timeout: 3000 })
            }

            // If the note was successfully added to Autotask, attempt to save it to the database
            // Set default values to save to the database
            var resourceId = 29683367 // This is the resource ID for Maintel Software, set by Autotask as the default
            var ticketNoteId = rtn.itemId

            var createdDate = this.getDetaultNoteDate()
            var lastActivityDate = this.getDetaultNoteDate()

            // Try to retrieve the ticket from the database to obtain the correct values
            rtn = await this.getSpecificTicketNote(ticketNoteId)
            if (rtn) {
              if (rtn.toString().indexOf('Error:') !== 0) {
                createdDate = rtn.createdDate
                lastActivityDate = rtn.lastActivityDate
                resourceId = rtn.resourceId
              }
            } else {
              console.log('Failed to retrieve new ticket note from database - using default values')
            }

            payload = {
              Title: title,
              Body: body,
              ContactId: contactId,
              Id: ticketNoteId,
              TicketId: this.ticketId,
              CreatedDate: createdDate,
              LastActivityDate: lastActivityDate,
              ResourceId: resourceId
            }
            rtn = await this.saveTicketNoteToDatabase(payload)
            // Moved from above as we don't want to refresh the page until AFTER the note has been added to the database
            this.$emit('note-added')
          }
          this.saving = false
        } else {
          console.log('ERROR - could not resolve or set a contact for the creation of the note.')
        }
      }
    },
    getDetaultNoteDate: function () {
      var newTicketDate = new Date()
      var defaultDate = newTicketDate.getFullYear() + '-' + this.padTimeComponent(newTicketDate.getMonth() + 1) + '-' + this.padTimeComponent(newTicketDate.getDate())
      defaultDate += 'T' + this.padTimeComponent(newTicketDate.getHours()) + ':'
      defaultDate += this.padTimeComponent(newTicketDate.getMinutes()) + ':' + this.padTimeComponent(newTicketDate.getSeconds())
      return defaultDate
    },
    padTimeComponent: function (component) {
      if (component.toString().length === 1) {
        return '0' + component.toString()
      } else {
        return component.toString()
      }
    },
    checkTitle () {
      this.noteTitleError = false
      if (this.note.title.length === 0) {
        this.$store.commit('showMessage', { content: 'Please enter a note title', color: 'red', timeout: 3000 })
        this.noteTitleError = true
      }
    },
    checkBody () {
      this.noteBodyError = false
      if (this.note.body.length === 0) {
        this.$store.commit('showMessage', { content: 'Please enter the text for the note', color: 'red', timeout: 3000 })
        this.noteBodyError = true
      }
    },
    updateNotifyResources (state) {
      this.notifyResources = (state === 1)
    },
    sendNotification: async function (addresses, body, title) {
      await this.sendResourceNotification(addresses, title, body, this.ticket)
    },
    checkOtherRecipients () {
      var isOk = true
      if (this.note.recipients) {
        this.note.recipients = this.note.recipients.replace(' ', '')
        var fields = this.note.recipients.split(';')
        for (let i = 0; i < fields.length; i++) {
          if (fields[i].length > 0) {
            if (!this.validateEmail(fields[i])) {
              isOk = false
            }
          }
        }
      }
      this.recipientEmailError = !isOk
      if (!isOk) {
        this.$store.commit('showMessage', { content: 'One or more "Other Recipients" email addresses are not valid', color: 'red', timeout: 3000 })
      }
    },
    validateEmail (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>

<style scoped>
  .ticketNoteLabel {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    width:140px;
  }
  .ticketNoteTextbox {
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: solid 1px #9aa8b1;
    background-color: #fff;
    width:100%
  }
  .ticketNoteTextboxError {
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: solid 1px red;
    background-color: #fff;
    width:100%
  }
  .ticketBodyTextarea {
    border-radius: 5px;
    border: solid 1px #9aa8b1;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    width:100%;
    size:7;
  }
  .ticketBodyTextareaError {
    border-radius: 5px;
    border: solid 1px red;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    width:100%;
    size:7;
  }
  .tickeRecipientsTextarea {
    border-radius: 5px;
    border: solid 1px #9aa8b1;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    padding: 0 5px;
    width:100%;
    size:1;
  }
  .tickeRecipientsTextareaError {
    border-radius: 5px;
    border: solid 1px red;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    width:100%;
    size:1;
  }
  .buttonDisabled {
    cursor: default;
    border: default;
    background: default;
  }
  .buttonEnabled {
    cursor: pointer;
    border: solid 1px #309b22;
    background: white;
  }
  .toggleSwitch {
    vertical-align: middle;
  }
  .buttonContainer {
    width: 100%;
    text-align: right;
  }
</style>
