<template>
  <v-snackbar
    v-model="show"
    :timeout="timeout"
    :color="color"
    bottom
    right>
    {{ message }}
    <v-btn
      v-if="buttonText"
      dark
      flat
      icon
      class="confirmBtn"
      @click="clickHandler"
    > {{ buttonText }} </v-btn>
    <v-btn
      dark
      flat
      icon
      class="closeBtn"
      @click="() => { show = false }"
    >Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ConfirmationSnackbar',
  data () {
    return {
      show: false,
      message: '',
      color: '',
      timeout: 3000,
      buttonText: '',
      callback: () => {}
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showConfirmationMessage') {
        this.message = state.ConfirmationSnackbar.content
        this.color = state.ConfirmationSnackbar.color
        this.timeout = state.ConfirmationSnackbar.timeout
        this.callback = state.ConfirmationSnackbar.callback
        this.buttonText = state.ConfirmationSnackbar.buttonText
        this.show = true
      }
    })
  },
  methods: {
    clickHandler: function () {
      this.show = false
      this.callback()
    }
  }
}
</script>
<style scoped>
  .confirmBtn {
    border: solid 1px #fff;
    border-radius: 10px;
    padding: 0 10px;
  }
  .closeBtn {
    border: solid 1px #fff;
    border-radius: 10px;
    padding: 0 10px;
  }
  .v-btn.v-btn--icon {
    width: 68px;
  }
</style>
