<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconTeamsConnector"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="teamsConnectorAttendantConsoleComponent.quote.quoteOpportunityId + ' (revision ' + teamsConnectorAttendantConsoleComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>Teams Connector - Attendant Console</h3>
      <br>
      <label
        class=""
        for="cboAttendantConsoleSubscriptionBillingPeriod"
      >Subscription Billing Period</label>
      <select
        id="cboAttendantConsoleSubscriptionBillingPeriod"
        v-model.number="teamsConnectorAttendantConsoleComponent.teamsConnector.attendantConsoleSubscriptionBillingPeriodId"
      >
        <option
          key="1"
          value="1"
          title="Annual"
        >Annual</option>
        <option
          key="2"
          value="2"
          title="Monthly"
        >Monthly</option>
      </select>
      <br><br>
      <label
        :class="{InputError:!isValidNumAttendantConsoles}"
        for="numAttendantConsoles"
      >Number of Attendant Consoles<TooltipIcon
        text="Number of Attendant Consoles" />
      </label>
      <input
        id="numAttendantConsoles"
        v-model.number="teamsConnectorAttendantConsoleComponent.teamsConnector.numAttendantConsoles"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('TeamsConnectorRecordings')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'TeamsConnectorAttendantConsole',
  components: {
    TooltipIcon,
    TriStateToggleSwitch
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin],
  data () {
    return {
      teamsConnectorAttendantConsoleComponent: {
        quote: {},
        teamsConnector: {
          id: 0,
          numAttendantConsoles: 0,
          attendantConsoleSubscriptionBillingPeriodId: 1 /* 1 = annual */
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconTeamsConnector () {
      return require(`../../../assets/icons/teamsConnector.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidNumAttendantConsoles () {
      var value = this.teamsConnectorAttendantConsoleComponent.teamsConnector.numAttendantConsoles
      return (this.iQuoteFunctions_IsInteger(value))
    }
  },
  async mounted () {
    this.teamsConnectorAttendantConsoleComponent.quote = this.$store.getters.quote
    this.teamsConnectorAttendantConsoleComponent.teamsConnector = this.iQuoteStore_GetTeamsConnector
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTeamsConnector'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      if (!this.isValidNumAttendantConsoles
      ) {
        ValidationFailed = true
      }

      if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      }

      this.setQuoteTeamsConnector(this.teamsConnectorAttendantConsoleComponent.teamsConnector)
      await this.saveQuoteTeamsConnectorProperties(this.teamsConnectorAttendantConsoleComponent.teamsConnector)
      this.setCurrentQuoteState(page)
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 620px}
  .quotePanel label {width:320px;}
  .small {width:60px;}
  .hidden {visibility: hidden;}
</style>
