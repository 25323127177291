<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-managesites"
          primary-title
        >
          Managing Sites
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section describes managing sites details, memberships and role assignments through the security page."
              />
              <HelpSection
                instruction="1.) Navigate to the 'Security' page via the left hand navigation menu."
                image-source="manage_sites_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Select the ‘Sites’ tab to view a list of all sites available to manage for the current user."
                indentation="1"
              />
              <HelpSection
                instruction="b.) Upon selection of a site a menu will be displayed to the right of the table."
                indentation="1"
              />
              <HelpSection
                instruction="3.) Under the ‘Details for…’ section the site name can be updated."
                image-source="manage_sites_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="3.) Under the 'Members of...' section all users that are assigned to the site will be listed."
                image-source="manage_sites_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) New users can be assigned to the site using the 'Add Member' button, this will allow the user access to that site and the associated data."
                indentation="1"
                image-source="manage_sites_4.png"
              />
              <HelpSection
                instruction="4.) Under the 'Roles at...' section all of the roles that are assigned to that site will be listed."
                image-source="manage_sites_5.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) New roles can be assigned using the 'Add Role' button, this will allow permissions in that role to be assigned to users in that site."
                indentation="1"
                image-source="manage_sites_6.png"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpManageUsers',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
