<template>
  <div>
    <div
      id="BomEntriesLeftPanel"
    >
      <h2>Edit BOM</h2>
      <label
        :class="{InputError:!isValidDescription(externalQuotesModelMixin_State.selectedQuoteBom.description)}"
        for="txtBomDescription"
      >Description:</label>
      <input
        id="txtBomDescription"
        v-model="externalQuotesModelMixin_State.selectedQuoteBom.description"
        type="text"
        maxlength="30"
      >
      <br>
      <br>
      <div id="BomEntries">
        <table
          id="EditBomTable"
          class="iQuoteTable"
          style="display: block"
        >
          <tr>
            <th
              rowspan="2"
              style="width:190px">Description</th>
            <th
              rowspan="2"
              style="width:100px">SF COE</th>
            <th
              colspan="2"
              style="width:100px">Upfront Cost</th>
            <th
              colspan="2"
              style="width:100px">Annual Cost</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th
              style="width:100px">External</th>
            <th
              style="width:100px">Internal</th>
            <th
              style="width:100px">External</th>
            <th
              style="width:100px">Internal</th>
            <th>&nbsp;</th>
          </tr>
          <tr v-if="externalQuotesModelMixin_State.selectedQuoteBom.entries.length === 0">
            <td colspan="7">The are currently no entries on this BOM</td>
          </tr>
          <tr
            v-for="bomEntry in externalQuotesModelMixin_State.selectedQuoteBom.entries"
            :key="bomEntry.id"
          >
            <td :class="{InputError:!isValidBomEntryDescription(bomEntry.description)}">
              <input
                v-model="bomEntry.description"
                type="text"
                maxlength="100">
            </td>
            <td :class="{InputError:!isValidBomEntrySfCoeCode(bomEntry.salesforceCoeCodeId)}">
              <select
                id="cboSalesforceCoeCodes"
                v-model="bomEntry.salesforceCoeCodeId"
              >
                <option
                  v-if="bomEntry.salesforceCoeCodeId === 0"
                  key="0"
                  value="0"
                  title="< Please Select >"
                >&lt; Please Select &gt;
                </option>
                <option
                  v-for="t in bomPageComponent.salesforceCoeCodes"
                  :key="t.id"
                  :value="t.id"
                  :title="t.code"
                >{{ t.code }}
                </option>
              </select>
            </td>
            <td>£
              <input
                v-model.number="bomEntry.upfrontExternalCost"
                type="number"
                min="0"
                max="10000000"
                class="currencyInput"
              >
            </td>
            <td>£
              <input
                v-model.number="bomEntry.upfrontInternalCost"
                type="number"
                min="0"
                max="10000000"
                class="currencyInput"
              >
            </td>
            <td>£
              <input
                v-model.number="bomEntry.recurringExternalCost"
                type="number"
                min="0"
                max="10000000"
                class="currencyInput"
              >
            </td>
            <td>£
              <input
                v-model.number="bomEntry.recurringInternalCost"
                type="number"
                min="0"
                max="10000000"
                class="currencyInput"
              >
            </td>
            <td>
              <input
                :id="'btnDelBomEntry' + bomEntry.toString()"
                value="x"
                type="button"
                @click="delBomEntry(bomEntry.id)"
              >
            </td>
          </tr>
          <tr>
            <th colspan="2">TOTAL:</th>
            <th>£{{ externalQuotesModelMixin_getBomItemTotalUpfrontExternalCost(externalQuotesModelMixin_State.selectedQuoteBom) }}</th>
            <th>£{{ externalQuotesModelMixin_getBomItemTotalUpfrontInternalCost(externalQuotesModelMixin_State.selectedQuoteBom) }}</th>
            <th>£{{ externalQuotesModelMixin_getBomItemTotalRecurringExternalCost(externalQuotesModelMixin_State.selectedQuoteBom) }}</th>
            <th>£{{ externalQuotesModelMixin_getBomItemTotalRecurringInternalCost(externalQuotesModelMixin_State.selectedQuoteBom) }}</th>
            <th>&nbsp;</th>
          </tr>
        </table>
        <br>
        <input
          v-if="externalQuotesModelMixin_State.selectedQuoteBom.id !== undefined"
          id="'btnAddNewBomEntry'"
          value="Add New BOM Entry"
          type="button"
          @click="addBomEntry"
        >
      </div>
    </div>
    <div id="FilesSection">
      <h2>Bom Spreadsheet</h2>
      <input
        id="btnDownloadBomTemplate"
        value="Download BOM Template"
        type="button"
        @click="downloadBomTemplate()"
      >
      <input
        v-if="bomSpreadsheetFile.fileUploadId === undefined"
        id="'btnUploadFile'"
        value="Upload BOM Spreadsheet"
        type="button"
        @click="uploadFileButtonClicked('Upload New External BOM', 0)"
      >
      <table
        v-if="bomPageComponent.inheritedFileDetails !== null && bomSpreadsheetFile.fileUploadId === undefined"
        id="InheritedFileTable"
        class="iQuoteTable"
      >
        <tr>
          <th>Description</th>
          <th>File Name</th>
          <th>Notes</th>
        </tr>
        <tr
        >
          <td><div>{{ bomPageComponent.inheritedFileDetails.title }} <br><br><b>Notes:</b> {{ bomPageComponent.inheritedFileDetails.versionNotes }}</div></td>
          <td><div>{{ bomPageComponent.inheritedFileDetails.name }}</div></td>
          <td style="font-weight: 600">Duplicated spreadsheet<br>
            <input
              id="btnDownloadInheritedSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.selectedQuoteBom.fileUploadVersionId)"
            >
          </td>
        </tr>
      </table>
      <table
        v-if="bomSpreadsheetFile.fileUploadId !== undefined"
        id="FilesTable"
        class="iQuoteTable"
      >
        <tr
        >
          <th>#</th>
          <th>Description</th>
          <th>File Name</th>
          <th>&nbsp;</th>
        </tr>
        <tr
          v-if="bomSpreadsheetFile.fileUploadId !== undefined"
        >
          <td>{{ bomSpreadsheetLatestFileVersion.versionNumber }}</td>
          <td><div>{{ bomSpreadsheetFile.title }} <br><br><b>Notes:</b> {{ bomSpreadsheetLatestFileVersion.versionNotes }}</div></td>
          <td><div>{{ bomSpreadsheetFile.fileName }}</div></td>
          <td>
            <input
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(bomSpreadsheetLatestFileVersion.fileVersionId)"
            >
            <input
              id="btnSetValuesFromLatestSpreadsheet"
              value="Import BOM Values"
              type="button"
              @click="setValuesFromSpreadsheet(bomSpreadsheetLatestFileVersion.fileVersionId)"
            >
            <input
              id="'btnUploadFile'"
              value="Replace"
              type="button"
              @click="uploadFileButtonClicked('Upload Updated External BOM', bomSpreadsheetFile.fileUploadId)"
            >
          </td>
        </tr>
        <tr
          v-if="bomSpreadsheetPreviousFileVersions.length > 0 || externalQuotesModelMixin_State.selectedQuoteBom.fileUploadVersionId"
        >
          <td colspan="4">&nbsp;</td>
        </tr>
        <tr
          v-if="bomSpreadsheetPreviousFileVersions.length > 0 || externalQuotesModelMixin_State.selectedQuoteBom.fileUploadVersionId"
        >
          <th colspan="3">
            Previous Versions
          </th>
          <th>
            <input
              v-if="!bomPageComponent.showPreviousVersions"
              id="'btnShowPreviousVersion'"
              value="show"
              type="button"
              @click="bomPageComponent.showPreviousVersions = true"
            >
            <input
              v-if="bomPageComponent.showPreviousVersions"
              id="'btnHidePreviousVersion'"
              value="hide"
              type="button"
              @click="bomPageComponent.showPreviousVersions = false"
            >
          </th>
        </tr>
        <tr
          v-if="(bomSpreadsheetPreviousFileVersions.length > 0 || externalQuotesModelMixin_State.selectedQuoteBom.fileUploadVersionId) && bomPageComponent.showPreviousVersions"
        >
          <th>#</th>
          <th>Notes</th>
          <th>File Name</th>
          <th>&nbsp;</th>
        </tr>
        <tbody
          v-if="bomPageComponent.showPreviousVersions"
        >
          <tr v-if="bomPageComponent.inheritedFileDetails">
            <td>0</td>
            <td><div>{{ bomPageComponent.inheritedFileDetails.versionNotes }}<br>(Duplicated)</div></td>
            <td><div>{{ bomPageComponent.inheritedFileDetails.name }}</div></td>
            <td>
              <input
                id="btnDownloadInheritedSpreadsheet"
                value="Download"
                type="button"
                @click="downloadFile(externalQuotesModelMixin_State.selectedQuoteBom.fileUploadVersionId)"
              >
            </td>
          </tr>
          <tr
            v-for="file in bomSpreadsheetPreviousFileVersions"
            :key="file.id">
            <td>{{ file.versionNumber }}</td>
            <td><div>{{ file.versionNotes }}</div></td>
            <td><div>{{ file.name }}</div></td>
            <td>
              <input
                :id="'btnDownloadFile' + file.id"
                value="Download"
                type="button"
                @click="downloadFile(file.fileVersionId)"
              >
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <FileUploadDialogBox
        :dialog="bomPageComponent.showFileUploadDialog"
        :dlg-message="bomPageComponent.fileUploadDialogMessage"
        :dlg-close-from-top="false"
        :file-upload-catalog-id="bomPageComponent.selectedFileUploadCatalogId"
        :checking-file="bomPageComponent.fileDialogCheckingFile"
        :selected-file-error="bomPageComponent.fileDialogSelectedFileError"
        dlgid="fileUploadDialogBox"
        dlg-icon="mdi-alert-circle-outline"
        @uploadAction="fileUploaded"
        @cancelAction="bomPageComponent.showFileUploadDialog = false"
        @fileChosenAction="fileChosen"
      />
    </div>
    <br><br>
    <div class="buttons">
      <div class="leftButtons">
        <input
          id="btnOk"
          title="Save changes"
          value="OK"
          type="button"
          @click="okButtonPressed()"
        >
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../../common/tooltipIcon'
import FileUploadDialogBox from '../../../common/fileUploadDialogBox'
import { iQuoteCalculationsCommonMixin } from '../../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStaticDataAPIMixin } from '../../../../mixins/iQuote/iQuoteStaticDataAPIMixin'
import { UploadsAPIMixin } from '../../../../mixins/uploadsAPIMixin'
import { iQuoteExportsAPIMixin } from '../../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { externalQuotesModelMixin } from '../../../../mixins/iQuote/Models/externalQuotesModelMixin'

import { mapActions } from 'vuex'
export default {
  name: 'BomPage',
  components: {
    TooltipIcon, FileUploadDialogBox
  },
  mixins: [iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin, iQuoteStaticDataAPIMixin, UploadsAPIMixin, iQuoteExportsAPIMixin, externalQuotesModelMixin],
  data () {
    return {
      bomPageComponent: {
        salesforceCoeCodes: [],
        showFileUploadDialog: false,
        fileUploadDialogMessage: '',
        selectedFileUploadCatalogId: 0,
        fileDialogCheckingFile: false,
        fileDialogSelectedFileError: '',
        showPreviousVersions: false,
        inheritedFileDetails: null
      }
    }
  },
  computed: {
    bomSpreadsheetFile () {
      if (this.externalQuotesModelMixin_State.selectedQuoteBom.id === undefined) {
        return {}
      } else {
        for (let index = 0; index < this.externalQuotesModelMixin_State.selectedQuoteBom.files.length; index++) {
          const file = this.externalQuotesModelMixin_State.selectedQuoteBom.files[index]
          if (file.isBomSpreadsheet) {
            return file
          }
        }

        return {}
      }
    },
    bomSpreadsheetLatestFileVersion () {
      if (this.bomSpreadsheetFile.id === undefined) {
        return {}
      }

      return this.externalQuotesModelMixin_getLatestFileVersion(this.bomSpreadsheetFile)
    },
    bomSpreadsheetPreviousFileVersions () {
      if (this.bomSpreadsheetFile.id === undefined ||
          this.bomSpreadsheetFile.versionList === undefined || /* should only be "undefined" for a short period, whilst the uploads service is queried on externalQuotesModel mounted () */
          this.bomSpreadsheetFile.versionList.count <= 1) {
        return []
      }

      var latestFileVersionNumber = this.bomSpreadsheetLatestFileVersion.versionNumber

      return this.bomSpreadsheetFile.versionList.filter(x => x.versionNumber < latestFileVersionNumber)
    }
  },
  async mounted () {
    var salesforceCoeCodes = await this.iQuoteStaticDataAPIMixin_getSalesforceCoeCodes()

    this.bomPageComponent.salesforceCoeCodes = salesforceCoeCodes.filter(x => x.selectableForBoms === true)

    const fileUploadVersionId = this.externalQuotesModelMixin_State.selectedQuoteBom.fileUploadVersionId
    if (fileUploadVersionId) {
      this.bomPageComponent.inheritedFileDetails = await this.UploadsAPIMixin_GetFileDetailsByVersionId('iQuote', fileUploadVersionId)
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),
    isValidDescription (description) {
      return (description.trim().length > 0)
    },
    isValidBomEntryDescription (description) {
      return (description.trim().length > 0)
    },
    isValidBomEntrySfCoeCode (salesforceCoeCodeId) {
      return (salesforceCoeCodeId !== 0)
    },
    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
    setValuesFromSpreadsheet: async function (fileId) {
      var data = await this.UploadsAPIMixin_DownloadFile('iquote', fileId)
      var fileDetails = await this.UploadsAPIMixin_GetFileDetailsByVersionId('iQuote', fileId)

      var base64data
      var contentType = 'application/octet-stream'
      var fileExtension = fileDetails.name.split('.').pop().toLowerCase()

      switch (fileExtension) {
        case 'xls':
          contentType = 'application/vnd.ms-excel'
          break
        case 'xlsx':
          contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
        case 'doc':
          contentType = 'application/vnd.ms-word'
          break
        case 'docx':
          contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          break
        case 'pdf':
          contentType = 'application/pdf'
          break
        case 'jpg':
          contentType = 'image/jpeg'
          break
        case 'png':
          contentType = 'image/png'
          break
      }

      var blob = new Blob([data], { type: contentType })

      base64data = await this.blobToBase64(blob)

      var bomData = await this.iQuoteExportsAPIMixin_getBOMDataFromExcel(base64data)

      var isValidData = true

      bomData.entries.forEach(bomItem => {
        if (!this.isValidSFCoeCode(bomItem.SF_COE)) {
          isValidData = false

          this.iQuoteFunctions_ShowError(bomItem.SF_COE + ' is an invalid SF COE Code.  This data cannot be imported')
        }
      })

      if (isValidData) {
        this.externalQuotesModelMixin_State.selectedQuoteBom.entries = []

        var designPackItemLookupId = 0
        bomData.entries.forEach(bomItem => {
          designPackItemLookupId++
          this.externalQuotesModelMixin_State.selectedQuoteBom.entries.push(
            {
              id: this.getTempBomEntryId(),
              description: bomItem.Description,
              salesforceCoeCodeId: this.getSF_COE_IdFromName(bomItem.SF_COE),
              upfrontExternalCost: bomItem.UpfrontExternalCost,
              upfrontInternalCost: bomItem.UpfrontInternalCost,
              recurringExternalCost: bomItem.RecurringExternalCost,
              recurringInternalCost: bomItem.RecurringInternalCost,
              designPackItemLookupId: designPackItemLookupId
            }
          )
        })
      }
    },
    isValidSFCoeCode (salesforceCoeCode) {
      return this.bomPageComponent.salesforceCoeCodes.some(e => e.code === salesforceCoeCode)
    },
    getSF_COE_IdFromName (salesforceCoeCode) {
      var matches = this.bomPageComponent.salesforceCoeCodes.filter(e => e.code === salesforceCoeCode)

      if (matches.length > 0) {
        return matches[0].id
      } else {
        return 0
      }
    },
    downloadBomTemplate: async function () {
      var data = await this.iQuoteExportsAPIMixin_getBomTemplate()
      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
      a.download = 'Bom Template.xlsx'
      document.body.appendChild(a)
      a.click()
    },
    downloadFile: async function (fileId) {
      var data = await this.UploadsAPIMixin_DownloadFile('iquote', fileId)
      var fileDetails = await this.UploadsAPIMixin_GetFileDetailsByVersionId('iQuote', fileId)
      var contentType = 'application/octet-stream'
      var fileExtension = fileDetails.name.split('.').pop().toLowerCase()

      switch (fileExtension) {
        case 'xls':
          contentType = 'application/vnd.ms-excel'
          break
        case 'xlsx':
          contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
        case 'doc':
          contentType = 'application/vnd.ms-word'
          break
        case 'docx':
          contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          break
        case 'pdf':
          contentType = 'application/pdf'
          break
        case 'jpg':
          contentType = 'image/jpeg'
          break
        case 'png':
          contentType = 'image/png'
          break
      }

      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: contentType }))
      a.download = fileDetails.name
      document.body.appendChild(a)
      a.click()
    },
    getTempBomEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.externalQuotesModelMixin_State.selectedQuoteBom.entries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    addBomEntry () {
      this.externalQuotesModelMixin_State.selectedQuoteBom.entries.push({
        id: this.getTempBomEntryId(),
        description: '',
        salesforceCoeCodeId: 0,
        upfrontExternalCost: 0,
        upfrontInternalCost: 0,
        recurringExternalCost: 0,
        recurringInternalCost: 0
      })
    },
    delBomEntry (id) {
      for (let index = this.externalQuotesModelMixin_State.selectedQuoteBom.entries.length - 1; index !== -1; index--) {
        const element = this.externalQuotesModelMixin_State.selectedQuoteBom.entries[index]

        if (element.id === id) {
          this.externalQuotesModelMixin_State.selectedQuoteBom.entries.splice(index, 1)
        }
      }
    },
    delBomEntries () {
      this.externalQuotesModelMixin_State.selectedQuoteBom.entries = []
    },
    async okButtonPressed () {
      var selectedQuoteBom = this.externalQuotesModelMixin_State.selectedQuoteBom
      await this.externalQuotesModelMixin_handleBomUpdated(selectedQuoteBom)
      this.externalQuotesModelMixin_setSelectedQuoteBom({})
      this.externalQuotesModelMixin_setCurrentPage('rootPage')
    },
    uploadFileButtonClicked (message, FileUploadCatalogId) {
      this.bomPageComponent.fileUploadDialogMessage = message
      this.bomPageComponent.fileDialogSelectedFileError = ''
      this.bomPageComponent.showFileUploadDialog = true
      this.bomPageComponent.selectedFileUploadCatalogId = FileUploadCatalogId
    },
    async fileChosen (newFile) {
      this.bomPageComponent.fileDialogCheckingFile = true
      this.bomPageComponent.fileDialogSelectedFileError = ''
      var fileExtension = newFile.name.split('.').pop().toLowerCase()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xls' && fileExtension !== 'xltm' && fileExtension !== 'xlsm') {
        this.bomPageComponent.fileDialogSelectedFileError = 'Only Excel Spreadsheets can be uploaded'
      } else {
        var data = await this.iQuoteExportsAPIMixin_getBOMDataFromExcel(newFile.contents)

        if (data.error === undefined) {
          this.bomPageComponent.fileDialogSelectedFileError = 'Error contacting file export service.  Cannot upload.  Please contact your administrator for assistance'
        } else if (data.error !== '') {
          this.bomPageComponent.fileDialogSelectedFileError = data.error
        } else if (data.entries.length === 0) {
          this.bomPageComponent.fileDialogSelectedFileError = 'No BOM Entries found on this spreadsheet'
        }
      }

      this.bomPageComponent.fileDialogCheckingFile = false
    },
    async fileUploaded (newFile) {
      this.bomPageComponent.showFileUploadDialog = false

      this.bomSpreadsheetFile.fileName = newFile.name

      if (this.bomPageComponent.selectedFileUploadCatalogId === newFile.fileUploadCatalogId) {
        // new version of existing file
        for (let index = 0; index < this.externalQuotesModelMixin_State.selectedQuoteBom.files.length; index++) {
          const file = this.externalQuotesModelMixin_State.selectedQuoteBom.files[index]
          if (file.fileUploadId === newFile.fileUploadCatalogId) {
            file.fileName = newFile.name
            file.versionList.push(
              {
                name: newFile.name,
                versionNotes: newFile.versionNotes,
                versionNumber: newFile.versionNumber,
                fileVersionId: newFile.fileVersionId
              }
            )
          }
        }
      } else {
        // New File
        this.externalQuotesModelMixin_State.selectedQuoteBom.files.push(
          {
            id: -1,
            fileUploadId: newFile.fileUploadCatalogId,
            title: newFile.title,
            fileName: newFile.name,
            isBomSpreadsheet: true,
            versionList: [
              {
                name: newFile.name,
                versionNotes: newFile.versionNotes,
                versionNumber: newFile.versionNumber,
                fileVersionId: newFile.fileVersionId
              }
            ]
          }
        )
      }
      await this.setValuesFromSpreadsheet(this.bomSpreadsheetLatestFileVersion.fileVersionId)
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 1520px}
  .small {width:60px;}
  .hidden {visibility: hidden;}

  .quotePanel label {
    width:80px;
    font-weight: bold;
  }

  #txtBomDescription {
    width:500px
  }

  table {
    font-size: 1em;
  }

  .quotePanel input[type=number].currencyInput{
    width: 60px;
  }

  #FilesTable {
    margin-top: 17px;
    width: 515px;
    display: block;
  }

  #FilesTable td div {
    width: 150px;
    white-space: normal;
    word-wrap: break-word;
  }

  #EditBomTable {
    width: 780px;
  }

  #EditBomTable input[type=text] {
    border: none;
    margin: 0;
    padding: 0;
  }

  #FilesSection {
    width: 700px;
    display: inline-block;
    vertical-align: top;
    padding-left: 30px;
  }

  #BomEntries {
    display: inline-block;
  }

  #BomEntriesLeftPanel {
    display: inline-block;
  }

  #EditBomTable td input[type=number] {
    border: none;
    margin: 0;
    padding: 0;
  }

  #EditBomTable input[type=button] {
    margin: 0;
    padding: 5px;
  }

  #EditBomTable td {
    margin: 0;
    padding: 5px;
  }

  #cboSalesforceCoeCodes {
    width: 150px;
    border: none;
    margin: 0;
    padding: 0;
    height: 18px;
  }
</style>
