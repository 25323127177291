<template>
  <div>
    <div
      v-if="!deleteEntityPermission"
      class="noResults"
    >
      Sorry, you do not have permission to delete users
    </div>
    <div
      v-else
      id="deleteUsersContainer"
      class="deleteSectionData"
    >
      <div class="deleteUsersHolder">
        <div v-if="showDeleteUsersSection">
          <div class="selectAllSwitch">
            <label> Select All Users </label>
            <TriStateToggleSwitch
              ref="user-toggle"
              :enabled="true"
              :current-state="userList.length === selectedUsers.length ? 1 : 2"
              :tri-state="false"
              class="tableToggleSwitch allUsersTableToggleSwitch"
              @statechanged="
                ({ selectedState }) => {
                  selectAllUsers(selectedState);
                }
              "
            />
          </div>
          <div class="mdContainer appSectionBody">
            <div style="padding: 10px">
              <table id="usersTable">
                <thead />
                <tbody>
                  <tr
                    v-for="u in currentFilter"
                    :key="u.id"
                    :tabindex="20"
                  >
                    <td>{{ u.userName }}</td>
                    <td>{{ u.firstName }}</td>
                    <td>{{ u.lastName }}</td>
                    <td>{{ u.emailAddress }}</td>
                    <td style="padding: 6px 0 6px 0">
                      <TriStateToggleSwitch
                        :enabled="true"
                        :current-state="isUserSelected(u.id)"
                        :user-id="u.id"
                        :tri-state="false"
                        class="tableToggleSwitch"
                        @statechanged="addSelectedUser"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="bottombar">
          <Spinner v-if="isDeleting" />
          <input
            v-if="!isDeleting"
            id="backButton"
            type="button"
            class="backButton"
            title="Back to support page"
            @click="closeDeleteUsersForm()"
          >
          <input
            v-if="!isDeleting"
            id="deleteUsersButton"
            :disabled="!buttonEnabled"
            :class="buttonEnabled ? 'deleteUsersButton' : 'deleteUsersButtonDisabled'"
            type="button"
            title="Delete selected users"
            @click="setDialog(true)"
          >
        </div>
      </div>
      <ConfirmationDialogBox
        :dialog="showConfirmationDialogBox"
        :dlg-message="`Are you sure want to delete ${selectedUsers.length} users?`"
        :dlg-close-from-top="false"
        dlgid="deleteUsersConfirmationDialogBox"
        dlg-icon="mdi-alert-circle-outline"
        negative-btn-text="No"
        positive-btn-text="Yes"
        @negativeAction="setDialog"
        @positiveAction="deleteSelectedUsers()"
        @cancelDialog="setDialog"
      />
    </div>
  </div>
</template>

<script>
import { usersMixin } from '../../mixins/users'
import { adminMixin } from '../../mixins/adminMixin'
import { autotaskAPIMixin } from '../../mixins/autotaskAPIMixin'
import { permissionsMixin } from '../../mixins/permissions'
import tableHandler from '../../mixins/tableHandler'
import AuthRepository from '../../communications/repositories/authRepository'
import { DataEventBus } from '../../events/dataEvents'
import Spinner from '../common/spinner'
import ConfirmationDialogBox from '../common/confirmationDialogBox'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'
import { mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'Support - Delete Users'
  },
  name: 'DeleteUsers',
  components: {
    Spinner,
    TriStateToggleSwitch,
    ConfirmationDialogBox
  },
  mixins: [
    AuthRepository,
    usersMixin,
    adminMixin,
    permissionsMixin,
    autotaskAPIMixin,
    tableHandler
  ],
  data () {
    return {
      loading: false,
      dataReturned: [],
      userList: [],
      selectedUsers: [],
      sectionComplete: false,
      showDeleteUsersSection: true,
      showConfirmationDialogBox: false,
      saveInProgress: false,
      isDeleting: false,
      formOK: false
    }
  },
  computed: {
    viewEntityPermission () {
      return this.$store.getters.viewEntityPermission
    },
    deleteEntityPermission () {
      return this.$store.getters.deleteEntityPermission
    },
    currentUserId () {
      return this.$store.getters.userid
    },
    currentUsers () {
      const users = this.$store.getters.users.filter(
        (user) => user.id !== this.currentUserId
      )
      return users
    },
    buttonEnabled () {
      return this.selectedUsers.length > 0
    }
  },
  watch: {
    reloadUsers (val) {
      if (val) {
        this.getMyUsers()
        this.setReloadUsers(false)
      }
    },
    currentUsers () {
      this.getMyUsers()
    }
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.selected = '-1'
      this.selectedUsers = []
      this.getMyUsers()
    })
  },
  created () {
    this.checkPermission()
  },
  methods: {
    ...mapActions(['setPage', 'setReloadUsers']),
    closeDeleteUsersForm () {
      this.showDeleteUsersSection = false
      this.formOK = false
      this.$emit('setTileMode')
    },
    checkPermission: async function () {
      if (this.viewEntityPermission) {
        this.getMyUsers()
        this.setPage('UserManagement')
      } else {
        this.setPage('Login')
      }
    },
    getMyUsers () {
      if (this.viewEntityPermission) {
        this.userList = []
        var userStringList = ''
        if (this.currentUsers.length > 0) {
          this.currentUsers.forEach((user) => {
            userStringList += '|' + user.id
          })
          userStringList = userStringList.substring(1)
          this.getUserList(userStringList).then((result) => {
            this.dataReturned = result
            // remove the avatar so the search results aren't affected by this un-used field
            this.dataReturned.forEach((data) => delete data.avatar)
            this.userList = this.dataReturned
            this.initTable()
          })
        } else {
          this.dataReturned = []
          this.userList = this.dataReturned
          this.initTable()
        }
      }
    },
    initTable: function () {
      this.loading = true
      this.$nextTick(() => {
        this.initialiseTable(
          'usersTable',
          this.userList,
          ['userName', 'firstName', 'lastName', 'emailAddress', 'disabled'],
          ['User Name', 'First Name', 'Last Name', 'Email Address', ''],
          ['', '', '', '', '', ''],
          null,
          ['20%', '15%', '15%', '40%', '10%'],
          20
        )
      })
      this.loading = false
    },
    addSelectedUser (selectedState) {
      if (selectedState?.userId > 0) {
        if (this.selectedUsers.includes(selectedState.userId)) {
          this.selectedUsers = this.selectedUsers.filter(
            (id) => id !== selectedState.userId
          )
        } else {
          this.selectedUsers.push(selectedState.userId)
        }
      }
    },
    selectAllUsers (selectedState) {
      if (selectedState === 1) {
        this.selectedUsers = this.userList.map((user) => user.id)
      } else {
        this.selectedUsers = []
      }
    },
    setDialog: function (value) {
      this.showConfirmationDialogBox = value
    },
    isUserSelected (userId) {
      if (this.selectedUsers.length > 0) {
        return this.selectedUsers.includes(userId) ? 1 : 2
      } else {
        return 2
      }
    },
    deleteUsersPost: async function () {
      const failedUserIds = []
      try {
        for (let i = 0; i < this.selectedUsers.length; i++) {
          const rtn = await this.removeUser(this.selectedUsers[i])
          if (rtn !== 'OK') {
            failedUserIds.push(this.selectedUsers[i])
          } else {
            // Otherwise we need to remove the user as a contact from Autotask
            var userToDelete = this.userList.filter((user) => user.id === this.selectedUsers[i])
            if (userToDelete !== null && userToDelete !== undefined && userToDelete.length > 0) {
              if (userToDelete[0].emailAddress !== null && userToDelete[0].emailAddress !== undefined && userToDelete[0].emailAddress !== '') {
                var delContact = await this.deleteAutotaskContact(userToDelete[0].emailAddress)
                if (delContact === 'error') {
                  failedUserIds.push(this.selectedUsers[i])
                }
              } else {
                console.error('Unable to find email address for user ID: ' + this.selectedUsers[i])
              }
            } else {
              console.error('Unable to find user details for user ID: ' + this.selectedUsers[i])
            }
          }
        }
      } catch (ex) {
        console.error(ex)
        this.isDeleting = false
      }
      return failedUserIds
    },
    deleteSelectedUsers: async function () {
      this.isDeleting = true
      const rtns = await this.deleteUsersPost()
      if (rtns.length > 0) {
        if (rtns.length === this.selectedUsers.length) {
          this.$store.commit('showMessage', {
            content: 'The delete users call has failed',
            color: 'red',
            timeout: 3000
          })
          this.selectedUsers = []
        } else {
          const undeletedUsers = rtns.map((userId) => {
            return this.userList.filter(u => u.id === userId)[0].userName
          })
          const totalUsers = parseInt(this.selectedUsers.length)
          const deletedUsers = totalUsers - parseInt(rtns.length)
          this.$store.commit('showMessage', {
            content: `Deleted ${deletedUsers} of ${totalUsers} users. The following users were not deleted: ${undeletedUsers.join(', ')} were not deleted.`,
            color: 'red',
            timeout: 10000
          })
          this.selectedUsers = this.selectedUsers.filter(element => rtns.includes(element))
        }
      } else {
        this.$store.commit('showMessage', {
          content: 'Successfully deleted the selected users',
          color: 'blue',
          timeout: 3000
        })
        this.selectedUsers = []
      }
      this.setReloadUsers(true)
      this.getMyUsers()
      this.isDeleting = false
      this.setDialog(false)
    }
  }
}
</script>
<style scoped>
.selectAllSwitch {
  display: flex;
  padding-right: 10px;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
}
.selectAllSwitch label {
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
}
.deleteUsersHolder {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
}
.bottombar {
  display: flex;
  justify-content: space-between;
  text-align: right;
  margin-bottom: 40px;
  padding: 10px;
}
.tableToggleSwitch {
  float: right;
  margin-right: 10px;
  min-width: 60px;
}
.deleteUsersButton {
  background-image: url('../../assets/icons/trashHover.svg');
  width: 20px;
  height: 20px;
  border: none !important;
  cursor: pointer;
}
.deleteUsersButtonDisabled {
  background-image: url('../../assets/icons/trash.svg');
  width: 20px;
  height: 20px;
  border: none !important;
  cursor: default;
}
</style>
