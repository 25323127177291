<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconPhoneLinePlus"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="phoneLinePlusComponent.quote.quoteOpportunityId + ' (revision ' + phoneLinePlusComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>PhoneLine+</h3>
      <br>
      <h4>Line Requirements</h4>
      <label
        :class="{InputError:!isValidGammaPhoneLineNumRequired}"
        class=""
        for="txtGammaPhoneLineNumRequired"
      >Gamma Phoneline+</label>
      <input
        id="txtGammaPhoneLineNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.gammaPhoneLineNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        class=""
        for="chkDiscount"
      >Discount<TooltipIcon
        text="<ul><li>1 Year discount is based on 2 months free use.  The 2 months free are spread across the term and results in a reduced cost of £3.33</li><li>3 Year discount is based on 8 months free use.  The 8 months free are spread across the term and results in a reduced cost of £3.10</li></ul>" />
      </label>
      <input
        id="chkNumberPortingRequired"
        v-model="phoneLinePlusComponent.phoneLinePlus.discount"
        type="checkbox"
        class="small"
      >
      <label
        class=""
        for="chkNumberPortingRequired"
      >Number Porting Required<TooltipIcon
        text="Porting uses pricing for Single line only" />
      </label>
      <input
        id="chkNumberPortingRequired"
        v-model="phoneLinePlusComponent.phoneLinePlus.numberPortingRequired"
        type="checkbox"
        class="small"
      >
      <label
        class=""
        for="chkHandsetInstallationRequired"
      >Handset Installation Required
        <TooltipIcon
          text="Does the customer require handset installation?<br>Pre-reqs:<ul><li>Do they have LAN POE?</li><li>Have they ordered power supplies if no POE?</li><li>Have they enough plug sockets?</li></ul>" />
      </label>
      <input
        id="chkHandsetInstallationRequired"
        v-model="phoneLinePlusComponent.phoneLinePlus.handsetInstallationRequired"
        type="checkbox"
        class="small"
      >
      <label
        v-if="phoneLinePlusComponent.phoneLinePlus.handsetInstallationRequired"
        :class="{InputError:!isValidNumSites}"
        class=""
        for="txtNumSites"
      >Number of Sites<TooltipIcon
        text="How many sites require handset installation" /></label>
      <input
        v-if="phoneLinePlusComponent.phoneLinePlus.handsetInstallationRequired"
        id="txtNumSites"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.numSites"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        v-if="phoneLinePlusComponent.phoneLinePlus.numberPortingRequired"
        class="indent"
        for="cboOohPortings"
      >Number Porting - Out of Hours Charges<TooltipIcon
        text="Number porting is carried out during working hours on weekdays by default.  A supplement applies if you want porting Weekday Evenings - £125; Saturdays - £250.  State requirement" /></label>
      <select
        v-if="phoneLinePlusComponent.phoneLinePlus.numberPortingRequired"
        id="cboOohPortings"
        v-model="phoneLinePlusComponent.phoneLinePlus.outOfHoursPortingTypeId"
      >
        <option
          v-for="o in oohPortingsStore"
          :key="o.id"
          :value="o.id"
          :title="o.name"
        >{{ o.name }}</option>
      </select>
      <br>
      <br>
      <h4>Handset and Headset Requirements</h4>
      <label
        :class="{InputError:!isValidAtaUnitsNumRequired}"
        class=""
        for="txtAtaUnitsNumRequired"
      >ATA units required<TooltipIcon
        text="Includes Cisco ATA plus power supply" /></label>
      <input
        id="txtAtaUnitsNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.ataUnitsNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidPlantronicsBlackwire5220USBNumRequired}"
        class=""
        for="txtplantronicsBlackwire5220USBNumRequired"
      >Plantronics Blackwire 5220 USB</label>
      <input
        id="txtplantronicsBlackwire5220USBNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.plantronicsBlackwire5220USBNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidPolyVoyager5200BluetoothHeadsetNumRequired}"
        class=""
        for="txtPolyVoyager5200BluetoothHeadsetNumRequired"
      >Poly Voyager 5200 Bluetooth Headset</label>
      <input
        id="txtPolyVoyager5200BluetoothHeadsetNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.polyVoyager5200BluetoothHeadsetNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidSennheiserSC165USBNumRequired}"
        class=""
        for="txtSennheiserSC165USBNumRequired"
      >Sennheiser SC 165 USB</label>
      <input
        id="txtSennheiserSC165USBNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.sennheiserSC165USBNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidPolycomVVX150NumRequired}"
        class=""
        for="txtPolycomVVX150NumRequired"
      >Polycom VVX 150</label>
      <input
        id="txtPolycomVVX150NumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.polycomVVX150NumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidPolycomVVX250NumRequired}"
        class=""
        for="txtPolycomVVX250NumRequired"
      >Polycom VVX 250</label>
      <input
        id="txtPolycomVVX250NumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.polycomVVX250NumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidPolycomVVX450NumRequired}"
        class=""
        for="txtPolycomVVX450NumRequired"
      >Polycom VVX 450</label>
      <input
        id="txtPolycomVVX450NumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.polycomVVX450NumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidPolycomVVXx50PowerSupplyNumRequired}"
        class=""
        for="txtPolycomVVXx50PowerSupplyNumRequired"
      >Polycom VVX x50 Power Supply<TooltipIcon
        text="Add power supplies for all Polycom VVX sets required" /></label>
      <input
        id="txtPolycomVVXx50PowerSupplyNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.polycomVVXx50PowerSupplyNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidDectRepeaterNumRequired}"
        class=""
        for="txtDectRepeaterNumRequired"
      >Yealink DECT Repeater</label>
      <input
        id="txtDectRepeaterNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.dectRepeaterNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidDectYealinkW52HNumRequired}"
        class=""
        for="txtDectYealinkW52HNumRequired"
      >Yealink W52H Dect Handset</label>
      <input
        id="txtDectYealinkW52HNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.dectYealinkW52HNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidDectYealinkW52PNumRequired}"
        class=""
        for="txtDectYealinkW52PNumRequired"
      >Yealink W52P Dect Handset and Base Station</label>
      <input
        id="txtDectYealinkW52PNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.dectYealinkW52PNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidDectYealinkW73HNumRequired}"
        class=""
        for="txtDectYealinkW73HNumRequired"
      >Yealink W73H Dect Handset</label>
      <input
        id="txtDectYealinkW73HNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.dectYealinkW73HNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidDectYealinkW73PNumRequired}"
        class=""
        for="txtDectYealinkW73PNumRequired"
      >Yealink W73P Dect Handset and Base Station</label>
      <input
        id="txtDectYealinkW73PNumRequired"
        v-model.number="phoneLinePlusComponent.phoneLinePlus.dectYealinkW73PNumRequired"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SIPServices')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'PhoneLinePlus',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin],
  data () {
    return {
      phoneLinePlusComponent: {
        quote: {},
        phoneLinePlus: {
          gammaPhoneLineNumRequired: 0,
          numberPortingRequired: false,
          handsetInstallationRequired: false,
          outOfHoursPortingTypeId: 1, /* NONE */
          ataUnitsNumRequired: 0,
          plantronicsBlackwire5220USBNumRequired: 0,
          polyVoyager5200BluetoothHeadsetNumRequired: 0,
          sennheiserSC165USBNumRequired: 0,
          polycomVVX150NumRequired: 0,
          polycomVVX250NumRequired: 0,
          polycomVVX450NumRequired: 0,
          polycomVVXx50PowerSupplyNumRequired: 0,
          dectRepeaterNumRequired: 0,
          dectYealinkW52HNumRequired: 0,
          dectYealinkW52PNumRequired: 0,
          dectYealinkW73HNumRequired: 0,
          dectYealinkW73PNumRequired: 0,
          discount: false
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconPhoneLinePlus () {
      return require(`../../../assets/icons/phoneLinePlus.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidGammaPhoneLineNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.gammaPhoneLineNumRequired
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidAtaUnitsNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.ataUnitsNumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidPlantronicsBlackwire5220USBNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.plantronicsBlackwire5220USBNumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidPolyVoyager5200BluetoothHeadsetNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.polyVoyager5200BluetoothHeadsetNumRequired
      return (this.iQuoteFunctions_IsInteger(value) && value >= 0)
    },
    isValidSennheiserSC165USBNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.sennheiserSC165USBNumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidPolycomVVX150NumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.polycomVVX150NumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidPolycomVVX250NumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.polycomVVX250NumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidPolycomVVX450NumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.polycomVVX450NumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidPolycomVVXx50PowerSupplyNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.polycomVVXx50PowerSupplyNumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidDectRepeaterNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.dectRepeaterNumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidDectYealinkW52HNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.dectYealinkW52HNumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidDectYealinkW52PNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.dectYealinkW52PNumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidDectYealinkW73HNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.dectYealinkW73HNumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidDectYealinkW73PNumRequired () {
      var value = this.iQuoteStore_GetPhoneLinePlus.dectYealinkW73PNumRequired
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumSites () {
      var value = this.iQuoteStore_GetPhoneLinePlus.numSites
      return (this.iQuoteFunctions_IsInteger(value) && value > 0)
    },
    oohPortingsStore () {
      return this.$store.getters.oohPortings
    }
  },
  async mounted () {
    this.phoneLinePlusComponent.quote = this.$store.getters.quote
    this.phoneLinePlusComponent.phoneLinePlus = this.iQuoteStore_GetPhoneLinePlus

    if (this.iQuoteStore_GetPhoneLinePlus.id === undefined) {
      this.iQuoteStore_GetPhoneLinePlus.id = await this.getQuoteTechnologyId(this.phoneLinePlusComponent.quote.id, this.iQuoteConstants.technologies.phoneLinePlus)

      var phoneLinePlusFromDb = await this.getQuotePhoneLinePlusProperties(this.phoneLinePlusComponent.quote.id)

      if (phoneLinePlusFromDb.outOfHoursPortingTypeId === undefined) {
        // Not in Db
        this.phoneLinePlusComponent.phoneLinePlus = {
          id: 0,
          quoteId: this.quote.id,
          gammaPhoneLineNumRequired: 0,
          numberPortingRequired: false,
          handsetInstallationRequired: false,
          numSites: 1,
          outOfHoursPortingTypeId: 1, /* NONE */
          ataUnitsNumRequired: 0,
          plantronicsBlackwire5220USBNumRequired: 0,
          polyVoyager5200BluetoothHeadsetNumRequired: 0,
          sennheiserSC165USBNumRequired: 0,
          polycomVVX150NumRequired: 0,
          polycomVVX250NumRequired: 0,
          polycomVVX450NumRequired: 0,
          polycomVVXx50PowerSupplyNumRequired: 0,
          dectRepeaterNumRequired: 0,
          dectYealinkW52HNumRequired: 0,
          dectYealinkW52PNumRequired: 0,
          dectYealinkW73HNumRequired: 0,
          dectYealinkW73PNumRequired: 0,
          discount: false
        }
      } else {
        this.phoneLinePlusComponent.phoneLinePlus = phoneLinePlusFromDb
      }

      this.setQuotePhoneLinePlus(this.phoneLinePlusComponent.phoneLinePlus)

      this.phoneLinePlusComponent.phoneLinePlus = this.iQuoteStore_GetPhoneLinePlus
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuotePhoneLinePlus'
    ]),
    async gotoPage (page) {
      var technologyId = await this.getQuoteTechnologyId(this.phoneLinePlusComponent.quote.id, this.iQuoteConstants.technologies.phoneLinePlus)

      if (this.iQuoteStore_GetPhoneLinePlus.overrideSIPChannels === 0 && this.iQuoteStore_GetPhoneLinePlus.sipConfigured === 0 && technologyId >= 1) {
      // SIP not required so delete from DB
        this.deleteQuoteTechnology(technologyId)
        // Clear form details from store
        this.setQuotePhoneLinePlus({})
      } else if (!(technologyId >= 1)) {
        // It's not in the db, so clear details
        this.setQuotePhoneLinePlus({})
      } else if (page === 'SIPServices') {
        // Going back, so reset to what it was in db
        var phoneLinePlusFromDb = await this.getQuotePhoneLinePlusProperties(this.phoneLinePlusComponent.quote.id)
        this.setQuotePhoneLinePlus(phoneLinePlusFromDb)
      }
      this.setCurrentQuoteState(page)
    },
    async finishService () {
      var ValidationFailed = false
      if (!this.isValidGammaPhoneLineNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidAtaUnitsNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidPlantronicsBlackwire5220USBNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidPolyVoyager5200BluetoothHeadsetNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidSennheiserSC165USBNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidPolycomVVX150NumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidPolycomVVX250NumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidPolycomVVX450NumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidDectRepeaterNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidDectYealinkW52HNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidDectYealinkW52PNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidDectYealinkW73HNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidDectYealinkW73PNumRequired) {
        ValidationFailed = true
      }
      if (!this.isValidPolycomVVXx50PowerSupplyNumRequired) {
        ValidationFailed = true
      }

      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied
        if (this.iQuoteStore_GetPhoneLinePlus.id === 0) {
          await this.addQuoteTechnologyId(this.phoneLinePlusComponent.quote.id, this.iQuoteConstants.technologies.phoneLinePlus)
        }

        this.phoneLinePlusComponent.phoneLinePlus = await this.saveQuotePhoneLinePlusProperties(this.phoneLinePlusComponent.phoneLinePlus)
        this.setQuotePhoneLinePlus(this.phoneLinePlusComponent.phoneLinePlus)
        this.gotoPage('SelectService')
      } else {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
      }
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 620px}
  .quotePanel label {width:420px;}
  .small {width:60px;}
  .hidden {visibility: hidden;}
  .numericCombobox {width: 60px}
  .indent {padding-left:30px}
</style>
