<template>
  <div class="createCommercialContainer">
    <h2>Commercials</h2>
  </div>
</template>

<script>
export default {
  name: 'Commercial',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style scoped>

</style>
