import autotaskAPI from '../communications/interceptors/apiInterceptor'
import { mapActions } from 'vuex'
export const autotaskAPIMixin = {
  data () {
    return {
      ticketGetCount: 0,
      alreadyGettingTickets: false,
      archivedTickets: []
    }
  },
  methods: {
    ...mapActions([
      'setTickets',
      'setTempTickets'
    ]),
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
    },
    getReferenceDataList: async function (rname) {
      if (!rname) {
        return
      }
      var rtn = []
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/referencedata/${rname}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getReferenceDataList with rname: ' + rname)
      }
      return rtn
    },
    getAutotaskContactByEmail: async function (email) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/contacts/email/${email}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskContact with email: ' + email)
      }
      return rtn
    },
    getAutotaskContactById: async function (id) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/contacts/${id}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskContactById with id: ' + id)
      }
      return rtn
    },
    getAutotaskResourceById: async function (id) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/resources/${id}`, axiosConfig).then((response) => {
          rtn = response.data
          console.log(rtn)
        })
      } catch (e) {
        console.log('Error calling getAutotaskResourceById with id: ' + id)
      }
      return rtn
    },
    getAutotaskContactList: async function (siteIds) {
      var rtn = []
      var axiosConfig = this.getHeaders()
      var payload = '{"siteList":"' + siteIds + '"}'
      try {
        await autotaskAPI.post(`/autotaskapi/contacts/sitelist`, payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskContactList with ids: ' + siteIds)
      }
      return rtn
    },
    addNewTicket: async function (newTicket) {
      var data = 'error'
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.post('/autotaskapi/tickets', JSON.stringify(newTicket), axiosConfig).then((reponse) => {
          var rtn = reponse.data
          data = rtn
        })
      } catch (error) {
        console.log('Error calling addNewTicket with ticket payload: ' + JSON.stringify(newTicket))
      }
      return data
    },
    updateTicketStatus: async function (ticketId, statusId) {
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.put('/autotaskapi/tickets/status/' + ticketId, {
          status: statusId
        }, axiosConfig)
      } catch (error) {
        console.log('Error calling updateTicketStatus with ticketid: ' + ticketId + ' and status ' + statusId)
      }
    },
    addAutotaskAPIContact: async function (newContact) {
      var data = 'error'
      var axiosConfig = this.getHeaders()
      try {
        var url = '/autotaskapi/contacts'

        console.log(JSON.stringify(newContact))
        await autotaskAPI.post(url, JSON.stringify(newContact), axiosConfig).then((reponse) => {
          var rtn = reponse.data
          data = rtn
        })
      } catch (error) {
        console.log('Error calling addAutotaskAPIContact with contact payload: ' + JSON.stringify(newContact))
      }
      return data
    },
    deleteAutotaskContact: async function (emailAddress) {
      var data = 'error'
      var axiosConfig = this.getHeaders()
      try {
        var url = '/autotaskapi/contacts/' + emailAddress
        await autotaskAPI.delete(url, axiosConfig).then((reponse) => {
          var rtn = reponse.data
          data = rtn
        })
      } catch (error) {
        console.log('Error calling deleteContact with email address: ' + emailAddress)
      }
      return data
    },
    getAutotaskEntityFields: async function (ename) {
      if (!ename) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/referencedata/${ename}/fields`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskEntityFields with entity: ' + ename)
      }
      return rtn
    },
    getAutotaskEntityCustomFields: async function (ename) {
      if (!ename) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/referencedata/${ename}/customfields`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskEntityCustomFields with entity: ' + ename)
      }
      return rtn
    },
    getTicketAttachments: async function (id) {
      if (!id) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/tickets/attachments/${id}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getTicketAttachments with id: ' + id)
        rtn = 'Error: Unable to retrieve the ticket attachments'
      }
      return rtn
    },
    getAutotaskConfigItemList: async function (siteIds) {
      var rtn = []
      var axiosConfig = this.getHeaders()
      var payload = '{"siteList":"' + siteIds + '"}'
      try {
        await autotaskAPI.post(`/autotaskapi/configurationItems/sitelist`, payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskConfigItemList with ids: ' + siteIds)
      }
      return rtn
    },
    getAutotaskConfigItemsByTypeAndSite: async function (typeId, siteId) {
      var rtn = []
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/configurationItems/${typeId}/${siteId}`, '', axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskConfigItemsByTypeAndSite with typeId: ' + typeId + ' and siteId: ' + siteId)
      }
      return rtn
    },
    addTicketNote: async function (id, note) {
      if (!note) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.put(`/autotaskapi/tickets/notes/${id}`, note, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling addTicketNote with ticket id: ' + id + ' and note: ' + note)
        rtn = 'error'
      }
      return rtn
    },
    addTicketAttachment: async function (id, attachment) {
      if (!attachment) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.put(`/autotaskapi/tickets/attachments/${id}`, attachment, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling addTicketAttachment with ticket id: ' + id + ' and attachment: ' + attachment)
        rtn = 'error'
      }
      return rtn
    },
    getAttachmentData: async function (ticketid, attachmentId) {
      if (!ticketid || !attachmentId) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/tickets/attachmentdata/${ticketid}/${attachmentId}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAttachmentData with ticket id: ' + ticketid + ' and attachmentId: ' + attachmentId)
      }
      return rtn
    },
    getResourceById: async function (id) {
      if (!id) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/resources/${id}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getResourceById with id: ' + id)
      }
      return rtn
    },
    getAutotaskResourcesByResourceList: async function (resourceList) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      var payload = '{"resourceList":"' + resourceList + '"}'
      try {
        await autotaskAPI.post(`/autotaskapi/resources/resourceList`, payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskResourcesByResourceList with ids: ' + resourceList)
      }
      return rtn
    },
    getTicketById: async function (id) {
      if (!id) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/tickets/id/${id}`, axiosConfig).then((response) => {
          rtn = response.data[0]
        })
      } catch (e) {
        console.log('Error calling getTicketById with id: ' + id)
      }
      return rtn
    },
    getAutotaskProductList: async function (productIds) {
      var rtn = []
      var axiosConfig = this.getHeaders()
      var payload = '{"productList":"' + productIds + '"}'
      try {
        await autotaskAPI.post(`/autotaskapi/products/get`, payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskProductList with ids: ' + productIds)
      }
      return rtn
    },
    getConfigurationItemCount: async function (siteid, typeid) {
      var rtn = []
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/configurationitems/typecount/` + siteid + '/' + typeid, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getConfigurationItemCount with siteid: ' + siteid + ' and typeid: ' + typeid)
      }
      return rtn
    },
    getSpecificTicketNote: async function (id) {
      if (!id) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskAPI.get(`/autotaskapi/ticketnotes/${id}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getSpecificTicketNote with id: ' + id)
      }
      return rtn
    }
  }
}
