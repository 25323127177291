<template>
  <div>
    <div class="divTop">
      <div class="entriesPanel">
        <div>
          <div class="divTitle">
            <label
              v-if="adminHome.entriesFound"
              class="labelTitle"
            >Activity in the past 7 days</label>
          </div>
          <label
            v-if="!adminHome.entriesFound"
            class="entryNotFound"
          >No entries found</label>
          <div class="mdContainer appSectionBody">
            <div
              :class="{mdHalfScreen : false}"
              class="mdQuotes">
              <div
                :class="{hidden: adminHome.auditEntries.length === 0}">
                <table
                  id="auditTable">
                  <thead />
                  <tbody>
                    <tr
                      v-for="entry in currentFilter"
                      :key="entry.id"
                    >
                      <td>{{ entry.auditDateTime }}</td>
                      <td>{{ entry.quoteDesignPackId }}</td>
                      <td>{{ entry.userName }}</td>
                      <td>{{ entry.firstName }}</td>
                      <td>{{ entry.lastName }}</td>
                      <td>{{ entry.revisionNumber }}</td>
                      <td>{{ entry.action === 'DP duplicated' ? `Duplicate from ${entry.duplicateId}` : entry.action }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chartAndFilterPanel">
        <div class="divFilter">
          <label
            for="cboFilter"
            class="labelFilter"
          >Filter:</label>
          <select
            id="cboFilter"
            v-model="adminHome.filter"
            class="cboFilter"
            @change="showDPAuditsAndClearTimer()"
          >
            <option
              key="0"
              value="All"
            >All
            </option>
            <option
              key="1"
              value="Created-DP-from-quote"
            >From quote
            </option>
            <option
              key="2"
              value="Created-standalone-DP"
            >Standalone
            </option>
            <option
              key="3"
              value="Created-DP-from-Excel"
            >From Excel
            </option>
            <option
              key="4"
              value="Revision-created"
            >New revisions
            </option>
            <option
              key="5"
              value="DP-duplicated"
            >Duplicated
            </option>
          </select>
        </div>
        <div class="divChart">
          <material-chart-card
            id="DPChart"
            :data="chartData.data"
            :options="chartOptions"
            :responsive-options="chartResponsiveOptions"
            color="info"
            type="Bar"
          >
            <div
              v-if="!chartData.data.series[0].some(d => d !== 0)"
              class="noDataBanner"
            > No Data Available
            </div>
            <div class="divCenter">
              <span class="chartTitle">DPs raised over the last 7 days:&nbsp;&nbsp;</span>
              <span class="chartTitleTotal">{{ adminHome.auditEntries.length }}</span>
            </div>
            <div class="divCenter">
              <span class="caption grey--text font-weight-light">Last updated: {{ lastUpdatedDate }}</span>
            </div>
          </material-chart-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tableHandler from '../../../mixins/tableHandler'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'

export default {
  name: 'AdminHome',
  mixins: [ tableHandler, iQuoteQuoteDesignPacksAPIMixin ],
  data () {
    return {
      timer: 0,
      lastUpdatedDate: null,
      chartOptions: {
        axisX: {
          showGrid: false
        },
        low: 0,
        chartPadding: {
          left: -10
        }
      },
      chartResponsiveOptions: [
        ['screen and (max-width: 340px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0]
            }
          }
        }]
      ],
      chartData: {
        data: {
          labels: ['From quote', 'Standalone', 'From Excel', 'New revisions', 'Duplicated'],
          series: [ [0, 0, 0, 0, 0] ]
        }
      },
      adminHome: {
        auditEntries: [],
        entriesFound: true,
        filter: 'All'
      }
    }
  },
  computed: {
    from_quote () {
      return this.adminHome.auditEntries.filter(entry => entry.action === 'Created DP from quote').length
    },
    standalone () {
      return this.adminHome.auditEntries.filter(entry => entry.action === 'Created standalone DP').length
    },
    fromExcel () {
      return this.adminHome.auditEntries.filter(entry => entry.action === 'Created DP from Excel').length
    },
    newRevisions () {
      return this.adminHome.auditEntries.filter(entry => entry.action === 'Revision created').length
    },
    duplicated () {
      return this.adminHome.auditEntries.filter(entry => entry.action === 'DP duplicated').length
    }
  },
  mounted () {
    this.showDPAuditsAndClearTimer()
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    showDPAuditsAndClearTimer () {
      this.showDPAudits()
      this.timer = this.setTimer()
    },
    async showDPAudits () {
      await this.fetchDataFromDB()
      this.setTable()
      this.setChart()
      this.lastUpdatedDate = new Date().toLocaleTimeString('en-gb')
    },
    async fetchDataFromDB () {
      const action = this.adminHome.filter
      const entries = (await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackLast7DaysUserAuditsFromDb(action)).designPackUserAudits
      this.adminHome.auditEntries = []
      if (entries.length > 0) {
        entries.forEach(entry => {
          if (entry.action !== 'Viewed' && entry.action !== 'Edited') {
            entry.auditDateTime = new Date(entry.auditDateTime).toLocaleString('en-gb')
            this.adminHome.auditEntries.push(entry)
          }
        })
        this.adminHome.entriesFound = true
      } else {
        this.adminHome.entriesFound = false
      }
    },
    async setTable () {
      this.initialiseTable('auditTable', this.adminHome.auditEntries,
        ['auditDateTime', 'quoteDesignPackId', 'userName', 'firstName', 'lastName', 'revisionNumber', 'action'],
        ['AuditDateTime', 'DPNumber', 'UserName', 'FirstName', 'LastName', 'Revision', 'Action'],
        null,
        '494',
        ['80px', '65px', '70px', '70px', '70px', '54px', '85px'],
        19, // entries per page
        true,
        false,
        true) // pagination at top
    },
    async setChart () {
      let series = [[]]
      let labels = []

      switch (this.adminHome.filter) {
        case 'All':
          series = [[this.from_quote, this.standalone, this.fromExcel, this.newRevisions, this.duplicated]]
          labels = [`From quote\n(${series[0][0]})`, `Standalone\n(${series[0][1]})`, `From Excel\n(${series[0][2]})`, `New revisions\n(${series[0][3]})`, `Duplicated\n(${series[0][4]})`]
          break
        case 'Created-DP-from-quote':
          series = [[this.from_quote]]
          labels = [`From quote\n(${series[0][0]})`]
          break
        case 'Created-standalone-DP':
          series = [[this.standalone]]
          labels = [`Standalone\n(${series[0][0]})`]
          break
        case 'Created-DP-from-Excel':
          series = [[this.fromExcel]]
          labels = [`From Excel\n(${series[0][0]})`]
          break
        case 'Revision-created':
          series = [[this.newRevisions]]
          labels = [`New revisions\n(${series[0][0]})`]
          break
        case 'DP-duplicated':
          series = [[this.duplicated]]
          labels = [`Duplicated\n(${series[0][0]})`]
          break
      }
      this.chartData.data.labels = labels
      this.chartData.data.series = series

      let max = 0
      series[0].forEach(value => { max = (value > max ? value : max) })
      this.chartOptions.high = max * 1.20
    },
    setTimer () {
      clearInterval(this.timer)
      return setInterval(async () => {
        await this.showDPAudits()
      }, 30000)
    }
  }
}
</script>
<style scoped>
  label {
    font-size: large;
    margin-right: 10px;
  }
 .mdContainer{
    display: flex;
    flex-direction: row;
  }
  .mdQuotes{
    margin: 4px 0 0 0;
    width:100%;
    min-height: calc(100vh - 300px);
  }
  .mdQuotes.mdHalfScreen{
    width:calc(100% - 600px);
    min-height: calc(100vh - 50px);
  }
  #auditTable {
    text-align:left;
    margin-bottom: 40px;
  }
  .hidden {
    display: none
  }
  .entryNotFound {
    font-weight: 500;
  }
  .divTitle {
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: larger;
    margin-top: 5px;
  }
  .labelTitle {
    font-size: medium;
    margin-bottom: 10px;
  }
  .divTop {
    display: flex;
    justify-content: space-between;
  }
  .entriesPanel {
    display: flex;
    justify-content: space-between;
    width: 64%;
    margin-top: 0.5%;
  }
  .chartAndFilterPanel {
    margin-left: 1%;
    width: 36%;
  }
  .divFilter {
    margin-top: 1%;
    margin-left: 3%;
  }
  .labelFilter {
    font-weight: 400;
  }
  .divChart {
    margin-top: 9%;
  }
  .noDataBanner {
    position: absolute;
    top: 50px;
    left: calc(100% - 65%);
    font-weight: 400;
    color: #fff;
  }
  .divCenter {
    display: flex;
    justify-content: center;
  }
  .chartTitle {
    font-weight: 300 !important;
  }
  .chartTitleTotal {
    font-weight: 600 !important;
  }
  .cboFilter {
    border: 1px solid rgb(208, 208, 208);
    width: 170px;
    height: 40px;
    padding: 5px;
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
}
select:focus
{
  border: 1px solid rgb(208, 208, 208);
}

</style>
