import axios from '../interceptors/apiInterceptor'
import { mapActions } from 'vuex'
export const sitesAndCircuitsRepository = {
  methods: {
    ...mapActions([
      'setSites'
    ]),
    sitesGet (uid) {
      if (uid !== undefined) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        return axios
          .get('/Site/' + uid, axiosConfig)
          .then(res => {
            this.setSites(res.data)
            return res.data
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log("error getting sites", err)
            return []
          })
      } else {
        return []
      }
    },
    sitesGetById (id) {
      if (id) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        return axios
          .get('/site/id/' + id, axiosConfig)
          .then(res => {
            return res.data
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log("error getting sites", err)
            return []
          })
      } else {
        return []
      }
    },
    sitesClear () {
      this.setSites(null)
    }
  }
}
