import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteCalculationsDesignPackMixin } from './iQuoteCalculationsDesignPack'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { mapActions } from 'vuex'

export const iQuoteCalculationsDesignPackCircuitsMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin,
    iQuoteFunctions,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackMixin
  ],
  data () {
    return {
      iQuoteCalculationsDesignPackCircuitsMixin: {
        circuitsEquinixCloudConnectEntries: [],
        circuitsWanConnectivityEntries: [],
        circuitsHostingAndCablingEntries: [],
        circuitsBroadbandEntries: [],
        circuitsFixedLineVoiceEntries: [],
        equinixCircuitTypes: [],
        colocationAndAEnds: [
          { id: 1, description: 'Telehouse North', postcode: 'E14 2AA' },
          { id: 2, description: 'Equinix Harbour Exchange', postcode: 'E14 9GE' },
          { id: 3, description: 'Fareham', postcode: 'PO15 5TX' },
          { id: 4, description: 'Goswell Road', postcode: 'EC1V 7EB' },
          { id: 5, description: 'INTERNET', postcode: 'N/A' },
          { id: 6, description: 'IP VPN', postcode: 'N/A' }
        ]
      }
    }
  },
  computed: {
    iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack () {
      return this.iQuoteStore_GetDesignPack
    }
  },
  async created () {
  },
  async mounted () {
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    async iQuoteCalculationsDesignPackCircuitsMixin_updateEquinixCloudConnectEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.circuitsEquinixCloudConnectEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackCircuitsMixin_updateEquinixCloudConnectEntries')
    },
    async iQuoteCalculationsDesignPackCircuitsMixin_updateWanConnectivityEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.circuitsWanConnectivityEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackCircuitsMixin_updateWanConnectivityEntries')
    },
    async iQuoteCalculationsDesignPackCircuitsMixin_updateHostingAndCablingEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.circuitsHostingAndCablingEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackCircuitsMixin_updateHostingAndCablingEntries')
    },
    async iQuoteCalculationsDesignPackCircuitsMixin_updateBroadbandEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.circuitsBroadbandEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackCircuitsMixin_updateBroadbandEntries')
    },
    async iQuoteCalculationsDesignPackCircuitsMixin_updateFixedLineVoiceEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.circuitsFixedLineVoiceEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackCircuitsMixin_updateFixedLineVoiceEntries')
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getHostingAndCablingProductUpfrontCost (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.hostingAndCablingProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].upfrontCost
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getHostingAndCablingProductRecurringCost (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.hostingAndCablingProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].recurringCost
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductUpfrontCost (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].upfrontCost
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductRecurringCost (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].recurringCost
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductAtCode (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].atCode
      } else {
        return ''
      }
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductTermLengthMonths (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].termLengthMonths
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getFixedLineVoiceProductUpfrontCost (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.fixedLineVoiceProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].upfrontCost
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getFixedLineVoiceProductRecurringCost (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.fixedLineVoiceProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].recurringCost
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getFixedLineVoiceProductAtCode (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.fixedLineVoiceProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].atCode
      } else {
        return ''
      }
    },
    iQuoteCalculationsDesignPackCircuitsMixin_getFixedLineVoiceProductTermLengthMonths (id) {
      var matches = this.iQuoteStore_GetCircuitsTableLookups.fixedLineVoiceProducts.filter(x => x.id === id)

      if (matches.length > 0) {
        return matches[0].termLengthMonths
      } else {
        return 0
      }
    }
  }
}
