<template>
  <div class="quotePanelContainer">
    <div class="quotePanel" >
      <label for="txtiQuoteReference">iQuote</label>
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <p>Select required Service&nbsp;
      </p>
      <br>
      <ServiceTile
        v-for="s in services.filter(x => x.id !== iQuoteConstants.services.callmediaCXNow)"
        :service-id="s.id"
        :service-name="s.name"
        :key="s.id"
        @removed="setForm()"
      />
      <br><br>
      <p class="InputError">{{ quoteServicesPrompt }}</p>
      <div
        v-if="iQuoteCalculationsiQuoteModuleStatsMixin_ucAnalyticsFoundationsUserCount > 0 && iQuoteStore_GetQuoteUCAnalytics.id === undefined"
        class="InputError"
        style="margin-bottom: 20px;"
      >
        UC Analytics Foundations is included as standard, offering a free customer dashboard.<br>To update level select from the UC Analytics module
      </div>
      <p
        v-if="numPrimarySipChannels < gammaSip.sipConfigured && numPrimarySipChannels > 0"
        class="InputError">Warning: Calculated required SIP channels is {{ gammaSip.sipConfigured }} but this has been overridden to {{ numPrimarySipChannels }}</p>
      <div
        v-if="selectionErrors.length > 0"
        class="InputError">Selection Errors.  Please fix:
        <ul>
          <li
            v-for="e in selectionErrors"
            :key="e">{{ e }}</li>
        </ul>
      </div>
      <br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('ChooseOpportunity')"
          >
        </div>
        <div class="rightButtons">
          <input
            v-if="servicesComplete"
            id="btnNext"
            value="Complete Quote >"
            type="button"
            @click="completeClicked()"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TooltipIcon from '../../../common/tooltipIcon'
import { iQuoteStaticDataAPIMixin } from '../../../../mixins/iQuote/iQuoteStaticDataAPIMixin'
import { iQuoteConstants } from '../../../../mixins/iQuote/iQuoteConstants'
import { iQuoteStoreMixin } from '../../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteCalculationsCommonMixin } from '../../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsiQuoteModuleStatsMixin } from '../../../../mixins/iQuote/Calculations/iQuoteCalculationsiQuoteModuleStatsMixin'
import ServiceTile from '../../tiles/iQuoteServiceTile'

export default {
  name: 'SelectService',
  components: {
    ServiceTile,
    TooltipIcon
  },
  mixins: [iQuoteStaticDataAPIMixin, iQuoteConstants, iQuoteCalculationsCommonMixin, iQuoteStoreMixin, iQuoteQuoteTechnologyAPIMixin, iQuoteCalculationsiQuoteModuleStatsMixin],
  data () {
    return {
      services: [],
      servicesComplete: false,
      quote: {},
      quoteServicesPrompt: '',
      gammaSip: {}
    }
  },
  computed: {
    question () {
      return require(`../../../../assets/icons/question.png`)
    },
    toiQuotePrevious () {
      return require(`../../../../assets/icons/toiQuotePrev.png`)
    },
    completeQuote () {
      return require(`../../../../assets/icons/completeQuote.png`)
    },
    iconCommunciateSelected () {
      return this.numCommunicateSolutions > 0
    },
    iconContactSelected () {
      return this.numContactCentreSolutions > 0
    },
    sipSelected () {
      var quoteGammaSIP = this.$store.getters.quoteGammaSIP

      return quoteGammaSIP.id !== undefined
    },
    callmediaCXNowSelected () {
      var quoteTechnologyCallmediaCXNow = this.$store.getters.quoteTechnologyCallmediaCXNow

      return quoteTechnologyCallmediaCXNow.id !== undefined
    },
    iconGatewaySelected () {
      var quoteTechnologyIconGateway = this.$store.getters.quoteTechnologyIconGateway

      return quoteTechnologyIconGateway.id !== undefined
    },
    expansionsSelected () {
      var quoteExpansions = this.$store.getters.quoteExpansions
      return quoteExpansions.id !== undefined
    },
    numCommunicateSolutions () {
      var count = 0

      var quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
      if (quoteTechnologyAvayaAura.id !== undefined) {
        count++
      }

      var quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
      if (quoteTechnologyMitelUC.id !== undefined) {
        count++
      }

      return count
    },
    numContactCentreSolutions () {
      var count = 0

      var quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite
      if (quoteTechnologyAvayaElite.id !== undefined) {
        count++
      }

      var quoteTechnologyMitelCC = this.$store.getters.quoteTechnologyMitelCC
      if (quoteTechnologyMitelCC.id !== undefined) {
        count++
      }

      var quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC
      if (quoteTechnologyAvayaAACC.id !== undefined) {
        count++
      }

      var quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia
      if (quoteTechnologyCallmedia.id !== undefined) {
        count++
      }

      return count
    },
    selectionErrors () {
      var result = []

      var quote = this.$store.getters.quote
      var quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
      var quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
      var quoteTechnologyMitelCC = this.$store.getters.quoteTechnologyMitelCC
      var quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite
      var quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC
      var quoteTechnologyCallmediaCXNow = this.$store.getters.quoteTechnologyCallmediaCXNow
      var quoteGammaSIP = this.$store.getters.quoteGammaSIP
      var quoteGenesys = this.$store.getters.quoteGenesys

      if (this.numCommunicateSolutions > 1) {
        result.push('You have tried to add multiple ICON Communicate solutions.  Please only select 1')
      }

      if (this.numContactCentreSolutions > 1) {
        result.push('You have tried to add multiple ICON Contact solutions.  Please only select 1')
      }

      if (quoteTechnologyAvayaAura.id !== undefined) {
        if (quoteTechnologyMitelCC.id !== undefined) {
          result.push('ICON Communicate with Avaya Aura cannot be used with Mitel Contact Centre')
        }
        if (this.quoteTechnologyAvayaAACC.id !== undefined) {
          if (this.quoteTechnologyAvayaAura.adjuncts.redbox && this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls > 0) {
            result.push('You have selected both Redbox and WFO Recording Solutions.  Please choose only one')
          }
        }
        if (this.quoteTechnologyAvayaElite.id !== undefined) {
          if (this.quoteTechnologyAvayaAura.adjuncts.redbox && this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls > 0) {
            result.push('You have selected both Redbox and WFO Recording Solutions.  Please choose only one')
          }
        }
      }

      if (!this.sipSelected && this.iconCommunciateSelected) {
        result.push('ICON Communicate requires SIP to be configured')
      }

      if (!this.iconCommunciateSelected && this.iconContactSelected) {
        result.push('ICON Contact requires ICON Communicate to be configured')
      }

      if (quoteTechnologyMitelUC.id !== undefined) {
        if (quoteTechnologyAvayaElite.id !== undefined) {
          result.push('ICON Communicate with Mitel cannot be used with Avaya Elite')
        }
        if (quoteTechnologyAvayaAACC.id !== undefined) {
          result.push('ICON Communicate with Mitel cannot be used with Avaya AACC')
        }
        if (quote.criticalAvailability) {
          result.push('ICON Communicate with Mitel does not support Critical Availability')
        }
        if (quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
          result.push('ICON Communicate with Mitel does not support Surrender licensing')
        }
      }

      if (quoteTechnologyCallmediaCXNow.id !== undefined) {
        if (this.numCommunicateSolutions >= 1) {
          result.push('Callmedia CX Now cannot be used with ICON Communicate')
        }
        if (this.numContactCentreSolutions >= 1) {
          result.push('Callmedia CX Now cannot be used with ICON Contact')
        }
        if (this.quoteTechnologyIconGateway.id !== undefined) {
          result.push('Callmedia CX Now cannot be used with ICON Gateway')
        }
        if (quoteGenesys.id !== undefined) {
          result.push('Callmedia CX Now cannot be used with Genesys')
        }
      }

      if (quoteGenesys.id !== undefined) {
        if (this.numCommunicateSolutions >= 1) {
          result.push('Genesys cannot be used with ICON Communicate')
        }
        if (this.numContactCentreSolutions >= 1) {
          result.push('Genesys cannot be used with ICON Contact')
        }
        if (this.quoteTechnologyIconGateway.id !== undefined) {
          result.push('Genesys cannot be used with ICON Gateway')
        }
      }

      if (quoteGammaSIP.id !== undefined && (quoteGammaSIP.sipPrimaryResilienceId === 2 || quoteGammaSIP.sipPrimaryResilienceId === 2) /* No Resilience */) {
        if (quoteTechnologyAvayaAura.id !== undefined || quoteTechnologyMitelUC.id !== undefined) {
          result.push('ICON Communicate only supports Active/Standby for SIP')
        }
      }

      return result
    }
  },
  mounted () {
    this.setForm()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuote',
      'setQuoteGammaSIP'
    ]),
    gotoPage (page) {
      this.setCurrentQuoteState(page)
    },
    completeClicked () {
      if (((this.quoteTechnologyAvayaElite.id !== undefined && this.quoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls > 0) ||
          (this.quoteTechnologyAvayaAACC.id !== undefined && this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls > 0)) && this.quote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
        this.quote.quoteLicenseTypeId = this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION
        this.setQuote(this.quote)
      }

      this.gotoPage('Review')
    },
    showOrHideCompleteButton () {
      this.servicesComplete = false
      // eslint-disable-next-line
      var iconCommunicateComplete = false
      // eslint-disable-next-line
      var iconContactComplete = false
      // eslint-disable-next-line
      var iconSIPComplete = false
      // eslint-disable-next-line
      var iconGatewayComplete = false
      // eslint-disable-next-line
      var callmediaCxNowComplete = false
      // eslint-disable-next-line
      var expansionsComplete = false
      // eslint-disable-next-line
      var teamsConnectorComplete = false
      // eslint-disable-next-line
      var genesysComplete = false
      // eslint-disable-next-line
      var phoneLinePlusComplete = false
      // determine which services we have completed

      var quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
      var quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
      var quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC
      var quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite
      var quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia
      var quoteGammaSIP = this.$store.getters.quoteGammaSIP
      var quoteSIPMigrationProperties = this.$store.getters.quoteSIPMigrationProperties
      var quoteTechnologyMitelCC = this.$store.getters.quoteTechnologyMitelCC
      var quoteTechnologyCallmediaCXNow = this.$store.getters.quoteTechnologyCallmediaCXNow
      var quoteTechnologyIconGateway = this.$store.getters.quoteTechnologyIconGateway
      var quoteExpansions = this.$store.getters.quoteExpansions
      var teamsConnector = this.iQuoteStore_GetTeamsConnector
      var quotePhoneLinePlus = this.iQuoteStore_GetPhoneLinePlus
      var quoteGenesys = this.iQuoteStore_GetQuoteGenesys

      if (quoteTechnologyAvayaAura.id !== undefined || quoteTechnologyMitelUC.id !== undefined) {
        iconCommunicateComplete = true
      }

      if (teamsConnector.id !== undefined) {
        teamsConnectorComplete = true
      }

      if (quoteGenesys.id !== undefined) {
        genesysComplete = true
      }

      if (quotePhoneLinePlus.id !== undefined) {
        phoneLinePlusComplete = true
      }

      if (quoteTechnologyAvayaElite.id !== undefined || quoteTechnologyAvayaAACC.id !== undefined ||
          quoteTechnologyCallmedia.id !== undefined || quoteTechnologyMitelCC.id !== undefined) {
        iconContactComplete = true
      }

      if (quoteGammaSIP.id !== undefined || quoteSIPMigrationProperties.id !== undefined) {
        iconSIPComplete = true
      }

      if (quoteTechnologyCallmediaCXNow.id !== undefined) {
        callmediaCxNowComplete = true
      }

      if (quoteTechnologyIconGateway.id !== undefined) {
        iconGatewayComplete = true
      }

      if (quoteExpansions.id !== undefined) {
        expansionsComplete = true
      }

      this.quoteServicesPrompt = ''

      if (this.selectionErrors.length === 0) {
        this.servicesComplete = true
      }
    },
    setForm: async function () {
      this.quote = this.$store.getters.quote
      this.services = this.$store.getters.serviceList

      this.quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
      this.quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia
      this.quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC
      this.quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite
      this.quoteTechnologyMitelCC = this.$store.getters.quoteTechnologyMitelCC
      this.quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
      this.quoteTechnologyCallmediaCXNow = this.$store.getters.quoteTechnologyCallmediaCXNow
      this.quoteTechnologyIconGateway = this.$store.getters.quoteTechnologyIconGateway
      this.quotePhoneLinePlus = this.$store.getters.quotePhoneLinePlus

      if (
        (this.quoteTechnologyAvayaAura.id !== undefined ||
           this.quoteTechnologyCallmedia.id !== undefined ||
           this.quoteTechnologyAvayaAACC.id !== undefined ||
           this.quoteTechnologyAvayaElite.id !== undefined ||
           this.quoteTechnologyMitelCC.id !== undefined ||
           this.quoteTechnologyMitelUC.id !== undefined
        ) &&
          this.quoteTechnologyCallmediaCXNow.id === undefined) {
        this.calculationsCommon_calcNumSipChannelsRequired()
        this.gammaSip = this.$store.getters.quoteGammaSIP
        this.gammaSip = await this.saveQuoteSipProperties(this.gammaSip)
        this.setQuoteGammaSIP(this.gammaSip)
      }

      this.showOrHideCompleteButton()

      if (this.calculationsCommonMixin_allowedTermLengths.find(x => x === this.quote.termLengthMonths) === undefined) {
        this.iQuoteFunctions_ShowError('Quote was set to term length of ' + this.quote.termLengthMonths + ', which is invalid with the selected services.  It has now been changed to 36 months')
        this.quote.termLengthMonths = 36
      }
    }
  }
}
</script>
<style scoped>
.completeButton{
  width: 96px;
  margin-left: 300px;
  cursor: pointer;
}
.quotePanel{
    width: 700px;
}
</style>
