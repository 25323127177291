<template>
  <div
    :id="id"
    class="companyHolder"
  >
    <Spinner v-if="loading" />
    <div
      v-else
    >
      <label
        for="txtUpdateCompanyName"
        class="companyDetailLabel"
      >Company Name:</label>
      <input
        v-if="editCompanyPermission"
        id="txtUpdateCompanyName"
        :class="{ errorHighlight: companyNameError }"
        v-model="company.name"
        class="companyDetailInput"
        type="text"
        @blur="checkForm"
      >
      <label
        v-if="!editCompanyPermission"
        id="lblUpdateCompanyName"
        class="companyDetailData"
      >{{ company.name }}</label>
      <div class="controlContainer">
        <label
          for="txtUpdateTraverseContainerId"
          class="companyDetailLabel"
        >Traverse Container ID:</label>
        <input
          v-if="editCompanyPermission"
          id="txtUpdateTraverseContainerId"
          :class="{ errorHighlight: companyNameError }"
          v-model="traverseContainerId"
          class="companyDetailInput"
          type="text"
          @blur="checkForm"
        >
        <label
          v-if="!editCompanyPermission"
          id="lblUpdateTraverseContainerId"
          class="companyDetailData"
        >{{ traverseContainerId }}</label>
      </div>
      <Spinner v-if="isSaving" />
      <div
        v-if="editCompanyPermission && !isSaving"
        class="updateButtonArea"
      >
        <input
          id="btnUpdateArea"
          :disabled="!formOK"
          type="button"
          class="companyUpdateButton"
          value="Update Company"
          title="Updates the name and description of the company"
          maxlength="255"
          @click="updateCompany()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { permissionsMixin } from '../../mixins/permissions'
import { traverseMixin } from '../../mixins/traverse'
import Spinner from '../common/spinner'
export default {
  name: 'CompanyDetails',
  components: {
    Spinner
  },
  mixins: [
    permissionsMixin,
    traverseMixin
  ],
  props: {
    id: {
      type: String,
      default: ''
    },
    companyid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      company: {},
      loading: false,
      formOK: false,
      companyNameError: false,
      traverseContainerId: '',
      isSaving: false
    }
  },
  computed: {
    editCompanyPermission () {
      return this.$store.getters.editCompanyPermission
    },
    companies () {
      return this.$store.getters.companies
    }
  },
  watch: {
    companyid () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    setComponent: async function () {
      this.loading = true
      if (this.companyid > -1) {
        this.company = this.companies.find(company => company.id === this.companyid)
        var rtn = await this.returnCompanyContainer(this.companyid)
        if (rtn.length > 0) {
          this.traverseContainerId = rtn[0].containerId
        } else {
          this.traverseContainerId = ''
        }
        this.checkForm()
        this.loading = false
      } else {
        this.loading = false
      }
    },
    updateCompany: async function () {
      if (!this.isSaving) {
        this.isSaving = true
        if (this.formOK) {
          await this.updateCompanyContainer(this.company.id, this.traverseContainerId)
          this.updateCompany(this.company).then(() => {
            this.getCompanies()
            this.isSaving = false
            this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
          })
        } else {
          this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
          this.isSaving = false
        }
      }
    },
    isNumeric (str) {
      if (typeof str !== 'string') return false
      return !isNaN(str) && !isNaN(parseFloat(str))
    },
    checkForm: function () {
      this.companyNameError = this.company.name.length === 0
      var error = this.company.id < 0 || this.companyNameError
      if (error) {
        var errorMessage = 'Please fix the issues with the following field: ' +
        (this.companyNameError ? 'company name' : '')
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      // the traverse container id should be a number
      if (this.traverseContainerId.length > 0) {
        if (!this.isNumeric(this.traverseContainerId)) {
          error = true
          this.$store.commit('showMessage', { content: 'The Traverse container id should be a number.', color: 'red', timeout: 5000 })
        }
      }
      this.formOK = !error
    }
  }
}
</script>
<style scoped>
  .companyHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }
  .companyDetailData {
    width: 250px;
    display: inline-block;
  }
  .controlContainer {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 10px 0;
  }
  .companyDetailLabel {
    width: 130px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .companyDetailInput {
    width: 244px;
    display: inline-block;
    margin: 0 0 10px 10px;
    vertical-align: bottom;
  }
  .companyUpdateButton {
    float:right;
    cursor: pointer;
  }
  .companyUpdateButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
</style>
