<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-mytickets"
          primary-title
        >
          Tickets
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="The Tickets screen shows details of all company tickets currently in Autotask."
              />
              <HelpSection
                instruction="1.) There are five ticket status switches on top of the ticket page to indicate which ticket status to be displayed."
                image-source="tickets_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="2.) The coloured buttons are filters which will determine according to priority which tickets are displayed."
                indentation="0"
              />
              <HelpSection
                instruction="a.) Selecting a filter will return only tickets with the selected priority i.e. high priority tickets only."
                indentation="1"
                image-source="tickets_2.png"
              />
              <HelpSection
                instruction="3.) Clicking on a ticket opens the ticket details component. This section contains additional information about the ticket including if the ticket has been raised as a problem. The header contains the priority information including a colour coded icon, and the ticket number field has a 'clipboard' icon next to it to allow the ticket number to be copied directly to the clipboard."
                image-source="tickets_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Clicking the details button on the ticket details component opens a dialog screen that allows the full ticket details, notes and atttachments to be seen."
                indentation="1"
                image-source="tickets_4.png"
              />
              <HelpSection
                instruction="b.) To add a note, click on the Note tab and fill the mandatory fields including note title and body. Resources can be notified of the update by setting the 'Notify Resources' toggle to green. This will notify the Maintel resources by default; other recipients can be added by manually entering the email of the person to be notified. Multiple addresses may be added, separated by semi-colon."
                indentation="1"
                image-source="tickets_5.png"
              />
              <HelpSection
                instruction="c.) To add an attachment, click on the Attachment tab. A new file can be added by dragging it and dropping it into the ‘Drag a file here or click’ area, or by clicking on this area to select a file. If a title is provided, it should be entered before attaching the file. Resources can be notified of the update by setting the 'Notify Resources' toggle to green. This will notify the Maintel resources by default; other recipients can be added by manually entering the email of the person to be notified. Multiple addresses may be added, separated by semi-colon. After selecting a file or files to upload, the uploaded files will be listed in the Upload Files section. Files can be deleted from this list before clicking on the Add Attachments button."
                indentation="1"
                image-source="tickets_6.png"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpMyTickets',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
