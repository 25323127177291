<template>
  <div
    :style="tooltopIconStyle"
    class="tooltip">
    <img :src="questionIcon" >
    <span
      :style="tooltipTextStyle"
      class="tooltiptext"
      v-html="text" />
  </div>
</template>

<script>
export default {
  name: 'TooltipIcon',
  props: {
    text: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '400'
    },
    align: {
      type: String,
      default: 'left'
    },
    iconPosition: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
    }
  },
  computed: {
    questionIcon () {
      return require('../../assets/icons/question.png')
    },
    tooltopIconStyle () {
      if (this.iconPosition.toLowerCase() === 'default') {
        return 'position: absolute;  display: inline-block;  margin-left: 10px;'
      } else if (this.iconPosition.toLowerCase() === 'top-right') {
        return 'position: relative;  display: inline-block;  left: -20px; top:-30px'
      }
    },
    tooltipTextStyle () {
      if (this.align.toLowerCase() === 'center' || this.align.toLowerCase() === 'centre') {
      /* Use half of the width (120/2 = 60), to center the tooltip */
      /* If we want to use this, need to make it appear on top of left-hand menu if tooltip is too close to the left-hand side.  (z-index didn't work) */
        return 'width:' + this.width + 'px;margin-left:-' + (this.width / 2) + 'px;'
      } else if (this.align.toLowerCase() === 'right') {
        return 'width:' + this.width + 'px;margin-left:-' + this.width + 'px;'
      } else {
        return 'width:' + this.width + 'px;'
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>

.tooltip img {
  width: 16px;
  cursor: pointer;
  vertical-align: middle;
  padding-left:0px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 100;
  font-size: 12px;
  white-space: normal;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 100%;
  left: 50%;

  z-index: 999;
}

/* Tooltip text */
.tooltip .tooltiptext strong, b {
  font-size: 1em;
}

/* Padding for list items in tooltips */
/* Using deep selector >>> for this.  See: https://medium.com/@brockreece/scoped-styles-with-v-html-c0f6d2dc5d8e */
.tooltip .tooltiptext >>> li {
  margin-bottom:5px;
  text-align:left;
}

/* Tooltip text */
.tooltip .tooltiptext >>> li strong, b {
  font-size: 1em;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
