<template>
  <div>
    <Spinner v-if="isLoading" />
    <router-link
      v-ripple
      v-if="!isLoading"
      class="toolbar-items"
      to="pmn?ticketsFilter=">
      <material-stats-card
        v-if="!isLoading"
        :value="pmnCount"
        color="green"
        icon="mdi-clock"
        title="Total PMN Tickets"
        sub-icon="mdi-clock"
        sub-text="Number of planned maintenance tickets"
        style="margin-bottom: 35px!important;"
      />
    </router-link>
  </div>
</template>

<script>
import { DataEventBus } from '../../../events/dataEvents'
import Spinner from '../../common/spinner'

export default {
  name: 'PMNEmergencyCoponent',
  components: {
    Spinner
  },
  data () {
    return {
      pmnCount: '0',
      isLoading: false
    }
  },
  computed: {
    PMNTicketCount () {
      return this.$store.getters.pmnTickets.filter(t => this.getUDFValue(t.id, 'Planned Maintenance Type') !== 'WSUS').length
    },
    currentTicketUDFs () {
      return this.$store.getters.ticketUDFs
    }
  },
  mounted () {
    DataEventBus.$on('load-dashboard', (state) => {
      this.populateTickets()
    })
    DataEventBus.$on('tickets-loaded', () => {
      this.populateTickets()
    })
    this.populateTickets()
  },
  methods: {
    populateTickets () {
      this.isLoading = true
      if (this.PMNTicketCount) {
        this.pmnCount = this.PMNTicketCount.toString()
      } else {
        this.pmnCount = '0'
      }
      this.isLoading = false
    },
    getUDFValue (id, key) {
      var udf = this.currentTicketUDFs.filter(u => u.id === id)
      for (let i = 0; i < udf.length; i++) {
        if (udf[i].name === key) {
          return udf[i].value
        }
      }
      return ''
    }
  }
}
</script>
<style scoped>
  .v-card__text { padding-bottom: 6px;}
</style>
