<template>
  <div class="designPackSummaryContainer quotePanel">
    <div>
      <table
        id="DesignPacStandardCaveatsTable"
        class="iquoteDesignpackTable">
        <tr>
          <th style="width: 300px">Quotation Instructions</th>
          <td
            :class="{ReadonlyTableCell: isReadOnly}"
            colspan="2">
            <textarea
              id=""
              v-model="iQuoteStore_GetDesignPack.standardCaveats"
              :readonly="isReadOnly"
              style="border: none; width: 100%"
              name="txtStandardCaveats"
              cols="215"
              rows="20"
              @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(iQuoteStore_GetDesignPack)"/></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackCircuitsMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackCircuits'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { iQuoteCalculationsDesignPackExportMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackExport'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'

export default {
  name: 'StandardCaveats',
  components: {
  },
  mixins: [
    iQuoteStoreMixin,
    iQuoteConstants,
    iQuoteFunctions,
    iQuoteCalculationsDesignPackCircuitsMixin,
    iQuoteExportsAPIMixin,
    iQuoteCalculationsDesignPackExportMixin,
    iQuoteCalculationsDesignPackMixin
  ],
  props: {
    isReadOnly: {
      type: Boolean,
      default: function () { return {} }
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    ...mapActions([
    ])
  }
}
</script>
<style scoped>
</style>
