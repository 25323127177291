<template>
  <div
    :class="[ showDetails ? 'active' : 'inactive']"
    class="serviceStatusContainer"
  >
    <div
      class="serviceStatusMenu"
    >
      <div
        v-show="viewSecureServicePermission && 1===0"
        id="secureIcon"
        title="ICON Secure"
      >
        <img
          :src="serviceIcon('ICONSecure.png')"
          class="serviceStatusMenuImage"
          alt="ICON Secure"
          @click="toggleDetails('secure')"
        >
        <div
          :class="calculateServiceStatus('secure')"
          class="ragStatus"
        />
      </div>
    </div>
    <div
      :class="[ showDetails ? 'active' : 'inactive']"
      class="serviceStatusDetails"
    >
      <ServiceOverview
        :selected-service="selectedService"
        :service-warning-message="serviceWarningMessage"
      />
    </div>
  </div>
</template>

<script>

import { autotaskServiceMixin } from '../../mixins/autotaskServiceMixin'
import ServiceOverview from './ServiceOverview'
import { DataEventBus } from '../../events/dataEvents'

export default {
  components: {
    ServiceOverview
  },
  mixins: [
    autotaskServiceMixin
  ],
  props: {
  },
  data: () => ({
    serviceData: [],
    showDetails: false,
    selectedService: '',
    serviceWarningMessage: '',
    currentCustomerId: 0
  }),
  computed: {
    companyContainers () {
      return this.$store.getters.companyContainers
    },
    selectedCustomer () {
      return this.$store.getters.currentCustomer
    },
    viewCommunicateServicePermission () {
      return this.$store.getters.viewCommunicateServicePermission
    },
    viewSecureServicePermission () {
      return this.$store.getters.viewSecureServicePermission
    },
    viewConnectServicePermission () {
      return this.$store.getters.viewConnectServicePermission
    },
    viewNowServicePermission () {
      return this.$store.getters.viewNowServicePermission
    },
    alarmList () {
      return this.$store.getters.ratings || []
    },
    sitesList () {
      return this.$store.getters.sites
    }
  },
  watch: {
    serviceData () {
      // console.log('serviceData')
      // console.log(this.serviceData)
    }
  },
  mounted () {
    DataEventBus.$on('company-changed', (customer) => {
      this.getProductData()
      this.showDetails = false
    })
    DataEventBus.$on('refresh-data', () => {
      this.getProductData()
      this.showDetails = false
    })
    DataEventBus.$on('company-dropdown-displayed', () => {
      this.showDetails = false
    })
  },
  methods: {
    getProductData () {
      if (this.selectedCustomer.topLevelAutotaskSite !== this.currentCustomerId) {
        // getProductData is called when the top-level Autotask site DOES match the current customer ID
        // We only want to blank and reload the serviceData if the top-level site HAS changed
        this.serviceData = []
        this.currentCustomerId = this.selectedCustomer.topLevelAutotaskSite        
      }
    },
    toggleDetails (service) {
      this.serviceWarningMessage = ''

      if (!this.showDetails || service === this.selectedService) {
        this.showDetails = !this.showDetails
      }
      this.selectedService = service

      var hasService = false
      if (service === 'now' || service === 'secure') {
        hasService = this.serviceData && this.serviceData.some(o => o.product.toLowerCase().includes(`icon ${service}`))
      } else if (service === 'communicate') {
        hasService = this.serviceData && this.serviceData.some(o => o.product.toLowerCase().includes(`icon ${service}`)) && !this.serviceData.some(o => o.product.toLowerCase().includes('icon now'))
      } else if (service === 'connect') {
        hasService = this.serviceData && this.serviceData.some(o => o.product.toLowerCase().includes(`icon ${service}`))
      }

      if (hasService && service === 'secure') {
        this.serviceWarningMessage = 'Monitoring on this feature is not yet available.'
      } else if (!hasService && service !== 'connect') {
        var toTitleCase = s => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase()
        this.serviceWarningMessage = `You do not have the ICON ${toTitleCase(service)} service, please contact your account manager.`
        return ''
      }
    },
    serviceIcon (image) {
      return require(`../../assets/images/${image}`)
    },
    calculateServiceStatus (service) {
      var hasService = false
      switch (service.toLowerCase()) {
        case 'connect':
          return this.getFilteredSiteList('high').length
            ? 'ragRed'
            : this.getFilteredSiteList('medium').length
              ? 'ragAmber'
              : 'ragGreen'
        case 'communicate':
          if (this.serviceData && this.serviceData.length > 0) {
            hasService = this.serviceData && this.serviceData.length > 0 &&
            this.serviceData.some(o => o.product.toLowerCase().includes('icon communicate')) &&
            !this.serviceData.some(o => o.product.toLowerCase().includes('icon now'))
          } else {
            hasService = false
          }
          if (!hasService) {
            return ''
          }

          return this.companyContainers && this.companyContainers.some(({ statusName }) => statusName.toLowerCase() === 'critical')
            ? 'ragRed'
            : this.companyContainers && this.companyContainers.some(({ statusName }) => statusName.toLowerCase() === 'warning')
              ? 'ragAmber'
              : this.companyContainers
                ? 'ragGreen'
                : 'ragGrey'
        case 'secure':
          hasService = this.serviceData && this.serviceData.length > 0 && this.serviceData.some(o => o.product.toLowerCase().includes('icon secure'))
          if (!hasService) {
            return ''
          }

          return ''
        case 'now':
          hasService = this.serviceData && this.serviceData.length > 0 && this.serviceData.some(o => o.product.toLowerCase().includes('icon now'))

          if (!hasService) {
            return ''
          }

          return this.companyContainers && this.companyContainers.some(({ statusName }) => statusName.toLowerCase() === 'critical')
            ? 'ragRed'
            : this.companyContainers && this.companyContainers.some(({ statusName }) => statusName.toLowerCase() === 'warning')
              ? 'ragAmber'
              : 'ragGreen'
        default:
          return ''
      }
    },
    getFilteredSiteList: function (filter = '') {
      var filteredAlarmList = this.getFilteredAlarmList(filter)
      return this.sitesList
        ? this.sitesList.filter(site => filteredAlarmList.some(({ siteId }) => siteId === site.id))
        : []
    },
    getFilteredAlarmList: function (filter) {
      var highPriorityAlarms = this.alarmList.filter(alarm =>
        alarm.raised &&
        !this.alarmList.some(alarm2 => !alarm2.raised && alarm2.reference === alarm.reference && new Date(alarm2.dateRaised) >= new Date(alarm.dateRaised)))
      switch (filter ? filter.toLowerCase() : '') {
        case 'high':
          return highPriorityAlarms
        case 'medium':
          return this.alarmList.filter(alarm =>
            alarm.raised &&
            new Date(alarm.dateRaised) >= new Date(new Date().setHours(0, 0, 0, 0)) &&
            !highPriorityAlarms.some(({ siteId }) => siteId === alarm.siteId))
        case 'low':
          return this.alarmList.filter(alarm =>
            !alarm.raised && new Date(alarm.dateRaised) >= new Date().setMonth(new Date().getMonth() - 1))
        default:
          return this.alarmList
      }
    }
  }
}
</script>

<style scoped>
  .serviceStatusContainer {
    position: fixed;
    z-index: 100;
    right: 0;
    height: 100vh;
    width: 30vw;
    flex-direction: row;
    vertical-align: middle;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    margin-top: 64px;
  }

  .serviceStatusContainer.active{
    transform: translateX(0);
    transition: 0.5s;
  }

  .serviceStatusContainer.inactive{
    transform: translateX(26vw);
    transition: 0.5s;
  }

  .serviceStatusMenu {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: center;
    position: absolute;
    top: 25vh;
    width:4vw;
    border-radius: 1vw 0 0 1vw;
  }

  .serviceStatusDetails {
    margin-left: 4vw;
    height: 100vh;
    border-left: 1px solid rgb(153, 153, 153);
  }

  .serviceStatusMenuImage{
    width:3vw;
    height:3vw;
    cursor: pointer;
    margin:0 0.5vw 0.5vw 0.5vw;
    border-radius: 0.5vw;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  }

  .serviceStatusMenuImage.top{
    margin-top:0.5vw;
  }

  .serviceStatusMenuImage:hover {
    box-shadow: 2px 4px 3px rgba(102, 187, 106, 0.3)
  }

  .ragStatus {
    border-radius: 20px;
    color: #000;
    font-weight: bold;
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: rgb(153, 153, 153);
    margin: 0.3vh 0.3vw 0 0;
    position: absolute;
    right: 0.2vw;
    border: 0.5px solid #fff;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);

  }

  .ragStatus.top {
    margin-top: 0.9vh;
  }

  .ragRed {
    background-color: rgb(239, 83, 80);
  }

  .ragAmber {
    background-color: rgb(255, 167, 38);
  }

  .ragGreen{
    background-color: rgb(102, 187, 106);
  }

  @media only screen and (max-width: 768px) {
    .serviceStatusContainer {
      width: 97vw;
    }
    .serviceStatusContainer.active{
      transform: translateX(0);
      transition: 0.5s;
    }

    .serviceStatusContainer.inactive{
      transform: translateX(93vw);
      transition: 0.5s;
    }
  }
</style>
