<template>
  <div
    class="adcHolder"
  >
    <p class="adcText"> To resend the request access email, press the Resend Email button below:</p>

    <input
      :disabled="!selectedUser"
      value="Resend Email"
      type="button"
      title="Resend the access request email"
      class="adcButton customerUpdateButton"
      @click="sendRequestAccessEmail()"
    >
  </div>
</template>
<script>

import { usersMixin } from '../../../mixins/users'
import { adminMixin } from '../../../mixins/adminMixin'
import PortalDropdown from '../../common/portalDropdown'

export default {
  components: {
    PortalDropdown
  },
  mixins: [
    usersMixin,
    adminMixin
  ],
  props: {
    request: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedUser: {}
    }
  },
  methods: {
    sendRequestAccessEmail: async function () {
      // console.log(this.selectedUser)
      this.$store.commit('showMessage', {
        content: `About to send the request access email for '${this.request.emailAddress}'`,
        color: 'blue',
        timeout: 3000
      })
      var payload = {}
      payload.EmailAddress = this.request.emailAddress
      payload.FirstName = this.request.firstName
      payload.LastName = this.request.lastName
      payload.CompanyId = 0
      payload.CompanyName = this.request.companyName
      payload.url = window.location.origin + '/'
      var rtn = await this.sendApproveRequestEmail(payload)
      if (rtn.length === 0) {
        this.$store.commit('showMessage', {
          content: `The request access email for '${this.request.emailAddress}' was sent successfully!`,
          color: 'green',
          timeout: 3000
        })
      } else {
        this.$store.commit('showMessage', {
          content: `The request access email for '${this.request.emailAddress}' failed with '${rtn}'`,
          color: 'red',
          timeout: 3000
        })
      }
    }
  }
}
</script>
<style scoped>
  .adcButton{
    width:140px;
    border: solid 1px grey;
    color: grey;
  }
  .adcSubHeader{
    padding-left:10px;
    display: inline-block;
    width: 220px;
  }
  .adcHolder {
    background-color: rgba(255,255,255,0.7);
    margin-bottom: 10px;
    padding: 10px 0;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    padding: 10px 5px;
    display: none;
    text-align: right;
    width:100%;
  }
  .adcText {
    text-align: left;
  }
  .customerOnboardingDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .customerOnboardingDetailInput {
    width: 254px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .customerUpdateButton {
    cursor: pointer;
  }
</style>
