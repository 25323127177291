<template>
  <div>
    <div
      v-if="!createEntityPermission"
      class="noResults"
    >
      Sorry, you do not have permission to assign users to all companies
    </div>
    <div
      v-else
      id="assignUsersContainer"
      class="assignSectionData"
    >
      <Spinner v-if="isLoading" />
      <div class="mdContainer appSectionBody">
        <div
          :class="{mdHalfScreen : !!selectedUser}"
          class="mdUserManagement"
        >
          <table id="usersTable">
            <thead />
            <tbody>
              <tr
                v-for="u in currentFilter"
                :key="u.id"
                :tabindex="20"
                :class="getRowColour(u.id)"
                @click="setCurrentUser(u)"
              >
                <td>{{ u.userName }}</td>
                <td>{{ u.firstName }}</td>
                <td>{{ u.lastName }}</td>
                <td>{{ u.emailAddress }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="selectedUser !== null"
          class="mdSelection"
        >
          <SectionCollapsedHeader
            id="sectionAssignUser"
            title="Assign user to companies"
            type="subHeader"
            icon-file="Edit"
            controls="assignUserComponent"
          />
          <UserAssignComponent
            id="assignUserComponent"
            :user="selectedUser"
          />
        </div>
      </div>
    </div>
    <input
      id="backButton"
      type="button"
      class="backButton"
      title="Back to support page"
      @click="closeAssignUsersForm()"
    >
    <br><br>
  </div>
</template>

<script>
import { usersMixin } from '../../../mixins/users'
import { adminMixin } from '../../../mixins/adminMixin'
import tableHandler from '../../../mixins/tableHandler'
import { DataEventBus } from '../../../events/dataEvents'
import Spinner from '../../common/spinner'
import UserAssignComponent from './userAssignComponent'
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'
import { mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'Support - Assign All Companies To Users'
  },
  name: 'AssignUsers',
  components: {
    Spinner,
    UserAssignComponent,
    SectionCollapsedHeader
  },
  mixins: [
    usersMixin,
    adminMixin,
    tableHandler
  ],
  data () {
    return {
      isLoading: false,
      dataReturned: [],
      userList: [],
      selectedUser: null
    }
  },
  computed: {
    viewEntityPermission () {
      return this.$store.getters.viewEntityPermission
    },
    createEntityPermission () {
      return this.$store.getters.createEntityPermission
    },
    currentUserId () {
      return this.$store.getters.userid
    },
    currentUser () {
      const users = this.$store.getters.users.filter(
        (user) => user.id !== this.currentUserId
      )
      return users
    },
    allUsers () {
      return this.$store.getters.users
    },
    buttonEnabled () {
      return this.selectedUser.id > -1
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    reloadUsers (val) {
      if (val) {
        this.getMyUsers()
        this.setReloadUsers(false)
      }
    }
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.selected = '-1'
      this.getMyUsers()
    })
  },
  created () {
    this.checkPermission()
  },
  methods: {
    ...mapActions(['setPage', 'setReloadUsers']),
    closeAssignUsersForm () {
      this.formOK = false
      this.$emit('setTileMode')
    },
    checkPermission: async function () {
      if (this.viewEntityPermission) {
        this.getMyUsers()
        this.setPage('UserManagement')
      } else {
        this.setPage('Login')
      }
    },
    getMyUsers () {
      if (this.viewEntityPermission) {
        this.userList = []
        var userStringList = ''
        this.isLoading = true
        if (this.allUsers.length > 0) {
          this.allUsers.forEach((user) => {
            userStringList += '|' + user.id
          })
          userStringList = userStringList.substring(1)
          this.getUserList(userStringList).then((result) => {
            this.dataReturned = result
            // remove the avatar so the search results aren't affected by this un-used field
            this.dataReturned.forEach((data) => delete data.avatar)
            this.userList = this.dataReturned.filter(user => user.emailAddress.indexOf('@maintel.co.uk') > -1)
            this.initTable()
          })
        } else {
          this.dataReturned = []
          this.userList = this.dataReturned
          this.initTable()
        }
        this.isLoading = false
      }
    },
    initTable: function () {
      this.loading = true
      this.$nextTick(() => {
        this.initialiseTable(
          'usersTable',
          this.userList,
          ['userName', 'firstName', 'lastName', 'emailAddress'],
          ['User Name', 'First Name', 'Last Name', 'Email Address'],
          ['', '', '', '', ''],
          null,
          ['30%', '15%', '15%', '40%'],
          20
        )
      })
      this.loading = false
    },
    getRowColour (id) {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      if (this.selectSite === id) {
        rtn = 'itemSelected'
      }
      return rtn
    },
    setCurrentUser (usr) {
      if (this.selectedUser === null) {
        this.selectedUser = usr
      } else if (this.selectedUser.id === usr.id) {
        this.selectedUser = null
      } else {
        this.selectedUser = usr
      }
    }
  }
}
</script>
<style scoped>
.assignSectionData {
  margin: 20px;
}
.backButton {
  margin: 20px;
}
</style>
