<template>
  <div
    class="customerEntitiesHolder"
  >
    <Spinner v-if="loading" />
    <div
      class="entityCustomersContainer"
    >
      <div
        class="customerSeachContainer"
      >
        <label for="inputCustomerSeach">Search:</label>
        <input
          id="inputCustomerSeach"
          type="text"
          @keyup="searchKeyUp"
        >
        <label for="checkboxShowActive">Active Only:</label>
        <input
          id="checkboxShowActive"
          type="checkbox"
          @change="setShowActive"
        >
      </div>
      <table
        v-show="!loading"
        id="customersTable"
        style="width:100%"
      >
        <thead>
          <tr>
            <th style="width:80%;">Top Level Site</th>
            <th
              v-if="assignToCustomerPermission"
              style="width:20%;"
            >
              Enabled
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="c in currentUserCustomers"
            :class="getRowColour()"
            :key="c.id"
          >
            <td> {{ c.name }} </td>
            <td
              v-if="assignToCustomerPermission"
            >
              <TriStateToggleSwitch
                :ref="`customer-toggle-${c.id}`"
                :enabled="true"
                :current-state="customers && customers.some(cu => cu.id === c.id) ? 1 : 2"
                :tri-state="false"
                class="tableToggleSwitch"
                @statechanged="({ selectedState }) => {
                  updateCustomerState({ entityId: userid, customerId: c.id, isDefault: c.isDefault, selectedState})
                }"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <UserCustomerNewRole
        :roles="customerRoles"
        :userid="selectedUserId"
        @RolesAdded="addUserToCustomer"
        @RoleCancelled="undoCustomerAdd"
      />
    </div>
  </div>
</template>
<script>
import { permissionsMixin } from '../../mixins/permissions'
import Spinner from '../common/spinner'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'
import UserCustomerNewRole from './userCustomerNewRole'
import { mapActions } from 'vuex'
export default {
  name: 'UserCustomerComponent',
  components: {
    Spinner,
    TriStateToggleSwitch,
    UserCustomerNewRole
  },
  mixins: [
    permissionsMixin
  ],
  props: {
    userid: {
      type: Number,
      default: -1
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      loading: true,
      customers: [],
      customerRoles: [],
      selectedUserId: -1,
      selectedCustomerId: -1,
      selectedIsDefault: -1,
      currentUserCustomers: []
    }
  },
  computed: {
    assignToCustomerPermission () {
      return this.$store.getters.assignToCustomerPermission
    }
  },
  watch: {
    userid () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setSubForm'
    ]),
    searchKeyUp (src) {
      this.doSearch(src.srcElement.value)
    },
    setSearch () {
      var val = document.getElementById('inputCustomerSeach').value
      this.doSearch(val)
    },
    doSearch (val) {
      if (val.length > 0) {
        this.currentUserCustomers = this.currentUserCustomers.filter(customer => customer.name.toLowerCase().includes(val.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name))
      } else {
        this.currentUserCustomers = this.$store.getters.customers.sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    setComponent: async function () {
      if (this.userid > -1) {
        this.getCurrentCustomers(true)
      }
    },
    getCurrentCustomers: async function (updateLoading) {
      this.currentUserCustomers = this.$store.getters.customers
      if (updateLoading) {
        this.loading = true
      }
      this.getUserCustomers(this.userid).then((results) => {
        this.customers = results
        if (updateLoading) {
          this.loading = false
          this.setSearch()
        }
      })
    },
    updateCustomerState: async function ({ entityId, customerId, isDefault, selectedState }) {
      this.loading = true
      this.selectedUserId = entityId
      this.selectedCustomerId = customerId
      this.selectedIsDefault = isDefault
      // console.log('updateCustomerState - entityId: ' + entityId + ', customerId: ' + customerId + ', isDefault: ' + isDefault + ', selectedState: ' + selectedState)
      if (selectedState === 1) {
        // get a list of roles that are currently assigned to the customer
        this.customerRoles = await this.getRolesByCustomerAndEntity(customerId, entityId)
        // get the roles for the user that we are assigning the customer to
        var targetUserRoles = await this.getRoleMembersForEntity(entityId, customerId)

        // compare customer roles with current users roles
        if (!targetUserRoles || (targetUserRoles && this.customerRoles && !targetUserRoles.some(role => this.customerRoles.some(cRole => cRole.id === role.roleId)))) {
          // if there is only one role available - create the users new roleentity record without the popup showing
          if (this.customerRoles.length === 1) {
            this.addEntityRole(entityId, this.customerRoles[0].id)
            this.addUserToCustomer([ this.customerRoles[0] ])
          } else {
            // if they don't have a matching role and there is more than one role available then the user should select a role from the available list
            this.setSubForm(true)
          }
        } else {
          this.addUserToCustomer(this.customerRoles.filter(role => targetUserRoles.some(cRole => cRole.id === role.roleId)))
        }
      } else if (selectedState === 2) {
        // await this.deleteEntityRoleForCustomer({ entityId, customerId })
        await this.deleteUserCustomer({ entityId, customerId })
        this.loading = false
        this.setSearch()
      }
      this.getCurrentCustomers(false)
    },
    addUserToCustomer: async function (assignedRoles) {
      // check if the user is currently assigned to an area or one of it's child areas
      var customerAreas = await this.getAreaChildAreas(
        this.currentUserCustomers.find(o => o.id === this.selectedCustomerId).topLevelAutotaskSite)

      // Get the target users areas
      var targetUserAreas = await this.getTargetUserAreas(this.selectedUserId, this.selectedCustomerId)

      if (customerAreas && !customerAreas.some(area => targetUserAreas && targetUserAreas.some(uArea => uArea.id === area.id))) {
        // If the user doesn't have an area record in one of the customers areas, add a record for the top level area assigned to that customer
        this.addEntityArea(
          this.selectedUserId,
          this.currentUserCustomers.find(o => o.id === this.selectedCustomerId).topLevelAutotaskSite
        )
      }
      await this.createUserCustomer({ entityId: this.selectedUserId, customerId: this.selectedCustomerId, isDefault: this.selectedIsDefault })
      this.getCurrentCustomers()
      this.loading = false
      this.setSearch()
      if (assignedRoles) {
        this.$store.commit('showMessage', { content: `User has been assigned to the top level site with the role(s): ${assignedRoles.map(role => role.name).join(',')} `, color: 'blue', timeout: 5000 })
      } else {
        this.$store.commit('showMessage', { content: 'User has been assigned to the top level site', color: 'blue', timeout: 5000 })
      }
    },
    undoCustomerAdd () {
      if (this.selectedCustomerId !== undefined) {
        this.$refs[`customer-toggle-${this.selectedCustomerId}`][0].toggleState(2)
      }
    },
    getRowColour () {
      if (this.useAltColour) {
        return 'LightGreen'
      } else {
        return 'SolidLightGreen'
      }
    },
    setShowActive (elem) {
      if (elem.target.checked) {
        this.currentUserCustomers = this.customers.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        this.currentUserCustomers = this.$store.getters.customers.sort((a, b) => a.name.localeCompare(b.name))
      }
    }
  }
}

</script>
<style scoped>
  .entityCustomersContainer {
    padding: 15px 10px 10px 10px;
  }

  .customerEntitiesHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    margin-bottom: 10px;
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }

  table thead tr th {
    padding:3px 5px;
    font-size: 0.9em;
  }
  #inputCustomerSeach {
    border-radius: 3px;
    margin: 0 10px 5px 0;
  }
  .customerSeachContainer label {
    font-weight: bold;
  }
</style>
