<template>
  <div>
    <Spinner
      v-if="loading"
    />
    <a
      v-else
      title="Access Highlight Portal"
      @click="() => { highlightSSO() }"
    >
      <material-card>
        <div style="width:100%">
          <div style="width:33%;display: inline-block;">
            <img
              :src="highlightIcon"
              class="highlightImage"
              title="Access Highlight Portal"
            >
          </div>
          <div
            class="md-title"
            style="width: 65%;display: inline-block;vertical-align: top;text-align: left;margin-top: 35px;"
          >Access Highlight Portal
          </div>
        </div>
      </material-card>
    </a>
  </div>
</template>

<script>
import { highlightMixin } from '../../../mixins/highlight'
import { usersMixin } from '../../../mixins/users'
import Spinner from '../../../components/common/spinner'

export default {
  name: 'HighlightLinkTile',
  components: {
    Spinner
  },
  mixins: [
    highlightMixin,
    usersMixin
  ],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    highlightIcon () {
      return require(`../../../assets/icons/highlight-icon-2.png`)
    }
  },
  methods: {
    highlightSSO () {
      var partner = 'Maintel Support'
      this.loading = true

      this.getUser(this.$store.getters.username).then((user) => {
        var email = user.emailAddress
        this.getEncryptedToken(partner, email).then((token) => {
          // console.log(`token retrieved: ${token}`)
          if (token && token.toLowerCase().indexOf('error') === -1) {
            this.decryptToken(token).then((decryptedToken) => {
              // console.log(`token decrypted to: ${decryptedToken}`)
              if (decryptedToken && decryptedToken.toLowerCase().indexOf('error') === -1) {
                this.getCookie(partner, email, decryptedToken).then((cookie) => {
                  // console.log(`cookie retrieved: ${cookie}`)
                  var form = document.createElement('form')
                  form.setAttribute('method', 'post')
                  form.setAttribute('action', process.env.VUE_APP_HIGHLIGHT_SSO_API + '/Account/LoginSSO')
                  form.setAttribute('target', '_blank')

                  var hfEmail = document.createElement('input')
                  hfEmail.setAttribute('type', 'hidden')
                  hfEmail.setAttribute('name', 'uid')
                  hfEmail.setAttribute('value', email)
                  form.appendChild(hfEmail)

                  var hfCookie = document.createElement('input')
                  hfCookie.setAttribute('type', 'hidden')
                  hfCookie.setAttribute('name', 'cookie')
                  hfCookie.setAttribute('value', cookie)
                  form.appendChild(hfCookie)
                  document.body.appendChild(form)
                  form.submit()
                  this.loading = false
                })
              } else {
                this.$store.commit('showMessage', { content: 'There is a problem with the Single Sign On facility.', color: 'red', timeout: 3000 })
                this.loading = false
              }
            })
          } else {
            if (token.indexOf('code=1') !== -1) {
              this.$store.commit('showConfirmationMessage', {
                content: 'This user does not have a valid Highlight account. If you wish to login using a different account please click the login button to be directed to Highlight.',
                color: 'orange',
                timeout: 10000,
                buttonText: 'Login',
                callback: () => {
                  window.open(
                    process.env.VUE_APP_HIGHLIGHT_SSO_API,
                    '_blank'
                  )
                }
              })
            } else {
              window.open('https://maintel.highlighter.net', '_blank')
            }
            this.loading = false
          }
        })
      })
    }
  }
}
</script>
<style scoped>
.highlightImage {
  width:92px;
  height:92px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 12px 20px -10px rgb(133 133 89 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(133 133 89 / 20%) !important;
  -moz-box-shadow: 3px 3px  rgba(0,0,0,0.1);
  -webkit-box-shadow: 3px 3px  rgba(0,0,0,0.1);
  -o-box-shadow: 3px 3px  rgba(0,0,0,0.1);
  border-radius:90px;
}
</style>
