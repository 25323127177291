<template>
  <div
    id="serviceOverview"
    class="appSectionContainer"
  >
    <div
      v-if="viewCommunicateServicePermission || viewAreaTicketsPermission"
    >
      <Spinner
        v-if="loading"
      />
      <div
        v-else
      >
        <h3>
          {{ serviceName }}
        </h3>
        <div
          v-if="serviceWarningMessage"
          id="serviceStatusWarningMessage"
          class="noResults"
        >
          {{ serviceWarningMessage }}
        </div>
        <CommunicateServiceOverview
          v-if="viewCommunicateServicePermission && selectedService === 'communicate' && !serviceWarningMessage"
        />
        <ConnectServiceOverview
          v-if="viewAreaTicketsPermission && selectedService === 'connect' && !serviceWarningMessage"
        />
        <NowServiceOverview
          v-if="viewNowServicePermission && selectedService === 'now' && !serviceWarningMessage"
        />
      </div>
    </div>
    <div
      v-else
      class="noResults"
    >
      Sorry, you do not have permission to view the service status page
    </div>
  </div>
</template>

<script>

import Spinner from '../common/spinner'
import CommunicateServiceOverview from './CommunicateServiceOverview'
import ConnectServiceOverview from './ConnectServiceOverview'
import NowServiceOverview from './NowServiceOverview'

export default {
  components: {
    Spinner,
    CommunicateServiceOverview,
    ConnectServiceOverview,
    NowServiceOverview
  },
  mixins: [
  ],
  props: {
    selectedService: {
      type: String,
      default: ''
    },
    serviceWarningMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      data: []
    }
  },
  computed: {
    serviceName () {
      switch (this.selectedService.toLowerCase()) {
        case 'connect':
          return 'ICON Connect'
        case 'communicate':
        case 'contact':
          return 'ICON Communicate / ICON Contact'
        case 'secure':
          return 'ICON Secure'
        case 'now':
          return 'ICON Now'
        default:
          return 'No Service Selected'
      }
    },
    viewCommunicateServicePermission () {
      return this.$store.getters.viewCommunicateServicePermission
    },
    viewNowServicePermission () {
      return this.$store.getters.viewNowServicePermission
    },
    viewAreaTicketsPermission () {
      return this.$store.getters.viewAreaTicketsPermission
    }
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
  .appSectionContainer {
    min-height: 100%;
  }

  h3 {
    cursor: pointer;
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0 0 0.5vw 0;
    color: #fff;
  }
</style>
