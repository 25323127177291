// This component is used when changing a password for a different user (if you are an admin) or yourself (if you supply your original password)

<template>
  <div
    id="changePassword"
    class="pccSectionContainer"
  >
    <div class="pccContainer" >
      <h4> Change your Password </h4>
      <PortalTextBox
        :item-value="oldPassword"
        class="pccPassword"
        text-id="pwdOldPassword"
        item-place-holder="Old Password"
        item-type="pwd"
        @EditValue="oldPassword = $event"
        @blur="validateElement('pwdOldPassword', $event.srcElement.value)"
      />
      <PortalTextBox
        :item-value="password"
        class="pccPassword"
        text-id="pwdChangePasswordNew"
        item-place-holder="New Password"
        item-type="pwd"
        @EditValue="password = $event"
        @blur="validateElement('pwdChangePasswordNew', $event.srcElement.value)"
      />
      <PortalTextBox
        :item-value="confirmPassword"
        class="pccPassword"
        text-id="pwdChangePasswordConfirm"
        item-place-holder="Confirm Password"
        item-type="pwd"
        @EditValue="confirmPassword = $event"
        @blur="validateElement('pwdChangePasswordConfirm', $event.srcElement.value)"
      />
      <div
        class="pccButton"
      >
        <div style="height: 25px;">
          <TextButton
            :button-tabindex="4"
            button-class="pccChangePasswordButton"
            button-style="margin: 0 10px 10px 0; float: right;"
            button-id="btnApplyChange"
            title="Apply Change"
            text="Apply Change"
            @click="sendPasswordChange()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PortalButton from '../common/portalButton'
import PortalTextBox from '../common/portalTextBox'
import AuthRepository from '../../communications/repositories/authRepository'
import TextButton from '../common/textButton'

export default {
  name: 'PasswordChange',
  components: {
    PortalButton,
    PortalTextBox,
    TextButton
  },
  mixins: [
    AuthRepository
  ],
  data: () => {
    return {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    }
  },
  computed: {
    username: () => {
      return this.$store.getters.username
    },
    errorStyle: () => {
      return 'solid 1px #a33'
    },
    correctStyle: () => {
      return 'solid 1px #666'
    }
  },
  methods: {
    sendPasswordChange () {
      if (this.confirmPasswordState() && this.isValidNewPassword()) {
        this.authRequestSendPasswordChange(this.$store.getters.username, this.password, this.oldPassword)
        this.resetControl()
      } else {
        this.$store.commit('showMessage', { content: 'Please ensure the password is greater than 8 and less than 256 characters.', color: 'red', timeout: 5000 })
      }
    },
    confirmPasswordState () {
      var rtn = false
      if (this.oldPassword.length === 0 || this.password.length === 0 || this.confirmPassword.length === 0) {
        this.$store.commit('showMessage', { content: 'Please ensure the password is greater than 8 and less than 256 characters.', color: 'red', timeout: 8000 })
      } else if (this.password === this.oldPassword) {
        this.$store.commit('showMessage', { content: 'Please ensure the new password is different from the old password.', color: 'red', timeout: 8000 })
        document.getElementById('pwdOldPassword').style.borderBottom = this.correctStyle
        document.getElementById('pwdChangePasswordNew').style.borderBottom = this.errorStyle
        document.getElementById('pwdChangePasswordConfirm').style.borderBottom = this.errorStyle
      } else if (this.password !== this.confirmPassword) {
        this.$store.commit('showMessage', { content: 'The passwords you entered did not match.', color: 'red', timeout: 10000 })
        document.getElementById('pwdOldPassword').style.borderBottom = this.correctStyle
        document.getElementById('pwdChangePasswordNew').style.borderBottom = this.errorStyle
        document.getElementById('pwdChangePasswordConfirm').style.borderBottom = this.errorStyle
      } else {
        document.getElementById('pwdOldPassword').style.borderBottom = this.correctStyle
        document.getElementById('pwdChangePasswordNew').style.borderBottom = this.correctStyle
        document.getElementById('pwdChangePasswordConfirm').style.borderBottom = this.correctStyle
        rtn = true
      }
      return rtn
    },
    isValidNewPassword () {
      var validNewPasswordLength = this.password.length >= 8 && this.password.length <= 255
      var validConfirmPasswordLength = this.confirmPassword.length >= 8 && this.confirmPassword.length <= 255
      var newPasswordAndConfirmPasswordMatch = this.password === this.confirmPassword

      return validNewPasswordLength && validConfirmPasswordLength && newPasswordAndConfirmPasswordMatch
    },
    validateElement (field, value) {
      var err = (value.length < 8 || value.length > 255)
      if (err) {
        this.$store.commit('showMessage', { content: 'Please ensure all the password fields are at least 8 and less than 255 characters long.', color: 'red', timeout: 8000 })
        document.getElementById(field).style.borderBottom = this.errorStyle
      } else {
        document.getElementById(field).style.borderBottom = this.correctStyle
      }
    },
    resetControl () {
      this.password = ''
      this.confirmPassword = ''
      this.oldPassword = ''
    }
  }
}
</script>
<style scoped>
  .pccSectionContainer {
    width:100%;
    margin-bottom:10px;
  }
  .pccContainer {
    background-color: rgba(255,255,255,0.7);
    padding:20px;
    margin-bottom:10px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .pccButton {
    justify-self: right;
    margin:20px 0 0 0;
  }
  .pccPassword {
    height: 30px;
    width: 100%;
    margin-top:10px;
  }
  .pccContainer.pccIsPasswordResetRequest .pccPassword {
    margin-top: 0px;
  }

  .pccChangePasswordButton {
      width:100%;
  }
</style>
