<template>
  <div
    :id="id"
    class="areaMembersHolder"
  >
    <Spinner
      v-if="loading"
      id="areaMemberSpinner"
    />
    <p
      v-if="!loading && !members.length"
      class="alert"
    >
      This site has no members.
    </p>
    <table v-if="!loading">
      <tbody>
        <tr
          v-for="m in members"
          :class="getRowColour()"
          :key="m.id"
        >
          <td
            :title="m.userName"
            style="width:50%"
          >{{ m.userName }} </td>
          <td
            :title="m.firstName"
            style="width:21%"
          >{{ m.firstName }} </td>
          <td
            :title="m.lastName"
            style="width:21%"
          >{{ m.lastName }} </td>
          <td
            v-if="removeEntityAreaPermission"
            style="text-align:right;width:8px;"
          >
            <input
              type="button"
              class="deleteButton"
              title="Remove member from site"
              @click="removeAreaMember(m)"
            >
          </td>
        </tr>
      </tbody>
    </table>
    <input
      v-if="assignEntityAreaPermission && !loading"
      id="areaMembersButton"
      type="button"
      class="areaMembersButton"
      value="Add Member"
      title="Adds the new user to the area"
      @click="addMember()"
    >
    <SiteNewMember
      :areaid="areaid"
      :userid="userid"
      :currentmembers="currentmembers"
    />
  </div>
</template>
<script>
import SiteNewMember from './areaNewMember'
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import Spinner from '../../common/spinner'
import { mapActions } from 'vuex'
export default {
  name: 'AreaMembers',
  components: {
    SiteNewMember,
    Spinner
  },
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    areaid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      loading: false,
      members: []
    }
  },
  computed: {
    reloadMembers () {
      return this.$store.getters.reloadMembers
    },
    assignEntityAreaPermission () {
      return this.$store.getters.assignEntityAreaPermission
    },
    removeEntityAreaPermission () {
      return this.$store.getters.removeEntityAreaPermission
    },
    currentmembers () {
      return this.members
    }
  },
  watch: {
    areaid () {
      this.setComponent()
    },
    reloadMembers (val) {
      if (val) {
        this.setComponent()
        this.setReloadMembers(false)
      }
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setSubForm',
      'setReloadMembers'
    ]),
    setComponent: async function () {
      if (this.areaid > -1) {
        this.loading = true
        this.getMembers()
      }
    },
    getMembers: async function () {
      var userList = ''
      this.getAreaMembers(this.areaid, this.userid).then((results) => {
        if (results.length > 0) {
          results.forEach(entity => {
            userList += '|' + entity.id
          })
          userList = userList.substring(1)
          this.getUserList(userList).then((members) => {
            this.members = members
            this.loading = false
          })
        } else {
          this.members = []
          this.loading = false
        }
      })
    },
    addMember: async function () {
      this.setSubForm(true)
    },
    removeAreaMember: async function (m) {
      await this.deleteEntityArea(m.id, this.areaid)
      this.setComponent()
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    }
  }
}

</script>
<style scoped>
  .areaMembersHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    display: none;
    margin-bottom:5px;
    max-height: 300px;
    overflow-y: auto;
  }
  .areaMembersButton {
    margin: 10px;
    float: right;
    cursor: pointer;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
  }
</style>
