<template>
  <div
    :id="id"
    class="rpmSectionContainer"
  >
    <Spinner
      v-if="loading"
      id="rolePermissionSpinner"
    />
    <div
      v-if="!loading"
      class="rpmContainer"
    >
      <table id="rolePermissionTable">
        <thead>
          <tr>
            <th style="width:50%">Permission</th>
            <th style="width:16%">Allowed</th>
            <th style="width:16%">Grant</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="({permissionId, allowed, permissionName, grant}) in uniquePermissions"
            :class="getRowColour()"
            :key="permissionId"
          >
            <td>{{ permissionName }} </td>
            <td style="padding: 6px 5px 0 0;">
              <TriStateToggleSwitch
                :enabled="updateAllowPermissionPermission"
                :current-state="allowed"
                :permission-id="permissionId"
                :role-id="roleid"
                @statechanged="updateAllowedPermission"
              />
            </td>
            <td style="padding: 6px 5px 0 0;">
              <TriStateToggleSwitch
                :enabled="updateGrantPermissionPermission"
                :current-state="grant"
                :permission-id="permissionId"
                :role-id="roleid"
                @statechanged="updateGrantPermission"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { permissionsMixin } from '../../../mixins/permissions'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import Spinner from '../../common/spinner'
export default {
  name: 'RolePermissions',
  components: {
    TriStateToggleSwitch,
    Spinner
  },
  mixins: [ permissionsMixin ],
  props: {
    roleid: {
      type: Number,
      default: -1
    },
    areaid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      loading: true,
      perms: []
    }
  },
  computed: {
    updateAllowPermissionPermission () {
      return this.$store.getters.updateAllowPermissionPermission
    },
    updateGrantPermissionPermission () {
      return this.$store.getters.updateGrantPermissionPermission
    },
    collapseIconName () {
      return require(`../../../assets/icons/expand.png`)
    },
    uniquePermissions () {
      var mappedPermissions = this.perms && this.perms.map(({ permissionId, allowed, grant, permissionName }) => ({
        permissionId,
        permissionName,
        allowed,
        grant
      }))

      // return a unique list of permissions
      return mappedPermissions && mappedPermissions.filter((permission, index) => {
        const _permission = JSON.stringify(permission)
        return index === mappedPermissions.findIndex(perm => {
          return JSON.stringify(perm) === _permission
        })
      })
    },
    permissions () {
      return this.$store.getters.permissions
    }
  },
  watch: {
    roleid () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    setCollapseIconName (state) {
      return require(`../../../assets/icons/${state}.png`)
    },
    setComponent: async function () {
      if (this.roleid > -1) {
        this.loading = true
        this.populatePermissions()
      }
    },
    populatePermissions: async function () {
      this.getRolePermissions(this.roleid, this.areaid).then((result) => {
        this.perms = [
          ...this.permissions
            .filter(o => !result || !result.some(p => p.permissionId === o.permissionId))
            .map(o => ({
              ...o,
              allowed: 0,
              grant: 0
            })),
          ...result
        ].sort((a, b) => a.permissionName !== b.permissionName ? a.permissionName < b.permissionName ? -1 : 1 : 0)
        this.loading = false
      })
    },
    toggle (event) {
      var div = event.target
      if (div.className === 'rpmtitle') { div = div.children[0].children[0] }
      var state = 'expand'
      if (div.tagName === 'IMG') {
        if (div.alt.substring(0, 4) === 'Open') {
          state = 'collapse'
          div.alt = 'Close ' + div.name
        } else {
          div.alt = 'Open ' + div.name
        }
        div.src = this.setCollapseIconName(state)
        div = div.parentNode.parentNode
      }
      if (state === 'expand') {
        div.parentNode.childNodes[1].className = 'rpmbody'
        div.style.borderRadius = '5px'
      } else {
        div.parentNode.childNodes[1].className = 'rpmbody-visible'
        div.style.borderRadius = '5px 5px 0 0'
      }
    },
    testToggle (event) {
      if (event.keyCode === 32) {
        this.toggle(event)
      }
    },
    updateAllowedPermission ({ roleId, permissionId, selectedState }) {
      this.updateAssignedPermissionByRole({
        permissionId,
        roleId
      }, 'allowed', selectedState)
    },
    updateGrantPermission ({ roleId, permissionId, selectedState }) {
      this.updateAssignedPermissionByRole({
        permissionId,
        roleId
      }, 'grant', selectedState)
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    }
  }
}
</script>

<style scoped>
  .rpmSectionContainer{
    width:390px;
    display: none;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom:10px;
  }
  .rpmContainer{
    background-color: rgba(255,255,255,0.7);
    padding:15px 10px 10px 10px;
    max-height: 300px;
    overflow-y: auto;
  }
  .rpmtable {
    margin-top: 5px;
    overflow-y: auto;
    max-height: 320px;
    margin-bottom:5px;
  }
  .rpmtitle{
    background-color:rgba(44,62,80,0.9);
    color: #fff;
    cursor: pointer;
    padding: 3px 5px;
    margin: 10px 0 0 0;
    border-radius: 5px;
    height: 24px;
  }
  .rpmbody{
    display:none;
  }
  .rpmbody-visible{
    display:block;
    border:solid 1px rgba(44,62,80,0.9);
  }
  .toggleIcon {
    float: right;
    padding: 0px;
    height: 18px;
  }
  .toggleIcon img {
    width:18px;
  }
  h4 {margin-bottom: 10px;}
  label {font-weight: bold;}
  select { width: calc(100% - 80px); height: 28px; padding: 0 5px 0 5px; border: none; border-radius: 15px;margin-left: 10px;}
  #cboAreas { width: calc(100% - 100px); height: 28px; padding: 0 5px 0 5px; border: none; border-radius: 15px;margin-left: 10px;display: inline-block;}

</style>
