import { iQuoteConstants } from './iQuoteConstants'
import { mapActions } from 'vuex'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'

export const iQuoteCalculationsBladeServersMixin = {
  mixins: [iQuoteConstants, iQuoteStoreMixin],
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'setQuoteBladeServers'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcBladeServersRequired: function () {
      var quoteVMsRequired = this.iQuoteStore_GetQuoteVMsRequired
      var quoteVMsAvailable = this.iQuoteStore_GetVirtualMachines
      var quoteExpansions = this.iQuoteStore_GetQuoteExpansions

      var farehamDCHighPowerBladeUCRequirements = {
        dataCentreId: this.iQuoteConstants.dataCentres.fareham,
        bladeServerId: this.iQuoteConstants.bladeServers.HPR_UC,
        requiredCPUGhz: 0,
        requiredMemoryGb: 0
      }
      var farehamDCHighPowerBladeCCRequirements = {
        dataCentreId: this.iQuoteConstants.dataCentres.fareham,
        bladeServerId: this.iQuoteConstants.bladeServers.HPR_CC,
        requiredCPUGhz: 0,
        requiredMemoryGb: 0
      }
      var goswellDCHighPowerBladeUCRequirements = {
        dataCentreId: this.iQuoteConstants.dataCentres.goswellRoad,
        bladeServerId: this.iQuoteConstants.bladeServers.HPR_UC,
        requiredCPUGhz: 0,
        requiredMemoryGb: 0
      }
      var goswellDCHighPowerBladeCCRequirements = {
        dataCentreId: this.iQuoteConstants.dataCentres.goswellRoad,
        bladeServerId: this.iQuoteConstants.bladeServers.HPR_CC,
        requiredCPUGhz: 0,
        requiredMemoryGb: 0
      }

      quoteVMsAvailable.forEach(vmAvailable => {
        // Loop through every possible VM

        quoteVMsRequired.forEach(vmRequired => {
          if (vmRequired.virtualMachineId === vmAvailable.id) {
            // If this is a VM that we need then work out how much blade capacity it requires
            var requiredCPUReservation = vmAvailable.specification.cpuReservationGhz * vmRequired.quantity
            var requiredMemoryReservation = vmAvailable.specification.memoryReservationGb * vmRequired.quantity

            if (vmRequired.dataCentreId === this.iQuoteConstants.dataCentres.fareham) {
              if (vmAvailable.categoryId === this.iQuoteConstants.virtualMachineCategories.UC) {
                // High Powered UC Blades in Fareham
                farehamDCHighPowerBladeUCRequirements.requiredCPUGhz += requiredCPUReservation
                farehamDCHighPowerBladeUCRequirements.requiredMemoryGb += requiredMemoryReservation
              } else {
                // High Powered CC Blades in Fareham
                farehamDCHighPowerBladeCCRequirements.requiredCPUGhz += requiredCPUReservation
                farehamDCHighPowerBladeCCRequirements.requiredMemoryGb += requiredMemoryReservation
              }
            } else if (vmRequired.dataCentreId === this.iQuoteConstants.dataCentres.goswellRoad) {
              if (vmAvailable.categoryId === this.iQuoteConstants.virtualMachineCategories.UC) {
                // High Power UC Blades in Goswell
                goswellDCHighPowerBladeUCRequirements.requiredCPUGhz += requiredCPUReservation
                goswellDCHighPowerBladeUCRequirements.requiredMemoryGb += requiredMemoryReservation
              } else {
                // High Power CC Blades in Goswell
                goswellDCHighPowerBladeCCRequirements.requiredCPUGhz += requiredCPUReservation
                goswellDCHighPowerBladeCCRequirements.requiredMemoryGb += requiredMemoryReservation
              }
            }
          }
        }) // End for each required VM
      }) // End for each available VM

      if (quoteExpansions.id !== undefined) {
        var quoteExpansionCustomVms = quoteExpansions.expansionVirtualMachines.filter(x => x.virtualMachineId === null)

        quoteExpansionCustomVms.forEach(vm => {
          var requiredCPUReservation = vm.cpuReservationGhz * vm.quantity
          var requiredMemoryReservation = vm.memoryGb * vm.quantity

          farehamDCHighPowerBladeUCRequirements.requiredCPUGhz += requiredCPUReservation
          farehamDCHighPowerBladeUCRequirements.requiredMemoryGb += requiredMemoryReservation

          if (vm.expansionVmResilienceTypeId === this.iQuoteConstants.expansionVmResilienceTypes.APPLICATION) {
            goswellDCHighPowerBladeUCRequirements.requiredCPUGhz += requiredCPUReservation
            goswellDCHighPowerBladeUCRequirements.requiredMemoryGb += requiredMemoryReservation
          }
        })
      }

      /*
        START
        Take the highest Requirements from Fareham and Goswell and apply to both DCs
      */

      var requiredCPUGhz
      var requiredMemoryGb

      requiredCPUGhz = Math.max(farehamDCHighPowerBladeUCRequirements.requiredCPUGhz, goswellDCHighPowerBladeUCRequirements.requiredCPUGhz)
      requiredMemoryGb = Math.max(farehamDCHighPowerBladeUCRequirements.requiredMemoryGb, goswellDCHighPowerBladeUCRequirements.requiredMemoryGb)
      farehamDCHighPowerBladeUCRequirements.requiredCPUGhz = requiredCPUGhz
      farehamDCHighPowerBladeUCRequirements.requiredMemoryGb = requiredMemoryGb
      goswellDCHighPowerBladeUCRequirements.requiredCPUGhz = requiredCPUGhz
      goswellDCHighPowerBladeUCRequirements.requiredMemoryGb = requiredMemoryGb

      requiredCPUGhz = Math.max(farehamDCHighPowerBladeCCRequirements.requiredCPUGhz, goswellDCHighPowerBladeCCRequirements.requiredCPUGhz)
      requiredMemoryGb = Math.max(farehamDCHighPowerBladeCCRequirements.requiredMemoryGb, goswellDCHighPowerBladeCCRequirements.requiredMemoryGb)
      farehamDCHighPowerBladeCCRequirements.requiredCPUGhz = requiredCPUGhz
      farehamDCHighPowerBladeCCRequirements.requiredMemoryGb = requiredMemoryGb
      goswellDCHighPowerBladeCCRequirements.requiredCPUGhz = requiredCPUGhz
      goswellDCHighPowerBladeCCRequirements.requiredMemoryGb = requiredMemoryGb

      /*
        END
        Take the highest Requirements from Fareham and Goswell and apply to both DCs
       */

      this.addRequiredBladesToQuote(farehamDCHighPowerBladeUCRequirements)
      this.addRequiredBladesToQuote(farehamDCHighPowerBladeCCRequirements)
      this.addRequiredBladesToQuote(goswellDCHighPowerBladeUCRequirements)
      this.addRequiredBladesToQuote(goswellDCHighPowerBladeCCRequirements)
    },
    addRequiredBladesToQuote: function (bladeRequirements) {
      if (bladeRequirements.requiredCPUGhz === 0 || bladeRequirements.requiredMemoryGb === 0) {
        return
      }

      var cpuGhzCapacity
      var memoryGbCapacity

      var quoteBladeServersAvailable = this.iQuoteStore_GetBladeServers
      var quoteBladeServers = this.iQuoteStore_GetQuoteBladeServers

      // Find details of the blade, so we know it's specification and cost
      quoteBladeServersAvailable.forEach(bladeServer => {
        if (bladeServer.id === bladeRequirements.bladeServerId) {
          if (bladeServer.bladeServerTypeId === this.iQuoteConstants.bladeServerTypes.HighPower) {
            cpuGhzCapacity = 84
            memoryGbCapacity = 128.0
          }

          var quantityByMemory = bladeRequirements.requiredMemoryGb / memoryGbCapacity
          var quantityByCpuGhz = bladeRequirements.requiredCPUGhz / cpuGhzCapacity
          var quantityRequired = Math.max(quantityByMemory, quantityByCpuGhz)

          if (quantityRequired > 2.0) {
            // We provision fractional portions of blades if less than 2 required.  Otherwise we round up to nearest whole
            quantityRequired = Math.ceil(quantityRequired)
          }

          quantityRequired = Number(quantityRequired.toFixed(2))
          quantityByCpuGhz = Number(quantityByCpuGhz.toFixed(2))
          quantityByMemory = Number(quantityByMemory.toFixed(2))

          quoteBladeServers.push({
            bladeServerId: bladeRequirements.bladeServerId,
            dataCentreId: bladeRequirements.dataCentreId,
            requiredCPUGhz: bladeRequirements.requiredCPUGhz,
            requiredMemoryGb: bladeRequirements.requiredMemoryGb,
            quantityRequiredByCPUGhz: quantityByCpuGhz,
            quantityRequiredByMemoryGb: quantityByMemory,
            quantityRequired: quantityRequired,
            cost: bladeServer.cost
          })
          // console.log('adding ' + quantityRequired + ' Blade(s).  ServerID ' + bladeRequirements.bladeServerId + ' to DC ' + bladeRequirements.dataCentreId)
        }
      })

      this.setQuoteBladeServers(quoteBladeServers)
    }
  }
}
