import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'
import { mapActions } from 'vuex'

export const iQuoteCalculationsMitelCCMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
      quoteTechnologyMitelCC: {},
      quoteTechnologyMitelUC: {},
      quote: {},
      mitelCCOutboundUsers: 0,
      licenses: {}
    }
  },
  computed: {
    mitelCCCalcs_usersToHandle () {
      var users = 0

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteTechnologyMitelUC.userProfiles.forEach(userProfile => {
        if (userProfile.description === 'Basic' ||
            userProfile.description === 'Entry' ||
            userProfile.description === 'Standard' ||
            userProfile.description === 'Premium' ||
            userProfile.description === 'Ultimate') {
          users += userProfile.maxAllowed
        }
      })
      return users
    },
    mitelCCCalcs_agentsToHandle () {
      var users = 0

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteTechnologyMitelCC.userProfiles.forEach(userProfile => {
        users += userProfile.maxAllowed
      })
      return users
    },
    mitelCCCalcs_agentsToLicense () {
      var users = 0

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteTechnologyMitelCC.userProfiles.forEach(userProfile => {
        users += userProfile.minCommitment
      })
      return users
    },
    mitelCCCalcs_multiChannelAgentsToHandle () {
      var users = 0

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteTechnologyMitelCC.userProfiles.forEach(userProfile => {
        if (userProfile.description.toLowerCase().includes('multi-channel')) {
          users += userProfile.maxAllowed
        }
      })
      return users
    },
    mitelCCCalcs_voiceAgentsToHandle () {
      var users = 0

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteTechnologyMitelCC.userProfiles.forEach(userProfile => {
        if (userProfile.description.toLowerCase().includes('voice agent')) {
          users += userProfile.maxAllowed
        }
      })
      return users
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyMitelCC'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcMitelCCVMs: function () {
      var quantity = 0

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined || this.mitelCCCalcs_voiceAgentsToHandle === 0) {
        return
      }

      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICC_SQL, this.iQuoteConstants.dataCentres.fareham, true, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICC_SQL, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)

      if (this.mitelCCCalcs_voiceAgentsToHandle <= 100 && this.mitelCCCalcs_multiChannelAgentsToHandle === 0) {
        quantity = 1
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_SML, this.iQuoteConstants.dataCentres.fareham, true, quantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_SML, this.iQuoteConstants.dataCentres.goswellRoad, false, quantity)
      } else if (this.mitelCCCalcs_voiceAgentsToHandle <= 500 && this.mitelCCCalcs_multiChannelAgentsToHandle === 0) {
        quantity = 1
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_MED, this.iQuoteConstants.dataCentres.fareham, true, quantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_MED, this.iQuoteConstants.dataCentres.goswellRoad, false, quantity)
      } else if (this.mitelCCCalcs_voiceAgentsToHandle > 500 || this.mitelCCCalcs_multiChannelAgentsToHandle > 0) {
        quantity = 1
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_LRG, this.iQuoteConstants.dataCentres.fareham, true, quantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_LRG, this.iQuoteConstants.dataCentres.goswellRoad, false, quantity)
      }

      this.calcMitelCCAdjunct_VMs()
    },
    calcMitelCCAdjunct_VMs: function () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.adjuncts.wfm) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFM, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFM, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.adjuncts.dpa) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.DPA, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.DPA, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.adjuncts.speechAnalysis) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SPEECH_ANALYSIS, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SPEECH_ANALYSIS, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.adjuncts.avayaWFOOptionId === this.iQuoteConstants.avayaWFOOptions.VERINT) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_VERINT, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_VERINT, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (this.iQuoteStore_GetQuoteTechnologyMitelCC.adjuncts.avayaWFOOptionId === this.iQuoteConstants.avayaWFOOptions.AVAYA) {
        // TODO: Add VMs here if required
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_AVAYA, this.iQuoteConstants.dataCentres.fareham, true, 1)
        // this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.WFO_AVAYA, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }
    },
    calcMitelCCAdjunctLicenses: function () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined) {
        return
      }

      this.licenses = this.$store.getters.licenses

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.options.ivrPorts > 0 && this.iQuoteStore_GetQuote.quoteLicenseTypeId !== this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_IVRPORTS, this.iQuoteStore_GetQuoteTechnologyMitelCC.options.ivrPorts)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.adjuncts.wfm) {
        // TODO: Add Licenses here if required
        // this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.WFM, 1)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.adjuncts.dpa) {
        // TODO: Add Licenses here if required
        // this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DPA, 1)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.adjuncts.speechAnalysis) {
        // TODO: Add Licenses here if required
        // this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SPEECH_ANALYSIS, 1)
      }
    },
    calcMitelCCUserLicenses: function () {
      this.licenses = this.$store.getters.licenses
      var userProfileLicenses = this.$store.getters.userProfileLicenses
      var requiredLicenseId = 0
      var tierId = 1
      var quoteTechnologyMitelCC = this.iQuoteStore_GetQuoteTechnologyMitelCC

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined) {
        return
      }

      if (this.mitelCCCalcs_usersToLicense > 500) {
        tierId = 3
      } else if (this.mitelCCCalcs_usersToLicense > 250) {
        tierId = 2
      } else {
        tierId = 1
      }

      for (let index = 0; index < quoteTechnologyMitelCC.userProfiles.length; index++) {
        const userProfile = quoteTechnologyMitelCC.userProfiles[index]
        userProfile.annualPrice = 0
        userProfile.rate = 0
        userProfile.annualTotalCost = 0
        requiredLicenseId = 0

        for (let index2 = 0; index2 < userProfileLicenses.length; index2++) {
          const userProfileLicense = userProfileLicenses[index2]
          if (userProfileLicense.technologyUserProfileId === userProfile.technologyUserProfileId &&
              userProfileLicense.quoteLicenseTypeId === this.iQuoteStore_GetQuote.quoteLicenseTypeId &&
              userProfileLicense.quoteServiceLevelTypeId === this.iQuoteStore_GetQuote.quoteServiceLevelTypeId &&
              userProfileLicense.licenseTierId === tierId) {
            requiredLicenseId = userProfileLicense.licenseId

            if (requiredLicenseId !== 0) {
              this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
              userProfile.annualTotalCost += userProfile.minCommitment * this.utilFunctions_GetLicenseCost(requiredLicenseId) * 12
              userProfile.annualPrice += userProfile.minCommitment * this.utilFunctions_GetLicensePrice(requiredLicenseId) * 12
              userProfile.rate += this.utilFunctions_GetLicensePrice(requiredLicenseId)
            }
          }
        }
      }

      this.setQuoteTechnologyMitelCC(quoteTechnologyMitelCC)
    }
  }
}
