<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconContact"
        class="iconButton"
      >
      <br>
      <input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <br>
      <br>
      <h3 class="appDarkFont">Adjuncts</h3>
      <label
        class=""
        for="cboWFO"
      >WFO</label>
      <select
        id="cboWFO"
        v-model="quoteTechnologyCallmedia.adjuncts.avayaWFOOptionId"
        class="small"
      >
        <option
          v-for="w in wfo"
          :key="w.id"
          :value="w.id"
        >{{ w.name }}</option>
      </select>
      <br>
      <label
        class=""
        for="chkDpa"
      >dpa</label>
      <img
        :src="question"
        class="question"
        @click="showHelp('dpa')"
      >
      <input
        id="chkDpa"
        v-model="quoteTechnologyCallmedia.adjuncts.dpa"
        type="checkbox"
        class="small"
      >
      <label
        class=""
        for="cbowfm"
      >Speech Analysis</label>
      <img
        :src="question"
        class="question"
        @click="showHelp('speechanalysis')"
      >
      <input
        id="cboEnablespeechAnalysis"
        v-model="quoteTechnologyCallmedia.adjuncts.speechAnalysis"
        type="checkbox"
        class="small"
      >
      <label
        class=""
        for="cbowfm"
      >WFM</label>
      <img
        :src="question"
        class="question"
        @click="showHelp('wfm')"
      >
      <input
        id="cboEnablespeechAnalysis"
        v-model="quoteTechnologyCallmedia.adjuncts.wfm"
        type="checkbox"
        class="small"
      >
      <br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('UserProfilesCallmedia')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'AdjunctsCallmedia',
  mixins: [ShowHelpMixin, iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions],
  data () {
    return {
      wfo: [],
      quote: {},
      quoteTechnologyCallmedia: {
        userProfiles: [],
        adjuncts: {
          wfo: [],
          speechAnalysis: false,
          wfm: false,
          dpa: false,
          avayaWFOOptionId: 1
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconContact () {
      return require(`../../../assets/icons/iconContact.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidMaxConcurrentCalls () {
      if (this.quoteTechnologyCallmedia.adjuncts.speechAnalysis === false) {
        return true
      }

      var value = this.quoteTechnologyCallmedia.adjuncts.maxConcurrentCalls
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidNumCallsPerAgentPerDay () {
      if (this.quoteTechnologyCallmedia.adjuncts.speechAnalysis === false) {
        return true
      }

      var value = this.quoteTechnologyCallmedia.adjuncts.numCallsPerAgentPerDay
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidAvgCallDuration () {
      if (this.quoteTechnologyCallmedia.adjuncts.speechAnalysis === false) {
        return true
      }

      var value = this.quoteTechnologyCallmedia.adjuncts.avgCallDuration
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidRetentionPeriod () {
      if (this.quoteTechnologyCallmedia.adjuncts.speechAnalysis === false) {
        return true
      }

      var value = this.quoteTechnologyCallmedia.adjuncts.retentionPeriod
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    this.wfo = this.$store.getters.wfooptions
    this.quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia
    if (this.quoteTechnologyCallmedia.id === undefined) {
      this.quoteTechnologyCallmedia.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.Callmedia)
      // Using $set, as directly assigning to the arrays was not updating the DOM
      var userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyCallmedia.id)
      var adjuncts = await this.getQuoteCallmediaAdjuncts(this.quote.id)

      this.$set(this.quoteTechnologyCallmedia, 'userProfiles', userProfiles)
      this.$set(this.quoteTechnologyCallmedia, 'adjuncts', adjuncts)

      this.setQuoteTechnologyCallmedia(this.quoteTechnologyCallmedia)
    }

    // TODO: Remove this when we add support for setting Callmedia Adjuncts
    this.finishService()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyCallmedia'
    ]),
    gotoPage (page) {
      this.setQuoteTechnologyCallmedia(this.quoteTechnologyCallmedia)
      this.setCurrentQuoteState(page)
    },
    async finishService () {
      // Save to DB only if valid data is supplied
      await this.saveQuoteCallmediaAdjuncts(this.quoteTechnologyCallmedia.adjuncts)
      this.setQuoteTechnologyCallmedia(this.quoteTechnologyCallmedia)
      this.gotoPage('SelectService')
    }
  }
}
</script>
<style scoped>
  .small{width:60px;}
</style>
