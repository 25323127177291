<template>
  <div class="quotePanel">
    <label
      :class="{InputError: createStandaloneDesignPackComponent.contactId === 0}"
      for="cboContact"
    >Choose Contact:</label>
    <select
      v-if="salesforceOpportunity.contacts !== undefined"
      id="cboContact"
      v-model="createStandaloneDesignPackComponent.contactId"
    >
      <option
        v-for="c in salesforceOpportunity.contacts.filter(x => x.email !== null)"
        :key="c.id"
        :value="c.id"
        :title="c.firstName + ' ' + c.lastName"
        :selected="c.id === salesforceOpportunity.contactId"
      >{{ c.firstName + ' ' + c.lastName }}</option>
    </select>
    <br>
    <label
      :class="{InputError: createStandaloneDesignPackComponent.name === ''}"
      for="txtDesignPackName"
    >Designpack Name:
      <TooltipIcon
        width="500"
        text="Descriptive name for this design pack.  Do not include the revision number in this name" />
    </label>
    <input
      id="txtDesignPackName"
      v-model="createStandaloneDesignPackComponent.name"
      type="text"
    >
    <br>
    <label
      :class="{InputError: createStandaloneDesignPackComponent.termLengthMonths < 0 || createStandaloneDesignPackComponent.termLengthMonths > 60}"
      for="txtDesignPackTermLengthMonths"
    >Term Length (Months):
      <TooltipIcon
        width="400"
        text="How many months over which the customer will be charged" />
    </label>
    <input
      id="txtDesignPackTermLengthMonths"
      v-model.number="createStandaloneDesignPackComponent.termLengthMonths"
      type="number"
    >
    <br>
    <br>
    <input
      v-if="createStandaloneDesignPackComponent.contactId !== 0 && createStandaloneDesignPackComponent.name !== '' &&
      createStandaloneDesignPackComponent.termLengthMonths > 0 && createStandaloneDesignPackComponent.termLengthMonths <= 60"
      id="btnCreateDesignPackWithNoQuote"
      value="Create Design Pack"
      type="button"
      @click="createBlankQuoteDesignPack()"
    >
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { mapActions } from 'vuex'
import { iQuoteAdjunctDataAPIMixin } from '../../../mixins/iQuote/iQuoteAdjunctDataAPIMixin'
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import tableHandler from '../../../mixins/tableHandler'
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'
import quoteSummary from '../panels/quoteSummary'
import { usersMixin } from '../../../mixins/users'
import { iQuoteLoaderMixin } from '../../../mixins/iQuote/utils/iQuoteLoaderMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'

export default {
  name: 'CreateStandaloneDesignPack',
  components: {
    TooltipIcon,
    SectionCollapsedHeader,
    quoteSummary
  },
  mixins: [
    usersMixin,
    tableHandler,
    iQuoteConstants,
    ShowHelpMixin,
    iQuoteAdjunctDataAPIMixin,
    iQuoteQuoteAPIMixin,
    iQuoteFunctions,
    iQuoteStoreMixin,
    iQuoteLoaderMixin,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackMixin
  ],
  props: {
    salesforceQuoteNumber: {
      type: String,
      default: function () {
        return ''
      }
    },
    salesforceOpportunity: {
      type: Object,
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      createStandaloneDesignPackComponent: {
        contactId: 0,
        name: '',
        termLengthMonths: 36
      }
    }
  },
  computed: {
  },
  mounted () {
    this.createStandaloneDesignPackComponent.contactId = ''
    this.createStandaloneDesignPackComponent.name = ''
  },
  created () {
  },
  updated () {
  },
  methods: {
    ...mapActions([
    ]),
    async createBlankQuoteDesignPack () {
      var contact = this.salesforceOpportunity.contacts.find(x => x.id === this.createStandaloneDesignPackComponent.contactId)

      var opportunity = {
        opportunityId: this.salesforceOpportunity.opportunityID,
        quoteNumber: this.salesforceQuoteNumber,
        contactFirstName: contact.firstName,
        contactLastName: contact.lastName,
        contactEmail: contact.email,
        opportunityName: this.salesforceOpportunity.opportunityName,
        accountName: this.salesforceOpportunity.accountName
      }
      var returnedOpportunity = await this.createOpportunity(opportunity)

      var salesforceOpportunityId = returnedOpportunity.id

      await this.createDesignPack(salesforceOpportunityId)
    },
    async createDesignPack (salesforceOpportunityId) {
      var newDesignPack = {
        quoteDesignPackId: 0,
        name: this.createStandaloneDesignPackComponent.name,
        quoteId: null,
        salesforceOpportunityId: salesforceOpportunityId,
        termLengthMonths: this.createStandaloneDesignPackComponent.termLengthMonths,
        quoteDesignPackRevisionId: 0,
        revisionNumber: 1,
        revisions: [{
          id: 0,
          revisionNumber: 1
        }],
        users: [],
        description: '',
        manufacturerCode: '',
        projectName: '',
        numberOfSites: 1,
        newOrExistingCustomer: '',
        upgradeMigration: false,
        pmoReviewer: '',
        maintenanceTakeOn: false,
        contractFrameworkId: 1,
        professionalServicesReview: false,
        newManufacturerCodeRequired: false,
        contractIdCoterminatedWith: '',
        showPSTab: true,
        showAutotaskTab: true,
        showHardwareTab: false,
        showCircuitsTab: false,
        showStandardCaveatsTab: false,
        showCeasesTab: false,
        dpExported: false,
        psCoreEntries: [],
        psOptionsEntries: [],
        psExpenseEntries: [],
        psThirdPartyInstallationEntries: [],
        psThirdPartyOtherEntries: [],
        hardwareMainEntries: [],
        hardwareMiscEntries: [],
        circuitsEquinixCloudConnectEntries: [],
        circuitsWanConnectivityEntries: [],
        circuitsHostingAndCablingEntries: [],
        circuitsBroadbandEntries: [],
        circuitsFixedLineVoiceEntries: [],
        circuitsIpVpnSetupEntries: [],
        margins: [],
        discounts: [],
        priceOverrides: [],
        exchangeRateUSDToGPB: null,
        exchangeRateUSDToGPBDate: null,
        exchangeRateGBPToEUR: null,
        exchangeRateGBPToEURDate: null,
        autotaskCustAccNo: '',
        locked: false,
        createdByUserId: this.$store.getters.userid,
        hldReference: '',
        pmoReview: '',
        createdBy: '',
        disabledEntries: [],
        addPCTime: false,
        useDetailDataForSummaries: true,
        chargeUpfront: false,
        ccUsers: 0,
        ucUsers: 0,
        ccUsersOverride: null,
        ucUsersOverride: null,
        standardCaveats: '',
        ceasesEntries: [],
        marginCircuitsEquinixCloudConnectCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsEquinixCloudConnectOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsHostingAndCablingCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsHostingAndCablingOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsWanConnectivityCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsWanConnectivityOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsBroadbandCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsBroadbandOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsFixedLineVoiceCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsFixedLineVoiceOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginHardwareMainPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginHardwareMiscEquipmentPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        isReadOnly: false,
        quoteDesignPackTypeId: this.iQuoteConstants.QuoteDesignPackTypes.STANDALONE,
        externalDesignPackFileUploadCatalogId: null,
        externalDPEntries: [],
        editedDesignOverviewEntry: null,
        editedDetailedSummaryBreakdownEntries: [],
        editedAutotaskEntries: [],
        serviceLevelTypeId: 0,
        ccaasProvider: '',
        ucaasProvider: '',
        removedPCTime: false,
        lastDesignPackItemLookupId: 0,
        priceOverrideARFrameworkCharge: null,
        priceOverrideOOFrameworkCharge: null,
        priceOverrideOrderCoordination: null,
        disableSupportContingency: false
      }

      var savedDesignPack = await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(newDesignPack)

      const action = 'Created standalone DP'
      await this.iQuoteQuoteDesignPacksAPIMixin_addAuditing(action, 1, null)

      this.$emit('designpack-selected', savedDesignPack)
    }
  }
}
</script>
<style scoped>
  .quotePanel {
    width: 580px;
    text-align: left;
  }

  #txtDesignPackName {
    width: 300px;
  }

  #txtDesignPackTermLengthMonths {
    width: 50px;
  }
</style>
