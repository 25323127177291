<template>
  <div
    :id="customerid"
    class="customerHolder"
  >
    <Spinner v-if="loading" />
    <div
      v-else
    >
      <label
        for="txtUpdateCustomerName"
        class="customerDetailLabel"
      >
        Name:
      </label>
      <input
        v-if="editCustomerPermission"
        id="txtUpdateCustomerName"
        :class="{ errorHighlight: customerNameError }"
        v-model="customer.name"
        class="customerDetailInput"
        type="text"
        @blur="checkForm"
      >
      <label
        for="txtUpdateCustomerTopLevelSite"
        class="customerDetailLabel"
      >
        Top Level Site:
      </label>
      <input
        v-if="editCustomerPermission"
        id="txtUpdateCustomerTopLevelSite"
        :class="{ errorHighlight: customerTopLevelSiteError }"
        v-model="customer.topLevelAutotaskSite"
        class="customerDetailInput"
        type="number"
        @blur="checkForm"
      >
      <label
        for="txtUpdateCustomerSalesforceAccount"
        class="customerDetailLabel"
      >
        Salesforce A/C:
      </label>
      <input
        v-if="editCustomerPermission"
        id="txtUpdateCustomerSalesforceAccount"
        :class="{ errorHighlight: customerSalesforceAccountError }"
        v-model="customer.salesforceAccountId"
        class="customerDetailInput"
        type="text"
        @blur="checkForm"
      >
      <label
        for="txtUpdateCustomerTraverseParentContainerId"
        class="customerDetailLabel"
      >
        Traverse Id:
      </label>
      <label
        id="txtUpdateCustomerTraverseParentContainerId"
        class="customerDetailInput"
      > {{ traverseParentContainerId }}
      </label>
      <label
        v-if="!editCustomerPermission"
        id="lblUpdateCustomerName"
        class="customerDetailData"
      >{{ customer.name }}</label>
      <label
        for="txtUpdateCustomerEnabled"
        class="customerDetailLabel"
      >
        Enabled:
      </label>
      <TriStateToggleSwitch
        id="txtUpdateCustomerEnabled"
        ref="enabled-toggle"
        :enabled="editCustomerPermission"
        :current-state="customer.enabled ? 1 : 2"
        :tri-state="false"
        class="toggleSwitch"
        style="float: right; margin-right: 5px;"
        @statechanged="selectCustomerEnabled"
      />
      <Spinner v-if="isSaving" />
      <div
        v-if="editCustomerPermission && !isSaving"
        class="updateButtonCustomer"
      >
        <input
          id="btnUpdateArea"
          :disabled="!formOK"
          type="button"
          class="customerUpdateButton"
          value="Update Top Level Site"
          title="Updates the name and enabled state of the top level site"
          @click="updateCustomerDetails()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { permissionsMixin } from '../../../mixins/permissions'
import { traverseMixin } from '../../../mixins/traverse'
import Spinner from '../../common/spinner'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
export default {
  name: 'CustomerDetails',
  components: {
    Spinner,
    TriStateToggleSwitch
  },
  mixins: [
    permissionsMixin,
    traverseMixin
  ],
  props: {
    customerid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      customer: {},
      traverseParentContainerId: '',
      loading: false,
      formOK: false,
      customerNameError: false,
      customerTopLevelSiteError: false,
      customerSalesforceAccountError: false,
      traverseParentContainerIdError: false,
      isSaving: false
    }
  },
  computed: {
    editCustomerPermission () {
      return this.$store.getters.editCustomerPermission
    },
    customers () {
      return this.$store.getters.customers
    }
  },
  watch: {
    customerid () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    setComponent: async function () {
      this.loading = true
      if (this.customerid > -1) {
        this.customer = this.customers.find(customer => customer.id === this.customerid)
        await this.getCompanyContainer(this.customer.companyId)
        if (this.$store.getters.currentCompanyParentContainer.length > 0) {
          this.traverseParentContainerId = this.$store.getters.currentCompanyParentContainer[0].containerId
        } else {
          this.traverseParentContainerId = ''
        }
        this.checkForm()
      }
      this.loading = false
    },
    updateCustomerDetails: async function () {
      this.isSaving = true
      if (this.formOK) {
        this.updateCustomer(this.customer).then(async () => {
          await this.updateCompanyContainer(this.customer.id, this.traverseParentContainerId).then(async () => {
            await this.getCompanyContainer(this.customer.companyId).then(async () => {
              var parentContainers = this.$store.getters.currentCompanyParentContainer.map(({ containerId }) => ({ containerId }))
              await this.getChildContainers(parentContainers)
              this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
            })
          })
        })
        this.isSaving = false
      } else {
        this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
        this.isSaving = false
      }
    },
    checkForm: function () {
      this.customerNameError = this.customer.name.length === 0 || this.customer.name.length > 100
      this.customerTopLevelSiteError = this.customer.topLevelAutotaskSite.length < 2
      this.traverseParentContainerIdError = this.traverseParentContainerId.length < 5 || this.traverseParentContainerId.length > 9
      if (this.customer.salesforceAccountId) {
        this.customerSalesforceAccountError = this.customer.salesforceAccountId.length === 0 || this.customer.salesforceAccountId.length > 38
      }
      var error = this.customerNameError

      if (error) {
        var errorMessage = 'Please fix the issues with the following field: ' +
        (this.customerNameError ? 'customer name' : '') +
        (this.customerTopLevelSiteError ? 'top level site' : '') +
        (this.customerSalesforceAccountError ? 'Salesforce account' : '') +
        (this.traverseParentContainerIdError ? 'Traverse parent container id' : '')
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !error
    },
    selectCustomerEnabled ({ selectedState }) {
      this.customer.enabled = selectedState === 1
    }
  }
}
</script>
<style scoped>
  .customerHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }
  .customerDetailData {
    width: 260px;
    display: inline-block;
  }
  .customerDetailLabel {
    width: 120px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .customerDetailInput {
    width: 236px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .customerUpdateButton {
    float:right;
    cursor: pointer;
  }
  .customerUpdateButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
</style>
