<template>
  <div
    id="changePassword"
    class="pccSectionContainer"
  >
    <div class="pccContainer" >
      <label
        for="pwdChangePasswordNew"
        class="pccLabel"
      >
        New Password:
      </label>
      <input
        id="pwdChangePasswordNew"
        v-model="password"
        type="password"
        class="pccPassword"
      >
      <label
        for="pwdChangePasswordConfirm"
        class="pccLabel"
      >
        Confirm Password:
      </label>
      <input
        id="pwdChangePasswordConfirm"
        v-model="confirmPassword"
        type="password"
        class="pccPassword"
      >
      <div class="pccButtonContainer">
        <input
          id="btnChangePassword"
          value="Apply Change"
          type="button"
          class="acaButton"
          @click="sendPasswordChange()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import PortalButton from '../common/portalButton'
import PortalTextBox from '../common/portalTextBox'
import AuthRepository from '../../communications/repositories/authRepository'

export default {
  name: 'PasswordChange',
  components: {
    PortalButton,
    PortalTextBox
  },
  mixins: [
    AuthRepository
  ],
  props: {
    username: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      password: '',
      confirmPassword: ''
    }
  },
  methods: {
    sendPasswordChange () {
      if (!this.isValidNewPassword()) {
        this.$store.commit('showMessage', { content: 'Please ensure the new password and confirm password fields match and are between 8 and 255 characters long.', color: 'red', timeout: 5000 })
        return
      }

      this.authRequestSendPasswordChange(this.username, this.password, '')
      this.password = ''
      this.confirmPassword = ''
    },
    isValidNewPassword () {
      var validNewPasswordLength = this.password.length >= 8 && this.password.length < 255
      var validConfirmPasswordLength = this.confirmPassword.length >= 8 && this.confirmPassword.length < 255
      var newPasswordAndConfirmPasswordMatch = this.password === this.confirmPassword

      return validNewPasswordLength && validConfirmPasswordLength && newPasswordAndConfirmPasswordMatch
    }
  }
}
</script>
<style scoped>
  .pccSectionContainer {
    width:100%;
    margin-bottom:10px;
  }
  .pccContainer {
    background-color: rgba(255,255,255,0.7);
    padding:10px;
    margin-bottom:10px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .acaButton {
    margin: 10px 0 0 270px;
  }
  .pccLabel {
    font-weight: bold;
    width: 160px;
    display: inline-block;
  }
  .pccPassword {
    height: 30px;
    width: 220px;
    padding:0 0 0 5px;
    margin-top:10px;
  }
  .pccContainer.pccIsPasswordResetRequest .pccPassword {
    margin-top: 0px;
  }

  .pccButtonContainer {
      padding:0px;
  }
</style>
