<template>
  <div class="createAdminContainer">
    <h2>Admin Licenses</h2>
    <p>This section will eventually contain editors to change global parameters such as pricing, and virtual machine specs</p>
  </div>
</template>

<script>

export default {
  name: 'AdminLicenses',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style scoped>

</style>
