// All paths are relative to /src/views
export default [
  {
    path: '',
    view: 'Logon'
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    view: 'UserProfile'
  },
  {
    path: '/home',
    name: 'Home',
    view: 'QuotePage'
  },
  {
    path: '/iquote',
    name: 'iQuote',
    view: 'QuotePage'
  },
  {
    path: '/user-management',
    name: 'User Management',
    view: 'UserManagement'
  },
  {
    path: '/iquote/quotes/:searchText',
    name: 'iQuoteQuoteSearch',
    view: 'QuotePage'
  },
  {
    path: '/iquote/designpacks/:designpackrevisionid',
    name: 'iQuoteDPRevision',
    view: 'QuotePage'
  },
  {
    path: '/iquote/designpacks/:designpackid/r:designpackRevisionNumber',
    name: 'iQuoteDPByRevisionNumber',
    view: 'QuotePage'
    },
  {
    path: '/security',
    name: 'Security',
    view: 'Security'
  },
  {
    path: '/setpassword',
    name: 'Set Password',
    view: 'SetPassword'
  },
  {
    path: '/accessrequest',
    name: 'Access Request',
    view: 'AccessRequest'
  },
  {
    path: '/logout'
  },
  {
    path: '/my-account',
    name: 'My Account',
    view: 'UserProfile'
  },
  {
    path: '/help',
    name: 'Help',
    view: 'Help'
  },
  {
    path: '/support',
    name: 'Support',
    view: 'Support'
  },
  {
    path: '/service-overview',
    name: 'Service Overview',
    view: 'ServiceOverview'
  }
]
