import autotaskService from '../communications/interceptors/apiInterceptor'
import { DataEventBus } from '../events/dataEvents'
import { mapActions } from 'vuex'
export const autotaskServiceMixin = {
  data () {
    return {
      alreadyGettingTickets: false,
      alreadyGettingSites: false
    }
  },
  methods: {
    ...mapActions([
      'setProjectSummary',
      'setTopLevelProjectSummary',
      'setTopLevelSitesSummary',
      'setContractBlocks',
      'setTickets',
      'setPMNTickets',
      'setTicketUDFs',
      'setSites'
    ]),
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
    },
    ticketsGet: async function () {
      var sites = this.$store.getters.sites
      if (sites && sites.length > 0 && !this.alreadyGettingTickets) {
        this.alreadyGettingTickets = true
        DataEventBus.$emit('loading-tickets')
        var siteList = ''
        for (let i = 0; i < sites.length; i++) {
          siteList += sites[i].id + ','
        }
        siteList = siteList.slice(0, -1)
        await autotaskService.post('/autotaskservice/tickets/sitelist', {
          siteList: siteList
        })
          .then(res => {
            if (res.data.length > 0) {
              this.setTickets(res.data)
            } else {
              this.setTickets([])
            }
            this.alreadyGettingTickets = false
            DataEventBus.$emit('tickets-loaded')
          })
          .catch((err) => {
            // eslint-disable-next-line
            this.setTickets([])
            console.log('Error getting tickets with error: ' + err)
            this.alreadyGettingTickets = false
          })
      }
    },
    ticketUDFsGet: async function () {
      var sites = this.$store.getters.sites
      if (sites && sites.length > 0 && !this.alreadyGettingTickets) {
        this.alreadyGettingTickets = true
        DataEventBus.$emit('loading-tickets')
        var siteList = ''
        for (let i = 0; i < sites.length; i++) {
          siteList += sites[i].id + ','
        }
        siteList = siteList.slice(0, -1)
        await autotaskService.post('/autotaskservice/tickets/udf', {
          siteList: siteList
        })
          .then(res => {
            if (res.data.length > 0) {
              this.setTicketUDFs(res.data)
            } else {
              this.setTicketUDFs([])
            }
            this.alreadyGettingTickets = false
            DataEventBus.$emit('tickets-loaded')
          })
          .catch((err) => {
            // eslint-disable-next-line
            this.setTicketUDFs([])
            console.log('Error getting ticket UDFs with error: ' + err)
            this.alreadyGettingTickets = false
          })
      }
    },
    pmnTicketsGet: async function () {
      var sites = this.$store.getters.sites
      if (sites && sites.length > 0) {
        var siteList = ''
        for (let i = 0; i < sites.length; i++) {
          siteList += sites[i].id + ','
        }
        siteList = siteList.slice(0, -1)
        await autotaskService.post('/autotaskservice/pmn/sitelist', {
          siteList: siteList
        })
          .then(res => {
            if (res.data.length > 0) {
              this.setPMNTickets(res.data)
            } else {
              this.setPMNTickets([])
            }
          })
          .catch((err) => {
            // eslint-disable-next-line
            this.setPMNTickets([])
            console.log('Error getting PMN tickets with error: ' + err)
          })
      }
    },
    addAutotaskServiceTicket: async function (ticket) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      await autotaskService.put('/autotaskservice/tickets', JSON.stringify(ticket), axiosConfig)
        .then(res => {
          DataEventBus.$emit('tickets-loaded')
        })
        .catch((err) => {
          console.log('Error adding autotask service ticket with error: ' + err)
        })
    },
    sitesGetFull: async function () {
      var sites = this.$store.getters.sites
      if (sites && sites.length > 0 && !this.alreadyGettingSites) {
        this.alreadyGettingSites = true
        DataEventBus.$emit('loading-sites')
        var siteList = ''
        for (let i = 0; i < sites.length; i++) {
          siteList += sites[i].id + ','
        }
        siteList = siteList.slice(0, -1)
        await autotaskService.post('/autotaskservice/sites/sitelist', {
          siteList: siteList
        })
          .then(res => {
            if (res.data.length > 0) {
              this.setSites(res.data)
            } else {
              this.setSites([])
            }
            this.alreadyGettingSites = false
            DataEventBus.$emit('sites-loaded')
          })
          .catch((err) => {
            // eslint-disable-next-line
            this.setTickets([])
            console.log('Error getting sites with error: ' + err)
            this.alreadyGettingSites = false
          })
      }
    },
    sitesGetById (id) {
      if (id) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        return autotaskService
          .get('/autotaskservice/sites/id/' + id, axiosConfig)
          .then(res => {
            return res.data
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log("error getting sites", err)
            return []
          })
      } else {
        return []
      }
    },
    getUnassignedTopLevelSites: async function () {
      var data = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        // Get the roles that user is entitled to see
        await autotaskService.get('/autotaskservice/sites/parent/0', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        console.log('Error in getUnassignedTopLevelSites with message: ' + error)
      }
      return data
    },
    getContactById: async function (id) {
      var rtn = {}
      if (id) {
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'CustomerId': this.$store.getters.currentCustomer.id
          }
        }
        await autotaskService
          .get('/autotaskservice/contacts/' + id, axiosConfig)
          .then(res => {
            rtn = res.data
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log("error getting contacts", err)
          })
      }
      return rtn
    },
    getTopLevelSummary: async function (sites) {
      if (!sites || sites.length === 0) {
        return
      }
      var axiosConfig = this.getHeaders()
      try {
        await autotaskService.post(`/autotaskservice/projects/toplevelsummary`, `{"siteList":"${sites.map(({ areaId }) => areaId).join(',')}"}`, axiosConfig)
          .then((response) => {
            this.setTopLevelProjectSummary(response.data)
          })
          .catch((rtn) => {
            console.log('Error calling getTopLevelSummary with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling getTopLevelSummary with exception:' + e)
      }
    },
    getProjectSummary: async function (sites, callback) {
      this.setProjectSummary([])
      if (!sites || sites.length === 0) {
        return
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await autotaskService.post(`/autotaskservice/projects/projectsummary`, `{'siteList':'${sites.map(({ areaId }) => areaId).join(',')}'}`, axiosConfig)
          .then((response) => {
            this.setProjectSummary(response.data)
            if (callback) {
              callback()
            }
          })
          .catch((rtn) => {
            console.log('Error calling getProjectSummary with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling getProjectSummary with exception:' + e)
      }
    },
    getSiteProjectsSummary: async function (sites) {
      if (!sites || sites.length === 0) {
        return
      }
      var axiosConfig = this.getHeaders()
      try {
        await autotaskService.post(`/autotaskservice/projects/site`, `{'siteList':'${sites.map(({ areaId }) => areaId).join(',')}'}`, axiosConfig).then((response) => {
          this.setTopLevelSitesSummary(response.data
            ? response.data.map((project) => ({
              ...project,
              status: project.completeDate === null || (project.completeDate && new Date(project.completeDate) >= new Date()) ? 'open' : 'closed'
            }))
            : []
          )
        })
      } catch (e) {
        console.log('Error calling getSiteProjectsSummary with exception:' + e)
      }
    },
    getProductsSummary: async function (siteId) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskService.get(`/autotaskservice/products/site/${siteId}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getProductsSummary with exception:' + e)
      }
      return rtn
    },
    getContractBlocks: async function (siteId) {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
      try {
        await autotaskService.get(`/autotaskservice/contractblocks/site/${siteId}`, axiosConfig).then((response) => {
          this.setContractBlocks(response.data)
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getContractBlocks with exception:' + e)
      }
      return rtn
    },
    getAutotaskContact: async function (email) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskService.get(`/autotaskservice/contacts/email/${email}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getAutotaskContact with email: ' + email)
      }
      return rtn
    },
    addAutotaskContact: async function (payload) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskService.put(`/autotaskservice/contacts`, JSON.stringify(payload), axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling addAutotaskContact with email: ' + payload.emailAddress)
      }
      return rtn
    },
    getConfigurationItemTypesBySite: async function (siteId) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskService.get(`/autotaskservice/configurationitems/type/` + siteId, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getConfigurationItemTypesBySite with siteId: ' + siteId)
      }
      return rtn
    },
    saveTicketNoteToDatabase: async function (note) {
      if (!note) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskService.put(`/autotaskservice/ticketnotes`, note, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling saveTicketNoteToDatabase with note: ' + note)
        rtn = 'error'
      }
      return rtn
    },
    getTicketNotes: async function (id) {
      if (!id) {
        return
      }
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await autotaskService.get(`/autotaskservice/ticketnotes/getAllNotes/${id}`, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getTicketNotes with id: ' + id)
        rtn = 'Error: Unable to retrieve the ticket notes'
      }
      return rtn
    }
  }
}
