<template>
  <div
    class="securityGroupsHolder"
  >
    <Spinner
      v-if="loading"
      id="securityGroupMemberSpinner"
    />
    <div
      v-if="!loading"
    >
      <p
        class="alert"
      >
        CAUTION! Deleting the company will result in users associated with the UC Analytics instance not being able to access the data via the widgets on the dashboard and management in the security screen.
      </p>
      <input
        v-if="!loading"
        id="deleteCompanyButton"
        type="button"
        class="deleteCompanyButton"
        value="Delete Company"
        title="Deletes the company from accessing UC Analytics permanently"
        @click="removeCompany()"
      >
    </div>
  </div>
</template>
<script>
import { stormMixin } from '../../../mixins/stormMixin'
import Spinner from '../../common/spinner'
export default {
  name: 'StormDeleteCompany',
  components: {
    Spinner
  },
  mixins: [
    stormMixin
  ],
  props: {
    stormCompanyId: {
      type: Number,
      default: -1
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      showNew: false,
      loading: false,
      userSecurityGroups: []
    }
  },
  computed: {
  },
  watch: {
    stormUserId () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    setComponent: async function () {
      this.loading = true
      this.loading = false
    },
    removeCompany: async function () {
      var rtn = await this.deleteStormCompany(this.stormCompanyId)
      if (rtn) {
        this.$store.commit('showMessage', { content: 'Successfully Deleted Company!', color: 'blue', timeout: 3000 })
        this.$emit('reload-companies', 0)
      } else {
        this.$store.commit('showMessage', { content: 'There was an error deleting the company. Please contact support about this issue.', color: 'red', timeout: 3000 })
      }
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    }
  }
}
</script>
<style scoped>
  .securityGroupsHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .deleteCompanyButton {
    margin: 10px;
    float: right;
    cursor: pointer;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
  }
</style>
