<template>
  <div class="quotePanel">
    <label
      :class="{InputError: createDesignPackFromExternalDPComponent.contactId === 0}"
      for="cboContact"
    >Choose Contact:</label>
    <select
      v-if="salesforceOpportunity.contacts !== undefined"
      id="cboContact"
      v-model="createDesignPackFromExternalDPComponent.contactId"
    >
      <option
        v-for="c in salesforceOpportunity.contacts.filter(x => x.email !== null)"
        :key="c.id"
        :value="c.id"
        :title="c.firstName + ' ' + c.lastName"
        :selected="c.id === salesforceOpportunity.contactId"
      >{{ c.firstName + ' ' + c.lastName }}</option>
    </select>
    <br>
    <label
      :class="{InputError: createDesignPackFromExternalDPComponent.name === ''}"
      for="txtDesignPackName"
    >Designpack Name:
      <TooltipIcon
        width="500"
        text="Descriptive name for this design pack.  Do not include the revision number in this name" />
    </label>
    <input
      id="txtDesignPackName"
      v-model="createDesignPackFromExternalDPComponent.name"
      type="text"
    >
    <br>
    <label
      :class="{InputError: createDesignPackFromExternalDPComponent.termLengthMonths < 0 || createDesignPackFromExternalDPComponent.termLengthMonths > 60}"
      for="txtDesignPackTermLengthMonths"
    >Term Length (Months):
      <TooltipIcon
        width="400"
        text="How many months over which the customer will be charged" />
    </label>
    <input
      id="txtDesignPackTermLengthMonths"
      v-model.number="createDesignPackFromExternalDPComponent.termLengthMonths"
      type="number"
    >
    <br>
    <br>
    <input
      v-show="createDesignPackFromExternalDPComponent.contactId!== '' && createDesignPackFromExternalDPComponent.termLengthMonths > 0 && createDesignPackFromExternalDPComponent.termLengthMonths <= 60 && createDesignPackFromExternalDPComponent.name !== ''"
      id="'btnUploadFile'"
      value="Upload External Design Pack Template"
      type="button"
      @click="uploadFileButtonClicked('Upload Design Pack Template Spreadsheet', 0)"
    >
    <FileUploadDialogBox
      :dialog="createDesignPackFromExternalDPComponent.showFileUploadDialog"
      :dlg-message="createDesignPackFromExternalDPComponent.fileUploadDialogMessage"
      :dlg-close-from-top="false"
      :file-upload-catalog-id="createDesignPackFromExternalDPComponent.selectedFileUploadCatalogId"
      :checking-file="createDesignPackFromExternalDPComponent.fileDialogCheckingFile"
      :selected-file-error="createDesignPackFromExternalDPComponent.fileDialogSelectedFileError"
      dlgid="fileUploadDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      @uploadAction="fileUploaded"
      @cancelAction="createDesignPackFromExternalDPComponent.showFileUploadDialog = false"
      @fileChosenAction="fileChosen"
    />
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import FileUploadDialogBox from '../../common/fileUploadDialogBox'
import { mapActions } from 'vuex'
import { iQuoteAdjunctDataAPIMixin } from '../../../mixins/iQuote/iQuoteAdjunctDataAPIMixin'
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'
import quoteSummary from '../panels/quoteSummary'
import { usersMixin } from '../../../mixins/users'
import { iQuoteLoaderMixin } from '../../../mixins/iQuote/utils/iQuoteLoaderMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { iQuoteStaticDataAPIMixin } from '../../../mixins/iQuote/iQuoteStaticDataAPIMixin'

export default {
  name: 'CreateDesignPackFromExternalDP',
  components: {
    TooltipIcon,
    SectionCollapsedHeader,
    quoteSummary,
    FileUploadDialogBox
  },
  mixins: [
    usersMixin,
    iQuoteConstants,
    ShowHelpMixin,
    iQuoteAdjunctDataAPIMixin,
    iQuoteQuoteAPIMixin,
    iQuoteFunctions,
    iQuoteStoreMixin,
    iQuoteLoaderMixin,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackMixin,
    iQuoteExportsAPIMixin,
    iQuoteStaticDataAPIMixin
  ],
  props: {
    salesforceQuoteNumber: {
      type: String,
      default: function () {
        return ''
      }
    },
    salesforceOpportunity: {
      type: Object,
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      createDesignPackFromExternalDPComponent: {
        contactId: '',
        name: '',
        termLengthMonths: 36,
        showFileUploadDialog: false,
        fileUploadDialogMessage: '',
        selectedFileUploadCatalogId: 0,
        fileDialogCheckingFile: false,
        fileDialogSelectedFileError: '',
        salesforceCoeCodes: []
      }
    }
  },
  computed: {
  },
  async mounted () {
    this.createDesignPackFromExternalDPComponent.contactId = ''
    this.createDesignPackFromExternalDPComponent.name = ''
    this.createDesignPackFromExternalDPComponent.salesforceCoeCodes = await this.iQuoteStaticDataAPIMixin_getSalesforceCoeCodes()
  },
  created () {
  },
  updated () {
  },
  methods: {
    ...mapActions([
    ]),
    async createQuoteDesignPackFromExcelDP (newFile) {
      var isValidData = true

      this.createDesignPackFromExternalDPComponent.spreadsheetData.externalDPEntries.forEach(designPackExternalDPEntry => {
        if (designPackExternalDPEntry.SF_COE.trim() !== '' && !this.isValidSFCoeCode(designPackExternalDPEntry.SF_COE)) {
          isValidData = false

          this.iQuoteFunctions_ShowError(designPackExternalDPEntry.SF_COE + ' is an invalid SF COE Code.  This data cannot be imported')
        }
      })

      if (!isValidData) {
        return
      }

      var designPackExternalDPEntries = []
      var nextId = 0

      this.createDesignPackFromExternalDPComponent.spreadsheetData.externalDPEntries.forEach(designPackExternalDPEntry => {
        nextId--

        designPackExternalDPEntries.push(
          {
            id: nextId,
            description: designPackExternalDPEntry.Description,
            salesforceCoeCodeId: this.getSF_COE_IdFromName(designPackExternalDPEntry.SF_COE),
            upfrontSellPrice: designPackExternalDPEntry.UpfrontSellPrice,
            upfrontThirdPartyCost: designPackExternalDPEntry.UpfrontThirdPartyCost,
            upfrontInternalCost: designPackExternalDPEntry.UpfrontInternalCost,
            recurringSellPrice: designPackExternalDPEntry.RecurringSellPrice,
            recurringThirdPartyCost: designPackExternalDPEntry.RecurringThirdPartyCost,
            recurringInternalCost: designPackExternalDPEntry.RecurringInternalCost
          }
        )
      })

      var contact = this.salesforceOpportunity.contacts.find(x => x.id === this.createDesignPackFromExternalDPComponent.contactId)

      // This is a new quote that hasn't had the salesforce opportunity details saved to the DB yet, so we need to do that now
      var opportunity = {
        opportunityId: this.salesforceOpportunity.opportunityID,
        quoteNumber: this.salesforceQuoteNumber,
        contactFirstName: contact.firstName,
        contactLastName: contact.lastName,
        contactEmail: contact.email,
        opportunityName: this.salesforceOpportunity.opportunityName,
        accountName: this.salesforceOpportunity.accountName
      }
      var returnedOpportunity = await this.createOpportunity(opportunity)

      var salesforceOpportunityId = returnedOpportunity.id

      await this.createDesignPack(salesforceOpportunityId, newFile, designPackExternalDPEntries)
    },
    uploadFileButtonClicked (message, FileUploadCatalogId) {
      this.createDesignPackFromExternalDPComponent.fileUploadDialogMessage = message
      this.createDesignPackFromExternalDPComponent.fileDialogSelectedFileError = ''
      this.createDesignPackFromExternalDPComponent.showFileUploadDialog = true
      this.createDesignPackFromExternalDPComponent.selectedFileUploadCatalogId = FileUploadCatalogId
    },
    async fileChosen (newFile) {
      this.createDesignPackFromExternalDPComponent.fileDialogCheckingFile = true
      this.createDesignPackFromExternalDPComponent.fileDialogSelectedFileError = ''
      var fileExtension = newFile.name.split('.').pop().toLowerCase()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xls' && fileExtension !== 'xltm' && fileExtension !== 'xlsm') {
        this.createDesignPackFromExternalDPComponent.fileDialogSelectedFileError = 'Only Excel Spreadsheets can be uploaded'
      } else {
        var data = await this.iQuoteExportsAPIMixin_getDesignPackDataFromExcel(newFile.contents)

        if (data.error === undefined) {
          this.createDesignPackFromExternalDPComponent.fileDialogSelectedFileError = 'Error contacting file export service.  Cannot upload.  Please contact your administrator for assistance'
        } else if (data.error !== '') {
          this.createDesignPackFromExternalDPComponent.fileDialogSelectedFileError = data.error
        } else if (data.externalDPEntries.length === 0) {
          this.createDesignPackFromExternalDPComponent.fileDialogSelectedFileError = 'No Entries found on this spreadsheet'
        } else {
          /* Store parsed data from spreadsheet for saving to DB later.  This data is not saved into DB until you upload
             the file (fileUploaded() method) */
          this.createDesignPackFromExternalDPComponent.spreadsheetData = data
        }
      }

      this.createDesignPackFromExternalDPComponent.fileDialogCheckingFile = false
    },
    async fileUploaded (newFile) {
      this.createDesignPackFromExternalDPComponent.showFileUploadDialog = false

      await this.createQuoteDesignPackFromExcelDP(newFile)
    },
    async createDesignPack (salesforceOpportunityId, newFile, designPackExternalDPEntries) {
      var newDesignPack = {
        quoteDesignPackId: 0,
        name: this.createDesignPackFromExternalDPComponent.name,
        quoteId: null,
        salesforceOpportunityId: salesforceOpportunityId,
        termLengthMonths: this.createDesignPackFromExternalDPComponent.termLengthMonths,
        quoteDesignPackRevisionId: 0,
        revisionNumber: 1,
        revisions: [{
          id: 0,
          revisionNumber: 1
        }],
        users: [],
        description: '',
        manufacturerCode: '',
        projectName: '',
        numberOfSites: 1,
        newOrExistingCustomer: '',
        upgradeMigration: false,
        pmoReviewer: '',
        maintenanceTakeOn: false,
        contractFrameworkId: 1,
        professionalServicesReview: false,
        newManufacturerCodeRequired: false,
        contractIdCoterminatedWith: '',
        showPSTab: true,
        showAutotaskTab: true,
        showHardwareTab: false,
        showCircuitsTab: false,
        showStandardCaveatsTab: false,
        showCeasesTab: false,
        dpExported: false,
        psCoreEntries: [],
        psOptionsEntries: [],
        psExpenseEntries: [],
        psThirdPartyInstallationEntries: [],
        psThirdPartyOtherEntries: [],
        hardwareMainEntries: [],
        hardwareMiscEntries: [],
        circuitsEquinixCloudConnectEntries: [],
        circuitsWanConnectivityEntries: [],
        circuitsHostingAndCablingEntries: [],
        circuitsBroadbandEntries: [],
        circuitsFixedLineVoiceEntries: [],
        circuitsIpVpnSetupEntries: [],
        margins: [],
        discounts: [],
        priceOverrides: [],
        editedDesignOverviewEntry: null,
        editedDetailedSummaryBreakdownEntries: [],
        editedAutotaskEntries: [],
        exchangeRateUSDToGPB: null,
        exchangeRateUSDToGPBDate: null,
        exchangeRateGBPToEUR: null,
        exchangeRateGBPToEURDate: null,
        autotaskCustAccNo: '',
        locked: true,
        createdByUserId: this.$store.getters.userid,
        hldReference: '',
        pmoReview: '',
        createdBy: '',
        disabledEntries: [],
        addPCTime: false,
        useDetailDataForSummaries: true,
        chargeUpfront: false,
        ccUsers: 0,
        ucUsers: 0,
        ccUsersOverride: null,
        ucUsersOverride: null,
        standardCaveats: '',
        ceasesEntries: [],
        marginCircuitsEquinixCloudConnectCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsEquinixCloudConnectOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsHostingAndCablingCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsHostingAndCablingOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsWanConnectivityCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsWanConnectivityOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsBroadbandCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsBroadbandOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsFixedLineVoiceCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginCircuitsFixedLineVoiceOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginHardwareMainPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginHardwareMiscEquipmentPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        isReadOnly: false,
        quoteDesignPackTypeId: this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP,
        externalDesignPackFileUploadCatalogId: newFile.fileUploadCatalogId,
        spreadsheetDPFileUploadVersionID: newFile.fileVersionId,
        externalDPEntries: designPackExternalDPEntries,
        serviceLevelTypeId: 0,
        ccaasProvider: '',
        ucaasProvider: '',
        removedPCTime: false,
        lastDesignPackItemLookupId: 0,
        priceOverrideARFrameworkCharge: null,
        priceOverrideOOFrameworkCharge: null,
        priceOverrideOrderCoordination: null,
        disableSupportContingency: false
      }

      var savedDesignPack = await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(newDesignPack)
      savedDesignPack.isReadOnly = true

      const action = 'Created DP from Excel'
      await this.iQuoteQuoteDesignPacksAPIMixin_addAuditing(action, 1, null)

      this.$emit('designpack-selected', savedDesignPack)
    },
    isValidSFCoeCode (salesforceCoeCode) {
      return this.createDesignPackFromExternalDPComponent.salesforceCoeCodes.some(e => e.code.toLowerCase() === salesforceCoeCode.toLowerCase())
    },
    getSF_COE_IdFromName (salesforceCoeCode) {
      if (salesforceCoeCode === null) {
        return null
      }

      var matches = this.createDesignPackFromExternalDPComponent.salesforceCoeCodes.filter(e => e.code.toLowerCase() === salesforceCoeCode.toLowerCase())

      if (matches.length > 0) {
        return matches[0].id
      } else {
        return 0
      }
    }
  }
}
</script>
<style scoped>
  .quotePanel {
    width: 580px;
    text-align: left;
  }

  #txtDesignPackName {
    width: 300px;
  }

  #txtDesignPackTermLengthMonths {
    width: 50px;
  }
</style>
