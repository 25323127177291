export const iQuoteImagesMixin = {
  mixins: [],
  data () {
    return {
    }
  },
  computed: {
    iQuoteImagesMixin_iconPause () {
      return require(`../../../assets/icons/pause.png`)
    },
    iQuoteImagesMixin_iconResume () {
      return require(`../../../assets/icons/resume.png`)
    },
    iQuoteImagesMixin_iconDelete () {
      return require(`../../../assets/icons/Trashred.png`)
    },
    iQuoteImagesMixin_iconClear () {
      return require(`../../../assets/icons/clear.png`)
    },
    iQuoteImagesMixin_iconPlusMinus () {
      return require(`../../../assets/icons/plus-minus.png`)
    },
    iQuoteImagesMixin_iconUndo () {
      return require(`../../../assets/icons/undo.png`)
    },
    iQuoteImagesMixin_iconEdit () {
      return require(`../../../assets/icons/Edit.png`)
    },
    iQuoteImagesMixin_busyAnimation () {
      return require(`../../../assets/icons/SmallBusyAnimation.gif`)
    }
  },
  mounted () {
  },
  methods: {
  }
}
