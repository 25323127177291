<template>
  <div />
</template>
<script>
import { DataEventBus } from '../../events/dataEvents'
import { usersMixin } from '../../mixins/users'
export default {
  name: 'Chat',
  mixins: [
    DataEventBus,
    usersMixin
  ],
  mounted () {
    this.$store.watch(
      (state, getters) => getters.maintelTesting,
      (newValue) => {
        if (newValue !== undefined) {
          this.setChatScript()
        }
      }
    )
    DataEventBus.$on('usersSet', () => {
      this.setChatParameters()
    })
    this.setChatScript()
  },
  methods: {
    async setChatScript () {
      if (this.$store.getters.maintelTesting) {
        let script = document.createElement('script')
        script.setAttribute('src', `https://eu.engage.app/api/ecs/v1/loader/33fbc0e1-41b5-4abc-aee1-edd74d190edc.js?path=${encodeURIComponent(window.location.origin + window.location.pathname)}&selectedVersion=${(new URLSearchParams(window.location.search)).get('ecsSelectedVersion') || ''}`)
        script.async = true
        script.setAttribute('type', 'text/javascript')
        document.body.appendChild(script)
      }
    },
    async setChatParameters () {
      var username = this.$store.getters.username
      var usr = await this.getUser(username)
      var fullName = usr.firstName + ' ' + usr.lastName
      var emailAddress = usr.emailAddress
      window.talkativeCustomConfig = {
        events: {
          enterStandby () {
            const userData = [
              {
                data: fullName,
                name: 'name',
                label: 'Name',
                type: 'string'
              },
              {
                data: emailAddress,
                name: 'email',
                label: 'Email',
                type: 'string'
              }
            ]
            window.talkativeApi.interactionData.appendInteractionData(userData)
          }
        }
      }
    }
  }
}

</script>
<style scoped>
  .w-full {z-index: 1;}
  .shadow-lg {border: solid grey 1px;}
</style>
