import portalAPIGateway from '../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteUnitCostsAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteUnitCostsAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    getQuoteUnitCostsFromDB: async function () {
      var axiosConfig = this.iQuoteQuoteUnitCostsAPIMixin_getAxiosConfig()
      var data = []

      var quoteId = this.$store.getters.quote.id

      try {
        await portalAPIGateway.get('iquotearray/quoteUnitCosts/' + quoteId, '', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteUnitCostsFromDB failed!')
      }

      return data
    },
    saveQuoteUnitCostsToDB: async function () {
      var axiosConfig = this.iQuoteQuoteUnitCostsAPIMixin_getAxiosConfig()

      var quoteId = this.$store.getters.quote.id
      var unitCosts = this.$store.getters.quoteUnitCosts

      try {
        await portalAPIGateway.post('iquote/quoteUnitCosts/' + quoteId, unitCosts, axiosConfig).then(() => {
          return true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('saveQuoteUnitCostsToDB failed!')
      }
    }
  }
}
