<template>
  <div
    :id="id"
    class="deleteSectionContainer"
  >
    <div
      id="frmDeleteUser"
      class="deleteUserHolder"
    >
      <div class="deleteUserText">
        <p><b>Warning: </b>Deleting a user is <b>permanent</b>.</p>
      </div>
      <Spinner v-if="isDeleting" />
      <div class="actionBar">
        <input
          id="deleteUserButton"
          type="button"
          class="deleteButton deleteUsersButton"
          title="Delete Selected user"
          @click="setDialog(true)"
        >
      </div>
    </div>
    <ConfirmationDialogBox
      :dialog="showConfirmationDialogBox"
      :dlg-message="`Are you sure want to delete the user ${username}?`"
      :dlg-close-from-top="false"
      dlgid="deleteUserConfirmationDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      negative-btn-text="No"
      positive-btn-text="Yes"
      @negativeAction="setDialog"
      @positiveAction="deleteSelectedUser()"
      @cancelDialog="setDialog"
    />
  </div>
</template>

<script>
import { usersMixin } from '../../mixins/users'
import { adminMixin } from '../../mixins/adminMixin'
import { autotaskAPIMixin } from '../../mixins/autotaskAPIMixin'
import ConfirmationDialogBox from '../common/confirmationDialogBox'
import Spinner from '../common/spinner'
import { mapActions } from 'vuex'

export default {
  components: {
    Spinner,
    ConfirmationDialogBox
  },
  mixins: [
    usersMixin,
    autotaskAPIMixin,
    adminMixin
  ],
  props: {
    userid: {
      type: Number,
      default: 0
    },
    username: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isDeleting: false,
      showConfirmationDialogBox: false,
      formOK: true
    }
  },
  computed: {
    deleteEntityPermission () {
      return this.$store.getters.deleteEntityPermission
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setReloadUsers'
    ]),
    setComponent: async function () {
    },
    deleteSelectedUser: async function () {
      this.isDeleting = true
      var deletionError = false // Because there are two potential points of failure, deleting user and deleting contact

      // Obtain the user's email address before we attempt the deletion
      var userToDelete = await this.getUserList(String(this.userid))

      var rtn = await this.removeUser(this.userid)
      if (rtn === 'OK') {
        if (userToDelete !== null && userToDelete !== undefined && userToDelete.length > 0) {
          if (userToDelete[0].emailAddress !== null && userToDelete[0].emailAddress !== undefined && userToDelete[0].emailAddress !== '') {
            var delContact = await this.deleteAutotaskContact(userToDelete[0].emailAddress)
            if (delContact === 'error') {
              deletionError = true
              console.error('Failed to delete contact record for user ID: ' + String(this.userid))
            }
          } else {
            deletionError = true
            console.error('Unable to find email address for user ID: ' + String(this.userid))
          }
        } else {
          deletionError = true
          console.error('Unable to find user details for user ID: ' + String(this.userid))
        }
      } else {
        deletionError = true
      }

      // The deletion was a success if we removed both the user record and the Autotask contact
      if (!deletionError) {
        this.$store.commit('showMessage', { content: 'Successfully deleted the user', color: 'blue', timeout: 3000 })
        this.setReloadUsers(true)
        this.$emit('selectuser')
      } else {
        this.$store.commit('showMessage', { content: 'The delete user call has failed', color: 'red', timeout: 3000 })
      }
      this.isDeleting = false
      this.setDialog(false)
    },
    setDialog: function (value) {
      this.showConfirmationDialogBox = value
    }
  }
}
</script>

<style scoped>
  .deleteSectionContainer {
    width:400px;
    display: none;
    margin-bottom:10px;
  }
  .deleteUserHolder {
    background-color: rgba(255,255,255,0.7);
    padding:10px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .actionBar {
    direction: rtl;
  }
</style>
