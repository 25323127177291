import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const iQuoteCalculationsSycurioMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
    }
  },
  computed: {
    /*
     * BUSINESS VALUES
     */
    calcSycurio_UpfrontCharges () {
      var total = 0

      if (this.iQuoteStore_GetQuoteSycurio.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.sycurio) {
            total += unitCost.price * unitCost.quantity
          }
        }
      })
      return total
    },
    calcSycurio_RecurringCharges () {
      var total = 0

      if (this.iQuoteStore_GetQuoteSycurio.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.sycurio) {
            total += unitCost.price * unitCost.quantity
          }
        }
      })
      return total
    }
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcSycurio_calculate: function () {
      if (this.iQuoteStore_GetQuoteSycurio.id === undefined) {
        return
      }

      var psCost = 0

      if (this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers < 50) {
        psCost = 8000
        switch (this.iQuoteStore_GetQuote.termLengthMonths) {
          case 12:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER1_1YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
          case 36:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER1_3YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
          case 60:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER1_5YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
        }
      } else if (this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers < 250) {
        psCost = 16000
        switch (this.iQuoteStore_GetQuote.termLengthMonths) {
          case 12:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER2_1YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
          case 36:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER2_3YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
          case 60:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER2_5YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
        }
      } else if (this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers < 500) {
        psCost = 24000
        switch (this.iQuoteStore_GetQuote.termLengthMonths) {
          case 12:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER3_1YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
          case 36:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER3_3YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
          case 60:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER3_5YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
        }
      } else if (this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers < 1000) {
        psCost = 28000
        switch (this.iQuoteStore_GetQuote.termLengthMonths) {
          case 12:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER4_1YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
          case 36:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER4_3YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
          case 60:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SYCURIO_ESSENTIALS_USER_TIER4_5YR, this.iQuoteStore_GetQuoteSycurio.options.numEssentialsUsers)
            break
        }
      }

      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SYCURIO_PS, 1, null, psCost, psCost / 0.8)

      if (this.iQuoteStore_GetQuoteSycurio.options.numPSPProviders > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SYCURIO_PSP, this.iQuoteStore_GetQuoteSycurio.options.numPSPProviders)
      }

      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SYCURIO_MAINTEL_ANNUAL_ASSESSMENT, 1)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SYCURIO_MAINTEL_SEGMENTATION_TEST, 1)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SYCURIO_MAINTEL_VULNERABILITY_SCANNER, 1)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SYCURIO_MAINTEL_SIEM_LICENSES, 1)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SYCURIO_MAINTEL_SOC_HEADCOUNT, 1)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SYCURIO_MAINTEL_CIRCUIT_COST, 1)
    }
  }
}
