import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const iQuoteCalculationsTeamsConnectorMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
    }
  },
  computed: {
    /*
     * BUSINESS VALUES
     */

    calcTeamsConnector_UpfrontCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.teamsConnector) {
            total += unitCost.price * unitCost.quantity
          }
        }
      })
      return total
    }
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcTeamsConnector_calculate: function () {
      if (this.iQuoteStore_GetTeamsConnector.id === undefined) {
        return
      }

      var quote = this.$store.getters.quote
      var numSipChannels = 0
      var additionalSbcLicencesForInternalCalls = this.iQuoteStore_GetTeamsConnector.additionalSbcLicencesForInternalCalls
      var totalNumUsers = this.iQuoteStore_GetTeamsConnector.numTeamsUsers + this.iQuoteStore_GetTeamsConnector.numPBXUsers

      numSipChannels = this.iQuoteStore_GetTeamsConnector.sipChannelsCalculated

      if (this.iQuoteStore_GetTeamsConnector.numAdditionalSIPChannels > 0) {
        if (quote.termLengthMonths >= 36) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_ACTIVE_36, this.iQuoteStore_GetTeamsConnector.numAdditionalSIPChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_STANDBY_36, this.iQuoteStore_GetTeamsConnector.numAdditionalSIPChannels)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_ACTIVE_12, this.iQuoteStore_GetTeamsConnector.numAdditionalSIPChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_STANDBY_12, this.iQuoteStore_GetTeamsConnector.numAdditionalSIPChannels)
        }
      }

      if (numSipChannels > 0) {
        if (!this.utilFunctions_UnitCostExistsOnQuote(this.iQuoteConstants.unitCosts.TEAMS_SIP_SETUP) &&
            !this.utilFunctions_UnitCostExistsOnQuote(this.iQuoteConstants.unitCosts.CXNOW_GAMMA_SIP_SETUP)) {
          // Might have already been added by Genesys Connector or CXNow if ordered together with Teams
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_SIP_SETUP, 2)
        }

        if (quote.termLengthMonths >= 36) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_ACTIVE_36, numSipChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_STANDBY_36, numSipChannels)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_ACTIVE_12, numSipChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_STANDBY_12, numSipChannels)
        }

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_MAINTEL_SBC_SUPPORT, Math.ceil((numSipChannels + additionalSbcLicencesForInternalCalls) / 25))

        if (numSipChannels <= 100 || this.iQuoteStore_GetTeamsConnector.numPBXUsers > 0) {
          switch (this.iQuoteStore_GetTeamsConnector.usersPerSipChannel) {
            case 3:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER1, totalNumUsers)
              break
            case 5:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER1, totalNumUsers)
              break
            case 7:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER1, totalNumUsers)
              break
            case 10:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER1, totalNumUsers)
              break
          }
        } else {
          switch (this.iQuoteStore_GetTeamsConnector.usersPerSipChannel) {
            case 3:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER2, totalNumUsers)
              break
            case 5:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER2, totalNumUsers)
              break
            case 7:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER2, totalNumUsers)
              break
            case 10:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER2, totalNumUsers)
              break
          }
        }

        var iconBandwidth = 2 * (5 + ((numSipChannels + additionalSbcLicencesForInternalCalls) / 5))
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ICON_BANDWIDTH_CONTRIBUTION, iconBandwidth)

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_SBC_PORT_CHARGE, numSipChannels + additionalSbcLicencesForInternalCalls)

        if (this.iQuoteStore_GetTeamsConnector.teamsConnectorNumberPortingOptionId === 2 /* 2 = NEW DDI */) {
          var numDDIs = this.iQuoteStore_GetTeamsConnector.newDDIs
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_NEW_DDI, numDDIs)
        }

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_PROJECT_COORDINATOR, 1)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ENGINEER_LEVEL_B, 1)
      }

      this.calcTeamsConnector_calculateRecordings()
      this.calcTeamsConnector_calculateAttendantConsole()
    },
    calcTeamsConnector_calculateRecordings: function () {
      var licenseId
      var teamsConnector = this.iQuoteStore_GetTeamsConnector

      if (teamsConnector.recordingComplianceRequired) {
        if (teamsConnector.teamsConnectorRecordingTypeId === this.iQuoteConstants.teamsConnectorRecordingTypes.VOICE) {
          licenseId = this.iQuoteConstants.licenses.TEAMS_REC_COMP
        } else {
          licenseId = this.iQuoteConstants.licenses.TEAMS_REC_COMP_UC
        }
      } else {
        if (teamsConnector.teamsConnectorRecordingTypeId === this.iQuoteConstants.teamsConnectorRecordingTypes.VOICE) {
          licenseId = this.iQuoteConstants.licenses.TEAMS_REC_SMART
        } else {
          licenseId = this.iQuoteConstants.licenses.TEAMS_REC_UC
        }
      }

      if (teamsConnector.numRecordingChannels >= 2500) {
        licenseId += 3
      } else if (teamsConnector.numRecordingChannels >= 1000) {
        licenseId += 2
      } else if (teamsConnector.numRecordingChannels >= 200) {
        licenseId += 1
      }

      this.iQuoteStore_AddRequiredLicenseToQuote(licenseId, teamsConnector.numRecordingChannels)

      if (teamsConnector.recordingAnalyticsRequired) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_REC_ADDON_ANALYTICS, teamsConnector.numRecordingChannels)
      }

      this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_REC_TRANSCRIPTION_100H, teamsConnector.numRecordingTranscriptionPackages)
      this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_REC_TRANSLATE_100H, teamsConnector.numRecordingTranslationPackages)

      if (teamsConnector.teamsConnectorRecordingRedundencyOptionId === this.iQuoteConstants.teamsConnectorRecordingRedundencyOptions.LOCAL) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_REC_STORAGE_500GB_LRS, teamsConnector.numRecordingStorageUnits)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_REC_ARCH_STORAGE_500GB_LRS, teamsConnector.numRecordingArchiveStorageUnits)
      } else if (teamsConnector.teamsConnectorRecordingRedundencyOptionId === this.iQuoteConstants.teamsConnectorRecordingRedundencyOptions.GEO_REDUNDANCY) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_REC_STORAGE_500GB_GRS, teamsConnector.numRecordingStorageUnits)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_REC_ARCH_STORAGE_500GB_GRS, teamsConnector.numRecordingArchiveStorageUnits)
      }

      if (teamsConnector.teamsConnectorRecordingExportTypeId === this.iQuoteConstants.teamsConnectorRecordingExportTypes.PUBLIC) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_REC_DATA_EXPORT_PUB, teamsConnector.numRecordingExportPackages)
      } else if (teamsConnector.teamsConnectorRecordingExportTypeId === this.iQuoteConstants.teamsConnectorRecordingExportTypes.AZURE) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_REC_DATA_EXPORT_AZURE, teamsConnector.numRecordingExportPackages)
      }

      if (teamsConnector.numRecordingChannels > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_REC_ONBOARDING, 1)
      }

      //  this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_REC_TENANT_ACTIVATION, 1)
    },
    calcTeamsConnector_calculateAttendantConsole: function () {
      var teamsConnector = this.iQuoteStore_GetTeamsConnector

      if (teamsConnector.numAttendantConsoles > 0) {
        if (teamsConnector.attendantConsoleSubscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ATTENDANT_CONSOLE_MONTHLY, teamsConnector.numAttendantConsoles)
        } else {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ATTENDANT_CONSOLE_ANNUAL, teamsConnector.numAttendantConsoles)
        }
      }
    }
  }
}
