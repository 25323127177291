// This module is used for state mamangement for navigation related variables

const state = {
  currentPage: 'Dashboard',
  showAccount: true,
  lastActionTime: null,
  lastAction: null,
  lastActionTimeoutSeconds: 120
}

const mutations = {
  SET_PAGE (state, currentPage) {
    state.currentPage = currentPage
  },
  SET_SHOW_ACCOUNT (state, show) {
    state.showAccount = show
  },
  SET_LAST_ACTION (state, action) {
    state.lastAction = action
  },
  SET_LAST_ACTION_TIME (state, date) {
    state.lastActionTime = date
  }
}

const actions = {
  setPage: ({ commit }, currentPage) => {
    commit('SET_PAGE', currentPage)
  },
  setShowAccount: ({ commit }, showAccount) => {
    commit('SET_SHOW_ACCOUNT', showAccount)
  },
  setLastAction: ({ commit }, action) => {
    commit('SET_LAST_ACTION', action)
    commit('SET_LAST_ACTION_TIME', new Date())
  },
  clearLastAction: ({ commit }) => {
    commit('SET_LAST_ACTION', null)
    commit('SET_LAST_ACTION_TIME', null)
  }
}

const getters = {
  currentPage: state => {
    return state.currentPage
  },
  showAccount: state => {
    return state.showAccount
  },
  lastActionTime: state => {
    return state.lastActionTime
  },
  lastActionTimeoutSeconds: state => {
    return state.lastActionTimeoutSeconds
  }
}

// This export is used to allow the vuex components to extend ito the global state
export default {
  state,
  mutations,
  actions,
  getters
}
