<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconExpansions"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>Expansions</h3>
      <h4 class="expansionsApprovalWarning">Design Approval Required</h4>
      <br>
      <p>Select Expansion Servers:</p>
      <br><br>
      <table
        id="iquoteExpansionsTable">
        <tr>
          <th width="230px">Category</th>
          <th
            v-if="selectedExpansionVmCategoryId !== iQuoteConstants.expansionVmCategories.CUSTOM && expansionVmSubCategoriesUnderSelectedCategory.length > 0"
            width="230px">Subcategory</th>
          <th
            v-if="selectedExpansionVmCategoryId !== iQuoteConstants.expansionVmCategories.CUSTOM"
            width="500px">Server</th>
          <th
            v-if="selectedExpansionVmCategoryId === iQuoteConstants.expansionVmCategories.CUSTOM"
            width="500px">Server Name</th>
        </tr>
        <tr><td colspan="4">&nbsp;</td></tr>
        <tr>
          <td>
            <select
              id="cboServices"
              v-model="selectedExpansionVmCategoryId"
              style=""
              @change="resetSelectedSubCategoryAndVm()"
            >
              <option
                v-if="selectedExpansionVmCategoryId === 0"
                key="0"
                value="0"
                title="< Please Select >"
              >&lt; Please Select &gt;
              </option>
              <option
                v-for="t in expansionVmCategoriesStore"
                :key="t.id"
                :value="t.id"
                :title="t.name"
              >{{ t.name }}
              </option>
            </select>
            <TooltipIcon
              text="Only use 'Custom' option for VM types that are listed as supported on the ISS website. If you wish to deploy VMs that do not match this description then you MUST contact the ICON Infrastructure before quoting."
              icon-position="top-right" />
          </td>
          <td v-if="selectedExpansionVmCategoryId !== iQuoteConstants.expansionVmCategories.CUSTOM && expansionVmSubCategoriesUnderSelectedCategory.length > 0">
            <select
              id="cboTechnologies"
              v-model="selectedExpansionVmSubCategoryId"
              @change="resetSelectedVm()"
            >
              <option
                v-if="selectedExpansionVmSubCategoryId === 0"
                key="0"
                value="0"
                title="< Please Select >"
              >&lt; Please Select &gt;
              </option>
              <option
                v-for="t in expansionVmSubCategoriesUnderSelectedCategory"
                :key="t.id"
                :value="t.id"
                :title="t.name"
              >{{ t.name }}
              </option>
            </select>
          </td>
          <td v-if="selectedExpansionVmCategoryId !== iQuoteConstants.expansionVmCategories.CUSTOM">
            <select
              id="cboServers"
              v-model="selectedVirtualMachineIdToAdd"
              style="width:470px"
            >
              <option
                v-if="selectedVirtualMachineIdToAdd === 0"
                key="0"
                value="0"
                title="< Please Select >"
              >&lt; Please Select &gt;
              </option>
              <option
                v-for="t in serversUnderSelectedCategoryAndSubCategory"
                :key="t.id"
                :value="t.id"
                :title="t.name"
              >{{ t.name }}
              </option>
            </select>
          </td>
          <td v-if="selectedExpansionVmCategoryId === iQuoteConstants.expansionVmCategories.CUSTOM">
            <input
              v-model="newCustomServerName"
              type="text"
              style="width:380px"
            >
            <TooltipIcon
              text="Please enter a descriptive name of the expansion you are adding"
              icon-position="top-right"/>
          </td>
          <td>
            <input
              v-if="selectedExpansionVmCategoryId !== iQuoteConstants.expansionVmCategories.CUSTOM && selectedVirtualMachineIdToAdd"
              id="btnAddServer"
              value="add"
              type="button"
              @click="addStandardServer(selectedVirtualMachineIdToAdd)"
            >
            <input
              v-if="selectedExpansionVmCategoryId === iQuoteConstants.expansionVmCategories.CUSTOM && newCustomServerName !== ''"
              id="btnAddServer"
              value="add"
              type="button"
              @click="addCustomServer()"
            >
          </td>
        </tr>
      </table>
      <br><br>
      <div
        v-for="rvm in quoteExpansions.expansionVirtualMachines"
        :key="rvm.id"
        class="expansionVirtualMachineSection">
        <SectionCollapsedHeader
          :id="'vmDetailsSection' + rvm.id"
          :title="displayExpansions_getServerNameForRequiredVirtualMachine(rvm) "
          :controls="'iquoteExpansionsTable' + rvm.id"
          :start-collapsed="false"
          :show-delete-button="true"
          :icon-file="iconForExpansionVm(rvm)"
          type="subHeader"
          @delete="removeServer(rvm.id)"
        />
        <table
          :id="'iquoteExpansionsTable' + rvm.id"
          class="iquoteExpansionsTable">
          <tr class="inputControlsHeader">
            <th style="width: 125px">Quantity</th>
            <th v-if="rvm.virtualMachineId == null">Name</th>
            <th v-if="rvm.virtualMachineId == null">Operating System</th>
            <th v-if="rvm.virtualMachineId == null">SQL</th>
            <th v-if="rvm.virtualMachineId == null">Resilience Type</th>
          </tr>
          <tr>
            <td>
              <input
                v-model.number="rvm.quantity"
                :id="'expansionVmQuantity' + rvm.id"
                :class="{invalidInput: !isValidQuantity(rvm.quantity)}"
                type="number"
                min="1"
                max="99"
                class="shortTextBox"
              >
              <TooltipIcon
                text="Quantity is the number of instances that are required in a single data centre. There is no need to double the quantity for resilience purposes, iQuote will do this for you."
                icon-position="top-right" />
            </td>
            <td v-if="rvm.virtualMachineId == null">
              <input
                v-model="rvm.customServerName"
                :class="{invalidInput: !containsText(rvm.customServerName)}"
                maxlength="200"
                type="text"
              ><TooltipIcon
                text="Please enter a descriptive name of the expansion you are adding"
                icon-position="top-right"/>
            </td>
            <td v-if="rvm.virtualMachineId == null">
              <select
                id="cboOperatingSystem"
                v-model.number="rvm.operatingSystemId"
                :class="{invalidInput: !containsValue(rvm.operatingSystemId)}"
                @change="operatingSystemChangedEvent($event, rvm)"
              >
                <option
                  key="1"
                  value="1"
                  title="Appliance"
                >Appliance
                </option>
                <option
                  key="2"
                  value="2"
                  title="Linux"
                >Linux
                </option>
                <option
                  key="3"
                  value="3"
                  title="Windows"
                >Windows
                </option>
              </select>
            </td>
            <td v-if="rvm.virtualMachineId == null">
              <TriStateToggleSwitch
                ref="sql-toggle"
                :enabled="rvm.operatingSystemId === iQuoteConstants.operatingSystems.WINDOWS"
                :current-state="rvm.sql ? 1 : 2"
                :tri-state="false"
                class="toggleSwitch"
                tabindex="1"
                @statechanged="rvm.sql = !rvm.sql"
              />
            </td>
            <td v-if="rvm.virtualMachineId == null">
              <select
                id="cboVmResilience"
                v-model.number="rvm.expansionVmResilienceTypeId"
                :class="{invalidInput: !containsValue(rvm.expansionVmResilienceTypeId)}"
              >
                <option
                  key="1"
                  value="1"
                  title="Application"
                >Application
                </option>
                <option
                  key="2"
                  value="2"
                  title="Infrastructure"
                >Infrastructure
                </option>
                <option
                  key="3"
                  value="3"
                  title="No Resilience"
                >No Resilience
                </option>
              </select>
              <TooltipIcon
                text="Use Application Resilience for VMs which can run two instances, one in each DC. Alternatively, use Infrastructure Resilience for VMs which cannot run Active-Active."
                icon-position="top-right"
                align="right"/>
            </td>
          </tr>
          <tr
            v-if="rvm.virtualMachineId == null"
            class="inputControlsHeader">
            <th>vCPU</th>
            <th>CPU Reservation (Ghz)</th>
            <th>Memory (Gb)</th>
            <th>Fast Storage (Gb)</th>
            <th>Archive Storage (Gb)</th>
          </tr>
          <tr
            v-if="rvm.virtualMachineId == null">
            <td>
              <input
                v-model.number="rvm.vcpu"
                :class="{invalidInput: !isValidvCPU(rvm.vcpu)}"
                type="number"
                min="1"
                max="48"
                class="shortTextBox"
                @change="vmCPUChanged(rvm)"
              >
            </td>
            <td>
              <input
                v-model.number="rvm.cpuReservationGhz"
                type="number"
                min="1"
                max="100"
                class="shortTextBox"
                readonly
              >
            </td>
            <td>
              <input
                v-model.number="rvm.memoryGb"
                :class="{invalidInput: !isValidMemoryGb(rvm.memoryGb)}"
                type="number"
                min="1"
                max="256"
                class="shortTextBox"
              >
            </td>
            <td>
              <input
                v-model.number="rvm.storageSizeGbStandard"
                :class="{invalidInput: !isValidStandardStorage(rvm.storageSizeGbStandard)}"
                type="number"
                min="1"
                max="256"
                class="shortTextBox"
              >
            </td>
            <td>
              <input
                v-model.number="rvm.storageSizeGbArchive"
                :class="{invalidInput: !isValidArchiveStorage(rvm.storageSizeGbArchive)}"
                type="number"
                min="1"
                max="256"
                class="shortTextBox"
              >
            </td>
          </tr>
          <tr
            class="inputControlsHeader">
            <th>
              Requirements <TooltipIcon text="Detail here your reasons for including this custom expansion" />
            </th>
          </tr>
          <tr>
            <td colspan="5">
              <textarea
                v-model="rvm.requirements"
                :class="{invalidInput: !containsText(rvm.requirements)}"
                class="txtRequirements"
                rows="3"
                cols="100"
              />
            </td>
          </tr>
        </table>
      </div>
      <br>
      <h4
        v-if="quoteExpansions.expansionVirtualMachines.length > 0"
        class="expansionsApprovalWarning">Design Approval Required</h4>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('selectService')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService()"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteQuoteExpansionsAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteExpansionsAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteDisplayExpansionsMixin } from '../../../mixins/iQuote/display/iQuoteDisplayExpansionsMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import Vue from 'vue'

import { mapActions } from 'vuex'
export default {
  name: 'Expansions',
  components: {
    TooltipIcon,
    SectionCollapsedHeader,
    TriStateToggleSwitch
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin, iQuoteQuoteExpansionsAPIMixin, iQuoteDisplayExpansionsMixin],
  data () {
    return {
      quote: {},
      quoteExpansions: {
        expansionVirtualMachines: []
      },
      virtualMachines: {},
      selectedVirtualMachineIdToAdd: 0,
      selectedExpansionVmCategoryId: 0,
      selectedExpansionVmSubCategoryId: 0,
      newCustomServerName: ''
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconExpansions () {
      return require(`../../../assets/icons/iconExpansions.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    serversUnderSelectedCategoryAndSubCategory () {
      var vms = this.virtualMachinesStore
      var vmIdsInSelectedCategory = this.virtualMachineExpansionCategoryLinksStore.filter(
        vml => vml.expansionVmSubCategoryId === this.selectedExpansionVmSubCategoryId ||
               (vml.expansionVmCategoryId === this.selectedExpansionVmCategoryId &&
                this.selectedExpansionVmSubCategoryId === 0)).map(item => item.virtualMachineId)

      var filteredVms = vms.filter(vm => vmIdsInSelectedCategory.includes(vm.id))
      return filteredVms.sort(this.vmCompare)
    },
    expansionVmSubCategoriesUnderSelectedCategory () {
      return this.expansionVmSubCategoriesStore.filter(sc => sc.expansionVmCategoryId === this.selectedExpansionVmCategoryId)
    },
    virtualMachinesStore () {
      return this.$store.getters.virtualMachines
    },
    expansionVmCategoriesStore () {
      return this.$store.getters.expansionVmCategories
    },
    expansionVmSubCategoriesStore () {
      return this.$store.getters.expansionVmSubCategories
    },
    virtualMachineExpansionCategoryLinksStore () {
      return this.$store.getters.virtualMachineExpansionCategoryLinks
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote

    var quoteExpansionsStore = this.$store.getters.quoteExpansions

    if (quoteExpansionsStore.id === undefined || quoteExpansionsStore.id === 0) {
      this.quoteExpansions.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.quoteExpansions)

      if (this.quoteExpansions.id === 0) {
        this.quoteExpansions.id = await this.addQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.quoteExpansions)
      }

      this.$set(this.quoteExpansions, 'expansionVirtualMachines', [])

      this.setQuoteExpansions(this.quoteExpansions)
    } else {
      this.quoteExpansions = quoteExpansionsStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteExpansions'
    ]),
    vmCPUChanged (rvm) {
      if (rvm.virtualMachineId == null) {
        rvm.cpuReservationGhz = Number((rvm.vcpu * 1.5).toFixed(2))
      }
    },
    async gotoPage (page) {
      var validationFailed = false
      var rvms = this.quoteExpansions.expansionVirtualMachines

      rvms.forEach(rvm => {
        if (!this.isValidExpansionVm(rvm)) {
          validationFailed = true
        } else if (rvm.storageSizeGbArchive === '' || rvm.storageSizeGbArchive === null) {
          rvm.storageSizeGbArchive = 0
        }
      })

      if (validationFailed) {
        this.iQuoteFunctions_ShowError('Please correct highlighted errors on this page before proceeding')
        return
      }

      if (rvms.length > 0) {
        // update local store
        this.setQuoteExpansions(this.quoteExpansions)
      } else {
        this.deleteQuoteTechnology(this.quoteExpansions.id)
        // delete from local store
        this.setQuoteExpansions({ expansionVirtualMachines: [] })
      }

      // Save to DB
      await this.saveQuoteExpansionVirtualMachinesToDB(this.quote.id, this.quoteExpansions)

      this.setCurrentQuoteState(page)
    },
    operatingSystemChangedEvent (event, rvm) {
      console.log('operatingSystemChangedEvent')
      console.log(event.target.value)
      console.log(this.iQuoteConstants.operatingSystems.WINDOWS)
      if (parseInt(event.target.value) !== this.iQuoteConstants.operatingSystems.WINDOWS) {
        rvm.sql = false
      }
    },
    isValidExpansionVm (rvm) {
      var foundInvalid = false

      if (!this.isValidQuantity(rvm.quantity)) {
        foundInvalid = true
      }

      if (!this.containsText(rvm.requirements)) {
        foundInvalid = true
      }

      if (rvm.virtualMachineId === null) {
        if (!this.containsText(rvm.customServerName)) {
          foundInvalid = true
        }

        if (!this.containsValue(rvm.operatingSystemId)) {
          foundInvalid = true
        }

        if (!this.containsValue(rvm.expansionVmResilienceTypeId)) {
          foundInvalid = true
        }

        if (!this.isValidvCPU(rvm.vcpu)) {
          foundInvalid = true
        }

        if (!this.isValidMemoryGb(rvm.memoryGb)) {
          foundInvalid = true
        }

        if (!this.isValidCPUGhz(rvm.cpuReservationGhz)) {
          foundInvalid = true
        }

        if (!this.isValidStandardStorage(rvm.storageSizeGbStandard)) {
          foundInvalid = true
        }

        if (!this.isValidArchiveStorage(rvm.storageSizeGbArchive)) {
          foundInvalid = true
        }
      }

      return !foundInvalid
    },
    iconForExpansionVm (rvm) {
      if (this.isValidExpansionVm(rvm)) {
        return 'check-tick'
      } else {
        return 'cancel'
      }
    },
    isValidQuantity (value) {
      return (this.iQuoteFunctions_IsInteger(value) && value > 0 && value <= 99)
    },
    containsText (value) {
      return value !== null && value.trim().length > 0
    },
    containsValue (value) {
      return value != null
    },
    isValidvCPU (value) {
      return value != null && value > 0 && value < 48
    },
    isValidMemoryGb (value) {
      return value != null && value > 0 && value <= 256
    },
    isValidCPUGhz (value) {
      return value != null && value > 0 && value <= 100
    },
    isValidArchiveStorage (value) {
      return value === null || value === '' || (value < 100000 && value >= 0)
    },
    isValidStandardStorage (value) {
      return value != null && value > 0 && value < 100000
    },
    resetSelectedSubCategoryAndVm () {
      this.selectedVirtualMachineIdToAdd = 0
      this.selectedExpansionVmSubCategoryId = 0
    },
    resetSelectedVm () {
      this.selectedVirtualMachineIdToAdd = 0
    },
    finishService () {
      this.gotoPage('SelectService')
    },
    vmCompare (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    },
    getTempId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1
      var arr = this.quoteExpansions.expansionVirtualMachines

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    addStandardServer () {
      if (this.quoteExpansions.expansionVirtualMachines.map(x => x.virtualMachineId).includes(this.selectedVirtualMachineIdToAdd)) {
        this.iQuoteFunctions_ShowError('You have already added this virtual machine to the list below')
      } else {
        var newVM = {
          id: this.getTempId(),
          virtualMachineId: this.selectedVirtualMachineIdToAdd,
          customServerName: null,
          quantity: null,
          operatingSystemId: null,
          sql: false,
          expansionVmResilienceTypeId: null,
          requirements: '',
          vcpu: null,
          cpuReservationGhz: null,
          memoryGb: null,
          StorageSizeGbStandard: null,
          StorageSizeGbArchive: null
        }

        this.quoteExpansions.expansionVirtualMachines.unshift(newVM)
        this.iQuoteFunctions_ShowMessage('Standard Virtual Machine "' + this.displayExpansions_getServerNameForRequiredVirtualMachine(newVM) + '" added')

        this.selectedVirtualMachineIdToAdd = 0

        Vue.nextTick(() => {
          document.getElementById('expansionVmQuantity' + newVM.id).focus()
        })
      }
    },
    addCustomServer () {
      var newVM = {
        id: this.getTempId(),
        virtualMachineId: null,
        customServerName: this.newCustomServerName,
        quantity: null,
        operatingSystemId: null,
        sql: false,
        expansionVmResilienceTypeId: null,
        requirements: '',
        vcpu: null,
        cpuReservationGhz: null,
        memoryGb: null,
        storageSizeGbStandard: null,
        storageSizeGbArchive: null
      }

      this.quoteExpansions.expansionVirtualMachines.unshift(newVM)
      this.iQuoteFunctions_ShowMessage('Custom Virtual Machine "' + newVM.customServerName + '" added')

      this.newCustomServerName = ''

      Vue.nextTick(() => {
        document.getElementById('expansionVmQuantity' + newVM.id).focus()
      })
    },
    removeServer (id) {
      if (window.confirm('Remove this server?')) {
        var delVM = this.iQuoteFunctions_GetArrayItemById(this.quoteExpansions.expansionVirtualMachines, id)
        this.iQuoteFunctions_ShowMessage('Virtual Machine "' + this.displayExpansions_getServerNameForRequiredVirtualMachine(delVM) + '" Removed')
        this.iQuoteFunctions_ArrayRemoveById(this.quoteExpansions.expansionVirtualMachines, id)
      }
    }
  }
}
</script>
<style scoped>
  .small{width:60px;}
  .quotePanel label{width: 130px}
  .quotePanel {width: 1000px}
  .hidden{visibility: hidden}

  .iquoteExpansionsTable {
    display: inline-block;
    padding-left: 15px;
    width: 1000px;
    border: 1px solid silver;
  }

  .iquoteExpansionsTable td,th {
    padding-left: 5px;
  }

  .quotePanel .iquoteExpansionsTable input[type=text],input[type=number],input[type=checkbox],select {
    margin-left: 0px;
  }

  .expansionVirtualMachineHeader{
    border-bottom: 1px solid black;
    background-color:  rgb(34, 51, 59);  /* $maintel-teal */
    height: 40px;
    color: white;
  }

  .expansionVirtualMachineSection {
    /* border: 1px solid black; */
    background-color: white;
    margin-bottom: 25px;
  }

  .expansionVirtualMachineHeaderText {
    margin-left: 30px;
    margin-top: 20px;
    font-weight: bold;
  }

  .txtRequirements {
    width: 950px;
  }

  .inputControlsHeader th{
    padding-top: 25px;
  }

  .quotePanel.shortTextBox input[type=number] {
    width: 50px;
  }

  .quotePanel h4.expansionsApprovalWarning {
    color: red;
    font-weight: bold;
    text-align: center;
    background-color: blanchedalmond;
    border: 1px solid silver;
  }
</style>
