<template>
  <div class="securityHolder">
    <div class="mdContainer appSectionBody">
      <div
        :class="{ mdHalfScreen : !!selectedPermission && viewAssignedPermissionPermission }"
        class="mdPermissionManagement"
      >
        <table id="permissionsTable">
          <thead/>
          <tbody>
            <tr
              v-for="v in currentFilter"
              :key="v.id"
              :class="getRowColour(v.id)"
              :tabindex="20"
              @click="selectPermission(v)"
              @keyup="testSelectPermission($event, v)"
            >
              <td>{{ v.permissionName }}</td>
              <td>{{ v.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="!!selectedPermission"
        class="mdSelection"
      >
        <SectionCollapsedHeader
          v-if="viewAssignedPermissionPermission"
          id="sectionPermissionRoleMembers"
          :title="permissionsRoleTitle"
          type="subHeader"
          icon-file="People"
          controls="permissionRoleMembers"
        />
        <PermissionRoleMembers
          id="permissionRoleMembers"
          :permissionid="selectedPermission.permissionId"
          :userid="userid"
          :use-alt-colour="useAltColour"
        />
        <SectionCollapsedHeader
          v-if="viewAssignedPermissionPermission"
          id="sectionPermissionEntityMembers"
          :title="permissionsEntityTitle"
          type="subHeader"
          icon-file="Person"
          controls="permissionEntityMembers"
        />
        <PermissionEntityMembers
          id="permissionEntityMembers"
          :permission="selectedPermission"
          :use-alt-colour="useAltColour"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PermissionRoleMembers from './permissions/permissionRoleMembers'
import PermissionEntityMembers from './permissions/permissionEntityMembers'
import SectionCollapsedHeader from '../../components/common/sectionCollapsedHeader'
import tableHandler from '../../mixins/tableHandler'
import { permissionsMixin } from '../../mixins/permissions'
import { mapActions } from 'vuex'
export default {
  name: 'PermissionsPage',
  components: {
    SectionCollapsedHeader,
    PermissionRoleMembers,
    PermissionEntityMembers
  },
  mixins: [
    permissionsMixin,
    tableHandler
  ],
  props: {
    username: {
      type: String,
      default: ''
    },
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      selectedPermission: null,
      selected: null
    }
  },
  computed: {
    permissionsEntityTitle () {
      return 'Permission Members'
    },
    permissionsRoleTitle () {
      return 'Role Permissions'
    },
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    reloadPermissions () {
      return this.$store.getters.reloadPermissions
    },
    viewAssignedPermissionPermission () {
      return this.$store.getters.viewAssignedPermissionPermission
    },
    permissions () {
      var perms = this.$store.getters.permissions
      var that = this
      let activePermissionss = []
      if (perms.length > 0) {
        perms.forEach(function (perm) {
          const permission = that.allPermissions.find(p => p.action === perm.action)
          if (permission) {
            perm.description = permission.description
            activePermissionss.push(perm)
          }
        })
      }
      return activePermissionss
    },
    allPermissions () {
      return this.$store.getters.allPermissions
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    userid () {
      this.setComponent()
    },
    reloadPermissions (val) {
      if (val) {
        this.setComponent()
        this.setReloadPermissions(false)
      }
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setReloadPermissions'
    ]),
    setComponent () {
      this.getPermissions()
    },
    selectPermission (selectedRow) {
      this.selectedPermission = selectedRow
      if (this.selected === selectedRow.permissionId) {
        this.selected = '-1'
        this.selectedPermission = null
      } else {
        this.selected = selectedRow.permissionId
      }
    },
    getPermissions () {
      this.initialiseTable(
        'permissionsTable',
        this.permissions.sort((a, b) => a.permissionName !== b.permissionName ? a.permissionName < b.permissionName ? -1 : 1 : 0),
        ['permissionName', 'description'],
        ['Permission Name', 'Description'],
        ['', ''],
        '600px',
        null,
        18
      )
    },
    testSelectPermission (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectPermission(selectedRow)
      }
    },
    getRowColour (id) {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      if (this.selectSite === id) {
        rtn = 'itemSelected'
      }
      return rtn
    }
  }
}

</script>
<style scoped>
  .mdSelection {
    display:inline-block;
    width: 380px;
    vertical-align: top;
    margin-left: 10px;
  }
  .mdPermissionManagement{
    display:inline-block;
    margin: 0;
    width:100%;
  }
  .mdPermissionManagement.mdHalfScreen{
    width:calc(100% - 400px);
  }
  .securityHolder {width:100%;}
  .appSectionBody{
    display:inline-block;
    margin-top:10px;
    background: white;
    padding: 15px;
  }
  .pgContainer {
    height: calc(100vh - 280px);
  }
</style>
