<template>
  <div
    class="securityGroupsHolder"
  >
    <Spinner
      v-if="loading"
      id="securityGroupMemberSpinner"
    />
    <div
      v-if="!loading"
    >
      <p
        class="alert"
      >
        CAUTION! Deleting the user will also delete the user in the UC Analytics instance and they will no longer to access the Datatrack site.
      </p>
      <p
        class="alert"
      >
        Please be sure that this action is necessary.
      </p>
      <input
        v-if="stormAdmin && !loading"
        id="deleteUserButton"
        type="button"
        class="deleteUserButton"
        value="Delete User"
        title="Deletes the user from UC Analytics permanently"
        @click="removeUser()"
      >
    </div>
  </div>
</template>
<script>
import { stormMixin } from '../../../mixins/stormMixin'
import Spinner from '../../common/spinner'
export default {
  name: 'StormDeleteUser',
  components: {
    Spinner
  },
  mixins: [
    stormMixin
  ],
  props: {
    stormUserId: {
      type: Number,
      default: -1
    },
    stormAdmin: {
      type: Boolean,
      default: false
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      showNew: false,
      loading: false,
      userSecurityGroups: []
    }
  },
  watch: {
    stormUserId () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    setComponent: async function () {
      this.loading = true
      this.loading = false
    },
    removeUser: async function () {
      var rtn = await this.deleteStormUser(this.stormUserId)
      if (rtn) {
        await this.setupStormUsers()
        this.$store.commit('showMessage', { content: 'Successfully Deleted User!', color: 'blue', timeout: 3000 })
        this.$emit('reload-storm-users', 0)
      } else {
        this.$store.commit('showMessage', { content: 'There was an error deleting the user. Please ensure no secuirty groups are assigned.', color: 'red', timeout: 3000 })
      }
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    }
  }
}
</script>
<style scoped>
  .securityGroupsHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .deleteUserButton {
    margin: 10px;
    float: right;
    cursor: pointer;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
  }
</style>
