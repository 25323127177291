<template>
  <div
    class="customerEntitiesHolder"
  >
    <Spinner v-if="loading" />
    <div
      class="entityCustomersContainer"
    >
      <table
        v-show="!loading"
        style="width:100%"
      >
        <thead>
          <tr>
            <th style="width:80%;">User</th>
            <th
              v-if="assignToCustomerPermission"
              style="width:20%;"
            >
              Enabled
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="u in users"
            :class="getRowColour()"
            :key="u.id"
          >
            <td> {{ u.firstName + ' ' + u.lastName }} </td>
            <td
              v-if="assignToCustomerPermission"
            >
              <TriStateToggleSwitch
                :ref="`customer-toggle-${u.id}`"
                :enabled="true"
                :current-state="customerEntityAssignments.some(ce => ce.entityId === u.id && ce.customerId === customerid) ? 1 : 2"
                :tri-state="false"
                class="tableToggleSwitch"
                @statechanged="({ selectedState }) => {
                  updateCustomerState({
                    entityId: u.id,
                    customerId: customerid,
                    isDefault: false,
                    selectedState: selectedState})
                }"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <UserCustomerNewRole
        :roles="customerRoles"
        :userid="selectedUserId"
        @RolesAdded="addUserToCustomer"
        @RoleCancelled="undoCustomerAdd"
      />
    </div>
  </div>
</template>
<script>
import { permissionsMixin } from '../../../mixins/permissions'
import { usersMixin } from '../../../mixins/users'
import Spinner from '../../common/spinner'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { mapActions } from 'vuex'
import UserCustomerNewRole from '../../users/userCustomerNewRole'

export default {
  name: 'CustomerUserComponent',
  components: {
    Spinner,
    TriStateToggleSwitch,
    UserCustomerNewRole
  },
  mixins: [
    permissionsMixin,
    usersMixin
  ],
  props: {
    customerid: {
      type: Number,
      default: -1
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      loading: false,
      users: [],
      customerEntityAssignments: [],
      customerRoles: [],
      selectedUserId: -1,
      selectedCustomerId: -1,
      selectedIsDefault: -1
    }
  },
  computed: {
    assignToCustomerPermission () {
      return this.$store.getters.assignToCustomerPermission
    },
    currentUserCustomers () {
      return this.$store.getters.customers
    }
  },
  watch: {
    customerid () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setSubForm'
    ]),
    setComponent: async function () {
      if (this.customerid > -1) {
        this.getUsers(true)
      }
    },
    getUsers: async function (updateLoading) {
      var storeUsers = this.$store.getters.users
      var userStringList = ''

      if (storeUsers.length > 0) {
        if (updateLoading) {
          this.loading = true
        }
        storeUsers.forEach(user => { userStringList += '|' + user.id })
        userStringList = userStringList.substring(1)
        Promise.all([
          this.getCustomerEntities({ customerId: this.customerid }).then((results) => {
            this.customerEntityAssignments = results
          }),
          this.getUserList(userStringList).then((results) => {
            this.users = results
          })
        ]).then(() => {
          if (updateLoading) {
            this.loading = false
          }
        })
      }
    },
    updateCustomerState: async function ({ entityId, customerId, isDefault, selectedState }) {
      this.loading = true
      this.selectedUserId = entityId
      this.selectedCustomerId = customerId
      this.selectedIsDefault = isDefault

      if (selectedState === 1) {
        // get a list of roles that are currently assigned to the customer
        this.customerRoles = await this.getRolesByCustomerAndEntity(customerId, entityId)

        // get the roles for the user that we are assigning the customer to
        var targetUserRoles = await this.getRoleMembersForEntity(entityId, customerId)

        // compare customer roles with current users roles
        if (!targetUserRoles || (targetUserRoles && !targetUserRoles.some(role => this.customerRoles.some(cRole => cRole.id === role.roleId)))) {
          // if there is only one role available - create the users new roleentity record without the popup showing
          if (this.customerRoles.length === 1) {
            this.addEntityRole(entityId, this.customerRoles[0].id)
            this.addUserToCustomer([ this.customerRoles[0] ])
          } else {
            // if they don't have a matching role and there is more than one role available then the user should select a role from the available list
            this.setSubForm(true)
          }
        } else {
          this.addUserToCustomer(this.customerRoles.filter(role => targetUserRoles.some(cRole => cRole.id === role.roleId)))
        }
      } else if (selectedState === 2) {
        // await this.deleteEntityRoleForCustomer({ entityId, customerId })
        await this.deleteUserCustomer({ entityId, customerId })
        this.loading = false
      }
      this.getUsers(false)
    },
    addUserToCustomer: async function (assignedRoles) {
      var targetSite = this.currentUserCustomers.find(o => o.id === this.selectedCustomerId).topLevelAutotaskSite
      // check if the user is currently assigned to an area or one of it's child areas
      var customerAreas = await this.getAreaChildAreas(
        targetSite)

      // Get the target users areas
      var targetUserAreas = await this.getTargetUserAreas(this.selectedUserId, this.selectedCustomerId)

      if (customerAreas && !customerAreas.some(area => targetUserAreas && targetUserAreas.some(uArea => uArea.id === area.id))) {
        // If the user doesn't have an area record in one of the customers areas, add a record for the top level area assigned to that customer
        await this.addEntityArea(
          this.selectedUserId,
          targetSite
        )
      }
      await this.createUserCustomer({ entityId: this.selectedUserId, customerId: this.selectedCustomerId, isDefault: this.selectedIsDefault })
      await this.getUsers(false)
      this.loading = false
      if (assignedRoles) {
        this.$store.commit('showMessage', { content: `User has been assigned to the top level site with the role(s): ${assignedRoles.map(role => role.name).join(',')} `, color: 'blue', timeout: 5000 })
      } else {
        this.$store.commit('showMessage', { content: 'User has been assigned to the top level site', color: 'blue', timeout: 5000 })
      }
    },
    undoCustomerAdd () {
      this.$refs[`customer-toggle-${this.selectedCustomerId}`][0].toggleState(2)
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    }
  }
}

</script>
<style scoped>
  .entityCustomersContainer {
    padding: 15px 10px 10px 10px;
  }

  .customerEntitiesHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    margin-bottom: 10px;
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }

  table thead tr th {
    padding:3px 5px;
    font-size: 0.9em;
  }

</style>
