<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-ucanalytics"
          primary-title
        >
          Managing UC Analytics Users
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details the management of UC Analytics users"
                indentation="0"
              />
              <HelpSection
                instruction="1) Navigate to the Security page – if your user is a member of the “Icon User” or “Icon Admin” security groups, you will see an additional “UC Analytics Users” tab."
                image-source="uc_analytics_2.png"
                indentation="1"
              />
              <HelpSection
                instruction="2) If the user is a member of the “Icon Admin” security group, they will also see the “Add New UC Analytics User” on the top right and are able to change values shown."
                indentation="1"
              />
              <HelpSection
                instruction="From here, “Icon Admin” users can add and remove UC Analytics Users, as well as edit their details and Security groups."
                indentation="2"
              />
              <HelpSection
                instruction="If the user is a member of the “Icon User” security group, they can view all the user details on the screen but cannot perform any operations on them."
                indentation="2"
              />
              <HelpSection
                instruction="If the user is a member of the “Web Interface User” security group, they will be shown the “Launch UC Analytics Portal” button on the main UC analytics page that will allow them to log into their Datatrack account directly. Please not that there are a limited number of these roles that may be awarded, depending on the subscription tier."
                indentation="2"
              />
              <HelpSection
                instruction="3) Select a user by clicking on them in the table to display the options that are able to be performed against them."
                image-source="uc_analytics_4a.png"
                indentation="1"
              />
              <HelpSection
                instruction="4) Expand the “Details” option to see the user details which may be changed if an “Icon Admin” user or viewed if an 'Icon User' user."
                image-source="uc_analytics_4.png"
                indentation="1"
              />
              <HelpSection
                instruction="5) Expand the “Security groups” option to see a list of Security groups associated with the user."
                image-source="uc_analytics_5.png"
                indentation="1"
              />
              <HelpSection
                instruction="Clicking on the delete icon next to a Security Group will remove the user from this Security Group."
                indentation="2"
              />
              <HelpSection
                instruction="6) By selecting “Add Security Group” you can add the user to additional Security Groups via the pop up selection screen."
                image-source="uc_analytics_5a.png"
                indentation="1"
              />
              <HelpSection
                instruction="7) Expand the “User groups” option to see a list of User groups associated with the user."
                image-source="uc_analytics_5b.png"
                indentation="1"
              />
              <HelpSection
                instruction="Clicking on the delete icon next to a User Group will remove the user from this User Group."
                indentation="2"
              />
              <HelpSection
                instruction="8) By selecting “Add User Group” you can add the user to additional User Groups via the pop up selection screen."
                image-source="uc_analytics_5c.png"
                indentation="1"
              />
              <HelpSection
                instruction="9) Select a user and expand the “Delete User” option to be able to delete the UC Analytics user (note: this will not delete the user from Portal, but just remove their individual UC Analytics integration)"
                image-source="uc_analytics_6.png"
                indentation="1"
              />
              <HelpSection
                instruction="10) To add a new UC Analytics user, press the “Add New UC Analytics User” button."
                image-source="uc_analytics_3.png"
                indentation="1"
              />
              <HelpSection
                instruction="11) Once all compulsory fields are filled in and “Add User” is selected, the user will be added to the list of UC Analytics users."
                indentation="1"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpUCAnalyticsManagement',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
