import portalAPIGateway from '../../../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteIconNowAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteIconNowAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteQuoteIconNowAPIMixin_saveQuoteIconNowToDB: async function (quoteId, quoteIconNow) {
      var axiosConfig = this.iQuoteQuoteIconNowAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.put('iquote/quoteiconNow/' + quoteId, quoteIconNow, axiosConfig).then((response) => {
          returnValue = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteIconNowAPIMixin_saveQuoteIconNowToDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteIconNowAPIMixin_deleteQuoteIconNowFromDB: async function (quoteIconNow) {
      var axiosConfig = this.iQuoteQuoteIconNowAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.delete('iquote/quoteiconNow/' + quoteIconNow.id, null, axiosConfig).then((response) => {
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteIconNowAPIMixin_deleteQuoteIconNowFromDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteIconNowAPIMixin_getQuoteIconNowFromDb: async function (quoteId) {
      var axiosConfig = this.iQuoteQuoteIconNowAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquote/quoteiconNow/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteIconNowAPIMixin_getQuoteIconNowFromDb returned error: ' + error)
      }

      return data
    }
  }
}
