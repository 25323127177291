import { render, staticRenderFns } from "./sipServices.vue?vue&type=template&id=7cab9b00&scoped=true&"
import script from "./sipServices.vue?vue&type=script&lang=js&"
export * from "./sipServices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cab9b00",
  null
  
)

export default component.exports