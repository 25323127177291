<template>
  <div style="padding-top:30px;">
    <div
      v-if="!editCompanyPermission"
      class="noResults"
    >
      Sorry, you do not have permission update companies
    </div>
    <div
      v-else
      :class="sectionClass"
    >
      <table
        id="companiesTable"
        style="table-layout: auto;margin-bottom:20px;font-weight: normal;"
      >
        <thead>
          <tr>
            <th style="width:25px">ID</th>
            <th style="width:calc(100% - 25px)">Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="companies.length === 0">
            <td
              v-if="!loadingSites"
              colspan="2"
            >There are no companies in the database</td>
          </tr>
          <tr
            v-for="c in currentFilter"
            v-else
            :key="c.id"
            @click="selectCompany(c)"
          >
            <td>{{ c.id }}</td>
            <td
              :title="c.name"
              style="max-width:280px"
            >{{ c.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="selectedCompany>0"
      class="mdSelection"
    >
      <SectionCollapsedHeader
        id="sectionUpdateCompany"
        icon-file="Edit"
        type="subHeader"
        title="Update Company"
        controls="updateCompanyComponent"
      />
      <CompanyDetails
        id="updateCompanyComponent"
        :companyid="selectedCompany"
      />
    </div>
    <div class="bottombar" >
      <Spinner v-if="saveInProgress" />
      <input
        v-if="!saveInProgress"
        id="cancelCompanyButton"
        class="cancelCompanyButton"
        type="button"
        title="Cancel the update company process"
        @click="closeUpdateCompanyForm()"
      >
    </div>
  </div>
</template>

<script>
import SectionCollapsedHeader from '../common/sectionCollapsedHeader'
import Spinner from '../common/spinner'
import tableHandler from '../../mixins/tableHandler'
import CompanyDetails from './companyDetails'
export default {
  metaInfo: {
    title: 'Support - Update Company'
  },
  name: 'UpdateCompany',
  components: {
    Spinner,
    SectionCollapsedHeader,
    CompanyDetails
  },
  mixins: [
    tableHandler
  ],
  data () {
    return {
      saveInProgress: false,
      loadingSites: false,
      formOK: false,
      selectedCompany: 0
    }
  },
  computed: {
    editCompanyPermission () {
      return this.$store.getters.editCompanyPermission
    },
    companies () {
      return this.$store.getters.companies
    },
    sectionClass () {
      if (this.selectedCompany > 0) {
        return 'halfSection'
      } else {
        return 'fullSection'
      }
    }
  },
  mounted () {
    this.setUpTable(this.companies)
    this.visibleSites = this.companies
  },
  methods: {
    closeUpdateCompanyForm () {
      this.$emit('setTileMode')
    },
    selectCompany: async function (selectedRecord) {
      this.selectedSite = selectedRecord
      if (this.selectedCompany === selectedRecord.id) {
        this.selectedCompany = -1
      } else {
        this.selectedCompany = selectedRecord.id
      }
    },
    setUpTable (data) {
      this.initialiseTable(
        'companiesTable',
        data,
        ['id', 'name'],
        ['Id', 'Company Name'],
        ['', ''],
        ['50px', 'calc(100% - 50px)'],
        null,
        24
      )
    }
  }
}
</script>
<style scoped>
  .updateSectionData {
    display: inline-block;
    width: 100%;
    padding-left: 20px;
  }
  .cancelCompanyButton {
    background-image: url('../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
    float: right;
    margin-bottom: 40px;
  }
  .halfSection {
    display: inline-block;
    width:calc(100% - 420px);
    padding-left:20px;
  }
  .fullSection {
    display: inline-block;
    width:100%;
    padding-left:20px;
  }
</style>
