<template>
  <div
    :id="id"
    class="prmSectionContainer"
  >
    <div
      id="frmUserPermissions"
      class="prmContainer"
    >
      <label
        appDarkFont
        for="cboAreas"
        title="Permissions Site"
      >At Site: </label>
      <PortalDropdown
        id="cboAreas"
        :options="areas"
        :disabled="false"
        :max-item="10"
        :attach-blur-event="false"
        parent-class="prmSectionContainer"
        placeholder="Please select a site"
        @selected="changeArea($event)"
      />
      <div class="prmtable">
        <div class="prmsection">
          <div class="prmbody-visible">
            <table>
              <thead>
                <tr>
                  <th style="width:50%">Permission</th>
                  <th style="width:16%">Allowed</th>
                  <th
                    v-if="areaHasChildren"
                    style="width:16%">Grant</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="v in perms"
                  :key="v.permissionId"
                >
                  <td>{{ v.permissionName }} </td>
                  <td style="padding: 6px 5px 0 0;">
                    <TriStateToggleSwitch
                      :enabled="v.canGrant"
                      :current-state="v.allowed"
                      :permission-id="v.permissionId"
                      :user-id="userid"
                      @statechanged="updateAllowedPermission"
                    />
                  </td>
                  <td
                    v-if="areaHasChildren"
                    style="padding: 6px 5px 0 0;">
                    <TriStateToggleSwitch
                      :enabled="updateGrantPermissionPermission"
                      :current-state="v.grant"
                      :permission-id="v.permissionId"
                      :user-id="userid"
                      @statechanged="updateGrantPermission"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { permissionsMixin } from '../../mixins/permissions'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'
import PortalDropdown from '../common/portalDropdown'
export default {
  components: {
    TriStateToggleSwitch,
    PortalDropdown
  },
  mixins: [ permissionsMixin ],
  props: {
    username: {
      type: String,
      default: ''
    },
    userid: {
      type: Number,
      default: -1
    },
    areaid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      perms: [],
      areas: [],
      currentAreaId: -1,
      areaHasChildren: false,
      updateGrantPermissionPermission: false,
      updateAllowPermissionPermission: false
    }
  },
  computed: {
    collapseIconName () {
      return require(`../../assets/icons/expand.png`)
    }
  },
  watch: {
    userid () {
      this.setComponent()
    },
    areaid () {
      this.currentAreaId = this.areaid
    }
  },
  created () {
    this.setComponent()
  },
  methods: {
    changeArea: async function (event) {
      if (event.id) {
        this.currentAreaId = event.id
        await this.populatePermissions(this.currentAreaId)
      }
    },
    setCollapseIconName (state) {
      return require(`../../assets/icons/${state}.png`)
    },
    setComponent: async function () {
      if (this.userid > -1) {
        this.loading = true
        await this.populateAreas()
        if (this.areas.length > 0) {
          this.currentAreaId = this.areas[0].id
          await this.populatePermissions(this.currentAreaId)
        }
        this.loading = false
      }
    },
    populateAreas: async function () {
      var values = await this.getUserAreas(this.userid)
      this.areas = values
    },
    populatePermissions: async function (area) {
      if (area !== undefined) {
        var values = await this.getUserPermissions(this.userid, area)
        var currentArea = this.areas.find(a => a.id === area)
        var currentUserPermissions = currentArea && currentArea.parent
          ? await this.getUserPermissions(this.$store.getters.userid, currentArea.parent)
          : await this.getUserPermissions(this.$store.getters.userid, area)
        var children = await this.getAreaChildAreas(area)
        this.areaHasChildren = children.length > 0

        values = values.map((value) => ({
          ...value,
          canGrant: this.updateAllowedPermission && this.canGrantPermission(value.action, currentUserPermissions)
        }))

        this.perms = values
      }
    },
    itemsMatchingBag (bagname) {
      return this.perms.filter(function (item) {
        return item.bagName === bagname
      })
    },
    inheritedTitle (roleName) {
      return 'Inherited from: ' + roleName
    },
    updateAllowedPermission ({ permissionId, selectedState, userId, roleId }) {
      var areaId = this.currentAreaId
      this.updateAssignedPermissionByEntity({
        permissionId,
        entityId: userId,
        areaId
      }, 'allowed', selectedState)
    },
    updateGrantPermission ({ permissionId, selectedState, userId, roleId }) {
      var areaId = this.currentAreaId
      this.updateAssignedPermissionByEntity({
        permissionId,
        entityId: userId,
        areaId
      }, 'grant', selectedState)
    },
    // setPermissions: async function () {
    //   this.updateAllowPermissionPermission = await this.getUserPermission(this.userid, this.currentAreaId, 'updateAllowPermission')
    //   this.updateGrantPermissionPermission = await this.getUserPermission(this.userid, this.currentAreaId, 'updateGrantPermission')
    // },
    canGrantPermission (permissionAction, currentUserPermissions) {
      var rtn = currentUserPermissions.some(permission => permission.action === permissionAction && permission.grant === 1)
      return rtn
    }
  }
}
</script>

<style scoped>
  .prmSectionContainer{
    width:400px;
    display: none;
    margin-bottom:10px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .prmContainer{
    background-color: rgba(255,255,255,0.7);
    padding:15px 10px 10px 10px;
    overflow-y: auto;
    max-height: 375px;
  }
  .prmtable {
    margin-top: 5px;
    margin-bottom:5px;
  }
  .prmtitle{
    background-color:rgba(44,62,80,0.9);
    color: #fff;
    cursor: pointer;
    padding: 3px 5px;
    margin: 0 0 10px 0;
    border-radius: 5px;
    height: 28px;
  }
  .prmbody{
    display:none;
  }
  .prmbody-visible{
    display:block;
    overflow-y: auto;
    max-height: 250px;
  }
  .toggleIcon {
    float: right;
    padding: 0px;
  }
  .toggleIcon img {
    width: 18px;
  }
  h4 {margin-bottom: 10px;}
  label {font-weight: bold;}
  #cboAreas { width: calc(100% - 100px); height: 28px; padding: 0 5px 0 5px; border: none; border-radius: 15px;margin: 0 0 10px 10px;display: inline-block;}

  /* table {font-size:0.8em;border-spacing:0px;border-collapse: separate;width: 100%; background-color: rgba(255,255,255,0.7);} */
  /* tbody tr:nth-child(even) {background-color: #5ab157 } */
  /* tbody tr td {padding:3px;} */
</style>
