<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-pmn"
          primary-title
        >
          Planned Maintenance Notifications
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details how the planned maintenance noticiation screen and it's related components behave."
              />
              <HelpSection
                instruction="1.) The home page now has two extra component whhich relate to the Planned Maintenance screen."
                image-source="pmn_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) The PMN Summary component shows the number of PMN tickets by impact type. Clicking on one of the segments opens the PMN screen and filters it to the particlular impact clicked on."
                indentation="1"
              />
              <HelpSection
                instruction="b.) THE PMN Due Dates component shows how many PMM tickets are due within timeframes. clicking on a segment opens the PMN screen and filters it to the selected date range."
                indentation="1"
              />
              <HelpSection
                instruction="2.) The main PMN screen can also be reached by clicking on the Planned Maintenance menu link on the left hand side. It shows all planned maintenance tickets that are outstanding for the customer."
                image-source="pmn_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="3.) Clicking on a ticket in the list displays the summary data for that ticket on the right hand side."
                image-source="pmn_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="4.) The details button on the ticket details component may be clocked to show the full details against the ticket including all notes and attachments."
                indentation="0"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpCreateUsers',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
