<template>
  <div
    style="padding-top:30px;width:100%;"
  >
    <div
      v-if="!editCompanyPermission"
      class="noResults"
    >
      Sorry, you do not have permission update companies
    </div>
    <div
      v-else
      :class="sectionClass"
    >
      <div>
        <label
          for="txtUpdateCompany"
          class="updateEmailSuffixLabel"
        >Company:</label>
        <input
          id="txtUpdateCompany"
          :value="companyname"
          readonly="readonly"
          style="width: 270px;"
        >
      </div>
      <div>
        <label
          for="txtEmailAddress"
          class="updateEmailSuffixLabel"
        >
          Email Suffix:
        </label>
        <input
          id="txtEmailAddress"
          :class="{ errorHighlight: emailSuffixError || emailDuplicationError }"
          v-model.trim="emailSuffixText"
          type="text"
          class="updateEmailSuffixTextbox emailSuffix"
          @blur="checkForm"
        >
      </div>
    </div>
    <div class="bottombar" >
      <Spinner v-if="saveInProgress" />
      <TextButton
        v-if="!saveInProgress"
        id="updateEmailSuffixButton"
        :disabled="!emailSuffixError && !emailDuplicationError"
        class="updateEmailSuffixButton"
        type="button"
        title="updates the email suffix entry"
        text="Update"
        @click="changeEmailSuffix()"
      />
    </div>
  </div>
</template>

<script>
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'
import Spinner from '../../common/spinner'
import TextButton from '../../common/textButton.vue'
import { permissionsMixin } from '../../../mixins/permissions'
export default {
  metaInfo: {
    title: 'Support - Update Company'
  },
  name: 'UpdateCompany',
  components: {
    Spinner,
    SectionCollapsedHeader,
    TextButton
  },
  mixins: [
    permissionsMixin
  ],
  props: {
    emailsuffixid: {
      type: Number,
      default: 0
    },
    companyid: {
      type: Number,
      default: 0
    },
    emailsuffix: {
      type: String,
      default: ''
    },
    companyname: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      saveInProgress: false,
      loadingSites: false,
      emailSuffixError: false,
      emailDuplicationError: false,
      emailSuffixText: '',
      emailSuffixes: []
    }
  },
  computed: {
    editCompanyPermission () {
      return this.$store.getters.editCompanyPermission
    },
    sectionClass () {
      if (this.selectedCompany > 0) {
        return 'halfSection'
      } else {
        return 'fullSection'
      }
    }
  },
  watch: {
    emailsuffixid () {
      this.emailSuffixText = this.emailsuffix
      this.emailSuffixError = false
      this.emailDuplicationError = false
    }
  },
  async mounted () {
    this.emailSuffixText = this.emailsuffix
    this.emailSuffixes = await this.getAllCompanyEmailSuffixes()
  },
  methods: {
    async changeEmailSuffix () {
      this.checkForm({ target: { } })
      if (!this.emailSuffixError && !this.emailDuplicationError) {
        if (await this.updateEmailSuffix(this.emailsuffixid, this.companyid, this.emailSuffixText)) {
          this.$store.commit('showMessage', { content: 'The email suffix was updated successfully', color: 'blue', timeout: 3000 })
          this.close()
        } else {
          this.$store.commit('showMessage', { content: 'An error occurred updating the email suffix to the database', color: 'red', timeout: 5000 })
        }
      }
    },
    selectCustomerCompany (selectedRecord) {
      this.selectedSite = selectedRecord
      if (this.selectedCompany === selectedRecord.id) {
        this.selectedCompany = -1
      } else {
        this.selectedCompany = selectedRecord.id
      }
    },
    validateEmailSuffix (suffix) {
      var re = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return !re.test(suffix)
    },
    isDuplicateSuffix (suffix) {
      var res = this.emailSuffixes.find(s => s.emailSuffix === suffix)
      return res !== undefined
    },
    checkForm ({ target: { className: checkField } }) {
      console.log('checkform')
      if (checkField) {
        if (checkField.indexOf('emailSuffix') > 0) {
          this.emailSuffixError = this.validateEmailSuffix(this.emailSuffixText)
          this.emailDuplicationError = this.isDuplicateSuffix(this.emailSuffixText)
        }
      } else {
        this.emailSuffixError = this.validateEmailSuffix(this.emailSuffixText)
        this.emailDuplicationError = this.isDuplicateSuffix(this.emailSuffixText)
      }
      if (this.emailSuffixError) {
        this.$store.commit('showMessage', { content: 'The email suffix is not valid', color: 'red', timeout: 5000 })
      } else if (this.emailDuplicationError) {
        this.$store.commit('showMessage', { content: 'The email suffix is already in use and cannot be used again', color: 'red', timeout: 5000 })
      }
    },
    close () {
      this.emailSuffixText = ''
      this.$emit('emailSuffixUpdated')
    }
  }
}
</script>
<style scoped>
  .updateSectionData {
    display: inline-block;
    width: 100%;
    padding-left: 20px;
  }
  .updateEmailSuffixLabel {
    width: 100px;
    display: inline-block;
  }
  .updateEmailSuffixButton {
    margin: 20px 8px 20px 0;
    float: right;
  }
  .updateEmailSuffixTextbox {
    padding: 0 0 0 5px;
  }
  .halfSection {
    display: inline-block;
    width:calc(100% - 420px);
    padding-left:20px;
  }
  .fullSection {
    display: inline-block;
    width:100%;
    padding-left:20px;
  }
  .errorHighlight {
    border: 1px solid red;
  }
</style>
