<template>
  <div
    :id="id"
    class="permissionHolder"
  >
    <label
      class="permissionDetailLabel"
      for="txtUpdatePermissionName">Permission Name</label>
    <input
      id="txtUpdatePermissionName"
      :value="permission.name"
      class="permissionDetailInput"
      type="text"
    >
    <label
      class="permissionDetailLabel"
      for="txtUpdatePermissionDescription">Description</label>
    <input
      id="txtUpdatePermissionDescription"
      :value="permission.description"
      class="permissionDetailInput"
      type="text"
    >
    <input
      v-if="updateMemberPermissionPermission"
      id="btnUpdatePermission"
      type="button"
      class="permissionUpdateButton"
      value="Update Permission"
      title="Adds the new user to the permission"
      @click="updateMemberPermission()"
    >
  </div>
</template>
<script>
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions } from 'vuex'
export default {
  name: 'PermissionDetails',
  mixins: [
    permissionsMixin
  ],
  props: {
    permissionid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      permission: {}
    }
  },
  computed: {
    formOK () {
      var rtn = true
      if (this.permission.id < 0) {
        rtn = false
      }
      if (document.getElementById('txtUpdatePermissionName').value.length < 2) {
        rtn = false
      }
      if (document.getElementById('txtUpdatePermissionDescription').value.length < 2) {
        rtn = false
      }
      return rtn
    },
    updateMemberPermissionPermission () {
      return this.$store.getters.updateMemberPermission
    }
  },
  watch: {
    permissionid () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setReloadPermissions'
    ]),
    setComponent () {
      this.getDetails()
    },
    getDetails: async function () {
      var rtn = await this.getPermission(this.permissionid)
      this.permission = rtn
    },
    updateMemberPermission: async function () {
      if (this.formOK) {
        this.permission.name = document.getElementById('txtUpdatePermissionName').value
        this.permission.description = document.getElementById('txtUpdatePermissionDescription').value
        await this.updatePermission(this.permission)
        this.setReloadPermissions(true)
      }
    }
  }
}

</script>
<style scoped>
  .permissionHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    display: none;
  }
  .permissionDetailLabel {
    display: inline-block;
    width: 35%;
  }
  .permissionDetailInput {
    display: inline-block;
    width: 60%;
    margin:0 0 10px 10px;
  }
  .permissionUpdateButton {
    float:right;
    cursor: pointer;
  }
</style>
