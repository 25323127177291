<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconSipMigrationOffer"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>SIP Migration Offer</h3>
      <br>
      <label
        :class="{InputError:!isValidNumEdgemarcDevices}"
        for="numEdgemarcDevices"
      >Edgemarc Devices
        <TooltipIcon
          text="How many physical edgemarc devices do you require?" />
      </label>
      <input
        id="numEdgemarcDevices"
        v-model.number="quoteSIPMigrationProperties.numEdgemarcDevices"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidNumOverridePurchaseCost}"
        for="numOverridePurchaseCost"
      >Override the Hardware Purchase Cost
        <TooltipIcon text="Itemised hardware costs will all be £0, and a single-line item with the value you set will be added to the quote" />
      </label>
      <input
        id="chkOverridePurchaseCost"
        :checked="quoteSIPMigrationProperties.overridePurchaseCost !== null"
        type="checkbox"
        style="margin-right:10px; margin-left:-23px"
        @change="setOverridePurchaseCost($event)"
      >
      <input
        id="numOverridePurchaseCost"
        :class="{hidden: quoteSIPMigrationProperties.overridePurchaseCost === null}"
        v-model.number="quoteSIPMigrationProperties.overridePurchaseCost"
        class="small"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        :class="{InputError:!isValidNumOverrideShipmentCosts}"
        for="numOverrideShipmentCosts"
      >Override shipment costs
        <TooltipIcon text="By default this will be £150 per unit" />
      </label>
      <input
        id="chkOverrideShipmentCosts"
        :checked="quoteSIPMigrationProperties.overrideShipmentCosts !== null"
        type="checkbox"
        style="margin-right:10px; margin-left:-23px"
        @change="setOverrideShipmentCosts($event)"
      >
      <input
        id="numOverrideShipmentCosts"
        :class="{hidden: quoteSIPMigrationProperties.overrideShipmentCosts === null}"
        v-model.number="quoteSIPMigrationProperties.overrideShipmentCosts"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        for="numSurvivability"
      >Survivability
      </label>
      <input
        id="chkSurvivability"
        v-model="quoteSIPMigrationProperties.survivability"
        type="checkbox"
        style="margin-right:10px; margin-left:-23px"
      >
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SIPServices')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'SipMigrationOffer',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin],
  data () {
    return {
      quote: {},
      codecs: [],
      quoteSIPMigrationProperties: {
        id: 0,
        numEdgemarcDevices: 0,
        overrideShipmentCosts: null,
        overridePurchaseCost: null,
        survivability: false
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconSipMigrationOffer () {
      return require(`../../../assets/icons/sipMigrationOfferComingSoon.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidNumEdgemarcDevices () {
      var value = this.quoteSIPMigrationProperties.numEdgemarcDevices
      return (this.iQuoteFunctions_IsInteger(value) && (value >= 1))
    },
    isValidNumOverridePurchaseCost () {
      var value = this.quoteSIPMigrationProperties.overridePurchaseCost
      return (value === null || (this.iQuoteFunctions_IsInteger(value) && (value >= 1)))
    },
    isValidNumOverrideShipmentCosts () {
      var value = this.quoteSIPMigrationProperties.overrideShipmentCosts
      return (value === null || (this.iQuoteFunctions_IsInteger(value) && (value >= 1)))
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    this.quoteSIPMigrationProperties = this.$store.getters.quoteSIPMigrationProperties
    this.quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura

    if (this.quoteSIPMigrationProperties.id === undefined) {
      var sipMigrationOfferFromDb = await this.getQuoteSIPMigrationProperties(this.quote.id)

      if (sipMigrationOfferFromDb.numEdgemarcDevices === undefined) {
        // Not in Db
        this.quoteSIPMigrationProperties = {
          id: 0,
          quoteId: this.quote.id,
          numEdgemarcDevices: 0,
          overrideShipmentCosts: null,
          overridePurchaseCost: null,
          survivability: false
        }
      } else {
        this.quoteSIPMigrationProperties = sipMigrationOfferFromDb
      }

      this.setQuoteSIPMigrationProperties(this.quoteSIPMigrationProperties)

      this.quoteSIPMigrationProperties = this.$store.getters.quoteSIPMigrationProperties
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteSIPMigrationProperties'
    ]),
    async gotoPage (page) {
      var technologyId = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.sipMigrationOffer)

      if (this.quoteSIPMigrationProperties.numEdgemarcDevices === 0 && technologyId >= 1) {
      // SIP not required so delete from DB
        this.deleteQuoteTechnology(technologyId)
        // Clear form details from store
        this.setQuoteSIPMigrationProperties({})
      } else if (!(technologyId >= 1)) {
        // It's not in the db, so clear details
        this.setQuoteSIPMigrationProperties({})
      } else if (page === 'SIPServices') {
        // Going back, so reset to what it was in db
        var sipMigrationOfferFromDb = await this.getQuoteSIPMigrationProperties(this.quote.id)
        this.setQuoteSIPMigrationProperties(sipMigrationOfferFromDb)
      }
      this.setCurrentQuoteState(page)
    },
    async finishService () {
      var ValidationFailed = false
      if (!this.isValidNumEdgemarcDevices) {
        ValidationFailed = true
      }

      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied
        if (this.quoteSIPMigrationProperties.id === 0) {
          // eslint-disable-next-line
          var techId = await this.addQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.sipMigrationOffer)
        }

        this.quoteSIPMigrationProperties = await this.saveQuoteSipMigrationProperties(this.quoteSIPMigrationProperties)
        this.gotoPage('SelectService')
      } else {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
      }
    },
    setOverrideShipmentCosts (event) {
      var val = event.target.checked
      if (val) {
        this.quoteSIPMigrationProperties.overrideShipmentCosts = 0
      } else {
        this.quoteSIPMigrationProperties.overrideShipmentCosts = null
      }
    },
    setOverridePurchaseCost (event) {
      var val = event.target.checked
      if (val) {
        this.quoteSIPMigrationProperties.overridePurchaseCost = 0
      } else {
        this.quoteSIPMigrationProperties.overridePurchaseCost = null
      }
    }
  }
}
</script>
<style scoped>
  .quotePanel {width: 620px}
  .quotePanel label{width:320px;}
  .hidden{visibility: hidden};
  .small{width:60px;}
</style>
