<template>
  <div
    v-if="$store.getters.openMainForm"
  >
    <div
      v-if="!createEntityPermission"
      class="noResults"
    >
      Sorry, you do not have permission to create users
    </div>
    <div
      v-else
      id="createUser"
      class="createSectionData"
    >
      <div
        id="CreateUser"
        class="createUserHolder"
      >
        <h2>
          Create New User
        </h2>
        <div class="parentCreateUserForm">
          <h3
            id="PersonalSectionHeader"
            :class="[showPersonalSection ? 'expanded' : '']"
            @click="() => { showPersonalSection = !showPersonalSection }"
          >
            1. Personal
            <span class="statuscontainer">
              <span
                :class="[sectionComplete ? 'completeSection' : 'incompleteSection']"
                :title="[sectionComplete ? 'Section Complete' : 'Section Incomplete']"
              />
              <span class="background" />
            </span>
            <span
              :class="[showPersonalSection ? 'minimiseSection' : 'maximiseSection']"
            />
          </h3>
          <transition name="slide">
            <div
              v-if="showPersonalSection"
              class="createUserLeft"
            >
              <label
                for="txtUpdateFirstName"
                class="createUserLabel"
              >First Name:</label>
              <input
                id="txtUpdateFirstName"
                :class="{ errorHighlight: firstNameError }"
                v-model="user.firstName"
                type="text"
                class="createUserTextbox firstName"
                @blur="checkForm"
              >
              <label
                for="txtUpdateLastName"
                class="createUserLabel"
              >Last Name:</label>
              <input
                id="txtUpdateLastName"
                :class="{ errorHighlight: lastNameError }"
                v-model="user.lastName"
                type="text"
                class="createUserTextbox lastName"
                @blur="checkForm"
              >
            </div>
          </transition>
          <transition name="slide">
            <div
              v-if="showPersonalSection"
              class="createUserRight"
            >
              <label
                for="txtUpdateEmailAddress"
                class="createUserLabel"
              >Email Address:</label>
              <input
                id="txtUpdateEmailAddress"
                :class="{ errorHighlight: emailError || userNameExists || externalEmailError }"
                v-model.trim="user.emailAddress"
                type="text"
                class="createUserTextbox emailAddress"
                @blur="checkForm"
              >
            </div>
          </transition>
        </div>
        <div
          class="formContent"
          tabindex="3"
        >
          <CreateUserAreas
            :area-selected="areas.length > 0"
            :all-areas-selected="showAllAreas"
            @areaChanged="updateAreas"
            @topLevelAreaSelected="updateAllAreas"
          />
          <CreateUserRoles
            :role-selected="roles.length > 0"
            :selected-roles="roles"
            :selected-areas="areas"
            @roleChanged="updateRoles"
            @allRolesSelected="updateAllRoles"
          />
        </div>
        <div class="bottombar">
          <Spinner v-if="isSaving" />
          <input
            v-if="!isSaving"
            id="cancelUserButton"
            class="cancelUserButton"
            type="button"
            title="Cancel the create user process"
            @click="closeCreateUserForm()"
          >
          <input
            v-if="!isSaving"
            id="createUserButton"
            :disabled="!formOK && !sectionComplete"
            :class="getButtonClass"
            type="button"
            title="Create the selected user"
            @click="createSelectedUser()"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { usersMixin } from '../../mixins/users'
import { permissionsMixin } from '../../mixins/permissions'
import AuthRepository from '../../communications/repositories/authRepository'
import Spinner from '../common/spinner'
import { mapActions } from 'vuex'
import CreateUserRoles from './createUserRoles'
import CreateUserAreas from './createUserAreas'
import { DataEventBus } from '../../events/dataEvents'

export default {
  name: 'CreateUser',
  components: {
    Spinner,
    CreateUserRoles,
    CreateUserAreas
  },
  mixins: [
    AuthRepository,
    usersMixin,
    permissionsMixin
  ],
  props: {
  },
  data () {
    return {
      user: {
        firstName: null,
        lastName: null,
        emailAddress: null,
        userName: null
      },
      roles: [],
      areas: [],
      formOK: false,
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      externalEmailError: false,
      areasError: false,
      rolesError: false,
      userNameExists: false,
      isSaving: false,
      showPersonalSection: true,
      showAllAreas: 2
    }
  },
  computed: {
    createEntityPermission () {
      return this.$store.getters.createEntityPermission
    },
    getButtonClass () {
      if (this.formOK) {
        return 'createUserButtonEnabled'
      } else {
        return 'createUserButton'
      }
    },
    sectionComplete () {
      return this.user.firstName &&
      this.user.lastName &&
      this.user.emailAddress &&
      this.validateEmail(this.user.emailAddress) &&
      this.validateFirstChar(this.user.emailAddress) &&
      this.testChannelAccount(this.user.emailAddress) &&
      !this.userNameExists
    },
    topLevelAreas () {
      return this.$store.getters.areas.filter(({ parent }) => parent === 0)
    }
  },
  mounted () {
    this.getUserAreas(this.$store.getters.userid)

    DataEventBus.$on('refresh-data', () => {
      this.roles = []
      this.areas = []
      this.getUserAreas(this.$store.getters.userid)
    })
  },
  methods: {
    ...mapActions([
      'setMainForm',
      'setReloadUsers'
    ]),
    createSelectedUser: async function () {
      this.isSaving = true

      await this.checkForm({ target: { } })

      if (this.formOK) {
        var usr = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          userName: this.user.emailAddress,
          emailAddress: this.user.emailAddress
        }
        var createdEntity

        try {
          // Create the user in the auth service
          await this.authRequestCreateNewUser({
            userName: usr.emailAddress,
            emailAddress: usr.emailAddress,
            enabled: true
          })
            .catch((ex) => {
              console.error(ex)
              this.userCleanup({
                ...usr,
                entityId: createdEntity
              })
              throw ex
            })
          // Create the user in the users service
          await this.addUser(usr)
            .then(async (result) => {
              // We need the id from the users table to insert into the entities table
              createdEntity = result.id
            })
            .catch((ex) => {
              console.error(ex)
              this.userCleanup({
                ...usr,
                entityId: createdEntity
              })
              throw ex
            })

          var customerList = []
          var areas = this.areas.map(({ areaId }) => areaId)
          var roles = this.roles.map(({ roleId }) => roleId)
          this.areas.forEach(area => {
            if (customerList.filter(customer => customer.Id === area.customerId).length === 0) {
              var cust = {
                Id: area.customerId,
                IsDefault: customerList.length === 0
              }
              customerList.push(cust)
            }
          })
          var rtn = await this.createEntityFull(createdEntity, usr.emailAddress, areas, roles, customerList)
          if (rtn) {
            // If the created user in a member of the channel company, then we will need to update the channelUsers store list.
            if (usr.emailAddress.indexOf(this.$store.getters.channelEmailSuffix) > -1) {
              this.setChannelEntities()
            }
            // Everything has been saved, send the welcome email
            await this.authRequestWelcomeUser({
              userName: usr.userName
            })
            this.$emit('userCreated')
          } else {
            this.$store.commit('showMessage', { content: 'User creation failed, rolling back...', color: 'red', timeout: 3000 })
          }
          this.isSaving = false
          this.closeCreateUserForm()
        } catch (ex) {
          console.error(ex)
          this.$store.commit('showMessage', { content: 'User creation failed, rolling back...', color: 'red', timeout: 3000 })
          this.isSaving = false
        }
      } else {
        this.$store.commit('showMessage', { content: 'Something went wrong, please try again...', color: 'red', timeout: 3000 })
        this.isSaving = false
      }
    },
    async checkForm ({ target: { className: checkField } }) {
      var errorMessage = ''
      if (checkField) {
        if (checkField.indexOf('firstName') > -1) {
          this.firstNameError = !this.user.firstName
        } else if (checkField.indexOf('lastName') > -1) {
          this.lastNameError = !this.user.lastName
        } else if (checkField.indexOf('emailAddress') > -1) {
          this.emailError = !this.user.emailAddress || !this.validateEmail(this.user.emailAddress) || !this.validateFirstChar(this.user.emailAddress)
          this.userNameExists = false
          if (!this.emailError) {
            this.externalEmailError = !this.testChannelAccount(this.user.emailAddress)
            this.userNameExists = await this.validateUsername(this.user.emailAddress)
          }
        } else if (checkField.indexOf('areas') > -1) {
          this.areasError = this.areas.length === 0
        } else if (checkField.indexOf('roles') > -1) {
          this.rolesError = this.roles.length === 0
        }
      } else {
        this.firstNameError = !this.user.firstName
        this.lastNameError = !this.user.lastName
        this.areasError = this.areas.length === 0
        this.rolesError = this.roles.length === 0
        this.emailError = !this.user.emailAddress || !this.validateEmail(this.user.emailAddress) || !this.validateFirstChar(this.user.emailAddress)
        this.userNameExists = false
        if (!this.emailError) {
          this.externalEmailError = !this.testChannelAccount(this.user.emailAddress)
          this.userNameExists = await this.validateUsername(this.user.emailAddress)
        }
      }
      var error = this.firstNameError || this.lastNameError || this.emailError || this.areasError || this.rolesError || this.userNameExists || this.externalEmailError
      if (error) {
        errorMessage = ('Please fix the issues with the following field(s): ' +
            (this.firstNameError ? 'First Name, ' : '') +
            (this.lastNameError ? 'Last Name, ' : '') +
            (this.areasError ? 'Site selections, ' : '') +
            (this.rolesError ? 'Roles, ' : '') +
            (this.emailError ? 'provide a valid email.' : this.userNameExists ? 'the email address ' + this.user.emailAddress + ' is already in use.' : '') +
            (this.externalEmailError ? 'external email addresses cannot be used for the main channel company.' : '')
        ).replace(/,\s*$/, '')

        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 10000 })
      }
      // Check to see that we have all the roles that we require for the areas that we have selected
      if (this.roles.length > 0) {
        var chkRoles = await this.checkRoleAreas()
        if (chkRoles.length > 0) {
          error = true
          var roleMissing = ''
          chkRoles.forEach(chk => {
            roleMissing += chk
          })
          this.$store.commit('showMessage', { content: roleMissing, color: 'red', timeout: 3000 })
        }
      }
      this.formOK = !error && this.areas.length > 0 && this.roles.length > 0
    },
    validateFirstChar (email) {
      var re = /^[A-Za-z0-9_-]*$/
      return re.test(email[0])
    },
    validateEmail (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    testChannelAccount (email) {
      var rtn = false
      if (this.$store.getters.channelCompany.companyId === this.$store.getters.currentCompany.id) {
        if (email.indexOf(this.$store.getters.channelEmailSuffix) > -1) {
          rtn = true
        }
      } else {
        rtn = true
      }
      return rtn
    },
    async validateUsername (value) {
      const username = value.toLowerCase()
      var rtn = false
      if (username !== null) {
        await this.getUserListByUsername(username)
          .then(result => {
            rtn = !result || result.some(user => user.userName.toLowerCase() === username)
          })
          .catch((_) => {
            console.log('Username not found')
          })
      }
      return rtn
    },
    updateAreas ({ areaId, selectedState }) {
      if (selectedState === 1) {
        var resolvedArea = this.$store.getters.areas.filter(area => area.id === areaId)
        if (resolvedArea.length > 0) {
          var parentId = resolvedArea[0].parent
          var customerId = resolvedArea[0].customerId
          if (!this.areas.some(area => area.areaId === areaId)) {
            this.areas.push({
              areaId,
              parentId,
              customerId,
              selectedState
            })
          }
        }
      } else {
        this.areas = this.areas.filter(area => area.areaId !== areaId)
      }

      // Update the validation
      this.checkForm({ target: { className: 'areas' } })
    },
    async checkRoleAreas () {
      var rtn = []
      for (let i = 0; i < this.areas.length; i++) {
        var id = this.areas[i].areaId
        var ras = await this.getRoleAreasByAreaRecusrive(id)
        if (ras.length > 0) {
          var foundOne = false
          ras.forEach(ra => {
            if (this.roles.filter(role => role.roleId === ra.roleId).length > 0) {
              foundOne = true
            }
          })
          if (!foundOne) {
            var resolvedArea = this.$store.getters.areas.filter(area => area.id === this.areas[i].areaId)
            rtn.push('Area ' + resolvedArea[0].name + ' does not have a role assigned to allow access.')
          }
        }
      }
      return rtn
    },
    updateAllAreas ({ selectedState }) {
      if (selectedState === 1) {
        this.areas = [
          ...this.topLevelAreas.map(({ id, customerId }) => ({ areaId: id, customerId: customerId, selectedState }))
        ]
      } else {
        this.areas = []
      }
      this.checkForm({ target: { className: 'areas' } })
    },
    updateAllRoles ({ selectedState }) {
      if (selectedState === 1) {
        this.roles = this.getFilteredRoles(this.$store.getters.roles.map(({ id }) => ({
          roleId: id,
          selectedState
        })))
      } else {
        this.roles = []
      }
      this.checkForm({ target: { className: 'roles' } })
    },
    getFilteredRoles (data) {
      if (this.$store.getters.channelCompany.companyId === this.$store.getters.currentCompany.id) {
        return data
      } else {
        // We only want to allow maintel roles to be added if we are at the channel company
        var res = data.filter(role => !this.$store.getters.channelRoles.includes(role.roleId))
        return res
      }
    },
    updateRoles ({ roleId, selectedState }) {
      if (selectedState === 1) {
        if (!this.roles.some(role => role.roleId === roleId)) {
          this.roles.push({
            roleId,
            selectedState
          })
        }
      } else {
        this.roles = this.roles.filter(role => role.roleId !== roleId)
      }
      this.checkForm({ target: { className: 'roles' } })
    },
    closeCreateUserForm () {
      this.firstNameError = false
      this.lastNameError = false
      this.emailError = false
      this.areasError = false
      this.rolesError = false
      this.userNameExists = false
      this.user = {
        firstName: null,
        lastName: null,
        emailAddress: null,
        userName: null
      }
      this.areas = []
      this.roles = []
      this.setMainForm(false)
    },
    userCleanup ({ entityId, userName }) {
      // delete auth user
      this.authRequestDeleteUser({ userName })
      // delete users service user
      this.deleteUser({ id: entityId })
      // delete permissions entity record
      this.deleteEntity({ id: entityId })
    }
  }
}
</script>

<style scoped>
  .parentCreateUserForm{
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .createUserLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .createUserTextbox {
    width: 68%;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }
  .createUserData {
    width: 145px;
    display: inline-block;
  }
  .createSectionData {
    position: absolute;
    bottom: 36px;
    top: 0;
    right: 0;
    background-color: #fff;
    left: 0;
    z-index: 1;
    margin-right: 3vw;
  }
  .createUserHolder {
    background-color: rgba(255,255,255,0.7);
    padding: 0 22px 0 10px;
    overflow-y: auto;
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .cancelUserButton {
    background-image: url('../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
  }
  .createUserButton {
    background-image: url('../../assets/icons/check-tick-disabled.svg');
    cursor: default;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .createUserButtonEnabled {
    background-image: url('../../assets/icons/check-tick.svg');
    cursor: pointer;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .createUserLeft {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .createUserRight {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .bottombar {
    text-align: right;
  }
  .slide-enter-active, .slide-leave-active {
    transition: max-height .2s ease-out;
  }

  .slide-enter, .slide-leave-to {
    max-height: 0px;
  }

  .slide-enter-to, .slide-leave {
    max-height: 200px;
  }

  h3 {
    cursor: pointer;
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0;
    color: #fff;
    border-radius: 5px;
  }
  .expanded {
    border-radius: 5px 5px 0 0;
  }

  input {
    border-radius: 5px;
  }

  .completeSection {
    background-image: url('../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .incompleteSection {
    background-image: url('../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  span.statuscontainer {
    float: left;
    margin-right: 5px;
  }

  span.statuscontainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }

  .minimiseSection {
    background-image: url('../../assets/icons/up_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .maximiseSection {
    background-image: url('../../assets/icons/down_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .formContent {
    overflow-y: auto;
  }

</style>
