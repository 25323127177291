<template>
  <div class="designPackSummaryContainer quotePanel">
    <h2>Upfront Charges</h2>
    <table
      id="QuoteUpfrontPreviewTable"
      class="iQuoteTable"
    >
      <tr>
        <th
          style="width:300px">Service</th>
        <th
          style="">Description</th>
        <th
          style="width:80px">Quantity</th>
        <th
          style="width:120px">Price</th>
        <th
          style="width:120px">TotalPrice</th>
      </tr>
      <tbody v-if="quoteEntries !== null">
        <tr
          v-for="autotaskEntry in quoteEntries.filter(x => !x.recurringCost || x.unitOOPrice !== 0)"
          :key="autotaskEntry.id"
        >
          <td>{{ autotaskEntry.categoryName }}</td>
          <td>{{ autotaskEntry.description }}</td>
          <td>{{ autotaskEntry.quantity }}</td>
          <td>£{{ iQuoteFunctions_ThousandSeparator(autotaskEntry.unitOOPrice.toFixed(2)) }}</td>
          <td>£{{ iQuoteFunctions_ThousandSeparator(calcTotalPrice(autotaskEntry.unitOOPrice, autotaskEntry.quantity).toFixed(2)) }}</td>
        </tr>
        <tr>
          <td colspan="3" />
          <td><strong>Sub Total</strong></td>
          <td><strong>£{{ iQuoteFunctions_ThousandSeparator(subTotalUpfront.toFixed(2)) }}</strong></td>
        </tr>
        <tr>
          <td colspan="3" />
          <td><strong>VAT @ 20%</strong></td>
          <td><strong>£{{ iQuoteFunctions_ThousandSeparator((subTotalUpfront * 0.2).toFixed(2)) }}</strong></td>
        </tr>
        <tr>
          <td colspan="3" />
          <td><strong>Total</strong></td>
          <td><strong>£{{ iQuoteFunctions_ThousandSeparator((subTotalUpfront * 1.2).toFixed(2)) }}</strong></td>
        </tr>
      </tbody>
    </table>
    <br>
    <h2>Annual Subscription</h2>
    <table
      id="QuoteRecurringPreviewTable"
      class="iQuoteTable"
    >
      <tr>
        <th
          style="width:300px">Service</th>
        <th
          style="">Description</th>
        <th
          style="width:80px">Quantity</th>
        <th
          style="width:120px">Price</th>
        <th
          style="width:120px">TotalPrice</th>
      </tr>
      <tbody v-if="quoteEntries !== null">
        <tr
          v-for="autotaskEntry in quoteEntries.filter(x => x.recurringCost || x.unitARPrice !== 0)"
          :key="autotaskEntry.id"
        >
          <td>{{ autotaskEntry.categoryName }}</td>
          <td>{{ autotaskEntry.description }}</td>
          <td>{{ autotaskEntry.quantity }}</td>
          <td>£{{ iQuoteFunctions_ThousandSeparator(autotaskEntry.unitARPrice.toFixed(2)) }}</td>
          <td>£{{ iQuoteFunctions_ThousandSeparator(calcTotalPrice(autotaskEntry.unitARPrice, autotaskEntry.quantity).toFixed(2)) }}</td>
        </tr>
        <tr>
          <td colspan="3" />
          <td><strong>Sub Total</strong></td>
          <td><strong>£{{ iQuoteFunctions_ThousandSeparator(subTotalRecurring.toFixed(2)) }}</strong></td>
        </tr>
        <tr>
          <td colspan="3" />
          <td><strong>VAT @ 20%</strong></td>
          <td><strong>£{{ iQuoteFunctions_ThousandSeparator((subTotalRecurring * 0.2).toFixed(2)) }}</strong></td>
        </tr>
        <tr>
          <td colspan="3" />
          <td><strong>Total</strong></td>
          <td><strong>£{{ iQuoteFunctions_ThousandSeparator((subTotalRecurring * 1.2).toFixed(2)) }}</strong></td>
        </tr>
      </tbody>
    </table>
    <br>
    <strong>Contract Term ({{ iQuoteStore_GetDesignPack.termLengthMonths }} months) Total: £{{ contractTermTotal }} (Plus VAT)</strong>
    <br>
    <div
      v-if="iQuoteStore_GetDesignPack.standardCaveats.length > 0">
      <h3>Quotation Instructions</h3>
      <p>
        {{ iQuoteStore_GetDesignPack.standardCaveats }}
      </p>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { externalQuotesModelMixin } from '../../../mixins/iQuote/Models/externalQuotesModelMixin'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { iQuoteCalculationsDesignPackExportMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackExport'
import Spinner from '../../common/spinner'
import DownloadButton from '../../common/downloadButton'

export default {
  name: 'Autotask',
  components: {
    Spinner,
    DownloadButton
  },
  mixins: [
    iQuoteStoreMixin,
    iQuoteConstants,
    iQuoteFunctions,
    externalQuotesModelMixin,
    iQuoteExportsAPIMixin,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackExportMixin
  ],
  props: {
    autotaskCustAccNo: {
      type: String,
      default: ''
    },
    quoteEntries: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      quotePreviewComponent: {
        autotaskCustAccNo: ''
      }
    }
  },
  computed: {
    isReadOnly () {
      return this.iQuoteStore_GetDesignPack.isReadOnly
    },
    contractTermTotal () {
      var total = this.subTotalUpfront
      total += this.subTotalRecurring * (this.iQuoteStore_GetDesignPack.termLengthMonths / 12)

      return this.iQuoteFunctions_ThousandSeparator(total.toFixed(2))
    },
    subTotalUpfront () {
      var total = 0

      this.quoteEntries.forEach(entry => {
        total += this.calcTotalPrice(entry.quantity, entry.unitOOPrice)
      })

      return total
    },
    subTotalRecurring () {
      var total = 0

      this.quoteEntries.forEach(entry => {
        total += this.calcTotalPrice(entry.quantity, entry.unitARPrice)
      })

      return total
    }
  },
  async mounted () {
    this.quotePreviewComponent.autotaskCustAccNo = this.autotaskCustAccNo /* The property */
  },
  updated () {

  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    calcTotalPrice (price, quantity) {
      if (quantity > 0) {
        return (quantity * price)
      } else {
        return (-quantity * price)
      }
    }
  }
}
</script>
<style scoped>

#QuoteUpfrontPreviewTable, #QuoteRecurringPreviewTable {
  width: 1080px;
  margin-left: 15px;
  border: 1px solid black;
}
</style>
