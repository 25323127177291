<template>
  <div class="quotePanelContainer">
    <div class="quotePanel" >
      <label
        :class="{InputError: salesforceOpportunityId === 0}"
        for="txtSalesforceQuote"
      >Salesforce Quote #
        <TooltipIcon
          width="500"
          text="Login to Salesforce and retrieve from Salesforce Opportunity Number" />
      </label>
      <input
        id="txtSalesforceQuote"
        v-model="salesforceQuoteNumber"
        :readonly="iQuoteStore_GetQuote.quoteOpportunityId !== 0"
        type="text"
        @keyup="resetSalesforceOpportunity()"
      >
      <img
        v-if="iQuoteStore_GetQuote.quoteOpportunityId === 0"
        :src="search"
        class="search"
        @click="getSalesforceOpportunity()"
      >
      <div>
        <div>
          <label for="txtAccount">Account</label>
          <input
            id="txtAccount"
            :value="salesforceAccountName"
            readonly="true"
            type="text"
          >
        </div>
        <div v-if="opportunity != null">
          <label
            :class="{InputError: salesforceContactFirstName === null}"
            for="cboContact"
          >Contact</label>
          <select
            id="cboContact"
            v-model="opportunity.contactId"
            @change="contactChanged($event)"
          >
            <option
              v-for="c in opportunity.contacts"
              :key="c.id"
              :value="c.id"
              :title="c.firstName + ' ' + c.lastName"
              :selected="c.id === opportunity.contactId"
            >{{ c.firstName + ' ' + c.lastName }}</option>
          </select>
        </div>
        <div v-if="opportunity == null">
          <label for="txtContact" >Contact</label>
          <input
            id="txtContact"
            :value="contactFullName"
            type="text"
            readonly="true"
          >
          <img
            v-if="salesforceContactFirstName != null"
            :src="search"
            class="search"
            @click="getSalesforceOpportunity()"
          >
        </div>
        <div>
          <label
            :class="{InputError: salesforceContactEmail === null && salesforceContactFirstName !== null}"
            for="txtContactEmail"
          >Contact Email</label>
          <input
            id="txtContactEmail"
            :value="salesforceContactEmail"
            type="text"
            readonly="true"
          >
          <label for="txtOpportunityName">Opportunity Name</label>
          <input
            id="txtOpportunityName"
            :value="salesforceOpportunityName"
            type="text"
            readonly="true"
          >
          <label for="txtQuoteDescription">Quote Description</label>
          <input
            id="txtQuoteDescription"
            ref="quoteDescription"
            v-model="quoteDescription"
            type="text"
          >
        </div>
      </div>
    </div>
    <hr>
    <div class="quotePanel" >
      <label for="txtiQuoteReference">iQuote Reference</label>
      <input
        id="txtiQuoteReference"
        :value="displayQuoteRef"
        type="text"
        readonly="true"
      >
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            :disabled="isBusy"
            value="< Back"
            type="button"
            @click="setCurrentQuotePage('Home')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            :disabled="isBusy"
            value="Next >"
            type="button"
            @click="nextPage"
          >
        </div>
        <div class="middleButtons">
          <input
            id="saveButton"
            :disabled="isBusy"
            value="Save"
            type="button"
            @click="saveQuoteButtonClicked"
          >
        </div>
      </div>

      <br>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { mapActions } from 'vuex'
import { iQuoteAdjunctDataAPIMixin } from '../../../mixins/iQuote/iQuoteAdjunctDataAPIMixin'
import { iQuoteSalesforceAPIMixin } from '../../../mixins/iQuote/iQuoteSalesforceAPIMixin'
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteDuplicateMixin } from '../../../mixins/iQuote/utils/iQuoteDuplicateMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'

export default {
  name: 'DuplicateQuote',
  components: {
    TooltipIcon
  },
  mixins: [ShowHelpMixin, iQuoteAdjunctDataAPIMixin, iQuoteSalesforceAPIMixin, iQuoteQuoteAPIMixin, iQuoteFunctions, iQuoteStoreMixin, iQuoteConstants, iQuoteDuplicateMixin],
  data () {
    return {
      salesforceOpportunityId: '',
      salesforceContactFirstName: '',
      salesforceContactLastName: '',
      salesforceContactEmail: '',
      salesforceOpportunityName: '',
      salesforceAccountName: '',
      salesforceQuoteNumber: '',
      quoteDescription: '',
      opportunity: null,
      isBusy: false
    }
  },
  computed: {
    displayQuoteRef () {
      if (this.iQuoteStore_GetQuote.id === null || this.iQuoteStore_GetQuote.id === 0) {
        return '<Not Saved Yet>'
      } else {
        return this.iQuoteStore_GetQuote.quoteOpportunityId + ' (revision ' + this.iQuoteStore_GetQuote.revisionNumber + ')'
      }
    },
    contactFullName () {
      if (this.salesforceContactFirstName !== null) {
        return this.salesforceContactFirstName + ' ' + this.salesforceContactLastName
      } else {
        return ''
      }
    },
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    search () {
      return require(`../../../assets/icons/search.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    toiQuoteSave () {
      return require(`../../../assets/icons/save.png`)
    },
    quoteIsValid () {
      return this.salesforceContactEmail !== null
    }
  },
  mounted () {
    this.initialise()
    this.$refs.quoteDescription.focus()
    this.$refs.quoteDescription.select()
  },
  created () {
    this.initialise()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuote',
      'setCurrentQuotePage'
    ]),
    nextPage: async function () {
      var result = await this.saveQuote()
      if (result === 'ok') {
        // If there wasn't a problem with saving the quote, and it is valid we can change to the next page

        this.setCurrentQuoteState('SelectService')
      } else {
        this.iQuoteFunctions_ShowError(result)
      }
    },
    saveQuoteButtonClicked: async function () {
      await this.saveQuote()
      if (this.iQuoteStore_GetQuote.id !== 0) {
        // If there wasn't a problem with saving the quote we can tell the user
        this.iQuoteFunctions_ShowMessage('Quote was sucessfully saved')
      }
    },
    getSalesforceOpportunity: async function () {
      var SalesforceOpportunity = await this.iQuoteSalesforceAPIMixin_getSalesforceOpportunityData(this.salesforceQuoteNumber)
      if (SalesforceOpportunity === null || SalesforceOpportunity === '') {
        this.salesforceOpportunityId = null
      } else {
        this.salesforceOpportunityId = SalesforceOpportunity.opportunityID
        if (this.quoteDescription === '') {
          this.quoteDescription = SalesforceOpportunity.opportunityName
        }
        this.salesforceOpportunityName = SalesforceOpportunity.opportunityName
        this.salesforceAccountName = SalesforceOpportunity.accountName
        // A contact has already been chosen, so use that rather than the one assigned in salesforce for this opportunity
        if (this.salesforceContactFirstName !== null) {
          for (let i = 0; i < SalesforceOpportunity.contacts.length; i++) {
            if (SalesforceOpportunity.contacts[i].contactFirstName === this.salesforceContactFirstName && SalesforceOpportunity.contacts[i].contactLastName === this.salesforceContactLastName) {
              SalesforceOpportunity.contactId = SalesforceOpportunity.contacts[i].id
            }
          }
        }
        this.opportunity = SalesforceOpportunity
        this.setQuoteContactDetails(this.opportunity.contactId)
      }
    },
    resetSalesforceOpportunity: async function () {
      this.salesforceOpportunityId = null
      this.salesforceAccountName = null
      this.salesforceOpportunityName = null
      this.salesforceContactEmail = null
      this.salesforceContactFirstName = null
      this.salesforceContactLastName = null
      this.opportunity = null
      if (this.salesforceQuoteNumber !== undefined && this.salesforceQuoteNumber.length === 7) {
        this.getSalesforceOpportunity()
      } else {
        this.salesforceOpportunityId = null
      }
    },
    saveQuote: async function () {
      if (!this.quoteIsValid) {
        return 'Please supply all required information to proceed'
      } else {
        this.isBusy = true
        // Get the current quote from the store
        var quote = this.iQuoteStore_GetQuote

        // Update some of the values that we want to change in it (without saving to DB)
        quote.salesforceOpportunityId = this.salesforceOpportunityId
        quote.salesforceAccountName = this.salesforceAccountName
        quote.salesforceOpportunityName = this.salesforceOpportunityName
        quote.salesforceContactEmail = this.salesforceContactEmail
        quote.salesforceContactFirstName = this.salesforceContactFirstName
        quote.salesforceContactLastName = this.salesforceContactLastName
        quote.quoteDescription = this.quoteDescription
        quote.salesforceQuoteNumber = this.salesforceQuoteNumber

        if (quote.id === 0) {
          // Will create a new quote using the passed in quote as a template, and will save to DB
          quote = await this.iQuoteDuplicate_CreateDuplicateQuote(quote, true, true)
        } else {
          // already been saved, so updated it
          quote = await this.iQuoteQuoteAPIMixin_AddOrUpdateQuoteRevision(quote)
        }

        this.setQuote(quote)

        this.isBusy = false
        return 'ok'
      }
    },
    getComboItemName: function (list, id) {
      var name = ''

      list.forEach(element => {
        if (element.id === id) {
          name = element.name
        }
      })

      return name
    },
    contactChanged: function (event) {
      this.setQuoteContactDetails(event.target.value)
    },
    setQuoteContactDetails: function (contactId) {
      for (let i = 0; i < this.opportunity.contacts.length; i++) {
        if (this.opportunity.contacts[i].id === contactId) {
          this.salesforceContactEmail = this.opportunity.contacts[i].email
          this.salesforceContactFirstName = this.opportunity.contacts[i].firstName
          this.salesforceContactLastName = this.opportunity.contacts[i].lastName
        }
      }
    },
    async initialise () {
      var quote = this.iQuoteStore_GetQuote

      this.salesforceContactFirstName = quote.salesforceContactFirstName
      this.salesforceContactLastName = quote.salesforceContactLastName
      this.salesforceContactEmail = quote.salesforceContactEmail
      this.salesforceOpportunityName = quote.salesforceOpportunityName
      this.salesforceAccountName = quote.salesforceAccountName
      this.salesforceQuoteNumber = quote.salesforceQuoteNumber
      this.quoteDescription = quote.quoteDescription
      this.salesforceOpportunityId = quote.salesforceOpportunityId
    }
  }
}
</script>
<style scoped>
  .quotePanel {
    width: 580px;
    text-align: left;
  }

  #txtAccount {width:200px;}
  #txtContact {width:160px;}
  #txtContactEmail {width:200px;}
  #txtOpportunityName {width:200px;}
  #txtQuoteDescription {width:200px;}
  #txtFirstName {width:54px;}
  #txtLastName {width:54px;}
</style>
