<template>
  <div class="quotePanelContainer">
    <div class="quotePanel" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { iQuoteAdjunctDataAPIMixin } from '../../../mixins/iQuote/iQuoteAdjunctDataAPIMixin'
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteLoaderMixin } from '../../../mixins/iQuote/utils/iQuoteLoaderMixin'

export default {
  name: 'QuoteType',
  mixins: [ShowHelpMixin, iQuoteAdjunctDataAPIMixin, iQuoteQuoteAPIMixin, iQuoteLoaderMixin],
  data () {
    return {
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    search () {
      return require(`../../../assets/icons/search.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    }
  },
  mounted () {
    this.iQuoteLoaderMixin_clearQuoteDataFromStore() /* Clear adjuncts etc from previous quotes */

    this.setQuote({
      id: 0,
      quoteOpportunityId: 0,
      salesforceOpportunityId: null,
      salesforceAccountName: null,
      salesforceOpportunityName: null,
      quoteDescription: null,
      opportunity: null,
      termLengthMonths: 36,
      criticalAvailability: false,
      quoteServiceLevelTypeId: 1,
      quoteResilienceTypeId: 1,
      quoteLicenseTypeId: 1,
      salesforceContactEmail: null,
      salesforceContactFirstName: null,
      salesforceContactLastName: null,
      chargeUpfront: false,
      marginInfrastructure: 30, /* todo - needs to be configurable */
      marginAWS: 20, /* todo - needs to be configurable */
      marginIconGateway: 20, /* todo - needs to be configurable */
      marginGammaSip: 33.3333, /* todo - needs to be configurable */
      marginSipMigration: 30, /* todo - needs to be configurable */
      marginCxNowPS: 50, /* todo - needs to be configurable */
      createdByUserId: this.$store.getters.userid,
      lastEditedByUserId: this.$store.getters.userid,
      quoteCallTarrifId: 1, /* None */
      datePublished: null,
      removedChargeUpfront: false
    })

    this.setCurrentQuoteState('ChooseOpportunity')
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuote'
    ])
  }
}
</script>
<style scoped>
  #txtContactEmail {width:200px;}
  #txtOpportunityName {width:200px;}
  #txtQuoteDescription {width:200px;}
  #txtFirstName {width:54px;}
  #txtLastName {width:54px;}
  #txtLastEditedFirstName{width:54px;}
  #txtLastEditedLastName{width:54px;}
</style>
