<template>
  <div id="appRoot" >
    <Loader
      :is-loading="isLoading"
      :message="loaderMessage"
    />
    <v-app>
      <core-view />
      <snackbar />
      <confirmationSnackbar />
    </v-app>
  </div>
</template>
<script>
import snackbar from './components/common/snackbar'
import confirmationSnackbar from './components/common/confirmationSnackbar'
import Loader from './components/common/loader'
import { AuthEventBus } from './events/authEvents'
import { DataEventBus } from './events/dataEvents'
import { setTimeout, clearTimeout } from 'timers'
import { usersMixin } from './mixins/users'
import { permissionsMixin } from './mixins/permissions'
import { iQuoteSalesforceAPIMixin } from './mixins/iQuote/iQuoteSalesforceAPIMixin'
import { dashboardRepository } from './communications/repositories/dashboardRepository'
import AuthRepository from './communications/repositories/authRepository'
import { ratingMarkerRepository } from './communications/repositories/ratingMarkerRepository'
import { sitesAndCircuitsRepository } from './communications/repositories/sitesAndCircuitsRepository'
import { iQuoteFunctions } from './mixins/iQuote/iQuoteFunctions'
import { mapActions } from 'vuex'

export default {
  components: {
    Loader,
    snackbar,
    confirmationSnackbar
  },
  mixins: [
    dashboardRepository,
    ratingMarkerRepository,
    sitesAndCircuitsRepository,
    AuthRepository,
    usersMixin,
    permissionsMixin,
    iQuoteSalesforceAPIMixin,
    iQuoteFunctions
  ],
  data () {
    return {
      autoLogoutTime: process.env.VUE_APP_AUTO_LOGOUT_TIME, // 1200000 = 20 mins
      dataRefreshTime: process.env.VUE_APP_DATA_REFRESH_TIME,
      dataRefreshInterval: '',
      isLoading: false,
      dataLoading: false,
      loaderMessage: '',
      autoLogoutTimer: ''
    }
  },
  computed: {
    isLoggedOn () {
      return this.$store.getters.loggedOn
    },
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    hasActiveCustomer () {
      var rtn = false
      var customers = this.$store.getters.customers

      if (customers) {
        rtn = customers.some(customer => customer.enabled)
      }

      return rtn
    },
    viewCommunicateServicePermission () {
      return this.$store.getters.viewCommunicateServicePermission
    },
    viewSecureServicePermission () {
      return this.$store.getters.viewSecureServicePermission
    },
    viewConnectServicePermission () {
      return this.$store.getters.viewConnectServicePermission
    }

  },
  watch: {
    isLoggedOn () {
      if (!this.isLoggedOn) {
      }
    }
  },
  mounted () {
    AuthEventBus.$on('show-loader', (state, message) => {
      this.isLoading = state
      if (message) {
        this.loaderMessage = message
      }
    })
    AuthEventBus.$on('load-page', (pageTitle) => {
      this.changePage(pageTitle, false)
      this.getRefreshToken(this.$store.getters.username)
    })
    AuthEventBus.$on('set-logon-display', (displayAuth) => {
      clearTimeout(this.logoSwitchTimeout)
      if (displayAuth === 'fp') {
        this.logoIsVisible = false
        return
      }
      const _this = this
      this.logoSwitchTimeout = setTimeout(function () { _this.logoIsVisible = true }, 400)
    })
    AuthEventBus.$on('session-closed', (message) => {
      // eslint-disable-next-line
      console.log('session-closed')
      this.clearSessionAndExit()
    })
    AuthEventBus.$on('session-expired', (message) => {
      // eslint-disable-next-line
      console.log('session-expired')
      this.clearSessionAndExit()
    })
    DataEventBus.$on('logged-on', (username) => {
      this.setLastAction('Logged On')
      this.resetStore()
      this.loadData(username, true)
    })
    DataEventBus.$on('load-data', () => {
      this.loadData(this.$store.getters.username, false)
    })
    DataEventBus.$on('logged-off', () => {
      this.setLastAction('Logged Off')
      this.setLoggedOn(false)
      console.log('Logged Off')
      this.clearSessionAndExit()
    })
    DataEventBus.$on('company-changed', (company) => {
      this.changeCustomer(company)
      DataEventBus.$emit('refresh-data')
    })
    DataEventBus.$on('still-active', () => {
      this.getRefreshToken(this.$store.getters.username)
      // this.setAutoLogoutInterval()
    })
    // If we refresh but are logged in, we should load the data as normal, otherwise we'll check that we have tokens and if so will log on.
    if (this.$store.getters.loggedOn) {
      this.loadData(this.$store.getters.username, true)
    } else {
      if (this.$store.getters.tokens.token.length > 0 && this.$store.getters.tokens.refreshToken && this.$store.getters.username.length > 0) {
        this.setLoggedOn(true)
        this.loadData(this.$store.getters.username, true)
      }
    }
  },
  methods: {
    ...mapActions([
      'setPage',
      'logOff',
      'setLoggedOn',
      'setLastAction',
      'clearLastAction',
      'clearPermissions',
      'setUserid',
      'setUserEmail',
      'setPermissionAction',
      'clearCustomers',
      'setNavigation',
      'setCurrentUser'
    ]),
    changePage (pageTitle, force) {
      // Initial load set an await of 0.1 seconds to handle transition
      if (!force && this.currentPage === '') {
        clearTimeout(this.timeoutSet)
        this.timeoutSet = setTimeout(() => { this.changePage(pageTitle, true) }, 100)
        return
      }
      if (this.currentPage === pageTitle) {
        return
      }
      // A logout request
      if (!pageTitle || pageTitle === '') {
        pageTitle = ''
      } else {
        this.setLastAction(`Page Change - ${pageTitle}`)
      }
      document.title = `Icon Portal ${pageTitle}`
      this.initalImage = !this.initalImage
      // Don't set up another timer if we are going to the login page
      // if (pageTitle.length > 0) {
      //   this.setAutoLogoutInterval()
      // }
      this.$router.push(`/${pageTitle}`)
    },
    loadData: async function (username, showLoading) {
      if (!this.dataLoading) {
        this.dataLoading = true
        try {
          if (showLoading) {
            AuthEventBus.$emit('show-loader', true, 'Launching iQuote')
          }
          var usr = await this.getUserByUsername(username)
          if (usr.disabled) {
            console.log('User Disabled')
            this.clearSessionAndExit()
            AuthEventBus.$emit('show-loader', false)
            this.$store.commit('showMessage', { content: 'Your account is disabled', color: 'red', timeout: 3000 })
            this.setLoggedOn(false)
            AuthEventBus.$emit('load-page', '')
          } else {
            this.setCurrentUser(usr)
            this.setUserid(usr.id)
            this.setUserEmail(usr.emailAddress)
            this.resolveEntityStyles(usr.id)
            await this.getCustomers()
            await this.getCompanies()
            if (!this.hasActiveCustomer) {
              console.log('No Active Customers')
              this.clearSessionAndExit()
              AuthEventBus.$emit('show-loader', false)
              this.$store.commit('showMessage', { content: 'There are no enabled customers on your account', color: 'red', timeout: 5000 })
              this.setLoggedOn(false)
              AuthEventBus.$emit('load-page', '')
            } else {
              await this.setCompanyData(username)
              DataEventBus.$emit('load-dashboard', true)
              AuthEventBus.$emit('show-loader', false)
            }
          }
        } catch (_) {
          console.log('Error in loadData')
          AuthEventBus.$emit('show-loader', false)
        }
        DataEventBus.$emit('data-loaded', showLoading)
        this.dataLoading = false
        // this.setAutoLogoutInterval()
      }
    },
    setCompanyData: async function () {
      try {
        var id = this.$store.getters.userid
        var username = this.$store.getters.username
        var email = this.$store.getters.userEmail
        await this.getTopLevelArea()
        await this.getCompanyEmailSuffixes()
        await this.getUserTopLevelPermissions(id)
        await this.setUserPermissions(id)

        if (this.$store.getters.viewAreasPermission) { await this.sitesGet(id) }
        this.setNavigation()
        if (this.$store.getters.viewRolePermission) { this.rolesGet() }
        if (this.$store.getters.viewEntityPermission) {
          var rtn = await this.usersGet()
          DataEventBus.$emit('usersSet', rtn)
        }
        if (this.$store.getters.viewPermissionPermission) { this.getAllPermissions() }
        DataEventBus.$emit('refresh-data')
      } catch (_) {
        console.log('Error in setCompanyData')
        AuthEventBus.$emit('show-loader', false)
      }
    },
    changeCustomer: async function (company) {
      AuthEventBus.$emit('show-loader', true, 'Changing company data to: <br/><br/>' + company.name)
      await this.setCompanyData()
      AuthEventBus.$emit('show-loader', false)
    },
    setAutoLogoutInterval () {
      console.log('setAutoLogoutInterval at: ' + (new Date()).toISOString().slice(0, 19).replace(/-/g, '/').replace('T', ' '))
      console.log('Next timeout due in: ' + this.autoLogoutTime / 1000 + ' seconds')
      this.autoLogoutTimer = clearTimeout()
      this.autoLogoutTimer = setTimeout(() => {
        console.log('Timeout fired at: ' + (new Date()).toISOString().slice(0, 19).replace(/-/g, '/').replace('T', ' '))
        this.clearSessionAndExit()
      }, this.autoLogoutTime)
    },
    clearAutoLogoutInterval () {
      if (this.autoLogoutTimer) {
        clearTimeout(this.autoLogoutTimer)
      }
      this.autoLogoutTimer = null
    },
    clearSessionAndExit () {
      // eslint-disable-next-line
      console.log('clearSessionAndExit at: ' + (new Date()).toISOString().slice(0, 19).replace(/-/g, '/').replace('T', ' '))
      var doExit = false
      if (doExit) {
        this.clearAutoLogoutInterval()
        var returnUrl = localStorage.getItem('returnUrl')
        if (returnUrl && returnUrl !== '' && returnUrl !== 'undefined') {
          this.changePage(`/?returnUrl=${returnUrl}`, false)
          localStorage.setItem('returnUrl', undefined)
        } else {
          this.changePage('', false)
        }
        this.resetStore()
        this.logOff()
      }
    },
    resetStore () {
      this.clearPermissions()
      this.clearCustomers()
    },
    setUserPermissions: async function (uid) {
      let canViewiQuote = false
      var perms = await this.getUserViewPermissions(uid)
      this.clearPermissions()
      for (let i = 0; i < perms.length; i++) {
        var val = (perms[i].allowed === 1)
        // console.log('Setting: ' + perms[i].action + ' to ' + val)
        this.setPermissionAction({ action: perms[i].action, value: val })
        if (perms[i].action === 'viewiQuote' && val) {
          canViewiQuote = true
        }
      }
      if (!canViewiQuote) {
        this.iQuoteFunctions_ShowError(`Sorry, you do not have permission to view the iQuote application.`)
        this.$router.push({ path: 'logout' })        
        return
      }
      await this.checkSecurity(uid)
    }   
  }
}
</script>
<style lang="scss">
@import '@/styles/index.scss';

.appLoggedOff {
  display: none;
}
.appLoggedOn {
  display: block;
}
</style>
