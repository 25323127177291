<template>
  <router-link
    v-ripple
    class="toolbar-items"
    to="iQuote">
    <material-chart-card
      id="iQuoteStatsTile"
      :data="quoteStatusData.data"
      :options="quoteOptions"
      :responsive-options="responsiveOptions"
      color="info"
      type="Bar"
    >
      <div
        v-if="!quoteStatusData.data.series[0].some(o => o !== 0)"
        class="noDataBanner"
      > No Data Available </div>
      <h4 class="title font-weight-light">iQuote (Last 30 Days)</h4>
      <p class="category d-inline-flex font-weight-light">
        <v-icon
          color="green"
          small
        >
          mdi-arrow-up
        </v-icon>
        <span class="green--text">{{ quotesPerDay.toFixed(2) }}</span>&nbsp;Quotes produced per day
      </p>
    </material-chart-card>
  </router-link>
</template>
<script>
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { dashboardMixin } from '../../../mixins/dashboard.js'
import { DataEventBus } from '../../../events/dataEvents'

export default {
  mixins: [dashboardMixin, iQuoteQuoteAPIMixin, iQuoteConstants],
  data () {
    return {
      timer: '',
      quotesPerDay: 0,
      quoteOptions: {
        axisX: {
          showGrid: false
        },
        low: 0,
        high: 100,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 0
        }
      },
      responsiveOptions: [
        ['screen', {
          seriesBarDistance: 5,
          axisX: {
            offset: 15 },
          axisY: {
            offset: 30
          },
          low: 0,
          showPoint: false
        }]
      ],
      quoteStatusData: {
        data: {
          labels: ['Unpub', 'Pub', 'Exp', 'Ord', 'Super'],
          series: [
            [0, 0, 0, 0, 0]
          ]
        }
      }
    }
  },
  mounted () {
    DataEventBus.$on('load-dashboard', (state) => {
      this.setQuoteStatus()
    })
    this.setQuoteStatus()
  },
  created () {
  },
  methods: {
    setQuoteStatus: async function () {
      if (this.$store.getters.viewiQuotePermission) {
        var userid = this.$store.getters.userId

        if (!(userid >= 1)) {
          return /* Not logged in, so return */
        }

        var searchEndDate = new Date()
        var searchStartDate = new Date()
        var countUnpublished = 0
        var countPublished = 5
        var countExpired = 0
        var countOrdered = 0
        var countSuperseded = 0
        var countCreated = 0

        searchStartDate.setDate(searchStartDate.getDate() - 30)
        searchStartDate.setHours(0, 0, 0, 0)
        searchEndDate.setHours(23, 59, 59, 999)
        searchEndDate.setHours(23, 59, 59, 999)

        var searchResults = await this.searchQuotes('', searchStartDate, searchEndDate)

        if (searchResults != null && searchResults.length !== undefined) {
          var max = 0
          searchResults.forEach(quote => {
            switch (quote.quoteStateId) {
              case this.iQuoteConstants.quoteStates.UNPUBLISHED:
                countUnpublished++
                break
              case this.iQuoteConstants.quoteStates.PUBLISHED:
                countPublished++
                break
              case this.iQuoteConstants.quoteStates.EXPIRED:
                countExpired++
                break
              case this.iQuoteConstants.quoteStates.ORDERED:
                countOrdered++
                break
              case this.iQuoteConstants.quoteStates.SUPERSEDED:
                countSuperseded++
                break
            }

            if (new Date(quote.dateCreated) >= searchStartDate) {
              countCreated++
            }
          })

          max = Math.max(countUnpublished, countPublished, countExpired, countOrdered, countSuperseded)
          max *= 1.1
          max = Math.ceil(max)

          this.quoteStatusData.data.series = [[countUnpublished, countPublished, countExpired, countOrdered, countSuperseded]]
          this.quoteOptions.high = max
          this.quotesPerDay = countCreated / 30
        }
      }
    }
  }
}
</script>

<style scoped>
  .noDataBanner {
    position: absolute;
    top: 50px;
    left: calc(100% - 65%);
    font-weight: 400;
    color: #fff;
  }
</style>
