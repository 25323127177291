<template>
  <div class="pctContainer">
    <div class="pctVerticalList">
      <div class="sstTitleBar">
        <div class="sstHeader sstBar">
          <h3 class="appDarkFont"><u>Quote Status</u></h3>
        </div>
      </div>
      <div class="pctHeader pctBar">
        <div
          class="pctItem pctHeader"
          style="width:60%"
        >
          <h3 class="appDarkFont">&nbsp;Unpublished</h3>
        </div>
        <div
          class="pctItem pctUp"
          style="width:40%;text-align:center;"
        >
          <h3 class="appDarkFont">95</h3>
        </div>
      </div>
      <div class="pctHeader pctBar">
        <div
          class="pctItem pctHeader"
          style="width:60%"
        >
          <h3 class="appDarkFont">&nbsp;Published</h3>
        </div>
        <div
          class="pctItem pctUp"
          style="width:40%; text-align:center"
        >
          <h3 class="appDarkFont">10</h3>
        </div>
      </div>
      <div class="pctHeader pctBar">
        <div
          class="pctItem pctHeader"
          style="width:60%"
        >
          <h3 class="appDarkFont">&nbsp;Expired</h3>
        </div>
        <div
          class="pctItem pctDown"
          style="width:40%; text-align:center"
        >
          <h3 class="appDarkFont">2</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      circuitStatus: { noUp: 185, noPartial: 0, noDown: 2 },
      upTime: '99.9992%'
    }
  }
}
</script>

<style>
  .pctItem {
    margin:2px;
  }
  .pctContainer {
    display:flex;
    flex-direction: column;
    padding:15px 0 5px 0;
  }
  .pctTitleBar {
    display:flex;
    flex-direction: row;
  }
  .pctBar {
    display:flex;
    flex-direction: row;
    padding:5px;
  }
  .pctNumber {
    width:33%;
    text-align:center;
  }
  .pctHeader {
    font-weight:600;
  }
  .pctUp {
    background-color:rgba(39, 174, 96,0.2);
    border:2px rgba(39, 174, 96,0.8) solid;
  }
  .pctPartial {
    background-color:rgba(230, 126, 34,0.2);
    border:2px rgba(230, 126, 34,0.8) solid;
  }
  .pctDown {
    background-color:rgba(192, 57, 43,0.2);
    border:2px rgba(192, 57, 43,0.8) solid;
  }
  .pctNoValue {
    opacity: 0.3;
  }
  .pctHeader.pctItem {
    padding-bottom:0;
  }
</style>
