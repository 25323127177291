<template>
  <div class="projectTopLevelSummaryContainer">
    <table
      v-show="topLevelProjectSummary.length > 0"
      id="projectsSummaryTable"
      class="dashboardTable"
    >
      <thead />
      <tbody
        v-show="topLevelProjectSummary.length > 0"
        id="projectsSummaryTableBody"
      >
        <tr
          v-for="v in currentFilter"
          :key="v.id + v.status"
          :tabindex="20"
          :class="ragClass(v.id, v.status.toLowerCase())"
          @click="drilldownSite(v)"
        >
          <td>{{ v.site }}</td>
          <td>{{ getSiteCount(v.id,v.status.toLowerCase()) }}</td>
          <td>{{ v.status }}</td>
        </tr>
      </tbody>
    </table>
    <p
      v-if="topLevelProjectSummary.length === 0"
      class="noResults"
    >
      No project data available.
    </p>
  </div>
</template>

<script>

import { autotaskServiceMixin } from '../../mixins/autotaskServiceMixin'
import tableHandler from '../../mixins/tableHandler'
import { DataEventBus } from '../../events/dataEvents'

export default {
  name: 'ProjectsTopLevelSummary',
  components: {
  },
  mixins: [
    autotaskServiceMixin,
    tableHandler
  ],
  props: {
    filter: {
      type: String,
      default: ''
    },
    displayClosedProjects: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    topLevelArea () {
      return this.$store.getters.topLevelArea
    },
    topLevelProjectSummary () {
      return this.$store.getters.topLevelProjectSummary
        ? this.$store.getters.topLevelProjectSummary
          .filter(o => (this.filteredSites ? this.filteredSites.some(({ siteId, status }) => o.id === siteId && o.status.toLowerCase() === status.toLowerCase() && o.status.toLowerCase() === (this.displayClosedProjects ? status : 'open')) : true))
        : []
    },
    filteredSites () {
      switch (this.filter.toLowerCase()) {
        case 'high':
          return this.$store.getters.topLevelSitesSummary.filter(o => o.ragStatus.toLowerCase() === 'red')
        case 'medium':
          return this.$store.getters.topLevelSitesSummary.filter(o => o.ragStatus.toLowerCase() === 'amber')
        case 'low':
          return this.$store.getters.topLevelSitesSummary.filter(o => o.ragStatus.toLowerCase() === 'green')
        default:
          return this.$store.getters.topLevelSitesSummary
      }
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    userSites () {
      this.selectSite()
    },
    filter () {
      this.selectSite()
    },
    topLevelProjectSummary () {
      this.selectSite()
    }
  },
  mounted () {
    this.selectSite()
    DataEventBus.$on('updateSiteProjectSummary', () => {
      this.selectSite()
    })
    DataEventBus.$on('refresh-data', () => {
      this.selectSite()
    })
    DataEventBus.$on('updateTopLevelSummary', () => {
      this.selectSite()
    })
    DataEventBus.$on('company-changed', () => {
      this.selectSite()
    })
  },
  created () {
  },
  methods: {
    selectSite () {
      this.$nextTick(() => {
        this.initialiseTable('projectsSummaryTable', this.topLevelProjectSummary,
          ['site', 'projectCount', 'status'],
          ['Site', 'Projects', 'Status'],
          ['', '', ''],
          null,
          ['56%', '22%', '22%'])
      })
    },
    drilldownSite ({ id: siteId, status }) {
      this.$emit('siteProjectDrillDown', {
        siteId,
        status
      })
    },
    ragClass (id, status) {
      var rtn = ''
      if (!this.useAltColour) {
        rtn = 'Solid'
      }
      var filteredSites = this.filteredSites.filter(o => o.siteId === id)
      if (filteredSites.some(o => o.ragStatus.toLowerCase() === 'red' && o.status === status)) {
        rtn += 'Red'
      } else if (filteredSites.some(o => o.ragStatus.toLowerCase() === 'amber' && o.status === status)) {
        rtn += 'Amber'
      } else if (filteredSites.some(o => o.ragStatus.toLowerCase() === 'green' && o.status === status)) {
        rtn += 'Green'
      } else {
        rtn += 'Grey'
      }
      return rtn
    },
    getSiteCount (siteId, status) {
      return this.filteredSites.filter(o => o.siteId === siteId && o.status === status).length
    }
  }
}
</script>

<style scoped>
  .projectTopLevelSummaryContainer {
    max-height: 400px;
    padding:10px;
  }

  .dashboardTable td {
    display:inline-block;
    width:20%;
  }

  .dashboardTable td:nth-child(1) {
    display:inline-block;
    width:56%;
  }

  .ddlSites {
    min-width: 100%;
    margin-bottom: 5px;
  }
</style>
