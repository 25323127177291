<template>
  <div>
    <Spinner v-if="!hasData" />
    <router-link
      v-ripple
      v-if="hasData"
      class="toolbar-items"
      to="tickets">
      <material-chart-card
        :data="ticketStatusData.data"
        :options="ticketOptions"
        :responsive-options="responsiveOptions"
        color="info"
        type="Bar"
      >
        <div
          v-if="!ticketStatusData.data.series[0].some(o => o !== 0)"
          class="noDataBanner"
        > No Data Available </div>
        <h4 class="title font-weight-light">Ticket Management</h4>
        <p class="category d-inline-flex font-weight-light">Open Ticket Information</p>
        <template slot="actions">
          <v-icon
            class="mr-2"
            small
          >mdi-clock-outline
          </v-icon>
          <span class="caption grey--text font-weight-light">{{ lastUpdatedText }}</span>
        </template>
      </material-chart-card>
    </router-link>
  </div>
</template>
<script>
import { dashboardMixin } from '../../../mixins/dashboard.js'
import { dashboardRepository } from '../../../communications/repositories/dashboardRepository'
import { DataEventBus } from '../../../events/dataEvents'
import Spinner from '../../common/spinner'
export default {
  name: 'TicketManagementTile',
  components: {
    Spinner
  },
  mixins: [
    dashboardMixin,
    dashboardRepository
  ],
  data () {
    return {
      timer: 0,
      lastUpdatedDate: Date.now(),
      lastUpdatedText: 'Updated 0 seconds ago',
      ticketOptions: {
        axisX: {
          showGrid: false
        },
        low: 0,
        high: 1000,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 0
        }
      },
      responsiveOptions: [
        ['screen and (max-width: 640px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0]
            }
          }
        }]
      ],
      ticketStatusData: {
        data: {
          labels: ['Critical', 'High', 'Medium', 'Low', 'Ch.', 'SR.'],
          series: [ [0, 0, 0, 0, 0, 0] ]
        }
      },
      hasData: false
    }
  },
  mounted () {
    DataEventBus.$on('updateTicketStatus', async () => {
      await this.ticketStatusGet()
      this.setTicketStatusChartData()
      this.timer = this.setTimer()
    })
    this.setTicketStatusChartData()
  },
  created () {
    this.timer = this.setTimer()
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    setTicketStatusChartData: function () {
      var rtn = this.$store.getters.ticketstatus
      if (rtn != null && rtn.length !== undefined) {
        var ticketStatus = this.getTicketStatus(rtn)
        if (!this.areTheSameData(ticketStatus, this.ticketStatusData)) {
          var max = 0
          var labels = []
          var series = []
          var seriesArr = []
          for (let i = 0; i < ticketStatus.length; i++) {
            labels.push(ticketStatus[i].priority)
            seriesArr.push(ticketStatus[i].ticketNumber)
            if (ticketStatus[i].ticketNumber > max) {
              max = ticketStatus[i].ticketNumber
            }
          }
          series.push(seriesArr)
          this.ticketStatusData.data.labels = labels
          this.ticketStatusData.data.series = series
          this.lastUpdatedDate = Date.now()
          this.ticketOptions.high = max + 5
        }
      }
      this.lastUpdatedText = this.setLastUpdatedText(this.lastUpdatedDate)
      this.hasData = true
    },
    getTicketStatus (rtn) {
      let ticketStatus = []
      ticketStatus = ['Critical', 'High', 'Medium', 'Low', 'Ch.', 'SR.'].map((filter) => {
        let filteredList = []
        if (filter.toLowerCase() === 'sr.') {
          filteredList = [22]
        } else if (filter.toLowerCase() === 'critical') {
          filteredList = [4, 5, 6, 11]
        } else if (filter.toLowerCase() === 'high') {
          filteredList = [1, 7, 12]
        } else if (filter.toLowerCase() === 'medium') {
          filteredList = [2, 8, 13]
        } else if (filter.toLowerCase() === 'low') {
          filteredList = [3, 9]
        } else if (filter.toLowerCase() === 'ch.') {
          filteredList = [14, 15]
        }
        let numberOfTickets = 0
        filteredList.forEach(pr => {
          const rtnProirity = rtn.filter(p => p.priorityId === pr)[0]
          if (rtnProirity) {
            if (rtnProirity.ticketNumber) {
              numberOfTickets += rtnProirity.ticketNumber
            }
          }
        })
        const item = { ticketNumber: numberOfTickets, priority: `${filter} \n (${numberOfTickets})` }
        return item
      })
      return ticketStatus
    },
    setTimer () {
      clearInterval(this.timer)
      return setInterval(async () => {
        await this.ticketStatusGet()
        this.setTicketStatusChartData()
      }, 30000)
    }
  }
}
</script>

<style scoped>
  .noDataBanner {
    position: absolute;
    top: 50px;
    left: calc(100% - 65%);
    font-weight: 400;
    color: #fff;
  }
</style>
