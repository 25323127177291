<template>
  <div class="quotePanelContainer">
    <br>
    <b>Choose the design pack to open:</b>
    <br>
    <br>
    <p v-if="selectDesignPackComponent.searchResults.length === 0">No Design Packs Found!</p>
    <div
      class="mdQuotes">
      <div
        v-show="selectDesignPackComponent.searchResults.length !== 0">
        <table
          id="designPacksTable">
          <thead />
          <tbody>
            <tr
              v-for="v in currentFilter"
              :key="v.id"
              :tabindex="20"
              @click="selectDesignPack(v)"
            >
              <td>{{ v.id }}</td>
              <td>{{ v.quoteOpportunityId }}</td>
              <td>{{ v.name }}</td>
              <td>{{ v.revisionNumber }}</td>
              <td>{{ v.createDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { mapActions } from 'vuex'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import tableHandler from '../../../mixins/tableHandler'

export default {
  name: 'SelectDesignPack',
  components: {
    TooltipIcon
  },
  mixins: [tableHandler, iQuoteConstants, ShowHelpMixin, iQuoteQuoteDesignPacksAPIMixin, iQuoteQuoteAPIMixin, iQuoteFunctions, iQuoteStoreMixin],
  props: {
    salesforceQuoteNumber: {
      type: String,
      default: function () {
        return ''
      }
    },
    accountName: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      quote: {},
      lastEditedUser: {},
      createdByUser: {},
      searchedSalesforceQuoteNumber: '',
      selectDesignPackComponent: {
        searchResults: []
      }
    }
  },
  computed: {
    lastEditedByName () {
      return this.iQuoteStore_DisplayUserNameFromId(this.quote.lastEditedByUserId)
    },
    createdByName () {
      return this.iQuoteStore_DisplayUserNameFromId(this.quote.createdByUserId)
    },
    displayQuoteRef () {
      if (this.quote.id === null || this.quote.id === 0) {
        return '<Not Saved Yet>'
      } else {
        return this.quote.id
      }
    },
    contactFullName () {
      if (this.quote.salesforceContactFirstName !== null) {
        return this.quote.salesforceContactFirstName + ' ' + this.quote.salesforceContactLastName
      } else {
        return ''
      }
    },
    quoteUsers () {
      return this.$store.getters.quoteUsers
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.designPackList,
      (newValue) => {
        this.selectDesignPackComponent.searchResults = newValue
        this.setTable(newValue)
      }
    )
    this.selectDesignPackComponent.searchResults = this.iQuoteStore_GetDesignPackList
    this.setTable(this.selectDesignPackComponent.searchResults)
  },
  created () {
  },
  updated () {
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuote'
    ]),
    async selectDesignPack (selectedDesignPack) {
      var designPack = await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackFromDb(selectedDesignPack.latestQuoteDesignPackRevisionId)

      this.$emit('designpack-selected', designPack)
    },
    displayDate: function (dateToFormat) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' }

      if (dateToFormat === '0001-01-01T00:00:00') {
        return ''
      } else {
        return new Date(dateToFormat).toLocaleDateString('en-gb', options)
      }
    },
    initialiseFromStore () {
    },
    setTable: function (newValue) {
      this.initialiseTable('designPacksTable', newValue,
        ['id', 'quoteOpportunityId', 'name', 'revisionNumber', 'createDate'],
        ['DesignPack Id', 'QuoteId', 'Name', 'Revision Number', 'Create Date'],
        null,
        '400px',
        ['150px', '150px', '350px', '70px', '100px'],
        10)
    },
    stateTextFromId: function (id) {
      switch (id) {
        case this.iQuoteConstants.quoteStates.UNPUBLISHED:
          return 'Unpublished'
        case this.iQuoteConstants.quoteStates.PUBLISHED:
          return 'Published'
        case this.iQuoteConstants.quoteStates.EXPIRED:
          return 'Expired'
        case this.iQuoteConstants.quoteStates.ORDERED:
          return 'Ordered'
        case this.iQuoteConstants.quoteStates.SUPERSEDED:
          return 'Superseded'
      }
    }
  }
}
</script>
<style scoped>
  .quotePanel {
    width: 580px;
    text-align: left;
  }

  #designPacksTable {
    width: 900px;
    margin-bottom: 60px;
  }

  #txtAccount {width:200px;}
  #txtContact {width:160px;}
  #txtContactEmail {width:200px;}
  #txtOpportunityName {width:200px;}
  #txtQuoteDescription {width:200px;}
  #txtFirstName {width:54px;}
  #txtLastName {width:54px;}
</style>
