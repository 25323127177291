export const iQuoteCalculationsUtilFunctionsMixin = {
  data () {
    return {
    }
  },
  mixins: [
  ],
  methods: {
    utilFunctions_GetUnitCostFromId: function (unitCostId) {
      var unitCosts = this.$store.getters.unitCosts
      var returnValue = null

      unitCosts.forEach(unitCost => {
        if (unitCost.id === unitCostId) {
          returnValue = unitCost
        }
      })

      return returnValue
    },
    utilFunctions_GetLicenseFromId: function (licenseId) {
      var licenses = this.$store.getters.licenses
      var returnValue = null

      licenses.forEach(license => {
        if (license.id === licenseId) {
          returnValue = license
        }
      })

      return returnValue
    },
    utilFunctions_GetLicensePrice: function (requiredLicenseId) {
      var licenses = this.$store.getters.licenses

      for (let index = 0; index < licenses.length; index++) {
        const license = licenses[index]
        if (license.id === requiredLicenseId) {
          return license.price
        }
      }
      return 0
    },
    utilFunctions_GetLicenseCost: function (requiredLicenseId) {
      var licenses = this.$store.getters.licenses

      for (let index = 0; index < licenses.length; index++) {
        const license = licenses[index]
        if (license.id === requiredLicenseId) {
          return license.cost
        }
      }
      return 0
    },
    utilFunctions_GetUnitCostPrice: function (unitCostId) {
      var unitCosts = this.$store.getters.unitCosts
      var price

      for (let index = 0; index < unitCosts.length; index++) {
        const unitCost = unitCosts[index]
        if (unitCost.id === unitCostId) {
          if (unitCost.price === null) {
            var serviceId = this.getUnitCostServiceId(unitCost.id)
            switch (serviceId) {
              case this.iQuoteConstants.services.sip:
                if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
                  price = unitCost.cost / ((100 - this.iQuoteStore_GetQuote.marginSipMigration) / 100)
                } else {
                  price = unitCost.cost / ((100 - this.iQuoteStore_GetQuote.marginGammaSip) / 100)
                }
                break
              case this.iQuoteConstants.services.callmediaCXNow:
                if (unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.CALLMEDIA_CX_PS) {
                  price = unitCost.cost / ((100 - this.iQuoteStore_GetQuote.marginCxNowPS) / 100)
                } else if (unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.AWS_INFRASTRUCTURE) {
                  price = unitCost.cost / ((100 - this.iQuoteStore_GetQuote.marginAWS) / 100)
                } else {
                  price = 'ERROR'
                  console.log('Unitcost ' + unitCost.id + ' cannot determine price, as CXNow service category was not PS or AWSInfrastructure')
                }
                break
              case this.iQuoteConstants.services.gateway:
                price = unitCost.cost / ((100 - this.iQuoteStore_GetQuote.marginIconGateway) / 100)
                break
              case this.iQuoteConstants.services.communicate:
                price = unitCost.cost / ((100 - this.iQuoteStore_GetQuote.marginInfrastructure) / 100)
                break
              case this.iQuoteConstants.services.contact:
                price = unitCost.cost / ((100 - this.iQuoteStore_GetQuote.marginInfrastructure) / 100)
                break
              default:
                console.log('Unitcost ' + unitCost.id + ' cannot determine price, as unexpected service ' + serviceId)
                price = 'ERROR'
            }
          } else {
            price = unitCost.price
          }

          return this.iQuoteFunctions_RoundToTwoDecimalPlaces(price)
        }
      }
      return 0
    },
    utilFunctions_GetUnitCostAmountPerYear: function (unitCostId) {
      var returnValue = 0
      var unitCosts = this.$store.getters.unitCosts

      unitCosts.forEach(availableUnitCost => {
        if (availableUnitCost.id === unitCostId && availableUnitCost.recurringCost === true) {
          if (availableUnitCost.cost === null) {
            returnValue = null
          } else if (availableUnitCost.paymentsPerYear !== -1) {
            returnValue = availableUnitCost.cost * availableUnitCost.paymentsPerYear
          } else {
            var quote = this.$store.getters.quote
            returnValue = availableUnitCost.cost / (quote.termLengthMonths / 12)
          }
        }
      })

      return this.iQuoteFunctions_RoundToFourDecimalPlaces(returnValue)
    },
    utilFunctions_GetUnitCostPriceAmountPerYear: function (unitCostId) {
      var returnValue = 0
      var unitCosts = this.$store.getters.unitCosts
      var unitPrice

      unitCosts.forEach(availableUnitCost => {
        if (availableUnitCost.id === unitCostId && availableUnitCost.recurringCost === true) {
          unitPrice = this.utilFunctions_GetUnitCostPrice(unitCostId)
          if (unitPrice === 'ERROR') {
            returnValue = 'ERROR'
          } else if (availableUnitCost.paymentsPerYear !== -1) {
            returnValue = unitPrice * availableUnitCost.paymentsPerYear
          } else {
            var quote = this.$store.getters.quote
            returnValue = unitPrice / (quote.termLengthMonths / 12)
            // Note that if you have a custom term length that's not a whole year, say 18 months, then the last year of the contract will actually be
            // less than the value returned by this function.  In the example of 18 months the first year would have 66.66% of the cost, and the last 6 months
            // would be 33.33% of the figure returned.  So any code calling this function will need to bare that in mind and multiply this value by the fraction
            // of a year that the last year is (0.5 in the case of 18 month contract).  For a 6 month contract multply this value by 0.5.
          }
        }
      })

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(returnValue)
    },
    utilFunctions_GetUnitCostCategoryId: function (unitCostId) {
      var unitCosts = this.$store.getters.unitCosts

      for (let index = 0; index < unitCosts.length; index++) {
        const unitCost = unitCosts[index]
        if (unitCost.id === unitCostId) {
          return unitCost.unitCostCategoryId
        }
      }
      return 0
    },
    utilFunctions_GetUnitCostCost: function (unitCostId) {
      var unitCosts = this.$store.getters.unitCosts

      for (let index = 0; index < unitCosts.length; index++) {
        const unitCost = unitCosts[index]
        if (unitCost.id === unitCostId) {
          return unitCost.cost
        }
      }
      return 0
    },
    utilFunctions_UnitCostExistsOnQuote: function (unitCostId) {
      var unitCosts = this.$store.getters.quoteUnitCosts

      return (unitCosts.find(element => element.unitCostId === unitCostId) !== undefined)
    }
  },
  mounted () {
  }
}
