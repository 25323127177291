var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"serviceOverview"}},[(_vm.viewAreaTicketsPermission)?_c('div',[_c('div',[_c('ServiceStatusTile',{staticClass:"dashboardTile"}),_vm._v(" "),_c('div',{staticStyle:{"padding":"15px 0"},attrs:{"id":"ServiceAlarms"}},[_c('overviewCard',{attrs:{"id":"ticketsClickthroughUnresolved","value":_vm.filteredAlarms.find(function (ref) {
	var name = ref.name;

	return name === 'Unresolved Alarms';
}).alarmCount.toString() || 'N/A',"to":"/site-health?alarmsFilter=high","title":"Total Unresolved Alarms","colour":"red","icon":"mdi-alarm"}}),_vm._v(" "),_c('overviewCard',{attrs:{"id":"ticketsClickthroughRaised","value":_vm.filteredAlarms.find(function (ref) {
	var name = ref.name;

	return name === 'Alarms Raised';
}).alarmCount.toString(),"to":"/site-health","title":"Total Alarms Raised","colour":"grey","icon":"mdi-alarm","sub-icon":"mdi-calendar","sub-text":"Within Last 7 Days"}})],1)],1)]):_c('div',{staticClass:"noResults"},[_vm._v("\n    Sorry, you do not have permission to view the service status page\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }