import dashboardAPI from '../interceptors/apiInterceptor'
import { mapActions } from 'vuex'
export const dashboardRepository = {
  methods: {
    ...mapActions([
      'setCircuitStatus',
      'setTicketStatus'
    ]),
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
    },
    getSiteList () {
      var sites = this.$store.getters.sites
      if (sites && sites.length > 0) {
        var siteList = ''
        for (let i = 0; i < sites.length; i++) {
          siteList += sites[i].id + ','
        }
        if (sites.length > 0) {
          siteList = siteList.slice(0, -1)
        }
      }
      return siteList
    },
    circuitStatusGet: async function (callback) {
      var siteList = this.getSiteList()
      if (siteList) {
        return dashboardAPI
          .post('/dashboard/circuitstatus', {
            SiteList: siteList
          })
          .then(res => {
            this.setCircuitStatus(res.data)
            if (callback) {
              callback()
            }
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log('circuitstatus error: ', err)
            return []
          })
      } else {
        return []
      }
    },
    ticketStatusGet: async function (callback) {
      var siteList = this.getSiteList()
      if (siteList) {
        return dashboardAPI
          .post('/dashboard/ticketstatus', {
            SiteList: siteList
          })
          .then(res => {
            if (res.data.length === 0) {
              res.data = []
            }
            this.setTicketStatus(res.data)
            if (callback) {
              callback()
            }
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log('ticketstatus error: ', err)
            return []
          })
      } else {
        return []
      }
    },
    addDashboardImage: async function (dashboardImage) {
      if (dashboardImage) {
        return dashboardAPI
          .post('/dashboardimage/uploaddashboardimage/dashboard', dashboardImage)
          .then(res => {
            // console.log(res)
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log('dashboardimage error: ', err)
            return []
          })
      }
    },
    dashboardClear () {
      this.setCircuitStatus({})
      this.setTicketStatus({})
    }
  }
}
