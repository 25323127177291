<template>
  <div class="quoteDetailsContainer">
    <span class="containerHeader">
      <label> {{ selectedOpportunity.opportunityName || 'N/A' }} </label>
    </span>
    <span class="formDetails">
      <label> Quote Number: </label> <p> {{ selectedOpportunity.quote_Number || 'N/A' }} </p>
    </span>
    <span
      class="formDetails"
    >
      <label> Salesforce Owner: </label> <p> {{ selectedOpportunity.owner && selectedOpportunity.owner.name || 'N/A' }} </p>
    </span>
    <span
      class="formDetails"
    >
      <label> Overall Solution Owner: </label> <p> {{ selectedOpportunity.overall_Solution_Owner || 'N/A' }} </p>
    </span>
    <span class="formDetails">
      <label> Status: </label>
      <p
        :class="statusClass"
        class="ragStatus"
      >
        {{ selectedOpportunity.stageName || 'N/A' }}
      </p>
    </span>
    <span
      v-if="selectedOpportunity.createdDate"
      class="formDetails"
    >
      <label> Create Date: </label> <p> {{ selectedOpportunity.createdDate ? new Date(selectedOpportunity.createdDate).toDateString() : 'N/A' }} </p>
    </span>
    <span
      v-if="selectedOpportunity.closeDate"
      class="formDetails"
    >
      <label> Close Date: </label> <p> {{ selectedOpportunity.closeDate ? new Date(selectedOpportunity.closeDate).toDateString() : 'N/A' }} </p>
    </span>
  </div>
</template>

<script>

import tableHandler from '../../mixins/tableHandler'
import Spinner from '../common/spinner'

export default {
  name: 'ProjectDetails',
  components: {
    Spinner
  },
  mixins: [
    tableHandler
  ],
  props: {
    selectedOpportunity: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  computed: {
    statusClass () {
      switch (this.selectedOpportunity && this.selectedOpportunity.stageName ? this.selectedOpportunity.stageName.toLowerCase() : '') {
        case 'pricing provided':
          return 'ragBlue'
        default:
          return 'ragAmber'
      }
    }
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
  .quoteDetailsContainer {
    max-height: 400px;
    overflow-y: auto;
    padding:10px;
  }

  .quoteDetailsContainer label {
    font-weight: 700;
  }

  .ragStatus {
    border-radius: 20px;
    padding: 2px 10px !important;
    color: #000;
    font-weight: bold;
  }

  .quoteDetailsContainer p {
    display:inline-block;
    padding-left: 5px;
  }

  .quoteDetailsContainer span {
    width: 100%;
    display:block;
  }

  .ragBlue {
    background-color: #42a5f5;
    color: #fff;
  }

  .ragAmber{
    background-color: #ffc861;
  }

  .containerHeader {
    background: #274a5a;
    padding: 6px 10px;
    margin: 0 0 5px 0;
    color: #fff;
    border-radius: 5px;
  }

  .formDetails {
    padding: 0 0 0 15px;
    margin: 0 0 5px 0;
  }

  .formDetails p {
    margin: 0;
  }

  .progressbar {
    height: 25px;
    background-color: rgb(154, 168, 177);
    border-radius: 5px;
    margin-bottom: 5px;
    color: #fff;
  }

  .progression {
    background-color: rgb(99, 180, 82);
    height:25px;
    border-radius: 5px;
    padding: 0 5px;
  }

  .w0{
    width:25%;
    background-color: rgb(154, 168, 177);
  }
  .w25 {
    width: 25%;
  }
  .w50 {
    width: 50%;
  }
  .w75 {
    width: 75%;
  }
  .w100 {
    width: 100%;
  }

</style>
