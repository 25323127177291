const state = {
  users: [],
  currentUser: {}
}

const mutations = {
  SET_USERS (state, users) {
    state.users = users
  },
  SET_CURRENT_USER (state, currentUser) {
    state.currentUser = currentUser
  }
}

const actions = {
  setUsers: ({ commit }, users) => {
    commit('SET_USERS', users)
  },
  setCurrentUser: ({ commit }, currentUser) => {
    commit('SET_CURRENT_USER', currentUser)
  }
}

const getters = {
  users: state => {
    return state.users
  },
  currentUser: state => {
    return state.currentUser
  }
}
export default {
  state,
  mutations,
  actions,
  getters
}
