<template>
  <div id="mainContainer">
    <div id="mainTableContainer">
      <table
        id="mainTable"
        class="iquoteDesignpackTable noTableShading">
        <tr class="mainHeader">
          <th colspan="4">&nbsp;</th>
        </tr>
        <tr class="mainHeader">
          <th colspan="2">&nbsp;</th>
          <th colspan="1">Rental</th>
          <th colspan="1">&nbsp;</th>
        </tr>
        <tr class="subHeader">
          <th>Circuit Type</th>
          <th>AT Code</th>
          <th>Annual Cost<br>(Total)</th>
          <th>&nbsp;</th>
        </tr>
        <tbody
          v-for="circuitsEquinixCloudConnectEntry in circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries"
          :key="circuitsEquinixCloudConnectEntry.id">
          <tr
            v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry) || (!isReadOnly && isBlankEntry(circuitsEquinixCloudConnectEntry))"
            :class="{isNewRow: circuitsEquinixCloudConnectEntry.timeCopied !== undefined, disabled: circuitsEquinixCloudConnectEntry.disabled}"
            class="data"
          >
            <td>
              <select
                :id="'equinixCircuitInput' + circuitsEquinixCloudConnectEntry.id"
                :disabled="isReadOnly"
                v-model="circuitsEquinixCloudConnectEntry.designPackCircuitsEquinixCircuitTypeId"
                class="equinixCircuitInput"
                @blur="onInputItemFocusLost(circuitsEquinixCloudConnectEntry, $event)"
                @change="sendChangesToParent(circuitsEquinixCloudConnectEntry)"
              >
                <option
                  v-if="circuitsEquinixCloudConnectEntry.designPackCircuitsEquinixCircuitTypeId === 0"
                  key="0"
                  value="0"
                  title="< Please Select >"
                >&lt; Please Select &gt;
                </option>
                <option
                  v-for="t in iQuoteStore_GetCircuitsTableLookups.equinixCircuitTypes"
                  :key="t.id"
                  :value="t.id"
                  :title="t.description"
                >{{ t.description }}
                </option>
              </select>
            </td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)">{{ getEntryAtCode(circuitsEquinixCloudConnectEntry) }}</span></td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)">£{{ getEntryAnnualCost(circuitsEquinixCloudConnectEntry).toFixed(2) }}</span></td>
            <td>
              <button
                v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry) && !isReadOnly && !circuitsEquinixCloudConnectEntry.disabled"
                :id="'btnDisable' + circuitsEquinixCloudConnectEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Disable this entry so that the costs and prices are NOT INCLUDED in the design pack and quote"
                @click="disableEntry(circuitsEquinixCloudConnectEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconPause"
                  alt="remove">
              </button>
              <button
                v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry) && !isReadOnly && circuitsEquinixCloudConnectEntry.disabled"
                :id="'btnDisable' + circuitsEquinixCloudConnectEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Enable this entry so that the costs and prices are included in the design pack and quote"
                @click="enableEntry(circuitsEquinixCloudConnectEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconResume"
                  alt="insert">
              </button>
              <button
                v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry) && !isReadOnly"
                :id="'btnDelete' + circuitsEquinixCloudConnectEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Delete this entry"
                style="margin-left: 15px"
                @click="deleteEntry(circuitsEquinixCloudConnectEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconDelete"
                  alt="delete">
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="marginsTableContainer">
      <table
        id="marginsTable"
        class="iquoteDesignpackTable noTableShading">
        <tr class="mainHeader">
          <th
            id="capexMarginColumn"
            colspan="3">Capex Sales Margin
          </th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th
            id="opexMarginColumn"
            colspan="3">Opex Sales Margin</th>
        </tr>
        <tr class="mainHeader">
          <th colspan="3">
            <div
              v-if="!isReadOnly"
              class="verticalAlignTop"
            >
              <input
                id="chkOverrideCapexMargin"
                v-model.number="circuitsEquinixCloudConnectTableEditorComponent.overrideCapexMargin"
                type="checkbox"
              >
              <input
                v-show="circuitsEquinixCloudConnectTableEditorComponent.overrideCapexMargin"
                id="txtCapexMarginOverride"
                v-model.number="iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsEquinixCloudConnectCapexPercent"
                :class="{invalidInput: (iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsEquinixCloudConnectCapexPercent < 0 || iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsEquinixCloudConnectCapexPercent >= 100)}"
                :disabled="isReadOnly"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                max="99"
                class="marginInput marginOverrideTextboxInTableHeader"
                @change="handleCapexMarginChange($event)">
              <span v-if="!circuitsEquinixCloudConnectTableEditorComponent.overrideCapexMargin">Override Margin</span>
              <span v-if="circuitsEquinixCloudConnectTableEditorComponent.overrideCapexMargin">%</span>
            </div>
          </th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th colspan="3">
            <div
              v-if="!isReadOnly"
              class="verticalAlignTop"
            >
              <input
                id="chkOverrideOpexMargin"
                v-model.number="circuitsEquinixCloudConnectTableEditorComponent.overrideOpexMargin"
                type="checkbox"
              >
              <input
                v-show="circuitsEquinixCloudConnectTableEditorComponent.overrideOpexMargin"
                id="txtOpexMarginOverride"
                v-model.number="iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsEquinixCloudConnectOpexPercent"
                :class="{invalidInput: (iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsEquinixCloudConnectOpexPercent < 0 || iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsEquinixCloudConnectOpexPercent >= 100)}"
                :disabled="isReadOnly"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                max="99"
                class="marginInput marginOverrideTextboxInTableHeader"
                @change="handleOpexMarginChange($event)">
              <span v-if="!circuitsEquinixCloudConnectTableEditorComponent.overrideOpexMargin">Override Margin</span>
              <span v-if="circuitsEquinixCloudConnectTableEditorComponent.overrideOpexMargin">%</span>
            </div>
          </th>
        </tr>
        <tr class="subHeader">
          <th>Margin %</th>
          <th>Margin</th>
          <th>Sale Price</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th>Margin %</th>
          <th>Margin</th>
          <th>Sale Price</th>
        </tr>
        <tbody
          v-for="circuitsEquinixCloudConnectEntry in circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries"
          :key="circuitsEquinixCloudConnectEntry.id">
          <tr
            v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry) || (!isReadOnly && isBlankEntry(circuitsEquinixCloudConnectEntry))"
            class="data"
          >
            <td
              v-if="getEntryUpfrontCost(circuitsEquinixCloudConnectEntry) === 0"
              class="NotApplicableMargin"
              colspan="3"
            >
              <span
                v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)"
              >Not Applicable
              </span>
            </td>
            <td
              v-if="getEntryUpfrontCost(circuitsEquinixCloudConnectEntry) !== 0"
            >
              <input
                v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)"
                :id="'unitCostInput' + circuitsEquinixCloudConnectEntry.id"
                v-model.number="circuitsEquinixCloudConnectEntry.marginCapexPercent"
                :class="{invalidInput: (circuitsEquinixCloudConnectEntry.marginCapexPercent < 0 || circuitsEquinixCloudConnectEntry.marginCapexPercent >= 100)}"
                :disabled="isReadOnly || getEntryUpfrontCost(circuitsEquinixCloudConnectEntry) === 0"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                class="marginInput"
                @change="sendChangesToParent(circuitsEquinixCloudConnectEntry)">
              <span v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)">%</span>
            </td>
            <td
              v-if="getEntryUpfrontCost(circuitsEquinixCloudConnectEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)">£{{ getEntryCapexMargin(circuitsEquinixCloudConnectEntry).toFixed(2) }}</span></td>
            <td
              v-if="getEntryUpfrontCost(circuitsEquinixCloudConnectEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)">£{{ getEntryCapexSalePrice(circuitsEquinixCloudConnectEntry).toFixed(2) }}</span></td>
            <td class="noborder fixedSpacer">&nbsp;</td>
            <td
              v-if="getEntryAnnualCost(circuitsEquinixCloudConnectEntry) === 0"
              class="NotApplicableMargin"
              colspan="3"
            >
              <span
                v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)"
              >Not Applicable
              </span>
            </td>
            <td
              v-if="getEntryAnnualCost(circuitsEquinixCloudConnectEntry) !== 0 && circuitsEquinixCloudConnectEntry.priceOverride === null"
            >
              <input
                v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)"
                :id="'unitCostInput' + circuitsEquinixCloudConnectEntry.id"
                v-model.number="circuitsEquinixCloudConnectEntry.marginOpexPercent"
                :class="{invalidInput: (circuitsEquinixCloudConnectEntry.marginOpexPercent < 0 || circuitsEquinixCloudConnectEntry.marginOpexPercent >= 100)}"
                :disabled="isReadOnly || getEntryAnnualCost(circuitsEquinixCloudConnectEntry) === 0"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                style="width: 80%"
                class="marginInput"
                max="99"
                @change="sendChangesToParent(circuitsEquinixCloudConnectEntry)">
              <span v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)">%</span>
            </td>
            <td
              v-if="getEntryAnnualCost(circuitsEquinixCloudConnectEntry) !== 0 && circuitsEquinixCloudConnectEntry.priceOverride !== null"
              class="readOnlyCell"
            >
              <span
                v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)">
                {{ calcRecurringMarginPercent(circuitsEquinixCloudConnectEntry) }}
                <br>
                <TooltipIcon
                  style="margin-left: -8px"
                  width="200"
                  align="right"
                  text="Price was set on Detailed Summary Tab" />
              </span>
            </td>
            <td
              v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry) && getEntryAnnualCost(circuitsEquinixCloudConnectEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)">£{{ getEntryOpexMargin(circuitsEquinixCloudConnectEntry).toFixed(2) }}</span></td>
            <td
              v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry) && getEntryAnnualCost(circuitsEquinixCloudConnectEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsEquinixCloudConnectEntry)">£{{ getEntryOpexSalePrice(circuitsEquinixCloudConnectEntry).toFixed(2) }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import { iQuoteStoreMixin } from '../../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackCircuitsMixin } from '../../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackCircuits'
import { iQuoteImagesMixin } from '../../../../mixins/iQuote/Globals/iQuoteImages'
import TooltipIcon from '../../../common/tooltipIcon'

import { iQuoteEventBus } from '../../../../events/iQuoteEvents'

import { mapActions } from 'vuex'

export default {
  name: 'CircuitsEquinixCloudConnectTableEditor',
  components: {
    TooltipIcon
  },
  mixins: [ iQuoteStoreMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsDesignPackCircuitsMixin, iQuoteImagesMixin ],
  props: {
  },
  data () {
    return {
      circuitsEquinixCloudConnectTableEditorComponent: {
        circuitsEquinixCloudConnectEntries: [],
        quoteDesignPackRevisionId: 0,
        overrideCapexMargin: false,
        overrideOpexMargin: false
      }
    }
  },
  computed: {
    isReadOnly () {
      return this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.isReadOnly
    },
    designPackTableSection_Technology () {
      return this.iQuoteStore_GetDesignPackTableSections.find(x => x.id === this.iQuoteConstants.designPackTableSections.TECH)
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.designPack,
      (newValue) => {
        this.initialise(newValue)
      }
    )

    this.initialise(this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack)
  },
  async created () {
    iQuoteEventBus.$on('designpack-priceOverridesChanged', async (designPack) => {
      var forceReload = true
      await this.initialise(designPack, forceReload)
    })
  },
  async destroyed () {
    iQuoteEventBus.$off('designpack-priceOverridesChanged')
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    async initialise (designPack, forceReload = false) {
      if (designPack.quoteDesignPackRevisionId === undefined) {
        // There is no design pack so nothing we can do here
        return
      }

      if (this.circuitsEquinixCloudConnectTableEditorComponent.quoteDesignPackRevisionId === designPack.quoteDesignPackRevisionId) {
        //  The designpack that just saved, is the one we are currently looking at

        if (this.currentPage !== 0) this.circuitsEquinixCloudConnectTableEditorComponent.currentPage = 0

        if (!forceReload) {
          // DP is the same one as before, and we aren't forcing a reload. So just return
          return
        }
      }

      this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries = []
      this.circuitsEquinixCloudConnectTableEditorComponent.quoteDesignPackRevisionId = designPack.quoteDesignPackRevisionId

      // Copy entries from store into this component for editing
      designPack.circuitsEquinixCloudConnectEntries.forEach(entry => {
        this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries.push(entry)
      })

      if (designPack.isReadOnly === false) {
        await this.iQuoteFunctions_delay(2000) // Wait a while for the DP to finish loading

        // Update entries to latest costs from the lookups table
        this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries.forEach(entry => {
          if (!this.isBlankEntry(entry)) {
            entry.costMonthly = this.getEntryAnnualCost(entry) / 12
          }
        })

        var updatedEntries = []

        var array = this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries

        for (let index = 0; index < array.length; index++) {
          const entry = array[index]

          if (!this.isBlankEntry(entry)) {
            updatedEntries.push(entry)
          }
        }

        // this.$emit('tableUpdated', updatedEntries)
      }

      this.addBlankEntry()
    },
    calcUpfrontMarginPercent (entry) {
      var cost = this.getEntryUpfrontCost(entry)
      var price = this.getEntryCapexSalePrice(entry)
      var profit = price - cost
      var margin = (profit / price) * 100

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(margin) + '%'
    },
    calcRecurringMarginPercent (entry) {
      var cost = entry.costMonthly * 12
      var price = this.getEntryOpexSalePrice(entry)
      var profit = price - cost
      var margin = (profit / price) * 100

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(margin) + '%'
    },
    handleCapexMarginChange (event) {
      this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries.forEach(entry => {
        entry.marginCapexPercent = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsEquinixCloudConnectCapexPercent
      })
      this.sendChangesToParent()
    },
    handleOpexMarginChange (event) {
      this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries.forEach(entry => {
        entry.marginOpexPercent = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsEquinixCloudConnectOpexPercent
      })
      this.sendChangesToParent()
    },
    getEntryUpfrontCost (entry) {
      return 0
    },
    getEntryAnnualCost (entry) {
      if (this.isBlankEntry(entry)) {
        return 0
      }

      return this.iQuoteStore_GetCircuitsTableLookups.equinixCircuitTypes.filter(x => x.id === entry.designPackCircuitsEquinixCircuitTypeId)[0].recurringCost
    },
    getEntryAtCode (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      return this.iQuoteStore_GetCircuitsTableLookups.equinixCircuitTypes.filter(x => x.id === entry.designPackCircuitsEquinixCircuitTypeId)[0].atCode
    },
    getEntryCapexMargin (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      return this.getEntryCapexSalePrice(entry) - this.getEntryUpfrontCost(entry)
    },
    getEntryCapexSalePrice (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      return this.getEntryUpfrontCost(entry) / ((100 - entry.marginCapexPercent) / 100)
    },
    getEntryOpexMargin (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      return this.getEntryOpexSalePrice(entry) - this.getEntryAnnualCost(entry)
    },
    getEntryOpexSalePrice (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }
      var price = this.getEntryAnnualCost(entry) / ((100 - entry.marginOpexPercent) / 100)
      var priceOverride = entry.priceOverride

      if (priceOverride !== null) {
        price = priceOverride
      }

      return price
    },
    onInputItemFocusLost (entry, event) {
      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }
    },
    isBlankEntry (entry) {
      return entry.designPackCircuitsEquinixCircuitTypeId === 0
    },
    hasBlankEntry () {
      var foundBlankEntry = false
      this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries.forEach(entry => {
        if (this.isBlankEntry(entry)) {
          foundBlankEntry = true
        }
      })

      return foundBlankEntry
    },
    addBlankEntry () {
      this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries.push(this.getBlankEntry())
    },
    getBlankEntry () {
      return {
        id: this.getTempEntryId(),
        designPackCircuitsEquinixCircuitTypeId: 0,
        marginCapexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        marginOpexPercent: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
        costMonthly: 0,
        disabled: false,
        designPackItemLookupId: 0,
        priceOverride: null
      }
    },
    sendChangesToParent (entry) {
      if (entry !== undefined) {
        entry.costMonthly = this.getEntryAnnualCost(entry) / 12
      }

      var newEntries = []
      var blankEntry = this.getBlankEntry()
      var array = this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries

      for (let index = 0; index < array.length; index++) {
        const entry = array[index]

        if (!this.isBlankEntry(entry)) {
          // Loop through all properties of the blank entry and compare against the entry to be
          // saved to check that the user hasn't entered a value of the wrong data type. This typically
          // will only happen if they deleted the contents of a numeric field. This would result in a empty
          // string being returned, and will cause a failure if we tried to save that to the DB, as it's not numeric
          for (const property in blankEntry) {
            if (typeof blankEntry[property] !== typeof entry[property] &&
                property !== 'priceOverride' /* can be object (null) or a number */) {
              // User has entered something of wrong datatype,
              // which will fail when saving, so reset it to default value

              console.log('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])
              this.iQuoteFunctions_ShowError('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])

              entry[property] = blankEntry[property] // Set to default
            }
          }
          entry.designPackItemLookupId = entry.designPackCircuitsEquinixCircuitTypeId
          newEntries.push(entry)
        }
      }

      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }

      this.$emit('tableUpdated', newEntries)
    },
    getTempEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    deleteEntry (entry) {
      var arr = this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === entry.id) {
          arr.splice(i, 1)
          this.sendChangesToParent(entry)
          return
        }
      }
    },
    disableEntry (entry, sendChangesEvent = true) {
      entry.disabled = true
      if (sendChangesEvent) {
        this.sendChangesToParent(entry)
      }
    },
    enableEntry (entry, sendChangesEvent = true) {
      entry.disabled = false
      if (sendChangesEvent) {
        this.sendChangesToParent(entry)
      }
    },
    /*
    FUNCTION: duplicateEntry
    PARAMS:
      entry = the entry to be copied
    */
    duplicateEntry (entry) {
      var newItem = {
        id: this.getTempEntryId(),
        designPackCircuitsEquinixCircuitTypeId: entry.designPackCircuitsEquinixCircuitTypeId,
        marginCapexPercent: entry.marginCapexPercent,
        marginOpexPercent: entry.marginOpexPercent,
        costMonthly: entry.costMonthly
      }

      newItem.timeCopied = new Date() /* This will cause the cells for this row to be highlighted */

      var arr = this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === entry.id) {
          arr.splice(arr.length - 1, 0, newItem)
          this.sendChangesToParent(entry)
          setTimeout(() => this.stopHighlightingNewlyAddedRecords(), 2000)
          return
        }
      }
    },
    /*
    When items are newly added to the table a "timeCopied" property was set, and will cause
    a class to be added to those cells.  This function is called after a delay in order to
    remove that property, which in turn will stop the cells from being highlighted.
    */
    stopHighlightingNewlyAddedRecords () {
      var arr = this.circuitsEquinixCloudConnectTableEditorComponent.circuitsEquinixCloudConnectEntries

      arr.forEach(record => {
        if (Date.now() - record.timeCopied > 1850) {
          record.timeCopied = undefined
        }
      })
    }
  }
}
</script>
<style scoped>
  table.iquoteDesignpackTable tr{
    height: 38px;
  }

  #mainContainer:after {
    content: "";
    display: table;
    clear: both;
  }

  #mainTable tr, #mainTable td {
    transition: background .5s ease-in;;
    -moz-transition: background .5s ease-in;
    -o-transition: background .5s ease-in;
    -webkit-transition: background .5s ease-in;
  }

  #mainTable tr.isNewRow{
    background-color: burlywood;
  }

  #mainTable tr.isNewRow td.readOnlyCell {
    background-color: chocolate;
  }

  #mainTableContainer {
    width: calc(100% - 400px);
    display: inline;
    float: left;
    padding-right: 100px;
  }

  #marginsTableContainer {
    width: 400px;
    display: inline;
    float: right;
  }

  #mainTable {
    width: auto;
    table-layout: fixed;
    border: none;
    margin: 0, 150px, 0, 0;
  }

  #marginsTable {
    margin: 0;
    table-layout: fixed;
    border: none;
  }

  #mainTable th, #mainTable td, #marginsTable th, #marginsTable td {
    white-space:nowrap;
    height: 32px;
    text-align: center;
  }

  table input[type=text], table input[type=number], table input[type=button], table select {
    border: none;
    margin: 0;
    height: 25px;
    font-size: 1em;
  }

  tr.mainHeader {
    text-align: center;
    font-size: 1.5em;
  }

  #mainTable tr.disabled td,  #marginsTable tr.disabled td {
    background-color: silver;
  }

  #mainTable select.equinixCircuitInput {
    width: 300px;
  }

  #mainTable tr.data:nth-child(1) {
    width: 100px;
  }

  #marginsTable tr.data:nth-child(1) {
    width: 100px;
  }

  #marginsTable tr td.fixedSpacer {
    width: 15px;
  }

  .readOnlyCell {
    background-color: silver;
  }

  .noborder {
    border: none;
  }

  #mainTable tr td.NotApplicableMargin {
    text-align: center;
    font-style: italic;
    background-color: silver;
  }
</style>
