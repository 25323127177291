<template>
  <div
    :id="id"
    class="areaRolesHolder"
  >
    <Spinner
      v-if="loading"
      id="areaRolesSpinner"
    />
    <table v-if="!loading">
      <tbody>
        <tr
          v-for="r in roles"
          :class="getRowColour()"
          :key="r.id"
        >
          <td>{{ r.name }} </td>
          <td
            v-if="removeRoleAreaPermission"
            style="text-align:right"
          >
            <input
              type="button"
              class="deleteButton"
              title="Remove role from site"
              @click="removeAreaRole(r)"
            >
          </td>
        </tr>
      </tbody>
    </table>
    <input
      v-if="assignRoleAreaPermission && !loading"
      id="areaRolesButton"
      type="button"
      class="areaRolesButton"
      value="Add Role"
      title="Adds the new role to the area"
      @click="addRole()"
    >
    <SiteNewRole
      :areaid="areaid"
      :userid="userid"
    />
  </div>
</template>
<script>
import SiteNewRole from './areaNewRole'
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import Spinner from '../../common/spinner'
import { mapActions } from 'vuex'
export default {
  name: 'AreaRoles',
  components: {
    SiteNewRole,
    Spinner
  },
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    areaid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      loading: false,
      roles: []
    }
  },
  computed: {
    reloadRoles () {
      return this.$store.getters.reloadRoles
    },
    assignRoleAreaPermission () {
      return this.$store.getters.assignRoleAreaPermission
    },
    removeRoleAreaPermission () {
      return this.$store.getters.removeRoleAreaPermission
    }
  },
  watch: {
    areaid () {
      this.setComponent()
    },
    reloadRoles (val) {
      if (val) {
        this.setComponent()
        this.setReloadRoles(false)
      }
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setSubForm2',
      'setReloadRoles'
    ]),
    setComponent: async function () {
      if (this.areaid > -1) {
        this.loading = true
        this.getRoles()
      }
    },
    getRoles: async function () {
      this.getAreaRoles(this.areaid, this.userid).then((results) => {
        this.roles = results
        this.loading = false
      })
    },
    addRole: async function () {
      this.setSubForm2(true)
    },
    removeAreaRole: async function (r) {
      await this.deleteRoleArea(r.id, this.areaid)
      this.setComponent()
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    }
  }
}

</script>
<style scoped>
  .areaRolesHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }
  .areaRolesButton {
    margin: 10px;
    float: right;
    cursor: pointer;
  }
</style>
