<template>
  <div
    class="quoteSummarySectionContainer"
    @keydown.esc="closeForms">
    <div
      v-if="quote.locked"
      style="background-color: orangered; color: white; margin-bottom 25px; padding: 5px; text-align:center;">
      This Quote is locked and cannot be edited!
    </div>
    <div
      v-show="!hideHeader && quote.quoteOpportunityId !== undefined"
      id="iQuoteQuoteSummaryHeader">
      <div>
        <span class="floatLeft"><label>Quote Id: </label>{{ quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')' }}</span>
        <span class="floatRight"><label>Quote Number: </label>{{ quote.salesforceQuoteNumber }}</span>
      </div>
      <hr>
      <div
        class="quoteSummaryDetails">
        <label>Description:</label> {{ quote.quoteDescription }}
      </div>
    </div>
    <div class="serviceList">
      <Spinner v-if="loading || externalQuotesModelMixin.isLoading" />
      <div
        v-if="(!loading && !externalQuotesModelMixin.isLoading)"
      >
        <div
          v-for="serviceEntry in quoteSummaryServices"
          :key="serviceEntry.serviceName"
          class="QuoteSummaryService">
          <div class="quoteSummaryServiceHeader">{{ serviceEntry.serviceName }}</div>
          <div
            v-for="servicePropertySection in serviceEntry.propertySections"
            :key="servicePropertySection.name"
            class="QuoteSummaryServiceSection">
            <div class="QuoteSummaryServiceSectionHeader">{{ servicePropertySection.name }}</div>
            <div
              v-for="servicePropertySectionEntry in servicePropertySection.entries"
              :key="servicePropertySectionEntry.key"
              class="QuoteSummaryServiceSectionEntry">
              <span class="QuoteSummaryServiceSectionEntryKey">{{ servicePropertySectionEntry.key }}:</span><span class="QuoteSummaryServiceSectionEntryValue">{{ servicePropertySectionEntry.value }}</span>
            </div>
          </div>
        </div>
      </div>
      <br style="clear:left">
      <div
        v-if="(!loading && !externalQuotesModelMixin.isLoading) &&
          !(quoteSummaryServices.length > 0 ||
            callmediacxnow.id !== undefined ||
            callmedia.id !== undefined ||
            externalQuotesModelMixin_State.quoteBoms.length > 0 ||
            externalQuotesModelMixin_State.quoteIconConnect.id != undefined ||
            externalQuotesModelMixin_State.quoteIconNow.id != undefined ||
            externalQuotesModelMixin_State.quoteManagedService.id != undefined ||
            externalQuotesModelMixin_State.quoteNewManagedService.id != undefined ||
            externalQuotesModelMixin_State.quoteCallsAndLines.id != undefined ||
            externalQuotesModelMixin_State.quoteHorizon.id != undefined ||
            externalQuotesModelMixin_State.quoteIconSecure.id != undefined ||
            externalQuotesModelMixin_State.quoteSecureHomeworker.id != undefined ||
            externalQuotesModelMixin_State.quoteTwilio.id != undefined
      )">
        <h3>No Services have been added to this quote yet</h3>
      </div>

      <div
        v-if="(!loading && !externalQuotesModelMixin.isLoading) && (callmediacxnow.id !== undefined || callmedia.id !== undefined)"
        class="reviewPanel">
        <h2>User Profiles</h2>
        <table class="noTableShading">
          <tr>
            <th style="width:420px"/>
            <th style="width:100px">Min Commit</th>
          </tr>
          <tbody v-if="callmedia.id !== undefined">
            <tr><td colspan="3"><h3>ICON Contact - Callmedia</h3></td></tr>
            <tr
              v-for="userProfile in callmedia.userProfiles.filter(x => x.minCommitment > 0)"
              :key="userProfile.id">
              <td>{{ userProfile.description }}</td>
              <td>{{ userProfile.minCommitment }}</td>
            </tr>
          </tbody>
          <tbody v-if="callmediacxnow.id !== undefined">
            <tr><td colspan="3"><h3>Callmedia CX Now</h3></td></tr>
            <tr
              v-for="userProfile in callmediacxnow.userProfiles.filter(x => x.minCommitment > 0)"
              :key="userProfile.id">
              <td>{{ userProfile.description }}</td>
              <td>{{ userProfile.minCommitment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <RootPage :is-read-only="true" />
      </div>
    </div>
    <div v-if="(!loading && !externalQuotesModelMixin.isLoading)">
      <input
        v-for="button in buttons"
        :key="button"
        :id="'btn' + button.replace(' ', '')"
        :value="button"
        type="button"
        @click="$emit('buttonClicked', button)">
      <input
        id="downloadDesignPack"
        class="downloadDesignPackButton"
        value="Export"
        type="button"
        @click="downloadDesignPack"
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteDisplayExpansionsMixin } from '../../../mixins/iQuote/display/iQuoteDisplayExpansionsMixin'
import { iQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteTechnologyAPIMixin'
import { externalQuotesModelMixin } from '../../../mixins/iQuote/Models/externalQuotesModelMixin'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import RootPage from './externalQuotes/rootPage'
import Spinner from '../../common/spinner'

export default {
  components: {
    Spinner,
    RootPage
  },
  mixins: [
    iQuoteConstants, iQuoteTechnologyAPIMixin, iQuoteDisplayExpansionsMixin, iQuoteStoreMixin, externalQuotesModelMixin, iQuoteExportsAPIMixin
  ],
  props: {
    showCustomerDetails: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Array,
      default: function () { return [] }
    }
  },
  data () {
    return {
      communicateTechnologies: [],
      contactTechnologies: [],
      communicateUserProfiles: [],
      contactUserProfiles: []
    }
  },
  computed: {
    quote () {
      return this.iQuoteStore_GetQuote
    },
    aura () {
      return this.iQuoteStore_GetQuoteTechnologyAvayaAura
    },
    miteluc () {
      return this.iQuoteStore_GetQuoteTechnologyMitelUC
    },
    callmediacxnow () {
      return this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow
    },
    sycurio () {
      return this.iQuoteStore_GetQuoteSycurio
    },
    ucAnalytics () {
      return this.iQuoteStore_GetQuoteUCAnalytics
    },
    icongateway () {
      return this.iQuoteStore_GetQuoteTechnologyIconGateway
    },
    callmedia () {
      return this.iQuoteStore_GetQuoteTechnologyCallmedia
    },
    elite () {
      return this.iQuoteStore_GetQuoteTechnologyAvayaElite
    },
    aacc () {
      return this.iQuoteStore_GetQuoteTechnologyAvayaAACC
    },
    mitelcc () {
      return this.iQuoteStore_GetQuoteTechnologyMitelCC
    },
    genesys () {
      return this.iQuoteStore_GetQuoteGenesys
    },
    avayaAXP () {
      return this.iQuoteStore_GetQuoteAvayaAXP
    },
    ringCentral () {
      return this.iQuoteStore_GetQuoteRingCentral
    },
    quotegammasip () {
      return this.iQuoteStore_GetGammaSip
    },
    quoteteamsconnector () {
      return this.iQuoteStore_GetTeamsConnector
    },
    quotesipmigrationproperties () {
      return this.iQuoteStore_GetQuoteSIPMigrationProperties
    },
    quoteexpansions () {
      return this.iQuoteStore_GetQuoteExpansions
    },
    quotephonelineplus () {
      return this.iQuoteStore_GetPhoneLinePlus
    },
    quoteSummaryServices () {
      var services = []

      if (this.quotegammasip.id !== undefined) {
        services.push(this.getSipProperties())
      }

      if (this.quotesipmigrationproperties.id !== undefined) {
        services.push(this.getSipMigrationProperties())
      }

      if (this.aura.id !== undefined) {
        services.push(this.getAuraProperties())
      }

      if (this.elite.id !== undefined) {
        services.push(this.getEliteProperties())
      }

      if (this.aacc.id !== undefined) {
        services.push(this.getAACCProperties())
      }

      if (this.miteluc.id !== undefined) {
        services.push(this.getMitelUCProperties())
      }

      if (this.mitelcc.id !== undefined) {
        services.push(this.getMitelCCProperties())
      }

      if (this.quoteteamsconnector.id !== undefined) {
        services.push(this.getTeamsProperties())
      }

      if (this.genesys.id !== undefined) {
        services.push(this.getGenesysProperties())
      }

      if (this.avayaAXP.id !== undefined) {
        services.push(this.getAvayaAXPProperties())
      }

      if (this.ringCentral.ucTechId !== undefined || this.ringCentral.ccTechId !== undefined) {
        services.push(this.getRingCentralProperties())
      }

      if (this.quoteexpansions.id !== undefined) {
        services.push(this.getExpansionProperties())
      }

      if (this.icongateway.id !== undefined) {
        services.push(this.getIconGatewayProperties())
      }

      if (this.quotephonelineplus.id !== undefined) {
        services.push(this.getPhonelinePlusProperties())
      }

      if (this.sycurio.id !== undefined) {
        services.push(this.getSycurioProperties())
      }

      if (this.ucAnalytics.id !== undefined) {
        services.push(this.getUCAnalyticsProperties())
      }

      return services
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
    ]),
    downloadDesignPack: async function () {
      var data = await this.iQuoteExportsAPIMixin_getExcelDesignPack(this.quote.id)
      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
      a.download = 'iQuote_' + this.quote.quoteOpportunityId + '_r' + this.quote.revisionNumber + '.xlsx'
      document.body.appendChild(a)
      a.click()
    },
    getSipProperties () {
      var newService = {
        serviceName: 'Icon SIP'
      }
      newService.propertySections = []

      var primaryProviderProperties = {
        name: 'Primary SIP Provider ',
        entries: []
      }

      newService.propertySections.push(primaryProviderProperties)

      var secondaryProviderProperties = {
        name: 'Secondary SIP Provider ',
        entries: []
      }

      var genericSipProperties = {
        name: 'SIP Configuration',
        entries: []
      }

      var gammaProviderProperties = null

      /* ADD ENTRIES FOR PRIMARY SIP PROVIDER */

      if (this.quotegammasip.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
        primaryProviderProperties.name += '(Gamma)'
        gammaProviderProperties = primaryProviderProperties
      } else {
        primaryProviderProperties.name += '(Talk Talk Business)'
      }

      if (this.quotegammasip.overridePrimarySIPChannels === 0) {
        primaryProviderProperties.entries.push({ key: 'Num SIP Channels', value: this.quotegammasip.sipConfigured })
      } else {
        primaryProviderProperties.entries.push({ key: 'Num SIP Channels', value: this.quotegammasip.overridePrimarySIPChannels })
      }

      if (this.quotegammasip.primarySipResilienceId === this.iQuoteConstants.sipResilienceTypes.ACTIVE_STANDBY) {
        primaryProviderProperties.entries.push({ key: 'Resilience', value: 'Active / Standby' })
      } else {
        primaryProviderProperties.entries.push({ key: 'Resilience', value: 'Active' })
      }

      primaryProviderProperties.entries.push({ key: 'New DDIs', value: this.quotegammasip.newDDIs })
      primaryProviderProperties.entries.push({ key: 'Num Circuits', value: this.quotegammasip.numPrimaryCircuits })
      primaryProviderProperties.entries.push({ key: 'Num Porting SingleLine', value: this.quotegammasip.numPortingPrimarySingleLine })
      primaryProviderProperties.entries.push({ key: 'Num Porting MultiLine', value: this.quotegammasip.numPortingPrimaryMultiLine })
      primaryProviderProperties.entries.push({ key: 'Num Porting MultiLine1To10', value: this.quotegammasip.numPortingPrimaryMultiLine1To10 })
      primaryProviderProperties.entries.push({ key: 'Num Porting MultiLineBlocksOver10', value: this.quotegammasip.numPortingPrimaryMultiLineBlocksOver10 })

      /* ADD ENTRIES FOR SECONDARY SIP PROVIDER */

      if (this.quotegammasip.secondarySipProviderId !== this.iQuoteConstants.sipProviders.none) {
        newService.propertySections.push(secondaryProviderProperties)

        if (this.quotegammasip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
          secondaryProviderProperties.name += '(Gamma)'
          gammaProviderProperties = secondaryProviderProperties
        } else {
          secondaryProviderProperties.name += '(Talk Talk Business)'
        }

        if (this.quotegammasip.overrideSecondarySIPChannels === 0) {
          secondaryProviderProperties.entries.push({ key: 'Num SIP Channels', value: this.quotegammasip.sipConfigured })
        } else {
          secondaryProviderProperties.entries.push({ key: 'Num SIP Channels', value: this.quotegammasip.overrideSecondarySIPChannels })
        }

        if (this.quotegammasip.secondarySipResilienceId === this.iQuoteConstants.sipResilienceTypes.ACTIVE_STANDBY) {
          secondaryProviderProperties.entries.push({ key: 'Resilience', value: 'Active / Standby' })
        } else {
          secondaryProviderProperties.entries.push({ key: 'Resilience', value: 'Active' })
        }

        secondaryProviderProperties.entries.push({ key: 'New DDIs', value: this.quotegammasip.newDDIsSecondary })
        secondaryProviderProperties.entries.push({ key: 'Num Circuits', value: this.quotegammasip.numSecondaryCircuits })
        secondaryProviderProperties.entries.push({ key: 'Num Porting SingleLine', value: this.quotegammasip.numPortingSecondarySingleLine })
        secondaryProviderProperties.entries.push({ key: 'Num Porting MultiLine', value: this.quotegammasip.numPortingSecondaryMultiLine })
        secondaryProviderProperties.entries.push({ key: 'Num Porting MultiLine 1 To 10', value: this.quotegammasip.numPortingSecondaryMultiLine1To10 })
        secondaryProviderProperties.entries.push({ key: 'Num Porting MultiLine Blocks Over 10', value: this.quotegammasip.numPortingSecondaryMultiLineBlocksOver10 })
      }

      /* ADD ENTRIES FOR GAMMA SIP
         List gamma only properties (listed against either the primary or secondary provider, depending which one is using gamma)
      */
      if (gammaProviderProperties !== null) {
        gammaProviderProperties.entries.push({ key: 'STCM Required', value: this.quotegammasip.stcmRequired ? 'Yes' : 'No' })

        if (this.quotegammasip.stcmRequired) {
          gammaProviderProperties.entries.push({ key: 'SIP Trunk Call Manager - Number of DDIs', value: this.quotegammasip.sipTrunkCallManager })
        }

        gammaProviderProperties.entries.push({ key: 'Semaphone Uplift', value: this.quotegammasip.semaphoneUplift ? 'Yes' : 'No' })
      }

      /* ADD GENERIC SIP ENTRIES */
      genericSipProperties.entries.push({ key: 'SBC Ports', value: this.quotegammasip.sbcPorts })
      genericSipProperties.entries.push({ key: 'Num Porting OOH Weekday Evening', value: this.quotegammasip.numPortingOohWeekdayEvening })
      genericSipProperties.entries.push({ key: 'Num Porting OOH Saturday', value: this.quotegammasip.numPortingOohSaturday })
      newService.propertySections.push(genericSipProperties)

      return newService
    },
    getSipMigrationProperties () {
      var newService = {
        serviceName: 'SIP Migration'
      }
      newService.propertySections = []

      var optionsSection = {
        name: 'Options',
        entries: []
      }

      optionsSection.entries.push({ key: 'Edgemarc Devices', value: this.quotesipmigrationproperties.numEdgemarcDevices })
      optionsSection.entries.push({ key: 'Survivability', value: this.quotesipmigrationproperties.survivability ? 'Yes' : 'No' })

      newService.propertySections.push(optionsSection)

      return newService
    },
    getPhonelinePlusProperties () {
      var newService = {
        serviceName: 'Phoneline+'
      }
      newService.propertySections = []

      var optionsSection = {
        name: 'Options',
        entries: []
      }

      optionsSection.entries.push({ key: 'Gamma Phoneline+', value: this.quotephonelineplus.gammaPhoneLineNumRequired })
      optionsSection.entries.push({ key: 'ATA Units', value: this.quotephonelineplus.ataUnitsNumRequired })
      optionsSection.entries.push({ key: 'Headsets', value: this.quotephonelineplus.plantronicsBlackwire5220USBNumRequired + this.quotephonelineplus.polyVoyager5200BluetoothHeadsetNumRequired + this.quotephonelineplus.sennheiserSC165USBNumRequired + this.quotephonelineplus.polycomVVX150NumRequired + this.quotephonelineplus.polycomVVX250NumRequired + this.quotephonelineplus.polycomVVX450NumRequired })

      newService.propertySections.push(optionsSection)

      return newService
    },
    getExpansionProperties () {
      var newService = {
        serviceName: 'Expansions'
      }
      newService.propertySections = []

      var standardVMsSection = {
        name: 'Standard VMs',
        entries: []
      }

      var customVMsSection = {
        name: 'Custom VMs',
        entries: []
      }

      this.quoteexpansions.expansionVirtualMachines.forEach(vm => {
        if (vm.virtualMachineId === null) {
          customVMsSection.entries.push({ key: this.displayExpansions_getServerNameForRequiredVirtualMachine(vm), value: vm.quantity })
        } else {
          standardVMsSection.entries.push({ key: this.displayExpansions_getServerNameForRequiredVirtualMachine(vm), value: vm.quantity })
        }
      })

      if (standardVMsSection.entries.length > 0) {
        newService.propertySections.push(standardVMsSection)
      }

      if (customVMsSection.entries.length > 0) {
        newService.propertySections.push(customVMsSection)
      }

      return newService
    },
    getIconGatewayProperties () {
      var newService = {
        serviceName: 'Icon Gateway'
      }
      newService.propertySections = []

      var rackUSection = {
        name: 'Rack U',
        entries: []
      }

      var sizeOfConnectionSection = {
        name: 'Size of Connection',
        entries: []
      }

      rackUSection.entries.push({ key: 'Fareham', value: this.icongateway.options.farehamRackU })
      rackUSection.entries.push({ key: 'Goswell Road', value: this.icongateway.options.goswellRackU })
      sizeOfConnectionSection.entries.push({ key: 'Fareham', value: this.icongateway.options.farehamConnectionSize })
      sizeOfConnectionSection.entries.push({ key: 'Goswell Road', value: this.icongateway.options.goswellConnectionSize })

      newService.propertySections.push(rackUSection)
      newService.propertySections.push(sizeOfConnectionSection)

      return newService
    },
    getAuraProperties () {
      var newService = {
        serviceName: 'Avaya Aura'
      }
      newService.propertySections = []

      var userProfilesSection = {
        name: 'User Profiles',
        entries: []
      }

      var hardwareSection = {
        name: 'Hardware',
        entries: []
      }

      newService.propertySections.push(userProfilesSection)

      /*
      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)
      */

      this.aura.userProfiles.forEach(userProfile => {
        if (userProfile.minCommitment > 0) {
          userProfilesSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
        }
      })

      this.aura.hardware.forEach(hardware => {
        if (hardware.quantity > 0) {
          hardwareSection.entries.push({ key: hardware.description, value: hardware.quantity })
        }
      })

      if (hardwareSection.entries.length > 0) {
        newService.propertySections.push(hardwareSection)
      }

      return newService
    },
    getEliteProperties () {
      var newService = {
        serviceName: 'Avaya Elite'
      }
      newService.propertySections = []

      var userProfilesSection = {
        name: 'User Profiles',
        entries: []
      }

      newService.propertySections.push(userProfilesSection)

      /*
      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)
      */

      this.elite.userProfiles.forEach(userProfile => {
        if (userProfile.minCommitment > 0) {
          userProfilesSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
        }
      })

      return newService
    },
    getAACCProperties () {
      var newService = {
        serviceName: 'Avaya AACC'
      }
      newService.propertySections = []

      var userProfilesSection = {
        name: 'User Profiles',
        entries: []
      }

      newService.propertySections.push(userProfilesSection)

      /*
      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)
      */

      this.aacc.userProfiles.forEach(userProfile => {
        if (userProfile.minCommitment > 0) {
          userProfilesSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
        }
      })

      return newService
    },
    getMitelCCProperties () {
      var newService = {
        serviceName: 'Mitel CC'
      }
      newService.propertySections = []

      var userProfilesSection = {
        name: 'User Profiles',
        entries: []
      }

      newService.propertySections.push(userProfilesSection)

      /*
      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)
      */

      this.mitelcc.userProfiles.forEach(userProfile => {
        if (userProfile.minCommitment > 0) {
          userProfilesSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
        }
      })

      return newService
    },
    getMitelUCProperties () {
      var newService = {
        serviceName: 'Mitel UC'
      }
      newService.propertySections = []

      var userProfilesSection = {
        name: 'User Profiles',
        entries: []
      }

      newService.propertySections.push(userProfilesSection)

      /*
      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)
      */

      this.miteluc.userProfiles.forEach(userProfile => {
        if (userProfile.minCommitment > 0) {
          userProfilesSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
        }
      })

      return newService
    },
    getTeamsProperties () {
      var newService = {
        serviceName: 'Teams'
      }
      newService.propertySections = []

      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)

      optionsSection.entries.push({ key: 'Num Teams Users', value: this.quoteteamsconnector.numTeamsUsers })
      optionsSection.entries.push({ key: 'Num PBX Users', value: this.quoteteamsconnector.numPBXUsers })

      return newService
    },
    getSycurioProperties () {
      var newService = {
        serviceName: 'Sycurio'
      }
      newService.propertySections = []

      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)

      optionsSection.entries.push({ key: 'Num Essentials Users', value: this.sycurio.options.numEssentialsUsers })
      optionsSection.entries.push({ key: 'Num PSP Providers', value: this.sycurio.options.numPSPProviders })

      return newService
    },
    getUCAnalyticsProperties () {
      var newService = {
        serviceName: 'UC Analytics'
      }
      newService.propertySections = []

      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)

      optionsSection.entries.push({ key: 'Num Essentials Users', value: this.ucAnalytics.options.numEssentialsUsers })
      optionsSection.entries.push({ key: 'Num Plus Users', value: this.ucAnalytics.options.numPlusUsers })
      optionsSection.entries.push({ key: 'Num Tailored Users', value: this.ucAnalytics.options.numTailoredUsers })
      optionsSection.entries.push({ key: 'Is Proof Of Concept?', value: this.ucAnalytics.options.isProofOfConcept })

      return newService
    },
    getGenesysProperties () {
      var newService = {
        serviceName: 'Genesys'
      }
      newService.propertySections = []

      var userProfilesSection = {
        name: 'User Profiles',
        entries: []
      }

      newService.propertySections.push(userProfilesSection)

      /*
      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)
      */

      this.genesys.userProfiles.forEach(userProfile => {
        if (userProfile.minCommitment > 0) {
          userProfilesSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
        }
      })

      return newService
    },
    getAvayaAXPProperties () {
      var newService = {
        serviceName: 'Avaya AXP'
      }
      newService.propertySections = []

      var userProfilesSection = {
        name: 'User Profiles',
        entries: []
      }

      newService.propertySections.push(userProfilesSection)

      this.avayaAXP.userProfiles.filter(x => x.userProfileCategoryId === this.iQuoteConstants.AvayaAXPUserProfileCategories.PROFILES).forEach(userProfile => {
        if (userProfile.minCommitment > 0) {
          userProfilesSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
        }
      })

      var avayaElite = this.avayaAXP.userProfiles.filter(x => x.userProfileCategoryId === this.iQuoteConstants.AvayaAXPUserProfileCategories.AVAYA_ELITE &&
                                                         x.minCommitment > 0)

      if (avayaElite.length > 0) {
        var avayaEliteSection = {
          name: 'Avaya Elite',
          entries: []
        }

        newService.propertySections.push(avayaEliteSection)

        avayaElite.forEach(userProfile => {
          avayaEliteSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
        })
      }

      var addons = this.avayaAXP.userProfiles.filter(x => x.userProfileCategoryId === this.iQuoteConstants.AvayaAXPUserProfileCategories.ADDONS &&
                                                         x.minCommitment > 0)

      if (addons.length > 0) {
        var addOnsSection = {
          name: 'Add-Ons',
          entries: []
        }

        newService.propertySections.push(addOnsSection)

        addons.forEach(userProfile => {
          if (userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.INTERACTION_DATA_EXPORT_MANAGER) {
            addOnsSection.entries.push({ key: userProfile.description, value: 'Yes' })
          } else {
            addOnsSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
          }
        })
      }

      /*
      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)
      */

      return newService
    },
    getRingCentralProperties () {
      var newService = {
        serviceName: 'Ring Central'
      }

      newService.propertySections = []

      if (this.ringCentral.ucTechId !== undefined &&
        this.ringCentral.ucUserProfiles?.length) {
        var ucUserProfilesSection = {
          name: 'UC User Profiles',
          entries: []
        }
        this.ringCentral.ucUserProfiles.forEach(userProfile => {
          if (userProfile.minCommitment > 0) {
            ucUserProfilesSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
          }
        })

        if (ucUserProfilesSection.entries.length > 0) {
          newService.propertySections.push(ucUserProfilesSection)
        }
      }

      if (this.ringCentral.ccTechId !== undefined &&
        this.ringCentral.ccUserProfiles?.length) {
        var ccUserProfilesSection = {
          name: 'CC User Profiles',
          entries: []
        }
        this.ringCentral.ccUserProfiles.forEach(userProfile => {
          if (userProfile.minCommitment > 0) {
            ccUserProfilesSection.entries.push({ key: userProfile.description, value: userProfile.minCommitment })
          }
        })

        if (ccUserProfilesSection.entries.length > 0) {
          newService.propertySections.push(ccUserProfilesSection)
        }
      }

      /*
      var optionsSection = {
        name: 'Options',
        entries: []
      }

      newService.propertySections.push(optionsSection)
      */

      return newService
    },
    filterUCUserProfiles () {
      let ucUserProfiles = []
      if (this.ringCentral.ucUserProfiles?.length) {
        ucUserProfiles = this.ringCentral.ucUserProfiles.filter(x => x.minCommitment > 0)
      }
      return ucUserProfiles
    },
    filterCCUserProfiles () {
      let ccUserProfiles = []
      if (this.ringCentral.ccUserProfiles?.length) {
        ccUserProfiles = this.ringCentral.ccUserProfiles.filter(x => x.minCommitment > 0)
      }
      return ccUserProfiles
    }
  }
}
</script>

<style scoped>
  .quoteSummarySectionContainer{
    width:590px;
    margin-bottom:10px;
    display: inline-block;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    padding: 20px;
    background-color: white;
    z-index: 999;
    max-height: 620px;
  }

  .quoteSummarySectionContainer label{
    width:auto;
  }

  .serviceList{
    overflow: scroll;
    overflow-x: hidden;
    height: 350px;
    border: 1px solid silver;
    padding: 5px;
    margin-bottom: 15px;
  }

  .quoteSummaryDetails {
    margin-top:20px;
    margin-bottom: 30px;
  }

  label {
    font-weight: bold;
  }

  .floatLeft {
    float: left;
  }

  .floatRight {
    float: right;
  }

  .leftAlign {
    text-align: left;
  }

  table {
    text-align: left;
    margin-bottom: 30px;
  }

  h2 {
    text-align: left;
  }

  #btnEdit {
    margin-bottom: 50px;
  }

  .QuoteSummaryService {
    border: 2px solid black;
    border-radius: 5px;
    padding: 5px;
    width: 435px;
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }

  .quoteSummaryServiceHeader {
    font-size: 1.5em;
    border: 1px solid darkgreen;
    font-weight: bold;
    background-image: linear-gradient(to left, rgb(40, 54, 131), rgb(99, 180, 82));
    color: white;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    text-shadow: -1px 1px 2px #23430C,
            1px 1px 2px #23430C,
            1px -1px 0 #23430C,
            -1px -1px 0 #23430C;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }

  .QuoteSummaryServiceSection {
    margin-top: 25px;
    border: 1px solid silver;
    border-radius: 5px;
    padding-bottom: 15px;
  }

  .QuoteSummaryServiceSectionHeader {
    font-size: 1.0em;
    font-weight: bold;
    border-bottom: 1x solid black;
    margin-bottom: 15px;
    background-color: #080808;
    color: white;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .QuoteSummaryServiceSectionEntry {
    font-size: 0.8em;
    font-weight: normal;
  }

  .QuoteSummaryServiceSectionEntryKey {
    font-weight: bold;
    display: inline-block;
    width: 250px;
    margin-right: 20px;
    margin-left: 5px;
  }
</style>
