<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-sitehealth"
          primary-title
        >
          Site Health
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="The Site Health screen shows details of all of a companies sites and their associated alarms and open tickets."
              />
              <HelpSection
                instruction="1.) There are filters available to filter which sites are displayed."
                image-source="site_health_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Selecting a filter will return only sites with alarms at the selected state i.e. unresolved alarms."
                indentation="1"
                image-source="site_health_2.png"
              />
              <HelpSection
                instruction="2.) Selecting a site will display a side panel detailing the alarms against the selected site as well as the current tickets."
                image-source="site_health_3.png"
                indentation="0"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpSiteHealth',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
