import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'
import { mapActions } from 'vuex'

export const iQuoteCalculationsAvayaAuraMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
      quoteTechnologyAvayaAura: {},
      gammaSip: {},
      quote: {},
      licenses: {}
    }
  },
  computed: {
    auraCalcs_usersToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyAvayaAura.userProfiles.forEach(userProfile => {
        users += userProfile.maxAllowed
      })
      return users
    },
    auraCalcs_usersToLicense () {
      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyAvayaAura.userProfiles.forEach(userProfile => {
        users += userProfile.minCommitment
      })
      return users
    },
    auraCalcs_usersStandardOrPremiumToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyAvayaAura.userProfiles.forEach(userProfile => {
        if (userProfile.description === 'Standard' || userProfile.description === 'Premium') {
          users += userProfile.maxAllowed
        }
      })
      return users
    },
    auraCalcs_usersAttendantToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyAvayaAura.userProfiles.forEach(userProfile => {
        if (userProfile.description === 'Attendant') {
          users += userProfile.maxAllowed
        }
      })
      return users
    },
    auraCalcs_usersEntryToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyAvayaAura.userProfiles.forEach(userProfile => {
        if (userProfile.description === 'Entry') {
          users += userProfile.maxAllowed
        }
      })
      return users
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyAvayaAura'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    avayaAuraCalcs_calculate: function () {
      this.gammaSip = this.iQuoteStore_GetGammaSip

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined) {
        return
      }

      this.avayaAuraCalcs_calcVMs()
      this.avayaAuraCalcs_calcUserLicenses()
      this.avayaAuraCalcs_calcHardwareLicenses()
      this.avayaAuraCalcs_calcAdjunctLicenses()
      this.avayaAuraCalcs_calcUnitCosts()
    },
    avayaAuraCalcs_calcUnitCosts: function () {
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_PS_IOH_01, 10)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_PS_OOH_01, 5)

      var iconSecCertCostPerYear = 0
      var servers = this.iQuoteStore_GetQuoteVMsRequired.filter(x => (x.virtualMachineId === this.iQuoteConstants.virtualMachines.AURA_CM_SIMPLEX ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.AURA_CM_DUPLEX ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.SIP_SBC8_0_ASBCE) &&
                                                                         x.dataCentreId === this.iQuoteConstants.dataCentres.fareham
      )

      var serverCount = 0

      servers.forEach(server => {
        serverCount += server.quantity
      })

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.options.publicNameSpaceOptionId === this.iQuoteConstants.PublicNameSpaceOptions.MAINTEL_PROVIDES) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_UPFRONT, 1, null, this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_UPFRONT_COST)

        if (serverCount > 3) {
          iconSecCertCostPerYear = this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_COST_MAX
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_RECURRING, 1, iconSecCertCostPerYear, iconSecCertCostPerYear)
        } else {
          iconSecCertCostPerYear = this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_BASE_COST + (this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_COST_PER_SERVER * serverCount)
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_RECURRING, 1, iconSecCertCostPerYear, iconSecCertCostPerYear)
        }
      }

      // No longer required.  Ticket 3465 & 3426
      // this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.AVAYA_USAGE_MONITORING, 1)
    },
    avayaAuraCalcs_calcVMs: function () {
      var serverQuantity

      if (this.iQuoteStore_GetQuote.criticalAvailability) {
        serverQuantity = 2
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_CM_DUPLEX, this.iQuoteConstants.dataCentres.fareham, true, serverQuantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_CM_DUPLEX, this.iQuoteConstants.dataCentres.goswellRoad, true, serverQuantity)
      } else {
        serverQuantity = 1
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_CM_SIMPLEX, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_CM_SIMPLEX, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }

      // These VMs are required for every Avaya Aura Deployment
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SMGR8_1_PROFILE_2, this.iQuoteConstants.dataCentres.fareham, true, serverQuantity)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SMGR8_1_PROFILE_2, this.iQuoteConstants.dataCentres.goswellRoad, true, serverQuantity)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_WEBLM_8_1, this.iQuoteConstants.dataCentres.fareham, true, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_WEBLM_8_1, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_ADS, this.iQuoteConstants.dataCentres.fareham, true, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_ADS, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_MCSM, this.iQuoteConstants.dataCentres.fareham, true, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_MCSM, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_RFDC, this.iQuoteConstants.dataCentres.fareham, true, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_RFDC, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_VM_UVR, this.iQuoteConstants.dataCentres.fareham, true, 1)
      this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_VM_UVR, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)

      this.avayaAuraCalcs_calcAADSandSM_VMs()
      this.avayaAuraCalcs_calcAAMS_VMs()
      this.avayaAuraCalcs_calcIXMessaging_VMs()
      this.avayaAuraCalcs_calcAdjunct_VMs()
      this.avayaAuraCalcs_calcPsServer_VMs()
      this.avayaAuraCalcs_calcAesVMs()
    },
    avayaAuraCalcs_calcAADSandSM_VMs: function () {
      var usersToAllocate = this.auraCalcs_usersEntryToHandle + this.auraCalcs_usersStandardOrPremiumToHandle

      while (usersToAllocate > 0) {
        while (usersToAllocate >= 7001) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SM_PROFILE_4, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SM_PROFILE_4, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          if (this.auraCalcs_usersStandardOrPremiumToHandle > 0) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AADS_8_0_PROFILE_4, this.iQuoteConstants.dataCentres.fareham, true, 1)
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AADS_8_0_PROFILE_4, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          }
          usersToAllocate -= 10000
        }

        while (usersToAllocate >= 4501) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SM_PROFILE_3, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SM_PROFILE_3, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          if (this.auraCalcs_usersStandardOrPremiumToHandle > 0) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AADS_8_0_PROFILE_3, this.iQuoteConstants.dataCentres.fareham, true, 1)
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AADS_8_0_PROFILE_3, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          }
          usersToAllocate -= 7000
        }

        while (usersToAllocate >= 2001) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SM_PROFILE_2, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SM_PROFILE_2, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          if (this.auraCalcs_usersStandardOrPremiumToHandle > 0) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AADS_8_0_PROFILE_2, this.iQuoteConstants.dataCentres.fareham, true, 1)
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AADS_8_0_PROFILE_2, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          }
          usersToAllocate -= 4500
        }

        while (usersToAllocate >= 1) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SM_PROFILE_1, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_SM_PROFILE_1, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          if (this.auraCalcs_usersStandardOrPremiumToHandle > 0) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AADS_8_0_PROFILE_1, this.iQuoteConstants.dataCentres.fareham, true, 1)
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AADS_8_0_PROFILE_1, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          }
          usersToAllocate -= 2000
        }
      }
    },
    avayaAuraCalcs_calcAAMS_VMs: function () {
      var codecResourcesRequiredMultiplier = 1.0
      var SIPCallSetupOverhead = 1.1 // TODO: This overhead to cover call setup needs to be configurable
      var conferencingOverhead = 100 // TODO: This overhead to cover conferencing needs to be configurable
      var codecs = this.$store.getters.codecs
      var serverQuantity

      if (this.iQuoteStore_GetQuote.criticalAvailability) {
        serverQuantity = 2
      } else {
        serverQuantity = 1
      }

      var sipCodecId = this.gammaSip.sipCodecId

      for (let index = 0; index < codecs.length; index++) {
        const codec = codecs[index]

        if (codec.id === sipCodecId) {
          codecResourcesRequiredMultiplier = codec.resourcesRequiredMultiplier
        }
      }

      var mpuRequired = conferencingOverhead
      mpuRequired += this.numPrimarySipChannels * SIPCallSetupOverhead * codecResourcesRequiredMultiplier

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.recordingLicenses > 0 && this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.maxConcurrentCalls > 0) {
        if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.maxConcurrentCalls * 3 > this.numPrimarySipChannels) {
          mpuRequired += (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.maxConcurrentCalls * 3) - this.numPrimarySipChannels
        }
      }

      while (mpuRequired > 0) {
        while (mpuRequired > 1100) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AAMS_PROFILE_4, this.iQuoteConstants.dataCentres.fareham, true, serverQuantity)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AAMS_PROFILE_4, this.iQuoteConstants.dataCentres.goswellRoad, true, serverQuantity)
          mpuRequired -= 1100
        }

        while (mpuRequired > 0) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AAMS_PROFILE_2, this.iQuoteConstants.dataCentres.fareham, true, serverQuantity)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AAMS_PROFILE_2, this.iQuoteConstants.dataCentres.goswellRoad, true, serverQuantity)
          mpuRequired -= 550
        }
      }

      if (this.auraCalcs_usersAttendantToHandle > 0) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AAMS_PROFILE_2, this.iQuoteConstants.dataCentres.fareham, true, serverQuantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AAMS_PROFILE_2, this.iQuoteConstants.dataCentres.goswellRoad, true, serverQuantity)
      }
    },
    avayaAuraCalcs_calcPsServer_VMs: function () {
      var usersToAllocate = this.auraCalcs_usersStandardOrPremiumToHandle

      if (usersToAllocate === 0) {
        return
      }

      if (usersToAllocate <= 1000) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_APS_8_1_PROFILE_3, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_APS_8_1_PROFILE_3, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (usersToAllocate <= 5000) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_APS_8_1_PROFILE_4, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_APS_8_1_PROFILE_4, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (usersToAllocate <= 11000) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_APS_8_1_PROFILE_5, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_APS_8_1_PROFILE_5, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      }
    },
    avayaAuraCalcs_calcIXMessaging_VMs: function () {
      var usersToAllocate = this.auraCalcs_usersToHandle

      if (usersToAllocate === 0) {
        return
      }

      if (usersToAllocate <= 4800) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_IXMESSAGING_10_8_PROFILE_1, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_IXMESSAGING_10_8_PROFILE_1, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      } else if (usersToAllocate <= 10000) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_IXMESSAGING_10_8_PROFILE_2, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_IXMESSAGING_10_8_PROFILE_2, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      }
    },
    avayaAuraCalcs_calcAdjunct_VMs: function () {
      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.recordingLicenses > 0) {
        const hoursPerGb = 18.2
        const minutesPerGb = hoursPerGb * 60
        const daysPerYear = 260
        const recordingSpacePerMinute = 1 / minutesPerGb

        var storage = this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.maxConcurrentCalls *
                      this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.numCallsPerAgentPerDay *
                      this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.avgCallDuration *
                      recordingSpacePerMinute *
                      ((this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.retentionPeriod / 12) * daysPerYear)

        if (storage > 2000) {
          storage = storage / 5
        }
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.virtualMachineId, this.iQuoteConstants.dataCentres.fareham, true, 1, 0, storage)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.virtualMachineId, this.iQuoteConstants.dataCentres.goswellRoad, true, 1, 0, storage, false, true)
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.rightfax) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.RIGHTFAX, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.RIGHTFAX, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.dataTrackCallLogging) {
        // TODO: don't appear to be any VMs for this?
      }
    },
    avayaAuraCalcs_calcAdjunctLicenses: function () {
      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined) {
        return
      }

      this.licenses = this.$store.getters.licenses

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.recordingLicenses > 0) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AURA_ASC_RECORDING_LICENSES, this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.recordingLicenses)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AURA_ASC_QUALITY_MONITORING, this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.qualityMonitoring)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AURA_ASC_SPEECH_ANALYTICS_KEYWORD_SPOTTING, this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.keywordSpotting)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AURA_ASC_SPEECH_ANALYTICS_TRANSCRIPTION, this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.transcription)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AURA_ASC_SPEECH_ANALYTICS_ENGINE_ENABLING, this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.engineEnabling)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AURA_ASC_CONNECT_FOR_SALESFORCE, this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.connectForSalesforce)
        if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.contactCentreIntegration) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.AURA_ASC_CONTACT_CENTRE_INTEGRATION, this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.recordingLicenses)
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.dataTrackCallLogging) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DATATRACK_CDR, 1)

        if (this.auraCalcs_usersToLicense > 5001) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DATATRACK_TIER3, this.auraCalcs_usersToLicense)
        } else if (this.auraCalcs_usersToLicense >= 2501) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DATATRACK_TIER1, this.auraCalcs_usersToLicense)
        } else if (this.auraCalcs_usersToLicense >= 1000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.DATATRACK_TIER1, this.auraCalcs_usersToLicense)
        }
      }
    },
    avayaAuraCalcs_calcHardwareLicenses: function () {
      this.licenses = this.$store.getters.licenses
      var requiredLicenseId = 0

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined) {
        return
      }

      var quoteTechnologyAvayaAura = this.iQuoteStore_GetQuoteTechnologyAvayaAura

      quoteTechnologyAvayaAura.hardware.forEach(hardware => {
        hardware.annualPrice = 0

        switch (hardware.technologyHardwareId) {
          case this.iQuoteConstants.hardware.AVAYA_HANDSET_J129:
            requiredLicenseId = this.iQuoteConstants.licenses.AVAYA_HANDSET_J129
            break
          case this.iQuoteConstants.hardware.AVAYA_HANDSET_J139:
            requiredLicenseId = this.iQuoteConstants.licenses.AVAYA_HANDSET_J139
            break
          case this.iQuoteConstants.hardware.AVAYA_HANDSET_J169:
            requiredLicenseId = this.iQuoteConstants.licenses.AVAYA_HANDSET_J169
            break
          case this.iQuoteConstants.hardware.AVAYA_HANDSET_J179:
            requiredLicenseId = this.iQuoteConstants.licenses.AVAYA_HANDSET_J179
            break
        }

        if (requiredLicenseId !== 0) {
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, hardware.quantity)
          hardware.annualPrice = hardware.quantity * this.utilFunctions_GetLicensePrice(requiredLicenseId) * 12
        }
      })

      this.setQuoteTechnologyAvayaAura(quoteTechnologyAvayaAura)
    },
    avayaAuraCalcs_calcAesVMs () {
      var usersToAllocate = 0

      var quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia
      var quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC
      var quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite

      if (quoteTechnologyCallmedia.id !== undefined) {
        usersToAllocate += 1

        quoteTechnologyCallmedia.userProfiles.forEach(userProfile => {
          usersToAllocate += userProfile.maxAllowed
        })
      }

      if (quoteTechnologyAvayaAACC.id !== undefined) {
        usersToAllocate += 1

        quoteTechnologyAvayaAACC.userProfiles.forEach(userProfile => {
          usersToAllocate += userProfile.maxAllowed
        })
      }

      if (quoteTechnologyAvayaElite.id !== undefined) {
        usersToAllocate += 1

        quoteTechnologyAvayaElite.userProfiles.forEach(userProfile => {
          usersToAllocate += userProfile.maxAllowed
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.adjuncts.recordingLicenses > 0) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_1, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_1, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      }

      if ((this.iQuoteStore_GetQuoteTechnologyAvayaElite.id !== undefined && this.iQuoteStore_GetQuoteTechnologyAvayaElite.adjuncts.maxConcurrentCalls > 0) ||
            (this.iQuoteStore_GetQuoteTechnologyAvayaAACC.id !== undefined && this.iQuoteStore_GetQuoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls > 0)) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_1, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_1, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      }

      while (usersToAllocate >= 6000) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_3, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_3, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        usersToAllocate -= 12000
      }

      while (usersToAllocate >= 5000) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_2, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_2, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        usersToAllocate -= 6000
      }

      while (usersToAllocate >= 1) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_1, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.AURA_AES_8_0_PROFILE_1, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        usersToAllocate -= 5000
      }
    },
    avayaAuraCalcs_calcUserLicenses: function () {
      this.licenses = this.$store.getters.licenses
      var requiredLicenseId = 0

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined) {
        return
      }

      var quoteTechnologyAvayaAura = this.iQuoteStore_GetQuoteTechnologyAvayaAura

      quoteTechnologyAvayaAura.userProfiles.forEach(userProfile => {
        userProfile.annualPrice = 0
        requiredLicenseId = 0

        switch (userProfile.description) {
          case 'Entry':
            if (this.auraCalcs_usersToLicense > 5000) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER3_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER3_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER3_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER3_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER3_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER3_PREMIUM_SURRENDER
                }
              }
            } else if (this.auraCalcs_usersToLicense > 2500) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER2_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER2_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER2_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER2_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER2_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER2_PREMIUM_SURRENDER
                }
              }
            } else if (this.auraCalcs_usersToLicense >= 1000) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER1_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER1_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER1_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER1_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER1_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ENTRY_TIER1_PREMIUM_SURRENDER
                }
              }
            }
            break
          case 'Standard':
            if (this.auraCalcs_usersToLicense > 5000) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER3_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER3_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER3_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER3_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER3_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER3_PREMIUM_SURRENDER
                }
              }
            } else if (this.auraCalcs_usersToLicense > 2500) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER2_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER2_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER2_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER2_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER2_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER2_PREMIUM_SURRENDER
                }
              }
            } else if (this.auraCalcs_usersToLicense >= 1000) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER1_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER1_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER1_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER1_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER1_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_STANDARD_TIER1_PREMIUM_SURRENDER
                }
              }
            }
            break
          case 'Premium':
            if (this.auraCalcs_usersToLicense > 5000) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER3_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER3_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER3_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER3_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER3_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER3_PREMIUM_SURRENDER
                }
              }
            } else if (this.auraCalcs_usersToLicense > 2500) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER2_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER2_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER2_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER2_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER2_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER2_PREMIUM_SURRENDER
                }
              }
            } else if (this.auraCalcs_usersToLicense >= 1000) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER1_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER1_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER1_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER1_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER1_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_PREMIUM_TIER1_PREMIUM_SURRENDER
                }
              }
            }
            break
          case 'Attendant':
            if (this.auraCalcs_usersToLicense > 5000) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER3_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER3_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER3_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER3_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER3_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER3_PREMIUM_SURRENDER
                }
              }
            } else if (this.auraCalcs_usersToLicense > 2500) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER2_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER2_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER2_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER2_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER2_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER2_PREMIUM_SURRENDER
                }
              }
            } else if (this.auraCalcs_usersToLicense >= 1000) {
              if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER1_STANDARD_SUBSCRIPTION
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER1_PREMIUM_SUBSCRIPTION
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER1_STANDARD_PERPETUAL
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER1_PREMIUM_PERPETUAL
                }
              } else if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === this.iQuoteConstants.quoteLicenceTypes.SURRENDER) {
                if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER1_STANDARD_SURRENDER
                } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
                  requiredLicenseId = this.iQuoteConstants.licenses.AURA_ATTENDANT_TIER1_PREMIUM_SURRENDER
                }
              }
            }
            break
        }

        if (requiredLicenseId !== 0) {
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
          userProfile.annualTotalCost = userProfile.minCommitment * this.utilFunctions_GetLicenseCost(requiredLicenseId) * 12
          userProfile.annualPrice = userProfile.minCommitment * this.utilFunctions_GetLicensePrice(requiredLicenseId) * 12
          userProfile.rate = this.utilFunctions_GetLicensePrice(requiredLicenseId)
        }
      })

      this.setQuoteTechnologyAvayaAura(quoteTechnologyAvayaAura)
    }
  }
}
