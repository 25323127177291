<template>
  <div v-once />
</template>

<script>
import { pleaseWait } from 'please-wait'
import 'please-wait/build/please-wait.css'

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    message: {
      type: String,
      default: ''
    }
  },
  computed: {
    maintelLogo () {
      return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.siteLogo}`
    },
    maintelSpinnerImage () {
      return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.spinnerImage}`
    }
  },
  watch: {
    isLoading: {
      handler (isLoading) {
        if (isLoading) {
          this.open()
        } else {
          this.close()
        }
      },
      immediate: true
    },
    message: {
      handler (message) {
        var loadingMessage = document.querySelector('.pg-loading-html .loading-message')
        if (loadingMessage) {
          loadingMessage.innerHTML = message
        }
      }
    }
  },
  methods: {
    open () {
      if (!this.pleaseWaitInstance) {
        this.pleaseWaitInstance = pleaseWait({
          logo: '',
          backgroundColor: '#fff',
          loadingHtml:
            `<img src="` + this.maintelLogo + `" />
            <br/>
            <br/>
            <br/>
            <h4 id="loadingMessage" class="loading-message display-1 font-weight-thin">` + this.message + `</h4>
            <div class="spinner">
              <img src="` + this.maintelSpinnerImage + `" />
            </div>`
        })
      }
    },
    close () {
      if (this.pleaseWaitInstance != null) {
        this.pleaseWaitInstance.finish()
        this.pleaseWaitInstance = null
      }
    }
  }
}
</script>
