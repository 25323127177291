<template>
  <div
    class="adcHolder"
  >
    <p class="adcText"> To approve <b>{{ request.firstName }} {{ request.lastName }}</b> for company <b>{{ requestedCompanyName }}</b>, press the Approve button below:</p>
    <div v-if="request.companyId === 0">
      <p class="adcText">
        The user is to be added to company: <b>{{ request.companyName }}</b>. Please select the correct company from the box below:
      </p>
      <select
        class="companySelect"
        @change="setCompany">
        <option
          v-if="selectedCompanyId === 0"
          key="0"
          value=""
          title="Please select a compamy"
        >Please Select
        </option>
        <option
          v-for="company in companies"
          :key="company.id"
          :value="company.id"
        >{{ company.name }}</option>
      </select>
    </div>
    <br>
    <input
      :disabled="!selectedUser"
      value="Approve"
      type="button"
      title="Approve access request"
      class="adcButton customerUpdateButton"
      @click="approveAccess()"
    >
    <br>
  </div>
</template>
<script>

import { usersMixin } from '../../../mixins/users'
import { adminMixin } from '../../../mixins/adminMixin'
import PortalDropdown from '../../common/portalDropdown'

export default {
  components: {
    PortalDropdown
  },
  mixins: [
    usersMixin,
    adminMixin
  ],
  props: {
    request: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedUser: {},
      selectedCompanyId: 0
    }
  },
  computed: {
    companies () {
      return this.$store.getters.companies
    },
    requestedCompanyName () {
      if (this.request.companyId > 0) {
        var co = this.companies.filter(company => company.id === this.request.companyId)
        return co[0].name
      } else {
        return this.request.companyName
      }
    }
  },
  watch: {
    request () {
      this.selectedCompanyId = 0
    }
  },
  methods: {
    approveAccess: async function () {
      var companyId = this.request.companyId
      if (companyId === 0) {
        companyId = this.selectedCompanyId
      }
      if (companyId > 0) {
        var payload = '{"FirstName":"' + this.request.firstName + '", ' +
          '"LastName":"' + this.request.lastName + '", ' +
          '"EmailAddress":"' + this.request.emailAddress + '", ' +
          '"CompanyId":' + companyId + ', ' +
          '"Url":"' + window.location.origin + '/' + '", ' +
          '"Approved":true}'
        this.$store.commit('showMessage', { content: 'Processing the request access for ' + this.request.emailAddress + ', please wait...', color: 'blue', timeout: 3000 })
        var rtn = await this.approveAccessRequest(payload)
        if (rtn.length === 0) {
          this.$store.commit('showMessage', { content: `The request access for '${this.request.emailAddress}' has been approved successfully!`, color: 'green', timeout: 3000 })
        } else {
          this.$store.commit('showMessage', { content: 'An error occurred processing the access request for ' + this.request.emailAddress + ': ' + rtn, color: 'red', timeout: 5000 })
        }
      } else {
        this.$store.commit('showMessage', { content: 'No company is selected for the access request', color: 'red', timeout: 3000 })
      }
      this.$emit('updated')
    },
    setCompany: function (elm) {
      this.selectedCompanyId = elm.target.value
    }
  }
}
</script>
<style scoped>
  .adcButton{
    width:140px;
    border: solid 1px grey;
    color: grey;
  }
  .adcSubHeader{
    padding-left:10px;
    display: inline-block;
    width: 220px;
  }
  .adcHolder {
    background-color: rgba(255,255,255,0.7);
    margin-bottom: 10px;
    padding: 10px 0;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    padding: 10px 5px;
    display: none;
    text-align: right;
    width:100%;
  }
  .adcText {
    text-align: left;
  }
  .customerOnboardingDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .customerOnboardingDetailInput {
    width: 254px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .customerUpdateButton {
    cursor: pointer;
  }
  .companySelect {
    width: 380px;
    background-color: #ddd;
    border: 1px solid #999;
    padding: 5px;
    margin-right: 5px;
    font-weight: bold;
    -moz-appearance:menulist!important; /* Firefox */
    -webkit-appearance: menulist!important;
    appearance: menulist!important;
  }
</style>
