<template>
  <div
    :id="id"
    class="companyHolder"
    @keydown.esc="closeForms"
  >
    <div :class="createBanner">
      <CreateEmailSuffixComponent
        @emailSuffixCreated="() => { handleEmailSuffixChanged() }"
      />
      <Spinner v-if="loading" />
      <TextButton
        v-if="createCompanyPermission && !$store.getters.openMainForm"
        :button-tabindex="10"
        button-style="float: right;margin-bottom: 10px;;width:160px;"
        button-id="btnNewEmailSuffix"
        title="Add a new email suffix"
        text="Add Email Suffix"
        @click="openNewEmailSuffix"
      />
    </div>
    <div
      v-if="!loading && !$store.getters.openMainForm"
      :class="sectionClass"
    >
      <table
        id="emailSuffixesTable"
        style="margin-bottom: 15px;"
      >
        <thead>
          <tr>
            <th style="width:120px;">Company ID</th>
            <th style="width:55%">Company Name</th>
            <th style="width:35%">Email Suffix</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="3">
              <Spinner />
            </td>
          </tr>
          <tr v-if="currentFilter.length === 0 && !loading">
            <td
              colspan="3"
            >There are no email suffixes in the database that are not associated with a company</td>
          </tr>
          <tr
            v-for="s in currentFilter"
            :key="s.id"
            @click="selectEmailSuffixFromGrid(s)"
          >
            <td>{{ s.companyId }}</td>
            <td>{{ s.companyName }}</td>
            <td>{{ s.emailSuffix }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="emailsuffixid>0"
      class="mdSelection"
    >
      <SectionCollapsedHeader
        id="sectionUpdateEmailSuffix"
        icon-file="Edit"
        type="subHeader"
        title="Update Email Suffix"
        controls="updateEmailSuffixComponent"
      />
      <UpdateEmailSuffixComponent
        id="updateEmailSuffixComponent"
        :emailsuffixid="emailsuffixid"
        :companyid="companyid"
        :emailsuffix="emailsuffix"
        :companyname="companyname"
        @emailSuffixUpdated="() => { handleEmailSuffixChanged() }"
      />
      <SectionCollapsedHeader
        id="sectionDeleteEmailSuffix"
        icon-file="Trashgreen"
        type="subHeader"
        title="Delete Email Suffix"
        controls="deleteEmailSuffixComponent"
      />
      <DeleteEmailSuffixComponent
        id="deleteEmailSuffixComponent"
        :emailsuffixid="emailsuffixid"
        :companyid="companyid"
        :emailsuffix="emailsuffix"
        :companyname="companyname"
        @emailSuffixDeleted="() => { handleEmailSuffixChanged() }"
      />
    </div>
    <Spinner v-if="isSaving" />
    <div class="bottombar" >
      <Spinner v-if="isSaving" />
      <input
        v-if="!isSaving && !$store.getters.openMainForm"
        id="cancelEmailSuffixButton"
        class="cancelEmailSuffixButton"
        type="button"
        title="Close the email suffix screen"
        @click="closeEmailSuffixForm()"
      >
    </div>
  </div>
</template>
<script>
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'
import TextButton from '../../common/textButton.vue'
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions } from 'vuex'
import tableHandler from '../../../mixins/tableHandler'
import Spinner from '../../common/spinner'
import UpdateEmailSuffixComponent from './updateEmailSuffixComponent'
import DeleteEmailSuffixComponent from './deleteEmailSuffixComponent'
import CreateEmailSuffixComponent from './createEmailSuffixComponent'
export default {
  name: 'EmailSuffixes',
  components: {
    Spinner,
    SectionCollapsedHeader,
    UpdateEmailSuffixComponent,
    DeleteEmailSuffixComponent,
    CreateEmailSuffixComponent,
    TextButton
  },
  mixins: [
    permissionsMixin,
    tableHandler
  ],
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      formOK: false,
      emailSuffixError: false,
      isSaving: false,
      sectionClass: 'fullSection',
      emailsuffixid: 0,
      companyid: 0,
      emailsuffix: '',
      companyname: '',
      showNew: false
    }
  },
  computed: {
    editCompanyPermission () {
      return this.$store.getters.editCompanyPermission
    },
    companies () {
      return this.$store.getters.companies
    },
    createCompanyPermission () {
      return this.$store.getters.createCompanyPermission
    },
    createBanner () {
      if (this.showNew) {
        return 'createBannerShow'
      } else {
        return 'createBannerHide'
      }
    }
  },
  watch: {
    id () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setMainForm'
    ]),
    async setComponent () {
      var emailSuffixes = await this.getAllCompanyEmailSuffixes()
      var data = this.setCompanyNames(emailSuffixes)
      this.setTable(data)
    },
    setCompanyNames (emailSuffixes) {
      var data = []
      if (emailSuffixes) {
        emailSuffixes.forEach(es => {
          var record = {}
          record.id = es.id
          record.companyId = es.companyId
          record.emailSuffix = es.emailSuffix
          var company = this.companies.find(c => c.id === es.companyId)
          if (company) {
            record.companyName = company.name
          } else {
            record.companyName = 'Unknown'
          }
          data.push(record)
        })
      }
      return data
    },
    setTable (data) {
      this.initialiseTable(
        'emailSuffixesTable',
        data,
        ['companyId', 'companyName', 'emailSuffix'],
        ['Company ID', 'Company Name', 'Email Suffix'],
        null,
        '528px',
        ['120px', '55%', '35%'],
        15
      )
    },
    selectEmailSuffixFromGrid (companyEmailSuffix) {
      if (this.emailsuffixid === companyEmailSuffix.id) {
        this.emailsuffixid = 0
        this.companyid = 0
        this.emailsuffix = ''
        this.companyname = ''
        this.sectionClass = 'fullSection'
      } else {
        this.emailsuffixid = companyEmailSuffix.id
        this.companyid = companyEmailSuffix.companyId
        this.emailsuffix = companyEmailSuffix.emailSuffix
        this.companyname = companyEmailSuffix.companyName
        this.sectionClass = 'halfSection'
      }
    },
    handleEmailSuffixChanged () {
      this.showNew = false
      this.emailsuffixid = 0
      this.sectionClass = 'fullSection'
      this.setComponent()
    },
    openNewEmailSuffix () {
      this.emailsuffixid = 0
      this.sectionClass = 'fullSection'
      this.showNew = true
      this.setMainForm(true)
    },
    closeEmailSuffixForm () {
      this.$emit('setTileMode')
    }
  }
}
</script>
<style scoped>
  .companyHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    overflow-y: auto;
  }
  .createBannerHide {
    width:100%;
    height: 40px;
  }
  .createBannerShow {
    width:100%;
    height: calc(100vh - 160px);
  }
  .emailSuffixData {
    width: 250px;
    display: inline-block;
  }
  .emailSuffixLabel {
    width: 130px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .emailSuffixInput {
    width: 244px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .suffixUpdateButton {
    float:right;
    cursor: pointer;
  }
  .suffixUpdateButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .cancelEmailSuffixButton {
    background-image: url('../../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
    margin-top: -4px;
  }
  .halfSection {
    display: inline-block;
    width:calc(100% - 420px);
    padding-left:5px;
  }
  .fullSection {
    display: inline-block;
    width:100%;
    padding-left:5px;
  }
</style>
