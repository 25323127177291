// This store has been created to act as a top level store for all the sub stores.  Common tasks which are not service specific (i.e. auth) should be added here
// service specific state management should be added to a separate file and added in the modules object below.
import Vue from 'vue'
import Vuex from 'vuex'
import appStore from './modules/appStore'
import authStore from './modules/authStore'
import autotaskStore from './modules/autotaskStore'
import userStore from './modules/userStore'
import ticketStore from './modules/ticketStore'
import sitesCircuitsStore from './modules/sitesCircuitsStore'
import dashboardStore from './modules/dashboardStore'
import jemStore from './modules/jemStore'
import stormStore from './modules/stormStore'
import channelConfigurationStore from './modules/channelConfigurationStore'
import navigationStore from './modules/navigationStore'
import permissionsStore from './modules/permissionsStore'
import iQuoteVariableStore from './modules/iQuoteVariableStore'
import iQuoteUserDataStore from './modules/iQuoteUserDataStore'
import iQuoteQuoteDataStore from './modules/iQuoteQuoteDataStore'
import iQuoteStaticDataStore from './modules/iQuoteStaticDataStore'
import Snackbar from './modules/snackbar'
import ConfirmationSnackbar from './modules/confirmationSnackbar'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    appStore,
    authStore,
    autotaskStore,
    userStore,
    ticketStore,
    sitesCircuitsStore,
    dashboardStore,
    jemStore,
    stormStore,
    channelConfigurationStore,
    navigationStore,
    permissionsStore,
    iQuoteVariableStore,
    iQuoteUserDataStore,
    iQuoteQuoteDataStore,
    iQuoteStaticDataStore,
    Snackbar,
    ConfirmationSnackbar
  }
})
