<template>
  <div>
    <div
      :class="{ tabStrip: hasLine, tabStripNoLine: !hasLine }">
      <div
        v-for="tabItem in tabs"
        :key="tabItem.name"
        :id="tabItem.name"
        :class="{selected: selectedTab === tabItem.name, tabNavItem: hasLine, tabNavItemNoMarginTop: !hasLine }"
        @click="setSelectedTab(tabItem)"
      >
        {{ tabItem.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  // eslint-disable-next-line
  name: 'TabStrip',
  props: {
    tabs: {
      type: Array,
      default: function () { return {} }
    },
    selectedTab: {
      type: String,
      default: function () { '' }
    },
    hasLine: {
      type: Boolean,
      default: function () { return true }
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    ...mapActions([
    ]),
    titleCase (str) {
      return str[0].toUpperCase() + str.substring(1, str.length)
    },
    setSelectedTab (item) {
      this.$emit('tabChanged', item)
    }
  }
}
</script>
<style scoped>

  .tabStrip {
    display: block;
    text-align: left;
    border-bottom: 1px solid black;
    color: white;
    height: 50px;
    margin-top: 15px;
    padding: 10px;
    margin-bottom: 25px;
  }
  .tabStripNoLine {
    display: block;
    text-align: left;
    color: white;
    height: 35px;
    padding: 10px;
  }

  .tabNavItem {
    border-radius: 15px 15px 0px 0px;
    border: 1px solid black;
    margin-top: 10px;
    margin-bottom: 0px;
    display: inline-block;
    background-color: gray;
    padding: 2px 10px;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
  }

  .tabNavItemNoMarginTop {
    border-radius: 15px 15px 0px 0px;
    border: 1px solid black;
    display: inline-block;
    background-color: gray;
    padding: 2px 10px;
    cursor: pointer;
  }
  .selected {
    border-bottom: 1px solid #63B452;
    background-color: black;
    font-weight: bold;
    box-shadow: 0 12px 20px -10px rgba(255, 255, 255, 0.44), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.33) !important;
  }

</style>
