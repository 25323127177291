<template>
  <div class="bottombar">
    <Spinner v-if="saving" />
    <!-- <input
      v-if="!isSaving"
      id="cancelUserButton"
      class="cancelUserButton"
      type="button"
      title="Cancel the update Dashboard Image"
      @click="closeUploadDashboardImageForm()"
    > -->
    <input
      v-if="!saving"
      id="submitButton"
      :disabled="disabled"
      :class="getButtonClass"
      :title="title"
      type="button"
      @click="()=>this.$emit('submit')"
    >
  </div>
</template>

<script>
import Spinner from '../common/spinner'

export default {
  name: 'FormButton',
  components: {
    Spinner
  },
  props: {
    saving: {
      type: Boolean,
      default: false
    },
    status: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    getButtonClass () {
      if (this.disabled) {
        return 'submitButtonDisabled'
      } else {
        return 'submitButton'
      }
    }
  }
}
</script>

<style scoped>
  .bottombar {
    text-align: right;
  }
  .submitButtonDisabled {
    background-image: url('../../assets/icons/check-tick-disabled.svg');
    cursor: default;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .submitButton {
    background-image: url('../../assets/icons/check-tick.svg');
    cursor: pointer;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .cancelUserButton {
    background-image: url('../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
  }
</style>
