import { mapActions } from 'vuex'
import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteQuoteTechnologyAPIMixin } from '../iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../iQuoteQuoteAPIMixin'
import { iQuoteQuoteExpansionsAPIMixin } from '../iQuoteQuoteExpansionsAPIMixin'
import { iQuoteQuoteDesignPackEntriesAPIMixin } from '../iQuoteQuoteDesignPackEntriesAPIMixin'
import { iQuoteFunctions } from '../iQuoteFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteCalculationsCommonMixin } from '../iQuoteCalculationsCommonMixin'

export const iQuoteLoaderMixin = {
  data () {
    return {
    }
  },
  mixins: [
    iQuoteConstants,
    iQuoteQuoteTechnologyAPIMixin,
    iQuoteQuoteAPIMixin,
    iQuoteQuoteExpansionsAPIMixin,
    iQuoteFunctions,
    iQuoteStoreMixin,
    iQuoteQuoteDesignPackEntriesAPIMixin,
    iQuoteCalculationsCommonMixin
  ],
  computed: {
  },
  methods: {
    ...mapActions([
      'setQuote',
      'setQuoteGammaSIP',
      'setQuoteTeamsConnector',
      'setQuoteGenesys',
      'setQuoteSIPMigrationProperties',
      'setQuotePhoneLinePlus',
      'setQuoteTechnologyAvayaAura',
      'setQuoteTechnologyCallmedia',
      'setQuoteTechnologyCallmediaCXNow',
      'setQuoteTechnologyIconGateway',
      'setQuoteTechnologyMitelCC',
      'setQuoteTechnologyMitelUC',
      'setQuoteTechnologyAvayaElite',
      'setQuoteTechnologyAvayaAACC',
      'setQuoteVMsRequired',
      'setQuoteLicensesRequired',
      'setQuoteBladeServers',
      'setQuoteUnitCosts',
      'setQuoteDesignPackEntries',
      'setQuoteExpansions',
      'setCurrentQuoteState',
      'setCurrentQuotePage',
      'setCurrentQuoteSubPage',
      'setQuoteRingCentral',
      'setCurrentQuoteExternalQuotesState',
      'setQuoteSycurio',
      'setQuoteUCAnalytics',
      'setQuoteAvayaAXP'
    ]),
    iQuoteLoaderMixin_loadQuoteIntoStore: async function (quoteId) {
      this.iQuoteLoaderMixin_clearQuoteDataFromStore() /* Clear adjuncts etc from previous quotes */

      await this.iQuoteLoaderMixin_getQuoteDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getAuraDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getSipDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getSipMigrationDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getCallmediaDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getCallmediaCXNowDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getAvayaEliteDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getAvayaAACCDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getMitelCCDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getMitelUCDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getIconGatewayDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getExpansionDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getTeamsConnectorDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getPhoneLinePlusDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getGenesysDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getRingCentralDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getSycurioDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getUCAnalyticsDetailsFromDB(quoteId)
      await this.iQuoteLoaderMixin_getAvayaAXPDetailsFromDB(quoteId)

      var previouslyCalculatedUnitCosts = await this.getQuoteUnitCostsFromDB()
      var previouslyCalculatedLicenses = await this.getQuoteLicensesFromDB()
      var previouslyCalculatedDesignPackEntries = await this.getDesignPackEntriesFromDB()

      this.setQuoteUnitCosts(previouslyCalculatedUnitCosts)
      this.setQuoteLicensesRequired(previouslyCalculatedLicenses)
      this.setQuoteDesignPackEntries(previouslyCalculatedDesignPackEntries)
    },
    iQuoteLoaderMixin_clearQuoteDataFromStore () {
      this.setQuote({})
      this.setQuoteTechnologyAvayaAura({})
      this.setQuoteTechnologyMitelUC({})
      this.setQuoteTechnologyMitelCC({})
      this.setQuoteTechnologyCallmedia({})
      this.setQuoteTechnologyCallmediaCXNow({})
      this.setQuoteTechnologyIconGateway({})
      this.setQuoteTechnologyAvayaElite({})
      this.setQuoteTechnologyAvayaAACC({})
      this.setQuoteGammaSIP({})
      this.setQuoteTeamsConnector({})
      this.setQuoteGenesys({})
      this.setQuoteSIPMigrationProperties({})
      this.setQuotePhoneLinePlus({})
      this.setQuoteExpansions({})
      this.setQuoteLicensesRequired([])
      this.setQuoteVMsRequired([])
      this.setQuoteBladeServers([])
      this.setQuoteUnitCosts([])
      this.setQuoteDesignPackEntries([])
      this.setQuoteRingCentral({})
      this.setQuoteSycurio({})
      this.setQuoteUCAnalytics({})
      this.setQuoteAvayaAXP({})

      var externalQuotesState = this.iQuoteStore_CurrentQuoteExternalQuotesState

      externalQuotesState.currentPage = 'rootPage'
      externalQuotesState.quoteId = 0
      externalQuotesState.quoteBoms = []
      externalQuotesState.selectedQuoteBom = {}
      externalQuotesState.quoteIconNow = {}
      externalQuotesState.quoteIconConnect = {}
      externalQuotesState.quoteManagedService = {}
      externalQuotesState.quoteNewManagedService = {}
      externalQuotesState.quoteCallsAndLines = {}
      externalQuotesState.quoteHorizon = {}
      externalQuotesState.quoteIconSecure = {}
      externalQuotesState.quoteSecureHomeworker = {}
      externalQuotesState.quoteTwilio = {}

      this.setCurrentQuoteExternalQuotesState(externalQuotesState)
    },
    iQuoteLoaderMixin_getQuoteDetailsFromDB: async function (quoteId) {
      var quote = await this.getQuoteById(quoteId)

      quote.marginAWS = 20 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
      quote.marginIconGateway = 20 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
      quote.marginInfrastructure = 30 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
      quote.marginGammaSip = 33.3333 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
      quote.marginSipMigration = 30 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
      quote.marginCxNowPS = 50 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */

      // quote.locked = false /* TODO: Remove this when we properly support locked quotes.  had to put this for now, as some quotes have been locked in DB */

      this.setQuote(quote)
    },
    iQuoteLoaderMixin_getSipDetailsFromDB: async function (quoteId) {
      var gammaSip = await this.getQuoteSIPProperties(quoteId)
      this.setQuoteGammaSIP(gammaSip)
    },
    iQuoteLoaderMixin_getTeamsConnectorDetailsFromDB: async function (quoteId) {
      var quoteTeamsConnector = await this.getQuoteTeamsConnectorProperties(quoteId)
      this.setQuoteTeamsConnector(quoteTeamsConnector)
    },
    iQuoteLoaderMixin_getSipMigrationDetailsFromDB: async function (quoteId) {
      var quoteSIPMigrationProperties = await this.getQuoteSIPMigrationProperties(quoteId)
      this.setQuoteSIPMigrationProperties(quoteSIPMigrationProperties)
    },
    iQuoteLoaderMixin_getPhoneLinePlusDetailsFromDB: async function (quoteId) {
      var quotePhoneLinePlus = await this.getQuotePhoneLinePlusProperties(quoteId)
      this.setQuotePhoneLinePlus(quotePhoneLinePlus)
    },
    iQuoteLoaderMixin_getExpansionDetailsFromDB: async function (quoteId) {
      var quoteExpansions = {}

      quoteExpansions.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.quoteExpansions)

      if (quoteExpansions.id === 0) {
        quoteExpansions = {}
      } else {
        var expansionVirtualMachines = await this.getQuoteExpansionVirtualMachinesFromQuoteId(quoteId)

        // Using $set, as directly assigning to the arrays was not updating the DOM
        this.$set(quoteExpansions, 'expansionVirtualMachines', expansionVirtualMachines)

        var vmCount = 0
        quoteExpansions.expansionVirtualMachines.forEach(vm => {
          vmCount += vm.quantity
        })

        if (vmCount === 0) {
          quoteExpansions = {}
        }
      }

      this.setQuoteExpansions(quoteExpansions)
    },
    iQuoteLoaderMixin_getAuraDetailsFromDB: async function (quoteId) {
      var quoteTechnologyAvayaAura = {}

      quoteTechnologyAvayaAura.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.avayaAura)

      if (quoteTechnologyAvayaAura.id === 0) {
        quoteTechnologyAvayaAura = {}
      } else {
        var userProfiles = await this.getQuoteTechnologyUserProfiles(quoteTechnologyAvayaAura.id)
        var hardware = await this.getQuoteTechnologyHardware(quoteTechnologyAvayaAura.id)
        var adjuncts = await this.getQuoteAuraAdjuncts(quoteId)
        var options = await this.getQuoteOptionsAvayaAura(quoteId)

        if (adjuncts.length === 0) {
          adjuncts = {}
        }

        // Using $set, as directly assigning to the arrays was not updating the DOM
        this.$set(quoteTechnologyAvayaAura, 'userProfiles', userProfiles)
        this.$set(quoteTechnologyAvayaAura, 'hardware', hardware)
        this.$set(quoteTechnologyAvayaAura, 'adjuncts', adjuncts)
        this.$set(quoteTechnologyAvayaAura, 'options', options)

        var userProfileCount = 0
        quoteTechnologyAvayaAura.userProfiles.forEach(userProfile => {
          userProfileCount += userProfile.minCommitment
        })

        if (userProfileCount === 0) {
          quoteTechnologyAvayaAura = {}
        }
      }

      this.setQuoteTechnologyAvayaAura(quoteTechnologyAvayaAura)
    },
    iQuoteLoaderMixin_getCallmediaDetailsFromDB: async function (quoteId) {
      var quoteTechnologyCallmedia = {}

      quoteTechnologyCallmedia.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.callmedia)

      if (quoteTechnologyCallmedia.id === 0) {
        quoteTechnologyCallmedia = {}
      } else {
        var userProfiles = await this.getQuoteTechnologyUserProfiles(quoteTechnologyCallmedia.id)
        var adjuncts = await this.getQuoteCallmediaAdjuncts(quoteId)
        var options = await this.getQuoteOptionsCallmedia(quoteId)

        // Using $set, as directly assigning to the arrays was not updating the DOM
        this.$set(quoteTechnologyCallmedia, 'userProfiles', userProfiles)
        this.$set(quoteTechnologyCallmedia, 'adjuncts', adjuncts)
        this.$set(quoteTechnologyCallmedia, 'options', options)

        var userProfileCount = 0
        quoteTechnologyCallmedia.userProfiles.forEach(userProfile => {
          userProfileCount += userProfile.minCommitment
        })

        if (userProfileCount === 0) {
          quoteTechnologyCallmedia = {}
        }
      }

      this.setQuoteTechnologyCallmedia(quoteTechnologyCallmedia)
    },
    iQuoteLoaderMixin_getCallmediaCXNowDetailsFromDB: async function (quoteId) {
      var quoteTechnologyCallmediaCXNow = {}

      quoteTechnologyCallmediaCXNow.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.callmediaCXNow)

      if (quoteTechnologyCallmediaCXNow.id === 0) {
        quoteTechnologyCallmediaCXNow = {}
      } else {
        var userProfiles = await this.getQuoteTechnologyUserProfiles(quoteTechnologyCallmediaCXNow.id)
        var adjuncts = await this.getQuoteCallmediaCXNowAdjuncts(quoteId)
        var options = await this.getQuoteOptionsCallmediaCXNow(quoteId)
        var connectors = await this.getQuoteCallmediaCXNowConnectors(quoteId)
        var apps = await this.getQuoteCallmediaCXNowApps(quoteId)

        // Using $set, as directly assigning to the arrays was not updating the DOM
        this.$set(quoteTechnologyCallmediaCXNow, 'userProfiles', userProfiles)
        this.$set(quoteTechnologyCallmediaCXNow, 'adjuncts', adjuncts)
        this.$set(quoteTechnologyCallmediaCXNow, 'options', options)
        this.$set(quoteTechnologyCallmediaCXNow, 'connectors', connectors)
        this.$set(quoteTechnologyCallmediaCXNow, 'apps', apps)

        var userProfileCount = 0
        quoteTechnologyCallmediaCXNow.userProfiles.forEach(userProfile => {
          userProfileCount += userProfile.minCommitment
        })

        if (userProfileCount === 0) {
          quoteTechnologyCallmediaCXNow = {}
        }
      }

      this.setQuoteTechnologyCallmediaCXNow(quoteTechnologyCallmediaCXNow)
    },
    iQuoteLoaderMixin_getIconGatewayDetailsFromDB: async function (quoteId) {
      var quoteTechnologyIconGateway = {}

      quoteTechnologyIconGateway.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.iconGateway)

      if (quoteTechnologyIconGateway.id === 0) {
        quoteTechnologyIconGateway = {}
      } else {
        var options = await this.getQuoteOptionsIconGateway(quoteId)

        // Using $set, as directly assigning to the arrays was not updating the DOM
        this.$set(quoteTechnologyIconGateway, 'options', options)
      }
      this.setQuoteTechnologyIconGateway(quoteTechnologyIconGateway)
    },
    iQuoteLoaderMixin_getAvayaEliteDetailsFromDB: async function (quoteId) {
      var quoteTechnologyAvayaElite = {}

      quoteTechnologyAvayaElite.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.avayaElite)

      if (quoteTechnologyAvayaElite.id === 0) {
        quoteTechnologyAvayaElite = {}
      } else {
        var userProfiles = await this.getQuoteTechnologyUserProfiles(quoteTechnologyAvayaElite.id)
        var adjuncts = await this.getQuoteAvayaEliteAdjuncts(quoteId)

        // Using $set, as directly assigning to the arrays was not updating the DOM
        this.$set(quoteTechnologyAvayaElite, 'userProfiles', userProfiles)
        this.$set(quoteTechnologyAvayaElite, 'adjuncts', adjuncts)

        var userProfileCount = 0
        quoteTechnologyAvayaElite.userProfiles.forEach(userProfile => {
          userProfileCount += userProfile.minCommitment
        })

        if (userProfileCount > 0) {
          this.setQuoteTechnologyAvayaElite(quoteTechnologyAvayaElite)
        }
      }
    },
    iQuoteLoaderMixin_getAvayaAACCDetailsFromDB: async function (quoteId) {
      var quoteTechnologyAvayaAACC = {}

      quoteTechnologyAvayaAACC.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.avayaAACC)

      if (quoteTechnologyAvayaAACC.id === 0) {
        quoteTechnologyAvayaAACC = {}
      } else {
        var userProfiles = await this.getQuoteTechnologyUserProfiles(quoteTechnologyAvayaAACC.id)
        var adjuncts = await this.getQuoteAvayaAACCAdjuncts(quoteId)
        var options = await this.getQuoteOptionsAvayaAACC(quoteId)

        // Using $set, as directly assigning to the arrays was not updating the DOM
        this.$set(quoteTechnologyAvayaAACC, 'userProfiles', userProfiles)
        this.$set(quoteTechnologyAvayaAACC, 'adjuncts', adjuncts)
        this.$set(quoteTechnologyAvayaAACC, 'options', options)

        var userProfileCount = 0
        quoteTechnologyAvayaAACC.userProfiles.forEach(userProfile => {
          userProfileCount += userProfile.minCommitment
        })

        if (userProfileCount === 0) {
          quoteTechnologyAvayaAACC = {}
        }
      }

      this.setQuoteTechnologyAvayaAACC(quoteTechnologyAvayaAACC)
    },
    iQuoteLoaderMixin_getMitelCCDetailsFromDB: async function (quoteId) {
      var quoteTechnologyMitelCC = {}

      quoteTechnologyMitelCC.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.mitelCC)

      if (quoteTechnologyMitelCC.id === 0) {
        quoteTechnologyMitelCC = {}
      } else {
        var userProfiles = await this.getQuoteTechnologyUserProfiles(quoteTechnologyMitelCC.id)
        var adjuncts = await this.getQuoteMitelCCAdjuncts(quoteId)
        var options = await this.getQuoteOptionsMitelCC(quoteId)

        // Using $set, as directly assigning to the arrays was not updating the DOM
        this.$set(quoteTechnologyMitelCC, 'userProfiles', userProfiles)
        this.$set(quoteTechnologyMitelCC, 'adjuncts', adjuncts)
        this.$set(quoteTechnologyMitelCC, 'options', options)

        var userProfileCount = 0
        quoteTechnologyMitelCC.userProfiles.forEach(userProfile => {
          userProfileCount += userProfile.minCommitment
        })

        if (userProfileCount === 0) {
          quoteTechnologyMitelCC = {}
        }
      }
      this.setQuoteTechnologyMitelCC(quoteTechnologyMitelCC)
    },
    iQuoteLoaderMixin_getMitelUCDetailsFromDB: async function (quoteId) {
      var quoteTechnologyMitelUC = {}

      quoteTechnologyMitelUC.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.mitelUC)

      if (quoteTechnologyMitelUC.id === 0) {
        quoteTechnologyMitelUC = {}
      } else {
        var userProfiles = await this.getQuoteTechnologyUserProfiles(quoteTechnologyMitelUC.id)
        var adjuncts = await this.getQuoteMitelUCAdjuncts(quoteId)
        var options = await this.getQuoteOptionsMitelUC(quoteId)

        // Using $set, as directly assigning to the arrays was not updating the DOM
        this.$set(quoteTechnologyMitelUC, 'userProfiles', userProfiles)
        this.$set(quoteTechnologyMitelUC, 'adjuncts', adjuncts)
        this.$set(quoteTechnologyMitelUC, 'options', options)

        var userProfileCount = 0
        quoteTechnologyMitelUC.userProfiles.forEach(userProfile => {
          userProfileCount += userProfile.minCommitment
        })

        if (userProfileCount === 0) {
          quoteTechnologyMitelUC = {}
        }
      }
      this.setQuoteTechnologyMitelUC(quoteTechnologyMitelUC)
    },
    iQuoteLoaderMixin_getGenesysDetailsFromDB: async function (quoteId) {
      var quoteGenesys = {}

      quoteGenesys.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.genesys)

      if (quoteGenesys.id === 0) {
        quoteGenesys = {}
      } else {
        var userProfiles = await this.getQuoteTechnologyUserProfiles(quoteGenesys.id)
        var options = await this.getQuoteOptionsGenesys(quoteId)

        if (userProfiles.length === 0) {
          // Incomplete record.  Most likely because the user previously had gone to add this technology to a quote
          // but didn't press next, then navigated away from the page
          this.deleteQuoteTechnology(quoteGenesys.id)
          quoteGenesys = {}
        } else {
          var userProfileCount = 0
          userProfiles.forEach(userProfile => {
            userProfileCount += userProfile.minCommitment
          })

          if (userProfileCount === 0) {
            this.deleteQuoteTechnology(quoteGenesys.id)
            quoteGenesys = {}
          } else {
            // Using $set, as directly assigning to the arrays was not updating the DOM
            this.$set(quoteGenesys, 'userProfiles', userProfiles)
            this.$set(quoteGenesys, 'options', options)
          }
        }
      }
      this.setQuoteGenesys(quoteGenesys)
    },
    iQuoteLoaderMixin_getAvayaAXPDetailsFromDB: async function (quoteId) {
      var quoteAvayaAXP = {}

      quoteAvayaAXP.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.avayaAXP)

      if (quoteAvayaAXP.id === 0) {
        quoteAvayaAXP = {}
      } else {
        var userProfiles = await this.getQuoteTechnologyUserProfiles(quoteAvayaAXP.id)
        var options = await this.getQuoteOptionsAvayaAXP(quoteId)

        if (userProfiles.length === 0) {
          // Incomplete record.  Most likely because the user previously had gone to add this technology to a quote
          // but didn't press next, then navigated away from the page
          this.deleteQuoteTechnology(quoteAvayaAXP.id)
          quoteAvayaAXP = {}
        } else {
          var userProfileCount = 0
          userProfiles.forEach(userProfile => {
            userProfileCount += userProfile.minCommitment
          })

          if (userProfileCount === 0) {
            this.deleteQuoteTechnology(quoteAvayaAXP.id)
            quoteAvayaAXP = {}
          } else {
            // Using $set, as directly assigning to the arrays was not updating the DOM
            this.$set(quoteAvayaAXP, 'userProfiles', userProfiles)
            this.$set(quoteAvayaAXP, 'options', options)
          }
        }
      }
      this.setQuoteAvayaAXP(quoteAvayaAXP)
    },
    iQuoteLoaderMixin_getSycurioDetailsFromDB: async function (quoteId) {
      var quoteSycurio = {}

      quoteSycurio.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.sycurio)

      if (quoteSycurio.id === 0) {
        quoteSycurio = {}
      } else {
        var options = await this.getQuoteOptionsSycurio(quoteId)

        if (options.numEssentialsUsers === 0) {
          this.deleteQuoteTechnology(quoteSycurio.id)
          quoteSycurio = {}
        } else {
          // Using $set, as directly assigning to the arrays was not updating the DOM
          this.$set(quoteSycurio, 'options', options)
        }
      }
      this.setQuoteSycurio(quoteSycurio)
    },
    iQuoteLoaderMixin_getUCAnalyticsDetailsFromDB: async function (quoteId) {
      var quoteUCAnalytics = {}

      quoteUCAnalytics.id = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.ucAnalytics)

      if (quoteUCAnalytics.id === 0) {
        quoteUCAnalytics = {}
      } else {
        var options = await this.getQuoteOptionsUCAnalytics(quoteId)

        if (options.numEssentialsUsers === 0 && options.numPlusUsers === 0 && options.numTailoredUsers === 0) {
          this.deleteQuoteTechnology(quoteUCAnalytics.id)
          quoteUCAnalytics = {}
        } else {
          // Using $set, as directly assigning to the arrays was not updating the DOM
          this.$set(quoteUCAnalytics, 'options', options)
        }
      }
      this.setQuoteUCAnalytics(quoteUCAnalytics)
    },
    iQuoteLoaderMixin_getRingCentralDetailsFromDB: async function (quoteId) {
      var quoteRingCentral = {}

      quoteRingCentral.ucTechId = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.ringCentralUC)
      quoteRingCentral.ccTechId = await this.getQuoteTechnologyId(quoteId, this.iQuoteConstants.technologies.ringCentralCC)
      if (quoteRingCentral.ucTechId === 0) {
        quoteRingCentral = {}
      } else {
        var ucUserProfiles = await this.getQuoteTechnologyUserProfiles(quoteRingCentral.ucTechId)
        var ccUserProfiles = await this.getQuoteTechnologyUserProfiles(quoteRingCentral.ccTechId)
        var ucUserProfilesApprovedRCPrice = await this.getQuoteTechnologyUserProfilesApprovedRCPrice(quoteRingCentral.ucTechId)
        var ccUserProfilesApprovedRCPrice = await this.getQuoteTechnologyUserProfilesApprovedRCPrice(quoteRingCentral.ccTechId)
        var options = await this.getQuoteOptionsRingCentral(quoteId)

        if (ucUserProfiles.length === 0) {
          // Incomplete record.  Most likely because the user previously had gone to add this technology to a quote
          // but didn't press next, then navigated away from the page
          this.deleteQuoteTechnology(quoteRingCentral.ucTechId)
          this.deleteQuoteTechnology(quoteRingCentral.ccTechId)
          quoteRingCentral = {}
        } else {
          var userProfileCount = 0
          if (ucUserProfiles?.length) {
            ucUserProfiles.forEach(userProfile => {
              userProfileCount += userProfile.minCommitment
            })
          }

          if (ccUserProfiles?.length) {
            ccUserProfiles.forEach(userProfile => {
              userProfileCount += userProfile.minCommitment
            })
          }
          if (userProfileCount === 0) {
            // No users so remove the technology
            this.deleteQuoteTechnology(quoteRingCentral.ucTechId)
            this.deleteQuoteTechnology(quoteRingCentral.ccTechId)
            quoteRingCentral = {}
          } else {
            // Using $set, as directly assigning to the arrays was not updating the DOM
            this.$set(quoteRingCentral, 'ucUserProfiles', ucUserProfiles)
            this.$set(quoteRingCentral, 'ccUserProfiles', ccUserProfiles)
            this.$set(quoteRingCentral, 'ucUserProfilesApprovedRCPrice', ucUserProfilesApprovedRCPrice)
            this.$set(quoteRingCentral, 'ccUserProfilesApprovedRCPrice', ccUserProfilesApprovedRCPrice)
            this.$set(quoteRingCentral, 'options', options)
          }
        }
      }
      this.setQuoteRingCentral(quoteRingCentral)
    }
  },
  mounted () {
  }
}
