<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="sycurioIcon"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="sycurioComponent.quote.quoteOpportunityId + ' (revision ' + sycurioComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br><br>
      <label
        :class="{InputError:!isValidNumEssentialsUsers}"
        for="numEssentialsUsers"
      >Number of Essentials Users
      </label>
      <input
        id="numEssentialsUsers"
        v-model.number="sycurioComponent.sycurio.options.numEssentialsUsers"
        class="small"
        type="number"
        min="1"
        max="999"
      >
      <div
        v-if="sycurioComponent.sycurio.options.numEssentialsUsers > 999"
        class="iQuoteWarningMessage"
      >If customer requires over 1000 users , please contact vendor for quote</div>
      <br>
      <label
        :class="{InputError:!isValidNumPSPProviders}"
        for="numNumPSPProviders"
      >Number of new Payment Service Providers (PSP) required
      </label>
      <input
        id="numNumPSPProviders"
        v-model.number="sycurioComponent.sycurio.options.numPSPProviders"
        class="small"
        type="number"
        min="1"
        max="999"
      >
      <br><br>
      <div class="iQuoteWarningMessage">
        Quotes for Enhanced or Enterprise Bundles are required from Sycurio
      </div>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService()"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { mapActions } from 'vuex'
export default {
  name: 'Sycurio',
  components: {
    TooltipIcon,
    TriStateToggleSwitch
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteStoreMixin],
  data () {
    return {
      sycurioComponent: {
        quote: {},
        sycurio: {
          options: {
            numEssentialsUsers: 0,
            numPSPProviders: 0
          }
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    sycurioIcon () {
      return require(`../../../assets/icons/sycurio.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidNumEssentialsUsers () {
      var value = this.sycurioComponent.sycurio.options.numEssentialsUsers
      return (this.iQuoteFunctions_IsInteger(value) && value >= 10 && value <= 999)
    },
    isValidNumPSPProviders () {
      var value = this.sycurioComponent.sycurio.options.numPSPProviders
      return (this.iQuoteFunctions_IsInteger(value) && value >= 0 && value <= 999)
    },
    totalSycurioUserCount () {
      return this.sycurioComponent.sycurio.options.numEssentialsUsers
    }
  },
  async mounted () {
    this.sycurioComponent.quote = this.$store.getters.quote
    var sycurioStore = this.$store.getters.quoteSycurio

    if (sycurioStore.id === undefined || sycurioStore.id === 0) {
      var options

      this.sycurioComponent.sycurio.id = await this.getQuoteTechnologyId(this.sycurioComponent.quote.id, this.iQuoteConstants.technologies.sycurio)

      if (this.sycurioComponent.sycurio.id === 0) {
        this.sycurioComponent.sycurio.id = await this.addQuoteTechnologyId(this.sycurioComponent.quote.id, this.iQuoteConstants.technologies.sycurio)

        options = {
          id: 0,
          quoteId: this.sycurioComponent.quote.id,
          numEssentialsUsers: 0,
          numPSPProviders: 0
        }
      } else {
        // Retrieve data from DB
        options = await this.getQuoteOptionsSycurio(this.sycurioComponent.quote.id)
      }

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.sycurioComponent.sycurio, 'options', options)
      }

      this.setQuoteSycurio(this.sycurioComponent.sycurio)
    } else {
      this.sycurioComponent.sycurio = sycurioStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteSycurio'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false

      if (!this.isValidNumEssentialsUsers || !this.isValidNumPSPProviders) {
        ValidationFailed = true
      }

      if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      } else if (this.totalSycurioUserCount < 10) {
        if (page === 'SelectService' && this.totalSycurioUserCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter at least 10 users, or enter 0 (this will remove sycurio from the quote)')
          return
        }
      } else if (this.totalSycurioUserCount > 999) {
        if (page === 'SelectService' && this.totalSycurioUserCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter 999 or less users, or enter 0 (this will remove sycurio from the quote)')
        }
        return
      }

      if (this.totalSycurioUserCount > 0) {
        if (!ValidationFailed) {
          // Save to DB only if valid data is supplied (even if pressing back rather than next)
          this.sycurioComponent.sycurio.options = await this.saveQuoteOptionsSycurio(this.sycurioComponent.sycurio.options)
        }
        // update local store
        this.setQuoteSycurio(this.sycurioComponent.sycurio)
      } else {
        this.deleteQuoteTechnology(this.sycurioComponent.sycurio.id)
        // delete from local store
        this.setQuoteSycurio({})
      }

      // update local store
      this.setCurrentQuoteState(page)
    },
    async finishService () {
      var ValidationFailed = false
      if (!this.isValidNumEssentialsUsers || !this.isValidNumPSPProviders) {
        ValidationFailed = true
      }

      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied

        await this.saveQuoteOptionsSycurio(this.sycurioComponent.sycurio.options)
        this.setQuoteSycurio(this.sycurioComponent.sycurio)
        this.setCurrentQuoteState('SelectService')
      } else {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
      }
    }
  }
}
</script>
<style scoped>
  table tr th {text-align: center;}
  table tr td {text-align: center;}
  .telsetsLabel {
    display: inline-block;
    width: 60px;
    margin: 20px 0;
    font-weight: 600;
  }

  .quotePanel {
    width: 800px;
  }

  table tr td .numericTableColumn {
    width:55px;
  }

  table tr th .numericTableColumn {
    width:55px;
  }
</style>
