import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'

export const iQuoteCalculationsDesignPackEntriesMixin = {
  mixins: [iQuoteConstants, iQuoteStoreMixin],
  data () {
    return {
      quote: {}
    }
  },
  computed: {
    iQuoteCalculationsDesignPackEntriesMixin_totalUpfrontTechnologyDataCostDefault () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          switch (unitCost.unitCostId) {
            case this.iQuoteConstants.unitCosts.SIP_CPE_STANDARD:
            case this.iQuoteConstants.unitCosts.SIP_CPE_RESILIENT:
              total += unitCost.cost * unitCost.quantity
          }
        }
      })
      return Number(total.toFixed(2))
    },
    iQuoteCalculationsDesignPackEntriesMixin_iconInfrastructureTotalUpfrontCostDefault () {
      // Returns the total upfront infrastructure cost for everything apart from Teams Connector and Callmedia CX Now
      return this.totalUpfrontInfrastructureCost - this.iQuoteCalculationsDesignPackEntriesMixin_totalUpfrontTechnologyDataCostDefault
    },
    iQuoteCalculationsDesignPackEntriesMixin_iconInfrastructureTotalUpfrontCostTC () {
      // Returns the total upfront infrastructure cost for Teams Connector

    },
    iQuoteCalculationsDesignPackEntriesMixin_iconInfrastructureTotalUpfrontCostCXN () {
      // Returns the total upfront infrastructure cost for Callmedia CX Now
    },
    iQuoteCalculationsDesignPackEntriesMixin_amortisedIconInfastructureRecurringExternal () {
      return (this.iQuoteCalculationsDesignPackEntriesMixin_iconInfrastructureTotalUpfrontCostDefault) / (this.quote.termLengthMonths / 12)
    },
    iQuoteCalculationsDesignPackEntriesMixin_amortisedIconInfastructureRecurringInternal () {
      if (this.quoteSIPMigrationProperties.id !== undefined) {
        return this.totalInternalAnnualUnitCosts + this.annualIconInfrastructureCost
      } else {
        return this.totalInternalAnnualUnitCosts + this.upfrontIconInfrastructureCostAmortised + this.annualTeamsUnitCostsCost
      }
    },
    iQuoteCalculationsDesignPackEntriesMixin_amortisedAWSInfastructureRecurringExternal () {
      return this.annualAWSPlatformCost
    },
    iQuoteCalculationsDesignPackEntriesMixin_amortisedIconGatewayRecurringExternal () {
      return this.annualIconGatewayCost
    },
    iQuoteCalculationsDesignPackEntriesMixin_sipInstallCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if ((unitCost.serviceId === this.iQuoteConstants.services.sip || unitCost.serviceId === this.iQuoteConstants.services.teamsConnector) &&
               unitCost.unitCostCategoryId !== this.iQuoteConstants.unitCostCategories.ICON_PS && unitCost.unitCostCategoryId !== this.iQuoteConstants.unitCostCategories.INTERNAL_PS) {
            if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
              if (unitCost.unitCostCategoryId !== this.iQuoteConstants.unitCostCategories.ICON_INFRASTRUCTURE) {
                total += unitCost.cost * unitCost.quantity /* for Sip Migration the infrastructure is listed seperately on DP export */
              }
            } else {
              total += unitCost.cost * unitCost.quantity
            }
          } else if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.CXNOW_GAMMA_SIP_SETUP) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })

      return Number(total.toFixed(2))
    },
    iQuoteCalculationsDesignPackEntriesMixin_recurringAgentSoftwareLicenseExternal () {
      var total = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.serviceId !== this.iQuoteConstants.services.sip && license.serviceId !== this.iQuoteConstants.services.teamsConnector && license.includeInCalculations) {
          total += license.annualCost
        }
      })

      return total
    },
    iQuoteCalculationsDesignPackEntriesMixin_recurringManagedServiceCost () {
      return this.managedServiceCost + this.totalAnnualExternalHelpdeskCost + this.totalAnnualInternalHelpdeskCost
    },
    iQuoteCalculationsDesignPackEntriesMixin_recurringInfrastructureCost () {
      if (this.quoteSIPMigrationProperties.id !== undefined) {
        return this.totalInternalAnnualUnitCosts + this.annualIconInfrastructureCost
      } else {
        return this.totalInternalAnnualUnitCosts
      }
    }
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcDesignPackEntries_calculate: function () {
      this.calcDesignPackEntries_addDesignPackSections()
    },
    calcDesignPackEntries_addDesignPackSections: function () {
      var sections = this.iQuoteStore_GetDesignPackTableSections
      var quoteUnitCosts = this.iQuoteStore_GetQuoteUnitCostsRequired

      var tableEntryTypes = [
        this.iQuoteConstants.designPackEntryTypes.AMORTISED,
        this.iQuoteConstants.designPackEntryTypes.UPFRONT
      ]

      tableEntryTypes.forEach(tableEntryType => {
        sections.forEach(section => {
          var upfrontExternalCost = section.upfrontExternalEntriesCanExist ? 0.0 : null
          var upfrontInternalCost = section.upfrontInternalEntriesCanExist ? 0.0 : null
          var recurringExternalCost = section.recurringExternalEntriesCanExist ? 0.0 : null
          var recurringInternalCost = section.recurringInternalEntriesCanExist ? 0.0 : null
          var upfrontExternalPrice = null
          var upfrontInternalPrice = null
          var recurringExternalPrice = null
          var recurringInternalPrice = null
          var upfrontExternalHiddenCost = 0
          var upfrontInternalHiddenCost = 0
          var recurringExternalHiddenCost = 0
          var recurringInternalHiddenCost = 0
          var upfrontExternalQuoteOnlyCost = 0
          var upfrontInternalQuoteOnlyCost = 0
          var recurringExternalQuoteOnlyCost = 0
          var recurringInternalQuoteOnlyCost = 0

          if (!(section.description === 'Icon Infrastructure' && section.coe === 'NS Upfront' && tableEntryType === this.iQuoteConstants.designPackEntryTypes.AMORTISED)) {
            // Ns Upfront on Amortised table Shouldn't be displayed. Every other combination should

            if (section.description === 'Managed Services') {
              recurringInternalCost = this.managedServiceCost
            } else if (section.description === 'Icon Infrastructure' && section.coe === 'CaaS' && tableEntryType === this.iQuoteConstants.designPackEntryTypes.AMORTISED) {
              recurringExternalHiddenCost = this.iQuoteCalculationsDesignPackEntriesMixin_amortisedIconInfastructureRecurringExternal
            } else if (section.description === 'Professional Services') {
              upfrontInternalCost = 0 /* Don't want this greyed out, as will be used in the future */
            }

            recurringExternalCost += this.calcDesignPackEntries_getLicenseRecurringCostTotal(section.id)
            upfrontExternalCost += this.calcDesignPackEntries_getLicenseUpfrontCostTotal(section.id)
            recurringExternalHiddenCost += this.calcDesignPackEntries_getLicenseRecurringHiddenCostTotal(section.id)
            upfrontExternalHiddenCost += this.calcDesignPackEntries_getLicenseUpfrontHiddenCostTotal(section.id)

            if (section.precalculatedPricing === true) {
              recurringExternalPrice = this.calcDesignPackEntries_getRecurringCustomerFacingLicensePriceTotal(section.id)
              recurringExternalPrice += this.calcDesignPackEntries_getCustomerFacingUnitCostRecurringPriceTotal(section.id, this.iQuoteConstants.costTypes.external)
              recurringInternalPrice = this.calcDesignPackEntries_getCustomerFacingUnitCostRecurringPriceTotal(section.id, this.iQuoteConstants.costTypes.internal)
              upfrontExternalPrice = this.calcDesignPackEntries_getCustomerFacingUnitCostUpfrontPriceTotal(section.id, this.iQuoteConstants.costTypes.external)
              upfrontExternalPrice += this.calcDesignPackEntries_getUpfrontCustomerFacingLicensePriceTotal(section.id)
              upfrontInternalPrice = this.calcDesignPackEntries_getCustomerFacingUnitCostUpfrontPriceTotal(section.id, this.iQuoteConstants.costTypes.internal)
            }

            quoteUnitCosts.forEach(unitCost => {
              if (unitCost.designPackTableSectionId === section.id) {
                if (unitCost.visibleToCustomer) {
                  if (unitCost.includeInCalculations) {
                    if (unitCost.recurringCost) {
                      if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
                        recurringInternalCost += unitCost.yearlyCost
                      } else {
                        recurringExternalCost += unitCost.yearlyCost
                      }
                    } else {
                      if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
                        upfrontInternalCost += unitCost.cost * unitCost.quantity
                      } else {
                        upfrontExternalCost += unitCost.cost * unitCost.quantity
                      }
                    }
                  }

                  if (!unitCost.includeInCalculations) {
                    if (unitCost.recurringCost) {
                      if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
                        recurringInternalQuoteOnlyCost += unitCost.cost * unitCost.quantity * unitCost.paymentsPerYear
                      } else {
                        recurringExternalQuoteOnlyCost += unitCost.cost * unitCost.quantity * unitCost.paymentsPerYear
                      }
                    } else {
                      if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
                        upfrontInternalQuoteOnlyCost += unitCost.cost * unitCost.quantity
                      } else {
                        upfrontExternalQuoteOnlyCost += unitCost.cost * unitCost.quantity
                      }
                    }
                  }
                } else {
                  //  Not visible to customer (Hidden costs)

                  if (unitCost.recurringCost) {
                    if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
                      recurringInternalHiddenCost += unitCost.yearlyCost
                    } else {
                      recurringExternalHiddenCost += unitCost.yearlyCost
                    }
                  } else {
                    if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
                      upfrontInternalHiddenCost += unitCost.cost * unitCost.quantity
                    } else {
                      upfrontExternalHiddenCost += unitCost.cost * unitCost.quantity
                    }
                  }
                }
              }
            })

            //          if (section.description.startsWith('Icon Infrastructure')) {
            //            var upfrontInfrastructure = 0.00
            //
            //            if (section.description === 'Icon Infrastructure') {
            //              upfrontInfrastructure = this.totalUpfrontInfrastructureCost
            //            } else if (section.description === 'Icon Infrastructure TC') {
            //              upfrontInfrastructure = 0.00 /* No infrastructure for Teams */
            //            } else if (section.description === 'Icon Infrastructure CXN') {
            //              upfrontInfrastructure = 0.00 /* No infrastructure for CXNow */
            //            }
            //
            //           if (tableEntryType === this.iQuoteConstants.designPackEntryTypes.UPFRONT) {
            //              this.iQuoteStore_AddDesignPackEntryToQuote(tableEntryType,
            //                section.description,
            //                'NS Upfront',
            //                upfrontInfrastructure,
            //                null,
            //                null,
            //                null,
            //                section.id,
            //               'iQuote'
            //              )
            //            } else {
            //              recurringExternalCost = upfrontInfrastructure / (quote.termLengthMonths / 12)
            //            }
            //          }

            this.iQuoteStore_AddDesignPackEntryToQuote(tableEntryType,
              section.description,
              section.coe,
              upfrontExternalCost,
              upfrontInternalCost,
              recurringExternalCost,
              recurringInternalCost,
              section.id,
              upfrontExternalPrice,
              upfrontInternalPrice,
              recurringExternalPrice,
              recurringInternalPrice,
              upfrontExternalHiddenCost,
              upfrontInternalHiddenCost,
              recurringExternalHiddenCost,
              recurringInternalHiddenCost,
              upfrontExternalQuoteOnlyCost,
              upfrontInternalQuoteOnlyCost,
              recurringExternalQuoteOnlyCost,
              recurringInternalQuoteOnlyCost
            )
          }
        })
      })
    },
    calcDesignPackEntries_getLicenseRecurringCostTotal: function (designPackTableSectionId) {
      var cost = 0
      for (let index = 0; index < this.iQuoteStore_GetQuoteLicensesRequired.length; index++) {
        const license = this.iQuoteStore_GetQuoteLicensesRequired[index]
        if (license.designPackTableSectionId === designPackTableSectionId &&
          license.visibleToCustomer === true) {
          if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
            cost += license.quantity * license.cost * 12
          } else if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.YEARLY) {
            cost += license.quantity * license.cost
          }
        }
      }
      return cost
    },
    calcDesignPackEntries_getLicenseUpfrontCostTotal: function (designPackTableSectionId) {
      var cost = 0
      for (let index = 0; index < this.iQuoteStore_GetQuoteLicensesRequired.length; index++) {
        const license = this.iQuoteStore_GetQuoteLicensesRequired[index]
        if (license.designPackTableSectionId === designPackTableSectionId &&
          license.visibleToCustomer === true) {
          if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.ONE_OFF) {
            cost += license.quantity * license.cost
          }
        }
      }
      return cost
    },
    calcDesignPackEntries_getLicenseRecurringHiddenCostTotal: function (designPackTableSectionId) {
      var cost = 0
      for (let index = 0; index < this.iQuoteStore_GetQuoteLicensesRequired.length; index++) {
        const license = this.iQuoteStore_GetQuoteLicensesRequired[index]
        if (license.designPackTableSectionId === designPackTableSectionId &&
          license.visibleToCustomer === false) {
          if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
            cost += license.quantity * license.cost * 12
          } else if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.YEARLY) {
            cost += license.quantity * license.cost
          }
        }
      }
      return cost
    },
    calcDesignPackEntries_getLicenseUpfrontHiddenCostTotal: function (designPackTableSectionId) {
      var cost = 0
      for (let index = 0; index < this.iQuoteStore_GetQuoteLicensesRequired.length; index++) {
        const license = this.iQuoteStore_GetQuoteLicensesRequired[index]
        if (license.designPackTableSectionId === designPackTableSectionId &&
          license.visibleToCustomer === false) {
          if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.ONE_OFF) {
            cost += license.quantity * license.cost
          }
        }
      }
      return cost
    },
    calcDesignPackEntries_getRecurringCustomerFacingLicensePriceTotal: function (designPackTableSectionId) {
      var price = 0
      for (let index = 0; index < this.iQuoteStore_GetQuoteLicensesRequired.length; index++) {
        const license = this.iQuoteStore_GetQuoteLicensesRequired[index]
        if (license.visibleToCustomer &&
            license.designPackTableSectionId === designPackTableSectionId) {
          if (license.chargeIntervalId === 1 /* Monthly */) {
            price += license.quantity * license.price * 12
          } else if (license.chargeIntervalId === 2 /* Annual */) {
            price += license.quantity * license.price
          }
        }
      }
      return price
    },
    calcDesignPackEntries_getUpfrontCustomerFacingLicensePriceTotal: function (designPackTableSectionId) {
      var price = 0
      for (let index = 0; index < this.iQuoteStore_GetQuoteLicensesRequired.length; index++) {
        const license = this.iQuoteStore_GetQuoteLicensesRequired[index]
        if (license.visibleToCustomer &&
            license.designPackTableSectionId === designPackTableSectionId) {
          if (license.chargeIntervalId === 3 /* One-Off */) {
            price += license.quantity * license.price
          }
        }
      }
      return price
    },
    calcDesignPackEntries_getCustomerFacingUnitCostRecurringPriceTotal: function (designPackTableSectionId, costTypeId) {
      var price = 0
      for (let index = 0; index < this.iQuoteStore_GetQuoteUnitCostsRequired.length; index++) {
        const unitCost = this.iQuoteStore_GetQuoteUnitCostsRequired[index]
        if (unitCost.visibleToCustomer &&
            unitCost.costTypeId === costTypeId &&
            unitCost.recurringCost &&
            unitCost.designPackTableSectionId === designPackTableSectionId) {
          price += unitCost.quantity * unitCost.price * unitCost.paymentsPerYear
        }
      }

      return price
    },
    calcDesignPackEntries_getCustomerFacingUnitCostUpfrontPriceTotal: function (designPackTableSectionId, costTypeId) {
      var price = 0
      for (let index = 0; index < this.iQuoteStore_GetQuoteUnitCostsRequired.length; index++) {
        const unitCost = this.iQuoteStore_GetQuoteUnitCostsRequired[index]
        if (unitCost.visibleToCustomer &&
            unitCost.costTypeId === costTypeId &&
            !unitCost.recurringCost &&
            unitCost.designPackTableSectionId === designPackTableSectionId) {
          price += unitCost.quantity * unitCost.price
        }
      }

      return price
    }
  },
  mounted () {
    this.quote = this.$store.getters.quote
  }
}
