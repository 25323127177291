<template>
  <div class="prtContainer">
    <div class="prtVerticalList">
      <div class="sstTitleBar">
        <div class="sstHeader sstBar">
          <h3 class="appDarkFont"><u>Circuits and Bandwidth</u></h3>
        </div>
      </div>
      <div class="prtHeader prtBar">
        <div
          class="prtHeader"
          style="width:50%; text-align:center"
        >
          <h3 class="appDarkFont">In Flight</h3>
        </div>
        <div
          class="prtHeader"
          style="width:50%; text-align:center"
        >
          <h3 class="appDarkFont">Delayed</h3>
        </div>
      </div>
      <div class="prtHeader prtBar">
        <div
          class="prtLargeTileContainer"
          style="width:50%; text-align:center"
        >
          <div class="prtLargeTile prtUp">
            <h2 class="appDarkFont">205</h2>
          </div>
        </div>
        <div
          class="prtLargeTileContainer"
          style="width:50%; text-align:center"
        >
          <div class="prtLargeTile prtDown">
            <h2 class="appDarkFont">11</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      circuitStatus: { noUp: 185, noPartial: 0, noDown: 2 },
      upTime: '99.9992%'
    }
  }
}
</script>

<style>
  .prtItem {
    margin:2px;
  }
  .prtContainer {
    display:flex;
    flex-direction: column;
    padding:15px 0 5px 0;
  }
  .prtTitleBar {
    display:flex;
    flex-direction: row;
  }
  .prtBar {
    display:flex;
    flex-direction: row;
    padding:5px;
  }
  .prtNumber {
    width:33%;
    text-align:center;
  }
  .prtHeader {
    font-weight:600;
  }
  .prtUp {
    background-color:rgba(39, 174, 96,0.2);
    border:2px rgba(39, 174, 96,0.8) solid;
  }
  .prtPartial {
    background-color:rgba(230, 126, 34,0.2);
    border:2px rgba(230, 126, 34,0.8) solid;
  }
  .prtDown {
    background-color:rgba(192, 57, 43,0.2);
    border:2px rgba(192, 57, 43,0.8) solid;
  }
  .prtNoValue {
    opacity: 0.3;
  }
  .prtHeader.prtItem {
    padding-bottom:0;
  }
  .prtLargeTile {
    width:90%;
    margin:5%;
  }
</style>
