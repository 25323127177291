<template>
  <div
    :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader', containerCollapsed}"
    class="shContainer">
    <div
      :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader', shEnableClick:enableClick}"
      class="shHeader"
      @click="$emit('click')">
      <div
        :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader'}"
        class="shTitleImage">
        <img
          :src="iconFileName"
          :id="`${id}_image`"
          :alt="title"
          :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader'}"
          srcset=""
          class="shIcon"
        >
      </div>
      <div
        :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader'}"
        class="shTitle">
        <h2 v-if="type!=='subHeader' && type!=='miniHeader'">{{ title }}</h2>
        <h3
          v-else-if="type==='subHeader'"
          :title="title">{{ title }}</h3>
        <h4 v-else-if="type==='miniHeader'">{{ title }}</h4>
      </div>
      <div
        v-if="showDeleteButton"
        :id="'deleteButton-' + id"
        class="shDeleteButton"
        title="Delete this item"
        tabindex="49"
        @click="deleteClicked ()"
      >
        <input
          type="button"
          class="deleteButton"
        >
      </div>
      <div
        :id="'collapseButton-' + id"
        :class="toggleStyle"
        :title="collapsedTitle"
        class="shCollapse"
        tabindex="50"
        @click="toggleContents"
        @keyup="testToggle($event)">
        <img :src="collapseIconName">
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'SectionCollapsedHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    iconFile: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    enableClick: {
      type: Boolean,
      default: false
    },
    controls: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    startCollapsed: {
      type: Boolean,
      default: true
    },
    showDeleteButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      toggleStyle: 'collapsed',
      containerCollapsed: 'container-collapsed',
      isCollapsed: this.startCollapsed,
      collapsedTitle: `Open ${this.title} contents`,
      collapseState: 'expand'
    }
  },
  computed: {
    iconFileName () {
      return require(`../../assets/icons/${this.iconFile}.png`)
    },
    collapseIconName () {
      return require(`../../assets/icons/${this.collapseState}.png`)
    }
  },
  mounted () {
    if (!this.startCollapsed) {
      this.isCollapsed = false
    }
    this.setState()
  },
  methods: {
    clicked () {
      this.$emit('click')
    },
    deleteClicked () {
      this.$emit('delete')
    },
    setState () {
      var controlsDisplay = 'inline-block'

      if (this.isCollapsed) {
        this.collapseState = 'expand'
        this.collapsedTitle = `Open ${this.title} contents`
        this.toggleStyle = 'collapsed'
        this.containerCollapsed = 'container-collapsed'
        controlsDisplay = 'none'
      } else {
        this.collapseState = 'collapse'
        this.collapsedTitle = `Close ${this.title} contents`
        this.toggleStyle = ''
        this.containerCollapsed = ''
      }

      document.getElementById(this.controls).style.display = controlsDisplay
    },
    toggleContents () {
      this.isCollapsed = !this.isCollapsed
      this.setState()
      return false
    },
    testToggle (event) {
      if (event.keyCode === 32) {
        this.toggleContents()
      }
    }
  }
}
</script>
