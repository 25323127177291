<template>
  <div class="requestAccessContainer">
    <div
      v-show="!showExtraCompany"
      class="requestAccessIntro"
    >To request access to the portal, fill in the form below and press the REQUEST ACCESS button.</div>
    <div class="requestAccessFieldContainer">
      <PortalTextBox
        :item-error="errorFirstName"
        text-id="requestAccessFirstName"
        item-type="fst"
        item-place-holder="First Name"
        @blur="updateElementName('requestAccessFirstName', $event)"/>
      <PortalTextBox
        :item-error="errorLastName"
        text-id="requestAccessLastName"
        item-type="lst"
        item-place-holder="Last Name"
        @blur="updateElementName('requestAccessLastName', $event)"/>
      <PortalTextBox
        :item-error="errorEmailAddress"
        text-id="requestAccessEmailAddress"
        item-type="ema"
        item-place-holder="Email Address"
        @blur="updateElementEmail($event)"/>
    </div>
    <div
      v-show="showExtraCompany"
      class="requestAccessFieldContainer"
    >
      <p class="companyText">Please enter your organisation name to access:</p>
      <PortalTextBox
        :item-error="errorCompanyName"
        text-id="requestAccessCompanyName"
        item-type="com"
        item-place-holder="Company Name"
        @blur="updateElementCompanyName('requestAccessCompanyName', $event)"/>
    </div>
    <div class="errorText">{{ errorText }}</div>
    <button
      id="btnRquestAccess"
      :style="loginBackgroundColour"
      class="loginButton"
      title="Request Access"
      @click="requestAccess()"
    >
      <p style="width:250px">REQUEST ACCESS</p>
    </button>
  </div>
</template>
<script>
import PortalTextBox from '../common/portalTextBox'
import { adminMixin } from '../../mixins/adminMixin'
import { usersMixin } from '../../mixins/users'
import { permissionsMixin } from '../../mixins/permissions'
export default {
  components: {
    PortalTextBox
  },
  mixins: [
    usersMixin,
    adminMixin,
    permissionsMixin
  ],
  data () {
    return {
      requestAccessEmailAddress: '',
      requestAccessLastName: '',
      requestAccessFirstName: '',
      errorLastName: false,
      errorFirstName: false,
      errorEmailAddress: false,
      errorText: '',
      errorCompanyName: false,
      resolvedCompanyId: 0,
      extraCompany: '',
      showExtraCompany: false,
      processing: false
    }
  },
  computed: {
    loginBackgroundColour () {
      return `background-color: ${this.$store.getters.loginButtonColour};margin-left:40px;`
    }
  },
  methods: {
    requestAccess: async function () {
      if (!this.processing) {
        this.processing = true
        if (this.requestAccessEmailAddress.length > 0 &&
          this.requestAccessLastName.length > 1 &&
          this.requestAccessFirstName.length > 1) {
          var payload = {}
          payload.EmailAddress = this.requestAccessEmailAddress
          payload.FirstName = this.requestAccessFirstName
          payload.LastName = this.requestAccessLastName
          payload.CompanyName = ''
          if (!this.showExtraCompany && await this.validateCompanyEmail(this.requestAccessEmailAddress.toLowerCase())) {
            // this.$store.commit('showMessage', { content: 'Processing...', color: 'blue', timeout: 2000 })
            var chk = await this.checkRequestAccessUser(this.requestAccessEmailAddress)
            if (chk.emailAddress === this.requestAccessEmailAddress) {
              this.$store.commit('showMessage', { content: 'This user is already submitted for access request approval.', color: 'blue', timeout: 5000 })
            } else {
              this.$store.commit('showMessage', { content: 'A request has been sent to your administrator.', color: 'green', timeout: 10000 })
              payload.CompanyId = this.resolvedCompanyId
              var co = await this.getCompanyById(payload.CompanyId)
              payload.CompanyName = co.name
              // console.log('resolvedCompanyId: ' + this.resolvedCompanyId + ', requestAccessEmailAddress:' + this.requestAccessEmailAddress + ', requestAccessLastName: ' + this.requestAccessLastName + ', requestAccessFirstName: ' + this.requestAccessFirstName)
              var rtn = await this.addRequestAccessUser(payload)
              if (rtn.length === 0) {
                payload.url = window.location.origin + '/'
                rtn = await this.sendApproveRequestEmail(payload)
              }
            }
            this.resetFields()
          } else if (this.showExtraCompany) {
            if (this.extraCompany.length > 2) {
              var companyPayload = {}
              companyPayload.EmailAddress = this.requestAccessEmailAddress
              companyPayload.FirstName = this.requestAccessFirstName
              companyPayload.LastName = this.requestAccessLastName
              companyPayload.CompanyId = 0
              companyPayload.CompanyName = this.extraCompany
              rtn = await this.addRequestAccessUser(companyPayload)
              if (rtn.length === 0) {
                this.$store.commit('showMessage', { content: 'A request has been sent to the administrator.', color: 'green', timeout: 10000 })
                companyPayload.url = window.location.origin + '/'
                rtn = await this.sendApproveRequestEmail(companyPayload)
                this.showExtraCompany = false
                this.resetFields()
              } else {
                this.$store.commit('showMessage', { content: 'We encountered an error sending the approval request. Please contact support at: portalquery@maintel.co.uk.', color: 'red', timeout: 10000 })
              }
            } else {
              this.errorText = 'Please fix the form issues'
            }
          } else {
            this.showExtraCompany = true
            this.$store.commit('showMessage', { content: 'We could not match an organisation against your email address. Please enter the organisation you belong to.', color: 'blue', timeout: 5000 })
          }
        } else {
          this.errorText = 'Please fix the form issues'
        }
        this.processing = false
      }
    },
    resetFields () {
      this.requestAccessEmailAddress = ''
      this.requestAccessFirstName = ''
      this.requestAccessLastName = ''
      this.extraCompany = ''
      this.resolvedCompanyId = 0
      this.showExtraCompany = false
      document.getElementById('requestAccessEmailAddress').value = ''
      document.getElementById('requestAccessFirstName').value = ''
      document.getElementById('requestAccessLastName').value = ''
      document.getElementById('requestAccessCompanyName').value = ''
    },
    updateElementName (field, value) {
      this.errorText = ''
      var val = value.srcElement.value
      var err = (val.length < 2)
      if (err) {
        document.getElementById(field).style.borderBottom = 'solid 1px #a33'
        this.errorText = 'The first and last names must be at least 2 characters'
      } else {
        document.getElementById(field).style.borderBottom = 'solid 1px #666'
        this.setNameElement(field, val)
      }
    },
    setNameElement (field, val) {
      if (field === 'requestAccessFirstName') {
        this.requestAccessFirstName = val
      } else {
        this.requestAccessLastName = val
      }
    },
    updateElementEmail: async function (value) {
      this.errorText = ''
      var val = value.srcElement.value.toLowerCase()
      if (this.validateEmail(val) && this.validateFirstChar(val)) {
        this.requestAccessEmailAddress = val
      } else {
        this.errorText = 'The email address format is invalid '
      }
    },
    validateFirstChar (email) {
      var re = /^[A-Za-z0-9_-]*$/
      return re.test(email[0])
    },
    validateEmail (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validateCompanyEmail: async function (email) {
      var rtn = false
      if (email !== null) {
        var domain = email.substr(email.indexOf('@') + 1, email.length - email.indexOf('@'))
        var result = await this.getCompanyByEmailSuffix(domain)
        if (result.length === 1) {
          rtn = result.some(user => user.emailSuffix === domain)
          this.resolvedCompanyId = result[0].companyId
        }
      }
      return rtn
    },
    updateElementCompanyName (field, value) {
      var val = value.srcElement.value
      var err = (val.length < 3)
      if (err) {
        document.getElementById(field).style.borderBottom = 'solid 1px #a33'
        this.errorText = 'The company name must be at least 3 characters'
      } else {
        document.getElementById(field).style.borderBottom = 'solid 1px #666'
        this.extraCompany = val.replace('\'', '`')
      }
    }
  }
}
</script>
<style scoped>
  .requestAccessContainer {
    display: inline-block;
  }
  .requestAccessFieldContainer {
    margin-bottom: 5px;
  }
  .requestAccessIntro {
    color: #333;
    display: inline-block;
    font-size: 1em;
    margin-bottom: 15px;
  }
  .errorText {
    height:24px;
    color:rgb(184, 33, 33);
    margin: -5px 0 0 10px;
    font-size: 0.8em;
  }
  .companyText {
    padding-left: 10px;
  }
</style>
