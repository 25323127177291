<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      :overlay="true"
      persistent
      max-width="50rem"
      transition="dialog-bottom-transition"
      class="dialog"
    >
      <v-card
        id="dlgDuplicateDesignpack"
        class="ma-0 dialog-border"
        height="640px"
        tile
      >
        <v-card-title class="card">
          <v-flex
            xs12
            sm12
            md10
          >
            <h1>Duplicate Design Pack</h1>
          </v-flex>
        </v-card-title>
        <v-card-title class="card">
          <v-flex
            xs12
            sm12
            md10
          >
            <div id="salesforceDetails">
              <br>
              <div
                id="searchOpportunityPrompt"
              >
                <b>Please Search for the opportunity to attach to the duplicated design pack</b>
                <br>
                <br>
              </div>
              <label
                :class="{InputError: duplicateDesignPackDialogBoxComponent.salesforceOpportunity.salesforceOpportunityId === 0}"
                for="txtSalesforceQuote"
              >Salesforce Opportunity #
                <TooltipIcon
                  width="500"
                  text="Login to Salesforce and retrieve from Salesforce Opportunity Number" />
              </label>
              <input
                id="txtSalesforceQuote"
                v-model="duplicateDesignPackDialogBoxComponent.salesforceQuoteNumber"
                type="text"
                @keyup="resetSalesforceOpportunity()"
              >
              <button
                v-if="duplicateDesignPackDialogBoxComponent.salesforceQuoteNumber !== ''"
                id="btnClearOpportunity"
                type="button"
                class="iQuoteIconButton"
                title="Reset Search"
                @click="resetSearch()"
              >
                <img
                  :src="iQuoteImagesMixin_iconDelete"
                  alt="delete">
              </button>
              <div>
                <div>
                  <label for="txtAccount">Account</label>
                  <input
                    id="txtAccount"
                    :value="duplicateDesignPackDialogBoxComponent.salesforceOpportunity.accountName"
                    readonly="true"
                    type="text"
                  >
                </div>
                <div>
                  <label for="txtOpportunityName">Opportunity Name</label>
                  <input
                    id="txtOpportunityName"
                    :value="duplicateDesignPackDialogBoxComponent.salesforceOpportunity.opportunityName"
                    type="text"
                    readonly="true"
                  >
                </div>
                <br>
              </div>
              <label
                :class="{InputError: duplicateDesignPackDialogBoxComponent.contactId === 0}"
                for="cboContact"
              >Choose Contact:</label>
              <select
                v-if="duplicateDesignPackDialogBoxComponent.salesforceOpportunity.contacts !== undefined"
                id="cboContact"
                v-model="duplicateDesignPackDialogBoxComponent.contactId"
              >
                <option
                  v-for="c in duplicateDesignPackDialogBoxComponent.salesforceOpportunity.contacts.filter(x => x.email !== null)"
                  :key="c.id"
                  :title="c.firstName + ' ' + c.lastName"
                  :selected="c.id === duplicateDesignPackDialogBoxComponent.salesforceOpportunity.contactId"
                  :value="c.id"
                >{{ c.firstName + ' ' + c.lastName }}</option>
              </select>
              <br>
              <label
                :class="{InputError: duplicateDesignPackDialogBoxComponent.name === ''}"
                for="txtDesignPackName"
              >Design Pack Name:
                <TooltipIcon
                  width="500"
                  text="Descriptive name for this design pack.  Do not include the revision number in this name" />
              </label>
              <input
                id="txtDesignPackName"
                v-model="duplicateDesignPackDialogBoxComponent.name"
                type="text"
              >
              <br>
              <label
                :class="{InputError: !isValidTermLengthMonths}"
                for="txtDesignPackTermLengthMonths"
              >Term Length (Months):
                <TooltipIcon
                  width="400"
                  text="How many months over which the customer will be charged" />
              </label>
              <input
                id="txtDesignPackTermLengthMonths"
                v-model.number="duplicateDesignPackDialogBoxComponent.termLengthMonths"
                type="number"
              >
              <br>
            </div>
          </v-flex>
        </v-card-title>
        <v-card-actions style="position: absolute;bottom: 1rem; right: 1rem;">
          <v-spacer/>
          <v-btn
            id="negativeButton"
            style="color: orange !important;"
            color="orange"
            flat
            @click="negativeAction"
          >Cancel</v-btn>
          <v-btn
            v-if="duplicateDesignPackDialogBoxComponent.contactId !== 0 && isValidTermLengthMonths && duplicateDesignPackDialogBoxComponent.name !== ''"
            id="positiveButton"
            color="orange"
            dark
            @click="positiveAction"
          >Duplicate Now</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { iQuoteSalesforceAPIMixin } from '../../../mixins/iQuote/iQuoteSalesforceAPIMixin'
import { iQuoteImagesMixin } from '../../../mixins/iQuote/Globals/iQuoteImages'
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { iQuoteEventBus } from '../../../events/iQuoteEvents'

export default {
  name: 'DuplicateDesignPackDialogBox',
  components: {
    TooltipIcon
  },
  mixins: [
    iQuoteSalesforceAPIMixin,
    iQuoteImagesMixin,
    iQuoteQuoteAPIMixin,
    iQuoteStoreMixin,
    iQuoteQuoteDesignPacksAPIMixin
  ],
  props: {
    dialog: { type: Boolean, default: false },
    sourceDesignPack: { type: Object, default: () => ({}) },
    sourceQuoteNumber: { type: String, default: '' }
  },
  data () {
    return {
      duplicateDesignPackDialogBoxComponent: {
        isPressed: false,
        salesforceOpportunity: {
          salesforceOpportunityId: 0,
          accountName: '',
          opportunityName: '',
          salesforceOpportunity: '',
          contacts: []
        },
        salesforceQuoteNumber: '',
        name: '',
        termLengthMonths: 36,
        contactId: 0,
        loading: false
      }
    }
  },
  computed: {
    isValidTermLengthMonths () {
      return this.duplicateDesignPackDialogBoxComponent.termLengthMonths > 0 && this.duplicateDesignPackDialogBoxComponent.termLengthMonths <= 60
    }
  },
  watch: {
    /* We need to alter the dialog properties if the selected design pack or salesforce Quote number is changed */
    sourceDesignPack: function (newVal, oldVal) {
      if (newVal.termLengthMonths !== undefined) {
        this.duplicateDesignPackDialogBoxComponent.name = ''
        this.duplicateDesignPackDialogBoxComponent.termLengthMonths = newVal.termLengthMonths
      }
    },
    sourceQuoteNumber: function (newVal, oldVal) {
      this.duplicateDesignPackDialogBoxComponent.salesforceQuoteNumber = newVal
      this.resetSalesforceOpportunity()
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuotePage',
      'setQuoteUsers',
      'setDesignPackList',
      'setDesignPack'
    ]),
    negativeAction () {
      this.$emit('negativeAction')
    },
    async positiveAction () {
      this.duplicateDesignPackDialogBoxComponent.isPressed = true
      var contact = this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.contacts.find(x => x.id === this.duplicateDesignPackDialogBoxComponent.contactId)

      // Save salesforce opportunity details to the DB yet
      var opportunity = {
        opportunityId: this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.opportunityID,
        quoteNumber: this.duplicateDesignPackDialogBoxComponent.salesforceQuoteNumber,
        contactFirstName: contact.firstName,
        contactLastName: contact.lastName,
        contactEmail: contact.email,
        opportunityName: this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.opportunityName,
        accountName: this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.accountName
      }
      var returnedOpportunity = await this.createOpportunity(opportunity)

      var salesforceOpportunityId = returnedOpportunity.id

      var designPack = this.iQuoteStore_GetDesignPack
      designPack.quoteDesignPackRevisionId = 0
      designPack.isReadOnly = false /* Do this before saving, to prevent a warning that we are trying to save a read-only designpack */
      designPack.locked = false
      designPack.dpExported = false
      designPack.salesforceOpportunityId = salesforceOpportunityId
      designPack.termLengthMonths = this.duplicateDesignPackDialogBoxComponent.termLengthMonths
      designPack.name = this.duplicateDesignPackDialogBoxComponent.name
      designPack.useDetailDataForSummaries = true

      iQuoteEventBus.$emit('designpack-modified', designPack, 'duplicate designpack positiveAction')

      this.$emit('positiveAction', this.duplicateDesignPackDialogBoxComponent.salesforceQuoteNumber)
    },
    cancel () {
      this.$emit('cancelDialog')
    },
    resetSalesforceOpportunity: async function () {
      this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.salesforceOpportunityId = 0
      this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.accountName = ''
      this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.opportunityName = ''

      if (this.duplicateDesignPackDialogBoxComponent.salesforceQuoteNumber !== undefined && this.duplicateDesignPackDialogBoxComponent.salesforceQuoteNumber.length === 7) {
        this.getSalesforceOpportunity()
      } else {
        this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.salesforceOpportunityId = 0
      }
    },
    resetSearch () {
      this.duplicateDesignPackDialogBoxComponent.salesforceQuoteNumber = ''
      this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.salesforceOpportunityId = 0
      this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.accountName = ''
      this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity.opportunityName = ''
      this.duplicateDesignPackDialogBoxComponent.loading = false
    },
    getSalesforceOpportunity: async function () {
      this.duplicateDesignPackDialogBoxComponent.loading = true
      var SalesforceOpportunity = await this.iQuoteSalesforceAPIMixin_getSalesforceOpportunityData(this.duplicateDesignPackDialogBoxComponent.salesforceQuoteNumber)
      if (SalesforceOpportunity.accountName === undefined) {
        this.iQuoteFunctions_ShowError('Error Querying Salesforce.  Please try again.')
        this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity = {
          salesforceOpportunityId: 0,
          accountName: '',
          opportunityName: '',
          salesforceOpportunity: ''
        }
      } else {
        this.duplicateDesignPackDialogBoxComponent.salesforceOpportunity = SalesforceOpportunity
        this.duplicateDesignPackDialogBoxComponent.currentDesignPackSelectScreen = 'SelectDesignPack'
      }
      this.duplicateDesignPackDialogBoxComponent.loading = false
    }
  }
}
</script>

<style>
  .v-dialog {
    overflow-y: unset !important;
  }
  .card {
      padding:0.5rem 1.5rem -1rem 1rem;
  }
  .title {
    padding-top: 5px;
  }
  .dialog-border {
    border-left: 1rem solid orange !important
  }
  .v-btn__content {
    font-weight: bold;
  }

  .card label {
    width: 220px;
    margin: 5px;
    display: inline-block;
  }

  #btnClearOpportunity img {
    width: 22px;
    height: 22px;
    padding: 0;
    margin: 0;
  }

  #cboContact {
  width: 200px;
  border:solid 1px #aeb7bd;
  height: 28px;
}
</style>
