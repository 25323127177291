<template>
  <div class="quotePanelContainer">
    <div class="quotePanel" >
      <label for="txtiQuoteReference">iQuote</label>
      <img
        :src="sipServices"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <p>Select required Category</p>
      <TooltipIcon text="Select Required Category" />
      <br>
      <ServiceCategoryTile
        v-for="t in serviceCategories"
        :service-category-id="t.id"
        :service-category-name="t.serviceCategoryName"
        :key="t.id"
      />
      <br><br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { iQuoteTechnologyAPIMixin } from '../../../../mixins/iQuote/iQuoteTechnologyAPIMixin'
import TooltipIcon from '../../../common/tooltipIcon'
import ServiceCategoryTile from '../../tiles/iQuoteServiceCategoryTile'
export default {
  name: 'SipServices',
  components: {
    ServiceCategoryTile,
    TooltipIcon
  },
  mixins: [iQuoteTechnologyAPIMixin],
  data () {
    return {
      serviceCategories: [],
      quote: {}
    }
  },
  computed: {
    question () {
      return require(`../../../../assets/icons/question.png`)
    },
    sipServices () {
      return require(`../../../../assets/icons/sipServices.png`)
    },
    toiQuotePrevious () {
      return require(`../../../../assets/icons/toiQuotePrev.png`)
    }
  },
  mounted () {
    this.setService()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),
    gotoPage (page) {
      this.setCurrentQuoteState(page)
    },
    setService: async function () {
      this.quote = this.$store.getters.quote
      var data = await this.getServiceCategoriesByServiceId(this.$store.getters.currentService)
      this.serviceCategories = []

      data.forEach(cat => {
        if (cat.id > 0) {
          this.serviceCategories.push(cat)
        }
      })
    }
  }
}
</script>
<style scoped>
</style>
