<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-customeronboarding"
          primary-title
        >
          Add Top Level Site
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="1.) Navigate to the 'Support' page via the left hand navigation menu and click on the 'Add Top Level Sites' button."
                indentation="0"
                image-source="customer_onboarding_1.png"
              />
              <HelpSection
                instruction="2.) The 'Add Top Level Site' screen should be seen with a number of options detailed below:"
                indentation="0"
              />
              <HelpSection
                instruction="a.) A new top level site can be created by changing the 'New Company' toggle to on and inputting a unique top level site name to be created."
                indentation="1"
                image-source="customer_onboarding_2.png"
              />
              <HelpSection
                instruction="b.) An existing top level site can be used by changing the 'New Company' toggle to off and selecting a top level site from the drop down list of top level sites available."
                indentation="1"
                image-source="customer_onboarding_3.png"
              />
              <HelpSection
                instruction="c.) There is the additional option of selecting an existing admin account if it is an existing top level site. When this toggle button is turned on, a list of accounts at that existing company are displayed to select from. The 'Exsisting Company' must be selected first for these users to be resolved."
                indentation="1"
                image-source="customer_onboarding_4.png"
              />
              <HelpSection
                instruction="d.) The admin email entered should be the email of the admin account associated with the new top level site."
                indentation="1"
              />
              <HelpSection
                instruction="e.) The autotask top level account number entered should be the site id from autotask."
                indentation="1"
              />
              <HelpSection
                instruction="f.) If this id is resolved correctly from autotask, it will be displayed underneath as confirmation of which site is going to be used in the creation process."
                indentation="1"
              />
              <HelpSection
                instruction="g.) The Traverse parent container identifier is optional and should be entered if known. "
                indentation="1"
              />
              <HelpSection
                instruction="h.) The enabled flag should be set if the top level site should be accessible to all users on creation."
                indentation="1"
              />
              <HelpSection
                instruction="i.) If a top level site is disabled, the top level site and it's data will still be accessible to administrator accounts."
                indentation="1"
              />
              <HelpSection
                instruction="j.) The permissions that will be applied to the admin account should be applied in the permissions table."
                indentation="1"
              />
              <HelpSection
                instruction="k.) There are a default set of permission that will be enabled by default, these can be changed as required."
                indentation="1"
              />
              <HelpSection
                instruction="l.) There are three states for permissions, enabled which will grant the permission, disabled which will deny the permission and inherit which will allow other roles assigned to a user to dictate whether a permission is allowed or denied."
                indentation="1"
              />
              <HelpSection
                instruction="m.) Once all of the inputs have been completed a green tick will be displayed and the top level site will be able to be created once the green tick button has been clicked."
                indentation="1"
                image-source="customer_onboarding_5.png"
              />
              <HelpSection
                instruction="n.) The red cross next to the create user button cancels the form and returns to the support page. This may be pressed at any time."
                indentation="1"
              />
              <HelpSection
                instruction="3.) On creation of the top level site, the site will be created and the welcome email will be sent to the designated admin email address."
                indentation="0"
              />
              <HelpSection
                instruction="a.) An example of the welcome email message sent is shown below with holders for the dynamic information introduced:."
                indentation="1"
                image-source="customer_onboarding_6.png"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpCustomerOnboarding',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
