// This component is used to enable or disable user accounts

<template>
  <div
    :id="id"
    class="adcHolder"
  >
    <div class="adcSectionContainer">
      <div class="adcSubHeader">
        <h4 class="adcText">{{ accountTitleText }}</h4>
      </div>
      <div class="adcButtonContainer">
        <input
          id="btnChangeAccountStatus"
          :value="accountActionButtonText"
          type="button"
          class="adcButton"
          @click="changeEnabledStatus()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import PortalButton from '../common/portalButton'
import { usersMixin } from '../../mixins/users'
import { mapActions } from 'vuex'
export default {
  components: {
    PortalButton
  },
  mixins: [
    usersMixin
  ],
  props: {
    username: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: function () { return {} }
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    accountActionButtonText () {
      if (!this.user.disabled) {
        return `Disable Account`
      }
      return `Enable Account`
    },
    accountTitleText () {
      if (!this.user.disabled) {
        return 'Account is enabled'
      }
      return 'Account is disabled'
    }
  },
  methods: {
    ...mapActions([
      'setReloadUsers'
    ]),
    changeEnabledStatus: async function () {
      var usr = {
        Id: this.user.id,
        Disabled: !this.user.disabled,
        Locked: this.user.locked,
        Deleted: this.user.deleted
      }
      // now we've created the user object, we'll send it off to the user service to update
      await this.updateUser(usr)
      this.setReloadUsers(true)
      this.user.disabled = !this.user.disabled
    }
  }
}
</script>
<style scoped>
  .adcButtonContainer{
    padding-left:30px;
    display: inline-block;
    width: 180px;
  }
  .adcText{
    font-weight: bold;
  }
  .adcButton{
    width:140px;
    border: solid 1px grey;
    color: grey;
  }
  .adcSubHeader{
    padding-left:10px;
    display: inline-block;
    width: 220px;
  }
  .adcHolder {
    background-color: rgba(255,255,255,0.7);
    margin-bottom: 10px;
    padding: 10px 0;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
</style>
