import reportsAPI from '../communications/interceptors/apiInterceptor'

export const reportsMixin = {
  data: function () {
    return {
      ticketPriorityTitle: 'P1 to P3',
      threshholdTicketsForFullScreenWidget: 10,
      maximumNumberOfSitesToShow: 10,
      threshholdScreenWidthToShowLabels: 1800
    }
  },
  computed: {

  },
  methods: {
    /*************************************************
     * Autotask service Reports controller methods
    **************************************************/
    ticketTrendsPayload: function (prioritylist) {
      // The ticket priorities are:-
      // 5: P1 - Critical
      // 7: P2 - High
      // 8: P3 - Medium
      // 9: P4 - Low
      // By default, will filter on P1, P2 and P3 tickets only
      if (prioritylist === undefined || prioritylist === '') {
        prioritylist = '5,7,8'
      }

      var sites = this.$store.getters.sites
      var rtn = '{"siteList":"'
      var siteList = ''
      if (sites && sites.length > 0) {
        for (let i = 0; i < sites.length; i++) {
          siteList += sites[i].id + ','
        }
        if (sites.length > 0) {
          siteList = siteList.slice(0, -1)
        }
      }
      rtn += siteList + '", "priorityList": "' + prioritylist + '" }'
      return rtn
    },
    getP1toP4Raised: async function () {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await reportsAPI.post('autotaskservice/reports/P1toP4Raised', this.ticketTrendsPayload(), axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('The getP1toP4Raised call failed with' + error)
      }
      return rtn
    },
    getP1toP4Closed: async function () {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await reportsAPI.post('autotaskservice/reports/P1toP4Closed', this.ticketTrendsPayload(), axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('The getP1toP4Closed call failed with' + error)
      }
      return rtn
    },
    getMIs: async function () {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await reportsAPI.post('autotaskservice/reports/MIs', this.ticketTrendsPayload(), axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('The getMIs call failed with' + error)
      }
      return rtn
    },
    getTicketsPerSite: async function () {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await reportsAPI.post('autotaskservice/reports/TicketsPerSite', this.ticketTrendsPayload(), axiosConfig).then((response) => {
          // We have been asked to show only the top ten sites (by number of tickets raised). To allow for easy adjustment if this trequirement
          //  changes, we are retrieving all sites in descending order of number of tickets raised, and selecting the top ten in the UI
          var allSiteCounts = response.data
          var totalSites = 1
          for (let i = 0; i < allSiteCounts.length; i++) {
            if (totalSites <= this.maximumNumberOfSitesToShow) {
              var currentSiteData = { 'id': allSiteCounts[i].id, 'accountName': allSiteCounts[i].accountName, 'number_of_Tickets': allSiteCounts[i].number_of_Tickets }
              rtn.push(currentSiteData)
              totalSites += 1
            } else {
              break
            }
          }
          if (rtn.length === 0) {
            rtn = ''
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('The getTicketsPerSite call failed with' + error)
      }
      return rtn
    },
    getMACSUsed: async function () {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await reportsAPI.post('autotaskservice/reports/MACSUsed', this.ticketTrendsPayload(), axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('The getMACSUsed call failed with' + error)
      }
      return rtn
    },
    getSLASummary: async function () {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await reportsAPI.post('autotaskservice/reports/SLASummary', this.ticketTrendsPayload(), axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('The getSLASummary call failed with' + error)
      }
      return rtn
    },
    getSLADetail: async function () {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await reportsAPI.post('autotaskservice/reports/SLADetail', this.ticketTrendsPayload(), axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('The getSLADetail call failed with' + error)
      }
      return rtn
    },
    getSLAGauge: async function (ticketPriority) {
      var priorityList = ''
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }

      // We are using the SLA summary data call to drive the SLA gauges, but
      // filtering the data by a single priority type for each gauge
      if (ticketPriority !== undefined) {
        switch (ticketPriority) {
          case 'P1':
            priorityList = '5'
            break
          case 'P2':
            priorityList = '7'
            break
          case 'P3':
            priorityList = '8'
            break
        }
      }
      try {
        await reportsAPI.post('autotaskservice/reports/SLASummary', this.ticketTrendsPayload(priorityList), axiosConfig).then((response) => {
          rtn = response.data
          // If there were zero total tickets, we want to pass a blank string
          // back to the widget so that we get a "no data" message
          if (rtn.length > 0) {
            if (rtn[0].total === 0) {
              rtn = ''
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('The getSLAGauge call for priority ' + ticketPriority + 'failed with' + error)
      }
      return rtn
    },
    getContractTimeUsage: async function () {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await reportsAPI.post('autotaskservice/reports/ContractTimeUsage', this.ticketTrendsPayload(), axiosConfig).then((response) => {
          if (response.data.length > 0) {
            rtn = response.data
          } else {
            rtn = ''
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('The getMACsUsed call failed with' + error)
      }
      return rtn
    },

    /*************************************************
     * Autotask API service Reports controller methods
    **************************************************/

    getSurveyResultMonthlyComparison: async function () {
      var companyId = this.$store.getters.currentCompany.id
      var allSurveyResults = []
      var targetSurveyId = 8
      var currentDate = new Date()
      currentDate.setDate(currentDate.getDate())

      var currentYear = currentDate.getFullYear()

      var currentMonth = currentDate.getMonth() + 1
      if (currentMonth.length === 1) {
        currentMonth = '0' + currentMonth
      }

      var currentDay = currentDate.getDay()
      if (currentDay.length === 1) {
        currentDay = '0' + currentDay
      }

      var startDate = currentDay + '-' + currentMonth + '-' + (currentYear - 1) + ' 00:00'
      var endDate = currentDay + '-' + currentMonth + '-' + currentYear + ' 23:59'
      allSurveyResults = await this.getReportSurveyResults(companyId, targetSurveyId, startDate, endDate)

      if (allSurveyResults.length === 0) {
        return ''
      } else {
        return allSurveyResults
      }
    },
    getReportSurveyResults: async function (companyId, surveyId, dateFrom, dateTo) {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        await reportsAPI.get(`/autotaskservice/reports/surveyresults/` + companyId + '/' + surveyId + '/' + dateFrom.replace(/"/g, '') + '/' + dateTo.replace(/"/g, ''), axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (e) {
        console.log('Error calling getReportSurveyResults with companyId: ' + companyId + ' and surveyId' + surveyId + ' and date from: ' + dateFrom + ' and date to: ' + dateTo)
      }
      return rtn
    }
  }
}
