// This module is used for state mamangement for channel company configuration values
import webAPI from '../../communications/interceptors/apiInterceptor'
const state = {
  // Used in setting up the white label site parameters
  channelCompany: {},
  channelUsers: [],
  channelRoles: [],
  channelEmailSuffix: '',
  backgroundImage: '',
  spinnerImage: '',
  siteLogo: '',
  menuLogo: '',
  loginButtonColour: '',
  loginButtonColourSelected: '',
  sidebarBackgroundColour: '',
  sidebarTextColour: '',
  sidebarSelectedTextStyle: '',
  sidebarHoverColour: '',
  topbarBackground: ''
}
const mutations = {
  SET_CHANNEL_COMPANY (state, channelCompany) {
    state.channelCompany = channelCompany
  },
  SET_CHANNEL_USERS (state, channelUsers) {
    state.channelUsers = channelUsers
  },
  SET_CHANNEL_ROLES (state, channelRoles) {
    state.channelRoles = channelRoles
  },
  SET_CHANNEL_EMAIL_SUFFIX (state, channelEmailSuffix) {
    state.channelEmailSuffix = channelEmailSuffix
  },
  SET_BACKGROUND_IMAGE (state, backgroundImage) {
    state.backgroundImage = backgroundImage
  },
  SET_SPINNER_IMAGE (state, spinnerImage) {
    state.spinnerImage = spinnerImage
  },
  SET_SITE_LOGO (state, siteLogo) {
    state.siteLogo = siteLogo
  },
  SET_MENU_LOGO (state, menuLogo) {
    state.menuLogo = menuLogo
  },
  SET_LOGIN_BUTTON_COLOUR (state, loginButtonColour) {
    state.loginButtonColour = loginButtonColour
  },
  SET_LOGIN_BUTTON_COLOUR_SELECTED (state, loginButtonColourSelected) {
    state.loginButtonColourSelected = loginButtonColourSelected
  },
  SET_SIDEBAR_BACKGROUND_COLOUR (state, sidebarBackgroundColour) {
    state.sidebarBackgroundColour = sidebarBackgroundColour
  },
  SET_SIDEBAR_TEXT_COLOUR (state, sidebarTextColour) {
    state.sidebarTextColour = sidebarTextColour
  },
  SET_SIDEBAR_SELECTED_TEXT_STYLE (state, sidebarSelectedTextStyle) {
    state.sidebarSelectedTextStyle = sidebarSelectedTextStyle
  },
  SET_SIDEBAR_HOVER_COLOUR (state, sidebarHoverColour) {
    state.sidebarHoverColour = sidebarHoverColour
  },
  SET_TOPBAR_BACKGROUND (state, topbarBackground) {
    state.topbarBackground = topbarBackground
  }
}
const actions = {
  setChannelUsers: ({ commit }, channelUsers) => {
    commit('SET_CHANNEL_USERS', channelUsers)
    localStorage.setItem('channelUsers', channelUsers)
  },
  setChannelRoles: ({ commit }, channelRoles) => {
    commit('SET_CHANNEL_ROLES', channelRoles)
    localStorage.setItem('channelRoles', channelRoles)
  },
  setChannelCompany: ({ commit }, channelCompany) => {
    commit('SET_CHANNEL_COMPANY', channelCompany)
    localStorage.setItem('channelCompany', channelCompany)
  },
  setChannelEmailSuffix: ({ commit }, channelEmailSuffix) => {
    commit('SET_CHANNEL_EMAIL_SUFFIX', channelEmailSuffix)
    localStorage.setItem('channelEmailSuffix', channelEmailSuffix)
  },
  setBackgroundImage: ({ commit }, backgroundImage) => {
    commit('SET_BACKGROUND_IMAGE', backgroundImage)
    localStorage.setItem('backgroundImage', backgroundImage)
  },
  setSpinnerImage: ({ commit }, spinnerImage) => {
    commit('SET_SPINNER_IMAGE', spinnerImage)
    localStorage.setItem('spinnerImage', spinnerImage)
  },
  setSiteLogo: ({ commit }, siteLogo) => {
    commit('SET_SITE_LOGO', siteLogo)
    localStorage.setItem('siteLogo', siteLogo)
  },
  setMenuLogo: ({ commit }, menuLogo) => {
    commit('SET_MENU_LOGO', menuLogo)
    localStorage.setItem('menuLogo', menuLogo)
  },
  setLoginButtonColour: ({ commit }, loginButtonColour) => {
    commit('SET_LOGIN_BUTTON_COLOUR', loginButtonColour)
    localStorage.setItem('loginButtonColour', loginButtonColour)
  },
  setLoginButtonColourSelected: ({ commit }, loginButtonColourSelected) => {
    commit('SET_LOGIN_BUTTON_COLOUR_SELECTED', loginButtonColourSelected)
    localStorage.setItem('loginButtonColourSelected', loginButtonColourSelected)
  },
  setSidebarBackgroundColour: ({ commit }, sidebarBackgroundColour) => {
    commit('SET_SIDEBAR_BACKGROUND_COLOUR', sidebarBackgroundColour)
    localStorage.setItem('sidebarBackgroundColour', sidebarBackgroundColour)
  },
  setSidebarTextColour: ({ commit }, sidebarTextColour) => {
    commit('SET_SIDEBAR_TEXT_COLOUR', sidebarTextColour)
    localStorage.setItem('sidebarTextColour', sidebarTextColour)
  },
  setSidebarSelectedTextStyle: ({ commit }, sidebarSelectedTextStyle) => {
    commit('SET_SIDEBAR_SELECTED_TEXT_STYLE', sidebarSelectedTextStyle)
    localStorage.setItem('sidebarSelectedTextStyle', sidebarSelectedTextStyle)
  },
  setSidebarHoverColour: ({ commit }, sidebarHoverColour) => {
    commit('SET_SIDEBAR_HOVER_COLOUR', sidebarHoverColour)
    localStorage.setItem('sidebarHoverColour', sidebarHoverColour)
  },
  setTopbarBackground: ({ commit }, topbarBackground) => {
    commit('SET_TOPBAR_BACKGROUND', topbarBackground)
    localStorage.setItem('topbarBackground', topbarBackground)
  },
  clearChannelConfigurations: ({ commit }) => {
    commit('SET_CHANNEL_COMPANY', {})
    commit('SET_CHANNEL_USERS', [])
    commit('SET_CHANNEL_ROLES', [])
    commit('SET_CHANNEL_EMAIL_SUFFIX', '')
    commit('SET_BACKGROUND_IMAGE', '')
    commit('SET_SPINNER_IMAGE', '')
    commit('SET_SITE_LOGO', '')
    commit('SET_MENU_LOGO', '')
    commit('SET_LOGIN_BUTTON_COLOUR', '')
    commit('SET_LOGIN_BUTTON_COLOUR_SELECTED', '')
    commit('SET_SIDEBAR_BACKGROUND_COLOUR', '')
    commit('SET_SIDEBAR_TEXT_COLOUR', '')
    commit('SET_SIDEBAR_SELECTED_TEXT_STYLE', '')
    commit('SET_SIDEBAR_HOVER_COLOUR', '')
    commit('SET_TOPBAR_BACKGROUND', '')
  },
  initChannel: async function ({ commit }) {
    var url = window.location.hostname
    var axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }
    try {
      await webAPI.get('permissions/channelcompanies/url/' + url, '', axiosConfig).then((response) => {
        var data = response.data
        commit('SET_CHANNEL_COMPANY', data)
      })
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
    try {
      await webAPI.get('permissionsarray/channelcompanystyles/' + state.channelCompany.id, '', axiosConfig).then((response) => {
        var styleData = response.data
        var obj = styleData.find(o => o.styleName === 'backgroundImage')
        commit('SET_BACKGROUND_IMAGE', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'siteLogo')
        commit('SET_SITE_LOGO', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'spinnerImage')
        commit('SET_SPINNER_IMAGE', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'menuLogo')
        commit('SET_MENU_LOGO', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'loginButtonColour')
        commit('SET_LOGIN_BUTTON_COLOUR', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'loginButtonColourSelected')
        commit('SET_LOGIN_BUTTON_COLOUR_SELECTED', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'sidebarBackgroundColour')
        commit('SET_SIDEBAR_BACKGROUND_COLOUR', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'sidebarTextColour')
        commit('SET_SIDEBAR_TEXT_COLOUR', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'sidebarSelectedTextStyle')
        commit('SET_SIDEBAR_SELECTED_TEXT_STYLE', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'sidebarHoverColour')
        commit('SET_SIDEBAR_HOVER_COLOUR', obj.styleValue)
        obj = styleData.find(o => o.styleName === 'topbarBackground')
        commit('SET_TOPBAR_BACKGROUND', obj.styleValue)
      })
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
    try {
      await webAPI.get('permissionsarray/entities/channel/' + state.channelCompany.companyId, '', axiosConfig).then((response) => {
        var data = response.data.map(a => a.id)
        commit('SET_CHANNEL_USERS', data)
      })
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
    try {
      await webAPI.get('permissionsarray/roles/channel/' + state.channelCompany.companyId, '', axiosConfig).then((response) => {
        var data = response.data.map(a => a.id)
        commit('SET_CHANNEL_ROLES', data)
      })
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
    try {
      await webAPI.get('permissionsarray/companyemailsuffix/company/' + state.channelCompany.companyId, '', axiosConfig).then((response) => {
        var data = response.data[0].emailSuffix
        commit('SET_CHANNEL_EMAIL_SUFFIX', data)
      })
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }
}

const getters = {
  channelCompany: state => {
    return state.channelCompany
  },
  channelUsers: state => {
    return state.channelUsers
  },
  channelRoles: state => {
    return state.channelRoles
  },
  channelEmailSuffix: state => {
    return state.channelEmailSuffix
  },
  backgroundImage: state => {
    return state.backgroundImage
  },
  spinnerImage: state => {
    return state.spinnerImage
  },
  siteLogo: state => {
    return state.siteLogo
  },
  menuLogo: state => {
    return state.menuLogo
  },
  loginButtonColour: state => {
    return state.loginButtonColour
  },
  loginButtonColourSelected: state => {
    return state.loginButtonColourSelected
  },
  sidebarBackgroundColour: state => {
    return state.sidebarBackgroundColour
  },
  sidebarTextColour: state => {
    return state.sidebarTextColour
  },
  sidebarSelectedTextStyle: state => {
    return state.sidebarSelectedTextStyle
  },
  sidebarHoverColour: state => {
    return state.sidebarHoverColour
  },
  topbarBackground: state => {
    return state.topbarBackground
  }
}
// This export is used to allow the vuex components to extend ito the global state
export default {
  state,
  mutations,
  actions,
  getters
}
