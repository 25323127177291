<template>
  <div
    class="overviewCardContainer"
  >
    <div
      class="overviewCardImageContainer"
      @click="navigateTo()"
    >
      <v-icon
        :class="colour"
        class="overviewCardImage"
        style="color:#fff;min-width: 44px;"
        mdi
        theme--dark
      >{{ icon }}
      </v-icon>
    </div>
    <div class="overviewCardTitleContainer">
      {{ title }}
      <br>
      {{ value }}
    </div>
    <div
      v-show="subText && subText.length > 0"
      class="overviewCardSubTextContainer"
      @click="navigateTo()"
    >
      <i
        :class="subIcon"
        aria-hidden="true"
        class="v-icon mr-2
        mdi
        theme--light"
        style="font-size: 16px;"
      />
      {{ subText }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'FormTextField',
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subText: {
      type: String,
      default: ''
    },
    subIcon: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    colour: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    }
  },
  methods: {
    navigateTo () {
      this.$router.push({ path: this.to })
    }
  }
}
</script>

<style scoped>
.overviewCardContainer {
  display: inline-block;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
.overviewCardImageContainer {
  cursor: pointer;
  display: inline-block;
  padding:2px;
}
.overviewCardTitleContainer {
  text-align: right;
  display: inline-block;
  font-size: 1.0em;
  font-weight: 500;
  vertical-align: top;
  width: calc(100% - 60px);
}
.overviewCardSubTextContainer {
  font-size: 0.8em;
  padding: 5px 0;
  margin-top: 5px;
  border-top: 1px solid #eee;
}
.overviewCardImage {
  padding:10px;
  border-radius: 5px;
  box-shadow: 0 12px 20px -10px rgb(120 120 120 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(120 120 120 / 20%) ;
}
.Grey {
  background-color: rgb(153, 153, 153);
}
</style>
