<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconContact"
        class="iconButton"
      >
      <br>
      <input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <br>
      <br>
      <h3 class="appDarkFont">Adjuncts</h3>

      <br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('UserProfilesMitelCC')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'AdjunctsMitelCC',
  mixins: [ShowHelpMixin, iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions],
  data () {
    return {
      wfo: [],
      quote: {},
      quoteTechnologyMitelCC: {
        userProfiles: [],
        adjuncts: {
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconContact () {
      return require(`../../../assets/icons/iconContact.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidMaxConcurrentCalls () {
      if (this.quoteTechnologyMitelCC.adjuncts.speechAnalysis === false) {
        return true
      }

      var value = this.quoteTechnologyMitelCC.adjuncts.maxConcurrentCalls
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidNumCallsPerAgentPerDay () {
      if (this.quoteTechnologyMitelCC.adjuncts.speechAnalysis === false) {
        return true
      }

      var value = this.quoteTechnologyMitelCC.adjuncts.numCallsPerAgentPerDay
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidAvgCallDuration () {
      if (this.quoteTechnologyMitelCC.adjuncts.speechAnalysis === false) {
        return true
      }

      var value = this.quoteTechnologyMitelCC.adjuncts.avgCallDuration
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidRetentionPeriod () {
      if (this.quoteTechnologyMitelCC.adjuncts.speechAnalysis === false) {
        return true
      }

      var value = this.quoteTechnologyMitelCC.adjuncts.retentionPeriod
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    this.wfo = this.$store.getters.wfooptions
    this.quoteTechnologyMitelCC = this.$store.getters.quoteTechnologyMitelCC

    if (this.quoteTechnologyMitelCC.id === undefined) {
      this.quoteTechnologyMitelCC.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.MitelCC)
      // Using $set, as directly assigning to the arrays was not updating the DOM
      var userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyMitelCC.id)
      var adjuncts = await this.getQuoteMitelCCAdjuncts(this.quote.id)

      this.$set(this.quoteTechnologyMitelCC, 'userProfiles', userProfiles)
      this.$set(this.quoteTechnologyMitelCC, 'adjuncts', adjuncts)

      this.setQuoteTechnologyMitelCC(this.quoteTechnologyMitelCC)
    }

    // TODO: Remove this when we add support for setting MitelCC Adjuncts
    this.finishService()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyMitelCC'
    ]),
    gotoPage (page) {
      this.setQuoteTechnologyMitelCC(this.quoteTechnologyMitelCC)
      this.setCurrentQuoteState(page)
    },
    async finishService () {
      // Save to DB only if valid data is supplied
      this.quoteTechnologyMitelCC.adjuncts = await this.saveQuoteMitelCCAdjuncts(this.quoteTechnologyMitelCC.adjuncts)
      this.setQuoteTechnologyMitelCC(this.quoteTechnologyMitelCC)
      this.gotoPage('SelectService')
    }
  }
}
</script>
<style scoped>
  .small{width:60px;}
</style>
