<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="ringCentralIcon"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="userProfilesRingCentralComponent.quote.quoteOpportunityId + ' (revision ' + userProfilesRingCentralComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <div class="titleBox">
        <h2>Ring Central - UC</h2>
        <Transition name="bounce">
          <div
            v-if="totalRingCentralUCUserCount > 0 && totalRingCentralUCUserCount < 500"
            class="banner">Pre-Sales must seek manual costings from David Hodge
          </div>
        </Transition>
      </div>
      <br>
      <label
        class=""
        for="cboSubscriptionBillingPeriod"
      >Subscription Billing Period</label>
      <select
        id="cboSubscriptionBillingPeriod"
        v-model.number="userProfilesRingCentralComponent.ringCentral.options.subscriptionBillingPeriodId"
      >
        <option
          key="1"
          value="1"
          title="Annual"
        >Annual</option>
        <option
          key="2"
          value="2"
          title="Monthly"
        >Monthly</option>
      </select>
      <br><br>
      <div style="display: flex; justify-content: flex-end; align-items: center; width: 87%">
        <label
          class=""
          for="chkUcPricingApprovedByRC"
          style="width: 35%;"
        >Pricing approved by RingCentral?</label>
        <input
          id="chkUcPricingApprovedByRC"
          v-model.number="userProfilesRingCentralComponent.ringCentral.options.ucPricingApprovedByRC"
          type="checkbox"
        >
      </div>
      <br>
      <table class="noTableShading">
        <tr>
          <th
            style="text-align:left;"
            class="numericTableColumn" />
          <th class="numericTableColumn">Min Commit Users<br>
            <TooltipIcon text="This covers the minimum number of agents committed to in the customer MSA and the actual number of users required for operation" />
          </th>
          <th
            v-if= "userProfilesRingCentralComponent.ringCentral.options.ucPricingApprovedByRC"
            class="numericTableColumn"
          >Approved Buy Price
          </th>
        </tr>
        <tr><td>&nbsp;</td></tr>
        <tbody
          v-for="category in userProfileCategories"
          :key="category.id">
          <tr><td>&nbsp;</td></tr>
          <tr class="TableSectionHeader">{{ category.description }}</tr>
          <tr
            v-for="userProfile in getUserProfilesByCategoryId(category.id)"
            :key="userProfile.technologyUserProfileId">
            <td
              :class="{InputError: !isValidUserProfile(userProfile)}"
              style="width:180px; text-align:left"
            >{{ userProfile.description }}
              <TooltipIcon
                v-if="userProfile.additionalInfo != ''"
                :text="userProfile.additionalInfo" />
            </td>
            <td
              class="numericTableColumn"
              style="width:150px; text-align:center"
            >
              <input
                v-model.number="userProfile.minCommitment"
                type="number"
                min="0"
                max="1000000"
                @keyup="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment)"
                @change="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment)"
              >
            </td>
            <td
              class="numericTableColumn"
              style="width:150px; text-align:center"
            >
              <input
                v-if= "userProfilesRingCentralComponent.ringCentral.options.ucPricingApprovedByRC"
                v-model.number="userProfile.buyPrice"
                type="text"
                min="0"
                max="1000000"
              >
            </td>
            <td>
              <label
                v-if="userProfile.minCommitment > 0 && userProfile.warning != ''"
                style="white-space: pre-wrap;"
                class="InputError"
              >{{ userProfile.warning }}
              </label>
            </td>
          </tr>
        </tbody>
        <tr><td colspan="3">&nbsp;</td></tr>
      </table>
      <h3>Options</h3>
      <br>
      <label
        class=""
      >Professional Services</label>
      <TriStateToggleSwitch
        ref="sql-toggle"
        :enabled="totalRingCentralUCUserCount >= 500"
        :current-state="userProfilesRingCentralComponent.ringCentral.options.ucProfessionalServicesRequired ? 1 : 2"
        :tri-state="false"
        class="toggleSwitch"
        tabindex="1"
        @statechanged="userProfilesRingCentralComponent.ringCentral.options.ucProfessionalServicesRequired = !userProfilesRingCentralComponent.ringCentral.options.ucProfessionalServicesRequired"
      />
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('UserProfilesRingCentralCC')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { mapActions } from 'vuex'
export default {
  name: 'UserProfilesRingCentral',
  components: {
    TooltipIcon,
    TriStateToggleSwitch
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteStoreMixin],
  data () {
    return {
      userProfilesRingCentralComponent: {
        quote: {},
        ringCentral: {
          ucUserProfiles: [],
          ccUserProfiles: [],
          ucUserProfilesApprovedRCPrice: [],
          ucUserProfilesApprovedRCPriceLoaded: false,
          options: {
            ucProfessionalServicesRequired: false,
            subscriptionBillingPeriodId: 1, /* 1 = annual */
            ucPricingApprovedByRC: false,
            ccPricingApprovedByRC: false
          }
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    ringCentralIcon () {
      return require(`../../../assets/icons/ringCentral.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    userProfileCategories () {
      var categories = []
      this.$store.getters.userProfileCategories.forEach(item => {
        if (item.technologyId === this.iQuoteConstants.technologies.ringCentralUC) {
          categories.push(item)
        }
      })

      return categories
    },
    totalRingCentralUCUserCount () {
      var total = 0
      this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.BASIC_USER ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ESSENTIAL_USER ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.STANDARD_USER ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.PREMIUM_USER ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ULTIMATE_USER
        ) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalRingCentralUCAddOnsUserCount () {
      var total = 0
      this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.CX_NOW_INTEGRATION ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.LIVE_REPORTS ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.INVIEW_PERFORMANCE_MANAGEMENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ADDITIONAL_LOCAL_NUMBER ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.MICROSOFT_DYNAMICS_UNIFIED_SERVICE_DESK
        ) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalRingCentralBasicUserCount () {
      var total = 0
      this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.BASIC_USER) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalRingCentralEssentialUserCount () {
      var total = 0
      this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ESSENTIAL_USER) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalRingCentralStandardUserCount () {
      var total = 0
      this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.STANDARD_USER) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalRingCentralPremiumUserCount () {
      var total = 0
      this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.PREMIUM_USER) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalRingCentralUltimateUserCount () {
      var total = 0
      this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ULTIMATE_USER) {
          total += item.minCommitment
        }
      })

      return total
    }
  },
  watch: {
    totalRingCentralUCUserCount (newValue) {
      if (newValue < 500) {
        this.userProfilesRingCentralComponent.ringCentral.options.ucProfessionalServicesRequired = false
      }
    }
  },
  async mounted () {
    this.userProfilesRingCentralComponent.quote = this.$store.getters.quote
    var ringCentralStore = this.$store.getters.quoteRingCentral
    if (ringCentralStore.ucTechId === undefined || ringCentralStore.ucTechId === 0) {
      var ucUserProfiles
      var ccUserProfiles
      var ucUserProfilesApprovedRCPrice
      var ccUserProfilesApprovedRCPrice
      var options

      this.userProfilesRingCentralComponent.ringCentral.ucTechId = await this.getQuoteTechnologyId(this.userProfilesRingCentralComponent.quote.id, this.iQuoteConstants.technologies.ringCentralUC)

      if (this.userProfilesRingCentralComponent.ringCentral.ucTechId === 0) {
        this.userProfilesRingCentralComponent.ringCentral.ucTechId = await this.addQuoteTechnologyId(this.userProfilesRingCentralComponent.quote.id, this.iQuoteConstants.technologies.ringCentralUC)
        this.userProfilesRingCentralComponent.ringCentral.ccTechId = await this.addQuoteTechnologyId(this.userProfilesRingCentralComponent.quote.id, this.iQuoteConstants.technologies.ringCentralCC)
        ucUserProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.ringCentralUC, this.userProfilesRingCentralComponent.ringCentral.ucTechId)
        ucUserProfilesApprovedRCPrice = this.iQuoteStore_getAllQuoteTechnologyUserProfilesApprovedRCPrice(ucUserProfiles, ringCentralStore.ucUserProfilesApprovedRCPrice)
        ccUserProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.ringCentralCC, this.userProfilesRingCentralComponent.ringCentral.ccTechId)
        ccUserProfilesApprovedRCPrice = this.iQuoteStore_getAllQuoteTechnologyUserProfilesApprovedRCPrice(ccUserProfiles, ringCentralStore.ccUserProfilesApprovedRCPrice)
        options = {
          id: 0,
          quoteId: this.userProfilesRingCentralComponent !== undefined ? this.userProfilesRingCentralComponent.quote.id : 0,
          ucProfessionalServicesRequired: false,
          ucPricingApprovedByRC: false,
          ccPricingApprovedByRC: false,
          subscriptionBillingPeriodId: this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL
        }
      } else {
        // Retrieve data from DB
        ucUserProfiles = await this.getQuoteTechnologyUserProfiles(this.userProfilesRingCentralComponent.ringCentral.ucTechId)
        ucUserProfilesApprovedRCPrice = await this.getQuoteTechnologyUserProfilesApprovedRCPrice(ringCentralStore.ucUserProfiles, ringCentralStore.ucUserProfilesApprovedRCPrice)
        ccUserProfiles = await this.getQuoteTechnologyUserProfiles(this.userProfilesRingCentralComponent.ringCentral.ccTechId)
        ccUserProfilesApprovedRCPrice = await this.getQuoteTechnologyUserProfilesApprovedRCPrice(ringCentralStore.ccUserProfiles, ringCentralStore.ccUserProfilesApprovedRCPrice)

        options = await this.getQuoteOptionsRingCentral(this.userProfilesRingCentralComponent.quote.id)
      }
      if (ucUserProfiles?.length) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.userProfilesRingCentralComponent.ringCentral, 'ucUserProfiles', ucUserProfiles)
      }
      if (ccUserProfiles?.length) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.userProfilesRingCentralComponent.ringCentral, 'ccUserProfiles', ccUserProfiles)
      }
      if (ucUserProfilesApprovedRCPrice?.length) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.userProfilesRingCentralComponent.ringCentral, 'ucUserProfilesApprovedRCPrice', ucUserProfilesApprovedRCPrice)
      }
      if (ccUserProfilesApprovedRCPrice?.length) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.userProfilesRingCentralComponent.ringCentral, 'ccUserProfilesApprovedRCPrice', ccUserProfilesApprovedRCPrice)
      }

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.userProfilesRingCentralComponent.ringCentral, 'options', options)
      }
      this.setQuoteRingCentral(this.userProfilesRingCentralComponent.ringCentral)
    } else {
      this.userProfilesRingCentralComponent.ringCentral = ringCentralStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteRingCentral'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      var minWasGreaterThanMax = false
      var maxNotHighEnough = false

      if (this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles?.length) {
        for (let index = 0; index < this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.length; index++) {
          const userProfile = this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles[index]
          if (!this.isValidUserProfile(userProfile)) {
            ValidationFailed = true
          }
        }
      }
      if (!this.validateEmptyUsers(page)) {
        this.iQuoteFunctions_ShowError('Min Commit Users can not be empty for all categories')
        return
      }
      if (!this.validateBuyPriceWithoutUser()) {
        this.iQuoteFunctions_ShowError('The approved buy price was set without any user')
        return
      }
      if (minWasGreaterThanMax) {
        this.iQuoteFunctions_ShowError('min commit cannot exceed maximum value')
        return
      } else if (maxNotHighEnough) {
        this.iQuoteFunctions_ShowError('maximum value must be at least 20% higher than the min commit')
        return
      } else if (this.totalRingCentralBasicUserCount > 0 && this.totalRingCentralBasicUserCount === this.totalRingCentralUCUserCount) {
        this.iQuoteFunctions_ShowError('Basic User cannot be ordered on it\'s own')
        return
      } else if (this.totalRingCentralBasicUserCount > 0 && this.totalRingCentralEssentialUserCount > 0) {
        this.iQuoteFunctions_ShowError('Basic User cannot be selected with Essential User')
        return
      } else if ((this.totalRingCentralEssentialUserCount > 0 && this.totalRingCentralEssentialUserCount !== this.totalRingCentralUCUserCount) ||
                 (this.totalRingCentralStandardUserCount > 0 && this.totalRingCentralStandardUserCount + this.totalRingCentralBasicUserCount !== this.totalRingCentralUCUserCount) ||
                 (this.totalRingCentralPremiumUserCount > 0 && this.totalRingCentralPremiumUserCount + this.totalRingCentralBasicUserCount !== this.totalRingCentralUCUserCount) ||
                 (this.totalRingCentralUltimateUserCount > 0 && this.totalRingCentralUltimateUserCount + this.totalRingCentralBasicUserCount !== this.totalRingCentralUCUserCount)) {
        this.iQuoteFunctions_ShowError('The selected user types cannot be ordered together')
        return
      } else if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      } else if (this.totalRingCentralUCUserCount > 10000) {
        if (page === 'SelectService' && this.totalRingCentralUCUserCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter 10,000 or less users, or enter 0 (this will remove RingCentral from the quote)')
        } else if (page === 'UserProfilesRingCentralCC') {
          this.iQuoteFunctions_ShowError('cannot support over 10,000 users')
        }
        return
      }
      if (this.totalRingCentralUCUserCount > 0 || this.totalRingCentralUCAddOnsUserCount > 0) {
        if (!ValidationFailed) {
          // Save to DB only if valid data is supplied (even if pressing back rather than next)
          if (this.userProfilesRingCentralComponent.ringCentral.options.ucPricingApprovedByRC) {
            await this.deleteQuoteTechnologyUserProfilesApprovedRCPrice(this.userProfilesRingCentralComponent.ringCentral.ucTechId)
            this.userProfilesRingCentralComponent.ringCentral.ucUserProfilesApprovedRCPrice = await this.saveQuoteTechnologyUserProfilesApprovedRCPrice(this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles)
          } else {
            this.userProfilesRingCentralComponent.ringCentral.ucUserProfilesApprovedRCPrice = await this.deleteQuoteTechnologyUserProfilesApprovedRCPrice(this.userProfilesRingCentralComponent.ringCentral.ucTechId)
          }
          this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles = await this.saveQuoteTechnologyUserProfiles(this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles)
          this.userProfilesRingCentralComponent.ringCentral.ccUserProfiles = await this.saveQuoteTechnologyUserProfiles(this.userProfilesRingCentralComponent.ringCentral.ccUserProfiles)
          this.userProfilesRingCentralComponent.ringCentral.options = await this.saveQuoteOptionsRingCentral(this.userProfilesRingCentralComponent.ringCentral.options)
        }
        // update local store
        this.setQuoteRingCentral(this.userProfilesRingCentralComponent.ringCentral)
      } else {
        this.deleteQuoteTechnology(this.userProfilesRingCentralComponent.ringCentral.ucTechId)
        this.deleteQuoteTechnology(this.userProfilesRingCentralComponent.ringCentral.ccTechId)
        // delete from local store
        this.setQuoteRingCentral({})
      }

      // update local store
      this.setCurrentQuoteState(page)
      this.userProfilesRingCentralComponent.ringCentral.ucUserProfilesApprovedRCPriceLoaded = false
    },
    isValidUserProfile (userProfile) {
      return userProfile.minCommitment <= userProfile.maxAllowed &&
                                          this.iQuoteFunctions_IsInteger(userProfile.minCommitment) &&
                                          this.iQuoteFunctions_IsInteger(userProfile.maxAllowed) &&
                                          userProfile.minCommitment >= 0 &&
                                          userProfile.maxAllowed >= 0 &&
                                          !((userProfile.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.BASIC_USER && this.totalRingCentralBasicUserCount > 0 && (this.totalRingCentralEssentialUserCount > 0 || this.totalRingCentralBasicUserCount === this.totalRingCentralUCUserCount)) ||
                                           (userProfile.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ESSENTIAL_USER && this.totalRingCentralEssentialUserCount > 0 && this.totalRingCentralEssentialUserCount !== this.totalRingCentralUCUserCount) ||
                                           (userProfile.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.STANDARD_USER && this.totalRingCentralStandardUserCount > 0 && this.totalRingCentralStandardUserCount + this.totalRingCentralBasicUserCount !== this.totalRingCentralUCUserCount) ||
                                           (userProfile.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.PREMIUM_USER && this.totalRingCentralPremiumUserCount > 0 && this.totalRingCentralPremiumUserCount + this.totalRingCentralBasicUserCount !== this.totalRingCentralUCUserCount) ||
                                           (userProfile.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ULTIMATE_USER && this.totalRingCentralUltimateUserCount > 0 && this.totalRingCentralUltimateUserCount + this.totalRingCentralBasicUserCount !== this.totalRingCentralUCUserCount)
                                          )
    },
    validateEmptyUsers (page) {
      if (page === 'UserProfilesRingCentralCC' && this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles?.length) {
        if (this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.every(item => item.minCommitment === 0)) {
          return false
        }
      }
      return true
    },
    validateBuyPriceWithoutUser () {
      if (this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles?.length) {
        for (let index = 0; index < this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.length; index++) {
          const userProfile = this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles[index]
          const minCommitment = userProfile?.minCommitment ? parseInt(userProfile.minCommitment) : 0
          const buyPrice = userProfile?.buyPrice ? parseInt(userProfile.buyPrice) : 0
          if (buyPrice > 0 && minCommitment === 0) {
            return false
          }
        }
      }
      return true
    },
    getUserProfilesByCategoryId (categoryId) {
      var userProfiles = []
      if (this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles?.length) {
        this.userProfilesRingCentralComponent.ringCentral.ucUserProfiles.forEach(item => {
          if (!this.userProfilesRingCentralComponent.ringCentral.ucUserProfilesApprovedRCPriceLoaded) {
            if (this.userProfilesRingCentralComponent.ringCentral.ucUserProfilesApprovedRCPrice?.length) {
              const approvedRCPrice = this.userProfilesRingCentralComponent.ringCentral.ucUserProfilesApprovedRCPrice.find(w => w.quoteTechnologyId === item.quoteTechnologyId &&
                w.technologyUserProfileId === item.technologyUserProfileId)
              if (approvedRCPrice !== undefined) {
                item.buyPrice = approvedRCPrice.buyPrice
              }
            }
          }
          if (item.userProfileCategoryId === categoryId) {
            userProfiles.push(item)
          }
        })
      }

      this.userProfilesRingCentralComponent.ringCentral.ucUserProfilesApprovedRCPriceLoaded = true
      return userProfiles
    }
  }
}
</script>
<style scoped>
  table tr th {text-align: center;}
  table tr td {text-align: center;}
  .telsetsLabel {
    display: inline-block;
    width: 60px;
    margin: 20px 0;
    font-weight: 600;
  }

  .quotePanel {
    width: 800px;
  }

  table tr td .numericTableColumn {
    width:55px;
  }

  table tr th .numericTableColumn {
    width:55px;
  }

  .titleBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%
  }

  .banner {
    margin: 12px 0 0 130px;
    padding: 10px;
    background: #009579;
    color: #F5F5F5;
    box-shadow: 3px 3px 5px 1px #585858;
    border-radius: 10px;
  }

  .bounce-enter-active {
    animation: bounce-in 0.4s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.4s reverse;
  }

  @keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
