<template>
  <div
    v-if="$store.getters.openSubForm3"
    class="roleNewAreaBackground"
  >
    <div class="roleNewAreaHolder">
      <div class="roleTableholder">
        <table>
          <thead>
            <tr>
              <th style="width:10%;border-top-right-radius:0px;border-top-left-radius:0px">
                <img
                  :src="newAreaIcon"
                  class="icon"
                >
              </th>
              <th style="width:90%;border-top-right-radius:0px;border-top-left-radius:0px" >Add Site To Role</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="m in areas"
              id="memberList"
              :key="m.id"
            >
              <td>
                <input
                  :id="m.id"
                  type="checkbox"
                  title="Select Site"
                  class="memberItem"
                  @change="checkForm"
                >
              </td>
              <td
                :title="m.name"
              >{{ m.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <input
        id="permissionCancelNewRoleButton"
        type="button"
        class="permissionCancelNewRoleButton"
        value="Cancel"
        title="Cancel the add operation"
        @click="cancelAdd()"
      >
      <input
        id="permissionAddNewRoleButton"
        :class="getButtonClass"
        type="button"
        value="Add Site"
        title="Adds the site to the currently selected role"
        @click="addArea()"
      >
      <input
        type="text"
        class="focusField"
        @focus="cycleFocus"
      >
    </div>
  </div>
</template>
<script>
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'RoleNewArea',
  mixins: [
    permissionsMixin
  ],
  props: {
    roleid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      formOK: false
    }
  },
  computed: {
    ...mapState([
      'openSubForm3'
    ]),
    getButtonClass () {
      if (this.formOK) {
        return 'permissionAddNewRoleButtonEnabled'
      } else {
        return 'permissionAddNewRoleButton'
      }
    },
    areas () {
      var areas = []
      for (let i = 0; i < this.$store.getters.areas.length; i++) {
        var aid = this.$store.getters.areas[i].id
        var arr = this.$store.getters.roleAreas.filter(function (ra) {
          return ra.id === aid
        })
        if (arr.length === 0) {
          areas.push(this.$store.getters.areas[i])
        }
      }
      return areas
    },
    newAreaIcon () {
      return require('../../../assets/icons/People.png')
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.openSubForm3,
      (newValue) => {
        if (document.getElementById('memberList') && newValue) {
          document.getElementById('memberList').focus()
        }
      }
    )
  },
  methods: {
    ...mapActions([
      'setSubForm3',
      'setReloadAreas'
    ]),
    checkForm () {
      var selected = false
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          selected = true
        }
      }
      this.formOK = selected
    },
    checkExists (entity, id) {
      return entity.id === id
    },
    closeForm () {
      this.setSubForm3(false)
    },
    addArea: async function () {
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          await this.addRoleArea(this.roleid, memberItems[i].id)
        }
      }
      this.setReloadAreas(true)
      this.closeForm()
    },
    cancelAdd () {
      this.closeForm()
    },
    cycleFocus () {
      if (document.getElementById('memberList')) {
        document.getElementById('memberList').focus()
      }
    }
  }
}

</script>
<style scoped>
  .roleNewAreaBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .roleNewAreaHolder {
    display: inline-block;
    background-color: rgba(255,255,255,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 160px);
    top: calc(10%);
    max-height: calc(100% - 180px);
    width: 360px;
    overflow: auto;
    z-index: 999;
    overflow-y: auto;
  }
  .permissionCancelNewRoleButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .permissionAddNewRoleButton {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    opacity: 0.5;
  }
  .permissionAddNewRoleButtonEnabled {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .roleTableholder {
    overflow-y: auto;
    max-height: 300px;
  }
  .focusField {
    position: absolute;
    left:0;
    bottom:0;
    width:0;
    opacity: 0;
    background-color: rgba(240,240,240,1);
  }
  .icon {
    height: 25px;
    width: 25px;
  }
</style>
