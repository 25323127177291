<template>
  <div>
    <div
      v-if="false"
      class="noResults"
    >
      Sorry, you do not have permission to assign sites to users
    </div>
    <div
      id="createUserSitesSectionContainer"
      class="createUserSitesSectionContainer"
    >
      <h3
        id="SitesSectionHeader"
        :class="[showAreaSection ? 'expanded' : '']"
        @click="() => { showAreaSection = !showAreaSection }"
      >
        2. Sites
        <span class="statuscontainer">
          <span
            :class="[sectionComplete ? 'completeSection' : 'incompleteSection']"
            :title="[sectionComplete ? 'Section Complete' : 'Section Incomplete']"
          />
          <span class="background" />
        </span>
        <span
          :class="[showAreaSection ? 'minimiseSection' : 'maximiseSection']"
        />
      </h3>
      <transition name="slide">
        <div
          v-if="showAreaSection"
        >
          <div
            class="createUserRolesContainer"
          >
            <div class="searchContainer">
              <label
                for="userAreasFilter"
              >
                Search:
              </label>
              <input
                id="userAreasFilter"
                v-model="filterText"
                class="searchFilter"
                type="text"
              >
            </div>
            <table
              v-show="showAreaSection"
              id="sitesTable"
            >
              <thead>
                <tr>
                  <th style="width:90%">
                    Site Name
                    <div style="float:right"> Assign all Sites: </div>
                  </th>
                  <th
                    style="width:10%"
                  >
                    <TriStateToggleSwitch
                      ref="area-toggle"
                      :enabled="true"
                      :current-state="allAreasSelected"
                      :tri-state="false"
                      class="tableToggleSwitch sitesTableToggleSwitch"
                      @statechanged="({ selectedState }) => {
                        addTopLevelArea(selectedState)
                      }"
                    />
                  </th>
                </tr>
              </thead>
              <tbody v-if="loadingArea">
                <tr>
                  <td>
                    <Spinner
                      v-if="loadingArea"
                      id="areaTableSpinner"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr
                  v-for="({ id, name }) in filteredAreas"
                  :key="id"
                >
                  <td>{{ name }} </td>
                  <td style="padding: 6px 0 6px 0;">
                    <TriStateToggleSwitch
                      v-if="!topLevelAreaSelected"
                      ref="area-toggle"
                      :enabled="true"
                      :current-state="2"
                      :area-id="id"
                      :tri-state="false"
                      class="tableToggleSwitch"
                      @statechanged="addArea"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { permissionsMixin } from '../../mixins/permissions'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'
import Spinner from '../common/spinner'
import { DataEventBus } from '../../events/dataEvents'
export default {
  name: 'CreateUserAreas',
  components: {
    TriStateToggleSwitch,
    Spinner
  },
  mixins: [ permissionsMixin ],
  props: {
    areaSelected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      topLevelAreaSelected: false,
      showAreaSection: false,
      sectionComplete: false,
      filterText: '',
      loadingArea: false,
      allAreasSelected: 2
    }
  },
  computed: {
    areas () {
      return this.$store.getters.areas
    },
    filteredAreas () {
      return this.filterAreas()
    }
  },
  watch: {
    areaSelected () {
      this.sectionComplete = this.areaSelected
    }
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.allAreasSelected = 2
      this.sectionComplete = false
    })
  },
  methods: {
    addArea: function ({ areaId, selectedState }) {
      this.$emit('areaChanged', {
        areaId,
        selectedState
      })
    },
    addTopLevelArea: function (selectedState) {
      this.allAreasSelected = selectedState
      this.topLevelAreaSelected = selectedState === 1
      this.$emit('topLevelAreaSelected', {
        selectedState
      })
    },
    filterAreas () {
      this.loadingArea = true
      var rtn = this.areas
      if (this.areas.length > 1) {
        rtn = this.areas.filter(({ name, parent }) => name.toLowerCase().includes(this.filterText.toLowerCase()))
      }
      this.loadingArea = false
      return rtn
    }
  }
}
</script>

<style scoped>
  .createUserSitesSectionContainer
  {
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width:100%;
    margin-bottom:10px;
    border-radius: 5px;
  }
  .createUserRolesContainer{
    background-color: rgba(255,255,255,0.7);
    max-height: 240px;
    overflow-y: auto;
  }

  label {
    font-weight: bold;
  }

  .slide-enter-active, .slide-leave-active {
    transition: max-height .2s ease-out;
  }

  .slide-enter, .slide-leave-to {
    max-height: 0px;
    overflow:hidden;
  }

  .slide-enter-to, .slide-leave {
    max-height: 300px;
    overflow:hidden;
  }

  h3 {
    cursor: pointer;
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0;
    color: #fff;
    border-radius: 5px;
  }
  .expanded {
    border-radius: 5px 5px 0 0;
  }
  .completeSection {
    background-image: url('../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .incompleteSection {
    background-image: url('../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  span.statuscontainer {
    float: left;
    margin-right: 5px;
  }

  span.statuscontainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }

  .minimiseSection {
    background-image: url('../../assets/icons/up_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .maximiseSection {
    background-image: url('../../assets/icons/down_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .searchFilter {
    width: 90%;
    border-radius: 5px;
  }

  .searchContainer {
    padding: 5px;
    position: sticky;
    height: 40px;
    display: inline-block;
    top: 0px;
    background-color: #fff;
    width: 100%;
    z-index: 1;
  }

  .tableToggleSwitch {
    float: right;
    margin-right: 10px
  }

</style>
