import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteCalculationsiQuoteModuleStatsMixin } from './iQuoteCalculationsiQuoteModuleStatsMixin'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const iQuoteCalculationsUCAnalyticsMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin,
    iQuoteCalculationsiQuoteModuleStatsMixin],
  data () {
    return {
    }
  },
  computed: {
    /*
     * BUSINESS VALUES
     */
    calcUCAnalytics_UpfrontCharges () {
      var total = 0

      if (this.iQuoteStore_GetQuoteUCAnalytics.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.ucAnalytics) {
            total += unitCost.price * unitCost.quantity
          }
        }
      })
      return total
    },
    calcUCAnalytics_RecurringCharges () {
      var total = 0

      if (this.iQuoteStore_GetQuoteUCAnalytics.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.ucAnalytics) {
            total += unitCost.price * unitCost.quantity
          }
        }
      })
      return total
    }
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcUCAnalytics_calculate: function () {
      // Added to all quotes

      if (this.iQuoteStore_GetQuoteUCAnalytics.id === undefined) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.UC_ANALYTICS_FOUNDATION_USER, this.iQuoteCalculationsiQuoteModuleStatsMixin_ucAnalyticsFoundationsUserCount)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.UC_ANALYTICS_FOUNDATION_USER_QUOTE_ONLY, this.iQuoteCalculationsiQuoteModuleStatsMixin_ucAnalyticsFoundationsUserCount)
        return
      }

      if (this.iQuoteStore_GetQuoteUCAnalytics.options.isProofOfConcept) {
        var overridePrice = 0

        // Note that Tailored is not supported for proof of concept, so we don't add license for that
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.UC_ANALYTICS_ESSENTIALS_USER, this.iQuoteStore_GetQuoteUCAnalytics.options.numEssentialsUsers, overridePrice)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.UC_ANALYTICS_PLUS_USER, this.iQuoteStore_GetQuoteUCAnalytics.options.numPlusUsers, overridePrice)

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.UC_ANALYTICS_PROJECT_DELIVERY_MANAGER, 2)
      } else {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.UC_ANALYTICS_ESSENTIALS_USER, this.iQuoteStore_GetQuoteUCAnalytics.options.numEssentialsUsers)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.UC_ANALYTICS_PLUS_USER, this.iQuoteStore_GetQuoteUCAnalytics.options.numPlusUsers)
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.UC_ANALYTICS_TAILORED_USER, this.iQuoteStore_GetQuoteUCAnalytics.options.numTailoredUsers)

        if (this.iQuoteStore_GetQuoteUCAnalytics.options.numEssentialsUsers + this.iQuoteStore_GetQuoteUCAnalytics.options.numPlusUsers > 0) {
          // Tailored quotes will have PS added via a BOM
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.UC_ANALYTICS_PROJECT_DELIVERY_MANAGER, 2)
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.UC_ANALYTICS_ENGINEERING_LEVEL_A, 1)
        }

        if (this.iQuoteStore_GetQuoteUCAnalytics.options.numPlusUsers > 0 &&
            this.iQuoteStore_GetQuoteUCAnalytics.options.ucAnalyticsQuoteTypeId !== this.iQuoteConstants.ucAnalyticsQuoteTypes.EXISTING_UC_ANALYTICS_ADDING_USERS) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.UC_ANALYTICS_SET_UP_FEE_PLUS_PACKAGE, 1)
        }
      }
    }
  }
}
