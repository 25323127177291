import { iQuoteConstants } from './iQuoteConstants'
import { mapActions } from 'vuex'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'

export const iQuoteCalculationsUnitCostsMixin = {
  mixins: [iQuoteConstants, iQuoteStoreMixin],
  data () {
    return {
    }
  },
  computed: {
    unitCostCalcs_yearlyDcHostingCost () {
      var value = (this.upfrontIconInfrastructureCost + this.totalUpfrontIconPSCost + this.totalRecurringIconPSCost) * 0.05
      value = Number(value.toFixed(2))

      return value
    }
  },
  methods: {
    ...mapActions([
      'setQuoteUnitCosts'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    unitCostCalcs_calcUnitCosts () {
      // eslint-disable-next-line
      var quoteTechnologyCallmediaCXNow = this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow
      var quoteTechnologyIconGateway = this.iQuoteStore_GetQuoteTechnologyIconGateway
      var quoteTechnologyAvayaAura = this.iQuoteStore_GetQuoteTechnologyAvayaAura
      var quoteTechnologyCallmedia = this.iQuoteStore_GetQuoteTechnologyCallmedia
      var quoteTechnologyAvayaElite = this.iQuoteStore_GetQuoteTechnologyAvayaElite
      var quoteTechnologyAvayaAACC = this.iQuoteStore_GetQuoteTechnologyAvayaAACC
      var quoteTechnologyMitelCC = this.iQuoteStore_GetQuoteTechnologyMitelCC
      var quoteTechnologyMitelUC = this.iQuoteStore_GetQuoteTechnologyMitelUC
      var quoteGammaSIP = this.iQuoteStore_GetGammaSip
      var quoteExpansions = this.iQuoteStore_GetQuoteExpansions

      if (quoteTechnologyIconGateway.id !== undefined) {
        this.unitCostCalcs_calcIconGatewayUnitCosts()
      }

      if (quoteTechnologyAvayaAura.id !== undefined ||
          quoteTechnologyCallmedia.id !== undefined ||
          quoteTechnologyAvayaElite.id !== undefined ||
          quoteTechnologyAvayaAACC.id !== undefined ||
          quoteTechnologyMitelCC.id !== undefined ||
          quoteTechnologyMitelUC.id !== undefined ||
          quoteGammaSIP.id !== undefined ||
          quoteExpansions.id !== undefined) {
        this.unitCostCalcs_calcICONHostedUnitCosts()
      }

      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_PORTAL, 1)
    },
    unitCostCalcs_calcIconGatewayUnitCosts () {
      var quoteTechnologyIconGateway = this.iQuoteStore_GetQuoteTechnologyIconGateway
      var annualCost = 0
      var monthlyCost = 0
      var unitCostId

      console.log('calculating icon gateway')
      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_FAREHAM_RACK
      monthlyCost = (13689.54 * (quoteTechnologyIconGateway.options.farehamRackU / 44) / 0.95) / 12
      monthlyCost = monthlyCost / quoteTechnologyIconGateway.options.farehamRackU /* Break it down into cost per U */
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12 * quoteTechnologyIconGateway.options.farehamRackU
      this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, quoteTechnologyIconGateway.options.farehamRackU, annualCost, monthlyCost)

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_GOSWELL_RACK
      monthlyCost = (12184.74 * (quoteTechnologyIconGateway.options.goswellRackU / 44) / 0.95) / 12
      monthlyCost = monthlyCost / quoteTechnologyIconGateway.options.goswellRackU /* Break it down into cost per U */
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12 * quoteTechnologyIconGateway.options.goswellRackU
      this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, quoteTechnologyIconGateway.options.goswellRackU, annualCost, monthlyCost)

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_FAREHAM_LINK
      monthlyCost = ((261 * quoteTechnologyIconGateway.options.farehamConnectionSize) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_GOSWELL_LINK
      monthlyCost = ((232 * quoteTechnologyIconGateway.options.goswellConnectionSize) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_FAREHAM_WAN_DUAL_FEED
      monthlyCost = ((4.78 * quoteTechnologyIconGateway.options.wanTotalFeedBandwidth) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_GOSWELL_WAN_DUAL_FEED
      monthlyCost = ((1.20 * quoteTechnologyIconGateway.options.wanTotalFeedBandwidth) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_FAREHAM_WAN_GOLD
      monthlyCost = ((2 * quoteTechnologyIconGateway.options.wanGoldBandwidth) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_GOSWELL_WAN_GOLD
      monthlyCost = ((2 * quoteTechnologyIconGateway.options.wanGoldBandwidth) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_FAREHAM_WAN_SILVER
      monthlyCost = ((1 * quoteTechnologyIconGateway.options.wanSilverBandwidth) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_GOSWELL_WAN_SILVER
      monthlyCost = ((1 * quoteTechnologyIconGateway.options.wanSilverBandwidth) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_FAREHAM_WAN_BRONZE
      monthlyCost = ((0.5 * quoteTechnologyIconGateway.options.wanBronzeBandwidth) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }

      unitCostId = this.iQuoteConstants.unitCosts.ICON_GATEWAY_GOSWELL_WAN_BRONZE
      monthlyCost = ((0.5 * quoteTechnologyIconGateway.options.wanBronzeBandwidth) / 0.95) / 12
      monthlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(monthlyCost)
      annualCost = monthlyCost * 12
      if (monthlyCost > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(unitCostId, 1, annualCost, monthlyCost)
      }
    },
    unitCostCalcs_VmExistsInGoswell (virtualMachineId) {
      var found = false
      var quoteVMsRequired = this.iQuoteStore_GetQuoteVMsRequired

      quoteVMsRequired.forEach(vmRequired => {
        if (vmRequired.virtualMachineId === virtualMachineId && vmRequired.dataCentreId === this.iQuoteConstants.dataCentres.goswellRoad) {
          found = true
        }
      })

      return found
    },
    unitCostCalcs_calcICONHostedUnitCosts () {
      /**
       *  NOTE: calcUnitCosts MUST be run after vms and blades have been provisioned
       */
      var quoteBladeServers = this.iQuoteStore_GetQuoteBladeServers
      var quoteVMsRequired = this.iQuoteStore_GetQuoteVMsRequired
      var quoteVMsAvailable = this.iQuoteStore_GetVirtualMachines
      var quoteTechnologyMitelUC = this.iQuoteStore_GetQuoteTechnologyMitelUC
      var quote = this.iQuoteStore_GetQuote

      var ucBladeCount = 0.0
      var ccBladeCount = 0.0
      var ucSqlCount = 0
      var ccSqlCount = 0
      var chassisCount = 0
      var ucStorageFastTb = 0.0
      var ucStorageArchiveTb = 0.0
      var ccStorageFastTb = 0.0
      var ccStorageArchiveTb = 0.0
      var vCPURequired = 0.0
      var ucZertoCount = 0.0
      var ccZertoCount = 0.0
      var activeVMCount = 0.0
      var standardBladeServerCount = 0.0
      var highPowerBladeServerCount = 0.0
      var expansionVmCount = 0
      var vmCount = 0

      quoteBladeServers.forEach(bladeRequired => {
        if (bladeRequired.bladeServerId === this.iQuoteConstants.bladeServers.STD_CC || bladeRequired.bladeServerId === this.iQuoteConstants.bladeServers.HPR_CC) {
          ccBladeCount += bladeRequired.quantityRequired
        } else if (bladeRequired.bladeServerId === this.iQuoteConstants.bladeServers.STD_UC || bladeRequired.bladeServerId === this.iQuoteConstants.bladeServers.HPR_UC) {
          ucBladeCount += bladeRequired.quantityRequired
        }

        if (bladeRequired.bladeServerId === this.iQuoteConstants.bladeServers.STD_UC || bladeRequired.bladeServerId === this.iQuoteConstants.bladeServers.STD_CC) {
          standardBladeServerCount += bladeRequired.quantityRequired
        } else if (bladeRequired.bladeServerId === this.iQuoteConstants.bladeServers.HPR_UC || bladeRequired.bladeServerId === this.iQuoteConstants.bladeServers.HPR_CC) {
          highPowerBladeServerCount += bladeRequired.quantityRequired
        }
      })

      if (quoteBladeServers.length > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_BLADE_SERVERS, 1, null, this.calculationsCommonMixin_totalUpfrontBladeServerCost)
      }

      quoteVMsAvailable.forEach(vmAvailable => {
        // Loop through every possible VM

        quoteVMsRequired.forEach(vmRequired => {
          if (vmRequired.virtualMachineId === vmAvailable.id) {
            // If this is a VM that we need then work out the licenses it requires
            if (vmAvailable.specification.sql) {
              var sqlQty = Math.ceil(vmAvailable.specification.vcpu / 2)

              if (sqlQty < 2) {
                sqlQty = 2
              }

              if (vmAvailable.categoryId === this.iQuoteConstants.virtualMachineCategories.UC) {
                ucSqlCount += sqlQty * vmRequired.quantity
              } else if (vmAvailable.categoryId === this.iQuoteConstants.virtualMachineCategories.CC) {
                ccSqlCount += sqlQty * vmRequired.quantity
              }
            }

            if ((vmAvailable.specification.zerto === true ||
                 vmAvailable.specification.operatingSystemId === this.iQuoteConstants.operatingSystems.WINDOWS)) {
              if (vmAvailable.categoryId === this.iQuoteConstants.virtualMachineCategories.UC) {
                ucZertoCount += vmRequired.quantity
              } else if (vmAvailable.categoryId === this.iQuoteConstants.virtualMachineCategories.CC) {
                ccZertoCount += vmRequired.quantity
              }
            }

            if (vmRequired.dataCentreId === this.iQuoteConstants.dataCentres.fareham) {
              if (vmAvailable.categoryId === this.iQuoteConstants.virtualMachineCategories.UC) {
                ucStorageFastTb += ((vmAvailable.specification.storageSizeGbStandard + vmRequired.additionalStorageGbStandard) / 1000) * vmRequired.quantity
                ucStorageArchiveTb += ((vmAvailable.specification.storageSizeGbArchive + vmRequired.additionalStorageGbArchive) / 1000) * vmRequired.quantity
              } else if (vmAvailable.categoryId === this.iQuoteConstants.virtualMachineCategories.CC) {
                ccStorageFastTb += ((vmAvailable.specification.storageSizeGbStandard + vmRequired.additionalStorageGbStandard) / 1000) * vmRequired.quantity
                ccStorageArchiveTb += ((vmAvailable.specification.storageSizeGbArchive + vmRequired.additionalStorageGbArchive) / 1000) * vmRequired.quantity
              }
            }

            vCPURequired += vmAvailable.specification.vcpu * vmRequired.quantity

            if (vmRequired.active) {
              activeVMCount += vmRequired.quantity
            }

            vmCount += vmRequired.quantity
            expansionVmCount += vmRequired.expansionQuantity
          }
        })
      })

      if (expansionVmCount !== vmCount) {
        // expansion only quotes do not require chassis space
        chassisCount = 1 / 12
      }

      var quoteExpansions = this.iQuoteStore_GetQuoteExpansions

      if (quoteExpansions.id !== undefined) {
        var quoteExpansionCustomVms = quoteExpansions.expansionVirtualMachines.filter(x => x.virtualMachineId === null)

        quoteExpansionCustomVms.forEach(vm => {
          if (vm.expansionVmResilienceTypeId === this.iQuoteConstants.expansionVmResilienceTypes.INFRASTRUCTURE ||
            vm.operatingSystemId === this.iQuoteConstants.operatingSystems.WINDOWS) {
            ucZertoCount += vm.quantity
          }

          var ucSqlQty = Math.ceil(vm.vcpu / 2)

          if (vm.sql === true) {
            if (ucSqlQty < 2) {
              ucSqlQty = 2
            }

            ucSqlCount += vm.quantity * ucSqlQty
          }

          ucStorageFastTb += (vm.storageSizeGbStandard / 1000) * vm.quantity
          ucStorageArchiveTb += (vm.storageSizeGbArchive / 1000) * vm.quantity

          vCPURequired += vm.vcpu * vm.quantity

          activeVMCount += vm.quantity

          if (vm.expansionVmResilienceTypeId === this.iQuoteConstants.expansionVmResilienceTypes.APPLICATION) {
            // Additional servers required in Goswell
            ucStorageFastTb += (vm.storageSizeGbStandard / 1000) * vm.quantity
            ucStorageArchiveTb += (vm.storageSizeGbArchive / 1000) * vm.quantity
            vCPURequired += vm.vcpu * vm.quantity
            activeVMCount += vm.quantity

            if (vm.sql === true) {
              ucSqlCount += vm.quantity * ucSqlQty
            }
          }
        })
      }

      // SQL unit Costs
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_DB_SQL_UC, Math.ceil(ucSqlCount))
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_DB_SQL_CC, Math.ceil(ccSqlCount))

      // Zerto unit costs
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_REP_UC_SUP, ucZertoCount)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_REP_CC_SUP, ccZertoCount)

      // Based on number of blades
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_OS_WIN_UC, (ucBladeCount * 16) / 2)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_OS_WIN_CC, (ccBladeCount * 16) / 2)

      // Static unit costs
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_CHA_01, chassisCount)

      // Costs that only apply to years 4-5
      if (quote.termLengthMonths > 36) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_CHA_01_SUP, chassisCount)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_BLD_STD_01_SUP, standardBladeServerCount)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_BLD_HPR_01_SUP, highPowerBladeServerCount)
      }

      // Based on provisioned VMs
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_VM_01_CPU_BASED, vCPURequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_MNT_01, activeVMCount)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SRG_FST_UC, ucStorageFastTb)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SRG_BAK_UC, ucStorageFastTb / 3)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SRG_FST_CC, ccStorageFastTb)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SRG_BAK_CC, ccStorageFastTb / 3)

      if (ucStorageArchiveTb + ccStorageArchiveTb > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SRG_ARC_TB_2, ucStorageArchiveTb + ccStorageArchiveTb)
      }

      // Static unit costs
      if (quoteTechnologyMitelUC.id !== undefined) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_PS_IOH_01, 5)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_PS_OOH_01, 0)
      }

      // Unit costs based on other costs
      var yearlyDcHostingCost = this.unitCostCalcs_yearlyDcHostingCost
      var yearlyDcHostingPrice = yearlyDcHostingCost / ((100 - this.iQuoteStore_GetQuote.marginInfrastructure) / 100)

      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_DC_01, 1, yearlyDcHostingCost, yearlyDcHostingCost, yearlyDcHostingPrice)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_INF_ILC, 1, yearlyDcHostingCost, yearlyDcHostingCost, yearlyDcHostingPrice)
    }
  }
}
