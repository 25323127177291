<template>
  <div
    id="tblSitesAndCircuits"
    class="ptrContainer"
  >
    <div
      v-if="sites.length>0"
      class="ptrTreeView"
    >
      <div class="ptrHeader">
        <div
          id="idptrHeaderSiteCircuit"
          class="ptrHeaderCell ptrHeaderSiteCircuit"
        >
          Site - Circuit
        </div>
        <div
          id="idptrHeaderStatus"
          class="ptrHeaderCell ptrHeaderStatus"
        >
          Status
        </div>
        <!-- <div id="idptrHeaderMos" class="ptrHeaderCell ptrHeaderMos">
          Mos Rating
        </div> -->
        <div
          id="idptrHeaderTickets"
          class="ptrHeaderCell ptrHeaderTickets"
        >
          Open Tickets
        </div>
      </div>
      <SitesCircuitsRow
        v-for="site in sites"
        :key="site.id"
        :site="site"
        :item-ielected="selectedItem"
        :focused-item="focusItem"
        @site-rating-click="siteRatings($event)"
        @site-tickets-click="siteTickets($event)"
        @raise-focus="focusSet($event)"
      />
    </div>
    <div
      v-else
      class="ptrNoRows"
    >
      No sites or circuits found
    </div>
  </div>
</template>
<script>

import SitesCircuitsRow from './sitesAndCircuitsRowComponent'
export default {
  components: {
    SitesCircuitsRow
  },
  props: {
    sites: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedItem: '',
      focusItem: ''
    }
  },
  methods: {
    setSelectedItem (changeItem) {
      if (changeItem === this.selectedItem) {
        return ''
      }
      return changeItem
    },
    raiseSelectedItem (value) {
      this.selectedItem = this.setSelectedItem(value)
      this.$emit('set-selection', this.selectedItem)
    },
    siteTickets (id) {
      this.raiseSelectedItem(`ST${id}`)
    },
    siteRatings (id) {
      this.raiseSelectedItem(`SR${id}`)
    },
    focusSet (id) {
      this.focusItem = id
    }
  }
}
</script>

<style>
  .ptrContainer {
    width:100%;
    display: block;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .ptrTreeView {
    background-color: transparent;
    border-spacing: 0;
    min-width: 600px;
  }
  .ptrHeader {
    display:flex;
    flex-direction: row;
    padding-bottom:5px;
    background-color:var(--main-bg-colour-trans);
    background-color:rgba(44,62,80,0.7);
    color:white;
  }
  .ptrHeaderCell {
    width:150px;
    font-size: 16pt;
    text-align:center;
    padding: 7px 5px 7px 5px;
    white-space: nowrap;
  }
  .ptrHeaderSiteCircuit {
    width:calc(100% - 150px);
    text-align: left;
  }
  .ptrHeaderCircuit {
    margin-left:20px;
    font-size: 12pt;
  }
  @media only screen and (max-width: 1200px) {
    .ptrElement {
      font-size: 12pt;
    }
    .ptrHeaderCell {
      font-size:14pt;
    }
  }
  @media only screen and (max-width: 900px) {
    .ptrElement{
      font-size: 10pt;
    }
    .ptrHeaderCell{
      font-size:12pt;
    }
  }
</style>
