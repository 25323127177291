import portalAPIGateway from '../../../communications/interceptors/apiInterceptor'
import { mapActions } from 'vuex'

export const iQuoteQuoteDesignPacksAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'setQuoteUsers'
    ]),
    iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteQuoteDesignPacksAPIMixin_saveQuoteDesignPackToDB: async function (designPack) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = {}
      var isReadOnly = designPack.isReadOnly || designPack.locked
      var isNewRevision = (designPack.quoteDesignPackRevisionId === 0)

      try {
        await portalAPIGateway.put('iquote/quoteDesignPacks', designPack, axiosConfig).then((response) => {
          data = response.data

          /* restore client-side only values after saving to DB */
          data.isReadOnly = isReadOnly

          if (data.errorSaving === true) {
            if (!isNewRevision) {
              this.iQuoteFunctions_ShowError('Save DesignPack to DB failed and has been reverted to the last known good state')
            } else {
              this.iQuoteFunctions_ShowError('Save DesignPack to DB failed')
            }

            console.log('saveQuoteDesignPacksToDB failed', designPack)
          } else {
            console.log('saved DP', designPack, data)
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('saveQuoteDesignPacksToDB failed!', designPack)
        data = designPack
        this.iQuoteFunctions_ShowError('saveQuoteDesignPacksToDB failed')
      }

      await this.iQuoteQuoteDesignPacksAPIMixin_ensureStoreContainsUsers(data.users)
      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackRevisionsFromDb: async function (quoteDesignPackId) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquotearray/quoteDesignPacks/' + quoteDesignPackId + '/Revisions', axiosConfig).then((response) => {
          data = response.data
          console.log('Retrieved DesignPack revisions for DP ' + quoteDesignPackId, data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackFromDb returned error: ' + error)
      }

      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackFromDb: async function (quoteDesignPackRevisionId) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = {}

      try {
        await portalAPIGateway.get('iquote/quoteDesignPacks/GetByRevisionId/' + quoteDesignPackRevisionId, axiosConfig).then((response) => {
          data = response.data
          data.isReadOnly = true
          console.log('Retrieved DesignPack ' + quoteDesignPackRevisionId, data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackFromDb returned error: ' + error)
      }

      await this.iQuoteQuoteDesignPacksAPIMixin_ensureStoreContainsUsers(data.users)
      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getCircuitLookupTablesFromDb: async function () {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = {}

      try {
        await portalAPIGateway.get('iquote/quoteDesignPacks/GetCircuitLookupTables/', axiosConfig).then((response) => {
          data = response.data
          console.log('Retrieved Circuit Lookup Tables ', data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getCircuitLookupTablesFromDb returned error: ' + error)
      }

      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getHardwareLookupTablesFromDb: async function () {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = {}

      try {
        await portalAPIGateway.get('iquote/quoteDesignPacks/GetHardwareLookupTables/', axiosConfig).then((response) => {
          data = response.data
          console.log('Retrieved Hardware Lookup Tables ', data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getHardwareLookupTablesFromDb returned error: ' + error)
      }

      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_requestDesignPackUser: async function (quoteDesignPackRevisionId, designPackUserRequest) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.put('iquotearray/quoteDesignPacks/' + quoteDesignPackRevisionId + '/RequestDesignPackUser', designPackUserRequest, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_requestDesignPackUser returned error: ' + error)
      }

      await this.iQuoteQuoteDesignPacksAPIMixin_ensureStoreContainsUsers(data)
      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_ensureStoreContainsUsers: async function (users) {
      var storeUsers = this.$store.getters.quoteUsers
      var foundUser = {}

      // loop through all users passed in
      for (let index = 0; index < users.length; index++) {
        const user = users[index]

        // check if the user is in the store
        foundUser = storeUsers.find(x => x.id === user.userId)

        if (foundUser === undefined) {
          // Not in the store, so request users service to find details
          var userFromUserService = await this.getUserById(user.userId)
          storeUsers.push(userFromUserService)
        }
      }

      this.setQuoteUsers(storeUsers)
    },
    iQuoteQuoteDesignPacksAPIMixin_releaseDesignPackUser: async function (quoteDesignPackRevisionId) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = []

      try {
        console.log('Sending ReleaseDesignPackUser on DP Revision ' + quoteDesignPackRevisionId)
        await portalAPIGateway.put('iquotearray/quoteDesignPacks/' + quoteDesignPackRevisionId + '/ReleaseDesignPackUser/', this.$store.getters.userid, axiosConfig).then((response) => {
          data = response.data
          console.log('Retrieved Response to ReleaseDesignPackUser on DP Revision' + quoteDesignPackRevisionId, data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_releaseDesignPackUser returned error: ' + error)
      }

      await this.iQuoteQuoteDesignPacksAPIMixin_ensureStoreContainsUsers(data)
      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getDesignPackUsers: async function (quoteDesignPackRevisionId) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquotearray/quoteDesignPacks/' + quoteDesignPackRevisionId + '/GetDesignPackUsers', axiosConfig).then((response) => {
          data = response.data
          console.log('Retrieved Response to ReleaseDesignPackUser on DP Revision' + quoteDesignPackRevisionId, data)
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getDesignPackUsers returned error: ' + error)
      }

      await this.iQuoteQuoteDesignPacksAPIMixin_ensureStoreContainsUsers(data)
      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackAutotaskEntriesFromDb: async function (quoteDesignPackRevisionId) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = null

      try {
        await portalAPIGateway.get('iquotearray/quoteDesignPacks/GetAutotaskEntriesByRevisionId/' + quoteDesignPackRevisionId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackAutotaskEntriesFromDb returned error: ' + error)
      }

      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackCustomerAutotaskEntriesFromDb: async function (quoteDesignPackRevisionId) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = null

      try {
        await portalAPIGateway.get('iquotearray/quoteDesignPacks/GetCustomerAutotaskEntriesByRevisionId/' + quoteDesignPackRevisionId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackCustomerAutotaskEntriesFromDb returned error: ' + error)
        return []
      }

      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPacksByOpportunityFromDb: async function (opportunityNumber) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquotearray/quoteDesignPacks/GetByOpportunityNumber/' + opportunityNumber, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('getQuoteDesignPacksByOpportunityFromDb returned error: ' + error)
      }

      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackUserAuditsByDPNumberFromDb: async function (quoteDesignPackId) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquote/DesignPackUserAudit/' + quoteDesignPackId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackUserAuditsByDPNumberFromDb returned error: ' + error)
      }

      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackLast7DaysUserAuditsFromDb: async function (action) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = []
      try {
        await portalAPIGateway.get(`iquote/DesignPackUserAudit/GetLast7Days/${action}`, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackLast7DaysUserAuditsFromDb returned error: ' + error)
      }

      return data
    },
    async iQuoteQuoteDesignPacksAPIMixin_addAuditing (action, revisionNumber, originalDPNumber) {
      const designPackUserAudit = {
        quoteDesignPackId: this.iQuoteStore_GetDesignPack.quoteDesignPackId,
        revisionNumber: revisionNumber,
        userId: this.$store.getters.userid,
        action: action,
        duplicateId: originalDPNumber
      }
      await this.iQuoteQuoteDesignPacksAPIMixin_addDesignPackUserAudit(designPackUserAudit)
    },
    iQuoteQuoteDesignPacksAPIMixin_addDesignPackUserAudit: async function (designPackUserAudit) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = []
      const userId = this.$store.getters.userid

      try {
        await portalAPIGateway.post(`iquote/DesignPackUserAudit/${userId}/`, designPackUserAudit, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_addDesignPackUserAudit returned error: ' + error)
      }

      return data
    },
    iQuoteQuoteDesignPacksAPIMixin_saveDetailData: async function (quoteDesignPackRevisionId, detailDataEntries) {
      var invalidEntryFound = false

      for (let index = 0; index < detailDataEntries.length; index++) {
        const entry = detailDataEntries[index]

        if (entry.unitARExternalCost === null || isNaN(entry.unitARExternalCost)) {
          console.log('Error unitARExternalCost is null or NaN for entry ' + entry.description)
          invalidEntryFound = true
          this.iQuoteFunctions_ShowError('Error saving DP Detail Data. unitARExternalCost was null for entry ' + entry.description)
        }
        if (entry.unitARInternalCost === null || isNaN(entry.unitARInternalCost)) {
          invalidEntryFound = true
          console.log('Error unitARInternalCost is null or NaN for entry ' + entry.description)
          this.iQuoteFunctions_ShowError('Error saving DP Detail Data. unitARInternalCost was null for entry ' + entry.description)
        }
        if (entry.unitOOExternalCost === null || isNaN(entry.unitOOExternalCost)) {
          invalidEntryFound = true
          console.log('Error unitOOExternalCost is null or NaN for entry ' + entry.description)
          this.iQuoteFunctions_ShowError('Error saving DP Detail Data. unitOOExternalCost was null for entry ' + entry.description)
        }
        if (entry.unitOOInternalCost === null || isNaN(entry.unitOOInternalCost)) {
          invalidEntryFound = true
          console.log('Error unitOOInternalCost is null or NaN for entry ' + entry.description)
          this.iQuoteFunctions_ShowError('Error saving DP Detail Data. unitOOInternalCost was null for entry ' + entry.description)
        }
        if (entry.unitARExternalPrice === null || isNaN(entry.unitARExternalPrice)) {
          invalidEntryFound = true
          console.log('Error unitARExternalPrice is null or NaN for entry ' + entry.description)
          this.iQuoteFunctions_ShowError('Error saving DP Detail Data. unitARExternalPrice was null for entry ' + entry.description)
        }
        if (entry.unitARInternalPrice === null || isNaN(entry.unitARInternalPrice)) {
          invalidEntryFound = true
          console.log('Error unitARInternalPrice is null or NaN for entry ' + entry.description)
          this.iQuoteFunctions_ShowError('Error saving DP Detail Data. unitARInternalPrice was null for entry ' + entry.description)
        }
        if (entry.unitOOExternalPrice === null || isNaN(entry.unitOOExternalPrice)) {
          invalidEntryFound = true
          console.log('Error unitOOExternalPrice is null or NaN for entry ' + entry.description)
          this.iQuoteFunctions_ShowError('Error saving DP Detail Data. unitOOExternalPrice was null for entry ' + entry.description)
        }
        if (entry.unitOOInternalPrice === null || isNaN(entry.unitOOInternalPrice)) {
          invalidEntryFound = true
          console.log('Error unitOOInternalPrice is null or NaN for entry ' + entry.description)
          this.iQuoteFunctions_ShowError('Error saving DP Detail Data. unitOOInternalPrice was null for entry ' + entry.description)
        }
      }

      if (invalidEntryFound === true) {
        return
      }

      console.log('Saving Detail Data ', detailDataEntries)

      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()

      try {
        await portalAPIGateway.put('iquotearray/quoteDesignPacks/' + quoteDesignPackRevisionId + '/DetailedData', detailDataEntries, axiosConfig)
      } catch (error) {
        this.iQuoteFunctions_ShowError('Error saving DP Detail Data.  Possible that a price could not be calculated')

        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_saveDetailData returned error: ' + error)
      }
    },
    iQuoteQuoteDesignPacksAPIMixin_getDetailedData: async function (quoteDesignPackRevisionId) {
      var axiosConfig = this.iQuoteQuoteDesignPacksAPIMixin_getAxiosConfig()
      var data = null

      try {
        await portalAPIGateway.get('iquotearray/quoteDesignPacks/' + quoteDesignPackRevisionId + '/DetailedData', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteDesignPacksAPIMixin_getDetailedData returned error: ' + error)
        return []
      }

      return data
    }
  }
}
