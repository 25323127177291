import { mapActions } from 'vuex'
import { iQuoteQuoteBomsAPIMixin } from '../API/externalQuotes/iQuoteQuoteBomsAPIMixin'
import { iQuoteQuoteIconConnectAPIMixin } from '../API/externalQuotes/iQuoteQuoteIconConnectAPIMixin'
import { iQuoteQuoteIconNowAPIMixin } from '../API/externalQuotes/iQuoteQuoteIconNowAPIMixin'
import { iQuoteQuoteManagedServiceAPIMixin } from '../API/externalQuotes/iQuoteQuoteManagedServiceAPIMixin'
import { iQuoteQuoteNewManagedServiceAPIMixin } from '../API/externalQuotes/iQuoteQuoteNewManagedServiceAPIMixin'
import { iQuoteQuoteCallsAndLinesAPIMixin } from '../API/externalQuotes/iQuoteQuoteCallsAndLinesAPIMixin'
import { iQuoteQuoteHorizonAPIMixin } from '../API/externalQuotes/iQuoteQuoteHorizonAPIMixin'
import { iQuoteQuoteIconSecureAPIMixin } from '../API/externalQuotes/iQuoteQuoteIconSecureAPIMixin'
import { iQuoteQuoteSecureHomeworkerAPIMixin } from '../API/externalQuotes/iQuoteQuoteSecureHomeworkerAPIMixin'
import { iQuoteQuoteTwilioAPIMixin } from '../API/externalQuotes/iQuoteQuoteTwilioAPIMixin'
import { iQuoteStaticDataAPIMixin } from '../iQuoteStaticDataAPIMixin'
import { UploadsAPIMixin } from '../../uploadsAPIMixin'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const externalQuotesModelMixin = {
  mixins: [
    iQuoteQuoteBomsAPIMixin,
    iQuoteStoreMixin,
    UploadsAPIMixin,
    iQuoteQuoteIconConnectAPIMixin,
    iQuoteQuoteIconNowAPIMixin,
    iQuoteQuoteManagedServiceAPIMixin,
    iQuoteQuoteNewManagedServiceAPIMixin,
    iQuoteQuoteCallsAndLinesAPIMixin,
    iQuoteQuoteHorizonAPIMixin,
    iQuoteQuoteIconSecureAPIMixin,
    iQuoteQuoteSecureHomeworkerAPIMixin,
    iQuoteQuoteTwilioAPIMixin,
    iQuoteStaticDataAPIMixin
  ],
  data () {
    return {
      externalQuotesModelMixin: {
        salesforceCoeCodes: [],
        isLoading: true
      }
    }
  },
  computed: {
    externalQuotesModelMixin_State () {
      return this.$store.getters.currentQuoteExternalQuotesState
    }
  },
  async mounted () {
    this.$store.watch(
      (state, getters) => getters.quote,
      async (newValue) => {
        var quoteId = newValue.id

        await this.externalQuotesModelMixin_initialise(quoteId)
      }
    )

    await this.externalQuotesModelMixin_initialise(this.iQuoteStore_GetQuote.id)
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteExternalQuotesState'
    ]),
    async externalQuotesModelMixin_initialise (quoteId) {
      if (quoteId !== undefined && quoteId !== 0) {
        await this.externalQuotesModelMixin_loadExternalQuotes(quoteId)
      } else if (quoteId !== 0) {
        var externalQuotesState = this.externalQuotesModelMixin_State

        externalQuotesState.quoteId = 0
        externalQuotesState.quoteBoms = []
        externalQuotesState.quoteIconConnect = {}
        externalQuotesState.quoteManagedService = {}
        externalQuotesState.quoteNewManagedService = {}
        externalQuotesState.quoteCallsAndLines = {}
        externalQuotesState.quoteHorizon = {}
        externalQuotesState.quoteIconSecure = {}
        externalQuotesState.quoteIconNow = {}
        externalQuotesState.quoteTwilio = {}

        this.setCurrentQuoteExternalQuotesState(externalQuotesState)
      }
    },
    async externalQuotesModelMixin_loadExternalQuotes (quoteId) {
      if (quoteId === undefined) {
        // When this mixin is first loaded, it's possible that quote hasn't been loaded yet.
        // We have a watch on the quote, so will load it when it is loaded.
        return
      }

      this.externalQuotesModelMixin.isLoading = true
      var state = this.externalQuotesModelMixin_State

      if (state.quoteId !== quoteId) {
        // Store doesn't have model for current quote, load it from DB

        state.quoteId = quoteId
        var quoteBomsFromDB = await this.iQuoteQuoteBomsAPIMixin_getQuoteBomsFromDb(quoteId)

        if (quoteBomsFromDB.length === 0) {
          state.quoteBoms = []
        } else {
          state.quoteBoms = quoteBomsFromDB

          // Query the file uploads service to find all previous versions of the file that's attached to this BOM
          for (let i = 0; i < state.quoteBoms.length; i++) {
            const quoteBom = state.quoteBoms[i]
            quoteBom.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(quoteBom.files)
          }
        }

        var quoteIconConnectFromDB = await this.iQuoteQuoteIconConnectAPIMixin_getQuoteIconConnectFromDb(quoteId)

        if (quoteIconConnectFromDB.id === undefined) {
          state.quoteIconConnect = {}
        } else {
          state.quoteIconConnect = quoteIconConnectFromDB
          // Query the file uploads service to find all previous versions of the file that's attached to Icon Connect
          state.quoteIconConnect.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteIconConnect.files)
        }

        var quoteManagedServiceFromDB = await this.iQuoteQuoteManagedServiceAPIMixin_getQuoteManagedServiceFromDb(quoteId)

        if (quoteManagedServiceFromDB.id === undefined) {
          state.quoteManagedService = {}
        } else {
          state.quoteManagedService = quoteManagedServiceFromDB
          // Query the file uploads service to find all previous versions of the file that's attached to Managed Service
          state.quoteManagedService.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteManagedService.files)
        }

        var quoteNewManagedServiceFromDB = await this.iQuoteQuoteNewManagedServiceAPIMixin_getQuoteNewManagedServiceFromDb(quoteId)

        if (quoteNewManagedServiceFromDB.id === undefined) {
          state.quoteNewManagedService = {}
        } else {
          state.quoteNewManagedService = quoteNewManagedServiceFromDB
          // Query the file uploads service to find all previous versions of the file that's attached to New Managed Service
          state.quoteNewManagedService.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteNewManagedService.files)
        }

        var quoteCallsAndLinesFromDB = await this.iQuoteQuoteCallsAndLinesAPIMixin_getQuoteCallsAndLinesFromDb(quoteId)

        if (quoteCallsAndLinesFromDB.id === undefined) {
          state.quoteCallsAndLines = {}
        } else {
          state.quoteCallsAndLines = quoteCallsAndLinesFromDB
          // Query the file uploads service to find all previous versions of the file that's attached to CallsAndLines
          state.quoteCallsAndLines.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteCallsAndLines.files)
        }

        var quoteHorizonFromDB = await this.iQuoteQuoteHorizonAPIMixin_getQuoteHorizonFromDb(quoteId)

        if (quoteHorizonFromDB.id === undefined) {
          state.quoteHorizon = {}
        } else {
          state.quoteHorizon = quoteHorizonFromDB
          // Query the file uploads service to find all previous versions of the file that's attached to Horizon
          state.quoteHorizon.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteHorizon.files)
        }

        var quoteIconSecureFromDB = await this.iQuoteQuoteIconSecureAPIMixin_getQuoteIconSecureFromDb(quoteId)

        if (quoteIconSecureFromDB.id === undefined) {
          state.quoteIconSecure = {}
        } else {
          state.quoteIconSecure = quoteIconSecureFromDB
          // Query the file uploads service to find all previous versions of the file that's attached to Icon Secure
          state.quoteIconSecure.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteIconSecure.files)
        }

        var quoteIconNowFromDB = await this.iQuoteQuoteIconNowAPIMixin_getQuoteIconNowFromDb(quoteId)

        if (quoteIconNowFromDB.id === undefined) {
          state.quoteIconNow = {}
        } else {
          state.quoteIconNow = quoteIconNowFromDB
          // Query the file uploads service to find all previous versions of the file that's attached to Icon Now
          state.quoteIconNow.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteIconNow.files)
        }

        var quoteTwilioFromDB = await this.iQuoteQuoteTwilioAPIMixin_getQuoteTwilioFromDb(quoteId)

        if (quoteTwilioFromDB.id === undefined) {
          state.quoteTwilio = {}
        } else {
          state.quoteTwilio = quoteTwilioFromDB
          // Query the file uploads service to find all previous versions of the file that's attached to Twilio
          state.quoteTwilio.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteTwilio.files)
        }

        this.setCurrentQuoteExternalQuotesState(state)
      }

      this.externalQuotesModelMixin.isLoading = false
    },
    externalQuotesModelMixin_reset () {
      var state = this.externalQuotesModelMixin_State
      state.currentPage = 'rootPage'
      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_setCurrentPage (newPage) {
      var state = this.externalQuotesModelMixin_State
      state.currentPage = newPage
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_getFileVersionInfoFromUploadsService (filesArray) {
      var fileDetails
      if (filesArray.length > 0) {
        for (let j = 0; j < filesArray.length; j++) {
          const file = filesArray[j]

          if (file.title === undefined) {
            fileDetails = await this.UploadsAPIMixin_GetFileUploadCatalogEntry('iQuote', file.fileUploadId)
            var latestFileVersion = this.externalQuotesModelMixin_getLatestFileVersion(fileDetails)

            file.title = fileDetails.title
            file.fileName = latestFileVersion.name
            file.versionList = fileDetails.versionList
          }
        }
      }
      return filesArray
    },
    externalQuotesModelMixin_getLatestFileVersion (fileDetails) {
      if (fileDetails.versionList === undefined) {
        return {}
      }

      var latestFileVersion = {}

      for (let index = 0; index < fileDetails.versionList.length; index++) {
        const fileVersionInArray = fileDetails.versionList[index]

        if (latestFileVersion.fileVersionId === undefined || fileVersionInArray.versionNumber > latestFileVersion.versionNumber) {
          latestFileVersion = fileVersionInArray
        }
      }

      return latestFileVersion
    },

    /*
        BOMs
    */

    externalQuotesModelMixin_setSelectedQuoteBom (newBom) {
      var state = this.externalQuotesModelMixin_State
      state.selectedQuoteBom = newBom
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleBomDeleted (quoteBom) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteBomsAPIMixin_deleteQuoteBomFromDB(quoteBom)
      state.quoteBoms = state.quoteBoms.filter(x => x.id !== quoteBom.id)
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleBomUpdated (quoteBom) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId
      var quoteBoms = state.quoteBoms
      var isNewBom = (quoteBom.id <= 0)

      // save the boms to the DB global store
      quoteBom = await this.iQuoteQuoteBomsAPIMixin_saveQuoteBomToDB(quoteId, quoteBom)

      quoteBom.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(quoteBom.files)

      if (isNewBom) {
        // New entry so add to array
        quoteBoms.push(quoteBom)
      } else {
        // existing entry so replace it
        for (let index = 0; index < quoteBoms.length; index++) {
          const existingBom = quoteBoms[index]
          if (existingBom.id === quoteBom.id) {
            quoteBoms[index] = quoteBom
          }
        }
      }

      state.quoteBoms = quoteBoms
      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getBomItemTotalUpfrontCost (bomItem) {
      return this.externalQuotesModelMixin_getBomItemTotalUpfrontExternalCost(bomItem) + this.externalQuotesModelMixin_getBomItemTotalUpfrontInternalCost(bomItem)
    },
    externalQuotesModelMixin_getBomItemTotalRecurringCost (bomItem) {
      return this.externalQuotesModelMixin_getBomItemTotalRecurringExternalCost(bomItem) + this.externalQuotesModelMixin_getBomItemTotalRecurringInternalCost(bomItem)
    },
    externalQuotesModelMixin_getBomItemTotalYear1Cost (bomItem) {
      return this.externalQuotesModelMixin_getBomItemTotalUpfrontCost(bomItem) + this.externalQuotesModelMixin_getBomItemTotalRecurringCost(bomItem)
    },
    externalQuotesModelMixin_getBomItemTotalContractCost (bomItem) {
      return this.externalQuotesModelMixin_getBomItemTotalUpfrontCost(bomItem) + (this.externalQuotesModelMixin_getBomItemTotalRecurringCost(bomItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getBomItemTotalUpfrontExternalCost (bomItem) {
      var total = 0

      bomItem.entries.forEach(entry => {
        total += entry.upfrontExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getBomItemTotalUpfrontInternalCost (bomItem) {
      var total = 0

      bomItem.entries.forEach(entry => {
        total += entry.upfrontInternalCost
      })

      return total
    },
    externalQuotesModelMixin_getBomItemTotalRecurringExternalCost (bomItem) {
      var total = 0

      bomItem.entries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getBomItemTotalRecurringInternalCost (bomItem) {
      var total = 0

      bomItem.entries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },

    /*
        ICON CONNECT
    */
    externalQuotesModelMixin_setSelectedIconConnect (newIconConnect) {
      var state = this.externalQuotesModelMixin_State
      state.quoteIconConnect = newIconConnect
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleIconConnectDeleted (quoteIconConnect) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteIconConnectAPIMixin_deleteQuoteIconConnectFromDB(quoteIconConnect)
      state.quoteIconConnect = {}
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleIconConnectUpdated (quoteIconConnect) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId

      // save the icon connect to the DB global store
      var quoteIconConnectFromDB = await this.iQuoteQuoteIconConnectAPIMixin_saveQuoteIconConnectToDB(quoteId, quoteIconConnect)
      state.quoteIconConnect = quoteIconConnectFromDB
      state.quoteIconConnect.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteIconConnect.files)

      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getIconConnectCostSummaryTotalUpfrontCost (iconConnectItem) {
      return this.externalQuotesModelMixin_getIconConnectCostSummaryTotalUpfrontExternalCost(iconConnectItem) + this.externalQuotesModelMixin_getIconConnectCostSummaryTotalUpfrontInternalCost(iconConnectItem)
    },
    externalQuotesModelMixin_getIconConnectCostSummaryTotalRecurringCost (iconConnectItem) {
      return this.externalQuotesModelMixin_getIconConnectCostSummaryTotalRecurringExternalCost(iconConnectItem) + this.externalQuotesModelMixin_getIconConnectCostSummaryTotalRecurringInternalCost(iconConnectItem)
    },
    externalQuotesModelMixin_getIconConnectCostSummaryTotalYear1Cost (iconConnectItem) {
      return this.externalQuotesModelMixin_getIconConnectCostSummaryTotalUpfrontCost(iconConnectItem) + this.externalQuotesModelMixin_getIconConnectCostSummaryTotalRecurringCost(iconConnectItem)
    },
    externalQuotesModelMixin_getIconConnectCostSummaryTotalContractCost (iconConnectItem) {
      return this.externalQuotesModelMixin_getIconConnectCostSummaryTotalUpfrontCost(iconConnectItem) + (this.externalQuotesModelMixin_getIconConnectCostSummaryTotalRecurringCost(iconConnectItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getIconConnectCostSummaryTotalUpfrontExternalCost (iconConnectItem) {
      var total = 0

      iconConnectItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getIconConnectCostSummaryTotalUpfrontInternalCost (iconConnectItem) {
      var total = 0

      iconConnectItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalCost
      })

      return total
    },
    externalQuotesModelMixin_getIconConnectCostSummaryTotalRecurringExternalCost (iconConnectItem) {
      var total = 0

      iconConnectItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getIconConnectCostSummaryTotalRecurringInternalCost (iconConnectItem) {
      var total = 0

      iconConnectItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },

    /*
        ICON NOW
    */
    externalQuotesModelMixin_setSelectedIconNow (newIconNow) {
      var state = this.externalQuotesModelMixin_State
      state.quoteIconNow = newIconNow
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleIconNowDeleted (quoteIconNow) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteIconNowAPIMixin_deleteQuoteIconNowFromDB(quoteIconNow)
      state.quoteIconNow = {}
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleIconNowUpdated (quoteIconNow) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId

      // save the icon now to the DB global store
      var quoteIconNowFromDB = await this.iQuoteQuoteIconNowAPIMixin_saveQuoteIconNowToDB(quoteId, quoteIconNow)
      state.quoteIconNow = quoteIconNowFromDB
      state.quoteIconNow.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteIconNow.files)

      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getIconNowCostSummaryTotalUpfrontCost (iconNowItem) {
      return this.externalQuotesModelMixin_getIconNowCostSummaryTotalUpfrontExternalCost(iconNowItem) + this.externalQuotesModelMixin_getIconNowCostSummaryTotalUpfrontInternalCost(iconNowItem)
    },
    externalQuotesModelMixin_getIconNowCostSummaryTotalRecurringCost (iconNowItem) {
      return this.externalQuotesModelMixin_getIconNowCostSummaryTotalRecurringExternalCost(iconNowItem) + this.externalQuotesModelMixin_getIconNowCostSummaryTotalRecurringInternalCost(iconNowItem)
    },
    externalQuotesModelMixin_getIconNowCostSummaryTotalYear1Cost (iconNowItem) {
      return this.externalQuotesModelMixin_getIconNowCostSummaryTotalUpfrontCost(iconNowItem) + this.externalQuotesModelMixin_getIconNowCostSummaryTotalRecurringCost(iconNowItem)
    },
    externalQuotesModelMixin_getIconNowCostSummaryTotalContractCost (iconNowItem) {
      return this.externalQuotesModelMixin_getIconNowCostSummaryTotalUpfrontCost(iconNowItem) + (this.externalQuotesModelMixin_getIconNowCostSummaryTotalRecurringCost(iconNowItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getIconNowCostSummaryTotalUpfrontExternalCost (iconNowItem) {
      var total = 0

      iconNowItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getIconNowCostSummaryTotalUpfrontInternalCost (iconNowItem) {
      var total = 0

      iconNowItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalCost
      })

      return total
    },
    externalQuotesModelMixin_getIconNowCostSummaryTotalRecurringExternalCost (iconNowItem) {
      var total = 0

      iconNowItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getIconNowCostSummaryTotalRecurringInternalCost (iconNowItem) {
      var total = 0

      iconNowItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },

    /*
        SECURE HOMEWORKER
    */
    externalQuotesModelMixin_setSelectedSecureHomeworker (newSecureHomeworker) {
      var state = this.externalQuotesModelMixin_State
      state.quoteSecureHomeworker = newSecureHomeworker
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleSecureHomeworkerDeleted (quoteSecureHomeworker) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteSecureHomeworkerAPIMixin_deleteQuoteSecureHomeworkerFromDB(quoteSecureHomeworker)
      state.quoteSecureHomeworker = {}
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleSecureHomeworkerUpdated (quoteSecureHomeworker) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId

      // save the icon now to the DB global store
      var quoteSecureHomeworkerFromDB = await this.iQuoteQuoteSecureHomeworkerAPIMixin_saveQuoteSecureHomeworkerToDB(quoteId, quoteSecureHomeworker)
      state.quoteSecureHomeworker = quoteSecureHomeworkerFromDB
      state.quoteSecureHomeworker.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteSecureHomeworker.files)

      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalUpfrontCost (iconNowItem) {
      return this.externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalUpfrontExternalCost(iconNowItem) + this.externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalUpfrontInternalCost(iconNowItem)
    },
    externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalRecurringCost (iconNowItem) {
      return this.externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalRecurringExternalCost(iconNowItem) + this.externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalRecurringInternalCost(iconNowItem)
    },
    externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalYear1Cost (iconNowItem) {
      return this.externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalUpfrontCost(iconNowItem) + this.externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalRecurringCost(iconNowItem)
    },
    externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalContractCost (iconNowItem) {
      return this.externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalUpfrontCost(iconNowItem) + (this.externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalRecurringCost(iconNowItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalUpfrontExternalCost (iconNowItem) {
      var total = 0

      iconNowItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalUpfrontInternalCost (iconNowItem) {
      var total = 0

      iconNowItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalCost
      })

      return total
    },
    externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalRecurringExternalCost (iconNowItem) {
      var total = 0

      iconNowItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getSecureHomeworkerCostSummaryTotalRecurringInternalCost (iconNowItem) {
      var total = 0

      iconNowItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },

    /*
        MANAGED SERVICE
    */

    externalQuotesModelMixin_setSelectedManagedService (managedService) {
      var state = this.externalQuotesModelMixin_State
      state.quoteManagedService = managedService
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleManagedServiceDeleted (quoteManagedService) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteManagedServiceAPIMixin_deleteQuoteManagedServiceFromDB(quoteManagedService)
      state.quoteManagedService = {}
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleManagedServiceUpdated (quoteManagedService) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId

      // save the Managed Service to the DB global store
      var quoteManagedServiceFromDB = await this.iQuoteQuoteManagedServiceAPIMixin_saveQuoteManagedServiceToDB(quoteId, quoteManagedService)
      state.quoteManagedService = quoteManagedServiceFromDB
      state.quoteManagedService.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteManagedService.files)

      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getManagedServiceCostSummaryTotalUpfrontCost (managedServiceItem) {
      return this.externalQuotesModelMixin_getManagedServiceCostSummaryTotalUpfrontExternalCost(managedServiceItem) + this.externalQuotesModelMixin_getManagedServiceCostSummaryTotalUpfrontInternalCost(managedServiceItem)
    },
    externalQuotesModelMixin_getManagedServiceCostSummaryTotalRecurringCost (managedServiceItem) {
      return this.externalQuotesModelMixin_getManagedServiceCostSummaryTotalRecurringExternalCost(managedServiceItem) + this.externalQuotesModelMixin_getManagedServiceCostSummaryTotalRecurringInternalCost(managedServiceItem)
    },
    externalQuotesModelMixin_getManagedServiceCostSummaryTotalYear1Cost (managedServiceItem) {
      return this.externalQuotesModelMixin_getManagedServiceCostSummaryTotalUpfrontCost(managedServiceItem) + this.externalQuotesModelMixin_getManagedServiceCostSummaryTotalRecurringCost(managedServiceItem)
    },
    externalQuotesModelMixin_getManagedServiceCostSummaryTotalContractCost (managedServiceItem) {
      return this.externalQuotesModelMixin_getManagedServiceCostSummaryTotalUpfrontCost(managedServiceItem) + (this.externalQuotesModelMixin_getManagedServiceCostSummaryTotalRecurringCost(managedServiceItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getManagedServiceCostSummaryTotalUpfrontExternalCost (managedServiceItem) {
      var total = 0

      managedServiceItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getManagedServiceCostSummaryTotalUpfrontInternalCost (managedServiceItem) {
      var total = 0

      managedServiceItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalCost
      })

      return total
    },
    externalQuotesModelMixin_getManagedServiceCostSummaryTotalRecurringExternalCost (managedServiceItem) {
      var total = 0

      managedServiceItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getManagedServiceCostSummaryTotalRecurringInternalCost (managedServiceItem) {
      var total = 0

      managedServiceItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },

    /*
        NEW MANAGED SERVICE
    */

    externalQuotesModelMixin_setSelectedNewManagedService (newManagedService) {
      var state = this.externalQuotesModelMixin_State
      state.quoteNewManagedService = newManagedService
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleNewManagedServiceDeleted (quoteNewManagedService) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteNewManagedServiceAPIMixin_deleteQuoteNewManagedServiceFromDB(quoteNewManagedService)
      state.quoteNewManagedService = {}
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleNewManagedServiceUpdated (quoteNewManagedService) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId

      // save the New Managed Service to the DB global store
      var quoteNewManagedServiceFromDB = await this.iQuoteQuoteNewManagedServiceAPIMixin_saveQuoteNewManagedServiceToDB(quoteId, quoteNewManagedService)
      state.quoteNewManagedService = quoteNewManagedServiceFromDB
      state.quoteNewManagedService.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteNewManagedService.files)

      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalUpfrontCost (newManagedServiceItem) {
      return this.externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalUpfrontExternalCost(newManagedServiceItem) + this.externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalUpfrontInternalCost(newManagedServiceItem)
    },
    externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalRecurringCost (newManagedServiceItem) {
      return this.externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalRecurringExternalCost(newManagedServiceItem) + this.externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalRecurringInternalCost(newManagedServiceItem)
    },
    externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalYear1Cost (newManagedServiceItem) {
      return this.externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalUpfrontCost(newManagedServiceItem) + this.externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalRecurringCost(newManagedServiceItem)
    },
    externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalContractCost (newManagedServiceItem) {
      return this.externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalUpfrontCost(newManagedServiceItem) + (this.externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalRecurringCost(newManagedServiceItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalUpfrontExternalCost (newManagedServiceItem) {
      var total = 0

      newManagedServiceItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalCost
      })
      return total
    },
    externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalUpfrontInternalCost (newManagedServiceItem) {
      var total = 0

      newManagedServiceItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalCost
      })
      return total
    },
    externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalRecurringExternalCost (newManagedServiceItem) {
      var total = 0

      newManagedServiceItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getNewManagedServiceCostSummaryTotalRecurringInternalCost (newManagedServiceItem) {
      var total = 0

      newManagedServiceItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },

    /*
        CALLS AND LINES
    */

    externalQuotesModelMixin_setSelectedCallsAndLines (newCallsAndLines) {
      var state = this.externalQuotesModelMixin_State
      state.quoteCallsAndLines = newCallsAndLines
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleCallsAndLinesDeleted (quoteCallsAndLines) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteCallsAndLinesAPIMixin_deleteQuoteCallsAndLinesFromDB(quoteCallsAndLines)
      state.quoteCallsAndLines = {}
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleCallsAndLinesUpdated (quoteCallsAndLines) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId

      // save the calls and lines to the DB global store
      var quoteCallsAndLinesFromDB = await this.iQuoteQuoteCallsAndLinesAPIMixin_saveQuoteCallsAndLinesToDB(quoteId, quoteCallsAndLines)
      state.quoteCallsAndLines = quoteCallsAndLinesFromDB
      state.quoteCallsAndLines.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteCallsAndLines.files)

      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalUpfrontCost (callsAndLinesItem) {
      return this.externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalUpfrontExternalCost(callsAndLinesItem) + this.externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalUpfrontInternalCost(callsAndLinesItem)
    },
    externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalRecurringCost (callsAndLinesItem) {
      return this.externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalRecurringExternalCost(callsAndLinesItem) + this.externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalRecurringInternalCost(callsAndLinesItem)
    },
    externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalYear1Cost (callsAndLinesItem) {
      return this.externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalUpfrontCost(callsAndLinesItem) + this.externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalRecurringCost(callsAndLinesItem)
    },
    externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalContractCost (callsAndLinesItem) {
      return this.externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalUpfrontCost(callsAndLinesItem) + (this.externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalRecurringCost(callsAndLinesItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalUpfrontExternalCost (callsAndLinesItem) {
      var total = 0

      callsAndLinesItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalUpfrontInternalCost (callsAndLinesItem) {
      var total = 0

      callsAndLinesItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalCost
      })

      return total
    },
    externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalRecurringExternalCost (callsAndLinesItem) {
      var total = 0

      callsAndLinesItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getCallsAndLinesCostSummaryTotalRecurringInternalCost (callsAndLinesItem) {
      var total = 0

      callsAndLinesItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },

    /*
        HORIZON
    */

    externalQuotesModelMixin_setSelectedHorizon (newHorizon) {
      var state = this.externalQuotesModelMixin_State
      state.quoteHorizon = newHorizon
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleHorizonDeleted (quoteHorizon) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteHorizonAPIMixin_deleteQuoteHorizonFromDB(quoteHorizon)
      state.quoteHorizon = {}
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleHorizonUpdated (quoteHorizon) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId

      // save the horizon to the DB global store
      var quoteHorizonFromDB = await this.iQuoteQuoteHorizonAPIMixin_saveQuoteHorizonToDB(quoteId, quoteHorizon)
      state.quoteHorizon = quoteHorizonFromDB
      state.quoteHorizon.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteHorizon.files)

      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontCost (horizonItem) {
      return this.externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontExternalCost(horizonItem) + this.externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontInternalCost(horizonItem)
    },
    externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringCost (horizonItem) {
      return this.externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringExternalCost(horizonItem) + this.externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringInternalCost(horizonItem)
    },
    externalQuotesModelMixin_getHorizonCostSummaryTotalYear1Cost (horizonItem) {
      return this.externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontCost(horizonItem) + this.externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringCost(horizonItem)
    },
    externalQuotesModelMixin_getHorizonCostSummaryTotalContractCost (horizonItem) {
      return this.externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontCost(horizonItem) + (this.externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringCost(horizonItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontExternalCost (horizonItem) {
      var total = 0

      horizonItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontInternalCost (horizonItem) {
      var total = 0

      horizonItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalCost
      })

      return total
    },
    externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringExternalCost (horizonItem) {
      var total = 0

      horizonItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringInternalCost (horizonItem) {
      var total = 0

      horizonItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },

    /*
        ICON SECURE
    */
    externalQuotesModelMixin_setSelectedIconSecure (newIconSecure) {
      var state = this.externalQuotesModelMixin_State
      state.quoteIconSecure = newIconSecure
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleIconSecureDeleted (quoteIconSecure) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteIconSecureAPIMixin_deleteQuoteIconSecureFromDB(quoteIconSecure)
      state.quoteIconSecure = {}
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleIconSecureUpdated (quoteIconSecure) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId

      // save the icon secure to the DB global store
      var quoteIconSecureFromDB = await this.iQuoteQuoteIconSecureAPIMixin_saveQuoteIconSecureToDB(quoteId, quoteIconSecure)
      state.quoteIconSecure = quoteIconSecureFromDB
      state.quoteIconSecure.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteIconSecure.files)

      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getIconSecureCostSummaryTotalUpfrontCost (iconSecureItem) {
      return this.externalQuotesModelMixin_getIconSecureCostSummaryTotalUpfrontExternalCost(iconSecureItem) + this.externalQuotesModelMixin_getIconSecureCostSummaryTotalUpfrontInternalCost(iconSecureItem)
    },
    externalQuotesModelMixin_getIconSecureCostSummaryTotalRecurringCost (iconSecureItem) {
      return this.externalQuotesModelMixin_getIconSecureCostSummaryTotalRecurringExternalCost(iconSecureItem) + this.externalQuotesModelMixin_getIconSecureCostSummaryTotalRecurringInternalCost(iconSecureItem)
    },
    externalQuotesModelMixin_getIconSecureCostSummaryTotalYear1Cost (iconSecureItem) {
      return this.externalQuotesModelMixin_getIconSecureCostSummaryTotalUpfrontCost(iconSecureItem) + this.externalQuotesModelMixin_getIconSecureCostSummaryTotalRecurringCost(iconSecureItem)
    },
    externalQuotesModelMixin_getIconSecureCostSummaryTotalContractCost (iconSecureItem) {
      return this.externalQuotesModelMixin_getIconSecureCostSummaryTotalUpfrontCost(iconSecureItem) + (this.externalQuotesModelMixin_getIconSecureCostSummaryTotalRecurringCost(iconSecureItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getIconSecureCostSummaryTotalUpfrontExternalCost (iconSecureItem) {
      var total = 0

      iconSecureItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getIconSecureCostSummaryTotalUpfrontInternalCost (iconSecureItem) {
      var total = 0

      iconSecureItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalCost
      })

      return total
    },
    externalQuotesModelMixin_getIconSecureCostSummaryTotalRecurringExternalCost (iconSecureItem) {
      var total = 0

      iconSecureItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getIconSecureCostSummaryTotalRecurringInternalCost (iconSecureItem) {
      var total = 0

      iconSecureItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },

    /*
        Twilio
    */

    externalQuotesModelMixin_setSelectedTwilio (Twilio) {
      var state = this.externalQuotesModelMixin_State
      state.quoteTwilio = Twilio
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleTwilioDeleted (quoteTwilio) {
      var state = this.externalQuotesModelMixin_State
      await this.iQuoteQuoteTwilioAPIMixin_deleteQuoteTwilioFromDB(quoteTwilio)
      state.quoteTwilio = {}
      this.setCurrentQuoteExternalQuotesState(state)
    },
    async externalQuotesModelMixin_handleTwilioUpdated (quoteTwilio) {
      var state = this.externalQuotesModelMixin_State
      var quoteId = state.quoteId

      // save Twilio to the DB global store
      var quoteTwilioFromDB = await this.iQuoteQuoteTwilioAPIMixin_saveQuoteTwilioToDB(quoteId, quoteTwilio)
      state.quoteTwilio = quoteTwilioFromDB
      state.quoteTwilio.files = await this.externalQuotesModelMixin_getFileVersionInfoFromUploadsService(state.quoteTwilio.files)

      this.setCurrentQuoteExternalQuotesState(state)
    },
    externalQuotesModelMixin_getTwilioCostSummaryTotalUpfrontCost (TwilioItem) {
      return this.externalQuotesModelMixin_getTwilioCostSummaryTotalUpfrontExternalCost(TwilioItem) + this.externalQuotesModelMixin_getTwilioCostSummaryTotalUpfrontInternalCost(TwilioItem)
    },
    externalQuotesModelMixin_getTwilioCostSummaryTotalRecurringCost (TwilioItem) {
      return this.externalQuotesModelMixin_getTwilioCostSummaryTotalRecurringExternalCost(TwilioItem) + this.externalQuotesModelMixin_getTwilioCostSummaryTotalRecurringInternalCost(TwilioItem)
    },
    externalQuotesModelMixin_getTwilioCostSummaryTotalYear1Cost (TwilioItem) {
      return this.externalQuotesModelMixin_getTwilioCostSummaryTotalUpfrontCost(TwilioItem) + this.externalQuotesModelMixin_getTwilioCostSummaryTotalRecurringCost(TwilioItem)
    },
    externalQuotesModelMixin_getTwilioCostSummaryTotalContractCost (TwilioItem) {
      return this.externalQuotesModelMixin_getTwilioCostSummaryTotalUpfrontCost(TwilioItem) + (this.externalQuotesModelMixin_getTwilioCostSummaryTotalRecurringCost(TwilioItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getTwilioCostSummaryTotalUpfrontExternalCost (TwilioItem) {
      var total = 0

      TwilioItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalCost
      })
      return total
    },
    externalQuotesModelMixin_getTwilioCostSummaryTotalUpfrontInternalCost (TwilioItem) {
      var total = 0

      TwilioItem.costSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalCost
      })
      return total
    },
    externalQuotesModelMixin_getTwilioCostSummaryTotalRecurringExternalCost (TwilioItem) {
      var total = 0

      TwilioItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringExternalCost
      })

      return total
    },
    externalQuotesModelMixin_getTwilioCostSummaryTotalRecurringInternalCost (TwilioItem) {
      var total = 0

      TwilioItem.costSummaryEntries.forEach(entry => {
        total += entry.recurringInternalCost
      })

      return total
    },
    externalQuotesModelMixin_getTwilioPriceSummaryTotalUpfrontPrice (TwilioItem) {
      return this.externalQuotesModelMixin_getTwilioPriceSummaryTotalUpfrontExternalPrice(TwilioItem) + this.externalQuotesModelMixin_getTwilioPriceSummaryTotalUpfrontInternalPrice(TwilioItem)
    },
    externalQuotesModelMixin_getTwilioPriceSummaryTotalRecurringPrice (TwilioItem) {
      return this.externalQuotesModelMixin_getTwilioPriceSummaryTotalRecurringExternalPrice(TwilioItem) + this.externalQuotesModelMixin_getTwilioPriceSummaryTotalRecurringInternalPrice(TwilioItem)
    },
    externalQuotesModelMixin_getTwilioPriceSummaryTotalYear1Price (TwilioItem) {
      return this.externalQuotesModelMixin_getTwilioPriceSummaryTotalUpfrontPrice(TwilioItem) + this.externalQuotesModelMixin_getTwilioPriceSummaryTotalRecurringPrice(TwilioItem)
    },
    externalQuotesModelMixin_getTwilioPriceSummaryTotalContractPrice (TwilioItem) {
      return this.externalQuotesModelMixin_getTwilioPriceSummaryTotalUpfrontPrice(TwilioItem) + (this.externalQuotesModelMixin_getTwilioPriceSummaryTotalRecurringPrice(TwilioItem) * (this.iQuoteStore_GetQuote.termLengthMonths / 12))
    },
    externalQuotesModelMixin_getTwilioPriceSummaryTotalUpfrontExternalPrice (TwilioItem) {
      var total = 0

      TwilioItem.priceSummaryEntries.forEach(entry => {
        total += entry.upfrontExternalPrice
      })
      return total
    },
    externalQuotesModelMixin_getTwilioPriceSummaryTotalUpfrontInternalPrice (TwilioItem) {
      var total = 0

      TwilioItem.priceSummaryEntries.forEach(entry => {
        total += entry.upfrontInternalPrice
      })
      return total
    },
    externalQuotesModelMixin_getTwilioPriceSummaryTotalRecurringExternalPrice (TwilioItem) {
      var total = 0

      TwilioItem.priceSummaryEntries.forEach(entry => {
        total += entry.recurringExternalPrice
      })

      return total
    },
    externalQuotesModelMixin_getTwilioPriceSummaryTotalRecurringInternalPrice (TwilioItem) {
      var total = 0

      TwilioItem.priceSummaryEntries.forEach(entry => {
        total += entry.recurringInternalPrice
      })

      return total
    }
  }
}
