import portalAPIGateway from '../../../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteNewManagedServiceAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteNewManagedServiceAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    iQuoteQuoteNewManagedServiceAPIMixin_saveQuoteNewManagedServiceToDB: async function (quoteId, quoteNewManagedService) {
      var axiosConfig = this.iQuoteQuoteNewManagedServiceAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.put('iquote/quotenewmanagedservice/' + quoteId, quoteNewManagedService, axiosConfig).then((response) => {
          returnValue = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteNewManagedServiceAPIMixin_saveQuoteNewManagedServiceToDB failed!: error: ' + error)
      }

      return returnValue
    },
    iQuoteQuoteNewManagedServiceAPIMixin_deleteQuoteNewManagedServiceFromDB: async function (quoteNewManagedService) {
      if (quoteNewManagedService === undefined) return
      var axiosConfig = this.iQuoteQuoteNewManagedServiceAPIMixin_getAxiosConfig()
      var returnValue = {}

      try {
        await portalAPIGateway.delete('iquote/quotenewmanagedservice/' + quoteNewManagedService.id, null, axiosConfig).then((response) => {
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteNewManagedServiceAPIMixin_deleteQuoteNewManagedServiceFromDB failed!')
      }

      return returnValue
    },
    iQuoteQuoteNewManagedServiceAPIMixin_getQuoteNewManagedServiceFromDb: async function (quoteId) {
      var axiosConfig = this.iQuoteQuoteNewManagedServiceAPIMixin_getAxiosConfig()
      var data = []

      try {
        await portalAPIGateway.get('iquote/quotenewmanagedservice/' + quoteId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteQuoteNewManagedServiceAPIMixin_getQuoteNewManagedServiceFromDb returned error: ' + error)
      }

      return data
    }
  }
}
