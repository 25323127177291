<template>
  <div>
    <div
      id="HorizonEntriesLeftPanel"
    >
      <h2>Edit Horizon</h2>
      <label
        :class="{InputError:!isValidDescription(externalQuotesModelMixin_State.quoteHorizon.description)}"
        for="txtDescription"
      >Description:</label>
      <input
        id="txtDescription"
        v-model="externalQuotesModelMixin_State.quoteHorizon.description"
        type="text">
      <br>
      <br>
      <div id="HorizonEntries">
        <table
          id="HorizonCostSummaryTable"
          class="iQuoteTable"
          style="display: block"
        >
          <tr>
            <th
              rowspan="2"
              style="width:190px">Description</th>
            <th
              rowspan="2"
              style="width:100px">SF COE</th>
            <th
              colspan="2"
              style="width:100px">Upfront Cost</th>
            <th
              colspan="2"
              style="width:100px">Annual Cost</th>
          </tr>
          <tr>
            <th
              style="width:100px">External</th>
            <th
              style="width:100px">Internal</th>
            <th
              style="width:100px">External</th>
            <th
              style="width:100px">Internal</th>
          </tr>
          <tr v-if="externalQuotesModelMixin_State.quoteHorizon.costSummaryEntries.length === 0">
            <td colspan="7">The are currently no entries.  Please upload a valid calculator spreadsheet</td>
          </tr>
          <tr
            v-for="costSummaryEntry in externalQuotesModelMixin_State.quoteHorizon.costSummaryEntries"
            :key="costSummaryEntry.id"
          >
            <td :class="{InputError:!isValidCostSummaryEntryDescription(costSummaryEntry.description)}">
              <input
                v-model="costSummaryEntry.description"
                type="text"
                maxlength="100"
                disabled
              >
            </td>
            <td :class="{InputError:!isValidCostSummaryEntrySfCoeCode(costSummaryEntry.salesforceCoeCodeId)}">
              {{ getSF_COE_NameFromId(costSummaryEntry.salesforceCoeCodeId) }}
            </td>
            <td>£{{ iQuoteFunctions_ThousandSeparator(costSummaryEntry.upfrontExternalCost.toFixed(2)) }}
            </td>
            <td>£{{ iQuoteFunctions_ThousandSeparator(costSummaryEntry.upfrontInternalCost.toFixed(2)) }}
            </td>
            <td>£{{ iQuoteFunctions_ThousandSeparator(costSummaryEntry.recurringExternalCost.toFixed(2)) }}
            </td>
            <td>£{{ iQuoteFunctions_ThousandSeparator(costSummaryEntry.recurringInternalCost.toFixed(2)) }}
            </td>
          </tr>
          <tr>
            <th colspan="2">TOTAL:</th>
            <th>£{{ iQuoteFunctions_ThousandSeparator(externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontExternalCost(externalQuotesModelMixin_State.quoteHorizon).toFixed(2)) }}</th>
            <th>£{{ iQuoteFunctions_ThousandSeparator(externalQuotesModelMixin_getHorizonCostSummaryTotalUpfrontInternalCost(externalQuotesModelMixin_State.quoteHorizon).toFixed(2)) }}</th>
            <th>£{{ iQuoteFunctions_ThousandSeparator(externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringExternalCost(externalQuotesModelMixin_State.quoteHorizon).toFixed(2)) }}</th>
            <th>£{{ iQuoteFunctions_ThousandSeparator(externalQuotesModelMixin_getHorizonCostSummaryTotalRecurringInternalCost(externalQuotesModelMixin_State.quoteHorizon).toFixed(2)) }}</th>
          </tr>
        </table>
        <br>
        <table
          id="HorizonPsTable"
          class="iQuoteTable"
          style="display: block"
        >
          <tr>
            <th
              style="width:250px">Role</th>
            <th
              style="width:100px">Days</th>
            <th
              style="width:100px">Mon To Sat OOH</th>
            <th
              style="width:100px">Sundays and Bank Hols</th>
          </tr>
          <tr v-if="externalQuotesModelMixin_State.quoteHorizon.psEntries.length === 0">
            <td colspan="4">The are currently no entries.  Please upload a valid calculator spreadsheet</td>
          </tr>
          <tr
            v-for="psEntry in externalQuotesModelMixin_State.quoteHorizon.psEntries"
            :key="psEntry.id"
          >
            <td>
              {{ getPsRoleProfileNameFromId(psEntry.designPackPSRoleProfileId) }}
            </td>
            <td>{{ psEntry.days }}
            </td>
            <td>{{ psEntry.monToSatOOH }}
            </td>
            <td>{{ psEntry.sunAndBankHols }}
            </td>
          </tr>
          <tr>
            <th>TOTAL:</th>
            <th>{{ totalPsDays.toFixed(1) }}</th>
            <th>{{ totalMonToSatOOH }}</th>
            <th>{{ totalSunAndBankHols }}</th>
          </tr>
        </table>
      </div>
    </div>
    <div id="FilesSection">
      <h2>Horizon Calculator Spreadsheet</h2>
      <input
        v-if="horizonSpreadsheetFile.fileUploadId === undefined"
        id="'btnUploadFile'"
        value="Upload Calculator Spreadsheet"
        type="button"
        @click="uploadFileButtonClicked('Upload New Horizon Spreadsheet', 0)"
      >
      <table
        v-if="horizonPageComponent.inheritedFileDetails !== null && horizonSpreadsheetFile.fileUploadId === undefined"
        id="InheritedFileTable"
        class="iQuoteTable"
      >
        <tr>
          <th>Description</th>
          <th>File Name</th>
          <th>Notes</th>
        </tr>
        <tr
        >
          <td><div>{{ horizonPageComponent.inheritedFileDetails.title }} <br><br><b>Notes:</b> {{ horizonPageComponent.inheritedFileDetails.versionNotes }}</div></td>
          <td><div>{{ horizonPageComponent.inheritedFileDetails.name }}</div></td>
          <td style="font-weight: 600">Duplicated spreadsheet<br>
            <input
              id="btnDownloadInheritedSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(externalQuotesModelMixin_State.quoteHorizon.fileUploadVersionId)"
            >
          </td>
        </tr>
      </table>
      <table
        v-if="horizonSpreadsheetFile.fileUploadId !== undefined"
        id="FilesTable"
        class="iQuoteTable"
      >
        <tr
        >
          <th>#</th>
          <th>Description</th>
          <th>File Name</th>
          <th>&nbsp;</th>
        </tr>
        <tr
          v-if="horizonSpreadsheetFile.fileUploadId !== undefined"
        >
          <td>{{ horizonSpreadsheetLatestFileVersion.versionNumber }}</td>
          <td><div>{{ horizonSpreadsheetFile.title }} <br><br><b>Notes:</b> {{ horizonSpreadsheetLatestFileVersion.versionNotes }}</div></td>
          <td><div>{{ horizonSpreadsheetFile.fileName }}</div></td>
          <td>
            <input
              id="btnDownloadFileLatestSpreadsheet"
              value="Download"
              type="button"
              @click="downloadFile(horizonSpreadsheetLatestFileVersion.fileVersionId)"
            >
            <input
              id="btnSetValuesFromLatestSpreadsheet"
              value="Import Calculator Values"
              type="button"
              @click="getSpreadsheetDataFromUploadServiceAndSetValues(horizonSpreadsheetLatestFileVersion.fileVersionId)"
            >
            <input
              id="'btnUploadFile'"
              value="Replace"
              type="button"
              @click="uploadFileButtonClicked('Upload Updated Calculator Spreadsheet', horizonSpreadsheetFile.fileUploadId)"
            >
          </td>
        </tr>
        <tr
          v-if="horizonSpreadsheetPreviousFileVersions.length > 0 || externalQuotesModelMixin_State.quoteHorizon.fileUploadVersionId"
        >
          <td colspan="4">&nbsp;</td>
        </tr>
        <tr
          v-if="horizonSpreadsheetPreviousFileVersions.length > 0 || externalQuotesModelMixin_State.quoteHorizon.fileUploadVersionId"
        >
          <th colspan="3">
            Previous Versions
          </th>
          <th>
            <input
              v-if="!horizonPageComponent.showPreviousVersions"
              id="'btnShowPreviousVersion'"
              value="show"
              type="button"
              @click="horizonPageComponent.showPreviousVersions = true"
            >
            <input
              v-if="horizonPageComponent.showPreviousVersions"
              id="'btnHidePreviousVersion'"
              value="hide"
              type="button"
              @click="horizonPageComponent.showPreviousVersions = false"
            >
          </th>
        </tr>
        <tr
          v-if="(horizonSpreadsheetPreviousFileVersions.length > 0 || externalQuotesModelMixin_State.quoteHorizon.fileUploadVersionId) && horizonPageComponent.showPreviousVersions"
        >
          <th>#</th>
          <th>Notes</th>
          <th>File Name</th>
          <th>&nbsp;</th>
        </tr>
        <tbody
          v-if="horizonPageComponent.showPreviousVersions"
        >
          <tr v-if="horizonPageComponent.inheritedFileDetails">
            <td>0</td>
            <td><div>{{ horizonPageComponent.inheritedFileDetails.versionNotes }}<br>(Duplicated)</div></td>
            <td><div>{{ horizonPageComponent.inheritedFileDetails.name }}</div></td>
            <td>
              <input
                id="btnDownloadInheritedSpreadsheet"
                value="Download"
                type="button"
                @click="downloadFile(externalQuotesModelMixin_State.quoteHorizon.fileUploadVersionId)"
              >
            </td>
          </tr>
          <tr
            v-for="file in horizonSpreadsheetPreviousFileVersions"
            :key="file.id">
            <td>{{ file.versionNumber }}</td>
            <td><div>{{ file.versionNotes }}</div></td>
            <td><div>{{ file.name }}</div></td>
            <td>
              <input
                :id="'btnDownloadFile' + file.id"
                value="Download"
                type="button"
                @click="downloadFile(file.fileVersionId)"
              >
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <FileUploadDialogBox
        :dialog="horizonPageComponent.showFileUploadDialog"
        :dlg-message="horizonPageComponent.fileUploadDialogMessage"
        :dlg-close-from-top="false"
        :file-upload-catalog-id="horizonPageComponent.selectedFileUploadCatalogId"
        :checking-file="horizonPageComponent.fileDialogCheckingFile"
        :selected-file-error="horizonPageComponent.fileDialogSelectedFileError"
        dlgid="fileUploadDialogBox"
        dlg-icon="mdi-alert-circle-outline"
        @uploadAction="fileUploaded"
        @cancelAction="horizonPageComponent.showFileUploadDialog = false"
        @fileChosenAction="fileChosen"
      />
    </div>
    <br><br>
    <div class="buttons">
      <div class="leftButtons">
        <input
          id="btnOk"
          title="Save changes"
          value="OK"
          type="button"
          @click="okButtonPressed()"
        >
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../../common/tooltipIcon'
import FileUploadDialogBox from '../../../common/fileUploadDialogBox'
import { iQuoteCalculationsCommonMixin } from '../../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStaticDataAPIMixin } from '../../../../mixins/iQuote/iQuoteStaticDataAPIMixin'
import { UploadsAPIMixin } from '../../../../mixins/uploadsAPIMixin'
import { iQuoteExportsAPIMixin } from '../../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { externalQuotesModelMixin } from '../../../../mixins/iQuote/Models/externalQuotesModelMixin'
import { iQuoteStoreMixin } from '../../../../mixins/iQuote/iQuoteStoreMixin'

import { mapActions } from 'vuex'
export default {
  name: 'HorizonPage',
  components: {
    TooltipIcon, FileUploadDialogBox
  },
  mixins: [iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin, iQuoteStaticDataAPIMixin, UploadsAPIMixin, iQuoteExportsAPIMixin, externalQuotesModelMixin, iQuoteStoreMixin],
  data () {
    return {
      horizonPageComponent: {
        salesforceCoeCodes: [],
        showFileUploadDialog: false,
        fileUploadDialogMessage: '',
        selectedFileUploadCatalogId: 0,
        fileDialogCheckingFile: false,
        fileDialogSelectedFileError: '',
        showPreviousVersions: false,
        inheritedFileDetails: null
      }
    }
  },
  computed: {
    horizonSpreadsheetFile () {
      if (this.externalQuotesModelMixin_State.quoteHorizon.id === undefined) {
        return {}
      } else {
        for (let index = 0; index < this.externalQuotesModelMixin_State.quoteHorizon.files.length; index++) {
          const file = this.externalQuotesModelMixin_State.quoteHorizon.files[index]
          if (file.isHorizonSpreadsheet) {
            return file
          }
        }

        return {}
      }
    },
    horizonSpreadsheetLatestFileVersion () {
      if (this.horizonSpreadsheetFile.id === undefined) {
        return {}
      }

      return this.externalQuotesModelMixin_getLatestFileVersion(this.horizonSpreadsheetFile)
    },
    horizonSpreadsheetPreviousFileVersions () {
      if (this.horizonSpreadsheetFile.id === undefined ||
          this.horizonSpreadsheetFile.versionList === undefined || /* should only be "undefined" for a short period, whilst the uploads service is queried on externalQuotesModel mounted () */
          this.horizonSpreadsheetFile.versionList.count <= 1) {
        return []
      }

      var latestFileVersionNumber = this.horizonSpreadsheetLatestFileVersion.versionNumber

      return this.horizonSpreadsheetFile.versionList.filter(x => x.versionNumber < latestFileVersionNumber)
    },
    totalPsDays () {
      var total = 0
      this.externalQuotesModelMixin_State.quoteHorizon.psEntries.forEach(entry => {
        total += entry.days
      })
      return total
    },
    totalMonToSatOOH () {
      var total = 0
      this.externalQuotesModelMixin_State.quoteHorizon.psEntries.forEach(entry => {
        total += entry.monToSatOOH
      })
      return total
    },
    totalSunAndBankHols () {
      var total = 0
      this.externalQuotesModelMixin_State.quoteHorizon.psEntries.forEach(entry => {
        total += entry.sunAndBankHols
      })
      return total
    }
  },
  async mounted () {
    this.horizonPageComponent.salesforceCoeCodes = await this.iQuoteStaticDataAPIMixin_getSalesforceCoeCodes()

    const fileUploadVersionId = this.externalQuotesModelMixin_State.quoteHorizon.fileUploadVersionId
    if (fileUploadVersionId) {
      this.horizonPageComponent.inheritedFileDetails = await this.UploadsAPIMixin_GetFileDetailsByVersionId('iQuote', fileUploadVersionId)
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),
    isValidDescription (description) {
      return (description.trim().length > 0)
    },
    isValidCostSummaryEntryDescription (description) {
      return (description.trim().length > 0)
    },
    isValidCostSummaryEntrySfCoeCode (salesforceCoeCodeId) {
      return (salesforceCoeCodeId !== 0)
    },
    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
    getSpreadsheetDataFromUploadServiceAndSetValues: async function (fileId) {
      var data = await this.UploadsAPIMixin_DownloadFile('iquote', fileId)
      var fileDetails = await this.UploadsAPIMixin_GetFileDetailsByVersionId('iQuote', fileId)

      var base64data
      var contentType = 'application/octet-stream'
      var fileExtension = fileDetails.name.split('.').pop().toLowerCase()

      switch (fileExtension) {
        case 'xls':
          contentType = 'application/vnd.ms-excel'
          break
        case 'xlsx':
          contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
        case 'doc':
          contentType = 'application/vnd.ms-word'
          break
        case 'docx':
          contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          break
        case 'pdf':
          contentType = 'application/pdf'
          break
        case 'jpg':
          contentType = 'image/jpeg'
          break
        case 'png':
          contentType = 'image/png'
          break
      }

      var blob = new Blob([data], { type: contentType })

      base64data = await this.blobToBase64(blob)
      this.horizonPageComponent.spreadsheetData = await this.iQuoteExportsAPIMixin_getHorizonDataFromExcel(base64data)
      await this.setValuesFromSpreadsheet()
    },
    setValuesFromSpreadsheet: async function () {
      var isValidData = true

      this.horizonPageComponent.spreadsheetData.costSummaryEntries.forEach(costSummaryEntry => {
        if (costSummaryEntry.SF_COE.trim() !== '' && !this.isValidSFCoeCode(costSummaryEntry.SF_COE)) {
          isValidData = false

          this.iQuoteFunctions_ShowError(costSummaryEntry.SF_COE + ' is an invalid SF COE Code.  This data cannot be imported')
        }
      })

      this.horizonPageComponent.spreadsheetData.psEntries.forEach(psEntry => {
        if (!this.isValidPSRoleName(psEntry.RoleName)) {
          isValidData = false

          this.iQuoteFunctions_ShowError(psEntry.RoleName + ' is an invalid PS Role.  This data cannot be imported')
        }
      })

      if (isValidData) {
        this.externalQuotesModelMixin_State.quoteHorizon.costSummaryEntries = []

        this.horizonPageComponent.spreadsheetData.costSummaryEntries.forEach(costSummaryEntry => {
          this.externalQuotesModelMixin_State.quoteHorizon.costSummaryEntries.push(
            {
              id: this.getTempCostSummaryEntryId(),
              description: costSummaryEntry.Description,
              salesforceCoeCodeId: this.getSF_COE_IdFromName(costSummaryEntry.SF_COE),
              upfrontExternalCost: costSummaryEntry.UpfrontExternalCost,
              upfrontInternalCost: costSummaryEntry.UpfrontInternalCost,
              recurringExternalCost: costSummaryEntry.RecurringExternalCost,
              recurringInternalCost: costSummaryEntry.RecurringInternalCost,
              upfrontMargin: costSummaryEntry.UpfrontMargin,
              recurringMargin: costSummaryEntry.RecurringMargin
            }
          )
        })

        this.externalQuotesModelMixin_State.quoteHorizon.psEntries = []

        this.horizonPageComponent.spreadsheetData.psEntries.forEach(psEntry => {
          this.externalQuotesModelMixin_State.quoteHorizon.psEntries.push(
            {
              id: this.getTempPsEntryId(),
              designPackPSRoleProfileId: this.getPsRoleProfileIdFromName(psEntry.RoleName),
              days: Number(psEntry.Days.toFixed(1)),
              monToSatOOH: psEntry.MonToSatOOH,
              sunAndBankHols: psEntry.SunAndBankHols
            }
          )
        })

        this.externalQuotesModelMixin_State.quoteHorizon.autotaskEntries = []

        this.horizonPageComponent.spreadsheetData.autotaskEntries.forEach(autotaskEntry => {
          this.externalQuotesModelMixin_State.quoteHorizon.autotaskEntries.push(
            {
              id: this.getTempAutotaskEntryId(),
              code: autotaskEntry.Code,
              unitOOPrice: autotaskEntry.UnitOOPrice,
              unitOOCost: autotaskEntry.UnitOOCost,
              unitARPrice: autotaskEntry.UnitARPrice,
              unitARCost: autotaskEntry.UnitARCost,
              unitMRCost: autotaskEntry.UnitMRCost,
              unitMRPrice: autotaskEntry.UnitMRPrice,
              costTermMonths: autotaskEntry.CostTermMonths,
              sellTermMonths: autotaskEntry.SellTermMonths,
              quantity: autotaskEntry.Quantity,
              supplier: autotaskEntry.Supplier,
              quoteRef: autotaskEntry.QuoteRef,
              description: autotaskEntry.Description,
              salesforceDescription: autotaskEntry.SalesforceDescription,
              salesforceCoeCodeId: this.getSF_COE_IdFromName(autotaskEntry.SalesforceCoe)
            }
          )
        })
      }
    },
    isValidSFCoeCode (salesforceCoeCode) {
      return this.horizonPageComponent.salesforceCoeCodes.some(e => e.code.toLowerCase() === salesforceCoeCode.toLowerCase())
    },
    isValidPSRoleName (roleName) {
      return this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.some(e => e.description.toLowerCase() === roleName.toLowerCase())
    },
    getSF_COE_IdFromName (salesforceCoeCode) {
      if (salesforceCoeCode === null) {
        return null
      }

      var matches = this.horizonPageComponent.salesforceCoeCodes.filter(e => e.code.toLowerCase() === salesforceCoeCode.toLowerCase())

      if (matches.length > 0) {
        return matches[0].id
      } else {
        return 0
      }
    },
    getPsRoleProfileIdFromName (roleName) {
      var matches = this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.filter(e => e.description.toLowerCase() === roleName.toLowerCase())

      if (matches.length > 0) {
        return matches[0].id
      } else {
        return 0
      }
    },
    getSF_COE_NameFromId (id) {
      var matches = this.horizonPageComponent.salesforceCoeCodes.filter(e => e.id === id)

      if (matches.length > 0) {
        return matches[0].code
      } else {
        return ''
      }
    },
    getPsRoleProfileNameFromId (id) {
      var matches = this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.filter(e => e.id === id)

      if (matches.length > 0) {
        return matches[0].description
      } else {
        return ''
      }
    },
    downloadFile: async function (fileId) {
      var data = await this.UploadsAPIMixin_DownloadFile('iquote', fileId)
      var fileDetails = await this.UploadsAPIMixin_GetFileDetailsByVersionId('iQuote', fileId)
      var contentType = 'application/octet-stream'
      var fileExtension = fileDetails.name.split('.').pop().toLowerCase()

      switch (fileExtension) {
        case 'xls':
          contentType = 'application/vnd.ms-excel'
          break
        case 'xlsx':
          contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
        case 'doc':
          contentType = 'application/vnd.ms-word'
          break
        case 'docx':
          contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          break
        case 'pdf':
          contentType = 'application/pdf'
          break
        case 'jpg':
          contentType = 'image/jpeg'
          break
        case 'png':
          contentType = 'image/png'
          break
      }

      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: contentType }))
      a.download = fileDetails.name
      document.body.appendChild(a)
      a.click()
    },
    getTempCostSummaryEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.externalQuotesModelMixin_State.quoteHorizon.costSummaryEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    getTempPsEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.externalQuotesModelMixin_State.quoteHorizon.psEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    getTempAutotaskEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.externalQuotesModelMixin_State.quoteHorizon.autotaskEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    delHorizonEntries () {
      this.externalQuotesModelMixin_State.quoteHorizon.costSummaryEntries = []
    },
    async okButtonPressed () {
      var quoteHorizon = this.externalQuotesModelMixin_State.quoteHorizon

      await this.externalQuotesModelMixin_handleHorizonUpdated(quoteHorizon)
      this.externalQuotesModelMixin_setCurrentPage('rootPage')
    },
    uploadFileButtonClicked (message, FileUploadCatalogId) {
      this.horizonPageComponent.fileUploadDialogMessage = message
      this.horizonPageComponent.fileDialogSelectedFileError = ''
      this.horizonPageComponent.showFileUploadDialog = true
      this.horizonPageComponent.selectedFileUploadCatalogId = FileUploadCatalogId
    },
    async fileChosen (newFile) {
      this.horizonPageComponent.fileDialogCheckingFile = true
      this.horizonPageComponent.fileDialogSelectedFileError = ''
      var fileExtension = newFile.name.split('.').pop().toLowerCase()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xls' && fileExtension !== 'xltm' && fileExtension !== 'xlsm') {
        this.horizonPageComponent.fileDialogSelectedFileError = 'Only Excel Spreadsheets can be uploaded'
      } else {
        var data = await this.iQuoteExportsAPIMixin_getHorizonDataFromExcel(newFile.contents)

        if (data.error === undefined) {
          this.horizonPageComponent.fileDialogSelectedFileError = 'Error contacting file export service.  Cannot upload.  Please contact your administrator for assistance'
        } else if (data.error !== '') {
          this.horizonPageComponent.fileDialogSelectedFileError = data.error
        } else if (data.costSummaryEntries.length === 0) {
          this.horizonPageComponent.fileDialogSelectedFileError = 'No Cost Summary Entries found on this spreadsheet'
        } else {
          /* Store parsed data from spreadsheet for saving to DB later.  This data is not saved into DB until you upload
             the file (fileUploaded() method) */
          this.horizonPageComponent.spreadsheetData = data
        }
      }

      this.horizonPageComponent.fileDialogCheckingFile = false
    },
    async fileUploaded (newFile) {
      this.horizonPageComponent.showFileUploadDialog = false

      await this.setValuesFromSpreadsheet() // triggers horizonSpreadsheetFile

      if (this.horizonPageComponent.selectedFileUploadCatalogId === newFile.fileUploadCatalogId) {
        // new version of existing file
        for (let index = 0; index < this.externalQuotesModelMixin_State.quoteHorizon.files.length; index++) {
          const file = this.externalQuotesModelMixin_State.quoteHorizon.files[index]
          if (file.fileUploadId === newFile.fileUploadCatalogId) {
            file.fileName = newFile.name
            file.versionList.push(
              {
                name: newFile.name,
                versionNotes: newFile.versionNotes,
                versionNumber: newFile.versionNumber,
                fileVersionId: newFile.fileVersionId
              }
            )

            // force triggering horizonSpreadsheetFile to update #FilesTable (it is a workaround, needs refactoring):
            this.externalQuotesModelMixin_State.quoteHorizon.files.splice(index, 1)
            this.externalQuotesModelMixin_State.quoteHorizon.files.push(file)
          }
        }
      } else {
        // New File (triggers horizonSpreadsheetFile)
        this.externalQuotesModelMixin_State.quoteHorizon.files.push(
          {
            id: -1,
            fileUploadId: newFile.fileUploadCatalogId,
            title: newFile.title,
            fileName: newFile.name,
            isHorizonSpreadsheet: true,
            versionList: [
              {
                name: newFile.name,
                versionNotes: newFile.versionNotes,
                versionNumber: newFile.versionNumber,
                fileVersionId: newFile.fileVersionId
              }
            ]
          }
        )
      }
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 1520px}
  .small {width:60px;}
  .hidden {visibility: hidden;}

  .quotePanel label {
    width:80px;
    font-weight: bold;
  }

  #txtDescription {
    width:500px
  }

  table {
    font-size: 1em;
  }

  .quotePanel input[type=number].currencyInput{
    width: 60px;
  }

  #FilesTable {
    margin-top: 17px;
    width: 515px;
    display: block;
  }

  #FilesTable td div {
    width: 150px;
    white-space: normal;
    word-wrap: break-word;
  }

  #HorizonCostSummaryTable {
    width: 690px;
  }

  #HorizonPsTable {
    width: 480px;
  }

  #HorizonAutotaskTable {
    width: 690px;
  }

  #HorizonCostSummaryTable input[type=text] {
    border: none;
    margin: 0;
    padding: 0;
  }

  #FilesSection {
    width: 700px;
    display: inline-block;
    vertical-align: top;
    padding-left: 30px;
  }

  #HorizonEntries {
    display: inline-block;
  }

  #HorizonEntriesLeftPanel {
    display: inline-block;
  }

  #HorizonCostSummaryTable td input[type=number] {
    border: none;
    margin: 0;
    padding: 0;
  }

  #HorizonCostSummaryTable input[type=button] {
    margin: 0;
    padding: 5px;
  }

  #HorizonCostSummaryTable td {
    margin: 0;
    padding: 5px;
  }

  #cboSalesforceCoeCodes {
    width: 150px;
    border: none;
    margin: 0;
    padding: 0;
    height: 18px;
  }
</style>
