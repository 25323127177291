<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-createglobaluser"
          primary-title
        >
          Create Global User
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details how to create global users."
              />
              <HelpSection
                instruction="1.) Navigate to the support page, then select the 'Create Global User' tile."
                image-source="create_global_user_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="2.) On the 'Create Global User' screen, all of the fields under the 'Personal' section must be populated. Note that the completion of a section is indicated by a tick in the section header."
                image-source="create_global_user_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="3.) Once all the fields have been populated, the ‘Submit’ button in the bottom right hand corner of the screen will be enabled."
                indentation="0"
              />
              <HelpSection
                instruction="4.) Assuming all of the validation passes, a notification will appear indicating the successful creation of the user and you will be redirected to the ‘Support Page’ page. Otherwise, a notification will be shown indicating any problems with the new user information."
                indentation="0"
              />
              <HelpSection
                instruction="5.) In the event that the provided email exists in the system, then a notification will be shown indicating that the email address is in use."
                image-source="create_global_user_3.png"
                indentation="0"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpCreateGlobalUser',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
