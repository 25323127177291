import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'
import { mapActions } from 'vuex'

export const iQuoteCalculationsExpansionsMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
      quoteExpansions: {},
      gammaSip: {},
      quote: {},
      licenses: {}
    }
  },
  computed: {
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteExpansions'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcExpansions: function () {
      var quoteExpansions = this.iQuoteStore_GetQuoteExpansions

      var numPSDays = 0

      if (quoteExpansions.id === undefined) {
        return
      }

      var rvms = quoteExpansions.expansionVirtualMachines

      rvms.forEach(rvm => {
        if (rvm.virtualMachineId !== null) {
          this.iQuoteStore_AddRequiredVmToQuote(rvm.virtualMachineId, this.iQuoteConstants.dataCentres.fareham, true, rvm.quantity, null, null, true)
          this.iQuoteStore_AddRequiredVmToQuote(rvm.virtualMachineId, this.iQuoteConstants.dataCentres.goswellRoad, true, rvm.quantity, null, null, true)
        }
        numPSDays += (rvm.quantity * 0.5)
      })

      /*
        Need to round up to nearest half or full day.  To do this, I'll multiply the number by 4, so it's always an whole number,
        then add one to it if it's an odd number (which means originally had either 1/4 or 3/4 of a day), then divide the number by
        4 to have it rounded up to nearest half day.

        for example:
           0.75 days * 4 = 3.  3 + 1 = 4.  4 / 4 = 1
           1.5 days * 4 = 6.  6 / 4 = 1.5
           2.25 days * 4 = 9. 9 + 1 = 10.  10 / 4 = 2.5
           3 days * 4 = 12. 12 / 4 = 3
           3.75 days * 4 = 15.  15 + 1 = 16. 16 / 4 = 4
      */

      numPSDays *= 4

      if (numPSDays % 2 === 1) {
        numPSDays += 1
      }

      numPSDays /= 4

      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_PS_IOH_01, numPSDays)
    }
  }
}
