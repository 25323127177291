<template>
  <div
    :id="id"
    class="roleHolder"
  >
    <label
      class="roleDetailLabel"
      for="txtUpdateRoleName">Role Name:</label>
    <input
      v-if="updateRolePermission"
      id="txtUpdateRoleName"
      :class="{ errorHighlight: roleNameError }"
      v-model="role.name"
      class="roleDetailInput"
      type="text"
      @blur="checkForm"
    >
    <label
      v-if="!updateRolePermission"
      id="lblUpdateRoleName"
      class="roleDetailData"
    >{{ role.name }}</label>
    <label
      class="roleDetailLabel"
      for="txtUpdateRoleDescription"
    >Description:</label>
    <input
      v-if="updateRolePermission"
      id="txtUpdateRoleDescription"
      v-model="role.description"
      class="roleDetailInput"
      type="text"
      @blur="checkForm"
    >
    <label
      v-if="!updateRolePermission"
      id="lblUpdateRoleDescription"
      class="roleDetailData"
    >{{ role.description }}</label>
    <Spinner v-if="isSaving" />
    <input
      v-if="updateRolePermission && !isSaving"
      id="btnUpdateRole"
      :disabled="!formOK"
      value="Update Role"
      type="button"
      class="roleUpdateButton"
      title="Updates the properties of the role"
      @click="updateRoleDetails()"
    >
  </div>
</template>
<script>
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions } from 'vuex'
import Spinner from '../../common/spinner'
export default {
  name: 'RoleDetails',
  components: {
    Spinner
  },
  mixins: [
    permissionsMixin
  ],
  props: {
    roleid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isSaving: false,
      formOK: false,
      role: {
        name: '',
        description: ''
      },
      roleNameError: false
    }
  },
  computed: {
    updateRolePermission () {
      return this.$store.getters.updateRolePermission
    },
    currentRole () {
      return this.$store.getters.roles.find(role => role.id === this.roleid)
    }
  },
  watch: {
    roleid () {
      this.role = this.currentRole
      this.checkForm()
    }
  },
  mounted () {
    this.role = this.currentRole
    this.checkForm()
  },
  methods: {
    ...mapActions([
      'setReloadRoles'
    ]),
    updateRoleDetails: async function () {
      this.isSaving = true
      if (this.formOK) {
        await this.updateRole(this.role)
        await this.rolesGet()
        this.setReloadRoles(true)
        this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
      } else {
        this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
      }
      this.isSaving = false
    },
    checkForm: function () {
      this.roleNameError = this.role.name.length < 2
      var error = this.role.id < 0 || this.roleNameError

      if (error) {
        var errorMessage = 'Please fix the issues with the following field: ' +
        (this.roleNameError ? 'role name' : '')
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !error
    }
  }
}

</script>
<style scoped>
  .roleHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .roleDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
  }
  .roleDetailData {
    width: 280px;
    display: inline-block;
  }
  .roleDetailInput {
    width: 265px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .roleUpdateButton {
    float:right;
    cursor: pointer;
  }
  .roleUpdateButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
</style>
