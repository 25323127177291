<template>
  <div
    v-if="$store.getters.openSubForm"
    class="siteNewMemberBackground"
  >
    <div class="siteNewMemberHolder">
      <div class="siteTableholder">
        <table style="table-layout: auto;">
          <thead>
            <tr>
              <th
                colspan="4"
                style="border-top-right-radius:0px;border-top-left-radius:0px"
              >
                <img
                  :src="newMemberIcon"
                  class="icon"
                >
                <div
                  class="title"
                >
                  New Site Member
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="!members.length"
              class="alert"
            >
              <td colspan="4">
                <p
                  class="alert"
                >
                  There are no members to add.
                </p>
              </td>
            </tr>
            <tr
              v-for="m in members"
              :key="m.id"
            >
              <td style="padding:0px;width:6%;">
                <input
                  :id="m.id"
                  type="checkbox"
                  title="Select User"
                  class="memberItem"
                  @change="checkForm"
                >
              </td>
              <td
                :title="m.userName"
                style="padding:0px;width:40%;"
              >{{ m.userName }} </td>
              <td
                :title="m.firstName"
                style="padding:0px;width:27%;"
              >{{ m.firstName }} </td>
              <td
                :title="m.lastName"
                style="padding:0px;width:27%;"
              >{{ m.lastName }} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <input
        id="siteCancelNewMemberButton"
        type="button"
        class="siteCancelNewMemberButton"
        value="Cancel"
        title="Cancel the add operation"
        @click="cancelAdd()"
      >
      <input
        v-if="members.length"
        id="siteAddNewMemberButton"
        :class="getButtonClass"
        type="button"
        value="Add Member"
        title="Adds the new user to the site"
        @click="addMember()"
      >
    </div>
  </div>
</template>
<script>
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SiteNewMember',
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    areaid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    currentmembers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      members: [],
      formOK: false
    }
  },
  computed: {
    ...mapState([
      'openSubForm'
    ]),
    getButtonClass () {
      if (this.formOK) {
        return 'siteAddNewMemberButtonEnabled'
      } else {
        return 'siteAddNewMemberButton'
      }
    },
    newMemberIcon () {
      return require('../../../assets/icons/Person.png')
    }
  },
  watch: {
    areaid () {
      this.setComponent()
    },
    visible () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setSubForm',
      'setReloadMembers'
    ]),
    setComponent () {
      this.getMembers()
    },
    checkForm () {
      var selected = false
      var memberItems = document.getElementsByClassName('memberItem')
      if (memberItems) {
        for (var i = 0; i < memberItems.length; i++) {
          if (memberItems[i].checked) {
            selected = true
          }
        }
      }
      this.formOK = selected
    },
    checkExists (entity, id) {
      return entity.id === id
    },
    getMembers: async function () {
      var userList = ''
      var rMembers = await this.getAreaMembers(this.areaid, this.userid)
      var aMembers = await this.getUsers(this.userid)
      var members = []
      if (aMembers.length > 0) {
        aMembers.forEach(member => {
          if (!this.checkExists(rMembers, member.id)) {
            members.push(member)
          }
        })
      }
      members.forEach(entity => {
        userList += '|' + entity.id
      })
      userList = userList.substring(1)
      var newMembers = await this.getUserList(userList)

      this.members = newMembers.filter(member => !this.currentmembers.some(current => current.id === member.id))
    },
    closeForm () {
      this.setSubForm(false)
    },
    addMember: async function () {
      var memberItems = document.getElementsByClassName('memberItem')
      for (var i = 0; i < memberItems.length; i++) {
        if (memberItems[i].checked) {
          await this.addEntityArea(memberItems[i].id, this.areaid)
        }
      }
      this.setReloadMembers(true)
      this.closeForm()
    },
    cancelAdd () {
      this.setSubForm(false)
    }
  }
}

</script>
<style scoped>
  .siteNewMemberBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .siteNewMemberHolder {
    display: inline-block;
    background-color: rgba(255,255,255,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 160px);
    top: calc(10%);
    max-height: calc(100% - 180px);
    width: 380px;
    z-index: 999;
  }
  .siteCancelNewMemberButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .siteAddNewMemberButton {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    opacity: 0.5;
  }
  .siteAddNewMemberButtonEnabled {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .siteTableholder {
    overflow: auto;
    height:calc(100% - 64px);
    max-height:300px;
  }
  .memberItem {
    margin: 7px;
  }
  .alert{
    width:100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top:10px;
    color: black;
    background-color: #fff;
  }
  .icon {
    height: 25px;
    width: 25px;
  }
  .title {
    display: inline-block;
    height: 25px;
    padding-top:3px;
    padding-left: 10px;
  }
</style>
