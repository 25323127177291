<template>
  <div class="designPackSummaryContainer">
    <DesignPackDataEditorDialogBox
      :dialog="detailedSummaryComponent.showDataEditorDialog"
      :entries="detailedSummaryComponent.editingEntries"
      :entries-type="detailedSummaryComponent.editingEntriesType"
      :dlg-close-from-top="false"
      :show-title="false"
      :warning-message="''"
      :dlg-message="detailedSummaryComponent.editingEntriesDialogHeader"
      dlgid="fileUploadDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      @cancelAction="detailedSummaryComponent.showDataEditorDialog = false"
      @saveAction="saveDPEntryEditAction"
    />
    <div>
      <h3>Contract</h3>
      <div
        v-if="iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length === 0"
        class="floatingBox"
      >
        <DesignPackMargins
          :is-read-only="isReadOnly"
          :show-price-overrides="hasCommercialsPermission"
          @marginsChanged="onMarginsChanged"
          @priceOverridesChanged="onPriceOverridesChanged"
          @priceOverrideTableLostFocus="priceOverrideTableLostFocus"
          @discountsChanged="onDiscountsChanged" />
      </div>
      <div class="floatingBox">
        <table
          id="DetailedContractSummaryTable"
          class="iquoteDesignpackTable noTableShading">
          <tr>
            <th
              rowspan="2"
            >Description</th>
            <th rowspan="2">SF COE</th>
            <th
              colspan="8"
              class="highlightLeftBorder">Total Contract</th>
          </tr>
          <tr>
            <th class="highlightLeftBorder">Sell</th>
            <th>Third Party Costs</th>
            <th>RLEC</th>
            <th>RLEC %</th>
            <th>Internal Costs</th>
            <th>GM</th>
            <th>GM%</th>
            <th>&nbsp;</th>
          </tr>
          <tbody
            v-for="designPackEntrySource in iQuoteCalculationsDesignPackMixin_designPackSummaryEntrySources"
            :key="designPackEntrySource"
          >
            <tr>
              <th
                style="text-align: left"
                colspan="10">{{ designPackEntrySource }}</th>
            </tr>
            <tr
              v-for="designPackEntry in iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.filter(x => x.source === designPackEntrySource)"
              :key="designPackEntry.description + designPackEntry.SF_COE"
              :class="{disabledEntry: iQuoteCalculationsDesignPackMixin_isDisabledEntry(designPackEntry)}">
              <td rowspan="1">{{ designPackEntry.description }}</td>
              <td rowspan="1">{{ designPackEntry.SF_COE }}</td>

              <td
                :class="getBreakdownEntrySellPriceClass(designPackEntry, true, true)"
                class="highlightLeftBorderInner">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_entryContractSellPrice(designPackEntry)) }}</td>
              <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_entryContractThirdPartyCost(designPackEntry)) }}</td>
              <td>{{ iQuoteCalculationsDesignPackMixin_getRlecValue(designPackEntry.upfrontSellPrice + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringSellPrice / 12)), designPackEntry.upfrontExternalCost + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringExternalCost / 12))) }}</td>
              <td>{{ iQuoteCalculationsDesignPackMixin_getRlecPercent(designPackEntry.upfrontSellPrice + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringSellPrice / 12)), designPackEntry.upfrontExternalCost + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringExternalCost / 12))) }}</td>
              <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_entryContractInternalCost(designPackEntry)) }}</td>
              <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(designPackEntry.upfrontSellPrice + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringSellPrice / 12)), designPackEntry.upfrontExternalCost + designPackEntry.upfrontInternalCost + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringExternalCost / 12)) + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringInternalCost / 12))) }}</td>
              <td>
                {{ iQuoteCalculationsDesignPackMixin_getGmPercent(designPackEntry.upfrontSellPrice + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringSellPrice / 12)), designPackEntry.upfrontExternalCost + designPackEntry.upfrontInternalCost + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringExternalCost / 12)) + (iQuoteStore_GetDesignPack.termLengthMonths * (designPackEntry.recurringInternalCost / 12))) }}
              </td>
              <td style="text-align: left">
                <button
                  v-if="!isReadOnly && !iQuoteCalculationsDesignPackMixin_isDisabledEntry(designPackEntry) && designPackEntry.supportsPausing"
                  :id="'btnDisable' + designPackEntry.id.toString()"
                  type="button"
                  class="iQuoteIconButton"
                  title="Disable this entry so that the costs and prices are NOT INCLUDED in the design pack and quote"
                  @click="iQuoteCalculationsDesignPackMixin_disableEntry(designPackEntry)"
                >
                  <img
                    :src="iQuoteImagesMixin_iconPause"
                    alt="disableEntry">
                </button>
                <button
                  v-if="!isReadOnly && iQuoteCalculationsDesignPackMixin_isDisabledEntry(designPackEntry)"
                  :id="'btnEnable' + designPackEntry.id.toString()"
                  type="button"
                  class="iQuoteIconButton"
                  title= "Enable this entry so that the costs and prices are included in the design pack and quote"
                  @click="iQuoteCalculationsDesignPackMixin_enableEntry(designPackEntry)"
                >
                  <img
                    :src="iQuoteImagesMixin_iconResume"
                    alt="disableEntry">
                </button>
              </td>
            </tr>
          </tbody>
          <tr>
            <th colspan="2">TOTAL</th>
            <th class="highlightLeftBorder">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalContractSellPrice) }}</th>
            <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost) }}</th>
            <th>{{ iQuoteCalculationsDesignPackMixin_totalContractRlecValue }}</th>
            <th>{{ iQuoteCalculationsDesignPackMixin_totalContractRlecPercent }}</th>
            <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalContractInternalCost) }}</th>
            <th>{{ iQuoteCalculationsDesignPackMixin_totalContractGmValue }}</th>
            <th>{{ iQuoteCalculationsDesignPackMixin_totalContractGmPercent }}</th>
            <th>&nbsp;</th>
          </tr>
        </table>
      </div>
    </div>

    <h3>Breakdown</h3>

    <table
      id="DetailedBreakdownSummaryTable"
      class="iquoteDesignpackTable noTableShading">
      <tr>
        <th
          rowspan="2"
          width="150px">Description</th>
        <th rowspan="2">SF COE</th>
        <th
          colspan="8"
          class="highlightLeftBorder">Implementation Services</th>
        <th
          colspan="8"
          class="highlightLeftBorder">Annual Recurring</th>
      </tr>
      <tr>
        <th class="highlightLeftBorder">Sell</th>
        <th>Third Party Costs</th>
        <th>RLEC</th>
        <th>RLEC %</th>
        <th>Internal Costs</th>
        <th>GM</th>
        <th>GM%</th>
        <th>&nbsp;</th>

        <th class="highlightLeftBorder">Sell</th>
        <th>Third Party Costs</th>
        <th>RLEC</th>
        <th>RLEC %</th>
        <th>Internal Costs</th>
        <th>GM</th>
        <th>GM%</th>
        <th>&nbsp;</th>
      </tr>
      <tbody
        v-for="designPackEntrySource in iQuoteCalculationsDesignPackMixin_designPackSummaryEntrySources"
        :key="designPackEntrySource">
        <tr>
          <th colspan="18">{{ designPackEntrySource }}</th>
        </tr>
        <tr
          v-for="designPackEntry in iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.filter(x => x.source === designPackEntrySource)"
          :key="designPackEntry.id"
          :class="{disabledEntry: iQuoteCalculationsDesignPackMixin_isDisabledEntry(designPackEntry)}"
        >
          <td>{{ designPackEntry.description }}</td>
          <td>{{ designPackEntry.SF_COE }}</td>

          <td
            :class="getBreakdownEntrySellPriceClass(designPackEntry, false, true)"
            class="highlightLeftBorderInner">
            <div>
              <span>
                <TooltipText
                  :text="iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontSellPrice)"
                  :tooltip-content="getDPEntryDetail(designPackEntry, ['unitOOInternalPrice', 'unitOOExternalPrice'])"
                  :disable-tooltip="!iQuoteStore_GetDesignPack.useDetailDataForSummaries || (designPackEntry.upfrontExternalPrice + designPackEntry.upfrontInternalPrice === 0) || detailIsSameAsSummary(designPackEntry)"
                  :align="'left'"
                  width="650" />
              </span>
            </div>
            <div
              v-if="designPackEntry.upfrontSellPrice !== designPackEntry.upfrontExternalPrice + designPackEntry.upfrontInternalPrice"
            >
              <TooltipIcon
                :text="'Sell Price overridden.  Original Price: ' + iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontInternalPrice + designPackEntry.upfrontExternalPrice)"
                style="margin-left: -15px"
                width="500"
              />
              <br>
            </div>
          </td>
          <td>
            <TooltipText
              :text="iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontExternalCost)"
              :tooltip-content="getDPEntryDetail(designPackEntry, ['unitOOExternalCost'])"
              :disable-tooltip="!iQuoteStore_GetDesignPack.useDetailDataForSummaries || designPackEntry.upfrontExternalCost === 0 || detailIsSameAsSummary(designPackEntry)"
              :align="'left'"
              width="750" />
          </td>
          <td>{{ iQuoteCalculationsDesignPackMixin_getRlecValue(designPackEntry.upfrontSellPrice, designPackEntry.upfrontExternalCost) }}</td>
          <td>{{ iQuoteCalculationsDesignPackMixin_getRlecPercent(designPackEntry.upfrontSellPrice, designPackEntry.upfrontExternalCost) }}</td>
          <td>
            <TooltipText
              :text="iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontInternalCost)"
              :tooltip-content="getDPEntryDetail(designPackEntry, ['unitOOInternalCost'])"
              :disable-tooltip="!iQuoteStore_GetDesignPack.useDetailDataForSummaries || designPackEntry.upfrontInternalCost === 0 || detailIsSameAsSummary(designPackEntry)"
              :align="'left'"
              width="750" />
          </td>
          <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(designPackEntry.upfrontSellPrice, designPackEntry.upfrontInternalCost + designPackEntry.upfrontExternalCost) }}</td>
          <td>
            {{ iQuoteCalculationsDesignPackMixin_getGmPercent(designPackEntry.upfrontSellPrice, designPackEntry.upfrontInternalCost + designPackEntry.upfrontExternalCost) }}
          </td>
          <td>
            <button
              v-if="!iQuoteCalculationsDesignPackMixin_isDisabledEntry(designPackEntry) && !isReadOnly && (designPackEntry.upfrontInternalPrice + designPackEntry.upfrontExternalPrice) !== 0"
              :id="'btnEditEntries' + designPackEntry.id.toString()"
              type="button"
              class="iQuoteIconButton"
              title="Edit Prices for this section"
              @click="editUpfrontEntriesClicked(designPackEntry)">
              <img
                :src="iQuoteImagesMixin_iconEdit"
                alt="edit">
            </button>
          </td>

          <td
            :class="getBreakdownEntrySellPriceClass(designPackEntry, true, false)"
            class="highlightLeftBorderInner">
            <span>
              <TooltipText
                :text="iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringSellPrice)"
                :tooltip-content="getDPEntryDetail(designPackEntry, ['unitARInternalPrice', 'unitARExternalPrice'])"
                :disable-tooltip="!iQuoteStore_GetDesignPack.useDetailDataForSummaries || (designPackEntry.recurringExternalPrice + designPackEntry.recurringInternalPrice === 0) || detailIsSameAsSummary(designPackEntry)"
                :align="'right'"
                width="750" />
            </span>
            <div
              v-if="designPackEntry.recurringSellPrice !== designPackEntry.recurringExternalPrice + designPackEntry.recurringInternalPrice"
            >
              <TooltipIcon
                :text="'Sell Price overridden.  Original Price: ' + iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringInternalPrice + designPackEntry.recurringExternalPrice)"
                width="500"
                align="right"
                style="margin-left: -15px"
              />
              <br>
            </div>
          </td>
          <td>
            <TooltipText
              :text="iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringExternalCost)"
              :tooltip-content="getDPEntryDetail(designPackEntry, ['unitARExternalCost'])"
              :disable-tooltip="!iQuoteStore_GetDesignPack.useDetailDataForSummaries || designPackEntry.recurringExternalCost === 0 || detailIsSameAsSummary(designPackEntry)"
              :align="'right'"
              width="750" />
          </td>
          <td>{{ iQuoteCalculationsDesignPackMixin_getRlecValue(designPackEntry.recurringSellPrice, designPackEntry.recurringExternalCost) }}</td>
          <td>{{ iQuoteCalculationsDesignPackMixin_getRlecPercent(designPackEntry.recurringSellPrice, designPackEntry.recurringExternalCost) }}</td>
          <td v-if="!iQuoteStore_GetDesignPack.useDetailDataForSummaries">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringInternalCost) }}</td>
          <td v-if="iQuoteStore_GetDesignPack.useDetailDataForSummaries">
            <TooltipText
              :text="iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringInternalCost)"
              :tooltip-content="getDPEntryDetail(designPackEntry, ['unitARInternalCost'])"
              :disable-tooltip="!iQuoteStore_GetDesignPack.useDetailDataForSummaries || designPackEntry.recurringInternalCost === 0 || detailIsSameAsSummary(designPackEntry)"
              :align="'right'"
              width="650" />
          </td>
          <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(designPackEntry.recurringSellPrice, designPackEntry.recurringInternalCost + designPackEntry.recurringExternalCost) }}</td>
          <td>
            {{ iQuoteCalculationsDesignPackMixin_getGmPercent(designPackEntry.recurringSellPrice, designPackEntry.recurringInternalCost + designPackEntry.recurringExternalCost) }}
          </td>
          <td>
            <button
              v-if="!iQuoteCalculationsDesignPackMixin_isDisabledEntry(designPackEntry) && !isReadOnly && (designPackEntry.recurringInternalPrice + designPackEntry.recurringExternalPrice) "
              :id="'btnEditEntries' + designPackEntry.id.toString()"
              type="button"
              class="iQuoteIconButton"
              title="Edit Prices for this section"
              @click="editRecurringEntriesClicked(designPackEntry)">
              <img
                :src="iQuoteImagesMixin_iconEdit"
                alt="edit">
            </button>
          </td>
        </tr>
      </tbody>
      <tr style="text-align:right">
        <th colspan="2">TOTAL</th>
        <th class="highlightLeftBorder">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalUpfrontRlecValue }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalUpfrontRlecPercent }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalUpfrontGmValue }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalUpfrontGmPercent }}</th>
        <th>&nbsp;</th>

        <th class="highlightLeftBorder">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalRecurringRlecValue }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalRecurringRlecPercent }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalRecurringGmValue }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalRecurringGmPercent }}</th>
        <th>&nbsp;</th>
      </tr>
    </table>
    <br>

    <table
      id="DetailedSFBreakdownSummaryTable"
      class="iquoteDesignpackTable">
      <tr>
        <th
          rowspan="2"
          width="150px">SF COE</th>
        <th rowspan="2">Business COE</th>
        <th
          colspan="7"
          class="highlightLeftBorder">Implementation Services</th>
        <th
          colspan="7"
          class="highlightLeftBorder">Annual Recurring</th>
      </tr>
      <tr>
        <th class="highlightLeftBorder">Sell</th>
        <th>Third Party Costs</th>
        <th>RLEC</th>
        <th>RLEC %</th>
        <th>Internal Costs</th>
        <th>GM</th>
        <th>GM%</th>

        <th class="highlightLeftBorder">Sell</th>
        <th>Third Party Costs</th>
        <th>RLEC</th>
        <th>RLEC %</th>
        <th>Internal Costs</th>
        <th>GM</th>
        <th>GM%</th>
      </tr>
      <tr
        v-for="sfToBusinessCoeItem in iQuoteCalculationsDesignPackMixin_sfAndBusinessValues"
        :key="sfToBusinessCoeItem.sfCoe"
        :class="{disabledEntry: iQuoteCalculationsDesignPackMixin_isDisabledSfCoe(sfToBusinessCoeItem.sfCoe)}">
        <td>{{ sfToBusinessCoeItem.sfCoe }}</td>
        <td>{{ sfToBusinessCoeItem.businessCoe }}</td>

        <td class="highlightLeftBorderInner">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(sfToBusinessCoeItem.upfrontSellPrice) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(sfToBusinessCoeItem.upfrontExternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getRlecValue(sfToBusinessCoeItem.upfrontSellPrice, sfToBusinessCoeItem.upfrontExternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getRlecPercent(sfToBusinessCoeItem.upfrontSellPrice, sfToBusinessCoeItem.upfrontExternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(sfToBusinessCoeItem.upfrontInternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(sfToBusinessCoeItem.upfrontSellPrice, sfToBusinessCoeItem.upfrontInternalCost + sfToBusinessCoeItem.upfrontExternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getGmPercent(sfToBusinessCoeItem.upfrontSellPrice, sfToBusinessCoeItem.upfrontInternalCost + sfToBusinessCoeItem.upfrontExternalCost) }}</td>

        <td class="highlightLeftBorderInner">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(sfToBusinessCoeItem.recurringSellPrice) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(sfToBusinessCoeItem.recurringExternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getRlecValue(sfToBusinessCoeItem.recurringSellPrice, sfToBusinessCoeItem.recurringExternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getRlecPercent(sfToBusinessCoeItem.recurringSellPrice, sfToBusinessCoeItem.recurringExternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(sfToBusinessCoeItem.recurringInternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(sfToBusinessCoeItem.recurringSellPrice, sfToBusinessCoeItem.recurringInternalCost + sfToBusinessCoeItem.recurringExternalCost) }}</td>
        <td>{{ iQuoteCalculationsDesignPackMixin_getGmPercent(sfToBusinessCoeItem.recurringSellPrice, sfToBusinessCoeItem.recurringInternalCost + sfToBusinessCoeItem.recurringExternalCost) }}</td>
      </tr>
      <tr>
        <th colspan="2">TOTAL</th>
        <th class="highlightLeftBorder">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalUpfrontRlecValue }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalUpfrontRlecPercent }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalUpfrontGmValue }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalUpfrontGmPercent }}</th>

        <th class="highlightLeftBorder">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalRecurringRlecValue }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalRecurringRlecPercent }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalRecurringInternalCostSalesforceView) }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalRecurringGmValueSalesforceView }}</th>
        <th>{{ iQuoteCalculationsDesignPackMixin_totalRecurringGmPercentSalesforceView }}</th>
      </tr>
    </table>

    <br>
    <br>
  </div>
</template>

<script>

import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'
import { iQuoteCalculationsDesignPackHardwareMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackHardware'
import { iQuoteCalculationsDesignPackCircuitsMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackCircuits'
import { iQuoteQuoteLicensesRequiredAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteLicensesRequiredAPIMixin'
import { iQuoteQuoteUnitCostsAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteUnitCostsAPIMixin'
import DesignPackMargins from '../designPackComponents/designPackMargins'
import DesignPackDataEditorDialogBox from '../designPackComponents/designPackDataEditorDialogBox'
import { iQuoteImagesMixin } from '../../../mixins/iQuote/Globals/iQuoteImages'
import TooltipIcon from '../../common/tooltipIcon'
import TooltipText from '../../common/tooltipText'
import { iQuoteEventBus } from '../../../events/iQuoteEvents'

import { mapActions } from 'vuex'

export default {
  name: 'DetailedSummary',
  components: {
    DesignPackMargins,
    TooltipIcon,
    TooltipText,
    DesignPackDataEditorDialogBox
  },
  mixins: [
    iQuoteStoreMixin,
    iQuoteConstants,
    iQuoteFunctions,
    iQuoteCalculationsDesignPackMixin,
    iQuoteCalculationsDesignPackHardwareMixin,
    iQuoteCalculationsDesignPackCircuitsMixin,
    iQuoteImagesMixin,
    iQuoteQuoteLicensesRequiredAPIMixin,
    iQuoteQuoteUnitCostsAPIMixin
  ],
  props: {
    isReadOnly: {
      type: Boolean,
      default: function () { return {} }
    }
  },
  data () {
    return {
      detailedSummaryComponent: {
        showDataEditorDialog: false,
        editingEntriesType: '', // 'upfront' or 'recurring'
        editingEntries: [], // The subset of the dp entries that are passed to the dataeditor dialog for editing
        editingEntriesDialogHeader: '' // the header shown on the dpeditoror dialog
      }
    }
  },
  computed: {
    hasCommercialsPermission () {
      return this.$store.getters.iQuoteCommercialsPermission
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    onMarginsChanged (newMargins) {
      var designPack = this.iQuoteStore_GetDesignPack
      this.iQuoteCalculationsDesignPackMixin_updateMargins(newMargins)

      designPack.margins = newMargins
      this.setDesignPack(designPack)
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack, 'Margins changed')
    },
    async priceOverrideTableLostFocus () {
      var designPack = this.iQuoteStore_GetDesignPack

      console.log('Foo', 'Focus Lost')

      if (this.iQuoteStore_GetDesignPack.useDetailDataForSummaries) {
      // When price override table loses focus, we take that to mean that editing is complete, so we can save to DB and also alter
      // the overriden price to be the value that we were able to override it to

        designPack.priceOverrides.forEach(entry => {
          var dpSectionDescription = entry.description
          var dpSectionSalesforceCoeCodeId = entry.salesforceCoeCodeId

          designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'recurringExternalSellPrice')
          designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'recurringInternalSellPrice')
          designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'upfrontExternalSellPrice')
          designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'upfrontInternalSellPrice')
        })
      }

      // Make a copy of the DP object so that it will trigger any watches on it (So the DP discounts table will update to new values)
      var designPackCopy = { ...designPack }

      this.setDesignPack(designPackCopy)

      // Note that we purposely aren't using the iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack() function here,
      // as we don't want what comes back from the database to overwrite the DP in the store, as it may cause
      // interference if the user is editing several prices in quick succession.
      await this.iQuoteQuoteDesignPacksAPIMixin_saveQuoteDesignPackToDB(designPack)

      await this.iQuoteQuoteDesignPacksAPIMixin_saveDetailData(designPack.quoteDesignPackRevisionId, this.iQuoteStore_GetDesignPackDetailedDataEntries)

      iQuoteEventBus.$emit('designpack-priceOverridesChanged', designPack)
    },
    async onPriceOverridesChanged (priceOverrideValueChangedData) {
      var fieldToOverride = ''
      var priceOverrideField = ''

      // Work out what fields on the detail entry we need to update, based on the price override entered
      switch (priceOverrideValueChangedData.field) {
        case 'recurringExternalSellPrice':
          fieldToOverride = 'unitARExternalPrice'
          priceOverrideField = 'unitARPriceOverride'
          break
        case 'recurringInternalSellPrice':
          fieldToOverride = 'unitARInternalPrice'
          priceOverrideField = 'unitARPriceOverride'
          break
        case 'upfrontExternalSellPrice':
          fieldToOverride = 'unitOOExternalPrice'
          priceOverrideField = 'unitOOPriceOverride'
          break
        case 'upfrontInternalSellPrice':
          fieldToOverride = 'unitOOInternalPrice'
          priceOverrideField = 'unitOOPriceOverride'
          break
      }

      var detailDataWithinDPSection = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.visibleToCustomer === true &&
       x.dpSectionDescription === priceOverrideValueChangedData.description && // With same DP Section description
       x.dpSectionSalesforceCoeCodeId === priceOverrideValueChangedData.salesforceCoeCodeId && // With same DP Section salesforce code
       x[fieldToOverride] !== 0 && // Exclude any items that have £0 price in that field
       x.recurringCost === (priceOverrideValueChangedData.field.includes('recurring')) &&
       ((priceOverrideValueChangedData.field.includes('Internal') && x.costTypeId === this.iQuoteConstants.costTypes.internal) ||
        (priceOverrideValueChangedData.field.includes('External') && x.costTypeId === this.iQuoteConstants.costTypes.external))
      )

      var totalOfFixedPriceEntries = detailDataWithinDPSection.filter(x => !x.priceOverrideAllowed).reduce((accumulator, dataEntry) => accumulator + (dataEntry[fieldToOverride] * dataEntry.quantity), 0)
      var totalOriginalPrice = detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + (dataEntry[fieldToOverride] * dataEntry.quantity), 0)
      var percentageOfTotalPrice = 0
      var totalAssignedToEntries = 0

      // Attempt to spread the requested price across the entries that are related to it
      detailDataWithinDPSection.forEach(detailDataEntry => {
        if (priceOverrideValueChangedData.value === null) {
          // Price isn't being overridden, so use the default price
          detailDataEntry[priceOverrideField] = null
          totalAssignedToEntries += detailDataEntry[fieldToOverride] * detailDataEntry.quantity
        } else {
          if (detailDataEntry.priceOverrideAllowed && detailDataEntry[fieldToOverride] !== 0) {
            // Work out the percentage of the original price this entry took, so we can start off with the same percentage of the overridden price and tweak from there if required
            percentageOfTotalPrice = (detailDataEntry[fieldToOverride] * detailDataEntry.quantity) / (totalOriginalPrice - totalOfFixedPriceEntries)

            detailDataEntry[priceOverrideField] = this.iQuoteFunctions_RoundToTwoDecimalPlaces((priceOverrideValueChangedData.value * percentageOfTotalPrice) / detailDataEntry.quantity)
            totalAssignedToEntries += detailDataEntry[priceOverrideField] * detailDataEntry.quantity
          } else {
            totalAssignedToEntries += detailDataEntry[fieldToOverride] * detailDataEntry.quantity
          }
        }
      })

      if (priceOverrideValueChangedData.value === null) {
        // There is no price override, so no need to go further and check if prices match what we asked for
        await this.iQuoteCalculationsDesignPackMixin_storePriceOverridesInDetailDataSources(this.iQuoteStore_GetDesignPackDetailedDataEntries)
        return
      }

      if (priceOverrideValueChangedData.value > totalAssignedToEntries) {
        // Due to rounding etc we have assigned less to these entries than requested.  So loop through them and attempt to increase the values until we meet target

        // loop from items of biggest value to smallest
        detailDataWithinDPSection.sort((a, b) => (b[fieldToOverride] * b.quantity) - (a[fieldToOverride] * a.quantity)).forEach(detailDataEntry => {
          var priceDifferenceInPence = (priceOverrideValueChangedData.value - totalAssignedToEntries) * 100

          while (priceDifferenceInPence >= detailDataEntry.quantity && detailDataEntry.quantity > 0) {
            // Increment the price of each unit by a penny then check again

            if (detailDataEntry.priceOverrideAllowed && detailDataEntry[fieldToOverride] !== 0) {
              detailDataEntry[priceOverrideField] = this.iQuoteFunctions_RoundToTwoDecimalPlaces(detailDataEntry[priceOverrideField] + 0.01)
              totalAssignedToEntries += 0.01 * detailDataEntry.quantity
              priceDifferenceInPence = (priceOverrideValueChangedData.value - totalAssignedToEntries) * 100
            }
          }
        })
      } else if (priceOverrideValueChangedData.value < totalAssignedToEntries) {
        // Due to rounding etc we have assigned more to these entries than requested.  So loop through them and attempt to reduce the values

        // loop from items of biggest value to smallest
        detailDataWithinDPSection.sort((a, b) => (b[fieldToOverride] * b.quantity) - (a[fieldToOverride] * a.quantity)).forEach(detailDataEntry => {
          var priceDifferenceInPence = (totalAssignedToEntries - priceOverrideValueChangedData.value) * 100

          while (priceDifferenceInPence >= detailDataEntry.quantity && detailDataEntry.quantity > 0) {
            // Increment the price of each unit by a penny then check again

            if (detailDataEntry.priceOverrideAllowed && detailDataEntry[fieldToOverride] !== 0 && priceOverrideValueChangedData.value !== null) {
              detailDataEntry[priceOverrideField] = this.iQuoteFunctions_RoundToTwoDecimalPlaces(detailDataEntry[priceOverrideField] - 0.01)
              totalAssignedToEntries -= 0.01 * detailDataEntry.quantity
              priceDifferenceInPence = (totalAssignedToEntries - priceOverrideValueChangedData.value) * 100
            }
          }
        })
      }

      await this.iQuoteCalculationsDesignPackMixin_storePriceOverridesInDetailDataSources(this.iQuoteStore_GetDesignPackDetailedDataEntries)
    },
    onDiscountsChanged (newDiscounts) {
      var designPack = this.iQuoteStore_GetDesignPack

      this.iQuoteCalculationsDesignPackMixin_updateDiscounts(newDiscounts)

      designPack.discounts = newDiscounts
      this.setDesignPack(designPack)

      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack, 'Discounts changed')
    },
    editUpfrontEntriesClicked (designPackEntry) {
      const detailEntriesForThisDPEntry = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.dpSectionSalesforceCoeCodeId === designPackEntry.salesforceCoeCodeId &&
                                                                                                              x.dpSectionDescription === designPackEntry.description &&
                                                                                                              x.dpSource === designPackEntry.source && (x.unitOOInternalPrice + x.unitOOExternalPrice !== 0) && x.visibleToCustomer === true)

      var detailEntriesForThisDPEntryCopy = detailEntriesForThisDPEntry.map(array => ({ ...array })) // map as new objects so we aren't passing by reference

      // set the editor's textboxes for priceoverride to match the current values for the price overrides
      detailEntriesForThisDPEntryCopy.forEach(entry => {
        entry.priceOverride = entry.unitOOPriceOverride
      })

      this.detailedSummaryComponent.editingEntriesType = 'upfront'
      this.detailedSummaryComponent.showDataEditorDialog = true
      this.detailedSummaryComponent.editingEntries = detailEntriesForThisDPEntryCopy
      this.detailedSummaryComponent.editingEntriesDialogHeader = 'Edit Upfront Prices for ' + designPackEntry.description + ' (' + designPackEntry.SF_COE + ')'
    },
    editRecurringEntriesClicked (designPackEntry) {
      var detailEntriesForThisDPEntry = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.dpSectionSalesforceCoeCodeId === designPackEntry.salesforceCoeCodeId &&
                                                                                                              x.dpSectionDescription === designPackEntry.description &&
                                                                                                              x.dpSource === designPackEntry.source && (x.unitARInternalPrice + x.unitARExternalPrice) && x.visibleToCustomer === true)

      var detailEntriesForThisDPEntryCopy = detailEntriesForThisDPEntry.map(array => ({ ...array })) // map as new objects so we aren't passing by reference

      // set the editor's textboxes for priceoverride to match the current values for the price overrides
      detailEntriesForThisDPEntryCopy.forEach(entry => {
        entry.priceOverride = entry.unitARPriceOverride
      })

      this.detailedSummaryComponent.editingEntriesType = 'recurring'
      this.detailedSummaryComponent.showDataEditorDialog = true
      this.detailedSummaryComponent.editingEntries = detailEntriesForThisDPEntryCopy
      this.detailedSummaryComponent.editingEntriesDialogHeader = 'Edit Recurring Prices for ' + designPackEntry.description + ' (' + designPackEntry.SF_COE + ')'
    },
    getDPEntryDetail (designPackEntry, entryProperties) {
      // Construct HTML for the tooltop when you hover over dpsummary cost or price

      var designPackTableSection = this.iQuoteStore_GetDesignPackTableSections.find(x => x.id === designPackEntry.designPackTableSectionId)
      var detailEntriesForThisDPEntry = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.dpSectionSalesforceCoeCodeId === designPackEntry.salesforceCoeCodeId &&
                                                                                                              x.dpSectionDescription === designPackEntry.description &&
                                                                                                              x.dpSource === designPackEntry.source)

      var headerText = 'Cost'
      var pricesHaveBeenOverriden = false

      if (entryProperties[0].includes('Price')) {
        headerText = 'Price'
        pricesHaveBeenOverriden = detailEntriesForThisDPEntry.filter(x =>
          (entryProperties[0].includes('unitAR') && x.unitARPriceOverride !== null) ||
          (entryProperties[0].includes('unitOO') && x.unitOOPriceOverride !== null)).length > 0
      }

      var returnHtml = '<table style="table-layout:auto; text-align:right; font-size:14px"><tr><th>Description</th>'

      if (entryProperties[0].includes('unitAR')) {
        if (entryProperties[0].includes('Price')) {
          returnHtml += '<th>Monthly Unit RRP</th>'
        }
        returnHtml += '<th>Monthly Unit ' + headerText + '</th><th>Annual Unit ' + headerText + '</th>'
      } else {
        if (entryProperties[0].includes('Price')) {
          returnHtml += '<th>Upfront Unit RRP</th>'
        }
        returnHtml += '<th>Upfront Unit ' + headerText + '</th>'
      }

      if (pricesHaveBeenOverriden) {
        // extra column in table if any prices have been overriden
        returnHtml += '<th>Price Override</th>'
      }

      if (entryProperties[0].includes('Price')) {
        returnHtml += '<th>Discount %</th>'
        returnHtml += '<th>Margin %</th>'
      }

      returnHtml += '<th>Quantity</th><th>Total ' + headerText + '</th></tr>'

      var total = 0
      var unitValue = 0
      var numEntries = 0
      var unitValueAsString = ''
      var unitValueMonthlyAsString = ''

      detailEntriesForThisDPEntry.forEach(detailEntry => {
        entryProperties.forEach(entryProperty => {
          unitValue = detailEntry[entryProperty]

          var quantityForCalculatingTotal = detailEntry.quantity

          if (quantityForCalculatingTotal < 0) {
            // a credited item will have a negative quantity and negative cost.  We don't want to multiply two negatives to make it a positive
            quantityForCalculatingTotal = -quantityForCalculatingTotal
          }

          if (entryProperties[0].includes('Cost')) {
            unitValueAsString = this.iQuoteCalculationsDesignPackMixin_getCostDisplay(unitValue, 4)

            if (entryProperties[0].includes('unitAR')) {
              unitValueMonthlyAsString = this.iQuoteCalculationsDesignPackMixin_getCostDisplay(unitValue / 12, 4)
            } else {
              unitValueMonthlyAsString = ''
            }
          } else {
            unitValueAsString = this.iQuoteCalculationsDesignPackMixin_getCostDisplay(unitValue)

            if (entryProperties[0].includes('unitAR')) {
              unitValueMonthlyAsString = this.iQuoteCalculationsDesignPackMixin_getCostDisplay(unitValue / 12)
            } else {
              unitValueMonthlyAsString = ''
            }
          }

          if (unitValue !== 0 && (detailEntry.includeInCalculations || (headerText === 'Price' && detailEntry.visibleToCustomer))) {
            returnHtml += '<tr style="color: white"><td>' + detailEntry.description + '</td>'

            if (entryProperties[0].includes('Price')) {
              returnHtml += '<td>' + this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.getDetailEntryRRP(detailEntry) / 12) + '</td>'
            }

            if (pricesHaveBeenOverriden) {
              if (entryProperties[0].includes('unitAR') && detailEntry.unitARPriceOverride !== null) {
                returnHtml += '<td>' + unitValueMonthlyAsString + '</td>'
                returnHtml += '<td>' + unitValueAsString + '</td>'
                returnHtml += '<td>' + this.iQuoteCalculationsDesignPackMixin_getCostDisplay(detailEntry.unitARPriceOverride) + '</td>'
                unitValue = detailEntry.unitARPriceOverride
              } else if (entryProperties[0].includes('unitOO') && detailEntry.unitOOPriceOverride !== null) {
                returnHtml += '<td>' + unitValueAsString + '</td>'
                returnHtml += '<td>' + this.iQuoteCalculationsDesignPackMixin_getCostDisplay(detailEntry.unitOOPriceOverride) + '</td>'
                unitValue = detailEntry.unitOOPriceOverride
              } else {
                if (entryProperties[0].includes('unitAR')) {
                  returnHtml += '<td>' + unitValueMonthlyAsString + '</td>'
                }

                returnHtml += '<td>' + unitValueAsString + '</td>'

                // Prices have been overridden for this section, but this particular entry hasn't, so just leave cell blank.
                returnHtml += '<td></td>'
              }
            } else {
              if (entryProperties[0].includes('unitAR')) {
                returnHtml += '<td>' + unitValueMonthlyAsString + '</td>'
              }

              returnHtml += '<td>' + unitValueAsString + '</td>'
            }

            if (entryProperties[0].includes('Price')) {
              var marginPercentColour = 'white'
              var discountPercentColour = 'white'

              if (designPackTableSection !== undefined) {
                var applicableMarginMinimum = null
                var applicableDiscountMaximum = null

                if (detailEntry.recurringCost) {
                  if (detailEntry.costTypeId === 1 /* recurring internal */) {
                    applicableDiscountMaximum = designPackTableSection.recurringInternalMaxDiscount
                    applicableMarginMinimum = designPackTableSection.recurringInternalIquoteUserMinMargin
                  } else { /* recurring external */
                    applicableDiscountMaximum = designPackTableSection.recurringExternalMaxDiscount
                    applicableMarginMinimum = designPackTableSection.recurringExternalIquoteUserMinMargin
                  }
                } else {
                  if (detailEntry.costTypeId === 1 /* upfront internal */) {
                    applicableDiscountMaximum = designPackTableSection.upfrontInternalMaxDiscount
                    applicableMarginMinimum = designPackTableSection.upfrontInternalIquoteUserMinMargin
                  } else { /* upfront external */
                    applicableDiscountMaximum = designPackTableSection.upfrontExternalMaxDiscount
                    applicableMarginMinimum = designPackTableSection.upfrontExternalIquoteUserMinMargin
                  }
                }

                if (detailEntry.unitARExternalRRP !== undefined || detailEntry.unitOOExternalRRP !== undefined) {
                  if (applicableDiscountMaximum !== null &&
                    this.getDetailEntryDiscountPercent(detailEntry) > this.iQuoteFunctions_RoundToTwoDecimalPlaces(applicableDiscountMaximum)) {
                    discountPercentColour = 'red'
                  }
                }
              }

              if (this.iQuoteFunctions_RoundToTwoDecimalPlaces(this.getDetailEntryDiscountPercent(detailEntry)) !== 0) {
                returnHtml += '<td style="color: ' + discountPercentColour + '">' + this.iQuoteFunctions_RoundToTwoDecimalPlaces(this.getDetailEntryDiscountPercent(detailEntry)) + '%</td>'
              } else {
                returnHtml += '<td>-</td>'
              }

              if (applicableMarginMinimum !== null &&
                this.getDetailEntryMargin(detailEntry) < this.iQuoteFunctions_RoundToTwoDecimalPlaces(applicableMarginMinimum)) {
                marginPercentColour = 'red'
              }

              returnHtml += '<td style="color: ' + marginPercentColour + '">' + this.iQuoteFunctions_RoundToTwoDecimalPlaces(this.getDetailEntryMargin(detailEntry)) + '%</td>'
            }

            returnHtml += '<td>' + detailEntry.quantity + '</td><td>' + this.iQuoteCalculationsDesignPackMixin_getCostDisplay(unitValue * quantityForCalculatingTotal) + '</td></tr>'

            total += Number((unitValue * quantityForCalculatingTotal).toFixed(2))
            numEntries++
          }
        })
      })

      if (numEntries === 0) {
        returnHtml += '<tr style="color: white"><td colspan="4">No Entries to Display</td></tr>'
      }

      var numColumns = 4

      if (pricesHaveBeenOverriden) {
        numColumns++
      }

      if (entryProperties[0].includes('Price')) {
        numColumns += 3
      }

      if (entryProperties[0].includes('unitAR')) {
        numColumns++
      }

      returnHtml += '<tr><th colspan="' + (numColumns - 1).toString() + '" style="text-align:right">TOTAL:</th><th>' + this.iQuoteCalculationsDesignPackMixin_getCostDisplay(total) + '</th></tr>'

      returnHtml += '</table>'

      return returnHtml
    },
    detailIsSameAsSummary (designPackEntry) {
      var detailEntriesForThisDPEntry = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.dpSectionSalesforceCoeCodeId === designPackEntry.salesforceCoeCodeId &&
                                                                                                              x.dpSectionDescription === designPackEntry.description &&
                                                                                                              x.dpSource === designPackEntry.source &&
                                                                                                              x.includeInCalculations === true)

      if (detailEntriesForThisDPEntry.length !== 1) {
        return false
      }

      var detailEntry = detailEntriesForThisDPEntry[0]

      if (designPackEntry.description === detailEntry.description && detailEntry.quantity === 1) {
        return true
      } else {
        return false
      }
    },
    async saveDPEntryEditAction () {
      var designPack = this.iQuoteStore_GetDesignPack

      this.detailedSummaryComponent.showDataEditorDialog = false

      // set the price overrides from the editor to the appropriate field depending on the recurrence type
      this.detailedSummaryComponent.editingEntries.forEach(entry => {
        if (this.detailedSummaryComponent.editingEntriesType === 'recurring') {
          entry.unitARPriceOverride = entry.priceOverride
        } else if (this.detailedSummaryComponent.editingEntriesType === 'upfront') {
          entry.unitOOPriceOverride = entry.priceOverride
        }
      })

      const storeEntries = this.iQuoteStore_GetDesignPackDetailedDataEntries
      var storeEntriesCopy = storeEntries.map(array => ({ ...array })) // map as new objects

      this.detailedSummaryComponent.editingEntries.forEach(editedEntry => {
        var storeEntry = storeEntriesCopy.find(x => x.itemId === editedEntry.itemId && x.recurringCost === editedEntry.recurringCost)

        if (storeEntry !== undefined) {
          storeEntry.unitARPriceOverride = editedEntry.unitARPriceOverride
          storeEntry.unitOOPriceOverride = editedEntry.unitOOPriceOverride
        } else {
          console.log('Cannot find entry ' + editedEntry.itemId + ' in the store to update')
        }
      })

      this.setDesignPackDetailedDataEntries(storeEntriesCopy)

      // Store the current state of the detail data now that margins and discounts are present to recalculate prices
      // var entries = this.iQuoteStore_GetDesignPackDetailedDataEntries.map(array => ({ ...array })) // map as new objects so that change is detected
      // this.setDesignPackDetailedDataEntries(entries)

      await this.iQuoteCalculationsDesignPackMixin_storePriceOverridesInDetailDataSources(storeEntriesCopy)

      var dpSectionDescription = this.detailedSummaryComponent.editingEntries[0].dpSectionDescription
      var dpSectionSalesforceCoeCodeId = this.detailedSummaryComponent.editingEntries[0].dpSectionSalesforceCoeCodeId

      if (this.detailedSummaryComponent.editingEntriesType === 'recurring') {
        designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'recurringExternalSellPrice')
        designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'recurringInternalSellPrice')
      } else {
        designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'upfrontExternalSellPrice')
        designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'upfrontInternalSellPrice')
      }

      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack, 'Price Editor Dialog Update')

      // Save designpack entries to DB
      await this.iQuoteQuoteDesignPacksAPIMixin_saveDetailData(designPack.quoteDesignPackRevisionId, this.iQuoteStore_GetDesignPackDetailedDataEntries)

      iQuoteEventBus.$emit('designpack-priceOverridesChanged', designPack)
    },
    getBreakdownEntrySellPriceClass (designPackSummaryEntry, recurringCost, upfrontCost) {
      var designPackTableSection = this.$store.getters.designPackTableSections.find(x => x.id === designPackSummaryEntry.designPackTableSectionId)
      var foundItemOutsideOfValidRange = false
      if (designPackTableSection === undefined) {
        return ''
      } else {
        var applicableMarginMinimum = null
        var applicableDiscountMaximum = null

        for (let costTypeId = 1; costTypeId <= 2; costTypeId++) {
          if (recurringCost) {
            if (costTypeId === 1 /* internal */) {
              applicableDiscountMaximum = designPackTableSection.recurringInternalMaxDiscount
              applicableMarginMinimum = designPackTableSection.recurringInternalIquoteUserMinMargin
            } else { /* external */
              applicableDiscountMaximum = designPackTableSection.recurringExternalMaxDiscount
              applicableMarginMinimum = designPackTableSection.recurringExternalIquoteUserMinMargin
            }
          }

          if (upfrontCost && applicableMarginMinimum === null && applicableDiscountMaximum === null) {
            if (costTypeId === 1 /* internal */) {
              applicableDiscountMaximum = designPackTableSection.upfrontInternalMaxDiscount
              applicableMarginMinimum = designPackTableSection.upfrontInternalIquoteUserMinMargin
            } else { /* external */
              applicableDiscountMaximum = designPackTableSection.upfrontExternalMaxDiscount
              applicableMarginMinimum = designPackTableSection.upfrontExternalIquoteUserMinMargin
            }
          }

          if (applicableMarginMinimum !== null || applicableDiscountMaximum !== null) {
            var detailEntriesForThisDPEntry = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.dpSectionSalesforceCoeCodeId === designPackSummaryEntry.salesforceCoeCodeId &&
                                                                                                                x.dpSectionDescription === designPackSummaryEntry.description &&
                                                                                                                x.dpSource === designPackSummaryEntry.source &&
                                                                                                                x.includeInCalculations === true &&
                                                                                                                x.costTypeId === costTypeId &&
                                                                                                                (
                                                                                                                  (x.recurringCost && recurringCost) ||
                                                                                                                  (!x.recurringCost && upfrontCost)
                                                                                                                ))

            detailEntriesForThisDPEntry.forEach(detailEntry => {
              if (
                (applicableMarginMinimum !== null && this.getDetailEntryMargin(detailEntry) < this.iQuoteFunctions_RoundToTwoDecimalPlaces(applicableMarginMinimum)) ||
                (applicableDiscountMaximum !== null && this.getDetailEntryDiscountPercent(detailEntry) > this.iQuoteFunctions_RoundToTwoDecimalPlaces(applicableDiscountMaximum))
              ) {
                foundItemOutsideOfValidRange = true
              }
            })
          }
        }

        return foundItemOutsideOfValidRange ? 'marginError' : ''
      }
    },
    getDetailEntryTotalCost (entry) {
      var quantity = entry.quantity

      if (quantity < 0) {
        // a credited item will have a negative quantity and negative cost.  We don't want to multiply two negatives to make it a positive
        quantity = -quantity
      }

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(this.getDetailEntryCost(entry)) * quantity
    },
    getDetailEntryTotalPrice (entry) {
      var quantity = entry.quantity

      if (quantity < 0) {
        // a credited item will have a negative quantity and negative cost.  We don't want to multiply two negatives to make it a positive
        quantity = -quantity
      }

      var priceOverride = null

      if (entry.recurringCost) {
        priceOverride = entry.unitARPriceOverride
      } else {
        priceOverride = entry.unitOOPriceOverride
      }

      if (priceOverride !== '' && priceOverride !== null) {
        return this.iQuoteFunctions_RoundToTwoDecimalPlaces(priceOverride) * quantity
      } else {
        return this.iQuoteFunctions_RoundToTwoDecimalPlaces(this.getDetailEntryPrice(entry)) * quantity
      }
    },
    getDetailEntryMargin (entry) {
      var designPack = this.iQuoteStore_GetDesignPack
      var priceOverride = null

      if (entry.recurringCost) {
        priceOverride = entry.unitARPriceOverride
      } else {
        priceOverride = entry.unitOOPriceOverride
      }

      var applicableMargin = designPack.margins.find(x => x.description === entry.dpSectionDescription &&
                                                 x.salesforceCoeCodeId === entry.dpSectionSalesforceCoeCodeId)

      if (entry.dpSectionDescription === 'SIP Software Licenses (RRP Model)') {
        console.log('foo')
      }

      if (priceOverride !== null || entry.usesGlobalMargins === false) {
        // User overrode the price or we are using RRP Model so calculate the margin

        var cost = this.getDetailEntryTotalCost(entry)
        var price = this.getDetailEntryTotalPrice(entry)
        return this.iQuoteFunctions_RoundToTwoDecimalPlaces(((price - cost) / price) * 100)
      } else if (applicableMargin !== undefined) {
        // We are using cost plus margin model and price hasn't been overriden, so just return the margin that was set

        if (entry.recurringCost === true) {
          if (entry.costTypeId === this.iQuoteConstants.costTypes.external && applicableMargin.recurringExternalMargin !== null) {
            // Recurring External specified
            return applicableMargin.recurringExternalMargin
          } else if (entry.costTypeId === this.iQuoteConstants.costTypes.internal && applicableMargin.recurringInternalMargin !== null) {
            // Recurring Internal specified
            return applicableMargin.recurringInternalMargin
          } else {
            // No Margin specified
            return 0.00
          }
        } else {
          if (entry.costTypeId === this.iQuoteConstants.costTypes.external && applicableMargin.upfrontExternalMargin !== null) {
            // Upfront External specified
            return applicableMargin.upfrontExternalMargin
          } else if (entry.costTypeId === this.iQuoteConstants.costTypes.internal && applicableMargin.upfrontInternalMargin !== null) {
            // Upfront Internal specified
            return applicableMargin.upfrontInternalMargin
          } else {
            // No Margin specified
            return 0.00
          }
        }
      } else {
        // No applicable margins found, so return 0
        return 0.00
      }
    },
    getDetailEntryDiscountPercent (entry) {
      var designPack = this.iQuoteStore_GetDesignPack

      var priceOverride = null

      if (entry.recurringCost) {
        priceOverride = entry.unitARPriceOverride
      } else {
        priceOverride = entry.unitOOPriceOverride
      }

      var applicableDiscount = designPack.discounts.find(x => x.description === entry.dpSectionDescription &&
                                                 x.salesforceCoeCodeId === entry.dpSectionSalesforceCoeCodeId)

      if (priceOverride !== null) {
        // User overrode the price, so return the calculated discount after override

        var rrp = this.getDetailEntryRRP(entry)

        if ((rrp === null || rrp === undefined) && applicableDiscount !== undefined) {
          // RRP wasn't specified.  So we'll just set it to what the default price was by removing the discount that was applied to it

          if (entry.recurringCost === true) {
            if (entry.costTypeId === this.iQuoteConstants.costTypes.external && applicableDiscount.recurringExternalDiscount !== null) {
              // Recurring External entry
              rrp = entry.unitARExternalPrice / ((100 - applicableDiscount.recurringExternalDiscount) / 100)
            } else if (entry.costTypeId === this.iQuoteConstants.costTypes.internal && applicableDiscount.recurringInternalDiscount !== null) {
              // Recurring Internal entry
              rrp = entry.unitARInternalPrice / ((100 - applicableDiscount.recurringInternalDiscount) / 100)
            } else {
              // No Discount specified
              return 0.00
            }
          } else {
            if (entry.costTypeId === this.iQuoteConstants.costTypes.external && applicableDiscount.upfrontExternalDiscount !== null) {
              // Upfront External entry
              rrp = entry.unitOOExternalPrice / ((100 - applicableDiscount.upfrontExternalDiscount) / 100)
            } else if (entry.costTypeId === this.iQuoteConstants.costTypes.internal && applicableDiscount.upfrontInternalDiscount !== null) {
              // Upfront Internal entry
              rrp = entry.unitOOInternalPrice / ((100 - applicableDiscount.upfrontInternalDiscount) / 100)
            } else {
              // No Discount specified
              return 0.00
            }
          }
        }

        if (rrp === null) {
          return 0
        }

        var discount = rrp - priceOverride
        return this.iQuoteFunctions_RoundToTwoDecimalPlaces((discount / rrp) * 100)
      } else if (entry.canBeDiscounted && applicableDiscount !== undefined) {
        // If discounts are allowed we need to return the discount that was specified

        if (entry.recurringCost === true) {
          if (entry.costTypeId === this.iQuoteConstants.costTypes.external && applicableDiscount.recurringExternalDiscount !== null) {
            // Recurring External specified
            return applicableDiscount.recurringExternalDiscount
          } else if (entry.costTypeId === this.iQuoteConstants.costTypes.internal && applicableDiscount.recurringInternalDiscount !== null) {
            // Recurring Internal specified
            return applicableDiscount.recurringInternalDiscount
          } else {
            // No Discount specified
            return 0.00
          }
        } else {
          if (entry.costTypeId === this.iQuoteConstants.costTypes.external && applicableDiscount.upfrontExternalDiscount !== null) {
            // Upfront External specified
            return applicableDiscount.upfrontExternalDiscount
          } else if (entry.costTypeId === this.iQuoteConstants.costTypes.internal && applicableDiscount.upfrontInternalDiscount !== null) {
            // Upfront Internal specified
            return applicableDiscount.upfrontInternalDiscount
          } else {
            // No Discount specified
            return 0.00
          }
        }
      } else {
        // Discounts aren't allowed, or there are none applicable so return 0
        return 0.00
      }
    },
    getDetailEntryCost (entry) {
      if (entry.recurringCost) {
        return entry.unitARInternalCost + entry.unitARExternalCost
      } else {
        return entry.unitOOInternalCost + entry.unitOOExternalCost
      }
    },
    getDetailEntryPrice (entry) {
      if (entry.recurringCost) {
        return entry.unitARInternalPrice + entry.unitARExternalPrice
      } else {
        return entry.unitOOInternalPrice + entry.unitOOExternalPrice
      }
    },
    getDetailEntryRRP (entry) {
      if (entry.recurringCost) {
        return entry.unitARExternalRRP
      } else {
        return entry.unitOOExternalRRP
      }
    }
  }
}
</script>
<style scoped>

#DetailedBreakdownSummaryTable {
  margin-top: 15px;
  width: 1536px;
}

#DetailedBreakdownSummaryTable td, #DetailedSFBreakdownSummaryTable td, #DetailedContractSummaryTable td  {
  text-align: right;
}

#DetailedSFBreakdownSummaryTable {
  margin-top: 15px;
  width: 1536px;
  text-align: right;
}

#DetailedContractSummaryTable {
  margin-top: 15px;
  width: 930px;
  table-layout: fixed;
  text-align: right;
}

#DetailedContractSummaryTable td {
  word-wrap: break-word;
}

.highlightLeftBorder {
  border-left: 3px solid white
}

.highlightLeftBorderInner {
  border-left: 3px solid silver
}

.floatingBox{
    width: 600px;
    display: inline-block;
    vertical-align:  top;
  }

tr.disabledEntry td{
  text-decoration:line-through;
  color: gray;
  background-color: silver;
}

.disableEntryButton {
  background-color: white;
  color: red;
  font-weight: bold;
}

.marginError {
    color: #ed2e11;
  font-weight: bold;
}

</style>
