<template>
  <div
    :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader'}"
    class="shContainer">
    <div
      :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader', shEnableClick:enableClick}"
      class="shHeader"
      @click="$emit('click')">
      <div
        :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader'}"
        class="shTitleImage">
        <img
          :src="iconFileName"
          :id="`${id}_image`"
          :alt="title"
          :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader'}"
          srcset=""
          class="shIcon"
        >
      </div>
      <div
        :class="{shSubHeader : type==='subHeader', shMiniHeader : type==='miniHeader'}"
        class="shTitle">
        <h2 v-if="type!=='subHeader' && type!=='miniHeader'">{{ title }}</h2>
        <h3 v-else-if="type==='subHeader'">{{ title }}</h3>
        <h4 v-else-if="type==='miniHeader'">{{ title }}</h4>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    iconFile: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    enableClick: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconFileName () {
      return require(`../../assets/icons/${this.iconFile}.png`)
    }
  },
  mounted () {
  },
  methods: {
    clicked () {
      this.$emit('click')
    }
  }
}
</script>
