<template>
  <div
    :class="{highlightRed: site.status==='Red', highlightAmber: site.status ==='Amber', highlightGreen: site.status==='Green'}"
    class="ptrElementContainer"
  >
    <div class="ptrElementSiteRow">
      <div
        :id="`idptrElementSite_${site.id}`"
        class="ptrElement ptrElementSite"
      >
        {{ site.accountName }}
      </div>
      <div
        v-if="!!site.siteRatingsExist"
        :id="`idptrElementSiteStatus_${site.id}`"
        :class="{ptrSelected:itemSelected===`SR${site.id}`, ptrFocused:focusedItem===`SR${site.id}`}"
        class="ptrElement ptrElementStatus"
        @click="raiseEvent('site-rating-click', site.id)"
      >
        <button
          :class="{ptrClickableAmber : site.status ==='Amber', ptrClickableRed : site.status==='Red'}"
          class="ptrClickable"
          @focus="raiseEvent('raise-focus',`SR${site.id}`)"
        >
          <h3>{{ site.status | capitalize }}</h3>
        </button>
      </div>
      <div
        v-else
        :id="`idptrElementSiteTickets_${site.id}`"
        class="ptrElement ptrElementStatus"
      >
        <span>{{ site.status | capitalize }}</span>
      </div>
      <div
        v-if="site.tickets>0"
        :id="`idptrElementSiteTickets_${site.id}`"
        :class="{ptrSelected:itemSelected===`ST${site.id}`, ptrFocused:focusedItem===`ST${site.id}`}"
        class="ptrElement ptrElementTickets"
        @click="raiseEvent('site-tickets-click', site.id)"
      >
        <button
          :class="{ptrClickableAmber : site.ticketHighlight === 'Amber', ptrClickableRed : site.ticketHighlight === 'Red'}"
          class="ptrClickable"
          @focus="raiseEvent('raise-focus',`ST${site.id}`)"
        >
          <h3>{{ site.tickets }}</h3>
        </button>
      </div>
      <div
        v-else
        :id="`idptrElementSiteTickets_${site.id}`"
        class="ptrElement ptrElementTickets"
      >
        <span>0</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    site: {
      type: String,
      default: ''
    },
    itemSelected: {
      type: String,
      default: ''
    },
    focusedItem: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      setHighlightItem: ''
    }
  },
  methods: {
    raiseEvent (event, id) {
      this.$emit(event, id)
    }
  }
}
</script>

<style>
  .ptrElementSiteRow {
    display:flex;
    flex-direction: row;
    font: 500 15px Arial;
    padding: 1px 6px;
  }
  .ptrElementCircuitRow {
    display:flex;
    flex-direction: row;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 6px;
  }
  .ptrElement {
    width:150px;
    padding: 1px;
    text-align:center;
    font-size: 12pt;
  }
  .ptrElementSite {
    width:calc(100% - 150px);
    font-size: 14pt;
    text-align: left;
  }
  .ptrElementCircuit {
    width:auto;
    margin-left:20px;
    width:calc(100% - 170px);
    text-align: left;
  }
  .ptrElementContainer {
    border:2px white solid;
    color:rgba(0,0,0,0.9);
  }
  .ptrElementContainer {
    border:1px rgba(0, 126, 51, 1) solid;
     /*  background-color: rgba(0, 126, 51, 0.5); */
    background-color:#20bf6bd9;
  }
  .ptrElementContainer.highlightAmber {
     /* border:2px rgba(255, 136, 0, 1) solid;
     background-color: rgba(255, 136, 0, 0.5); */
    background-color:rgba(253, 150, 68,0.8);
  }
  .ptrElementContainer.highlightRed {
    /* border:2px rgba(204, 0, 0, 1) solid;
    background-color: rgba(204, 0, 0, 0.5); */
    background-color:rgba(255, 52, 52, 0.8);
  }
  .ptrElementCircuitRow {
    border-top:1px rgba(0,0,0,0.2) solid;
  }
  .ptrElementCircuitRow:nth-child(2n+1) {
    background-color: rgba(255,255,255,0.3)
  }
  .ptrElementCircuitRow:nth-child(2n) {
    background-color: rgba(0,0,0,0.2)
  }
  .ptrElement {
    background-color: rgba(0, 126, 51, 0);
    color:white;
  }
  .ptrFocused {
    background-color: rgba(255, 255, 255, 0.5);
    border:white dashed 1px;
  }
  .ptrSelected {
    /* background-color:yellow; */
    text-decoration: underline;
    color:black;
    font-weight: 600;
    transform: scaleY(1.1) translateX(0);
    cursor: default;
  }
  .ptrClickable {
    background-color:rgba(0, 128, 0);
    color:black;
    opacity:1;
    border:1px black solid;
    padding:0 5px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.6);
  }
  .ptrClickableAmber {
    background-color: rgba(255, 136, 0, 1);
  }
  .ptrClickableRed {
    background-color: rgba(204, 0, 0, 1);
  }
  .ptrClickable:hover {
    box-shadow: 1px 2px 2px rgba(0,0,0,0.6);
  }
  @media only screen and (max-width: 1200px) {
    .ptrElement {
      font-size: 12pt;
    }
  }
  @media only screen and (max-width: 900px) {
    .ptrElement {
      font-size: 10pt;
    }
  }
</style>
