// This module is used for state mamangement for Storm related variables
const state = {
  stormCompany: {},
  stormUser: {},
  stormUsers: [],
  stormSecurityGroups: [],
  stormDashboards: [],
  stormWidgets: {},
  stormSubscriptionTiers: [],
  stormDisplayTypes: [],
  stormUserGroups: [],
  userToken: '',
  adminToken: '',
  stormSSOSecurityGroup: 'Web Interface User',
  stormStandardUserGroup: 'Maintel User Group',
  stormInternalUserGroup: 'Maintel Internal Group',
  stormStandardSecurityGroup: 'API Maintel',
  stormAdminSecurityGroup: 'Icon Admin',
  stormUserSecurityGroup: 'Icon User',
  stormDefaultUser: 'itsupport@maintel.co.uk'
}
const mutations = {
  SET_STORM_COMPANY (state, stormCompany) {
    state.stormCompany = stormCompany
  },
  SET_STORM_USER (state, stormUser) {
    state.stormUser = stormUser
  },
  SET_STORM_USERS (state, stormUsers) {
    state.stormUsers = stormUsers
  },
  SET_STORM_SECURITY_GROUPS (state, stormSecurityGroups) {
    state.stormSecurityGroups = stormSecurityGroups
  },
  SET_STORM_USER_TOKEN (state, userToken) {
    state.userToken = userToken
  },
  SET_STORM_ADMIN_TOKEN (state, adminToken) {
    state.adminToken = adminToken
  },
  SET_STORM_DASHBOARDS (state, stormDashboards) {
    state.stormDashboards = stormDashboards
  },
  SET_STORM_WIDGETS (state, stormWidgets) {
    state.stormWidgets = stormWidgets
  },
  SET_STORM_SUBSCRIPTION_TIERS (state, stormSubscriptionTiers) {
    state.stormSubscriptionTiers = stormSubscriptionTiers
  },
  SET_STORM_DISPLAY_TYPES (state, stormDisplayTypes) {
    state.stormDisplayTypes = stormDisplayTypes
  },
  SET_STORM_USER_GROUPS (state, stormUserGroups) {
    state.stormUserGroups = stormUserGroups
  }
}
const actions = {
  setStormCompany: ({ commit }, stormCompany) => {
    commit('SET_STORM_COMPANY', stormCompany)
  },
  setStormUser: ({ commit }, stormUser) => {
    commit('SET_STORM_USER', stormUser)
  },
  setStormUsers: ({ commit }, stormUsers) => {
    commit('SET_STORM_USERS', stormUsers)
  },
  setStormSecurityGroups: ({ commit }, stormSecurityGroups) => {
    commit('SET_STORM_SECURITY_GROUPS', stormSecurityGroups)
  },
  setStormUserToken: ({ commit }, userToken) => {
    commit('SET_STORM_USER_TOKEN', userToken)
  },
  setStormAdminToken: ({ commit }, adminToken) => {
    commit('SET_STORM_ADMIN_TOKEN', adminToken)
  },
  setStormDashboards: ({ commit }, stormDashboards) => {
    commit('SET_STORM_DASHBOARDS', stormDashboards)
  },
  setStormWidgets: ({ commit }, stormWidgets) => {
    commit('SET_STORM_WIDGETS', stormWidgets)
  },
  setStormSubscriptionTiers: ({ commit }, stormSubscriptionTiers) => {
    commit('SET_STORM_SUBSCRIPTION_TIERS', stormSubscriptionTiers)
  },
  setStormDisplayTypes: ({ commit }, stormDisplayTypes) => {
    commit('SET_STORM_DISPLAY_TYPES', stormDisplayTypes)
  },
  setStormUserGroups: ({ commit }, stormUserGroups) => {
    commit('SET_STORM_USER_GROUPS', stormUserGroups)
  },
  clearStormStore: ({ commit }) => {
    commit('SET_STORM_COMPANY', {})
    commit('SET_STORM_USER', {})
    commit('SET_STORM_USERS', [])
    commit('SET_STORM_SECURITY_GROUPS', [])
    commit('SET_STORM_USER_TOKEN', '')
    commit('SET_STORM_ADMIN_TOKEN', '')
    commit('SET_STORM_DASHBOARDS', [])
    commit('SET_STORM_WIDGETS', {})
    commit('SET_STORM_SUBSCRIPTION_TIERS', [])
    commit('SET_STORM_DISPLAY_TYPES', [])
    commit('SET_STORM_USER_GROUPS', [])
  }
}
const getters = {
  stormCompany: state => {
    return state.stormCompany
  },
  stormUser: state => {
    return state.stormUser
  },
  stormUsers: state => {
    return state.stormUsers
  },
  stormSecurityGroups: state => {
    return state.stormSecurityGroups
  },
  stormUserToken: state => {
    return state.userToken
  },
  stormAdminToken: state => {
    return state.adminToken
  },
  stormDashboards: state => {
    return state.stormDashboards
  },
  stormWidgets: state => {
    return state.stormWidgets
  },
  stormSubscriptionTiers: state => {
    return state.stormSubscriptionTiers
  },
  stormDisplayTypes: state => {
    return state.stormDisplayTypes
  },
  stormUserGroups: state => {
    return state.stormUserGroups
  },
  stormSSOSecurityGroup: state => {
    return state.stormSSOSecurityGroup
  },
  stormStandardUserGroup: state => {
    return state.stormStandardUserGroup
  },
  stormInternalUserGroup: state => {
    return state.stormInternalUserGroup
  },
  stormStandardSecurityGroup: state => {
    return state.stormStandardSecurityGroup
  },
  stormAdminSecurityGroup: state => {
    return state.stormAdminSecurityGroup
  },
  stormUserSecurityGroup: state => {
    return state.stormUserSecurityGroup
  },
  stormDefaultUser: state => {
    return state.stormDefaultUser
  }
}
export default {
  state,
  mutations,
  actions,
  getters
}
