<template>
  <div
    :id="id"
    class="stormHolder"
  >
    <Spinner v-if="loading" />
    <div
      v-else
    >
      <div class="controlContainer">
        <label
          for="txtUpdateTraverseContainerId"
          class="stormDetailLabel"
        >Base URL:</label>
        <input
          v-if="editCompanyPermission"
          id="txtUpdateBaseUrl"
          :class="{ errorHighlight: BaseUrlError }"
          v-model="company.baseUrl"
          class="stormDetailInput"
          type="text"
          @blur="checkForm"
        >
        <label
          for="txtUpdateTraverseContainerId"
          class="stormDetailLabel"
        >API Code:</label>
        <input
          v-if="editCompanyPermission"
          id="txtUpdateAPICode"
          :class="{ errorHighlight: ApiCodeError }"
          v-model="company.apiCode"
          class="stormDetailInput"
          type="text"
          @blur="checkForm"
        >
        <label
          v-if="!editCompanyPermission"
          id="lblUpdateBaseUrl"
          class="stormDetailData"
        >{{ company.baseUrl }}</label>
        <label
          for="txtUpdateTraverseContainerId"
          class="stormDetailLabel"
        >Subscripion Tier:</label>
        <select
          ref="subscriptionTierDropDown"
          class="subscriptionTierDropDown"
          @change="setSubscriptionTier()"
        >
          <option
            v-for="s in subscriptionTiers"
            :key="s.id"
            :value="s.id"
            :selected="company.subscriptionTierId===s.id"
          >{{ s.name }}
          </option>
        </select>
        <label
          v-if="!editCompanyPermission"
          id="lblUpdateSubscripionTier"
          class="stormDetailData"
        >{{ company.subscriptionTierName }}</label>
      </div>
      <Spinner v-if="isSaving" />
      <div
        v-if="editCompanyPermission && !isSaving"
        class="updateButtonCompany"
      >
        <input
          id="btnUpdateCompany"
          :disabled="!formOK"
          type="button"
          class="stormUpdateCompanyButton"
          value="Update Company"
          title="Updates the company UC Analytics parameters"
          @click="updateCompany()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { stormMixin } from '../../../mixins/stormMixin'
import Spinner from '../../common/spinner'
export default {
  name: 'StormManagement',
  components: {
    Spinner
  },
  mixins: [
    stormMixin
  ],
  props: {
    id: {
      type: String,
      default: ''
    },
    company: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      formOK: false,
      BaseUrlError: false,
      ApiCodeError: false,
      isSaving: false,
      stormAdmin: false
    }
  },
  computed: {
    editCompanyPermission () {
      return this.$store.getters.editCompanyPermission
    },
    companies () {
      return this.$store.getters.companies
    },
    subscriptionTiers () {
      return this.$store.getters.stormSubscriptionTiers
    }
  },
  watch: {
    company () {
      this.setComponent()
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    setComponent: async function () {
      this.loading = true
      if (this.company.companyId > -1) {
        await this.getStormCompanies()
        this.checkForm()
      }
      this.loading = false
    },
    updateCompany: async function () {
      if (!this.isSaving) {
        this.isSaving = true
        if (this.formOK) {
          var payload = { companyId: this.company.companyId, baseUrl: this.company.baseUrl, apiCode: this.company.apiCode, subscriptionTierId: this.company.subscriptionTierId }
          var rtn = await this.updateStormCompany(payload)
          if (rtn) {
            await this.setupStormCompany(false)
            this.$emit('reload-companies', 0)
            this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
          } else {
            this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
          }
        }
        this.isSaving = false
      }
    },
    isNumeric (str) {
      if (typeof str !== 'string') return false
      return !isNaN(str) && !isNaN(parseFloat(str))
    },
    checkForm: function () {
      this.BaseUrlError = false
      this.ApiCodeError = false
      if (this.company.baseUrl.length === 0 || this.company.apiCode.length === 0) {
        this.BaseUrlError = this.company.baseUrl.length === 0
        this.ApiCodeError = this.company.apiCode.length === 0
        var errorMessage = 'Please fix the issues with the following field: ' +
        (this.company.baseUrl.length === 0 ? 'base URL' : '') +
        (this.company.apiCode.length === 0 ? 'API Code' : '')
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      } else if (this.company.apiCode.length > 36) {
        this.ApiCodeError = true
        var apiCodeMessage = 'The API Code cannot be more than 36 characters long.'
        this.$store.commit('showMessage', { content: apiCodeMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !this.BaseUrlError && !this.ApiCodeError
    },
    setSubscriptionTier () {
      this.company.subscriptionTierId = Number(this.$refs.subscriptionTierDropDown.selectedOptions[0].value)
    }
  }
}
</script>
<style scoped>
  .stormHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }
  .stormDetailData {
    width: 250px;
    display: inline-block;
  }
  .controlContainer {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 10px 0;
  }
  .stormDetailLabel {
    width: 130px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .stormDetailInput {
    width: 344px;
    display: inline-block;
    padding: 0 0 0 5px;
    margin: 0 0 16px 0;
    vertical-align: bottom;
  }
  .subscriptionTierDropDown {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 0 0 0 5px;
    width: 344px;
    -moz-appearance: auto;
    -webkit-appearance: auto;
    appearance: auto;
  }
  .stormUpdateCompanyButton {
    float:right;
    cursor: pointer;
  }
  .stormUpdateCompanyButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
</style>
