import webAPI from '../communications/interceptors/apiInterceptor'
export const adminMixin = {
  data () {
    return {
    }
  },
  methods: {
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
    },
    onboardCustomer: async function (requestObject) {
      console.log(JSON.stringify(requestObject))
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await webAPI.post('/admin/provisioning/onboardcustomer', requestObject, axiosConfig).then((response) => {
          rtn = response.data
          if (rtn.length > 0) {
            console.log('Error onboarding customer: ' + rtn)
          }
        })
      } catch (e) {
        console.log('Error calling onboardCustomer ' + e)
        rtn = 'Error calling onboardCustomer ' + e
      }
      return rtn
    },
    resendemail: async function (userName, emailAddress) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        var data = {
          UserName: userName,
          EmailAddress: emailAddress
        }
        await webAPI.post('/admin/provisioning/resendemail', data, axiosConfig).then((response) => {
          rtn = response.data
          if (rtn.length > 0) {
            console.log('Error resending welcome email: ' + rtn)
          }
        })
      } catch (_) {
        console.log('Error calling resendemail')
      }
      return rtn
    },
    createGlobalUser: async function (user) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await webAPI.post(`/admin/user`, user, axiosConfig).then((response) => {
          rtn = 'OK'
        })
      } catch (_) {
        console.log('Error calling createGlobalUser')
      }
      return rtn
    },
    removeUser: async function (userId) {
      var rtn = ''
      var axiosConfig = this.getHeaders()
      try {
        await webAPI.delete(`/admin/user/${userId}`, axiosConfig).then((response) => {
          rtn = 'OK'
        })
      } catch (_) {
        console.log('Error calling removeUser')
      }
      return rtn
    },
    approveAccessRequest: async function (payload) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var rtn = ''
      try {
        await webAPI.post('admin/user/approverequest', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    sendApproveRequestEmail: async function (payload) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
      var rtn = ''
      try {
        await webAPI.post('admin/user/requestaccess', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    sendApproveAccessRequest: async function (payload) {
      console.log(JSON.stringify(payload))
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
      var rtn = ''
      try {
        await webAPI.post('admin/user/approverequest', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    sendUserUpdatedEmail: async function (payload) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var rtn = ''
      try {
        await webAPI.post('admin/user/details/updated', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    // This call has been replaced by a call directly to the MaintelPermissions database which is in the permissions mixin
    mergeCompany_Old: async function (payload) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var rtn = ''
      try {
        await webAPI.post('admin/provisioning/mergecompany', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
        rtn = error
      }
      return rtn
    },
    mergeCustomer_NotUsed: async function (payload) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var rtn = ''
      try {
        await webAPI.post('admin/provisioning/mergecustomer', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
        rtn = error
      }
      return rtn
    },
    assignAllCompaniesToUser: async function (roleType, user) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      // The admin controller is expect a RequestAccess object, so we pass that payload.
      var payload = '{"CompanyId":' + user.id + ', "CompanyName":"' + roleType + '", "FirstName":"' + user.firstName + '", "LastName":"' + user.lastName + '", "EmailAddress":"' + user.emailAddress + '", "Approved":false, "Url":""}'
      var rtn = ''
      try {
        await webAPI.post('admin/user/assignallcompanies', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    changeUserEmail: async function (user) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      // The admin controller is expect a RequestAccess object, so we pass that payload.
      var payload = '{"CompanyId":' + user.id + ', "CompanyName":"", "FirstName":"", "LastName":"", "EmailAddress":"' + user.emailAddress + '", "Approved":false, "Url":""}'
      var rtn = 'failed'
      try {
        await webAPI.post('admin/user/changeemail', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getDatabaseVersion: async function (dbName) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      // The admin controller is expect a RequestAccess object, so we pass that payload.
      var rtn = ''
      try {
        await webAPI.get('admin/database/' + dbName, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    checkSystemHealth: async function (baseURL) {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      // Pass the base URL for the service being checked in the POST data to avoid issues with the / character.
      var payload = '{"BaseURL":"' + baseURL + '"}'
      var rtn = 'failed'
      try {
        await webAPI.post('admin/systemstatus/systemhealth', payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        console.log(error)
      }
      return rtn
    }
  }
}
