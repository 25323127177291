//Generic template for a portal button

<template>
  <div class="mtbContainer">
    <button
      :id="buttonid"
      class="mtbActionButton"
      @click="itemClicked()" >
      {{ buttontext }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    buttontext: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: ''
    },
    buttonid: {
      type: String,
      default: ''
    }
  },
  methods: {
    itemClicked () {
      this.$emit('Click')
    }
  }
}
</script>

<style scoped>
  .mtbContainer {
    width: 100%;
    text-align: right;
  }
  .mtbActionButton{
    height:26px;
    border-radius: 5px;
    border:2px solid transparent;
    z-index:50;
    cursor: pointer;
  }
  .mtbActionButton:hover{
    font-weight: 600;
    border: 2px rgba(255,2552,255,0.5) solid;
  }
  .mtbActionButton:focus{
    font-weight: 600;
    border: 2px rgba(255,2552,255,0.5) dashed;
    text-decoration: underline;
  }
</style>
