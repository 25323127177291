<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-ucanalytics"
          primary-title
        >
          UC Analytics
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details the Portal's UC Analytics integration."
              />
              <HelpSection
                instruction="1) Navigate to the UC Analytics page via the left-hand navigation menu (if your company is not set up as a UC Analytics company, the option will not be present)."
                image-source="uc_analytics_1.png"
              />
              <HelpSection
                instruction="2) From this screen, if your user is set up with UC Analytics permissions you can view information as well as download it in SVG, PNG or CSV formats."
                indentation="0"
              />
              <HelpSection
                instruction="a. Furthermore, if your company's subscription tier permits it, you can click the “Launch UC Analytics Portal” button to open a link to the UC Analytics page."
                indentation="1"
              />
              <HelpSection
                instruction="Each chart component contains controls at the top right. These are used to change the chart view and are described below:"
                image-source="uc_analytics_1a.png"
                identation="0"
              />
              <HelpSection
                instruction="1. Pressing the plus button zooms into the chart."
                indentation="1"
              />
              <HelpSection
                instruction="2. Pressing the minus button zooms out of the chart."
                indentation="1"
              />
              <HelpSection
                instruction="3. When an area is selected on the chart, pressing the magnifying icon zooms into that section."
                indentation="1"
              />
              <HelpSection
                instruction="4. Pressing the hand icon allows the chart to be panned."
                indentation="1"
              />
              <HelpSection
                instruction="5. Pressing the home icon resets the chart to the default settings."
                indentation="1"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpUCAnalytics',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    h3 {
      padding: 0 1rem;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
