import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const iQuoteCalculationsPhoneLinePlusMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
    }
  },
  computed: {
    /*
     * BUSINESS VALUES
     */
    calcPhonelinePlus_numPhonesRequired () {
      return this.iQuoteStore_GetPhoneLinePlus.ataUnitsNumRequired +
             this.iQuoteStore_GetPhoneLinePlus.plantronicsBlackwire5220USBNumRequired +
             this.iQuoteStore_GetPhoneLinePlus.polyVoyager5200BluetoothHeadsetNumRequired +
             this.iQuoteStore_GetPhoneLinePlus.sennheiserSC165USBNumRequired +
             this.iQuoteStore_GetPhoneLinePlus.polycomVVX150NumRequired +
             this.iQuoteStore_GetPhoneLinePlus.polycomVVX250NumRequired +
             this.iQuoteStore_GetPhoneLinePlus.polycomVVX450NumRequired
    },
    calcPhonelinePlus_UpfrontCharges () {
      var total = 0

      if (this.iQuoteStore_GetPhoneLinePlus.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.sip) {
            total += unitCost.price * unitCost.quantity
          }
        }
      })
      return total
    }
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcPhonelinePlus_calculate: function () {
      if (this.iQuoteStore_GetPhoneLinePlus.id === undefined) {
        return
      }

      if (this.calcPhonelinePlus_numPhonesRequired >= 201) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_DELIVERY_BAND8, 1)
      } else if (this.calcPhonelinePlus_numPhonesRequired >= 151) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_DELIVERY_BAND7, 1)
      } else if (this.calcPhonelinePlus_numPhonesRequired >= 101) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_DELIVERY_BAND6, 1)
      } else if (this.calcPhonelinePlus_numPhonesRequired >= 80) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_DELIVERY_BAND5, 1)
      } else if (this.calcPhonelinePlus_numPhonesRequired >= 51) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_DELIVERY_BAND4, 1)
      } else if (this.calcPhonelinePlus_numPhonesRequired >= 31) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_DELIVERY_BAND3, 1)
      } else if (this.calcPhonelinePlus_numPhonesRequired >= 11) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_DELIVERY_BAND2, 1)
      } else if (this.calcPhonelinePlus_numPhonesRequired >= 1) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_DELIVERY_BAND1, 1)
      }

      //      this.iQuoteStore_GetPhoneLinePlus.numberPortingRequired
      //      this.iQuoteStore_GetPhoneLinePlus.outOfHoursPortingTypeId

      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_CISCO_ATA, this.iQuoteStore_GetPhoneLinePlus.ataUnitsNumRequired)
      // this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_CISCO_PA100, this.iQuoteStore_GetPhoneLinePlus.ataUnitsNumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_PLANTRONICS_BLACKWIRE, this.iQuoteStore_GetPhoneLinePlus.plantronicsBlackwire5220USBNumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_POLYVOYAGER_5200, this.iQuoteStore_GetPhoneLinePlus.polyVoyager5200BluetoothHeadsetNumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_SENNHEISER_SC165, this.iQuoteStore_GetPhoneLinePlus.sennheiserSC165USBNumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_POLYCOM_VVX150, this.iQuoteStore_GetPhoneLinePlus.polycomVVX150NumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_POLYCOM_VVX250, this.iQuoteStore_GetPhoneLinePlus.polycomVVX250NumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_POLYCOM_VVX450, this.iQuoteStore_GetPhoneLinePlus.polycomVVX450NumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_POLYCOM_POWER, this.iQuoteStore_GetPhoneLinePlus.polycomVVXx50PowerSupplyNumRequired)

      // Yealink DECTS
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_YEALINK_DECT_REPEATER, this.iQuoteStore_GetPhoneLinePlus.dectRepeaterNumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_YEALINK_DECT_W52H, this.iQuoteStore_GetPhoneLinePlus.dectYealinkW52HNumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_YEALINK_DECT_W52P, this.iQuoteStore_GetPhoneLinePlus.dectYealinkW52PNumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_YEALINK_DECT_W73H, this.iQuoteStore_GetPhoneLinePlus.dectYealinkW73HNumRequired)
      this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_YEALINK_DECT_W73P, this.iQuoteStore_GetPhoneLinePlus.dectYealinkW73PNumRequired)

      var quote = this.$store.getters.quote

      if (quote.termLengthMonths >= 36) {
        if (this.iQuoteStore_GetPhoneLinePlus.discount) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.PHONEPLUS_3YEAR_DISCOUNT, this.iQuoteStore_GetPhoneLinePlus.gammaPhoneLineNumRequired)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.PHONEPLUS_3YEAR, this.iQuoteStore_GetPhoneLinePlus.gammaPhoneLineNumRequired)
        }
      } else {
        if (this.iQuoteStore_GetPhoneLinePlus.discount) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.PHONEPLUS_1YEAR_DISCOUNT, this.iQuoteStore_GetPhoneLinePlus.gammaPhoneLineNumRequired)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.PHONEPLUS_1YEAR, this.iQuoteStore_GetPhoneLinePlus.gammaPhoneLineNumRequired)
        }
      }

      if (this.iQuoteStore_GetPhoneLinePlus.numberPortingRequired) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_NUMBER_PORTING, this.iQuoteStore_GetPhoneLinePlus.gammaPhoneLineNumRequired)

        if (this.iQuoteStore_GetPhoneLinePlus.outOfHoursPortingTypeId === this.iQuoteConstants.outOfHoursPorting.WEEKDAY_EVENINGS) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_PORTING_WEEKDAY_EVE, 1)
        } else if (this.iQuoteStore_GetPhoneLinePlus.outOfHoursPortingTypeId === this.iQuoteConstants.outOfHoursPorting.SATURDAYS) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_PORTING_SATURDAY, 1)
        }
      }

      if (this.iQuoteStore_GetPhoneLinePlus.handsetInstallationRequired) {
        var numPSDays = this.iQuoteStore_GetPhoneLinePlus.numSites * 0.5
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.PHONEPLUS_PS_IOH_01, numPSDays)
      }
    }
  }
}
