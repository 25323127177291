import portalAPIGateway from '../communications/interceptors/apiInterceptor'
export const UploadsAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    UploadsAPIMixin_getAxiosConfigJSON () {
      var userid = this.$store.getters.userId
      return {
        timeout: 120000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    UploadsAPIMixin_getAxiosConfigBinary () {
      var userid = this.$store.getters.userId
      return {
        responseType: 'arraybuffer',
        dataType: 'binary',
        timeout: 120000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Accept': 'application/octet-stream',
          'LoggedInAs': userid
        }
      }
    },
    UploadsAPIMixin_GetFileUploadCatalogEntry: async function (folderName, fileUploadCatalogId) {
      var data = {}
      var axiosConfig = this.UploadsAPIMixin_getAxiosConfigJSON()
      try {
        await portalAPIGateway.get('portalUploads/' + folderName + '/GetFileUploadCatalogEntry/' + fileUploadCatalogId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('UploadsAPIMixin_GetFileUploadCatalogEntry returned error: ' + error)
      }
      return data
    },
    UploadsAPIMixin_GetFileDetailsByVersionId: async function (folderName, fileUploadCatalogId) {
      var data = {}
      var axiosConfig = this.UploadsAPIMixin_getAxiosConfigJSON()
      try {
        await portalAPIGateway.get('portalUploads/' + folderName + '/GetFileUploadVersion/' + fileUploadCatalogId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('UploadsAPIMixin_GetFileDetailsByVersionId returned error: ' + error)
      }
      return data
    },
    UploadsAPIMixin_DeleteFile: async function (folderName, fileUploadCatalogId) {
      var data = {}
      var axiosConfig = this.UploadsAPIMixin_getAxiosConfigJSON()
      try {
        await portalAPIGateway.delete('portalUploads/' + folderName + '/DeleteFile/' + fileUploadCatalogId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('UploadsAPIMixin_DeleteFile returned error: ' + error)
      }
      return data
    },
    UploadsAPIMixin_DeleteFileVersion: async function (folderName, fileVersionId) {
      var data = {}
      var axiosConfig = this.UploadsAPIMixin_getAxiosConfigJSON()
      try {
        await portalAPIGateway.delete('portalUploads/' + folderName + '/DeleteFileVersion/' + fileVersionId, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('UploadsAPIMixin_DeleteFileVersion returned error: ' + error)
      }
      return data
    },
    UploadsAPIMixin_DownloadFile: async function (folderName, id) {
      var data = {}
      var axiosConfig = this.UploadsAPIMixin_getAxiosConfigBinary()
      try {
        await portalAPIGateway.get('portalUploads/' + folderName + '/DownloadFile/' + id, axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('UploadsAPIMixin_DownloadFileByVersionId returned error: ' + error)
      }
      return data
    }
  }
}
