// This module is used for state mamangement for Jem related variables
const state = {
  o2AccountNumbers: []
}
const mutations = {
  SET_O2_ACCOUNT_NUMBERS (state, o2AccountNumbers) {
    state.o2AccountNumbers = o2AccountNumbers
  }
}
const actions = {
  seto2AccountNumbers: ({ commit }, o2AccountNumbers) => {
    commit('SET_O2_ACCOUNT_NUMBERS', o2AccountNumbers)
  }
}
const getters = {
  o2AccountNumbers: state => {
    return state.o2AccountNumbers
  }
}
export default {
  state,
  mutations,
  actions,
  getters
}
