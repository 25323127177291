<template>
  <div class="formFieldContainer">
    <label
      :for="id"
      class="createTicketLabel"
    >{{ `${label}: ` }}</label>
    <select
      :id="id"
      v-model="value"
      :class="{ errorHighlight: error }"
      class="createTicketTextbox"
      @change="()=>this.$emit('change')"
    >
      <option
        v-for="option in options"
        :key="option.name"
        :value="option.value"
        :title="option.name"
      >{{ option.name }}</option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'FormSelect',
  props: {
    id: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    classname: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
  .formFieldContainer {
    padding-top: 5px;
  }
  .createTicketLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .createTicketTextbox {
    width: 68%;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }
  .errorHighlight {
    border: 1px solid red!important;
  }
  select {
    -webkit-appearance: menulist;
    border: solid 1px #9aa8b1;
    height: 26px;
  }
</style>
