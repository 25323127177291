<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <h2>Callmedia CX Now DDI Detail</h2>
      #<label for="txtiQuoteReference">iQuote</label>
      <input
        id="txtiQuoteReference"
        :value="cxNowDdiDetailComponent.quote.id"
        type="text"
        readonly="true"
      ><br>
      <table id="cxNowDDIDetailSummary">
        <tr>
          <td width="240px">
            <h1 class="TableHeader">Customer</h1>
          </td>
        </tr>
        <tr>
          <td id="quoteSummaryTableColumn1">
            <label for="txtAccount">Account</label>
          </td>
          <td>
            <input
              id="txtAccount"
              :value="cxNowDdiDetailComponent.quote.salesforceAccountName"
              readonly="true"
              type="text"
            >
          </td>
        </tr>
        <tr>
          <td>
            <label for="txtAddress">Customer Site Address</label>
          </td>
          <td>
            <textarea
              id="txtAddress"
              v-model="cxNowDdiDetailComponent.opportunity.address"
              class="addressTextbox"
              rows="3"
              cols="50"
              readonly="true"
            />
          </td>
        </tr>
        <tr>
          <td>
            <label for="txtEmergencyAddress">Address for emergency services (if different to client address)</label>
          </td>
          <td>
            <textarea
              id="txtEmergencyAddress"
              v-model="cxNowDdiDetailComponent.opportunity.emergencyAddress"
              rows="3"
              cols="50"
              readonly="true"
              type="text"
            />
          </td>
        </tr>
        <tr>
          <td>
            <label for="txtAddress">Opportunity Name</label>
          </td>
          <td>
            <input
              id="txtOpportunityName"
              :value="cxNowDdiDetailComponent.quote.salesforceOpportunityName"
              readonly="true"
              type="text"
            >
          </td>
        </tr>
        <tr>
          <td>
            <label for="txtFirstName">Contact Name</label>
          </td>
          <td>
            <input
              id="txtFirstName"
              :value="cxNowDdiDetailComponent.quote.salesforceContactFirstName"
              type="text"
              readonly="true"
            >
            <input
              id="txtLastName"
              :value="cxNowDdiDetailComponent.quote.salesforceContactLastName"
              readonly="true"
              type="text"
            >
          </td>
        </tr>
        <tr>
          <td>
            <h1 class="TableHeader">Order</h1>
          </td>
        </tr>
        <tr>
          <td>
            <label for="txtProductOrdered">Product Ordered</label>
          </td>
          <td>
            <input
              id="txtProductOrdered"
              value="Callmedia CX Now"
              type="text"
              readonly="true"
            >
          </td>
        </tr>
        <tr>
          <td>
            <label for="dteDateOrderClosed">Date Order Closed</label>
          </td>
          <td>
            <PortalDateTime
              id="dteDateOrderClosed"
              v-model="cxNowDdiDetailComponent.cxNowDDIDetail.dateOrderClosed"
              @blur="cxNowDdiDetailModified()"
            />
          </td>
        </tr>
        <tr>
          <td>
            <label for="dteReadyForServiceTargetDate">Ready For Service Target Date</label>
          </td>
          <td>
            <PortalDateTime
              id="dteReadyForServiceTargetDate"
              v-model="cxNowDdiDetailComponent.cxNowDDIDetail.readyForServiceTargetDate"
              @blur="cxNowDdiDetailModified()"
            />
          </td>
        </tr>
        <tr>
          <td>
            <h2 class="TableHeader">Services Ordered</h2>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>
            <table>
              <tr>
                <th>Bundle</th>
                <th>Agents</th>
              </tr>
              <tr
                v-if="callmediaCXNowCalcs_usersToLicense === 0">
                <td>CX Now Start Bundle</td>
                <td>{{ callmediaCXNowCalcs_usersToLicense }}</td>
              </tr>
              <tr
                v-if="callmediaCXNowCalcs_usersToLicense > 0">
                <td>CX Now Digital Bundle</td>
                <td>{{ callmediaCXNowCalcs_usersToLicense }}</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <h2 class="TableHeader">Personalised Services Quote</h2>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>
            <table
              id="personalisedServicesTable">
              <tr v-if="cxNowDdiDetailComponent.cxNowDDIDetail.personalisedServices.length > 0">
                <th>Role</th>
                <th>Days</th>
                <th>Schedule</th>
                <th>Scope</th>
              </tr>
              <tr
                v-for="ps in cxNowDdiDetailComponent.cxNowDDIDetail.personalisedServices"
                :key="ps.id">
                <td>
                  <input
                    :id="'psRole' + ps.id"
                    :class="{invalidInput: !isValidRole(ps.role)}"
                    v-model="ps.role"
                    type="text"
                    @blur="cxNowDdiDetailModified()"
                  >
                </td>
                <td>
                  <input
                    :id="'psRoleDays' + ps.id"
                    :class="{invalidInput: !isValidDays(ps.days)}"
                    v-model.number="ps.days"
                    type="number"
                    @blur="cxNowDdiDetailModified()"
                  >
                </td>
                <td>
                  <input
                    :id="'psRoleSchedule' + ps.id"
                    :class="{invalidInput: !isValidSchedule(ps.schedule)}"
                    v-model="ps.schedule"
                    type="text"
                    @blur="cxNowDdiDetailModified()"
                  >
                </td>
                <td>
                  <input
                    :id="'psRoleScope' + ps.id"
                    :class="{invalidInput: !isValidScope(ps.scope)}"
                    v-model="ps.scope"
                    type="text"
                    @blur="cxNowDdiDetailModified()"
                  >
                </td>
                <td>
                  <div
                    :id="'deleteRoleButton-' + ps.id"
                    class="shDeleteButton"
                    title="Delete this item"
                    tabindex="49"
                    @click="deletePsRole(ps.id)"
                  >
                    <input
                      type="button"
                      class="deleteButton"
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <input
                  :id="'psRoleAdd'"
                  value="Add"
                  type="button"
                  @click="addPsRole()"
                >
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <h2 class="TableHeader">Application Store</h2>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>
            Integration Connector(s)
          </td>
          <td>
            {{ cxNowConnectorsList }}
          </td>
        </tr>
        <tr>
          <td>
            Application(s)
          </td>
          <td>
            {{ cxNowApplicationsList }}
          </td>
        </tr>
        <tr>
          <td width="300px">
            <h1 class="TableHeader">Telephony</h1>
          </td>
        </tr>
        <tr>
          <td id="quoteSummaryTableColumn1">
            <label for="txtAccount">DDI Start Range</label>
          </td>
          <td>
            <input
              id="txtDdiStartRange"
              :class="{invalidInput: !isValidDdiStartRange}"
              v-model="cxNowDdiDetailComponent.cxNowDDIDetail.ddiStartRange"
              type="text"
              class="quantityColumn"
              @blur="cxNowDdiDetailModified()"
            >
          </td>
        </tr>
        <tr>
          <td id="quoteSummaryTableColumn1">
            <label for="txtAccount">DDI End Range</label>
          </td>
          <td>
            <input
              id="txtDdiEndRange"
              :class="{invalidInput: !isValidDdiEndRange}"
              v-model="cxNowDdiDetailComponent.cxNowDDIDetail.ddiEndRange"
              type="text"
              @blur="cxNowDdiDetailModified()"
            >
          </td>
        </tr>
        <tr>
          <td id="quoteSummaryTableColumn1">
            <label for="txtAccount">Total Numbers</label>
          </td>
          <td>
            <input
              id="txtTotalNumbers"
              :class="{invalidInput: !isValidTotalNumbers}"
              v-model.number="cxNowDdiDetailComponent.cxNowDDIDetail.totalNumbers"
              class="quantityColumn"
              type="number"
              @blur="cxNowDdiDetailModified()"
            >
          </td>
        </tr>
        <tr>
          <td id="quoteSummaryTableColumn1">
            <label for="txtnumberOfChannels">Number of channels and split if applicable</label>
          </td>
          <td>
            <input
              id="txtnumberOfChannels"
              :class="{invalidInput: !isValidnumberOfChannels}"
              v-model.number="cxNowDdiDetailComponent.cxNowDDIDetail.numberOfChannels"
              class="quantityColumn"
              type="number"
              @blur="cxNowDdiDetailModified()"
            >
          </td>
        </tr>
        <tr>
          <td id="quoteSummaryTableColumn1">
            <label for="txtAccount">Any Call Barring required</label>
          </td>
          <td>
            <TriStateToggleSwitch
              ref="sql-toggle"
              :enabled="true"
              :current-state="cxNowDdiDetailComponent.cxNowDDIDetail.callBarringRequired ? 1 : 2"
              :tri-state="false"
              class="toggleSwitch"
              tabindex="1"
              @statechanged="cxNowDdiDetailComponent.cxNowDDIDetail.callBarringRequired = !cxNowDdiDetailComponent.cxNowDDIDetail.callBarringRequired"
            />
          </td>
        </tr>
        <tr>
          <td id="quoteSummaryTableColumn1">
            <label for="txtAccount">CLI Flexibility (can be turned on/off quickly)</label>
          </td>
          <td>
            <TriStateToggleSwitch
              ref="sql-toggle"
              :enabled="true"
              :current-state="cxNowDdiDetailComponent.cxNowDDIDetail.cliFlexibility ? 1 : 2"
              :tri-state="false"
              class="toggleSwitch"
              tabindex="1"
              @statechanged="cxNowDdiDetailComponent.cxNowDDIDetail.cliFlexibility = !cxNowDdiDetailComponent.cxNowDDIDetail.cliFlexibility"
            />
          </td>
        </tr>
        <tr>
          <td id="quoteSummaryTableColumn1">
            <label for="txtAccount">Spend Cap &amp; Fraud Settings</label>
          </td>
          <td>
            <table>
              <tr>
                <th>No of SIP Channels</th>
                <th>Default Daily Spend</th>
                <th>Default Weekly Spend</th>
                <th>Default Warning Alert %</th>
              </tr>
              <tbody
                v-for="capAndFraud in cxNowCapAndFraudList"
                :key="capAndFraud.id">
                <tr v-if="capAndFraud.id === cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsId">
                  <td>{{ capAndFraud.numSipChannels }}</td>
                  <td>£{{ capAndFraud.defaultDailySpend }}</td>
                  <td>£{{ capAndFraud.defaultWeeklySpend }}</td>
                  <td>{{ capAndFraud.defaultWarningAlert }}%</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <div class="quotePanel">
        <hr>
        <div class="buttons">
          <div class="leftButtons">
            <input
              id="btnBack"
              value="< Back"
              type="button"
              @click="setCurrentQuoteState('Review')"
            >
          </div>
          <div class="rightButtons" />
          <div class="middleButtons">
            <input
              id="downloadQuote"
              class="downloadQuoteButton"
              value="Download"
              type="button"
              @click="downloadDdiDetail"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { iQuoteQuoteCallmediaCXNowDdiDetailsAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteCallmediaCXNowDdiDetailsAPIMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteCalculationsCallmediaCXNowMixin } from '../../../mixins/iQuote/iQuoteCalculationsCallmediaCXNowMixin'
import { iQuoteQuotePersonalisedServicesAPIMixin } from '../../../mixins/iQuote/iQuoteQuotePersonalisedServicesAPIMixin'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { iQuoteSalesforceAPIMixin } from '../../../mixins/iQuote/iQuoteSalesforceAPIMixin'
import TooltipIcon from '../../common/tooltipIcon'
import PortalDateTime from '../../common/portalDateTime'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'

export default {
  name: 'CXNowDDIDetail',
  components: {
    TooltipIcon,
    PortalDateTime,
    TriStateToggleSwitch
  },
  mixins: [
    iQuoteCalculationsCommonMixin,
    iQuoteQuoteCallmediaCXNowDdiDetailsAPIMixin,
    iQuoteExportsAPIMixin,
    iQuoteSalesforceAPIMixin,
    iQuoteFunctions,
    iQuoteStoreMixin,
    iQuoteCalculationsCallmediaCXNowMixin,
    iQuoteQuotePersonalisedServicesAPIMixin],
  data () {
    return {
      cxNowDdiDetailComponent: {
        quote: {
          chargeUpfront: true
        },
        opportunity: {
          approved: ''
        },
        quoteTechnologyCallmediaCXNow: {
          userProfiles: [],
          adjuncts: {}
        },
        cxNowDDIDetail: {
          personalisedServices: [
          ],
          dateOrderClosed: new Date(),
          readyForServiceTargetDate: new Date(),
          ddiStartRange: '',
          ddiEndRange: '',
          totalNumbers: 0,
          numberOfChannels: 0,
          callBarringRequired: false,
          cliFlexibility: false,
          spendCapAndFraudSettingsId: 1
        }
      },
      cxNowCapAndFraudList: [
        {
          id: 1,
          numSipChannels: 'up to 5',
          defaultDailySpend: 100,
          defaultWeeklySpend: 1000,
          defaultWarningAlert: 85
        },
        {
          id: 2,
          numSipChannels: '5 to 20',
          defaultDailySpend: 200,
          defaultWeeklySpend: 1000,
          defaultWarningAlert: 85
        },
        {
          id: 3,
          numSipChannels: '21 to 100',
          defaultDailySpend: 250,
          defaultWeeklySpend: 1500,
          defaultWarningAlert: 85
        },
        {
          id: 4,
          numSipChannels: '101 to 200',
          defaultDailySpend: 325,
          defaultWeeklySpend: 1500,
          defaultWarningAlert: 85
        },
        {
          id: 5,
          numSipChannels: '201 to 800',
          defaultDailySpend: 350,
          defaultWeeklySpend: 1750,
          defaultWarningAlert: 85
        },
        {
          id: 6,
          numSipChannels: '> 800',
          defaultDailySpend: 400,
          defaultWeeklySpend: 2000,
          defaultWarningAlert: 85
        }
      ]
    }
  },
  computed: {
    refresh () {
      return require(`../../../assets/icons/refresh.png`)
    },
    approvalStatus () {
      if (this.cxNowDdiDetailComponent.opportunity.approvalStatus === undefined) {
        return 'Failed to retrieve status'
      } else if (this.cxNowDdiDetailComponent.opportunity.approvalStatus === null || this.cxNowDdiDetailComponent.opportunity.approvalStatus === '') {
        return 'Not Approved'
      } else {
        return this.cxNowDdiDetailComponent.opportunity.approvalStatus
      }
    },
    isValidDdiStartRange () {
      var value = this.cxNowDdiDetailComponent.cxNowDDIDetail.ddiStartRange
      return (value.length > 0 && value.length <= 50)
    },
    isValidDdiEndRange () {
      var value = this.cxNowDdiDetailComponent.cxNowDDIDetail.ddiEndRange
      return (value.length > 0 && value.length <= 50)
    },
    isValidTotalNumbers () {
      var value = this.cxNowDdiDetailComponent.cxNowDDIDetail.totalNumbers
      return (value > 0 && value < 10000)
    },
    isValidnumberOfChannels () {
      var value = this.cxNowDdiDetailComponent.cxNowDDIDetail.numberOfChannels
      return (value > 0 && value < 10000)
    },
    inputsAreValid () {
      var foundInvalid = false

      if (!this.isValidnumberOfChannels || !this.isValidTotalNumbers || !this.isValidDdiEndRange || !this.isValidDdiStartRange) {
        foundInvalid = true
      }

      var arr = this.cxNowDdiDetailComponent.cxNowDDIDetail.personalisedServices

      for (var i = 0; i < arr.length; i++) {
        if (!this.isValidRole(arr[i].role) || !this.isValidDays(arr[i].days) || !this.isValidSchedule(arr[i].schedule) || !this.isValidScope(arr[i].scope)) {
          foundInvalid = true
        }
      }

      return !foundInvalid
    },
    downloadDesignPackImageUrl () {
      return require(`../../../assets/icons/downloadDesignPack.png`)
    },
    cxNowConnectorsList () {
      var list = ''

      this.quoteTechnologyCallmediaCXNowStore.connectors.forEach(connector => {
        if (connector.selected) {
          if (list !== '') {
            list += ', '
          }

          list += connector.description
        }
      })

      if (list === '') {
        list = 'None'
      }

      return list
    }
  },
  async mounted () {
    this.cxNowDdiDetailComponent.quote = this.$store.getters.quote
    this.cxNowDdiDetailComponent.quoteTechnologyCallmediaCXNow = this.$store.getters.quoteTechnologyCallmediaCXNow

    if (this.cxNowDdiDetailComponent.cxNowDDIDetail.id === undefined) {
      var cxNowDDIDetailFromDB = await this.getQuoteCallmediaCXNowDdiDetailsFromQuoteId(this.cxNowDdiDetailComponent.quote.id)

      if (cxNowDDIDetailFromDB.id !== undefined) {
        cxNowDDIDetailFromDB.dateOrderClosed = new Date(cxNowDDIDetailFromDB.dateOrderClosed)
        cxNowDDIDetailFromDB.readyForServiceTargetDate = new Date(cxNowDDIDetailFromDB.readyForServiceTargetDate)
        cxNowDDIDetailFromDB.personalisedServices = []
        this.cxNowDdiDetailComponent.cxNowDDIDetail = cxNowDDIDetailFromDB
      } else {
        this.cxNowDdiDetailComponent.cxNowDDIDetail = {
          personalisedServices: [],
          dateOrderClosed: new Date(),
          readyForServiceTargetDate: new Date(),
          ddiStartRange: '',
          ddiEndRange: '',
          totalNumbers: 0,
          numberOfChannels: 0,
          callBarringRequired: false,
          cliFlexibility: false,
          spendCapAndFraudSettingsId: 1
        }
      }
    }

    this.cxNowDdiDetailComponent.cxNowDDIDetail.personalisedServices = await this.getQuotePersonalisedServicesFromQuoteId(this.cxNowDdiDetailComponent.quote.id)
    var numSipChannels = Math.ceil(this.callmediaCXNowCalcs_usersToLicense * 1.2) + this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow.options.pstnConnectionsRequired

    if (numSipChannels <= 5) {
      this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsId = 1
    } else if (numSipChannels <= 20) {
      this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsId = 2
    } else if (numSipChannels <= 100) {
      this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsId = 3
    } else if (numSipChannels <= 200) {
      this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsId = 4
    } else if (numSipChannels <= 800) {
      this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsId = 5
    } else {
      this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsId = 6
    }

    for (let i = 0; i < this.cxNowCapAndFraudList.length; i++) {
      var item = this.cxNowCapAndFraudList[i]
      if (item.id === this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsId) {
        this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsNumSipChannels = item.numSipChannels
        this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsDefaultDailySpend = item.defaultDailySpend
        this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsDefaultWeeklySpend = item.defaultWeeklySpend
        this.cxNowDdiDetailComponent.cxNowDDIDetail.spendCapAndFraudSettingsDefaultWarningAlert = item.defaultWarningAlert
      }
    }

    this.getSalesforceOpportunity()
    this.setCallmediaCXNowDdiDetails(this.cxNowDdiDetailComponent.cxNowDDIDetail)
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setCallmediaCXNowDdiDetails'
    ]),
    viewBillOfMaterials () {
      this.setCurrentQuoteState('BillOfMaterials')
    },
    viewCxNowDdiDetail () {
      this.setCurrentQuoteState('CxNowDdiDetail')
    },
    quoteModified () {
    },
    isValidRole (value) {
      return (value.length > 0 && value.length <= 250)
    },
    isValidSchedule (value) {
      return (value.length > 0 && value.length <= 250)
    },
    isValidScope (value) {
      return (value.length > 0 && value.length <= 250)
    },
    isValidDays (value) {
      return (value > 0 && value <= 999)
    },
    downloadDdiDetail: async function () {
      if (!this.inputsAreValid) {
        this.iQuoteFunctions_ShowError('Please correct highlighted incorrect inputs on the page before downloading')
      } else {
        this.setCallmediaCXNowDdiDetails(this.cxNowDdiDetailComponent.cxNowDDIDetail)
        await this.saveQuotePersonalisedServicesToDB()
        await this.saveQuoteCallmediaCXNowDdiDetailsToDB()

        var data = await this.iQuoteExportsAPIMixin_getQuoteCxNowDdiDetailDocument(this.cxNowDdiDetailComponent.quote.id)
        var a = document.createElement('a')
        a.style = 'display: none'
        a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-word' }))
        a.download = 'quote_ddiDetail_' + this.cxNowDdiDetailComponent.quote.id + '.docx'
        document.body.appendChild(a)
        a.click()
      }
    },
    getSalesforceOpportunity: async function () {
      this.cxNowDdiDetailComponent.opportunity = {}
      this.cxNowDdiDetailComponent.opportunity.approvalStatus = 'Updating...'
      var SalesforceOpportunity = await this.iQuoteSalesforceAPIMixin_getSalesforceOpportunityData(this.cxNowDdiDetailComponent.quote.salesforceQuoteNumber)
      if (SalesforceOpportunity !== null && SalesforceOpportunity !== '') {
        this.cxNowDdiDetailComponent.opportunity = SalesforceOpportunity
      } else {
        this.cxNowDdiDetailComponent.opportunity = {}
      }
    },
    getTempPsId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1
      var arr = this.cxNowDdiDetailComponent.cxNowDDIDetail.personalisedServices

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    addPsRole: function () {
      var newRole = {
        id: this.getTempPsId(),
        role: '',
        days: 0,
        schedule: '',
        scope: ''
      }
      this.cxNowDdiDetailComponent.cxNowDDIDetail.personalisedServices.push(newRole)
    },
    deletePsRole: function (id) {
      if (window.confirm('Remove this role?')) {
        var delItem = this.iQuoteFunctions_GetArrayItemById(this.cxNowDdiDetailComponent.cxNowDDIDetail.personalisedServices, id)
        this.iQuoteFunctions_ShowMessage('Role "' + delItem.role + '" Removed')
        this.iQuoteFunctions_ArrayRemoveById(this.cxNowDdiDetailComponent.cxNowDDIDetail.personalisedServices, id)
        this.setCallmediaCXNowDdiDetails(this.cxNowDdiDetailComponent.cxNowDDIDetail)
        this.saveQuotePersonalisedServicesToDB()
      }
    },
    cxNowDdiDetailModified: function () {
      this.setCallmediaCXNowDdiDetails(this.cxNowDdiDetailComponent.cxNowDDIDetail)

      if (this.inputsAreValid) {
        this.saveQuotePersonalisedServicesToDB()
        this.saveQuoteCallmediaCXNowDdiDetailsToDB()
      }
    }
  }
}
</script>
<style scoped>
#cxNowDDIDetailSummary table tr:nth-child(1) {
  width: 100px;
}

  #cxNowDDIDetailSummary table tr th {
    vertical-align: top;
  }

  .downloadDesignPackButton {cursor:pointer;}

  .quotePanel {
    width: 950px;
    text-align: left;
  }

  .quotePanel label {
    width: 190px;
    display: inline-block;
    font-size: 0.9em;
  }
  .quotePanel input {margin:3px;}
  .quotePanel input[type=text]{
    width: 140px;
    display: inline-block;
    /*border: solid 1px #000;*/
  }
  .quotePanel input[type=button]{
    margin: 5px;
    border: solid 1px #000;
    cursor: pointer;
  }
  .quotePanel input[type=number]{
    width: 40px;
    /*border: solid 1px #000;*/
  }
  .quotePanel .longLabel {
    width: 350px;
  }
  .quotePanel .chargeLabel {
    width: 330px;
  }

  .quotePanel .middleButtons {
    width: 450px;
  }

  table {
    width: auto;
  }

  table td {
    padding: 2px 6px;
  }

  table th {
    padding: 2px 6px;
  }

  h2 {
    margin-top: 0px;
  }

  input[type="checkbox"][readonly] {
  pointer-events: none;
  }
  #personalisedServicesTable {
    display: inline-block;
  }

  #personalisedServicesTable tr td input[type="text"] {
    width: 120px;
    margin-left: 0px;
  }

  #quoteSummaryTable label{
    font-weight: bold;
  }

  #quoteSummaryTableColumn2 {
    padding-left:40px;
  }

  #quoteSummaryTableColumn1 label {
    width:170px;
  }

  #quoteSummaryTableColumn2 label {
    width:125px;
  }

  .serviceTitleColumn {
    width: 350px;
  }

  .priceColumn {
    width: 120px;
  }

  .quantityColumn {
    width: 120px;
  }

  #txtiQuoteReference {width: 240px;}
  #txtAccount  {width: 240px;}
  #txtFirstName {width: 115px;}
  #txtLastName {width: 115px;}
  #txtQuoteDescription {width: 240px;}

  h1.TableHeader {
    margin-top: 50px;
    font-size: 150%;
  }

  h2.TableHeader {
    margin-top: 50px;
    font-size: 120%;
  }

</style>
