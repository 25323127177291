// This module is used for state mamangement for iQuote related variables
const state = {
  userId: 1
}

const mutations = {
  SET_USERID (state, userId) {
    state.userId = userId
  }
}

const actions = {
  setUserId: ({ commit }, userId) => {
    commit('SET_USERID', userId)
  }
}

const getters = {
  userId: state => {
    return state.userId
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
