<template>
  <div class="widget-wrapper">
    <apexchart
      :options="getChartOptions(widget)"
      :series="getChartSeries(widget)"
      width="100%"
    />
    <div class="widget-footer">
      <div class="widget-footer-timestamp">
        <span
          :title="'Data last updated at ' + timestamp()"
          class="timestamp"
        >{{ timestamp() }}</span>
      </div>
    </div>
    <TimerProgressComponent
      id="controlRefreshBar"
      :polling-interval="pollingIntervalInSeconds"
      :widget-id="widget.id"
      @reload-widget="setWidgetData"
    />
  </div>
</template>
<script>
import TimerProgressComponent from '../UCAnalytics/timerProgressComponent'
import { reportsMixin } from '../../mixins/reportsMixin'
export default {
  name: 'ChartSLAGauge',
  components: {
    TimerProgressComponent
  },
  mixins: [
    reportsMixin
  ],
  props: {
    widget: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      timerObj: {},
      widgetData: {},
      tickCount: 1,
      barProgress: 0
    }
  },
  computed: {
    chartLabels () {
      var rtn = ['% IN sla']
      return rtn
    },
    pollingIntervalInSeconds () {
      return this.widget.pollingInterval
    },
    progressTitle () {
      if ((this.pollingIntervalInSeconds - Math.round(this.tickCount)) > 60) {
        return Math.round((this.pollingIntervalInSeconds - Math.round(this.tickCount)) / 60) + ' minutes until next data refresh'
      } else {
        return (this.pollingIntervalInSeconds - Math.round(this.tickCount)) + ' seconds until next data refresh'
      }
    }
  },
  mounted () {
    this.widgetData = this.widget
  },
  methods: {
    getChartOptions (widget) {
      var green = '#00E396'
      var amber = '#FEB019'
      var red = '#ff0000'

      var greenZone = 90
      var amberZone = 81
      var gaugeColour = red
      var percentageInSla = this.getInSlaPercentage(widget)

      if (percentageInSla >= greenZone) {
        gaugeColour = green
      } else {
        if (percentageInSla >= amberZone) {
          gaugeColour = amber
        }
      }
      var fillColours = []
      var colours = []
      colours.push(gaugeColour)
      fillColours.push(gaugeColour)
      fillColours.push(gaugeColour)

      var showGrid = (widget.data)
      var obj = {}
      obj = {
        series: [],
        labels: this.chartLabels,
        noData: {
          text: 'No tickets found'
        },
        chart: {
          title: widget.title,
          id: widget.id,
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true
          },
          toolbar: {
            show: showGrid,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            }
          }
        },
        colors: colours,
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#e7e7e7',
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '22px',
                formatter: function (val) {
                  if (gaugeColour === red && val === 100) {
                    val = 0
                  }
                  return val + '%'
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: showGrid
          }
        },
        title: {
          text: widget.title,
          align: 'middle'
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            gradientToColors: fillColours,
            stops: [0, 100]
          }
        }
      }
      return obj
    },
    getChartSeries (widget) {
      var dataSeries = []
      var percentageInSla = this.getInSlaPercentage(widget)
      if (percentageInSla >= 0) {
        dataSeries.push(percentageInSla)
      }
      return dataSeries
    },
    getInSlaPercentage: function (widget) {
      var noDataReturned = '-1'
      if (widget.data) {
        if (widget.data) {
          var ticketsInsideSla = widget.data[0].noInsideSLA
          var totalTickets = widget.data[0].total
          if (totalTickets > 0) {
            var onePercent = totalTickets / 100
            var percentageInsideSla = ticketsInsideSla / onePercent
            percentageInsideSla = percentageInsideSla.toFixed(0)
            return percentageInsideSla
          } else {
            return noDataReturned
          }
        } else {
          return noDataReturned
        }
      }
    },
    // Reload the widget data on reaching the polling interval
    setWidgetData: async function (widgetId) {
      if (widgetId === this.widget.id) {
        // var rtn = await this.getWidgetById(this.id)
        // if (rtn.chartWidgets) {
        //   this.widgetData = rtn.chartWidgets[0]
        // }
      }
    },
    timestamp (utc) {
      var localDate = new Date(Date.now()).toLocaleString()
      if (utc) {
        localDate = new Date(utc).toLocaleString()
      }
      return localDate
    }
  }
}
</script>
<style scoped>

.widget-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #000000;
  border: #dddddd 1px solid;
  padding: 10px 0 0 0;
}
.widget-header-title {
  cursor: default;
  padding: 0.4em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-content-wrapper {
  overflow: visible;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
}
.widget-content {
  height: 100%;
  font-size: 2em;
}
.widget-footer {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  flex-shrink: 0;
}
.widget-footer-timestamp {
  font-size: 0.8em;
  color: #aaa;
  text-align: right;
  margin-left: auto;
}
.timestamp {
  transition: all 0.1s ease;
  color: #aaa;
  font-size: 0.8em;
  text-align: right;
  background-color: white;
  cursor: pointer;
}
.apexcharts-title-text {
  margin-bottom: 10px;
}
#controlRefreshBar {
  display: inline-block;
  width: 100%;
  border-radius: 0;
  height: 4px;
}
#controlRefreshBar[value]::-webkit-progress-bar {
  background-color: #fff;
}
#controlRefreshBar[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, rgb(218, 240, 255), rgb(218, 240, 255));
  border-radius: 0;
  background-size: 100%;
}
</style>
