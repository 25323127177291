<template>
  <div
    :id="customerid"
    class="adcHolder"
  >
    <p> Please select a user from the list below to send a welcome email which allows the password to be reset.</p>
    <label
      for="txtTargetUsername"
      class="customerOnboardingDetailLabel"
    >
      Username:
    </label>
    <PortalDropdown
      id="txtTargetUsername"
      :options="users"
      :disabled="false"
      :max-item="999999"
      :attach-blur-event="false"
      class="customerOnboardingDetailInput"
      style="border:1px solid #e7ecf5!important"
      parent-class="adcHolder"
      placeholder="Please select a user to send the email to"
      @selected="({ id }) => { selectedUser = users.find(user => user.id === id) }"
    />
    <input
      :disabled="!selectedUser"
      value="Send"
      type="button"
      class="adcButton customerUpdateButton"
      @click="sendOnboardingEmail()"
    >
  </div>
</template>
<script>

import { usersMixin } from '../../../mixins/users'
import { adminMixin } from '../../../mixins/adminMixin'
import PortalDropdown from '../../common/portalDropdown'

export default {
  components: {
    PortalDropdown
  },
  mixins: [
    usersMixin,
    adminMixin
  ],
  props: {
    customerid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      selectedUser: {},
      users: []
    }
  },
  computed: {
    customer () {
      return this.$store.getters.customers.find(o => o.id === this.customerid)
    }
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    getUsers: function () {
      var storeUsers = this.$store.getters.users
      var userStringList = ''
      if (storeUsers.length > 0) {
        storeUsers.forEach(user => { userStringList += '|' + user.id })
        userStringList = userStringList.substring(1)
        this.getUserList(userStringList).then((results) => {
          this.users = results.map(({ id, firstName, lastName, userName, emailAddress }) => ({
            id,
            name: firstName + ' ' + lastName,
            userName,
            emailAddress
          }))
        })
      }
    },
    sendOnboardingEmail: async function () {
      // console.log(this.selectedUser)
      this.$store.commit('showMessage', {
        content: `About to send welcome email to '${this.selectedUser.userName}'`,
        color: 'green',
        timeout: 3000
      })
      var rtn = await this.resendemail(this.selectedUser.userName, this.selectedUser.emailAddress)
      if (rtn.length === 0) {
        this.$store.commit('showMessage', {
          content: `The welcome email was sent to '${this.selectedUser.userName}' successfully!`,
          color: 'blue',
          timeout: 3000
        })
      } else {
        this.$store.commit('showMessage', {
          content: `The welcome email sent to '${this.selectedUser.userName}' failed with '${rtn}'`,
          color: 'red',
          timeout: 3000
        })
      }
    }
  }
}
</script>
<style scoped>
  .adcButton{
    width:140px;
    border: solid 1px grey;
    color: grey;
  }
  .adcSubHeader{
    padding-left:10px;
    display: inline-block;
    width: 220px;
  }
  .adcHolder {
    background-color: rgba(255,255,255,0.7);
    margin-bottom: 10px;
    padding: 10px 0;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    padding: 10px 5px;
    height: 150px;
    display: none;
  }
  .customerOnboardingDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .customerOnboardingDetailInput {
    width: 254px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .customerUpdateButton {
    float:right;
    cursor: pointer;
  }
</style>
