<template>
  <div
    class="adcHolder"
  >
    <p class="adcText"> To add the user, <b>{{ user.firstName }} {{ user.lastName }}</b>, to all companies in the Portal database, please select the role to assign them to, and then press the button below.</p>
    <label
      for="roleTypeList"
      class="roleTypeListText">Please select to role to add for the user:</label>
    <select
      id="roleTypeList"
      v-model="roleType"
    >
      <option
        v-if="roleType.length === 0"
        key="0"
        value=""
        title="Please select a role to associate with the companies"
      >Please Select
      </option>
      <option
        key="1"
        value="Admin">Admin</option>
      <option
        key="2"
        value="SDM">SDM</option>
    </select>
    <input
      :disabled="!selectedUser"
      value="Add All Companies"
      type="button"
      title="Add All Companies to the selected user"
      class="adcButton assignUserButton"
      @click="addCompaniesToUser()"
    >
  </div>
</template>
<script>

import { usersMixin } from '../../../mixins/users'
import { adminMixin } from '../../../mixins/adminMixin'

export default {
  mixins: [
    usersMixin,
    adminMixin
  ],
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedUser: {},
      roleType: ''
    }
  },
  watch: {
    user () {
      this.roleType = ''
    }
  },
  methods: {
    addCompaniesToUser: async function () {
      if (this.roleType === '') {
        this.$store.commit('showMessage', { content: 'Please select a role to assign the user, ' + this.user.emailAddress + ', to the companies with.', color: 'red', timeout: 3000 })
        return
      } else {
        var rtn = await this.assignAllCompaniesToUser(this.roleType, this.user)
        if (rtn === '') {
          this.$store.commit('showMessage', { content: 'The user, ' + this.user.emailAddress + ', has been assigned to all companies.', color: 'green', timeout: 3000 })
        } else {
          this.$store.commit('showMessage', { content: 'An error occurred assigning all companies to ' + this.user.emailAddress, color: 'red', timeout: 5000 })
        }
        this.roleType = ''
      }
      this.$emit('updated')
    }
  }
}
</script>
<style scoped>
  .adcButton{
    width: 140px;
    border: solid 1px grey;
    color: grey;
    margin-top: 30px;
  }
  .adcSubHeader{
    padding-left:10px;
    display: inline-block;
    width: 220px;
  }
  .adcHolder {
    background-color: rgba(255,255,255,0.7);
    margin-bottom: 10px;
    padding: 10px 0;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    padding: 10px 5px;
    text-align: left;
    width: 100%;
  }
  .adcText {
    text-align: left;
  }
  .customerOnboardingDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .customerOnboardingDetailInput {
    width: 254px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .assignUserButton {
    padding: 5px 10px;
    cursor: pointer;
    float: right;
    width: 160px;
  }
  #roleTypeList {
    -webkit-appearance: checkbox;
    border: 1px solid #aaa;
    padding: 2px 5px;
    margin-left: 14px;
    font-size: 0.9em;
    width: 120px;
  }
  .roleTypeListText {
    font-size: 0.9em;
  }
</style>
