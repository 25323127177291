<template>
  <a
    href="https://maintel.itclientportal.com/ClientPortal/LandingPage/LandingPage.aspx"
    title="Access Autotask Client Portal"
    target="_blank"
  >
    <material-card>
      <div style="width:100%">
        <div style="width:33%;display: inline-block;">
          <img
            :src="autotaskIcon"
            class="autotaskImage"
            title="Access Autotask Client Portal"
          >
        </div>
        <div
          class="md-title"
          style="width: 65%;display: inline-block;vertical-align: top;text-align: left;margin-top: 35px;"
        >Access Autotask Client Portal
        </div>
      </div>
    </material-card>
  </a>
</template>

<script>
export default {
  name: 'AutotaskLinkTile',
  data () {
    return {
    }
  },
  computed: {
    autotaskIcon () {
      return require(`../../../assets/icons/autotask-icon.png`)
    }
  }
}
</script>
<style scoped>
.autotaskImage {
  width:92px;
  height:92px;
  background-color: rgb(221, 229, 238);
  box-shadow: 0 12px 20px -10px rgb(70 166 89 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(70 166 89 / 20%) !important;
  -moz-box-shadow: 3px 3px  rgba(0,0,0,0.1);
  -webkit-box-shadow: 3px 3px  rgba(0,0,0,0.1);
  -o-box-shadow: 3px 3px  rgba(0,0,0,0.1);
  border-radius:90px;
}
</style>
