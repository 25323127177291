import webAPI from '../communications/interceptors/apiInterceptor'
import { mapActions } from 'vuex'

export const traverseMixin = {
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'setCompanyContainers',
      'setCurrentCompanyParentContainer'
    ]),
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
    },
    returnCompanyContainer: async function (companyId) {
      var data = ''
      var axiosConfig = this.getHeaders()
      try {
        await webAPI.get(`/traverse/companycontainers/${companyId}`, axiosConfig)
          .then((response) => {
            data = response.data
          })
          .catch((rtn) => {
            console.log('Error calling returnCompanyContainer with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling returnCompanyContainer with exception:' + e)
      }
      return data
    },
    getCompanyContainer: async function (companyId) {
      if (!companyId) {
        return
      }
      var axiosConfig = this.getHeaders()
      try {
        await webAPI.get(`/traverse/companycontainers/${companyId}`, axiosConfig)
          .then((response) => {
            this.setCurrentCompanyParentContainer(response.data)
          })
          .catch((rtn) => {
            console.log('Error calling getCompanyContainer with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling getCompanyContainer with exception:' + e)
      }
    },
    getChildContainers: async function (containerIds) {
      var childContainers = []

      if (!containerIds) {
        return
      }

      var axiosConfig = this.getHeaders()
      try {
        var containerStatuses = await this.getContainerStatuses()
        await containerIds.forEach(async ({ containerId }) => {
          await webAPI.get(`/traverse/traverse/containers/${containerId}/children`, axiosConfig)
            .then((response) => {
              if (response.data) {
                if (response.data.length > 0 && response.data.indexOf('Error') !== 0) {
                  for (var containerPtr = 0; containerPtr < response.data.length; containerPtr++) {
                    if (response.data[containerPtr].containerHealthHistory) {
                      var containerHealthHistorySeverities = []
                      if (response.data[containerPtr].containerHealthHistory.severities !== null && response.data[containerPtr].containerHealthHistory.severities !== undefined &&
                         response.data[containerPtr].containerHealthHistory.severities.length > 0) {
                        for (var i = 0; i < response.data[containerPtr].containerHealthHistory.severities.length; i++) {
                          containerHealthHistorySeverities.push(response.data[containerPtr].containerHealthHistory.severities[i])
                        }
                      }

                      var containerHealthHistoryToAdd = {
                        'serialNumber': response.data[containerPtr].containerHealthHistory.serialNumber,
                        'startTime': response.data[containerPtr].containerHealthHistory.startTime,
                        'endTime': response.data[containerPtr].containerHealthHistory.endTime,
                        'interval': response.data[containerPtr].containerHealthHistory.interval,
                        'severities': containerHealthHistorySeverities
                      }
                    }

                    var statusName = 'Unknown'
                    if (containerStatuses.length > 0) {
                      for (var j = 0; j < containerStatuses.length; j++) {
                        if (response.data[containerPtr].containerSerialNumber !== null && response.data[containerPtr].containerSerialNumber !== undefined) {
                          if (containerStatuses[j].serialNumber === response.data[containerPtr].containerSerialNumber.toString()) {
                            statusName = containerStatuses[j].status
                          }
                        }
                      }
                    }

                    var containerToAdd = {
                      'departmentSerialNumber': response.data[containerPtr].departmentSerialNumber,
                      'departmentName': response.data[containerPtr].departmentName,
                      'containerSerialNumber': response.data[containerPtr].containerSerialNumber,
                      'containerName': response.data[containerPtr].containerName,
                      'containerStatus': response.data[containerPtr].containerStatus,
                      'networkStatus': response.data[containerPtr].networkStatus,
                      'applicationStatus': response.data[containerPtr].applicationStatus,
                      'systemStatus': response.data[containerPtr].systemStatus,
                      'hasChildContainers': response.data[containerPtr].hasChildContainers,
                      'hasChildDevices': response.data[containerPtr].hasChildDevices,
                      'hasChildTests': response.data[containerPtr].hasChildTests,
                      'lastStatusChangeTime': response.data[containerPtr].lastStatusChangeTime,
                      'containerType': response.data[containerPtr].containerType,
                      'containerSummaryUrl': response.data[containerPtr].containerSummaryUrl,
                      'containerManageUrl': response.data[containerPtr].containerManageUrl,
                      'comment': response.data[containerPtr].comment,
                      'containerHealthHistory': containerHealthHistoryToAdd,
                      'statusName': statusName
                    }
                    childContainers.push(containerToAdd)
                  }
                }
              }
            })
            .catch((rtn) => {
              console.log('Error calling getChildContainers with error:' + rtn)
            })
        })

        this.setCompanyContainers(
          childContainers
        )
      } catch (e) {
        console.log('Error calling getChildContainers with exception:' + e)
      }
    },
    getContainerStatuses: async function () {
      var rtn = ''

      var axiosConfig = this.getHeaders()
      try {
        await webAPI.get(`/traverse/traverse/statuses`, axiosConfig)
          .then((response) => {
            rtn = response.data
          })
          .catch((rtn) => {
            console.log('Error calling getContainerStatuses with error:' + rtn)
          })
      } catch (e) {
        console.log('Error calling getContainerStatuses with exception:' + e)
      }
      return rtn
    },
    updateCompanyContainer: async function (companyId, tpcId) {
      if (!companyId) {
        return false
      }
      var axiosConfig = this.getHeaders()
      try {
        var data = {
          CompanyId: companyId,
          ContainerId: tpcId
        }
        await webAPI.post(`/traverse/companycontainers`, data, axiosConfig)
          .then((response) => {
            return true
          })
          .catch((rtn) => {
            console.log('Error calling getCompanyContainer with error:' + rtn)
            return false
          })
      } catch (e) {
        console.log('Error calling getCompanyContainer with exception:' + e)
        return false
      }
    }
  }
}
