<template>
  <div>
    <div
      v-if="!viewCompanyPermission"
      class="noResults"
    >
      Sorry, you do not have permission to manage Context companies
    </div>
    <div
      v-else
      id="contextCompanyManagementContainer"
      class="contextCompanyManagementSectionData"
    >
      <Spinner v-if="isLoading" />
      <ContextNewSlug
        @reload-companies="setContextCompanies()"
      />
      <TextButton
        v-if="createCompanyPermission"
        button-id="btnNewContext"
        class="contextAddButton"
        title="Add a new Context slug to a company"
        text="Add slug to a company"
        @click="openNewContext"
      />
      <div
        v-show="!isLoading"
        class="mdContainer appSectionBody">
        <div
          :class="{mdHalfScreen : !!selectedCompany}"
          class="mdContextCompanyManagement"
        >
          <table id="companiesTable">
            <thead />
            <tbody>
              <tr
                v-for="c in currentFilter"
                :key="c.companyId"
                :tabindex="20"
                :class="getRowColour(c.companyId)"
                @click="setCurrentCompany(c)"
              >
                <td>{{ c.companyName }}</td>
                <td>{{ c.companySlug }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="!!selectedCompany"
          class="mdSelection"
        >
          <SectionCollapsedHeader
            v-if="editCompanyPermission"
            id="sectionRoleDetails"
            :title="contextCompanyDetailsTitle"
            type="subHeader"
            icon-file="Edit"
            controls="contextDetails"
          />
          <ContextDetails
            id="contextDetails"
            :company="selectedCompany"
            @reload-companies="setContextCompanies()"
          />
          <SectionCollapsedHeader
            v-if="deleteCompanyPermission"
            id="sectionContextDeleteSlug"
            :title="contextCompanyDeleteTitle"
            type="subHeader"
            icon-file="Cross"
            controls="contextDeleteCompany"
          />
          <ContextDeleteSlug
            id="contextDeleteCompany"
            :context-company-id="selectedCompany.companyId"
            :context-company-slug="selectedCompany.companySlug"
            @reload-companies="setContextCompanies()"
          />
        </div>
      </div>
    </div>
    <input
      id="backButton"
      type="button"
      class="backButton"
      title="Back to support page"
      @click="closeContextManagementForm()"
    >
    <br><br>
  </div>
</template>

<script>
import ContextDetails from './contextDetails'
import ContextDeleteSlug from './contextDeleteSlug'
import ContextNewSlug from './contextNewSlug'
import { contextMixin } from '../../../mixins/contextMixin'
import TextButton from '../../common/textButton'
import tableHandler from '../../../mixins/tableHandler'
import { DataEventBus } from '../../../events/dataEvents'
import { mapActions } from 'vuex'
import Spinner from '../../common/spinner'
import SectionCollapsedHeader from '../../common/sectionCollapsedHeader'

export default {
  metaInfo: {
    title: 'Support - Manage Context Company Slugs'
  },
  name: 'ContextComponent',
  components: {
    Spinner,
    SectionCollapsedHeader,
    ContextDetails,
    ContextDeleteSlug,
    ContextNewSlug,
    TextButton
  },
  mixins: [
    contextMixin,
    tableHandler
  ],
  data () {
    return {
      isLoading: false,
      dataReturned: [],
      contextCompanies: [],
      selectedCompany: null
    }
  },
  computed: {
    contextCompanyDetailsTitle () {
      return 'Edit ' + this.selectedCompany.companyName
    },
    contextCompanyDeleteTitle () {
      return 'Delete ' + this.selectedCompany.companyName
    },
    viewCompanyPermission () {
      return this.$store.getters.viewCompanyPermission
    },
    editCompanyPermission () {
      return this.$store.getters.editCompanyPermission
    },
    deleteCompanyPermission () {
      return this.$store.getters.deleteCompanyPermission
    },
    createCompanyPermission () {
      return this.$store.getters.createCompanyPermission
    },
    companies () {
      return this.$store.getters.companies
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  mounted () {
    this.setContextCompanies()
    DataEventBus.$on('refresh-data', () => {
      this.setContextCompanies()
    })
  },
  methods: {
    ...mapActions([
      'setMainForm'
    ]),
    closeContextManagementForm () {
      this.formOK = false
      this.$emit('setTileMode')
    },
    setContextCompanies: async function () {
      this.setMainForm(false)
      this.selectedCompany = null
      if (this.viewCompanyPermission) {
        this.isLoading = true
        this.contextCompanies = []
        var rtn = await this.getSlugCompanies()
        for (let i = 0; i < rtn.length; i++) {
          var company = rtn[i]
          company.companyName = this.companies.filter(c => c.id === rtn[i].companyId)[0].name
          this.contextCompanies.push(company)
        }
        this.initTable()
        this.isLoading = false
      }
    },
    initTable: function () {
      this.loading = true
      this.$nextTick(() => {
        this.initialiseTable(
          'companiesTable',
          this.contextCompanies,
          ['companyName', 'companySlug'],
          ['Company', 'Context Slug'],
          ['', ''],
          null,
          ['60%', '40%'],
          20,
          true
        )
      })
      this.loading = false
    },
    getRowColour (id) {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      if (this.selectedCompany && this.selectedCompany.companyId === id) {
        rtn = 'itemSelected'
      }
      return rtn
    },
    setCurrentCompany (cmp) {
      if (this.selectedCompany && this.selectedCompany.companyId === cmp.companyId) {
        this.selectedCompany = null
      } else {
        this.selectedCompany = cmp
      }
    },
    openNewContext () {
      this.setMainForm(true)
    }
  }
}
</script>
<style scoped>
.contextCompanyManagementSectionData {
  margin: 20px;
}
.backButton {
  margin: 20px;
}
.contextAddButton {
  cursor: pointer;
  margin: 0 0 10px calc(100% - 202px);
}
.shContainer {
  width: 360px;
}
</style>
