<template>
  <div
    v-if="$store.getters.openMainForm"
    class="contextNewBackground"
  >
    <div class="contextNewHolder">
      <div class="contextTableholder">
        <div class="contextNewTitle">Assign Company Slug</div>
        <label
          class="contextCompanyDetailLabel"
          for="cboAddCompany"
        ><span class="compulsoryField">*</span>Company:</label>
        <PortalDropdown
          id="cboAddCompany"
          v-model="newCompany.companyId"
          :options="companies"
          :disabled="false"
          :max-item="999999"
          :class="{ errorHighlight: contextCompanyError }"
          :attach-blur-event="false"
          parent-class="createCustomerRight"
          placeholder="Please select a Company to assign a slug to"
          tabindex="2"
          @selected="selectCustomerCompany"
          @blur="checkForm"
        />
        <label
          class="contextCompanyDetailLabel"
          for="txtAddCompanySlug"
        ><span class="compulsoryField">*</span>Slug:</label>
        <input
          id="txtAddCompanySlug"
          v-model="newCompany.companySlug"
          :class="{ errorHighlight: contextSlugError }"
          class="contextCompanyDetailInput"
          type="text"
          @blur="checkForm"
        >
        <p style="margin: 20px 0 10px 10px;">(<span class="compulsoryField">*</span> marks a compulsory field )</p>
        <Spinner v-if="isSaving" />
        <input
          id="contextCancelNewButton"
          type="button"
          class="contextCancelNewButton"
          value="Cancel"
          title="Cancel the new company slug operation"
          @click="closeForm()"
        >
        <input
          v-if="createCompanyPermission && !isSaving"
          id="btnAddCompany"
          :disabled="!formOK"
          value="Add Company Slug"
          type="button"
          class="contextCompanyAddButton"
          title="Adds a new Context slug to the company"
          @click="addNewCompany()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { contextMixin } from '../../../mixins/contextMixin'
import Spinner from '../../common/spinner'
import PortalDropdown from '../../common/portalDropdown'
import { mapActions } from 'vuex'
export default {
  name: 'ContextNewCompany',
  components: {
    Spinner,
    PortalDropdown
  },
  mixins: [
    contextMixin
  ],
  data () {
    return {
      isSaving: false,
      formOK: false,
      contextCompanyError: false,
      contextSlugError: false,
      newCompany: {}
    }
  },
  computed: {
    createCompanyPermission () {
      return this.$store.getters.createCompanyPermission
    },
    companies () {
      return this.$store.getters.companies
    }
  },
  methods: {
    ...mapActions([
      'setMainForm'
    ]),
    addNewCompany: async function () {
      if (this.newCompany.companyId === 0 || this.newCompany.companySlug.length === 0) {
        this.$store.commit('showMessage', { content: 'You must fill in all compulsory fields in order to add a new company slug', color: 'red', timeout: 3000 })
      } else {
        this.isSaving = true
        if (this.formOK) {
          var rtn = await this.addCompanySlug(this.newCompany.companyId, this.newCompany.companySlug)
          if (rtn) {
            this.closeForm()
            this.$emit('reload-companies', 0)
            this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
          } else {
            this.$store.commit('showMessage', { content: 'Something went wrong adding the new company slug, please try again..', color: 'red', timeout: 3000 })
          }
        } else {
          this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
        }
        this.isSaving = false
      }
    },
    closeForm () {
      this.setMainForm(false)
      this.clearErrors()
    },
    clearErrors () {
      this.contextSlugError = false
      this.contextCompanyError = false
    },
    checkForm: function () {
      this.clearErrors()
      this.contextCompanyError = this.newCompany.companyId === 0
      if (!this.contextCompanyError) {
        this.contextSlugError = this.newCompany.companySlug.length === 0
      }

      var errorMessage = ''
      if (this.contextCompanyError) {
        errorMessage = ' Please select a company to add the Context slug to.'
      }
      if (this.contextSlugError) {
        errorMessage = ' Please enter the Context slug to assign against the company.'
      }
      if (errorMessage !== '') {
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !this.contextCompanyError && !this.contextSlugError
    },
    selectCustomerCompany (option) {
      if (option) {
        this.newCompany.companyId = option.id
      }
    }
  }
}

</script>
<style scoped>
  .contextCompanyHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .contextCompanyDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
    margin: 0 0 0 8px;
  }
  .contextCompanyDetailSubscriptionTierLabel {
    width: 160px;
    display: inline-block;
    font-weight: bold;
    margin: 0 0 0 8px;
  }
  .contextCompanyDetailData {
    width: 270px;
    display: inline-block;
  }
  .contextCompanyDetailInput {
    width: 280px;
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 0 0 0 5px;
  }
  .contextCompanyAddButton {
    float:right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    margin: 10px;
  }
  .contextCancelNewButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .contextCompanyAddButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .contextNewBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .contextNewHolder {
    display: inline-block;
    background-color: rgba(240,240,240,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 220px);
    top: calc(50% - 200px);
    height: 260px;
    width: 420px;
    z-index: 999;
  }
  .contextNewTitle {
    padding:10px;
    background-color: rgba(44,62,80,0.7);
    color: #fff;
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .contextTableholder {
    overflow: auto;
    height: 330px;
    max-height: 340px;
  }
  .compulsoryField {
    color:red;
    padding:0 8px;
  }
  .subscriptionTierDropDown {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 0 0 0 5px;
    width: 214px;
    -moz-appearance: auto;
    -webkit-appearance: auto;
    appearance: auto;
  }
  #cboAddCompany {
    width:280px;
    margin: 0 0 10px 0;
  }
</style>
