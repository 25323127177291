<template>
  <div class="projectSiteSummaryContainer">
    <table
      v-show="projectData.length > 0"
      id="siteSummaryTable"
      class="dashboardTable"
    >
      <thead />
      <tbody
        v-show="projectData.length > 0"
        id="projectSiteSummaryTableBody"
      >
        <tr
          v-for="v in currentFilter"
          :key="v.id"
          :tabindex="20"
          :class="ragClass(v.ragStatus)"
          @click="drilldownProject(v)"
        >
          <td :title="v.site">{{ v.site }}</td>
          <td :title="v.name">{{ v.name }}</td>
          <td :title="formatManagerDisplayName(v.projectManagerName)">{{ formatManagerDisplayName(v.projectManagerName) }}</td>
          <td
            :title="v.startDate ? new Date(v.startDate).toDateString() : 'N/A'"
          >
            {{ v.startDate ? new Date(v.startDate).toDateString() : 'N/A' }}
          </td>
          <td :title="v.ragStatus" >{{ v.ragStatus }}</td>
        </tr>
      </tbody>
    </table>
    <p
      v-if=" projectData.length === 0"
      class="noResults"
    >
      No Project data available.
    </p>
  </div>
</template>

<script>

import { autotaskServiceMixin } from '../../mixins/autotaskServiceMixin'
import tableHandler from '../../mixins/tableHandler'
import { DataEventBus } from '../../events/dataEvents'

export default {
  name: 'ProjectSiteSummary',
  components: {
  },
  mixins: [
    autotaskServiceMixin,
    tableHandler
  ],
  props: {
    selectedSite: {
      type: Number,
      default: -1
    },
    status: {
      type: String,
      default: ''
    },
    filter: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      projectData: []
    }
  },
  computed: {
    filteredProjects () {
      var projectsPerSite = this.$store.getters.topLevelSitesSummary.filter(({ siteId }) => siteId === this.selectedSite)

      switch (this.filter.toLowerCase()) {
        case 'high':
          return projectsPerSite.filter(o => o.ragStatus.toLowerCase() === 'red')
        case 'medium':
          return projectsPerSite.filter(o => o.ragStatus.toLowerCase() === 'amber')
        case 'low':
          return projectsPerSite.filter(o => o.ragStatus.toLowerCase() === 'green')
        default:
          return projectsPerSite
      }
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    selectedSite () {
      this.displaySiteProjects()
    },
    status () {
      this.displaySiteProjects()
    },
    filter () {
      this.displaySiteProjects()
    }
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.projectData = []
      this.displaySiteProjects()
    })
  },
  created () {
  },
  methods: {
    displaySiteProjects: function () {
      var currentStatus = this.status.toLowerCase()
      var projectData = this.filteredProjects.filter(({ status }) => status === currentStatus)

      this.projectData = projectData.map(o => ({
        ...o,
        startDateSort: o.startDate ? new Date(o.startDate) : new Date()
      }))

      this.selected = -1
      this.$nextTick(() => {
        this.initialiseTable('siteSummaryTable', this.projectData,
          ['site', 'name', 'projectManagerName', 'purchaseOrderNumber', 'salesforceNumber', 'startDateSort', 'ragStatus'],
          ['Site', 'Name', 'Project Lead', 'Start Date', 'RAG Status'],
          ['', '', '', '', '', ''],
          null,
          ['20%', '20%', '20%', '20%', '20%'],
          25,
          true
        )
      })
    },
    drilldownProject: function ({ id }) {
      this.$emit('projectDrillDown', {
        project: this.projectData.find(o => o.id === id)
      })
    },
    formatManagerDisplayName: function (managerName) {
      if (managerName) {
        if ((managerName.indexOf(',')) > -1) {
          var parts = managerName.split(',')
          return `${parts[1]} ${parts[0]}`
        } else {
          return managerName
        }
      } else {
        return ''
      }
    },
    ragClass (ragStatus) {
      var rtn = ''
      if (!this.useAltColour) {
        rtn = 'Solid'
      }
      switch (ragStatus.toLowerCase()) {
        case 'red':
          rtn += 'Red'
          break
        case 'amber':
          rtn += 'Amber'
          break
        case 'green':
          rtn += 'Green'
          break
        default:
          rtn += 'Grey'
          break
      }
      return rtn
    }
  }
}
</script>

<style scoped>
  #siteSummaryTable {
    height: auto !important;
  }
  .projectSiteSummaryContainer {
    max-height: 400px;
    padding:10px;
  }

  .dashboardTable td {
    display:inline-block;
    width:19%;
  }
</style>
