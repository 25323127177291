<template>
  <div class="pmnSummaryContainer">
    <Spinner v-if="loading" />
    <material-card>
      <vc-donut
        :size="200"
        :thickness="40"
        :sections="pmnData || undefined"
        :total="pmnTotal || undefined"
        :start-angle="0"
        background="white"
        foreground="grey"
        unit="px"
        has-legend
        legend-placement="bottom"
        @section-click="sectionClick">
        <h4 class="title font-weight-light">PMN Summary</h4>
      </vc-donut>
      <template slot="actions">
        <v-icon
          class="mr-2"
          small
        >mdi-clock-outline
        </v-icon>
        <span class="caption grey--text font-weight-light">{{ updateInterval }}</span>
      </template>
    </material-card>
  </div>
</template>

<script>

import { DataEventBus } from '../../../events/dataEvents'
import { dashboardMixin } from '../../../mixins/dashboard'
import { autotaskServiceMixin } from '../../../mixins/autotaskServiceMixin'

export default {
  name: 'PMNSummary',
  mixins: [
    dashboardMixin,
    autotaskServiceMixin
  ],
  data () {
    return {
      loading: false,
      timer: 0,
      timerInterval: process.env.VUE_APP_DATA_REFRESH_TIME,
      pmnData: [],
      lastUpdatedDate: Date.now(),
      lastUpdatedText: 'Updated 0 seconds ago'
    }
  },
  computed: {
    currentTickets () {
      return this.$store.getters.pmnTickets
    },
    currentTicketUDFs () {
      return this.$store.getters.ticketUDFs
    },
    pmnTotal () {
      var rtn = 0
      if (this.currentTickets) {
        this.currentTickets.forEach((pmn) => {
          if (this.getUDFValue(pmn.id, 'Planned Maintenance Type') !== 'WSUS') {
            rtn++
          }
        })
      }
      return rtn
    },
    pmnSIs () {
      var sis = []
      this.currentTickets.forEach((pmn) => {
        if (this.getUDFValue(pmn.id, 'Planned Maintenance Type') !== 'WSUS') {
          var si = this.getUDFValue(pmn.id, 'Service Impact')
          if (!sis.includes(si)) {
            sis.push(si)
          }
        }
      })
      return sis
    },
    updateInterval () {
      return `Updates every ${(process.env.VUE_APP_DATA_REFRESH_TIME / 1000)} seconds`
    }
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.displayPMNSummary()
    })
    this.displayPMNSummary()
  },
  methods: {
    displayPMNSummary: function () {
      this.loading = true
      this.pmnData = []
      this.pmnSIs.forEach((si) => {
        this.pmnData.push({
          label: `${si} (${this.pmnCounts(si)})`,
          value: this.pmnCounts(si),
          color: this.getPMNColour(si)
        })
      })
      if (!this.pmnData.length) {
        this.pmnData.push({
          label: 'No Data Available',
          value: 0,
          color: this.getPMNColour('')
        })
      }
      this.lastUpdatedText = this.setLastUpdatedText(this.lastUpdatedDate)
      this.loading = false
    },
    pmnCounts (si) {
      var rtn = 0
      this.currentTickets.forEach((pmn) => {
        if (this.getUDFValue(pmn.id, 'Service Impact') === si &&
          this.getUDFValue(pmn.id, 'Planned Maintenance Type') !== 'WSUS') {
          rtn++
        }
      })
      return rtn
    },
    getPMNColour (si) {
      var rtn = 'Grey'
      if (si === 'Critical Impact') rtn = 'rgb(166, 106, 174)'
      if (si === 'Risk Only') rtn = 'rgb(100, 194, 201)'
      if (si === 'High Impact') rtn = 'rgb(233, 106, 102)'
      if (si === 'Medium Impact') rtn = 'rgb(255, 200, 97)'
      if (si === 'Low Impact') rtn = 'rgb(102, 187, 106)'
      return rtn
    },
    setTimer () {
      clearInterval(this.timer)
      return setInterval(async () => {
        await this.getProjectSummary(this.$store.getters.topLevelAreas)
        this.getProjectsSummaryChartData()
      }, this.timerInterval)
    },
    getUDFValue (id, key) {
      var udf = this.currentTicketUDFs.filter(u => u.id === id)
      for (let i = 0; i < udf.length; i++) {
        if (udf[i].name === key) {
          return udf[i].value
        }
      }
      return ''
    },
    sectionClick (e) {
      console.log(e.label)
      if (e.label.indexOf('Critical Impact') > -1) { this.$router.push('pmn?ticketsFilter=critical') }
      if (e.label.indexOf('Risk Only') > -1) { this.$router.push('pmn?ticketsFilter=risk') }
      if (e.label.indexOf('High Impact') > -1) { this.$router.push('pmn?ticketsFilter=high') }
      if (e.label.indexOf('Medium Impact') > -1) { this.$router.push('pmn?ticketsFilter=medium') }
      if (e.label.indexOf('Low Impact') > -1) { this.$router.push('pmn?ticketsFilter=low') }
    }
  }
}
</script>

<style scoped>
  #pmnSummaryTable {
    height: auto !important;
  }
  .pmnSummaryContainer {
    max-height: 400px;
    padding:10px;
  }

  .dashboardTable td {
    display:inline-block;
    width:19%;
  }
  .cdc-filler {
    cursor: pointer;
  }
</style>
