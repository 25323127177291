<template>
  <div class="createAdminContainer">
    <label
      :class="{InputError: adminReports.iQDPNumber === 0}"
      for="txtiQDPNumber"
    >Enter iQDP Number
    </label>
    <input
      id="txtiQDPNumber"
      ref="DPNumber"
      v-model="adminReports.iQDPNumber"
      type="text"
      @keyup="(event) => { onKeyUp(event) }"
    >
    <TextButton
      :button-tabindex="1"
      button-style="margin: 5px 0 10px 5px;"
      button-id="btnSearch"
      title="Search audits"
      text="Search"
      @click="searchDPAuditing()"
    />
    <br>
    <label
      v-if="!adminReports.entriesFound"
      class="entryNotFound"
    >No entries found</label>
    <div class="mdContainer appSectionBody">
      <div
        :class="{mdHalfScreen : false}"
        class="mdQuotes">
        <div
          :class="{hidden: adminReports.auditEntries.length === 0}">
          <table
            id="auditTable">
            <thead />
            <tbody>
              <tr
                v-for="entry in currentFilter"
                :key="entry.id"
                :tabindex="4"
              >
                <td>{{ entry.auditDateTime }}</td>
                <td>{{ entry.userName }}</td>
                <td>{{ entry.firstName }}</td>
                <td>{{ entry.lastName }}</td>
                <td>{{ entry.revisionNumber }}</td>
                <td>{{ entry.action === 'DP duplicated' ? `Duplicate from ${entry.duplicateId}` : entry.action }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextButton from '../../common/textButton.vue'
import tableHandler from '../../../mixins/tableHandler'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'

export default {
  name: 'AdminReports',
  components: {
    TextButton
  },
  mixins: [ tableHandler, iQuoteQuoteDesignPacksAPIMixin ],
  data () {
    return {
      adminReports: {
        iQDPNumber: '',
        auditEntries: [],
        entriesFound: true
      }
    }
  },
  mounted () {
    this.$refs.DPNumber.focus()
  },
  methods: {
    async searchDPAuditing () {
      await this.setTable()
    },
    async setTable () {
      if (this.adminReports.iQDPNumber.trim() === '') {
        return
      }
      this.adminReports.auditEntries = []
      const entries = await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackUserAuditsByDPNumberFromDb(this.adminReports.iQDPNumber)
      if (entries.designPackUserAudits.length > 0) {
        entries.designPackUserAudits.forEach(entry => {
          entry.auditDateTime = new Date(entry.auditDateTime).toLocaleString('en-gb')
          this.adminReports.auditEntries.push(entry)
        })
        this.adminReports.entriesFound = true
      } else {
        this.adminReports.entriesFound = false
      }

      this.initialiseTable('auditTable', this.adminReports.auditEntries,
        ['auditDateTime', 'userName', 'firstName', 'lastName', 'revisionNumber', 'action'],
        ['AuditDateTime', 'UserName', 'FirstName', 'LastName', 'RevisionNumber', 'Action'],
        null,
        '700px',
        ['70px', '70px', '70px', '70px', '70px', '70px'],
        18, // entries per page
        true,
        false,
        true) // pagination at top
    },
    onKeyUp (event) {
      if (event.keyCode === 13) {
        this.searchDPAuditing()
      }
    }
  }
}
</script>
<style scoped>
  label {
    font-size: large;
    margin-right: 10px;
  }
 .mdContainer{
    display: flex;
    flex-direction: row;
  }
  .mdQuotes{
    margin: 0;
    width:100%;
    min-height: calc(100vh - 300px);
  }
  .mdQuotes.mdHalfScreen{
    width:calc(100% - 600px);
    min-height: calc(100vh - 50px);
  }
  #auditTable {
    text-align:left;
    margin-bottom: 40px;
  }
  .hidden {
    display: none
  }

  .entryNotFound {
    font-weight: 500;
  }
</style>
