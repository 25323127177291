<template>
  <div
    :id="id"
    class="stormUserHolder"
  >
    <label
      class="stormUserDetailLabel"
      for="txtUpdateUserForename"
    ><span class="compulsoryField">*</span>Forename:</label>
    <label
      v-if="!stormAdmin"
      id="lblUpdateUserName"
      class="stormUserDetailData"
    >{{ currentUser.forename }}</label>
    <input
      v-if="stormAdmin"
      id="txtUpdateUserForename"
      v-model="currentUser.forename"
      :class="{ errorHighlight: stormForenameError }"
      class="stormUserDetailInput"
      type="text"
      @blur="checkForm"
    >
    <label
      class="stormUserDetailLabel"
      for="txtUpdateUserSurname"
    ><span class="compulsoryField">*</span>Surname:</label>
    <label
      v-if="!stormAdmin"
      id="lblUpdateUserForename"
      class="stormUserDetailData"
    >{{ currentUser.surname }}</label>
    <input
      v-if="stormAdmin"
      id="txtUpdateUserSurname"
      v-model="currentUser.surname"
      :class="{ errorHighlight: stormForenameError }"
      class="stormUserDetailInput"
      type="text"
      @blur="checkForm"
    >
    <label
      class="stormUserDetailLabel"
      for="txtUpdateUserEmail"
    ><span class="compulsoryField">*</span>Email:</label>
    <label
      v-if="!stormAdmin || stormUser.accountName === adminEmail"
      id="lblUpdateUserSurname"
      class="stormUserDetailData"
    >{{ currentUser.email }}</label>
    <input
      v-if="stormAdmin && stormUser.accountName !== adminEmail"
      id="txtUpdateUserEmail"
      v-model="currentUser.email"
      :class="{ errorHighlight: stormEmailError }"
      class="stormUserDetailInput"
      type="text"
      @blur="checkForm"
    >
    <label
      class="stormUserDetailLabel"
      for="txtUpdatePhone"
    ><span class="compulsoryField">&nbsp;</span>Phone:</label>
    <label
      v-if="!stormAdmin"
      id="lblUpdateUserPhone"
      class="stormUserDetailData"
    >{{ currentUser.phone }}</label>
    <input
      v-if="stormAdmin"
      id="txtUpdatePhone"
      v-model="currentUser.phone"
      class="stormUserDetailInput"
      type="text"
    >
    <p style="margin: 0 0 10px 10px;">(<b class="compulsoryField">*</b>marks a compulsory field)</p>
    <Spinner v-if="isSaving" />
    <input
      v-if="stormAdmin && !isSaving"
      id="btnUpdateUser"
      :disabled="!formOK"
      value="Update User"
      type="button"
      class="stormUserUpdateButton"
      title="Updates the properties of the storm user"
      @click="updateUserDetails()"
    >
  </div>
</template>
<script>
import { stormMixin } from '../../../mixins/stormMixin'
import Spinner from '../../common/spinner'
export default {
  name: 'StormDetails',
  components: {
    Spinner
  },
  mixins: [
    stormMixin
  ],
  props: {
    stormUser: {
      type: Object,
      default: function () { return {} }
    },
    stormAdmin: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isSaving: false,
      formOK: false,
      originalEmail: '',
      stormForenameError: false,
      stormSurnameError: false,
      stormEmailError: false
    }
  },
  computed: {
    currentUser () {
      return this.stormUser
    },
    adminEmail () {
      return process.env.VUE_APP_STORM_ADMIN_USER
    }
  },
  watch: {
    stormUserid () {
      this.originalEmail = this.currentUser.email
      this.checkForm()
    }
  },
  mounted () {
    this.originalEmail = this.currentUser.email
    this.checkForm()
  },
  methods: {
    updateUserDetails: async function () {
      this.isSaving = true
      if (this.formOK) {
        var updatedUser = this.currentUser
        updatedUser.password = ''
        updatedUser.accountName = updatedUser.email
        updatedUser.name = updatedUser.forename + ' ' + updatedUser.surname
        updatedUser.timeZone = 'GMT Standard Time'
        var rtn = await this.updateStormUser(updatedUser)
        if (rtn) {
          await this.setupStormUsers()
          this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
        } else {
          this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
        }
      } else {
        this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
      }
      this.isSaving = false
    },
    checkExistingUser (email) {
      return this.$store.getters.stormUsers.filter(u => u.id !== this.currentUser.id && u.email === this.currentUser.email.trim()).length > 0
    },
    clearErrors () {
      this.stormForenameError = false
      this.stormSurameError = false
      this.stormEmailError = false
    },
    checkForm: function () {
      this.clearErrors()
      this.stormForenameError = this.currentUser.forename.length === 1
      if (!this.stormForenameError) {
        this.stormSurameError = this.currentUser.surname.length === 1
      }
      if (!this.stormSurameError && this.currentUser.email && this.currentUser.email.length > 0) {
        this.stormEmailError = !this.validateEmail(this.currentUser.email) || !this.validateFirstChar(this.currentUser.email)
      }

      var errorMessage = ''
      if (this.stormForenameError) {
        errorMessage = ' Please ensure the forename is greater than a single character.'
      }
      if (this.stormSurameError) {
        errorMessage = ' Please ensure the surname is greater than a single character.'
      }
      if (this.stormEmailError) {
        errorMessage = ' The email address provided is not valid.'
      }

      if (!this.stormEmailError && this.currentUser.email && this.currentUser.email.length > 0 && this.originalEmail !== this.currentUser.email) {
        this.stormEmailError = this.checkExistingUser(this.currentUser.email)
        if (this.stormEmailError) {
          errorMessage = ' The email address provided is already in use and cannot be used again.'
        }
      }

      if (errorMessage !== '') {
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !this.stormForenameError && !this.stormSurameError && !this.stormEmailError
    },
    validateFirstChar (email) {
      var re = /^[A-Za-z0-9_-]*$/
      return re.test(email[0])
    },
    validateEmail (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}

</script>
<style scoped>
  .stormUserHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .stormUserDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
  }
  .stormUserDetailData {
    padding: 5px 10px 10px 10px;
    width: 270px;
    display: inline-block;
  }
  .stormUserDetailInput {
    width: 265px;
    display: inline-block;
    margin:0 0 10px 10px;
    padding: 0 0 0 5px;
  }
  .stormUserUpdateButton {
    float:right;
    cursor: pointer;
  }
  .stormUserUpdateButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .compulsoryField {
    color:red;
    padding: 0 8px 0 0;
  }
</style>
