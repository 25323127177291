<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      :overlay="true"
      persistent
      max-width="90rem"
      transition="dialog-bottom-transition"
      class="dialog"
    >
      <v-card
        :id="dlgid"
        class="ma-0 dialog-border"
        height="560px"
        tile
      >
        <v-toolbar
          dense
          flat
          color="white"
        >
          <v-flex
            xs12
            sm12
            md12
          >
            <h3>{{ dlgMessage }}</h3>
            <hr>
            <br>
          </v-flex>
        </v-toolbar>
        <div v-if="warningMessage !== ''">
          <div
            class="warningMessage"
            v-html="warningMessage"/>
        </div>
        <v-card-title class="card">
          <v-flex
            xs12
            sm12
            md12
          >
            <v-spacer />
            <v-spacer />
            <table
              id="editPricesTable"
              class="tableNoBoxShadow">
              <tr style="vertical-align: bottom; border-bottom: 1px solid black; margin-bottom: 10px">
                <th style="width:500px">Description</th>
                <th>Cost Type</th>
                <th>Quantity</th>
                <th>{{ entriesType === 'upfront' ? 'Upfront' : 'Annual' }}<br>Unit Cost</th>
                <th>{{ entriesType === 'upfront' ? 'Upfront' : 'Annual' }}<br>Unit Price</th>
                <th>Override<br>{{ entriesType === 'upfront' ? 'Upfront' : 'Annual' }}<br>Unit Price</th>
                <th style="width:100px;">&nbsp;</th>
                <th>Total Cost</th>
                <th>Total Price</th>
                <th>Margin</th>
              </tr>
              <tr
                v-for="entry in dialogBoxComponent.currentPageEntries"
                :key="entry.description"
              >
                <td>{{ entry.description }}</td>
                <td>{{ entry.costType }}</td>
                <td>{{ entry.quantity }}</td>
                <td>{{ displayCurrency(getCost(entry), 4) }}</td>
                <td>{{ displayCurrency(getPrice(entry)) }}</td>
                <td v-if="entry.priceOverrideAllowed && entry.itemId !== ''">£<input
                  v-model.number="entry.priceOverride"
                  type="number"
                  style="width: 80px;text-align:right; margin-right: -32px"
                  @blur="setOverrideToCorrectPolarity(entry)"
                >
                </td>
                <td v-if="!entry.priceOverrideAllowed || entry.itemId === ''">N/A</td>
                <td>&nbsp;</td>
                <td>{{ displayCurrency(getTotalCost(entry)) }}</td>
                <td>{{ displayCurrency(getTotalPrice(entry)) }}</td>
                <td
                  :class="{ marginError: isUnderMinMargin(entry) }"
                >{{ displayMargin(getMargin(entry)) }}</td>
                <td>&nbsp;</td>
              </tr>
            </table>
            <br><br>
          </v-flex>
        </v-card-title>
        <v-card-actions style="position: absolute;bottom: 1rem; right: 1rem;">
          <v-spacer/>
          <v-btn
            dark
            @click="saveAction"
          >Save</v-btn>
          <v-btn
            dark
            @click="cancelAction"
          >Cancel</v-btn>
        </v-card-actions>
        <pagination
          :index="dialogBoxComponent.paginationComponentIndex"
          :page-length="pag_pageLength"
          :margin-top="-5"
          :margin-bottom="3"
          :has-replica-in-the-same-page="dialogBoxComponent.hasReplicaInTheSamePage"
          @pageChanged="pag_pageChanged"
          @firstClicked="pag_firstClicked"
          @prevClicked="pag_prevClicked"
          @nextClicked="pag_nextClicked"
          @lastClicked="pag_lastClicked"
          @searchText="pag_searchText"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Spinner from '../../common/spinner'
import pagination from '../../common/pagination'
import { DataEventBus } from '../../../events/dataEvents'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

export default {
  name: 'DesignPackDataEditorDialogBox',
  components: {
    Spinner,
    pagination
  },
  mixins: {
    iQuoteConstants, iQuoteFunctions
  },
  props: {
    dialog: { type: Boolean, default: false },
    dlgid: { type: String, required: false, default: '' },
    dlgIcon: { type: String, required: false, default: '' },
    dlgMessage: { type: String, default: '' },
    dlgCloseFromTop: { type: Boolean, default: false },
    showTitle: { type: Boolean, default: true },
    warningMessage: { type: String, default: '' },
    entriesType: { type: String, default: '' },
    entries: { type: Array, default: function () { return [] } }
  },
  data () {
    return {
      dialogBoxComponent: {
        paginationComponentIndex: 4,
        hasReplicaInTheSamePage: false,
        maxNumberOfEntries: 2000,
        currentPage: 0,
        pageLength: 10,
        cachedEntries: [],
        currentPageEntries: [],
        filteredEntries: [],
        filter: '',
        animatePageTransitions: true,
        showData: true,
        uniqueId: 0
      }
    }
  },
  computed: {
    pag_cachedEntries () {
      return this.dialogBoxComponent.cachedEntries
    },
    pag_filteredEntries () {
      return this.dialogBoxComponent.filteredEntries
    },
    pag_filter () {
      return this.dialogBoxComponent.filter
    },
    pag_hasFilter () {
      return this.dialogBoxComponent.filter !== undefined && this.dialogBoxComponent.filter !== ''
    },
    pag_currentPage () {
      return this.dialogBoxComponent.currentPage
    },
    pag_pageLength () {
      return this.dialogBoxComponent.pageLength
    },
    pag_totalPages () {
      let totalEntries = 0
      if (this.pag_hasFilter) {
        totalEntries = this.pag_filteredEntries
      } else {
        totalEntries = this.pag_cachedEntries
      }
      return Math.ceil(totalEntries.length / this.pag_pageLength)
    }
  },
  watch: {
    entries () {
      this.pag_initialisePaginationData()
    },
    pag_currentPage () {
      this.pag_refreshCurrentPageData()
      this.pag_refreshPaginationTab()
    },
    pag_totalPages () {
      if (this.pag_currentPage === this.pag_totalPages) {
        this.dialogBoxComponent.currentPage = this.pag_currentPage - 1
      }
    },
    pag_filter () {
      this.pag_refreshCurrentPageData()
      this.pag_refreshPaginationTab()
      if (this.pag_totalPages > 0) {
        this.dialogBoxComponent.currentPage = 0
        DataEventBus.$emit(`refreshReplica${this.dialogBoxComponent.paginationComponentIndex}`, 'currentPage', false, 1)
      }
    }
  },
  created () {
  },
  destroyed () {
  },
  methods: {
    resetDialog () {
    },
    closeAction () {
      this.cancelAction()
    },
    saveAction () {
      // We need to copy any price override changes in the cached dp entries to the dp entries in the store
      this.dialogBoxComponent.cachedEntries.forEach(entry => {
        var parentEntry = this.entries.find(x => x.itemId === entry.itemId)

        if (entry.priceOverride === '') {
          // If the override textbox is empty, then translate this to a null in the DB
          entry.priceOverride = null
        }

        if (parentEntry !== undefined) {
          parentEntry.priceOverride = entry.priceOverride
        } else {
          console.log('Could not find item ' + entry.itemId + ' to alter priceoverride on')
        }
      })

      this.$emit('saveAction')
      this.resetDialog()
    },
    cancelAction () {
      this.$emit('cancelAction')
      this.resetDialog()
    },
    isUnderMinMargin (entry) {
      var designPackTableSection = this.$store.getters.designPackTableSections.find(x => x.id === entry.designPackTableSectionId)

      if (designPackTableSection === undefined) {
        return false
      } else {
        var applicableMarginMinimum = null
        var applicableDiscountMaximum = null

        if (entry.recurringCost) {
          if (entry.costTypeId === 1 /* internal */) {
            applicableDiscountMaximum = designPackTableSection.recurringInternalMaxDiscount
            applicableMarginMinimum = designPackTableSection.recurringInternalIquoteUserMinMargin
          } else { /* external */
            applicableDiscountMaximum = designPackTableSection.recurringExternalMaxDiscount
            applicableMarginMinimum = designPackTableSection.recurringExternalIquoteUserMinMargin
          }
        }

        if (entry.upfrontCost && applicableMarginMinimum === null && applicableDiscountMaximum === null) {
          if (entry.costTypeId === 1 /* internal */) {
            applicableDiscountMaximum = designPackTableSection.upfrontInternalMaxDiscount
            applicableMarginMinimum = designPackTableSection.upfrontInternalIquoteUserMinMargin
          } else { /* external */
            applicableDiscountMaximum = designPackTableSection.upfrontExternalMaxDiscount
            applicableMarginMinimum = designPackTableSection.upfrontExternalIquoteUserMinMargin
          }
        }

        if (applicableMarginMinimum === null && applicableDiscountMaximum === null) {
          return false
        } else if (applicableDiscountMaximum !== null && this.roundToTwoDecimalPlaces(this.getDetailEntryDiscountPercent(entry)) > this.roundToTwoDecimalPlaces(applicableDiscountMaximum)) {
          return true
        } else if (applicableMarginMinimum !== null && this.roundToTwoDecimalPlaces(this.getMargin(entry)) < this.roundToTwoDecimalPlaces(applicableMarginMinimum)) {
          return true
        }
      }
    },
    roundToTwoDecimalPlaces (value) {
      return Number((+(Math.round(value + 'e+2') + 'e-2')).toFixed(2))
    },
    getCost (entry) {
      if (entry.recurringCost) {
        return entry.unitARInternalCost + entry.unitARExternalCost
      } else {
        return entry.unitOOInternalCost + entry.unitOOExternalCost
      }
    },
    getPrice (entry) {
      if (entry.recurringCost) {
        return entry.unitARInternalPrice + entry.unitARExternalPrice
      } else {
        return entry.unitOOInternalPrice + entry.unitOOExternalPrice
      }
    },
    getTotalCost (entry) {
      var quantity = entry.quantity

      if (quantity < 0) {
        // a credited item will have a negative quantity and negative cost.  We don't want to multiply two negatives to make it a positive
        quantity = -quantity
      }

      return Number((this.getCost(entry) * quantity).toFixed(2))
    },
    getTotalPrice (entry) {
      var quantity = entry.quantity

      if (quantity < 0) {
        // a credited item will have a negative quantity and negative cost.  We don't want to multiply two negatives to make it a positive
        quantity = -quantity
      }

      if (entry.priceOverride !== '' && entry.priceOverride !== null) {
        return Number((Number(entry.priceOverride) * quantity).toFixed(2))
      } else {
        return Number((this.getPrice(entry) * quantity).toFixed(2))
      }
    },
    getMargin (entry) {
      var cost = this.getTotalCost(entry)
      var price = this.getTotalPrice(entry)

      return (((price - cost) / price) * 100)
    },
    getDetailEntryDiscountPercent (entry) {
      var rrp = this.getDetailEntryRRP(entry) * entry.quantity
      var price = this.getTotalPrice(entry)
      var discount = rrp - price

      return ((discount / rrp) * 100)
    },
    getDetailEntryRRP (entry) {
      if (entry.recurringCost) {
        return entry.unitARExternalRRP
      } else {
        return entry.unitOOExternalRRP
      }
    },
    displayCurrency (value, maxDecimalPlaces = 2) {
      if (value === 0 || value === null || isNaN(value) || !Number.isFinite(value)) {
        return '-'
      } else {
        return '£' + this.ThousandSeparator(value.toFixed(maxDecimalPlaces))
      }
    },
    displayMargin (margin) {
      return margin.toFixed(2) + '%'
    },
    ThousandSeparator (amount) {
      var returnValue = amount
      if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
        var numberParts = amount.split('.')
        var partBeforeDecimalPlace = numberParts[0]
        var partAfterDecimalPlace = '00'

        if (numberParts.length > 1) {
          partAfterDecimalPlace = numberParts[1]
        }

        returnValue = partBeforeDecimalPlace.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        returnValue += '.' + partAfterDecimalPlace
      }
      return returnValue
    },
    pag_initialisePaginationData () {
      this.pag_refreshCurrentPageData()
      this.pag_refreshPaginationTab()
      this.pag_clearFilter()
    },
    pag_refreshCurrentPageData () { // mount current page - triggered on page change, search, filter and delete
      this.dialogBoxComponent.uniqueId = 0
      this.dialogBoxComponent.cachedEntries = this.entries.map(array => ({ ...array, costType: array.costTypeId === 1 ? 'Internal' : 'External', uniqueId: ++this.dialogBoxComponent.uniqueId, selected: false }))
      const offset = this.pag_currentPage * this.pag_pageLength
      if (!this.pag_hasFilter) {
        this.dialogBoxComponent.currentPageEntries = this.pag_cachedEntries.slice(offset, offset + this.pag_pageLength)
      } else {
        this.dialogBoxComponent.filteredEntries = []
        this.pag_cachedEntries.forEach(element => {
          var foundOne = false
          for (var key in element) {
            if (key === 'id' || key === 'uniqueId' || key === 'disabled') continue
            if (element[key] && element[key].toString().toLowerCase().indexOf(this.pag_filter.toLowerCase()) > -1) {
              foundOne = true
            }
          }

          if (foundOne) { this.dialogBoxComponent.filteredEntries.push(element) }
        })
        this.dialogBoxComponent.currentPageEntries = this.pag_filteredEntries.slice(offset, offset + this.pag_pageLength)
      }
    },
    pag_refreshPaginationTab () {
      let numberOfEntries
      if (this.pag_hasFilter) {
        numberOfEntries = this.pag_filteredEntries.length
      } else {
        numberOfEntries = this.pag_cachedEntries.length
      }
      DataEventBus.$emit(`refreshTotalEntries${this.dialogBoxComponent.paginationComponentIndex}`, numberOfEntries)
    },
    pag_clearFilter () {
      this.dialogBoxComponent.filter = ''
      DataEventBus.$emit(`refreshReplica${this.dialogBoxComponent.paginationComponentIndex}`, 'filter', true, '')
    },
    pag_pageChanged (pageNumber) {
      this.dialogBoxComponent.currentPage = pageNumber - 1
      if (this.dialogBoxComponent.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.dialogBoxComponent.paginationComponentIndex}`, 'currentPage', false, this.dialogBoxComponent.currentPage + 1)
      }
      this.pag_animateTable()
    },
    pag_firstClicked () {
      this.dialogBoxComponent.currentPage = 0
      if (this.dialogBoxComponent.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.dialogBoxComponent.paginationComponentIndex}`, 'currentPage', false, this.dialogBoxComponent.currentPage + 1)
      }
      this.pag_animateTable()
    },
    pag_prevClicked () {
      this.dialogBoxComponent.currentPage--
      if (this.dialogBoxComponent.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.dialogBoxComponent.paginationComponentIndex}`, 'currentPage', false, this.dialogBoxComponent.currentPage + 1)
      }
      this.pag_animateTable()
    },
    pag_nextClicked () {
      this.dialogBoxComponent.currentPage++
      if (this.dialogBoxComponent.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.dialogBoxComponent.paginationComponentIndex}`, 'currentPage', false, this.dialogBoxComponent.currentPage + 1)
      }
      this.pag_animateTable()
    },
    pag_lastClicked () {
      this.dialogBoxComponent.currentPage = this.pag_totalPages - 1
      if (this.dialogBoxComponent.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.dialogBoxComponent.paginationComponentIndex}`, 'currentPage', false, this.dialogBoxComponent.currentPage + 1)
      }
      this.pag_animateTable()
    },
    pag_searchText (filter) {
      this.dialogBoxComponent.filter = filter
      DataEventBus.$emit(`refreshReplica${this.dialogBoxComponent.paginationComponentIndex}`, 'filter', true, filter)
    },
    pag_animateTable () {
      if (this.dialogBoxComponent.animatePageTransitions) {
        this.dialogBoxComponent.showData = false
        setTimeout(() => {
          this.dialogBoxComponent.showData = true
        }, 400)
      }
    },
    setOverrideToCorrectPolarity (entry) {
      if (entry.priceOverride === '') {
        return
      }
      // If quantity is negative, then the price must be negative.  Otherwise, it's positive
      entry.priceOverride = entry.quantity < 1 ? -Math.abs(entry.priceOverride) : Math.abs(entry.priceOverride)
    }
  }
}
</script>

<style>

  #editPricesTable {
    box-shadow: none;
    text-align: right;
    table-layout:auto;
    padding-right: 25px;
  }

  #editPricesTable tr {
    height: 30px;
  }

  #editPricesTable tr td {
    vertical-align: top;
  }

  .v-dialog {
    overflow-y: unset !important;
  }
  .card {
      padding:0.5rem 1.5rem -1rem 1rem;
  }
  .title {
    padding-top: 5px;
  }
  .dialog-border {
    border-left: 1rem solid silver !important
  }
  .v-btn__content {
    font-weight: bold;
  }

  .warningMessage {
    margin-left: 20px;
    font-size: x-large;
    line-height: 1.3em;
    font-weight: 500;
    font-family: 'Arial';
    color: #ed2e11;
  }

  .marginError {
    color: #ed2e11;
    font-weight: bold;
  }
</style>
