<template>
  <span
    v-show="isVisible"
    class="downloadButtonContainer">
    <input
      :value="title"
      :title="title"
      :style="buttonStyle"
      :disabled="downloadButtonComponent.downloading"
      class="downloadButton"
      type="button"
      @click="handleDownloadButtonClicked()"
    >
    <span
      v-show="downloadButtonComponent.downloading"
      class="downloadButtonPleaseWaitText"
    >
      Please Wait..
    </span>
  </span>
</template>

<script>

export default {
  name: 'DownloadButton',
  components: {
  },
  props: {
    downloadFunc: {
      type: Function,
      default: null
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    defaultFilename: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      downloadButtonComponent: {
        downloading: false
      }
    }
  },
  computed: {
    buttonStyle () {
      var style = 'margin: 0; z-index: 1;'

      if (this.downloadButtonComponent.downloading) {
        /* font is same color as background to hide it */
        style += ' background-color: gray; color: gray;'
      }

      return style
    }
  },
  methods: {
    handleDownloadButtonClicked () {
      this.downloadButtonComponent.downloading = true

      // Do on a separate thread, so the button text will change to "Please wait..." whilst we wait
      setTimeout(() => {
        this.doDownload()
      }, 0)
    },
    async doDownload () {
      this.downloadFunc().then((data) => {
        if (data !== null && data.byteLength > 0) {
          var a = document.createElement('a')
          a.style = 'display: none'
          a.href = window.URL.createObjectURL(new Blob([data], { type: this.fileType }))
          a.download = this.defaultFilename
          document.body.appendChild(a)
          a.click()
        } else {
          var errorMessage = 'Error Downloading.  Please retry. Contact your system administrator if the problem persists'
          this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
        }

        // Slight delay before enabling the button again
        setTimeout(() => {
          this.downloadButtonComponent.downloading = false
        }, 2000)
      })
    }
  }
}
</script>

<style scoped>
.downloadButtonContainer {
  padding: 0px;
  text-align: center;
  display:inline-block;
  position: relative; /* Just set to this because the PleaseWait text needs to move relative to this container */
}

.downloadButtonPleaseWaitText {
  background-color:transparent;
  color: white;
  position: absolute;
  top: 5px;
  width: 100%;
  left: 0;
  z-index: 2;
  font-size: .8em;
}
</style>
