<template>
  <div class="commercialContainer">
    <h2>Commercial Settings</h2>
    <p>This section will eventually contain the ability to alter pricing per quote</p>
  </div>
</template>

<script>

export default {
  name: 'CommercialSettings',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style scoped>

</style>
