<template>
  <div class="sstContainer">
    <div class="sstVerticalList">
      <div class="sstTitleBar">
        <div class="sstHeader sstBar">
          <h3 class="appDarkFont"><u>Circuit Status</u></h3>
        </div>
      </div>
      <div class="sstBar">
        <div class="sstItem sstHeader sstNumber">
          Up
        </div>
        <div class="sstItem sstHeader sstNumber">
          Partial
        </div>
        <div class="sstItem sstHeader sstNumber">
          Down
        </div>
      </div>
      <div class="sstBody sstBar" >
        <div
          :class="{sstNoValue:circuitStatus.noUp === 0}"
          class="sstItem sstBody sstNumber sstUp"
        >
          {{ circuitStatus.noUp }}
        </div>
        <div
          :class="{sstNoValue:circuitStatus.noPartial === 0}"
          class="sstItem sstBody sstNumber sstPartial"
        >
          {{ circuitStatus.noPartial }}
        </div>
        <div
          :class="{sstNoValue:circuitStatus.noDown === 0}"
          class="sstItem sstBody sstNumber sstDown"
        >
          {{ circuitStatus.noDown }}
        </div>
      </div>
      <hr>
      <div class="sstHeader sstBar">
        <div
          class="sstItem sstHeader"
          style="width:35%"
        >
          &nbsp;Uptime
        </div>
        <div
          class="sstItem sstUp"
          style="width:70%; text-align:center"
        >
          {{ upTime }}
        </div>
      </div>
      <hr>
      <div class="sstHeader sstBar">
        <div
          class="sstItem sstHeader"
          style="width:35%"
        >
          &nbsp;Avg Mos
        </div>
        <div
          class="sstItem sstUp"
          style="width:15%; text-align:center"
        >
          4.3
        </div>
        <div
          class="sstItem sstHeader"
          style="width:35%"
        >
          &nbsp;Cur Mos
        </div>
        <div
          class="sstItem sstUp"
          style="width:15%; text-align:center"
        >
          4.6
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      circuitStatus: { noUp: 185, noPartial: 0, noDown: 2 },
      upTime: '99.9992%'
    }
  }
}
</script>

<style>
  .sstItem{
    margin:2px;
  }
  .sstContainer {
    display:flex;
    flex-direction: column;
    padding:15px 0 5px 0;
  }
  .sstTitleBar {
    display:flex;
    flex-direction: row;
  }
  .sstBar {
    display:flex;
    flex-direction: row;
    padding:5px;
  }
  .sstNumber {
    width:33%;
    text-align:center;
  }
  .sstHeader {
    font-weight:600;
  }
  .sstUp {
    background-color:rgba(39, 174, 96,0.2);
    border:2px rgba(39, 174, 96,0.8) solid;
  }
  .sstPartial {
    background-color:rgba(230, 126, 34,0.2);
    border:2px rgba(230, 126, 34,0.8) solid;
  }
  .sstDown {
    background-color:rgba(192, 57, 43,0.2);
    border:2px rgba(192, 57, 43,0.8) solid;
  }
  .sstNoValue {
    opacity: 0.3;
  }
  .sstHeader.sstItem {
    padding-bottom:0;
  }
</style>
