<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconGamma"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="sipGammaServiceComponent.quote.quoteOpportunityId + ' (revision ' + sipGammaServiceComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>ICON SIP (UK)</h3>
      <br>
      <label
        for="numSIPConfigured"
      >SIP Configured
        <TooltipIcon
          text="Calculates required number of SIP channels based on number of user and agent profiles.  The following assumptions are used:
               <br><br>
               <ul>
                 <li>UC Users - 10:1</li>
                 <li>Voice Agents - 1:1</li>
                 <li>Multimedia Agents - 7:1</li>
               </ul> " />
      </label>
      <input
        id="numSIPConfigured"
        v-model.number="sipGammaServiceComponent.gammaSip.sipConfigured"
        class="small"
        type="number"
        min="0"
        max="100000"
        readonly
      >
      <br>
      <label
        class=""
        for="cboCodec"
      >Codec</label>
      <select
        id="cboCodec"
        v-model="sipGammaServiceComponent.gammaSip.sipCodecId"
      >
        <option
          v-for="c in sipGammaServiceComponent.codecs"
          :key="c.id"
          :value="c.id"
          :title="c.name"
        >{{ c.name }}</option>
      </select>
      <h4 class="sectionHeader">Primary SIP</h4>
      <label
        class=""
        for="cboPrimarySipProviders"
      >Primary SIP Carrier</label>
      <select
        id="cboPrimarySipProviders"
        v-model.number="sipGammaServiceComponent.gammaSip.primarySipProviderId"
        @change="primarySIPProviderChanged($event)"
      >
        <option
          key="1"
          value="1"
          title="Gamma"
        >Gamma</option>
        <option
          key="2"
          value="2"
          title="Talk Talk Business"
        >Talk Talk Business</option>
      </select>
      <label
        :class="{InputError:!isValidNumOverridePrimarySIPChannels}"
        for="numOverridePrimarySIPChannels"
      >Override Primary SIP Channels
        <TooltipIcon text="Add number of SIP channels required for Primary Service" />
      </label>
      <input
        id="chkOverridePrimarySIP"
        :checked="overridePrimarySIP"
        type="checkbox"
        style="margin-right:10px; margin-left:-23px"
        @change="setSIPPrimaryOverride($event)"
      >
      <input
        id="numOverridePrimarySIPChannels"
        :class="{hidden: !overridePrimarySIP}"
        v-model.number="sipGammaServiceComponent.gammaSip.overridePrimarySIPChannels"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br>
      <label
        :class="{InputError:!isValidNumPrimaryCircuits}"
        for="numPrimaryCircuits"
      >Multiple Circuits
        <TooltipIcon text="Add number of Circuits required for Primary Service" />
      </label>
      <input
        id="chkNeedMultiplePrimaryCircuits"
        :checked="needMultiplePrimaryCircuits"
        type="checkbox"
        style="margin-right:10px; margin-left:-23px"
        @change="setNeedMultiplePrimaryCircuits($event)"
      >
      <input
        id="numPrimaryCircuits"
        :class="{hidden: !needMultiplePrimaryCircuits && !sipGammaServiceComponent.gammaSip.isEditingPrimaryCircuits}"
        v-model.number="sipGammaServiceComponent.gammaSip.numPrimaryCircuits"
        class="small"
        type="number"
        min="0"
        max="1000"
        @focus="sipGammaServiceComponent.gammaSip.isEditingPrimaryCircuits = true"
        @blur="sipGammaServiceComponent.gammaSip.isEditingPrimaryCircuits = false"
      >
      <div v-if="sipGammaServiceComponent.gammaSip.primarySipProviderId === iQuoteConstants.sipProviders.gamma">
        <label
          for="chkSemaphoneUplift"
        >Semaphone Uplift
        </label>
        <input
          id="chkSemaphoneUplift"
          :checked="sipGammaServiceComponent.gammaSip.semaphoneUplift"
          type="checkbox"
          style="margin-right:10px; margin-left:-23px"
          @change="semaphoneChanged()"
        >
        <br>
        <label
          for="chkSTCMRequired"
        >SIP Trunk Call Manager (STCM)
        </label>
        <input
          id="chkSTCMRequired"
          :checked="sipGammaServiceComponent.gammaSip.stcmRequired"
          type="checkbox"
          style="margin-right:10px; margin-left:-23px"
          @change="stcmRequiredChanged()"
        >
        <span v-if="sipGammaServiceComponent.gammaSip.stcmRequired"><strong>Can the opportunity use MyInbound instead of STCM?</strong></span>
        <br>
        <label
          class=""
          for="cboSipResiliency"
        >Resiliency
          <TooltipIcon
            width="600"
            text="Active / Standby is mandated mode for ICON Communicate / Contact quotes" />
        </label>
        <select
          id="cboSipResiliency"
          v-model.number="sipGammaServiceComponent.gammaSip.primarySipResilienceId"
        >
          <option
            key="1"
            value="1"
            title="Active/Standby"
          >Active/Standby</option>
          <option
            key="2"
            value="2"
            title="Active"
          >Active</option>
        </select>
        <div
          v-if="sipGammaServiceComponent.gammaSip.secondarySipResilienceId === 2 && (iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined || iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) "
          class="InputError indentOnce"
        ><span>ICON Communicate only supports Active/Standby</span>
          <br>
          <br>
        </div>
      </div>
      <h4 class="sectionHeader">Secondary SIP</h4>
      <label
        class=""
        for="cboCodec"
      >Secondary SIP Carrier</label>
      <select
        id="cboSecondarySipProviders"
        v-model.number="sipGammaServiceComponent.gammaSip.secondarySipProviderId"
        @change="secondarySIPProviderChanged($event)"
      >
        <option
          key="3"
          value="3"
          title="None"
        >None</option>
        <option
          v-if="sipGammaServiceComponent.gammaSip.primarySipProviderId !== iQuoteConstants.sipProviders.gamma"
          key="1"
          value="1"
          title="Gamma"
        >Gamma</option>
        <option
          v-if="sipGammaServiceComponent.gammaSip.primarySipProviderId !== iQuoteConstants.sipProviders.ttb"
          key="2"
          value="2"
          title="Talk Talk Business"
        >Talk Talk Business</option>
      </select>
      <label
        v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
        :class="{InputError:!isValidNumOverrideSecondarySIPChannels}"
        for="numOverrideSecondarySIPChannels"
      >Override Secondary SIP Channels
        <TooltipIcon text="Add number of SIP channels required for Secondary Service" />
      </label>
      <input
        v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
        id="chkOverrideSecondarySIP"
        :checked="overrideSecondarySIP"
        type="checkbox"
        style="margin-right:10px; margin-left:-23px"
        @change="setSIPSecondaryOverride($event)"
      >
      <input
        v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
        id="numOverrideSecondarySIPChannels"
        :class="{hidden: !overrideSecondarySIP}"
        v-model.number="sipGammaServiceComponent.gammaSip.overrideSecondarySIPChannels"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
        :class="{InputError:!isValidNumSecondaryCircuits}"
        for="numSecondaryCircuits"
      >Multiple Circuits
        <TooltipIcon text="Add number of Circuits required for Secondary Service" />
      </label>
      <input
        v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
        id="chkNeedMultipleSecondaryCircuits"
        :checked="needMultipleSecondaryCircuits"
        type="checkbox"
        style="margin-right:10px; margin-left:-23px"
        @change="setNeedMultipleSecondaryCircuits($event)"
      >
      <input
        v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
        id="numSecondaryCircuits"
        :class="{hidden: !needMultipleSecondaryCircuits && !sipGammaServiceComponent.gammaSip.isEditingSecondaryCircuits}"
        v-model.number="sipGammaServiceComponent.gammaSip.numSecondaryCircuits"
        class="small"
        type="number"
        min="0"
        max="1000"
        @focus="sipGammaServiceComponent.gammaSip.isEditingSecondaryCircuits = true"
        @blur="sipGammaServiceComponent.gammaSip.isEditingSecondaryCircuits = false"
      >

      <div v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId === iQuoteConstants.sipProviders.gamma">
        <label
          for="chkSemaphoneUplift"
        >Semaphone Uplift
        </label>
        <input
          id="chkSemaphoneUplift"
          :checked="sipGammaServiceComponent.gammaSip.semaphoneUplift"
          type="checkbox"
          style="margin-right:10px; margin-left:-23px"
          @change="semaphoneChanged()"
        >
        <br>
        <label
          for="chkSTCMRequired"
        >SIP Trunk Call Manager (STCM)
        </label>
        <input
          id="chkSTCMRequired"
          :checked="sipGammaServiceComponent.gammaSip.stcmRequired"
          type="checkbox"
          style="margin-right:10px; margin-left:-23px"
          @change="stcmRequiredChanged()"
        >
        <span v-if="sipGammaServiceComponent.gammaSip.stcmRequired">Can the opportunity use MyInbound instead of STCM?</span>
        <br>
        <label
          class=""
          for="cboSipResiliency"
        >Resiliency
          <TooltipIcon
            width="600"
            text="Active / Standby is mandated mode for ICON Communicate / Contact quotes" />
        </label>
        <select
          id="cboSipResiliency"
          v-model.number="sipGammaServiceComponent.gammaSip.secondarySipResilienceId"
        >
          <option
            key="1"
            value="1"
            title="Active/Standby"
          >Active/Standby</option>
          <option
            key="2"
            value="2"
            title="Active"
          >Active</option>
        </select>
        <div
          v-if="sipGammaServiceComponent.gammaSip.secondarySipResilienceId === 2 && (iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined || iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) "
          class="InputError indentOnce"
        ><span>ICON Communicate only supports Active/Standby</span>
          <br>
          <br>
        </div>
      </div>
      <h4 class="sectionHeader">Number Porting</h4>
      <table>
        <tr
          v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
        >
          <th class="labelColumn"/>
          <th>Primary</th>
          <th>Secondary</th>
        </tr>
        <tr>
          <td class="labelColumn">
            <label
              :class="{InputError:!isValidNumPortingPrimarySingleLine || !isValidNumPortingSecondarySingleLine}"
              for="numPortingPrimarySingleLine"
            >Single Line
            </label>
          </td>
          <td>
            <input
              id="numPortingPrimarySingleLine"
              v-model.number="sipGammaServiceComponent.gammaSip.numPortingPrimarySingleLine "
              :class="{invalidInput:!isValidNumPortingPrimarySingleLine}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
          <td
            v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
          >
            <input
              id="numPortingSecondarySingleLine"
              v-model.number="sipGammaServiceComponent.gammaSip.numPortingSecondarySingleLine "
              :class="{invalidInput:!isValidNumPortingSecondarySingleLine}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
        </tr>
        <tr>
          <td class="labelColumn">
            <label
              :class="{InputError:!isValidNumPortingPrimaryMultiLine || !isValidNumPortingSecondaryMultiLine}"
              for="numPortingPrimaryMultiLine"
            >Multi Line
            </label>
          </td>
          <td>
            <input
              id="numPortingPrimaryMultiLine"
              v-model.number="sipGammaServiceComponent.gammaSip.numPortingPrimaryMultiLine "
              :class="{invalidInput:!isValidNumPortingPrimaryMultiLine}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
          <td
            v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
          >
            <input
              id="numPortingSecondaryMultiLine"
              v-model.number="sipGammaServiceComponent.gammaSip.numPortingSecondaryMultiLine "
              :class="{invalidInput:!isValidNumPortingSecondaryMultiLine}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
        </tr>
        <tr>
          <td class="labelColumn">
            <label
              :class="{InputError:!isValidNumPortingPrimaryMultiLine1To10 || !isValidNumPortingSecondaryMultiLine1To10}"
              for="numPortingPrimaryMultiLine1To10"
            >Multi Line (1-10)
            </label>
          </td>
          <td>
            <input
              id="numPortingPrimaryMultiLine1To10"
              v-model.number="sipGammaServiceComponent.gammaSip.numPortingPrimaryMultiLine1To10 "
              :class="{invalidInput:!isValidNumPortingPrimaryMultiLine1To10}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
          <td
            v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
          >
            <input
              id="numPortingSecondaryMultiLine1To10"
              v-model.number="sipGammaServiceComponent.gammaSip.numPortingSecondaryMultiLine1To10 "
              :class="{invalidInput:!isValidNumPortingSecondaryMultiLine1To10}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
        </tr>
        <tr>
          <td class="labelColumn">
            <label
              :class="{InputError:!isValidNumPortingPrimaryMultiLineBlocksOver10 || !isValidNumPortingSecondaryMultiLineBlocksOver10}"
              for="numPortingPrimaryMultiLineBlocksOver10"
            >Multi Line (Blocks of > 10)
            </label>
          </td>
          <td>
            <input
              id="numPortingPrimaryMultiLineBlocksOver10"
              v-model.number="sipGammaServiceComponent.gammaSip.numPortingPrimaryMultiLineBlocksOver10"
              :class="{invalidInput:!isValidNumPortingPrimaryMultiLineBlocksOver10}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
          <td
            v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId !== iQuoteConstants.sipProviders.none"
          >
            <input
              id="numPortingSecondaryMultiLineBlocksOver10"
              v-model.number="sipGammaServiceComponent.gammaSip.numPortingSecondaryMultiLineBlocksOver10"
              :class="{invalidInput:!isValidNumPortingSecondaryMultiLineBlocksOver10}"
              class="small"
              type="number"
              min="0"
              max="100000"
            >
          </td>
        </tr>
      </table>

      <div
        v-if="numPortingTotalCount > 0"
      >
        <br><br>
        <label
          :class="{InputError:!isValidNumPortingOohWeekdayEvening }"
          for="numPortingOohWeekdayEvening "
        >Num OOH Weekday Evenings
        </label>
        <input
          id="numPortingOohWeekdayEvening "
          v-model.number="sipGammaServiceComponent.gammaSip.numPortingOohWeekdayEvening "
          class="small"
          type="number"
          min="0"
          max="100000"
        >
        <label
          :class="{InputError:!isValidNumPortingOohSaturday}"
          for="numPortingOohSaturday"
        >Num OOH Saturdays
        </label>
        <input
          id="numPortingOohSaturday"
          v-model.number="sipGammaServiceComponent.gammaSip.numPortingOohSaturday"
          class="small"
          type="number"
          min="0"
          max="100000"
        >
      </div>

      <h4 class="sectionHeader">DDIs</h4>
      <label
        :class="{InputError:!isValidNumNewDDIs}"
        for="numNewDDI"
      >New DDI<TooltipIcon
        text="If you require new DDI numbers please state how many you need" />
      </label>
      <input
        id="numNewDDI"
        v-model.number="sipGammaServiceComponent.gammaSip.newDDIs"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId === iQuoteConstants.sipProviders.ttb"
        :class="{InputError:!isValidNumNewDDIsSecondary}"
        for="numNewDDI"
      >New DDI (Secondary Provider)<TooltipIcon
        text="If you require new DDI numbers please state how many you need" />
      </label>
      <input
        v-if="sipGammaServiceComponent.gammaSip.secondarySipProviderId === iQuoteConstants.sipProviders.ttb"
        id="numNewDDISecondary"
        v-model.number="sipGammaServiceComponent.gammaSip.newDDIsSecondary"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br v-if="sipGammaServiceComponent.gammaSip.stcmRequired">
      <label
        v-if="sipGammaServiceComponent.gammaSip.stcmRequired"
        :class="{InputError:!isValidSipTrunkCallManager}"
        for="numSipTrunkCallManager"
      >SIP Trunk Call Manager - Number of DDIs<TooltipIcon
        text="STCM is included in the package at no additional cost for up to 1000 DDI's.  Charges apply where STCM is used to manage > 1000 DDI's.  State total amount of DDI's." />
      </label>
      <input
        v-if="(sipGammaServiceComponent.gammaSip.stcmRequired)"
        id="numSipTrunkCallManager"
        v-model.number="sipGammaServiceComponent.gammaSip.sipTrunkCallManager"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br>
      <label
        :class="{InputError:!isValidSBCPorts}"
        for="numSBCPorts"
      >SBC Ports<TooltipIcon
        text="SBC Ports ordered additionally to SIP trunks" />
      </label>
      <input
        id="numSBCPorts"
        v-model.number="sipGammaServiceComponent.gammaSip.sbcPorts"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SIPServices')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'SipGammaService',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteStoreMixin, iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin],
  data () {
    return {
      sipGammaServiceComponent: {
        quote: {},
        codecs: [],
        gammaSip: {
          id: 0,
          sipCodecId: 1,
          overridePrimarySIPChannels: 0,
          overrideSecondarySIPChannels: 0,
          sipConfigured: 0,
          pciCompliance: false,
          sipTrunkCallManager: 0,
          newDDIs: 0,
          outOfHoursPortingTypeId: 1, /* None */
          primarySipProviderId: 1, /* Gamma */
          secondarySipProviderId: 3, /* None */
          primarySipResilienceId: 1, /* Active Standby */
          secondarySipResilienceId: 1, /* Active Standby */
          sbcPorts: 0,
          newDDIsSecondary: 0,
          semaphoneUplift: false,
          stcmRequired: false,
          numPrimaryCircuits: 1,
          numSecondaryCircuits: 0,
          isEditingPrimaryCircuits: false,
          isEditingSecondaryCircuits: false,
          numPortingOohWeekdayEvening: 0,
          numPortingOohSaturday: 0,
          numPortingPrimarySingleLine: 0,
          numPortingPrimaryMultiLine: 0,
          numPortingPrimaryMultiLine1To10: 0,
          numPortingPrimaryMultiLineBlocksOver10: 0,
          numPortingSecondarySingleLine: 0,
          numPortingSecondaryMultiLine: 0,
          numPortingSecondaryMultiLine1To10: 0,
          numPortingSecondaryMultiLineBlocksOver10: 0
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconGamma () {
      return require(`../../../assets/icons/gammaSIP.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidNumOverridePrimarySIPChannels () {
      var value = this.iQuoteStore_GetGammaSip.overridePrimarySIPChannels
      return (this.iQuoteFunctions_IsInteger(value) && (value >= 1 || this.iQuoteStore_GetGammaSip.sipConfigured > 0))
    },
    isValidNumOverrideSecondarySIPChannels () {
      if (this.sipGammaServiceComponent.gammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.none) {
        return true
      }

      var value = this.iQuoteStore_GetGammaSip.overrideSecondarySIPChannels
      return (this.iQuoteFunctions_IsInteger(value) && (value >= 1 || this.iQuoteStore_GetGammaSip.sipConfigured > 0))
    },
    isValidNumPrimaryCircuits () {
      var value = this.iQuoteStore_GetGammaSip.numPrimaryCircuits
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidNumSecondaryCircuits () {
      if (this.sipGammaServiceComponent.gammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.none) {
        return true
      }

      var value = this.iQuoteStore_GetGammaSip.numSecondaryCircuits
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidNumNewDDIs () {
      var value = this.iQuoteStore_GetGammaSip.newDDIs
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumNewDDIsSecondary () {
      var value = this.iQuoteStore_GetGammaSip.newDDIsSecondary
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumPCICompliance () {
      var value = this.iQuoteStore_GetGammaSip.pciCompliance
      return (this.iQuoteFunctions_IsInteger(value) && value >= 0)
    },
    isValidNumPortingOohWeekdayEvening () {
      var value = this.iQuoteStore_GetGammaSip.numPortingOohWeekdayEvening
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumPortingOohSaturday () {
      var value = this.iQuoteStore_GetGammaSip.numPortingOohSaturday
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumPortingPrimarySingleLine () {
      var value = this.iQuoteStore_GetGammaSip.numPortingPrimarySingleLine
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumPortingPrimaryMultiLine () {
      var value = this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumPortingPrimaryMultiLine1To10 () {
      var value = this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine1To10
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumPortingPrimaryMultiLineBlocksOver10 () {
      var value = this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLineBlocksOver10
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumPortingSecondarySingleLine () {
      var value = this.iQuoteStore_GetGammaSip.numPortingSecondarySingleLine
      return (this.iQuoteFunctions_IsInteger(value) || this.sipGammaServiceComponent.gammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.none)
    },
    isValidNumPortingSecondaryMultiLine () {
      var value = this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine
      return (this.iQuoteFunctions_IsInteger(value) || this.sipGammaServiceComponent.gammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.none)
    },
    isValidNumPortingSecondaryMultiLine1To10 () {
      var value = this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine1To10
      return (this.iQuoteFunctions_IsInteger(value) || this.sipGammaServiceComponent.gammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.none)
    },
    isValidNumPortingSecondaryMultiLineBlocksOver10 () {
      var value = this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLineBlocksOver10
      return (this.iQuoteFunctions_IsInteger(value) || this.sipGammaServiceComponent.gammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.none)
    },
    isValidSipTrunkCallManager () {
      var value = this.iQuoteStore_GetGammaSip.sipTrunkCallManager
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidSBCPorts () {
      var value = this.iQuoteStore_GetGammaSip.sbcPorts
      return (this.iQuoteFunctions_IsInteger(value))
    },
    oohPortingsStore () {
      return this.$store.getters.oohPortings
    },
    overridePrimarySIP () {
      return (this.sipGammaServiceComponent.gammaSip.overridePrimarySIPChannels > 0 || this.sipGammaServiceComponent.gammaSip.overridePrimarySIPChannels === '' || this.sipGammaServiceComponent.gammaSip.sipConfigured === 0)
    },
    overrideSecondarySIP () {
      return (this.sipGammaServiceComponent.gammaSip.overrideSecondarySIPChannels > 0 || this.sipGammaServiceComponent.gammaSip.overrideSecondarySIPChannels === '' || this.sipGammaServiceComponent.gammaSip.sipConfigured === 0)
    },
    needMultiplePrimaryCircuits () {
      return (this.sipGammaServiceComponent.gammaSip.numPrimaryCircuits > 1 || this.sipGammaServiceComponent.gammaSip.numPrimaryCircuits === '')
    },
    needMultipleSecondaryCircuits () {
      return (this.sipGammaServiceComponent.gammaSip.numSecondaryCircuits > 1 || this.sipGammaServiceComponent.gammaSip.numSecondaryCircuits === '')
    },
    numPortingTotalCount () {
      var total = this.sipGammaServiceComponent.gammaSip.numPortingPrimarySingleLine +
        this.sipGammaServiceComponent.gammaSip.numPortingPrimaryMultiLine +
        this.sipGammaServiceComponent.gammaSip.numPortingPrimaryMultiLine1To10 +
        this.sipGammaServiceComponent.gammaSip.numPortingPrimaryMultiLineBlocksOver10

      if (this.sipGammaServiceComponent.gammaSip.secondarySipProviderId !== this.iQuoteConstants.sipProviders.none) {
        total += this.sipGammaServiceComponent.gammaSip.numPortingSecondarySingleLine +
          this.sipGammaServiceComponent.gammaSip.numPortingSecondaryMultiLine +
          this.sipGammaServiceComponent.gammaSip.numPortingSecondaryMultiLine1To10 +
          this.sipGammaServiceComponent.gammaSip.numPortingSecondaryMultiLineBlocksOver10
      }
      return total
    }
  },
  async mounted () {
    this.sipGammaServiceComponent.codecs = this.$store.getters.codecs
    this.sipGammaServiceComponent.quote = this.$store.getters.quote
    this.sipGammaServiceComponent.gammaSip = this.iQuoteStore_GetGammaSip

    if (this.iQuoteStore_GetGammaSip.id === undefined) {
      var gammaSipFromDb = await this.getQuoteSIPProperties(this.sipGammaServiceComponent.quote.id)

      if (gammaSipFromDb.sipCodecId === undefined) {
        // Not in Db
        this.sipGammaServiceComponent.gammaSip = {
          id: 0,
          quoteId: this.quote.id,
          sipCodecId: 1,
          overridePrimarySIPChannels: 0,
          overrideSecondarySIPChannels: 0,
          sipConfigured: 0,
          pciCompliance: false,
          sipTrunkCallManager: 0,
          newDDIs: 0,
          outOfHoursPortingTypeId: this.iQuoteConstants.outOfHoursPorting.NONE,
          primarySipProviderId: this.iQuoteConstants.sipProviders.gamma,
          secondarySipProviderId: this.iQuoteConstants.sipProviders.none,
          primarySipResilienceId: this.iQuoteConstants.sipResilienceTypes.ACTIVE_STANDBY,
          secondarySipResilienceId: this.iQuoteConstants.sipResilienceTypes.ACTIVE_STANDBY,
          sbcPorts: 0,
          newDDIsSecondary: 0,
          semaphoneUplift: false,
          stcmRequired: false,
          numPrimaryCircuits: 1,
          numSecondaryCircuits: 0,
          numPortingOohWeekdayEvening: 0,
          numPortingOohSaturday: 0,
          numPortingPrimarySingleLine: 0,
          numPortingPrimaryMultiLine: 0,
          numPortingPrimaryMultiLine1To10: 0,
          numPortingPrimaryMultiLineBlocksOver10: 0,
          numPortingSecondarySingleLine: 0,
          numPortingSecondaryMultiLine: 0,
          numPortingSecondaryMultiLine1To10: 0,
          numPortingSecondaryMultiLineBlocksOver10: 0
        }
      } else {
        this.sipGammaServiceComponent.gammaSip = gammaSipFromDb
      }

      this.setQuoteGammaSIP(this.sipGammaServiceComponent.gammaSip)

      this.calculationsCommon_calcNumSipChannelsRequired()

      this.sipGammaServiceComponent.gammaSip = this.iQuoteStore_GetGammaSip
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteGammaSIP'
    ]),
    async gotoPage (page) {
      var technologyId = await this.getQuoteTechnologyId(this.sipGammaServiceComponent.quote.id, this.iQuoteConstants.technologies.gammaSIP)

      if (this.iQuoteStore_GetGammaSip.overridePrimarySIPChannels === 0 && this.iQuoteStore_GetGammaSip.overrideSecondarySIPChannels === 0 && this.iQuoteStore_GetGammaSip.sipConfigured === 0 && technologyId >= 1) {
      // SIP not required so delete from DB
        this.deleteQuoteTechnology(technologyId)
        // Clear form details from store
        this.setQuoteGammaSIP({})
      } else if (!(technologyId >= 1)) {
        // It's not in the db, so clear details
        this.setQuoteGammaSIP({})
      } else if (page === 'SIPServices') {
        // Going back, so reset to what it was in db
        var gammaSipFromDb = await this.getQuoteSIPProperties(this.sipGammaServiceComponent.quote.id)
        this.setQuoteGammaSIP(gammaSipFromDb)
        this.calculationsCommon_calcNumSipChannelsRequired()
      }
      this.setCurrentQuoteState(page)
    },
    async finishService () {
      var ValidationFailed = false

      if (!this.isValidNumOverridePrimarySIPChannels) {
        ValidationFailed = true
      }
      if (!this.isValidNumOverrideSecondarySIPChannels) {
        ValidationFailed = true
      }
      if (!this.isValidNumNewDDIs) {
        ValidationFailed = true
      }
      if (!this.isValidNumPCICompliance) {
        ValidationFailed = true
      }

      if (
        !this.isValidNumPortingOohWeekdayEvening ||
        !this.isValidNumPortingOohSaturday ||
        !this.isValidNumPortingPrimarySingleLine ||
        !this.isValidNumPortingPrimaryMultiLine ||
        !this.isValidNumPortingPrimaryMultiLine1To10 ||
        !this.isValidNumPortingPrimaryMultiLineBlocksOver10 ||
        !this.isValidNumPortingSecondarySingleLine ||
        !this.isValidNumPortingSecondaryMultiLine ||
        !this.isValidNumPortingSecondaryMultiLine1To10 ||
        !this.isValidNumPortingSecondaryMultiLineBlocksOver10
      ) {
        ValidationFailed = true
      }

      if (!ValidationFailed) {
        if (this.sipGammaServiceComponent.gammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.none) {
          this.sipGammaServiceComponent.gammaSip.newDDIsSecondary = 0
          this.sipGammaServiceComponent.gammaSip.numPortingSecondarySingleLine = 0
          this.sipGammaServiceComponent.gammaSip.numPortingSecondaryMultiLine = 0
          this.sipGammaServiceComponent.gammaSip.numPortingSecondaryMultiLine1To10 = 0
          this.sipGammaServiceComponent.gammaSip.numPortingSecondaryMultiLineBlocksOver10 = 0
          this.sipGammaServiceComponent.gammaSip.overrideSecondarySIPChannels = 0
          this.sipGammaServiceComponent.gammaSip.numSecondaryCircuits = 0
        }

        if (this.sipGammaServiceComponent.gammaSip.primarySipProviderId !== this.iQuoteConstants.sipProviders.gamma &&
            this.sipGammaServiceComponent.gammaSip.secondarySipProviderId !== this.iQuoteConstants.sipProviders.gamma) {
          this.sipGammaServiceComponent.gammaSip.semaphoneUplift = false
        }

        if (this.sipGammaServiceComponent.gammaSip.semaphoneUplift) {
          this.sipGammaServiceComponent.gammaSip.sipTrunkCallManager = 0
        }

        // Save to DB only if valid data is supplied
        if (this.iQuoteStore_GetGammaSip.id === 0) {
          // eslint-disable-next-line
          var techId = await this.addQuoteTechnologyId(this.sipGammaServiceComponent.quote.id, this.iQuoteConstants.technologies.gammaSIP)
        }

        this.sipGammaServiceComponent.gammaSip = await this.saveQuoteSipProperties(this.sipGammaServiceComponent.gammaSip)
        this.setQuoteGammaSIP(this.sipGammaServiceComponent.gammaSip)
        this.gotoPage('SelectService')
      } else {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
      }
    },
    setSIPPrimaryOverride (event) {
      var val = event.target.checked
      if (val) {
        this.sipGammaServiceComponent.gammaSip.overridePrimarySIPChannels = this.sipGammaServiceComponent.gammaSip.sipConfigured
      } else {
        this.sipGammaServiceComponent.gammaSip.overridePrimarySIPChannels = 0
      }
    },
    setSIPSecondaryOverride (event) {
      var val = event.target.checked
      if (val) {
        this.sipGammaServiceComponent.gammaSip.overrideSecondarySIPChannels = this.sipGammaServiceComponent.gammaSip.sipConfigured
      } else {
        this.sipGammaServiceComponent.gammaSip.overrideSecondarySIPChannels = 0
      }
    },
    setNeedMultiplePrimaryCircuits (event) {
      var val = event.target.checked
      if (val) {
        this.sipGammaServiceComponent.gammaSip.numPrimaryCircuits = 2
      } else {
        this.sipGammaServiceComponent.gammaSip.numPrimaryCircuits = 1
      }
    },
    setNeedMultipleSecondaryCircuits (event) {
      var val = event.target.checked
      if (val) {
        this.sipGammaServiceComponent.gammaSip.numSecondaryCircuits = 2
      } else {
        this.sipGammaServiceComponent.gammaSip.numSecondaryCircuits = 1
      }
    },
    primarySIPProviderChanged (event) {
      this.sipGammaServiceComponent.gammaSip.secondarySipProviderId = this.iQuoteConstants.sipProviders.none
    },
    secondarySIPProviderChanged (event) {
      if (this.sipGammaServiceComponent.gammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
        this.sipGammaServiceComponent.gammaSip.secondarySipResilienceId = 2 /* active */
      }

      if (this.sipGammaServiceComponent.gammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.none) {
        this.sipGammaServiceComponent.gammaSip.numSecondaryCircuits = 0
      } else if (this.sipGammaServiceComponent.gammaSip.numSecondaryCircuits === 0) {
        this.sipGammaServiceComponent.gammaSip.numSecondaryCircuits = 1
      }
    },
    semaphoneChanged (event) {
      this.sipGammaServiceComponent.gammaSip.semaphoneUplift = !this.sipGammaServiceComponent.gammaSip.semaphoneUplift

      if (this.sipGammaServiceComponent.gammaSip.semaphoneUplift) {
        this.sipGammaServiceComponent.gammaSip.sipTrunkCallManager = 0
      }
    },
    stcmRequiredChanged (event) {
      this.sipGammaServiceComponent.gammaSip.stcmRequired = !this.sipGammaServiceComponent.gammaSip.stcmRequired

      if (this.sipGammaServiceComponent.gammaSip.stcmRequired) {
        this.sipGammaServiceComponent.gammaSip.sipTrunkCallManager = 0
      }
    },
    setCBO (type, event) {
      var cbo = event.target.value
      if (type === 'codec') {
        this.iQuoteStore_GetGammaSip.sipCodecId = Number(cbo)
      }
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 820px}
  .quotePanel label {width:320px;}
  .small {width:60px;}
  .hidden {visibility: hidden;}

  .quotePanel .labelColumn {
    width: 335px;
  }
</style>
