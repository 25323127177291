// This module is used for state mamangement for permission related variables
const state = {
  navLinks: [],
  reloadUsers: false,
  reloadRoles: false,
  reloadPermissions: false,
  reloadAreas: false,
  reloadMembers: false,
  openMainForm: false,
  openSubForm: false,
  openSubForm2: false,
  openSubForm3: false,
  openSubForm4: false,
  roles: [],
  areas: [],
  roleAreas: [],
  roleMembers: [],
  permissions: [],
  allPermissions: [],
  customers: [],
  companies: [],
  customerFlags: [],
  currentCompanyParentContainer: [],
  companyContainers: [],
  currentCustomer: {},
  currentCompany: {},
  topLevelAreas: [],
  companyEmailSuffixes: [],
  entityStyles: [],
  viewiQuotePermission: false,
  viewAreaTicketsPermission: false,
  updateTicketPermission: false,
  createTicketPermission: false,
  viewAreasPermission: false,
  viewEntityPermission: false,
  viewSecurityPermission: false,
  viewRolePermission: false,
  viewPermissionPermission: false,
  viewRoleAreaPermission: false,
  viewEntityAreaPermission: false,
  viewEntityRolePermission: false,
  viewAssignedPermissionPermission: false,
  createEntityPermission: false,
  deleteEntityPermission: false,
  updateEntityPermission: false,
  createRolePermission: false,
  deleteRolePermission: false,
  updateRolePermission: false,
  assignEntityRolePermission: false,
  removeEntityRolePermission: false,
  updateAreaPermission: false,
  assignEntityAreaPermission: false,
  removeEntityAreaPermission: false,
  assignRoleAreaPermission: false,
  removeRoleAreaPermission: false,
  updateGrantPermissionPermission: false,
  updateAllowPermissionPermission: false,
  viewQuotesAndProjectsPermission: false,
  assignToCustomerPermission: false,
  viewCustomerPermission: false,
  createCustomerPermission: false,
  editCustomerPermission: false,
  deleteCustomerPermission: false,
  viewSupportPermission: false,
  viewCompanyPermission: false,
  createCompanyPermission: false,
  editCompanyPermission: false,
  deleteCompanyPermission: false,
  viewCommunicateServicePermission: false,
  viewSecureServicePermission: false,
  viewConnectServicePermission: false,
  viewNowServicePermission: false,
  uploadDashboardImage: false,
  maintelTesting: false,
  iQuoteAdminPermission: false,
  iQuoteCommercialsPermission: false,
  iQuoteTesterPermission: false,
  hasJemAccount: false,
  viewMobileReporting: false,
  updateTraverseContainers: false
}

const mutations = {
  SET_NAV_LINKS (state, links) {
    state.navLinks = links
  },
  SET_AREAS (state, areas) {
    state.areas = areas
  },
  SET_ROLES (state, roles) {
    state.roles = roles
  },
  SET_ROLE_AREAS (state, roleAreas) {
    state.roleAreas = roleAreas
  },
  SET_ROLE_MEMBERS (state, roleMembers) {
    state.roleMembers = roleMembers
  },
  SET_PERMISSIONS (state, permissions) {
    state.permissions = permissions
  },
  SET_ALL_PERMISSIONS (state, allPermissions) {
    state.allPermissions = allPermissions
  },
  SET_CUSTOMERS (state, customers) {
    state.customers = customers
    if (customers.length === 0) {
      state.currentCustomer = {}
    } else if (customers.length === 1 && state.currentCustomer.id === undefined) {
      state.currentCustomer = customers[0]
    } else if (state.currentCustomer.id === undefined) {
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].isDefault && state.currentCustomer.id === undefined) {
          state.currentCustomer = customers[i]
        }
      }
    }
  },
  SET_COMPANIES (state, companies) {
    state.companies = companies
    if (companies.length === 0) {
      state.currentCompany = {}
    } else if (companies.length === 1 && state.currentCompany.id === undefined) {
      state.currentCompany = companies[0]
    } else if (state.currentCompany.id === undefined) {
      for (let i = 0; i < companies.length; i++) {
        var defaultCustomer = state.customers.find(customer => customer.isDefault)
        if (companies[i].id === defaultCustomer.companyId && state.currentCompany.id === undefined) {
          state.currentCompany = companies[i]
        }
      }
    }
  },
  SET_CUSTOMER_FLAGS (state, customerFlags) {
    state.customerFlags = customerFlags
  },
  SET_CURRENT_COMPANY_PARENT_CONTAINER (state, currentCompanyParentContainer) {
    state.currentCompanyParentContainer = currentCompanyParentContainer
  },
  SET_COMPANY_CONTAINERS (state, companyContainers) {
    state.companyContainers = companyContainers
  },
  SET_CURRENT_CUSTOMER (state, currentCustomer) {
    state.currentCustomer = currentCustomer
  },
  SET_CURRENT_COMPANY (state, currentCompany) {
    state.currentCompany = currentCompany
  },
  RELOAD_USERS (state, reloadUsers) {
    state.reloadUsers = reloadUsers
  },
  RELOAD_ROLES (state, reloadRoles) {
    state.reloadRoles = reloadRoles
  },
  RELOAD_PERMISSIONS (state, reloadPermissions) {
    state.reloadPermissions = reloadPermissions
  },
  RELOAD_AREAS (state, reloadAreas) {
    state.reloadAreas = reloadAreas
  },
  RELOAD_MEMBERS (state, reloadMembers) {
    state.reloadMembers = reloadMembers
  },
  SET_MAINFORM (state, openMainForm) {
    state.openMainForm = openMainForm
  },
  SET_SUBFORM (state, openSubForm) {
    state.openSubForm = openSubForm
  },
  SET_SUBFORM2 (state, openSubForm2) {
    state.openSubForm2 = openSubForm2
  },
  SET_SUBFORM3 (state, openSubForm3) {
    state.openSubForm3 = openSubForm3
  },
  SET_SUBFORM4 (state, openSubForm4) {
    state.openSubForm4 = openSubForm4
  },
  SET_PERMISSION_VIEW_IQUOTE (state, viewiQuotePermission) {
    state.viewiQuotePermission = viewiQuotePermission
  },
  SET_PERMISSION_VIEW_TICKETS (state, viewAreaTicketsPermission) {
    state.viewAreaTicketsPermission = viewAreaTicketsPermission
  },
  SET_PERMISSION_VIEW_AREAS (state, viewAreasPermission) {
    state.viewAreasPermission = viewAreasPermission
  },
  SET_PERMISSION_VIEW_ENTITIES (state, viewEntityPermission) {
    state.viewEntityPermission = viewEntityPermission
  },
  SET_PERMISSION_VIEW_SECURITY (state, viewSecurityPermission) {
    state.viewSecurityPermission = viewSecurityPermission
  },
  SET_PERMISSION_VIEW_ROLES (state, viewRolePermission) {
    state.viewRolePermission = viewRolePermission
  },
  SET_PERMISSION_VIEW_PERMISSIONS (state, viewPermissionPermission) {
    state.viewPermissionPermission = viewPermissionPermission
  },
  SET_PERMISSION_VIEW_ROLE_AREA (state, viewRoleAreaPermission) {
    state.viewRoleAreaPermission = viewRoleAreaPermission
  },
  SET_PERMISSION_VIEW_ENTITY_AREA (state, viewEntityAreaPermission) {
    state.viewEntityAreaPermission = viewEntityAreaPermission
  },
  SET_PERMISSION_VIEW_ENTITY_ROLE (state, viewEntityRolePermission) {
    state.viewEntityRolePermission = viewEntityRolePermission
  },
  SET_PERMISSION_VIEW_ASSIGNED_PERMISSIONS (state, viewAssignedPermissionPermission) {
    state.viewAssignedPermissionPermission = viewAssignedPermissionPermission
  },
  SET_PERMISSION_VIEW_EXEC_DASHBOARD (state, viewQuotesAndProjectsPermission) {
    state.viewQuotesAndProjectsPermission = viewQuotesAndProjectsPermission
  },
  SET_PERMISSION_CREATE_ENTITY (state, createEntityPermission) {
    state.createEntityPermission = createEntityPermission
  },
  SET_PERMISSION_CREATE_TICKET (state, createTicketPermission) {
    state.createTicketPermission = createTicketPermission
  },
  SET_PERMISSION_DELETE_ENTITY (state, deleteEntityPermission) {
    state.deleteEntityPermission = deleteEntityPermission
  },
  SET_PERMISSION_UPDATE_ENTITY (state, updateEntityPermission) {
    state.updateEntityPermission = updateEntityPermission
  },
  SET_PERMISSION_UPDATE_TICKET (state, updateTicketPermission) {
    state.updateTicketPermission = updateTicketPermission
  },
  SET_PERMISSION_CREATE_ROLE (state, createRolePermission) {
    state.createRolePermission = createRolePermission
  },
  SET_PERMISSION_DELETE_ROLE (state, deleteRolePermission) {
    state.deleteRolePermission = deleteRolePermission
  },
  SET_PERMISSION_UPDATE_ROLE (state, updateRolePermission) {
    state.updateRolePermission = updateRolePermission
  },
  SET_PERMISSION_ASSIGN_ENTITY_ROLE (state, assignEntityRolePermission) {
    state.assignEntityRolePermission = assignEntityRolePermission
  },
  SET_PERMISSION_REMOVE_ENTITY_ROLE (state, removeEntityRolePermission) {
    state.removeEntityRolePermission = removeEntityRolePermission
  },
  SET_PERMISSION_UPDATE_AREA (state, updateAreaPermission) {
    state.updateAreaPermission = updateAreaPermission
  },
  SET_PERMISSION_ASSIGN_ENTITY_AREA (state, assignEntityAreaPermission) {
    state.assignEntityAreaPermission = assignEntityAreaPermission
  },
  SET_PERMISSION_REMOVE_ENTITY_AREA (state, removeEntityAreaPermission) {
    state.removeEntityAreaPermission = removeEntityAreaPermission
  },
  SET_PERMISSION_ASSIGN_ROLE_AREA (state, assignRoleAreaPermission) {
    state.assignRoleAreaPermission = assignRoleAreaPermission
  },
  SET_PERMISSION_REMOVE_ROLE_AREA (state, removeRoleAreaPermission) {
    state.removeRoleAreaPermission = removeRoleAreaPermission
  },
  SET_PERMISSION_UPDATE_GRANT_PERMISSION (state, updateGrantPermissionPermission) {
    state.updateGrantPermissionPermission = updateGrantPermissionPermission
  },
  SET_PERMISSION_UPDATE_ALLOW_PERMISSION (state, updateAllowPermissionPermission) {
    state.updateAllowPermissionPermission = updateAllowPermissionPermission
  },
  SET_TOP_LEVEL_AREA (state, topLevelAreas) {
    state.topLevelAreas = topLevelAreas
  },
  SET_COMPANY_EMAIL_SUFFIXES (state, companyEmailSuffixes) {
    state.companyEmailSuffixes = companyEmailSuffixes
  },
  SET_ENTITY_STYLES (state, entityStyles) {
    state.entityStyles = entityStyles
  },
  SET_PERMISSION_ASSIGN_TO_CUSTOMER (state, assignToCustomerPermission) {
    state.assignToCustomerPermission = assignToCustomerPermission
  },
  SET_PERMISSION_VIEW_CUSTOMER (state, viewCustomerPermission) {
    state.viewCustomerPermission = viewCustomerPermission
  },
  SET_PERMISSION_CREATE_CUSTOMER (state, createCustomerPermission) {
    state.createCustomerPermission = createCustomerPermission
  },
  SET_PERMISSION_EDIT_CUSTOMER (state, editCustomerPermission) {
    state.editCustomerPermission = editCustomerPermission
  },
  SET_PERMISSION_DELETE_CUSTOMER (state, deleteCustomerPermission) {
    state.deleteCustomerPermission = deleteCustomerPermission
  },
  SET_PERMISSION_VIEW_SUPPORT (state, viewSupportPermission) {
    state.viewSupportPermission = viewSupportPermission
  },
  SET_PERMISSION_VIEW_COMPANY (state, viewCompanyPermission) {
    state.viewCompanyPermission = viewCompanyPermission
  },
  SET_PERMISSION_CREATE_COMPANY (state, createCompanyPermission) {
    state.createCompanyPermission = createCompanyPermission
  },
  SET_PERMISSION_EDIT_COMPANY (state, editCompanyPermission) {
    state.editCompanyPermission = editCompanyPermission
  },
  SET_PERMISSION_DELETE_COMPANY (state, deleteCompanyPermission) {
    state.deleteCompanyPermission = deleteCompanyPermission
  },
  SET_PERMISSION_VIEW_COMMUNICATE_SERVICE (state, viewCommunicateServicePermission) {
    state.viewCommunicateServicePermission = viewCommunicateServicePermission
  },
  SET_PERMISSION_VIEW_SECURE_SERVICE (state, viewSecureServicePermission) {
    state.viewSecureServicePermission = viewSecureServicePermission
  },
  SET_PERMISSION_VIEW_CONNECT_SERVICE (state, viewConnectServicePermission) {
    state.viewConnectServicePermission = viewConnectServicePermission
  },
  SET_PERMISSION_VIEW_NOW_SERVICE (state, viewNowServicePermission) {
    state.viewNowServicePermission = viewNowServicePermission
  },
  SET_PERMISSION_UPLOAD_DASHBOARD_IMAGE (state, uploadDashboardImage) {
    state.uploadDashboardImage = uploadDashboardImage
  },
  SET_PERMISSION_MAINTEL_TESTING (state, maintelTesting) {
    state.maintelTesting = maintelTesting
  },
  SET_PERMISSION_IQUOTE_ADMIN (state, iQuoteAdminPermission) {
    state.iQuoteAdminPermission = iQuoteAdminPermission
  },
  SET_PERMISSION_IQUOTE_TESTER (state, iQuoteTesterPermission) {
    state.iQuoteTesterPermission = iQuoteTesterPermission
  },
  SET_PERMISSION_IQUOTE_COMMERCIALS (state, iQuoteCommercialsPermission) {
    state.iQuoteCommercialsPermission = iQuoteCommercialsPermission
  },
  SET_HAS_JEM_ACCOUNT (state, hasJemAccount) {
    state.hasJemAccount = hasJemAccount
  },
  SET_VIEW_MOBILE_REPORTING (state, viewMobileReporting) {
    state.viewMobileReporting = viewMobileReporting
  },
  SET_UPDATE_TRAVERSE_CONTAINERS (state, updateTraverseContainers) {
    state.updateTraverseContainers = updateTraverseContainers
  }
}

const actions = {
  setNavLinks: ({ commit }, links) => {
    commit('SET_NAV_LINKS', links)
  },
  setPermissions: ({ commit }, permissions) => {
    commit('SET_PERMISSIONS', permissions)
  },
  setAllPermissions: ({ commit }, allPermissions) => {
    commit('SET_ALL_PERMISSIONS', allPermissions)
  },
  setCustomers: ({ commit }, customers) => {
    commit('SET_CUSTOMERS', customers)
  },
  setCompanies: ({ commit }, companies) => {
    commit('SET_COMPANIES', companies)
  },
  setCustomerFlags: ({ commit }, customerFlags) => {
    commit('SET_CUSTOMER_FLAGS', customerFlags)
  },
  setCompanyContainers: ({ commit }, companyContainers) => {
    commit('SET_COMPANY_CONTAINERS', companyContainers)
  },
  setCurrentCompanyParentContainer: ({ commit }, currentCompanyParentContainer) => {
    commit('SET_CURRENT_COMPANY_PARENT_CONTAINER', currentCompanyParentContainer)
  },
  setCurrentCustomer: ({ commit }, currentCustomer) => {
    commit('SET_CURRENT_CUSTOMER', currentCustomer)
  },
  setCurrentCompany: ({ commit }, currentCompany) => {
    commit('SET_CURRENT_COMPANY', currentCompany)
  },
  setAreas: ({ commit }, areas) => {
    commit('SET_AREAS', areas)
  },
  setRoles: ({ commit }, roles) => {
    commit('SET_ROLES', roles)
  },
  setRoleAreas: ({ commit }, roleAreas) => {
    commit('SET_ROLE_AREAS', roleAreas)
  },
  setRoleMembers: ({ commit }, roleMembers) => {
    commit('SET_ROLE_MEMBERS', roleMembers)
  },
  setReloadUsers: ({ commit }, reloadUsers) => {
    commit('RELOAD_USERS', reloadUsers)
  },
  setReloadRoles: ({ commit }, reloadRoles) => {
    commit('RELOAD_ROLES', reloadRoles)
  },
  setReloadPermissions: ({ commit }, reloadPermissions) => {
    commit('RELOAD_PERMISSIONS', reloadPermissions)
  },
  setReloadAreas: ({ commit }, reloadAreas) => {
    commit('RELOAD_AREAS', reloadAreas)
  },
  setReloadMembers: ({ commit }, reloadMembers) => {
    commit('RELOAD_MEMBERS', reloadMembers)
  },
  setMainForm: ({ commit }, openMainForm) => {
    commit('SET_MAINFORM', openMainForm)
  },
  setSubForm: ({ commit }, openSubForm) => {
    commit('SET_SUBFORM', openSubForm)
  },
  setSubForm2: ({ commit }, openSubForm2) => {
    commit('SET_SUBFORM2', openSubForm2)
  },
  setSubForm3: ({ commit }, openSubForm3) => {
    commit('SET_SUBFORM3', openSubForm3)
  },
  setSubForm4: ({ commit }, openSubForm4) => {
    commit('SET_SUBFORM4', openSubForm4)
  },
  setViewiQuotePermission: ({ commit }, viewiQuotePermission) => {
    commit('SET_PERMISSION_VIEW_IQUOTE', viewiQuotePermission)
  },
  setViewTicketsPermission: ({ commit }, viewAreaTicketsPermission) => {
    commit('SET_PERMISSION_VIEW_TICKETS', viewAreaTicketsPermission)
  },
  setUpdateTicketPermission: ({ commit }, updateTicketPermission) => {
    commit('SET_PERMISSION_UPDATE_TICKET', updateTicketPermission)
  },
  setCreateTicketPermission: ({ commit }, createTicketPermission) => {
    commit('SET_PERMISSION_CREATE_TICKET', createTicketPermission)
  },
  setViewAreasPermission: ({ commit }, viewAreasPermission) => {
    commit('SET_PERMISSION_VIEW_AREAS', viewAreasPermission)
  },
  setViewEntityPermission: ({ commit }, viewEntityPermission) => {
    commit('SET_PERMISSION_VIEW_ENTITIES', viewEntityPermission)
  },
  setViewSecurityPermission: ({ commit }, viewSecurityPermission) => {
    commit('SET_PERMISSION_VIEW_SECURITY', viewSecurityPermission)
  },
  setViewRolePermission: ({ commit }, viewRolePermission) => {
    commit('SET_PERMISSION_VIEW_ROLES', viewRolePermission)
  },
  setViewPermissionPermission: ({ commit }, viewPermissionPermission) => {
    commit('SET_PERMISSION_VIEW_PERMISSIONS', viewPermissionPermission)
  },
  setViewRoleAreaPermission: ({ commit }, viewRoleAreaPermission) => {
    commit('SET_PERMISSION_VIEW_ROLE_AREA', viewRoleAreaPermission)
  },
  setViewEntityAreaPermission: ({ commit }, viewEntityAreaPermission) => {
    commit('SET_PERMISSION_VIEW_ENTITY_AREA', viewEntityAreaPermission)
  },
  setViewEntityRolePermission: ({ commit }, viewEntityRolePermission) => {
    commit('SET_PERMISSION_VIEW_ENTITY_ROLE', viewEntityRolePermission)
  },
  setViewAssignedPermissionPermission: ({ commit }, viewAssignedPermissionPermission) => {
    commit('SET_PERMISSION_VIEW_ASSIGNED_PERMISSIONS', viewAssignedPermissionPermission)
  },
  setCreateEntityPermission: ({ commit }, createEntityPermission) => {
    commit('SET_PERMISSION_CREATE_ENTITY', createEntityPermission)
  },
  setDeleteEntityPermission: ({ commit }, deleteEntityPermission) => {
    commit('SET_PERMISSION_DELETE_ENTITY', deleteEntityPermission)
  },
  setUpdateEntityPermission: ({ commit }, updateEntityPermission) => {
    commit('SET_PERMISSION_UPDATE_ENTITY', updateEntityPermission)
  },
  setCreateRolePermission: ({ commit }, createRolePermission) => {
    commit('SET_PERMISSION_CREATE_ROLE', createRolePermission)
  },
  setDeleteRolePermission: ({ commit }, deleteRolePermission) => {
    commit('SET_PERMISSION_DELETE_ROLE', deleteRolePermission)
  },
  setUpdateRolePermission: ({ commit }, updateRolePermission) => {
    commit('SET_PERMISSION_UPDATE_ROLE', updateRolePermission)
  },
  setAssignEntityRolePermission: ({ commit }, assignEntityRolePermission) => {
    commit('SET_PERMISSION_ASSIGN_ENTITY_ROLE', assignEntityRolePermission)
  },
  setRemoveEntityRolePermission: ({ commit }, removeEntityRolePermission) => {
    commit('SET_PERMISSION_REMOVE_ENTITY_ROLE', removeEntityRolePermission)
  },
  setUpdateAreaPermission: ({ commit }, updateAreaPermission) => {
    commit('SET_PERMISSION_UPDATE_AREA', updateAreaPermission)
  },
  setAssignEntityAreaPermission: ({ commit }, assignEntityAreaPermission) => {
    commit('SET_PERMISSION_ASSIGN_ENTITY_AREA', assignEntityAreaPermission)
  },
  setRemoveEntityAreaPermission: ({ commit }, removeEntityAreaPermission) => {
    commit('SET_PERMISSION_REMOVE_ENTITY_AREA', removeEntityAreaPermission)
  },
  setAssignRoleAreaPermission: ({ commit }, assignRoleAreaPermission) => {
    commit('SET_PERMISSION_ASSIGN_ROLE_AREA', assignRoleAreaPermission)
  },
  setRemoveRoleAreaPermission: ({ commit }, removeRoleAreaPermission) => {
    commit('SET_PERMISSION_REMOVE_ROLE_AREA', removeRoleAreaPermission)
  },
  setUpdateGrantPermissionPermission: ({ commit }, updateGrantPermissionPermission) => {
    commit('SET_PERMISSION_UPDATE_GRANT_PERMISSION', updateGrantPermissionPermission)
  },
  setUpdateAllowPermissionPermission: ({ commit }, updateAllowPermissionPermission) => {
    commit('SET_PERMISSION_UPDATE_ALLOW_PERMISSION', updateAllowPermissionPermission)
  },
  setviewQuotesAndProjectsPermission: ({ commit }, viewQuotesAndProjectsPermission) => {
    commit('SET_PERMISSION_VIEW_EXEC_DASHBOARD', viewQuotesAndProjectsPermission)
  },
  setTopLevelArea: ({ commit }, topLevelAreas) => {
    commit('SET_TOP_LEVEL_AREA', topLevelAreas)
  },
  setCompanyEmailSuffixes: ({ commit }, companyEmailSuffixes) => {
    commit('SET_COMPANY_EMAIL_SUFFIXES', companyEmailSuffixes)
  },
  setEntityStyles: ({ commit }, entityStyles) => {
    commit('SET_ENTITY_STYLES', entityStyles)
  },
  setAssignToCustomerPermission: ({ commit }, assignToCustomerPermission) => {
    commit('SET_PERMISSION_ASSIGN_TO_CUSTOMER', assignToCustomerPermission)
  },
  setViewCustomerPermission: ({ commit }, viewCustomerPermission) => {
    commit('SET_PERMISSION_VIEW_CUSTOMER', viewCustomerPermission)
  },
  setCreateCustomerPermission: ({ commit }, createCustomerPermission) => {
    commit('SET_PERMISSION_CREATE_CUSTOMER', createCustomerPermission)
  },
  setEditCustomerPermission: ({ commit }, editCustomerPermission) => {
    commit('SET_PERMISSION_EDIT_CUSTOMER', editCustomerPermission)
  },
  setDeleteCustomerPermission: ({ commit }, deleteCustomerPermission) => {
    commit('SET_PERMISSION_DELETE_CUSTOMER', deleteCustomerPermission)
  },
  setViewSupportPermission: ({ commit }, viewSupportPermission) => {
    commit('SET_PERMISSION_VIEW_SUPPORT', viewSupportPermission)
  },
  setViewCompanyPermission: ({ commit }, viewCompanyPermission) => {
    commit('SET_PERMISSION_VIEW_COMPANY', viewCompanyPermission)
  },
  setCreateCompanyPermission: ({ commit }, createCompanyPermission) => {
    commit('SET_PERMISSION_CREATE_COMPANY', createCompanyPermission)
  },
  setEditCompanyPermission: ({ commit }, editCompanyPermission) => {
    commit('SET_PERMISSION_EDIT_COMPANY', editCompanyPermission)
  },
  setDeleteCompanyPermission: ({ commit }, deleteCompanyPermission) => {
    commit('SET_PERMISSION_DELETE_COMPANY', deleteCompanyPermission)
  },
  setViewCommunicateService: ({ commit }, viewCommunicateServicePermission) => {
    commit('SET_PERMISSION_VIEW_COMMUNICATE_SERVICE', viewCommunicateServicePermission)
  },
  setViewSecureService: ({ commit }, viewSecureServicePermission) => {
    commit('SET_PERMISSION_VIEW_SECURE_SERVICE', viewSecureServicePermission)
  },
  setViewConnectService: ({ commit }, viewConnectServicePermission) => {
    commit('SET_PERMISSION_VIEW_CONNECT_SERVICE', viewConnectServicePermission)
  },
  setViewNowService: ({ commit }, viewNowServicePermission) => {
    commit('SET_PERMISSION_VIEW_NOW_SERVICE', viewNowServicePermission)
  },
  setUploadDashboardImage: ({ commit }, uploadDashboardImage) => {
    commit('SET_PERMISSION_UPLOAD_DASHBOARD_IMAGE', uploadDashboardImage)
  },
  setMaintelTesting: ({ commit }, maintelTesting) => {
    commit('SET_PERMISSION_MAINTEL_TESTING', maintelTesting)
  },
  setIQuoteAdminPermission: ({ commit }, iQuoteAdminPermission) => {
    commit('SET_PERMISSION_IQUOTE_ADMIN', iQuoteAdminPermission)
  },
  setIQuoteTesterPermission: ({ commit }, iQuoteTesterPermission) => {
    commit('SET_PERMISSION_IQUOTE_TESTER', iQuoteTesterPermission)
  },
  setIQuoteCommercialsPermission: ({ commit }, iQuoteCommercialsPermission) => {
    commit('SET_PERMISSION_IQUOTE_COMMERCIALS', iQuoteCommercialsPermission)
  },
  setHasJemAccount: ({ commit }, hasJemAccount) => {
    commit('SET_HAS_JEM_ACCCOUNT', hasJemAccount)
  },
  setViewMobileReporting: ({ commit }, viewMobileReporting) => {
    commit('SET_VIEW_MOBILE_REPORTING', viewMobileReporting)
  },
  setUpdateTraverseContainers: ({ commit }, updateTraverseContainers) => {
    commit('SET_UPDATE_TRAVERSE_CONTAINERS', updateTraverseContainers)
  },
  setNavigation () {
      var nLinks = []
    if (state.viewEntityPermission) {
      nLinks.push({ to: '/user-management', icon: 'mdi-account-settings', text: 'User Management' })
    }
    if (state.viewSecurityPermission) {
      nLinks.push({ to: '/security', icon: 'mdi-lock', text: 'Security' })
    }
    if (state.viewSupportPermission && (state.createCustomerPermission || state.uploadDashboardImage || state.deleteEntityPermission || this.createEntityPermission || this.updateTraverseContainers)) {
      nLinks.push({ to: '/support', icon: 'mdi-headset', text: 'Support' })
    }
    if (state.viewiQuotePermission) {
      nLinks.push({ to: '/iquote', icon: 'mdi-information-outline', text: 'iQuote' })
    }

    nLinks.push({ to: '/logout', icon: 'mdi-logout', text: 'Log Out' })
    state.navLinks = nLinks
  },
  setPermissionAction (i, cmd) {
    var action = cmd.action
    var val = cmd.value
    // console.log('setPermissionAction: ' + action + ' with value: ' + val)
    if (action === 'viewAreaTickets') {
      state.viewAreaTicketsPermission = val
    } else if (action === 'viewArea') {
      state.viewAreasPermission = val
    } else if (action === 'viewEntity') {
      state.viewEntityPermission = val
    } else if (action === 'viewRole') {
      state.viewRolePermission = val
    } else if (action === 'viewPermission') {
      state.viewPermissionPermission = val
    } else if (action === 'viewRoleArea') {
      state.viewRoleAreaPermission = val
    } else if (action === 'viewEntityRole') {
      state.viewEntityRolePermission = val
    } else if (action === 'viewEntityArea') {
      state.viewEntityAreaPermission = val
    } else if (action === 'viewAssignedPermission') {
      state.viewAssignedPermissionPermission = val
    } else if (action === 'createTicket') {
      state.createTicketPermission = val
    } else if (action === 'updateTicket') {
      state.updateTicketPermission = val
    } else if (action === 'viewiQuote') {
      state.viewiQuotePermission = val
    } else if (action === 'createEntity') {
      state.createEntityPermission = val
    } else if (action === 'deleteEntity') {
      state.deleteEntityPermission = val
    } else if (action === 'updateEntity') {
      state.updateEntityPermission = val
    } else if (action === 'createRole') {
      state.createRolePermission = val
    } else if (action === 'deleteRole') {
      state.deleteRolePermission = val
    } else if (action === 'updateRole') {
      state.updateRolePermission = val
    } else if (action === 'assignEntityRole') {
      state.assignEntityRolePermission = val
    } else if (action === 'removeEntityRole') {
      state.removeEntityRolePermission = val
    } else if (action === 'updateArea') {
      state.updateAreaPermission = val
    } else if (action === 'assignEntityArea') {
      state.assignEntityAreaPermission = val
    } else if (action === 'removeEntityArea') {
      state.removeEntityAreaPermission = val
    } else if (action === 'assignRoleArea') {
      state.assignRoleAreaPermission = val
    } else if (action === 'removeRoleArea') {
      state.removeRoleAreaPermission = val
    } else if (action === 'updateGrantPermission') {
      state.updateGrantPermissionPermission = val
    } else if (action === 'updateAllowPermission') {
      state.updateAllowPermissionPermission = val
    } else if (action === 'viewQuotesAndProjects') {
      state.viewQuotesAndProjectsPermission = val
    } else if (action === 'assignToCustomer') {
      state.assignToCustomerPermission = val
    } else if (action === 'viewCustomer') {
      state.viewCustomerPermission = val
    } else if (action === 'createCustomer') {
      state.createCustomerPermission = val
    } else if (action === 'editCustomer') {
      state.editCustomerPermission = val
    } else if (action === 'deleteCustomer') {
      state.deleteCustomerPermission = val
    } else if (action === 'viewSupport') {
      state.viewSupportPermission = val
    } else if (action === 'viewCompany') {
      state.viewCompanyPermission = val
    } else if (action === 'createCompany') {
      state.createCompanyPermission = val
    } else if (action === 'editCompany') {
      state.editCompanyPermission = val
    } else if (action === 'deleteCompany') {
      state.deleteCompanyPermission = val
    } else if (action === 'viewCommunicateService') {
      state.viewCommunicateServicePermission = val
    } else if (action === 'viewSecureService') {
      state.viewSecureServicePermission = val
    } else if (action === 'viewConnectService') {
      state.viewConnectServicePermission = val
    } else if (action === 'viewNowService') {
      state.viewNowServicePermission = val
    } else if (action === 'uploadDashboardImage') {
      state.uploadDashboardImage = val
    } else if (action === 'maintelTesting') {
      state.maintelTesting = val
    } else if (action === 'iQuoteAdmin') {
      state.iQuoteAdminPermission = val
    } else if (action === 'iQuoteTest') {
      state.iQuoteTesterPermission = val
    } else if (action === 'iQuoteCommercial') {
      state.iQuoteCommercialsPermission = val
    } else if (action === 'hasJemAccount') {
      state.hasJemAccount = val
    } else if (action === 'viewMobileReports') {
      state.viewMobileReporting = val
    } else if (action === 'updateTraverseContainers') {
      state.updateTraverseContainers = val
    }
  },
  clearPermissions () {
    state.viewAreaTicketsPermission = false
    state.viewAreasPermission = false
    state.viewEntityPermission = false
    state.viewRolePermission = false
    state.viewPermissionPermission = false
    state.viewRoleAreaPermission = false
    state.viewEntityRolePermission = false
    state.viewEntityAreaPermission = false
    state.viewAssignedPermissionPermission = false
    state.updateTicketPermission = false
    state.createTicketPermission = false
    state.viewiQuotePermission = false
    state.createEntityPermission = false
    state.deleteEntityPermission = false
    state.updateEntityPermission = false
    state.createRolePermission = false
    state.deleteRolePermission = false
    state.updateRolePermission = false
    state.assignEntityRolePermission = false
    state.removeEntityRolePermission = false
    state.updateAreaPermission = false
    state.assignEntityAreaPermission = false
    state.removeEntityAreaPermission = false
    state.assignRoleAreaPermission = false
    state.removeRoleAreaPermission = false
    state.updateGrantPermissionPermission = false
    state.updateAllowPermissionPermission = false
    state.viewQuotesAndProjectsPermission = false
    state.assignToCustomerPermission = false
    state.viewCustomerPermission = false
    state.createCustomerPermission = false
    state.editCustomerPermission = false
    state.deleteCustomerPermission = false
    state.viewSupportPermission = false
    state.viewCompanyPermission = false
    state.createCompanyPermission = false
    state.editCompanyPermission = false
    state.deleteCompanyPermission = false
    state.viewCommunicateServicePermission = false
    state.viewSecureServicePermission = false
    state.viewConnectServicePermission = false
    state.viewNowServicePermission = false
    state.uploadDashboardImage = false
    state.maintelTesting = false
    state.iQuoteAdminPermission = false
    state.iQuoteTesterPermission = false
    state.iQuoteCommercialsPermission = false
    state.hasJemAccount = false
    state.viewMobileReporting = false
    state.updateTraverseContainers = false
  },
  clearCustomers: ({ commit }) => {
    commit('SET_CUSTOMERS', [])
    commit('SET_CUSTOMER_FLAGS', [])
    commit('SET_CURRENT_CUSTOMER', {})
    commit('SET_COMPANIES', [])
    commit('SET_CURRENT_COMPANY', [])
  }
}

const getters = {
  navLinks: state => {
    return state.navLinks
  },
  areas: state => {
    return state.areas
  },
  roles: state => {
    return state.roles
  },
  roleAreas: state => {
    return state.roleAreas
  },
  roleMembers: state => {
    return state.roleMembers
  },
  permissions: state => {
    return state.permissions
  },
  allPermissions: state => {
    return state.allPermissions
  },
  customers: state => {
    return state.customers
  },
  companies: state => {
    return state.companies
  },
  customerFlags: state => {
    return state.customerFlags
  },
  currentCompanyParentContainer: state => {
    return state.currentCompanyParentContainer
  },
  companyContainers: state => {
    return state.companyContainers
  },
  currentCustomer: state => {
    return state.currentCustomer
  },
  currentCompany: state => {
    return state.currentCompany
  },
  reloadUsers: state => {
    return state.reloadUsers
  },
  reloadRoles: state => {
    return state.reloadRoles
  },
  reloadPermissions: state => {
    return state.reloadPermissions
  },
  reloadAreas: state => {
    return state.reloadAreas
  },
  reloadMembers: state => {
    return state.reloadMembers
  },
  openMainForm: state => {
    return state.openMainForm
  },
  openSubForm: state => {
    return state.openSubForm
  },
  openSubForm2: state => {
    return state.openSubForm2
  },
  openSubForm3: state => {
    return state.openSubForm3
  },
  openSubForm4: state => {
    return state.openSubForm4
  },
  viewiQuotePermission: state => {
    return state.viewiQuotePermission
  },
  viewAreaTicketsPermission: state => {
    return state.viewAreaTicketsPermission
  },
  updateTicketPermission: state => {
    return state.updateTicketPermission
  },
  createTicketPermission: state => {
    return state.createTicketPermission
  },
  viewAreasPermission: state => {
    return state.viewAreasPermission
  },
  viewEntityPermission: state => {
    return state.viewEntityPermission
  },
  viewSecurityPermission: state => {
    return state.viewSecurityPermission
  },
  viewRolePermission: state => {
    return state.viewRolePermission
  },
  viewPermissionPermission: state => {
    return state.viewPermissionPermission
  },
  viewRoleAreaPermission: state => {
    return state.viewRoleAreaPermission
  },
  viewEntityAreaPermission: state => {
    return state.viewEntityAreaPermission
  },
  viewEntityRolePermission: state => {
    return state.viewEntityRolePermission
  },
  viewAssignedPermissionPermission: state => {
    return state.viewAssignedPermissionPermission
  },
  viewQuotesAndProjectsPermission: state => {
    return state.viewQuotesAndProjectsPermission
  },
  createEntityPermission: state => {
    return state.createEntityPermission
  },
  deleteEntityPermission: state => {
    return state.deleteEntityPermission
  },
  updateEntityPermission: state => {
    return state.updateEntityPermission
  },
  createRolePermission: state => {
    return state.createRolePermission
  },
  deleteRolePermission: state => {
    return state.deleteRolePermission
  },
  updateRolePermission: state => {
    return state.updateRolePermission
  },
  assignEntityRolePermission: state => {
    return state.assignEntityRolePermission
  },
  removeEntityRolePermission: state => {
    return state.removeEntityRolePermission
  },
  updateAreaPermission: state => {
    return state.updateAreaPermission
  },
  assignEntityAreaPermission: state => {
    return state.assignEntityAreaPermission
  },
  removeEntityAreaPermission: state => {
    return state.removeEntityAreaPermission
  },
  assignRoleAreaPermission: state => {
    return state.assignRoleAreaPermission
  },
  removeRoleAreaPermission: state => {
    return state.removeRoleAreaPermission
  },
  updateGrantPermissionPermission: state => {
    return state.updateGrantPermissionPermission
  },
  updateAllowPermissionPermission: state => {
    return state.updateAllowPermissionPermission
  },
  topLevelAreas: state => {
    return state.topLevelAreas
  },
  companyEmailSuffixes: state => {
    return state.companyEmailSuffixes
  },
  entityStyles: state => {
    return state.entityStyles
  },
  assignToCustomerPermission: state => {
    return state.assignToCustomerPermission
  },
  viewCustomerPermission: state => {
    return state.viewCustomerPermission
  },
  createCustomerPermission: state => {
    return state.createCustomerPermission
  },
  editCustomerPermission: state => {
    return state.editCustomerPermission
  },
  deleteCustomerPermission: state => {
    return state.deleteCustomerPermission
  },
  viewSupportPermission: state => {
    return state.viewSupportPermission
  },
  viewCompanyPermission: state => {
    return state.viewCompanyPermission
  },
  createCompanyPermission: state => {
    return state.createCompanyPermission
  },
  editCompanyPermission: state => {
    return state.editCompanyPermission
  },
  deleteCompanyPermission: state => {
    return state.deleteCompanyPermission
  },
  viewCommunicateServicePermission: state => {
    return state.viewCommunicateServicePermission
  },
  viewSecureServicePermission: state => {
    return state.viewSecureServicePermission
  },
  viewConnectServicePermission: state => {
    return state.viewConnectServicePermission
  },
  viewNowServicePermission: state => {
    return state.viewNowServicePermission
  },
  uploadDashboardImage: state => {
    return state.uploadDashboardImage
  },
  maintelTesting: state => {
    return state.maintelTesting
  },
  iQuoteAdminPermission: state => {
    return state.iQuoteAdminPermission
  },
  iQuoteTesterPermission: state => {
    return state.iQuoteTesterPermission
  },
  iQuoteCommercialsPermission: state => {
    return state.iQuoteCommercialsPermission
  },
  hasJemAccount: state => {
    return state.hasJemAccount
  },
  viewMobileReporting: state => {
    return state.viewMobileReporting
  },
  updateTraverseContainers: state => {
    return state.updateTraverseContainers
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
