// This module is used for state mamangement for authentication related variables, more modules will be introduced one
// there are more areas of state which need to be managed.

const state = {
  // Tokens are used for the authentication of the current user
  tokens: {
    token: '',
    refreshToken: ''
  },
  // Username is stored separately from the tokens as they may be used externally from a token and refresh (i.e. to check multiple failed logons)
  username: '',
  userid: -1,
  userEmail: '',

  // Mounted is being used in here to help with animations, thus once something has loaded it will mount and play the animation (this is in auth as mounted is only used on logon)
  mounted: false,
  loggedOff: false,
  loggedOn: false
}
const mutations = {
  SET_TOKENS (state, tokens) {
    state.tokens = tokens
  },
  SET_USERNAME (state, username) {
    // Always store the username in lowercase to avoid having to use ToLowerCase() everytime!
    state.username = username ? username.toLowerCase() : null
  },
  SET_USERID (state, userid) {
    state.userid = userid
  },
  SET_USEREMAIL (state, userEmail) {
    state.userEmail = userEmail
  },
  SET_MOUNTED (state, mounted) {
    state.mounted = mounted
  },
  SET_LOGGEDON (state, loggedOn) {
    state.loggedOn = loggedOn
  },
  SET_LOGGEDOFF (state, loggedOff) {
    state.loggedOff = loggedOff
  }
}
const actions = {
  setTokens: ({ commit }, tokens) => {
    commit('SET_TOKENS', tokens)
    if (tokens.token) {
      localStorage.setItem('token', tokens.token)
    }
    if (tokens.jwt) {
      localStorage.setItem('token', tokens.jwt)
    }
    localStorage.setItem('refreshToken', tokens.refreshToken)
  },
  setUsername: ({ commit }, username) => {
    commit('SET_USERNAME', username)
    localStorage.setItem('username', username)
  },
  setUserid: ({ commit }, userid) => {
    commit('SET_USERID', userid)
    localStorage.setItem('userid', userid)
  },
  setUserEmail: ({ commit }, userEmail) => {
    commit('SET_USEREMAIL', userEmail)
    localStorage.setItem('userEmail', userEmail)
  },
  setMounted: ({ commit }, mounted) => {
    commit('SET_MOUNTED', mounted)
  },
  clearAuth: ({ commit }) => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userid')
    localStorage.removeItem('username')
    localStorage.removeItem('userEmail')
    commit('SET_TOKENS', null)
    commit('SET_USERID', -1)
    commit('SET_USERNAME', null)
    commit('SET_USEREMAIL', null)
    commit('SET_MOUNTED', false)
    commit('SET_LOGGEDOFF', false)
    commit('SET_LOGGEDON', false)
  },
  logOff: ({ commit }) => {
    setTimeout(function () {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userid')
      localStorage.removeItem('username')
      localStorage.removeItem('userEmail')
      commit('SET_TOKENS', null)
      commit('SET_USERID', -1)
      commit('SET_USERNAME', null)
      commit('SET_USEREMAIL', null)
      commit('SET_MOUNTED', false)
      commit('SET_LOGGEDOFF', false)
      commit('SET_LOGGEDON', false)
    }, 100)
    commit('SET_LOGGEDOFF', true)
  },
  setLoggedOn: ({ commit }, loggedOn) => {
    commit('SET_LOGGEDON', loggedOn)
  }
}

const getters = {
  tokens: state => {
    return state.tokens
  },
  username: state => {
    return state.username
  },
  userid: state => {
    return state.userid
  },
  userEmail: state => {
    return state.userEmail
  },
  loggedOn: state => {
    return state.loggedOn
  },
  loggedOff: state => {
    return state.loggedOff
  },
  mounted: state => {
    return !!state.mounted
  },
  isAdmin: state => { // Validating if a user has an admin role, this will be modified once we have permissions entered in the system.  Hence the resource hungry atob each time this is called.  In future this will happen at logon once and stored in localaised state
    if (state.tokens && state.tokens.length > 0) {
      const jwtPayload = atob(state.tokens.token.split('.')[1])
      if (jwtPayload && jwtPayload !== '') {
        const jwtPayloadObject = JSON.parse(jwtPayload)
        if (jwtPayloadObject.role && jwtPayloadObject.role.toLowerCase() === 'admin') {
          return true
        }
      }
    }
    return false
  }
}
// This export is used to allow the vuex components to extend ito the global state
export default {
  state,
  mutations,
  actions,
  getters
}
