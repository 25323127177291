<template>
  <div>
    <Spinner v-if="isLoading" />
    <router-link
      v-ripple
      v-if="!isLoading"
      class="toolbar-items"
      to="tickets">
      <material-stats-card
        v-if="!isLoading"
        :value="newtickets"
        color="green"
        icon="mdi-ticket-confirmation"
        title="New Tickets"
        sub-icon="mdi-calendar"
        sub-text="Last 24 Hours"
        style="margin-bottom: 35px!important;"
      />
    </router-link>
  </div>
</template>

<script>
import { DataEventBus } from '../../../events/dataEvents'
import Spinner from '../../common/spinner'

export default {
  name: 'NewTicketsTile',
  components: {
    Spinner
  },
  data () {
    return {
      newtickets: '0',
      isLoading: false
    }
  },
  mounted () {
    DataEventBus.$on('load-dashboard', (state) => {
      this.populateNewTickets()
    })
    DataEventBus.$on('tickets-loaded', () => {
      this.populateNewTickets()
    })
    this.populateNewTickets()
  },
  methods: {
    populateNewTickets () {
      this.isLoading = true
      if (this.$store.getters.tickets) {
        this.newtickets = this.$store.getters.tickets.filter(t => t.statusId === 1).length.toString()
      }
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
  .v-card__text { padding-bottom: 6px;}
</style>
