<template>
  <div
    id="nowServiceOverview"
  >
    <div
      v-if="viewNowServicePermission"
    >
      <Spinner
        v-if="loading"
      />
      <div
        v-else
      >
        <table
          id="serviceSummaryTableNow"
          class="serviceSummaryTableNow"
        >
          <thead />
          <tbody>
            <tr
              v-for="({ containerSerialNumber, containerName, statusName, departmentSerialNumber }) in currentFilter"
              :key="`${containerSerialNumber}${departmentSerialNumber}`"
              :class="serviceStatusColour(statusName)"
              @click="() => {
                selectedContainer = containerName
                loadStatuses(containerName)
              }"
            >
              <td
                id="containerName"
                :title="containerName"
              >
                {{ containerName }}
              </td>
              <td
                :title="statusName"
              >
                <div
                  :class="serviceStatusColour(statusName)"
                  :title="statusName"
                  class="lozenge"
                >
                  {{ statusName }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="selectedContainer"
          id="selectedContainerDetails"
        >
          <h3>
            {{ selectedContainer }}
          </h3>
          <material-stats-card
            v-for="({ id, color, icon, title }) in statusData"
            :key="color+icon+title"
            :id="id"
            :title="title"
            :color="color"
            :icon="icon"
            style="margin: 35px 0 !important;"
          />
          <div
            class="healthHistoryBar"
          >
            <div
              v-for="({ healthStatus, index }) in selectedContainerHealthHistory.severities.map((healthStatus,index) => ({ healthStatus, index }))"
              :key="index"
              :class="loadStatusColor(healthStatus)"
              :title="parseStatus(healthStatus)"
              class="healthHistorySegment"
            />
          </div>
          <p
            class="healthHistoryDetails"
          >
            Health History {{ selectedContainerHealthHistoryRange }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="noResults"
    >
      Sorry, you do not have permission to view the service status page
    </div>
  </div>
</template>

<script>

import Spinner from '../common/spinner'
import tableHandler from '../../mixins/tableHandler'
import { DataEventBus } from '../../events/dataEvents'
import { traverseMixin } from '../../mixins/traverse'

export default {
  components: {
    Spinner
  },
  mixins: [
    tableHandler,
    traverseMixin
  ],
  props: {
  },
  data () {
    return {
      loading: false,
      data: [],
      statusData: [],
      containerHealthSeries: [],
      selectedContainer: ''
    }
  },
  computed: {
    traverseIcon () {
      return require(`../../assets/icons/TraverseIcon.png`)
    },
    companyContainers () {
      return this.$store.getters.companyContainers
    },
    viewNowServicePermission () {
      return this.$store.getters.viewNowServicePermission
    },
    selectedContainerHealthHistory () {
      return this.companyContainers.find(({ containerName }) => containerName === this.selectedContainer).containerHealthHistory
    },
    selectedContainerHealthHistoryRange () {
      return `(${new Date(this.selectedContainerHealthHistory.startTime).toUTCString()} to ${new Date(this.selectedContainerHealthHistory.endTime).toUTCString()})`
    }
  },
  watch: {
    companyContainers () {
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
    DataEventBus.$on('company-changed', (customer) => {
      this.loadData()
    })
    DataEventBus.$on('refresh-data', () => {
      this.loadData()
    })
  },
  created () {
  },
  methods: {
    serviceStatusColour: function (statusName) {
      switch (statusName ? statusName.toLowerCase() : '') {
        case 'critical':
          return 'Red'
        case 'ok':
          return 'Green'
        case 'warning':
          return 'Amber'
        default:
          return 'Grey'
      }
    },
    loadData: function () {
      this.loading = true
      this.resetData()
      if (this.companyContainers.length > 0) {
        this.$nextTick(() => {
          this.initialiseTable('serviceSummaryTableNow',
            this.companyContainers,
            ['containerName', 'statusName'],
            ['Name', 'Status'],
            ['', ''],
            null,
            ['70%', '30%']
          )
        })
      }
      this.loading = false
    },
    resetData () {
      this.initialiseTable('serviceSummaryTableNow',
        [],
        [],
        [],
        [],
        null,
        []
      )
    },
    loadStatuses (service) {
      var container = this.companyContainers.find(({ containerName }) => containerName.toLowerCase() === service.toLowerCase())
      this.statusData = [
        {
          id: 'networkStatus',
          title: `Network Status (${this.parseStatus(container.networkStatus)})`,
          color: this.loadStatusColor(container.networkStatus),
          icon: this.loadStatusIcon(container.networkStatus)
        },
        {
          id: 'systemStatus',
          title: `System Status (${this.parseStatus(container.systemStatus)})`,
          color: this.loadStatusColor(container.systemStatus),
          icon: this.loadStatusIcon(container.systemStatus)
        },
        {
          id: 'applicationStatus',
          title: `Application Status (${this.parseStatus(container.applicationStatus)})`,
          color: this.loadStatusColor(container.applicationStatus),
          icon: this.loadStatusIcon(container.applicationStatus)
        }
      ]
    },
    loadStatusColor (status) {
      switch (status) {
        case 2048: // OK
          return 'green'
        case 134217728: // Critical
          return 'red'
        case 524288: // Unreachable
          return 'grey'
        case 8: // Not configured
          return 'grey'
        case 128: // Suspended
          return 'blue'
        case 8388608: // Warning
          return 'amber'
      }
    },
    loadStatusIcon (status) {
      switch (status) {
        case 2048: // OK
          return 'mdi-check'
        case 134217728: // Critical
          return '!!'
        case 524288: // Unreachable
          return 'mdi-ethernet-cable-off'
        case 8: // Not configured
          return '?'
        case 128: // Suspended
          return '!!'
        case 8388608: // Warning
          return 'mdi-exclamation'
      }
    },
    parseStatus (status) {
      switch (status) {
        case 2048: // OK
          return 'OK'
        case 134217728: // Critical
          return 'Critical'
        case 524288: // Unreachable
          return 'Unreachable'
        case 8: // Not configured
          return 'Not Configured'
        case 128: // Suspended
          return 'Suspended'
        case 8388608: // Warning
          return 'Warning'
      }
    }
  }
}
</script>

<style scoped>
  .openIcon {
    width: 20px;
    height: 20px;
  }
  .appSectionContainer {
    min-height: 100%;
  }

  h3 {
    cursor: pointer;
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0 0 0.5vw 0;
    color: #fff;
  }

  .lozenge {
    border-radius: 20px;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .Red {
    background-color: rgb(239, 83, 80);
  }

  .Amber {
    background-color: rgb(255, 167, 38);
  }

  .Green {
    background-color: rgb(102, 187, 106);
  }

  .Grey {
    background-color: rgb(153, 153, 153);
  }

  .Purple {
    background-color: rgb(167, 80, 239);
  }

  .iconLink {
    text-align: center; padding: 5px 5px 0 0;
  }

  .serviceSummaryTableNow {
    margin-bottom: 40px;
  }

  .healthHistoryBar {
    display:flex;
    min-width: 24vw;
    height: 20px;
    max-width:24;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow-x:hidden;
  }

  .healthHistorySegment {
    flex:1;
    margin-left: 1px;
  }

  .healthHistoryDetails {
    margin-top: 0.5vh;
    font-size: 0.7vw;
    text-align: center;
  }

</style>
