<template>
  <div
    v-if="$store.getters.openMainForm"
    class="roleNewBackground"
  >
    <div class="roleNewHolder">
      <div class="roleTableholder">
        <div class="roleNewTitle">New Role</div>
        <div class="newRoleFieldHolder">
          <label
            for="txtRoleName"
            class="newRoleLabel"
          >Role Name</label>
          <input
            id="txtRoleName"
            ref="roleName"
            v-model="role.Name"
            type="text"
            class="newRoleTextbox"
            @blur="checkForm"
          >
          <label
            for="txtDescription"
            class="newRoleLabel"
          >Description</label>
          <input
            id="txtRoleDescription"
            v-model="role.Description"
            type="text"
            class="newRoleTextbox"
            @blur="checkForm"
          >
          <label
            for="cboArea"
            class="newRoleLabel"
          >Default Site</label>
          <select
            id="cboArea"
            ref="cboArea"
            class="newRoleSelect"
            @blur="checkForm"
          >
            <option
              v-for="m in areas"
              :key="m.id"
              :value="m.id"
            >{{ m.name }}</option>
          </select>
        </div>
      </div>
      <input
        id="roleCancelNewButton"
        type="button"
        class="roleCancelNewButton"
        value="Cancel"
        title="Cancel the add operation"
        @click="closeForm()"
      >
      <input
        id="roleAddNewButton"
        :class="getButtonClass"
        type="button"
        value="Add Role"
        title="Adds the new role"
        @click="addNewRole()"
      >
      <input
        type="text"
        class="focusField"
        @focus="cycleFocus"
      >
    </div>
  </div>
</template>
<script>
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'RoleNew',
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      members: [],
      areas: [],
      formOK: false,
      role: {
        id: -1,
        Name: '',
        Description: ''
      }
    }
  },
  computed: {
    ...mapState([
      'openMainForm'
    ]),
    getButtonClass () {
      if (this.formOK) {
        return 'roleAddNewButtonEnabled'
      } else {
        return 'roleAddNewButton'
      }
    }
  },
  created () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setMainForm'
    ]),
    setComponent () {
      this.populateAreas()
    },
    populateAreas: async function () {
      var rtn = await this.getUserAreas(this.$store.getters.userid)
      this.areas = rtn
    },
    closeForm () {
      this.setMainForm(false)
    },
    addNewRole: async function () {
      if (this.formOK) {
        this.role = await this.addRole(this.role)
        if (this.role.id !== undefined) {
          // We now need to make sure the role is available to the user who created it by
          // adding it to the selected area
          var area = this.$refs['cboArea'][this.$refs['cboArea'].selectedIndex].value
          var rtn = await this.addRoleArea(this.role.id, area)
          if (rtn) {
            this.rolesGet().then(() => {
              this.closeForm()
            })
          }
        }
      }
    },
    checkForm () {
      var roleNameError = !this.role.Name || this.role.Name.length < 2
      var roleDescriptionError = !this.role.Description

      this.formOK = !roleNameError && !roleDescriptionError
    },
    cycleFocus () {
      if (this.$refs['roleName']) {
        this.$refs['roleName'].focus()
      }
    }
  }
}

</script>
<style scoped>
  .roleNewBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .roleNewHolder {
    display: inline-block;
    background-color: rgba(240,240,240,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 160px);
    top: calc(50% - 140px);
    height: 320px;
    width: 360px;
    z-index: 999;
  }
  .roleNewTitle {
    padding:10px;
    background-color: rgba(44,62,80,0.7);
    color: #fff;
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
  }
  .newRoleFieldHolder {
    padding:10px;
  }
  .newRoleLabel {
    display: inline-block;
    width: 100px;
  }
  .newRoleTextbox {
    display: inline-block;
    width: calc(100% - 100px);
    line-height: 1.4em;
    margin-bottom: 8px;
    border: solid 1px #000;
    padding: 0 5px;
  }
  .roleCancelNewButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .roleAddNewButton {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    opacity: 0.5;
    cursor: default;
  }
  .roleAddNewButtonEnabled {
    margin: 10px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .newRoleSelect {
    display: inline-block;
    border: solid 1px #000;
    margin-bottom: 8px;
    width: calc(100% - 100px);
  }
  .roleTableholder {
    overflow: auto;
    height:254px;
    max-height: 300px;
  }
  .focusField {
    position: absolute;
    left:0;
    bottom:0;
    width:0;
    opacity:0;
    background-color: rgba(240,240,240,1);
  }
</style>
