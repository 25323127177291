<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label>iQuote</label>
      <br>
      <label for="txtReferenceNo">iQuote Reference Number:</label>
      <input
        id="txtReferenceNo"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <label for="txtDescription">Description:</label>
      <input
        id="txtDescription"
        :value="quote.quoteDescription"
        type="text"
        readonly="true"
      >
      <br>
      <label for="datePublished">Published on the </label>
      <input
        id="datePublished"
        :value="quoteDatePublished"
        type="date"
        readonly="true"
      >
      <label for="dateExpires">Expires on the </label>
      <input
        id="dateExpires"
        :value="expires"
        type="date"
        readonly="true"
      >
      <br><br>
    </div>
    <br>
    <input
      id="btnBack"
      value="< Back"
      type="button"
      @click="gotoPage('Review')"
    >
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Published',
  mixins: [],
  data () {
    return {
      quote: {
        id: 0,
        quoteDescription: null,
        datePublished: null,
        expires: null
      }
    }
  },
  computed: {
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    expires () {
      return new Date(this.quote.datePublished + (30 * 24 * 60 * 60 * 1000))
    },
    quoteDatePublished () {
      if (this.quote.datePublished === undefined || this.quote.datePublished === '0001-01-01T00:00:00') {
        return null
      } else {
        return new Date(this.quote.datePublished)
      }
    }
  },
  mounted () {
    this.quote = this.$store.getters.quote
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),

    gotoPage (page) {
      this.setCurrentQuoteState(page)
    }
  }
}
</script>
<style scoped>
  .small{width:60px;}
</style>
