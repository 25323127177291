<template>
  <div
    class="adcHolder"
  >
    <p class="adcText"> To reject <b>{{ request.firstName }} {{ request.lastName }}</b>, press the reject button below:</p>

    <input
      :disabled="!selectedUser"
      value="Reject"
      type="button"
      title="Reject access request"
      class="adcButton customerUpdateButton"
      @click="rejectRequest()"
    >
  </div>
</template>
<script>

import { usersMixin } from '../../../mixins/users'
import { adminMixin } from '../../../mixins/adminMixin'

export default {
  mixins: [
    usersMixin,
    adminMixin
  ],
  props: {
    request: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedUser: {}
    }
  },
  methods: {
    rejectRequest: async function () {
      var rtn = await this.deleteRequestAccessUser(this.request.emailAddress)
      if (rtn.length === 0) {
        this.$store.commit('showMessage', { content: 'The access request for ' + this.request.emailAddress + ' was rejected successfully.', color: 'green', timeout: 3000 })
      } else {
        this.$store.commit('showMessage', { content: 'An error occurred rejecting the access request for ' + this.request.emailAddress + ': ' + rtn, color: 'red', timeout: 5000 })
      }
      this.$emit('updated')
    }
  }
}
</script>
<style scoped>
  .adcButton{
    width:140px;
    border: solid 1px grey;
    color: grey;
  }
  .adcSubHeader{
    padding-left:10px;
    display: inline-block;
    width: 220px;
  }
  .adcHolder {
    background-color: rgba(255,255,255,0.7);
    margin-bottom: 10px;
    padding: 10px 0;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    padding: 10px 5px;
    display: none;
    text-align: right;
    width: 100%;
  }
  .adcText {
    text-align: left;
  }
  .customerOnboardingDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .customerOnboardingDetailInput {
    width: 254px;
    display: inline-block;
    margin:0 0 10px 10px;
  }
  .customerUpdateButton {
    cursor: pointer;
  }
</style>
