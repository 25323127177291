import portalAPIGateway from '../../communications/interceptors/apiInterceptor'

export const iQuoteQuoteVMsRequiredAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteQuoteVMsRequiredAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    saveQuoteVMsRequiredToDB: async function () {
      var axiosConfig = this.iQuoteQuoteVMsRequiredAPIMixin_getAxiosConfig()

      var quoteId = this.$store.getters.quote.id
      var vmsRequired = this.$store.getters.quoteVMsRequired

      try {
        await portalAPIGateway.post('iquote/quoteVMsRequired/' + quoteId, vmsRequired, axiosConfig).then(() => {
          return true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('saveQuoteVMsRequiredToDB failed!')
      }
    }
  }
}
