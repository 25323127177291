<template>
  <div style="padding-top:30px;">
    <div
      v-if="!createCompanyPermission || !deleteCompanyPermission || !createCustomerPermission || !deleteCustomerPermission"
      class="noResults"
    >
      Sorry, you do not have permission to merge companies and customers
    </div>
    <div
      v-else
      class="mergeSectionData"
    >
      <div
        id="CreateCustomer"
        class="mergeCustomerHolder"
      >
        <div
          :class="{parentMergeCustomerForm: showMergeCompanySection}"
        >
          <h3>
            Merge Companies
            <span class="statuscontainer">
              <span
                :class="[formOK ? 'completeSection' : 'incompleteSection']"
                :title="[formOK ? 'Section Complete' : 'Section Incomplete']"
              />
              <span class="background" />
            </span>
          </h3>
          <div
            style="border:solid grey 1px;"
          >
            <div class="mainCompany">
              <label
                for="cboMainCompany"
                class="createCustomerLabel"
              > Main company to merge into</label>
              <PortalDropdown
                id="cboMainCompany"
                :options="companies"
                :disabled="false"
                :max-item="999999"
                :class="{ errorHighlight: companySelectError }"
                :attach-blur-event="false"
                placeholder="Select main company"
                style="width: 360px;font-size: 1.2em;"
                tabindex="1"
                @selected="selectMainCompany"
              />
            </div>
            <div
              v-if="showMergeCompanySection"
              class="mergeCompany"
            >
              <label
                for="cboMergeCompany"
                class="createCompanyLabel"
              > Company to merge and delete</label>
              <PortalDropdown
                id="cboMergeCompany"
                :options="companies"
                :disabled="false"
                :max-item="999999"
                :class="{ errorHighlight: companySelectError }"
                :attach-blur-event="false"
                placeholder="Select Company to merge"
                style="width: 360px;font-size: 1.2em;"
                tabindex="2"
                @selected="selectMergeCompany"
              />
            </div>
            <div
              v-if="showMergeCustomerSection"
              class="mergeCompany"
              style="visibility:hidden;"
            >
              <label style="vertical-align: top;">Merge Companies</label>
              <TriStateToggleSwitch
                v-if="!saveInProgress"
                :enabled="true"
                :current-state="mergeCompanies"
                :tri-state="false"
                @statechanged="setMergeCustomers"
              />
            </div>
            <div
              v-if="showCustomerSection"
              class="mergeCustomerSection">
              <div
                class="mergeCustomerLeftSection">
                <label>Main Company Customers</label>
                <table
                  style="table-layout: auto;margin-bottom:20px;font-weight: normal;"
                >
                  <thead>
                    <tr>
                      <th style="width:25px">ID</th>
                      <th style="width:calc(100% - 50px)">Name</th>
                      <th style="width:25px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="mainCustomers.length === 0">
                      <td
                        v-if="!loadingSites"
                        colspan="2"
                      >There are no customers in the database that are associated with the main company</td>
                    </tr>
                    <tr
                      v-for="c in mainCustomers"
                      v-else
                      :key="c.id"
                    >
                      <td>{{ c.id }}</td>
                      <td
                        :title="c.name"
                        style="max-width:280px"
                      >{{ c.name }}</td>
                      <td>
                        <TriStateToggleSwitch
                          :enabled="true"
                          :current-state="c.currentState"
                          :customer-id="c.id"
                          :text="c.name"
                          :tri-state="false"
                          @statechanged="setMainCustomer"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="mergeCustomerRightSection">
                <label>Merge Company Customers</label>
                <table
                  style="table-layout: auto;margin-bottom:20px;font-weight: normal;"
                >
                  <thead>
                    <tr>
                      <th style="width:25px">ID</th>
                      <th style="width:calc(100% - 50px)">Name</th>
                      <th style="width:25px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="mergeCustomers.length === 0">
                      <td
                        v-if="!loadingSites"
                        colspan="3"
                      >There are no customers in the database that are associated with the merge company</td>
                    </tr>
                    <tr
                      v-for="c in mergeCustomers"
                      v-else
                      :key="c.id"
                    >
                      <td>{{ c.id }}</td>
                      <td
                        :title="c.name"
                        style="max-width:280px"
                      >{{ c.name }}</td>
                      <td>
                        <TriStateToggleSwitch
                          :enabled="true"
                          :current-state="c.currentState"
                          :customer-id="c.id"
                          :text="c.name"
                          :tri-state="false"
                          @statechanged="setMergeCustomer"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mergeLabel">
              <label>{{ mergeLabel }}</label>
            </div>
            <Spinner v-if="loadingRoles" />
            <div
              v-if="formOK && !loadingRoles"
              class="mergeRoles"
            >
              <label>Roles to be merged:</label>
              <table
                style="table-layout: auto;margin-bottom:20px;font-weight: normal;"
              >
                <thead>
                  <tr>
                    <th style="width:50%">{{ companyMergeName }} Roles</th>
                    <th style="width:50%">Matching {{ companyMainName }} roles</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="mergeRoles.length === 0">
                    <td
                      colspan="2"
                    >There are no roles found associated with the company in the database</td>
                  </tr>
                  <tr
                    v-for="r in mergeRoles"
                    v-else
                    :key="r.id"
                  >
                    <td
                      :title="r.name"
                    >{{ r.name }}</td>
                    <td>
                      <select
                        class="mainRoleSelect"
                        @change="getMappedRoles"
                      >
                        <option
                          v-for="m in mainRoles"
                          :key="m.id"
                          :value="m.id"
                        >
                          {{ m.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottombar" >
      <Spinner v-if="saveInProgress" />
      <input
        v-if="!saveInProgress"
        id="mergeCustomerButton"
        :disabled="!formOK"
        :class="getButtonClass"
        type="button"
        title="Merge the selected customers"
        style="float:right;"
        tabindex="8"
        @click="performMerge()"
      >
      <input
        v-if="!saveInProgress"
        id="cancelCustomerButton"
        class="cancelCustomerButton"
        type="button"
        title="Cancel the merge customer process"
        @click="closeMergeCustomerForm()"
      >
    </div>
  </div>
</template>
<script>
import { permissionsMixin } from '../../mixins/permissions'
import PortalDropdown from '../common/portalDropdown'
import Spinner from '../common/spinner'
import { DataEventBus } from '../../events/dataEvents'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'
export default {
  metaInfo: {
    title: 'Support - Merge Customers'
  },
  components: {
    Spinner,
    PortalDropdown,
    TriStateToggleSwitch
  },
  mixins: [
    permissionsMixin
  ],
  data () {
    return {
      companyMainId: 0,
      companyMergeId: 0,
      customerMainId: 0,
      customerMergeId: 0,
      companyMainName: '',
      companyMergeName: '',
      customerMainName: '',
      customerMergeName: '',
      mainCustomers: [],
      mergeCustomers: [],
      mainRoles: [],
      mergeRoles: [],
      mergeCompanies: 1,
      saveInProgress: false,
      companySelectError: false,
      showMergeCompanySection: false,
      showMergeCustomerSection: false,
      showCustomerSection: false,
      loadingRoles: false
    }
  },
  computed: {
    createCustomerPermission () {
      return this.$store.getters.createCustomerPermission
    },
    deleteCustomerPermission () {
      return this.$store.getters.deleteCustomerPermission
    },
    createCompanyPermission () {
      return this.$store.getters.createCompanyPermission
    },
    deleteCompanyPermission () {
      return this.$store.getters.deleteCompanyPermission
    },
    getButtonClass () {
      if (this.formOK) {
        return 'mergeCustomerButtonEnabled'
      } else {
        return 'mergeCustomerButton'
      }
    },
    mergeLabel () {
      // Sets the lebel to show either customer or company merge details
      if (this.mergeCompanies === 2) {
        if (this.mergeCustomers.length < 2) {
          return 'The merge customer has a single site so can only be merged as a company.'
        } else if (this.customerMainId === 0 || this.customerMergeId === 0) {
          return ''
        } else if (this.customerMainId === this.customerMergeId) {
          return 'The same customer cannot be merged. Please select differing customers.'
        } else {
          return 'Customer: ' + this.customerMergeName + ' (' + this.customerMergeId + ') to be merged into customer: ' + this.customerMainName + ' (' + this.customerMainId + ')'
        }
      } else {
        if (this.companyMainId === 0 || this.companyMergeId === 0) {
          return ''
        } else if (this.companyMainId === this.companyMergeId) {
          return 'The same company cannot be merged. Please select differing companies.'
        } else {
          return 'Company: ' + this.companyMergeName + ' (' + this.companyMergeId + ') to be merged into company: ' + this.companyMainName + ' (' + this.companyMainId + ')'
        }
      }
    },
    formOK () {
      if (this.mergeCompanies === 2) {
        return (this.customerMainId !== 0 && this.customerMergeId !== 0 && this.customerMainId !== this.customerMergeId && this.mergeCustomers.length > 1)
      } else {
        return (this.companyMainId !== 0 && this.companyMergeId !== 0 && this.companyMainId !== this.companyMergeId)
      }
    },
    companies () {
      return this.$store.getters.companies
    }
  },
  methods: {
    performMerge: async function () {
      this.saveInProgress = true
      if (this.formOK) {
        if (this.mergeCompanies === 2) {
          var mergeCustomerObject = {}
          mergeCustomerObject.CustomerIdMain = Number(this.customerMainId)
          mergeCustomerObject.CustomerIdMerge = Number(this.customerMergeId)
          var rtn = await this.mergeCustomer(mergeCustomerObject)
          if (rtn.length === 0) {
            this.$store.commit('showMessage', {
              content: `Merging customers with id's '${this.customerMainId}' and '${this.customerMergeId}' has been added successfully!`,
              color: 'blue',
              timeout: 3000
            })
            this.resetCustomerForm()
            this.getCustomers()
          } else {
            this.$store.commit('showMessage', {
              content: `Merging customers with id's '${this.customerMainId}' and '${this.customerMergeId}' failed with '${rtn}'`,
              color: 'red',
              timeout: 5000
            })
          }
        } else {
          var mergeCompanyObject = {}
          mergeCompanyObject.CompanyIdMain = Number(this.companyMainId)
          mergeCompanyObject.CompanyIdMerge = Number(this.companyMergeId)
          mergeCompanyObject.MappedRoles = this.getMappedRoles()
          var mergeResult = await this.mergeCompany(this.companyMainId, this.companyMergeId, mergeCompanyObject)
          if (mergeResult.length === 0) {
            this.$store.commit('showMessage', {
              content: `Merging companies with id's '${this.companyMainId}' and '${this.companyMergeId}' has been added successfully!`,
              color: 'blue',
              timeout: 3000
            })
            this.resetCustomerForm()
            this.getCompanies()
            this.getCustomers()
          } else {
            this.$store.commit('showMessage', {
              content: `Merging companies with id's '${this.companyMainId}' and '${this.companyMergeId}' failed with '${mergeResult}'`,
              color: 'red',
              timeout: 5000
            })
          }
        }
      }
      this.saveInProgress = false
      DataEventBus.$emit('load-data')
    },
    resetMainCustomers () {
      this.mainCustomers.forEach(customer => {
        if (customer.id !== this.customerMainId) {
          customer.currentState = 2
        } else {
          customer.currentState = 1
        }
      })
    },
    resetMergeCustomers () {
      this.mergeCustomers.forEach(customer => {
        if (customer.id !== this.customerMergeId) {
          customer.currentState = 2
        } else {
          customer.currentState = 1
        }
      })
    },
    selectMainCompany (option) {
      if (option.id) {
        this.companyMainId = option.id
        this.companyMainName = option.name
        this.showMergeCompanySection = true
        this.setMainCompanyCustomers()
      }
    },
    selectMergeCompany (option) {
      if (option.id) {
        this.companyMergeId = option.id
        this.companyMergeName = option.name
        this.showMergeCustomerSection = true
        this.setMergeCompanyCustomers()
      }
    },
    setMainCompanyCustomers: async function () {
      this.mainCustomers = await this.getCompanyCustomers(this.companyMainId)
      this.mainCustomers = this.mainCustomers.map(obj => ({ ...obj, currentState: 2 }))
      this.setMainCompanyRoles()
    },
    setMergeCompanyCustomers: async function () {
      this.mergeCustomers = await this.getCompanyCustomers(this.companyMergeId)
      this.mergeCustomers = this.mergeCustomers.map(obj => ({ ...obj, currentState: 2 }))
      this.customerMergeId = 0
      this.setMergedCompanyRoles()
    },
    setMergeCustomers (selectedState) {
      this.mergeCompanies = selectedState.selectedState
      this.showCustomerSection = this.mergeCompanies === 2
      if (!this.showCustomerSection) {
        this.customerMainId = 0
        this.customerMergeId = 0
        this.mainCustomers = this.mainCustomers.map(obj => ({ ...obj, currentState: 2 }))
        this.mergeCustomers = this.mergeCustomers.map(obj => ({ ...obj, currentState: 2 }))
      }
    },
    setMainCustomer (obj) {
      if (obj.selectedState === 1) {
        this.customerMainId = obj.customerId
        this.customerMainName = obj.text
      } else {
        this.customerMainId = 0
        this.customerMainName = ''
      }
      this.resetMainCustomers()
    },
    setMergeCustomer (obj) {
      if (obj.selectedState === 1) {
        this.customerMergeId = obj.customerId
        this.customerMergeName = obj.text
      } else {
        this.customerMergeId = 0
        this.customerMergeName = ''
      }
      this.resetMergeCustomers()
    },
    closeMergeCustomerForm () {
      this.resetCustomerForm()
      this.$emit('setTileMode')
    },
    resetCustomerForm () {
      this.$nextTick(() => {
        this.companyMainId = 0
        this.companyMergeId = 0
        this.companyMainName = ''
        this.companyMergeName = ''
        this.customerMainId = 0
        this.customerMergeId = 0
        this.customerMainName = ''
        this.customerMergeName = ''
        this.mergeCompanies = 1
        this.saveInProgress = false
        this.showMergeCompanySection = false
        this.showMergeCustomerSection = false
        this.showCustomerSection = false
      })
    },
    setMergedCompanyRoles: async function () {
      this.mergeRoles = []
      if (this.companyMergeId > 0) {
        this.loadingRoles = true
        this.mergeRoles = await this.getRolesByCompany(this.companyMergeId)
        this.loadingRoles = false
      }
    },
    setMainCompanyRoles: async function () {
      this.mainRoles = []
      if (this.companyMainId > 0) {
        this.loadingRoles = true
        this.mainRoles = await this.getRolesByCompany(this.companyMainId)
        this.loadingRoles = false
      }
    },
    getMappedRoles () {
      var rtn = []
      var mapMain = document.getElementsByClassName('mainRoleSelect')
      for (let i = 0; i < mapMain.length; i++) {
        rtn.push(this.mergeRoles[i].id + '=' + mapMain[i].value)
      }
      return rtn
    }
  }
}
</script>
<style scoped>
  table tbody tr:nth-child(odd):hover {
    background-color: #e8f5e9;
    color: #1b5e20;
  }
  table tbody tr:nth-child(even):hover {
    background-color: #c8e6c9;
    color: #1b5e20;
  }
  h3 {
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0;
    color: #fff;
    border-radius: 5px 5px 0 0;
  }
  input {
    border-radius: 5px;
  }
  .bottombar {
    text-align: right;
  }
  .completeSection {
    background-image: url('../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  .incompleteSection {
    background-image: url('../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }
  .mergeCustomerSection {
    padding: 25px 5px 0 10px;
  }
  .mergeCustomerHolder {
    background-color: rgba(255,255,255,0.7);
    padding:10px;
    font-weight: bold;
  }
  .parentMergeCustomerForm{
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom: 2vh;
    border-radius: 5px;
  }
  .mergeCustomerLeftSection {
    width: calc(50% - 20px);
    margin-right: 10px;
    display: inline-block;
  }
  .mergeCustomerRightSection {
    width: calc(50% - 10px);
    display: inline-block;
  }
  .mergeCompanyLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .mergeCompany {
    width: 100%;
    display: inline-block;
    padding: 5px 5px 0 10px;
  }
  .mainCompany {
    width: 100%;
    display: inline-block;
    padding: 5px 5px 0 10px;
  }
  .mergeLabel {
    font-weight: bold;
    margin: 15px 0;
    padding: 5px 5px 0 10px;
  }
  .mergeRoles {
    width: 100%;
    font-weight: bold;
    margin: 15px 0;
    padding: 5px 5px 0 10px;
  }
  .mainRoleSelect {
    width: 100%;
    border: 1px solid #999;
  }
  span.statuscontainer {
    float: left;
    margin-right: 5px;
  }
  span.statuscontainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }
  .mergeCustomerButton {
    background-image: url('../../assets/icons/check-tick-disabled.svg');
    cursor: default;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .mergeCustomerButtonEnabled {
    background-image: url('../../assets/icons/check-tick.svg');
    cursor: pointer;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .cancelCustomerButton {
    background-image: url('../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
    margin-top: -4px;
  }
</style>
