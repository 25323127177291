<template>
  <div
    class="uploadDashboardImageTileContainer"
  >
    <div>
      <img
        :src="imageSource"
        :alt="uploadDashboardImageName"
        @click="()=>this.$emit('setCurrentView', 'upload-dashboard-image')"
      >
    </div>
    <div class="tileButtons" >
      <button @click="alert()">Remove</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadDashboardImageComponent',
  data () {
    return {
      uploadDashboardImageName: 'Upload dashboard image'
    }
  },
  computed: {
    imageSource () {
      return require(`../../../assets/icons/avayaAura.png`)
    }
  }
}
</script>
<style scoped>
  .uploadDashboardImageTileContainer {
    display: inline-block;
    margin: 20px 0 0 20px;
    background-color: #fff;
    border: solid 2px #235622;
    border-radius: 8px;
  }
  .uploadDashboardImageTileContainer:hover {
    background-color: rgb(199, 209, 199);
    border: solid 2px #000;
  }
  .uploadDashboardImageTileContainer.selected {
    display: inline-block;
    margin: 20px 0 0 20px;
    background-color: rgb(199, 209, 199);
    border: solid 2px #235622;
  }
  .uploadDashboardImageTileContainer.selected:hover {
    background-color: rgb(122, 136, 122);
    border: solid 2px #000;
  }
  .uploadDashboardImageTileContainer img {
    width:140px;
    cursor: pointer;
  }
  .uploadDashboardImageTileContainer .tileButtons{
    visibility:hidden;
  }
  .uploadDashboardImageTileContainer.selected .tileButtons{
    visibility:visible;
  }
</style>
