import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsTeamsConnectorMixin } from './Calculations/iQuoteCalculationsTeamsConnectorMixin'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'

export const iQuoteCalculationsCustomerSummaryEntriesMixin = {
  mixins: [iQuoteConstants, iQuoteCalculationsTeamsConnectorMixin, iQuoteStoreMixin],
  data () {
    return {
      quote: {}
    }
  },
  computed: {
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcCustomerSummaryEntries: function () {
      var quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
      var quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC
      var quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite
      var quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
      var quoteTechnologyMitelCC = this.$store.getters.quoteTechnologyMitelCC
      var quoteTechnologyCallmediaCXNow = this.$store.getters.quoteTechnologyCallmediaCXNow
      var quoteTechnologyIconGateway = this.$store.getters.quoteTechnologyIconGateway
      var quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia
      var quoteExpansions = this.$store.getters.quoteExpansions
      var teamsConnector = this.$store.getters.teamsConnector
      var quoteGenesys = this.$store.getters.quoteGenesys
      var quoteRingCentral = this.$store.getters.quoteRingCentral
      var quoteGammaSIP = this.$store.getters.quoteGammaSIP
      var quote = this.$store.getters.quote
      var quoteSIPMigrationProperties = this.$store.getters.quoteSIPMigrationProperties
      var phoneLinePlus = this.$store.getters.quotePhoneLinePlus
      var customerSummaryCategoryId
      var serviceId

      if (quoteSIPMigrationProperties.id !== undefined) {
        for (let index = 0; index < this.iQuoteStore_GetQuoteUnitCostsRequired.length; index++) {
          const unitCost = this.iQuoteStore_GetQuoteUnitCostsRequired[index]
          if (unitCost.serviceId === this.iQuoteConstants.services.sip && unitCost.visibleToCustomer && unitCost.recurringCost) {
            customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.SIP_SERVICES
            this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, unitCost.description, unitCost.quantity, unitCost.price, unitCost.yearlyPrice, true)
          }
        }

        for (let index = 0; index < this.iQuoteStore_GetQuoteLicensesRequired.length; index++) {
          const license = this.iQuoteStore_GetQuoteLicensesRequired[index]
          if (license.serviceId === this.iQuoteConstants.services.sip && license.visibleToCustomer) {
            customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.SIP_SERVICES
            this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, license.description, license.quantity, license.annualPrice / (12 * license.quantity), license.annualPrice, true)
          }
        }
      }

      // UPFRONT COSTS

      if (quote.chargeUpfront) {
        if (this.totalUpfrontInfrastructureCostWithMarkup > 0) {
          var upfrontCost = this.totalUpfrontInfrastructureCostWithMarkup

          if (quoteTechnologyAvayaAura.id !== undefined) {
            customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_COMMUNICATE_WITH_AVAYA_AURA
          } else if (quoteTechnologyMitelUC.id !== undefined) {
            customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_COMMUNICATE_WITH_MITEL
          } else if (quoteTechnologyCallmediaCXNow.id !== undefined) {
            customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.CALLMEDIA_CX_NOW
          } else if (quoteSIPMigrationProperties.id !== undefined || quoteGammaSIP.id !== undefined) {
            customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.SIP_SERVICES
            upfrontCost += this.totalUpfrontSipSetupCostWithMarkup + this.totalUpfrontInfrastructureCostWithMarkup
          } else if (quoteExpansions.id !== undefined) {
            customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.EXPANSION
          }

          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Platform Reservation Charge', 1, upfrontCost, null, false)
        }

        if (teamsConnector.id !== undefined) {
          customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.TEAMS_CONNECTOR
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Teams Connector Setup Charges', 1, this.calcTeamsConnector_UpfrontCharges, null, false)
        }

        if (quoteGenesys.id !== undefined) {
          customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.GENESYS
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Genesys Setup Charges', 1, this.calcGenesysConnector_UpfrontCharges, null, false)
        }

        if (quoteRingCentral.ucTechId !== undefined && this.calcRingCentralMixin_UpfrontCharges > 0) {
          customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.RINGCENTRAL
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'RingCentral Setup Charges', 1, this.calcRingCentralMixin_UpfrontCharges, null, false)
        }

        if (quoteGammaSIP.id !== undefined) {
          if (this.totalUpfrontSipSetupCostWithMarkup > 0) {
            customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.SIP_SERVICES
            this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'SIP Setup', 1, this.totalUpfrontSipSetupCostWithMarkup, null, false)
          }
        }

        if (quoteTechnologyAvayaAura.id !== undefined) {
          customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_COMMUNICATE_WITH_AVAYA_AURA
          serviceId = this.iQuoteConstants.services.communicate
        } else if (quoteTechnologyMitelUC.id !== undefined) {
          customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_COMMUNICATE_WITH_MITEL
          serviceId = this.iQuoteConstants.services.communicate
        } else if (quoteTechnologyCallmediaCXNow.id !== undefined) {
          customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.CALLMEDIA_CX_NOW
          serviceId = this.iQuoteConstants.services.communicate
        } else if (quoteExpansions.id !== undefined) {
          customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.EXPANSION
          serviceId = this.iQuoteConstants.services.expansions
        }

        if (phoneLinePlus.id !== undefined) {
          customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.PHONELINE_PLUS
        }

        var customerFacingUpfrontUnitCostsSortedByService = this.customerFacingUpfrontUnitCosts.sort(function (a, b) { return a.serviceId - b.serviceId })

        for (let index = 0; index < customerFacingUpfrontUnitCostsSortedByService.length; index++) {
          const unitCost = customerFacingUpfrontUnitCostsSortedByService[index]
          switch (unitCost.serviceId) {
            case this.iQuoteConstants.services.communicate:
              if (quoteTechnologyAvayaAura.id !== undefined) {
                customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_COMMUNICATE_WITH_AVAYA_AURA
              } else if (quoteTechnologyMitelUC.id !== undefined) {
                customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_COMMUNICATE_WITH_MITEL
              }
              break
            case this.iQuoteConstants.services.contact:
              if (quoteTechnologyAvayaAACC.id !== undefined) {
                customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_CONTACT_WITH_AVAYA_AACC
              } else if (quoteTechnologyMitelCC.id !== undefined) {
                customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_CONTACT_WITH_MITEL
              } else if (quoteTechnologyAvayaElite.id !== undefined) {
                customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_CONTACT_WITH_AVAYA_ELITE
              } else if (quoteTechnologyCallmedia.id !== undefined) {
                customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_CONTACT_WITH_CALLMEDIA
              }
              break
            case this.iQuoteConstants.services.sip:
              customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.SIP_SERVICES
              break
            case this.iQuoteConstants.services.callmediaCXNow:
              customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.CALLMEDIA_CX_NOW
              break
            case this.iQuoteConstants.services.gateway:
              customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_GATEWAY
              break
            case this.iQuoteConstants.services.expansions:
              customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.EXPANSION
              break
            case this.iQuoteConstants.services.teamsConnector:
              customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.TEAMS_CONNECTOR
              break
            case this.iQuoteConstants.services.genesys:
              customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.GENESYS
              break
            case this.iQuoteConstants.services.ringCentral:
              customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.RINGCENTRAL_UC
              break
          }

          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, unitCost.description, unitCost.quantity, unitCost.price, null, false)
        }
      }

      // ANNUAL COSTS

      if (quoteTechnologyAvayaAura.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_COMMUNICATE_WITH_AVAYA_AURA
        serviceId = this.iQuoteConstants.services.communicate
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)
      }

      if (quoteTechnologyMitelUC.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_COMMUNICATE_WITH_MITEL
        serviceId = this.iQuoteConstants.services.communicate
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)
      }

      if (quoteTechnologyAvayaAACC.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_CONTACT_WITH_AVAYA_AACC
        serviceId = this.iQuoteConstants.services.contact
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)
      }

      if (quoteTechnologyMitelCC.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_CONTACT_WITH_MITEL
        serviceId = this.iQuoteConstants.services.contact
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)
      }

      if (quoteTechnologyAvayaElite.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_CONTACT_WITH_AVAYA_ELITE
        serviceId = this.iQuoteConstants.services.contact
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)
      }

      if (quoteTechnologyCallmedia.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_CONTACT_WITH_CALLMEDIA
        serviceId = this.iQuoteConstants.services.contact
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)
      }

      if (quoteTechnologyCallmediaCXNow.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.CALLMEDIA_CX_NOW
        serviceId = this.iQuoteConstants.services.callmediaCXNow
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)
      }

      if (quoteTechnologyIconGateway.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.ICON_GATEWAY
        serviceId = this.iQuoteConstants.services.gateway
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)

        this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Icon Gateway Charges', 1, this.annualIconGatewayCostWithMarkup / 12, this.annualIconGatewayCostWithMarkup, true)
      }

      if (teamsConnector.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.TEAMS_CONNECTOR
        serviceId = this.iQuoteConstants.services.teamsConnector
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)

        if (teamsConnector.overrideSIPChannels !== 0) {
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Inclusive Landline and UK Mobile Bolt-on', teamsConnector.overrideSIPChannels, 0, 0, true)
        } else if (teamsConnector.sipChannelsCalculated > 0) {
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Inclusive Landline and UK Mobile Bolt-on', teamsConnector.sipChannelsCalculated, 0, 0, true)
        }

        if (teamsConnector.numAttendantConsoles > 0) {
          if (teamsConnector.attendantConsoleSubscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Attendant Consoles (Charged Annually)', teamsConnector.numAttendantConsoles, 0, this.utilFunctions_GetUnitCostPrice(this.iQuoteConstants.unitCosts.TEAMS_ATTENDANT_CONSOLE_ANNUAL), true)
          } else {
            this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Attendant Consoles (Charged Monthly)', teamsConnector.numAttendantConsoles, this.utilFunctions_GetUnitCostPrice(this.iQuoteConstants.unitCosts.TEAMS_ATTENDANT_CONSOLE_MONTHLY), this.utilFunctions_GetUnitCostPrice(this.iQuoteConstants.unitCosts.TEAMS_ATTENDANT_CONSOLE_MONTHLY) * 12, true)
          }
        }
      }

      if (quoteRingCentral.ucTechId !== undefined) {
        serviceId = this.iQuoteConstants.services.ringCentral

        for (let index = 0; index < this.licensesRequired.length; index++) {
          const license = this.licensesRequired[index]
          if (license.serviceId === serviceId && license.visibleToCustomer) {
            if (license.licenseId >= this.iQuoteConstants.licenses.RINGCENTRAL_CC_DIGITAL_AGENT &&
               license.licenseId <= this.iQuoteConstants.licenses.RINGCENTRAL_CC_ZEN_AGENT) {
              this.iQuoteStore_AddCustomerSummaryEntryToQuote(this.iQuoteConstants.customerSummaryCategories.RINGCENTRAL_CC, license.description, license.quantity, license.price, license.annualPrice, true)
            } else {
              this.iQuoteStore_AddCustomerSummaryEntryToQuote(this.iQuoteConstants.customerSummaryCategories.RINGCENTRAL_UC, license.description, license.quantity, license.price, license.annualPrice, true)
            }
          }
        }
      }

      if (quoteGenesys.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.GENESYS
        serviceId = this.iQuoteConstants.services.genesys
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)
        serviceId = this.iQuoteConstants.services.teamsConnector
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)

        if (quoteGenesys.options.overrideSIPChannels !== 0) {
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Inclusive Landline and UK Mobile Bolt-on', quoteGenesys.options.overrideSIPChannels, 0, 0, true)
        } else {
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Inclusive Landline and UK Mobile Bolt-on', quoteGenesys.options.sipChannelsCalculated, 0, 0, true)
        }

        // KP 3542 - Remove genesys managed services
        // this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Managed Services', 1, this.calcGenesysConnector_ManagedServicesCharges, this.calcGenesysConnector_ManagedServicesCharges, true)

        if (quoteGenesys.options.customerServiceManagerRequired) {
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Customer Success Manager', 1, this.calcGenesysConnector_CSMCharges, this.calcGenesysConnector_CSMCharges, true)
        }
      }

      if (phoneLinePlus.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.PHONELINE_PLUS
        serviceId = this.iQuoteConstants.services.sip
        this.addLicensesToCustomerSummary(customerSummaryCategoryId, serviceId)
      }

      if (quoteExpansions.id !== undefined && quoteTechnologyAvayaAACC.id === undefined && quoteTechnologyMitelUC.id === undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.EXPANSION
        serviceId = this.iQuoteConstants.services.expansions

        this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Platform Charge', 1, this.totalRecurringAnnualPrice / 12, this.totalRecurringAnnualPrice, true)
      }

      if (quoteGammaSIP.id !== undefined) {
        customerSummaryCategoryId = this.iQuoteConstants.customerSummaryCategories.SIP_SERVICES

        serviceId = this.iQuoteConstants.services.sip
        var sipPrimaryPrice = 0
        var sipPrimaryQuantity = 0
        var sipSecondaryPrice = 0
        var sipSecondaryQuantity = 0

        this.licensesRequired.forEach(license => {
          if (license.visibleToCustomer) {
            switch (license.licenseId) {
              case (this.iQuoteConstants.licenses.SIP_ACTIVE_12):
              case (this.iQuoteConstants.licenses.SIP_STANDBY_12):
              case (this.iQuoteConstants.licenses.SIP_ACTIVE_36):
              case (this.iQuoteConstants.licenses.SIP_STANDBY_36):
                sipPrimaryPrice += license.price
                sipPrimaryQuantity = license.quantity
                break
              case (this.iQuoteConstants.licenses.TTB_SIP_ACTIVE_12):
              case (this.iQuoteConstants.licenses.TTB_SIP_ACTIVE_36):
                sipSecondaryPrice += license.price
                sipSecondaryQuantity = license.quantity
                break
              case (this.iQuoteConstants.licenses.SIP_ACTIVE_SEMA):
              case (this.iQuoteConstants.licenses.SIP_STANDBY_SEMA):
                if (quoteGammaSIP.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
                  sipPrimaryPrice += license.price
                } else if (quoteGammaSIP.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
                  sipSecondaryPrice += license.price
                }
                break
            }
          }
        })

        var sipAnnualPrice = sipPrimaryQuantity * sipPrimaryPrice * 12
        this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Primary Channels', sipPrimaryQuantity, sipPrimaryPrice, sipAnnualPrice, true)

        if (sipSecondaryQuantity > 0) {
          sipAnnualPrice = sipSecondaryQuantity * sipSecondaryPrice * 12
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Secondary Channels', sipSecondaryQuantity, sipSecondaryPrice, sipAnnualPrice, true)
        }

        if (this.annualSipCostWithMarkup > 0) {
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, 'Access Service', 1, this.annualSipCostWithMarkup / 12, this.annualSipCostWithMarkup, true)
        }
      }
    },
    addLicensesToCustomerSummary: function (customerSummaryCategoryId, serviceId) {
      for (let index = 0; index < this.licensesRequired.length; index++) {
        const license = this.licensesRequired[index]
        if (license.serviceId === serviceId && license.visibleToCustomer) {
          this.iQuoteStore_AddCustomerSummaryEntryToQuote(customerSummaryCategoryId, license.description, license.quantity, license.price, license.annualPrice, true)
        }
      }
    }
  },
  mounted () {
    this.quote = this.$store.getters.quote
  }
}
