<template>
  <div
    class="deleteSectionContainer"
  >
    <div
      id="frmDeleteEmailSuffix"
      class="deleteEmailSuffixHolder"
    >
      <div class="deleteEmailSuffixText">
        <p><b>Warning: </b>Deleting the {{ emailsuffix }} email suffix for company {{ companyname }} is <b>permanent</b>.</p>
      </div>
      <Spinner v-if="isDeleting" />
      <div class="actionBar">
        <input
          id="deleteEmailSuffixButton"
          type="button"
          class="deleteButton deleteEmailSuffixsButton"
          title="Delete Selected email suffix"
          @click="setDialog(true)"
        >
      </div>
    </div>
    <ConfirmationDialogBox
      :dialog="showConfirmationDialogBox"
      :dlg-message="`Are you sure want to delete the email suffix ${emailsuffix} for company ${companyname }?`"
      :dlg-close-from-top="false"
      dlgid="deleteEmailSuffixConfirmationDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      negative-btn-text="No"
      positive-btn-text="Yes"
      @negativeAction="setDialog"
      @positiveAction="deleteSelectedEmailSuffix()"
      @cancelDialog="setDialog"
    />
  </div>
</template>

<script>
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import ConfirmationDialogBox from '../../common/confirmationDialogBox'
import Spinner from '../../common/spinner'

export default {
  components: {
    Spinner,
    ConfirmationDialogBox
  },
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    emailsuffixid: {
      type: Number,
      default: 0
    },
    companyid: {
      type: Number,
      default: 0
    },
    emailsuffix: {
      type: String,
      default: ''
    },
    companyname: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isDeleting: false,
      showConfirmationDialogBox: false,
      formOK: true
    }
  },
  computed: {
    deleteCompanyPermission () {
      return this.$store.getters.deleteCompanyPermission
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    setComponent: async function () {
    },
    deleteSelectedEmailSuffix: async function () {
      this.isDeleting = true
      try {
        await this.deleteEmailSuffix(this.emailsuffixid)
        this.$emit('emailSuffixDeleted')
        this.$store.commit('showMessage', { content: 'Successfully deleted the email suffix', color: 'blue', timeout: 3000 })
      } catch (error) {
        this.$store.commit('showMessage', { content: 'The delete email suffix call has failed', color: 'red', timeout: 3000 })
      }
      this.isDeleting = false
      this.setDialog(false)
    },
    setDialog: function (value) {
      this.showConfirmationDialogBox = value
    }
  }
}
</script>

<style scoped>
  .deleteSectionContainer {
    width:400px;
    display: none;
    margin-bottom:10px;
  }
  .deleteEmailSuffixHolder {
    background-color: rgba(255,255,255,0.7);
    padding:10px;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .actionBar {
    direction: rtl;
  }
</style>
