import { render, staticRenderFns } from "./createUserAreas.vue?vue&type=template&id=7401864a&scoped=true&"
import script from "./createUserAreas.vue?vue&type=script&lang=js&"
export * from "./createUserAreas.vue?vue&type=script&lang=js&"
import style0 from "./createUserAreas.vue?vue&type=style&index=0&id=7401864a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7401864a",
  null
  
)

export default component.exports