<template>
  <div class="designPackSummaryContainer quotePanel">
    <div class="hardwareBox">
      <TabStrip
        :tabs="hardwareTabs"
        :selected-tab="hardwareComponent.hardwareCurrentTab"
        :has-line="false"
        @tabChanged="handleHardwareTabChanged"/>
      <div
        v-if="hardwareComponent.hardwareCurrentTab === 'HardwareEntries'">
        <h3>Primary WAN CCTs</h3>
        <HardwareMainTableEditor
          @tableUpdated="onHardwareMainTableUpdated"
        />
        <h3>Misc Equipment &amp; Patch Cables</h3>
        <HardwareMiscTableEditor
          @tableUpdated="onHardwareMiscTableUpdated"
        />
      </div>
      <div
        v-if="hardwareComponent.hardwareCurrentTab === 'UploadSheet'"
        class="uploadSheetBox">
        <input
          v-if="!isReadOnly"
          id="btnDownloadHardwareTemplate"
          value="Download Hardware Template"
          type="button"
          @click="downloadHardwareTemplate()"
        >
        <br><br>
        <input
          v-if="!isReadOnly"
          id="btnUploadHardware"
          value="Upload Hardware Spreadsheet"
          type="button"
          @click="uploadHardwareSpreadsheetButtonClicked('Upload Design Pack Hardware Spreadsheet', 0)"
        >
      </div>
    </div>
    <p />
    <FileUploadDialogBox
      :dialog="hardwareComponent.showFileUploadDialog"
      :dlg-message="hardwareComponent.fileUploadDialogMessage"
      :dlg-close-from-top="false"
      :file-upload-catalog-id="hardwareComponent.selectedFileUploadCatalogId"
      :checking-file="hardwareComponent.fileDialogCheckingFile"
      :selected-file-error="hardwareComponent.fileDialogSelectedFileError"
      :save-file="false"
      :show-title="false"
      :warning-message="'Warning:<br>This will remove any existing Primary and Misc table entries'"
      :handle-after-upload="true"
      dlgid="fileUploadDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      @afterUploadAction="afterFileUploaded"
      @cancelAction="hardwareComponent.showFileUploadDialog = false"
      @fileChosenAction="fileChosen"
    />
    <ConfirmationDialogBox
      :dialog="hardwareComponent.showConfirmationDialogBox"
      :dlg-message="`This will remove any existing Hardware Primary and Misc table entries.<br>Do you wish to continue?`"
      :dlg-close-from-top="false"
      :hide-buttons-after-press="false"
      dlgid="uploadHardware"
      dlg-icon="mdi-alert-circle-outline"
      negative-btn-text="No"
      positive-btn-text="Yes"
      @negativeAction="cancelUpload()"
      @cancelDialog="cancelUpload()"
      @positiveAction="confirmUpload()"
    />
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import HardwareMainTableEditor from '../designPackComponents/hardwareMainTableEditor'
import HardwareMiscTableEditor from '../designPackComponents/hardwareMiscTableEditor'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackHardwareMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackHardware'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import TabStrip from '../../common/tabStrip'
import ConfirmationDialogBox from '../../common/confirmationDialogBox'
import FileUploadDialogBox from '../../common/fileUploadDialogBox'
import { DataEventBus } from '../../../events/dataEvents'
import camelCase from 'lodash/camelCase'

export default {
  name: 'Hardware',
  components: {
    HardwareMainTableEditor,
    HardwareMiscTableEditor,
    TabStrip,
    FileUploadDialogBox,
    ConfirmationDialogBox
  },
  mixins: [ iQuoteStoreMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsDesignPackHardwareMixin, iQuoteExportsAPIMixin ],
  data () {
    return {
      hardwareComponent: {
        hardwareCurrentTab: 'HardwareEntries',
        hardwareEntries: [],
        fileUploadDialogMessage: '',
        fileDialogSelectedFileError: '',
        showFileUploadDialog: false,
        FileUploadCatalogId: 0,
        selectedFileUploadCatalogId: 0,
        fileDialogCheckingFile: false,
        fileName: '',
        showConfirmationDialogBox: false,
        uploadedFile: null
      }
    }
  },
  computed: {
    hardwareTabs () {
      var tabItems = [
        { name: 'HardwareEntries', text: 'Primary/Misc' }
      ]
      if (!this.isReadOnly) {
        tabItems.push({ name: 'UploadSheet', text: 'Upload sheet' })
      }

      return tabItems
    },
    isReadOnly () {
      return this.iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack.isReadOnly
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    async onHardwareMainTableUpdated (newEntries) {
      await this.iQuoteCalculationsDesignPackHardwareMixin_updateMainEntries(newEntries)
    },
    async onHardwareMiscTableUpdated (newEntries) {
      await this.iQuoteCalculationsDesignPackHardwareMixin_updateMiscEntries(newEntries)
    },
    handleHardwareTabChanged (newTab) {
      this.hardwareComponent.hardwareCurrentTab = newTab.name === 'HardwareEntries' ? 'HardwareEntries' : 'UploadSheet'
    },
    convertToCamelCase (obj) {
      obj.map((item) => {
        for (var key in item) {
          var camelCaseKey = camelCase(key)
          if (camelCaseKey !== key) {
            item[camelCaseKey] = item[key]
            delete item[key]
          }
        }
      })
      return obj
    },
    downloadHardwareTemplate: async function () {
      var data = await this.iQuoteExportsAPIMixin_getDesignPackHardwareTemplate()
      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
      a.download = 'Hardware Template.xlsx'
      document.body.appendChild(a)
      a.click()
    },
    uploadHardwareSpreadsheetButtonClicked: async function (message, fileUploadCatalogId) {
      DataEventBus.$emit('resetDialog')
      this.hardwareComponent.fileUploadDialogMessage = message
      this.hardwareComponent.fileDialogSelectedFileError = ''
      this.hardwareComponent.showFileUploadDialog = true
      this.hardwareComponent.selectedFileUploadCatalogId = fileUploadCatalogId
    },
    async fileChosen (newFile) {
      this.hardwareComponent.fileDialogCheckingFile = true
      this.hardwareComponent.fileDialogSelectedFileError = ''
      var fileExtension = newFile.name.split('.').pop().toLowerCase()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xls' && fileExtension !== 'xltm' && fileExtension !== 'xlsm') {
        this.hardwareComponent.fileDialogSelectedFileError = 'Only Excel Spreadsheets can be uploaded'
      } else {
        var data = await this.iQuoteExportsAPIMixin_getDesignPackHardwareDataFromExcel(newFile.contents)

        if (data.error === undefined) {
          this.hardwareComponent.fileDialogSelectedFileError = 'Error contacting file export service. Cannot upload. Please contact your administrator for assistance'
        } else if (data.error !== '') {
          this.hardwareComponent.fileDialogSelectedFileError = data.error
        } else if (data.entries.length === 0) {
          this.hardwareComponent.fileDialogSelectedFileError = 'No Hardware Main/Misc Hardware Entries found on this spreadsheet'
        }
      }

      this.hardwareComponent.fileDialogCheckingFile = false
    },
    cancelUpload () {
      this.hardwareComponent.showConfirmationDialogBox = false
      DataEventBus.$emit('showUploadProgress', false)
    },
    async confirmUpload () {
      this.hardwareComponent.showConfirmationDialogBox = false
      DataEventBus.$emit('showUploadProgress', true)

      await this.setValuesFromSpreadsheet(this.hardwareComponent.uploadedFile)
    },
    async afterFileUploaded (newFile) {
      this.hardwareComponent.fileName = newFile.name
      this.hardwareComponent.uploadedFile = newFile
      this.hardwareComponent.showConfirmationDialogBox = true
    },
    setValuesFromSpreadsheet: async function (newFile) {
      var designPackHardwareData = await this.iQuoteExportsAPIMixin_getDesignPackHardwareDataFromExcel(newFile.contents)
      var designPack = this.iQuoteStore_GetDesignPack

      designPack.hardwareMainEntries = this.convertToCamelCase(designPackHardwareData.entries.HardwareMainEntries)
      designPack.hardwareMiscEntries = this.convertToCamelCase(designPackHardwareData.entries.HardwareMiscEntries)

      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack)

      DataEventBus.$emit('afterUploadCompleted')

      this.hardwareComponent.hardwareCurrentTab = 'HardwareEntries'
    }
  }
}
</script>
<style scoped>
  td.cellContainsValue {
    font-weight: bold;
  }
  .hardwareBox {
    border: 1px solid #808080;
    padding: 8px;
    margin-bottom: 0;
  }
  .uploadSheetBox {
    height: 425px;
    padding-top: 50px;
    margin-left: 20px;
  }

  table tbody tr td {
    font-size: 1.1em;
  }
</style>
