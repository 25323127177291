<template>
  <div class="designPackSummaryContainer quotePanel">
    <div class="ceasesBox">
      <TabStrip
        :tabs="ceasesTabs"
        :selected-tab="ceasesComponent.ceasesCurrentTab"
        :has-line="false"
        @tabChanged="handleCeasesTabChanged"/>
      <div v-if="ceasesComponent.ceasesCurrentTab === 'CeasesEntries'">
        <h3>Ceases</h3>
        <table
          id="mainTable"
          class="iquoteDesignpackTable noTableShading">
          <colgroup>
            <col width="15%">
            <col width="14%">
            <col width="17%">
            <col width="15%">
            <col width="35%">
            <col width="4%">
          </colgroup>
          <tr class="mainHeader">
            <th colspan="3">Site Information</th>
            <th colspan="2">Cease Information</th>
            <th>&nbsp;</th>
          </tr>
          <tr class="subHeader">
            <th>Name</th>
            <th>Postcode/Address</th>
            <th>Provider/Service</th>
            <th>Circuit ID/Serial Number (if applicable)</th>
            <th>Product Description (if available)</th>
            <th>&nbsp;</th>
          </tr>
          <tbody
            v-for="ceasesEntry in ceasesComponent.ceasesEntries"
            :key="ceasesEntry.id">
            <tr>
              <td class="noTableShading">
                <input
                  :id="'nameInput' + ceasesEntry.id"
                  v-model="ceasesEntry.name"
                  :disabled="isReadOnly"
                  type="text"
                  @blur="onInputItemFocusLost(ceasesEntry, $event)"
                  @change="saveChanges()">
              </td>
              <td class="noTableShading">
                <input
                  :id="'postcodeInput' + ceasesEntry.id"
                  v-model="ceasesEntry.postcode"
                  :disabled="isReadOnly"
                  type="text"
                  @blur="onInputItemFocusLost(ceasesEntry, $event)"
                  @change="saveChanges()">
              </td>
              <td>
                <input
                  :id="'providerInput' + ceasesEntry.id"
                  v-model="ceasesEntry.provider"
                  :disabled="isReadOnly"
                  type="text"
                  @blur="onInputItemFocusLost(ceasesEntry, $event)"
                  @change="saveChanges()">
              </td>
              <td>
                <input
                  :id="'circuitIdInput' + ceasesEntry.id"
                  v-model="ceasesEntry.circuitId"
                  :disabled="isReadOnly"
                  type="text"
                  @blur="onInputItemFocusLost(ceasesEntry, $event)"
                  @change="saveChanges()">
              </td>
              <td>
                <input
                  :id="'descriptionInput' + ceasesEntry.id"
                  v-model="ceasesEntry.description"
                  :disabled="isReadOnly"
                  type="text"
                  @blur="onInputItemFocusLost(ceasesEntry, $event)"
                  @change="saveChanges()">
              </td>
              <td style="text-align: center;">
                <button
                  v-if="!isBlankEntry(ceasesEntry) && !isReadOnly"
                  :id="'btnDelete' + ceasesEntry.id.toString()"
                  :tabindex = "-1"
                  type="button"
                  class="iQuoteIconButton"
                  title="Delete this entry"
                  @click="deleteEntry(ceasesEntry)"
                >
                  <img
                    :src="iQuoteImagesMixin_iconDelete"
                    alt="delete">
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          id="informationBoxTable"
          class="informationBoxTable noTableShading">
          <tr>
            <th colspan="3">&nbsp;</th>
          </tr>
          <tbody>
            <tr>
              <td>
                <span>Please input any ceases which will need to be completed as a part of this DP, for circuits please provide the address, postcode, provider (aka Carrier), circuit ID and if known the circuit type such as FTTC 80:20. For hardware, support or licensing please input a name, address, postcode if known but ensure you provide the appropriate detail in the Description field such as Avaya Support contract if the DP is for the migration from Avaya to Ring Central.</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="ceasesComponent.ceasesCurrentTab === 'UploadSheet'"
        class="uploadSheetBox">
        <input
          v-if="!isReadOnly"
          id="btnDownloadCeasesTemplate"
          value="Download Ceases Template"
          type="button"
          @click="downloadCeasesTemplate()"
        >
        <br><br>
        <input
          v-if="!isReadOnly"
          id="btnUploadCeases"
          value="Upload Ceases Spreadsheet"
          type="button"
          @click="uploadCeasesSpreadsheetButtonClicked('Upload Design Pack Ceases Spreadsheet', 0)"
        >
      </div>
    </div>
    <p />
    <FileUploadDialogBox
      :dialog="ceasesComponent.showFileUploadDialog"
      :dlg-message="ceasesComponent.fileUploadDialogMessage"
      :dlg-close-from-top="false"
      :file-upload-catalog-id="ceasesComponent.selectedFileUploadCatalogId"
      :checking-file="ceasesComponent.fileDialogCheckingFile"
      :selected-file-error="ceasesComponent.fileDialogSelectedFileError"
      :save-file="false"
      :show-title="false"
      :warning-message="'Warning:<br>This will remove any existing Ceases entries'"
      :handle-after-upload="true"
      dlgid="fileUploadDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      @afterUploadAction="afterFileUploaded"
      @cancelAction="ceasesComponent.showFileUploadDialog = false"
      @fileChosenAction="fileChosen"
    />
    <ConfirmationDialogBox
      :dialog="ceasesComponent.showConfirmationDialogBox"
      :dlg-message="`This will remove any existing Ceases entries.<br>Do you wish to continue?`"
      :dlg-close-from-top="false"
      :hide-buttons-after-press="false"
      dlgid="uploadCeases"
      dlg-icon="mdi-alert-circle-outline"
      negative-btn-text="No"
      positive-btn-text="Yes"
      @negativeAction="cancelUpload()"
      @cancelDialog="cancelUpload()"
      @positiveAction="confirmUpload()"
    />
  </div>
</template>

<script>

import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackCeasesMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackCeases'
import { iQuoteImagesMixin } from '../../../mixins/iQuote/Globals/iQuoteImages'
import { DataEventBus } from '../../../events/dataEvents'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import TabStrip from '../../common/tabStrip'
import ConfirmationDialogBox from '../../common/confirmationDialogBox'
import FileUploadDialogBox from '../../common/fileUploadDialogBox'
import camelCase from 'lodash/camelCase'

import { mapActions } from 'vuex'

export default {
  name: 'Ceases',
  components: {
    TabStrip,
    FileUploadDialogBox,
    ConfirmationDialogBox
  },
  mixins: [ iQuoteStoreMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsDesignPackCeasesMixin, iQuoteImagesMixin, iQuoteExportsAPIMixin ],
  props: {
  },
  data () {
    return {
      ceasesComponent: {
        ceasesCurrentTab: 'CeasesEntries',
        ceasesEntries: [],
        quoteDesignPackRevisionId: 0,
        fileUploadDialogMessage: '',
        fileDialogSelectedFileError: '',
        showFileUploadDialog: false,
        FileUploadCatalogId: 0,
        selectedFileUploadCatalogId: 0,
        fileDialogCheckingFile: false,
        fileName: '',
        showConfirmationDialogBox: false,
        uploadedFile: null
      }
    }
  },
  computed: {
    ceasesTabs () {
      var tabItems = [
        { name: 'CeasesEntries', text: 'Ceases' }
      ]
      if (!this.isReadOnly) {
        tabItems.push({ name: 'UploadSheet', text: 'Upload sheet' })
      }

      return tabItems
    },
    isReadOnly () {
      return this.iQuoteCalculationsDesignPackCeasesMixin_selectedDesignPack.isReadOnly
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.designPack,
      (newValue) => {
        this.initialise(newValue)
      }
    )
    this.initialise(this.iQuoteCalculationsDesignPackCeasesMixin_selectedDesignPack)
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    initialise (designPack) {
      if (this.ceasesComponent.quoteDesignPackRevisionId === designPack.quoteDesignPackRevisionId) {
        // The designpack that just saved, is the one we are currently looking at, so no need to reload
        return
      }

      this.ceasesComponent.quoteDesignPackRevisionId = designPack.quoteDesignPackRevisionId

      if (designPack.quoteDesignPackRevisionId === undefined || designPack.quoteDesignPackRevisionId === 0) {
        // There is no design pack, or it is in the process of being duplicated
        return
      }

      this.addCeasesEntries(designPack.ceasesEntries)
    },
    addCeasesEntries (ceasesEntries) {
      this.ceasesComponent.ceasesEntries = []
      if (ceasesEntries) {
        ceasesEntries.forEach(entry => {
          this.ceasesComponent.ceasesEntries.push({ ...entry, id: this.getTempEntryId() })
        })
      }

      this.addBlankEntry()
    },
    handleCeasesTabChanged (newTab) {
      this.ceasesComponent.ceasesCurrentTab = newTab.name === 'CeasesEntries' ? 'CeasesEntries' : 'UploadSheet'
    },
    convertToCamelCase (obj) {
      obj.map((item) => {
        for (var key in item) {
          var camelCaseKey = camelCase(key)
          if (camelCaseKey !== key) {
            item[camelCaseKey] = item[key]
            delete item[key]
          }
        }
      })
      return obj
    },
    onInputItemFocusLost (entry, event) {
      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }
    },
    isBlankEntry (entry) {
      return entry.name === '' &&
          entry.postcode === '' &&
          entry.provider === '' &&
          entry.circuitId === '' &&
          entry.description === ''
    },
    hasBlankEntry () {
      var foundBlankEntry = false
      this.ceasesComponent.ceasesEntries.forEach(entry => {
        if (this.isBlankEntry(entry)) {
          foundBlankEntry = true
        }
      })

      return foundBlankEntry
    },
    addBlankEntry () {
      this.ceasesComponent.ceasesEntries.push(this.getBlankEntry())
    },
    getBlankEntry () {
      return {
        id: this.getTempEntryId(),
        name: '',
        postcode: '',
        provider: '',
        circuitId: '',
        description: ''
      }
    },
    saveChanges () {
      var newEntries = []
      var blankEntry = this.getBlankEntry()
      var array = this.ceasesComponent.ceasesEntries

      for (let index = 0; index < array.length; index++) {
        const entry = array[index]
        if (!this.isBlankEntry(entry)) {
          // Loop through all properties of the blank entry and compare against the entry to be
          // saved to check that the user hasn't entered a value of the wrong data type. This typically
          // will only happen if they deleted the contents of a numeric field. This would result in a empty
          // string being returned, and will cause a failure if we tried to save that to the DB, as it's not numeric
          for (const property in blankEntry) {
            if (typeof blankEntry[property] !== typeof entry[property]) {
              // User has entered something of wrong datatype,
              // which will fail when saving, so reset it to default value

              console.log('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])
              this.iQuoteFunctions_ShowError('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])

              entry[property] = blankEntry[property] // Set to default
            }
          }
          newEntries.push(entry)
        }
      }
      this.iQuoteCalculationsDesignPackCeasesMixin_updateEntries(newEntries)
    },
    getTempEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.ceasesComponent.ceasesEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    deleteEntry (entry) {
      var arr = this.ceasesComponent.ceasesEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === entry.id) {
          arr.splice(i, 1)
          this.saveChanges()
          return
        }
      }
    },
    downloadCeasesTemplate: async function () {
      var data = await this.iQuoteExportsAPIMixin_getDesignPackCeasesTemplate()
      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
      a.download = 'Ceases Template.xlsx'
      document.body.appendChild(a)
      a.click()
    },
    uploadCeasesSpreadsheetButtonClicked: async function (message, fileUploadCatalogId) {
      DataEventBus.$emit('resetDialog')
      this.ceasesComponent.fileUploadDialogMessage = message
      this.ceasesComponent.fileDialogSelectedFileError = ''
      this.ceasesComponent.showFileUploadDialog = true
      this.ceasesComponent.selectedFileUploadCatalogId = fileUploadCatalogId
    },
    async fileChosen (newFile) {
      this.ceasesComponent.fileDialogCheckingFile = true
      this.ceasesComponent.fileDialogSelectedFileError = ''
      var fileExtension = newFile.name.split('.').pop().toLowerCase()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xls' && fileExtension !== 'xltm' && fileExtension !== 'xlsm') {
        this.ceasesComponent.fileDialogSelectedFileError = 'Only Excel Spreadsheets can be uploaded'
      } else {
        var data = await this.iQuoteExportsAPIMixin_getDesignPackCeasesDataFromExcel(newFile.contents)

        if (data.error === undefined) {
          this.ceasesComponent.fileDialogSelectedFileError = 'Error contacting file export service. Cannot upload. Please contact your administrator for assistance'
        } else if (data.error !== '') {
          this.ceasesComponent.fileDialogSelectedFileError = data.error
        } else if (data.entries.length === 0) {
          this.ceasesComponent.fileDialogSelectedFileError = 'No Ceases Entries found on this spreadsheet'
        }
      }

      this.ceasesComponent.fileDialogCheckingFile = false
    },
    cancelUpload () {
      this.ceasesComponent.showConfirmationDialogBox = false
      DataEventBus.$emit('showUploadProgress', false)
    },
    async confirmUpload () {
      this.ceasesComponent.showConfirmationDialogBox = false
      DataEventBus.$emit('showUploadProgress', true)

      await this.setValuesFromSpreadsheet(this.ceasesComponent.uploadedFile)
    },
    async afterFileUploaded (newFile) {
      this.ceasesComponent.fileName = newFile.name
      this.ceasesComponent.uploadedFile = newFile
      this.ceasesComponent.showConfirmationDialogBox = true
    },
    setValuesFromSpreadsheet: async function (newFile) {
      var designPackCeasesData = await this.iQuoteExportsAPIMixin_getDesignPackCeasesDataFromExcel(newFile.contents)
      var designPack = this.iQuoteStore_GetDesignPack

      designPack.ceasesEntries = this.convertToCamelCase(designPackCeasesData.entries)
      this.addCeasesEntries(designPack.ceasesEntries)

      await this.iQuoteCalculationsDesignPackCeasesMixin_updateEntries(designPack.ceasesEntries)

      DataEventBus.$emit('afterUploadCompleted')

      this.ceasesComponent.ceasesCurrentTab = 'CeasesEntries'
    }
  }
}
</script>
<style scoped>
  .ceasesBox {
    border: 1px solid #808080;
    padding: 8px;
    margin-bottom: 0;
  }
  .uploadSheetBox {
    height: 425px;
    padding-top: 50px;
    margin-left: 20px;
  }
  #mainTable {
    margin-top: 15px;
    width: 100%;
    border: none;
  }

  table input[type=text], table input[type=number], table input[type=button], table select {
    border: none;
    margin: 0;
    height: 25px;
    font-size: 1em;
    width: 100%;
  }

  tr.mainHeader {
    text-align: center;
    font-size: 1.5em;
  }

  .readOnlyCell {
    background-color: silver;
  }

  .noborder {
    border: none;
  }

  #mainTable tr.disabled td {
    background-color: silver;
  }

  table.informationBoxTable {
    margin-top: 15px;
    font-size: 1.2em;
    table-layout: auto;
    width: 623px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  table.informationBoxTable th {
    background-color: #005A9C;
  }

  table.informationBoxTable td {
    border: 1px solid black;
    padding: 3px;
    vertical-align: top;
    white-space: normal;
    overflow: visible;
  }
</style>
