import stormAPI from '../communications/interceptors/apiInterceptor'
import { mapActions } from 'vuex'
export const stormMixin = {
  data () {
    return {
    }
  },
  computed: {
    adminUser () {
      return process.env.VUE_APP_STORM_ADMIN_USER
    }
  },
  methods: {
    ...mapActions([
      'clearStormStore',
      'setStormAdminToken',
      'setStormCompany',
      'setStormSecurityGroups',
      'setStormUsers',
      'setStormUser',
      'setStormUserToken',
      'setStormDashboards',
      'setStormWidgets',
      'setStormSubscriptionTiers',
      'setStormDisplayTypes',
      'setStormUserGroups'
    ]),

    /************************************
     * Auth controller methods
    *************************************/
    setupAdminStormAuthToken: async function () {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        var payload = '{"username":"' + this.adminUser + '"}'
        await stormAPI.post('storm/auth/' + companyId, payload, axiosConfig).then((response) => {
          this.setStormAdminToken(response.data)
          rtn = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    setupUserStormAuthToken: async function (Username) {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        var payload = '{"Username": "' + Username + '"}'
        await stormAPI.post('storm/auth/' + companyId, payload, axiosConfig).then((response) => {
          this.setStormUserToken(response.data)
          rtn = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getDefaultUserStormAuthToken: async function () {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        var payload = '{"Username": "' + this.$store.getters.stormDefaultUser + '"}'
        await stormAPI.post('storm/auth/' + companyId, payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getStormAuthCookie: async function () {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormUserToken
        }
      }
      try {
        await stormAPI.get('storm/auth/cookie/' + companyId, axiosConfig).then((response) => {
          rtn = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getStormCompanySysInfo: async function () {
      var rtn = 0
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormUserToken
        }
      }
      try {
        await stormAPI.get('storm/auth/sysinfo/' + companyId, axiosConfig).then((response) => {
          var result = response.data[0]
          if (result.error !== null) {
            console.log(result.error)
          } else {
            rtn = result.webUserLimit
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getupAdminStormAuthTokenForCompany: async function (companyId) {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        var payload = '{"username":"' + this.adminUser + '"}'
        await stormAPI.post('storm/auth/' + companyId, payload, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },

    /************************************
     * Company controller methods
    *************************************/
    setupStormCompany: async function (clear) {
      var rtn = false
      // When we change company, we want to clear all existing Storm store variables
      if (clear) {
        this.clearStormStore()
      }
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/companies/' + companyId, axiosConfig).then((response) => {
          if (response.data.length > 0) {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('setupStormCompany call failed with: ' + response.data)
            } else {
              this.setStormCompany(response.data[0])
              rtn = true
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getStormCompanies: async function () {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/companies/', axiosConfig).then((response) => {
          if (response.data.length > 0) {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('getStormCompanies call failed with: ' + response.data)
            } else {
              rtn = response.data
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    addStormCompany: async function (payload) {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.post('storm/companies/', payload, axiosConfig).then((response) => {
          if (response.data.length > 0) {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('addStormCompany call failed with: ' + response.data)
            } else {
              rtn = response.data
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    deleteStormCompany: async function (companyId) {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.delete('storm/companies/' + companyId, axiosConfig).then((response) => {
          if (response.data.length > 0) {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('deleteStormCompany call failed with: ' + response.data)
            } else {
              rtn = response.data
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    updateStormCompany: async function (payload) {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.post('storm/companies/', payload, axiosConfig).then((response) => {
          if (response.data.length > 0) {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('updateStormCompany call failed with: ' + response.data)
            } else {
              rtn = response.data
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },

    /************************************
     * Subscription Tier controller methods
    *************************************/
    setupStormSubscriptionTiers: async function () {
      var rtn = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/SubscriptionTiers/', axiosConfig).then((response) => {
          if (response.data.length > 0) {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('setupStormSubscriptionTiers call failed with: ' + response.data)
            } else {
              this.setStormSubscriptionTiers(response.data)
              rtn = true
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },

    /************************************
     * Display Types controller methods
    *************************************/
    setupStormDisplayTypes: async function () {
      var rtn = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/Displaytypes/', axiosConfig).then((response) => {
          if (response.data.length > 0) {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('setupStormDisplayTypes call failed with: ' + response.data)
            } else {
              this.setStormDisplayTypes(response.data)
              rtn = true
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },

    /************************************
     * User controller methods
    *************************************/
    setupStormUsers: async function () {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/users/' + companyId, axiosConfig).then((response) => {
          if (response.data.length === 0) {
            this.setStormUsers([])
          } else {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('getStormUsers call failed with: ' + response.data)
            } else {
              this.setStormUsers(response.data)
              rtn = true
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    addStormUser: async function (user) {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var desc = user.description === null ? 'null' : '"' + user.description + '"'
      var phone = user.phoneNumber === null ? 'null' : '"' + user.phoneNumber + '"'
      var uri = user.uri === null ? 'null' : '"' + user.uri + '"'
      var payload = '{"Id":0, "Name":"' + user.name + '", ' +
        '"Description":' + desc + ', ' +
        '"AccountName":"' + user.accountName + '", ' +
        '"Password":"' + user.password + '", ' +
        '"HasSystemAccount":' + user.hasSystemAccount + ', ' +
        '"SystemOnly":' + user.systemOnly + ', ' +
        '"TimeZone":"' + user.timeZone + '", ' +
        '"Forename":"' + user.forename + '", ' +
        '"Surname":"' + user.surname + '", ' +
        '"Email":"' + user.email + '", ' +
        '"PhoneNumber":' + phone + ', ' +
        '"Uri":' + uri + ', ' +
        '"AccountDisabled":' + user.accountDisabled +
      '}'
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormUserToken
        }
      }
      try {
        await stormAPI.post('storm/users/' + companyId, payload, axiosConfig).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('addStormUser call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    updateStormUser: async function (user) {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      // The password field is left empty so that it is not updated in this call.
      var desc = user.description === null ? 'null' : '"' + user.description + '"'
      var phone = user.phoneNumber === null ? 'null' : '"' + user.phoneNumber + '"'
      var uri = user.uri === null ? 'null' : '"' + user.uri + '"'
      var payload = '{"Id": ' + user.id +
        ', "Name":"' + user.name + '"' +
        ', "Description":' + desc +
        ', "AccountName":"' + user.accountName + '"' +
        ', "Password":""' +
        ', "HasSystemAccount":' + user.hasSystemAccount +
        ', "SystemOnly":' + user.systemOnly +
        ', "TimeZone":"' + user.timeZone + '"' +
        ', "Forename":"' + user.forename + '"' +
        ', "Surname":"' + user.surname + '"' +
        ', "Email":"' + user.email + '"' +
        ', "PhoneNumber":' + phone +
        ', "Uri":' + uri +
        ', "AccountDisabled":' + user.accountDisabled +
      '}'
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'CustomerId': this.$store.getters.currentCustomer.id,
          'StormAuthorization': this.$store.getters.stormUserToken
        }
      }
      try {
        await stormAPI.put('storm/users/' + companyId, payload, axiosConfig).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('updateStormUser call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    deleteStormUser: async function (userId) {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      try {
        await stormAPI.delete('storm/users/' + companyId + '/' + userId, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'StormAuthorization': this.$store.getters.stormUserToken
          }
        }).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('deleteStormUser call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    addStormUserToCompany: async function (user, companyId, stormUserToken) {
      var rtn = false
      var desc = user.description === null ? 'null' : '"' + user.description + '"'
      var phone = user.phoneNumber === null ? 'null' : '"' + user.phoneNumber + '"'
      var uri = user.uri === null ? 'null' : '"' + user.uri + '"'
      var payload = '{"Id":0, "Name":"' + user.name + '", ' +
        '"Description":' + desc + ', ' +
        '"AccountName":"' + user.accountName + '", ' +
        '"Password":"' + user.password + '", ' +
        '"HasSystemAccount":' + user.hasSystemAccount + ', ' +
        '"SystemOnly":' + user.systemOnly + ', ' +
        '"TimeZone":"' + user.timeZone + '", ' +
        '"Forename":"' + user.forename + '", ' +
        '"Surname":"' + user.surname + '", ' +
        '"Email":"' + user.email + '", ' +
        '"PhoneNumber":' + phone + ', ' +
        '"Uri":' + uri + ', ' +
        '"AccountDisabled":' + user.accountDisabled +
      '}'
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': stormUserToken
        }
      }
      try {
        await stormAPI.post('storm/users/' + companyId, payload, axiosConfig).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('addStormUser call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getStormUsers: async function (companyId, stormAdminToken) {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/users/' + companyId, axiosConfig).then((response) => {
          if (response.data.length > 0) {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('getStormUsers call failed with: ' + response.data)
            } else {
              rtn = response.data
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },

    /************************************
     * Security Group controller methods
    *************************************/
    setupStormSecurityGroups: async function () {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/securitygroups/' + companyId, axiosConfig).then((response) => {
          if (response.data.length === 0) {
            this.setStormSecurityGroups([])
          } else {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('setupStormSecurityGroups call failed with: ' + response.data)
            } else {
              this.setStormSecurityGroups(response.data)
              rtn = true
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getStormSecurityGroupsByUser: async function (userId) {
      var rtn = []
      if (userId !== undefined) {
        var companyId = this.$store.getters.currentCustomer.companyId
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'StormAuthorization': this.$store.getters.stormAdminToken
          }
        }
        try {
          await stormAPI.get('storm/securitygroups/' + companyId + '/' + userId, axiosConfig).then((response) => {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('getStormSecurityGroupsByUser call failed with: ' + response.data)
            } else {
              rtn = response.data
            }
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return rtn
    },
    addUserToStormSecurityGroup: async function (userId, groupId) {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var payload = '{"CompanyId": ' + companyId + ', "UserId": ' + userId + ', "SecurityGroupId": ' + groupId + '}'
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormUserToken
        }
      }
      try {
        await stormAPI.post('storm/securitygroups', payload, axiosConfig).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('addUserToStormSecurityGroup call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    deleteUserFromStormSecurityGroup: async function (userId, groupId) {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      try {
        await stormAPI.delete('storm/securitygroups', {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'StormAuthorization': this.$store.getters.stormUserToken
          },
          data: {
            CompanyId: companyId, UserId: userId, SecurityGroupId: groupId
          }
        }).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('deleteUserFromStormSecurityGroup call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getStormSecurityGroups: async function (companyId, stormAdminToken) {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/securitygroups/' + companyId, axiosConfig).then((response) => {
          if (response.data.length === 0) {
          } else {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('setupStormSecurityGroups call failed with: ' + response.data)
            } else {
              rtn = response.data
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    addUserToStormSecurityGroupForCompany: async function (userId, groupId, companyId, adminAuthToken) {
      var rtn = false
      var payload = '{"CompanyId": ' + companyId + ', "UserId": ' + userId + ', "SecurityGroupId": ' + groupId + '}'
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': adminAuthToken
        }
      }
      try {
        await stormAPI.post('storm/securitygroups', payload, axiosConfig).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('addUserToStormSecurityGroupForCompany call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },

    /************************************
     * User Group controller methods
    *************************************/
    setupStormUserGroups: async function () {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/usergroups/' + companyId, axiosConfig).then((response) => {
          if (response.data.length === 0) {
            this.setStormUserGroups([])
          } else {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('setupStormUserGroups call failed with: ' + response.data)
            } else {
              this.setStormUserGroups(response.data)
              rtn = true
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getStormUserGroupsByUser: async function (userId) {
      var rtn = []
      if (userId !== undefined) {
        var companyId = this.$store.getters.currentCustomer.companyId
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'StormAuthorization': this.$store.getters.stormAdminToken
          }
        }
        try {
          await stormAPI.get('storm/usergroups/' + companyId + '/' + userId, axiosConfig).then((response) => {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('getStormUserGroupsByUser call failed with: ' + response.data)
            } else {
              rtn = response.data
            }
          })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
        }
      }
      return rtn
    },
    addUserToStormUserGroup: async function (userId, groupId) {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var payload = '{"CompanyId": ' + companyId + ', "UserId": ' + userId + ', "UserGroupId": ' + groupId + '}'
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormUserToken
        }
      }
      try {
        await stormAPI.post('storm/usergroups', payload, axiosConfig).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('addUserToStormUserGroup call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    deleteUserFromStormUserGroup: async function (userId, groupId) {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      try {
        await stormAPI.delete('storm/usergroups', {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'LoggedInAs': this.$store.getters.userid,
            'StormAuthorization': this.$store.getters.stormUserToken
          },
          data: {
            CompanyId: companyId, UserId: userId, UserGroupId: groupId
          }
        }).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('deleteUserFromStormUserGroup call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getStormUserGroups: async function (companyId, stormAdminToken) {
      var rtn = []
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/usergroups/' + companyId, axiosConfig).then((response) => {
          if (response.data.length > 0) {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('setupStormUserGroups call failed with: ' + response.data)
            } else {
              rtn = response.data
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    addUserToStormUserGroupForCompany: async function (userId, groupId, companyId, adminAuthToken) {
      var rtn = false
      var payload = '{"CompanyId": ' + companyId + ', "UserId": ' + userId + ', "UserGroupId": ' + groupId + '}'
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': adminAuthToken
        }
      }
      try {
        await stormAPI.post('storm/usergroups', payload, axiosConfig).then((response) => {
          if (response.data.indexOf('Error: ') === 0) {
            console.log('addUserToStormUserGroupForCompany call failed with: ' + response.data)
          } else {
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },

    /************************************
     * Dashboard controller methods
    *************************************/
    setupStormDashboards: async function () {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormUserToken
        }
      }
      try {
        await stormAPI.get('storm/dashboards/' + companyId, axiosConfig).then((response) => {
          if (response.data.length === 0) {
            this.setupDefaultStormDashboards()
          } else {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('setupStormDashboards call failed with: ' + response.data)
            } else {
              this.setStormDashboards(response.data)
              rtn = true
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    setupDefaultStormDashboards: async function () {
      var rtn = false
      var userToken = await this.getDefaultUserStormAuthToken()
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': userToken
        }
      }
      try {
        await stormAPI.get('storm/dashboards/' + companyId, axiosConfig).then((response) => {
          if (response.data.length === 0) {
            this.setStormDashboards([])
          } else {
            if (response.data.indexOf('Error: ') === 0) {
              console.log('setupDefaultStormDashboards call failed with: ' + response.data)
            } else {
              this.setStormDashboards(response.data)
              rtn = true
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },

    /************************************
     * Widget controller methods
    *************************************/
    setupStormWidgets: async function (dashboardId) {
      var rtn = false
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormUserToken
        }
      }
      try {
        await stormAPI.get('storm/widgets/dashboard/' + companyId + '/' + dashboardId, axiosConfig).then((response) => {
          if (response.data.error !== null) {
            this.setStormWidgets([])
            console.log('setupStormWidgets call failed with: ' + response.data.error)
          } else {
            this.setStormWidgets(response.data)
            rtn = true
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    getWidgetById: async function (widgetId) {
      var rtn = {}
      var companyId = this.$store.getters.currentCustomer.companyId
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid,
          'StormAuthorization': this.$store.getters.stormAdminToken
        }
      }
      try {
        await stormAPI.get('storm/widgets/widget/' + companyId + '/' + widgetId, axiosConfig).then((response) => {
          if (response.data.error !== null) {
            console.log('getStormSecurityGroupsByUser call failed with: ' + response.data)
          } else {
            rtn = response.data
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    }
  }
}
