// This module is used for state mamangement for site and circuit related variables

const state = {
  sites: [],
  circuits: [],
  ratings: []
}

const mutations = {
  SET_SITES (state, sites) {
    state.sites = sites
  },
  SET_CIRCUITS (state, circuits) {
    state.circuits = circuits
  },
  SET_RATINGS (state, ratings) {
    state.ratings = ratings
  }
}

const actions = {
  setSites: ({ commit }, sites) => {
    commit('SET_SITES', sites)
  },
  setSite: ({ commit }, site) => {
    var sites = state.sites
    var index = sites.findIndex(x => x.id === site.id)
    sites[index] = site
    commit('SET_SITES', sites)
  },
  clearSites: ({ commit }) => {
    commit('SET_SITES', null)
  },
  setCircuits: ({ commit }, circuits) => {
    commit('SET_CIRCUITS', circuits)
  },
  setCircuit: ({ commit }, circuit) => {
    var circuits = state.circuits
    var index = circuits.findIndex(x => x.id === circuit.id)
    circuits[index] = circuit
    commit('SET_CIRCUITS', circuits)
  },
  clearCircuits: ({ commit }) => {
    commit('SET_CIRCUITS', null)
  },
  setRatings: ({ commit }, ratings) => {
    commit('SET_RATINGS', ratings)
  },
  setRating: ({ commit }, rating) => {
    var ratings = state.ratings
    var index = ratings.findIndex(x => x.id === rating.id)
    ratings[index] = rating
    commit('SET_RATINGS', ratings)
  },
  clearRatings: ({ commit }) => {
    commit('SET_RATINGS', null)
  }
}

const getters = {
  sites: state => {
    return state.sites
  },
  site: (state) => (id) => {
    return state.sites.filter(x => x.id === id)[0]
  },
  circuits: state => {
    return state.circuits
  },
  circuit: (state) => (id) => {
    return state.circuits.filter(x => x.id === id)[0]
  },
  ratings: (state) => {
    return state.ratings
  },
  rating: (state) => (id) => {
    return state.ratings.filter(x => x.id === id)[0]
  },
  siteHighlight: state => {
    if (!state.sites) { return '' }
    if (state.sites.findIndex(x => x.raised && x.rating === 3) > -1) { return 'Red' }
    if (state.sites.findIndex(x => x.raised && x.rating === 2) > -1) { return 'Amber' }
    if (state.sites.findIndex(x => x.raised && x.rating === 1) > -1) { return 'Green' }
    return ''
  }
}

// This export is used to allow the vuex components to extend ito the global state
export default {
  state,
  mutations,
  actions,
  getters
}
