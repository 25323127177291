<template>
  <div class="ticketDetailContainer">
    <Spinner v-if="saving" />
    <div v-if="!saving">

      <div class="parentCreateTicketForm">
        <h3
          id="PersonalSectionHeader"
          :class="[showTicketDetailSection ? 'expanded' : '']"
          @click="() => { showTicketDetailSection = !showTicketDetailSection }"
        >
          Ticket Details{{ !ticketDetailSectionCompleted ? ' - Compulsory fields indicated with a red asterix.' : '' }}
          <span class="statuscontainer">
            <span
              :class="[ticketDetailSectionCompleted ? 'completeSection' : 'incompleteSection']"
              :title="[ticketDetailSectionCompleted ? 'Section Complete' : 'Section Incomplete']"
            />
            <span class="background" />
          </span>
          <span
            :class="[showTicketDetailSection ? 'minimiseSection' : 'maximiseSection']"
          />
        </h3>
        <transition name="slide">
          <div
            v-if="showTicketDetailSection"
            class="createTicketLeft"
          >
            <label
              for="txtTitle"
              class="createTicketLabel"
            >Title<span class="redAsterix">*</span>:</label>
            <input
              id="txtTitle"
              :class="{ errorHighlight: titleError }"
              v-model="ticket.Title"
              type="text"
              class="createTicketTextbox ticketTitle"
              tabindex="2"
              maxlength="255"
              @blur="checkForm"
            >
            <label
              for="txtDescription"
              class="createTicketTextareaLabel"
            >Description<span class="redAsterix">*</span>:</label>
            <textarea
              id="txtDescription"
              :class="{ errorHighlight: descriptionError }"
              v-model="ticket.Description"
              type="text"
              class="createTicketTextbox ticketDescription"
              tabindex="3"
              maxlength="5000"
              @blur="checkForm"
            />
          </div>
        </transition>
        <transition name="slide">
          <div
            v-if="showTicketDetailSection"
            class="createTicketLeft"
          >
            <div>
              <label
                for="cboSubIssueType"
                class="createTicketLabel"
              >Sub Issue Type<span class="redAsterix">*</span>:</label>
              <select
                id="cboSubIssueType"
                :class="{ errorHighlight: subIssueTypeError }"
                v-model="subIssueTypeId"
                class="createTicketTextbox ticketPriority"
                tabindex="6"
                @blur="checkForm"
                @change="selectSubIssueType"
              >
                <option
                  v-for="subIssueType in subIssueTypes"
                  :key="subIssueType.id"
                  :value="subIssueType.id"
                  :title="subIssueType.name"
                >{{ subIssueType.name }}</option>
              </select>
            </div>
            <div>
              <label
                for="txtDPid"
                class="createTicketLabel"
              >Design Pack Id:</label>
              <input
                id="txtDPid"
                v-model="designPackId"
                class="infoInput"
                type="text">
            </div>
            <div>
              <label
                for="txtSFORnumber"
                class="createTicketLabel"
              >Salesforce Opportunity No:</label>
              <input
                id="txtSFORnumber"
                v-model="SFOpportunityNo"
                class="infoInput"
                type="text">
            </div>
          </div>
        </transition>
      </div>
      <div class="parentCreateTicketForm">
        <h3
          id="attachmentSectionHeader"
          :class="[showAttachmentSection ? 'expanded' : '']"
          @click="() => { showAttachmentSection = !showAttachmentSection }"
        >
          Attachment
          <span class="statuscontainer">
            <span
              :class="[attachmentSectionCompleted ? 'completeSection' : 'incompleteSection']"
              :title="[attachmentSectionCompleted ? 'Section Complete' : 'Section Incomplete']"
            />
            <span class="background" />
          </span>
          <span
            :class="[showAttachmentSection ? 'minimiseSection' : 'maximiseSection']"
          />
        </h3>
        <transition
          v-if="showAttachmentSection"
          name="slide"
        >
          <div class="divNewAttachmentContainer">
            <AttachFiles
              :show="showAttachmentSection"
              @recipients-change="updateRecipients"
              @updated="filesUpdated"
            />
          </div>
        </transition>
      </div>
      <div class="buttonHolder">
        <TextButton
          :status="true"
          :saving="false"
          :disabled="!formOK"
          title="Save and Close"
          text="Save and Close"
          @click="submitForm()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '../../common/spinner'
import AttachFiles from '../../tickets/attachFiles'
import PortalDropdown from '../../common/portalDropdown'
import TextButton from '../../common/textButton'
import { autotaskAPIMixin } from '../../../mixins/autotaskAPIMixin'
import { autotaskServiceMixin } from '../../../mixins/autotaskServiceMixin'
import { ticketMixin } from '../../../mixins/ticket'
import { mapActions } from 'vuex'

export default {
  name: 'CreateTicket',
  components: {
    Spinner,
    AttachFiles,
    PortalDropdown,
    TextButton
  },
  mixins: [
    autotaskAPIMixin,
    autotaskServiceMixin,
    ticketMixin
  ],
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true,
      note: 'If the dialog is shown or not.'
    }
  },
  data () {
    return {
      ticket: {
        Title: '',
        Description: '',
        PriorityId: null,
        TicketTypeId: null,
        CategoryId: null,
        AccountId: null,
        ContactID: null,
        ContractID: 0,
        ServiceLevelAgreementID: null,
        CustomFields: []
      },
      files: [],
      titleError: false,
      descriptionError: false,
      subIssueTypeError: false,
      isSaving: false,
      oldDescription: '',
      showTicketDetailSection: true,
      showAttachmentSection: false,
      ticketDetailSectionCompleted: false,
      attachmentSectionCompleted: true,
      saving: false,
      formOK: false,
      recipients: '',
      subIssueTypeId: 0,
      designPackId: '',
      SFOpportunityNo: ''
    }
  },
  computed: {
    subIssueTypes () {
      return [
        { 'id': 477, 'name': 'iQuote (Design Pack)' },
        { 'id': 476, 'name': 'iQuote (Quotations)' },
        { 'id': 478, 'name': 'iQuote (Other)' }
      ]
    },
    autotaskContactID () {
      if (this.$store.getters.autotaskContact.length === 0) {
        return 0
      } else {
        return this.$store.getters.autotaskContact[0].id
      }
    }
  },
  watch: {
    dialog () {
      if (this.dialog) {
        this.clearForm()
      }
    }
  },
  mounted () {
    this.ticket.Title = ''
    this.ticket.Description = ''
  },
  methods: {
    ...mapActions([
      'setTickets'
    ]),
    setForm () {
      this.showTicketDetailSection = true
    },
    checkForm (elem) {
      this.subIssueTypeError = this.subIssueTypeId === 0
      if (elem.target.id === 'txtTitle') {
        this.titleError = this.ticket.Title.length === 0
      }
      if (elem.target.id === 'txtDescription') {
        this.descriptionError = this.ticket.Description.length === 0
      }

      this.formOK = !this.subIssueTypeError && !this.titleError && !this.descriptionError
      this.ticketDetailSectionCompleted = this.formOK
    },
    /**********************************************
    * This is where the ticket is physically
    * added into Autotask. The process followed
    * is:
    * 1. Get the payload for the new ticket.
    * 2. resolve the contact and add to the payload.
    * 3. Send the ticket to Autotask API service.
    * 4. If successful:
    *   a. Upload the associated file(s).
    *   b. Clear and close the page.
    *   c. Add the ticket to the Autotask Database.
    * 5. If not:
    *   a. Set the old Description back in place.
    **********************************************/
    submitForm: async function () {
      this.saving = true
      if (this.formOK && this.ticket) {
        var newTicket = this.getNewTicketPayload()

        var resolvedContactId = await this.setNewContact(this.ticket.AccountId)
        if (isNaN(resolvedContactId) && this.ticket.ContactID === 0) {
          this.$store.commit('showMessage', { content: 'Failed to resolve user as an Autotask contact with: ' + resolvedContactId, color: 'red', timeout: 5000 })
          // Switch off the "saving" flag or the screen appears to hang
          this.saving = false
          return
        } else {
          // If we do not get a contact id returned, then the resolution of contact and the adding a new contact have failed.
          if (resolvedContactId === 0) {
            this.$store.commit('showMessage', { content: 'Failed to get or add Autotask contact for user: ' + this.$store.getters.currentUser.emailAddress, color: 'red', timeout: 5000 })
            // Switch off the "saving" flag or the screen appears to hang
            this.saving = false
            return
          }
        }
        // If we have reached this far then we have a valid contact id for the site the ticket is being added to and the ticket payload is complete.
        this.ticket.ContactID = resolvedContactId

        // console.log(JSON.stringify(newTicket))
        var result = await this.addNewTicket(newTicket)
        if (!result.itemId) {
          console.log('Ticket failure: ' + result)
          this.ticket.Description = this.oldDescription
          this.$store.commit('showMessage', { content: 'New ticket failed to create with: ' + result, color: 'red', timeout: 3000 })
        } else {
          var ticketId = result.itemId
          console.log('New ticket id: ' + ticketId)
          await this.uploadFiles(ticketId)
          this.$store.commit('showMessage', { content: 'New ticket created successfully.', color: 'green', timeout: 3000 })
          await this.addCreatedTicket(ticketId)

          // Save and Close
          this.clearFormAndClose()
        }
      } else {
        this.$store.commit('showMessage', { content: 'The form failed to check correctly.', color: 'orange', timeout: 3000 })
      }
      this.saving = false
    },
    selectSubIssueType: function (subIssueType) {
      if (subIssueType.target) {
        if (subIssueType.target.value) {
          this.subIssueTypeId = subIssueType.target.value
        }
      }
    },
    getNewTicketPayload () {
      var newTicket = this.ticket
      newTicket.Title = newTicket.Title.replace(RegExp('\'', 'g'), '`')
      newTicket.Description = newTicket.Description.replace(RegExp('\'', 'g'), '`')
      this.oldDescription = newTicket.Description
      if (this.designPackId.length > 0) {
        newTicket.Description = newTicket.Description + '\r\nDesign Pack Id: ' + this.designPackId
      }
      if (this.SFOpportunityNo.length > 0) {
        newTicket.Description = newTicket.Description + '\r\nSalesforce Opportunity Number: ' + this.SFOpportunityNo
      }
      newTicket.SubIssueTypeId = parseInt(this.subIssueTypeId)
      // The issue type of iQuote is always 86
      newTicket.IssueTypeId = 86
      // Always set to P3 - Medium priority (8)
      newTicket.PriorityId = 8
      // Always set category to standard (3)
      newTicket.CategoryId = 3
      // Always set the ticket type to problem (3)
      newTicket.TicketTypeId = 3
      // Always set the account (site) to maintel
      newTicket.AccountId = 8194
      return newTicket
    },
    filesUpdated (files) {
      this.files = files
    },
    uploadFiles: async function (ticketId) {
      if (this.files.length > 0) {
        var autotaskId = null
        if (autotaskId === null) {
          autotaskId = this.ticket.ContactID
        }
        if (autotaskId === null && this.$store.getters.autotaskContact.length > 0) {
          autotaskId = this.$store.getters.autotaskContact[0].id
        }
        for (let i = 0; i < this.files.length; i++) {
          var data = this.files[i].file
          var title = this.files[i].title
          if (title.length === 0) { title = this.files[i].name }
          if (data.indexOf(',') > -1) {
            data = data.substring(data.indexOf(',') + 1, data.length)
          }
          var payload = {
            name: title,
            fileName: this.files[i].name,
            data: data,
            ContactID: autotaskId,
            isNew: true
          }
          var rtn = await this.addTicketAttachment(ticketId, payload)
          if (rtn === 'error') {
            this.$store.commit('showMessage', { content: 'The ticket attachments failed to add correctly.', color: 'red', timeout: 3000 })
          } else {
            console.log('New Attachment id: ' + rtn.itemId)
            this.$store.commit('showMessage', { content: 'The ticket attachments added correctly.', color: 'green', timeout: 3000 })
            if (this.recipients && this.recipients.length > 0) {
              this.sendNotification(ticketId, this.recipients)
            }
          }
        }
        this.files = []
      }
    },
    addCreatedTicket: async function (ticketId) {
      var ticket = await this.getTicketById(ticketId)
      if (ticket.ticketNumber !== null) {
        await this.addAutotaskServiceTicket(ticket)
        var tickets = this.$store.getters.tickets
        tickets.push(ticket)
        this.setTickets(tickets)
      }
    },
    clearForm () {
      this.ticket.Title = ''
      this.ticket.Description = ''
      this.ticket.PriorityId = null
      this.ticket.TicketTypeId = null
      this.ticket.CategoryId = null
      this.ticket.AccountId = null
      this.ticket.ContactID = null
      this.configurationItem = {}
      this.configurationItemId = null
      this.requestTypeId = null
      this.configurationItems = []
      this.configurationItemTypes = []
      this.priorities = []
      this.showTicketDetailSection = true
      this.showCustomFieldsSection = false
      this.showAttachmentSection = false
      this.showExtraFieldsSection = false
      this.requestTypeSectionCompleted = false
      this.ticketDetailSectionCompleted = false
      this.customFieldsSectionCompleted = false
      this.subIssueTypeId = 0
      this.mobileNetwork = 0
      this.designPackId = ''
      this.SFOpportunityNo = ''
      this.formOK = false
    },
    clearFormAndClose () {
      this.clearForm()
      this.$emit('close', false)
    },
    sendNotification: async function (ticketId, addresses) {
      var Title = 'New Files Attached To Ticket:'
      var body = 'Files added :<br/>'
      for (let i = 0; i < this.files.length; i++) {
        body += this.files[i].name + '<br/>'
      }
      var ticket = await this.getTicketById(ticketId)
      await this.sendResourceNotification(addresses, Title, body, ticket)
    },
    updateRecipients (val) {
      this.recipients = val
    }
  }
}
</script>

<style scoped>
  .headerWarning {
    color: red;
    font-weight: 500;
    margin-top: -15px;
    line-height: 2.5em;
  }
  .textAreaInput {
    display:inline-block;
    border: solid 1px #9aa8b1;
    border-radius:5px;
    width:calc(100% - 20px);
    padding:0 5px;
  }
  .textAreaInputError {
    display:inline-block;
    border: solid 1px red;
    border-radius:5px;
    width:calc(100% - 20px);
    padding:0 5px;
  }
  .buttonHolder {
    width:100%;
    text-align: right;
  }
  select {
    border-radius: 5px;
  }
  .dropdown  {
    border-radius: 5px;
  }
  .fieldLabel {
    display: inline-block;
    font-weight: bold;
    width: 240px;
    padding-left: 5px;
    margin-bottom: 10px;
  }
  .ticketDetailContainer {
    padding: 0 10px 0 10px;
  }
  .createTicketRight {
    width: 50%;
    display: inline-block;
    padding: 5px 5px 0 5px;
  }
  .createTicket {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .errorHighlight {
    border: 1px solid red!important;
  }
  .parentCreateTicketForm{
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .createTicketLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
  }
  .createTicketTextareaLabel {
    width: 30%;
    line-height: 2em;
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
  }
  .createTicketTextbox {
    width: 68%;
    padding: 0 5px;
    display: inline-block;
  }
  textarea {
    border-radius: 5px;
    border: solid 1px #9aa8b1;
  }
  .outline {
    border: solid 1px #9aa8b1;
  }
  .createTicketData {
    width: 145px;
    display: inline-block;
  }
  .createSectionData {
    position: absolute;
    bottom: 36px;
    top: 0;
    right: 0;
    background-color: #fff;
    left: 0;
    z-index: 1;
    margin-right: 3vw;
  }
  .createTicketHolder {
    background-color: rgba(255,255,255,0.7);
    padding: 0 15px 0 10px;
    overflow-y: auto;
  }
  .cancelTicketButton {
    background-image: url('../../../assets/icons/cancel.svg');
    width: 45px;
    height: 45px;
    border: 0 !important;
  }
  .createTicketButton {
    background-image: url('../../../assets/icons/check-tick-disabled.svg');
    cursor: default;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .createTicketButtonEnabled {
    background-image: url('../../../assets/icons/check-tick.svg');
    cursor: pointer;
    width: 38px;
    height: 38px;
    padding: 0;
    border: 0 !important;
    margin: 0 8px 9px 0;
  }
  .createTicketLeft {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .createTicketRight {
    width: calc(50% - 5px);
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .tickeRecipientsTextarea {
    border-radius: 5px;
    border: solid 1px #9aa8b1;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    padding: 0 5px;
    width:100%;
    size:1;
  }
  .tickeRecipientsTextareaError {
    border-radius: 5px;
    border: solid 1px red;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    width:100%;
    size:1;
  }
  .divNewAttachmentContainer {
    padding: 20px;
    background-color: #e8f5e9;
    border: solid #9aa8b1 1px;
  }
  .bottombar {
    text-align: right;
  }
  .slide-enter-active, .slide-leave-active {
    transition: max-height .2s ease-out;
  }

  .slide-enter, .slide-leave-to {
    max-height: 0px;
  }

  .slide-enter-to, .slide-leave {
    max-height: 200px;
  }

  h3 {
    cursor: pointer;
    background: #274a5a;
    width: 100%;
    padding: 5px;
    margin: 0;
    color: #fff;
    border-radius: 5px;
  }
  .expanded {
    border-radius: 5px 5px 0 0;
  }

  input {
    border-radius: 5px;
  }
  textarea {
    border-radius: 5px;
  }

  .completeSection {
    background-image: url('../../../assets/icons/check-tick.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .incompleteSection {
    background-image: url('../../../assets/icons/cancel.svg');
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  span.statuscontainer {
    float: left;
    margin-right: 5px;
  }

  span.statuscontainer .background{
    width: 15px;
    background-color: #fff;
    height: 15px;
    display: inherit;
    margin-left: 5px;
    margin-top: 6px;
  }

  .minimiseSection {
    background-image: url('../../../assets/icons/up_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .maximiseSection {
    background-image: url('../../../assets/icons/down_arrow.png');
    background-position: center;
    width: 27.5px;
    height: 27.5px;
    display: block;
    float: right;
  }

  .formContent {
    overflow-y: auto;
  }
  select {
    -webkit-appearance: menulist;
    border: solid 1px #9aa8b1;
    height: 26px;
  }
  #configurationItemID {
    width: calc(100% - 260px);
  }
  .redAsterix {
    color: red;
    padding: 0 4px;
  }
  .infoInput {padding: 0 5px;}
</style>
