<template>
  <div
    :id="id"
    class="statusHolder"
  >
    <div
      v-if="siteRatingMarkers.length === 0"
      class="noStatusHolder"
    >{{ defaultGridText }}</div>
    <table v-if="siteRatingMarkers.length > 0">
      <thead>
        <tr>
          <th>Date</th>
          <th>Status</th>
          <th>Equipment Ref</th>
          <th style="width:26px;" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="v in siteRatingMarkers"
          :key="v.id"
          :class="getRowColour(v.Status)"
        >
          <td>{{ v.Date }}</td>
          <td>{{ v.Status }}</td>
          <td>{{ v.Reference }}</td>
          <td style="text-align: right; padding: 5px 5px 0 0;">
            <img
              :src="highlightIcon"
              class="openIcon"
              title="Open in Highlight"
              @click="highlightLink"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import PortalGrid from '../common/portalGrid'
export default {
  components: {
    PortalGrid
  },
  props: {
    ratingColumns: {
      type: Array,
      default: function () { return [] }
    },
    siteRatingMarkers: {
      type: Array,
      default: function () { return [] }
    },
    id: {
      type: String,
      default: ''
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    highlightIcon () {
      return require(`../../assets/icons/highlight-icon-2.png`)
    },
    defaultGridText () {
      return `No Event Alarms (Past 7 days)`
    }
  },
  methods: {
    highlightLink () {
      window.open('https://maintel.highlighter.net', '_blank')
    },
    getRowColour (status) {
      if (!this.useAltColour) {
        return 'Solid' + status
      } else {
        return status
      }
    }
  }
}
</script>
<style scoped>
  .statusHolder {
    display: none;
    max-height: 350px;
    overflow: auto;
    background-color: rgba(255,255,255,0.7);
    width: 100%;
    padding: 10px 5px 10px 5px;
    margin: 0 0 10px 0;
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .noStatusHolder {
    text-align: center;
    font-weight: bold;
  }
  .openIcon {
    width: 20px;
    height: 20px;
  }
</style>
