<template>
  <div style="padding:20px 0 60px 20px;">

    <Spinner v-if="loading" />
    <div
      v-else
    >
      <div class="sectionHolder">
        <h2>Service Health</h2>
        <p style="font-weight:bold">Please ensure that you have VPN connected to view this page correctly</p>
        <table
          style="table-layout: auto;margin-bottom:20px;font-weight: normal;"
        >
          <thead>
            <tr>
              <th style="width:30%">Service</th>
              <th style="width:40%">Base URL</th>
              <th style="width:15%">Version</th>
              <th style="width:15%">Health</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="serviceHealth.length === 0">
              <td colspan="4">No services could be found to resolve health for</td>
            </tr>
            <tr
              v-for="h in serviceHealth"
              v-else
              :key="h.name"
              :class="[h.health === 'Call Failed' ? 'errorRow' : '']"
            >
              <td>{{ h.name }}</td>
              <td>{{ h.baseURL }}</td>
              <td
                :title="h.versionError"
              >{{ h.version }}</td>
              <td
                :title="h.healthError"
              >{{ h.health }}</td>
            </tr>
          </tbody>
        </table>
        <Spinner v-if="loadingServiceHealth" />
      </div>
      <div class="sectionHolder">
        <h2>Database Versions</h2>
        <table
          style="table-layout: auto;margin-bottom:20px;font-weight: normal;"
        >
          <thead>
            <tr>
              <th style="width:60%">Database</th>
              <th style="width:40%">Version</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="databaseVersions.length === 0">
              <td colspan="2">No databases could be found to resolve the version for</td>
            </tr>
            <tr
              v-for="d in databaseVersions"
              v-else
              :key="d.name"
              :class="[d.version === 'Not Resolved' ? 'errorRow' : '']"
            >
              <td>{{ d.name }}</td>
              <td
                :title="d.error"
              >{{ d.version }}</td>
            </tr>
          </tbody>
        </table>
        <Spinner v-if="loadingDatabaseVersions" />
      </div>
    </div>
    <div class="bottombar">
      <input
        id="backButton"
        type="button"
        class="backButton"
        title="Back to support page"
        @click="closeSystemStatus()"
      >
    </div>
  </div>
</template>
<script>
import Spinner from '../common/spinner'
import { adminMixin } from '../../mixins/adminMixin'
export default {
  metaInfo: {
    title: 'Support - Service Status'
  },
  components: {
    Spinner
  },
  mixins: [
    adminMixin
  ],
  data () {
    return {
      loading: false,
      loadingServiceHealth: false,
      loadingDatabaseVersions: false,
      serviceHealth: [],
      databaseVersions: []
    }
  },
  computed: {
  },
  mounted () {
    this.loading = true
    this.getServerStatus()
    this.loading = false
  },
  methods: {
    getServerStatus () {
      this.getServiceHealth()
      this.getDatabaseVersions()
    },
    getServiceHealth: async function () {
      this.loadingServiceHealth = true
      var services = JSON.parse(process.env.VUE_APP_SERVICES)
      this.serviceHealth = []
      for (let i = 0; i < services.length; i++) {
        var obj = {}
        obj.name = services[i].Name
        obj.baseURL = services[i].BaseURL
        obj.error = 'No errors encountered'
        obj.version = 'Not resolved'
        try {
          var result = await this.checkSystemHealth(services[i].BaseURL)
          try {
            if (typeof result === 'object') {
              obj.health = result.status
              obj.version = result.version
            } else {
              obj.health = 'Call Failed'
              obj.error = 'Invalid response from health check page'
            }
          } catch (e) {
            obj.health = 'Call Failed'
            obj.error = 'Invalid response from health check page'
          }
        } catch (err) {
          obj.health = 'Call Failed'
          obj.error = err
        }
        this.serviceHealth.push(obj)
      }
      this.loadingServiceHealth = false
    },
    getDatabaseVersions: async function () {
      this.loadingDatabaseVersions = true
      var databases = JSON.parse(process.env.VUE_APP_DATABASES)
      this.databaseVersions = []
      for (let i = 0; i < databases.length; i++) {
        var obj = {}
        obj.name = databases[i]
        try {
          var ver = await this.getDatabaseVersion(databases[i])
          if (ver.indexOf('Error:') === 0) {
            obj.version = 'Not Resolved'
            obj.error = ver
          } else {
            obj.version = ver
          }
        } catch (err) {
          obj.version = 'Not Resolved'
          obj.error = err
        }
        this.databaseVersions.push(obj)
      }
      this.loadingDatabaseVersions = false
    },
    httpGet: async function (url) {
      var response = await fetch(url)
      var rtn = await response.text()
      return rtn
    },
    closeSystemStatus: function () {
      this.$emit('setTileMode')
    }
  }
}
</script>
<style scoped>
.bottombar {
    margin:10px 0 0 0;
}
.errorRow {
  background-color: #ff9a9a;
}
</style>
