<template>
  <div class="securityHolder">
    <div class="mdContainer appSectionBody">
      <div
        :class="{ mdHalfScreen : !!selectedArea }"
        class="mdAreaManagement"
      >
        <table id="areasTable">
          <thead />
          <tbody>
            <tr
              v-for="v in currentFilter"
              :key="v.id"
              :class="getRowColour(v.id)"
              :tabindex="20"
              @click="selectArea(v)"
              @keyup="testSelectArea($event, v)"
            >
              <td :title="v.name">{{ v.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="!!selectedArea"
        class="mdSelection"
      >
        <SectionCollapsedHeader
          id="sectionAreaDetails"
          :title="areasDetailsTitle"
          type="subHeader"
          icon-file="Edit"
          controls="areaDetails"
        />
        <AreaDetails
          id="areaDetails"
          :areaid="selectedArea.id"
          :userid="userid"
        />
        <SectionCollapsedHeader
          v-if="viewEntityAreaPermission"
          id="sectionAreaRoles"
          :title="areasMembersTitle"
          type="subHeader"
          icon-file="Person"
          controls="areaMembers"
        />
        <AreaMembers
          id="areaMembers"
          :areaid="selectedArea.id"
          :userid="userid"
          :use-alt-colour="useAltColour"
        />
        <SectionCollapsedHeader
          v-if="viewRoleAreaPermission"
          id="sectionAreaMembers"
          :title="roleMembersTitle"
          :use-alt-colour="useAltColour"
          type="subHeader"
          icon-file="People"
          controls="areaRoles"
        />
        <AreaRoles
          id="areaRoles"
          :areaid="selectedArea.id"
          :userid="userid"
          :use-alt-colour="useAltColour"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AreaMembers from './areas/areaMembers'
import AreaDetails from './areas/areaDetails'
import AreaRoles from './areas/areaRoles'
import tableHandler from '../../mixins/tableHandler'
import SectionCollapsedHeader from '../../components/common/sectionCollapsedHeader'
import { permissionsMixin } from '../../mixins/permissions'
import { mapActions } from 'vuex'
export default {
  name: 'AreasPage',
  components: {
    SectionCollapsedHeader,
    AreaMembers,
    AreaDetails,
    AreaRoles
  },
  mixins: [
    permissionsMixin,
    tableHandler
  ],
  props: {
    username: {
      type: String,
      default: ''
    },
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      selectedArea: null,
      selected: null
    }
  },
  computed: {
    areasMembersTitle () {
      return 'Members of ' + this.selectedArea.name
    },
    areasDetailsTitle () {
      return 'Details for ' + this.selectedArea.name
    },
    roleMembersTitle () {
      return 'Roles at ' + this.selectedArea.name
    },
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    reloadAreas () {
      return this.$store.getters.reloadAreas
    },
    viewRoleAreaPermission () {
      return this.$store.getters.viewRoleAreaPermission
    },
    viewEntityAreaPermission () {
      return this.$store.getters.viewEntityAreaPermission
    },
    areas () {
      return this.$store.getters.sites
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    userid () {
      this.setComponent()
    },
    reloadAreas (val) {
      if (val) {
        this.setComponent()
        this.setReloadAreas(false)
      }
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setReloadAreas'
    ]),
    setComponent () {
      this.getAreas()
    },
    selectArea (selectedRow) {
      this.selectedArea = selectedRow
      if (this.selected === selectedRow.id) {
        this.selected = '-1'
        this.selectedArea = null
      } else {
        this.selected = selectedRow.id
      }
    },
    testSelectArea (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectArea(selectedRow)
      }
    },
    getAreas () {
      this.initialiseTable(
        'areasTable',
        this.areas,
        ['name'],
        ['Site Name'],
        ['100%'],
        '600px',
        null,
        18
      )
    },
    getRowColour (id) {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      if (this.selectSite === id) {
        rtn = 'itemSelected'
      }
      return rtn
    }
  }
}

</script>
<style scoped>
  .mdSelection {
    display:inline-block;
    width: 380px;
    vertical-align: top;
    margin-left: 10px;
  }
  .mdAreaManagement{
    display:inline-block;
    margin: 0;
    width:100%;
  }
  .mdAreaManagement.mdHalfScreen{
    width:calc(100% - 400px);
  }
  .securityHolder {width:100%;}
  .appSectionBody{
    display:inline-block;
    margin-top:10px;
    background: white;
    padding: 15px;
  }
  .pgContainer {
    height: calc(100vh - 280px);
  }
  .shHeader h3 {
    width: 280px;
  }
</style>
