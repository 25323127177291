<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-manageroles"
          primary-title
        >
          Managing Roles
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details managing roles details, members, permission assingments and site assignments through the security page."
                indentation="0"
              />
              <HelpSection
                instruction="1.) Navigate to the 'Security' page via the left hand navigation menu."
                indentation="0"
              />
              <HelpSection
                instruction="a.) Select the ‘Roles’ tab to view a list of all roles available to manage for the current user."
                indentation="1"
              />
              <HelpSection
                instruction="2.) Clicking the 'Add New Role' button in the top right hand corner will cause a pop-up to be returned, allowing the creation and details of a new role."
                image-source="manage_roles_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="3.) The pop-up is displayed below. The ‘Role Name’ and ‘Description’ fields are mandatory for successful creation of the new role."
                image-source="manage_roles_2.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) The Default Site is the initial site that the role will be assigned to."
                indentation="1"
              />
              <HelpSection
                instruction="4.) Upon successful creation the new role will appear in the roles list. In this example the roles list has been filtered by using the search function in the bottom right."
                image-source="manage_roles_3.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) When a role is selected, a panel will be displayed to the right with different options to manage the role."
                indentation="1"
              />
              <HelpSection
                instruction="5.) Under the 'Details for...' section, the role name and description can be updated."
                image-source="manage_roles_4.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) Both ‘Role name’ and ‘Description’ are mandatory. Upon a successful update, the newly updated details will be displayed in the roles table."
                indentation="1"
              />
              <HelpSection
                instruction="6.) Under the 'Members of...' section, users can be added or removed from this role."
                image-source="manage_roles_5.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) New users can be assigned to the role using the 'Add Member' button at the bottom of the members list, which displays the user list in a pop-up. Only users who are not currently assigned to the role will appear in this list."
                image-source="manage_roles_6.png"
                indentation="1"
              />
              <HelpSection
                instruction="b.) Once a new user has been assigned to the role, they will be shown in the role member list."
                image-source="manage_roles_7.png"
                indentation="1"
              />
              <HelpSection
                instruction="c.) Users can be removed from the role by clicking the bin icon against their user record in the list. This will remove the role and associated permissions from the user."
                indentation="1"
              />
              <HelpSection
                instruction="7.) Under the 'Sites for...' section, the sites assigned to the role can be managed."
                image-source="manage_roles_8.png"
                indentation="0"
              />
              <HelpSection
                instruction="a.) New sites can be assigned to the role using the 'Add Site’ button at the bottom of the sites list, which displays the site assignment list in a pop-up. Only sites that are not currently assigned to the role will appear in this list."
                indentation="1"
              />
              <HelpSection
                instruction="b.) Once a new site has been assigned to the role, they will be shown in the role sites list."
                image-source="manage_roles_10.png"
                indentation="1"
              />
              <HelpSection
                instruction="c.) Sites can be removed from the role by using the bin button against the site record in the list, this will remove the role and associated permissions from the site."
                indentation="1"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpManageUsers',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
