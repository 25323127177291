<template>
  <div
    :id="id"
    class="permissionRoleMembersHolder"
  >
    <Spinner
      v-if="loading"
      id="permissionRoleSpinner"
    />
    <div
      v-if="!loading"
      id="permissionRoles"
      class="prmContainer"
    >
      <table
        id="permissionRolesTable"
        style="width:100%"
      >
        <thead>
          <tr>
            <th style="width:50%;">Role</th>
            <th style="width:16%;">Allowed</th>
            <th style="width:16%;">Grant</th>
            <th
              v-if="updateAllowPermissionPermission"
              style="width:8%;"/>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="m in members"
            :class="getRowColour()"
            :key="m.id"
          >
            <td>{{ m.roleName }} </td>
            <td style="padding: 6px 5px 0 0;">
              <TriStateToggleSwitch
                :enabled="updateAllowPermissionPermission"
                :current-state="m.allowed"
                :permission-id="m.permissionId"
                :role-id="m.roleId"
                @statechanged="updateAllowedPermission"
              />
            </td>
            <td style="padding: 6px 5px 0 0;">
              <TriStateToggleSwitch
                :enabled="updateGrantPermissionPermission"
                :current-state="m.grant"
                :permission-id="m.permissionId"
                :role-id="m.roleId"
                @statechanged="updateGrantPermission"
              />
            </td>
            <td
              v-if="updateAllowPermissionPermission"
              style="text-align:right;"
            >
              <input
                type="button"
                class="deleteButton"
                title="Remove role from permission"
                @click="removePermissionMember(m)"
              >
            </td>
          </tr>
        </tbody>
      </table>
      <input
        v-if="updateAllowPermissionPermission && members.length !== roles.length"
        id="permissionRoleMembersButton"
        type="button"
        class="permissionRoleMembersButton"
        value="Add Role"
        title="Adds the new role to the permission"
        @click="addMember()"
      >
    </div>
    <PermissionNewRole
      :permissionid="permissionid"
      :current-roles="members"
      :userid="userid"
    />
  </div>
</template>
<script>
import { usersMixin } from '../../../mixins/users'
import { permissionsMixin } from '../../../mixins/permissions'
import PermissionNewRole from './permissionNewRole'
import Spinner from '../../common/spinner'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { mapActions } from 'vuex'
export default {
  name: 'PermissionRoleMembers',
  components: {
    TriStateToggleSwitch,
    PermissionNewRole,
    Spinner
  },
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    permissionid: {
      type: Number,
      default: -1
    },
    userid: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    },
    useAltColour: {
      type: Boolean,
      default: function () { return false }
    }
  },
  data () {
    return {
      loading: true,
      members: [],
      areaid: -1
    }
  },
  computed: {
    reloadRoles () {
      return this.$store.getters.reloadRoles
    },
    updateAllowPermissionPermission () {
      return this.$store.getters.updateAllowPermissionPermission
    },
    updateGrantPermissionPermission () {
      return this.$store.getters.updateGrantPermissionPermission
    },
    roles () {
      return this.$store.getters.roles
    }
  },
  watch: {
    permissionid () {
      this.setComponent()
    },
    reloadRoles (val) {
      if (val) {
        this.setComponent()
        this.setReloadRoles(false)
      }
    }
  },
  mounted () {
    this.setComponent()
  },
  methods: {
    ...mapActions([
      'setSubForm2',
      'setReloadRoles'
    ]),
    setComponent: async function () {
      if (this.userid > -1) {
        this.getRoles()
      }
    },
    getRoles: async function () {
      this.loading = true
      this.getPermissionRoleDetails(this.permissionid, this.areaid).then((results) => {
        var members = []

        for (let i = 0; i < results.length; i++) {
          if (results[i].roleId > 0) {
            var role = this.roles.find(role => role.id === results[i].roleId)
            if (typeof (role) === 'object') {
              results[i].roleName = role.name
              if (members.findIndex(member => member.roleId === role.id)) {
                members.push(results[i])
              }
            }
          }
        }
        this.members = members
        this.loading = false
      })
    },
    addMember: async function () {
      this.setSubForm2(true)
    },
    removePermissionMember: async function (m) {
      this.deleteRolePermission(m.roleId, this.permissionid, this.areaid).then(() => {
        this.getRoles()
      })
    },
    updateAllowedPermission ({ roleId, permissionId, selectedState }) {
      this.updateAssignedPermissionByRole({
        permissionId,
        roleId
      }, 'allowed', selectedState)
    },
    updateGrantPermission ({ roleId, permissionId, selectedState }) {
      this.updateAssignedPermissionByRole({
        permissionId,
        roleId
      }, 'grant', selectedState)
    },
    getRowColour () {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      return rtn
    }
  }
}

</script>
<style scoped>
  .prmContainer {
    padding: 15px 10px 10px 10px;
  }
  .permissionRoleMembersHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    margin-bottom: 10px;
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }
  .permissionRoleMembersButton {
    margin: 10px 0;
    float: right;
    cursor: pointer;
  }
  select { width: calc(100% - 80px); height: 28px; padding: 0 5px 0 5px; border: none; border-radius: 15px; margin: 0 0 15px 10px;}
  table thead tr th {padding:3px 5px;font-size: 0.9em;}
  #cboAreas { width: calc(100% - 100px); height: 28px; padding: 0 5px 0 5px; border: none; border-radius: 15px;margin: 10px;display: inline-block;}

</style>
