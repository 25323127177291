<template>
  <div>
    <label for="toggleAltColours">Show alternate grid colours in a lighter shade:</label>
    <TriStateToggleSwitch
      id="toggleAltColours"
      :enabled="true"
      :current-state="currentAltColour"
      :tri-state="false"
      class="toggleAltColour"
      @statechanged="({ selectedState }) => {changeAltColour(selectedState)}"
    />
  </div>
</template>

<script>
import { usersMixin } from '../../mixins/users'
import { permissionsMixin } from '../../mixins/permissions'
import TriStateToggleSwitch from '../common/triStateToggleSwitch'
export default {
  name: 'ThemeComponent',
  components: {
    TriStateToggleSwitch
  },
  mixins: [
    usersMixin,
    permissionsMixin
  ],
  props: {
    userid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      entityStyles: [],
      currentAltColour: 1
    }
  },
  computed: {
  },
  mounted () {
    this.setThemeVariables()
  },
  methods: {
    setThemeVariables: async function () {
      if (this.userid > -1) {
        this.entityStyles = await this.getEntityStyles(this.userid, '-')
        for (let i = 0; i < this.entityStyles.length; i++) {
          if (this.entityStyles[i].styleName === 'alt-colour') {
            if (this.entityStyles[i].styleValue === 'true') {
              this.currentAltColour = 1
            } else {
              this.currentAltColour = 2
            }
          }
        }
      }
    },
    changeAltColour: async function (selectedState) {
      if (selectedState === 1) {
        await this.upsertEntityStyle(this.userid, 'alt-colour', 'true')
      } else {
        await this.upsertEntityStyle(this.userid, 'alt-colour', 'false')
      }
      await this.resolveEntityStyles(this.userid)
    }
  }
}
</script>

<style scoped>
  .toggleAltColour {
    margin: 0 0 0 30px;
    vertical-align: sub;
  }
</style>
