<template>
  <div
    v-if="$store.getters.openMainForm"
    class="stormNewBackground"
  >
    <div class="stormNewHolder">
      <div class="stormTableholder">
        <div class="stormNewTitle">New UC Analytics User</div>
        <label
          class="stormUserDetailLabel"
          for="txtAddUserForename"
        ><span class="compulsoryField">*</span>Forename:</label>
        <input
          id="txtAddUserForename"
          v-model="newUser.forename"
          :class="{ errorHighlight: stormForenameError }"
          class="stormUserDetailInput"
          type="text"
          @blur="checkForm"
        >
        <label
          class="stormUserDetailLabel"
          for="txtAddUserSurname"
        ><span class="compulsoryField">*</span>Surname:</label>
        <input
          id="txtAddUserSurname"
          v-model="newUser.surname"
          :class="{ errorHighlight: stormSurnameError }"
          class="stormUserDetailInput"
          type="text"
          @blur="checkForm"
        >
        <label
          class="stormUserDetailLabel"
          for="txtAddUserEmail"
        ><span class="compulsoryField">*</span>Email:</label>
        <input
          id="txtAddUserEmail"
          v-model="newUser.email"
          :class="{ errorHighlight: stormEmailError }"
          autocomplete="new-password"
          class="stormUserDetailInput"
          type="text"
          @blur="checkForm"
        >
        <label
          class="stormUserDetailLabel"
          for="txtAddUserPassword"
        ><span class="compulsoryField">*</span>Password:</label>
        <input
          id="txtAddUserPassword"
          v-model="newUser.password"
          :class="{ errorHighlight: stormPasswordError }"
          autocomplete="new-password"
          class="stormUserDetailInput"
          type="password"
          @blur="checkForm"
        >
        <label
          class="stormUserDetailLabel"
          for="txtAddUserPassword"
        ><span class="compulsoryField">*</span>Confirm:</label>
        <input
          id="txtAddUserPasswordConfirm"
          v-model="passwordConfirm"
          :class="{ errorHighlight: stormPasswordError }"
          autocomplete="new-password"
          class="stormUserDetailInput"
          type="password"
          @blur="checkForm"
        >
        <label
          class="stormUserDetailLabel"
          for="txtAddPhone"
        ><span class="compulsoryField">&nbsp;</span>Phone:</label>
        <input
          id="txtAddPhone"
          v-model="newUser.phone"
          class="stormUserDetailInput"
          type="text"
        >
        <p style="margin: 0 0 10px 10px;">(<span class="compulsoryField">*</span> marks a compulsory field )</p>
        <Spinner v-if="isSaving" />
        <input
          id="stormCancelNewButton"
          type="button"
          class="stormCancelNewButton"
          value="Cancel"
          title="Cancel the add operation"
          @click="closeForm()"
        >
        <input
          v-if="!isSaving"
          id="btnAddUser"
          :disabled="!formOK"
          value="Add User"
          type="button"
          class="stormUserAddButton"
          title="Adds a new UC Analytics user"
          @click="addNewUser()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { stormMixin } from '../../../mixins/stormMixin'
import Spinner from '../../common/spinner'
import { mapActions } from 'vuex'
export default {
  name: 'StormNewUser',
  components: {
    Spinner
  },
  mixins: [
    stormMixin
  ],
  data () {
    return {
      isSaving: false,
      formOK: false,
      newUser: {},
      stormForenameError: false,
      stormSurnameError: false,
      stormEmailError: false,
      stormPasswordError: false,
      passwordConfirm: ''
    }
  },
  computed: {
    stormStandardUserGroup () {
      return this.$store.getters.stormStandardUserGroup
    },
    stormInternalUserGroup () {
      return this.$store.getters.stormInternalUserGroup
    },
    stormStandardSecurityGroup () {
      return this.$store.getters.stormStandardSecurityGroup
    }
  },
  mounted () {
    this.passwordConfirm = ''
    this.newUser = this.getNewUser()
  },
  methods: {
    ...mapActions([
      'setMainForm'
    ]),
    getNewUser () {
      return { id: 0, name: '', description: null, accountName: '', password: '', passwordConfirm: '', hasSystemAccount: false, systemOnly: false, timeZone: 'GMT Standard Time', forename: '', surname: '', email: '', phoneNumber: null, uri: null, accountDisabled: false }
    },
    checkExistingUser (email) {
      return this.$store.getters.stormUsers.filter(u => u.email === this.newUser.email.trim()).length > 0
    },
    addNewUser: async function () {
      if (this.newUser.email.length === 0 || this.newUser.forename.length === 0 || this.newUser.surname.length === 0 || this.newUser.password.length === 0 || this.passwordConfirm.length === 0) {
        this.$store.commit('showMessage', { content: 'You must fill in all compulsory fields in order to add a new user', color: 'red', timeout: 3000 })
      } else {
        this.isSaving = true
        if (this.formOK) {
          var nUser = this.newUser
          nUser.timeZone = 'GMT Standard Time'
          nUser.name = nUser.forename + ' ' + nUser.surname
          nUser.accountName = nUser.email
          var rtn = await this.addStormUser(nUser)
          if (rtn) {
            await this.setupStormUsers()
            rtn = await this.addStormUserToUserGroup(nUser.email)
            if (rtn) {
              rtn = await this.addStormUserToSecurityGroup(nUser.email)
              if (rtn) {
                this.closeForm()
                this.$emit('reload-storm-users', 0)
                this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
              } else {
                this.$store.commit('showMessage', { content: 'Something went wrong assigning the security group, please try again..', color: 'red', timeout: 3000 })
              }
            } else {
              this.$store.commit('showMessage', { content: 'Something went wrong assigning the user group, please try again..', color: 'red', timeout: 3000 })
            }
          } else {
            this.$store.commit('showMessage', { content: 'Something went wrong adding the user, please try again..', color: 'red', timeout: 3000 })
          }
        } else {
          this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
        }
        this.isSaving = false
      }
    },
    addStormUserToUserGroup: async function (email) {
      // Adds the user to the appropriate user group (maintel.co.uk addresses are added to "Maintel Internal Group", all others to "Maintel User Group")
      var groupName = this.stormStandardUserGroup
      var userId = this.$store.getters.stormUsers.filter(u => u.email === email)[0].id
      if (email.toLowerCase().indexOf('@maintel.co.uk') > -1) {
        groupName = this.stormInternalUserGroup
      }
      var groupId = this.$store.getters.stormUserGroups.filter(g => g.name === groupName)[0].id
      var rtn = await this.addUserToStormUserGroup(userId, groupId)
      return rtn
    },
    addStormUserToSecurityGroup: async function (email) {
      // Adds the user to the appropriate user group (maintel.co.uk addresses are added to "Maintel Internal Group", all others to "Maintel User Group")
      var groupName = this.stormStandardSecurityGroup
      var userId = this.$store.getters.stormUsers.filter(u => u.email === email)[0].id
      var groupId = this.$store.getters.stormSecurityGroups.filter(g => g.name === groupName)[0].id
      var rtn = await this.addUserToStormSecurityGroup(userId, groupId)
      return rtn
    },
    closeForm () {
      this.setMainForm(false)
      this.clearErrors()
      this.newUser = this.getNewUser()
    },
    clearErrors () {
      this.stormForenameError = false
      this.stormSurameError = false
      this.stormEmailError = false
      this.stormPasswordError = false
    },
    checkForm: function () {
      this.clearErrors()
      this.stormForenameError = this.newUser.forename.length === 1
      if (!this.stormForenameError) {
        this.stormSurameError = this.newUser.surname.length === 1
      }
      if (!this.stormSurameError && this.newUser.email.length > 0) {
        this.stormEmailError = !this.validateEmail(this.newUser.email) || !this.validateFirstChar(this.newUser.email)
      }
      if (!this.stormSurameError && !this.stormEmailError && this.newUser.password.length > 0 && this.passwordConfirm.length > 0) {
        this.stormPasswordError = this.passwordConfirm !== this.newUser.password || this.newUser.password.length < 6
      }

      var errorMessage = ''
      if (this.stormForenameError) {
        errorMessage = ' Please ensure the forename is greater than a single character.'
      }
      if (this.stormSurameError) {
        errorMessage = ' Please ensure the surname is greater than a single character.'
      }
      if (this.stormEmailError) {
        errorMessage = ' The email address provided is not valid.'
      }
      if (this.stormPasswordError) {
        errorMessage = ' The passwords must match and be at least 6 characters long.'
      }

      if (!this.stormEmailError && this.newUser.email.length > 0) {
        this.stormEmailError = this.checkExistingUser(this.newUser.email)
        if (this.stormEmailError) {
          errorMessage = ' The email address provided is already in use and cannot be used again.'
        }
      }

      if (errorMessage !== '') {
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !this.stormForenameError && !this.stormSurameError && !this.stormEmailError && !this.stormPasswordError
    },
    validateFirstChar (email) {
      var re = /^[A-Za-z0-9_-]*$/
      return re.test(email[0])
    },
    validateEmail (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}

</script>
<style scoped>
  .stormUserHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .stormUserDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
    margin: 0 0 0 8px;
  }
  .stormUserDetailData {
    width: 270px;
    display: inline-block;
  }
  .stormUserDetailInput {
    width: 265px;
    display: inline-block;
    margin:0 0 10px 10px;
    padding: 0 0 0 5px;
  }
  .stormUserAddButton {
    float:right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    margin: 10px;
  }
  .stormCancelNewButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .stormUserAddButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .stormNewBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .stormNewHolder {
    display: inline-block;
    background-color: rgba(240,240,240,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 220px);
    top: calc(50% - 200px);
    height: 400px;
    width: 420px;
    z-index: 999;
  }
  .stormNewTitle {
    padding:10px;
    background-color: rgba(44,62,80,0.7);
    color: #fff;
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .stormTableholder {
    overflow: auto;
    height: 400px;
    max-height: 450px;
  }
  .compulsoryField {
    color:red;
    padding:0 8px;
  }
</style>
