<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <h2
          id="help-dashboard-image"
          primary-title
        >
          Upload Dashboard Image
        </h2>
      </v-flex>
    </v-layout>
    <div class="card-group">
      <v-layout class="layout-margin">
        <v-flex
          xs12
          padding-adjust
        >
          <v-card
            class="card-style"
          >
            <v-card-title
              class="helpSectionContainer"
            >
              <HelpSection
                instruction="This section details how to update the dashboard image."
              />
              <HelpSection
                instruction="1.) Navigate to the support page, then click on the 'Update Dashboard Image' tile."
                image-source="upload_dashboard_image_1.png"
                indentation="0"
              />
              <HelpSection
                instruction="2.) Enter information into the fields provided."
                indentation="0"
              />
              <HelpSection
                instruction="a.) If you provide a 'URL' then a link will be put around the image to open in a new window. If you leave this field blanck, then no link will be added."
                indentation="1"
              />
              <HelpSection
                instruction="b.) If a URL is provided then a link will be put around the image to open in a new window. If the field is left blank, then no link will be added."
                indentation="1"
              />
              <HelpSection
                instruction="c.) The 'Alt Text' field must be populated."
                indentation="1"
                image-source="upload_dashboard_image_2.png"
              />
              <HelpSection
                instruction="d.) Once the 'Alt Text' field has been populated, the ‘Submit’ button in the bottom right hand corner of the screen will be enabled."
                indentation="1"
                image-source="upload_dashboard_image_3.png"
              />
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>

import HelpSection from './HelpSection'

export default {
  name: 'HelpDashboardImage',
  components: {
    HelpSection
  },
  mixins: [
  ],
  props: {
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
    .card-group {
        border-left: solid rgb(99, 180, 82);
    }

    .overview-green {
        color: rgb(32, 73, 24);
    }

    .v-card__title {
      margin-right: 0 !important;
      padding: 0 !important;
      width:100%;
    }

    .padding-adjust {
      padding: 0 12px !important;
    }

    .helpSectionContainer {
      display:inline-block;
    }

</style>
