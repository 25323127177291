<template>
  <div
    id="ticketDetailDialogboxContainer"
    class="ticketDetailContainer"
  >
    <div class="ticketDetailHeader">
      <div>
        <div class="ticketNumberLabel">Incident:</div>
        <div
          id="ticketNumberField"
          class="ticketNumberField"
        >{{ ticket.ticketNumber }}</div>
        <clipboard
          class="clipboardIcon"
          element="ticketNumberField"
        />
      </div>
    </div>
    <v-tabs
      id="ticketsDetailsTab"
      v-model="tab"
      grow
    >
      <v-tabs-slider color="green" />
      <v-tab
        id="btnDetailsTab"
        key="details"
        :selected="true"
      >
        Details
      </v-tab>
      <v-tab key="notes">
        Notes
      </v-tab>
      <v-tab key="attachments">
        Attachments
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="details">
        <div class="detailsContainer">
          <div class="divTextContainer">
            <div class="itemTitle">Description:</div>
            <div
              class="itemSubTitle"
              v-html="ticketDescription"
            />
          </div>
          <div class="divTextContainer">
            <div class="itemTitle">Site:</div>
            <div class="itemSubTitle">{{ site }}</div>
          </div>
          <div class="ticketSubDetailContent">
            <div class="ticketDetailContentColLeft">
              <div class="divTextContainerCol">
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">Issue Type:</div>
                  <div class="itemSubTitle">{{ issueType }}</div>
                </div>
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">SubIssue Type:</div>
                  <div class="itemSubTitle">{{ subIssueType }}</div>
                </div>
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">Ticket Created:</div>
                  <div class="itemSubTitle">{{ getScreenDate(ticket.createDateTime) }}</div>
                </div>
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">Created By:</div>
                  <div class="itemSubTitle">{{ ticket.createdByName }}</div>
                </div>
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">Last Activity:</div>
                  <div class="itemSubTitle">{{ getScreenDate(ticket.lastActivityDateTime) }}</div>
                </div>
                <div
                  v-show="ticket.dueDateTime"
                  class="ticketDetailContentItem"
                >
                  <div class="itemTitle">Ticket Due Date:</div>
                  <div class="itemSubTitle">{{ getScreenDate(ticket.dueDateTime) }}</div>
                </div>
                <div
                  v-show="ticket.completed"
                  class="ticketDetailContentItem"
                >
                  <div class="itemTitle">Completed:</div>
                  <div class="itemSubTitle">{{ ticket.completed }}</div>
                </div>
                <div
                  v-show="ticket.completeDateTime"
                  class="ticketDetailContentItem"
                >
                  <div class="itemTitle">Complete Date Time:</div>
                  <div class="itemSubTitle">{{ getScreenDate(ticket.completeDateTime) }}</div>
                </div>
              </div>
            </div>
            <div class="ticketDetailContentColRight">
              <div class="divTextContainerCol">
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">Customer Reference:</div>
                  <div class="itemSubTitle">{{ getUDFValue(ticket.id, 'Customer Reference') }}</div>
                </div>
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">Status:</div>
                  <div class="itemSubTitle">{{ ticketStatus }}</div>
                </div>
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">Priority:</div>
                  <div class="itemSubTitle">{{ ticketPriority }}</div>
                </div>
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">Category:</div>
                  <div class="itemSubTitle">{{ ticketCategory }}</div>
                </div>
                <div class="ticketDetailContentItem">
                  <div class="itemTitle">Ticket Type:</div>
                  <div class="itemSubTitle">{{ ticketType }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item key="notes">
        <div
          v-show="notesLoading"
          id="notesSpinner"
          class="divSpinner"
        >
          <img
            :src="maintelSpinnerImage"
            alt="loading notes, please wait..."
          >
          <p class="pSpinnerText">loading notes, please wait...</p>
        </div>
        <div
          v-show="!notesLoading"
          class="notesContainer"
        >
          <div class="ticketDetailContent">
            <div
              v-if="updateTicketPermission && showNewNotes"
              class="divNewNoteContainer"
            >
              <h3>Add A New Note</h3>
              <TicketNote
                v-if="updateTicketPermission"
                :show-add-button="true"
                :ticket="ticket"
                :ticket-id="ticket.id"
                :site-id="ticket.accountId"
                @note-added="notesUpdated"
              />
            </div>
            <div
              v-for="note in notes"
              :key="note.id"
              class="divTextContainer"
            >
              <b class="noteTitle">{{ note.title }}</b><br>
              <b>{{ getScreenDate(note.createdDate) }}&nbsp;&nbsp;{{ note.resourceName }}</b><br>
              <div
                class="divNoteBody"
                v-html="getFormattedText(note.body)" />
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item key="attachments">
        <div
          v-show="filesLoading"
          id="attachmentsSpinner"
          class="divSpinner"
        >
          <img
            :src="maintelSpinnerImage"
            alt="loading attachments, please wait..."
          >
          <p class="pSpinnerText">loading attachments, please wait...</p>
        </div>
        <div
          v-show="!filesLoading"
          class="attachmentsContainer"
        >
          <div class="ticketDetailContent">
            <table
              v-if="attachments.length>0"
              id="attachmentTable"
              class="attachmentTable"
            >
              <thead>
                <tr>
                  <th>Created</th>
                  <th>Title</th>
                  <th>File Name</th>
                  <th>File Size</th>
                  <th style="width:50px;cursor:pointer;"/>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="attachment in attachments"
                  :key="attachment.id"
                >
                  <td :title="getScreenDate(attachment.createdDate)">{{ getScreenDate(attachment.createdDate) }}</td>
                  <td :title="attachment.name">{{ attachment.name }}</td>
                  <td :title="attachment.fileName">{{ attachment.fileName }}</td>
                  <td :title="getScreenFileSize(attachment.fileSize)">{{ getScreenFileSize(attachment.fileSize) }}</td>
                  <td>
                    <v-icon
                      :title="`Download ${attachment.fileName}`"
                      @click="downloadAttachment(attachment.id, attachment.fileName, attachment.contentType)"
                    >
                      mdi-download
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              v-else
              class="noAttachments"
            >
              <b>{{ noattachmentTitle }}</b>
            </p>
            <div
              v-if="updateTicketPermission && showNewNotes"
              class="divNewAttachmentContainer"
            >
              <h3>Add A New Attachment</h3>
              <AttachFiles
                v-if="updateTicketPermission"
                :show-add-button="true"
                :ticket="ticket"
                :ticket-id="ticket.id"
                :site-id="ticket.accountId"
                @attachment-added="filesUpdated"/>
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TicketNote from './ticketNote'
import AttachFiles from './attachFiles'
import clipboard from '../common/clipboard'
import { autotaskAPIMixin } from '../../mixins/autotaskAPIMixin'
import { autotaskServiceMixin } from '../../mixins/autotaskServiceMixin'
import { DataEventBus } from '../../events/dataEvents'
export default {
  components: {
    clipboard,
    TicketNote,
    AttachFiles
  },
  mixins: [
    autotaskAPIMixin,
    autotaskServiceMixin
  ],
  props: {
    ticket: {
      type: Object,
      default: () => {}
    },
    showNewNotes: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      tab: 0,
      notes: [],
      attachments: [],
      sites: [],
      notesLoading: false,
      filesLoading: false,
      currentTicket: 0,
      amContactForSite: false,
      noattachmentTitle: 'No attachments found for this ticket.'
    }
  },
  computed: {
    currentTicketUDFs () {
      return this.$store.getters.ticketUDFs
    },
    ticketDescription () {
      if (this.ticket.description) {
        return this.ticket.description.replace(RegExp('\\n', 'g'), '<br>')
      } else {
        return ''
      }
    },
    ticketStatus () {
      var rtn = 'NA'
      if (this.ticket.statusId) {
        var obj = this.$store.getters.statusList.find(c => c.value === this.ticket.statusId.toString())
        if (obj !== undefined) {
          rtn = obj.name
        }
      }
      return rtn
    },
    ticketPriority () {
      var rtn = 'NA'
      if (this.ticket.priorityId) {
        var obj = this.$store.getters.priorityList.find(c => c.value === this.ticket.priorityId.toString())
        if (obj !== undefined) {
          rtn = obj.name
        }
      }
      return rtn
    },
    ticketCategory () {
      var rtn = 'NA'
      if (this.ticket.categoryId) {
        var obj = this.$store.getters.ticketCategoryList.find(c => c.value === this.ticket.categoryId.toString())
        if (obj !== undefined) {
          rtn = obj.name
        }
      }
      return rtn
    },
    ticketType () {
      var rtn = 'NA'
      if (this.ticket.ticketTypeId) {
        var obj = this.$store.getters.ticketTypeList.find(t => t.value === this.ticket.ticketTypeId.toString())
        if (obj !== undefined) {
          rtn = obj.name
        }
      }
      return rtn
    },
    issueType () {
      var rtn = 'NA'
      if (this.ticket.issueTypeId) {
        var obj = this.$store.getters.issueTypeList.find(i => i.value === this.ticket.issueTypeId.toString())
        if (obj !== undefined) {
          rtn = obj.name
        }
      }
      return rtn
    },
    subIssueType () {
      var rtn = 'NA'
      if (this.ticket.subIssueTypeId) {
        var obj = this.$store.getters.subIssueTypeList.find(s => s.value === this.ticket.subIssueTypeId.toString())
        if (obj !== undefined) {
          rtn = obj.name
        }
      }
      return rtn
    },
    site () {
      var rtn = 'NA'
      if (this.ticket.accountId) {
        var obj = this.$store.getters.sites.find(s => s.id === this.ticket.accountId)
        if (obj !== undefined) {
          rtn = obj.name
        }
      }
      return rtn
    },
    updateTicketPermission () {
      return this.$store.getters.updateTicketPermission
    },
    maintelSpinnerImage () {
      return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.spinnerImage}`
    },
    contactId () {
      if (this.$store.getters.autotaskContact.length > 0) {
        return this.$store.getters.autotaskContact[0].id
      } else {
        return null
      }
    }
  },
  watch: {
    ticket () {
      if (this.ticket) {
        if (this.currentTicket !== this.ticket.id) {
          this.setForm()
        }
      }
    }
  },
  mounted () {
    this.setForm()
  },
  methods: {
    setForm () {
      this.sites = this.$store.getters.sites
      this.setTicketNotes()
      this.setTicketAttachments()
      this.ticket.contactId = this.contactId
      this.currentTicket = this.ticket.id
      DataEventBus.$emit('still-active')
    },
    filesUpdated () {
      this.setTicketAttachments()
    },
    notesUpdated () {
      this.setTicketNotes()
    },
    setTicketNotes: async function () {
      if (this.ticket.id) {
        this.notesLoading = true
        var rtn = await this.getTicketNotes(this.ticket.id)
        if (rtn) {
          if (rtn.indexOf('Error:') !== 0) {
            rtn = await this.getNoteResources(rtn)
            this.notes = rtn
          } else {
            this.notes = [{ title: 'Unable to retrieve the ticket notes. Please try again in a few minutes' }]
          }
        } else {
          this.notes = [{ title: 'No notes found associated with this ticket.' }]
        }
        this.notesLoading = false
      }
    },
    setTicketAttachments: async function () {
      if (this.ticket.id) {
        this.filesLoading = true
        var rtn = await this.getTicketAttachments(this.ticket.id)
        if (rtn) {
          if (rtn.indexOf('Error:') !== 0) {
            this.attachments = this.reverseArray(rtn)
          } else {
            this.noattachmentTitle = 'Unable to retrieve the ticket attachments. Please try again in a few minutes'
            this.attachments = []
          }
        } else {
          this.attachments = []
        }
        this.filesLoading = false
      }
    },
    getNoteResources: async function (notes) {
      var resourceList = []
      for (let i = 0; i < notes.length; i++) {
        if (!resourceList.includes(notes[i].resourceId)) {
          // Bug fix - Sean's testing revealed an error where the resource list passed
          // began with a comma, eg: ,12345,678910
          if (notes[i].resourceId !== null && notes[i].resourceId !== undefined) {
            if (notes[i].resourceId.toString().trim() !== '') {
              resourceList.push(notes[i].resourceId)
            }
          }
        }
      }
      var rtn = await this.getAutotaskResourcesByResourceList(resourceList.join(','))
      for (let i = 0; i < notes.length; i++) {
        var resource
        if (rtn.length > 0) {
          resource = rtn.find(r => r.id === notes[i].resourceId)
        }
        if (resource) {
          notes[i].resourceName = resource.firstName + ' ' + resource.lastName
        } else {
          notes[i].resourceName = 'Unknown'
        }
      }
      return notes
    },
    getScreenDate (dte) {
      var rtn = ''
      if (dte) {
        rtn = dte.replace('T', ' ').substring(0, 16)
      }
      return rtn
    },
    getScreenFileSize (size) {
      var fileSize = size + ' bytes'
      if (size > 1000) {
        fileSize = (size / 1000) + ' kb'
      }
      if ((size / 1000) > 1000) {
        fileSize = (size / 1000 / 1000) + ' mb'
      }
      return fileSize
    },
    downloadAttachment: async function (attachmentId, fileName, contentType) {
      // console.log('ticketid: ' + this.ticket.id + ', attachment id: ' + attachmentId)
      var rtn = await this.getAttachmentData(this.ticket.id, attachmentId)
      if (rtn && rtn.data) {
        var url = `data:${contentType};base64,${rtn.data}`
        var a = document.createElement('a')
        a.style = 'display: none'
        a.href = url
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        this.$store.commit('showMessage', { content: 'Downloaded ' + fileName + '. You should find it in your download folder.', color: 'blue', timeout: 3000 })
      } else {
        this.$store.commit('showMessage', { content: 'No attachment data found.', color: 'red', timeout: 3000 })
      }
    },
    reverseArray (arr) {
      var newArr = []
      arr.forEach(item => {
        newArr.unshift(item)
      })
      return newArr
    },
    getFormattedText (text) {
      var rtn = ''
      if (text !== undefined) {
        rtn = text.replace(RegExp('\\n', 'g'), '<br>')
      }
      return rtn
    },
    getUDFValue (id, key) {
      var udf = this.currentTicketUDFs.filter(u => u.id === id)
      for (let i = 0; i < udf.length; i++) {
        if (udf[i].name === key) {
          return udf[i].value
        }
      }
      return ''
    }
  }
}
</script>
<style scoped>
  .divSpinner {
    text-align: center;
    margin-top: 60px;
  }
  .pSpinnerText {
    font-weight: bold;
  }
  .ticketNumberLabel {
    font-weight: bold;
    display: inline-block;
    font-size: 1.6em;
  }
  .ticketNumberField {
    font-weight: bold;
    display: inline-block;
    font-size: 1.6em;
  }
  .detailsContainer {
    margin: 24px 0;
  }
  .divNoteBody {
    display: inline-block;
    font-size: 1em;
    word-break: break-word;
  }
  .divTextContainer {
    margin: 0 0 8px 0;
    background: #e8f5e9;
    border: solid #9aa8b1 1px;
    padding: 5px;
    border-radius: 5px;
    word-break: break-all;
    display: flow-root;
  }
  .divTextContainerCol {
    background: #e8f5e9;
    border: solid #9aa8b1 1px;
    padding: 5px;
    border-radius: 5px;
    word-break: break-all;
  }
  .divNewNoteContainer {
    padding: 20px;
    background-color: #e8f5e9;
    border-radius: 5px;
    border: solid #9aa8b1 1px;;
    margin: 0 10px 0 0;
    width:45%;
    float: left;
  }
  .divNewAttachmentContainer {
    padding: 20px;
    background-color: #e8f5e9;
    border-radius: 5px;
    border: solid #9aa8b1 1px;
    margin-bottom: 10px;
    display: flow-root;
  }
  .noAttachments {
    float: left;
    width: 45%;
    padding: 20px;
    background-color: #e8f5e9;
    border-radius: 5px;
    border: solid #9aa8b1 1px;
    margin: 0 10px 0 0;
    font-size: 1.2em;
  }
  .ticketDetailContainer {
    padding: 10px;
  }
  .ticketDetailContentColLeft {
    width: calc(50% - 10px);
    margin-right:6px;
    display: inline-block;
    vertical-align: top;
  }
  .ticketDetailContentColRight {
    width: calc(50% - 10px);
    margin-left:6px;
    display: inline-block;
    vertical-align: top;
  }
  .ticketDetailHeader {
      margin-bottom: 20px;
  }
  .ticketDetailContent {
    display: inline-block;
    width: 100%;
    margin-top: 16px;
  }
  .ticketDetailContentItem {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .noteTitle {
    font-size: 1.2em;
    display: inline-block;
  }
  .itemTitle {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    margin: 5px 0px 10px 0px;
  }
  .itemSubTitle{
    color: rgba(0,0,0,.6);
    line-height: 1.2;
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 5px 0px 10px 0px;
  }
  .clipboardIcon {
    display: inline-block;
    cursor: pointer;
  }
  .attachmentTable {
    margin: 0 7px 0 0;
    float: left;
    width: 45%;
  }
  .attachmentTable th {
    cursor: default;
  }
  .attachmentTable td {
    cursor: default;
  }
  .v-tabs__div {
    font-size: 1.5em;
  }
</style>
