<template>
  <div class="designPackSummaryContainer">
    <div>
      <div
        v-if="iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length === 0"
        class="floatingBox"
      >
        <DesignPackMargins
          :is-read-only="true"
          :show-price-overrides="false"
          @marginsChanged="onMarginsChanged"
          @discountsChanged="onDiscountsChanged" />
      </div>
      <div class="floatingBox">
        <table
          id="CommentsTable"
          class="iquoteDesignpackTable">
          <tr>
            <th>Commercial Comments</th>
            <td>
              <textarea
                id="txtDescription"
                name="txtDescription"
                cols="57"
                rows="10">text will go here later</textarea>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <div class="floatingBox">
        <table
          id="ContractOverviewTable"
          class="iquoteDesignpackTable">
          <tr>
            <th colspan="4">Contract Term</th>
            <th>{{ iQuoteStore_GetDesignPack.termLengthMonths }}</th>
          </tr>
          <tr>
            <th />
            <th>Install</th>
            <th>Annual</th>
            <th>Year 1</th>
            <th>Contract</th>
          </tr>
          <tr>
            <th />
            <th>£</th>
            <th>£ ps</th>
            <th>£</th>
            <th>£</th>
          </tr>
          <tr>
            <td>Revenue</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_yr1RecurringSellPrice + iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalContractSellPrice) }}</td>
          </tr>
          <tr>
            <td>Third Party Costs</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost + iQuoteCalculationsDesignPackMixin_yr1RecurringThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost) }}</td>
          </tr>
          <tr>
            <td>RLEC £</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getRlecValue(iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getRlecValue(iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getRlecValue(iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice + iQuoteCalculationsDesignPackMixin_yr1RecurringSellPrice, iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost + iQuoteCalculationsDesignPackMixin_yr1RecurringThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getRlecValue(iQuoteCalculationsDesignPackMixin_totalContractSellPrice, iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost) }}</td>
          </tr>
          <tr>
            <td>RLEC %</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getRlecPercent(iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getRlecPercent(iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getRlecPercent(iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice + iQuoteCalculationsDesignPackMixin_yr1RecurringSellPrice, iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost + iQuoteCalculationsDesignPackMixin_yr1RecurringThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getRlecPercent(iQuoteCalculationsDesignPackMixin_totalContractSellPrice, iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost) }}</td>
          </tr>
          <tr>
            <td>Internal Costs</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + iQuoteCalculationsDesignPackMixin_yr1RecurringInternalCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_totalContractInternalCost) }}</td>
          </tr>
          <tr>
            <td>Gross Margin</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost + iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(iQuoteCalculationsDesignPackMixin_yr1RecurringSellPrice + iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, iQuoteCalculationsDesignPackMixin_yr1RecurringInternalCost + iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + iQuoteCalculationsDesignPackMixin_yr1RecurringThirdPartyCost + iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(iQuoteCalculationsDesignPackMixin_totalContractSellPrice, iQuoteCalculationsDesignPackMixin_totalContractInternalCost + iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost) }}</td>
          </tr>
          <tr>
            <td>GM%</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmPercent(iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmPercent(iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost + iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmPercent(iQuoteCalculationsDesignPackMixin_yr1RecurringSellPrice + iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, iQuoteCalculationsDesignPackMixin_yr1RecurringInternalCost + iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + iQuoteCalculationsDesignPackMixin_yr1RecurringThirdPartyCost + iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmPercent(iQuoteCalculationsDesignPackMixin_totalContractSellPrice, iQuoteCalculationsDesignPackMixin_totalContractInternalCost + iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost) }}</td>
          </tr>
        </table>
      </div>
      <div class="floatingBox">
        <table
          id="SalesforceViewTable"
          class="iquoteDesignpackTable">
          <tr>
            <th colspan="4">Salesforce View</th>
          </tr>
          <tr>
            <th />
            <th class="highlightLeftBorder">Yr1 Rev</th>
            <th>Yr1 GM</th>
            <th>Yr1 GM %</th>
          </tr>
          <tr>
            <th />
            <th class="highlightLeftBorder">£</th>
            <th>£</th>
            <th>%</th>
          </tr>
          <tr
            v-for="entry in iQuoteCalculationsDesignPackMixin_businessValues"
            :key="entry.businessCoe"
          >
            <td>{{ entry.businessCoe }}</td>
            <td class="highlightLeftBorderInner">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(entry.upfrontSellPrice + entry.recurringSellPrice) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmValue(entry.upfrontSellPrice + entry.recurringSellPrice, entry.upfrontInternalCost + entry.upfrontExternalCost + entry.recurringInternalCost + entry.recurringExternalCost) }}</td>
            <td>{{ iQuoteCalculationsDesignPackMixin_getGmPercent(entry.upfrontSellPrice + entry.recurringSellPrice, entry.upfrontInternalCost + entry.upfrontExternalCost + entry.recurringInternalCost + entry.recurringExternalCost) }}</td>
          </tr>
          <tr>
            <th><b>TOTAL</b></th>
            <th class="highlightLeftBorder">{{ iQuoteCalculationsDesignPackMixin_getCostDisplay(iQuoteCalculationsDesignPackMixin_yr1RecurringSellPrice + iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice ) }}</th>
            <th>{{ iQuoteCalculationsDesignPackMixin_getGmValue(iQuoteCalculationsDesignPackMixin_yr1RecurringSellPrice + iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, iQuoteCalculationsDesignPackMixin_yr1RecurringInternalCostSalesforceView + iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + iQuoteCalculationsDesignPackMixin_yr1RecurringThirdPartyCost + iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</th>
            <th>{{ iQuoteCalculationsDesignPackMixin_getGmPercent(iQuoteCalculationsDesignPackMixin_yr1RecurringSellPrice + iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, iQuoteCalculationsDesignPackMixin_yr1RecurringInternalCostSalesforceView + iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + iQuoteCalculationsDesignPackMixin_yr1RecurringThirdPartyCost + iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost) }}</th>
          </tr>
        </table>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>

  </div>
</template>

<script>

import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'
import DesignPackMargins from '../designPackComponents/designPackMargins'

import { mapActions } from 'vuex'

export default {
  name: 'Summary',
  components: {
    DesignPackMargins
  },
  mixins: [ iQuoteStoreMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsDesignPackMixin ],
  props: {
    isReadOnly: {
      type: Boolean,
      default: function () { return {} }
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    async onMarginsChanged (newMargins) {
      var designPack = this.iQuoteStore_GetDesignPack
      this.iQuoteCalculationsDesignPackMixin_updateMargins(newMargins)

      designPack.margins = newMargins
      this.setDesignPack(designPack)
      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack)
    },
    onPriceOverridesChanged (newPriceOverrides) {
      var designPack = this.iQuoteStore_GetDesignPack

      designPack.priceOverrides = newPriceOverrides
      this.setDesignPack(designPack)
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack)
    },
    async onDiscountsChanged (newDiscounts) {
      var designPack = this.iQuoteStore_GetDesignPack
      this.iQuoteCalculationsDesignPackMixin_updateDiscounts(newDiscounts)

      designPack.discounts = newDiscounts
      this.setDesignPack(designPack)
      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack)
    }
  }
}
</script>

<style scoped>

  #ContractOverviewTable {
    margin-top: 15px;
    width: 100%;
  }

  #SalesforceViewTable {
    width: 100%;
    margin-top: 15px;
  }

  #CommentsTable {
    width: 100%;
    height: 100%;
    margin-top: 15px;
  }

  .floatingBox{
    width: 700px;
    display: inline-block;
    vertical-align:  top;
  }

  .highlightLeftBorderInner {
    border-left: 3px solid silver
  }

  .highlightLeftBorder {
    border-left: 3px solid white
  }
</style>
