// This module is used for state mamangement for iQuote related variables
const state = {
  currentQuotePage: 'Home',
  currentQuoteSubPage: '',
  commercialsSubPage: 'CommercialsDesignPack',
  currentQuoteState: 'QuoteType',
  currentQuoteExternalQuotesState: {
    currentPage: 'rootPage',
    quoteId: 0,
    quoteBoms: [],
    selectedQuoteBom: {},
    quoteIconNow: {},
    quoteIconConnect: {},
    quoteManagedService: {},
    quoteNewManagedService: {},
    quoteCallsAndLines: {},
    quoteHorizon: {},
    quoteIconSecure: {},
    quoteSecureHomeworker: {},
    quoteTwilio: {}
  },
  currentProgressValue: 1,
  currentService: 1,
  currentServiceCategory: 1,
  currentTechnology: 1
}

const mutations = {
  SET_CURRENT_QUOTE_PAGE (state, currentQuotePage) {
    if (currentQuotePage) {
      state.currentQuotePage = currentQuotePage
    }
  },
  SET_CURRENT_QUOTE_EXTERNAL_QUOTES_STATE (state, currentQuoteExternalQuotesState) {
    if (currentQuoteExternalQuotesState) {
      state.currentQuoteExternalQuotesState = currentQuoteExternalQuotesState
    }
  },
  SET_CURRENT_QUOTE_SUB_PAGE (state, currentQuoteSubPage) {
    if (currentQuoteSubPage) {
      state.currentQuoteSubPage = currentQuoteSubPage
    }
  },
  SET_COMMERCIALS_SUB_PAGE (state, commercialsSubPage) {
    if (commercialsSubPage) {
      state.commercialsSubPage = commercialsSubPage
    }
  },
  SET_CURRENT_QUOTE_STATE (state, currentQuoteState) {
    if (currentQuoteState) {
      state.currentQuoteState = currentQuoteState
    }
  },
  SET_CURRENT_QUOTE_PROGRESS_VALUE (state, currentProgressValue) {
    if (currentProgressValue) {
      state.currentProgressValue = currentProgressValue
    }
  },
  SET_CURRENT_SERVICE (state, currentService) {
    if (currentService) {
      state.currentService = currentService
    }
  },
  SET_CURRENT_SERVICE_CATEGORY (state, currentServiceCategory) {
    if (currentServiceCategory) {
      state.currentServiceCategory = currentServiceCategory
    }
  },
  SET_CURRENT_TECHNOLOGY (state, currentTechnology) {
    if (currentTechnology) {
      state.currentTechnology = currentTechnology
    }
  }
}

const actions = {
  setCurrentQuotePage: ({ commit }, currentQuotePage) => {
    commit('SET_CURRENT_QUOTE_PAGE', currentQuotePage)
  },
  setCurrentQuoteSubPage: ({ commit }, currentQuoteSubPage) => {
    commit('SET_CURRENT_QUOTE_SUB_PAGE', currentQuoteSubPage)
  },
  setCurrentQuoteExternalQuotesState: ({ commit }, currentQuoteExternalQuotesState) => {
    commit('SET_CURRENT_QUOTE_EXTERNAL_QUOTES_STATE', currentQuoteExternalQuotesState)
  },
  setCommercialsSubPage: ({ commit }, commercialsSubPage) => {
    commit('SET_COMMERCIALS_SUB_PAGE', commercialsSubPage)
  },
  setCurrentQuoteState: ({ commit }, currentQuoteState) => {
    commit('SET_CURRENT_QUOTE_STATE', currentQuoteState)
  },
  setCurrentProgressValue: ({ commit }, currentProgressValue) => {
    commit('SET_CURRENT_QUOTE_PROGRESS_VALUE', currentProgressValue)
  },
  setCurrentService: ({ commit }, currentService) => {
    commit('SET_CURRENT_SERVICE', currentService)
  },
  setCurrentServiceCategory: ({ commit }, currentServiceCategory) => {
    commit('SET_CURRENT_SERVICE_CATEGORY', currentServiceCategory)
  },
  setCurrentTechnology: ({ commit }, currentTechnology) => {
    commit('SET_CURRENT_TECHNOLOGY', currentTechnology)
  }
}

const getters = {
  currentQuotePage: state => {
    return state.currentQuotePage
  },
  currentQuoteSubPage: state => {
    return state.currentQuoteSubPage
  },
  currentQuoteExternalQuotesState: state => {
    return state.currentQuoteExternalQuotesState
  },
  commercialsSubPage: state => {
    return state.commercialsSubPage
  },
  currentQuoteState: state => {
    return state.currentQuoteState
  },
  currentProgressValue: state => {
    return state.currentProgressValue
  },
  currentService: state => {
    return state.currentService
  },
  currentServiceCategory: state => {
    return state.currentServiceCategory
  },
  currentTechnology: state => {
    return state.currentTechnology
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
