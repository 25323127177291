<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      #<label for="txtiQuoteReference">iQuote</label>
      <input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <label for="txtAccount">Account</label>
      <input
        id="txtAccount"
        :value="quote.salesforceAccountName"
        readonly="true"
        type="text"
      >
      <br>
      <label for="txtFirstName">Contact Name</label>
      <input
        id="txtFirstName"
        :value="quote.salesforceContactFirstName"
        type="text"
        readonly="true"
      >
      <input
        id="txtLastName"
        :value="quote.salesforceContactLastName"
        readonly="true"
        type="text"
      >
      <br>
      <label for="txtQuoteDescription">Quote Description</label>
      <input
        id="txtQuoteDescription"
        :value="quote.quoteDescription"
        readonly="true"
        type="text"
      >
      <br>
      <label for="txtTermLength">Term Length</label>
      <input
        id="txtTermLength"
        :value="quote.termLengthMonths + ' Months'"
        readonly="true"
        type="text"
      >
    </div>
    <hr>
    <div class="quotePanel">
      <h2 v-if="quote.chargeUpfront">Upfront Charges</h2>
      <table
        v-if="quote.chargeUpfront"
        id="tblUpfrontFees"
        class="noHoverPointer"
      >
        <thead>
          <tr><th colspan="2" /><th>Qty</th><th>Price</th><th>Total Price</th></tr>
        </thead>
        <tbody>
          <tr v-if="totalUpfrontInfrastructureCostWithMarkup > 0 && quoteSIPMigrationProperties.id === undefined">
            <td
              colspan="2">Platform Reservation Charge<TooltipIcon text="Calculated Blade Servers + Upfront Unit Costs" />
            </td>
            <td>
              <input
                id="infrastructureQuantity"
                value="1"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                id="infrastructureCost"
                :value="iQuoteFunctions_ThousandSeparator(totalUpfrontInfrastructureCostWithMarkup.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                id="infrastructureTotalPrice"
                :value="iQuoteFunctions_ThousandSeparator(totalUpfrontInfrastructureCostWithMarkup.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
              <TooltipIcon
                :text="infrastructurePriceTooltip"
                width="200"
                align="right" />
            </td>
          </tr>
          <tr v-if="iQuoteStore_GetTeamsConnector.id !== undefined">
            <td
              colspan="2">Teams Connector Setup Charges</td>
            <td>
              <input
                id="teamsUpfrontQuantity"
                value="1"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                id="teamsUpfrontPrice"
                :value="iQuoteFunctions_ThousandSeparator(calcTeamsConnector_UpfrontCharges.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                id="sipTotalPrice"
                :value="iQuoteFunctions_ThousandSeparator(calcTeamsConnector_UpfrontCharges.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
          <tr v-if="quoteSIPMigrationProperties.id !== undefined">
            <td
              colspan="2">Platform Reservation Charge</td>
            <td>
              <input
                id="sipQuantity"
                value="1"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                id="sipPrice"
                :value="iQuoteFunctions_ThousandSeparator((totalUpfrontSipSetupCostWithMarkup + totalUpfrontInfrastructureCostWithMarkup).toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                id="sipTotalPrice"
                :value="iQuoteFunctions_ThousandSeparator((totalUpfrontSipSetupCostWithMarkup + totalUpfrontInfrastructureCostWithMarkup).toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
              <TooltipIcon
                :text="sipPriceTooltip"
                width="200"
                align="right" />
            </td>
          </tr>
          <tr v-if="iQuoteStore_GetGammaSip.id !== undefined">
            <td
              colspan="2">SIP Setup</td>
            <td>
              <input
                id="sipQuantity"
                value="1"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                id="sipPrice"
                :value="iQuoteFunctions_ThousandSeparator((totalUpfrontSipSetupCostWithMarkup).toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                id="sipTotalPrice"
                :value="iQuoteFunctions_ThousandSeparator((totalUpfrontSipSetupCostWithMarkup).toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
              <TooltipIcon
                :text="sipPriceTooltip"
                width="200"
                align="right" />
            </td>
          </tr>
          <tr
            v-for="unitCost in customerFacingUpfrontUnitCosts"
            :key="unitCost.unitCostId">
            <td colspan="2">{{ unitCost.description }}</td>
            <td>
              <input
                :id="'quantity' + unitCost.unitCostId"
                :value="unitCost.quantity"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                :id="'price' + unitCost.unitCostId"
                :value="iQuoteFunctions_ThousandSeparator(unitCost.price.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                :id="'numAnnualPrice' + unitCost.unitCostId"
                :value="iQuoteFunctions_ThousandSeparator(unitCost.price.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr class="TableSectionFooter">
            <td
              colspan="4"
              style="text-align:left; font-weight:bold; padding-right:20px;">Upfront Charges Subtotal:</td>
            <td>£
              <input
                id="numTotalUpfrontPrice"
                :value="iQuoteFunctions_ThousandSeparator(totalUpfrontCharges.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Subscription Minimum Commitment</h2>
      <table
        id="tblRecurringLicenses"
        class="noHoverPointer">
        <thead>
          <tr><th colspan="2" /><th>Qty<br>&nbsp;</th><th>Monthly Price<br>(Per unit)</th><th>Annual Price<br>(Total)</th></tr>
        </thead>
        <tbody
          v-for="service in iQuoteStore_GetServices"
          :key="service.id">
          <tr
            v-if="serviceLicences(service.id).length > 0 || serviceUnitCosts(service.id).length > 0"
            class="TableSectionHeader"><td colspan="5">{{ serviceDescription (service.id) }}</td></tr>
          <tr
            v-for="license in serviceLicences(service.id)"
            :key="license.description">
            <td colspan="2">{{ license.description }}</td>
            <td>
              <input
                :id="'quantity' + license.licenseId"
                :value="license.quantity"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                :id="'price' + license.licenseId"
                :value="iQuoteFunctions_ThousandSeparator(license.price.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                :id="'numAnnualPrice' + license.licenseId"
                :value="iQuoteFunctions_ThousandSeparator(license.annualPrice.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
          <tr
            v-for="unitCost in serviceUnitCosts(service.id)"
            :key="unitCost.description">
            <td colspan="2">{{ unitCost.description }}</td>
            <td>
              <input
                :id="'quantity' + unitCost.unitCostId"
                :value="unitCost.quantity"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                :id="'price' + unitCost.unitCostId"
                :value="iQuoteFunctions_ThousandSeparator(unitCost.price.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                :id="'numAnnualPrice' + unitCost.unitCostId"
                :value="iQuoteFunctions_ThousandSeparator(unitCost.annualPrice.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
          <tr
            v-if="service.id === iQuoteConstants.services.sip && annualSipCostWithMarkup > 0"
            :key="license.description">
            <td colspan="2">Access Service</td>
            <td>
              <input
                id="quantitySip"
                value="1"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                id="priceSip"
                :value="iQuoteFunctions_ThousandSeparator((annualSipCostWithMarkup/12).toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                id="numAnnualPriceSip"
                :value="iQuoteFunctions_ThousandSeparator(annualSipCostWithMarkup.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr
            v-if="annualIconGatewayCostWithMarkup > 0"
            class="TableSectionHeader"><td colspan="5"/></tr>
          <tr
            v-if="annualIconGatewayCostWithMarkup > 0"
            class="TableSectionHeader">Icon Gateway<td colspan="5"/></tr>
          <tr
            v-if="annualIconGatewayCostWithMarkup > 0">
            <td colspan="2">Icon Gateway Charges</td>
            <td>
              <input
                id="annualIconGatewayCostWithMarkupQuantity"
                value="1"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                id="monthlyIconGatewayCostWithMarkup"
                :value="iQuoteFunctions_ThousandSeparator((annualIconGatewayCostWithMarkup/12).toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                id="annualIconGatewayCostWithMarkup"
                :value="iQuoteFunctions_ThousandSeparator(annualIconGatewayCostWithMarkup.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
        <tbody v-if="iQuoteStore_GetQuoteExpansions.id !== undefined && iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined && iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined">
          <tr>
            <td colspan="2">Platform Charge</td>
            <td>
              <input
                id="annualExpansionCostWithMarkupQuantity"
                value="1"
                readonly="true"
                type="number"
              >
            </td>
            <td>£
              <input
                id="monthlyExpansionCostWithMarkup"
                :value="iQuoteFunctions_ThousandSeparator((totalRecurringAnnualPrice/12).toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
            <td>£
              <input
                id="annualExpansionCostWithMarkup"
                :value="iQuoteFunctions_ThousandSeparator(totalRecurringAnnualPrice.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
        <tbody class="nostripes">
          <tr class="TableSectionFooter">
            <td
              colspan="4"
              style="text-align:left; font-weight:bold; padding-right:20px;">Annual Charges Subtotal:</td>
            <td>£
              <input
                id="numSubTotal"
                :value="iQuoteFunctions_ThousandSeparator(totalRecurringAnnualPrice.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
          <tr
            v-if="calculationsCommonMixin_totalYr1Discount > 0"
            class="TableSectionFooter">
            <td
              colspan="4"
              style="text-align:left; font-weight:bold; padding-right:20px;">Yr1 Discount:</td>
            <td>£
              <input
                id="numYr1Discount"
                :value="iQuoteFunctions_ThousandSeparator(calculationsCommonMixin_totalYr1Discount.toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr class="TableSectionFooter">
            <td
              colspan="2"
              style="text-align:left; font-weight:bold; padding-right:20px;font-size:1.1em">Contract Term Minimum Commit Total:</td>
            <td colspan="2">£
              <input
                id="numMinCommitTotal"
                :value="iQuoteFunctions_ThousandSeparator((iQuoteCalculationsReviewScreenMixin_tcv).toFixed(2))"
                class="currencyInput"
                readonly="true"
                type="text"
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="quotePanel">
      <div class="buttons">
        <input
          id="btnBack"
          value="< Back"
          type="button"
          @click="gotoPage('Review')"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteCalculationsReviewScreenMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsReviewScreenMixin'
import { iQuoteCalculationsTeamsConnectorMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsTeamsConnectorMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'

export default {
  name: 'BillOfMaterials',
  components: {
    TooltipIcon
  },
  mixins: [
    iQuoteCalculationsCommonMixin, iQuoteFunctions, iQuoteCalculationsReviewScreenMixin, iQuoteStoreMixin, iQuoteCalculationsTeamsConnectorMixin
  ],
  data () {
    return {
      license: {},
      licensesRequiredSummary: [],
      quoteBladeServers: [],
      quote: {}
    }
  },
  computed: {
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    infrastructurePriceTooltip () {
      return 'Total Cost + ' + this.iQuoteStore_GetQuote.marginInfrastructure + '% margin'
    },
    sipPriceTooltip () {
      if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        return 'Total Cost + ' + this.iQuoteStore_GetQuote.marginSipMigration + '% margin'
      }

      if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        return 'Total Cost + ' + this.iQuoteStore_GetQuote.marginGammaSip + '% margin'
      }
    },
    iconContactSummaryTitle () {
      var quoteTechnologyCallmedia = this.iQuoteStore_GetQuoteTechnologyCallmedia
      var quoteTechnologyAvayaElite = this.iQuoteStore_GetQuoteTechnologyAvayaElite
      var quoteTechnologyAvayaAACC = this.iQuoteStore_GetQuoteTechnologyAvayaAACC
      var quoteTechnologyMitelCC = this.iQuoteStore_GetQuoteTechnologyMitelCC

      if (quoteTechnologyAvayaElite.id !== undefined) {
        return 'ICON Contact with Avaya Elite'
      } else if (quoteTechnologyCallmedia.id !== undefined) {
        return 'ICON Contact with Callmedia'
      } else if (quoteTechnologyAvayaAACC.id !== undefined) {
        return 'ICON Contact with Avaya AACC'
      } else if (quoteTechnologyMitelCC.id !== undefined) {
        return 'ICON Contact with Mitel'
      }

      return 'ICON Contact'
    },
    iconCommunicateSummaryTitle () {
      var quoteTechnologyAvayaAura = this.iQuoteStore_GetQuoteTechnologyAvayaAura
      var quoteTechnologyMitelUC = this.iQuoteStore_GetQuoteTechnologyMitelUC

      if (quoteTechnologyAvayaAura.id !== undefined) {
        return 'ICON Communicate with Avaya Aura'
      }
      if (quoteTechnologyMitelUC.id !== undefined) {
        return 'ICON Communicate with Mitel'
      }

      return 'ICON Communicate'
    }
  },
  mounted () {
    var licenses = this.iQuoteStore_GetLicenses
    var licensesRequired = this.iQuoteStore_GetQuoteLicensesRequired

    var description
    this.licensesRequiredSummary = []
    for (let index = 0; index < licensesRequired.length; index++) {
      const licenseRequired = licensesRequired[index]

      licenses.forEach(availableLicense => {
        if (availableLicense.id === licenseRequired.licenseId) {
          if (licenseRequired.visibleToCustomer && availableLicense.chargeIntervalId !== this.iQuoteConstants.chargeInterval.ONE_OFF) {
            description = licenseRequired.description
            switch (licenseRequired.licenseId) {
              case (this.iQuoteConstants.licenses.SIP_ACTIVE_12):
              case (this.iQuoteConstants.licenses.SIP_STANDBY_12):
              case (this.iQuoteConstants.licenses.SIP_ACTIVE_36):
              case (this.iQuoteConstants.licenses.SIP_STANDBY_36):
                description = 'Primary Channels'
                break
              case (this.iQuoteConstants.licenses.TTB_SIP_ACTIVE_12):
              case (this.iQuoteConstants.licenses.TTB_SIP_ACTIVE_36):
                description = 'Secondary Channels'
                break
            }

            this.addToLicensesRequiredSummary(licenseRequired.licenseId, licenseRequired.code, description, licenseRequired.quantity, licenseRequired.price, licenseRequired.annualPrice, this.getLicenseServiceId(licenseRequired.licenseId))
          }
        }
      })
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),
    gotoPage (page) {
      this.setCurrentQuoteState(page)
    },
    serviceLicences (serviceId) {
      var filteredLicenses = []
      var licensesRequired = this.licensesRequiredSummary

      for (let index = 0; index < licensesRequired.length; index++) {
        const license = licensesRequired[index]
        if (this.getLicenseServiceId(license.licenseId) === serviceId) {
          filteredLicenses.push(license)
        }
      }
      return filteredLicenses
    },
    serviceUnitCosts (serviceId) {
      var filteredUnitCosts = []
      for (let index = 0; index < this.customerFacingAnnualUnitCosts.length; index++) {
        const unitCost = this.customerFacingAnnualUnitCosts[index]
        unitCost.annualPrice = unitCost.price * unitCost.paymentsPerYear * unitCost.quantity

        if (this.getUnitCostServiceId(unitCost.unitCostId) === serviceId) {
          if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_SBC_PORT_CHARGE) {
            /* TODO: restricted to just this unitcost for now.  Need to validate that the other unit costs don't have visible to customer set unless they should appear in this list */
            filteredUnitCosts.push(unitCost)
          }
        }
      }
      return filteredUnitCosts
    },
    serviceDescription (serviceId) {
      var serviceDescription = this.getServiceName(serviceId)

      switch (serviceId) {
        case this.iQuoteConstants.services.communicate:
          serviceDescription = this.iconCommunicateSummaryTitle
          break
        case this.iQuoteConstants.services.contact:
          serviceDescription = this.iconContactSummaryTitle
          break
      }
      return serviceDescription
    },
    getServiceName (serviceId) {
      var serviceName = ''
      this.iQuoteStore_GetServices.forEach(service => {
        if (service.id === serviceId) {
          serviceName = service.name
        }
      })

      return serviceName
    },
    addToLicensesRequiredSummary (licenseId, code, description, quantity, price, annualPrice, serviceId) {
    /* Use this function to combine several licenses into one entity for display.
       Note that the quantity, and service etc is set by the first entry added.
       Subsequent additions add to the prices only */

      var existingEntryUpdated = false

      this.licensesRequiredSummary.forEach(license => {
        if (license.description === description) {
          existingEntryUpdated = true
          license.price += price
          license.annualPrice += annualPrice
        }
      })

      // If we haven't already added this license to the quote, we need to add a new entry to the list
      if (!existingEntryUpdated) {
        this.licensesRequiredSummary.push({
          licenseId: licenseId,
          code: code,
          description: description,
          quantity: quantity,
          price: price,
          annualPrice: annualPrice,
          serviceId: serviceId
        })
      }
    }
  }
}
</script>
<style scoped>
  #tblRecurringLicenses{margin-bottom: 10px;}
  #tblRecurringLicenses tr {padding-bottom: 30px;}
  #tblUpfrontFees {margin-bottom: 10px;}
  #tblRecurring {margin-bottom: 10px;display: none;}

  .licenseContainer{
    display: inline-block;
  }

  .quotePanel {
    width: 950px;
    text-align: left;
  }

  #txtiQuoteReference {width: 240px;}
  #txtAccount  {width: 240px;}
  #txtFirstName {width: 115px;}
  #txtLastName {width: 115px;}
  #txtQuoteDescription {width: 240px;}
  #txtTermLength {width: 240px;}

  table tbody.nostripes tr:nth-child(even){
    background-color: white;
  }
</style>
